import { ISelectBox } from './interfaces';


/**
 * The base class for a select box.
 * 
 * @export
 * @class SelectBox
 */
export class SelectBox<TValue> implements ISelectBox<TValue> {

    private targetElement: HTMLElement;
    private devExpressSelectBoxInstance?: DevExpress.ui.dxSelectBox;
    private jQueryStatic: JQueryStatic;

    /**
     * Creates an instance of SelectBox.
     * 
     * @param {HTMLElement} targetElement The target element.
     * @param {Window} currentWindow The current window.
     * @param {JQueryStatic} jQueryStatic jQuery.
     * @memberof SelectBox
     */
    public constructor(targetElement: HTMLElement, currentWindow: Window, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;
        // Close select list during popstate event since it's a view switch
        currentWindow.addEventListener('popstate', () => {
            if (this.devExpressSelectBoxInstance) {
                this.devExpressSelectBoxInstance.close();
            }
        });
    }

    /**
     * Bootstraps the select box.
     * 
     * @param {DevExpress.ui.dxSelectBoxOptions} options 
     * @memberof SelectBox
     */
    public bootstrap(options: DevExpress.ui.dxSelectBoxOptions<DevExpress.ui.dxSelectBox>): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }
        this.devExpressSelectBoxInstance = this.getJQueryElement().dxSelectBox(options).dxSelectBox('instance');
    }

    /**
     * Sets the value.
     *
     * @param {TValue} value
     * @memberof SelectBox
     */
    public setValue(value: TValue): void {
        if (this.devExpressSelectBoxInstance) {
            this.devExpressSelectBoxInstance.option('value', value);
        }
    }

    /**
     * Set the component disabled state.
     *
     * @param {boolean} setDisabled The disabled state.
     * @memberof SelectBox
     */
    public setDisabled(setDisabled: boolean): void {
        if (this.devExpressSelectBoxInstance) {
            this.devExpressSelectBoxInstance.option('disabled', setDisabled);
        }
    }

    /**
     * Gets the select box instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof SelectBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.jQueryStatic) {
            throw new Error('jQuery was not loaded.');
        }

        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }

}