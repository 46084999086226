import { HttpResourcePointer } from '../../dataProviders';
import { IdentityMapper } from '../identityMapper';
import { ArchiveDirectoryRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The Archive action is used to archive a directory within subfolders
 * 
 * @export
 * @class Archive
 * @extends {ServiceAction}
 */
export class Archive extends ServiceAction {
  /**
   * Name of the service action.
   * 
   * 
   * @memberof Archive
   */
  public name = 'archive';

  /**
   * Execute archiving of given directory.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {ArchiveDirectoryRequestOptions} archiveDirectoryRequestOptions The request options.
   * @returns {Promise<void>} A promise, which will resolve with the server response.
   * @memberof Archive
   */
  public async do(archiveDirectoryRequestOptions: ArchiveDirectoryRequestOptions): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/directories/Archive', {
        Item: IdentityMapper.getWebServiceIdentity(archiveDirectoryRequestOptions.identity),
        Recursive: archiveDirectoryRequestOptions.recursive
      }), archiveDirectoryRequestOptions.requestOptions).then((response:  any) => {
        if (response.data?.HasErrors) {
          this.logger.error('Archive', 'do', 'Failed to archive directory', response);
          reject('Failed to archive directory');
        } else {
          resolve(response);
        }
      }).catch((err: Error) => {
        this.logger.error('Archive', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}