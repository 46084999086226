import { ITemplateExtension } from '../extensions';
import { BaseFileTransferManagerHandler, IFileTransferManagerPopOverHandler } from './interfaces';
import { FileTransferManagerPopOverHandler } from '.';

/**
 * Class to File Transfer Manager.
 * 
 * @export
 * @class Transfer Manager
 */
export class FileTransferManagerHandler implements BaseFileTransferManagerHandler {

    public PopOver: IFileTransferManagerPopOverHandler;

    /**
     * Creates an instance of Transfer Manager.
     * @param {HTMLElement} element
     * @param {ITemplateExtension} templateExtension
     * @param {ILanguageProvider} languageProvider
     * @memberof FileTransferManagerPopOverHandler
     */
    public constructor(element: HTMLElement, templateExtension: ITemplateExtension) {
        this.PopOver = new FileTransferManagerPopOverHandler(element, templateExtension);
    }

}
