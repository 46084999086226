import { ILanguageProvider, Logger, Style, IUiComponentFactory, Utils, WindreamEntity, WindreamIdentity } from '../dynamicWorkspace';
import { ILoaderExtension } from '../extensions';
import { ISelectionManager } from '../loader';
import { IPubSubHandlerFactory } from '../pubSub';
import { GetDetailsRequestOptions, IServiceManager } from '../services';
import { WindreamFilePickerPopupOptions } from './models';

/**
 * The windream file picker popup class will render a popup in which a file can be selected.
 *
 * @export
 * @class WindreamFilePickerPopup
 */
export class WindreamFilePickerPopup {

    private currentDocument: Document;
    private languageProvider: ILanguageProvider;
    private jQuery: JQueryStatic;
    private loaderExtension: ILoaderExtension;
    private pubSubHandlerFactory: IPubSubHandlerFactory;
    private serviceManager: IServiceManager;
    private logger: Logger;
    private foundationLib: any;
    private uiComponentFactory: IUiComponentFactory;
    private readonly className = 'WindreamFilePickerPopup';
    private selectionManager?: ISelectionManager;

    /**
     * Creates an instance of WindreamFilePickerPopup.
     * 
     * @param {Document} currentDocument The current document.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {JQueryStatic} jQuery The jQuery lib.
     * @param {ILoaderExtension} loaderExtension The loader extension.
     * @param {IPubSubHandlerFactory} pubSubHandlerFactory The pubsub handler factory.
     * @param {IServiceManager} serviceManager The service manager.
     * @param {Logger} logger The logger.
     * @param {*} foundationLib The foundation lib.
     * @param {IUiComponentFactory} uiComponentFactory The ui component factory.
     * @param {ISelectionManager} [selectionManager] The selection manager.
     * @memberof WindreamFilePickerPopup
     */
    public constructor(currentDocument: Document, languageProvider: ILanguageProvider, jQuery: JQueryStatic,
        loaderExtension: ILoaderExtension, pubSubHandlerFactory: IPubSubHandlerFactory, serviceManager: IServiceManager,
        logger: Logger, foundationLib: any, uiComponentFactory: IUiComponentFactory, selectionManager?: ISelectionManager) {
        this.currentDocument = currentDocument;
        this.languageProvider = languageProvider;
        this.jQuery = jQuery;
        this.loaderExtension = loaderExtension;
        this.pubSubHandlerFactory = pubSubHandlerFactory;
        this.serviceManager = serviceManager;
        this.logger = logger;
        this.foundationLib = foundationLib;
        this.uiComponentFactory = uiComponentFactory;
        this.selectionManager = selectionManager;
    }

    /**
     * Renders the windream file picker popup.
     *
     * @param {WindreamFilePickerPopupOptions} options The options.
     * @returns {Promise<WindreamIdentity[]>} A promise, which will resolve with the selected identities.
     * @memberof WindreamFilePickerPopup
     */
    public render(options: WindreamFilePickerPopupOptions): Promise<WindreamIdentity[]> {
        const modalHtml = this.currentDocument.createElement('div');
        modalHtml.classList.add('reveal', 'large', 'wd-modal');
        const modalContainer = this.currentDocument.createElement('div');
        modalContainer.classList.add('wd-modal-container');
        const modalContainerHeading = this.currentDocument.createElement('div');
        modalContainerHeading.classList.add('wd-modal-heading-container');

        const modalHeading: HTMLDivElement = this.currentDocument.createElement('div');
        modalHeading.classList.add('wd-modal-heading');
        modalHeading.innerHTML = `<h2>${Utils.Instance.escapeStringValue(this.languageProvider.get('popups.windreamFilePicker.title'))}</h2>`;
        modalContainerHeading.appendChild(modalHeading);

        // Close button
        const modalCloseButton = this.currentDocument.createElement('button');
        modalCloseButton.innerHTML = '<span aria-hidden="true">&times;</span>';
        modalCloseButton.setAttribute('title', this.languageProvider.get('framework.generic.close'));
        modalCloseButton.setAttribute('data-close', '');
        modalCloseButton.type = 'button';
        modalCloseButton.classList.add('close-button');
        modalContainerHeading.appendChild(modalCloseButton);
        modalContainer.appendChild(modalContainerHeading);

        const modalContent = this.currentDocument.createElement('div');
        modalContent.classList.add('wd-modal-content');
        modalContainer.appendChild(modalContent);

        const flexContainer = this.currentDocument.createElement('div');
        flexContainer.classList.add('wd-windream-file-picker-flex-container');
        modalContent.appendChild(flexContainer);
        const windreamFilePickerListViewId = 'wd-popup-windream-file-picker-list-' + Utils.Instance.getRandomString();
        const windreamFilePickerTreeViewId = 'wd-popup-windream-file-picker-tree-' + Utils.Instance.getRandomString();

        const treeViewContent = this.currentDocument.createElement('div');
        treeViewContent.classList.add('wd-windream-file-picker-tree-container');
        treeViewContent.id = windreamFilePickerTreeViewId;
        treeViewContent.style.height = 'calc(60vh - 3.2rem)';
        treeViewContent.style.overflowY = 'auto';
        flexContainer.appendChild(treeViewContent);

        const listViewContent = this.currentDocument.createElement('div');
        listViewContent.classList.add('wd-windream-file-picker-list-view-container');
        listViewContent.id = windreamFilePickerListViewId;
        listViewContent.style.height = 'calc(60vh - 3.2rem)';
        listViewContent.style.overflowY = 'auto';
        listViewContent.classList.add('wd-style-colors-0');
        flexContainer.appendChild(listViewContent);


        // Container
        const inputContainer = this.currentDocument.createElement('div');
        inputContainer.classList.add('dx-field-item');
        // Label
        const inputLabel = this.currentDocument.createElement('div');
        inputLabel.textContent = this.languageProvider.get('popups.windreamFilePicker.label');
        inputLabel.classList.add('dx-field-item-label', 'dx-field-item-label-location-top');
        // Input 
        const inputField = this.currentDocument.createElement('div');

        inputContainer.appendChild(inputLabel);
        inputContainer.appendChild(inputField);
        modalContent.appendChild(inputContainer);
        modalHtml.appendChild(modalContainer);

        const modalFooter = this.currentDocument.createElement('div');
        modalFooter.classList.add('wd-modal-footer');
        const modalFooterButtons = this.currentDocument.createElement('div');
        modalFooterButtons.classList.add('wd-button-group');
        modalFooter.appendChild(modalFooterButtons);

        modalHtml.appendChild(modalFooter);
        this.currentDocument.body.appendChild(modalHtml);
        if (this.selectionManager) {
            this.selectionManager.attachSelectionListenerToElement(modalHtml);
        }
        return this.initializeUi(options, '#' + windreamFilePickerTreeViewId, '#' + windreamFilePickerListViewId,
            modalFooterButtons, inputField, modalHtml);
    }

    /**
     * Initialize the ui.
     *
     * @private
     * @param {WindreamFilePickerPopupOptions} options The options.
     * @param {string} treeViewPosition The treeview position to bootstrap.
     * @param {string} listViewPosition The listview position to bootstrap.
     * @param {HTMLDivElement} footerButtonContainer The footer button container to bootstrap.
     * @param {HTMLDivElement} inputField The input field to bootstrap.
     * @param {HTMLDivElement} modalHtml The modal html.
     * @returns {Promise<WindreamIdentity[]>} A promise, which will resolve with the selcected identities.
     * @memberof WindreamFilePickerPopup
     */
    private initializeUi(options: WindreamFilePickerPopupOptions, treeViewPosition: string, listViewPosition: string,
        footerButtonContainer: HTMLDivElement, inputField: HTMLDivElement, modalHtml: HTMLDivElement): Promise<WindreamIdentity[]> {
        const methodName = 'initializeUi';
        return new Promise<WindreamIdentity[]>((resolve, reject) => {
            let currentlySelectedIdentities = new Array<WindreamIdentity>();
            let modal: any;
            const treeViewGuid = 'POPUP_WINDREAM_FILE_PICKER_TREEVIEW';
            const listViewGuid = 'POPUP_WINDREAM_FILE_PICKER_LISTVIEW';
            const internalSubscription = 'POPUP_WINDREAM_FILE_PICKER';

            const okButton = this.currentDocument.createElement('div');
            const dxOkButton = this.uiComponentFactory.button(okButton);
            dxOkButton.bootstrap();
            dxOkButton.setOptions({
                text: this.languageProvider.get('framework.generic.ok'),
                isDisabled: true, stylingMode: 'text'
            });
            dxOkButton.onClick = () => {
                if (modal) {
                    modal.close();
                    modal.destroy();
                    modalHtml.remove();
                }
                resolve(currentlySelectedIdentities);
            };
            footerButtonContainer.appendChild(okButton);

            const cancelButton = this.currentDocument.createElement('div');
            const dxCancelButton = this.uiComponentFactory.button(cancelButton);
            dxCancelButton.bootstrap();
            dxCancelButton.setOptions({
                text: this.languageProvider.get('framework.generic.cancel'),
                stylingMode: 'text'
            });
            dxCancelButton.onClick = () => {
                if (modal) {
                    modal.close();
                    modal.destroy();
                    modalHtml.remove();
                }
                resolve(new Array<WindreamIdentity>());
            };
            footerButtonContainer.appendChild(cancelButton);

            const inputTextBox = this.uiComponentFactory.textBox(inputField);
            inputTextBox.bootstrap();
            inputTextBox.setOptions({ isReadOnly: true });
            const pubSub = this.pubSubHandlerFactory.create();
            this.loaderExtension.loadComponent({
                component: 'com.windream.treeview',
                configuration: {
                    DragDropEnabled: false,
                    ShowFavorites: false,
                    IsFavoritesEnabled: false
                },
                guid: treeViewGuid,
                isTitleVisible: false,
                name: { en: 'com.windream.treeview' },
                position: treeViewPosition,
                style: Style.default()
            }, pubSub).then(() => {
                this.loaderExtension.loadComponent({
                    component: 'com.windream.listView',
                    configuration: {
                        Columns: [{ name: 'szLongName' }],
                        MultiSelect: !!options.allowMultiSelect,
                        StartupPath: '\\',
                        DragDropEnabled: !!options.allowUpload
                    },
                    guid: listViewGuid,
                    isTitleVisible: false,
                    name: { en: 'com.windream.listView' },
                    position: listViewPosition,
                    style: Style.default()
                }, pubSub).then(() => {
                    // Set up PubSub configuration
                    pubSub.loadPubSub([{
                        in: [{
                            componentGuid: treeViewGuid,
                            parameter: 'SelectedFolderIdentity'
                        }],
                        out: [{
                            componentGuid: treeViewGuid,
                            parameter: 'FolderClicked'
                        }]
                    },
                    {
                        in: [{
                            componentGuid: listViewGuid,
                            parameter: 'CurrentWorkingFolder'
                        }],
                        out: [{
                            componentGuid: treeViewGuid,
                            parameter: 'FolderClicked'
                        }]
                    },
                    {
                        in: [{
                            componentGuid: listViewGuid,
                            parameter: 'SelectedNode'
                        }],
                        out: [{
                            componentGuid: listViewGuid,
                            parameter: 'SelectedFolder'
                        }]
                    },
                    {
                        in: [{
                            componentGuid: treeViewGuid,
                            parameter: 'SelectedFolderIdentity'
                        }],
                        out: [{
                            componentGuid: listViewGuid,
                            parameter: 'SelectedFolder'
                        }, {
                            componentGuid: listViewGuid,
                            parameter: 'DoubleClickedFolder'
                        }]
                    },
                    {
                        in: [{
                            componentGuid: internalSubscription,
                            parameter: 'SelectedItems'
                        }],
                        out: [{
                            componentGuid: listViewGuid,
                            parameter: 'SelectedItems'
                        }]
                    }
                    ]);
                    pubSub.subscribe(internalSubscription, 'SelectedItems', (fileIdentityPromise: Promise<WindreamIdentity[]>) => {
                        fileIdentityPromise.then((identities) => {
                            // eslint-disable-next-line max-nested-callbacks
                            identities = identities.filter((identity) => identity.entity === WindreamEntity.Document);
                            currentlySelectedIdentities = identities;
                            dxOkButton.setDisabled(identities.length === 0);
                            inputTextBox.setValue(this.generateInputDisplayString(identities));
                        }).catch((error) => {
                            this.logger.error(this.className, methodName, 'SelectedItems encountered an error', error);
                        });
                    });
                    const preSelectedFolder = new WindreamIdentity();
                    preSelectedFolder.entity = WindreamEntity.Folder;
                    preSelectedFolder.setLocationComplete(options.startUpPath ? options.startUpPath : '\\');
                    this.serviceManager.getServices().Directories.getDetails(new GetDetailsRequestOptions(preSelectedFolder))
                        .then((data) => {
                            const windreamIdentity = new WindreamIdentity();
                            windreamIdentity.id = data.id;
                            windreamIdentity.entity = data.entity;
                            windreamIdentity.location = data.location;
                            windreamIdentity.name = data.name;
                            pubSub.publish(treeViewGuid, 'FolderClicked', [
                                windreamIdentity
                            ]);
                        }).catch((error) => {
                            this.logger.warn(this.className, methodName, 'Can not preselect path', error);
                            reject(error);
                        });
                    modal = new this.foundationLib.Reveal(this.jQuery(modalHtml), {
                        animationIn: 'scale-in-up',
                        animationOut: 'scale-out-down',
                        closeOnClick: false,
                        closeOnEsc: false
                    });
                    // Destroy modal on close
                    modal.$element.on('closed.zf.reveal', () => {
                        modal.destroy();
                        modalHtml.remove();
                        resolve(new Array<WindreamIdentity>());
                    });
                    modal.open();
                }).catch((error) => reject(error));
            }).catch((error) => reject(error));
        });
    }

    /**
     * Generates the input display string.
     *
     * @private
     * @param {WindreamIdentity[]} windreamIdentities The identities to display.
     * @returns {string} The display string.
     * @memberof WindreamFilePickerPopup
     */
    private generateInputDisplayString(windreamIdentities: WindreamIdentity[]): string {
        let inputDisplayString = '';
        windreamIdentities.forEach((identity, index) => {
            if (index > 0) {
                inputDisplayString += '; ' + identity.name;
            } else {
                inputDisplayString += identity.name;
            }
        });
        return inputDisplayString;
    }
}