/**
 * Represents all available sort types.
 *
 * @export
 * @enum {number}
 */
export enum ColumnSortType {
    None = 0,
    Ascending = 1,
    Descending = 2
}