import { IServiceResponse } from '../../ajaxHandler';
import { WindreamEntity } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The WorkLockDirectory action is used to lock a directory for editing.
 *
 * @export
 * @class WorkLockDirectory
 * @extends {ServiceAction}
 */
export class WorkLockDirectory extends ServiceAction {

    /**
     * Creates an instance of WorkLockDirectory.
     *
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global configuration.
     * @param {Logger} logger The logger.
     * @memberof WorkLockDirectory
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'workLockDirectory';
    }


    /**
     * Locks a directory for editing.
     * Resolves with the converted server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WindreamRequestOptions} windreamRequestOptions The request options.
     * @returns {Promise<void>} The result promise.
     * @memberof WorkLock
     */
    public async do(windreamRequestOptions: WindreamRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (windreamRequestOptions.identity.entity !== WindreamEntity.Folder) {
                this.logger.error('WorkLock', 'do', 'Identity is not a directory', windreamRequestOptions.identity);
                reject(new Error('Identity is not a directory'));
                return;
            }
            const url = `${this.globalConfig.windreamWebServiceURL}/directories/WorkLock`;
            const documentLocationId = IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity);
            const payload = JSON.stringify({
                Item: documentLocationId
            });

            const resourcePointer = new HttpResourcePointer('POST', url, payload);
            this.requestExecutor.executeRequest(resourcePointer, windreamRequestOptions.requestOptions)
                .then((response: IServiceResponse<any>) => {
                    if (!response || !response.data || response.data.HasErrors) {
                        this.logger.error('WorkLock', 'do', 'Failed to set a worklock', response);
                        reject(new Error('Failed to set a worklock'));
                        return;
                    }

                    resolve();

                }).catch((error) => {
                    this.logger.error('WorkLock', 'do', 'Failed to execute request', error);
                    reject(error);
                });
        });
    }

}