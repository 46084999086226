import * as CryptoJS from 'crypto-js';

/**
 * Provides crypto standards.
 *
 * @export
 * @class EncryptionHelper
 */
export class EncryptionHelper {

  /**
   * The size of the encryption key.
   *
   * @private
   * @memberof EncryptionHelper
   */
  private readonly keyBitSize = 256;

  /**
   * The bit size of a DWORD.
   *
   * @private
   * @memberof EncryptionHelper
   */
  private readonly bitWordSize = 32;

  /**
   * Encrypts a message into AES string.
   *
   * @param {string} message The message to encrypt.
   * @param {string} secret The secret.
   * @returns {string} The encrypted text in AES.
   * @memberof EncryptionHelper
   */
  public encryptAES(message: string, secret: string): string {

    const key = CryptoJS.enc.Hex.parse(secret);

    const encryptor = CryptoJS.algo.AES.createEncryptor(key, {
      iv: CryptoJS.enc.Hex.parse(secret.substr(0, secret.length - 1))
    });
    if (!encryptor) {
      throw new Error('EncryptionHelper.encryptAES(): Can not create the encryptor instance.');
    }

    const fragment_size = 10000;
    const encryptedChunks = [];

    try {
      const messageLength = message.length;
      for (let i = 0; i * fragment_size < messageLength; ++i) {
        const chunk = message.slice(i * fragment_size, i * fragment_size + fragment_size);
        const encryptedChunk = encryptor.process(chunk);
        if (encryptedChunk) {
          encryptedChunks.push(CryptoJS.enc.Base64.stringify(encryptedChunk));
        }
      }
      const finalEncryptedChunk = encryptor.finalize();
      if (finalEncryptedChunk) {
        encryptedChunks.push(CryptoJS.enc.Base64.stringify(finalEncryptedChunk));
      }
    } catch (error) {
      throw new Error('EncryptionHelper.encryptAES(): ' + error);
    }

    return JSON.stringify(encryptedChunks);
  }

  /**
   * Decrypts a text into a readable string.
   *
   * @param {string} encryptedMessage The encrypted text.
   * @param {string} secret The secret.
   * @returns {string} The readable string.
   * @memberof EncryptionHelper
   */
  public decryptAES(encryptedMessage: string, secret: string): string {

    const key = CryptoJS.enc.Hex.parse(secret);

    const decryptor = CryptoJS.algo.AES.createDecryptor(key, {
      iv: CryptoJS.enc.Hex.parse(secret.substr(0, secret.length - 1))
    });
    if (!decryptor) {
      throw new Error('EncryptionHelper.decryptAES(): Can not create the decryptor instance.');
    }

    let decryptedMessage = '';

    try {
      const encryptedArray = JSON.parse(encryptedMessage);
      const arrayLength = encryptedArray.length;
      for (let i = 0; i < arrayLength; ++i) {
        const decryptedChunk: CryptoJS.lib.WordArray = decryptor.process(CryptoJS.enc.Base64.parse(encryptedArray[i]));
        if (decryptedChunk) {
          decryptedMessage += decryptedChunk.toString(CryptoJS.enc.Utf8);
        }
      }
      const decryptedFinalChunk: CryptoJS.lib.WordArray = decryptor.finalize();
      if (decryptedFinalChunk) {
        decryptedMessage += decryptedFinalChunk.toString(CryptoJS.enc.Utf8);
      }

    } catch (error) {
      throw new Error('EncryptionHelper.decryptAES(): ' + error);
    }

    return decryptedMessage;
  }

  /**
   * Generates a PBKDF2 from a message and a salt.
   *
   * @param {string} message The message.
   * @param {string} salt The salt.
   * @returns {string} The PBKDF2.
   * @memberof EncryptionHelper
   */
  public generatePBKDF2(message: string, salt: string): string {
    const keyLength256Bit = this.keyBitSize / this.bitWordSize;
    return CryptoJS.PBKDF2(message, salt, { keySize: keyLength256Bit, iterations: 1000 }).toString();
  }

  /**
   * Generates a SHA from the given message.
   *
   * @param {string} message The message to transform to SHA.
   * @returns {string} The message as SHA256 string.
   * @memberof EncryptionHelper
   */
  public sha256(message: string): string {
    return CryptoJS.SHA256(message).toString();
  }

}