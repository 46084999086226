import { IIntentHandler, IIntentManager } from './interfaces';
import { SHARE_INTENTS } from './models';
/**
 * Manage drag and drop share intennts.
 * 
 * @export
 * @class IntentManager
 * @implements {IIntentManager}
 */
export class IntentManager implements IIntentManager {
    private intentHandler: IIntentHandler[];
    /**
     * Creates an instance of IntentManager.
     * @memberof IntentManager
     */
    public constructor() {
        this.intentHandler = new Array<IIntentHandler>();
    }

    /**
     * Adds the given handler to the list of used handlers.
     * 
     * @param {IIntentHandler} handler Handler to add.
     * @memberof IntentManager
     */
    public addIntentHandler(handler: IIntentHandler): void {
        this.intentHandler.push(handler);
    }

    /**
     * Sends the intent.
     * 
     * @param {SHARE_INTENTS} intent Type of the intent to send.
     * @param {*} data Payload to send with the intent.
     * @returns {boolean} Whether the intent was handled.
     * @memberof IntentManager
     */
    public sendIntent(intent: SHARE_INTENTS, data: any): boolean {
        for (const handler of this.intentHandler) {
            if (handler.canHandleIntent(intent)) {
                handler.handleIntent(intent, data);
                return true;
            }
        }
        return false;
    }
}