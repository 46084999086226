import { EXTENSIONS_CORE_EVENT } from 'typings/core';
import { DWCore } from '../dynamicWorkspace';
import { CoreProvider } from './coreProvider';
import { ExternalCoreError } from './externalCoreError';
import { ExternalCoreErrorCode } from './externalCoreErrorCode';


/**
 * The external core implementation of the core provider interface.
 *
 * @export
 * @class ExternalCoreProvider
 * @implements {CoreProvider}
 */
export class ExternalCoreProvider implements CoreProvider {

    private readonly externalCoreBaseUrl = './extensions/core';
    private readonly document: Document;
    private readonly scriptID: string = '62DB3DC9-9BF8-4AC7-BBAA-6B515406C64E';
    private readonly HTTP_STATUS_CODE_NOT_FOUND = 404;


    /**
     * Creates an instance of ExternalCoreProvider.
     *
     * @param {Document} document The DOM document.
     * @memberof ExternalCoreProvider
     */
    public constructor(document: Document) {
        this.document = document;
    }


    /**
     * Loads the core.
     *
     * @return {Promise<DWCore.Core | undefined>}
     * @memberof ExternalCoreProvider
     */
    public async loadCore(): Promise<DWCore.Core> {
        return new Promise<DWCore.Core>(async (resolve, reject) => {
            const dwCoreErrorEvent = this.document.body.addEventListener(EXTENSIONS_CORE_EVENT.ON_ERROR, (event: CustomEvent) => {
                // @ts-ignore - Not matching typings
                this.document.body.removeEventListener(EXTENSIONS_CORE_EVENT.ON_ERROR, dwCoreErrorEvent);

                reject(new ExternalCoreError(event.detail, ExternalCoreErrorCode.runtimeError));
                return;
            });

            const dwCoreLoadedEvent = this.document.body.addEventListener(EXTENSIONS_CORE_EVENT.ON_LOADED, (event: CustomEvent) => {
                // @ts-ignore - Not matching typings
                this.document.body.removeEventListener(EXTENSIONS_CORE_EVENT.ON_LOADED, dwCoreLoadedEvent);

                resolve(event.detail);
                return;
            });

            try {
                // Load the core config
                const coreVersion = await this.loadVersion();

                await this.loadScript(this.externalCoreBaseUrl + `/core.js?v=${encodeURIComponent(coreVersion)}`);
            } catch (error) {
                reject(error);
                return;
            }
        });
    }

    /**
     * Loads the version of the external core.
     *
     * @private
     * @return {Promise<string>}
     * @memberof ExternalCoreProvider
     */
    private loadVersion(): Promise<string> {
        return new Promise<string>(async (resolve) => {

            try {
                const versionInfoResponse = await fetch(this.externalCoreBaseUrl + `/version.json?r=${encodeURIComponent(new Date().getTime().toString())}`);
                if (versionInfoResponse.ok) {
                    const versionInfo = await versionInfoResponse.json();
                    if (versionInfo && versionInfo.version) {
                        resolve(versionInfo.version);
                        return;
                    } else {
                        throw new Error('No version defined within the external core.');
                    }
                } else {
                    throw new Error(versionInfoResponse.statusText);
                }
            } catch {
                // If no core configuration could be loaded,
                // then just use a random string as version, so that the core.js file will be fetched in any case.
                resolve(new Date().getTime().toString());
                return;
            }
        });
    }

    /**
     * Loads a script file and returns a Promise for when it is loaded.
     *
     * @private
     * @param {string} uri The script source URI.
     * @return {Promise<void>}
     * @memberof ExternalCoreProvider
     */
    private loadScript(uri: string): Promise<HTMLScriptElement> {
        return new Promise<HTMLScriptElement>(async (resolve, reject) => {

            // Check if the script was already loaded.
            const foundScript = this.document.getElementById(this.scriptID) as HTMLScriptElement;
            if (foundScript) {
                resolve(foundScript);
                return;
            }
            try {
                const scriptResponse = await fetch(uri);
                if (scriptResponse.ok) {
                    const script = this.document.createElement('script');
                    script.id = this.scriptID;
                    script.innerHTML = await scriptResponse.text();
                    this.document.head.appendChild(script);
                    resolve(script);
                    return;
                } else {
                    if (scriptResponse.status === this.HTTP_STATUS_CODE_NOT_FOUND) {
                        reject(new ExternalCoreError(scriptResponse.statusText, ExternalCoreErrorCode.notFound));
                    } else {
                        reject(new ExternalCoreError(scriptResponse.statusText, ExternalCoreErrorCode.runtimeError));
                    }
                    return;
                }
            } catch {
                // Fetch fails ungraceful if the network is offline
                reject(new ExternalCoreError('Failed to fetch external core script', ExternalCoreErrorCode.notFound));
                return;
            }
        });
    }

}