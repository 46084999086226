import { IWebsocketProvider } from '../websocket';
import { IIndexMaskExtension } from './interfaces';

/**
 * The IndexMaskExtension provides the connection for the websocket index mask.
 * 
 * @export
 * @class IndexMaskExtension
 * @implements {IIndexMaskExtension}
 */
export class IndexMaskExtension implements IIndexMaskExtension {

  /**
   * The name of the index mask extension.
   * 
   * @type {string}
   * @memberof IndexMaskExtension
   */
  public name: string = 'index_mask';

  private websocketProvider: IWebsocketProvider;

  /**
   * Creates an instance of IndexMaskExtension.
   * @param {IWebsocketProvider} websocketProvider 
   * @param {Logger} logger 
   * 
   * @memberof IndexMaskExtension
   */
  public constructor(websocketProvider: IWebsocketProvider) {
    this.websocketProvider = websocketProvider;
  }

  /**
   * Returns a open websocket connection.
   * 
   * @param {string} url  URL to connect to.
   * @returns {Promise<WebSocket>}  Promise to resolve with the open websocket connection.
   * @async
   * 
   * @memberof IndexMaskExtension
   */
  public async getConnection(url: string): Promise<WebSocket> {
      return this.websocketProvider.getConnection(url);
  }
}