import { BaseRequestOptions } from '..';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging/';
import { ServiceAction } from '../serviceAction';

/**
 * Provides the CheckAnnotationsEnvironmentPrerequisites service action.
 *
 * @export
 * @class CheckAnnotationsEnvironmentPrerequisites
 * @extends {ServiceAction}
 */
export class CheckAnnotationsEnvironmentPrerequisites extends ServiceAction {

    /**
     * Creates an instance of CheckAnnotationsEnvironmentPrerequisites.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof CheckAnnotationsEnvironmentPrerequisites
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'checkAnnotationsEnvironmentPrerequisites';
    }

    /**
     * Check if annotations are available.
     *
     * @param {BaseRequestOptions} [baseRequestOptions] The request options.
     * @returns {Promise<void>} A promise which will resolve if annotations are available.
     * @memberof CheckAnnotationsEnvironmentPrerequisites
     */
    public async do(baseRequestOptions?: BaseRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/annotations/CheckEnvironmentPrerequisites`;
            this.requestExecutor.executeRequest(new HttpResourcePointer('GET', tempUrl), baseRequestOptions?.requestOptions)
                .then(() => {
                    // No check required because if it doesn't fail annotations are available
                    resolve();
                }).catch((err: Error) => {
                    this.logger.error('CheckAnnotationsEnvironmentPrerequisites', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}