
/**
 * Thrown by the runtime only when a undefined object is referenced.
 * 
 * @export
 * @class UndefinedReferenceError
 * @extends {Error}
 */
export class UndefinedReferenceError extends Error {

    /**
     * Creates an instance of UndefinedReferenceError.
     * @param {string} [message] 
     * 
     * @memberof UndefinedReferenceError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'UndefinedReferenceError';
    }
}