import { ComponentOperationParameter } from '../components/componentOperationParameter';
import { InConfig } from '../config';
import { ComponentConfig } from '../dynamicWorkspace';
import { LanguageProvider } from '../language/languageProvider';
import { IOperation } from '../pipeline';
import { PubSubDefinitions } from './pubSubDefinitions';

/**
 * This operation adds automatically "SetEnabled" and "SetDisabled" PubSub inputs to components.
 *
 * @export
 * @class AddEnableDisablePubSubOperation
 */
export class AddEnableDisablePubSubOperation implements IOperation<ComponentOperationParameter> {

    /**
     * Invokes the operation.
     *
     * @param {ComponentOperationParameter} parameter The operation parameter.
     * @returns {boolean} Determines whether the operation has failed or succeeded.
     * @memberof AddEnableDisablePubSubOperation
     */
    public invoke(parameter: ComponentOperationParameter): boolean {
        if (!parameter || !parameter.componentData || !parameter.languageManager) {
            return false;
        }

        const frameworkLanguageProvider = parameter.languageManager.getLanguageProvider('framework');

        // Inject the Enabled PubSub input
        const enabledPubSubName = frameworkLanguageProvider.get('framework.config.pubSub.enableComponent.name');
        const enabledPubSubDescription = frameworkLanguageProvider.get('framework.config.pubSub.enableComponent.description');
        this.addPubSubInput(
            parameter.componentData.componentConfig,
            parameter.componentData.languageProvider as LanguageProvider,
            PubSubDefinitions.PUBSUB_INPUT_SET_ENABLED_NAME,
            enabledPubSubName,
            enabledPubSubDescription,
            'DATATYPE_BOOLEAN'
        );

        // Inject the Disabled PubSub input
        const disabledPubSubName = frameworkLanguageProvider.get('framework.config.pubSub.disableComponent.name');
        const disabledPubSubDescription = frameworkLanguageProvider.get('framework.config.pubSub.disableComponent.description');
        this.addPubSubInput(
            parameter.componentData.componentConfig,
            parameter.componentData.languageProvider as LanguageProvider,
            PubSubDefinitions.PUBSUB_INPUT_SET_DISABLED_NAME,
            disabledPubSubName,
            disabledPubSubDescription,
            'DATATYPE_BOOLEAN'
        );

        return true;
    }

    /**
     * Adds a new PubSub input configuration.
     *
     * @private
     * @param {ComponentConfig} componentConfig The component configuration.
     * @param {LanguageProvider} componentLanguageProvider The component language provider.
     * @param {string} name The PubSub name.
     * @param {string} displayName The PubSub display name.
     * @param {string} description The PubSub description.
     * @param {(string | string[])} [dataType] The PubSub data type.
     * @memberof AddEnableDisablePubSubOperation
     */
    private addPubSubInput(componentConfig: ComponentConfig, componentLanguageProvider: LanguageProvider, name: string, displayName: string, description: string, dataType?: string | string[]): void {
        if (!componentConfig || componentConfig.isLogic || !componentConfig.pubSub || !componentLanguageProvider) {
            return;
        }

        // Only add the PubSub input, if it not already exists.
        if (!componentConfig.pubSub.in.find((inConfig) => inConfig.name === name)) {
            const newInConfig: InConfig = {
                name: name,
                description: description,
                dataType: dataType,
                id: `${componentConfig.id}.publisher.${name}`
            };
            componentConfig.pubSub.in.push(newInConfig);
        }

        if (componentLanguageProvider && typeof (componentLanguageProvider.set) === 'function') {
            componentLanguageProvider.set(`__windream.pubSub.${name}.name`, displayName);
            componentLanguageProvider.set(`__windream.pubSub.${name}.description`, description);
        }
    }

}