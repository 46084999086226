import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';
import { Utils } from '../dynamicWorkspace';
import { Logger } from '../logging/logger';
import { ILanguageManager, ILanguageProvider } from './interfaces';
import { IMultilingualTranslator, LanguageLoader, LanguageProvider } from '.';

/**
 * The LanguageManager handels all LanguageProvider and the communication with the server.
 * @version 1.0
 * @exports LanguageManager
 */
export class LanguageManager implements ILanguageManager {
    private requestExecutor: IRequestExecutor;
    private currentLanguage: string;
    private components: string[];
    private providerMap: Map<string, ILanguageProvider> = new Map<string, ILanguageProvider>();
    private logger: Logger;
    private className: string = 'LanguageManager';
    private translator: IMultilingualTranslator;
    private jQueryStatic: JQueryStatic;


    /**
     * Creates an instance of LanguageManager.
     *
     * @param {IRequestExecutor} requestExecutor
     * @param {string} language
     * @param {string[]} components
     * @param {Logger} logger
     * @param {IMultilingualTranslator} translator
     * @param {JQueryStatic} jQueryStatic
     * @memberof LanguageManager
     */
    public constructor(requestExecutor: IRequestExecutor, language: string, components: string[], logger: Logger,
        translator: IMultilingualTranslator, jQueryStatic: JQueryStatic) {
        this.requestExecutor = requestExecutor;
        this.currentLanguage = language;
        this.components = components;
        this.logger = logger;
        this.translator = translator;
        this.jQueryStatic = jQueryStatic;
    }

    public getLanguageFileUrl(componentId: string): string {
        if (componentId.toLowerCase() === 'framework') {
            return './framework/language/' + this.currentLanguage + '/translation.json';
        } else {
            const componentBasePath = Utils.getComponentBasePath(componentId);
            return `${componentBasePath}/language/${this.currentLanguage}/translation.json`;
        }
    }

    /**
     * Gets the LanguageProvider for a specific component.
     * @param {string} componentName The component name
     * @returns {Object} The LanguageProvider
     * @access public
     */
    public getLanguageProvider(componentName: string): ILanguageProvider {
        const methodName: string = 'getLanguageProvider';
        if (!componentName || typeof (componentName) !== 'string') {
            this.logger.info(this.className, methodName, 'componentName is not valid');
        }
        const specificUrl = this.getLanguageFileUrl(componentName);

        const languageProvider: ILanguageProvider | undefined = this.providerMap.get(specificUrl);
        if (languageProvider) {
            return languageProvider;
        } else {
            this.logger.info(this.className, methodName, `The translation for '${componentName}' was empty or not loaded`);
            return new LanguageProvider(null, this.currentLanguage, this.logger, this.translator, this.jQueryStatic);
        }
    }

    /**
     * Gets the current Language Culture Name.
     * 
     * @returns {string} The Language Culture Name.
     * 
     * @memberof LanguageManager
     */
    public getLanguageCultureName(): string {
        return this.currentLanguage;
    }

    /**
     * Initializes the language manager.
     * 
     * @returns {Promise<boolean>}  Resolves once the initialization is done.
     * @async
     * 
     * @memberof LanguageManager
     */
    public async init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const customUrl = './language/custom/' + this.currentLanguage + '/customTranslation.json';
            const frameworkLanguageProviderLocation = this.getLanguageFileUrl('framework');
            let customTranslationData = {};
            LanguageLoader.getLanguageFile(this.requestExecutor, customUrl, (data) => {
                if (data) {
                    customTranslationData = data;
                } else {
                    this.logger.debug(this.className, 'init', 'Can\'t load custom language');
                }
                LanguageLoader.getLanguageFile(this.requestExecutor, frameworkLanguageProviderLocation, (data) => {
                    if (data) {
                        const provider = new LanguageProvider(data, this.currentLanguage, this.logger, this.translator, this.jQueryStatic, customTranslationData);
                        this.providerMap.set(frameworkLanguageProviderLocation, provider);
                        resolve(true);
                    } else {
                        this.logger.debug(this.className, 'init', 'Can\'t load framework language');
                        reject(new Error('Can\'t load framework language'));
                    }
                });
            });
        });
    }

    /**
     * Gets a language provider from a language file url.
     *
     * @param {string} languageFileUrl The url to the language file.
     * @returns {Promise<ILanguageProvider>} A promise, resolving with the language provider.
     * @memberof LanguageManager
     */
    public async loadLanguageProviderFromUrl(languageFileUrl: string): Promise<ILanguageProvider> {
        return new Promise<ILanguageProvider>((resolve, reject) => {
            let customTranslationData: Object | undefined;
            const frameworkUrl = './framework/language/' + this.currentLanguage + '/translation.json';
            LanguageLoader.getLanguageFile(this.requestExecutor, languageFileUrl, (data) => {
                if (data) {
                    customTranslationData = data;
                } else {
                    this.logger.debug(this.className, 'loadLanguageProviderFromUrl', 'Can\'t load language file ' + languageFileUrl);
                }
                LanguageLoader.getLanguageFile(this.requestExecutor, frameworkUrl, (data) => {
                    if (data) {
                        let provider = this.providerMap.get(languageFileUrl);
                        if (!provider) {
                            provider = new LanguageProvider(data, this.currentLanguage, this.logger, this.translator, this.jQueryStatic, customTranslationData);
                            this.providerMap.set(languageFileUrl, provider);
                        }
                        resolve(provider);
                        return;
                    } else {
                        this.logger.debug(this.className, 'loadLanguageProviderFromUrl', 'Can\'t load framework language');
                        reject(new Error('Failed to create langauge provider because of missing data'));
                    }
                });
            });
        });
    }
    /**
     * Load all available language files for each component.
     * 
     * @param {string[]} components List of components name.
     * @returns {Promise<boolean>}  Promise to resolve once all language files have been loaded.
     * @async
     * 
     * @memberof LanguageManager
     */
    public async loadAllLanguageFiles(): Promise<boolean> {
        const methodName: string = 'loadAllLanguageFiles';
        return new Promise<boolean>((resolve) => {
            let callbackCounter = 0;
            const languageUrls = new Array<string>();

            if (this.components && this.components.length > 0) {
                this.components.forEach((component) => {
                    const componentBasePath = Utils.getComponentBasePath(component);
                    languageUrls.push(`${componentBasePath}/language/${this.currentLanguage}/translation.json`);
                });
            } else {
                this.logger.info(this.className, methodName, 'components are not set, not loading translation for these files');
            }
            let frameworkLanguageData: Object;
            let customTranslationData: Object | undefined;
            const frameworkUrl = './framework/language/' + this.currentLanguage + '/translation.json';
            const customUrl = './language/custom/' + this.currentLanguage + '/customTranslation.json';
            LanguageLoader.getLanguageFile(this.requestExecutor, customUrl, (data) => {
                if (data) {
                    customTranslationData = data;
                } else {
                    this.logger.debug(this.className, 'loadAllLanguageFiles', 'Can\'t load custom language');
                }
                LanguageLoader.getLanguageFile(this.requestExecutor, frameworkUrl, (data) => {
                    if (data) {
                        frameworkLanguageData = data;
                        if (!this.providerMap.has(frameworkUrl)) {
                            const provider = new LanguageProvider(data, this.currentLanguage, this.logger, this.translator, this.jQueryStatic, customTranslationData);
                            this.providerMap.set(frameworkUrl, provider);
                        }
                    } else {
                        this.logger.debug(this.className, 'loadAllLanguageFiles', 'Can\'t load framework language');
                    }
                    languageUrls.forEach((url) => {
                        LanguageLoader.getLanguageFile(this.requestExecutor, url, (data) => {
                            callbackCounter++;

                            if (data) {
                                if (!this.providerMap.has(url)) {
                                    const provider = new LanguageProvider(data, this.currentLanguage, this.logger, this.translator, this.jQueryStatic, customTranslationData, frameworkLanguageData);
                                    this.providerMap.set(url, provider);
                                }
                            } else {
                                this.logger.debug(this.className, 'loadAllLanguageFiles', 'The translation was empty');
                            }

                            if (callbackCounter === this.components.length) {
                                resolve(true);
                            }
                        });
                    });
                });
            });

        });
    }
}