import { IServiceResponse } from '../ajaxHandler/index';
import { HttpResourcePointer } from '../dataProviders/httpResourcePointer';
import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../logging';
import { IServiceManager } from '../services/interfaces/iServiceManager';
import { Version } from '../version';
import { ILicenseSeviceHelper, LicenseDetails, ThirdPartyLicense, WindreamLicense } from './index';

/**
 * This class provides data regarding the licenses.
 *
 * @export
 * @class LicenseServiceHelper
 * @implements {ILicenseSeviceHelper}
 */
export class LicenseServiceHelper implements ILicenseSeviceHelper {
    private readonly thirdPartyLicensesResourceUri = './license/thirdPartyLicenses.json';
    private readonly licenseResourceUri = './license/license.json';
    private readonly className = 'LicenseServiceHelper';
    private requestExecutor: IRequestExecutor;
    private logger: Logger;
    private serviceManager: IServiceManager;

    /**
     * Creates an instance of LicenseServiceHelper.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {Logger} logger The logger.
     * @param {IServiceManager} serviceManager The service manager.
     * @memberof LicenseServiceHelper
     */
    public constructor(requestExecutor: IRequestExecutor, logger: Logger, serviceManager: IServiceManager) {
        this.requestExecutor = requestExecutor;
        this.logger = logger;
        this.serviceManager = serviceManager;
    }

    /**
     * Get all license details.
     *
     * @returns {Promise<LicenseDetails>}
     * @memberof LicenseServiceHelper
     */
    public async getLicenseDetails(): Promise<LicenseDetails> {
        return new Promise<LicenseDetails>((resolve, reject) => {
            Promise.all([this.getWindreamLicense(), this.getThirdPartyLicenses(), this.getWebserviceDetails()])
                .then((values) => {
                    const windreamLicense = values[0] ? values[0] as WindreamLicense : new WindreamLicense('N/A', 'N/A', 'N/A');
                    const thirdPartyLicenses = values[1] ? values[1] as ThirdPartyLicense[] : new Array<ThirdPartyLicense>();
                    const licenseDetails = new LicenseDetails(thirdPartyLicenses, windreamLicense, Version.productVersion || '', Version.buildIdentifier);

                    if (!(DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core)) {
                        const webserviceVersion = values[2] ? values[2] as string : 'N/A';
                        licenseDetails.windreamWebServiceVersion = webserviceVersion;
                    }
                    resolve(licenseDetails);
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Gets all third party licenses.
     *
     * @returns {(Promise<ThirdPartyLicense[] | null>)}
     * @memberof LicenseServiceHelper
     */
    public async getThirdPartyLicenses(): Promise<ThirdPartyLicense[] | null> {
        const methodName = 'getThirdPartyLicenses';
        return new Promise<ThirdPartyLicense[] | null>((resolve) => {
            const ressourcePtr: HttpResourcePointer = new HttpResourcePointer('get', this.thirdPartyLicensesResourceUri);
            this.requestExecutor.executeRequest(ressourcePtr).then((response: IServiceResponse<ThirdPartyLicense[]>) => {
                if (response && response.data) {
                    try {
                        resolve(response.data);
                    } catch {
                        this.logger.error(this.className, methodName, 'Can not parse data to js object');
                        resolve(null);
                    }
                }
            }).catch(() => {
                this.logger.error(this.className, methodName, 'Could not get third party licenses');
                resolve(null);
            });
        });
    }

    /**
     * Gets the windream license.
     *
     * @returns {(Promise<WindreamLicense | null>)}
     * @memberof LicenseServiceHelper
     */
    public async getWindreamLicense(): Promise<WindreamLicense | null> {
        const methodName = 'getWindreamLicense';
        return new Promise<WindreamLicense | null>((resolve) => {
            const ressourcePtr: HttpResourcePointer = new HttpResourcePointer('get', this.licenseResourceUri);
            this.requestExecutor.executeRequest(ressourcePtr).then((response: IServiceResponse<WindreamLicense>) => {
                if (response && response.data) {
                    try {
                        resolve(response.data);
                    } catch {
                        this.logger.error(this.className, methodName, 'Can not parse data to js object');
                        resolve(null);
                    }
                }
            }).catch(() => {
                this.logger.error(this.className, methodName, 'Could not get windream license');
                resolve(null);
            });
        });
    }

    /**
     * Gets the webservice details.
     *
     * @returns {(Promise<string | null>)}
     * @memberof LicenseServiceHelper
     */
    public async getWebserviceDetails(): Promise<string | null> {
        const methodName = 'getWebserviceDetails';
        if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core) {
            return Promise.resolve(null);
        }

        return new Promise<string | null>((resolve) => {
            this.serviceManager.getServices().SystemDetails.getSystemDetails()
                .then((response) => {
                    resolve(response.response.data.SystemDetails.WebserviceVersion);
                }).catch((error) => {
                    this.logger.error(this.className, methodName, 'Could not get webservice details', error);
                    resolve(null);
                });
        });
    }
}