import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { IMetadataStore } from '../caching';
import { DeviceDetection } from '../common';
import { ConfigDataProvider, HttpResourcePointer } from '../dataProviders';
import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';
import { ILanguageManager, ILanguageProvider } from '../language';
import { IViewManager } from '../loader';
import { Logger } from '../logging';
import { IPubSubHandler, PubSubConfig } from '../pubSub';
import { ButtonTypes, IPopupHelper } from '../ui';
import { IConfigLoader, IUserConfigManager } from './interfaces';
import { ComponentConfig, ComponentInstanceConfig, Configuration, GlobalConfig } from './models';

/**
 * The UserConfigManager class provides the functionality to write user config files to a remote source.
 * 
 * @export
 * @class UserConfigManager
 * @implements {IUserConfigManager}
 */
export class UserConfigManager implements IUserConfigManager {

  /**
   * The debounce delay.
   * 
   * @static
   * @type {number}
   * @memberof UserConfigManager
   */
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  public static DEBOUNCE_DELAY: number = 2000;
  private globalConfig: GlobalConfig;
  private viewId?: string;
  private saveComponentConfigDebounce: any;
  private viewManager: IViewManager;
  private requestExecutor: IRequestExecutor;
  private configLoader: IConfigLoader;
  private metadataStore: IMetadataStore;
  private pubSubHandler: IPubSubHandler;
  private logger: Logger;
  private popupHelper: IPopupHelper;
  private className: string = 'UserConfigManager';
  private languageProvider: ILanguageProvider;
  private languageManager: ILanguageManager;
  private currentConfig: any;
  private userEditableConfigurations: string[];
  private currentComponentGuid?: string;
  private onUserConfigChangedFns: ((viewId: string, componentGuid: string, componentConfig: any) => void)[] = new Array<(viewId: string, componentGuid: string, componentConfig: any) => void>();

  /**
   * Creates an instance of UserConfigManager.
   * @param {IViewManager} viewManager
   * @param {IRequestExecutor} requestExecutor
   * @param {IConfigLoader} configLoader
   * @param {GlobalConfig} globalConfig
   * @param {IMetadataStore} metadataStore
   * @param {IPubSubHandler} pubSubHandler
   * @param {IPopupHelper} popupHelper
   * @param {Logger} logger
   * @param {ILanguageManager} languageManager
   * @memberof UserConfigManager
   */
  public constructor(viewManager: IViewManager, requestExecutor: IRequestExecutor, configLoader: IConfigLoader, globalConfig: GlobalConfig,
    metadataStore: IMetadataStore, pubSubHandler: IPubSubHandler, popupHelper: IPopupHelper, logger: Logger, languageManager: ILanguageManager) {
    this.viewManager = viewManager;
    this.globalConfig = globalConfig;
    this.requestExecutor = requestExecutor;
    this.configLoader = configLoader;
    this.metadataStore = metadataStore;
    this.pubSubHandler = pubSubHandler;
    this.popupHelper = popupHelper;
    this.languageProvider = languageManager.getLanguageProvider('framework');
    this.languageManager = languageManager;
    this.logger = logger;

    this.userEditableConfigurations = new Array<string>();
    this.currentConfig = new Object();
  }

  /**
   * Sets current view ID.
   *
   * @param {string} viewId
   *
   * @memberof UserConfigManager
   */
  public setViewId(viewId: string): void {
    this.viewId = viewId;
  }

  /**
   * Register a callback which will be called whenever a user config was changed.
   *
   * @param {(viewId: string, componentGuid: string, componentConfig: any) => void} callback The callback, which will be called.
   * @memberof UserConfigManager
   */
  public onUserConfigChanged(callback: (viewId: string, componentGuid: string, componentConfig: any) => void): void {
    this.onUserConfigChangedFns.push(callback);
  }

  /**
   * Saves the user specific configuration for a given component on a given view.
   * Will only perform the save if the function has not been called within the next `DEBOUNCE_DELAY` milliseconds.
   *
   * @param {string} componentGuid  GUID of the component.
   * @param {*} componentConfig     Configuration to save.
   * @param {(success: boolean) => void} callback
   *
   * @memberof UserConfigManager
   */
  public saveComponentConfig(componentGuid: string, componentConfig: any, callback?: (success: boolean, newConfig?: any) => void): void {
    if (this.saveComponentConfigDebounce) {
      clearTimeout(this.saveComponentConfigDebounce);
    }
    const currentViewId = this.viewId;
    // Only perform save if it will not be called within the next `DEBOUNCE_DELAY` ms
    this.saveComponentConfigDebounce = setTimeout(() => {
      if (currentViewId) {
        this.saveComponentConfigInstantly(componentGuid, componentConfig, currentViewId).then((newComponentConfig) => {
          this.onUserConfigChangedFns.forEach((callback) => {
            // eslint-disable-next-line promise/no-callback-in-promise
            callback(currentViewId, componentGuid, componentConfig);
          });
          if (callback) {
            // eslint-disable-next-line promise/no-callback-in-promise
            callback(true, newComponentConfig);
          }
        }).catch(() => {
          if (callback) {
            // eslint-disable-next-line promise/no-callback-in-promise
            callback(false, componentConfig);
          }
        });
      }
    }, UserConfigManager.DEBOUNCE_DELAY);
  }
  /**
   * Opens a popup using the PopupHelper to edit a single configuration.
   * Will load the com.windream.configuration component with the `configurationName` parameter, causing it to render only a single configuration.
   *
   * @param {string} componentGuid      GUID of the component to edit.
   * @param {string} configurationName  Name of the configuration to change.
   * @param {(success: boolean, newConfig?: any) => void} [callback]
   *
   * @memberof UserConfigManager
   */
  public openConfigPopup(componentGuid: string, configurationName: string, callback?: (success: boolean, newConfig?: any) => void): void {
    const methodName: string = 'openConfigPopup';
    const containerId = `wd-configuration-${componentGuid}-${configurationName}`;
    const viewConfig = this.viewManager.getCurrentViewConfig();

    if (!viewConfig) {
      this.logger.error(this.className, methodName, 'No view is currently active');
      return;
    }

    this.userEditableConfigurations = new Array<string>();
    let componentInstanceConfig: ComponentInstanceConfig;
    this.currentComponentGuid = componentGuid;

    // Get current settings of given component instance
    try {
      componentInstanceConfig = viewConfig.components.filter((componentInstanceConfig: ComponentInstanceConfig) => {
        return componentInstanceConfig.guid === componentGuid;
      })[0];
    } catch (err) {
      this.logger.error(this.className, methodName, `Component with GUID '${componentGuid}' does not exist on current view.`);
      return;
    }

    const popupTitle = componentInstanceConfig.component ? this.getTranslatedConfigurationName(componentInstanceConfig.component, configurationName) : configurationName;

    // Get possible settings of given component type
    this.configLoader.getAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
      const componentConfig: ComponentConfig | undefined = componentInstanceConfig.component ? componentConfigs.get(componentInstanceConfig.component) : undefined;
      if (componentConfig) {
        componentConfig.configuration.forEach((configuration: Configuration) => {
          if (configuration.userEditable && configuration.name) {
            this.userEditableConfigurations.push(configuration.name);
          }
        });
      } else {
        this.logger.error(this.className, methodName, `Unable to find information for component '${componentGuid}'.`);
        return;
      }

      // Show popup and load configuration component
      const popup = this.popupHelper.openPopup({
        body: `<div id="${containerId}"></div>`,
        buttons: [
          {
            buttonType: ButtonTypes.ok,
            callback: () => {
              this.handleSaveButtonClick().then((newConfig) => {
                if (callback) {
                  // eslint-disable-next-line promise/no-callback-in-promise
                  callback(true, newConfig);
                }
                popup.close();
              }).catch((err: Error) => {
                this.logger.error(this.className, 'openConfigPopup', 'Failed to save user config', err);
                if (callback) {
                  // eslint-disable-next-line promise/no-callback-in-promise
                  callback(false);
                }
              });
            },
            label: this.languageProvider.get('framework.generic.save')
          },
          {
            buttonType: ButtonTypes.cancel,
            callback: () => {
              this.discard();
              popup.close();
            },
            label: this.languageProvider.get('framework.generic.discard')
          }
        ],
        destroyOnClose: true,
        onClose: () => {
          this.pubSubHandler.publish('WINDREAM_USER_COMPONENT_CONFIGURATION', 'HasChanges_DiscardConfig', 'DiscardConfig');
        },
        title: popupTitle
      });

      this.viewManager.addComponent({
        component: 'com.windream.configuration',
        configuration: {
          availableAttributes: this.metadataStore.availableAttributes,
          availableComponents: componentConfigs,
          componentConfig: componentConfig || {},
          configurationName: configurationName,
          currentSettings: componentInstanceConfig,
          possibleSettings: componentConfig.configuration || [],
          type: 'component',
          viewConfig: viewConfig || {}
        },
        guid: 'WINDREAM_USER_COMPONENT_CONFIGURATION',
        isTitleVisible: false,
        name: { en: 'com.windream.componentPanel' },
        position: `#${containerId}`,
        style: componentConfig.style
      }, this.pubSubHandler).catch((err: Error) => {
        if (callback) {
          // eslint-disable-next-line promise/no-callback-in-promise
          callback(false);
        }
        this.logger.error('UserConfigManger', 'openConfigPopup', 'Failed to load configuration component', err);
      });

      const pubSubConfigData: PubSubConfig[] = [{
        in: [{
          componentGuid: 'WINDREAM_USER_COMPONENT_CONFIGURATION',
          parameter: 'ComponentHasChanged'
        }],
        out: [{
          componentGuid: 'WINDREAM_USER_COMPONENT_CONFIGURATION',
          parameter: 'ComponentHasChanged'
        }]
      }];

      this.pubSubHandler.loadPubSub(pubSubConfigData);
      this.pubSubHandler.subscribe('WINDREAM_USER_COMPONENT_CONFIGURATION', 'ComponentHasChanged', (promiseData: Promise<ComponentInstanceConfig>) => {
        promiseData.then((data) => {
          this.handleConfigUpdate(data);
        }).catch((error) => {
          this.logger.error('UserConfigManger', 'openConfigPopup', 'Error during component has changed', error);
        });
      });
    }).catch((err) => {
      this.logger.error('UserConfigManger', 'openConfigPopup', 'Error: ', err);
    });
  }


  /**
   * Saves the user specific configuration for a given component on a given view.
   *
   * @param {string} componentGuid  GUID of the component.
   * @param {*} componentConfig     Configuration to save.
   * @param {(success: boolean) => void} callback
   *
   * @memberof UserConfigManager
   */

  /**
   * Saves the user specific configuration for a given component on a given view.
   *
   * @private
   * @param {string} componentGuid GUID of the component.
   * @param {*} componentConfig Configuration to save.
   * @param {string} viewId  The view id.
   * @returns {Promise<any>} A promise, which will resolve with the updated component config.
   * @memberof UserConfigManager
   */
  private saveComponentConfigInstantly(componentGuid: string, componentConfig: any, viewId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (viewId && componentGuid && componentConfig) {
        const remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_SAVE_USER_COMPONENT_CONFIG_PATH;
        const resourcePointer = new HttpResourcePointer(
          'POST',
          remoteLocation,
          {
            ComponentID: componentGuid,
            ConfigData: {
              configuration: componentConfig
            },
            ViewID: viewId
          }
        );
        this.requestExecutor.executeRequest(resourcePointer)
          .then((response: IServiceResponse<any>) => {
            if (!!response.data) {
              // eslint-disable-next-line promise/no-callback-in-promise
              resolve(componentConfig);
            } else {
              reject(new Error('Failed to update the user data'));
            }
          }).catch((err: Error) => {
            this.logger.error('UserConfigManger', 'saveComponentConfigInstantly', 'Failed to save config', err);
            reject(err);
          });
      } else {
        reject(new Error('Argument `viewId` or `componentGuid` or `componentConfig` is undefined.'));
      }
    });
  }


  /**
   * Handles changing of configuration coming from the configuration component.
   *
   * @private
   * @param {ComponentInstanceConfig} newConfig New config coming from the configuration component.
   * @memberof UserConfigManager
   */
  private handleConfigUpdate(newConfig: ComponentInstanceConfig): void {
    this.currentConfig = new Object();

    for (const name in newConfig.configuration) {
      if (this.userEditableConfigurations.indexOf(name) !== -1) {
        this.currentConfig[name] = newConfig.configuration[name];
      }
    }
  }


  /**
   * Handles clicking of the save button.
   * Will take any changes received previously via PubSub and then save it to the web service.
   *
   * @private
   * @returns {Promise<any>} Promise to resolve with the new config once saving is complete.
   * @memberof UserConfigManager
   */
  private async handleSaveButtonClick(): Promise<any> {
    if (!this.currentComponentGuid) {
      this.logger.error('UserConfigManger', 'handleSaveButtonClick', 'No component GUID set');
      return Promise.reject(new Error('No current component GUID set'));
    }

    // Update cached config with new values
    const viewConfig = this.viewManager.getCurrentViewConfig();


    if (!viewConfig) {
      this.logger.error(this.className, 'handleSaveButtonClick', 'No view is currently active');
      return Promise.reject(new Error('No view is currently active'));
    }


    const componentConfigToUpdate = viewConfig.components.find((component) => component.guid === this.currentComponentGuid);
    if (componentConfigToUpdate) {
      componentConfigToUpdate.configuration = { ...componentConfigToUpdate.configuration, ...this.currentConfig };
      this.configLoader.updateCachedViewConfig(viewConfig, DeviceDetection.getCurrentDevice());
    }


    return new Promise<any>((resolve, reject) => {
      if (this.currentComponentGuid && this.viewId) {
        this.saveComponentConfigInstantly(this.currentComponentGuid, this.currentConfig, this.viewId).then((newConfig) => {
          this.onUserConfigChangedFns.forEach((callback) => {
            if (this.viewId && this.currentComponentGuid) {
              // eslint-disable-next-line promise/no-callback-in-promise
              callback(this.viewId, this.currentComponentGuid, this.currentConfig);
            }
          });
          resolve(newConfig);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  }


  /**
   * Discards changes made by the user.
   *
   * @private
   * @memberof UserConfigManager
   */
  private discard(): void {
    this.currentConfig = new Object();
  }


  /**
   * Receives a translation for the given component config name.
   * Will look for the configurations popupName translation property.
   * If no popupName property is found, the regular name is looked up.
   * If no translation is present at all, then the configuration name is returned.
   *
   * @private
   * @param {string} commponentName Name of the component to get configuration name for.
   * @param {string} configurationName Name of the configuration.
   * @returns {string} Translated configuration name.
   * @memberof UserConfigManager
   */
  private getTranslatedConfigurationName(commponentName: string, configurationName: string): string {
    // Get translation for configuration
    const componentLanguagProvider = this.languageManager.getLanguageProvider(commponentName);
    const editKey = `__windream.configuration.${configurationName}.editName`;
    const editTranslation = componentLanguagProvider.get(editKey);
    if (editTranslation.includes(editKey)) {
      // No specific translation for user editable configuration found, try to translate configuration name
      const key = `__windream.configuration.${configurationName}.name`;
      const translation = componentLanguagProvider.get(key);
      if (translation.includes(key)) {
        // No translation found, return configuration name
        return configurationName;
      } else {
        return translation;
      }
    } else {
      return editTranslation;
    }
  }
}