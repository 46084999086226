import { ComponentInstanceConfig, ViewConfigMetaData } from '../config';
import { ILoaderExtension } from '../extensions';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { IPubSubHandler, IPubSubHandlerFactory } from '../pubSub';
import { INotificationHelper, IProfileSettingsHelper, IStyleManager } from './interfaces';


/**
 * Thils helper will manage the profile link pane.
 * 
 * @export
 * @class ProfileSettingsHelper
 * @implements {IProfileSettingsHelper}
 */
export class ProfileSettingsHelper implements IProfileSettingsHelper {
    /**
     * Callback to execute when the settings have been changed.
     * 
     * @memberof ProfileSettingsHelper
     */
    public onSettingsChanged?: (viewConigs: ViewConfigMetaData[]) => Promise<void>;
    public onOpenSettings?: () => void;

    private readonly PROFILE_SETTINGS_COMPONENT_GUID = 'WINDREAM_PROFILE_SETTINGS';

    private languageProvider: ILanguageProvider;
    private loaderExtension: ILoaderExtension;
    private pubSubHandlerFactory: IPubSubHandlerFactory;
    private logger: Logger;
    private notificationHelper: INotificationHelper;
    private internalPubSub: IPubSubHandler;
    private viewConfigs: ViewConfigMetaData[];
    private targetElement: HTMLElement;
    private viewStyleManager: IStyleManager;

    /**
     * Creates an instance of ProfileSettingsHelper.
     * 
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {ILoaderExtension} loaderExtension The loader extension.
     * @param {IPubSubHandlerFactory} pubSubHandlerFactory The pubsub handler factory.
     * @param {Logger} logger The logger.
     * @param {INotificationHelper} notificationHelper The notification helper.
     * @param {HTMLElement} targetElement The target element.
     * @param {IStyleManager} viewStyleManager The view style manager.
     * @memberof ProfileSettingsHelper
     */
    public constructor(languageProvider: ILanguageProvider, loaderExtension: ILoaderExtension,
        pubSubHandlerFactory: IPubSubHandlerFactory, logger: Logger,
        notificationHelper: INotificationHelper, targetElement: HTMLElement, viewStyleManager: IStyleManager) {
        this.languageProvider = languageProvider;
        this.loaderExtension = loaderExtension;
        this.pubSubHandlerFactory = pubSubHandlerFactory;
        this.logger = logger;
        this.notificationHelper = notificationHelper;
        this.internalPubSub = this.pubSubHandlerFactory.create();
        this.viewConfigs = new Array<ViewConfigMetaData>();
        this.targetElement = targetElement;
        this.viewStyleManager = viewStyleManager;
    }
    /**
     * Setup the profile pane.
     *
     * @memberof ProfileSettingsHelper
     */
    public init() {
        this.loadComponent().then(() => {
            if (this.onOpenSettings) {
                this.onOpenSettings();
            }
        }).catch((err) => {
            this.logger.error('ProfileSettingsHelper', 'init', 'Failed to load component', err);
        });
    }

    /**
     * Updates the view configs.
     *
     * @param {ViewConfigMetaData[]} viewConfigs The view configs.
     * @memberof ProfileSettingsHelper
     */
    public updateViewConfigs(viewConfigs: ViewConfigMetaData[]): void {
        this.viewConfigs = viewConfigs;
        this.internalPubSub.publish(this.PROFILE_SETTINGS_COMPONENT_GUID, 'UpdateViewConfigs', this.viewConfigs);
    }

    /**
     * Saves the re-ordered view configurations.
     * 
     * @private
     * @param {ViewConfigMetaData[]} viewConfigs View configurations in the new order.
     * @memberof ProfileSettingsHelper
     */
    private saveViewConfigs(viewConfigs: ViewConfigMetaData[]): void {
        if (this.onSettingsChanged) {
            this.onSettingsChanged(viewConfigs).catch((err: Error) => {
                this.logger.error('ProfileSettingsHelper', 'saveViewConfigs', 'Failed to save configuration', err);
                this.notificationHelper.error({
                    body: this.languageProvider.get('framework.generic.anErrorOccured'),
                    title: this.languageProvider.get('framework.generic.save')
                });
            });
        }
    }

    /**
     * Creates a popup and loads the component.
     * 
     * @private
     * @returns {Promise<void>} 
     * @memberof ProfileSettingsHelper
     */
    private async loadComponent(): Promise<void> {
        this.targetElement.innerHTML = '';
        this.viewStyleManager.updateStyle('wd-style-colors-0');
        const componentConfig: ComponentInstanceConfig = {
            component: 'com.windream.profile.settings',
            configuration: {},
            guid: this.PROFILE_SETTINGS_COMPONENT_GUID,
            isTitleVisible: false,
            name: { en: 'com.windream.profile.settings' },
            position: '#' + this.targetElement.id,
            style: null
        };
        this.loaderExtension.loadComponent(componentConfig, this.internalPubSub).then(() => {
            this.internalPubSub.loadPubSub([
                {
                    in: [{
                        componentGuid: this.PROFILE_SETTINGS_COMPONENT_GUID,
                        parameter: 'UpdateViewConfigs'
                    }],
                    out: [{
                        componentGuid: this.PROFILE_SETTINGS_COMPONENT_GUID,
                        parameter: 'UpdateViewConfigs'
                    }],
                    executeForEmptyData: true
                },
                {
                    in: [{
                        componentGuid: this.PROFILE_SETTINGS_COMPONENT_GUID,
                        parameter: 'SaveViewConfigs'
                    }],
                    out: [{
                        componentGuid: this.PROFILE_SETTINGS_COMPONENT_GUID,
                        parameter: 'SaveViewConfigs'
                    }],
                    executeForEmptyData: true
                }]);
            this.internalPubSub.subscribe(this.PROFILE_SETTINGS_COMPONENT_GUID, 'SaveViewConfigs', (viewConfigsPromise: Promise<ViewConfigMetaData[]>) => {
                viewConfigsPromise.then((viewConfigs) => {
                    this.saveViewConfigs(viewConfigs);
                }).catch((error) => {
                    this.logger.error('ProfileSettingsHelper', 'loadComponent', 'SaveViewConfigs pubsub catched error', error);
                });
            });
            // Initially send current configuraiton
            this.internalPubSub.publish(this.PROFILE_SETTINGS_COMPONENT_GUID, 'UpdateViewConfigs', this.viewConfigs);
        }).catch((error) => {
            this.logger.error('ProfileSettingsHelper', 'loadComponent', 'Can not open profile popup', error);
        });
    }
}