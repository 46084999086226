import { IComponentManager } from '../components';
import { ComponentInstanceConfig, IConfigLoader, ViewConfig } from '../config';
import { Logger } from '../dynamicWorkspace';
import { IExtensionProvider } from '../extensions';
import { IComponentLifecycleManagerFactory } from '../lifecycle';
import { IComponent, IUiManager } from '../loader';
import { IPubSubHandler } from '../pubSub';
import { IServiceManager } from '../services';
import { ComponentToolbarHandler, ToolbarActionLoader } from '../toolbar';
import { IUiComponentFactory } from './components';
import { ISettingsHelper } from './interfaces';
import { ApplicationSettings } from './models';
import { MoreDropdownHandlerFactory } from './moreDropdownHandlerFactory';

/**
 * Helper class to load and handle the SettingsPanel component.
 * 
 * @export
 * @class SettingsHelper
 * @implements {ISettingsHelper}
 */
export class SettingsHelper implements ISettingsHelper {
  private readonly SETTINGS_COMPONENT_NAME = 'com.windream.settingsPanel';
  private readonly SETTINGS_COMPONENT_GUID = 'WINDREAM_SETTINGS_PANEL';
  private readonly SETTINGS_COMPONENT_CONTAINER_ID = 'wd-settings-panel-container';

  private container: HTMLElement;
  private componentManager: IComponentManager;
  private pubSubHandler: IPubSubHandler;
  private componentLifecycleManagerFactory: IComponentLifecycleManagerFactory;
  private uiManager: IUiManager;
  private extensionProvider: IExtensionProvider;
  private configLoader: IConfigLoader;
  private toolbarActionLoader: ToolbarActionLoader;
  private logger: Logger;
  private uiComponentFactory: IUiComponentFactory;
  private serviceManager: IServiceManager;

  /**
   * Creates an instance of SettingsHelper.
   * 
   * @param {HTMLElement} container The container.
   * @param {IComponentManager} componentManager The component manager.
   * @param {IPubSubHandler} pubSubHandler The pubsub handler.
   * @param {IComponentLifecycleManagerFactory} componentLifecycleManagerFactory The componentLifecycleManagerFactory.
   * @param {IUiManager} uiManager The ui manager.
   * @param {IExtensionProvider} extensionProvider The extension provider.
   * @param {IConfigLoader} configLoader The config loader.
   * @param {ToolbarActionLoader} toolbarActionLoader The toolbar action loader.
   * @param {Logger} logger The logger.
   * @param {IUiComponentFactory} uiComponentFactory The UI component factory.
   * @param {IServiceManager} serviceManager The service manager.
   * @memberof SettingsHelper
   */
  public constructor(container: HTMLElement, componentManager: IComponentManager, pubSubHandler: IPubSubHandler, componentLifecycleManagerFactory: IComponentLifecycleManagerFactory,
    uiManager: IUiManager, extensionProvider: IExtensionProvider,
    configLoader: IConfigLoader, toolbarActionLoader: ToolbarActionLoader, logger: Logger, uiComponentFactory: IUiComponentFactory,
    serviceManager: IServiceManager) {

    this.container = container;
    this.componentManager = componentManager;
    this.pubSubHandler = pubSubHandler;
    this.componentLifecycleManagerFactory = componentLifecycleManagerFactory;
    this.uiManager = uiManager;
    this.extensionProvider = extensionProvider;
    this.configLoader = configLoader;
    this.toolbarActionLoader = toolbarActionLoader;
    this.logger = logger;
    this.uiComponentFactory = uiComponentFactory;
    this.serviceManager = serviceManager;
  }

  /**
   * Initializes the SettingsHelper and loads the SettingsPanel component.
   * 
   * @returns {Promise<boolean>}  Promise to resolve after initialization.
   * @async
   * 
   * @memberof SettingsHelper
   */
  public async init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.bootstrapContainer(this.container);

      this.loadSettingsComponent().then(() => {
        this.setupPubSub();
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
  }

  /**
   * Callback function to execute after the settings have been changed.
   * Saves new settings back to the server.
   * 
   * @private
   * @param {ApplicationSettings} _settings  New settings to save.
   * @returns {Promise<void>}  Promise to resolve after saving the settings.
   * @async
   * 
   * @memberof SettingsHelper
   */
  private async savedSettings(_settings: ApplicationSettings): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Prepares the container to render the SettingsPanel component.
   * Will clear current contents of the given container.
   * 
   * @private
   * @param {HTMLElement} container Container to bootstrap.
   * 
   * @memberof SettingsHelper
   */
  private bootstrapContainer(container: HTMLElement): void {
    container.innerHTML = `
      <div id="${this.SETTINGS_COMPONENT_CONTAINER_ID}"></div>
    `;
  }

  /**
   * Loads the SettingsPanel component into the given element.
   * 
   * @private
   * @returns {Promise<IComponent>} Promise to resolve with the loaded instance.
   * @async
   * 
   * @memberof SettingsHelper
   */
  private async loadSettingsComponent(): Promise<IComponent> {
    return this.configLoader.loadAllViewConfigs().then(async (viewConfigs: ViewConfig[]) => {
      const componentConfig = <ComponentInstanceConfig>{
        component: this.SETTINGS_COMPONENT_NAME,
        configuration: <ApplicationSettings>{
          allViews: viewConfigs
        },
        guid: this.SETTINGS_COMPONENT_GUID,
        isTitleVisible: false,
        name: { en: this.SETTINGS_COMPONENT_GUID },
        position: `#${this.SETTINGS_COMPONENT_CONTAINER_ID}`,
        style: null
      };
      const moreDropdownHandlerFactory = new MoreDropdownHandlerFactory(document);
      return this.componentManager.addComponent(componentConfig, null, false).then(async (componentContainer) => {
        const componentLifecycleManager = this.componentLifecycleManagerFactory.create(componentContainer.container.component,
          new ComponentToolbarHandler(componentContainer.container, this.toolbarActionLoader, document, moreDropdownHandlerFactory, this.logger, this.uiComponentFactory, this.serviceManager));
        componentLifecycleManager.init(componentConfig);
        componentLifecycleManager.bind(this.pubSubHandler);
        componentLifecycleManager.extension(this.extensionProvider);
        componentLifecycleManager.render(this.uiManager);

        return Promise.resolve(componentContainer.container.component);
      });
    });
  }

  /**
   * Sets up PubSub configuration for the SettingsPanel component.
   * 
   * @private
   * 
   * @memberof SettingsHelper
   */
  private setupPubSub(): void {
    this.pubSubHandler.loadPubSub([
      {
        in: [{
          componentGuid: this.SETTINGS_COMPONENT_GUID,
          parameter: 'SavedSettings'
        }],
        out: [{
          componentGuid: this.SETTINGS_COMPONENT_GUID,
          parameter: 'SavedSettings'
        }]
      }
    ]);
    this.pubSubHandler.subscribe(this.SETTINGS_COMPONENT_GUID, 'SavedSettings', this.savedSettings.bind(this));
  }
}