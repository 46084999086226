import { AuthenticationProvider } from '../authenticationProvider';
import { ICredentialManager } from '../interfaces';
import { AuthenticationModes } from '../models/authenticationModes';


/**
 * This class adjusts a raw request, so that basic-http authentication is supported.
 * 
 * @export
 * @class BasicHttpAuthenticationProvider
 * @extends {AuthenticationProvider}
 */
export class BasicHttpAuthenticationProvider extends AuthenticationProvider {

    /**
     * The credential manager.
     * 
     * @protected
     * @type {ICredentialManager}
     * @memberof BasicHttpAuthenticationProvider
     */
    private credentialManager: ICredentialManager;


    /**
     * Creates an instance of BasicHttpAuthenticationProvider.
     *
     * @param {ICredentialManager} credentialManager 
     * @memberof BasicHttpAuthenticationProvider
     */
    public constructor(credentialManager: ICredentialManager) {
        super();
        if (!credentialManager) {
            throw new ReferenceError('The argument \"credentialManager\" is null or undefined.');
        }

        this.credentialManager = credentialManager;
    }

    /**
     * Gets the supported authentication mode.
     * 
     * @returns {AuthenticationModes} 
     * @memberof IAuthenticationProvider
     */
    public getSupportedAuthenticationMode(): AuthenticationModes {
        return AuthenticationModes.Basic;
    }

    /**
     * This handler will be called before the execution.
     * 
     * @async
     * @param {XMLHttpRequest} rawRequest 
     * @returns {Promise<XMLHttpRequest>} 
     * @memberof BasicHttpAuthenticationProvider
     */
    public async beforeRequestHandler(rawRequest: XMLHttpRequest): Promise<XMLHttpRequest> {
        return super.beforeRequestHandler(rawRequest).then(async (processedRawRequest) => {
            if (processedRawRequest) {
                // Get the current user details...
                const userCredentials = this.credentialManager.getCredentials();
                if (userCredentials && userCredentials.authenticationToken) {
                    // ... and use its authentication token to set the basic authentication header.
                    processedRawRequest.setRequestHeader('Authorization', 'Basic ' + userCredentials.authenticationToken);
                }
                return Promise.resolve(processedRawRequest);
            }
            return Promise.reject(new Error('Failed to receive request from super class'));
        });
    }

}