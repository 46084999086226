
/**
 * Represents a third party license.
 *
 * @export
 * @class ThirdPartyLicense
 */
export class ThirdPartyLicense {

  /**
   * The name of the product.
   *
   * @type {string}
   * @memberof ThirdPartyLicense
   */
  public productName: string;

  /**
   * The type of the license.
   *
   * @type {string}
   * @memberof ThirdPartyLicense
   */
  public type: string;

  /**
   * The description.
   *
   * @type {string}
   * @memberof ThirdPartyLicense
   */
  public description: string;

  /**
   * Creates an instance of ThirdPartyLicense.
   * @param {string} productName The product name.
   * @param {string} type The license type.
   * @param {string} description The description.
   * @memberof ThirdPartyLicense
   */
  public constructor(productName: string, type: string, description: string) {
    this.productName = productName;
    this.type = type;
    this.description = description;
  }
}