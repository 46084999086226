import * as moment from 'moment';
import { FormatHelper } from './formatHelper';

/**
 * The DateTimeFormatHelper provides date functionality.
 * 
 * @export
 * @class DateTimeFormatHelper
 * @extends {FormatHelper}
 */
export class DateTimeFormatHelper extends FormatHelper {
    /**
     * Creates an instance of DateTimeFormatHelper.
     * 
     * 
     * @memberof DateTimeFormatHelper
     */
    constructor() {
        super();
    }
    /**
     * Set the CurrentCulture and change MomentJS local language.
     * 
     * @param {string} currentCultureTag
     * 
     * @memberof DateTimeFormatHelper
     */
    public setCurrentCulture(currentCultureTag: string) {
        this.initMomentJS(currentCultureTag);
    }
    /**
     * Gets a formatted date string for the current set language.
     * @param {number} timestamp The timestamp.
     * @returns {string} The formatted date string.
     * @access public
     * @memberof DateTimeFormatHelper
     */
    public getFormattedDateFromTimestamp(timestamp: number): string {
        return moment(new Date(timestamp)).format('L');
    }


    /**
     * Gets a formatted date string for the current set language.
     * @param {Date} date The date.
     * @returns {string} The formatted date string.
     * @access public
     * @memberof DateTimeFormatHelper
     */
    public getFormattedDateFromDate(date: Date): string {
        return moment(date).format('L');

    }


    /**
     * Gets a formatted time string for the current set language.
     * 
     * @param {Date} date The date.
     * @returns {string} The formatted date string.
     * 
     * @memberof DateTimeFormatHelper
     */
    public getFormattedTimeFromDate(date: Date): string {
        return moment(date).format('LTS');
    }

    /**
     * Gets the date time in the current local format.
     * 
     * @param {Date} date 
     * @returns {string} 
     * 
     * @memberof DateTimeFormatHelper
     */
    public getFormattedDateTimeFromDate(date: Date): string {
        if (!date || date.toString().toLowerCase().indexOf('invalid') !== -1) {
            return '';
        }
        return this.getFormattedDateFromDate(date) + ' ' + this.getFormattedTimeFromDate(date);
    }

    /**
     * Returns the format for the given culture to use with time fields.
     * E.g. h:mm A
     * 
     * @param {boolean} [includeSeconds] Whether to include the seconds.
     * @returns {string} The time format string.
     * @memberof DateTimeFormatHelper
     */
    public getTimeFormat(includeSeconds?: boolean): string {
        let dateFormatString: moment.LongDateFormatKey = 'LT';
        if (includeSeconds) {
            dateFormatString = 'LTS';
        }
        return moment.localeData().longDateFormat(dateFormatString);
    }

    /**
     * Returns the format for the given culture to use with date fields.
     * E.g. MM/DD/YYYY
     * 
     * @returns {string} The date format string.
     * 
     * @memberof DateTimeFormatHelper
     */
    public getDateFormat(): string {
        return moment.localeData().longDateFormat('L');
    }

    /**
     * Returns the format for the given culture to use with date time fields.
     * E.g. MM/DD/YYYY h:mm A
     * 
     * @param {boolean} [includeSeconds] Whether to include the seconds.
     * @returns {string} The date time format string.
     * 
     * @memberof DateTimeFormatHelper
     */
    public getDateTimeFormat(includeSeconds?: boolean): string {
        let dateFormatString: moment.LongDateFormatKey = 'LT';
        if (includeSeconds) {
            dateFormatString = 'LTS';
        }
        return moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat(dateFormatString);
    }

    /**
     * Returns a date for the given date(time) string.
     *
     * @param {string} value The date(time) string.
     * @returns {Date} The parsed date.
     * @memberof DateTimeFormatHelper
     */
    public getDateFromString(value: string): Date {
        let result = moment(value, this.getDateTimeFormat(true)).toDate();
        if (result instanceof Date && result.toString().toLowerCase().indexOf('invalid') !== -1) {
            result = new Date(value);
        }

        return result;
    }

    /**
     * Init momentjs.
     * 
     * @private
     * @param {string} language
     * 
     * @memberof DateTimeFormatHelper
     */
    private initMomentJS(language: string) {
        moment.locale(language);
    }
}