/**
 * List of skeleton types to use with ISkeletonHelper.
 * 
 * @export
 * @enum {number}
 */
export enum SKELETON_TYPES {
    DEFAULT,
    TABLE,
    LIST,
    FORM,
    CHART
}