import { Utils } from '../common';
import { SortableEvent } from '../dataCollections';

/**
 * Helper class for sorting columns.
 * Binds mouse events that make the columns sortable by dragging the headers of the column.
 * 
 * @export
 * @class ColumnOrderingHelper
 */
export class ColumnOrderingHelper {
  /**
   * Callback to execute when sorting occured.
   * 
   * 
   * @memberof ColumnOrderingHelper
   */
  public onDrop?: (oldIndex: number, newIndex: number) => void;

  private lib: any;
  private tableElement: HTMLTableElement;
  // Options to pass to Sortable.js, see https://github.com/RubaXa/Sortable
  private sortableOptions = {
    animation: 300,
    delay: 0,
    draggable: 'th',
    handle: '.drag',
    onUpdate: (e: SortableEvent) => {
      if (!Utils.Instance.isDefined(e.oldIndex) || !Utils.Instance.isDefined(e.newIndex)) {
        return;
      }

      this.orderTable(this.tableElement, e.oldIndex, e.newIndex);

      if (this.onDrop && typeof this.onDrop === 'function') {
        this.onDrop(e.oldIndex, e.newIndex);
      }
    }
  };

  /**
   * Creates an instance of SortingHelper.
   * 
   * @param {*} lib Sorting lib to use.
   * 
   * @memberof ColumnOrderingHelper
   */
  public constructor(lib: any, tableElement: HTMLTableElement) {
    this.lib = lib;
    this.tableElement = tableElement;
  }

  /**
   * Make a single element sortable.
   *
   * @memberof ColumnOrderingHelper
   */
  public makeSortable(): void {
    const header = this.tableElement.querySelector('thead tr');
    if (header) {
      this.lib.create(header, this.sortableOptions);
    }
    this.tableElement.classList.add('wd-sortable');
  }

  /**
   * Sorts the table by moving the table body's cells into the right order for each row.
   * 
   * @private
   * @param {HTMLElement} table The table to re-order.
   * @param {number} oldIndex Old index of the moved column.
   * @param {number} newIndex New index of the moved column.
   * 
   * @memberof ColumnOrderingHelper
   */
  private orderTable(table: HTMLElement, oldIndex: number, newIndex: number): void {
    const rows = table.querySelectorAll('tbody tr');
    [].forEach.call(rows, (row: HTMLElement) => {
      let columns = row.querySelectorAll('td');

      let place = 'beforebegin';
      if (newIndex > oldIndex) {
        place = 'afterend';
      }
      // @ts-ignore - TODO: Check typing
      columns[newIndex].insertAdjacentElement(place, columns[oldIndex]);
      columns = row.querySelectorAll('td');
      // Move icons to first column
      if (oldIndex === 0 || newIndex === 0) {
        const icon = row.querySelector('.wd-icon');
        if (icon) {
          columns[0].insertAdjacentElement('afterbegin', icon);
        }
      }
    });
    // Set sorting header id inside of th
    const headers = table.querySelectorAll('th');
    let index = 0;
    [].forEach.call(headers, (header: HTMLElement) => {
      if (index === oldIndex || index === newIndex) {
        header.setAttribute('data-wd-header-id', index.toString());
      }
      index++;
    });
  }
}