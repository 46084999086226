import { SortDirection } from './index';
/**
 * Represents the sorting of a search.
 *
 * @export
 * @class SearchSorting
 */
export class SearchSorting {
    /**
     * The attribute to sort by.
     *
     * @type {string}
     * @memberof SearchSorting
     */
    public attributeName: string;

    /**
     * The sorting direction.
     *
     * @type {SortDirection}
     * @memberof SearchSorting
     */
    public direction: SortDirection;

    /**
     * Creates an instance of SearchSorting.
     * @memberof SearchSorting
     */
    public constructor() {
        this.attributeName = 'dwDocID';
        this.direction = SortDirection.Descending;
    }
}