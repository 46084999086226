import { DWCore } from '@windream/dw-core/dwCore';
import { GridStack, GridStackOptions, GridStackWidget } from 'gridstack/dist/es5/gridstack';
import { DeviceDetection, Utils } from '../common';
import { ComponentConfig, ComponentInstanceConfig, Position, Style } from '../config';
import { ILanguageManager, IPubSubHandler, Logger } from '../dynamicWorkspace';
import { GRID_SIZES, GridConfig, LayoutConfiguration, LayoutManager } from '.';
const GridStackImport: any = require('gridstack/dist/es5/gridstack');
/**
 * LayoutManager to handle a grid powered by gridstack.js.
 * 
 * @export
 * @class GridLayoutManager
 * @extends {LayoutManager}
 */
export class GridLayoutManager extends LayoutManager {

    private readonly headerHeight = 56;
    private readonly headerPadding = 10;
    private readonly MIN_CELL_HEIGHT = 20;
    private readonly MIN_CELL_HEIGHT_EDITABLE = 13;
    private className: string = 'GridLayoutManager';
    private currentDevice?: DWCore.Common.Devices;
    private dropAreaWrapper: HTMLElement | null = null;
    private gridAttrPrefix: string = 'gs-';
    private isBatchUpdateActive: boolean = false;
    private isHeaderLess = false;
    private gridConfig: GridConfig | undefined;
    private resizeHandler?: (e: Event) => void;


    public constructor(languageManager: ILanguageManager, root: HTMLElement, logger: Logger,
        pubSubHandler: IPubSubHandler, isHeaderLess: boolean) {
        super(languageManager, root, logger, pubSubHandler);
        this.isHeaderLess = isHeaderLess;
    }
    /**
     * Removes a component from the grid.
     * 
     * @param {string} componentGuid GUID of the component to remove.
     * 
     * @memberof LayoutManager
     */
    public removeComponent(componentGuid: string): void {
        const gridItem = this.layoutNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (gridItem) {
            this.getGridInstance()?.removeWidget(<HTMLElement>gridItem);
        } else {
            throw new Error(`No element for component with GUID ${componentGuid} found.`);
        }
    }

    /**
     * Prepares the layout by added a grid node to the root element.
     *
     * @param {DWCore.Common.Devices} device  Device to render for.
     *
     * @memberof LayoutManager
     */
    public prepareLayout(device: DWCore.Common.Devices): void {

        this.gridConfig = this.GRID_CONFIG.get(device);
        this.currentDevice = device;
        if (!this.gridConfig) {
            this.logger.error(this.className, 'prepareGrid', 'Unable to find config for device:' + device);
            return;
        }

        const deviceClass = `grid-${DeviceDetection.getDeviceName(device)}`;

        this.rootNode.innerHTML = '';

        const gridWrapper = document.createElement('div');
        gridWrapper.classList.add('grid-wrapper', deviceClass);

        const overflowContainer = document.createElement('div');
        overflowContainer.classList.add('grid-overflow-container');
        gridWrapper.appendChild(overflowContainer);

        this.layoutNode = document.createElement('div');
        this.layoutNode.classList.add('grid');

        // Prevent anything from being dropped into the main grid expect Girdstack items
        this.layoutNode.addEventListener('drop', (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        });
        this.layoutNode.setAttribute(this.gridAttrPrefix + 'w', this.gridConfig.width.toString());
        this.layoutNode.setAttribute(this.gridAttrPrefix + 'h', this.gridConfig.height.toString());
        overflowContainer.appendChild(this.layoutNode);

        this.rootNode.appendChild(gridWrapper);

        this.containerNodes = new Array<HTMLElement>();
        this.isInitialized = false;

        window.addEventListener('resize', this.resizeHandler = () => {
            this.setEditable(this.isEditable);
        });
    }

    /**
     * Renders a container for the given component.
     * Adds relevant attributes to the element so that the grid can be used.
     * Wraps the given element in a separate container.
     *
     * @param {ComponentInstanceConfig} componentInstanceConfig Configuration for the component instance.
     * @param {ComponentConfig} componentConfig The component config.
     * @param {HTMLElement} element Container to render the component in.
     * @param {HTMLDivElement} toolbarcontainer The toolbar container.
     * @param {(newComponentInstanceConfig: ComponentInstanceConfig) => void} [callback]  Callback to be called after loading with updated configuration (i.e. new position).
     * @memberof GridLayoutManager
     */
    // eslint-disable-next-line complexity
    public renderContainer(componentInstanceConfig: ComponentInstanceConfig,
        componentConfig: ComponentConfig, element: HTMLElement, toolbarcontainer: HTMLDivElement,
        callback?: (newComponentInstanceConfig: ComponentInstanceConfig) => void): void {
        const gridItem = document.createElement('div');
        if (componentInstanceConfig.guid) {
            gridItem.setAttribute('data-guid', componentInstanceConfig.guid);
        } else {
            this.logger.error(this.className, 'renderContainer', 'ComponentInstanceCOnfig has no GUID set', componentInstanceConfig);
        }
        if (componentInstanceConfig.component) {
            gridItem.setAttribute('data-component', componentInstanceConfig.component);
        } else {
            this.logger.error(this.className, 'renderContainer', 'ComponentInstanceCOnfig has no component set', componentInstanceConfig);
        }

        gridItem.classList.add('grid-stack-item', 'wd-ui-component');
        const backgroundElement = document.createElement('div');
        backgroundElement.classList.add('grid-stack-item-content');
        element.classList.add('component-content');

        // Try to set the component's colors
        if (componentInstanceConfig && componentInstanceConfig.style) {
            if (!componentInstanceConfig.style) {
                componentInstanceConfig.style = Style.default();
            }
            backgroundElement.classList.add(componentInstanceConfig.style.colors ? componentInstanceConfig.style.colors : '');
        }

        this.containerNodes.push(gridItem);
        if (componentConfig && componentConfig.size) {
            if (componentConfig.size.maxHeight) {
                gridItem.setAttribute(this.gridAttrPrefix + 'maxH', componentConfig.size.maxHeight.toString());
            }
            if (componentConfig.size.maxWidth) {
                gridItem.setAttribute(this.gridAttrPrefix + 'maxW', componentConfig.size.maxWidth.toString());
            }
            if (componentConfig.size.minHeight) {
                gridItem.setAttribute(this.gridAttrPrefix + 'minH', componentConfig.size.minHeight.toString());
            }
            if (componentConfig.size.minWidth) {
                gridItem.setAttribute(this.gridAttrPrefix + 'minW', componentConfig.size.minWidth.toString());
            }
        }
        if (componentInstanceConfig.position) { // If position is set, use it to render the component there
            if (!this.isInitialized) { // If grid is not intialized, render containers into DOM
                if (typeof componentInstanceConfig.position !== 'string') {
                    if (Utils.Instance.isDefined(componentInstanceConfig.position.x)) {
                        gridItem.setAttribute(this.gridAttrPrefix + 'x', componentInstanceConfig.position.x.toString());
                    }
                    if (Utils.Instance.isDefined(componentInstanceConfig.position.y)) {
                        gridItem.setAttribute(this.gridAttrPrefix + 'y', componentInstanceConfig.position.y.toString());
                    }
                    if (Utils.Instance.isDefined(componentInstanceConfig.position.width)) {
                        gridItem.setAttribute(this.gridAttrPrefix + 'w', componentInstanceConfig.position.width.toString());
                    }
                    if (Utils.Instance.isDefined(componentInstanceConfig.position.height)) {
                        gridItem.setAttribute(this.gridAttrPrefix + 'h', componentInstanceConfig.position.height.toString());
                    }
                }
                this.layoutNode.appendChild(gridItem);
            } else { // If grid is initialized, use Gridstack API
                const position = <Position>componentInstanceConfig.position;

                const gridStackWidget: GridStackWidget = {
                    autoPosition: false,
                    x: position.x,
                    y: position.y,
                    w: position.width,
                    h: position.height
                };
                this.getGridInstance()?.off('added');
                this.getGridInstance()?.addWidget(<any>gridItem, gridStackWidget);
                this.updateLayout();
                this.getGridInstance()?.on('added', (event: any, items: any) => this.addedItemsToGrid(event, items));
            }
        } else { // If position is not set (i.e. null), the component was added after page load (i.e. new component) and should be placed using Gridstack API
            const defaultSize = 2; // Size to use if default width or height are not defined
            const defaultWidth = componentConfig.size?.defaultWidth || defaultSize;
            const defaultHeight = componentConfig.size?.defaultHeight || defaultSize;

            const gridStackWidget: GridStackWidget = {
                autoPosition: true,
                x: undefined,
                y: undefined,
                w: defaultWidth,
                h: defaultHeight
            };
            const newComponentElement = this.getGridInstance()?.addWidget(<any>gridItem, gridStackWidget);
            if (!newComponentElement) {
                return;
            }
            // After placing it, read the new position and give it back using the callback
            const newPosition = new Position();
            const height = newComponentElement.getAttribute(this.gridAttrPrefix + 'h');
            if (!height) {
                throw new Error('Failed to read height property for component.');
            }
            newPosition.height = parseInt(height, 10);
            const width = newComponentElement.getAttribute(this.gridAttrPrefix + 'w');
            if (!width) {
                throw new Error('Failed to read width property for component.');
            }
            newPosition.width = parseInt(width, 10);
            const x = newComponentElement.getAttribute(this.gridAttrPrefix + 'x');
            if (!x) {
                throw new Error('Failed to read x property for component.');
            }
            newPosition.x = parseInt(x, 10);
            const y = newComponentElement.getAttribute(this.gridAttrPrefix + 'y');
            if (!y) {
                throw new Error('Failed to read y property for component.');
            }
            newPosition.y = parseInt(y, 10);

            const newComponentInstanceConfig = componentInstanceConfig;
            newComponentInstanceConfig.position = newPosition;

            if (callback && typeof callback === 'function') {
                callback(newComponentInstanceConfig);
            }
        }

        const hasErrors = componentConfig.id === 'com.windream.error';
        // Add editable header to component
        this.addComponentHeader(backgroundElement, true, hasErrors);

        this.addComponentHeader(backgroundElement, false, hasErrors);
        toolbarcontainer.hidden = true;
        backgroundElement.appendChild(toolbarcontainer);
        backgroundElement.appendChild(element);
        gridItem.appendChild(backgroundElement);
        if (componentInstanceConfig.guid) {
            const fallbackTitle = '<Title>';
            const fallbackName = '<Name>';
            const title = hasErrors ?
                (Utils.Instance.isDefined(componentInstanceConfig.component) ? this.getTranslatedOrEmpty(componentInstanceConfig.title) : fallbackTitle)
                : this.getTranslatedOrEmpty(componentInstanceConfig.title);
            const name = hasErrors ?
                (Utils.Instance.isDefined(componentInstanceConfig.component) ? this.getTranslatedOrEmpty(componentInstanceConfig.name) : fallbackName) :
                this.getTranslatedOrEmpty(componentInstanceConfig.name);
            const isTitleVisible = Utils.isDefined(componentInstanceConfig.isTitleVisible) ? componentInstanceConfig.isTitleVisible : false;
            this.updateComponentTitle(componentInstanceConfig.guid, name,
                title, isTitleVisible);
        } else {
            this.logger.error(this.className, 'renderContainer', 'ComponentInstanceCOnfig has no GUID set', componentInstanceConfig);
        }
    }

    /**
     * Initialize the layout by invoking Gridstack if Edit-Mode is active.
     * Will also disable the grid if invoked and Edit-Mode is not acive.
     */
    public initializeLayout(): void {
        if (!this.isInitialized) { // If editable and grid was not already initialized, initialize it
            const options: GridStackOptions = {
                acceptWidgets: '.wd-ui-component',
                cellHeight: this.calculateCellHeight(),
                draggable: {
                    appendTo: 'body',
                    handle: '.header-editable',
                    scroll: true
                },
                column: this.getDeviceColumns(this.currentDevice),
                float: true,
                resizable: {
                    handles: 'e, se, s, sw, w'
                },
                margin: '0px 3px 6px 3px',
                sizeToContent: false,
                minRow: this.getDeviceMinRows(this.currentDevice)
            };
            const grid = GridStackImport.GridStack.init(options, this.layoutNode);
            grid.on('dragstop resizestop', (_e: any, _ui: any) => {
                // Note: When the events are fired, the gs-* attributes are not yet updated!
                setTimeout(() => this.executeOnLayoutChange(this.getComponentPositions()));
            });
            grid.on('dropped', () => {
                // Fix drop issue for GridStack@1.2.1: If a component was dragged and dropped, two instead of one element were added.

                // Search for invalid items.
                const invalidItems = this.layoutNode.querySelectorAll('.grid-stack-item:not([data-guid])');

                // Try to remove all found invalid items.
                if (invalidItems) {
                    for (let i = 0; i < invalidItems.length; i++) {
                        invalidItems.item(i).remove();
                    }
                }
            });

            grid.on('added', (event: any, items: any) => this.addedItemsToGrid(event, items));

            this.isInitialized = true;
        }
        this.setEditable(this.isEditable);
    }

    /**
     * Sets editable property.
     * 
     * @param {boolean} editable Whether the layout is editable.
     */
    public setEditable(editable: boolean) {
        this.isEditable = editable;

        const editModeHeader = 100; // The edit mode header
        const logicComponentArea = 100; // The logic component grid area
        const gridDropArea = 155; // The additional drop area
        const fixedPhoneGridHeight = 490; // The fixed grid height for phone
        const fixedTabletGridHeight = 560; // The fixed grid height for tablet
        const fixedMobileGridPadding = 90; // The fixed grid padding for mobile container.
        const fixedCellPaddingBetweenComponents = 4; // The fixed horizontal padding between two components

        const languageProvider = this.languageManager.getLanguageProvider('framework');


        // Clear page limit indicators
        if (this.layoutNode.parentElement) {
            const limiter = [].slice.call(this.layoutNode.parentElement.querySelectorAll('.wd-page-limit-indicator')) as HTMLElement[];
            limiter.forEach((element) => {
                element.remove();
            });
        }

        if (this.isEditable && this.gridConfig) { // If editable, enable it
            let scaledgridHeight = window.document.body.offsetHeight; // The scaled grid height
            let restArea = 0; // The additional remaining space until fullscreen
            const betweenComponentPadding = this.gridConfig.height * fixedCellPaddingBetweenComponents;

            // Setup current grid height
            if (this.currentDevice === DWCore.Common.Devices.DESKTOP) {
                scaledgridHeight = window.document.body.offsetHeight - editModeHeader - logicComponentArea - gridDropArea;

                // Setup page limit element
                const limiterElement = document.createElement('div');
                limiterElement.classList.add('wd-page-limit-indicator');
                limiterElement.innerHTML = languageProvider.get('framework.pageLimiter');
                if (this.layoutNode.parentElement) {
                    this.layoutNode.parentElement.appendChild(limiterElement);
                }

                // Limiter has absolute postition.
                limiterElement.style.top = (scaledgridHeight + betweenComponentPadding).toString() + 'px';
            } else if (this.currentDevice === DWCore.Common.Devices.PHONE) {
                scaledgridHeight = fixedPhoneGridHeight;
                this.layoutNode.style.height = scaledgridHeight.toString() + 'px'; // TODO: Check if '' is ok or if we need null
                this.layoutNode.style.maxHeight = scaledgridHeight.toString() + 'px';

            } else if (this.currentDevice === DWCore.Common.Devices.TABLET) {
                scaledgridHeight = fixedTabletGridHeight;

                // Workaround. Otherwise grid height will be modified by gridstack.
                this.layoutNode.style.height = ''; // TODO: Check if '' is ok or if we need null
                this.layoutNode.style.maxHeight = scaledgridHeight.toString() + 'px';
                this.layoutNode.style.minHeight = scaledgridHeight.toString() + 'px';
            }


            // Calculate rest of grid space and add to min cell height
            if (this.currentDevice === DWCore.Common.Devices.TABLET) {
                restArea = scaledgridHeight - (this.gridConfig.height * this.MIN_CELL_HEIGHT_EDITABLE) - fixedMobileGridPadding;
            } else {
                restArea = scaledgridHeight - (this.gridConfig.height * this.MIN_CELL_HEIGHT_EDITABLE);
            }
            const gridStackInset = 4; // Gridstack adds inset, reclaim the space missed by the inset
            const scaledCellHeight = (restArea / this.gridConfig.height) + this.MIN_CELL_HEIGHT_EDITABLE + gridStackInset; // The scaled component cell height

            if (scaledCellHeight) {
                if (this.currentDevice === DWCore.Common.Devices.TABLET) {
                    this.getGridInstance()?.cellHeight(scaledCellHeight);
                } else if (this.currentDevice === DWCore.Common.Devices.PHONE) {
                    this.getGridInstance()?.cellHeight(Math.max(scaledCellHeight - gridStackInset, this.MIN_CELL_HEIGHT_EDITABLE));
                } else {
                    this.layoutNode.style.minHeight = scaledCellHeight * 13 + 'px';
                    this.getGridInstance()?.cellHeight(Math.max(scaledCellHeight, this.MIN_CELL_HEIGHT_EDITABLE));
                }
            } else {

                this.layoutNode.style.minHeight = this.MIN_CELL_HEIGHT_EDITABLE * 13 + 'px';
                this.getGridInstance()?.cellHeight(this.MIN_CELL_HEIGHT_EDITABLE);
            }
            this.getGridInstance()?.enable();
            this.layoutNode.setAttribute('data-editable', 'true');

        } else { // If not editable, disable it
            this.getGridInstance()?.cellHeight(Math.max(this.calculateCellHeight(), this.MIN_CELL_HEIGHT));
            this.getGridInstance()?.disable();
            this.layoutNode.setAttribute('data-editable', 'false');
        }
    }

    /**
     * Destroy the Layout Manager and deletes everything in the root node.
     */
    public destroy(): void {
        try {
            if (this.resizeHandler) {
                window.removeEventListener('resize', this.resizeHandler);
            }
            this.getGridInstance()?.destroy();
        } catch (err) { // If no instance is found, nothing is there to destroy
            this.logger.debug(this.className, 'destroy', 'Grid instance already destroyed', err);
        }
        this.clear();
    }


    /**
     * Returns the current positions of each component inside the layout.
     * 
     * @returns {LayoutConfiguration} The current layout configuration.
     * @memberof GridLayoutManager
     */
    public getComponentPositions(): LayoutConfiguration {
        const configuration = {} as LayoutConfiguration;

        this.containerNodes.forEach((gridItem: HTMLElement) => {
            const guid = gridItem.getAttribute('data-guid');

            const attrHeight = gridItem.getAttribute(this.gridAttrPrefix + 'h') || '0';
            const attrWidth = gridItem.getAttribute(this.gridAttrPrefix + 'w') || '0';
            const attrX = gridItem.getAttribute(this.gridAttrPrefix + 'x') || '0';
            const attrY = gridItem.getAttribute(this.gridAttrPrefix + 'y') || '0';

            if (guid) {
                configuration[guid] = {
                    height: parseInt(attrHeight, 10),
                    width: parseInt(attrWidth, 10),
                    x: parseInt(attrX, 10),
                    y: parseInt(attrY, 10)
                };
            } else {
                this.logger.warn(this.className, 'getGridConfiguration', 'Grid Item has no "data-guid" attribute!', gridItem);
            }
        });

        return configuration;
    }

    /**
     * Initializes the grid.
     * 
     * @protected
     * @memberof GridLayoutManager
     */
    protected init() {
        this.GRID_CONFIG.set(DWCore.Common.Devices.DESKTOP, new GridConfig(GRID_SIZES.DESKTOP_WIDTH, GRID_SIZES.DESKTOP_HEIGHT));
        this.GRID_CONFIG.set(DWCore.Common.Devices.TABLET, new GridConfig(GRID_SIZES.TABLET_WIDTH, GRID_SIZES.TABLET_HEIGHT));
        this.GRID_CONFIG.set(DWCore.Common.Devices.PHONE, new GridConfig(GRID_SIZES.PHONE_WIDTH, GRID_SIZES.PHONE_HEIGHT));

        this.isBatchUpdateActive = false;
    }

    /**
     * Updates the layout after changes were made.
     * 
     * @protected
     * @memberof GridLayoutManager
     */
    protected updateLayout(): void {
        if (this.isBatchUpdateActive === true) {
            this.getGridInstance()?.commit();
            this.isBatchUpdateActive = false;
            this.setEditable(true);
        }
    }

    /**
     * Gets the device columns (width).
     *
     * @private
     * @param {(DWCore.Common.Devices | undefined)} device The device.
     * @returns {number} The device columns (width).
     * @memberof GridLayoutManager
     */
    private getDeviceColumns(device: DWCore.Common.Devices | undefined): number {
        if (device === undefined) {
            return GRID_SIZES.DESKTOP_WIDTH;
        } else {
            switch (device) {
                case DWCore.Common.Devices.PHONE: return GRID_SIZES.PHONE_WIDTH;
                case DWCore.Common.Devices.TABLET: return GRID_SIZES.TABLET_WIDTH;
                default: return GRID_SIZES.DESKTOP_WIDTH;
            }
        }
    }

    /**
     * Gets the device minimum rows (height).
     *
     * @private
     * @param {(DWCore.Common.Devices | undefined)} device The device.
     * @returns {number} The device minimum rows (height).
     * @memberof GridLayoutManager
     */
    private getDeviceMinRows(device: DWCore.Common.Devices | undefined): number {
        if (device === undefined) {
            return GRID_SIZES.DESKTOP_HEIGHT;
        } else {
            switch (device) {
                case DWCore.Common.Devices.PHONE: return GRID_SIZES.PHONE_HEIGHT;
                case DWCore.Common.Devices.TABLET: return GRID_SIZES.TABLET_HEIGHT;
                default: return GRID_SIZES.DESKTOP_HEIGHT;
            }
        }
    }

    /**
     * Returns the current GridStack instance.
     * 
     * @private
     * @returns {GridStack | null} Current GridStack instance.
     * 
     * @memberof LayoutManager
     */
    private getGridInstance(): GridStack | null {
        const instance = GridStackImport.GridStack.init(undefined, this.layoutNode);
        if (!instance) {
            this.logger.warn(this.className, 'getGridInstance', 'GridStack instance not found.');
            return null;
        }
        return instance;
    }

    /**
     * Zoom to component when it's just been dropped
     *
     * @private
     * @param {Position} position
     * @param {GridStack} grid
     * @memberof GridLayoutManager
     */
    private transfromPositions(position: Position, grid: any): void {
        if (this.currentDevice === 0) {
            this.dropAreaWrapper = document.querySelector<HTMLElement>('.root-wrapper');
        } else {
            this.dropAreaWrapper = document.querySelector<HTMLElement>('.grid-wrapper > .ui-droppable');
        }
        if (this.dropAreaWrapper) {

            // @ts-ignore - Ignore because of Gridstack usage
            const scrollTopValue = position.y * grid.opts.cellHeight;
            // @ts-ignore - Ignore because of Gridstack usage
            const scrollLeftValue = position.x * grid.opts.minWidth / grid.opts.width;
            if (typeof this.dropAreaWrapper.scrollTo === 'function') {
                this.dropAreaWrapper.scrollTo(scrollLeftValue, scrollTopValue);
            } else {
                this.dropAreaWrapper.scrollTop = scrollTopValue;
                this.dropAreaWrapper.scrollLeft = scrollLeftValue;
            }
        }
    }

    /**
     * Calculate the cell height.
     *
     * @private
     * @returns {number} The cell height.
     * @memberof GridLayoutManager
     */
    private calculateCellHeight(): number {
        if (this.isHeaderLess) {
            return ((document.body.offsetHeight - this.headerPadding) / this.getDeviceMinRows(this.currentDevice));
        } else {
            return ((document.body.offsetHeight - this.headerHeight - this.headerPadding) / this.getDeviceMinRows(this.currentDevice));
        }
    }
    /**
     * Function which will handle the added items on the grid.
     *
     * @private
     * @param {*} _event The event.
     * @param {*} items The items which were added.
     * @memberof GridLayoutManager
     */
    private addedItemsToGrid(_event: any, items: any): void {
        if (Utils.Instance.isArray(items)) {
            const itemsCount = items.length;
            for (let i = 0; i < itemsCount; i++) {

                const item = items[i];
                if (!item) {
                    continue;
                }

                const componentId = item.el?.getAttribute('data-wd-component-id');
                if (!componentId) {
                    continue;
                }

                const position: Position = {
                    height: item.h,
                    width: item.w,
                    x: item.x,
                    y: item.y
                };

                if (componentId) {
                    const grid = this.getGridInstance();

                    if (grid) {
                        grid.batchUpdate();
                        this.isBatchUpdateActive = true;

                        if (item.el) {
                            grid.removeWidget(item.el);
                        }
                        this.addComponentDragDrop(componentId, position);
                        this.transfromPositions(position, grid);
                        this.executeOnLayoutChange(this.getComponentPositions());
                    }
                }
            }
        }
    }
}