import { IClonable } from '../common';

/**
 * The user details class provides the windream authentication information.
 * 
 * @export
 * @class UserDetails
 */

export class UserDetails implements IClonable<UserDetails> {
    /**
     * The full user name.
     * 
     * @type {string}
     * @memberof UserDetails
     */
    public fullUsername?: string;

    /**
     * The user's name.
     * 
     * @type {string}
     * @memberof UserDetails
     */
    public userName?: string;

    /**
     * The user's domain.
     *
     * @type {string}
     * @memberof UserDetails
     */
    public domain?: string;

    /**
     * Whether the user is valid or not.
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    public isValidUser: boolean = false;

    /**
     * The user's identifier.
     *
     * @type {number}
     * @memberof UserDetails
     */
    public id?: number;

    /**
     * The JWT received from the WebService.
     * 
     * @type {(string | null)}
     * @memberof UserDetails
     */
    public token: string | null = null;


    private _isAdmin: boolean | null = null;
    /**
     * Whether the user is an administrator or not.
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    public get isAdmin(): boolean {
        return !!this._isAdmin;
    }
    /**
     * Sets the information whether the user is an administrator.
     * Can only be set once.
     * 
     * @memberof UserDetails
     */
    public set isAdmin(newValue: boolean) {
        if (this._isAdmin === null) {
            this._isAdmin = newValue;
        }
    }

    /**
     * Creates a new object from a serialized string created by `serialize()`.
     * 
     * @static
     * @param {string} json String to create from.
     * @returns {UserDetails} Deserialized object.
     * @memberof UserDetails
     */
    public static deserialize(json: string): UserDetails {
        const parsed = JSON.parse(json);
        const result = new UserDetails();
        result.userName = parsed.userName;
        result.isAdmin = parsed.isAdmin;
        result.fullUsername = parsed.fullUsername;
        result.token = parsed.token;
        result.id = parsed.id;
        result.domain = parsed.domain;
        return result;
    }

    /**
     * Returns a clone of this object.
     * 
     * @returns {UserDetails} 
     * 
     * @memberof UserDetails
     */
    public clone(): UserDetails {
        const clone = new UserDetails();
        clone.fullUsername = this.fullUsername;
        clone.isAdmin = this.isAdmin;
        clone.isValidUser = this.isValidUser;
        clone.token = this.token;
        clone.userName = this.userName;
        clone.id = this.id;
        clone.domain = this.domain;
        return clone;
    }

    /**
     * Serializes the object.
     * De-serialize with `UserDetails.deserialize()`.
     * 
     * @returns {string} String representing this object.
     * @memberof UserDetails
     */
    public serialize(): string {
        return JSON.stringify(this);
    }

    /**
     * Gets the initials for the user.
     *
     * @type {(string)}
     * @memberof CurrentUser
     */
    public getInitials(defaultInitials: string): string {
        if (this.fullUsername || this.userName) {
            let name: string = '';
            if (this.fullUsername) {
                name = this.fullUsername.trim();
            } else if (this.userName) {
                name = this.userName.trim();
            }
            if (name.length === 0) {
                return defaultInitials;
            }
            // Split the full name into an array of words
            const nameArr = name.split(' ');
            // Initialize an empty string for initials
            let initials = '';
            // Get the first character of the first word
            if (nameArr.length > 0) {
                initials += nameArr[0].charAt(0).toUpperCase(); // Add the first letter of the first name
            }
            // Loop through the remaining words to get the initials
            for (let i = 1; i < nameArr.length; i++) {
                const word = nameArr[i];
                if (word.length > 0 && /[A-Z]/.test(word.charAt(0))) {
                    initials += word.charAt(0).toUpperCase(); // Add the first letter of each subsequent word
                }
            }
            return initials;
        } else {
            return defaultInitials;
        }
    }
}