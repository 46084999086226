import { Culture, Logger } from '../../../../typings';
import { DataCollectionDisplay } from '../../../../typings/core';
import { LanguageProvider } from '../../../../typings/language';
import {
    Button as IButton, CheckBox as ICheckBox, ComboBox as IComboBox, DatePicker as IDatePicker, DropDownButton as IDropDownButton, MasterTreeView as IMasterTreeView,
    MultiSelectBox as IMultiSelectBox, NumberBox as INumberBox, RadioButtonGroup as IRadioButtonGroup, SelectBox as ISelectBox, TagBox as ITagBox,
    TextArea as ITextArea, TextBox as ITextBox, List as IList, Tooltip as ITooltip, DynamicMasterTreeView as IDynamicMasterTreeView
} from '../../../../typings/ui';
import { DxMasterTable } from '../../masterTable';
import { DxMasterTreeView } from '../../masterTreeView';
import { DynamicMasterTreeView } from '../../masterTreeView/dynamicMasterTreeView';
import { Button } from './button';
import { CheckBox } from './checkBox';
import { ComboBox } from './comboBox';
import { DatePicker } from './datePicker';
import { DropDownButton } from './dropDownButton';
import { IUiComponentFactory } from './interfaces';
import { List } from './list';
import { LoadingIndicator } from './loadingIndicator';
import { MultiSelectBox } from './multiSelectBox';
import { NumberBox } from './numberBox';
import { RadioButtonGroup } from './radioButtonGroup';
import { SelectBox } from './selectBox';
import { TagBox } from './tagBox';
import { TextArea } from './textArea';
import { TextBox } from './textBox';
import { Tooltip } from './tooltip';

/**
 * Factory for UI components.
 *
 * @export
 * @class UiComponentFactory
 */
export class UiComponentFactory implements IUiComponentFactory {
    private jQueryStatic: JQueryStatic;
    private logger: Logger;
    private languageProvider?: LanguageProvider;
    private cultureHelper?: Culture;
    private currentWindow: Window;

    /**
     * Creates an instance of UiComponentFactory.
     * 
     * @param {JQueryStatic} jQueryStatic JQuery.
     * @param {Logger} logger The logger.
     * @param {Window} currentWindow The current window.
     * @memberof UiComponentFactory
     */
    public constructor(jQueryStatic: JQueryStatic, logger: Logger, currentWindow: Window) {
        this.jQueryStatic = jQueryStatic;
        this.logger = logger;
        this.currentWindow = currentWindow;
    }

    /**
     * Sets the language provider instance to use.
     *
     * @param {LanguageProvider} languageProvider Instance to use.
     * @memberof UiComponentFactory
     */
    public setLanguageProvider(languageProvider: LanguageProvider): void {
        this.languageProvider = languageProvider;
    }

    /**
     * Sets the culture helper instance to use.
     *
     * @param {Culture} cultureHelper Instance to use.
     * @memberof UiComponentFactory
     */
    public setCultureHelper(cultureHelper: Culture): void {
        this.cultureHelper = cultureHelper;
    }

    /**
     * Creates a text box component.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {TextBox} The component.
     * @memberof UiComponentFactory
     */
    public textBox(targetElement: HTMLElement): ITextBox {
        return new TextBox(targetElement);
    }

    /**
     * Creates a number box component.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {NumberBox} The component.
     * @memberof UiComponentFactory
     */
    public numberBox(targetElement: HTMLElement): INumberBox {
        if (!this.cultureHelper) {
            this.logger.error('UiComponentFactory', 'tagBox', 'Cannot create TagBox - no CultureHelper set');
            throw new Error('Cannot create TagBox - no CultureHelper set');
        } else {
            return new NumberBox(targetElement, this.jQueryStatic);
        }
    }

    /**
     * Creates a combo box component.
     *
     * @template T Type of the value used.
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {ComboBox} The component.
     * @memberof UiComponentFactory
     */
    public comboBox<T>(targetElement: HTMLElement): IComboBox<T> {
        return new ComboBox<T>(targetElement, this.currentWindow, this.jQueryStatic);
    }

    /**
     * Creates a check box component.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {CheckBox} The component.
     * @memberof UiComponentFactory
     */
    public checkBox(targetElement: HTMLElement): ICheckBox {
        return new CheckBox(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a select box component.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {CheckBox} The component.
     * @memberof UiComponentFactory
     */
    public selectBox<TValue>(targetElement: HTMLElement): ISelectBox<TValue> {
        return new SelectBox(targetElement, this.currentWindow, this.jQueryStatic);
    }

    /**
     * Creates a button component.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {Button} The component.
     * @memberof UiComponentFactory
     */
    public button(targetElement: HTMLElement): IButton {
        return new Button(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a date picker component.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {DatePicker} The component.
     * @memberof UiComponentFactory
     */
    public datePicker(targetElement: HTMLElement): IDatePicker {
        return new DatePicker(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a drop down button component.
     *
     * @param {HTMLElement} targetElement Element to render the component into.
     * @returns {DropDownButton} The component.
     * @memberof UiComponentFactory
     */
    public dropDownButton(targetElement: HTMLElement): IDropDownButton {
        return new DropDownButton(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a radio button group component.
     *
     * @template T Type of the value used.
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {IRadioButtonGroup<T>} The component.
     * @memberof UiComponentFactory
     */
    public radioButtonGroup<T>(targetElement: HTMLElement): IRadioButtonGroup<T> {
        return new RadioButtonGroup<T>(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a text area component.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {TextArea} The component.
     * @memberof UiComponentFactory
     */
    public textArea(targetElement: HTMLElement): ITextArea {
        return new TextArea(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a multi select box component.
     *
     * @template T Type of the value used.
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {IMultiSelectBox<T>} The component.
     * @memberof UiComponentFactory
     */
    public multiSelectBox<T>(targetElement: HTMLElement): IMultiSelectBox<T> {
        return new MultiSelectBox<T>(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a tag box component.
     * Will fail if no LanguageProvider has been set.
     *
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {TagBox} The component.
     * @memberof UiComponentFactory
     */
    public tagBox(targetElement: HTMLElement): ITagBox {
        if (!this.languageProvider) {
            this.logger.error('UiComponentFactory', 'tagBox', 'Cannot create TagBox - no LanguageProvider set');
            throw new Error('Cannot create TagBox - no LanguageProvider set');
        } else if (!this.cultureHelper) {
            this.logger.error('UiComponentFactory', 'tagBox', 'Cannot create TagBox - no CultureHelper set');
            throw new Error('Cannot create TagBox - no CultureHelper set');
        } else {
            return new TagBox(targetElement, this.jQueryStatic, this.languageProvider, this.cultureHelper);
        }
    }

    /**
     * Create a loading indicator.
     *
     * @param {HTMLElement} targetElement The target element.
     * @returns {LoadingIndicator} The loading indicator.
     * @memberof UiComponentFactory
     */
    public loadingIndicator(targetElement: HTMLElement): LoadingIndicator {
        return new LoadingIndicator(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a tree view component.
     *
     * @template T
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {IMasterTreeView<T>} The component.
     * @memberof UiComponentFactory
     */
    public treeView<T>(targetElement: HTMLElement): IMasterTreeView<T> {
        return new DxMasterTreeView<T>(targetElement, this.jQueryStatic);
    }

    /**
     * Creates a data grid component.
     *
     * @template T
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {DxMasterTable<T>} The component.
     * @memberof UiComponentFactory
     */
    public dataGrid<T>(targetElement: HTMLElement): DataCollectionDisplay<T> {
        if (!this.languageProvider) {
            this.logger.error('UiComponentFactory', 'table', 'Cannot create table - no LanguageProvider set');
            throw new Error('Cannot create table - no LanguageProvider set');
        } else if (!this.cultureHelper) {
            this.logger.error('UiComponentFactory', 'table', 'Cannot create table - no CultureHelper set');
            throw new Error('Cannot create table - no CultureHelper set');
        } else {
            return new DxMasterTable<T>(targetElement, this.languageProvider, this.jQueryStatic, this.logger, this.cultureHelper);
        }
    }

    /**
     * Creates a list component.
     *
     * @template T
     * @param {HTMLElement} targetElement Element to render component into.
     * @returns {IList<T>} The component.
     * @memberof UiComponentFactory
     */
    public list<T>(targetElement: HTMLElement): IList<T> {
        return new List<T>(targetElement, this.jQueryStatic);
    }

    /**
     * Create a tooltip.
     *
     * @param {HTMLElement} targetElement The target element.
     * @returns {*}  {ITooltip} The tooltip instance.
     * @memberof UiComponentFactory
     */
    public tooltip(targetElement: HTMLElement): ITooltip {
        return new Tooltip(targetElement);
    }

    /**
     * Dynamic Tree View component.
     *
     * @template T
     * @param {HTMLElement} targetElement The target for the component.
     * @param {LanguageProvider} languageProvider The language provider.
     * @returns {IDynamicMasterTreeView<T>} The component.
     * @memberof UiComponentFactory
     */
    public dynamicTreeView<T>(targetElement: HTMLElement, languageProvider: LanguageProvider): IDynamicMasterTreeView<T> {
        return new DynamicMasterTreeView<T>(targetElement, languageProvider);
    }
}