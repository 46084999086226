import { SearchDTO, SearchResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Search';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { IMetadataStore } from '../../caching';
import { ValueTypes, WindreamIdentityDetails } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { SearchModelConverter } from '../../interface/search';
import { ILanguageProvider } from '../../language';
import { Logger } from '../../logging/logger';
import { IPopupHelper } from '../../ui';
import { NotificationHelper } from '../../ui/notificationHelper';
import { SearchRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform a search against the windream webservice.
 * 
 * @export
 * @class Search
 * @extends {ServiceAction}
 */
export class Search extends ServiceAction {
    private readonly converter: SearchModelConverter;
    private readonly popupHelper: IPopupHelper;
    private readonly languageProvider: ILanguageProvider;
    private readonly metadataStore: IMetadataStore;

    /**
     * Creates an instance of Search.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {SearchModelConverter} converter
     * @param {IPopupHelper} popupHelper
     * @param {ILanguageProvider} languageProvider
     * @param {IMetadataStore} metadataStore
     * @memberof Search
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, converter: SearchModelConverter,
        popupHelper: IPopupHelper, languageProvider: ILanguageProvider, metadataStore: IMetadataStore) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'search';

        this.converter = converter;
        this.popupHelper = popupHelper;
        this.languageProvider = languageProvider;
        this.metadataStore = metadataStore;
    }

    /**
     * Executes a search in windream.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {SearchRequestOptions} searchRequestOptions The request options.
     * @returns {Promise<WindreamIdentityDetails[]>} A promise, which will resolve with the server response.
     * @memberof Search
     */
    public async do(searchRequestOptions: SearchRequestOptions): Promise<WindreamIdentityDetails[]> {
        return new Promise<WindreamIdentityDetails[]>((resolve, reject) => {
            let isIntendedToSortByVirtualColumn: boolean = false;

            let searchQuery: SearchDTO | null = null;
            if (searchRequestOptions.searchDTO) {
                searchQuery = searchRequestOptions.searchDTO;
            } else if (searchRequestOptions.searchQuery) {
                searchQuery = this.converter.convert(searchRequestOptions.searchQuery);
            }
            if (!searchQuery) {
                reject(new Error('No search query could be created.'));
                return;
            }

            // Remove sorting by a virtual column as this would throw an error.
            // TODO: Remove once it is supported in windream.
            if (searchQuery.Sorting && searchQuery.Sorting.AttributeName) {
                if (searchQuery.Sorting.AttributeName.match(/^#{2}.*#{2}/)) {
                    this.logger.warn('Search', 'do', 'Sorting by virtual column is not available, removed sorting.');
                    isIntendedToSortByVirtualColumn = true;
                    delete searchQuery.Sorting;
                } else {
                    // Remove vector sorting for now since it's not supported.
                    const attribute = this.metadataStore.availableAttributes.find((attribute) => searchQuery && searchQuery.Sorting && attribute.name === searchQuery.Sorting.AttributeName);
                    if (attribute && attribute.type === ValueTypes.Vector) {
                        this.logger.warn('Search', 'do', 'Sorting by vector column is not available, removed sorting.');
                        NotificationHelper.Instance.warning({
                            body: this.languageProvider.get('framework.search.vectorColumnsNotSupported')
                        });
                        delete searchQuery.Sorting;
                    }
                }

            }

            this.checkForEmptyConditions(searchQuery).then((execute: boolean) => {
                if (!execute) {
                    this.logger.warn('Search', 'do', 'About to execute search without any condition', searchQuery);

                    resolve(new Array<WindreamIdentityDetails>());
                    return;
                }

                if (!searchQuery) {
                    reject(new Error('No search query could be created.'));
                    return;
                }
                const tempUrl = `${this.globalConfig.windreamWebServiceURL}/search/Search`;
                const httpResourcePtr = new HttpResourcePointer('POST', tempUrl, searchQuery);

                this.requestExecutor.executeRequest(httpResourcePtr, searchRequestOptions.requestOptions)
                    .then((response: IServiceResponse<SearchResponseContainerDTO>) => {
                        if (response.data && !response.data.HasErrors && response.data.Result) {
                            if (!searchQuery) {
                                reject(new Error('No search query could be created.'));
                                return;
                            }
                            if (response.data?.HasMoreResults && searchQuery.Limit) {
                                if (isIntendedToSortByVirtualColumn) {
                                    NotificationHelper.Instance.info({
                                        body: this.languageProvider.getWithFormat('framework.search.virtualColumnsNotSupported',
                                            searchQuery.Limit.toString())
                                    });
                                } else {
                                    NotificationHelper.Instance.info({
                                        body: this.languageProvider.getWithFormat('framework.search.resultLimitExceeded',
                                            searchQuery.Limit.toString())
                                    });
                                }
                            }
                            const result = response.data.Result.map((item) => WindreamIdentityDetails.fromDto(item));
                            resolve(result);
                        } else {
                            this.logger.error('Search', 'do', 'Failed to search', response);
                            reject(new Error('Failed to search'));
                        }
                    }).catch((err: Error) => {
                        this.logger.error('Search', 'do', 'Failed to execute request', err);
                        reject(err);
                    });
            }).catch((err: Error) => {
                this.logger.error('Search', 'do', 'Failed to check for empty conditions', err);
                reject(err);
            });
        });
    }

    /**
     * Checks if the given search query has any conditions set.
     * If it has the Promise is resolved with true immideately.
     * If it has no conditions set, a warning prompt is being shown asking the user if the search
     * should be performed nonetheless. If the user confirms, the Promise is resolved with true, otherwise with false
     * 
     * @async
     * @private
     * @param {SearchDTO} searchQuery Query to check.
     * @returns {Promise<boolean>} Whether to execute the search.
     * @memberof Search
     */
    private async checkForEmptyConditions(searchQuery: SearchDTO): Promise<boolean> {
        if (!searchQuery.Conditions) {
            return Promise.reject(new Error('No condition object set in query'));
        } else if (searchQuery.Conditions.length > 0) {
            return Promise.resolve(true);
        } else {
            return new Promise<boolean>((resolve) => {
                this.popupHelper.openConfirmationPopup(() => {
                    // Yes
                    resolve(true);
                }, () => {
                    // No
                    resolve(false);
                }, {
                    body: this.languageProvider.get('framework.search.noConditionsPrompt.body'),
                    closeOnEsc: false,
                    title: this.languageProvider.get('framework.search.noConditionsPrompt.title')
                });
            });
        }
    }

}