/// <reference path="../../../node_modules/devextreme-dist/ts/dx.all.d.ts" />

import { Application } from '../application';
import { IRequestExecutor } from '../dataProviders';
import { GlobalConfig } from '../dynamicWorkspace';
import { ITemplateExtension } from '../extensions';
import { ILanguageProvider } from '../language';
import { ImprintTabPanelPage } from '../legal/imprint/imprintTabPanelPage';
import { TabPanelPage } from '../ui/models/tabPanelPage';
import { AboutTabPanelPage } from './models/aboutTabPanelPage';
import { LicenseInfoTabPanelPage } from './models/licenseInfoTabPanelPage';
import { ILicenseSeviceHelper, ILicenseTemplateHelper } from './index';

/**
 * This class renders the license popup.
 *
 * @export
 * @class LicenseTemplateHelper
 * @implements {ILicenseTemplateHelper}
 */
export class LicenseTemplateHelper implements ILicenseTemplateHelper {
    private currentLanguage: string;
    private languageProvider: ILanguageProvider;
    private templateExtension: ITemplateExtension;
    private jQuery: JQueryStatic;
    private requestExecutor: IRequestExecutor;
    private globalConfig?: GlobalConfig;
    private licenseServiceHelper: ILicenseSeviceHelper;

    /**
     * Creates an instance of LicenseTemplateHelper.
     * @param {ITemplateExtension} templateExtension
     * @param {string} currentLanguage The current language.
     * @param {ILanguageProvider} languageProvider
     * @param {JQueryStatic} jQuery
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {ILicenseSeviceHelper} licenseServiceHelper The license service helper.
     * @param {GlobalConfig} globalConfig
     * @memberof LicenseTemplateHelper
     */
    public constructor(templateExtension: ITemplateExtension,
        currentLanguage: string,
        languageProvider: ILanguageProvider,
        jQuery: JQueryStatic,
        requestExecutor: IRequestExecutor,
        licenseServiceHelper: ILicenseSeviceHelper,
        globalConfig?: GlobalConfig) {

        this.templateExtension = templateExtension;
        this.currentLanguage = currentLanguage;
        this.languageProvider = languageProvider;
        this.jQuery = jQuery;
        this.requestExecutor = requestExecutor;
        this.globalConfig = globalConfig;
        this.licenseServiceHelper = licenseServiceHelper;

        this.templateExtension.addHelper('getTranslationForLicensePanel', (key: string) => {
            return this.languageProvider.get(key);
        });
        this.templateExtension.addHelper('getBuildIdentifier', (identifier: string | number | null) => {
            if (identifier !== null) {
                return `(${identifier})`;
            } else {
                return this.languageProvider.get('framework.license.build.unknown');
            }
        });
    }

    /**
     * Gets the tab panel pages to display.
     *
     * @param {LicenseDetails} licenseDetails The license details.
     * @return {TabPanelPage[]}
     * @memberof LicenseTemplateHelper
     */
    public getTabPanelPages(): TabPanelPage[] {
        const tabPanelPages: TabPanelPage[] = [];
        tabPanelPages.push(new AboutTabPanelPage(this.templateExtension, this.currentLanguage, this.languageProvider, this.licenseServiceHelper));

        if (Application.hasExternalCore() || (this.globalConfig && this.globalConfig.imprint)) {
            tabPanelPages.push(new ImprintTabPanelPage(this.templateExtension, this.currentLanguage, this.languageProvider, this.requestExecutor));
        }

        tabPanelPages.push(new LicenseInfoTabPanelPage(this.templateExtension, this.currentLanguage, this.languageProvider, this.licenseServiceHelper, this.jQuery));

        return tabPanelPages;
    }

    /**
     * Renders the license content.
     *
     * @param {HTMLDivElement} targetElement The targetElement.
     * @param {ILicenseSeviceHelper} _licenseServiceHelper The license service helper.
     * @param {boolean} [isLoggedIn] Whether the user is logged in.
     * @memberof LicenseTemplateHelper
     */
    public renderLicenseContent(targetElement: HTMLDivElement, _licenseServiceHelper: ILicenseSeviceHelper, isLoggedIn?: boolean): void {
        const tabPanelPages = this.getTabPanelPages();
        const className = isLoggedIn ? 'wd-about-tab-panel logged-in' : 'wd-about-tab-panel';
        this.jQuery('<div class="' + className + '"></div>').dxTabPanel({
            items: tabPanelPages,
            itemTemplate: async (_itemData, itemIndex, itemElement) => {

                // Show loading indicator
                const loadingIndicatorElementWrapper = this.jQuery('<div></div>');
                loadingIndicatorElementWrapper.css({ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' });
                const loadingIndicator = loadingIndicatorElementWrapper.dxLoadIndicator({ visible: true }).appendTo(itemElement);

                // Add the text
                const textWrapper = this.jQuery('<div></div>').css({ position: 'absolute', top: '65%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' });
                textWrapper.append(this.languageProvider.get('framework.license.popup.collectinformations'));
                textWrapper.appendTo(itemElement);

                // Initialize the page
                await tabPanelPages[itemIndex].initialize();

                // Render the page
                await tabPanelPages[itemIndex].render(itemElement[0]);

                // Remove loading indicator
                loadingIndicator.remove();
                textWrapper.remove();

            },
            itemTitleTemplate: (_itemData, itemIndex, itemElement) => {
                const title = tabPanelPages[itemIndex].getTitle();
                itemElement.append(this.jQuery(`<span>${title}</span>`)[0]);
            }
        }).appendTo(targetElement);
    }

    /**
     * Renders an error page.
     *
     * @param {HTMLDivElement} targetElement The target element.
     * @memberof LicenseTemplateHelper
     */
    public renderLicenseError(targetElement: HTMLDivElement): void {
        this.templateExtension.render(targetElement, require('./templates/licenseError.html'));
    }
}