import { DWCore } from '@windream/dw-core/dwCore';
import { IConfigTranslation } from '../interfaces';
import { Style } from '.';

/**
 * This class is a data holder for each view config's meta data.
 * 
 * @export
 * @class ViewConfigMetaData
 */
export class ViewConfigMetaData implements DWCore.Views.ViewConfigMetaData {

    /**
     * The name of this view.
     *
     * @type {IConfigTranslation<string>}
     * @memberof ViewConfigMetaData
     */
    public name: IConfigTranslation<string>;

    /**
     * The description of this view.
     *
     * @type {IConfigTranslation<string>}
     * @memberof ViewConfigMetaData
     */
    public description: IConfigTranslation<string>;

    /**
     * The id of the view.
     * 
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public id: string;

    /**
     * The version of the view.
     * 
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public version?: string;

    /**
     * The modified date of the view.
     * 
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public modified?: string;

    /**
     * The author of the view.
     * 
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public author?: string;

    /**
     * The alias of the view.
     *
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public alias?: string;

    /**
     * The view's style.
     * 
     * @type {Style}
     * @memberof ViewConfigMetaData
     */
    public style?: Style;

    /**
     * Icon of this view.
     * 
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public icon: string;

    /**
     * The template identifier.
     *
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public templateId?: string;

    /**
     * The grid version.
     *
     * @type {string}
     * @memberof ViewConfigMetaData
     */
    public gridVersion?: string;

    /**
     * Whether the view is hidden in the navigation.
     *
     * @type {boolean}
     * @memberof ViewConfigMetaData
     */
    public isHiddenInNavigation: boolean;

    /**
     * The contextMenu name.
     *
     * @type {string}
     * @memberof ViewConfig
     */
    public contextMenu?: string;

    /**
     * Creates an instance of ViewConfigMetaData.
     *
     * @param {string} id ID of the view.
     * @memberof ViewConfigMetaData
     */
    public constructor(id: string) {
        this.id = id;
        this.name = {};
        this.description = {};
        this.icon = 'DEFAULT';
        this.isHiddenInNavigation = false;
    }
}