import { ComponentContainer, IComponentManager } from '../components';
import { ComponentInstanceConfig } from '../config';
import { IPubSubHandler } from '../pubSub';
import { ILoaderExtension } from './interfaces';

/**
 * The loader extension provides the possibility to load components.
 * 
 * @export
 * @class LoaderExtension
 * @implements {ILoaderExtension}
 */
export class LoaderExtension implements ILoaderExtension {

  /**
   * The name of this extension.
   * 
   * @type {string}
   * @memberof LoaderExtension
   */
  public name: string = 'component_loader';

  private componentManager: IComponentManager;

  /**
   * Creates an instance of LoaderExtension.
   * @param {IComponentManager} componentManager 
   * 
   * @memberof LoaderExtension
   */
  public constructor(componentManager: IComponentManager) {
    this.componentManager = componentManager;
  }

  /**
   * Adds a Component to the current view.
   * 
   * @param {ComponentInstanceConfig} componentInstanceConfig Configuration to use for new component.
   * @param {IPubSubHandler} pubSubHandler  PubSub Handler to use, if not provided the view PubSubHandler is used.
   * @returns {Promise<boolean>}  Promise to resolve after component has been loaded.
   * @async
   * 
   * @memberof LoaderExtension
   */
  public async loadComponent(componentInstanceConfig: ComponentInstanceConfig, pubSubHandler: IPubSubHandler): Promise<ComponentContainer> {
    return this.componentManager.addComponent(componentInstanceConfig, pubSubHandler);
  }
}