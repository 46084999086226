import { AuthenticationModes } from '../authentication';
import { GlobalConfig } from './models';
import { MSALConfig } from './models/msalConfig';

/**
 * This class provides a factory, which will generate GlobalConfig objects from JSON objects or JSON strings.
 * @version 1.0
 * @access public
 */
export class GlobalConfigFactory {
    /**
     * Gets the GlobalConfig from JSON.
     * @access public
     * @param {GlobalConfig | string} json The JSON, which should be transformed into a GlobalConfig.
     * @returns {GlobalConfig}
     * @throws {Error} The instance can not be created from the JSON.
     */
    public static fromJSON(json: GlobalConfig | string): GlobalConfig {
        const jsonObject = typeof json === 'string' ? JSON.parse(json) : json;
        // Prevent basic authentication for now
        let authenticationMode: AuthenticationModes;
        switch (jsonObject.authenticationMode) {
            case AuthenticationModes.MSAL:
            case AuthenticationModes.JWT:
            case AuthenticationModes.Windows:
                authenticationMode = jsonObject.authenticationMode;
                break;
            default:
                authenticationMode = AuthenticationModes.JWT;
                break;
        }
        const configObject: GlobalConfig = {
            authenticationMode: authenticationMode,
            availableComponents: jsonObject.availableComponents,
            availableThemes: jsonObject.availableThemes,
            customConfig: jsonObject.customConfig,
            imprint: jsonObject.imprint,
            languages: jsonObject.languages,
            logLevel: jsonObject.logLevel,
            windreamWebServiceURL: jsonObject.windreamWebServiceURL,
            windreamWebDAVServiceURL: jsonObject.windreamWebDAVServiceURL,
            msal: new MSALConfig(jsonObject.msal),
            useExternalCore: jsonObject.useExternalCore
        };
        // Utils.deepEqual(configObject, json);
        return configObject;
    }
}