/**
 * Extends the Event class to include the custom properties set by Sortable.js.
 * 
 * @export
 * @class SortableEvent
 * @extends {Event}
 */
export class SortableEvent {
  /**
   * Old index of the moved element.
   * 
   * @type {number}
   * @memberof SortableEvent
   */
  public oldIndex?: number;
  /**
   * New index of the moved element.
   * 
   * @type {number}
   * @memberof SortableEvent
   */
  public newIndex?: number;
  /**
   * Old parent element of the moved element.
   * 
   * @type {HTMLElement}
   * @memberof SortableEvent
   */
  public from?: HTMLElement;
  /**
   * New parent of the moved element.
   * 
   * @type {HTMLElement}
   * @memberof SortableEvent
   */
  public to?: HTMLElement;
  /**
   * Moved item.
   * 
   * @type {HTMLElement}
   * @memberof SortableEvent
   */
  public item?: HTMLElement;
  /**
   * Event target element.
   * 
   * @type {HTMLElement}
   * @memberof SortableEvent
   */
  public target?: HTMLElement;
}