import { ToolbarActionDisplayModeDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { DWCore } from '../dynamicWorkspace';

/**
 * Transform the toolbar config from the webservice to a local class.
 *
 * @export
 * @class ComponentInstanceToolbarDisplayOptionsConfigFactory
 */
export class ComponentInstanceToolbarDisplayOptionsConfigFactory {

    /**
     * Converts the DTO into a DW class.
     *
     * @static
     * @param {(ToolbarActionDisplayModeDTO| undefined)} toolbarConfigDto The DTO to convert.
     * @returns {DWCore.Components.ToolbarActionDisplayMode} The converted DTO.
     * @memberof ComponentInstanceToolbarDisplayOptionsConfigFactory
     */
    public static convertFromDTO(toolbarConfigDto: ToolbarActionDisplayModeDTO | undefined): DWCore.Components.ToolbarActionDisplayMode {
        switch (toolbarConfigDto) {
            case ToolbarActionDisplayModeDTO.Automatically:
                return DWCore.Components.ToolbarActionDisplayMode.Automatically;
            case ToolbarActionDisplayModeDTO.Icon:
                return DWCore.Components.ToolbarActionDisplayMode.Icon;
            case ToolbarActionDisplayModeDTO.Text:
                return DWCore.Components.ToolbarActionDisplayMode.Text;
            case ToolbarActionDisplayModeDTO.IconAndText:
                return DWCore.Components.ToolbarActionDisplayMode.IconAndText;
            default:
                return DWCore.Components.ToolbarActionDisplayMode.Automatically;
        }
    }
}