import { Logger } from '../logging';
import { IWebsocketFactory, IWebsocketProvider } from './interfaces';

/**
 * Websocket Provider to provide websocket connections.
 *
 * @export
 * @class WebsocketProvider
 * @implements {IWebsocketProvider}
 */
export class WebsocketProvider implements IWebsocketProvider {
  /**
   * Name of this class.
   *
   * @protected
   * @type {string}
   * @memberof WebsocketProvider
   */
  protected className: string = 'WebsocketProvider';

  private logger: Logger;
  private websocketFactory: IWebsocketFactory;

  /**
   * Creates an instance of WebsocketProvider.
   *
   * @param {IWebsocketFactory} websocketFactory 
   * @param {Logger} logger
   * @memberof WebsocketProvider
   */
  public constructor(websocketFactory: IWebsocketFactory, logger: Logger) {
    this.logger = logger;
    this.websocketFactory = websocketFactory;
  }

  /**
   * Returns a new WebSocket connection.
   *
   * @param {string} url URL to connect to.
   * @returns {Promise<WebSocket>}
   * @async
   *
   * @memberof WebsocketProvider
   */
  public async getConnection(webSocketUrl: string): Promise<WebSocket> {
    return new Promise<WebSocket>((resolve, reject) => {

      if (!webSocketUrl) {
        reject(new ReferenceError('missing argument "webSocketUrl"'));
      }

      if (webSocketUrl.indexOf('ws://') === -1 && webSocketUrl.indexOf('wss://') === -1 && window.location.protocol !== 'https:') {
        webSocketUrl = 'ws://' + webSocketUrl;
      }
      if (webSocketUrl.indexOf('wss://') === -1 && webSocketUrl.indexOf('ws://') === -1 && window.location.protocol === 'https:') {
        webSocketUrl = 'wss://' + webSocketUrl;
      }

      this.addAuthenticationToUrl(webSocketUrl).then((url: string) => {
        // Create the Websocket instance.
        const socket = this.websocketFactory.create(url);

        socket.onopen = () => {
          resolve(socket);
        };

        socket.onerror = (e: ErrorEvent) => {
          this.logger.error(this.className, 'getConnection', 'Failed to connect WebSocket', e);
          reject(new Error('Failed to connect WebSocket'));
        };
      }).catch((err: Error) => {
        this.logger.error(this.className, 'getConnection', 'Failed to add authentication', err);
        reject(err);
      });

    });
  }

  /**
   * Adds authentication information to the given URL.
   * 
   * @protected
   * @param {string} url URL to add authentication information to.
   * @returns {Promise<string>} URL with appended authentication information.
   * @memberof WebsocketProvider
   */
  protected async addAuthenticationToUrl(url: string): Promise<string> {
    return Promise.resolve(url);
  }
}