/* eslint-disable max-lines */
import { fromEvent } from 'file-selector';
import { IFileWithPath } from 'typings/core';
import { ValueTypes, WindreamEntity, WindreamIdentity } from '../common';

import { IUtils } from '../interface/publicAPI/iUtils';
import { DeviceDetection } from './deviceDetection';
import { HtmlSpecialCharacters, IClonable } from '.';
/**
 * Utils class provides useful methods.
 * @access public
 * @version 1.0
 */
export class Utils implements IUtils {

    /**
     * Use the Instance singleton's implementation instead of this static property.
     *
     * Gets the current DeviceDetection instance.
     *
     * @deprecated 
     * @static
     * @type {DeviceDetection}
     * @memberof Utils
     */
    public static deviceDetection = DeviceDetection;

    private static instance: IUtils = new Utils();

    private deepEqualLib = require('fast-deep-equal');

    /**
     * Creates an instance of Utils.
     *
     * @memberof Utils
     */
    // eslint-disable-next-line no-empty-function
    private constructor() {
    }


    /**
     * Gets the singleton instance.
     *
     * @readonly
     * @static
     * @type {IUtils}
     * @memberof Utils
     */
    public static get Instance(): IUtils {
        return Utils.instance;
    }


    /**
     * Gets the current DeviceDetection instance.
     *
     * @type {typeof DeviceDetection}
     * @memberof Utils
     */
    public get deviceDetection(): typeof DeviceDetection {
        return DeviceDetection;
    }


    /**
     * Use the Instance singleton's implementation instead of this static method.
     *
     * @static
     * @param {Object} expected
     * @param {Object} given
     * @param {boolean} [stringify]
     * @memberof Utils
     */
    public static deepEqual(expected: Object, given: Object, stringify?: boolean): void {
        Utils.Instance.deepEqual(expected, given, stringify);
    }

    /**
     *  Check whether the element is a HTMLElement or not.
     *
     * @static
     * @param {*} element
     * @returns {element is HTMLElement}
     * @memberof Utils
     */
    public static isHTMLElement(element: any): element is HTMLElement {
        return Utils.Instance.isHTMLElement(element);
    }
    /**
     * Check if two objects are deep equal to each other.
     *
     * @static
     * @param {*} expected
     * @param {*} given
     * @param {boolean} [stringify]
     * @returns {boolean}
     * @memberof Utils
     */
    public static isDeepEqual(expected: any, given: any, stringify?: boolean): boolean {
        return Utils.Instance.isDeepEqual(expected, given, stringify);
    }

    /**
     * Creates a deep clone of the given object.
     * 
     * @param {T} object Object to deep clone.
     * @param {boolean} [keysToLowerCase=false] Indicates whether the keys of the clone should be lower-case.
     * @returns {T} Deep clone of the given object.
     * 
     * @memberof Utils
     */
    public static deepClone<T>(object: T, keysToLowerCase: boolean = false): T {
        return Utils.Instance.deepClone(object, keysToLowerCase);
    }

    /**
     * Use the Instance singleton's implementation instead of this static method.
     *
     * Gets a newly created UUID.
     * @deprecated 
     * @access public
     * @returns {string} The UUID.
     */
    public static getUUID(): string {
        return Utils.Instance.getUUID();
    }

    /**
     * Use the Instance singleton's implementation instead of this static method.
     *
     * Gets a random string with the length of 5.
     *
     * @static
     * @deprecated 
     * @returns {string} The radom string
     *
     * @memberof Utils
     */
    public static getRandomString(): string {
        return Utils.Instance.getRandomString();
    }

    /**
     * Use the Instance singleton's implementation instead of this static method.
     *
     * Checks whether the argument is an array or not.
     * Type guard
     * @static
     * @deprecated 
     * @param {*} x The argument.
     * @returns {x is any[]}
     *
     * @memberof Utils
     */
    public static isArray(x: any): x is any[] {
        return Utils.Instance.isArray(x);
    }

    /**
     * Checks whether the argument is an array of strings.
     * Unable to check empty string array. Return false.
     *
     * @static
     * @param {*} x
     * @returns {x is string[]}
     * @memberof Utils
     */
    public static isStringArray(x: any): x is string[] {
        return Utils.Instance.isStringArray(x);
    }

    /**
     * Check whether an element is defined or not.
     * 
     * @private
     * @param {*} element The element to check.
     * @returns {element is (Object | string | boolean | number)}
     * 
     * @memberof Utils
     */
    public static isDefined(element: any): element is (Object | string | boolean | number) {
        return Utils.Instance.isDefined(element);
    }

    /**
     * Indicates whether a specified string is null, empty, or consists only of white-space characters.
     *
     * @param {string} element The string to test.
     * @returns {boolean} true if the value parameter is null or empty, or if value consists exclusively of white-space characters.
     * @memberof IUtils
     */
    public static isStringNullOrWhitespace(element: string): boolean {
        return Utils.Instance.isStringNullOrWhitespace(element);
    }

    /**
     * Moves one element to a given position.
     * See http://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another.
     * 
     * @param {any[]} arr to operate on
     * @param {ńumber} oldIndex Old index of the element.
     * @param {number} newIndex New index of the element.
     * 
     * @memberof Utils
     */
    public static moveArrayElement(arr: any[], oldIndex: number, newIndex: number): void {
        this.Instance.moveArrayElement(arr, oldIndex, newIndex);
    }

    /**
     * Compare two version strings separated by fullstops if they are larger, smaller or equal.
     *
     * @param {string} versionOne First version to check.
     * @param {string} versionTwo Second version to check.
     * @param {boolean} [isFourPointVersion] Whether the version has four points or not.
     * @returns {number} Returns either 0 if both versions are equal, a negative integer if versionOne < versionTwo or a positive integer if versionOne > versionTwo.
     * @memberof Utils
     */
    public static compareVersionString(versionOne: string, versionTwo: string, isFourPointVersion?: boolean): number {
        return this.Instance.compareVersionString(versionOne, versionTwo, isFourPointVersion);
    }

    /**
     * Check whether the string is null or empty.
     *
     * @static
     * @param {(string | null)} valueToCheck The value to check.
     * @returns {boolean} Whether the string is either null or empty.
     * @memberof Utils
     */
    public static isNullOrEmptyString(valueToCheck: string | null): boolean {
        return Utils.Instance.isNullOrEmptyString(valueToCheck);
    }

    /**
     * Returns the first parent element of the given element for which `checkFunction` returns true.
     * Will have a max amount of `maxIterations` if defined.
     * Will stop traversing up when reaching the body element.
     *
     * @param {HTMLElement} element
     * @param {(currentParent: HTMLElement) => boolean} checkFunction
     * @param {number} [maxIterations]
     * @returns {HTMLElement}
     *
     * @memberof Utils
     */
    public static parentsUntil(element: HTMLElement, checkFunction: (currentParent: HTMLElement) => boolean, maxIterations?: number): HTMLElement {
        return Utils.Instance.parentsUntil(element, checkFunction, maxIterations);
    }

    /**
     * Create a debounced version of the given function.
     * Use like this:
     * Create function: const myFunction = () => alert('Hello world');
     * Use Utils.debounce: const debounced = debounce(() => myFunction());
     * Now use debounce e.g. in a resize-listener.
     * 
     * @static
     * @param {() => void} func Function that should be debounced.
     * @param {number} timeout Timeout for the debounce.
     * @returns {() => void} The debounced function.
     * @memberof Utils
     */
    public static debounce(func: () => void, timeout: number): () => void {
        return Utils.Instance.debounce(func, timeout);
    }

    /**
     * Check the attribute type and return whether it is a number or not. 
     * 
     * @static
     * @param {ValueTypes.Decimal} type The used type.
     * @returns 
     * @memberof Utils
     */
    public static isNumberAttribute(type: ValueTypes) {
        return Utils.Instance.isNumberAttribute(type);
    }

    /**
     * Returns windream data type as dx compatible value.
     *
     * @static
     * @param {ValueTypes} type
     * @returns {string}
     * @memberof Utils
     */
    public static getValueTypeAsString(type: ValueTypes): string {
        return Utils.instance.getValueTypeAsString(type);
    }

    /**
     * Checks if an object implements the ICloneable interface and is therefore clonable.
     * 
     * @static
     * @param {Object} value Object to check.
     * @returns {value is IClonable<any>} Whether the object implements ICloneable.
     * 
     * @memberof Utils
     */
    public static isClonable(value: Object): value is IClonable<any> {
        return Utils.Instance.isClonable(value);
    }

    /**
     * Iterates over the elements parents to get the value of a windream attribute.
     * 
     * @static
     * @param {HTMLElement} element Element to start looking from.
     * @param {string} attribute Attribute to look for, e.g. id for `data-wd-id`.
     * @param {number} [maxIterations=3] Maximum number of parents to look up.
     * @returns {(string | null)} The attribute value or null if not specified.
     * @memberof Utils
     */
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public static getWdAttribute(element: HTMLElement, attribute: string, maxIterations: number = 3): string | null {
        return Utils.Instance.getWdAttribute(element, attribute, maxIterations);
    }

    /**
     * Checks the string if it matches exactly or not.
     *
     * @param {string} exactString The exact string which should be matched.
     * @param {string} text The text to check.
     * @param {boolean} [ignoreCasing] Whether casing should be ignored or not.
     * @returns {boolean} Whether the string matches exactly or not.
     * @memberof Utils
     */
    public static isExactStringMatch(exactString: string, text: string, ignoreCasing?: boolean): boolean {
        return Utils.Instance.isExactStringMatch(exactString, text, ignoreCasing);
    }

    /**
     * Check whether the drag event contains a file or not.
     *
     * @static
     * @param {DragEvent} event The event.
     * @returns {boolean} Whether the event contains a file or not.
     * @memberof Utils
     */
    public static isFileDrop(event: DragEvent): boolean {
        return Utils.instance.isFileDrop(event);
    }

    /**
     * Check whether the object is a promise or not.
     *
     * @static
     * @param {*} objectToCheck The object to check.
     * @returns {objectToCheck is Promise<any>} Whether the object is a promise or not.
     * @memberof Utils
     */
    public static isPromise(objectToCheck: any): objectToCheck is Promise<any> {
        return Utils.instance.isPromise(objectToCheck);
    }

    /**
     * Check for date object
     * Check if the original drag event came from an internal component.
     *
     * @static
     * @param {DragEvent} event Drag event that occured.
     * @returns {boolean} Whether the event is an interl drop or not.
     * @memberof Utils
     */
    public static isInternalDrop(event: DragEvent): boolean {
        return Utils.instance.isInternalDrop(event);
    }

    /**
     * Makes an array distinctive.
     *
     * @static
     * @param {any[]} array The array.
     * @returns {any[]} The distinctive array.
     * @memberof Utils
     */
    public static makeArrayDistinctive(array: any[]): any[] {
        return Utils.Instance.makeArrayDistinctive(array);
    }
    /**
     * Checks whether the given element is a Date instance.
     *
     * @static
     * @param {*} element
     * @returns {element is Date}
     * @memberof Utils
     */
    public static isDate(element: any): element is Date {
        return Utils.Instance.isDate(element);
    }
    /**
     * Checks whether the given string is a valid ISO date string.
     *
     * @static
     * @param {string} element Element to check.
     * @returns {boolean} Whether the given element is an ISO date string.
     * @memberof Utils
     */
    public static isDateString(element: string): boolean {
        return Utils.Instance.isDateString(element);
    }

    /**
     * Adds padding to the given string.
     * @static
     * @param {string} pad
     * @param {string} str
     * @param {boolean} leftPadded
     * @returns {string}
     * @memberof Utils
     */
    public static padString(pad: string, str: string, leftPadded: boolean): string {
        return Utils.Instance.padString(pad, str, leftPadded);
    }

    /**
     * Check whether the location is within localhost or not.
     *
     * @static
     * @param {Location} location
     * @returns {boolean}
     * @memberof Utils
     */
    public static isLocalHost(location: Location): boolean {
        return Utils.Instance.isLocalHost(location);
    }

    /**
     * Check whether a domain is within localhost.
     *
     * @static
     * @param {string} location The domain to check.
     * @returns {boolean} Whether a domain is within localhost.
     * @memberof Utils
     */
    public static isDomainWithinLocalHost(location: string): boolean {
        return Utils.Instance.isDomainWithinLocalHost(location);
    }

    /**
     * Parses an array of given identities and finds out if it either 
     * contains the same entities (1 | 2) or mixed (0)
     * @static
     * @param {WindreamIdentity[]} identities
     * @returns {WindreamEntity}
     * @memberof Utils
     */
    public static getIdentityTypes(identities: WindreamIdentity[]): WindreamEntity {
        return Utils.Instance.getIdentityTypes(identities);
    }

    /**
     * Escapes a string in order to use it in HTML.
     *
     * @static
     * @param {string} value The unescaped value.
     * @returns {string} The escaped value.
     * @memberof Utils
     */
    public static escapeStringValue(value: string): string {
        return Utils.Instance.escapeStringValue(value);
    }

    /**
     * Gets the amount of dragged files.
     *
     * @static
     * @param {DragEvent} dragEvent
     * @returns {number}
     * @memberof Utils
     */
    public static getDragFileCount(dragEvent: DragEvent): number {
        return Utils.Instance.getDragFileCount(dragEvent);
    }

    /**
     * Determines whether the drag event only contain files.
     *
     * @static
     * @param {DragEvent} dragEvent
     * @returns {boolean}
     * @memberof Utils
     */
    public static isFileDrag(dragEvent: DragEvent): boolean {
        return Utils.Instance.isFileDrag(dragEvent);
    }

    /**
     * Determines whether the given array contains the given value.
     *
     * @static
     * @param {any[]} array
     * @param {*} value   
     * @returns {boolean}
     * @memberof Utils
     */
    public static contains(array: any[], value: any): boolean {
        return Utils.Instance.contains(array, value);
    }

    /**
     * Returns the index of the first found element within the array, that is equal to the given value.
     * If the value can not be found within the array, -1 will be returned.
     *
     * @static
     * @param {any[]} array
     * @param {*} value    
     * @returns {number}
     * @memberof Utils
     */
    public static findIndex(array: any[], value: any): number {
        return Utils.Instance.findIndex(array, value);
    }

    /**
     * Recursively sorts the properties of an object and its nested objects.
     *
     * @param {*} obj - The object to be sorted.
     * @return {*}  {*} - A new object with sorted properties.
     * @memberof Utils
     */
    public static sortObjectPropertiesDeep(obj: any): any {
        return Utils.Instance.sortObjectPropertiesDeep(obj);
    }

    /**
     * Check the value is number and is not a null, undefined, NaN and infinity. 
     *
     * @param {number | undefined | null} value The number.
     * @returns {boolean} Whether it is a valid number.
     * @memberof Utils
     */
    public static isValidNumber(num: number | undefined | null): boolean {
        return Utils.Instance.isValidNumber(num);
    }

    /**
     * Rounds the given value.
     *
     * @param {number} value The value to round.
     * @param {number} [decimals] Defines how many decimal places should be used.
     * @returns {number}
     * @memberof Utils
     */
    public static round(value: number, decimals?: number): number {
        return Utils.Instance.round(value, decimals);
    }

    /**
     * Rounds the given value considering the value type.
     *
     * @param {number} value The value to round.
     * @param {ValueTypes} valueType The type of the given value.
     * @returns {number}
     * @memberof Utils
     */
    public static roundByValueType(value: number, valueType: ValueTypes): number {
        return Utils.Instance.roundByValueType(value, valueType);
    }

    /**
     * Check whether a domain is within localhost.
     *
     * @param {string} location The domain to check.
     * @returns {boolean} Whether a domain is within localhost.
     * @memberof Utils
     */
    public isDomainWithinLocalHost(location: string): boolean {
        if (!(location.startsWith('http://') || location.startsWith('https://'))) {
            location += 'http://';
        }
        const lastSlashBeforeEndOfDomainIndex = 3;
        const domain = location.split('/').slice(0, lastSlashBeforeEndOfDomainIndex).join('/');
        return !!(domain.includes('localhost') ||
            domain.includes('[::1]') ||
            domain.match(/^.*(127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}).*$/));
    }

    /**
     * Check whether the location is within localhost or not.
     *
     * @param {Location} location
     * @returns {boolean}
     * @memberof Utils
     */
    public isLocalHost(location: Location): boolean {
        return !!(location.hostname === 'localhost' ||
            location.hostname === '[::1]' ||
            location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
    }

    /**
     * Check if two objects are deep equal to each other.
     * Throws an error if objects are not equal.
     * Use optional stringify parameter if you also
     * want to take element order into consideration
     *
     * @param {*} expected
     * @param {*} given
     * @param {boolean} [stringify]
     * @memberof Utils
     */
    public deepEqual(expected: any, given: any, stringify?: boolean): void {
        if (stringify) {
            if (JSON.stringify(expected) !== JSON.stringify(given)) {
                throw new Error('Objects are not deepEqual: difference in stringified string');
            }
        } else {
            const isDeepEqual = this.deepEqualLib(expected, given);
            if (!isDeepEqual) {
                throw new Error('Objects are not deepEqual');
            }
        }
    }

    /**
     * Recursively sorts the properties of an object and its nested objects.
     *
     * @param {*} obj - The object to be sorted.
     * @return {*}  {*} - A new object with sorted properties.
     * @memberof Utils
     */
    public sortObjectPropertiesDeep(obj: any): any {
        if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
            // Get the keys of the object and sort them
            const sortedKeys = Object.keys(obj).sort();

            // Create a new object with the properties in the sorted order
            const sortedObj: Record<string, any> = {};
            for (const key of sortedKeys) {
                sortedObj[key] = this.sortObjectPropertiesDeep(obj[key]);
            }

            return sortedObj;
        } else if (Array.isArray(obj)) {
            // If it's an array, sort each element if it's an object
            return obj.map((item) => this.sortObjectPropertiesDeep(item));
        }

        return obj;
    }

    /**
     * Check whether the object is a promise or not.
     *
     * @param {*} objectToCheck The object to check.
     * @returns {objectToCheck is Promise<any>} Whether the object is a promise or not.
     * @memberof Utils
     */
    public isPromise(objectToCheck: any): objectToCheck is Promise<any> {
        return !!objectToCheck && (Promise.resolve(objectToCheck) === objectToCheck || (typeof objectToCheck.then === 'function' && typeof objectToCheck.catch === 'function'));
    }

    /**
     * Check if two objects are deep equal to each other.
     *
     * @param expected {*} The expected format of an object.
     * @param given {*} The given format of an object.
     * @returns {boolean} Whether objects are equal.
     * @memberof Utils
     */
    public isDeepEqual(expected: any, given: any, stringify?: boolean): boolean {
        try {
            this.deepEqual(expected, given, stringify);
            return true;
        } catch (err) {
            return false;
        }
    }

    /**
     * Check whether the string is null or empty.
     *
     * @param {string} valueToCheck The value to check.
     * @returns {boolean} Whether the string is either null or empty.
     * @memberof Utils
     */
    public isNullOrEmptyString(valueToCheck: string): boolean {
        if (Utils.Instance.isDefined(valueToCheck)) {
            return valueToCheck.length === 0;
        }
        return true;
    }

    /**
     * Creates a deep clone of the given object or array.
     * If undefined or null are passed, they are returned as is.
     * 
     * @param {T} object Object to deep clone.
     * @returns {T} Deep clone of the given object.
     * 
     * @memberof Utils
     */
    public deepClone<T>(object: T): T {
        if (object === null || typeof object === 'undefined' || typeof object !== 'object') {
            return object;
        }

        if (this.isClonable(object)) { // If object is clonable, use clone function
            return object.clone();
        }
        if (object instanceof Map) {
            const clone = new Map<any, any>();
            object.forEach((value, key) => {
                clone.set(key, this.deepClone(value));
            });
            return clone as any;
        }
        if (this.isArray(object)) {
            return object.map((value) => this.deepClone(value)) as any;
        }
        if (this.isDate(object)) {
            return new Date(object) as any;
        }
        const clone = {} as { [key: string]: any };
        const keys = Object.keys(object);
        for (const key of keys) {
            // @ts-ignore - Ignore because of no index
            const value = object[key];
            if (typeof value === 'undefined') {
                clone[key] = undefined;
            } else if (this.isArray(value)) {
                clone[key] = value.map((value) => this.deepClone(value));
            } else if (['string', 'number', 'boolean'].indexOf(typeof value) !== -1) {
                clone[key] = value;
            } else if (value instanceof Map) {
                clone[key] = new Map(value);
            } else if (typeof value === 'function') {
                throw new Error(`Functions are not supported, but function was present in key '${key}'`);
            } else {
                clone[key] = this.deepClone(value);
            }
        }
        return clone as T;
    }

    /**
     * Gets a newly created UUID.
     *
     * @access public
     * @returns {string} The UUID.
     */
    public getUUID(): string {
        // External library does not need linting
        // See http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
        const s4 = () => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return Math.floor((1 + Math.random()) * 0x10000)
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                .toString(16)
                .substring(1)
                .toUpperCase();
        };
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    /**
     * Gets a random string with the length of 5.
     *
     * @returns {string} The radom string
     *
     * @memberof Utils
     */
    public getRandomString(): string {
        const stringSeed = 36;
        const stringLength = 5;
        return Math.random().toString(stringSeed).replace(/[^a-z]+/g, '').substr(0, stringLength);
    }

    /**
     * Checks whether the argument is an array or not.
     * Type guard
     * @param {*} x The argument.
     * @returns {x is any[]}
     *
     * @memberof Utils
     */
    public isArray(x: any): x is any[] {
        if (Array.isArray) {
            return Array.isArray(x);
        }
        return Object.prototype.toString.call(x) === '[object Array]';
    }

    /**
     * Checks whether the argument is an string array or not.
     * Unable to check empty string array. Return false.
     * Type guard
     * @param {*} x The argument.
     * @returns {x is any[]}
     *
     * @memberof Utils
     */
    public isStringArray(x: any): x is string[] {
        if (Utils.Instance.isArray(x) && x.length > 0) {
            let somethingIsNotString = false;
            x.forEach((item) => {
                if (typeof item !== 'string') {
                    somethingIsNotString = true;
                }
            });
            if (!somethingIsNotString) {
                return true;
            }
        }
        return false;
    }

    /**
     * Escapes a string in order to use it in HTML.
     *
     * @param {string} value The unescaped value.
     * @returns {string} The escaped value.
     * @memberof Utils
     */
    public escapeStringValue(value: string): string {
        let returnValue = '';
        const chars = value.split('');
        chars.forEach((char) => {
            switch (char.charCodeAt(0)) {
                case HtmlSpecialCharacters.Quote:
                    returnValue += '&quot;';
                    break;
                case HtmlSpecialCharacters.And:
                    returnValue += '&amp;';
                    break;
                case HtmlSpecialCharacters.SingleQuote:
                    returnValue += '&#x27;';
                    break;
                case HtmlSpecialCharacters.Slash:
                    returnValue += '&#x2F;';
                    break;
                case HtmlSpecialCharacters.LesserThan:
                    returnValue += '&lt;';
                    break;
                case HtmlSpecialCharacters.GreaterThan:
                    returnValue += '&gt;';
                    break;
                default:
                    returnValue += char;
                    break;
            }
        });
        return returnValue;
    }

    /**
     * Check whether the element is a HTMLElement or not.
     *
     * @param {*} element The element to check.
     * @returns {element is HTMLElement}
     * @memberof Utils
     */
    public isHTMLElement(element: any): element is HTMLElement {
        try {
            return element instanceof HTMLElement;
        } catch (e) {
            return (typeof element === 'object') &&
                (element.nodeType === 1) && (typeof element.style === 'object') &&
                (typeof element.ownerDocument === 'object');
        }
    }
    /**
     * Check whether the drag event contains a file or not.
     *
     * @param {DragEvent} event The event.
     * @returns {boolean} Whether the event contains a file or not.
     * @memberof Utils
     */
    public isFileDrop(event: DragEvent): boolean {
        if (event.dataTransfer) {
            // Prefer .files as within .items we cannot differentiate between folders and files
            const items = event.dataTransfer.items;
            if (items && items.length >= 0) {
                let wrongFilesCount = 0;
                // Can't use that one on datatransfer list.
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < items.length; i++) {
                    // Took from DataTransfer.items
                    const item = items[i];
                    if (item.kind !== 'file' && !this.isKnownFileDropType(item.type)) {
                        wrongFilesCount++;
                    } else if (item.webkitGetAsEntry === null) {
                        wrongFilesCount++;
                    }
                }
                return wrongFilesCount === 0;
            }
            if (event.dataTransfer.types) {
                for (const i in event.dataTransfer.types) {
                    if (this.isKnownFileDropType(event.dataTransfer.types[i])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Check if the original drag event came from an internal component.
     *
     * @param {DragEvent} event Drag event that occured.
     * @returns {boolean} Whether the event is an internal drop or not.
     * @memberof Utils
     */
    public isInternalDrop(event: DragEvent): boolean {
        if (!event || !event.dataTransfer) {
            return false;
        }
        if(event.dataTransfer.files.length > 0) {
            // An internal browser drop can never have an actual file since we are dropping within a webpage.
            return false;
        }
        if (event.dataTransfer.types) {
            for (const i in event.dataTransfer.types) {
                // Workaround for IE 11
                if (event.dataTransfer.types[i] === 'Text' || event.dataTransfer.types[i] === 'text/plain' || event.dataTransfer.types[i] === 'text/html') {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Check whether an element is defined or not.
     * 
     * @param {*} element The element to check.
     * @returns {boolean}
     * 
     * @memberof Utils
     */
    public isDefined(element: any): element is (Object | string | boolean | number) {
        return typeof (element) !== 'undefined' && element !== null;
    }

    /**
     * Indicates whether a specified string is null, empty, or consists only of white-space characters.
     *
     * @param {string} element The string to test.
     * @returns {boolean} true if the value parameter is null or empty, or if value consists exclusively of white-space characters.
     * @memberof Utils
     */
    public isStringNullOrWhitespace(element: string): boolean {
        if (this.isDefined(element)) {
            return element.replace(/\s/g, '').length < 1;
        }
        return true;
    }

    /**
     * Check wheter an element is an Date object
     *
     * @param {*} element
     * @returns {element is Date}
     * @memberof Utils
     */
    public isDate(element: any): element is Date {
        return (element instanceof Date || Object.prototype.toString.call(element) === '[object Date]') && (typeof element.getDate === 'function' && !isNaN(element.getDate()));
    }


    /**
     * Checks whether the given string is a valid date string.
     * Checks for full ISO strings as given (e.g. 2021-01-08T20:26:55.552Z).
     * Or Checks if a shorthand string is given (e.g. 2021-10-01T00:00:00).
     *
     * @param {string} element Element to check.
     * @returns {boolean} Whether the given element is a valid date string.
     * @memberof Utils
     */
    public isDateString(element: string): boolean {
        const fullRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
        const isFullRegexMatch = !!element.match(fullRegex);

        const shortRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/;
        const isShortRegexMatch = !!element.match(shortRegex);

        return isFullRegexMatch || isShortRegexMatch;
    }

    /**
     * Adds padding to the given string.
     *
     * @param {string} pad The padding.
     * @param {string} str The string.
     * @param {boolean} leftPadded Wheter it is left padded.
     * @returns {string} The result.
     * @memberof Utils
     */
    public padString(pad: string, str: string, leftPadded: boolean): string {
        if (str === undefined) {
            return pad;
        }

        if (leftPadded) {
            return (pad + str).slice(-pad.length);
        } else {
            return (str + pad).substring(0, pad.length);
        }
    }

    /**
     * Check the attribute type and return whether it is a number or not. 
     *
     * @param {ValueTypes} type The used type.
     * @returns {boolean} Whether it is a number attribute.
     * @memberof Utils
     */
    public isNumberAttribute(type: ValueTypes) {
        return type === ValueTypes.Decimal ||
            type === ValueTypes.Double ||
            type === ValueTypes.Float ||
            type === ValueTypes.Int ||
            type === ValueTypes.Int64 ||
            type === ValueTypes.Currency;
    }

    /**
     * Checks the string if it matches exactly or not.
     *
     * @param {string} exactString The exact string which should be matched.
     * @param {string} text The text to check.
     * @param {boolean} [ignoreCasing] Whether casing should be ignored or not.
     * @returns {boolean} Whether the string matches exactly or not.
     * @memberof Utils
     */
    public isExactStringMatch(exactString: string, text: string, ignoreCasing?: boolean): boolean {
        if (ignoreCasing) {
            exactString = exactString.toLocaleLowerCase();
            text = text.toLocaleLowerCase();
        }
        return !!text.match('^' + exactString + '$');
    }

    /**
     * Returns windream data type as dx compatible value.
     *
     * @param {ValueTypes} type
     * @returns {string}
     * @memberof Utils
     */
    public getValueTypeAsString(type: ValueTypes): string {
        // Accepted Values for dxDataGrid: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime'
        switch (type) {
            case ValueTypes.Undefined:
                return 'string';
            case ValueTypes.Int:
                return 'number';
            case ValueTypes.Int64:
                return 'number';
            case ValueTypes.String:
                return 'string';
            case ValueTypes.Double:
                return 'number';
            case ValueTypes.Vector:
                return 'string';
            case ValueTypes.Boolean:
                return 'boolean';
            case ValueTypes.Float:
                return 'number';
            case ValueTypes.DateTime:
                return 'datetime';
            case ValueTypes.Date:
                return 'date';
            case ValueTypes.Time:
                return 'time';
            case ValueTypes.Decimal:
                return 'number';
            case ValueTypes.Currency:
                return 'number';
            default:
                return 'string';
        }
    }

    /**
     * Checks if an object implements the ICloneable interface and is therefore clonable.
     * 
     * @param {*} value Object to check.
     * @returns {value is IClonable<any>} Whether the object implements ICloneable.
     * 
     * @memberof Utils
     */
    public isClonable(value: any): value is IClonable<any> {
        return typeof value['clone'] === 'function';
    }

    /**
     * Iterates over the elements parents to get the value of a windream attribute.
     * 
     * @param {HTMLElement} element Element to start looking from.
     * @param {string} attribute Attribute to look for, e.g. id for `data-wd-id`.
     * @param {number} [maxIterations=3] Maximum number of parents to look up.
     * @returns {(string | null)} The attribute value or null if not specified.
     * @memberof Utils
     */
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public getWdAttribute(element: HTMLElement, attribute: string, maxIterations: number = 3): string | null {
        const fullAttribute = 'data-wd-' + attribute;
        if (maxIterations === 0 || element.getAttribute(fullAttribute)) {
            return element.getAttribute(fullAttribute);
        }
        const parent = Utils.parentsUntil(element, (element) => !!element.getAttribute(fullAttribute), maxIterations);
        if (parent.getAttribute(fullAttribute)) {
            return parent.getAttribute(fullAttribute);
        }
        return null;
    }

    /**
     * Makes an array distinctive.
     *
     * @param {any[]} array The array.
     * @returns {any[]} The distinctive array.
     * @memberof Utils
     */
    public makeArrayDistinctive(array: any[]): any[] {
        return array.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }

    /**
     * Compare two version strings separated by fullstops if they are larger, smaller or equal.
     *
     * @param {string} versionOne First version to check.
     * @param {string} versionTwo Second version to check.
     * @param {boolean} [isFourPointVersion] Whether the version has four points or not.
     * @returns {number} Returns either 0 if both versions are equal, a negative integer if versionOne < versionTwo or a positive integer if versionOne > versionTwo. NaN if no version was found.
     * @memberof Utils
     */
    public compareVersionString(versionOne: string, versionTwo: string, isFourPointVersion?: boolean): number {
        if (Utils.instance.isNullOrEmptyString(versionOne) && Utils.instance.isNullOrEmptyString(versionTwo)) {
            return NaN;
        } else if (Utils.instance.isNullOrEmptyString(versionOne)) {
            return -1;
        } else if (Utils.instance.isNullOrEmptyString(versionTwo)) {
            return 1;
        }

        let semanticVersionRegEx = '^((([0-9]+)\\.([0-9]+)\\.([0-9]+)(?:-([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?)(?:\\+([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?)$';
        if (isFourPointVersion) {
            semanticVersionRegEx = '^((([0-9]+)\\.([0-9]+)\\.([0-9]+)\\.([0-9]+)(?:-([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?)(?:\\+([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?)$';
        }
        const semanticVersionRegExp = new RegExp(semanticVersionRegEx);
        if (!semanticVersionRegExp.test(versionOne) || !semanticVersionRegExp.test(versionTwo)) {
            return NaN;
        }
        let hyphenPartVersionOne: string | undefined = undefined;
        let hyphenPartVersionTwo: string | undefined = undefined;
        if (versionOne.indexOf('-') >= 0) {
            hyphenPartVersionOne = versionOne.slice(versionOne.indexOf('-'));
            versionOne = versionOne.substring(0, versionOne.indexOf('-'));
        }
        if (versionTwo.indexOf('-') >= 0) {
            hyphenPartVersionTwo = versionTwo.slice(versionTwo.indexOf('-'));
            versionTwo = versionTwo.substring(0, versionTwo.indexOf('-'));
        }
        const versionOneParts = versionOne.split('.');
        const versionTwoParts = versionTwo.split('.');
        for (let i = 0; i < versionOneParts.length; ++i) {
            if (versionTwoParts.length === i) {
                return 1;
            }
            if (versionOneParts[i] === versionTwoParts[i]) {
                continue;

            } else if (Number.parseInt(versionOneParts[i], 10) > Number.parseInt(versionTwoParts[i], 10)) {
                return 1;
            } else {
                return -1;
            }
        }
        if (hyphenPartVersionOne && !hyphenPartVersionTwo) {
            return -1;
        }
        if (!hyphenPartVersionOne && hyphenPartVersionTwo) {
            return 1;
        }
        if (hyphenPartVersionOne && hyphenPartVersionTwo) {
            if (hyphenPartVersionOne === hyphenPartVersionTwo) {
                return 0;
            } else if (hyphenPartVersionOne > hyphenPartVersionTwo) {
                return 1;
            } else {
                return -1;
            }
        }
        if (versionOneParts.length !== versionTwoParts.length) {
            return -1;
        }
        return 0;
    }

    /**
     * Returns the first parent element of the given element for which `checkFunction` returns true.
     * Will have a max amount of `maxIterations` if defined.
     * Will stop traversing up when reaching the body element.
     *
     * @param {HTMLElement} element
     * @param {(currentParent: HTMLElement) => boolean} checkFunction
     * @param {number} [maxIterations]
     * @returns {HTMLElement}
     *
     * @memberof Utils
     */
    public parentsUntil(element: HTMLElement, checkFunction: (currentParent: HTMLElement) => boolean, maxIterations?: number): HTMLElement {
        let parent: HTMLElement = element;
        let i = 0;
        while (parent !== null && parent !== document.body && ((maxIterations && maxIterations > i) || !maxIterations) && !checkFunction(parent)) {
            if (parent.parentElement && parent !== document.body) {
                parent = parent.parentElement;
            } else {
                break;
            }
            i++;
        }
        return parent;
    }

    /**
     * Moves one element to a given position.
     * See http://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another.
     * 
     * @param {any[]} array Array to operate on.
     * @param {number} oldIndex Old index of the element.
     * @param {number} newIndex New index of the element.
     * @returns {void}
     * 
     * @memberof Utils
     */
    public moveArrayElement(array: any[], oldIndex: number, newIndex: number): void {
        if (newIndex >= array.length) {
            let k = newIndex - array.length;
            while ((k--) + 1) {
                array.push(undefined);
            }
        }
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    }

    /**
     * Parses an array of given identities and finds out if it either 
     * contains the same entities (1 | 2) or mixed (0)
     *
     * @param {WindreamIdentity[]} identities
     * @returns {WindreamEntity}
     * @memberof Utils
     */
    public getIdentityTypes(identities: WindreamIdentity[]): WindreamEntity {
        const entityTypes = new Array<WindreamEntity>();
        let definedEntity;
        if (!this.isDefined(identities)) {
            throw new Error('No or invalid identities given');
        }
        for (const item of identities) {
            if (this.isDefined(item.entity)) {
                // Check if entity is valid
                if (item.entity === WindreamEntity.Folder || item.entity === WindreamEntity.Document) {
                    entityTypes.push(item.entity);
                } else {
                    throw new Error('Invalid entity given');
                }
            }
        }
        if (entityTypes.length > 0) {
            definedEntity = entityTypes[0];
        }
        if (this.isDefined(definedEntity)) {
            for (const item of entityTypes) {
                if (item !== definedEntity) {
                    return WindreamEntity.DocumentAndMap;
                }
            }
            return definedEntity;
        } else {
            throw new Error('No or invalid identities given');
        }
    }

    /**
     * Gets the amount of dragged files.
     *
     * @param {DragEvent} dragEvent
     * @returns {number}
     * @memberof Utils
     */
    public getDragFileCount(dragEvent: DragEvent): number {
        if (!dragEvent || !dragEvent.dataTransfer || !dragEvent.dataTransfer.items || dragEvent.dataTransfer.items.length === 0) {
            return 0;
        }

        return dragEvent.dataTransfer.items.length;
    }

    /**
     * Determines whether the drag event only contain files.
     *
     * @param {DragEvent} dragEvent
     * @returns {boolean}
     * @memberof Utils
     */
    public isFileDrag(dragEvent: DragEvent): boolean {
        if (!dragEvent || !dragEvent.dataTransfer || !dragEvent.dataTransfer.items || dragEvent.dataTransfer.items.length === 0) {
            return false;
        }

        const fileCount = this.getDragFileCount(dragEvent);
        for (let i = 0; i < fileCount; i++) {
            const item = dragEvent.dataTransfer.items[i];
            if (!item) {
                continue;
            }

            if (item.kind !== 'file') {
                return false;
            }
        }

        return true;
    }

    /**
     * Determines whether the given array contains the given value.
     *
     * @param {any[]} array
     * @param {*} value
     * @returns {boolean}
     * @memberof Utils
     */
    public contains(array: any[], value: any): boolean {
        if (!this.isDefined(array) || !this.isArray(array)) {
            return false;
        }

        return this.findIndex(array, value) !== -1;
    }

    /**
     * Returns the index of the first found element within the array, that is equal to the given value.
     * If the value can not be found within the array, -1 will be returned.
     *
     * @param {any[]} array
     * @param {*} value
     * @returns {number}
     * @memberof Utils
     */
    public findIndex(array: any[], value: any): number {
        if (!this.isDefined(array) || !this.isArray(array)) {
            return -1;
        }

        return array.findIndex((tempValue) => this.isDeepEqual(tempValue, value));
    }


    /**
     * Create a debounced version of the given function.
     * Use like this:
     * Create function: const myFunction = () => alert('Hello world');
     * Use Utils.debounce: const debounced = debounce(() => myFunction());
     * Now use debounce e.g. in a resize-listener.
     * 
     * @param {() => void} func Function that should be debounced.
     * @param {number} timeout Timeout for the debounce.
     * @returns {() => void} The debounced function.
     * @memberof Utils
     */
    public debounce(func: () => void, timeout: number): () => void {
        let timer: any;
        return (...args: any[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    /**
     * Get all files from a data transfer.
     *
     * @param {(DragEvent | InputEvent)} event The event with a data transfer.
     * @returns {(Promise<(IFileWithPath | DataTransferItem)[]>)} A promise, which will resolve with the files.
     * @memberof Utils
     */
    public static async getFilesFromDataTransfer(event: DragEvent | InputEvent): Promise<(IFileWithPath | DataTransferItem)[]> {
        return Utils.Instance.getFilesFromDataTransfer(event);
    }

    /**
     * Get all files from a data transfer.
     *
     * @param {(DragEvent | InputEvent)} event The event with a data transfer.
     * @returns {(Promise<(IFileWithPath | DataTransferItem)[]>)} A promise, which will resolve with the files.
     * @memberof Utils
     */
    public async getFilesFromDataTransfer(event: DragEvent | InputEvent): Promise<(IFileWithPath | DataTransferItem)[]> {
        return fromEvent(event);
    }

    /**
     * Gets the base path of the specified component.
     *
     * @static
     * @param {string} component The component.
     * @returns {string} The base path of the specified component.
     * @memberof Utils
     */
    public static getComponentBasePath(component: string): string {
        return Utils.Instance.getComponentBasePath(component);
    }

    /**
     * Gets the base path of the specified component.
     *
     * @param {string} component The component.
     * @returns {string} The base path of the specified component.
     * @memberof Utils
     */
    public getComponentBasePath(component: string): string {
        return `${this.getComponentsBasePath(component)}/${component}`;
    }

    /**
     * Gets the components base path.
     *
     * @static
     * @param {string} component The component.
     * @returns {string} The components base path.
     * @memberof Utils
     */
    public static getComponentsBasePath(component: string): string {
        return Utils.Instance.getComponentsBasePath(component);
    }

    /**
     * Gets the components base path.
     *
     * @param {string} component The component.
     * @returns {string} The components base path.
     * @memberof Utils
     */
    public getComponentsBasePath(component: string): string {
        let componentsBasePath = './components';

        if (DynamicWorkspace.Extensions &&
            DynamicWorkspace.Extensions.core &&
            DynamicWorkspace.Extensions.core.componentProvider && DynamicWorkspace.Extensions.core.componentProvider.canHandle(component)) {

            componentsBasePath = DynamicWorkspace.Extensions.core.componentProvider.getComponentsBasePath();
        }

        return componentsBasePath;
    }

    /**
     * Determines whether the given type is a known file drop type.
     *
     * @private
     * @param {string} fileType The file type.
     * @returns {boolean} A value that determines whether the given type is a known file drop type.
     * @memberof Utils
     */
    private isKnownFileDropType(fileType: string): boolean {
        return fileType === 'Files' || // Chrome, IE, Edge
            fileType === 'public.file-url' || // Safari
            fileType === 'application/x-moz-file' || // Mozilla
            fileType === 'text/plain';
    }

    /**
     * Determines whether the given identity is valid.
     *
     * @private
     * @param {WindreamIdentity} identity The identity to validate.
     * @returns {boolean}
     * @memberof Utils
     */
    public static isValidWindreamIdentity(identity: WindreamIdentity): boolean {
        return Utils.Instance.isValidWindreamIdentity(identity);
    }

    /**
     * Determines whether the given identity is valid.
     *
     * @private
     * @param {WindreamIdentity} identity The identity to validate.
     * @returns {boolean}
     * @memberof Utils
     */
    public isValidWindreamIdentity(identity: WindreamIdentity): boolean {
        return identity && ((identity.id && identity.id > 0) || (identity.getLocationComplete() !== ''));
    }

    /**
     * Check the value is number and is not a null, undefined, NaN and infinity. 
     *
     * @param {number | undefined | null} value The used type.
     * @returns {boolean} Whether it is a valid number.
     * @memberof Utils
     */
    public isValidNumber(num: number | undefined | null): boolean {
        return num !== null && num !== undefined && typeof num === 'number' && Number.isFinite(num) && !Number.isNaN(num);
    }

    /**
     * Rounds the given value.
     *
     * @param {number} value The value to round.
     * @param {number} [decimals] Defines how many decimal places should be used.
     * @returns {number}
     * @memberof Utils
     */
    public round(value: number, decimals?: number): number {
        let multiplier = 1.0;
        if (decimals && this.isValidNumber(decimals) && decimals > 0) {
            multiplier = Math.pow(10, decimals);
        }
        return Math.round(value * multiplier) / multiplier;
    }

    /**
     * Rounds the given value considering the value type.
     *
     * @param {number} value The value to round.
     * @param {ValueTypes} valueType The type of the given value.
     * @returns {number}
     * @memberof Utils
     */
    public roundByValueType(value: number, valueType: ValueTypes): number {
        let decimals = undefined;

        if (valueType === ValueTypes.Currency) {
            decimals = 2;
        }

        return this.round(value, decimals);
    }
}