import { MappingEntry } from '../../../../typings/core';
import { Utils } from '../../common';
import { IColumn } from '../../shared';
import { IConfigMapping, IMappingHandler } from '../interfaces';

/**
 * Mapping handler to apply mapping to a columns configuration.
 * Replaces the object type ID.
 *
 * @export
 * @class ColumnsMappingHandler
 * @implements {IMappingHandler<IColumn | IColumn[], MappingEntry>}
 */
export class ColumnsMappingHandler implements IMappingHandler<IColumn | IColumn[], MappingEntry> {
    /**
     * Data types of configurations this handler can map.
     *
     * @memberof ColumnsMappingHandler
     */
    private readonly dataTypes = ['DATATYPE_WINDREAM_COLUMNS', 'DATATYPE_WINDREAM_COLUMN', 'DATATYPE_WINDREAM_NUMBER_COLUMN', 'DATATYPE_JSON'];


    /**
     * Checks whether the mapping for the given configuration data type can be handled.
     *
     * @param {string} configDataType Data type of the configuration to map.
     * @param {string} mappingDataType Data type of the mapping to use.
     * @returns {boolean} Whether the configuration can be mapped by this handler.
     * @memberof SubstitutionMappingHandler
     */
    public canHandleMapping(configDataType: string, mappingDataType: string): boolean {
        return this.dataTypes.indexOf(configDataType) !== -1 && ['DATATYPE_WINDREAM_COLUMN'].indexOf(mappingDataType) !== -1;
    }

    /**
     * Applies the mapping to the given input.
     *
     * @param {IColumn | IColumn[]} input Configuration value to map.
     * @param {IConfigMapping<MappingEntry>} mapping Mapping informaton to use.
     * @returns {IColumn | IColumn[]} The transformed value.
     * @memberof ColumnsMappingHandler
     */
    public applyMapping(input: IColumn | IColumn[], mapping: IConfigMapping<MappingEntry>): IColumn | IColumn[] {
        if (mapping.dataType === 'DATATYPE_WINDREAM_COLUMN' && Utils.Instance.isDefined(mapping.currentValue) && Utils.Instance.isDefined(mapping.newValue)) {
            if (Utils.Instance.isArray(input)) {
                return input.map((column) => {
                    if (!Utils.Instance.isDefined(mapping.currentValue) || !Utils.Instance.isDefined(mapping.newValue)) {
                        return column;
                    }
                    return this.applyMappingForIndexName(column, mapping.currentValue, mapping.newValue);
                });
            } else {
                return this.applyMappingForIndexName(input, mapping.currentValue, mapping.newValue);
            }
        }
        return input;
    }

    /**
     * Replaces the given object type name with the new object type name within the given search query.
     *
     * @private
     * @param {IColumn} column Column to replace the object type.
     * @param {MappingEntry} oldValue The old object type name.
     * @param {MappingEntry} newValue The new object type name.
     * @returns {IColumn} The column with the adjusted object type value.
     * @memberof ColumnsMappingHandler
     */
    private applyMappingForIndexName(column: IColumn, oldValue: MappingEntry, newValue: MappingEntry): IColumn {
        // Complex indices
        if (newValue.techName) {
            const convertedNewValue = typeof newValue.value === 'number' ? newValue.value : Number.parseInt(newValue.value, 10);
            const convertedOldValue = typeof oldValue.value === 'number' ? oldValue.value : Number.parseInt(oldValue.value, 10);
            if (column.name === oldValue.techName && column.objectTypeId === convertedOldValue) {
                column.name = newValue.techName;
                column.objectTypeId = convertedNewValue;
                // Number based indexes and invalid indexes
            } else if (column.name === oldValue.techName) {
                column.name = newValue.techName;
            }
        }
        return column;
    }
}