import { ValidationPatternTypes } from 'typings/ui';


/**
 * Contains validation help utilities.
 *
 * @export
 * @class ValidationHelper
 */
export class ValidationHelper {

    /**
     * Gets the validation pattern for a pattern type.
     *
     * @static
     * @param {ValidationPatternTypes} type The type.
     * @returns {RegExp} A RegExp, containing the validation pattern.
     * @memberof ValidationHelper
     */
    public static getValidationPattern(type: ValidationPatternTypes): RegExp {
        switch (type) {
            case ValidationPatternTypes.NONE:
                return new RegExp('^$', 'i');
            case ValidationPatternTypes.LIVESEARCH:
                return new RegExp('^[^"\\\\]*$', 'i');
        }
    }
}