import { ComponentToolbarHandler } from 'framework/src/toolbar';
import { ComponentInstanceConfig } from '../../config';
import { IComponentLifecycleManager } from '../../lifecycle';
import { MigrationComponentContainer } from '../../migration';
import { IComponent } from '../interfaces';


/**
 * This interfaces describes a bundle of a component together with it's config and the related lifecycle manager.
 * 
 * @export
 * @class ComponentContainer
 */
export class ComponentContainer {

    /**
     * Component inside the container.
     * 
     * @type {IComponent}
     * @memberof ComponentContainer
     */
    public component: IComponent;
    /**
     * Configuration of this component instance.
     * 
     * @type {ComponentInstanceConfig}
     * @memberof ComponentContainer
     */
    public componentInstanceConfig: ComponentInstanceConfig;

    /**
     * Lifecycle manager associate with the component.
     * 
     * @type {IComponentLifecycleManager}
     * @memberof ComponentContainer
     */
    public componentLifecycleManager: IComponentLifecycleManager;

    /**
     * The migration component container.
     *
     * @type {MigrationComponentContainer}
     * @memberof ComponentContainer
     */
    public container: MigrationComponentContainer;

    /**
     * The component toolbar handler.
     *
     * @type {ComponentToolbarHandler}
     * @memberof ComponentContainer
     */
    public componentToolbarHandler: ComponentToolbarHandler;

    /**
     * Creates an instance of ComponentContainer.
     * 
     * @param {MigrationComponentContainer} container The container.
     * @param {ComponentInstanceConfig} componentInstanceConfig The component instance config.
     * @param {IComponentLifecycleManager} componentLifecycleManager The component lifecycle manager.
     * @param {ComponentToolbarHandler} componentToolbarHandler The component toolbar handler.
     * @memberof ComponentContainer
     */
    public constructor(container: MigrationComponentContainer, componentInstanceConfig: ComponentInstanceConfig,
        componentLifecycleManager: IComponentLifecycleManager, componentToolbarHandler: ComponentToolbarHandler) {
        this.container = container;
        this.component = container.component;
        this.componentInstanceConfig = componentInstanceConfig;
        this.componentLifecycleManager = componentLifecycleManager;
        this.componentToolbarHandler = componentToolbarHandler;
    }

    /**
     * Updates the Component Instance Config associated with the component nested in this container.
     * 
     * @param {ComponentInstanceConfig} componentInstanceConfig
     * 
     * @memberof ComponentContainer
     */
    public updateConfig(componentInstanceConfig: ComponentInstanceConfig): void {
        this.componentInstanceConfig = componentInstanceConfig;
    }

}