import { PublicApi } from '../../../framework/src/dynamicWorkspace';
import { DeviceDetection, Utils } from '../common';
import { ViewConfigMetaData } from '../config';
import { ITemplateExtension } from '../extensions';
import { ILanguageProvider } from '../language';
import { ISubViewManager } from '../loader';
import { AboutRouter, IViewNavigationHandler, IViewNavigationHandlerFactory, RootRouter, RouteManager, SettingsRouter, ViewRouter } from '../router';
import { IStorage } from '../storage';
import { IThemeManager } from '../themeManager';
import { IUiComponentFactory } from './components';
import {
    IActiveBarHelperFactory, IAppBarHandler, IAppMenuHandler, IAppMenuHandlerFactory, IMenuBarHandler,
    IMenuBarHandlerFactory, ISubViewNavigationHandler, ISubViewNavigationHandlerFactory
} from './interfaces';
import { AppBarViewModel, AppMenuItem } from './models';
import { NavigationPanelHandler } from './navigationPanelHandler';

/**
 * Class to handle app bar.
 * 
 * @export
 * @class AppBarHandler
 */
export class AppBarHandler implements IAppBarHandler {
    /**
     * Returns the instance of the IViewNavigationHandler.
     * 
     * @readonly
     * @type {IViewNavigationHandler | undefined}
     * @memberof AppBarHandler
     */
    public get viewNavigationHandler(): IViewNavigationHandler | undefined {
        return this._viewNavigationHandler;
    }
    /**
     * Returns the instance of the ISubViewNavigationHandler.
     * 
     * @readonly
     * @type {ISubViewNavigationHandler | undefined}
     * @memberof AppBarHandler
     */
    public get subViewNavigationHandler(): ISubViewNavigationHandler | undefined {
        return this._subViewNavigationHandler;
    }
    /**
     * Returns the instance of the IMenuBarHandler.
     * 
     * @readonly
     * @type {IMenuBarHandler | undefined}
     * @memberof AppBarHandler
     */
    public get menuBarHandler(): IMenuBarHandler | undefined {
        return this._menuBarHandler;
    }
    /**
     * Callback to execute when edit mode is toggled.
     * Returns a Promise to resolve with whether edit mode was left or not.
     * 
     * @memberof AppBarHandler
     */
    public onToggleEdit?: (isEnabled: boolean) => Promise<boolean>;
    /**
     * Callback to execute when save button is clicked.
     * Returns a Promise to resolve after saving.
     * 
     * @memberof AppBarHandler
     */
    public onSave?: () => Promise<boolean>;

    private element: HTMLElement;
    private publicApi: PublicApi;
    private templateExtension: ITemplateExtension;
    private activeBarHelperFactory: IActiveBarHelperFactory;
    private appMenuHandlerFactory: IAppMenuHandlerFactory;
    private menuBarHandlerFactory: IMenuBarHandlerFactory;
    private languageProvider: ILanguageProvider;
    private currentTheme: IThemeManager;
    private subViewManager: ISubViewManager;
    private viewNavigationHandlerFactory?: IViewNavigationHandlerFactory;
    private subViewNavigationHandlerFactory?: ISubViewNavigationHandlerFactory;
    private currentlyInEditMode: boolean;
    private saveInProgress = false;

    private viewBar: HTMLElement | null;
    private editBar: HTMLElement | null;
    private navigationRootElement: HTMLElement;
    private _viewNavigationHandler?: IViewNavigationHandler;
    private _subViewNavigationHandler?: ISubViewNavigationHandler;
    private _menuBarHandler?: IMenuBarHandler;
    private viewModel: AppBarViewModel;
    private eventListener?: (e: Event) => void;
    private canSave: boolean;
    private saveAndEndEditMenuItem?: AppMenuItem;
    private endEditMenuItem?: AppMenuItem;
    private editAppMenuHandler?: IAppMenuHandler;
    private homeButtonRouteListener: (event: MouseEvent) => void;
    private navigationPanelHandler: NavigationPanelHandler;
    private isHeaderLess = false;
    private localStorage: IStorage;


    /**
     * Creates an instance of AppBarHandler.
     * @param {HTMLElement} element
     * @param {PublicApi} publicApi
     * @param {ITemplateExtension} templateExtension
     * @param {IActiveBarHelperFactory} activeBarHelperFactory
     * @param {IAppMenuHandlerFactory} appMenuHandlerFactory
     * @param {IMenuBarHandlerFactory} menuBarHandlerFactory
     * @param {ILanguageProvider} languageProvider
     * @param {IThemeManager} customTheme
     * @param {ISubViewManager} subViewManager
     * @param {HTMLElement} navigationRootElement
     * @param {boolean} isHeaderLess
     * @param {ViewRouter} viewRouter
     * @param {RootRouter} rootRouter
     * @param {AboutRouter} aboutRouter
     * @param {SettingsRouter} settingsRouter
     * @param {IStorage} localStorage
     * @param {UiComponentFactory} uiComponentFactory
     * @memberof AppBarHandler
     */
    // eslint-disable-next-line max-len
    public constructor(element: HTMLElement, publicApi: PublicApi, templateExtension: ITemplateExtension, activeBarHelperFactory: IActiveBarHelperFactory, appMenuHandlerFactory: IAppMenuHandlerFactory,
        menuBarHandlerFactory: IMenuBarHandlerFactory, languageProvider: ILanguageProvider,
        customTheme: IThemeManager, subViewManager: ISubViewManager, navigationRootElement: HTMLElement,
        isHeaderLess: boolean, viewRouter: ViewRouter, rootRouter: RootRouter, aboutRouter: AboutRouter, settingsRouter: SettingsRouter, localStorage: IStorage,
        uiComponentFactory: IUiComponentFactory) {
        this.element = element;
        this.publicApi = publicApi;
        this.templateExtension = templateExtension;
        this.activeBarHelperFactory = activeBarHelperFactory;
        this.appMenuHandlerFactory = appMenuHandlerFactory;
        this.menuBarHandlerFactory = menuBarHandlerFactory;
        this.languageProvider = languageProvider;
        this.currentTheme = customTheme;
        this.subViewManager = subViewManager;
        this.viewBar = null;
        this.editBar = null;
        this.navigationRootElement = navigationRootElement;
        this.localStorage = localStorage;
        this.navigationPanelHandler = new NavigationPanelHandler(window, navigationRootElement, languageProvider, viewRouter, rootRouter, aboutRouter, settingsRouter,
            this.currentTheme, this.templateExtension, this.localStorage, uiComponentFactory);
        this.navigationPanelHandler.onSearchViews = (searchTerm) => {
            this.viewNavigationHandler?.searchViews(searchTerm);
        };

        this.viewModel = new AppBarViewModel('/');
        this.subViewManager.onSubViewChanged(() => {
            this.viewModel.canGoBack = this.subViewManager.hasNavigationHistory();
            this.templateExtension.render(this.element, require('./templates/appBar.html'), this.viewModel, true);
            this.attachCustomRoutingListener();
            // Make button a11y
            const navigateBackButton = this.element.querySelector<HTMLButtonElement>('[data-wd-type="navigateBack"]');
            if (navigateBackButton) {
                navigateBackButton.title = this.languageProvider.get('framework.generic.back');
            }
        });
        this.canSave = false;
        this.currentlyInEditMode = false;
        this.isHeaderLess = isHeaderLess;
        this.homeButtonRouteListener = (event) => {
            event.preventDefault();
            RouteManager.navigate('/');
        };
    }

    /**
     * Sets the IViewNavigationHandlerFactory instance.
     * 
     * @param {IViewNavigationHandlerFactory} factory Instance to use.
     * @memberof AppBarHandler
     */
    public setViewNavigationHandlerFactory(factory: IViewNavigationHandlerFactory): void {
        this.viewNavigationHandlerFactory = factory;
    }

    /**
     * Sets the ISubViewNavigationHandlerFactory instance.
     * 
     * @param {ISubViewNavigationHandlerFactory} factory Instance to use.
     * @memberof AppBarHandler
     */
    public setSubViewNavigationHandlerFactory(factory: ISubViewNavigationHandlerFactory): void {
        this.subViewNavigationHandlerFactory = factory;
    }

    /**
     * Renders the app bar.
     * 
     * @param {boolean} [isLoggedIn=false] Whether the user is logged in.
     * @memberof AppBarHandler
     */
    public render(isLoggedIn: boolean = false): void {
        const currentConfig = this.currentTheme.getTheme();

        this.viewModel.isLoggedIn = isLoggedIn;
        this.viewModel.customConfig = currentConfig;
        this.viewModel.canGoBack = false;
        this.viewModel.isInsideApp = DeviceDetection.isApp();
        this.viewModel.goBackText = this.languageProvider.get('framework.header.goHome');

        this.templateExtension.render(this.element, require('./templates/appBar.html'), this.viewModel, true);
        this.attachCustomRoutingListener();
        this.viewBar = this.element.querySelector('#wd-app-bar-view');
        this.editBar = this.element.querySelector('#wd-app-bar-edit');
        if (this.eventListener) {
            this.element.removeEventListener('click', this.eventListener);
        }

        this.element.addEventListener('click', this.eventListener = (e: Event) => {
            const type = Utils.Instance.getWdAttribute(e.target as HTMLElement, 'type');
            if (type === 'leaveEditMode' && this.currentlyInEditMode) {
                this.leaveEditMode();
            } else if (type === 'navigateBack') {
                this.subViewManager.goBack();
            } else if (type === 'save') {
                if (this.canSave && !this.saveInProgress) {
                    this.save();
                }
            }
        });
        this.setUserInitials();
    }

    /**
     * Save the current view config.
     *
     * @private
     * @returns {Promise<void>} Whether the saving was a success.
     * @memberof AppBarHandler
     */
    private save(): Promise<void> {
        if (this.onSave && this.canSave && !this.saveInProgress) {
            this.saveInProgress = true;
            const saveButton = this.editBar?.querySelector<HTMLButtonElement>('.wd-save-button-container button');
            if (this.editBar) {
                if (saveButton) {
                    saveButton.disabled = true;
                }
                if (this.saveAndEndEditMenuItem && this.endEditMenuItem && this.editAppMenuHandler) {
                    this.saveAndEndEditMenuItem.isDisabled = true;
                    this.endEditMenuItem.isDisabled = true;
                    this.editAppMenuHandler.render();
                }
            }
            return this.onSave().then(() => {
                this.saveInProgress = false;
                if (this.saveAndEndEditMenuItem && this.endEditMenuItem && this.editAppMenuHandler) {
                    this.saveAndEndEditMenuItem.isDisabled = true;
                    this.endEditMenuItem.isDisabled = false;
                    this.editAppMenuHandler.render();
                }
            }).catch((err: Error) => {
                this.saveInProgress = false;
                if (this.saveAndEndEditMenuItem && this.endEditMenuItem && this.editAppMenuHandler) {
                    this.saveAndEndEditMenuItem.isDisabled = false;
                    this.endEditMenuItem.isDisabled = false;
                    if (saveButton) {
                        saveButton.disabled = false; // In order to automatically re-enable it once everything is valid, we need to remove this. 
                    }
                    this.editAppMenuHandler.render();
                }
                throw err;
            });
        } else {
            return Promise.reject(new Error('Can not save right now!'));
        }
    }
    /**
     * Handles logout of the current user.
     * 
     * @memberof AppBarHandler
     */
    public handleLogout(): void {
        this.viewNavigationHandler?.clear();
        this.navigationPanelHandler.hideNavigation();
        if (this._menuBarHandler) {
            this._menuBarHandler.destroy();
        }
        this.render();
    }

    /**
     * Sets the given view name in the view model.
     * Updates the view to reflect the changes.
     * 
     * @param {string} viewName View name to set.
     * @memberof AppBarHandler
     */
    public updateCurrentViewName(viewName: string): void {
        this.viewModel.currentViewName = viewName;
        this.render(this.viewModel.isLoggedIn);
    }

    /**
     * Updates whether the current view has pending changes.
     *
     * @param {boolean} hasChanges Whether the view has changes.
     * @memberof AppBarHandler
     */
    public currentViewHasChanges(hasChanges: boolean): void {
        this.viewModel.hasViewPendingChanges = hasChanges;
        if (this.saveAndEndEditMenuItem && this.editAppMenuHandler) {
            this.saveAndEndEditMenuItem.isDisabled = !hasChanges;
            this.editAppMenuHandler.render();
        }
        this.render(this.viewModel.isLoggedIn);
        this.canSave = hasChanges;
    }

    /**
     * Set the user Initials.
     *
     * @memberof AppBarHandler
     */
    public setUserInitials(): void {
        // Get user image HTML element
        const initialsElement = document.querySelector('.wd-profile-user-image');
        // Check, if imageElement is not null
        if (initialsElement) {
            // Get default initials from translations
            const defaultInitials = this.languageProvider.get('framework.userAccount.defaultInitials');
            // Get initials from current user
            const userInitials = this.publicApi.Authentication.getCurrentUser()?.getInitials(defaultInitials);
            // Check if initials are set
            if (userInitials) {
                initialsElement.textContent = userInitials;
            } else {
                initialsElement.textContent = defaultInitials;
            }
        }
    }

    /**
     * Handles login of the current user.
     * 
     * @param {boolean} isAdmin Whether the current user is an administrator.
     * @param {ViewConfigMetaData[]} viewConfigs Views to render navigation for.
     * @memberof AppBarHandler
     */
    public handleLogin(isAdmin: boolean, viewConfigs: ViewConfigMetaData[]): void {
        this.render(true);
        const viewNavigationContainer = this.navigationRootElement.querySelector<HTMLElement>('#wd-view-navigation');
        if (viewNavigationContainer && this.viewNavigationHandlerFactory) {
            this._viewNavigationHandler = this.viewNavigationHandlerFactory.create(viewNavigationContainer);
            this.viewNavigationHandler?.renderNavigation(viewConfigs).then(() => this.showNavigation()).catch((err: Error) => {
                throw err;
            });
        } else {
            throw new Error('No container with ID `wd-view-navigation` found in AppBar.');
        }

        if (this.editBar) {
            const editAppMenuContainer = this.editBar.querySelector<HTMLElement>('.wd-edit-app-menu-container');
            if (editAppMenuContainer) {
                const editAppMenuHandler = this.appMenuHandlerFactory.create(editAppMenuContainer);
                // TODO Remove save and pubsub from here if left side bar is implemented
                const saveAndEndEditMenuItem = new AppMenuItem(this.languageProvider.get('framework.header.buttongroup.saveAndEndEdit.title'));
                saveAndEndEditMenuItem.title = this.languageProvider.get('framework.header.buttongroup.saveAndEndEdit.tooltip');
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                saveAndEndEditMenuItem.position = 91; // Disable rule because of temporary solution
                saveAndEndEditMenuItem.onClick = () => {
                    this.save().then(() => {
                        this.toggleEdit(false);
                    }).catch((err) => {
                        throw err;
                    });
                };
                saveAndEndEditMenuItem.isDisabled = true; // Disabled first because no changes are present
                saveAndEndEditMenuItem.cssClass = 'wd-divider-top';
                const endEditingMenuItem = new AppMenuItem(this.languageProvider.get('framework.header.buttongroup.endEdit.title'));
                endEditingMenuItem.title = this.languageProvider.get('framework.header.buttongroup.endEdit.tooltip');
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                endEditingMenuItem.position = 93; // Disable rule because of temporary solution
                endEditingMenuItem.onClick = () => {
                    this.toggleEdit(false);
                };
                endEditingMenuItem.cssClass = 'wd-divider-top';
                this.endEditMenuItem = endEditingMenuItem;
                editAppMenuHandler.addMenuItems([saveAndEndEditMenuItem, endEditingMenuItem]);
                const subViewNavigationContainer = this.editBar.querySelector<HTMLElement>('#wd-sub-view-navigation');
                if (subViewNavigationContainer && this.subViewNavigationHandlerFactory) {
                    this._subViewNavigationHandler = this.subViewNavigationHandlerFactory.create(subViewNavigationContainer, editAppMenuHandler);
                    this._subViewNavigationHandler.render();
                    this.activeBarHelperFactory.create(subViewNavigationContainer);
                } else {
                    throw new Error('No container with ID `wd-sub-view-navigation` found in AppBar.');
                }
                editAppMenuHandler.render();
                this.editAppMenuHandler = editAppMenuHandler;
                this.saveAndEndEditMenuItem = saveAndEndEditMenuItem;
            } else {
                throw new Error('No container with class `wd-edit-app-menu-container` found in AppBar.');
            }
            const saveButtonText = this.editBar.querySelector<HTMLElement>('.wd-save-button-container button .text');
            if (saveButtonText) {
                saveButtonText.textContent = this.languageProvider.get('framework.generic.save');
            } else {
                throw new Error('No button inside container with class `wd-save-button-container` found in AppBar.');
            }
        } else {
            throw new Error('No container with ID `wd-app-bar-edit` found in AppBar.');
        }

        if (this.viewBar) {
            const viewAppMenuContainer = this.viewBar.querySelector<HTMLElement>('#wd-menu-bar-container');
            if (viewAppMenuContainer) {
                const viewAppMenuHandler = this.appMenuHandlerFactory.create(viewAppMenuContainer);

                this._menuBarHandler = this.menuBarHandlerFactory.create(viewAppMenuContainer, viewAppMenuHandler);
                this._menuBarHandler.init(isAdmin);
                this._menuBarHandler.onEnableEdit = () => {
                    this.toggleEdit(true);
                };
                this._menuBarHandler.onDisableEdit = async () => {
                    this.toggleEdit(false);
                    return Promise.resolve(true);
                };
                viewAppMenuHandler.render();
            } else {
                throw new Error('No container with ID `wd-menu-bar-container` found in AppBar.');
            }

            const headerTextColor = <string>this.currentTheme.getTheme().headerTextColor;
            const headerBackgroundColor = <string>this.currentTheme.getTheme().headerBackgroundColor;
            const navigationBackgroundColor = this.currentTheme.getTheme().navigationBackgroundColor;
            if (navigationBackgroundColor) {
                this.navigationRootElement.style.backgroundColor = navigationBackgroundColor;
            }
            const navigationTextcolor = this.currentTheme.getTheme().navigationTextColor;
            if (navigationTextcolor) {
                this.navigationRootElement.style.color = navigationTextcolor;
            }

            // Apply text color to more views dropdown icon. 
            const moreViewsButtonIconElement = this.viewBar.querySelector<HTMLElement>('.wd-icon.more');
            if (moreViewsButtonIconElement && headerTextColor) {
                moreViewsButtonIconElement.style.color = headerTextColor;
            }

            // Apply text color to edit dropdown icon. 
            const editMenuButtonIconElement = this.viewBar.querySelector<HTMLElement>('.wd-icon.more.wd-rotate-90');
            if (editMenuButtonIconElement && headerTextColor) {
                editMenuButtonIconElement.style.color = headerTextColor;
            }

            // Apply background color to more views dropdown menu.
            const navigationItemsListElement = this.viewBar.querySelector<HTMLElement>('#wd-navigation-item-more-dropdown');
            if (navigationItemsListElement &&
                this.currentTheme &&
                this.currentTheme.getDefaultConfig() &&
                headerBackgroundColor &&
                headerBackgroundColor !== this.currentTheme.getDefaultConfig().headerBackgroundColor) {
                navigationItemsListElement.style.backgroundColor = headerBackgroundColor;
                // Apply text color to all more views dropdown items.
                const childElements = navigationItemsListElement.querySelectorAll<HTMLElement>('*');
                if (childElements && childElements.length > 0) {
                    childElements.forEach((childElement) => {
                        childElement.style.color = headerTextColor;
                    });
                }
            }
        } else {
            throw new Error('No container with ID `wd-app-bar-view` found in AppBar.');
        }

        // @ts-ignore - Disabled because of Foundation usage
        if (typeof window['$'] !== 'undefined') {
            // @ts-ignore - Disabled because of Foundation usage
            $(this.element).foundation();
        }
    }

    /**
     * Clears the container element.
     * 
     * @memberof AppBarHandler
     */
    public clear(): void {
        this.viewNavigationHandler?.clear();
        if (this.subViewNavigationHandler) {
            this.subViewNavigationHandler.clear();
        }
        this.element.innerHTML = '';
    }

    /**
     * Toggles the edit mode base on the parameter given.
     * 
     * @param {boolean} isEnabled New edit mode state.
     * @returns {void} Return 'enterEditMode' or 'leaveEditMode' Method.
     * @memberof AppBarHandler
     */
    public toggleEdit(isEnabled: boolean): void {
        if (isEnabled) {
            return this.enterEditMode();
        } else {
            return this.leaveEditMode();
        }
    }

    /**
     * Shows or hides the menu for editing the current view.
     *
     * @param {boolean} isEditPossible
     * @memberof AppBarHandler
     */
    public toggleEditPossible(isEditPossible: boolean): void {
        if (this.viewBar) {
            const viewAppMenuContainer = this.viewBar.querySelector<HTMLElement>('#wd-menu-bar-container');
            if (viewAppMenuContainer) {
                viewAppMenuContainer.hidden = !isEditPossible;
            }
        }
    }

    /**
     * Toggles the visibility of the user app menu.
     *
     * @param {boolean} isVisible Whether to display the user app menu or not.
     * @memberof AppBarHandler
     */
    public toggleUserAppMenuContainerVisibility(isVisible: boolean): void {
        if (this.viewBar) {
            const userAppMenuContainer = this.viewBar.querySelector<HTMLElement>('#wd-user-bar');
            if (userAppMenuContainer) {
                userAppMenuContainer.hidden = !isVisible;
            }
        }
    }

    /**
     * Enters the edit mode.
     * Will show the edit app bar.
     * Will execute the onToggleEdit() callback with `true` if defined.
     *
     * @returns {Promise<void>}
     * @memberof AppBarHandler
     */
    public async enterEditModeAsync(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.currentlyInEditMode) {
                resolve();
                return;
            }
            this.navigationPanelHandler.hideNavigation();
            if (this.viewBar) {
                this.viewBar.setAttribute('aria-hidden', 'true');
                this.viewBar.hidden = true;
            }
            if (this.editBar) {
                // Execute callback delayed to make sure the edit bar is at least not display: hidden; anymore
                const callbackDelay = 10;
                setTimeout(() => {
                    if (this.onToggleEdit) {
                        this.onToggleEdit(true).then(() => {
                            this.currentlyInEditMode = true;
                            resolve();
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    }
                }, callbackDelay);
                // @ts-ignore - Ignored because of Foundation usage
                window['Foundation'].Motion.animateIn($(this.editBar), 'slide-in-down');
            }
        });
    }

    /**
     * Leaves the edit mode.
     * Will hide the edit app bar.
     * Will execute the onToggleEdit() callback with `false` if defined.
     *
     * @returns {Promise<void>}
     * @memberof AppBarHandler
     */
    public async leaveEditModeAsync(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.currentlyInEditMode) {
                resolve();
                return;
            }
            let leaveEditModePromise = Promise.resolve(true);
            if (this.onToggleEdit) {
                leaveEditModePromise = this.onToggleEdit(false);
            }
            leaveEditModePromise.then((leaveEditMode: boolean) => {
                if (leaveEditMode) {
                    if (this.viewBar) {
                        this.viewBar.removeAttribute('aria-hidden');
                        this.viewBar.hidden = false;
                    }
                    if (this.editBar) {
                        // @ts-ignore - Ignored because of Foundation usage
                        window['Foundation'].Motion.animateOut($(this.editBar), 'slide-out-up');
                    }
                    this.currentlyInEditMode = false;
                    this.showNavigation();
                    resolve();
                } else {
                    reject(new Error('Failed to leave edit mode'));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }
    /**
     * Leaves the edit mode.
     * Will hide the edit app bar.
     * Will execute the onToggleEdit() callback with `false` if defined.
     * 
     * @private
     * @memberof AppBarHandler
     */
    private leaveEditMode(): void {
        let leaveEditModePromise = Promise.resolve(true);
        if (this.onToggleEdit) {
            leaveEditModePromise = this.onToggleEdit(false);
        }
        leaveEditModePromise.then((leaveEditMode: boolean) => {
            if (leaveEditMode) {
                if (this.viewBar) {
                    this.viewBar.removeAttribute('aria-hidden');
                    this.viewBar.hidden = false;
                }
                if (this.editBar) {
                    // @ts-ignore - Ignored because of Foundation usage
                    window['Foundation'].Motion.animateOut($(this.editBar), 'slide-out-up');
                }
                this.currentlyInEditMode = false;
                this.showNavigation();
            }
        }).catch((err: Error) => {
            throw err;
        });
    }

    /**
     * Enters the edit mode.
     * Will show the edit app bar.
     * Will execute the onToggleEdit() callback with `true` if defined.
     * 
     * @private
     * @memberof AppBarHandler
     */
    private enterEditMode(): void {
        if (this.viewBar) {
            this.viewBar.setAttribute('aria-hidden', 'true');
            this.viewBar.hidden = true;
        }

        this.navigationPanelHandler.hideNavigation();
        if (this.editBar) {

            // Execute callback delayed to make sure the edit bar is at least not display: hidden; anymore
            const callbackDelay = 10;
            setTimeout(() => {
                if (this.onToggleEdit) {
                    this.onToggleEdit(true).then(() => {
                        this.currentlyInEditMode = true;
                    }).catch((err: Error) => {
                        throw err;
                    });
                }
            }, callbackDelay);
            // @ts-ignore - Ignored because of Foundation usage
            window['Foundation'].Motion.animateIn($(this.editBar), 'slide-in-down');
        }
    }

    /**
     * Attaches custom routing to specific links.
     *
     * @private
     * @memberof AppBarHandler
     */
    private attachCustomRoutingListener(): void {
        const homeButton = this.element.querySelector<HTMLAnchorElement>('.wd-app-bar-left-action-zone a');
        if (homeButton) {
            // TODO: Call render only once and not thrice.
            // Detach previous event listener if it was already attached
            homeButton.removeEventListener('click', this.homeButtonRouteListener);
            homeButton.addEventListener('click', this.homeButtonRouteListener);
        }
    }

    private showNavigation(): void {
        if (!this.isHeaderLess) {
            this.navigationPanelHandler.showNavigation();
        }
    }
}