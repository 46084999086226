import { DirectoryResponseDTO } from 'framework/typings/windreamWebService/Windream.WebService.Directories';
import { WindreamIdentityDetails as IWindreamIdentityDetails } from '../../../../typings/core';
import { DocumentResponseDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { ObjectType, WindreamAttribute } from '../../caching';
import { Utils } from '../utils';
import { WindreamEntity } from '../windreamEntity';
import { WindreamIdentity } from '../windreamIdentity';

export class WindreamIdentityDetails extends WindreamIdentity implements IWindreamIdentityDetails {
    public attributes: WindreamAttribute[];
    public objectType?: ObjectType;
    public parentWindreamObject?: WindreamIdentity;

    /**
     * Creates an instance of WindreamIdentityDetails.
     *
     * @memberof WindreamIdentityDetails
     */
    public constructor() {
        super();
        this.attributes = new Array<WindreamAttribute>();
    }

    /**
     * Returns a clone of this object.
     * 
     * @returns {WindreamIdentityDetails} Cloned object.
     * 
     * @memberof WindreamIdentityDetails
     */
    public clone(): WindreamIdentityDetails {
        const clone = new WindreamIdentityDetails();
        clone.entity = this.entity;
        clone.id = this.id;
        clone.location = this.location;
        clone.name = this.name;
        clone.attributes = Utils.Instance.deepClone(this.attributes);
        if (this.parentWindreamObject) {
            clone.parentWindreamObject = this.parentWindreamObject.clone();
        }
        if (this.objectType) {
            clone.objectType = Utils.Instance.deepClone(this.objectType);
        }
        return clone;
    }

    /**
     * Creates an instance based on the DTO.
     *
     * @static
     * @param {DocumentResponseDTO} dto DTO to create from.
     * @returns {WindreamIdentityDetails}
     * @memberof WindreamIdentityDetails
     */
    public static fromDto(dto: DocumentResponseDTO | DirectoryResponseDTO): WindreamIdentityDetails {

        const result = new WindreamIdentityDetails();
        result.id = dto.Id;
        result.name = dto.Name;
        result.location = dto.Location;
        result.entity = dto.Entity as number;

        if (dto.Attributes) {
            const attributes = dto.Attributes.map((attr) => WindreamAttribute.fromDto(attr));
            result.attributes = attributes;

        }
        if (dto.ObjectType) {
            const objectType = new ObjectType(dto.ObjectType.Id, dto.ObjectType.Name, WindreamEntity.Folder);
            result.objectType = objectType;
        }

        if (dto.ParentWindreamObject) {
            const parent = new WindreamIdentity();
            parent.entity = WindreamEntity.Folder;
            parent.id = dto.ParentWindreamObject.Id;
            parent.name = dto.ParentWindreamObject.Name;
            parent.location = dto.ParentWindreamObject.Location;
            result.parentWindreamObject = parent;
        }
        return result;
    }
}