import { WebBridgeDataTypes, WebBridgeEventTypes } from '.';

/**
 * The reponse model.
 * 
 * @export
 * @class WebBridgeReponseContainerModel
 */
export class WebBridgeReponseContainerModel {
    /**
     * The used eventtype.
     * 
     * @type {WebBridgeEventTypes}
     * @memberof WebBridgeReponseContainerModel
     */
    public eventType: WebBridgeEventTypes;
    /**
     * The used datatype.
     * 
     * @type {WebBridgeDataTypes}
     * @memberof WebBridgeReponseContainerModel
     */
    public dataType: WebBridgeDataTypes;
    /**
     * The reponse value.
     * 
     * @type {string}
     * @memberof WebBridgeReponseContainerModel
     */
    public value: string;
    /**
     * Creates an instance of WebBridgeReponseContainerModel.
     * @param {WebBridgeEventTypes} eventType 
     * @param {WebBridgeDataTypes} dataType 
     * @param {string} value 
     * @memberof WebBridgeReponseContainerModel
     */
    constructor(eventType: WebBridgeEventTypes, dataType: WebBridgeDataTypes, value: string) {
        this.eventType = eventType;
        this.dataType = dataType;
        this.value = value;
    }
}