import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Request options for a use case.
 *
 * @export
 * @class UseCaseRequestOptions
 * @extends {BaseRequestOptions}
 */
export class UseCaseRequestOptions extends BaseRequestOptions {

    /**
     * The usecase id.
     *
     * @type {string}
     * @memberof UseCaseRequestOptions
     */
    public useCaseId: string;

    /**
     * Creates an instance of UseCaseRequestOptions.
     * @param {string} useCaseId
     * @memberof UseCaseRequestOptions
     */
    public constructor(useCaseId: string) {
        super();
        if (typeof useCaseId !== 'string') {
            throw new Error('Argument \'useCaseId\' is not valid');
        }
        this.useCaseId = useCaseId;
    }
}