import { IResourcePointer, RequestOptions } from '../dataProviders';
import { IServiceRequest } from './interfaces/iServiceRequest';


/**
 * This class represents a request.
 * 
 * @export
 * @class ServiceRequest
 * @implements {IServiceRequest}
 */
export class ServiceRequest implements IServiceRequest {

    /**
     * The raw request.
     * 
     * @type {XMLHttpRequest}
     * @memberof ServiceRequest
     */
    public rawRequest: XMLHttpRequest;


    /**
     * The resource pointer.
     * 
     * @type {IResourcePointer}
     * @memberof ServiceRequest
     */
    public resourcePointer: IResourcePointer;


    /**
     * The request options.
     * 
     * @type {RequestOptions}
     * @memberof ServiceRequest
     */
    public options?: RequestOptions;


    /**
     * Creates an instance of Request.
     *
     * @param {XMLHttpRequest} rawRequest 
     * @param {IResourcePointer} resourcePointer 
     * @memberof ServiceRequest
     */
    public constructor(rawRequest: XMLHttpRequest, resourcePointer: IResourcePointer) {
        if (!rawRequest) {
            throw new ReferenceError('The argument \"rawRequest\" is null or undefined.');
        } else if (!resourcePointer) {
            throw new ReferenceError('The argument \"resourcePointer\" is null or undefined.');
        }

        this.rawRequest = rawRequest;
        this.resourcePointer = resourcePointer;
    }

}