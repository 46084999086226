import { Utils } from '../../../src/dynamicWorkspace';
import { CustomConfig } from '../../config/models/customConfig';

/**
 * View model of the AppBar.
 * 
 * @export
 * @class AppBarViewModel
 */
export class AppBarViewModel {
    /**
     * Name of the currently active view.
     * 
     * @type {string}
     * @memberof AppBarViewModel
     */
    public currentViewName?: string;
    /**
     * WHether the current user can edit the current view.
     *
     * @type {boolean}
     * @memberof AppBarViewModel
     */
    public canEditView: boolean = false;
    /**
     * Whether a user is currently logged in.
     * 
     * @type {boolean}
     * @memberof AppBarViewModel
     */
    public isLoggedIn: boolean = false;

    /**
     * The custom config.
     *
     * @type {CustomConfig}
     * @memberof AppBarViewModel
     */
    public customConfig?: CustomConfig;

    /**
     * Whether the app bar is inside the native app or not.
     *
     * @type {boolean}
     * @memberof AppBarViewModel
     */
    public isInsideApp: boolean = false;

    /**
     * Whether the app bar is rendered for mobile devices.
     *
     * @type {boolean}
     * @memberof AppBarViewModel
     */
    public isMobile: boolean = false;

    /**
     * Whether the appbar can go back or not.
     *
     * @type {boolean}
     * @memberof AppBarViewModel
     */
    public canGoBack: boolean = false;

    /**
     * Text that will be shown when the user hovers the top bar icon.
     *
     * @type {string}
     * @memberof AppBarViewModel
     */
    public goBackText?: string;

    /**
     * Path to the root/start page.
     * 
     * @type {string}
     * @memberof AppBarViewModel
     */
    public rootPath: string;

    /**
     * Whether the view has pending changes which should be indicated.
     *
     * @type {boolean}
     * @memberof AppBarViewModel
     */
    public hasViewPendingChanges: boolean = false;

    /**
     * Creates an instance of AppBarViewModel.
     *
     * @param {string} rootPath
     * @memberof AppBarViewModel
     */
    public constructor(rootPath: string) {
        this.rootPath = rootPath;
        this.isMobile = Utils.Instance.deviceDetection.isMobile();
    }
}