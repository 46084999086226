/**
 * This class represents view initialization options.
 *
 * @export
 * @class ViewInitializationOptions
 */
export class ViewInitializationOptions {

    /**
     * Determines whether the lifecycle steps should be promoted for the view.
     *
     * @type {boolean}
     * @memberof ViewInitializationOptions
     */
    public promoteLifecycleSteps: boolean;


    /**
     * Creates an instance of ViewInitializationOptions.
     *
     * @memberof ViewInitializationOptions
     */
    public constructor() {
        this.promoteLifecycleSteps = false;
    }

}