import { IConfigTranslation } from '../config';
import { Logger } from '../logging/logger';
import { ILanguageProvider, IMultilingualTranslator } from './interfaces';

/**
 * This class is used by every class, which needs translated strings.
 * Each LanguageProvider contains a specific translationObject.
 * @version 1.0
 * @exports LanguageProvider
 */
export class LanguageProvider implements ILanguageProvider {

    private translationObject: Object | null;
    private currentLanguage: string;
    private logger: Logger;
    private className: string = 'LanguageProvider';
    private translator: IMultilingualTranslator;

    private missingTranslations: string[];

    /**
     * Creates an instance of LanguageProvider.
     *
     * @param {(Object | null)} translation The translation as Object.
     * @param {string} currentLanguage The currently used language.
     * @param {Logger} logger The logger.
     * @param {IMultilingualTranslator} translator The translator
     * @param {JQueryStatic} jQueryStatic Static JQuery
     * @param {Object} [customTranslation] The customTranslation which will be merged with the translation.
     * @param {Object} [frameWorkTranslation] The frameworkTranslation which will be merged with the translation.
     * @memberof LanguageProvider
     */
    public constructor(translation: Object | null, currentLanguage: string, logger: Logger, translator: IMultilingualTranslator, jQueryStatic: JQueryStatic,
        customTranslation?: Object, frameWorkTranslation?: Object) {
        this.translationObject = {};
        if (frameWorkTranslation && !customTranslation) {
            jQueryStatic.extend(true, this.translationObject, frameWorkTranslation, translation);
        } else if (!frameWorkTranslation && customTranslation) {
            jQueryStatic.extend(true, this.translationObject, translation, customTranslation);
        } else if (frameWorkTranslation && customTranslation) {
            jQueryStatic.extend(true, this.translationObject, frameWorkTranslation, translation, customTranslation);
        } else {
            this.translationObject = translation;
        }
        this.currentLanguage = currentLanguage;
        this.logger = logger;
        this.translator = translator;
        this.missingTranslations = new Array<string>();
    }

    /**
     * Sets the string value for a specific key.
     *
     * @param {string} key The key.
     * @param {string} value The value.
     * @memberof LanguageProvider
     */
    public set(key: string, value: string): void {
        const keys = key.split('.');

        let insideObject: any = this.translationObject;
        for (let i = 0; i < keys.length; i++) {

            if (insideObject[keys[i]]) {
                if (i + 1 === keys.length) {
                    insideObject[keys[i]] = value;
                } else {
                    if (typeof (insideObject) === 'object') {
                        insideObject = insideObject[keys[i]];
                    }
                }
            } else {
                if (i + 1 === keys.length) {
                    insideObject[keys[i]] = value;
                } else {
                    insideObject[keys[i]] = {};
                    insideObject = insideObject[keys[i]];
                }
            }
        }
    }

    /**
     * Gets the translated string for an specific key, If no translation was found and no default string was defined, the key will be returned.
     * @param {string} key The key which shall be translated.
     * @param {string} defaultValue The default value that should be returned if no translation was found.
     * @returns {string} The translation.
     * @access public
     */
    public get(key: string, defaultValue?: string): string {
        let result;
        if (defaultValue) {
            result = defaultValue;
        } else {
            result = '#' + key + '#';
        }
        const methodName: string = 'get';
        if (!this.translationObject) {
            this.logger.info(this.className, methodName, `The translationObject is not set, returning key '${key}'`);
            return result;
        }
        if (!key || typeof (key) !== 'string') {
            this.logger.info(this.className, methodName, `The key '${key}' is not valid`);
            return result;
        }
        const keys = key.split('.');
        let insideObject: any = this.translationObject;
        for (let i = 0; i < keys.length; i++) {
            if (insideObject[keys[i]]) {
                if (i + 1 === keys.length) {
                    result = insideObject[keys[i]].toString();
                } else {
                    if (typeof (insideObject) === 'object') {
                        insideObject = insideObject[keys[i]];
                    } else {
                        this.logger.info(this.className, methodName, 'Error inside the translation file for ' + this.currentLanguage);
                    }
                }
            } else {
                if (this.missingTranslations.indexOf(key) === -1) { // Only log once for a missing key
                    this.logger.info(this.className, methodName, `Can't find the key '${key}' inside the translation object`);
                    this.missingTranslations.push(key);
                }
                return result;
            }
        }
        return result;
    }

    /**
     * Gets a string and formats it with additional parameters.
     * 
     * @param {string} key The translation key.
     * @param {...string[]} placeHolders The place holder values.
     * @returns The formatted string.
     * @memberof LanguageProvider
     */
    public getWithFormat(key: string, ...placeHolders: string[]) {
        const stringToFormat = this.get(key);
        if (placeHolders) {
            return stringToFormat.replace(/{(\d+)}/g, (match, formatIndex) => {
                return typeof placeHolders[formatIndex] !== 'undefined'
                    ? placeHolders[formatIndex]
                    : match;
            });
        }
        return stringToFormat;
    }

    /**
     * Gets the translation string or undefined from a config translation.
     *
     * @param {IConfigTranslation} property
     * @returns {string}
     * @memberof LanguageProvider
     */
    public getTranslationFromProperty(property: IConfigTranslation<string>): string {
        return this.translator.getTranslationFromProperty<string>(property);
    }
}