import { ObjectTypeFlagsDTO, ObjectTypeResponseContainerDTO, ObjectTypeRightsDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { ObjectType } from '../../dynamicWorkspace';

/**
 * Converts between the object type web service DTO model and the domain model.
 *
 * @export
 * @class ObjectTypeModelConverter
 */
export class ObjectTypeModelConverter {

    /**
     * Converts from the DTO model into the domain model.
     *
     * @param {ObjectTypeResponseContainerDTO} dto The object type dto.
     * @returns {ObjectType} The converted object type.
     * @memberof ObjectTypeModelConverter
     */
    public convertFromDTO(dto: ObjectTypeResponseContainerDTO): ObjectType {

        const result = new ObjectType(dto.Item.Id, dto.Item.Name, dto.Item.ObjectType.Entity);

        result.displayName = dto.Item.DisplayName;

        // Determine whether the object type is assignable.
        // Check it via flags if object type is read only.
        result.isAssignable = (dto.Item.Flags & ObjectTypeFlagsDTO.ReadOnly) !== ObjectTypeFlagsDTO.ReadOnly;

        // Check it via flags if object type is an internal object type (system type).
        if (result.isAssignable) {
            result.isAssignable = (dto.Item.Flags & ObjectTypeFlagsDTO.SystemType) !== ObjectTypeFlagsDTO.SystemType;
        }

        // If it is still true, check it also via rights.
        if (result.isAssignable && dto.Item.Rights && dto.Item.Rights.length > 0) {
            result.isAssignable = (dto.Item.Rights[0].Rights & ObjectTypeRightsDTO.Assign) === ObjectTypeRightsDTO.Assign;
        }

        // Determine whether the object type is searchable.
        result.isSearchable = true;
        if (dto.Item.Rights && dto.Item.Rights.length > 0) {
            result.isSearchable = (dto.Item.Rights[0].Rights & ObjectTypeRightsDTO.Search) === ObjectTypeRightsDTO.Search;
        }

        return result;
    }

}