import { ToolbarAction, ToolbarDataset } from '../../../../typings/core';
import { ILanguageProvider } from '../../language';
import { IUiComponentFactory } from '../../ui/components';
/**
 * The toolbar error action.
 *
 * @export
 * @class ToolbarErrorAction
 * @implements {ToolbarAction}
 */
export class ToolbarErrorAction implements ToolbarAction {

    private languageProvider: ILanguageProvider;
    private uiComponentFactory: IUiComponentFactory;

    /**
     * Creates an instance of ToolbarErrorAction.
     * 
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {IUiComponentFactory} uiComponentFactory The ui component factory.
     * @memberof ToolbarErrorAction
     */
     public constructor(languageProvider: ILanguageProvider, uiComponentFactory: IUiComponentFactory) {
        this.languageProvider = languageProvider;
        this.uiComponentFactory = uiComponentFactory;
    }

    /**
     * Sets the dataset.
     *
     * @param {ToolbarDataset} _dataset The dataset.
     * @returns {void}
     * @memberof ToolbarErrorAction
     */
    public setDataset(_dataset: ToolbarDataset): void {
        return;
    }

    /**
     * Render the error toolbar.
     *
     * @param {HTMLDivElement} targetElement The target element.
     * @memberof ToolbarErrorAction
     */
    public render(targetElement: HTMLDivElement): void {
        const dxButton = this.uiComponentFactory.button(targetElement);
        dxButton.bootstrap();
        dxButton.setOptions({
            icon: 'wd-icon frown',
            isDisabled: true, // Initially disabled
            stylingMode: 'text',
            text: this.languageProvider.get('framework.toolbar.error.failedToLoadComponent.title'),
            hint: this.languageProvider.get('framework.toolbar.error.failedToLoadComponent.hint')
        });
    }

    /**
     * Destroy the toolbar.
     *
     * @returns {void}
     * @memberof ToolbarErrorAction
     */
    public destroy(): void {
        return;
    }
}