import { MemberRequestDTO, ResponseContainerDTO } from '../../../../../framework/typings/windreamWebService/Windream.WebService';
import { ViewConfigResponseDTO, ViewConfigsResponseContainerDTO } from '../../../../../framework/typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { DynamicWorkspace } from '../../../../../typings';
import { MemberTypes } from '../../../../../typings/core';
import { ViewConfigMetaData } from '../../../dynamicWorkspace';
import { ServiceResponse, WebRequest } from '../../.:/../../../../typings/services';
import { RightsModel } from './models/rightsModel';

/**
 * Provides web service requests for the view system setting section.
 *
 * @export
 * @class ViewSystemSettingsWebServiceRequests
 */
export class ViewSystemSettingsWebServiceRequests {

    private publicApi: DynamicWorkspace;
    private readonly className = 'ViewSystemSettingsWebServiceRequests';

    /**
     * Creates an instance of ViewSystemSettingsWebServiceRequests.
     * @param {DynamicWorkspace} publicApi The public api.
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public constructor(publicApi: DynamicWorkspace) {
        this.publicApi = publicApi;
    }

    /**
     * Loads the default admin group.
     *
     * @returns {Promise<RightsModel>} The default admin group.
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public loadDefaultAdminGroup(): Promise<RightsModel> {
        return new Promise<RightsModel>((resolve, reject) => {
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/security/GetDynamicWorkspaceAdminsGroupIdentifier';
            const request: WebRequest = { body: null, method: 'GET' };
            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<any>) => {
                if (response && response.data && !response.data.HasErrors) {
                    const adminGroup = {
                        id: response.data.Group.Id,
                        name: response.data.Group.Name,
                        memberType: MemberTypes.group,
                        domain: response.data.Group.Domain,
                        canAdministrate: true,
                        canRead: true
                    } as RightsModel;
                    resolve(adminGroup);
                } else {
                    this.publicApi.Logger.error(this.className, 'loadDefaultAdminGroup', 'Failed to load default DW admin group');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'loadDefaultAdminGroup', 'Failed to load default DW admin group', error);
                reject();
            });
        });
    }

    /**
     * Loads all available views
     *
     * @returns {Promise<ViewConfigMetaData[]>} All available views.
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public loadAvailableViews(): Promise<ViewConfigMetaData[]> {
        return new Promise<ViewConfigMetaData[]>((resolve, reject) => {
            const viewConfigs = new Array<ViewConfigMetaData>();
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/LoadAllViewConfigs';
            const request: WebRequest = { body: null, method: 'GET', preventFetchFromCache: true };

            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<ViewConfigsResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    response.data.ConfigData.forEach((viewConfig: ViewConfigResponseDTO) => {
                        viewConfigs.push(this.getViewConfigMetadata(viewConfig));
                    });
                    resolve(viewConfigs);
                } else {
                    this.publicApi.Logger.error(this.className, 'loadAvailableViews', 'Failed to load views');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'loadAvailableViews', 'Failed to load views', error);
                reject();
            });
        });
    }


    /**
     * Gets the view config meta data from a view config response dto.
     * 
     * @param {ViewConfigResponseDTO} viewConfig The view config response dto.
     * @returns {ViewConfigMetaData} The view config meta data.     * 
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    private getViewConfigMetadata(viewConfig: ViewConfigResponseDTO): ViewConfigMetaData {
        const viewMetadata = new ViewConfigMetaData(viewConfig.Id);
        if (viewConfig.Alias !== null) {
            viewMetadata.alias = viewConfig.Alias;
        }
        viewMetadata.description = viewConfig.Description;
        viewMetadata.icon = viewConfig.Icon;
        viewMetadata.name = viewConfig.Name;
        viewMetadata.enabledDevices = viewConfig.EnabledDevices;
        return viewMetadata;
    }


    /**
     * Creates a view.
     *
     * @param {string} viewName The view name.
     * @param {boolean} showInNavigation Whether to show it in navigation.
     * @param {(string | null)} viewDescription The view description.
     * @param {(string | null)} viewAlias The view alias.
     * @param {(string | null)} selectedTemplate The selected template
     * @param {number} enabledDevices The devices.
     * @param {RightsModel[]} rights The rights
     * @returns {Promise<void>}
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public createView(viewName: string, showInNavigation: boolean, viewDescription: string | null,
        viewAlias: string | null, selectedTemplate: string | null, enabledDevices: number, rights: RightsModel[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const endpoint = selectedTemplate ? 'CreateViewFromTemplate' : 'CreateView';
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/' + endpoint;
            const currentLanguage = this.publicApi.Language.current;
            const adminMembers = new Array<MemberRequestDTO>();
            const normalMembers = new Array<MemberRequestDTO>();
            rights.forEach((right) => {
                if (right.canAdministrate) {
                    adminMembers.push({
                        Id: right.id,
                        MemberType: right.memberType as number
                    });
                } else if (right.canRead) {
                    normalMembers.push({
                        Id: right.id,
                        MemberType: right.memberType as number
                    });
                }
            });
            const bodyObject = {
                Metadata: {
                    Name: {
                    },
                    EnabledDevices: enabledDevices
                },
                AdminMembers: adminMembers,
                Members: normalMembers,
                IsHiddenInNavigation: !!showInNavigation
            } as any;
            bodyObject.Metadata.Name[currentLanguage] = viewName;
            if (currentLanguage !== 'en') {
                bodyObject.Metadata.Name['en'] = viewName;
            }
            if (viewAlias) {
                bodyObject.Alias = {};
                bodyObject.Alias.id = viewAlias;
            }
            if (viewDescription) {
                bodyObject.Metadata.Description = {};
                bodyObject.Metadata.Description[currentLanguage] = viewDescription;
            }
            if (selectedTemplate) {
                bodyObject.TemplateIdentifier = {};
                bodyObject.TemplateIdentifier.Id = selectedTemplate;
            }
            const request: WebRequest = {
                body: bodyObject, method: 'POST'
            };

            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<ResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    resolve();
                } else {
                    this.publicApi.Logger.error(this.className, 'createView', 'Failed to create view');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'createView', 'Failed to create view', error);
                reject();
            });
        });

    }

    /**
     * Loads the rights for a view.
     *
     * @param {ViewConfigMetaData} [selectedView] The view.
     * @returns {(Promise<RightsModel[] | null>)} The rights.
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public loadRightsForView(selectedView?: ViewConfigMetaData): Promise<RightsModel[] | null> {
        return new Promise<RightsModel[] | null>((resolve, reject) => {
            if (!selectedView) {
                resolve(null);
                return;
            }
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/GetAssignedViewMembers?parameter.identifier.id=' + selectedView.id;
            const request: WebRequest = { body: null, method: 'GET' };
            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<any>) => {
                if (response && response.data && !response.data.HasErrors) {
                    const foundRights = new Array<RightsModel>();
                    response.data.AssignedMembers.AdminMembers.forEach((rightBearer: any) => {
                        const foundUserOrGroup = {
                            id: rightBearer.Id,
                            name: rightBearer.Name,
                            memberType: rightBearer.MemberType,
                            domain: rightBearer.Domain,
                            canAdministrate: true,
                            canRead: true
                        } as RightsModel;
                        foundRights.push(foundUserOrGroup);
                    });
                    response.data.AssignedMembers.Members.forEach((rightBearer: any) => {
                        const foundUserOrGroup = {
                            id: rightBearer.Id,
                            name: rightBearer.Name,
                            memberType: rightBearer.MemberType,
                            domain: rightBearer.Domain,
                            canRead: true,
                            canAdministrate: false
                        } as RightsModel;
                        const elementInAdminGroup = foundRights.find((rights) => rights.id === foundUserOrGroup.id);
                        if (!elementInAdminGroup) {
                            foundRights.push(foundUserOrGroup);
                        }
                    });
                    resolve(foundRights);
                } else {
                    this.publicApi.Logger.error(this.className, 'loadRightsForView', 'Failed to load view rights');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'loadRightsForView', 'Failed to load view rights', error);
                reject();
            });
        });
    }

    /**
     * Edit the view.
     *
     * @param {string} viewName The view name.
     * @param {boolean} showInNavigation Wehter to show in navigaiton.
     * @param {(string | null)} viewDescription The view description.
     * @param {(string | null)} viewAlias The view alias.
     * @param {number} enabledDevices The devices.
     * @param {RightsModel[]} rights The rights.
     * @param {ViewConfigMetaData} selectedView The selected views.
     * @returns {Promise<void>}
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public editView(viewName: string, showInNavigation: boolean, viewDescription: string | null,
        viewAlias: string | null, enabledDevices: number, rights: RightsModel[], selectedView: ViewConfigMetaData): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/UpdateViewMetadata';
            const currentLanguage = this.publicApi.Language.current;
            const bodyObject = {
                Metadata: {
                    Name: {
                    },
                    EnabledDevices: enabledDevices
                },
                IsHiddenInNavigation: !!showInNavigation,
                Identifier: {
                    Id: selectedView.id
                }
            } as any;
            bodyObject.Metadata.Name = selectedView.name;
            bodyObject.Metadata.Name[currentLanguage] = viewName;
            if (currentLanguage !== 'en' && !bodyObject.Metadata.Name['en']) {
                bodyObject.Metadata.Name['en'] = viewName;
            }
            if (viewAlias) {
                bodyObject.Alias = {};
                bodyObject.Alias.id = viewAlias;
            }
            bodyObject.Metadata.Description = {};
            if (selectedView.description) {
                bodyObject.Metadata.Description = selectedView.description;
            }
            if (viewDescription) {
                bodyObject.Metadata.Description[currentLanguage] = viewDescription;
            }
            const request: WebRequest = {
                body: bodyObject, method: 'POST'
            };

            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<ResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    this.updateViewRights(selectedView, rights).then(() => {
                        resolve();
                    }).catch((error) => reject(error));
                } else {
                    this.publicApi.Logger.error(this.className, 'editView', 'Failed to edit view');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'editView', 'Failed to edit view', error);
                reject();
            });
        });
    }

    /**
     * Deletes the view.
     *
     * @param {string} viewId The view id.
     * @returns {Promise<void>}
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public deleteView(viewId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/DeleteView';
            const request: WebRequest = {
                body: {
                    Identifier: {
                        Id: viewId
                    }
                }, method: 'POST'
            };

            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<ResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    resolve();
                } else {
                    this.publicApi.Logger.error(this.className, 'deleteView', 'Failed to delete view');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'deleteView', 'Failed to load delete view', error);
                reject();
            });
        });
    }

    /**
     * Updates the view rights.
     *
     * @private
     * @param {ViewConfigMetaData} selectedView The selected view.
     * @param {RightsModel[]} rights The rights to update.
     * @returns {Promise<void>}
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    private updateViewRights(selectedView: ViewConfigMetaData, rights: RightsModel[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/UpdateAssignedViewMembers';
            const adminMembers = new Array<MemberRequestDTO>();
            const normalMembers = new Array<MemberRequestDTO>();
            rights.forEach((right) => {
                if (right.canAdministrate) {
                    adminMembers.push({
                        Id: right.id,
                        MemberType: right.memberType as number
                    });
                } else if (right.canRead) {
                    normalMembers.push({
                        Id: right.id,
                        MemberType: right.memberType as number
                    });
                }
            });
            const bodyObject = {
                AssignedMembers: {
                    AdminMembers: adminMembers,
                    Members: normalMembers
                },
                Identifier: { Id: selectedView.id }
            } as any;
            const request: WebRequest = {
                body: bodyObject, method: 'POST'
            };

            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<ResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    resolve();
                } else {
                    this.publicApi.Logger.error(this.className, 'updateViewRights', 'Failed to update view');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'updateViewRights', 'Failed to update view', error);
                reject();
            });
        });
    }

    /**
     * Updates the view order.
     *
     * @param {ViewConfigMetaData[]} orderedViews The views in correct order.
     * @returns {Promise<void>}
     * @memberof ViewSystemSettingsWebServiceRequests
     */
    public updateViewOrder(orderedViews: ViewConfigMetaData[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const viewIds = orderedViews.map((view) => view.id);
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/UpdateViewsOrder';
            const request: WebRequest = {
                body: {
                    OrderedViewIDs: viewIds
                }, method: 'POST'
            };

            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<ResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    resolve();
                } else {
                    this.publicApi.Logger.error(this.className, 'updateViewOrder', 'Failed to update view order');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'updateViewOrder', 'Failed to update view order', error);
                reject();
            });
        });
    }
    /**
    * Gets all available templates.
    *
    * @private
    * @returns {Promise<ViewConfigMetaData[]>} The view templates.
    * @memberof ViewSystemSettingsWebServiceRequests
    */
    public loadAvailableTemplates(): Promise<ViewConfigMetaData[]> {
        return new Promise<ViewConfigMetaData[]>((resolve, reject) => {
            const remoteLocation: string = this.publicApi.Config.windreamWebServiceURL + '/dynamicworkspace/views/LoadAllTemplates?deviceFilter=7';
            const request: WebRequest = { body: null, method: 'GET' };

            this.publicApi.RequestExecutor.execute(remoteLocation, request).then((response: ServiceResponse<ViewConfigsResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    const availableTemplates = new Array<ViewConfigMetaData>();
                    response.data.ConfigData.forEach((viewConfig: ViewConfigResponseDTO) => {
                        availableTemplates.push(this.getViewConfigMetadata(viewConfig));
                    });
                    resolve(availableTemplates);
                } else {
                    this.publicApi.Logger.error(this.className, 'loadAvailableTemplates', 'Failed to load view templates');
                    reject();
                }
            }).catch((error) => {
                this.publicApi.Logger.error(this.className, 'loadAvailableTemplates', 'Failed to load view templates', error);
                reject();
            });
        });
    }

}