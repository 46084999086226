import { IOperation } from '../pipeline/iOperation';
import { PubSubDefinitions } from './pubSubDefinitions';
import { PubSubOperationParameter } from './pubSubOperationParameter';

/**
 * This operation handles the automatically added "SetEnabled" and "SetDisabled" PubSub inputs.
 *
 * @export
 * @class HandleEnableDisablePubSubOperation
 * @implements {IOperation<PubSubOperationParameter>}
 */
export class HandleEnableDisablePubSubOperation implements IOperation<PubSubOperationParameter> {

    /**
     * A map of all blocking source components that are related to a specific target component.
     *
     * @private
     * @type {Map<string, string[]>} <target component identifier, blocking source components[]>
     * @memberof HandleEnableDisablePubSubOperation
     */
    private blockingComponents: Map<string, string[]>;


    /**
     * Creates an instance of HandleEnableDisablePubSubOperation.
     *
     * @memberof HandleEnableDisablePubSubOperation
     */
    public constructor() {
        this.blockingComponents = new Map<string, string[]>();
    }


    /**
     * Invokes the operation.
     *
     * @param {PubSubOperationParameter} parameter The operation parameter.
     * @returns {boolean} Determines whether the operation has failed or succeeded.
     * @memberof HandleEnableDisablePubSubOperation
     */
    public invoke(parameter: PubSubOperationParameter): boolean {

        if (!parameter) {
            return false;
        }

        const pubSubEvent = parameter.in.name;
        if (pubSubEvent !== PubSubDefinitions.PUBSUB_INPUT_SET_DISABLED_NAME && pubSubEvent !== PubSubDefinitions.PUBSUB_INPUT_SET_ENABLED_NAME) {
            return true;
        }

        if (!this.blockingComponents.has(parameter.in.guid)) {
            this.blockingComponents.set(parameter.in.guid, []);
        }
        const tempArray = this.blockingComponents.get(parameter.in.guid);
        if (tempArray) {

            if (pubSubEvent === PubSubDefinitions.PUBSUB_INPUT_SET_DISABLED_NAME) {
                if (parameter.in.value) {
                    // Disable
                    if (tempArray.indexOf(parameter.out.guid) === -1) {
                        tempArray.push(parameter.out.guid);
                    }
                } else {
                    // Enable
                    const index = tempArray.indexOf(parameter.out.guid);
                    if (index > -1) {
                        tempArray.splice(index, 1);
                    }
                    // Check whether the target component can be enabled
                    parameter.in.value = tempArray.length > 0;
                }
            } else if (pubSubEvent === PubSubDefinitions.PUBSUB_INPUT_SET_ENABLED_NAME) {
                if (parameter.in.value) {
                    // Enable
                    const index = tempArray.indexOf(parameter.out.guid);
                    if (index > -1) {
                        tempArray.splice(index, 1);
                    }
                    // Check whether the target component can be enabled
                    parameter.in.value = tempArray.length === 0;
                } else {
                    // Disable
                    if (tempArray.indexOf(parameter.out.guid) === -1) {
                        tempArray.push(parameter.out.guid);
                    }
                }
            }
        }

        return true;
    }

}