import { ConflictCodes } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { WindreamIdentity } from '../../dynamicWorkspace';

/**
 * Represents a webSocket file conflict.
 *
 * @export
 * @class FileConflict
 */
export class FileConflict {

    /**
     * The identity.
     *
     * @type {WindreamIdentity}
     * @memberof FileConflict
     */
    public identity: WindreamIdentity;

    /**
     * The modified identity.
     *
     * @type {WindreamIdentity}
     * @memberof FileConflict
     */
    public modifiedIdentity?: WindreamIdentity;

    /**
     * The conflict code.
     *
     * @type {ConflictCodes}
     * @memberof FileConflict
     */
    public code: ConflictCodes;

    /**
     * Determines whether the conflict was already handled.
     *
     * @type {boolean}
     * @memberof FileConflict
     */
    public isHandled: boolean;

    /**
     * Creates an instance of FileConflict.
     *
     * @param {WindreamIdentity} identity The windream file identity.
     * @param {ConflictCodes} code The conflic code.
     * @memberof FileConflict
     */
    public constructor(identity: WindreamIdentity, code: ConflictCodes) {
        this.identity = identity;
        this.code = code;
        this.isHandled = false;
    }

}