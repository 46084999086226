import { LifecycleResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { LifecycleDetailsContainer } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * This ServiceAction will handle the GetLifecycleDetails call.
 * 
 * @export
 * @class GetLifecycleDetails
 * @extends {ServiceAction}
 */
export class GetLifecycleDetails extends ServiceAction {
    /**
     * Creates an instance of GetLifecycleDetails.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @memberof GetLifecycleDetails
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'getLifecycleDetails';
    }

    /**
     * Retrieve lifecycle data from the webservice.
     *
     * @param {WindreamRequestOptions} windreamRequestOptions The request options.
     * @returns {Promise<LifecycleDetailsContainer>} A promise, which will resolve with the server response.
     * @memberof GetLifecycleDetails
     */
    public async do(windreamRequestOptions: WindreamRequestOptions): Promise<LifecycleDetailsContainer> {
        return new Promise<LifecycleDetailsContainer>((resolve, reject) => {
            let url = `${this.globalConfig.windreamWebServiceURL}/documents/GetLifecycleDetails`;
            if (windreamRequestOptions.identity.id) {
                url += '?parameter.item.id=' + windreamRequestOptions.identity.id;
            } else if (windreamRequestOptions.identity.location && windreamRequestOptions.identity.name) {
                url += '?parameter.item.location=' + windreamRequestOptions.identity.location +
                    '&parameter.item.name=' + windreamRequestOptions.identity.name;
            }
            const resourcePointer = new HttpResourcePointer(
                'GET',
                url
            );
            this.requestExecutor.executeRequest(resourcePointer, windreamRequestOptions.requestOptions)
                .then((response: IServiceResponse<LifecycleResponseContainerDTO>) => {
                    if (response.data?.HasErrors || !response.data) {
                        this.logger.error('GetLifecycleDetails', 'do', 'Failed to get lifecycle details', response);
                        reject(new Error('Failed to get lifecycle details'));
                    } else {
                        resolve(new LifecycleDetailsContainer(response.data));
                    }
                }).catch((err: Error) => {
                    this.logger.error('GetLifecycleDetails', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}