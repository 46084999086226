import { FileConflict } from './fileConflict';

/**
 * Represents an upload preflight response.
 *
 * @export
 * @class UploadPreflightResponse
 */
export class UploadPreflightResponse {

    /**
     * The conflicts.
     *
     * @type {FileConflict[]}
     * @memberof UploadPreflightResponse
     */
    public conflicts: FileConflict[];


    /**
     * Creates an instance of UploadPreflightResponse.
     *
     * @memberof UploadPreflightResponse
     */
    public constructor() {
        this.conflicts = [];
    }

}