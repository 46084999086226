import { AuthenticationModes } from '../authentication';

/**
 * This class represents request options.
 * 
 * @export
 * @class RequestOptions
 */
export class RequestOptions {

    /**
     * Determines whether the cache should be prevented during the request.
     * 
     * @type {boolean}
     * @memberof RequestOptions
     */
    public preventFetchFromCache?: boolean;

    /**
     * The entity Tag.
     * 
     * @type {string}
     * @memberof CacheData
     */
    public wdTag?: string;

    /**
     * Authentication mode to use.
     *
     * @type {AuthenticationModes}
     * @memberof RequestOptions
     */
    public authenticationMode?: AuthenticationModes;
}