import { ISubViewManager } from '../loader';
import { Logger } from '../logging/logger';
import { TriggerHandler } from './triggerHandler';

/**
 * The InstantTransitionTriggerHandler to handle direct transistions.
 * 
 * @export
 * @class InstantTransitionTriggerHandler
 * @extends {TriggerHandler}
 */
export class InstantTransitionTriggerHandler extends TriggerHandler {
    private subViewManager: ISubViewManager;
    /**
     * Creates an instance of InstantTransitionTriggerHandler.
     * 
     * @param {ISubViewManager} subViewManager The sub view manager - to get all required information of the sub view to display in the popups.
     * @param {Logger} logger The loggger.
     * 
     * @memberof InstantTransitionTriggerHandler
     */
    public constructor(subViewManager: ISubViewManager, logger: Logger) {
        super(logger);
        // Must start with a lower case character to avoid unwanted parsing in the WebService
        // See #71991
        this.triggerName = 'instantTransitionTriggerHandler';
        this.subViewManager = subViewManager;
    }

    /**
     * Handels the trigger switches view to the required sub view.
     * 
     * @param {*} subViewId The new subview ID which was specified.
     * 
     * @memberof InstantTransitionTriggerHandler
     */
    public handleTrigger(subViewId: any): void {
        // Set 'switchRequiresClosePopups' to true to force closing of all open sub view popups on triggering a direct transition
        this.subViewManager.switchRequiresClosePopups = true;
        this.subViewManager.switchSubView(subViewId);
    }
}