/**
 * This class represents the storage model for the StorageExtension.
 * 
 * @export
 * @class StorageDataModel
 */
export class StorageDataModel {

    /**
     * The identifier. 
     * 
     * @type {string}
     * @memberof StorageDataModel
     */
    public identifier: string;

    /**
     * The data. 
     * 
     * @type {string}
     * @memberof StorageDataModel
     */
    public data: string;

    /**
     * Creates an instance of StorageDataModel.
     * @param {string} identifier 
     * @param {string} data 
     * @memberof StorageDataModel
     */
    public constructor(identifier: string, data: string) {
        this.identifier = identifier;
        this.data = data;
    }
}