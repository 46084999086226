import { LicenseTabPanelPage } from './licenseTabPanelPage';

export class AboutTabPanelPage extends LicenseTabPanelPage {

    /**
     * Gets the title.
     *
     * @abstract 
     * @returns {string} The title.
     * @memberof AboutTabPanelPage
     */
    public getTitle(): string {
        return this.languageProvider.get('framework.license.popup.tabs.windream.title');
    }

    /**
     * Renders the content.
     *
     * @abstract
     * @param {HTMLElement} element The target element.
     * @memberof AboutTabPanelPage
     */
    public renderContent(element: HTMLElement): Promise<void> {
        this.templateExtension.render(element, require('../templates/about.html'), this.licenseDetails);
        return Promise.resolve();
    }

}