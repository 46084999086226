import { Component, ConfigRetriever } from 'typings/core';
import { ContextMenuItemDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IWindreamScriptExecutor, SCRIPT_SOURCE, Utils } from '../common';
import { ILanguageProvider } from '../language';
import { IServiceManager } from '../services';
import { INotificationHelper } from '../ui';
import { IContextMenuDataWebServiceHandler } from './interfaces';
import { ContextMenuData } from './models';

/**
 * WebService handler to fetch context menu data.
 * 
 * @export
 * @class ContextMenuDataWebServiceHandler
 * @implements {IContextMenuDataWebServiceHandler}
 */
export class ContextMenuDataWebServiceHandler implements IContextMenuDataWebServiceHandler {
    /**
     * Name of the application's context menu node.
     *
     * @static 
     * @memberof ContextMenuDataWebServiceHandler
     */
    public static CONTEXT_MENU_NAME = 'Dynamic Workspace';

    private serviceManager: IServiceManager;
    private scriptExecutor: IWindreamScriptExecutor;
    private notificationHelper: INotificationHelper;
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of ContextMenuDataWebServiceHandler.
     * @param {IServiceManager} serviceManager 
     * @param {IWindreamScriptExecutor} scriptExecutor 
     * @param {INotificationHelper} notificationHelper 
     * @param {ILanguageProvider} languageProvider 
     * @memberof ContextMenuDataWebServiceHandler
     */
    public constructor(serviceManager: IServiceManager, scriptExecutor: IWindreamScriptExecutor, notificationHelper: INotificationHelper, languageProvider: ILanguageProvider) {
        this.serviceManager = serviceManager;
        this.scriptExecutor = scriptExecutor;
        this.notificationHelper = notificationHelper;
        this.languageProvider = languageProvider;
    }
    /**
     * Gets the context menu structure for a specific root node.
     *
     * @template T Type of the data associated with the menu.
     * @param {string} contextMenuId The ID of the structure to get.
     * @returns {(Promise<ContextMenuData<T>[] | null>)} The context menu structure.
     * @memberof ContextMenuDataWebServiceHandler
     */
    public async getContextMenuDataModel<T>(contextMenuId: string): Promise<ContextMenuData<T>[] | null> {
        return new Promise<ContextMenuData<T>[] | null>((resolve, reject) => {
            this.serviceManager.getServices().DynamicWorkspace.getContextMenu().then((response) => {
                if (!response) {
                    reject(new Error('Unable to get context menu structure'));
                    return;
                }
                const parentMenu = response.ContextMenu.Children.find((subMenu) => subMenu.Name === ContextMenuDataWebServiceHandler.CONTEXT_MENU_NAME);
                if (!parentMenu) {
                    reject(new Error('Unable to get context menu structure for ' + ContextMenuDataWebServiceHandler.CONTEXT_MENU_NAME));
                    return;
                }
                const subMenu = parentMenu.Children.find((subMenu) => subMenu.Name === contextMenuId);
                if (!subMenu) {
                    reject(new Error('Unable to get requested context menu structure'));
                    return;
                }
                const rootMenu = new ContextMenuData<T>();
                rootMenu.name = 'ROOT';
                this.createContextMenuDataFromResponse<T>(subMenu, rootMenu);
                resolve(rootMenu.childs);
            }).catch((error) => reject(error));
        });
    }

    /**
     * Creates a submenu and it's children.
     *
     * @private
     * @template T Type of the data associated with the menu.
     * @param {ContextMenuItemDTO} contextMenuItem The context menu item.
     * @param {ContextMenuData<T>} parentMenu The parent menu item.
     * @returns {ContextMenuData<T>} The context menu.
     * @memberof ContextMenuDataWebServiceHandler
     */
    private createContextMenuDataFromResponse<T>(contextMenuItem: ContextMenuItemDTO, parentMenu: ContextMenuData<T>): ContextMenuData<T> {
        if (contextMenuItem.Children && contextMenuItem.Children.length) {
            for (const menu of contextMenuItem.Children) {
                const child = this.createContextMenuDataItemFromResponse<T>(menu);
                this.createContextMenuDataFromResponse<T>(menu, child);
                parentMenu.childs.push(child);
            }
        } else {
            return this.createContextMenuDataItemFromResponse<T>(contextMenuItem);
        }
        return parentMenu;
    }

    /**
     * Converts the given response to a context menu structure.
     *
     * @private
     * @template T Type of the data associated with the menu.
     * @param {ContextMenuItemDTO} contextMenuItem The context menu response.
     * @returns {ContextMenuData<T>} The context menu structure.
     * @memberof ContextMenuDataWebServiceHandler
     */
    private createContextMenuDataItemFromResponse<T>(contextMenuItem: ContextMenuItemDTO): ContextMenuData<T> {
        const item = new ContextMenuData<T>();
        item.name = Utils.Instance.escapeStringValue(contextMenuItem.DisplayName);

        if (contextMenuItem.Script) {
            item.onClick = (data: T[], sender?: Component) => {
                if (contextMenuItem.Script) {
                    const senderConfigSupport = sender as object as ConfigRetriever;
                    const options = senderConfigSupport && typeof senderConfigSupport.getComponentInstanceConfig === 'function' ?
                        senderConfigSupport.getComponentInstanceConfig() : undefined;
                    this.scriptExecutor.execute(SCRIPT_SOURCE.ContextMenu, contextMenuItem.Script, data, undefined, options).catch(() => {
                        this.notificationHelper.error({
                            body: this.languageProvider.get('framework.generic.error'),
                            title: this.languageProvider.get('framework.generic.error')
                        });
                    });
                }
            };
        }
        item.activationStatus = contextMenuItem.Settings.ActivationStatus;
        item.activeIfStatusSet = contextMenuItem.Settings.ActiveIfStatusSet;
        item.considerAllFlags = contextMenuItem.Settings.ConsiderAllFlags;
        item.entity = contextMenuItem.Settings.ExecutionEntity;
        item.multiSelect = contextMenuItem.Settings.DisplayByMultiSelect;
        item.singleSelect = contextMenuItem.Settings.DisplayBySingleSelect;

        return item;
    }
}