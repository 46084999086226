import { SystemConfigResponseDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { SystemConfig } from './models';

/**
 * This class provides a factory, which will generate SystemConfig objects from JSON objects or JSON strings.
 * 
 * @export
 * @class SystemConfigFactory
 */
export class SystemConfigFactory {

    /**
     * Converts the given system configuration DTO into an internal system configuration model.
     * 
     * @static
     * @param {SystemConfigResponseDTO} systemConfigDTO The system configuration DTO, which should be converted into an internal system configuration model.
     * @returns {SystemConfig} 
     * @memberof SystemConfigFactory
     */
    public static convertFromDTO(systemConfigDTO: SystemConfigResponseDTO): SystemConfig {
        const result = new SystemConfig();

        try {
            result.lastCacheReset = new Date(systemConfigDTO.LastCacheReset);
        } catch (err) {
            result.lastCacheReset = null;
        }

        return result;
    }
}