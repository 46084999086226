import { ScriptsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { BaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Gets all scripts.
 *
 * @export
 * @class GetAllScripts
 * @extends {ServiceAction}
 */
export class GetAllScripts extends ServiceAction {

    /**
     * Creates an instance of GetAllScripts.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof GetAllScripts
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getAllScripts';
    }

    /**
     * Retrieve metadata from all scripts.
     *
     * @param {BaseRequestOptions} [baseRequestOptions]
     * @returns {Promise<ScriptsResponseContainerDTO>}
     * @memberof GetAllScripts
     */
    public async do(baseRequestOptions?: BaseRequestOptions): Promise<ScriptsResponseContainerDTO> {
        return new Promise<ScriptsResponseContainerDTO>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer('get', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/scripts/GetAllScripts?parameter.flags=1');
            this.requestExecutor.executeRequest(resourcePointer, baseRequestOptions?.requestOptions)
                .then((response: IServiceResponse<ScriptsResponseContainerDTO>) => {
                    if (response.data && !response.data.HasErrors) {
                        resolve(response.data);
                    } else {
                        this.logger.error('GetAllScripts', 'do', 'Failed to retrieve all scripts', response);
                        reject(new Error('Failed to retrieve all scripts'));
                    }
                }).catch((err) => {
                    this.logger.error('GetAllScripts', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}