import { IDataSourceItem, IItemCollectionDataSource } from '../common';
import { IMultiSelectBox, IMultiSelectBoxOptions } from './interfaces';


/**
 * The base class for a multi select box.
 * 
 * @export
 * @class MultiSelectBox
 * @implements {IMultiSelectBox<T>}
 * @template T Value type that lies beyond.
 */
export class MultiSelectBox<T> implements IMultiSelectBox<T> {
    /**
     * The callback for the value changed event.
     * 
     * @memberof MultiSelectBox
     */
    public onValueChanged?: (items: IDataSourceItem<T>[] | null) => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof MultiSelectBox
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof MultiSelectBox
     */
    public onBlur?: () => void;

    private targetElement: HTMLElement;
    private listOptions: DevExpress.ui.dxListOptions;
    private instance?: DevExpress.ui.dxList;
    private jQueryStatic: JQueryStatic;
    private dataSource?: IItemCollectionDataSource<T>;
    private selectedData: IDataSourceItem<T>[];
    private options: IMultiSelectBoxOptions;

    // Used for multikey selection mode
    private lastSelectedIndex = -1;


    /**
     * Creates an instance of MultiSelectBox.
     * 
     * @param {HTMLElement} targetElement
     * @param {JQueryStatic} jQueryStatic
     * @memberof MultiSelectBox
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;
        this.selectedData = new Array<IDataSourceItem<T>>();

        this.listOptions = {
            activeStateEnabled: true,
            focusStateEnabled: true,
            itemTemplate: (itemData: IDataSourceItem<T>, _index, itemElement: DevExpress.core.dxElement) => {
                itemElement.text(itemData.displayValue || '');
                itemElement.addClass('wd-multi-select-box-item');
            },
            onItemClick: (event) => {
                if (!event) {
                    throw new Error('dxList.onValueChanged: The event parameter was null or undefined.');
                } else {
                    // Because we only access a boolean property, we can use this
                    const isCtrl = event.event ? !!((event.event as any as MouseEvent).ctrlKey) : false;
                    const isShift = event.event ? !!((event.event as any as MouseEvent).shiftKey) : false;
                    const itemIndex = typeof event.itemIndex === 'number' ? event.itemIndex : event.itemIndex.item;
                    this.handleSelectionClick(itemIndex, isCtrl, isShift);
                    this.onValueChangedTrigger(this.selectedData);
                }
            },
            selectionMode: 'single',
            showScrollbar: 'always',
            useNativeScrolling: true
        };

        // Define the default options.
        this.options = {
            allowSelectAll: true,
            selectionMode: 'single'
        };

        this.updateDevExtremeOptions(this.options);
    }


    /**
     * Bootstraps the multi select box.
     * 
     * @memberof MultiSelectBox
     */
    public bootstrap(): void {
        this.targetElement.classList.add('wd-multi-select-box');
        this.instance = this.getJQueryElement().dxList(this.listOptions)
            .on('focus', () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            }).on('blur', () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            }).on('keyup', (event: any) => {
                if (this.options.allowSelectAll && event.ctrlKey && event.key && event.key.toLowerCase() === 'a') {
                    event.preventDefault();
                    this.handleSelectAll();
                }
            }).dxList('instance');
    }


    /**
     * Sets the multi select box options.
     * 
     * @param {IButtonBoxOptions} options 
     * @memberof MultiSelectBox
     */
    public setOptions(options: IMultiSelectBoxOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }

        this.updateOptions(options);

        // Apply the DevExtreme options.
        this.updateDevExtremeOptions(options);
        this.getJQueryElement().dxList(this.listOptions);
    }


    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof MultiSelectBox
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof MultiSelectBox
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('disabled', isDisabled);
        }
    }

    /**
     * Sets the current value.
     * 
     * @param {(value: IDataSourceItem<T>[] | null)} items
     * @memberof MultiSelectBox
     */
    public setValue(items: IDataSourceItem<T>[] | null): void {
        const selectedItems = this.dataSource?.items.filter((item) => items && items.map((i) => i.value).indexOf(item.value) !== -1);
        this.getJQueryElement().dxList({
            selectedItems: selectedItems
        });
    }

    /**
     * Sets the data source.
     * 
     * @param {IItemCollectionDataSource<T>} datasource 
     * @memberof MultiSelectBox
     */
    public setDataSource(dataSource: IItemCollectionDataSource<T>): void {
        this.dataSource = dataSource;

        this.getJQueryElement().dxList({
            items: this.dataSource.items
        });
    }


    /**
     * Triggers the onValueChanged event.
     * 
     * @protected
     * @param {(DataSourceItem<T> | null)} value 
     * @memberof MultiSelectBox
     */
    protected onValueChangedTrigger(value: IDataSourceItem<T>[] | null): void {
        if (typeof this.onValueChanged === 'function') {
            // Create a new array to avoid pass-by-reference for the array
            this.onValueChanged(value === null ? null : [...value]);
        }
    }


    /**
     * Updates the options.
     *
     * @private
     * @param {IMultiSelectBoxOptions} options
     * @memberof MultiSelectBox
     */
    private updateOptions(options: IMultiSelectBoxOptions): void {
        if (options.hasOwnProperty('allowSelectAll')) {
            this.options.allowSelectAll = options.allowSelectAll;
        }
        if (options.hasOwnProperty('selectionMode')) {
            this.options.selectionMode = options.selectionMode;
        }
        if (options.hasOwnProperty('maxSelectedItems')) {
            this.options.maxSelectedItems = options.maxSelectedItems;
        }
        if (options.hasOwnProperty('tabIndex')) {
            this.options.tabIndex = options.tabIndex;
        }
        if (options.hasOwnProperty('focusStateEnabled')) {
            this.options.focusStateEnabled = options.focusStateEnabled;
        }
        if (options.hasOwnProperty('activeStateEnabled')) {
            this.options.activeStateEnabled = options.activeStateEnabled;
        }
    }


    /**
     * Updates the DevExtreme options.
     *
     * @private
     * @param {IMultiSelectBoxOptions} options
     * @memberof MultiSelectBox
     */
    private updateDevExtremeOptions(options: IMultiSelectBoxOptions): void {
        if (options.hasOwnProperty('selectionMode')) {
            this.listOptions.selectionMode = this.options.selectionMode === 'multi' || this.options.selectionMode === 'multikey' ? 'multiple' : 'single';
        }
    }


    /**
     * Gets the MultiSelectBox instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof MultiSelectBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }


    /**
     * Handles the click event and will update the selected data.
     *
     * @private
     * @param {number} clickedIndex Index of the element that has been clicked.
     * @param {boolean} isCtrl Whether ctrl key was pressed while selection.
     * @param {boolean} isShift Whether shift key was pressed while selection.
     * @memberof MultiSelectBox
     */
    private handleSelectionClick(clickedIndex: number, isCtrl: boolean, isShift: boolean): void {
        // Note: When this function runs the clicked item is already selected in the DX model
        const clickedData = this.dataSource?.items[clickedIndex];
        if (clickedData && this.instance) {
            if (this.options.selectionMode === 'multikey') {
                if (isShift && this.lastSelectedIndex !== -1) { // Select everything between last selected item and clicked item
                    const start = Math.min(this.lastSelectedIndex, clickedIndex);
                    const end = Math.max(this.lastSelectedIndex, clickedIndex);
                    // Select all items inbetween
                    for (let i = start; i <= end; i++) {
                        const itemWithinDataSource = this.dataSource?.items[i];
                        if (itemWithinDataSource && this.canSelectOneMore()) {
                            this.instance.selectItem(itemWithinDataSource);
                            this.selectItem(itemWithinDataSource);
                        }
                    }
                } else if (isCtrl) {
                    if (this.isItemSelected(clickedData) || !this.canSelectOneMore()) {
                        this.instance.unselectItem(clickedData);
                        this.unselectItem(clickedData);
                    } else {
                        this.instance.selectItem(clickedData);
                        this.selectItem(clickedData);
                    }
                } else { // Only select the clicked item
                    this.instance.unselectAll();
                    this.unselectAll();
                    this.instance.selectItem(clickedData);
                    this.selectItem(clickedData);
                }
            } else if (this.options.selectionMode === 'single') { // Only select the clicked item
                this.instance.unselectAll();
                this.unselectAll();
                this.instance.selectItem(clickedData);
                this.selectItem(clickedData);
            }
            this.lastSelectedIndex = clickedIndex;
        }
    }

    /**
     * Selects all items.
     *
     * @private
     * @memberof MultiSelectBox
     */
    private handleSelectAll(): void {
        if (this.instance) {
            this.instance.selectAll();
            this.selectAll();
        }
    }

    /**
     * Adds given item to the array of selected elements if it is not already present.
     *
     * @private
     * @param {IDataSourceItem<T>} selectedItem Item to select.
     * @memberof MultiSelectBox
     */
    private selectItem(selectedItem: IDataSourceItem<T>): void {
        const selectedDataIndex = this.selectedData.findIndex((item) => item === selectedItem);
        if (selectedDataIndex === -1) {
            this.selectedData.push(selectedItem);
            this.sortSelectedData();
        }
    }


    /**
     * Removes given item from the array of selected elements.
     *
     * @private
     * @param {IDataSourceItem<T>} selectedItem Item to un-select.
     * @memberof MultiSelectBox
     */
    private unselectItem(selectedItem: IDataSourceItem<T>): void {
        const selectedDataIndex = this.selectedData.findIndex((item) => item === selectedItem);
        if (selectedDataIndex !== -1) {
            this.selectedData.splice(selectedDataIndex, 1);
            this.sortSelectedData();
        }
    }

    /**
     * Checks whether the given item is selected.
     *
     * @private
     * @param {IDataSourceItem<T>} selectedItem The item to check.
     * @returns {boolean} Whether the item is selected.
     * @memberof MultiSelectBox
     */
    private isItemSelected(selectedItem: IDataSourceItem<T>): boolean {
        return this.selectedData.findIndex((item) => item === selectedItem) !== -1;
    }

    /**
     * Adds all entries to the array of selected elements.
     *
     * @private
     * @memberof MultiSelectBox
     */
    private selectAll(): void {
        if (this.dataSource) {
            this.selectedData.push(...this.dataSource.items);
        }
    }

    /**
     * Removes all entries from the array of selected elements.
     *
     * @private
     * @memberof MultiSelectBox
     */
    private unselectAll(): void {
        this.selectedData.length = 0;
    }

    /**
     * Sorts the selected data array to match he order within the data source.
     *
     * @private
     * @memberof MultiSelectBox
     */
    private sortSelectedData(): void {
        this.selectedData.sort((a, b) => {
            if (!this.dataSource) {
                return 0;
            }
            return this.dataSource.items.indexOf(a) - this.dataSource.items.indexOf(b);
        });
    }

    /**
     * Returns whether one more item can be selected according to `maxSelectedItems` option.
     * Will always return true of no limit is set.
     *
     * @private
     * @returns {boolean}
     * @memberof MultiSelectBox
     */
    private canSelectOneMore(): boolean {
        return this.options.maxSelectedItems ? this.selectedData.length < this.options.maxSelectedItems : true;
    }
}