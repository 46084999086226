import { Logger } from '../logging';
import { IBrowserInspector } from './interfaces/iBrowserInspector';

/**
 * The default browser inspector implementation.
 *
 * @export
 * @class BrowserInspector
 * @implements {IBrowserInspector}
 */
export class BrowserInspector implements IBrowserInspector {

    private navigator: Navigator;
    private browserLanguage: string;
    private className: string = 'BrowserInspector';
    private logger: Logger;


    /**
     * Creates an instance of BrowserInspector.
     * @param {Logger} logger
     * @memberof BrowserInspector
     */
    public constructor(logger: Logger, navigator: Navigator) {
        this.navigator = navigator;
        this.browserLanguage = this.navigator.language;
        this.logger = logger;
    }

    /**
     * Gets the current browser language.
     *
     * @memberof BrowserInspector
     */
    public getBrowserLanguage(): string {
        this.browserLanguage = this.navigator.language;
        const methodName = 'getBrowserLanguage';

        if (this.browserLanguage) {
            return this.browserLanguage;
        } else {
            this.logger.debug(this.className, methodName, 'Can\'t fetch the current browser language.');
            return '';
        }
    }
}