import { RequestOptions } from '../../dataProviders';

/**
 * Represents the most basic request option.
 *
 * @export
 * @class BaseRequestOptions
 */
export class BaseRequestOptions {

    /**
     * The current request options.
     *
     * @type {RequestOptions}
     * @memberof BaseRequestOptions
     */
    public requestOptions?: RequestOptions;
}