/**
 * Model for new connections created.
 * 
 * @export
 * @class NewConnection
 */
export class NewConnection {
    /**
     * Selected SubView ID.
     * 
     * @type {string}
     * @memberof NewConnection
     */
    public subViewId?: string;
    /**
     * Selected out component's GUID.
     * 
     * @type {string}
     * @memberof NewConnection
     */
    public outComponentGuid?: string;
    /**
     * Selected in component's GUID.
     * 
     * @type {string}
     * @memberof NewConnection
     */
    public inComponentGuid?: string;
    /**
     * Selected out event.
     * 
     * @type {string}
     * @memberof NewConnection
     */
    public outEvent?: string;
    /**
     * Selected in event,
     * 
     * @type {string}
     * @memberof NewConnection
     */
    public inEvent?: string;
    /**
     * Selected transition.
     * 
     * @type {string}
     * @memberof NewConnection
     */
    public transition?: string;
    /**
     * Whether the connection should be executed if value is empty.
     *
     * @type {boolean}
     * @memberof NewConnection
     */
    public executeForEmptyValue: boolean = false;
}