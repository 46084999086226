import { SubscriptionEvent } from './subscriptionEvent';

/**
 * Creates an instance of subscription event.
 *
 * @export
 * @class SubscriptionEventOptions
 */
export class SubscriptionEventOptions {
    /**
     * The subscription event id.
     *
     * @type {number}
     * @memberof SubscriptionEventOptions
     */
    public subscriptionEvents: SubscriptionEvent[];

    /**
     * Creates an instance of SubscriptionEvent.
     * 
     * @param {SubscriptionEvent[]} subscriptionEvents The subscription events.
     * @memberof SubscriptionEventOptions
     */
    public constructor(subscriptionEvents: SubscriptionEvent[]) {
        this.subscriptionEvents = subscriptionEvents;
    }
}