import { WMObjectStatus } from '../../typings/windreamWebService/Windream.WebService';
import { WindreamEntity } from '../common';
import { FormatHelper } from './formatHelper';

/**
 * The WindreamStatusFormatHelper provides windream status functionality.
 * 
 * @export
 * @class WindreamStatusFormatHelper
 * @extends {FormatHelper}
 */
export class WindreamStatusFormatHelper extends FormatHelper {

  /**
   * Returns a formatted string to represent the windream status flags of the object.
   * Result will always be a string in the format `R-V-A-BE-N-P-B-H-CE`.
   * R = Readonly
   * V = Preversion
   * A = Archived
   * BE = Status Work Lock, Edit Only
   * N = No Rights
   * P = Async Proxy Binary Permanent Lock
   * C = Non Async Proxy Binary Permanent Lock
   * B = User Work Lock
   * H = HSM Binary
   * CE = Check Out, Edit Only (Document)
   * O = Owner Rights (Folder)
   * 
   * @param {number} flags  The flags to represent as a string.
   * @param {WindreamEntity} entity Entity of the object to get flags for.
   * @returns {string} 
   * 
   * @memberof WindreamStatusFormatHelper
   */
  public getStatusShort(flags: number, entity: WindreamEntity): string {
    let attrStr: string = '';

    /**
     * Adds a dash to the return value if the value is not empty. 
     */
    const _addDash = () => {
      if (attrStr) {
        attrStr += '-';
      }
    };

    if ((flags & WMObjectStatus.WMObjectStatusReadOnly) === WMObjectStatus.WMObjectStatusReadOnly) {
      _addDash();
      attrStr += 'R';
    }

    if ((flags & WMObjectStatus.WMObjectStatusPreVersion) === WMObjectStatus.WMObjectStatusPreVersion) {
      _addDash();
      attrStr += 'V';
    }

    if ((flags & WMObjectStatus.WMObjectStatusArchived) === WMObjectStatus.WMObjectStatusArchived) {
      _addDash();
      attrStr += 'A';
    }

    if ((flags & WMObjectStatus.WMObjectStatusWorkLockEditOnly) === WMObjectStatus.WMObjectStatusWorkLockEditOnly) {
      _addDash();
      attrStr += 'BE';
    }

    if ((flags & WMObjectStatus.WMObjectStatusNoRights) === WMObjectStatus.WMObjectStatusNoRights) {
      _addDash();
      attrStr += 'N';
    }

    if ((flags & WMObjectStatus.WMObjectStatusPermanentLock) === WMObjectStatus.WMObjectStatusPermanentLock) {
      _addDash();
      if ((flags & WMObjectStatus.WMObjectStatusAsyncProxyBinary) === WMObjectStatus.WMObjectStatusAsyncProxyBinary) {
        attrStr += 'P';
      } else {
        attrStr += 'C';
      }
    }

    if ((flags & WMObjectStatus.WMObjectStatusUserWorkLock) === WMObjectStatus.WMObjectStatusUserWorkLock) {
      _addDash();
      attrStr += 'B';
    }

    if ((flags & WMObjectStatus.WMObjectStatusHSMBinary) === WMObjectStatus.WMObjectStatusHSMBinary) {
      _addDash();
      attrStr += 'H';
    }

    if (entity === WindreamEntity.Document) {
      if ((flags & WMObjectStatus.WMObjectStatusCheckOutEditOnly) === WMObjectStatus.WMObjectStatusCheckOutEditOnly) {
        _addDash();
        attrStr += 'CE';
      }
    } else if (entity === WindreamEntity.Folder) {
      if ((flags & WMObjectStatus.WMObjectStatusOwnerRights) === WMObjectStatus.WMObjectStatusOwnerRights) {
        _addDash();
        attrStr += 'O';
      }
    }

    return attrStr;
  }
}