import { WindreamEntity, DirectoryDetails as IDirectoryDetails} from '../../../../typings/core';
import { ItemResponseDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { DirectoryResponseDTO } from '../../../typings/windreamWebService/Windream.WebService.Directories';
import { WindreamIdentityDetails } from './windreamIdentityDetails';

export class DirectoryDetails extends WindreamIdentityDetails implements IDirectoryDetails{

    /**
     * Creates an instance based on the DTO.
     *
     * @static
     * @param {DirectoryResponseDTO} dto DTO to create from.
     * @returns {DirectoryDetails}
     * @memberof DirectoryDetails
     */
    public static fromDto(dto: DirectoryResponseDTO): DirectoryDetails {
        const extendedDto = dto as ItemResponseDTO;
        extendedDto.Entity = WindreamEntity.Folder as number;
        const result = super.fromDto(extendedDto);
        return result;
    }
}