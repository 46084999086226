import { DWCore } from '@windream/dw-core/dwCore';
import { ResponseContainerDTO } from 'framework/typings/windreamWebService/Windream.WebService';
import { ConfigResponseContainerDTO, DeleteViewParameterDTO, ViewConfigMetaDataDTO, ViewConfigResponseContainerDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { HttpResponse } from '../ajaxHandler';
import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { ConfigDataProvider, HttpResourcePointer } from '../dataProviders';
import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';
import { WEBSERVICE_APPLICATION_ERROR_CODES } from '../errors';
import { ServiceError } from '../services';
import { IConfigManager } from './interfaces';
import { ApplicationConfig, GlobalConfig, ViewConfig, ViewConfigMetaData } from './models';
import { ViewConfigFactory } from './viewConfigFactory';
import { ViewConfigMetaDataFactory } from './viewConfigMetaDataFactory';


/**
 * The ConfigManager class provides the functionality to write config files to a remote source.
 * @access public
 * @verison 1.0
 */
export class ConfigManager implements IConfigManager {
    private requestExecutor: IRequestExecutor;
    private globalConfig: GlobalConfig;


    /**
     * Creates an instance of ConfigManager.
     *
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @memberof ConfigManager
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig) {
        this.requestExecutor = requestExecutor;
        this.globalConfig = globalConfig;
    }

    /**
     * This function will update a view Config on the windream server.
     * @access public
     * @version 1.0
     * @param viewId     {string}      ID of the view to be updated.
     * @param device      {DEVICES}     Device to store the config for.
     * @param viewConfig {ViewConfig} View Configuration to be saved.
     * @returns {Promise<ViewConfig>}  Promise to resolve with the new view Config.
     * @async
     */
    public async updateViewConfig(viewId: string, device: DWCore.Common.Devices, viewConfig: ViewConfig): Promise<ViewConfig> {
        return new Promise<ViewConfig>(async (resolve, reject) => {

            // External core
            if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core && DynamicWorkspace.Extensions.core.viewProvider) {
                try {
                    const updatedViewConfig = await DynamicWorkspace.Extensions.core.viewProvider.updateView(viewConfig);
                    if (updatedViewConfig && updatedViewConfig.data) {
                        resolve(updatedViewConfig.data);
                    } else {
                        reject(new Error('ConfigManager.updateViewConfig(): Invalid response found.'));
                    }
                    return;
                } catch (err) {
                    reject(err);
                    return;
                }
            }

            const remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_UPDATE_VIEW_CONFIG_PATH;
            const httpResourcePointer = new HttpResourcePointer('POST', remoteLocation, {
                ConfigData: viewConfig,
                Device: device,
                ViewID: viewId
            });
            httpResourcePointer.scheme = 'config';
            this.requestExecutor.executeRequest(httpResourcePointer).then((response: IServiceResponse<ViewConfigResponseContainerDTO>) => {
                if (response.data && !response.data.HasErrors) {
                    const result = ViewConfigFactory.convertFromDTO(response.data.ConfigData);
                    resolve(result);
                } else {
                    reject(new Error(response.data?.Error?.Message || 'Failed to get ViewConfig'));
                }
            }).catch((reason: HttpResponse<ViewConfigResponseContainerDTO>) => {
                // TODO: Check why zone.js rejects with HTTPResponse
                const errorCode = reason.data?.Error?.ErrorCode || WEBSERVICE_APPLICATION_ERROR_CODES.Unknown;
                const error = new ServiceError('Failed to update view config', errorCode);
                reject(error);
            });
        });
    }

    /**
     * This function will create a view Config on the windream server.
     * @access public
     * @version 1.0
     * @param viewId     {string}      ID of the view to be created.
     * @param viewConfig {ViewConfigMetaData} View Configuration to be saved.
     * @param {ApplicationConfig} applicationConfig Application Config to use as update.
     * @returns {Promise<ViewConfigMetaData>}  Promise to resolve with the new view Config.
     * @async
     * 
     * @memberof ConfigManager
     */
    public async createViewConfig(viewId: string, viewConfig: ViewConfigMetaData, applicationConfig: ApplicationConfig): Promise<ViewConfigMetaData> {
        return new Promise<ViewConfigMetaData>((resolve, reject) => {
            const remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_CREATE_VIEW_CONFIG_PATH;

            const httpResourcePointer = new HttpResourcePointer('POST', remoteLocation, {
                ConfigData: viewConfig,
                ViewID: viewId
            });
            httpResourcePointer.scheme = 'config';
            this.requestExecutor.executeRequest(httpResourcePointer).then((response: IServiceResponse<ConfigResponseContainerDTO<ViewConfigMetaDataDTO>>) => {
                if (response.data && !response.data.HasErrors) {
                    const viewConfigMetaData = ViewConfigMetaDataFactory.convertFromDTO(response.data.ConfigData);
                    applicationConfig.activeViews.push(viewConfigMetaData);
                    resolve(viewConfigMetaData);
                } else {
                    reject(new Error(response.data?.Error ? response.data.Error.Message : 'Unable to save new ViewConfig'));
                }
            }).catch((reason: Error) => {
                reject(reason);
            });
        });
    }

    /**
     * This function will create a view Config on the windream server.
     * @access public
     * @version 1.0
     * @param viewId     {string}      ID of the view to be created.
     * @param {ApplicationConfig} applicationConfig Application Config to use as update.
     * @returns {Promise<void>}  Promise to resolve on success.
     * @async
     * 
     * @memberof ConfigManager
     */
    public async deleteViewConfig(viewId: string, applicationConfig: ApplicationConfig): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_DELETE_VIEW_CONFIG_PATH;

            const deleteViewParameter: DeleteViewParameterDTO = {
                ViewIdentifier: {
                    Id: viewId
                }
            };

            const httpResourcePointer = new HttpResourcePointer('POST', remoteLocation, deleteViewParameter);
            httpResourcePointer.scheme = 'config';

            this.requestExecutor.executeRequest(httpResourcePointer).then((response: IServiceResponse<ResponseContainerDTO>) => {
                if (response) {
                    const resultData = response.data;
                    if (resultData) {
                        if (!resultData.HasErrors) {
                            const index = applicationConfig.activeViews.findIndex((view) => view.id === viewId);
                            if (index > -1) {
                                applicationConfig.activeViews.splice(index, 1);
                                resolve();
                            } else {
                                reject(new Error('Unable to remove view from active views'));
                            }
                        } else {
                            reject(new Error(resultData.Error ? resultData.Error.toString() : 'Error deleting view.'));
                        }
                    }
                }

            }).catch((reason: Error) => {
                reject(reason);
            });
        });
    }

}