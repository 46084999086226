import { WindreamEntity, DocumentDetails as IDocumentDetails } from '../../../../typings/core';
import { ItemResponseDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { DocumentResponseDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { WindreamIdentityDetails } from './windreamIdentityDetails';

export class DocumentDetails extends WindreamIdentityDetails implements IDocumentDetails{

    /**
     * Creates an instance based on the DTO.
     *
     * @static
     * @param {DocumentResponseDTO} dto DTO to create from.
     * @returns {DocumentDetails}
     * @memberof DocumentDetails
     */
    public static fromDto(dto: DocumentResponseDTO): DocumentDetails {
        const extendedDto = dto as ItemResponseDTO;
        extendedDto.Entity = WindreamEntity.Document as number;
        const result = super.fromDto(extendedDto);
        return result;
    }
}