/**
 * Represents the header of a windream token.
 *
 * @export
 * @class WindreamTokenHeader
 */
export class WindreamTokenHeader {

    /**
     * The hash algorithm
     *
     * @type {string}
     * @memberof WindreamTokenHeader
     */
    public alg?: string;

    /**
     * The type of token.
     *
     * @type {string}
     * @memberof WindreamTokenHeader
     */
    public typ?:string;

    /**
     * The user of this token.
     *
     * @type {string}
     * @memberof WindreamTokenHeader
     */
    public username?: string;

    /**
     * The user id.
     *
     * @type {string}
     * @memberof WindreamTokenHeader
     */
    public userid?: string;

    /**
     * The language id.
     *
     * @type {string}
     * @memberof WindreamTokenHeader
     */
    public lcid?: string;
}