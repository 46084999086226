import { AttributeFlags, WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents request options for get details 
 *
 * @export
 * @class GetDetailsRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class GetDetailsRequestOptions extends WindreamRequestOptions {

    /**
     * The values of this request.
     * Defaults to an empty array.
     *
     * @type {string[]}
     * @memberof GetDetailsRequestOptions
     */
    public values: string[];

    /**
     * The attribute flags for this request.
     * Defaults to 0.
     *
     * @type {AttributeFlags}
     * @memberof GetDetailsRequestOptions
     */
    public attributeFlags: AttributeFlags;

    /**
     * Creates an instance of GetDetailsRequestOptions.
     * @param {WindreamIdentity} identity
     * @memberof GetDetailsRequestOptions
     */
    public constructor(identity: WindreamIdentity) {
        super(identity);
        this.values = [];
        this.attributeFlags = AttributeFlags.Default;
    }
}