/**
 * Helper class for resizing columns.
 * Binds mouse events that make the columns re-sizable by dragging the sides of the column.
 * 
 * @export
 * @class ColumnResizeHelper
 */
export class ColumnResizeHelper {
  /**
   * Callback to execute when resizing occured.
   * 
   * 
   * @memberof ColumnResizeHelper
   */
  public onResize?: (columnWidths: number[]) => void;

  private tableElement: HTMLTableElement;
  private columnSpacer: number[];
  private resizingSpaceIndex: number = -1;
  private headers?: NodeListOf<HTMLTableHeaderCellElement>;

  /**
   * Min width of a column.
   * 
   * @private
   * 
   * @memberof ColumnResizeHelper
   */
  private readonly MIN_WIDTH = 50;

  /**
   * Creates an instance of SortingHelper.
   * 
   * @param {*} lib Sorting lib to use.
   * 
   * @memberof ColumnResizeHelper
   */
  constructor(tableElement: HTMLTableElement) {
    this.tableElement = tableElement;
    this.columnSpacer = new Array<number>();
  }

  /**
   * Make a single element resizable.
   *
   * @memberof ColumnResizeHelper
   */
  public makeResizeable(): void {
    this.tableElement.classList.add('wd-resizable');
    this.calculateColumnSpacer(); // Initially calculate spacer
    this.bindEvents();
  }

  /**
   * Re-calculates column spacers.
   * 
   * 
   * @memberof ColumnResizeHelper
   */
  public reInit(): void {
    this.calculateColumnSpacer();
  }


  /**
   * Calculate column spacers.
   * Fills array of column widths.
   * 
   * @private
   * 
   * @memberof ColumnResizeHelper
   */
  private calculateColumnSpacer(): void {
    this.headers = this.tableElement.querySelectorAll('thead th') as NodeListOf<HTMLTableHeaderCellElement>;
    this.columnSpacer = [0]; // Left border is always 0

    if (this.headers && this.headers.length > 0) {
      [].forEach.call(this.headers, (cell: HTMLTableHeaderCellElement) => {
        const prevValue = this.columnSpacer[this.columnSpacer.length - 1] || 0;
        const width = prevValue + cell.offsetWidth;
        this.columnSpacer.push(width);
      });
    }
  }

  /**
   * Binds events to the DOM elements.
   * 
   * @private
   * @returns {void} 
   * 
   * @memberof ColumnResizeHelper
   */
  private bindEvents(): void {
    const header = this.tableElement.querySelector('thead');
    if (!header) {
      return;
    }

    // Bind handler for active column re-sizing while dragging
    header.addEventListener('mousedown', (e: MouseEvent) => {
      const spaceIndex = this.findSpacerIndex(e);
      if (spaceIndex > -1) {
        this.resizingSpaceIndex = spaceIndex;
        this.tableElement.classList.add('wd-resize-active');
      }
    });

    // Bind handler to prevent sorting while re-sizing
    header.addEventListener('click', (e: MouseEvent) => {
      const spaceIndex = this.findSpacerIndex(e);
      if (spaceIndex > -1) {
        e.stopImmediatePropagation();
      }
    }, true);


    // Bind handler for double click to set column size to auto
    header.addEventListener('dblclick', (e: MouseEvent) => {
      const spaceIndex = this.findSpacerIndex(e);
      if (spaceIndex < -1 || this.resizingSpaceIndex < -1 || !header) {
        return;
      }
      const headers = header.querySelectorAll('th');
      let headerIndex = 0;
      // Iterate through all elements
      [].forEach.call(headers, (tableHeader: HTMLElement) => {
        if (headerIndex === spaceIndex - 1 || !tableHeader.style.width) {
          let biggestSize = 0;
          const index = tableHeader.getAttribute('data-wd-header-id');
          const rows = this.tableElement.querySelectorAll('tbody tr');
          // Check each row
          [].forEach.call(rows, (row: HTMLElement) => {
            const columns = row.querySelectorAll('td');
            let headerIndex = 0;
            // Check each column and then get the size
            [].forEach.call(columns, (column: HTMLElement) => {
              if (index && Number.parseInt(index, 10) === headerIndex) {
                // Set best fitting width
                tableHeader.style.width = '0';
                this.calculateColumnSpacer();
                biggestSize = biggestSize > column.scrollWidth ? biggestSize : column.scrollWidth;
              }
              headerIndex++;
            });
          });
          tableHeader.style.width = biggestSize + 'px';
          this.calculateColumnSpacer();
        }
        headerIndex++;
      });
    });

    // Bind handler for resizing when moving the mouse
    header.addEventListener('mousemove', (e: MouseEvent) => {
      const header = this.tableElement.querySelector('thead');
      if (header) {
        const spaceIndex = this.findSpacerIndex(e);
        if (spaceIndex > -1 || this.resizingSpaceIndex > -1) {
          header.style.cursor = 'col-resize';
        } else {
          header.style.cursor = '';
        }
      }
    });

    // Bind handler to stop resizing when mouse button is released
    document.addEventListener('mouseup', () => {
      if (this.resizingSpaceIndex > -1) {
        this.resizingSpaceIndex = -1;
        this.tableElement.classList.remove('wd-resize-active');

        if (this.onResize && typeof this.onResize === 'function') {
          this.onResize(this.columnSpacer.map((value: number, index: number, array: number[]) => {
            if (index === 0) {
              return 0;
            } else {
              return value - array[index - 1];
            }
          }));
        }
      }
    });

    // Add resize cursor
    document.addEventListener('mousemove', (e: MouseEvent) => {
      if (this.resizingSpaceIndex > -1 && this.headers) {
        const oldWidth = this.headers[this.resizingSpaceIndex - 1].offsetWidth;
        let newWidth = this.getXPositionRelativeToTable(e) - this.columnSpacer[this.resizingSpaceIndex - 1];
        newWidth = Math.max(newWidth, this.MIN_WIDTH); // Set min width for columns

        this.tableElement.style.width = (this.tableElement.offsetWidth + (newWidth - oldWidth)) + 'px';
        this.headers[this.resizingSpaceIndex - 1].style.width = newWidth + 'px';
        this.calculateColumnSpacer(); // Re-calculate spacers

        // Scroll wrapper to have mouse cursor always visible when re-sizing
        const wrapper = this.tableElement.parentElement;
        if (wrapper) {
          wrapper.scrollLeft = this.getXPositionRelativeToTable(e);
        }
      }
    });
  }


  /**
   * Find the nearest column index for the given mouse event.
   * 
   * @private
   * @param {MouseEvent} e Mouse event to get nearest index for.
   * @returns {number} Index of the nearest column, -1 if none is found.
   * 
   * @memberof ColumnResizeHelper
   */
  private findSpacerIndex(e: MouseEvent): number {
    const offset = 10; // Offset to detect position in pixels
    return this.columnSpacer.findIndex((space: number) => {
      if (this.tableElement && this.tableElement.parentElement) {
        return Math.abs(space - this.getXPositionRelativeToTable(e)) < offset;
      }
      return false;
    });
  }

  /**
   * Returns the position o the x-axis for the given mouse event relative to the table.
   * 
   * @private
   * @param {MouseEvent} e Mouse event to get relative x-axis positon for.
   * @returns {number} Relative x-axis position, -1 in case of an error.y
   * 
   * @memberof ColumnResizeHelper
   */
  private getXPositionRelativeToTable(e: MouseEvent): number {
    if (this.tableElement && this.tableElement.parentElement) {
      return (e.pageX + (this.tableElement.parentElement.scrollLeft - this.tableElement.parentElement.getBoundingClientRect().left));
    }
    return -1;
  }
}