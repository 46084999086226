/**
 * Enumeration to reflect the different config types.
 * 
 * @enum {number}
 */
export enum CONFIG_MODES {
  /**
   * Nothing has been loaded yet.
   */
  NONE,
  /**
   * A component is being configured.
   */
  COMPONENT,
  /**
   * The view is being configured.
   */
  VIEW
}