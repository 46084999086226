import { IServiceResponse } from '../../ajaxHandler';
import { WindreamEntity } from '../../common';
import { GlobalConfig } from '../../config';
import { BinaryHttpResourcePointer, BinaryHttpResponseTypes, HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { WorklockRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';
import { IFileSaver } from './iFileSaver';

/**
 * The WorkLock action is used to lock a document for editing.
 *
 * @export
 * @class WorkLock
 * @extends {ServiceAction}
 */
export class WorkLock extends ServiceAction {

    private fileSaver: IFileSaver;


    /**
     * Creates an instance of WorkLock.
     *
     * @param {IFileSaver} fileSaver
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof WorkLock
     */
    public constructor(fileSaver: IFileSaver, requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'workLock';
        this.fileSaver = fileSaver;
    }


    /**
     * Locks a document for editing and optionally downloads it.
     * Resolves with the converted server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WorklockRequestOptions} worklockRequestOptions The request options.
     * @returns {Promise<ResponseContainerDTO>} A promise, which will resolve with the converted server response.
     * @memberof WorkLock
     */
    public async do(worklockRequestOptions: WorklockRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (worklockRequestOptions.identity.entity !== WindreamEntity.Document) {
                this.logger.error('WorkLock', 'do', 'Identity is not a document', worklockRequestOptions.identity);
                reject(new Error('Identity is not a document'));
                return;
            }
            const url = `${this.globalConfig.windreamWebServiceURL}/documents/WorkLock`;
            const documentLocationId = IdentityMapper.getWebServiceIdentity(worklockRequestOptions.identity);
            const payload = JSON.stringify({
                Item: documentLocationId,
                ShouldDownload: worklockRequestOptions.downloadOnSuccess
            });
            let resourcePointer: HttpResourcePointer;
            if (worklockRequestOptions.downloadOnSuccess) {
                resourcePointer = new BinaryHttpResourcePointer('POST', url, BinaryHttpResponseTypes.ARRAYBUFFER, payload);
            } else {
                resourcePointer = new HttpResourcePointer('POST', url, payload);
            }

            this.requestExecutor.executeRequest(resourcePointer, worklockRequestOptions.requestOptions)
                .then((response: IServiceResponse<any>) => {
                    if (!response || !response.data || response.data.HasErrors) {
                        this.logger.error('WorkLock', 'do', 'Failed to set a worklock', response);
                        reject(new Error('Failed to set a worklock'));
                        return;
                    }

                    if (worklockRequestOptions.downloadOnSuccess) {
                        // Success
                        this.fileSaver.saveAs(response.data, worklockRequestOptions.identity.name || 'File');
                    }

                    resolve();

                }).catch((error) => {
                    this.logger.error('WorkLock', 'do', 'Failed to execute request', error);
                    reject(error);
                });
        });
    }

}