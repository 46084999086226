import { IList, IListOptions } from './interfaces';

/**
 * A list component.
 *
 * @export
 * @class List
 * @implements {IList<T>}
 * @template T
 */
export class List<T> implements IList<T> {

    /**
     * Callback for item click.
     *
     * @memberof List
     */
    public onItemClick?: (value: T) => void;

    /**
     * Callback if the item was deleted.
     *
     * @memberof List
     */
    public onItemDeleted?: (value: T) => void;

    private jQueryStatic: JQueryStatic;
    private targetElement: HTMLElement;

    /**
     * Creates an instance of List.
     *
     * @param {HTMLElement} targetElement The target element.
     * @param {JQueryStatic} jQueryStatic jQueryStatic. 
     * @memberof List
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;
    }

    /**
     * Bootstrap this component.
     *
     * @memberof List
     */
    public bootstrap(): void {
        const listOptions: DevExpress.ui.dxListOptions = {};
        listOptions.onItemClick = (data) => {
            if (this.onItemClick) {
                this.onItemClick(data.itemData);
            }
        };
        listOptions.onItemDeleted = (data) => {
            if (this.onItemDeleted) {
                this.onItemDeleted(data.itemData);
            }
        };
        listOptions.useNativeScrolling = true;
        this.getJQueryElement().dxList(listOptions);
    }

    /**
     * Sets the options for this component.
     *
     * @param {IListOptions<T>} options
     * @memberof List
     */
    public setOptions(options: IListOptions<T>): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }

        // Create the DevExpress options object.
        const listOptions: DevExpress.ui.dxListOptions = {};

        if (options.hasOwnProperty('dataSource')) {
            listOptions.dataSource = options.dataSource;
        }
        if (options.hasOwnProperty('itemTemplate')) {
            listOptions.itemTemplate = options.itemTemplate;
        }
        if (options.hasOwnProperty('searchEnabled')) {
            listOptions.searchEnabled = options.searchEnabled;
        }
        if (options.hasOwnProperty('searchExpr')) {
            listOptions.searchExpr = options.searchExpr;
        }
        if (options.hasOwnProperty('selectionMode')) {
            listOptions.selectionMode = options.selectionMode;
        }
        if (options.hasOwnProperty('selectedItems')) {
            listOptions.selectedItems = options.selectedItems;
        }
        if (options.hasOwnProperty('allowItemDeleting')) {
            listOptions.allowItemDeleting = options.allowItemDeleting;
        }
        if (options.hasOwnProperty('itemDeleteMode')) {
            listOptions.itemDeleteMode = options.itemDeleteMode;
        }
        // Set the options.
        this.getJQueryElement().dxList(listOptions);
    }

    /**
     * Gets the jquery element.
     *
     * @private
     * @returns {JQuery<HTMLElement>}
     * @memberof List
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.jQueryStatic) {
            throw new Error('jQuery was not loaded.');
        }

        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }
}