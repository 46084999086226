import { CacheableResponse } from './cacheableResponse';
import { IHttpResponse } from './interfaces/iHttpResponse';


/**
 * This class describes a http response.
 * 
 * @export
 * @class HttpResponse
 */
export class HttpResponse<T> extends CacheableResponse<T> implements IHttpResponse<T> {

    /**
     * The http status code.
     * 
     * @type {number}
     * @memberof HttpResponse
     */
    public statusCode?: number;


    /**
     * The http response headers.
     * 
     * @type {Map<string, string>}
     * @memberof HttpResponse
     */
    public httpHeaders: Map<string, string>;


    /**
     * Creates an instance of HttpResponse.
     * 
     * @memberof HttpResponse
     */
    public constructor() {
        super();

        this.httpHeaders = new Map<string, string>();
    }

}