/**
 * The activator will create a class instance from a class reference.
 *
 * @export
 * @class Activator
 */
export class Activator {

    /**
     * Creates the class instance from a class reference.
     *
     * @param {*} classReference The class to instance.
     * @returns {(object | undefined)} The instanced class or undefined if the class reference doesn't contain a constructor.
     * @memberof Activator
     */
    public createInstance(classReference: any): object | undefined {
        try {
            return new classReference();
        } catch {
            return undefined;
        }
    }
}