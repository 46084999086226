import { DWCore } from '../dynamicWorkspace';

/**
 * The default implementation of the message provider interface.
 *
 * @export
 * @class MessageProvider
 * @implements {DWCore.Messages.MessageProvider}
 */
export class MessageProvider implements DWCore.Messages.MessageProvider {

    /**
     * The callback of the message event.
     *
     * @memberof MessageProvider
     */
    public onMessage: ((message: DWCore.Messages.Message) => void) | undefined;


    /**
     * Subscribes to the specified topic.
     *
     * @param {string} subscriberId The subscriber identifier.
     * @param {string} topic The topic.
     * @memberof MessageProvider
     */
    public subscribe(_subscriberId: string, _topic: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    /**
     * Unsubscribes from the specified topic.
     *
     * @param {string} subscriberId The subscriber identifier. 
     * @param {string} topic The topic.
     * @memberof MessageProvider
     */
    public unsubscribe(_subscriberId: string, _topic: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

}