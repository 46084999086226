import { AuthenticationModes } from '../../authentication/models/authenticationModes';
import { LogLevel } from '../../logging';
import { ITheme } from '../../themeManager';
import { MSALConfig } from './msalConfig';

/**
 * This class is a data holder for the global application config.
 * 
 * @export
 * @class GlobalConfig
 */
export class GlobalConfig {

    /**
     * The windreamWebServiceURL.
     * 
     * @type {string}
     * @memberof GlobalConfig
     */
    public windreamWebServiceURL: string;

    /**
     * The windream Web DAV Service URL.
     * 
     * @type {string}
     * @memberof GlobalConfig
     */
    public windreamWebDAVServiceURL?: string;

    /**
     * The current log level.
     * 
     * @type {LogLevel}
     * @memberof GlobalConfig
     */
    public logLevel?: LogLevel;

    /**
     * An array of all available components.
     * 
     * @type {string[]}
     * @memberof GlobalConfig
     */
    public availableComponents: string[];

    /**
     * List of themes that the user can select.
     *
     * @type {ITheme[]}
     * @memberof CustomConfig
     */
    public availableThemes?: ITheme[];

    /**
     * The supported languages in order of priority..
     * 
     * @type {string}
     * @memberof GlobalConfig
     */
    public languages: string[];

    /**
     * The preferred authentication mode.
     * 
     * @type {AuthenticationModes}
     * @memberof GlobalConfig
     */
    public authenticationMode?: AuthenticationModes;

    /**
     * Load custom config for themeing.
     *
     * @type {boolean}
     * @memberof GlobalConfig
     */
    public customConfig?: boolean;

    /**
     * Determines whether the imprint should be activated.
     *
     * @type {boolean}
     * @memberof GlobalConfig
     */
    public imprint?: boolean;

    /**
     * The DW MSAL configuration.
     *
     * @type {MSALConfig}
     * @memberof GlobalConfig
     */
    public msal?: MSALConfig;

    /**
     * Determines whether the core extension should be used.
     *
     * @type {boolean}
     * @memberof GlobalConfig
     */
    public useExternalCore?: boolean;

    /**
     * Creates an instance of GlobalConfig.
     * @param {string} webServiceUrl
     * @memberof GlobalConfig
     */
    public constructor(webServiceUrl: string) {
        this.languages = new Array<string>();
        this.availableThemes = new Array<ITheme>();
        this.availableComponents = new Array<string>();
        this.windreamWebServiceURL = webServiceUrl;
    }

}