import { ArgumentError } from './argumentError';
/**
 * 	Thrown by methods that do not allow an argument to be null.
 * 
 * @export
 * @class ArgumentNullError
 * @extends {Error}
 */
export class ArgumentNullError extends ArgumentError {

    /**
     * Creates an instance of ArgumentNullError.
     * @param {string} [message] 
     * 
     * @memberof ArgumentNullError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'ArgumentNullError';
    }
}