import { CheckBox as ICheckBox, CheckBoxOptions } from '../../../../../typings/ui';

/**
 * Checkbox Class.
 *
 * @export
 * @class CheckBox
 * @implements {ICheckBox}
 */
export class CheckBox implements ICheckBox {

    /**
     * Callback to send out value.
     *
     * @memberof CheckBox
     */
    public onValueChanged?: (state: boolean | undefined) => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof CheckBox
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof CheckBox
     */
    public onBlur?: () => void;

    private dxCheckBoxElement?: DevExpress.ui.dxCheckBox;
    private checkBoxOptions: DevExpress.ui.dxCheckBoxOptions;
    private options: CheckBoxOptions;
    private targetElement: HTMLElement;
    private jQueryStatic: JQueryStatic;
    private valueChangeInProgress: boolean = false;

    /**
     * Creates an instance of CheckBox.
     *
     * @param {HTMLElement} targetElement The target element.
     * @memberof CheckBox
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;

        this.options = {
            isThreeState: true
        };
        this.checkBoxOptions = {};
    }

    /**
     * Bootstraps the element to dom via jquery.
     *
     * @param {(boolean | undefined)} value The value to set.
     * @param {string} text The text to set.
     * @memberof CheckBox
     */
    public bootstrap(value: boolean | undefined, text: string): void {
        const _this = this;
        // Helper variables to enable three-state
        this.options.text = text;
        this.dxCheckBoxElement = this.getJQueryElement().dxCheckBox({
            activeStateEnabled: false,
            focusStateEnabled: true,
            height: '100%',
            onValueChanged: (event) => {
                // Checkbox state order:   true         -> false     -> undefined
                if (this.onValueChanged && !_this.valueChangeInProgress) {
                    if (event.previousValue === false) {
                        this.valueChangeInProgress = true;
                        this.onValueChanged(true);
                        this.dxCheckBoxElement?.option('value', true);
                        this.valueChangeInProgress = false;

                        return;
                    } else if (event.previousValue === true) {
                        if (this.options.isThreeState === true) { // Skip undefined state when checkbox option is disabled
                            this.valueChangeInProgress = true;
                            this.onValueChanged(undefined);
                            this.dxCheckBoxElement?.option('value', null);
                            this.valueChangeInProgress = false;
                        } else {
                            this.valueChangeInProgress = true;
                            this.onValueChanged(false);
                            this.dxCheckBoxElement?.option('value', false);
                            this.valueChangeInProgress = false;
                        }

                        return;
                    } else if (event.previousValue === null || typeof event.previousValue === 'undefined') {
                        this.valueChangeInProgress = true;
                        this.onValueChanged(false);
                        this.dxCheckBoxElement?.option('value', false);
                        this.valueChangeInProgress = false;

                        return;
                    }
                }
            },
            text: text,
            value: value
        }).on('focus', () => {
            if (this.onFocus) {
                this.onFocus();
            }
        }).on('blur', () => {
            if (this.onBlur) {
                this.onBlur();
            }
        }).dxCheckBox('instance');
    }

    /**
     * Sets the given value.
     * Undefined will result in indeterminate state.
     * Will always bypass onValueChanged event so component can alyways be set/reset externally.
     *
     * @param {(boolean | undefined)} value     The value.
     * @memberof CheckBox
     */
    public setValue(value: boolean | undefined): void {
        this.getJQueryElement().dxCheckBox({
            value: value
        });
    }

    /**
     * Sets the check box options.
     * 
     * @param {CheckBoxOptions} options The checkbox options.
     * @memberof CheckBox
     */
    public setOptions(options: CheckBoxOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }
        this.options = options;

        // Create the DevExpress options object.
        const checkBoxOptions: DevExpress.ui.dxCheckBoxOptions = {};

        if (options.hasOwnProperty('text')) {
            checkBoxOptions.text = options.text;
        }

        if (options.hasOwnProperty('isThreeState')) {
            checkBoxOptions.enableThreeStateBehavior = options.isThreeState;
        }
        if (options.hasOwnProperty('tabIndex')) {
            checkBoxOptions.tabIndex = options.tabIndex;
        }
        // Update options
        this.checkBoxOptions = checkBoxOptions;

        // Set the options.
        this.getJQueryElement().dxCheckBox(this.checkBoxOptions);
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof CheckBox
     */
    public setValidity(isValid: boolean): void {
        if (this.dxCheckBoxElement) {
            this.dxCheckBoxElement.option('isValid', isValid);
        }
    }

    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof CheckBox
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.dxCheckBoxElement) {
            this.dxCheckBoxElement.option('disabled', isDisabled);
        }
    }

    /**
     * Gets the combobox instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} The instance.
     * @memberof CheckBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.jQueryStatic) {
            throw new Error('jQuery was not loaded.');
        }

        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }
}