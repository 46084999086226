import { WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents the request options for archive directory.
 *
 * @export
 * @class ArchiveDirectoryRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class ArchiveDirectoryRequestOptions extends WindreamRequestOptions {

    /**
     * Flag to include recursive archiving.
     *
     * @type {string}
     * @memberof ArchiveDirectoryRequestOptions
     */
    public recursive: boolean;

    /**
     * Creates an instance of ArchiveDirectoryRequestOptions.
     *
     * @param {WindreamIdentity} identity
     * @param {boolean} recursive
     * @memberof ArchiveDirectoryRequestOptions
     */
    public constructor(identity: WindreamIdentity, recursive: boolean) {
        super(identity);
        if (typeof recursive !== 'boolean') {
            throw new Error('Argument \'recursive\' is not valid');
        }
        this.recursive = recursive;
    }
}