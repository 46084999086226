import { IPopupButtonOptions } from './interfaces';
import { IPopup } from './interfaces/iPopup';
import { IPopupButton } from './interfaces/iPopupButton';

/**
 * This class represents a popup button.
 *
 * @export
 * @class PopupButton
 * @implements {IPopupButton}
 */
export class PopupButton implements IPopupButton {

    /**
     * The popup button options.
     *
     * @type {IPopupButtonOptions}
     * @memberof PopupButton
     */
    public options: IPopupButtonOptions;

    /**
     * The wrapped element.
     *
     * @type {JQuery<HTMLElement>} The wrapped element.
     * @memberof Popup
     */
    public $element: JQuery<HTMLElement>;

    private jQuery: JQueryStatic;
    private popup: IPopup;

    private dxButtonInstance: DevExpress.ui.dxButton;


    /**
     * Creates an instance of PopupButton.
     *
     * @param {IPopupButtonOptions} options The popup button options.
     * @memberof PopupButton
     */
    public constructor(options: IPopupButtonOptions, popup: IPopup, targetElement: HTMLElement, jQuery: JQueryStatic) {
        this.options = options;
        this.popup = popup;
        this.jQuery = jQuery;

        this.$element = this.jQuery(targetElement).dxButton({
            hint: this.options.label,
            onClick: () => {
                if (this.options.callback) {
                    this.options.callback(this.options.label);
                }

                this.popup.onButtonClicked(this);
            },
            stylingMode: 'text',
            text: this.options.label
        });

        // Assign dxButtonInstance
        this.dxButtonInstance = this.jQuery(this.$element).dxButton('instance');

        if (this.options.buttonId) {
            this.$element.attr('id', this.options.buttonId);
        }
        if (this.options.attributes) {
            this.options.attributes.forEach((attribute) => {
                this.$element.attr(attribute.attribute, attribute.value);
            });
        }
        if (this.options.classNames) {
            this.$element.addClass(this.options.classNames);
        }
    }
    /**
     * 
     * Enable Button.
     * 
     * @memberof PopupButton
     */
    public enable(): void {
        if (this.dxButtonInstance) {
            this.dxButtonInstance.option('disabled', false);
        }
    }
    /**
     *
     * Disable Button.
     *
     * @memberof PopupButton
     */
    public disable(): void {
        if (this.dxButtonInstance) {
            this.dxButtonInstance.option('disabled', true);
        }
    }

}