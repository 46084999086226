import { ComponentConfig, IConfigLoader } from '../config';
import { Logger, ViewConfig } from '../dynamicWorkspace';
import { ILanguageManager } from '../language';
import { IOperation, Pipeline } from '../pipeline';
import { ComponentInjector } from './componentInjector';
import { ComponentOperationParameter } from './componentOperationParameter';
import { IComponentLoader } from './interfaces';
import { IComponentLoadMetadata } from './interfaces/iComponentLoadMetadata';
import { ComponentLoadManifest } from './models';

/**
 * The component loader will fetch files which are used by a component, component config etc.
 *
 * @export
 * @class ComponentLoader
 * @implements {IComponentLoader}
 */
export class ComponentLoader implements IComponentLoader {
  private configLoader: IConfigLoader;
  private logger: Logger;
  private className = 'ComponentLoader';
  private languageManager: ILanguageManager;
  private componentInjector: ComponentInjector;
  private loadComponentPipeline: Pipeline<ComponentOperationParameter>;

  /**
   * Creates an instance of ComponentLoader.
   * 
   * @param {IConfigLoader} configLoader The config loader.
   * @param {Logger} logger The logger.
   * @param {ILanguageManager} languageManager The language manager.
   * @param {ComponentInjector} componentInjector The component injector.
   * @memberof ComponentLoader
   */
  public constructor(configLoader: IConfigLoader, logger: Logger,
    languageManager: ILanguageManager, componentInjector: ComponentInjector) {
    this.configLoader = configLoader;
    this.logger = logger;
    this.languageManager = languageManager;
    this.componentInjector = componentInjector;
    this.loadComponentPipeline = new Pipeline<ComponentOperationParameter>();
  }

  /**
   * Registers the given pipeline operation.
   *
   * @param {IOperation<any>} operation The pipeline operation.
   * @memberof ComponentLoader
   */
  public registerLoadComponentPipelineOperation(operation: IOperation<ComponentOperationParameter>): void {
    if (!operation) {
      throw new Error('The argument "operation" must be defined.');
    }

    if (this.loadComponentPipeline) {
      this.loadComponentPipeline.register(operation);
    }
  }

  /**
   * Load component data from URLs.
   *
   * @param {ComponentLoadManifest} componentLoadManifest The load manifest.
   * @returns {Promise<IComponentLoadMetadata>} A promise, which will resolve with metadata.
   * @memberof ComponentLoader
   */
  public async loadComponentData(componentLoadManifest: ComponentLoadManifest): Promise<IComponentLoadMetadata> {
    return new Promise<IComponentLoadMetadata>((resolve, reject) => {
      if (!componentLoadManifest) {
        this.logger.error(this.className, 'loadComponent', 'componentLoadManifest not set');
        reject(new Error('componentLoadManifest not set'));
        return;
      }
      Promise.all([this.loadDependentConfig(componentLoadManifest),
      this.languageManager.loadLanguageProviderFromUrl(this.languageManager.getLanguageFileUrl(componentLoadManifest.componentId))]).then((loadedValues) => {

        const componentData: IComponentLoadMetadata = {
          componentConfig: loadedValues[0],
          languageProvider: loadedValues[1]
        };

        // Run the load component pipeline.
        this.executeComponentPipeline(componentData);

        resolve(componentData);
      }).catch((error) => reject(error));
    });
  }

  /**
   * Gets the required component to prefetch.
   *
   * @param {ViewConfig} [viewConfig] The view config.
   * @returns {string[]} An array of component ids.
   * @memberof ComponentLoader
   */
  public getRequiredComponentsToPrefetch(viewConfig?: ViewConfig): string[] {
    const componentsToLoad = !viewConfig ? [] : viewConfig.components.map((config) => config.component)
      .filter((value, index, self) => self.indexOf(value) === index);
    // Always load error component as well
    componentsToLoad.push('com.windream.error');
    return componentsToLoad;
  }

  /**
   * Executes the component pipeline.
   *
   * @private
   * @param {IComponentLoadMetadata} componentData The component data.
   * @memberof ComponentLoader
   */
  private executeComponentPipeline(componentData: IComponentLoadMetadata): void {
    if (this.loadComponentPipeline) {
      const loadComponentPipelineParameter = new ComponentOperationParameter(componentData, this.languageManager);
      this.loadComponentPipeline.invoke(loadComponentPipelineParameter);
    }
  }

  /**
   * Load config which is dependent on each other.
   *
   * @private
   * @param {ComponentLoadManifest} componentLoadManifest The load manifest.
   * @returns {Promise<ComponentConfig>} A promise, which will resolve with the component config.
   * @memberof ComponentLoader
   */
  private async loadDependentConfig(componentLoadManifest: ComponentLoadManifest): Promise<ComponentConfig> {
    const config = await this.configLoader.loadComponentConfig(componentLoadManifest);
    if (!config) {
      throw new Error('ComponentConfig is undefined');
    }
    const componentPath = componentLoadManifest.componentBasePath;
    const componentFolderPath = componentPath + componentLoadManifest.componentId + '/';
    const entryFileName = config.entry || 'index.js';
    const entryFilePath = componentFolderPath + entryFileName;
    await this.componentInjector.injectComponentScript(entryFilePath, componentLoadManifest.componentId);
    return config;
  }

  /**
   * Load the component template from a specific URL.
   *
   * @param {string} templateFileUrl The URL.
   * @returns {Promise<string>} A promise, which will resolve with the tempalte string.
   * @memberof ComponentLoader
   */
  public async loadComponentTemplate(templateFileUrl: string): Promise<string> {
    const response = await fetch(templateFileUrl);
    return response.text();
  }

  /**
   * Loads the data for many components.
   *
   * @param {ComponentLoadManifest[]} componentLoadManifests The load manifest.
   * @returns {Promise<IComponentLoadMetadata[]>} A promise, which will resolve with every components metadata.
   * @memberof ComponentLoader
   */
  public async loadComponentsData(componentLoadManifests: ComponentLoadManifest[]): Promise<IComponentLoadMetadata[]> {
    const componentsMetadata = new Array<IComponentLoadMetadata>();
    if (componentLoadManifests.length === 0) {
      return componentsMetadata;
    }

    componentLoadManifests.forEach(async (manifest) => {
      const componentData = await this.loadComponentData(manifest);

      // Run the load component pipeline.
      this.executeComponentPipeline(componentData);

      componentsMetadata.push(componentData);
    });

    return componentsMetadata;
  }
}