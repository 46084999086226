import { WEBSERVICE_APPLICATION_ERROR_CODES, WEBSERVICE_ERROR_CODES } from '../errors';

/**
 * An Error that occured during a WebService request.
 *
 * @export
 * @class WebServiceError
 * @extends {ExternalError}
 */
export class WebServiceError extends Error {

    /**
     * Error code provided by the WebService.
     *
     * @type {number}
     * @memberof WebServiceError
     */
    public errorCode?: WEBSERVICE_ERROR_CODES | WEBSERVICE_APPLICATION_ERROR_CODES;


    /**
     * Creates an instance of WebServiceError.
     * 
     * @param {string} text Error text.
     * @param {WEBSERVICE_ERROR_CODES | WEBSERVICE_APPLICATION_ERROR_CODES} errorCode Error code provided by the WebService.
     * @memberof WebServiceError
     */
    public constructor(text: string, errorCode?: WEBSERVICE_ERROR_CODES | WEBSERVICE_APPLICATION_ERROR_CODES) {
        super(text);
        this.errorCode = errorCode;
        this.name = 'WebServiceError';
    }
}