
/**
 * Represents the metadata for a toolbar action.
 *
 * @export
 * @class ToolbarActionMetadata
 */
export class ToolbarActionMetadata {

    /**
     * The name of the toolbar action.
     *
     * @type {string}
     * @memberof ToolbarActionMetadata
     */
    public name: string;

    /**
     * The identifier of the toolbar action.
     *
     * @type {string}
     * @memberof ToolbarActionMetadata
     */
    public id: string;

    /**
     * The supported type.
     *
     * @type {string[]}
     * @memberof ToolbarActionMetadata
     */
    public supportedTypes: string[];

    /**
     * The description of the toolbar action.
     *
     * @type {string}
     * @memberof ToolbarActionMetadata
     */
    public description?: string;

    /**
     * The version of the toolbar action.
     *
     * @type {string}
     * @memberof ToolbarActionMetadata
     */
    public version?: string;

    /**
     * The author of the toolbar action.
     *
     * @type {string}
     * @memberof ToolbarActionMetadata
     */
    public author?: string;

    /**
     * The entry of the toolbar action.
     *
     * @type {string}
     * @memberof ToolbarActionMetadata
     */
    public entry?: string;

    /**
     * Creates an instance of ToolbarActionMetadata.
     * 
     * @param {string} id The identifier of the toolbar action.
     * @param {string} name The name of the toolbar action.
     * @param {string[]} supportedTypes The name of the toolbar action.
     * @memberof ToolbarActionMetadata
     */
    public constructor(id: string, name: string, supportedTypes: string[]) {
        this.id = id;
        this.name = name;
        this.supportedTypes = supportedTypes;
    }
}