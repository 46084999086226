import { IServiceRequest } from '../ajaxHandler/interfaces/iServiceRequest';
import { HttpDataProvider } from './httpDataProvider';
import { IHttpResourcePointer } from '.';


/**
 * This class provides specific methods which are needed to get data from the windream Web Service.
 * 
 * @export
 * @class WindreamDataProvider
 * @extends {HttpDataProvider}
 */
export class WindreamDataProvider extends HttpDataProvider {

    /**
     * This handler will be called before the execution.
     * 
     * @protected
     * @param {IServiceRequest} request 
     * @returns {IServiceRequest} 
     * @memberof WindreamDataProvider
     */
    protected beforeRequestHandler(request: IServiceRequest): IServiceRequest {

        const processedRequest = super.beforeRequestHandler(request);

        if (processedRequest) {
            const httpRessourcePtr = processedRequest.resourcePointer as IHttpResourcePointer;

            if (httpRessourcePtr) {

                // Try to add the required http headers.
                if (!httpRessourcePtr.httpHeaders) {
                    httpRessourcePtr.httpHeaders = new Map<string, string>();
                }

                httpRessourcePtr.httpHeaders.set('Accept', 'application/json, text/plain, */*');

                if (!httpRessourcePtr.parameter || !(httpRessourcePtr.parameter instanceof FormData)) {
                    httpRessourcePtr.httpHeaders.set('Content-Type', 'application/json;charset=UTF-8');
                }
            }
        }

        return processedRequest;
    }

}