/**
 * Represents a slice for upload.
 *
 * @export
 * @class UploadFileSliceInfo
 */
export class UploadFileSliceInfo {

    /**
     * Whether this slice is the last slice.
     *
     * @type {boolean}
     * @memberof UploadFileSliceInfo
     */
    public isLastSlice: boolean;

    /**
     * The current byte index of this slice.
     *
     * @type {number}
     * @memberof UploadFileSliceInfo
     */
    public currentByteIndex: number;

    /**
     * The sliced blob which belongs to this info.
     *
     * @type {Blob}
     * @memberof UploadFileSliceInfo
     */
    public enqueuedBlob: Blob;

    /**
     * The timeframe in which time this file was uploaded.
     *
     * @type {number}
     * @memberof UploadFileSliceInfo
     */
    public uploadTimeFrame: number;

    /**
     * Creates an instance of UploadFileSliceInfo.
     * 
     * @param {boolean} isLastSlice Whether this slice is the last slice.
     * @param {number} currentByteIndex The current byte index of this slice.
     * @param {Blob} enqueuedBlob The sliced blob which belongs to this info.
     * @memberof UploadFileSliceInfo
     */
    public constructor(isLastSlice: boolean, currentByteIndex: number, enqueuedBlob: Blob) {
        this.isLastSlice = isLastSlice;
        this.currentByteIndex = currentByteIndex;
        this.enqueuedBlob = enqueuedBlob;
        this.uploadTimeFrame = 0;
    }
}