import { Utils } from '../common';
import { ComponentConfig, ComponentInstanceConfig, InConfig, ITrigger, OutConfig, ViewConfig } from '../config';
import { SubViewConfig } from '../loader';
import { Connection, DataTypeChecker, PubSubConfig } from '../pubSub';
import { IPubSubConnectionHelper } from './interfaces';
import { NewConnection } from '.';

/**
 * Helper to filter components and connections for PubSub.
 * 
 * @export
 * @class PubSubConnectionHelper
 */
export class PubSubConnectionHelper implements IPubSubConnectionHelper {

    private availableComponents: Map<string, ComponentConfig>;
    private readonly ERROR_COMPONENT_ID = 'com.windream.error';

    /**
     * Creates an instance of PubSubConnectionHelper.
     * @param {Map<string, ComponentConfig>} availableComponents 
     * @memberof PubSubConnectionHelper
     */
    public constructor(availableComponents: Map<string, ComponentConfig>) {
        this.availableComponents = availableComponents;
    }

    /**
     * Filters the given PubSub outs to get only those matching at least one PubSub in of the given in component.
     * 
     * @param {OutConfig[]} pubSubOuts PubSub outs to filter.
     * @param {ComponentInstanceConfig} inComponent In component to find matches for.
     * @returns {OutConfig[]} Valid PubSub outs for the given in component.
     * @memberof PubSubConnectionHelper
     */
    public filterPubSubOutByInComponent(pubSubOuts: OutConfig[], inComponent: ComponentInstanceConfig): OutConfig[] {
        const inComponentInfo = this.getComponentInfoByComponentId(inComponent.component);
        if (!inComponentInfo) {
            throw new Error(`No component info found for '${inComponent.component}'`);
        }
        const fittingPubSubs = pubSubOuts.filter((pubSubOut) => {
            return !!inComponentInfo.pubSub.in.find((pubSubIn) => DataTypeChecker.checkDataTypeMatch(pubSubOut, pubSubIn));
        });

        return fittingPubSubs.sort((a, b) => {
            if (a.dataType === DataTypeChecker.DATATYPE_ANY && b.dataType === DataTypeChecker.DATATYPE_ANY) {
                return 0;
            }
            if (a.dataType === DataTypeChecker.DATATYPE_ANY) {
                return 1;
            }
            if (b.dataType === DataTypeChecker.DATATYPE_ANY) {
                return -1;
            }
            return 0;
        });
    }

    /**
     * Returns all PubSub In connections for the given component that are required but missing.
     * 
     * @param {InConfig[]} pubSubConnections Already set connections.
     * @param {ComponentInstanceConfig} component The component.
     * @returns {InConfig[]} All missing required in connections.
     * @memberof PubSubConnectionHelper
     */
    public getMissingInConnectionsForComponent(pubSubConnections: Connection[], component: ComponentInstanceConfig): InConfig[] {
        const componentInfo = this.getComponentInfoByComponentId(component.component);
        if (!componentInfo && component.component !== this.ERROR_COMPONENT_ID) {
            throw new Error(`No component info found for '${component.component}'`);
        }
        if (!componentInfo || !componentInfo.pubSub.in) {
            return new Array<InConfig>();
        }
        const requiredConnections = componentInfo.pubSub.in.filter((connection) => connection.isRequired);
        const misingRequiredConnections = requiredConnections.filter((connection) => {
            const isMissing = !pubSubConnections.find((setConnection) => setConnection.parameter === connection.name);
            return isMissing;
        });
        return misingRequiredConnections;
    }

    /**
     * Filters the given components to return only those that have a PubSub in property matching the given out component.
     * 
     * @param {ComponentInstanceConfig} outComponent Instance of the out component.
     * @param {ComponentInstanceConfig[]} componentInstances Possible component instances for in.
     * @returns {ComponentInstanceConfig[]} Component instances that are a possible PubSub in for the given out component.
     * @memberof PubSubConnectionHelper
     */
    public getInComponentsMatchingPubSub(outComponent: ComponentInstanceConfig, componentInstances: ComponentInstanceConfig[]): ComponentInstanceConfig[] {
        const outComponentInfo = this.getComponentInfoByComponentId(outComponent.component);
        if (!outComponentInfo) {
            throw new Error(`No component info found for '${outComponent.component}'`);
        }
        if (!outComponentInfo.pubSub || !outComponentInfo.pubSub.out) {
            return [];
        }
        return componentInstances.filter((component) => {
            // Avoid self connection in drag event
            if (component.guid === outComponent.guid) {
                return false;
            }
            if (component.component === this.ERROR_COMPONENT_ID) {
                return false;
            }
            const componentInfo = this.getComponentInfoByComponentId(component.component);
            if (!componentInfo) {
                throw new Error(`No component info found for '${component.component}'`);
            }

            let isValid = false;
            if (!componentInfo.pubSub || !componentInfo.pubSub.in) {
                return false;
            }
            componentInfo.pubSub.in.forEach((pubSubIn) => {
                outComponentInfo.pubSub.out.forEach((outComponentPubSubIn) => {
                    if (DataTypeChecker.checkDataTypeMatch(outComponentPubSubIn, pubSubIn)) {
                        isValid = true;
                    }
                });
            });
            return isValid;
        });
    }

    /**
     * Returns a list of all components that have at least one PubSub in property that matches the new connection's out propertiy's data type.
     * Only looks for components that are located on the selected sub view.
     * 
     * @param {NewConnection} newConnection 
     * @param {SubViewConfig[]} subViews 
     * @param {ComponentInstanceConfig[]} componentInstances 
     * @returns {any[]} 
     * @memberof PubSubConnectionHelper
     */
    public getComponentsMatchingPubSub(newConnection: NewConnection, subViews: SubViewConfig[], componentInstances: ComponentInstanceConfig[]): ComponentInstanceConfig[] {
        if (!newConnection.outComponentGuid) {
            return [];
        }
        // Get data type of connection from component info
        const componentInfo = this.getComponentInfoByComponentGuid(newConnection.outComponentGuid, componentInstances);
        if (!componentInfo) {
            return [];
        }

        const pubSubPossibility = componentInfo.pubSub.out.find((pubSub) => {
            return pubSub.name === newConnection.outEvent;
        });
        const dataType = pubSubPossibility ? pubSubPossibility.dataType : '';

        // Find components on the given subview
        const subViewConfig = subViews.find((subViewConfig: SubViewConfig) => {
            return subViewConfig.id === newConnection.subViewId;
        });
        if (!subViewConfig) {
            throw new Error(`Sub view with ID ${newConnection.subViewId} not found in view`);
        }
        // Avoid self connection via button
        const componentsForSubView = componentInstances.filter((component) => {
            return subViewConfig.components.indexOf(component.guid) !== -1 && component.guid !== newConnection.outComponentGuid;
        });

        // Filter all components if they have at least one PubSub in property with the given data type
        const components = componentsForSubView.filter((component) => {
            const componentInfo = this.getComponentInfoByComponentGuid(component.guid, componentInstances);
            let hasDataType = false;

            if (componentInfo && componentInfo.pubSub.in) {
                componentInfo.pubSub.in.forEach((pubSub) => {
                    if (dataType && DataTypeChecker.checkDataTypeMatchWithString(pubSub, dataType)) {
                        hasDataType = true;
                    }
                });
            }

            return hasDataType;
        });
        return components;
    }


    /**
     * Filters a given list of PubSub properties to match a new connection.
     * Used to filter the given PubSub properties so that only those with an in property
     * that matches the out property of the new connection are returned.
     * 
     * @param {InConfig[]} pubSubs PubSub in connectio
     * @param {NewConnection} newConnection 
     * @param {ComponentInstanceConfig[]} componentInstances 
     * @returns {InConfig[]} 
     * @memberof PubSubConnectionHelper
     */
    public getPubSubFilteredByDataType(pubSubs: InConfig[], newConnection: NewConnection, componentInstances: ComponentInstanceConfig[]): InConfig[] {
        if (!newConnection.outComponentGuid) {
            return [];
        }

        const componentInfo = this.getComponentInfoByComponentGuid(newConnection.outComponentGuid, componentInstances);
        if (!componentInfo) {
            return [];
        }

        const pubSubOutConfig = componentInfo.pubSub.out;

        if (!pubSubOutConfig) { // Return empty array if component selected as 'out' has no outgoing connections
            return [];
        }

        // Filter possible PubSub properties by data type of the new connection's out
        const pubSubPossibility = pubSubOutConfig.find((pubSub) => {
            return pubSub.name === newConnection.outEvent;
        });
        if (!pubSubPossibility) {
            return [];
        }

        return pubSubs.filter((pubSub) => {
            return DataTypeChecker.checkDataTypeMatch(pubSubPossibility, pubSub);
        }).sort((a, b) => {
            if (a.dataType === DataTypeChecker.DATATYPE_ANY && b.dataType === DataTypeChecker.DATATYPE_ANY) {
                return 0;
            }
            if (a.dataType === DataTypeChecker.DATATYPE_ANY) {
                return 1;
            }
            if (b.dataType === DataTypeChecker.DATATYPE_ANY) {
                return -1;
            }
            return 0;
        });
    }

    /**
     * Remove unused transitions from view config.
     *
     * @param {ViewConfig} newViewConfig The config to clean.
     * @returns {ViewConfig}  {ViewConfig} The clean view config.
     * @memberof PubSubConnectionHelper
     */
    public removeUnusedTransitionsFromViewConfig(newViewConfig: ViewConfig): ViewConfig {
        // Filter and remove unused transition
        for (const viewConfigTriggerHandlers in newViewConfig.triggers) {
            if (!newViewConfig.triggers.hasOwnProperty(viewConfigTriggerHandlers)) {
                continue;
            }
            const triggerHandlers = newViewConfig.triggers[viewConfigTriggerHandlers];
            if (triggerHandlers) {
                const deletedTransitions = new Array<number>();
                triggerHandlers.forEach((triggerHandler, index) => {
                    const isDeleted = !newViewConfig.pubSub.find((pubSubConfig) => {
                        return triggerHandler.inId === pubSubConfig.in[0].componentGuid + pubSubConfig.in[0].parameter
                            && triggerHandler.outId === pubSubConfig.out[0].componentGuid + pubSubConfig.out[0].parameter;
                    });

                    if (isDeleted) {
                        deletedTransitions.push(index);
                    }
                });
                newViewConfig.triggers[viewConfigTriggerHandlers] = triggerHandlers.filter((_trigger, index) => deletedTransitions.indexOf(index) === -1);
            }
        }
        return newViewConfig;
    }

    /**
     * Returns a list of all possible components that have a out PubSub property.
     *
     * @private
     * @returns {ComponentInstanceConfig[]}
     *
     * @memberof PubSubConfigurationComponent
     */
    public getComponentsWithOut(componentInstances: ComponentInstanceConfig[]): ComponentInstanceConfig[] {
        const components = componentInstances.filter((component: ComponentInstanceConfig) => {
            const componentInfo = this.getComponentInfoByComponentGuid(component.guid, componentInstances);
            return (componentInfo && componentInfo.pubSub && componentInfo.pubSub.out && componentInfo.pubSub.out.length > 0);
        });
        return components;
    }


    /**
     * Returns the contents of windream-component.json for the component with the given GUID.
     * 
     * @param {string} guid GUID of the component to get ComponentConfig for.
     * @param {ComponentInstanceConfig[]} componentInstances All currently used component instances.
     * @returns {(ComponentConfig | undefined)} The matching component config or null if nothing matches.
     * @memberof PubSubConnectionHelper
     */
    public getComponentInfoByComponentGuid(guid: string, componentInstances: ComponentInstanceConfig[]): ComponentConfig | null {
        const component = componentInstances.find((component) => {
            return component.guid === guid;
        });

        if (!component) {
            return null;
        }

        const componentId = component.component;

        return this.getComponentInfoByComponentId(componentId);
    }

    /**
     * Get component configuration by component ID.
     * 
     * @param {string} componentId ID of the component to get ComponentConfig for.
     * @returns {(ComponentConfig | null)} ComponentConfig or null if invalid component ID was given.
     * @memberof PubSubConnectionHelper
     */
    public getComponentInfoByComponentId(componentId: string): ComponentConfig | null {
        return this.availableComponents.get(componentId) || null;
    }

    /**
     * Transforms the NewConnection model into a PubSubConfig object.
     * Will throw if a property is missing.
     * 
     * @param {NewConnection} newConnection New connection to parse.
     * @returns {PubSubConfig} Equivalent PubSubConfig representing the NewConnection.
     * @memberof PubSubConnectionHelper
     */
    public transformToPubSubConfig(newConnection: NewConnection): PubSubConfig {
        if (!(newConnection.inComponentGuid && newConnection.inEvent && newConnection.outComponentGuid && newConnection.outEvent)) {
            throw new Error('Unable to create PubSubConfig as some properties are missing');
        }
        const pubSubConfig = new PubSubConfig();
        pubSubConfig.in = [
            {
                parameter: newConnection.inEvent,
                componentGuid: newConnection.inComponentGuid
            }
        ];
        pubSubConfig.out = [
            {
                parameter: newConnection.outEvent,
                componentGuid: newConnection.outComponentGuid
            }
        ];
        pubSubConfig.executeForEmptyData = newConnection.executeForEmptyValue;
        // TODO: Check for transitions
        return pubSubConfig;
    }

    /**
     * Transforms the NewConnection model into a ITrigger object.
     * Will throw if a property is missing.
     * 
     * @param {NewConnection} newConnection New connection to parse.
     * @returns {ITrigger} Equivalent ITrigger configuration representing the NewConnection.
     * @memberof PubSubConnectionHelper
     */
    public transformToTriggerConfig(newConnection: NewConnection): ITrigger {
        if (!(newConnection.inComponentGuid && newConnection.inEvent && newConnection.outComponentGuid && newConnection.outEvent && newConnection.transition && newConnection.subViewId)) {
            throw new Error('Unable to create PubSubConfig as some properties are missing');
        }
        const trigger: ITrigger = {};
        trigger[newConnection.transition] = [{
            inId: newConnection.inComponentGuid + newConnection.inEvent,
            outId: newConnection.outComponentGuid + newConnection.outEvent,
            value: newConnection.subViewId
        }];
        return trigger;
    }


    /**
     * Checks whether the given value is considered empty.
     * Empty is:
     * Emtpy array
     * null
     * undefined
     *
     * @static
     * @param {*} value Value to check.
     * @returns {boolean} Whether the value is considered empty.
     * @memberof PubSubConnectionHelper
     */
    public static isValueEmpty(value: any): boolean {
        if (Utils.Instance.isArray(value) && value.length === 0) {
            return true;
        }
        if (!Utils.Instance.isDefined(value)) {
            return true;
        }
        return false;
    }
}