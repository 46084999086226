import { Utils } from '../common';
import { ITemplateExtension } from '../extensions';
import { IAppMenuHandler } from './interfaces';
import { AppMenuItem, AppMenuViewModel } from './models';
/**
 * Handler for the AppMenus.
 * Creates and manages instances of AppMenus to render in given containers.
 * 
 * @export
 * @class AppMenuHandler
 * @implements {IAppMenuHandler}
 */
export class AppMenuHandler implements IAppMenuHandler {
    private element: HTMLElement;
    private templateExtension: ITemplateExtension;

    private menuItems: AppMenuItem[];
    private eventListener?: (e: Event) => void;
    private isRendered: boolean;
    private viewModel: AppMenuViewModel;
    private dropdownPane: HTMLElement | null;

    /**
     * Creates an instance of AppMenuHandler.
     * @param {HTMLElement} element 
     * @param {ITemplateExtension} templateExtension 
     * @memberof AppMenuHandler
     */
    public constructor(element: HTMLElement, templateExtension: ITemplateExtension) {
        this.element = element;
        this.templateExtension = templateExtension;

        this.menuItems = new Array<AppMenuItem>();
        this.viewModel = new AppMenuViewModel(Utils.Instance.getRandomString());
        this.viewModel.menuItems = this.menuItems;
        this.isRendered = false;

        this.dropdownPane = null;
    }

    /**
     * Adds a single AppMenuItem to the list of menu items.
     * Will update the view if AppMenu was already rendered.
     * 
     * @param {AppMenuItem} menuItem Menu item to add.
     * @memberof AppMenuHandler
     */
    public addMenuItem(menuItem: AppMenuItem): void {
        this.addMenuItems([menuItem]);
    }


    /**
     * Adds multiple AppMenuItem to the list of menu items.
     * Will update the view if AppMenu was already rendered.
     * 
     * @param {AppMenuItem} menuItems Menu items to add.
     * @memberof AppMenuHandler
     */
    public addMenuItems(menuItems: AppMenuItem[]): void {
        this.menuItems.push(...menuItems);
        this.menuItems.sort((a, b) => a.position - b.position);

        if (this.isRendered) {
            this.render();
        }
    }

    /**
     * Renders the AppMenu into the container element.
     * 
     * @memberof AppMenuHandler
     */
    public render(): void {

        if (!this.isRendered) {
            // TODO: Remove manual index setting if issue with binding plugin is resolved
            this.templateExtension.render(this.element, require('./templates/appMenu.html'), this.viewModel, true);
            this.dropdownPane = this.element.querySelector<HTMLElement>('[data-dropdown]');

            if (!this.dropdownPane) {
                return;
            }
            this.templateExtension.render(this.dropdownPane, require('./templates/appMenuMenu.html'), this.viewModel, true);
            if (window) {
                // Close the drop down if a contextmenu appears and doesn't hit a target of the drop down.
                window.addEventListener('contextmenu', (event) => {
                    const indexString = Utils.Instance.getWdAttribute(event.target as HTMLElement, 'menu-index');
                    if (!indexString) {
                        this.closeDropdown();
                    }
                });
            }
            this.dropdownPane.addEventListener('click', this.eventListener = (e: Event) => {
                const indexString = Utils.Instance.getWdAttribute(e.target as HTMLElement, 'menu-index');
                if (indexString) {
                    const indexStrings = indexString.split('-');

                    const indexes = indexStrings.map((indexString) => parseInt(indexString, 10));

                    let subMenu = this.menuItems;
                    for (let i = 0; i < indexes.length; i++) {
                        if (i === indexes.length - 1) {
                            const fn = subMenu[indexes[i]].onClick;
                            if (fn) {
                                e.preventDefault();
                                e.stopImmediatePropagation();
                                if (!subMenu[indexes[i]].isDisabled) {
                                    fn();
                                    this.closeDropdown();
                                }
                            } else {
                                e.preventDefault();
                                e.stopImmediatePropagation();
                            }
                        } else {
                            if (subMenu[indexes[i]].hasChildren) {
                                subMenu = subMenu[indexes[i]].children;
                            }
                        }
                    }
                }
            }, true);
            // @ts-ignore - Disabled because of Foundation usage
            if (typeof window['$'] !== 'undefined' && this.dropdownPane) {
                $(this.dropdownPane).appendTo('body').foundation();
            }
            this.isRendered = true;
        } else {
            if (this.dropdownPane) {
                this.templateExtension.render(this.dropdownPane, require('./templates/appMenuMenu.html'), this.viewModel, true);
                // @ts-ignore - Disabled because of Foundation usage
                if (typeof window['$'] !== 'undefined') {
                    // @ts-ignore - Disabled because of Foundation usage
                    Foundation.reInit($(this.dropdownPane).find('[data-dropdown-menu]'));
                }
            }
        }
    }

    /**
     * Clears the container element.
     * 
     * @memberof AppMenuHandler
     */
    public clear(): void {
        this.isRendered = false;
        this.menuItems.length = 0;
        if (this.eventListener) {
            this.element.removeEventListener('click', this.eventListener, true);
        }
        this.element.innerHTML = '';
        // Remove attributes set by TemplateExtension so that future calls have initial state
        // TODO: Move to TemplateExtension
        this.element.removeAttribute('data-wd-template-id');
        this.element.removeAttribute('data-wd-rendered');
        if (this.dropdownPane) {
            this.dropdownPane.remove();
        }
    }

    /**
     * Close the dropdown pane.
     * 
     * @private
     * @memberof AppMenuHandler
     */
    private closeDropdown(): void {
        // @ts-ignore - Disabled because of Foundation usage
        if (typeof window['$'] !== 'undefined' && this.dropdownPane) {
            $(this.dropdownPane).foundation('close');
        }
    }
}