import { Actions } from '../../../typings/windreamWebService/Windream.WebService.Permissions';
import { HttpResponse } from '../../ajaxHandler/httpResponse';
import { TokenProvider } from '../../authentication';
import { GlobalConfig } from '../../config';
import { BinaryHttpResourcePointer, BinaryHttpResponseTypes } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { DownloadBase } from '../base/downloadBase';
import { NativeDownloadTrigger } from '../base/nativeDownloadTrigger';
import { IdentityMapper } from '../identityMapper';
import { DownloadRequestOptions, WindreamRequestOptions } from '../models';
import { CanPerformAction } from '../permissions';
import { IFileSaver } from './iFileSaver';


/**
 * The Download action is used to download a document from windream
 * 
 * @export
 * @class Download
 * @extends {DownloadBase}
 */
export class DownloadDocument extends DownloadBase {
    /**
     * Url to invoke.
     *
     * @protected
     * @type {string}
     * @memberof DownloadDocument
     */
    protected url: string;

    /**
     * Permissions to check.
     *
     * @protected
     * @type {Actions}
     * @memberof DownloadDocument
     */
    protected requiredPermissions: Actions;

    /**
     * Creates an instance of DownloadDocument.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {CanPerformAction} canPerformActionService
     * @param {IFileSaver} fileSaver
     * @param {NativeDownloadTrigger} nativeDownloadTrigger
     * @param {TokenProvider} [tokenProvider]
     * @memberof DownloadDocument
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, canPerformActionService: CanPerformAction,
        fileSaver: IFileSaver, nativeDownloadTrigger: NativeDownloadTrigger, tokenProvider?: TokenProvider) {
        super(requestExecutor, globalConfig, logger, canPerformActionService, fileSaver, nativeDownloadTrigger, tokenProvider);

        this.className = 'DownloadDocument';

        this.url = this.globalConfig.windreamWebServiceURL + '/Documents/Download';
        this.requiredPermissions = Actions.ReadContent;
    }

    /**
     * Downloads a specified document.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {DownloadRequestOptions} downloadRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolve if the download was a success depending on useBuffer option.
     * @memberof DownloadDocument
     */
    public async do(downloadRequestOptions: DownloadRequestOptions): Promise<void> {
        if (downloadRequestOptions.useBuffer) {
            return this.downloadUsingBuffer(downloadRequestOptions);
        }

        return Promise.all([this.canPerformActionService.do({
            identity: downloadRequestOptions.identity,
            actions: this.requiredPermissions
        }), this.tokenProvider?.getToken()]).then((results) => {
            const permission = results[0];
            const token = results[1];
            if (!token) {
                return Promise.reject(new Error('No token received'));
            }
            const canRead = (permission.allowedActions & this.requiredPermissions) === this.requiredPermissions;
            if (!canRead) {
                this.logger.error(this.className, 'do', 'No permission to perform action', permission);
                return Promise.reject(new Error('No permission to read content'));
            }
            const parameter = IdentityMapper.getWebServiceGetParameter(downloadRequestOptions.identity);
            const tempUrl = this.url + (this.url.includes('?') ? '&' : '?') + parameter + '&access_token=' + token;
            // Trigger save
            this.nativeDownloadTrigger.download(tempUrl, permission.identity.name);
        });
    }

    /**
     * Downloads the file by buffering it.
     * This is the behavior as it was before 7.0.11 and is included for legacy reasons.
     * The Promise received by this method only resolves after the download.
     *
     * @private
     * @param {WindreamRequestOptions} windreamRequestOptions Request options received.
     * @returns {Promise<void>} Promise to resolve after the download.
     * @memberof DownloadDocument
     */
    private downloadUsingBuffer(windreamRequestOptions: WindreamRequestOptions): Promise<void> {

        return new Promise<void>((resolve, reject) => {
            const parameter = IdentityMapper.getWebServiceGetParameter(windreamRequestOptions.identity);
            const tempUrl = this.url + (this.url.includes('?') ? '&' : '?') + parameter;
            const resourcePointer = new BinaryHttpResourcePointer('GET', tempUrl, BinaryHttpResponseTypes.ARRAYBUFFER);
            this.requestExecutor.executeRequest(resourcePointer, windreamRequestOptions.requestOptions)
                .then((response: HttpResponse<any>) => {
                    if (!response.data.hasOwnProperty('HasErrors')) {
                        // Success
                        try {
                            const fileName = this.getFileNameFromResponse(response, windreamRequestOptions.identity);
                            this.fileSaver.saveAs(response.data, fileName);
                            resolve();
                        } catch (error) {
                            this.logger.error(this.className, 'downloadUsingBuffer', 'Failed to save file', error);
                            reject(error);
                        }
                    } else {
                        this.logger.error(this.className, 'downloadUsingBuffer', 'Failed to download identity');
                        reject(new Error('Encountered errors during download'));
                    }

                }).catch((error) => {
                    this.logger.error(this.className, 'downloadUsingBuffer', 'Failed to execute request', error);
                    reject(error);
                });
        });
    }
}