import { WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from './windreamRequestOptions';


/**
 * Represents worklock request options.
 *
 * @export
 * @class WorklockRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class WorklockRequestOptions extends WindreamRequestOptions {

    /**
     * Whether the identity should be downloaded on success.
     *
     * @type {boolean}
     * @memberof WorklockRequestOptions
     */
    public downloadOnSuccess: boolean;

    /**
     * Creates an instance of WorklockRequestOptions.
     * @param {WindreamIdentity} identity
     * @param {boolean} downloadOnSuccess
     * @memberof WorklockRequestOptions
     */
    public constructor(identity: WindreamIdentity, downloadOnSuccess: boolean) {
        super(identity);
        if (typeof downloadOnSuccess !== 'boolean') {
            throw new Error('Argument \'downloadOnSuccess\' is not valid');
        }
        this.downloadOnSuccess = downloadOnSuccess;
    }
}