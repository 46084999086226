import { DynamicWorkspace as PublicApi, ModuleRegistrationType } from '../../../typings';
import { ComponentInstanceConfig, Configuration } from '../config';
import { ComponentConfig, DWCore, ISkeletonHelper, Logger, Style, Utils } from '../dynamicWorkspace';
import { PublicApi as OldPublicApi } from '../dynamicWorkspace';
import { ILayoutManager, ModuleRegistrationHelper } from '../loader';
import { MigrateFunction, MigrationComponentContainer, MigrationObject, PubSubNameMigration } from '../migration';
import { PubSubEventNameCollection } from '../pubSub';
import { ComponentInjector } from './componentInjector';
import { ComponentLoadManifest, IComponent, IComponentLoader } from '.';

/**
 * The ComponentInitializer will initialize components.
 *
 * @export
 * @class ComponentInitializer
 */
export class ComponentInitializer {

    private logger: Logger;
    private componentLoader: IComponentLoader;
    private componentInjector: ComponentInjector;
    private moduleRegistrationHelper: ModuleRegistrationHelper;
    private pubSubMigration = new Map<string, PubSubEventNameCollection>();
    private loadedTemplates = new Map<string, string>();
    private publicApi: PublicApi;
    private oldPublicApi: OldPublicApi;
    private skeletonHelper: ISkeletonHelper;
    private readonly className = 'ComponentInitializer'

    /**
     * Creates an instance of ComponentInitializer.
     * 
     * @param {Logger} logger The logger.
     * @param {IComponentLoader} componentLoader The componentLoader.
     * @param {ComponentInjector} componentInjector The componentInjector.
     * @param {ModuleRegistrationHelper} moduleRegistrationHelper The moduleRegistrationHelper.
     * @param {OldPublicApi} oldPublicApi The oldPublicApi.
     * @param {PublicApi} publicApi The new publicApi.
     * @param {ISkeletonHelper} skeletonHelper The skeletonHelper.
     * @memberof ComponentInitializer
     */
    public constructor(logger: Logger, componentLoader: IComponentLoader, componentInjector: ComponentInjector,
        moduleRegistrationHelper: ModuleRegistrationHelper, oldPublicApi: OldPublicApi, publicApi: PublicApi, skeletonHelper: ISkeletonHelper) {
        this.logger = logger;
        this.componentLoader = componentLoader;
        this.componentInjector = componentInjector;
        this.moduleRegistrationHelper = moduleRegistrationHelper;
        this.oldPublicApi = oldPublicApi;
        this.publicApi = publicApi;
        this.skeletonHelper = skeletonHelper;
    }

    /**
     * Sets the migration pubsubs.
     *
     * @param {Map<string, PubSubEventNameCollection>} pubSubMigration The pubsubs used for migration.
     * @memberof ComponentInitializer
     */
    public setMigrationPubSubs(pubSubMigration: Map<string, PubSubEventNameCollection>): void {
        pubSubMigration.forEach((value, key) => {
            this.pubSubMigration.set(key, value);
        });
    }

    /**
     * Deinitialize all components.
     *
     * @memberof ComponentInitializer
     */
    public deinitialize(): void {
        this.componentInjector.removeAllInjectedComponents();
    }

    /**
     * Reset the component template.
     *
     * @param {string} guid The guid of the component.
     * @memberof ComponentInitializer
     */
    public resetComponentTemplate(guid: string): void {
        // TODO: Find better way to differentiate internal components and/or a way that this is not necessary at all
        if (guid.startsWith('WINDREAM_')) { // Do not re-initiate internal components as they are programmed to work without that
            return;
        }
        const template = this.loadedTemplates.get(guid);
        if (!template) {
            return;
        }
        const container = this.componentInjector.getInjectionContainer(guid);
        if (!container) {
            throw new Error(`Unable to find element for component ${guid}`);
        }
        container.componentContainer.innerHTML = template;
    }

    /**
     * Initialize a component.
     *
     * @param {ComponentLoadManifest} componentLoadManifest The load manifest.
     * @param {(Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | null)} layoutManagers The layout managers.
     * @param {ComponentInstanceConfig} componentInstanceConfig The component instance config.
     * @param {boolean} hasErrors Whether the component has errors.
     * @returns {Promise<MigrationComponentContainer>} A promise, whicl will resolve with a migration component container.
     * @memberof ComponentInitializer
     */
    public async initializeComponent(componentLoadManifest: ComponentLoadManifest, layoutManagers: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | null,
        componentInstanceConfig: ComponentInstanceConfig, hasErrors: boolean): Promise<MigrationComponentContainer> {
        const componentId = componentInstanceConfig.component;
        if (!componentId) {
            this.logger.error(this.className, 'initializeComponent', 'ComponentInstanceConfig has no component set', componentInstanceConfig);
            throw new Error('ComponentInstanceConfig has no component set');
        }
        if (!componentInstanceConfig.guid) {
            this.logger.error(this.className, 'initializeComponent', 'ComponentInstanceConfig has no GUID set', componentInstanceConfig);
            throw new Error('ComponentInstanceConfig has no GUID set');
        }

        const componentLoadMetadata = await this.componentLoader.loadComponentData(componentLoadManifest);
        const layoutManager = this.getLayoutManagerForComponent(layoutManagers, componentLoadMetadata.componentConfig.isLogic ?
            DWCore.Components.COMPONENT_TYPES.LOGICAL : DWCore.Components.COMPONENT_TYPES.UI);
        const injectedContainers = await this.componentInjector.injectComponentContainer(componentInstanceConfig, componentLoadMetadata.componentConfig, layoutManager);
        componentInstanceConfig = this.initDefaultConfiguration(componentInstanceConfig, componentLoadMetadata.componentConfig);

        if (componentLoadMetadata.componentConfig.template && typeof componentLoadMetadata.componentConfig.template === 'string') {
            const template = await this.componentLoader.loadComponentTemplate(componentLoadManifest.componentBasePath +
                componentLoadMetadata.componentConfig.id + '/' + componentLoadMetadata.componentConfig.template);
            injectedContainers.componentContainer.innerHTML = template;
            this.loadedTemplates.set(componentInstanceConfig.guid, template);
        }
        const componentRegistration = this.moduleRegistrationHelper.getRegistration(componentId, ModuleRegistrationType.Component);
        if (!componentRegistration) {
            throw new Error('Failed to get component registration for: ' + componentId);
        }

        if (componentRegistration.migrationFunction) {
            const oldVersion = componentInstanceConfig.version ? componentInstanceConfig.version : '';
            const newVersion = componentLoadMetadata.componentConfig.version ? componentLoadMetadata.componentConfig.version : '';
            const isNewerVersionNumber = Utils.compareVersionString(oldVersion, newVersion);
            if (isNewerVersionNumber < 0 && !isNaN(isNewerVersionNumber)) {
                const publisherEvents = new Array<PubSubNameMigration>();
                const subscriberEvents = new Array<PubSubNameMigration>();
                const migrationPubSub = this.pubSubMigration.get(componentInstanceConfig.guid);
                if (migrationPubSub) {
                    migrationPubSub.publisherEventNames.forEach((pubSubName) => {
                        publisherEvents.push(new PubSubNameMigration(pubSubName));
                    });
                    migrationPubSub.subscriberEventNames.forEach((pubSubName) => {
                        subscriberEvents.push(new PubSubNameMigration(pubSubName));
                    });
                }
                const migrationObject = new MigrationObject(componentInstanceConfig, publisherEvents, subscriberEvents);
                // Migrate config to a new version
                // TODO: Use new API, check typing miss match of migration namespace and public api
                const migrationResult = await (componentRegistration.migrationFunction as unknown as MigrateFunction)(oldVersion, newVersion, migrationObject, this.publicApi);
                componentInstanceConfig.configuration = migrationResult.componentInstanceConfig.configuration;
                componentInstanceConfig.version = newVersion;


                return new MigrationComponentContainer(this.createComponentInstance(migrationResult.componentInstanceConfig,
                    injectedContainers.componentContainer, componentRegistration.classReference, hasErrors),
                    migrationResult.componentInstanceConfig, injectedContainers.toolbarElement, migrationResult);
            }
        }
        return new MigrationComponentContainer(this.createComponentInstance(componentInstanceConfig,
            injectedContainers.componentContainer, componentRegistration.classReference, hasErrors),
            componentInstanceConfig, injectedContainers.toolbarElement, undefined);
    }


    /**
     * Updates the current api.
     *
     * @param {PublicApi} dynamicWorkspace The new api.
     * @memberof ComponentInitializer
     */
    public updateApi(dynamicWorkspace: PublicApi): void {
        this.publicApi = dynamicWorkspace;
    }

    /**
     * Creates the component instance with new.
     *
     * @private
     * @param {ComponentInstanceConfig} instanceConfig The instance config.
     * @param {HTMLDivElement} targetElement The target element.
     * @param {*} componentClassReference The class reference.
     * @param {boolean} hasErrors Whether the component has errors.
     * @returns {IComponent} A new instance of a component.
     * @memberof ComponentInitializer
     */
    private createComponentInstance(instanceConfig: ComponentInstanceConfig,
        targetElement: HTMLDivElement, componentClassReference: any,
        hasErrors: boolean): IComponent {
        if (!instanceConfig.guid) {
            this.logger.error(this.className, 'createComponentInstance', 'ComponentInstanceConfig has no GUID set', instanceConfig);
            throw new Error('ComponentInstanceConfig has no GUID set');
        }
        const apiToUse = this.getApiToUse(instanceConfig);

        if (!componentClassReference) {
            throw new Error('Component class reference is undefined');
        }
        const componentInstance = new componentClassReference(instanceConfig.guid, targetElement, apiToUse) as IComponent;
        this.skeletonHelper.addDefaultSkeleton(componentInstance, instanceConfig, targetElement, false, hasErrors);
        return componentInstance;


    }

    /**
     * Init default configuration values.
     *
     * @private
     * @param {ComponentInstanceConfig} componentInstanceConfig The component instance config.
     * @param {ComponentConfig} componentConfig The component config.
     * @returns {ComponentInstanceConfig} The modified component instance config with default values.
     * @memberof ComponentInitializer
     */
    private initDefaultConfiguration(componentInstanceConfig: ComponentInstanceConfig, componentConfig: ComponentConfig): ComponentInstanceConfig {
        // Fill default values
        // Add default configuration values
        if (componentInstanceConfig.configuration && Utils.Instance.isArray(componentConfig.configuration)) {
            componentConfig.configuration.forEach((element: Configuration) => {
                if (element.name && element.hasOwnProperty('default')) {
                    if (!(<Object>componentInstanceConfig.configuration).hasOwnProperty(element.name)) {
                        componentInstanceConfig.configuration[element.name] = element.default;
                    }
                }
            });
        }

        // Add default style values
        if (Utils.isDefined(componentConfig.style)) {
            if (!Utils.isDefined(componentInstanceConfig.style)) {
                componentInstanceConfig.style = new Style();
            }

            for (const styleProperty in componentConfig.style) {
                if (componentConfig.style.hasOwnProperty(styleProperty)) {
                    // If the instance style is the same as the default style, then overwrite it.
                    // @ts-ignore - TODO: Check to use index signature
                    if (!Utils.isDefined(componentInstanceConfig.style[styleProperty])) {
                        // @ts-ignore - TODO: Check to use index signature
                        componentInstanceConfig.style[styleProperty] = componentConfig.style[styleProperty];
                    }
                }
            }
        }
        return componentInstanceConfig;
    }

    /**
     * Gets the correct layout manager for the component.
     *
     * @private
     * @param {(Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | null)} layoutManagers The layout managers.
     * @param {DWCore.Components.COMPONENT_TYPES} componentType The component type.
     * @returns {(ILayoutManager | undefined)} A layout manager or unedfined if no layout manager was found.
     * @memberof ComponentInitializer
     */
    private getLayoutManagerForComponent(layoutManagers: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | null, componentType: DWCore.Components.COMPONENT_TYPES): ILayoutManager | undefined {
        let layoutManager: ILayoutManager;
        // Undefined is fine as long as component is using query selector string as position
        if (!layoutManagers) {
            return undefined;
        } else if (componentType === DWCore.Components.COMPONENT_TYPES.LOGICAL) {
            const _layoutManager = layoutManagers.get(DWCore.Components.COMPONENT_TYPES.LOGICAL);
            if (!_layoutManager) {
                throw new Error(`No LayoutManager found: (COMPONENT_TYPE was ${DWCore.Components.COMPONENT_TYPES.LOGICAL})`);
            }
            layoutManager = _layoutManager;
        } else {
            const _layoutManager = layoutManagers.get(DWCore.Components.COMPONENT_TYPES.UI);
            if (!_layoutManager) {
                throw new Error(`No LayoutManager found: (COMPONENT_TYPE was ${DWCore.Components.COMPONENT_TYPES.UI})`);
            }
            layoutManager = _layoutManager;
        }
        return layoutManager;
    }

    /**
     * Gets the api to use.
     *
     * @private
     * @param {ComponentInstanceConfig} componenInstanceConfig The component instance config.
     * @returns {*}  A api to use.
     * @memberof ComponentInitializer
     */
    private getApiToUse(componenInstanceConfig: ComponentInstanceConfig): any {
        // TODO: Use new API only once whole DW has been changed to use it.
        return [
            'com.windream.configuration',
            'com.windream.componentPanel',
            'com.windream.settingsPanel',
            'com.windream.pubSub.addComponent',
            'com.windream.pubSub.mainframe',
            'com.windream.pubSub.addConnection',
            'com.windream.pubSub.sidePanel',
            'com.windream.profile.settings'
        ].indexOf(componenInstanceConfig.component) !== -1 ? this.oldPublicApi : this.publicApi;
    }
}