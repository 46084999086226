import { Utils } from '../common';
import { IResourcePointer } from '../dataProviders';
import { EncryptionHelper } from '../encryption/index';
import { IIndexedDBWrapper } from '../indexedDB';
import { Logger } from '../logging/logger';
import { ICache, ICacheArray } from './interfaces';
import { CacheData, IndexedDBCacheFormat } from './models';

/**
 * The caching class provides local caching functionality. The cache will save the data into an IndexedDB, thus enabling persistence offline functionality
 * 
 * @export
 * @class Cache
 * @implements {ICache}
 */
export class Cache implements ICache {

    /**
     * IndexDBWrapper instance that is being used to cache requests.
     * 
     * @type {(IIndexedDBWrapper | null)}
     * @memberof Cache
     */
    public indexedDB?: IIndexedDBWrapper | null;

    private logger: Logger;
    private storage: ICacheArray;
    private encryptionHelper: EncryptionHelper;
    private currentEncryptionKey?: string;
    private currentOrigin: string;

    private readonly className = 'Cache';

    /**
     * Creates an instance of Cache.
     * @param {Logger} logger
     * @param {EncryptionHelper} encryptionHelper
     * @param {string} origin
     * @memberof Cache
     */
    public constructor(logger: Logger, encryptionHelper: EncryptionHelper, origin: string) {
        this.logger = logger;
        this.encryptionHelper = encryptionHelper;
        this.currentOrigin = origin;
        this.storage = {};
    }

    /**
     * Clears the currently used cache.
     * 
     * @returns {Promise<boolean>} Whether it was successful.
     * @async
     * 
     * @memberof Cache
     */
    public async clearCache(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.storage = {};
            if (this.indexedDB) {
                this.indexedDB.clear().then((success: boolean) => {
                    resolve(success);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                reject(new Error('IndexedDB was not set'));
            }
        });
    }
    /**
     * Adds a new entry to the cache.
     * 
     * @param {CacheData} cacheData 
     * @memberof Cache
     */
    public setData(cacheData: CacheData): void {

        // TODO: Maybe add some kind of wrapper around it for PublicApi calls.
        if (!cacheData) {
            throw new ReferenceError('The argument "cacheData" is null or undefined.');
        }

        this.addToStorage(cacheData);
    }

    /**
     * Enables the encryption of the cache.
     *
     * @param {string} secret The secret.
     * @memberof Cache
     */
    public enableEncryption(secret: string): void {
        this.currentEncryptionKey = secret;
    }

    /**
     * Gets an entry from the cache.
     * 
     * @param {IResourcePointer} resourcePointer The resourcePointer for the data.
     * @returns {(Promise<CacheData | null>)} The data or null if no entry was found.
     * @async
     * 
     * @memberof Cache
     */
    public async getData(resourcePointer: IResourcePointer): Promise<CacheData | null> {

        return new Promise<CacheData | null>((resolve, reject) => {
            if (!resourcePointer) {
                throw new ReferenceError('The argument "resourcePointer" is null or undefined.');
            }

            if (!resourcePointer.uri) {
                resolve(null);
            }

            this.retrieveFromStorage(resourcePointer).then((cacheData: CacheData) => {
                if (cacheData) {
                    if (
                        Utils.Instance.isDefined(cacheData.timeToLive)
                        && Utils.Instance.isDefined(cacheData.timeRetrieved)
                        && cacheData.timeToLive >= new Date().getTime() - cacheData.timeRetrieved
                    ) {
                        cacheData.isStillAlive = true;
                        resolve(cacheData);
                    } else {
                        cacheData.isStillAlive = false;
                        resolve(cacheData);
                    }
                } else {
                    resolve(null);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }
    /**
     * Retrieves the data from the storage.
     * 
     * @private
     * @param {IResourcePointer} resourcePtr The resourcePointer of the data.
     * @returns {(Promise<CacheData | null>)} The data or null if no entry was found.
     * @async
     * 
     * @memberof Cache
     */
    private async retrieveFromStorage(resourcePtr: IResourcePointer): Promise<CacheData | null> {
        const methodName = 'retrieveFromStorage';
        return new Promise<CacheData | null>((resolve) => {
            // Check memory for result
            if (this.storage[resourcePtr.originalUri] && this.storage[resourcePtr.originalUri][resourcePtr.ptrHash]) {
                resolve(this.storage[resourcePtr.originalUri][resourcePtr.ptrHash]);
                return;
            }

            // Check IndexedDB for result
            if (this.indexedDB) {
                this.indexedDB.get(resourcePtr.ptrHash, (dataFromIndexedDB: IndexedDBCacheFormat) => {
                    if (dataFromIndexedDB && dataFromIndexedDB.data) {
                        if (!!this.currentEncryptionKey && dataFromIndexedDB.isEncrypted
                            && typeof dataFromIndexedDB.data === 'string') {
                            // Decrypt if encrypted
                            try {
                                const cacheData = JSON.parse(this.encryptionHelper.decryptAES(dataFromIndexedDB.data,
                                    this.currentEncryptionKey)) as CacheData;
                                cacheData.modified = false;
                                resolve(cacheData);
                            } catch (error) {
                                this.logger.error(this.className, methodName, 'Error during decryption', error);
                                resolve(null);
                            }
                        } else if (!this.currentEncryptionKey && dataFromIndexedDB.isEncrypted) {
                            // Encryption but no encryption key
                            this.logger.info(this.className, methodName, 'Retrieved encrypted data while no encryption was enabled');
                            resolve(null);
                        } else if (typeof dataFromIndexedDB.data === 'object') {
                            // Resolve if not encrypted
                            dataFromIndexedDB.data.modified = false;
                            resolve(dataFromIndexedDB.data);
                        } else {
                            // Everything else is an error
                            this.logger.error(this.className, methodName, 'Got weird format from IndexedDB return null');
                            resolve(null);
                        }
                    } else {
                        resolve(null);
                    }
                });
            } else {
                // No IndexedDB available
                resolve(null);
            }
        });
    }
    /**
     * Adds an entry to the cache.
     * 
     * @private
     * @param {CacheData} cacheData 
     * @memberof Cache
     */
    private addToStorage(cacheData: CacheData): void {
        // Check if second level of memory storage exists
        if (!this.storage[cacheData.resourcePtr.originalUri]) {
            this.storage[cacheData.resourcePtr.originalUri] = {};
        }

        if (cacheData.modified) {
            cacheData.timeRetrieved = new Date().getTime();
        }

        this.storage[cacheData.resourcePtr.originalUri][cacheData.resourcePtr.ptrHash] = cacheData;
        // Return same origin as it's stored by the service worker as well. It's still saved ephemeral.
        if (cacheData.resourcePtr.originalUri.indexOf(this.currentOrigin) === 0 ||
            (cacheData.resourcePtr.originalUri.indexOf('http://') !== 0 &&
                cacheData.resourcePtr.originalUri.indexOf('https://') !== 0)) {
            return;
        }
        // Also persist in IndexedDB
        if (this.indexedDB) {
            let dataToCache: IndexedDBCacheFormat | undefined;
            if (!!this.currentEncryptionKey) {
                try {
                    const encryptedData = this.encryptionHelper.encryptAES(JSON.stringify(cacheData), this.currentEncryptionKey);
                    dataToCache = {
                        data: encryptedData,
                        hash: cacheData.resourcePtr.ptrHash,
                        isEncrypted: !!this.currentEncryptionKey
                    } as IndexedDBCacheFormat;
                } catch (error) {
                    this.logger.warn(this.className, 'addToStorage', 'Unable to save to IndexedDB, encrpytion failed.', error);
                }
            } else {
                dataToCache = {
                    data: cacheData,
                    hash: cacheData.resourcePtr.ptrHash,
                    isEncrypted: !!this.currentEncryptionKey
                } as IndexedDBCacheFormat;
            }
            if (dataToCache) {
                this.indexedDB.put(dataToCache, (success: boolean) => {
                    if (!success) {
                        this.logger.warn(this.className, 'addToStorage', 'Unable to save to IndexedDB.');
                    }
                });
            }
        }
    }
}