/**
 * Option for the ToolstripHandler.
 * 
 * @export
 * @class ToolstripOption
 */
export class ToolstripOption {
    /**
     * Name of this option.
     * 
     * @type {string}
     * @memberof ToolstripOption
     */
    public name: string;
    /**
     * Class for the icon.
     * 
     * @type {string}
     * @memberof ToolstripOption
     */
    public iconClass: string;
    /**
     * Whether this option is selected.
     * 
     * @type {boolean}
     * @memberof ToolstripOption
     */
    public selected: boolean = false;
    /**
     * On click event associated with this option.
     * 
     * @memberof ToolstripOption
     */
    public onClick?: () => void;

    /**
     * Creates an instance of ToolstripOption.
     * @param {string} name Name of this option.
     * @param {string} iconClass Class for the icon.
     * @memberof ToolstripOption
     */
    public constructor(name: string, iconClass: string) {
        this.name = name;
        this.iconClass = iconClass;
    }
}