import { GlobalConfig } from '../config';
import { Logger } from '../logging';
import { WindreamWebsocketProvider } from '../websocket';
import { UploadWebSocket } from '../websocket/uploadWebSocket';

/**
 * Represents the upload extension.
 *
 * @export
 * @class UploadExtension
 */
export class UploadExtension {

    /**
     * The extension name / key.
     *
     * @type {string}
     * @memberof UploadExtension
     */
    public name: string = 'upload';

    private websocketProvider: WindreamWebsocketProvider;
    private logger: Logger;
    private globalConfig: GlobalConfig;

    /**
     * Creates an instance of UploadExtension.
     *
     * @param {GlobalConfig} globalConfig The global configuration.
     * @param {WindreamWebsocketProvider} websocketProvider The websocket provider instance.
     * @param {Logger} logger The logger instance.
     * @memberof UploadExtension
     */
    public constructor(globalConfig: GlobalConfig, websocketProvider: WindreamWebsocketProvider, logger: Logger) {
        this.globalConfig = globalConfig;
        this.websocketProvider = websocketProvider;
        this.logger = logger;
    }

    /**
     * Tries to connect to the given web socket URL. 
     *
     * @returns {Promise<UploadWebSocket>} Returns the connected web socket instance.
     * @memberof UploadExtension
     */
    public async connect(): Promise<UploadWebSocket> {
        return new Promise<UploadWebSocket>((resolve, reject) => {

            let webSocketConnectionUrl = this.globalConfig.windreamWebServiceURL;
            // If connection to web service is secure, use wss
            const isSecure = webSocketConnectionUrl.startsWith('https');
            const websocketProtocol = isSecure ? 'wss://' : 'ws://';
            const webServiceUrlWithoutSlashIndex = 2;
            webSocketConnectionUrl = websocketProtocol + webSocketConnectionUrl.substring(webSocketConnectionUrl.indexOf('//') + webServiceUrlWithoutSlashIndex);
            webSocketConnectionUrl += '/dynamicworkspace/documents/websocket/upload';

            const uploadWebSocket = new UploadWebSocket(this.websocketProvider, this.logger);
            uploadWebSocket.connect(webSocketConnectionUrl).then(() => {
                resolve(uploadWebSocket);
            }).catch((error) => reject(error));
        });
    }
}