import { NavigationGroup } from '.';

/**
 * View model for the AppMenu.
 * 
 * @export
 * @class ViewNavigationViewModel
 */
export class ViewNavigationViewModel {
    /**
     * Items of the ViewNavigationViewModel.
     * 
     * @type {NavigationGroup[]}
     * @memberof ViewNavigationViewModel
     */
    public items: NavigationGroup[];

    /**
     * Prefix of the ViewNavigation.
     * 
     * @type {string}
     * @memberof ViewNavigation
     */
    public prefix: string;

    /**
     * Creates an instance of ViewNavigationViewModel.
     *
     * @param {string} prefix
     * @memberof ViewNavigationViewModel
     */
    public constructor(prefix: string) {
        this.prefix = prefix;
        this.items = new Array<NavigationGroup>();
    }
}