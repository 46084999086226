import { IActiveBarHelper, IActiveBarHelperFactory } from './interfaces';
import { ActiveBarHelper } from '.';


/**
 * Factory for creating IActiveBarHelper instances.
 * 
 * @export
 * @class ActiveBarHelperFactory
 * @implements {IActiveBarHelperFactory}
 */
export class ActiveBarHelperFactory implements IActiveBarHelperFactory {

    /**
     * Creates an instance of IActiveBarHelper.
     * 
     * @param {HTMLElement} element 
     * @returns {IActiveBarHelper} 
     * @memberof ActiveBarHelperFactory
     */
    public create(element: HTMLElement): IActiveBarHelper {
        return new ActiveBarHelper(element);
    }
}