import { DWCore } from '@windream/dw-core/dwCore';

/**
 * Configuration of a single subview inside a view.
 * 
 * @export
 * @class SubViewConfig
 */
export class SubViewConfig implements DWCore.Views.SubViewConfig {
    /**
     * Name of the subview.
     * 
     * @type {string}
     * @memberof SubViewConfig
     */
    public name: string;
    /**
     * ID of the subview.
     * 
     * @type {string}
     * @memberof SubViewConfig
     */
    public id: string;
    /**
     * GUIDs of components within the subview.
     * 
     * @type {string[]}
     * @memberof SubViewConfig
     */
    public components: string[];

    /**
     * Creates an instance of SubViewConfig.
     * @param {string} id
     * @param {string} name
     * @memberof SubViewConfig
     */
    public constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
        this.components = new Array<string>();
    }
}