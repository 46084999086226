import { Culture } from '../../../../../typings';
import { LanguageProvider } from '../../../../../typings/language';
import { DataSourceItem } from '../common';
import { IDataSourceItem, IItemCollectionDataSource } from '../index';
import { ITagBox, ITagBoxOptions } from './interfaces';

/**
 * The base class for a tag box.
 * 
 * @export
 * @class TagBox
 */
export class TagBox implements ITagBox {

    /**
     * The callback for item validation on the custom item creating event. Passes the current inserted value for the new item.
     *
     * @memberof ITagBox
     */
    public canCustomItemBeCreated?: (value: string) => boolean;

    /**
     * The callback for the value changed event. Passes the current value as a array.
     * 
     * @memberof TagBox
     */
    public onValueChanged?: (items: IDataSourceItem<string>[] | null) => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof TagBox
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof TagBox
     */
    public onBlur?: () => void;

    private targetElement: HTMLElement;
    private tagBoxOptions: DevExpress.ui.dxTagBoxOptions;
    private instance?: DevExpress.ui.dxTagBox;
    private jQueryStatic: JQueryStatic;
    private dataSource?: IItemCollectionDataSource<string | number>;
    private languageProvider: LanguageProvider;
    private cultureHelper: Culture;

    private isNumeric: boolean;
    private semicolonValidation?: boolean;

    /**
     * Creates an instance of TagBox.
     *
     * @param {HTMLElement} targetElement
     * @param {JQueryStatic} jQueryStatic
     * @param {LanguageProvider} languageProvider
     * @param {Culture} cultureHelper
     * @memberof TagBox
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic, languageProvider: LanguageProvider, cultureHelper: Culture) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;
        this.languageProvider = languageProvider;
        this.cultureHelper = cultureHelper;

        this.isNumeric = false;

        this.tagBoxOptions = {
            displayExpr: 'displayValue',
            onCustomItemCreating: (event) => {
                const text = event.text;
                if (text) {
                    if (this.semicolonValidation) {
                        this.addSemicolonValidation(targetElement);
                    }
                    let value: number | string;
                    let displayValue: string;
                    if (this.isNumeric) {
                        value = cultureHelper.numberFormatHelper.fromString(text);
                        displayValue = cultureHelper.numberFormatHelper.format(value, {
                            withThousandsSeperator: false
                        });
                    } else {
                        displayValue = text;
                        value = text;
                    }
                    if (this.canCustomItemBeCreated && !this.canCustomItemBeCreated(value.toString())) {
                        // Set customItem to null if no new item shall be created
                        event.customItem = null;
                    } else {
                        const item = new DataSourceItem<string | number>(value);
                        item.displayValue = displayValue;
                        item.id = this.generateID(); // Item deletion requires unique ID
                        event.customItem = item; // This shall be used instead of return statement
                    }
                }
            },
            onFocusIn: () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            },
            onFocusOut: () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            },
            onValueChanged: (event: any) => {
                const values = event.value as IDataSourceItem<string>[];
                this.onValueChangedTrigger(values);
            },
            showClearButton: true,
            value: []
        };
    }

    /**
     * Bootstraps the tag box.
     * 
     * @memberof TagBox
     */
    public bootstrap(): void {
        this.targetElement.classList.add('wd-tag-box');
        this.instance = this.getJQueryElement().dxTagBox(this.tagBoxOptions)
            .on('focus', () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            }).on('blur', () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            }).dxTagBox('instance');
        // Do not display dropdown by default. It is re-evaluated in setDataSource()
        this.setDropdownVisible(false);
        this.setPlaceholderText(this.getPlaceholderText());
    }

    /**
     * Adds validation for ';' key press.
     * 
     * @param {HTMLElement} element the tagbox HTML element.
     * @memberof TagBox
     */
    private addSemicolonValidation(element: HTMLElement) {
        element.addEventListener('keydown', (event: any) => {
            if (event.key === ';' || event.keyCode === 186) { // 186 is the semicolon key code
                // Prevent default action to avoid adding the semicolon to the input
                event.preventDefault();

                // Create and dispatch an Enter key event
                const enterEvent = new KeyboardEvent('keydown', {
                    key: 'Enter',
                    keyCode: 13,
                    bubbles: true
                });
                event.target.dispatchEvent(enterEvent);
            }
        });
    }

    /**
     * Sets the tag box options.
     * 
     * @param {ITagBoxOptions} options 
     * @memberof TagBox
     */
    public setOptions(options: ITagBoxOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }

        // Create the DevExpress options object.
        const tagBoxOptions: DevExpress.ui.dxTagBoxOptions = {};

        if (options.hasOwnProperty('isReadOnly')) {
            tagBoxOptions.readOnly = options.isReadOnly;
        }
        if (options.hasOwnProperty('isDisabled')) {
            tagBoxOptions.disabled = options.isDisabled;
        }
        if (options.hasOwnProperty('acceptCustomValue')) {
            tagBoxOptions.acceptCustomValue = options.acceptCustomValue;
            this.tagBoxOptions.acceptCustomValue = options.acceptCustomValue;
        }
        if (options.hasOwnProperty('isNumeric')) {
            this.isNumeric = options.isNumeric || false;
        }
        if (options.hasOwnProperty('isMultiline')) {
            tagBoxOptions.multiline = options.isMultiline ? options.isMultiline : false;
        }
        if (options.hasOwnProperty('semicolonValidation')) {
            this.semicolonValidation = options.semicolonValidation ? options.semicolonValidation : false;
        }
        if (options.hasOwnProperty('searchEnabled')) {
            tagBoxOptions.searchEnabled = options.searchEnabled ?? false;
        }
        if (options.hasOwnProperty('tabIndex')) {
            tagBoxOptions.tabIndex = options.tabIndex;
        }
        // Set the options.
        const dxTagBox = this.getJQueryElement().dxTagBox(tagBoxOptions);
        // Set validator rules.
        if (options.isRequired) {
            dxTagBox.dxValidator({ validationRules: [{ type: 'required' }] });
        } else {
            dxTagBox.dxValidator({ validationRules: [] });
        }
        this.setPlaceholderText(this.getPlaceholderText());
    }

    /**
     * Sets the current value.
     * 
     * @param {IDataSourceItem<string>[] | null} items
     * @memberof TagBox
     */
    public setValue(items: IDataSourceItem<string>[] | null): void {
        this.getJQueryElement().dxTagBox({
            value: items ? items : new Array<string>()
        });
    }

    /**
     * Sets the data source.
     * 
     * @param {IItemCollectionDataSource<string>} datasource 
     * @memberof TagBox
     */
    public setDataSource(dataSource: IItemCollectionDataSource<string | number>): void {
        this.dataSource = dataSource;

        if (this.isNumeric) {
            for (const item of dataSource.items) {
                item.value = this.cultureHelper.numberFormatHelper.fromString(item.value.toString());
            }
        }

        this.getJQueryElement().dxTagBox({
            items: this.dataSource.items
        });

        // Do not display dropdown if no data is available
        this.setDropdownVisible(dataSource.items.length > 0);
        this.setPlaceholderText(this.getPlaceholderText());
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof TagBox
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof TagBox
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('disabled', isDisabled);
        }
    }

    /**
     * Destroys this instance.
     *
     * @memberof TagBox
     */
    public destroy(): void {
        if (this.instance) {
            this.instance.dispose();
        }
    }


    /**
     * Sets the styling mode.
     *
     * @param {('outlined' | 'underlined')} stylingMode Mode to apply.
     * @memberof TagBox
     */
    public setStylingMode(stylingMode: 'outlined' | 'underlined'): void {
        if (this.instance) {
            this.instance.option('stylingMode', stylingMode);
        }
    }


    /**
     * Triggers the onValueChanged event.
     * 
     * @protected
     * @param {IDataSourceItem<string>[] | null} value The value to call the change event with.
     * @memberof TagBox
     */
    protected onValueChangedTrigger(value: IDataSourceItem<string>[] | null): void {
        if (typeof this.onValueChanged === 'function') {
            this.onValueChanged(value);
        }
    }

    /**
     * Generates a random int
     *
     * @private
     * @returns
     * @memberof TagBox
     */
    private generateID() {
        const multiplicationFactor = 1000000;
        return Math.floor(Math.random() * multiplicationFactor);
    }

    /**
     * Gets the related jQuery element.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof TagBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }

    /**
     * Sets whether the dropdown is visible or not.
     *
     * @private
     * @param {boolean} isVisible Whether the dropdown is visible.
     * @memberof TagBox
     */
    private setDropdownVisible(isVisible: boolean): void {
        if (this.instance) {
            this.instance.option('openOnFieldClick', isVisible);
        }
    }


    /**
     * Returns the placeholder text based on the values of accept custom values and the lenght of the data source.
     *
     * @private
     * @returns {string}
     * @memberof TagBox
     */
    private getPlaceholderText(): string {
        const acceptCustomValues = this.tagBoxOptions.acceptCustomValue;

        let hasDataSourceEntries = false;
        if (this.dataSource) {
            hasDataSourceEntries = this.dataSource.items.length > 0;
        }
        if (acceptCustomValues && hasDataSourceEntries) {
            return this.languageProvider.get('framework.uiComponents.tagBox.customValuesPlaceholder');
        } else if (acceptCustomValues && !hasDataSourceEntries) {
            return this.languageProvider.get('framework.uiComponents.tagBox.noDataSourcePlaceholder');
        } else if (!acceptCustomValues && hasDataSourceEntries) {
            return this.languageProvider.get('framework.uiComponents.tagBox.selectPlaceholder');
        }
        return '';
    }


    /**
     * Sets the placeholder text.
     *
     * @private
     * @param {string} text Text to set.
     * @memberof TagBox
     */
    private setPlaceholderText(text: string): void {
        if (this.instance) {
            this.instance.option('placeholder', text);
        }
    }
}