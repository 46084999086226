import { IResourcePointer } from '../../dataProviders';


/**
 * This class contains all information to cache specific data.
 * 
 * @export
 * @class CacheData
 */
export class CacheData {


    /**
     * The resourcePtr.
     * 
     * @type {IResourcePointer}
     * @memberof CacheData
     */
    public resourcePtr: IResourcePointer;

    /**
     * The data.
     * 
     * @type {*}
     * @memberof CacheData
     */
    public data: any;

    /**
     * The timeToLive within the cache in milliseconds.
     * 
     * @type {number}
     * @memberof CacheData
     */
    public timeToLive?: number;

    /**
     * The time when this data was retrieved.
     * 
     * @type {number}
     * @memberof CacheData
     */
    public timeRetrieved?: number;

    /**
     * If this data was modified or not.
     * 
     * @type {boolean}
     * @memberof CacheData
     */
    public modified?: boolean;

    /**
     * The entity tag.
     * 
     * @type {string}
     * @memberof CacheData
     */
    public wdTag?: string;

    /**
     * Whether the request is still valid or not.
     * 
     * @type {boolean}
     * @memberof CacheData
     */
    public isStillAlive : boolean = false;

    /**
     * Creates an instance of CacheData.
     * @param {IResourcePointer} resourcePtr 
     * @memberof CacheData
     */
    public constructor(resourcePtr: IResourcePointer) {
        this.resourcePtr = resourcePtr;
    }

}