import { WindreamIdentity } from '../../common';
import { UpdateRequestOptions } from './updateRequestOptions';


/**
 * Represents update document request options.
 *
 * @export
 * @class UpdateDocumentRequestOptions
 * @extends {UpdateRequestOptions}
 */
export class UpdateDocumentRequestOptions extends UpdateRequestOptions {

    /**
     * Whether a new version should be created.
     *
     * @type {boolean}
     * @memberof UpdateDocumentRequestOptions
     */
    public createNewVersion?: boolean;

    /**
     * Creates an instance of UpdateDocumentRequestOptions.
     * @param {WindreamIdentity} identity
     * @memberof UpdateDocumentRequestOptions
     */
    public constructor(identity: WindreamIdentity) {
        super(identity);
    }
}