import { ITextBox, ITextBoxOptions } from './interfaces';


/**
 * The base class for a text box.
 * 
 * @export
 * @class TextBox
 */
export class TextBox implements ITextBox {

    /**
     * The callback for the value changed event. Passes the current value as a string.
     * 
     * @memberof TextBox
     */
    public onValueChanged?: (item: string, event?: KeyboardEvent) => void;
    /**
     * The callback for the enter pressed event. Passes the current value as a string.
     * 
     * @memberof TextBox
     */
    public onEnterPressed?: (item: string, event?: KeyboardEvent) => void;

    /**
     * The callback for the key down event. Passes the current value as a string.
     *
     * @memberof TextBox
     */
    public onKeyDown?: (value: string, event?: KeyboardEvent) => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof TextBox
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof TextBox
     */
    public onBlur?: () => void;

    /**
     * The callback for the onInitialized event.
     *
     * @memberof TextBox
     */
    public onInitialized?: () => void;

    private targetElement: HTMLElement;
    private textBoxOptions: DevExpress.ui.dxTextBoxOptions<DevExpress.ui.dxTextBox>;
    private instance?: DevExpress.ui.dxTextBox;


    /**
     * Creates an instance of TextBox.
     *
     * @param {HTMLElement} targetElement 
     * @memberof TextBox
     */
    public constructor(targetElement: HTMLElement) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;

        this.textBoxOptions = {
            value: '',
            onFocusIn: () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            },
            onFocusOut: () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            },
            onKeyUp: (event: any) => {
                if (!event) {
                    throw new Error('dxTextBox.onKeyUp: The event parameter was null or undefined.');
                }
                const keyboardEvent = (event.event as KeyboardEvent);
                const value = this.getValue();
                if (!value) {
                    this.onValueChangedTrigger('', keyboardEvent);
                } else {
                    this.onValueChangedTrigger(value, keyboardEvent);
                }
            },
            onEnterKey: (event: any) => {
                if (!event) {
                    throw new Error('dxTextBox.onEnterKey: The event parameter was null or undefined.');
                }
                const keyboardEvent = (event.event as KeyboardEvent);
                const value = this.getValue();
                if (!value) {
                    this.onEnterPressedTrigger('', keyboardEvent);
                } else {
                    this.onEnterPressedTrigger(value, keyboardEvent);
                }
            },
            onKeyDown: (event: any) => {
                if (!event) {
                    throw new Error('dxTextBox.onKeyDown: The event parameter was null or undefined.');
                }
                if (event && event.event) {
                    const keyboardEvent = (event.event as KeyboardEvent);
                    const key = keyboardEvent.key;
                    if (key && key.length === 1) {
                        this.onKeyDownTrigger(key, keyboardEvent);
                    }
                }
            },
            onInitialized: () => {
                this.onInitializedTrigger();
            },
            onValueChanged: (event: any) => {
                if (event) {
                    this.onValueChangedTrigger(event.value, event.event);
                }
            },
            valueChangeEvent: 'input',
            showClearButton: true
        };
    }

    /**
     * Bootstraps the text box.
     * 
     * @memberof TextBox
     */
    public bootstrap(): void {
        this.instance = this.getJQueryElement().dxTextBox(this.textBoxOptions).dxTextBox('instance');
    }


    /**
     * Sets the text box options.
     * 
     * @param {ITextBoxOptions} options 
     * @memberof TextBox
     */
    public setOptions(options: ITextBoxOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }

        // Create the DevExpress options object.
        const textBoxOptions: DevExpress.ui.dxTextBoxOptions<DevExpress.ui.dxTextBox> = {};

        if (options.hasOwnProperty('isReadOnly')) {
            textBoxOptions.readOnly = options.isReadOnly;
        }
        if (options.hasOwnProperty('isDisabled')) {
            textBoxOptions.disabled = options.isDisabled;
        }
        if (options.hasOwnProperty('placeholderText')) {
            textBoxOptions.placeholder = options.placeholderText;
        }
        if (options.hasOwnProperty('tabIndex')) {
            textBoxOptions.tabIndex = options.tabIndex;
        }
        if (options.hasOwnProperty('stylingMode')) {
            textBoxOptions.stylingMode = options.stylingMode;
        }
        if (options.hasOwnProperty('type')) {
            textBoxOptions.mode = options.type;
        }
        this.getJQueryElement().dxTextBox(textBoxOptions);

        // Try to initialize validation.
        this.initializeValidation(options);
    }


    /**
     * Sets the current value.
     * 
     * @param {string} value
     * @param {boolean} preventValueChangeEvent Determines whether the value change event should be triggered.
     * @memberof TextBox
     */
    public setValue(value: string, preventValueChangeEvent?: boolean): void {
        if (preventValueChangeEvent) {
            // Deactivate the value change event temporarily
            this.getJQueryElement().dxTextBox('instance').option('valueChangeEvent', '');
        }
        this.getJQueryElement().dxTextBox({
            value: value
        });
        if (preventValueChangeEvent) {
            // Re-activate the value change event again
            this.getJQueryElement().dxTextBox('instance').option('valueChangeEvent', 'input');
        }
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof TextBox
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.textBoxOptions.isValid = isValid;
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the hint text.
     *
     * @param {string} hint
     * @memberof TextBox
     */
    public setHint(hint: string): void {
        if (this.instance) {
            this.textBoxOptions.hint = hint;
            this.instance.option('hint', hint);
        }
    }

    /**
     * Sets the disabled state of the component.
     * Sets component internal to read only state for usability.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof TextBox
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.textBoxOptions.disabled = isDisabled;
            this.instance.option('readOnly', isDisabled);
        }
    }


    /**
     * Initializes the validation.
     *
     * @protected
     * @param {ITextBoxOptions} options The text box options.
     * @return {*}  {void}.
     * @memberof TextBox
     */
    protected initializeValidation(options: ITextBoxOptions): void {
        if (!options) {
            return;
        }

        if (options.hasOwnProperty('patternValidation') && options.patternValidation
            && options.patternValidation.pattern) {
            this.getJQueryElement().dxTextBox().dxValidator({
                validationRules: [{
                    type: 'pattern',
                    pattern: options.patternValidation?.pattern,
                    message: options.patternValidation?.validationErrorMessage
                }]
            });
        }

        const container = this.getJQueryElement()[0];
        if (container) {

            const inputElement = container.getElementsByTagName('input')[0];
            if (inputElement) {

                // Required
                if (options.isRequired) {
                    inputElement.required = true;
                }

                // Pattern
                if (options.patternValidation && options.patternValidation.pattern) {
                    inputElement.pattern = options.patternValidation.pattern.toString();
                    inputElement.title = options.patternValidation.validationErrorMessage;
                }
            }
        }
    }


    /**
     * Triggers the onValueChanged event.
     * 
     * @protected
     * @param {string)} value The value.
     * @param {KeyboardEvent} event The keyboard event.
     * @memberof TextBox
     */
    protected onValueChangedTrigger(value: string, event: KeyboardEvent): void {
        if (typeof this.onValueChanged === 'function') {
            this.onValueChanged(value, event);
        }
    }

    /**
     * Triggers the onEnterPressed event.
     * 
     * @protected
     * @param {string)} value The value.
     * @param {KeyboardEvent} event The keyboard event.
     * @memberof TextBox
     */
    protected onEnterPressedTrigger(value: string, event: KeyboardEvent): void {
        if (typeof this.onEnterPressed === 'function') {
            this.onEnterPressed(value, event);
        }
    }

    /**
     * Triggers the onKeyEvent event.
     *
     * @protected
     * @param {string} value The current value.
     * @param {KeyboardEvent} event The keyboard event.
     * @memberof TextBox
     */
    protected onKeyDownTrigger(value: string, event: KeyboardEvent): void {
        if (typeof this.onKeyDown === 'function') {
            let keyBoardEvent = event;
            if (!keyBoardEvent) {
                keyBoardEvent = new KeyboardEvent('keydown');
            }
            this.onKeyDown(value, keyBoardEvent);
        }
    }

    /**
     * Triggers the onInitialized event.
     *    
     * @protected
     * @memberof TextBox
     */
    protected onInitializedTrigger(): void {
        if (typeof this.onInitialized === 'function') {
            this.onInitialized();
        }
    }

    /**
     * Gets the text box instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof TextBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!$) {
            throw new Error('jQuery was not loaded.');
        }

        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return $(this.targetElement);
    }

    /**
     * Returns the current value.
     * 
     * @private
     * @returns {string} The current value.
     * @memberof TextBox
     */
    private getValue(): string {
        if (!this.instance) {
            throw new Error('No instance found');
        }
        // TODO: Find better way to access actual current value
        // @ts-ignore - Ignore because of DX Usage
        const inputElement = this.instance._input() as JQuery<HTMLInputElement>;
        if (!inputElement) {
            throw new Error('No input element found');
        }

        const value = inputElement.val() as string;
        return value;

    }

}