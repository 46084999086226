/**
 * An enumeration of all possible MsgBox return values.
 *
 * @export
 * @enum {number}
 */
export enum MsgBoxReturnValues {

    /**
     * Clicked Button: OK
     */
    OK = 0,

    /**
     * Clicked Button: Cancel
     */
    Cancel = 1,

    /**
     * Clicked Button: Retry
     */
    Retry = 2,

    /**
     * Clicked Button: Ignore
     */
    Ignore = 3,

    /**
     * Clicked Button: Yes
     */
    Yes = 4,

    /**
     * Clicked Button: No
     */
    No = 5

}