import { Actions } from '../../../typings/windreamWebService/Windream.WebService.Permissions';
import { HttpResponse } from '../../ajaxHandler/httpResponse';
import { TokenProvider } from '../../authentication';
import { GlobalConfig } from '../../config';
import { BinaryHttpResourcePointer, BinaryHttpResponseTypes } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { DownloadBase } from '../base/downloadBase';
import { NativeDownloadTrigger } from '../base/nativeDownloadTrigger';
import { IFileSaver } from '../documents';
import { IdentityMapper } from '../identityMapper';
import { DownloadRequestOptions, WindreamRequestOptions } from '../models';
import { CanPerformAction } from '../permissions';


/**
 * The Download action is used to download a directory from windream
 * 
 * @export
 * @class DownloadDirectory
 * @extends {DownloadBase}
 */
export class DownloadDirectory extends DownloadBase {
    /**
     * Creates an instance of DownloadDirectory.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {CanPerformAction} canPerformActionService
     * @param {IFileSaver} fileSaver
     * @param {NativeDownloadTrigger} nativeDownloadTrigger
     * @param {TokenProvider} [tokenProvider]
     * @memberof DownloadDirectory
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, canPerformActionService: CanPerformAction,
        fileSaver: IFileSaver, nativeDownloadTrigger: NativeDownloadTrigger, tokenProvider?: TokenProvider) {
        super(requestExecutor, globalConfig, logger, canPerformActionService, fileSaver, nativeDownloadTrigger, tokenProvider);

        this.className = 'DownloadDirectory';
    }

    /**
     * Downloads a specified directory.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {DownloadRequestOptions} downloadRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolve if the download was successful depending on useBuffer option.
     * @memberof DownloadDirectory
     */
    public async do(downloadRequestOptions: DownloadRequestOptions): Promise<void> {
        if (downloadRequestOptions.useBuffer) {
            return this.downloadUsingBuffer(downloadRequestOptions);
        }

        return Promise.all([this.canPerformActionService.do({
            identity: downloadRequestOptions.identity,
            actions: Actions.ReadContent
        }), this.tokenProvider?.getToken()]).then((results) => {
            const permission = results[0];
            const token = results[1];
            if (!token) {
                return Promise.reject(new Error('No token received'));
            }
            const canRead = (permission.allowedActions & Actions.ReadContent) === Actions.ReadContent;
            if (!canRead) {
                this.logger.error(this.className, 'do', 'No permission to read content', permission);
                return Promise.reject(new Error('No permission to read content'));
            }
            const parameter = IdentityMapper.getWebServiceGetParameter(downloadRequestOptions.identity);
            const tempUrl = this.globalConfig.windreamWebServiceURL + '/Directories/Download?' + parameter + '&access_token=' + token;
            // Trigger save
            this.nativeDownloadTrigger.download(tempUrl, permission.identity.name);
        });
    }

    /**
     * Downloads the directory by buffering it.
     * This is the behavior as it was before 7.0.11 and is included for legacy reasons.
     * The Promise received by this method only resolves after the download.
     *
     * @private
     * @param {WindreamRequestOptions} requestOptions Request options received.
     * @returns {Promise<void>} Promise to resolve after the download.
     * @memberof DownloadDirectory
     */
    private downloadUsingBuffer(requestOptions: WindreamRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const parameter = IdentityMapper.getWebServiceGetParameter(requestOptions.identity);
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/Directories/Download?${parameter}`;
            const resourcePointer = new BinaryHttpResourcePointer('GET', tempUrl, BinaryHttpResponseTypes.ARRAYBUFFER);
            this.requestExecutor.executeRequest(resourcePointer, requestOptions.requestOptions)
                .then((response: HttpResponse<any>) => {
                    if (!response.data.hasOwnProperty('HasErrors')) {
                        // Success
                        try {
                            const fileName = this.getFileNameFromResponse(response, requestOptions.identity);
                            this.fileSaver.saveAs(response.data, fileName);
                            resolve();
                        } catch (error) {
                            this.logger.error(this.className, 'downloadUsingBuffer', 'Failed to save file', error);
                            reject(error);
                        }
                        resolve();
                    } else {
                        this.logger.error(this.className, 'downloadUsingBuffer', 'Failed to download identity');
                        reject(new Error('Failed to download identity'));
                    }
                }).catch((error) => {
                    this.logger.error(this.className, 'downloadUsingBuffer', 'Failed to execute request', error);
                    reject(error);
                });
        });
    }
}