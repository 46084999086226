import { IdentityChangedType } from '../../../../typings/events';
import { DocumentResponseContainerDTO, MoveDocumentDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { IdentityChangedHandler } from '../../messages/events';
import { ServiceError } from '../common';
import { IdentityMapper } from '../identityMapper';
import { MoveDocumentRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Moves a document within windream.
 *
 * @export
 * @class MoveDocument
 * @extends {ServiceAction}
 */
export class MoveDocument extends ServiceAction {

    private identityChangedHandler: IdentityChangedHandler;
    /**
     * Creates an instance of MoveDocument.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {IdentityChangedHandler} identityChangedHandler The identity changed handler.
     * @memberof MoveDocument
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger,
        identityChangedHandler: IdentityChangedHandler) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'moveDocument';
        this.identityChangedHandler = identityChangedHandler;
    }

    /**
     * Move a document within windream to its destination.
     *
     * @param {MoveDocumentRequestOptions} moveDocumentRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolve if the move was successful.
     * @memberof MoveDocument
     */
    public async do(moveDocumentRequestOptions: MoveDocumentRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/documents/Move', {
                Item: IdentityMapper.getWebServiceIdentity(moveDocumentRequestOptions.source),
                Target: IdentityMapper.getWebServiceIdentity(moveDocumentRequestOptions.target),
                Flags: moveDocumentRequestOptions.flags as number,
                ResponseDetails: moveDocumentRequestOptions.responseDetailsType ? moveDocumentRequestOptions.responseDetailsType as number : 0
            } as MoveDocumentDTO), moveDocumentRequestOptions.requestOptions).then((response: IServiceResponse<DocumentResponseContainerDTO>) => {
                if (response.data && !response.data.HasErrors) {
                    if (response.data && response.data.Item) {
                        this.identityChangedHandler.distributeEvent({
                            type: IdentityChangedType.Move,
                            previous: moveDocumentRequestOptions.source,
                            current: this.modelFactory.createWindreamIdentityDetailsFromDto(response.data.Item)
                        });
                    }
                    resolve();
                } else {
                    this.logger.error('MoveDocument', 'do', 'Failed to move document', response);
                    reject(new ServiceError('Failed to move document', response.data?.Error?.ErrorCode || -1));
                }
            }).catch((err: Error) => {
                this.logger.error('MoveDocument', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}