import { ToolbarOrientationDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { DWCore } from '../dynamicWorkspace';

/**
 * Transform the toolbar config from the webservice to a local class.
 *
 * @export
 * @class ComponentInstanceToolbarOrientationConfigFactory
 */
export class ComponentInstanceToolbarOrientationConfigFactory {

    /**
     * Converts the DTO into a DW class.
     *
     * @static
     * @param {(ToolbarOrientationDTO | undefined)} toolbarConfigDto The DTO to convert.
     * @returns {DWCore.Components.ToolbarOrientation} The converted DTO.
     * @memberof ComponentInstanceToolbarOrientationConfigFactory
     */
    public static convertFromDTO(toolbarConfigDto: ToolbarOrientationDTO | undefined): DWCore.Components.ToolbarOrientation {
        switch (toolbarConfigDto) {
            case ToolbarOrientationDTO.Automatically:
                return DWCore.Components.ToolbarOrientation.Automatically;
            case ToolbarOrientationDTO.Left:
                return DWCore.Components.ToolbarOrientation.Left;
            case ToolbarOrientationDTO.Right:
                return DWCore.Components.ToolbarOrientation.Right;
            default:
                return DWCore.Components.ToolbarOrientation.Automatically;
        }
    }
}