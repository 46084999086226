import { ViewConfigMetaDataDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { Utils } from '../common';
import { ViewConfigMetaData } from './models';
import { StyleFactory } from './styleFactory';
/**
 * This class provides a factory, which will generate ViewConfig classes from JSON objects or JSON strings.
 * 
 * @export
 * @class ViewConfigMetaDataFactory
 */
export class ViewConfigMetaDataFactory {

    /**
     * Converts the given view configuration metadata DTO into the internal metadata model.
     *
     * @static
     * @param {ViewConfigMetaDataDTO} viewConfigMetaDataDTO The DTO received from the server.
     * @returns {ViewConfigMetaData} The converted object.
     * @memberof ViewConfigMetaDataFactory
     */
    public static convertFromDTO(viewConfigMetaDataDTO: ViewConfigMetaDataDTO): ViewConfigMetaData {
        const result = new ViewConfigMetaData(viewConfigMetaDataDTO.Id);

        result.author = viewConfigMetaDataDTO.Author;
        result.description = viewConfigMetaDataDTO.Description;
        result.icon = viewConfigMetaDataDTO.Icon;
        result.modified = viewConfigMetaDataDTO.Modified;
        result.name = viewConfigMetaDataDTO.Name;
        result.style = StyleFactory.convertFromDTO(viewConfigMetaDataDTO.Style);
        result.version = viewConfigMetaDataDTO.Version;
        result.isHiddenInNavigation = viewConfigMetaDataDTO.IsHiddenInNavigation;
        result.contextMenu = viewConfigMetaDataDTO.ContextMenu;
        result.enabledDevices = viewConfigMetaDataDTO.EnabledDevices;

        if (Utils.isDefined(viewConfigMetaDataDTO.TemplateId)) {
            result.templateId = viewConfigMetaDataDTO.TemplateId;
        }

        if (Utils.isDefined(viewConfigMetaDataDTO.Alias) && viewConfigMetaDataDTO.Alias !== '') {
            result.alias = viewConfigMetaDataDTO.Alias;
        }

        return result;
    }

}