import { AuthenticationProvider } from '../authenticationProvider';
import { AuthenticationModes } from '../models/authenticationModes';
import { TokenProvider } from '../tokenProvider';


/**
 * This class adjusts a raw request, so that MSAL JWT authentication is supported.
 * 
 * @export
 * @class MsalTokenHttpAuthenticationProvider
 * @extends {AuthenticationProvider}
 */
export class MsalTokenHttpAuthenticationProvider extends AuthenticationProvider {

    /**
     * The credential manager.
     * 
     * @protected
     * @type {ICredentialManager}
     * @memberof MsalTokenHttpAuthenticationProvider
     */
    private tokenProvider: TokenProvider;


    /**
     * Creates an instance of MsalTokenHttpAuthenticationProvider.
     *
     * @param {TokenProvider} tokenProvider 
     * @memberof MsalTokenHttpAuthenticationProvider
     */
    public constructor(tokenProvider: TokenProvider) {
        super();
        if (!tokenProvider) {
            throw new ReferenceError('The argument "tokenProvider" is null or undefined.');
        }

        this.tokenProvider = tokenProvider;
    }

    /**
     * Gets the supported authentication mode.
     * 
     * @returns {AuthenticationModes} 
     * @memberof MsalTokenHttpAuthenticationProvider
     */
    public getSupportedAuthenticationMode(): AuthenticationModes {
        return AuthenticationModes.MSAL;
    }

    /**
     * This handler will be called before the execution.
     * 
     * @async
     * @param {XMLHttpRequest} rawRequest 
     * @returns {Promise<XMLHttpRequest>} 
     * @memberof MsalTokenHttpAuthenticationProvider
     */
    public async beforeRequestHandler(rawRequest: XMLHttpRequest): Promise<XMLHttpRequest> {
        return super.beforeRequestHandler(rawRequest).then(async (processedRawRequest) => {
            if (processedRawRequest) {
                // Get the current token...
                const tokenPromise = this.tokenProvider.getToken();
                // ... and use it to set the bearer authentication header.
                return tokenPromise.then(async (token) => {
                    processedRawRequest.setRequestHeader('Authorization', 'Bearer ' + token);
                    return Promise.resolve(processedRawRequest);
                }, async (err: Error) => {
                    return Promise.reject(err);
                });
            }
            return Promise.reject(new Error('Failed to receive request from super class'));
        });
    }

    /**
     * Invalidates token by the token provider.
     *
     * @async
     * @returns {Promsise<boolean>} Promise to resolve with whether the request should be replayed afterwards.
     * @memberof MsalTokenHttpAuthenticationProvider
     */
    public async handleInvalidAuthentication(): Promise<boolean> {
        return this.tokenProvider.markTokenAsInvalid().then(async () => Promise.resolve(true));
    }

}