import { AuthenticationProvider } from '../authenticationProvider';
import { AuthenticationModes } from '../models/authenticationModes';


/**
 * This class adjusts a raw request, so that windows authentication is supported.
 * 
 * @export
 * @class WindowsAuthenticationProvider
 * @extends {AuthenticationProvider}
 */
export class WindowsAuthenticationProvider extends AuthenticationProvider {

    /**
     * Gets the supported authentication mode.
     * 
     * @returns {AuthenticationModes} 
     * @memberof IAuthenticationProvider
     */
    public getSupportedAuthenticationMode(): AuthenticationModes {
        return AuthenticationModes.Windows;
    }

    /**
     * This handler will be called before the execution.
     * 
     * @asnyc
     * @param {XMLHttpRequest} rawRequest 
     * @returns {Promise<XMLHttpRequest>} 
     * @memberof WindowsAuthenticationProvider
     */
    public async beforeRequestHandler(rawRequest: XMLHttpRequest): Promise<XMLHttpRequest> {
        return super.beforeRequestHandler(rawRequest).then(async (processedRawRequest) => {
            if (processedRawRequest) {
                // Adjust the raw request, so that windows credentials will be used for the request.
                processedRawRequest.withCredentials = true;
                return Promise.resolve(processedRawRequest);
            }
            return Promise.reject(new Error('Failed to receive request from super class'));
        });
    }

}