import * as Handlebars from 'handlebars';
// @ts-ignore - TODO: Check typing
import * as Sortable from 'sortablejs';
import { Culture } from '../../../typings';
import { CellData, DataCollection, HeaderData, RowData, SortingOption } from '../../../typings/core';
import { LanguageProvider } from '../../../typings/language';
import { DataCollectionData } from '../../../typings/ui';
import { Utils, ValueTypes, WindreamEntity } from '../common';
import { DataCollectionDisplay } from '../dataCollections/dataCollectionDisplay';
import { Logger } from '../logging';
import { IMasterTableOptions } from './interfaces';
import { ColumnOrderingHelper, ColumnResizeHelper } from '.';

/**
 * Provides an abstract table, which will generate a generic table into the DOM.
 *
 * @export
 * @abstract
 * @class MasterTable
 * @template T
 */
export abstract class MasterTable<T> extends DataCollectionDisplay<T> {
    protected currentId?: string;
    protected currentSorting?: SortingOption<T>;
    protected options: IMasterTableOptions<T>;
    private targetElement: HTMLElement;
    private columnResizeHelper?: ColumnResizeHelper;
    private columnOrderingHelper?: ColumnOrderingHelper;

    private clickHandler?: (event: MouseEvent) => void;
    private contextMenuHandler?: (event: MouseEvent) => void;
    private dblClickHandler?: (event: MouseEvent) => void;

    private defaultOptions: IMasterTableOptions<T> = {
        afterRenderCallback: undefined,
        canSort: true,
        columnOrdering: true,
        columnOrderingCallback: undefined,
        columnResize: true,
        showFileType: false,
        canShare: false
    };


    /**
     * Creates an instance of MasterTable.
     *
     * @param {HTMLElement} targetElement The targetElement, which will be used to render the table.
     * @param {LanguageProvider} languageProvider   Framework language provider to use.
     * @param {Logger} logger Logger to use.
     * @param {IMasterTableOptions} [options]  The table options.
     *
     * @memberof MasterTable
     */
    public constructor(targetElement: HTMLElement, languageProvider: LanguageProvider, logger: Logger, cultureHelper: Culture, options?: IMasterTableOptions<T>) {
        super(languageProvider, logger, cultureHelper);
        this.targetElement = targetElement;
        this.options = { ...this.defaultOptions, ...options };
        this.setupEvents(this.targetElement);
    }

    /**
     * Render the table into the DOM.
     *
     * @param {IMasterTableData<T>} [data]  The used data model.
     * @returns {void}
     * @memberof MasterTable
     */
    public render(data?: DataCollectionData<T> | null): void {
        if (!data && !this.currentData) {
            // TODO Error
            return;
        }
        if (data) {
            if (this.equalHeader(data.data.header)) { // If header data is equal to what is aleardy displayed, take own value with set widths, etc.
                this.mergeData(data.data);
            } else {
                this.currentData = data;
            }
            if (Utils.isDefined(data.id)) {
                this.currentId = data.id;
            } else {
                this.currentId = Utils.Instance.getRandomString();
            }
            if (data.sorting) {
                this.currentSorting = data.sorting;
            }
        } else {
            this.currentId = Utils.Instance.getRandomString();
        }

        if (!this.currentData) {
            // TODO Error
            return;
        }

        this.currentSorting = this.currentSorting ? this.currentSorting : this.getDefaultSorting();
        this.currentData.sorting = this.currentSorting;
        this.sort(this.currentSorting.columnIndex);

        let wrapper = <HTMLElement>this.targetElement.querySelector('.wd-table-wrapper');
        // Make sure scrolling is kept after repainting
        let scrollLeft = 0;
        if (wrapper) {
            scrollLeft = wrapper.scrollLeft;
        }

        const template = this.setupHtml(this.currentData);
        if (!template) {
            // TODO error
            return;
        }
        this.targetElement.innerHTML = template;

        if (Utils.isDefined(this.options)) {

            if (this.options.columnOrdering) {
                const table = this.targetElement.querySelector('table');
                if (table) {
                    this.columnOrderingHelper = new ColumnOrderingHelper(Sortable, table);
                    this.columnOrderingHelper.makeSortable();
                    this.columnOrderingHelper.onDrop = (oldIndex: number, newIndex: number) => {
                        if (!this.currentData) {
                            return;
                        }
                        // Sort internal model
                        if (oldIndex >= 0 && oldIndex < this.currentData.data.header.length && newIndex >= 0 && newIndex < this.currentData.data.header.length) {
                            this.currentData.data.header.splice(newIndex, 0, this.currentData.data.header.splice(oldIndex, 1)[0]);
                            this.currentData.data.rows.forEach((row: RowData<T>) => {
                                row.cellData.splice(newIndex, 0, row.cellData.splice(oldIndex, 1)[0]);
                            });
                            // Check if sorting column has to be moved
                            if (this.currentSorting && this.currentSorting.columnIndex === oldIndex) {
                                this.currentSorting.columnIndex = newIndex;
                            }
                        }

                        if (this.columnResizeHelper) {
                            this.columnResizeHelper.reInit();
                        }

                        if (this.options.columnOrderingCallback) {
                            this.options.columnOrderingCallback(oldIndex, newIndex);
                        }

                        this.redraw();
                    };
                }
            }

            if (this.options.columnResize) {
                const table = this.targetElement.querySelector('table');
                if (table) {
                    this.columnResizeHelper = new ColumnResizeHelper(table);
                    this.columnResizeHelper.makeResizeable();
                    this.columnResizeHelper.onResize = (columnWidths: number[]) => {
                        if (!this.currentData) {
                            return;
                        }
                        // Save current values for re-rendering
                        this.currentData.data.header.forEach((headerData: HeaderData<T>, index: number) => {
                            headerData.width = columnWidths[index + 1];
                        });

                        this.redraw();
                    };
                }
            }

        }

        // Make sure scrolling is kept after re-rendering
        wrapper = <HTMLElement>this.targetElement.querySelector('.wd-table-wrapper');
        if (wrapper) {
            wrapper.scrollLeft = scrollLeft;
        }

        if (this.options && typeof this.options.afterRenderCallback === 'function') {
            this.options.afterRenderCallback();
        }
    }

    /**
     * Forces the browser to to re-render the table.
     *
     * @memberof MasterTable
     */
    public redraw(): void {
        const wrapper = <HTMLElement>this.targetElement.querySelector('.wd-table-wrapper');
        // Make sure scrolling is kept after repainting
        let scrollLeft = 0;
        if (wrapper) {
            scrollLeft = wrapper.scrollLeft;
            wrapper.style.display = 'run-in';
            // Used to force redraw, see http://stackoverflow.com/questions/3485365/how-can-i-force-webkit-to-redraw-repaint-to-propagate-style-changes
            wrapper.offsetHeight;
            wrapper.style.display = '';
            wrapper.scrollLeft = scrollLeft;
        }
    }

    /**
     * Destroys the table and cleans the DOM.
     * 
     * @memberof MasterTable
     */
    public destroy(): void {
        if (this.clickHandler) {
            this.targetElement.removeEventListener('click', this.clickHandler);
        }
        if (this.dblClickHandler) {
            this.targetElement.removeEventListener('dblclick', this.dblClickHandler);
        }
        if (this.contextMenuHandler) {
            this.targetElement.removeEventListener('contextmenu', this.contextMenuHandler);
        }
        this.targetElement.innerHTML = '';
    }

    /**
     * The table was double clicked.
     * Not implemented.
     *
     * @protected
     * @abstract
     * @param {Event} event  The event.
     * @param {DataCollection<T>} data The current data.
     *
     * @memberof MasterTable
     */
    protected abstract doubleClick?(event: Event, data: DataCollection<T>): void;

    /**
     * The table was clicked.
     * Not implemented.
     *
     * @protected
     * @abstract
     * @param {Event} event  The event.
     * @param {DataCollection<T>} data The current data.
     *
     * @memberof MasterTable
     */
    protected abstract click?(event: Event, data: DataCollection<T>): void;

    /**
     * Callback to be called after sorting.
     *
     * @protected
     * @abstract
     * @param {DataCollection<T>} currentData    Sorted data.
     * @param {ISortingOption<T>} currentSorting    Current sorting.
     *
     * @memberof MasterTable
     */
    protected abstract afterSort?(currentData: DataCollection<T>, currentSorting: SortingOption<T>): void;

    /**
     * Sorts the data model.
     *
     * @protected
     * @param {number} index The index, which shall be sorted.
     *
     * @memberof MasterTable
     */
    protected sort(index: number): void {
        if (!this.currentData) {
            return;
        }
        this.currentData.data.rows.sort(this.getSortingMethodForColumn(index).bind(this));

        if (this.currentSorting && this.afterSort && typeof this.afterSort.bind(this) === 'function') {
            this.afterSort(this.currentData.data, this.currentSorting);
        }
    }
    /**
     * Setups the HTML template.
     *
     * @private
     * @param {DataCollectionData<T>} data The currently used data.
     * @returns {string}
     *
     * @memberof MasterTable
     */
    private setupHtml(data: DataCollectionData<T>): string {
        const template = require('./template/masterTableTemplate.html');
        data.id = this.currentId;
        Handlebars.registerHelper('getSortingClass', (index: number) => {
            if (this.currentSorting && this.currentSorting.columnIndex === index) {
                if (this.currentSorting.ascending) {
                    return 'sort-up';
                } else {
                    return 'sort-down';
                }
            }
            return '';
        });
        Handlebars.registerHelper('getTableClasses', () => {
            let tableClasses = 'wd-table';
            if (Utils.isDefined(this.options) && this.options.showFileType) {
                tableClasses += ' wd-show-file-types';
            }
            // Set class if columns have been resized by the user
            if (this.currentData && !!this.currentData.data.header.find((headerData: HeaderData<T>) => !!headerData.width)) {
                tableClasses += ' wd-resized';
            }
            return tableClasses;
        });
        Handlebars.registerHelper('getColumnClasses', (_headerData: HeaderData<T>, index: number) => {
            let columnClasses = '';
            if (this.currentSorting && this.currentSorting.columnIndex === index) {
                columnClasses += ' wd-sorted-column';
            }
            return columnClasses;
        });
        Handlebars.registerHelper('getCellClasses', (cellData: CellData, index: number) => {
            const columnClasses = '';
            if (this.options.showFileType && index === 0) { // Do not add class for first column
                return '';
            }
            if (cellData && cellData.valueType) {
                switch (cellData.valueType) {
                    case ValueTypes.Decimal: // Decimal values
                    case ValueTypes.Double: // Double values
                    case ValueTypes.Float: // Float values
                    case ValueTypes.Int: // Integer values
                    case ValueTypes.Int64: // Integer 64 values
                    case ValueTypes.Currency: // Currency values
                        return 'wd-number-cell';
                    default: // Other values (strings)
                        return '';

                }
            }

            return columnClasses;
        });
        Handlebars.registerHelper('getFileIcon', (dataObject: T) => {
            let icon: string = '';
            if (!this.options.showFileType) {
                return '';
            }
            // @ts-ignore - TODO: Check index signature
            if (dataObject && 'entity' in dataObject && dataObject['entity'] === WindreamEntity.Document) {
                let fileType = '';
                // @ts-ignore - TODO: Check index signature
                if (dataObject['name']) {
                    // @ts-ignore - TODO: Check index signature
                    fileType = dataObject['name'].substr(dataObject['name'].lastIndexOf('.')).replace(/\./g, '').toLowerCase();
                }
                icon = `<span class="wd-icon file ${fileType}" title="${this.languageProvider.get('framework.filetypes.file')} ${fileType ? '(' + fileType + ')' : ''}"></span>`;
                // @ts-ignore - TODO: Check index signature
            } else if (dataObject && 'entity' in dataObject && dataObject['entity'] === WindreamEntity.Folder) {
                icon = `<span class="wd-icon folder" title="${this.languageProvider.get('framework.filetypes.folder')}"></span>`;
            }
            return icon;
        });
        Handlebars.registerHelper('getDragDropAttributes', (_dataObject: T) => {
            let dragDropAttributes: string = '';
            if (this.options.canShare) {
                dragDropAttributes += ' draggable="true"';
            }
            return dragDropAttributes;
        });
        const handelbarsTemplateDelegate = Handlebars.compile(template);
        return handelbarsTemplateDelegate(data);
    }

    /**
     * Setup the events.
     *
     * @private
     * @param {HTMLElement} targetElement The current target element.
     *
     * @memberof MasterTable
     */
    private setupEvents(targetElement: HTMLElement): void {
        targetElement.addEventListener('click', this.clickHandler = (event: MouseEvent) => {
            if (event.target) {
                const htmlTarget = <HTMLElement>event.target;
                const amountOfParents = 3;
                const tableHeader = Utils.parentsUntil(htmlTarget, (child) => {
                    if (child.nodeName.toUpperCase() === 'TH') {
                        return true;
                    }
                    return false;
                }, amountOfParents);
                if (tableHeader && tableHeader.nodeName.toUpperCase() === 'TH') {
                    const attribute = tableHeader.getAttribute('data-wd-header-id');
                    const dataModel = this.getHeaderDataModelFromElement(tableHeader);
                    if (attribute && dataModel && dataModel.sortable) {
                        const index = Number.parseInt(attribute, 10);
                        this.internalSort(index, true);
                    }
                } else if (htmlTarget.nodeName.toUpperCase() === 'TD') {
                    const parent = <HTMLTableCellElement>htmlTarget.parentElement;
                    if (parent) {

                        // Try do unselect all other rows
                        const amountOfParents = 3;
                        const parentTable = Utils.parentsUntil(parent, (element: HTMLElement) => !!element.getAttribute('wd-table'), amountOfParents);
                        if (parentTable) {
                            const foundRows = Array.from(parentTable.querySelectorAll('tr'));
                            if (foundRows && foundRows.length > 0) {
                                foundRows.forEach((foundRow) => {
                                    this.unselectRow(foundRow);
                                });
                            }
                        }

                        // Then select the clicked row
                        const tempRow: HTMLTableRowElement | null = this.getRowByCell(parent);
                        this.selectRow(tempRow);

                        const attribute = parent.getAttribute('data-wd-row-id');
                        if (attribute) {
                            const id = Number.parseInt(attribute, 10);
                            if (Utils.isDefined(id) && this.currentData) {
                                const row = this.currentData.data.rows[id];
                                if (row && typeof row.onClick === 'function') {
                                    const data = this.getDataModelFromElement(parent);
                                    if (data && data.dataObject) {
                                        row.onClick([data.dataObject]);
                                    }
                                }
                            }
                        }
                    }
                    this.internalClick(event);
                }
            }

        });
        targetElement.addEventListener('dblclick', this.dblClickHandler = (event: MouseEvent) => {
            this.internalDoubleClick(event);
        });
        targetElement.addEventListener('contextmenu', this.contextMenuHandler = (event: MouseEvent) => {
            if (!this.currentData) {
                return;
            }
            const htmlTarget = <HTMLElement>event.target;
            if (htmlTarget.nodeName.toUpperCase() === 'TD' && (this.currentData.data.contextMenu.length > 0 || this.currentData.data.contextMenuCreator)) {
                const parent = htmlTarget.parentElement;
                if (parent) {
                    const data = this.getDataModelFromElement(parent);
                    if (data) {
                        event.preventDefault();
                        if (data.dataObject) {
                            this.setupContextMenu([data.dataObject]);
                        }
                        if (this.uiManager) {
                            this.uiManager.appIsBusy();
                        }
                        if (data.dataObject) {
                            this.getContextMenuInstance([data.dataObject]).then((contextMenu) => {
                                if (this.contextMenuExtension && data.webSocketDataModel) {
                                    this.contextMenuExtension.setFallbackMenu(contextMenu);
                                    this.contextMenuExtension.openMenu(data.webSocketDataModel.getLocationComplete(), event);
                                    // @ts-ignore - Ignore because of Foundation usage
                                } else if (window['Foundation'] && window['Foundation']['ContextMenu']) {
                                    // tslint:disable-next-line:no-unused-new - Disabled because of Foundation syntax
                                    // @ts-ignore - Ignore because of Foundation usage
                                    new window['Foundation']['ContextMenu'](window['$'](event.target), {
                                        accessible: true,
                                        position: event,
                                        single: true,
                                        structure: contextMenu.getItems(),
                                        emptyEntryWillCloseMenu: false
                                    });
                                }
                                if (this.uiManager) {
                                    this.uiManager.appIsIdle();
                                }
                            }).catch((err) => {
                                throw err;
                            });
                        }
                    }

                }
            }
            const amountOfParents = 2;
            const tableHeader = Utils.parentsUntil(htmlTarget, (child) => {
                if (child.nodeName.toUpperCase() === 'TH') {
                    return true;
                }
                return false;
            }, amountOfParents);
            if (tableHeader && tableHeader.nodeName.toUpperCase() === 'TH') {
                const data = this.getHeaderDataModelFromElement(tableHeader);
                if (Utils.isDefined(data)) {
                    if (typeof (data.onContextmenu) === 'function') {
                        data.onContextmenu(event, data);
                    }
                }
            }
        });
    }


    /**
     * Gets a row by its table cell element.
     *
     * @private
     * @param {HTMLTableCellElement} tableCell
     *
     * @memberof MasterTable
     */
    private getRowByCell(tableCell: HTMLTableCellElement): HTMLTableRowElement | null {
        const amountOfParents = 5;
        const result = <HTMLTableRowElement>Utils.parentsUntil(tableCell, (currentParent) => {
            return currentParent.nodeName.toUpperCase() === 'TR';
        }, amountOfParents);

        return result;
    }


    /**
     * Sets a row's state to selected.
     *
     * @private
     * @param {HTMLTableCellElement} element
     *
     * @memberof MasterTable
     */
    private selectRow(row: HTMLTableRowElement | null) {
        if (row && !row.classList.contains('selected')) {
            row.classList.add('selected');
        }
    }


    /**
     * Sets a row's state to not selected.
     *
     * @private
     * @param {HTMLTableRowElement} row
     *
     * @memberof MasterTable
     */
    private unselectRow(row: HTMLTableRowElement | null) {
        if (row && row.classList.contains('selected')) {
            row.classList.remove('selected');
        }
    }


    /**
     * Handels the double click.
     *
     * @private
     * @param {MouseEvent} event The MouseEvent.
     *
     * @memberof MasterTable
     */
    private internalDoubleClick(event: MouseEvent): void {
        if (this.doubleClick && this.currentData) {
            this.doubleClick(event, this.currentData.data);
        }
    }

    /**
     * Handels the click.
     *
     * @private
     * @param {MouseEvent} event The MouseEvent.
     *
     * @memberof MasterTable
     */
    private internalClick(event: MouseEvent): void {
        if (this.click && this.currentData) {
            this.click(event, this.currentData.data);
        }
    }

    /**
     * Handels the sort.
     *
     * @private
     * @param {number} index  The index, which shall be sorted.
     *
     * @memberof MasterTable
     */
    private internalSort(index: number, switchSortDirection: boolean): void {
        if (!this.currentData) {
            return;
        }
        const isColumnSorted = this.currentSorting && this.currentSorting.columnIndex === index;

        const wasAscending = this.currentSorting ? this.currentSorting.ascending : true;
        this.currentSorting = {
            ascending: isColumnSorted && switchSortDirection ? !wasAscending : wasAscending, // Ascending is default if column has not been sorted already
            columnIndex: index,
            headerData: this.currentData.data.header[index]
        };

        this.sort(index);
        this.render();
    }

    /**
     * Gets the sorting method for the column.
     *
     * @private
     * @param {number} index  The current index.
     * @returns {(a: RowData<T>, b: RowData<T>) => number}  The sorting function.
     *
     * @memberof MasterTable
     */
    private getSortingMethodForColumn(index: number): (a: RowData<T>, b: RowData<T>) => number {
        if (this.currentData && this.currentData.data.header[index]) {
            const sortingFunction = this.currentData.data.header[index].sorting;
            if (sortingFunction && typeof sortingFunction === 'function') {
                return sortingFunction;
            }
        }
        return this.getDefaultSortingMethodForColumnIndex(index, (!!this.currentSorting && this.currentSorting.ascending));
    }

    /**
     * Gets the id from the template.
     *
     * @private
     * @param {HTMLElement} element The current element.
     * @returns {(T | null)}  The id or null.
     *
     * @memberof MasterTable
     */
    private getDataModelFromElement(element: HTMLElement): RowData<T> | null {
        const attribute = element.getAttribute('data-wd-row-id');
        if (attribute) {
            const id = Number.parseInt(attribute, 10);
            if (this.currentData && this.currentData.data.rows[id]) {
                return this.currentData.data.rows[id];
            }
        }
        return null;
    }
    /**
     * Gets the HeaderDataModel from the template.
     *
     * @private
     * @param {HTMLElement} element The current element.
     * @returns {(T | null)}  The data model or null.
     *
     * @memberof MasterTable
     */
    private getHeaderDataModelFromElement(element: HTMLElement): HeaderData<T> | null {
        const attribute = element.getAttribute('data-wd-header-id');
        if (attribute) {
            const id = Number.parseInt(attribute, 10);
            if (this.currentData && this.currentData.data.header[id]) {
                return this.currentData.data.header[id];
            }
        }
        return null;
    }
    /**
     *  Gets the default sorting option.
     *
     * @private
     * @returns {ISortingOption<T>} The sorting option.
     *
     * @memberof MasterTable
     */
    private getDefaultSorting(): SortingOption<T> {
        if (!this.currentData) {
            throw new Error('Cannot get default sorting, no data present');
        }
        return {
            ascending: true, // Ascending is default if column has not been sorted already
            columnIndex: 0,
            headerData: this.currentData.data.header[0]
        };
    }

    /**
     * Gets the default sorting method.
     *
     * @private
     * @param {number} index The current index.
     * @param {boolean} ascending Whether it will be sorted asc or desc.
     * @returns {(a: RowData<T>, b: RowData<T>) => number} The sorting function.
     *
     * @memberof MasterTable
     */
    private getDefaultSortingMethodForColumnIndex(index: number, ascending: boolean): (a: RowData<T>, b: RowData<T>) => number {
        return (a: RowData<T>, b: RowData<T>) => {
            if (!a || !a.cellData || !b || !b.cellData) {
                return 0;
            }

            const valueA = a.cellData[index].value;
            const valueB = b.cellData[index].value;

            if (!valueA || !valueB) {
                return 0;
            }

            if (typeof (valueA) === 'string' && typeof (valueB) === 'string') {
                // String
                if (valueA.toLowerCase() < valueB.toLowerCase()) {
                    return ascending ? -1 : 1;
                } else if (valueA.toLowerCase() > valueB.toLowerCase()) {
                    return ascending ? 1 : -1;
                }
            } else {
                if (valueA < valueB) {
                    return ascending ? -1 : 1;
                } else if (valueA > valueB) {
                    return ascending ? 1 : -1;
                }
            }

            return 0;
        };
    }

    /**
     * Checks if the given new data's header equals the current data's header.
     * Compares display values of the headers, ignores positions.
     *
     * @private
     * @param {HeaderData<T>[]} newData New header data that will be set.
     * @returns {boolean} True if new header and current header have the same cells.
     *
     * @memberof MasterTable
     */
    private equalHeader(newData: HeaderData<T>[]): boolean {
        if (!this.currentData || !this.currentData.data) {
            return false;
        }
        const oldData = this.currentData.data.header;

        const oldDisplayValues = oldData.map((headerData) => headerData.displayValue).sort();
        const newDisplayValues = newData.map((headerData) => headerData.displayValue).sort();

        // See https://stackoverflow.com/a/22395463
        return (oldDisplayValues.length === newDisplayValues.length) && !!oldDisplayValues.find((element, index) => {
            return element === newDisplayValues[index];
        });
    }


    /**
     * Merges new data with existing current data.
     * This will use sorting and size of columns from current table.
     *
     * @private
     * @param {DataCollection<T>} newData New data to set.
     *
     * @memberof MasterTable
     */
    private mergeData(newData: DataCollection<T>): void {
        if (!this.currentData) {
            throw new Error('Cannot merge, no data present');
        }
        // Switch columns to current column sorting setting
        if (Utils.isDefined(this.options) && this.options.columnOrdering) {
            const oldSorting = newData.header.map((headerData: HeaderData<T>) => headerData.displayValue);
            const sortingMapping = this.currentData.data.header.map((headerData: HeaderData<T>) =>
                oldSorting.findIndex((displayValue: string) =>
                    headerData.displayValue === displayValue
                )
            );

            sortingMapping.forEach((oldIndex: number, newIndex: number) => {
                newData.rows.forEach((row: RowData<T>) => {
                    row.cellData.splice(newIndex, 0, row.cellData.splice(oldIndex, 1)[0]);
                });
            });
        }

        newData.header = this.currentData.data.header;
        this.currentData.data = newData;

        if (this.currentSorting) {
            // Sort data by current sorting setting
            this.internalSort(this.currentSorting.columnIndex, false);
        }
    }
}