import { Utils } from '../common';
import { ISelectionManager } from './interfaces';


/**
 * This class handles all concerns, which have to do with selection of elements such as components.
 *
 * @export
 * @class SelectionManager
 */
export class SelectionManager implements ISelectionManager {

    /**
     * Callback to execute when the selection changes.
     * Null if nothing becomes selected.
     *
     * @memberof SelectionManager
     */
    public onSelectionChange?: (activeGuid: string | null) => void;

    private readonly WD_ACTIVE_CLASS: string = 'wd-active';
    private rootElement: HTMLElement;
    private currentDocument: Document;

    /**
     * Creates an instance of SelectionManager.
     *
     * @param {HTMLElement} rootElement The root element.
     * @param {Document} currentDocument The current document.
     * @memberof SelectionManager
     */
    public constructor(rootElement: HTMLElement, currentDocument: Document) {
        this.rootElement = rootElement;
        this.currentDocument = currentDocument;
        this.initEventHandlers();
    }

    /**
     * Attach the selection manager to a specific element.
     *
     * @param {HTMLElement} target The target which will be attached to.
     * @memberof SelectionManager
     */
    public attachSelectionListenerToElement(target: HTMLElement): void {
        target.addEventListener('click', this.checkClickedElement, true);
    }

    /**
     * Adds the 'wd-active' class to the given element's class list.
     *
     * @param {HTMLElement} element
     *
     * @memberof SelectionManager
     */
    public activateComponent(element: HTMLElement): void {
        if (element && !element.classList.contains(this.WD_ACTIVE_CLASS)) {
            element.classList.add(this.WD_ACTIVE_CLASS);
        }

        if (this.onSelectionChange) {
            const activeGuid = element.getAttribute('data-guid');
            if (activeGuid) {
                this.onSelectionChange(activeGuid);
            }
        }
    }


    /**
     * Removes the 'wd-active' class from all components.
     *
     * @memberof SelectionManager
     */
    public deactivateAllComponents(): void {
        const foundElements: NodeListOf<Element> = this.currentDocument.body.querySelectorAll('[data-component].' + this.WD_ACTIVE_CLASS);
        if (foundElements) {
            const components = Array.from(foundElements);
            if (components && components.length > 0) {
                components.forEach((component) => {
                    component.classList.remove(this.WD_ACTIVE_CLASS);
                });
            }
        }
        if (this.onSelectionChange) {
            this.onSelectionChange(null);
        }
    }


    /**
     * Initializes all required event handlers.
     *
     * @memberof SelectionManager
     */
    private initEventHandlers(): void {
        // Add a click event handler to the root element.
        this.rootElement.addEventListener('click', this.checkClickedElement, true);
    }


    /**
     * Checks which element was clicked and triggers PubSub events accordingly.
     *
     * @private
     * @param {Event} e
     *
     * @memberof SelectionManager
     */
    private checkClickedElement = (e: Event): void => {
        if (!e || !e.target) {
            return;
        }
        // Only check HTMLElements and exclude SVGElements etc.
        if (e.target instanceof HTMLElement) {
            const currentElement = e.target;
            // Deactivate all components
            this.deactivateAllComponents();

            // Check if the target was a component.
            const component = Utils.parentsUntil(currentElement, (currentParent: HTMLElement) => !!currentParent.hasAttribute('data-component'));
            if (component && component.hasAttribute('data-component')) {
                // Activate the clicked component
                this.activateComponent(component);
            }
        }
    }

}