import { DWCore } from '@windream/dw-core/dwCore';
import { ViewRequestOptions } from './viewRequestOptions';

/**
 * Represents request options for the pubsub editor.
 *
 * @export
 * @class PubSubEditorRequestOptions
 * @extends {ViewRequestOptions}
 */
export class PubSubEditorRequestOptions extends ViewRequestOptions {

    /**
     * The device.
     *
     * @type {DWCore.Common.Devices}
     * @memberof PubSubEditorRequestOptions
     */
    public device: DWCore.Common.Devices;

    /**
     * Creates an instance of PubSubEditorRequestOptions.
     * @param {string} viewId
     * @param {DWCore.Common.Devices} device
     * @memberof PubSubEditorRequestOptions
     */
    public constructor(viewId: string, device: DWCore.Common.Devices) {
        super(viewId);
        if (typeof device !== 'number') {
            throw new Error('Argument \'device\' is not valid');
        }
        this.device = device;
    }
}