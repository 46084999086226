/**
 * Represents the MSAL config for DW.
 *
 * @export
 * @class MSALConfig
 */
export class MSALConfig {

    /**
     * The scopes.
     *
     * @type {Array<string>}
     * @memberof MSALConfig
     */
    public scopes?: Array<string>;

    /**
     * The client id.
     *
     * @type {string}
     * @memberof MSALConfig
     */
    public clientId?: string;

    /**
     * The tenant id.
     *
     * @type {string}
     * @memberof MSALConfig
     */
    public tenantId?: string;

    /**
     * The authority.
     *
     * @readonly
     * @type {(string | undefined)}
     * @memberof MSALConfig
     */
    public get authority(): string | undefined {
        if (this.tenantId) {
            return 'https://login.microsoftonline.com/' + this.tenantId;
        } else {
            return undefined;
        }
    }


    /**
     * Creates an instance of MSALConfig.
     *
     * @param {*} msalConfig The MSAL configuration data.
     * @memberof MSALConfig
     */
    public constructor(msalConfig: any) {
        if (msalConfig) {
            this.clientId = msalConfig.clientId;
            this.tenantId = msalConfig.tenantId;
        }
    }

}