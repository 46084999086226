import { CellData, WindreamIdentity } from '../../../../typings/core';

/**
 * The RowData model.
 * 
 * @export
 * @class RowData
 * @template T 
 */
export class RowData<T> {
    /**
     * The cell data model array.
     * 
     * @type {CellData[]}
     * @memberof RowData
     */
    public cellData: CellData[];

    /**
     * The current data object.
     * 
     * @type {T}
     * @memberof RowData
     */
    public dataObject?: T;

    /**
     * The onclick callback, which will be called if the row was clicked.
     * 
     * 
     * @memberof RowData
     */
    public onClick?: (data: T[]) => void;

    /**
     * The onclick callback, which will we called if the row was double clicked.
     *
     * @memberof RowData
     */
    public doubleClick?: (data: T) => void;

    /**
     * Whether the row item is marked as fovorite or not.
     *
     * @type {boolean}
     * @memberof RowData
     */
    isFavorite?: boolean;

    /**
     * The Datamodel for the websocket.
     * 
     * @type {WindreamIdentity}
     * @memberof RowData
     */
    public webSocketDataModel?: WindreamIdentity;
    /**
     * Creates an instance of RowData.
     * 
     * @memberof RowData
     */
    public constructor() {
        this.cellData = new Array<CellData>();
    }
}