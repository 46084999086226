import { ContextMenuData, HeaderData, RowData } from '../../../../typings/core';
import { ContextMenuCreator } from '../../contextMenu';

/**
 * The DataCollection model.
 * 
 * @export
 * @class DataCollection
 * @template T 
 */
export class DataCollection<T> {

    /**
     * The current array of HeaderData models.
     * 
     * @type {HeaderData<T>[]}
     * @memberof DataCollection
     */
    public header: HeaderData<T>[];

    /**
     * The current array of RowData models.
     * 
     * @type {RowData<T>[]}
     * @memberof DataCollection
     */
    public rows: RowData<T>[];

    /**
     * Context menu associated with the table.
     * 
     * @type {ContextMenuData<T>[]}
     * @memberof DataCollection
     */
    public contextMenu: ContextMenuData<T>[];

    /**
     * Context menu creator instance to use.
     * 
     * @type {ContextMenuCreator<T>}
     * @memberof DataCollection
     */
    public contextMenuCreator?: ContextMenuCreator<T>;

    /**
     * Creates an instance of DataCollection.
     * 
     * @memberof DataCollection
     */
    public constructor() {
        this.header = new Array<HeaderData<T>>();
        this.rows = new Array<RowData<T>>();
        this.contextMenu = new Array<ContextMenuData<T>>();
    }
}
