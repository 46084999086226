/**
 * Represents a linked list for pubsub nodes.
 *
 * @export
 * @class PubSubLinkedNode
 * @template T
 */
export class PubSubLinkedNode<T> {

    /**
     * The data.
     *
     * @type {T}
     * @memberof PubSubLinkedNode
     */
    public data: T;

    /**
     * The next node.
     *
     * @type {PubSubLinkedNode<T>[]}
     * @memberof PubSubLinkedNode
     */
    public next: PubSubLinkedNode<T>[];

    /**
     * Creates an instance of PubSubLinkedNode.
     * 
     * @param {T} data The data to set.
     * @memberof PubSubLinkedNode
     */
    public constructor(data: T) {
        this.data = data;
        this.next = new Array<PubSubLinkedNode<T>>();
    }
}