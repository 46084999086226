import { Logger } from '../logging';
import { IRouter } from '../router/interfaces';
import { RoutingUtils } from './routingUtils';


/**
 * The route manager will handle every route and router.
 *
 * @export
 * @class RouteManager
 */
export class RouteManager {
    private static window?: Window;
    private static document?: Document;
    private static router = new Array<IRouter>();
    private static logger?: Logger;

    /**
     * Dispatch a path to routes.
     *
     * @private
     * @static
     * @param {string} path The path to dispatch.
     * @param {*} [state] The state.
     * @returns {boolean} Whether a route was routed.
     * @memberof RouteManager
     */
    private static dispatchRoute(path: string, state?: any): boolean {
        const methodName = 'dispatchRoute';
        let navigationAffectedRoutes = 0;
        let errorsOnRoute = false;
        const availableRoutes = new Array<RegExp>();
        RouteManager.router.forEach((router) => {
            const regExp = router.getRoutePattern();
            availableRoutes.push(regExp);
            if (regExp.test(path)) {
                navigationAffectedRoutes++;
                const success = router.route(path, state);
                if (!success) {
                    errorsOnRoute = true;
                }
            }
        });
        if (navigationAffectedRoutes !== 1) {
            navigationAffectedRoutes === 0 ?
                RouteManager.logger?.info('RouteManager', methodName, 'No route was found for: ' + path, availableRoutes) :
                RouteManager.logger?.info('RouteManager', methodName, 'More than 1 router is registered for the route: ' + path);
        }
        return navigationAffectedRoutes > 0 && !errorsOnRoute;
    }

    /**
     * Init the route manager call this method once first.
     *
     * @static
     * @param {Window} window The window.
     * @param {Document} document The document.
     * @param {Logger} logger The logger.
     * @memberof RouteManager
     */
    public static init(window: Window, document: Document, logger: Logger): void {
        RouteManager.window = window;
        RouteManager.document = document;
        RouteManager.logger = logger;
        RouteManager.router = new Array<IRouter>();

        RouteManager.window.onpopstate = (event) => {
            if (RouteManager.document) {
                const isSuccess = RouteManager.dispatchRoute(RouteManager.document.location.pathname, event.state);
                if (!isSuccess && RouteManager.window) {
                    // Disallow wrong url in adress bar if routing doesn't work since another route is still used
                    // i.e. having a save popup open.
                    RouteManager.window.history.forward();
                }
            }
        };
    }

    /**
     * Registers a new router.
     *
     * @static
     * @param {IRouter} router The router.
     * @memberof RouteManager
     */
    public static registerRouter(router: IRouter): void {
        RouteManager.router.push(router);
    }

    /**
     * Navigates to a specifc url.
     *
     * @static
     * @param {string} url The url to navigagte to.
     * @param {boolean} [skipHistory=false] Whether to skip the history entry.
     * @param {boolean} [keepSearch=true] Whether the search values should be kept.
     * @returns {boolean} Whether the navigation was a success.
     * @memberof RouteManager
     */
    public static navigate(url: string, skipHistory: boolean = false, keepSearch: boolean = true): boolean {
        if (RouteManager.window) {
            if (!skipHistory) {
                const routingIsSuccessfull = RouteManager.dispatchRoute(url);
                if (routingIsSuccessfull) {
                    url = RouteManager.appendSearchOrHash(url, keepSearch, false);
                    RouteManager.window.history.pushState(null, '', url);
                }
                return routingIsSuccessfull;
            } else {
                return RouteManager.dispatchRoute(url);
            }
        } else {
            RouteManager.logger?.fatal('RouteManager', 'navigate', 'Window is not set, call init() first');
            return false;
        }
    }

    /**
     * Will push a state to the history api.
     *
     * @static
     * @param {*} data The state.
     * @param {string} title The title.
     * @param {(string | null | undefined)} [url] The url.
     * @memberof RouteManager
     */
    public static pushState(data: any, title: string, url?: string | null | undefined): void {
        if (RouteManager.window) {
            RouteManager.window.history.pushState(data, title, url);
        } else {
            RouteManager.logger?.fatal('RouteManager', 'pushState', 'Window is not set, call init() first');
        }
    }

    /**
     * Replaces the current state within history api.
     *
     * @static
     * @param {*} data The state.
     * @param {string} title The title.
     * @param {(string | null | undefined)} [url] The url.
     * @memberof RouteManager
     */
    public static replaceState(data: any, title: string, url?: string | null | undefined): void {
        if (RouteManager.window) {
            RouteManager.window.history.replaceState(data, title, url);
        } else {
            RouteManager.logger?.fatal('RouteManager', 'replaceState', 'Window is not set, call init() first');
        }
    }

    /**
     * Replaces the state if it's the current entry in the history api otherwise it will be pushed.
     *
     * @static
     * @param {*} data The state.
     * @param {string} title The title.
     * @param {string} url The url.
     * @param {boolean} [keepSearch=true] Whether the search values should be kept.
     * @memberof RouteManager
     */
    public static replaceOrPushState(data: any, title: string, url: string, keepSearch: boolean = true): void {
        if (RouteManager.window) {
            const currentPath = RouteManager.window.location.pathname;
            const currentOrigin = RouteManager.window.location.origin;
            const currentUrl = currentOrigin + currentPath;
            if (currentUrl === url || url.indexOf(currentOrigin) === -1 && url === currentPath) {
                url = RouteManager.appendSearchOrHash(url, keepSearch, false);
                RouteManager.replaceState(data, title, url);
            } else {
                url = RouteManager.appendSearchOrHash(url, keepSearch, false);
                RouteManager.pushState(data, title, url);
            }
        } else {
            RouteManager.logger?.fatal('RouteManager', 'replaceOrPushState', 'Window is not set, call init() first');
        }
    }


    /**
     * Replaces the current path.
     *
     * @static 
     * @param {string} path The path which  will be set.
     * @param {boolean} [keepSearch=true] Whether the search values should be kept.
     * @param {boolean} [keepHash=false] Whether the hash value should be kept.
     * @param {*} [state] The state to set.
     * @memberof RouteManager
     */
    public static replaceCurrentPath(path: string, keepSearch: boolean = true, keepHash: boolean = false, state?: any): void {
        if (RouteManager.window) {
            const currentPath = RouteManager.appendSearchOrHash(path, keepSearch, keepHash);
            RouteManager.replaceState(state, '', currentPath);
        } else {
            RouteManager.logger?.fatal('RouteManager', 'replaceCurrentPath', 'Window is not set, call init() first');
        }
    }

    /**
     * Navigate backwards.
     *
     * @static
     * @memberof RouteManager
     */
    public static back(): void {
        if (RouteManager.window) {
            RouteManager.window.history.back();
        } else {
            RouteManager.logger?.fatal('RouteManager', 'back', 'Window is not set, call init() first');
        }
    }

    /**
     * Reset the browser URL to the application root.
     *
     * @static
     * @memberof RouteManager
     */
    public static resetUrl(): void {
        // Reset the URL on logout
        if (RouteManager.window) {
            RouteManager.window.history.pushState(null, '', '/');
        }
    }

    /**
     * Appends the search and/or the hash to the url.
     *
     * @private
     * @static
     * @param {string} url The url.
     * @param {boolean} appendSearch The search.
     * @param {boolean} appendHash The hash.
     * @returns {string} The url with hash and/or search.
     * @memberof RouteManager
     */
    private static appendSearchOrHash(url: string, appendSearch: boolean, appendHash: boolean): string {
        if (RouteManager.window) {
            if (appendSearch) {
                if (RouteManager.window.location.search.length > 0) {
                    url += RouteManager.window.location.search;
                    if (RoutingUtils.getLegacyQueryString()) {
                        url += '&' + RoutingUtils.getLegacyQueryString();
                    }
                } else {
                    if (RoutingUtils.getLegacyQueryString()) {
                        url += '?' + RoutingUtils.getLegacyQueryString();
                    }
                }
            }
            if (appendHash) {
                url += RouteManager.window.location.hash;
            }
        }
        return url;
    }
}