import { Logger } from '../logging';
import { ConfigUiHelper } from '../ui';
import { IRouter, RoutingUtils } from '.';

/**
 * Router for sub view routing.
 *
 * @export
 * @class SubViewRouter
 * @implements {IRouter}
 */
export class SubViewRouter implements IRouter {
    private routeRegExp: RegExp;
    private catchRegExp: RegExp;
    private onSubViewChangeCallbacks: ((view: string) => void)[];
    private logger: Logger;
    private currentWindow: Window;

    /**
     * Creates an instance of SubViewRouter.
     * 
     * @param {Logger} logger The logger.
     * @param {Window} currentWindow The current window.
     * @memberof SubViewRouter
     */
    public constructor(logger: Logger, currentWindow: Window) {
        this.routeRegExp = new RegExp('\/view\/[^\/]+\/subview\/[^\/]+', 'i');
        this.catchRegExp = new RegExp('\/view\/[^\/]+\/subview\/([^\/]+)', 'i');
        this.onSubViewChangeCallbacks = new Array<(view: string) => void>();
        this.logger = logger;
        this.currentWindow = currentWindow;
    }

    /**
     * The route to route.
     *
     * @param {string} path The path.
     * @returns {boolean} Whether the routing was a success.
     * @memberof SubViewRouter
     */
    public route(path: string): boolean {
        const catchingGroups = this.catchRegExp.exec(path);
        if (catchingGroups) {
            this.onSubViewChangeCallbacks.forEach((callback) => {
                callback(catchingGroups[1]);
            });
            if (ConfigUiHelper.hasUnsavedChanges()) {
                return false;
            } else {
                return true;
            }
        } else {
            this.logger.error('SubViewRouter', 'route', 'Call was not routed because the catching group is somehow empty');
            return false;
        }
    }

    /**
     * Gets the current sub view from the path.
     *
     * @returns {(string | null)} The sub view or null if not found.
     * @memberof SubViewRouter
     */
    public getCurrentSubViewFromPath(): string | null {
        const catchingGroups = this.catchRegExp.exec(this.currentWindow.location.pathname);
        if (catchingGroups && catchingGroups.length > 1) {
            return catchingGroups[1];
        }
        return null;
    }

    /**
     * Gets the route pattern.
     *
     * @returns {RegExp} The pattern.
     * @memberof SubViewRouter
     */
    public getRoutePattern(): RegExp {
        return this.routeRegExp;
    }

    /**
     * Generate the url for a sub view.
     *
     * @static
     * @param {string} viewId The id.
     * @param {string} subViewId The sub view id.
     * @returns {string} The url.
     * @memberof SubViewRouter
     */
    public static generateSubViewUrl(viewId: string, subViewId: string): string {
        return RoutingUtils.getApplicationBasePath() + 'view/' + viewId + '/subview/' + subViewId;
    }

    /**
     * Register a callback for sub view change.
     *
     * @param {(subViewId: string) => void} callback The callback to register.
     * @memberof SubViewRouter
     */
    public registerOnSubViewChange(callback: (subViewId: string) => void): void {
        this.onSubViewChangeCallbacks.push(callback);
    }
}