import { ICredentialManager } from './interfaces/iCredentialManager';
import { UserCredentials } from './userCredentials';
import { UserDetails } from './userDetails';


/**
 * This class handles all credential related tasks.
 * 
 * @export
 * @class CredentialManager
 * @implements {ICredentialManager}
 */
export class CredentialManager implements ICredentialManager {

    private currentCredentials: UserCredentials | null;
    private authenticatedUser: UserDetails | null;

    /**
     * Creates an instance of CredentialManager.
     *
     * @memberof CredentialManager
     */
    public constructor() {
        this.currentCredentials = null;
        this.authenticatedUser = null;
    }


    /**
     * Gets the credentials.
     * 
     * @returns {(UserCredentials | null)} 
     * @memberof CredentialManager
     */
    public getCredentials(): UserCredentials | null {
        return this.currentCredentials;
    }

    /**
     * Sets the credentials.
     * 
     * @param {UserCredentials} credentials 
     * @memberof CredentialManager
     */
    public setCredentials(credentials: UserCredentials): void {
        this.currentCredentials = credentials;
    }

    /**
     * Clears the credentials.
     * 
     * @memberof CredentialManager
     */
    public clearCredentials() {
        this.currentCredentials = null;
    }


    /**
     * Gets the authenticated user.
     * 
     * @returns {(UserDetails | null)} 
     * @memberof CredentialManager
     */
    public getAuthenticatedUser(): UserDetails | null {
        return this.authenticatedUser;

    }

    /**
     * Sets the authenticated user.
     * 
     * @param {UserDetails} userDetails 
     * @memberof CredentialManager
     */
    public setAuthenticatedUser(userDetails: UserDetails) {
        this.authenticatedUser = userDetails;
    }

    /**
     * Clears the authenticated user.
     * 
     * @memberof CredentialManager
     */
    public clearAuthenticatedUser() {
        this.authenticatedUser = null;
    }

}