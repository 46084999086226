import { ITemplateExtension } from '../extensions';
import { ILanguageProvider } from '../language';
import { ISubViewManager } from '../loader';
import { IAppMenuHandler, ISubViewNavigationHandler, ISubViewNavigationHandlerFactory } from './interfaces';
import { SubViewNavigationHandler } from '.';

/**
 * Factory for creating ISubViewNavigationHandler instances.
 * 
 * @export
 * @class SubViewNavigationHandlerFactory
 */
export class SubViewNavigationHandlerFactory implements ISubViewNavigationHandlerFactory {
    private templateExtension: ITemplateExtension;
    private subViewManager: ISubViewManager;
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of SubViewNavigationHandlerFactory.
     * @param {ITemplateExtension} templateExtension ITemplateExtension instance to pass to the ISubViewNavigationHandler instance.
     * @param {ISubViewManager} subViewManager ISubViewManager instance to pass to the ISubViewNavigationHandler instance.
     * @param {ILanguageProvider} languageProvider ILanguageProvider instance to pass to the ISubViewNavigationHandler instance.
     * @memberof SubViewNavigationHandlerFactory
     */
    public constructor(templateExtension: ITemplateExtension, subViewManager: ISubViewManager, languageProvider: ILanguageProvider) {
        this.templateExtension = templateExtension;
        this.subViewManager = subViewManager;
        this.languageProvider = languageProvider;
    }

    /**
     * Creates an instance of ISubViewNavigationHandler.
     * 
     * @param {HTMLElement} element Element to pass to the ISubViewNavigationHandler instance.
     * @param {IAppMenuHandler} appMenuHandler IAppMenuHandler instance to pass to the ISubViewNavigationHandler instance.
     * @returns {ISubViewNavigationHandler} 
     * @memberof SubViewNavigationHandlerFactory
     */
    public create(element: HTMLElement, appMenuHandler: IAppMenuHandler): ISubViewNavigationHandler {
        return new SubViewNavigationHandler(element, this.templateExtension, this.subViewManager, appMenuHandler, this.languageProvider);
    }
}