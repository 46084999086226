import { IndexedDBWrapperFactory } from '../indexedDB/indexedDBWrapperFactory';
import { Logger } from '../logging';
import { StorageManager } from '../storage/storageManager';
import { IStorageExtension } from './interfaces';
import { StorageDataModel } from './models/storageDataModel';

/**
 * The storage extension provides storage access.
 * 
 * @export
 * @class StorageExtension
 * @implements {IStorageExtension}
 */
export class StorageExtension implements IStorageExtension {

    /**
     * The name of the extension
     * 
     * @type {string}
     * @memberof StorageExtension
     */
    public name: string = 'storage';

    private logger: Logger;
    private storageManager: StorageManager;
    private factory: IndexedDBWrapperFactory;
    /**
     * Creates an instance of LoaderExtension.
     * @param {Logger} logger 
     * @param {StorageManager} storage 
     * @param {IndexedDBWrapper} indexedDB 
     * @memberof StorageExtension
     */
    public constructor(logger: Logger, storage: StorageManager, factory: IndexedDBWrapperFactory) {
        this.logger = logger;
        this.storageManager = storage;
        this.factory = factory;
    }


    /**
     * Save the data into the storage.
     * 
     * @param {string} componentName The component name.
     * @param {StorageDataModel} data The data to save.
     * @returns {Promise<boolean>} Whether it is successful or not.
     * @async
     * 
     * @memberof StorageExtension
     */
    public async save(componentName: string, data: StorageDataModel): Promise<boolean> {
        const indexedDB = this.factory.create(this.logger, componentName);
        return new Promise<boolean>((resolve) => {
            if (indexedDB.indexedDBAvailable) {
                indexedDB.initializeDB(componentName, 'identifier').then(() => {
                    indexedDB.put(data, (success) => {
                        if (success) {
                            resolve(success);
                        } else {
                            this.storageManager.setItem(data.identifier, data.data);
                            resolve(true);
                        }
                    });
                }).catch((error) => {
                    this.logger.debug('StorageExtension', 'save', 'Cannot save to indexedDB', error);
                    this.storageManager.setItem(data.identifier, data.data);
                    resolve(true);
                });
            } else {
                this.storageManager.setItem(data.identifier, data.data);
                resolve(true);
            }
        });
    }

    /**
     * Load the data from storage. 
     * 
     * @param {string} componentName The component name.
     * @param {string} identifier The identifier from the saved dataset.
     * @returns {Promise<string>} The auto completion terms.
     * @async
     * 
     * @memberof StorageExtension
     */
    public async load(componentName: string, identifier: string): Promise<string> {
        const indexedDB = this.factory.create(this.logger, componentName);
        return new Promise<string>((resolve, reject) => {
            if (indexedDB.indexedDBAvailable) {
                indexedDB.initializeDB(componentName, 'identifier').then(() => {
                    indexedDB.get(identifier, (data) => {
                        if (data && data.data) {
                            resolve(data.data);
                        } else {
                            const data = this.storageManager.getItem(identifier);
                            data ? resolve(data) : reject(new Error('Can not retrieve data from storage'));
                        }
                    });
                }).catch((error) => {
                    const data = this.storageManager.getItem(identifier);
                    this.logger.debug('StorageExtension', 'save', 'Cannot load from indexedDB', error);
                    data ? resolve(data) : reject(new Error('Can not retrieve data from storage'));
                });
            } else {
                const data = this.storageManager.getItem(identifier);
                data ? resolve(data) : reject(new Error('Can not retrieve data from storage'));
            }
        });
    }
}