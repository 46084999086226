/**
 * The model class for a incoming PubSub event configuration.
 *
 * @export
 * @class InConfig
 */
export class InConfig {

    /**
     * The name of the PubSub event.
     *
     * @type {string}
     * @memberof InConfig
     */
    public name: string;

    /**
     * The description of the PubSub event.
     *
     * @type {string}
     * @memberof InConfig
     */
    public description?: string;

    /**
     *  The data types of the PubSub event.
     *
     * @type {(string | string[])}
     * @memberof InConfig
     */
    public dataType?: string | string[];

    /**
     * The display value of the PubSub event.
     *
     * @type {string}
     * @memberof InConfig
     */
    public displayValue?: string;

    /**
     * State to check if PubSub input event is required.
     *
     * @type {boolean}
     * @memberof InConfig
     */
    public isRequired?: boolean;

    /**
     * The id of this subscriber.
     *
     * @type {string}
     * @memberof InConfig
     */
    public id?: string;

    /**
     * Creates an instance of InConfig.
     *
     * @param {string} name
     * @memberof InConfig
     */
    public constructor(name: string) {
        this.name = name;
    }
}