import { Logger } from '../logging/logger';
import { TriggerHandler } from '../triggerHandler';
import { IPopupHelper } from '../ui';
import { HandleEnableDisablePubSubOperation } from './handleEnableDisablePubSubOperation';
import { IPubSubHandler, IPubSubHandlerFactory } from './interfaces';
import { PubSubHandler } from './pubSubHandler';

/**
 * This factory provides instances of IPubSubHandler
 * 
 * @export
 * @interface PubSubHandlerFactory
 */
export class PubSubHandlerFactory implements IPubSubHandlerFactory {
    private triggerHandlers: TriggerHandler[];
    private logger: Logger;
    private popupHelper: IPopupHelper;

    /**
     * Creates an instance of PubSubHandlerFactory.
     * @param {TriggerHandler[]} triggerHandlers Available trigger handler.
     * @param {Logger} logger Logger instance to use.
     * @param {IPopupHelper} popupHelper The popuphelper.
     * 
     * @memberof PubSubHandlerFactory
     */
    public constructor(triggerHandlers: TriggerHandler[], logger: Logger, popupHelper: IPopupHelper) {
        this.triggerHandlers = triggerHandlers;
        this.logger = logger;
        this.popupHelper = popupHelper;
    }

    /**
     * Creates a new instance of IPubSubHandler.
     *
     * @param {boolean} [strictRecursionCheck=false] Whether a strict recursion check should be applied.
     * @returns {IPubSubHandler} A new instance of IPubSubHandler.
     * @memberof PubSubHandlerFactory
     */
    public create(strictRecursionCheck: boolean = false): IPubSubHandler {
        const createdPubSubHandler = new PubSubHandler(this.triggerHandlers, this.logger, this.popupHelper, strictRecursionCheck);

        // Add default publish pipeline operations.
        createdPubSubHandler.registerPublishPipelineOperation(new HandleEnableDisablePubSubOperation());

        return createdPubSubHandler;
    }

}