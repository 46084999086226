import { DWCore } from '@windream/dw-core/dwCore';
import { MigrateFunction } from 'typings';
import { IServiceResponse } from './ajaxHandler/interfaces/iServiceResponse';
import { UserDetails } from './authentication/userDetails';
import { UserIdentity } from './authentication/userIdentity';
import { IMetadataStore, ObjectType, WindreamAttribute, WindreamAttributeTypes } from './caching';
import {
  AttributeFlags, DateItem, IClonable, IDirectScriptExecutor, IScriptMetadata, IWindreamScriptExecutor,
  SCRIPT_SOURCE, Utils, ValueTypes, WindreamEntity, WindreamIdentity
} from './common';
import { ComponentConfig, ComponentInstanceConfig, GlobalConfig, Position, ViewConfig, ViewConfigMetaData } from './config';
import { Style } from './config/models/style';
import { ContextMenu, ContextMenuItem, ContextMenuItemValidator } from './contextMenu';
import { CultureHelper, DateTimeFormatHelper, WindreamFormatter } from './culture';
import { CellData, DataCollection, DataCollectionDisplay, HeaderData, IDataCollectionData, ISortingOption, RowData } from './dataCollections';
import { IResourcePointer, RequestOptions } from './dataProviders';
import {
  IContextMenuExtension, IExtension, IExtensionProvider, IIndexMaskExtension, ILoaderExtension,
  IStorageExtension, ITemplateExtension, IUserConfigExtension, StorageDataModel, ToolbarExtension
} from './extensions';
import { ILanguage, IUtils } from './interface'; // TODO: Check if this can be removed
import { SEARCH_OPERATORS, SEARCH_RELATIONS, SearchAttributeFlags, SearchCondition, SearchGroup, SearchQuery, SearchSorting } from './interface/search';
import { KeyCodes } from './keyboard';
import { ILanguageManager, ILanguageProvider } from './language';
import { Changes, OnAfterBind, OnAfterRender, OnBind, OnDestroy, OnExtension, OnInit, OnRefresh, OnSubViewChanging, OnRender, OnSkeleton } from './lifecycle';
import { IComponent, IUiManager } from './loader';
import { Logger } from './logging';
import { MasterList } from './masterList';
import { DxMasterTable, IMasterTableOptions, MasterTable } from './masterTable';
import { CLICKPROGRESSION_MODES, IMasterTreeView, IMasterTreeViewData, IMasterTreeViewItem, IMasterTreeViewOptions2, MasterTreeView, TreeItem, TreeLibNode } from './masterTreeView';
import { MemoryManager } from './memoryManager/memoryManager';
import { DataTypeChecker, IPubSubHandler } from './pubSub';
import {
  ActionResponse, AddHistoryRequestOptions, FetchNodesRequestOptions,
  FileDataSource, GetDetailsRequestOptions,
  IdentityMapper, IServiceManager, IServices,
  PerformActionRequestOptions, ResultAmountRequestOptions, SearchRequestOptions,
  UpdateDirectoryRightsRequestOptions, UpdateDocumentRightsRequestOptions, UpdateNavigationSettingsRequestOptions,
  UpdateRequestOptions, UploadRequestOptions, WindreamRequestOptions
} from './services';
import { IIntentManager } from './sharing';
import { IThemeManager } from './themeManager';
import { ApplicationSettings, ButtonTypes, FileTransferManagerPopOverHandler, IPopupButtonOptions, IPopupHelper, IPopupOptions, ISkeletonHelper, POPUP_TYPES, SKELETON_TYPES } from './ui';
import { IUiComponentFactory } from './ui/components';
import { IList } from './ui/components/list';
import { INotificationHelper, BaseFileTransferManagerHandler } from './ui/interfaces';
import { NotificationOptions } from './ui/models/notificationOptions';
import { IAppDownloadHandler, IAppFocusHandler, IWebBridgeHandler, WebBridgeEventTypes } from './webBridge';
import { IWebsocketProvider } from './websocket';

// Export publicly available interfaces
export {
  // Core
  DWCore,
  // Parameters of Lifecycle steps
  IPubSubHandler, IUiManager, IExtensionProvider,
  // Configurations
  ComponentInstanceConfig, ViewConfig, ComponentConfig, GlobalConfig, ViewConfigMetaData, Position,
  // Lifecycle steps
  OnAfterBind, OnBind, OnInit, OnExtension, OnRender, OnSkeleton, OnDestroy, OnAfterRender, OnRefresh, OnSubViewChanging,
  // Models
  DateItem, WindreamEntity, WindreamIdentity, AttributeFlags, ValueTypes,
  // Extensions
  IExtension, IUserConfigExtension, ToolbarExtension, IContextMenuExtension, ILoaderExtension, IIndexMaskExtension, ITemplateExtension, IStorageExtension,
  // Public API related
  NotificationOptions, CultureHelper, IWebsocketProvider, IMetadataStore, IPopupOptions, ButtonTypes, IPopupHelper,
  POPUP_TYPES, DataTypeChecker, Logger, INotificationHelper, ILanguageProvider, ILanguageManager,
  // Other
  IComponent, ActionResponse, UserIdentity, ApplicationSettings, KeyCodes, IClonable,
  // TODO: Remove or re-organize
  Utils, WindreamAttribute, WindreamAttributeTypes, WindreamFormatter, IPopupButtonOptions, Style, StorageDataModel, DateTimeFormatHelper, FileDataSource, ObjectType, IdentityMapper,
  // Search
  SearchAttributeFlags, SearchQuery, SearchSorting, SEARCH_OPERATORS, SEARCH_RELATIONS, SearchCondition, SearchGroup,
  // Master table
  CellData, HeaderData, RowData, DataCollection, DataCollectionDisplay, IMasterTableOptions, ISortingOption, MasterTable, DxMasterTable, IDataCollectionData, MasterList,
  // Master tree
  TreeItem, TreeLibNode, IMasterTreeViewData, MasterTreeView, IMasterTreeView, IMasterTreeViewItem, IMasterTreeViewOptions2 as IMasterTreeViewOptions, CLICKPROGRESSION_MODES,
  // Context Menu
  ContextMenu, ContextMenuItem, ContextMenuItemValidator,
  // Skeletons
  ISkeletonHelper, SKELETON_TYPES,
  // WebBridge
  IWebBridgeHandler, WebBridgeEventTypes,
  // Service request options
  WindreamRequestOptions, GetDetailsRequestOptions,
  UploadRequestOptions, UpdateDocumentRightsRequestOptions, AddHistoryRequestOptions,
  UpdateRequestOptions, UpdateDirectoryRightsRequestOptions, UpdateNavigationSettingsRequestOptions,
  ResultAmountRequestOptions, FetchNodesRequestOptions, PerformActionRequestOptions, SearchRequestOptions,
  // Scripts
  IScriptMetadata, SCRIPT_SOURCE,
  // UI
  IList,
  // File Tramsfer Manager Pop-Over
  FileTransferManagerPopOverHandler
};

export * from './errors';
export * from './interface';
export * from './shared';
export * from './rights';
export * from './ui/components';
export * from './migration';
export * from './common/webService';
export * from './common/lifecycle';
export * from './common/history';


/**
 * The public accessible request executor interface.
 * 
 * @export
 * @interface IPublicRequestExecutor
 */
export interface IPublicRequestExecutor {
  executeRequest(resourcePointer: IResourcePointer, options?: RequestOptions): Promise<IServiceResponse<any>>;
}


/**
 * The PublicApi interface.
 *
 * @export
 * @interface PublicApi
 */
export interface PublicApi {
  App: {
    FocusHandler: IAppFocusHandler,
    DownloadHandler: IAppDownloadHandler;
  };

  Authentication: {
    getCurrentUser: () => UserDetails | null
  };

  Lifecycle: {
    /**
     * Function to register any action in the browser in, that is not part of any component.
     * Works similar to jQuery(document).ready().
     * If the function is called after the Framework already initialized, it is executed directly.
     * @param {Function} callback  Callback to execute once the Framework finished loading.
     */
    ready: (callback: () => void) => void,
    /**
     * Toggles refresh of components. 
     */
    refresh: (changes: Changes) => void,
    enableEdit: () => void,
    disableEdit: () => void
  };

  PubSub: {
    /**
     * Subscribes a callback to a combination of guid and event name.
     * Wraps the internal `PubSubHandler` object.
     * See PubSub documentation for further information.
     * @param {String}   guid      Guid of the component.
     * @param {String}   eventName Name of the event to subscribe to.
     * @param {Function} callback  Callback to execute once a mapped event is published.
     */
    subscribe: (guid: string, eventName: string, callback: (data: any) => void) => void,
    /**
     * Publishes an event for a combination of guid and event name.
     * Wraps the internal `PubSubHandler` object.
     * See PubSub documentation for further information.
     * @param {String} guid      Guid of the component.
     * @param {String} eventName Name of the event to subscribe to.
     * @param {Object} data      Data to send with the event.
     */
    publish: (guid: string, eventName: string, data: any) => void,
    /**
     * Registers a new configuration to the PubSubHandler.
     * Wraps the internal `PubSubHandler` object.
     * See PubSub documentation for further information.
     * @param {Object} config Configuraiton to use.
     */
    register: (config: any) => void
  };
  Config: {
    getGlobalConfig: () => GlobalConfig
  };
  /**
   * The culture helper class.
   */
  Culture: CultureHelper;
  /**
   * The language interface.
   */
  Language: ILanguage;

  /**
   * The logger class.
   */
  Logger: Logger;
  /**
   * Registers a component to the Lifecycle Manager.
   * Wraps the internal `LifecycleManager` object.
   * See Lifecycle Manager documentation for further information
   * @param {string} componentName Name of the component.
   * @param {any}  component     Component to register.
   * @param {[MigrateFunction]}  migrateFunction The migration function.
   */
  register: (componentName: string, component: any, migrateFunction?: MigrateFunction) => void;

  /**
   * The Utils.
   */
  Utils: IUtils;

  /**
   * The file transfer manager.
   */
  FileTransferManagerHandler: BaseFileTransferManagerHandler;

  /**
   * The current version.
   *
   * @type {string}
   * @memberof PublicApi
   */
  Version: string;

  /**
   * Popup Menu Helper
   */
  Popup: IPopupHelper;

  /**
   * Notification Helper
   */
  Notification: INotificationHelper;

  /**
   * The request executor.
   * 
   * @type {IPublicRequestExecutor}
   * @memberof PublicApi
   */
  RequestExecutor: IPublicRequestExecutor;

  /**
   * Provides the Windream Metadata Store that contains information about the windream server.
   */
  getWindreamMetadata: () => IMetadataStore;

  /**
   * Services
   */
  Services: IServices;

  /**
   *  The service manager.
   * 
   * @type {IServiceManager}
   * @memberof PublicApi
   */
  ServiceManager: IServiceManager;

  /**
   * The webbridge.
   * 
   * @type {IWebBridgeHandler}
   * @memberof PublicApi
   */
  WebBridge: IWebBridgeHandler;

  /**
   * The memory manager.
   * 
   * @type {MemoryManager}
   * @memberof PublicApi
   */
  Memory: MemoryManager;

  /**
   * The current Theme.
   *
   * @type {ThemeManager}
   * @memberof PublicApi
   */
  theme: IThemeManager;

  /**
   * The intent manager.
   * 
   * @type {IIntentManager}
   * @memberof PublicApi
   */
  Intents: IIntentManager;

  /**
   * The script executor to execute custom scripts.
   * 
   * @type {{
   *     Direct: IDirectScriptExecutor;
   *     Windream: IWindreamScriptExecutor;
   *   }}
   * @memberof PublicApi
   */
  ScriptExecutor: {
    Direct: IDirectScriptExecutor;
    Windream: IWindreamScriptExecutor;
  };

  Ui: {
    /**
     * Adds busy state to the application.
     * Use `appIsIdle()` to remove this state.
     */
    appIsBusy: () => void;

    /**
     * Removes busy state from the application.
     */
    appIsIdle: () => void;

    /**
     * Allows creation of UI components.
     *
     * @type {IUiComponentFactory}
     */
    components: IUiComponentFactory;
  };

  $: JQueryStatic;
}