import { PublicApi as Framework} from './framework';
import { KeyCodes } from './keyboard';

/**
 * Add class `tabbing` to the body when navigation with tab o shift + tab.
 * Remove class `tabbing` when mouse is clicked.
 */
// @ts-ignore - Ignore because of window usage
if (typeof window !== 'undefined' && typeof window['Foundation'] === 'object') {
  document.addEventListener('keydown', (e: Event) => {
    // @ts-ignore - Ignore because of window usage
    const keyPressed = window['Foundation'].Keyboard.parseKey(e);
    if (keyPressed === 'TAB' || keyPressed === 'SHIFT_TAB') {
      document.body.classList.add('tabbing');
    }
  });
  document.addEventListener('mousedown', () => {
    document.body.classList.remove('tabbing');
  });
}

/**
 * Prevent scrolling if a context menu is currently open.
 * This is used instead of a simple oveflow: hidden CSS class so the scrollbars do not "jump" once they are hidden
 * and to avoid having to set a class to every scrollable container.
 * See https://stackoverflow.com/questions/8701754/just-disable-scroll-not-hide-it
 */
['mousewheel', 'touchmove', 'keydown'].forEach((eventName) => {
  document.body.addEventListener(eventName, (e) => {
    // Do nothing if the event is a keyboard event not triggered by an arrow key
    if (e instanceof KeyboardEvent && e.type === 'keydown' && !(e.keyCode >= KeyCodes.LEFT_ARROW && e.keyCode <= KeyCodes.DOWN_ARROW)) {
      return;
    }
    if (document.body.classList.contains('contextmenu-open')) {
      e.preventDefault();
    }
  }, true);
});

// @ts-ignore - Ignore because of window usage
window['DynamicWorkspace'] = Framework;