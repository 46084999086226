import { ILanguageProvider } from '../../language';
import { ILogoutPageHelper } from '../index';

/**
 * Provides the logout page.
 *
 * @export
 * @class LogoutPageHelper
 * @implements {ILogoutPageHelper}
 */
export class LogoutPageHelper implements ILogoutPageHelper {
    private jQueryStatic: JQueryStatic;
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of LogoutPageHelper.
     * @param {JQueryStatic} jQueryStatic
     * @param {ILanguageProvider} languageProvider
     * @memberof LogoutPageHelper
     */
    public constructor(jQueryStatic: JQueryStatic, languageProvider: ILanguageProvider) {
        this.jQueryStatic = jQueryStatic;
        this.languageProvider = languageProvider;
    }

    /**
     * Render the logout page.
     *
     * @param {HTMLElement} targetElement The target to render into.
     * @memberof LogoutPageHelper
     */
    public render(targetElement: HTMLElement): void {
        this.jQueryStatic(targetElement).find('.static-page').remove();
        targetElement.innerHTML = '';
        const staticPage = this.jQueryStatic('<div class="static-page"></div>');
        const logoutContainer = this.jQueryStatic('<div class="text-center"></div>');
        const logoutHeader = this.jQueryStatic('<h2></h2>').text(this.languageProvider.get('framework.static.logout.title'));
        const logoutText = this.jQueryStatic('<p></p>').text(this.languageProvider.get('framework.static.logout.body'));
        const loginButton = this.jQueryStatic(this.jQueryStatic('<div></div>')).dxButton({
            text: this.languageProvider.get('framework.login.signInButton.title'),
            stylingMode: 'contained',
            onClick: () => {
                location.reload();
            },
            type: 'default',
        }).addClass('text-center');
        logoutContainer.append([logoutHeader, logoutText, loginButton]);
        staticPage.append(logoutContainer);
        this.jQueryStatic(targetElement).append(staticPage);
    }
}