/* eslint-disable max-lines */
import { Utils, WindreamEntity, WindreamIdentity } from '../common';
import { Style } from '../config';
import { ILoaderExtension } from '../extensions';
import { ILanguageProvider } from '../language';
import { ISelectionManager } from '../loader';
import { Logger } from '../logging/logger';
import { WindreamFilePickerPopup } from '../popups';
import { WindreamFilePickerPopupOptions, WindreamPreviewPopupOptions } from '../popups/models';
import { WindreamPreviewPopup } from '../popups/windreamPreviewPopup';
import {IPubSubHandler, IPubSubHandlerFactory, PubSubConfig } from '../pubSub';
import { GetDetailsRequestOptions, IServiceManager } from '../services/index';
import { INotificationHelper } from '../ui';
import { TextBox, IUiComponentFactory } from '../ui/components';
import { IComponentPopupOptions, IPopupButtonOptions, IPopupHelper, IPopupOptions } from './interfaces';
import { IPopup } from './interfaces/iPopup';
import { IPopupButton } from './interfaces/iPopupButton';
import { Popup } from './popup';
import { IInputPopupOptions } from './index';

/**
 * Helper class to create Popups.
 *
 * @export
 * @class PopupHelper
 * @implements {IPopupHelper}
 */
export class PopupHelper implements IPopupHelper {

    private languageProvider?: ILanguageProvider;
    private foundationLib: any;
    private jQueryLib: JQueryStatic;
    private pubSubHandlerFactory?: IPubSubHandlerFactory;
    private loaderExtension?: ILoaderExtension;
    private serviceManager?: IServiceManager;
    private logger: Logger;
    private notificationHelper: INotificationHelper;
    private className = 'PopupHelper';
    private uiComponentFactory: IUiComponentFactory;
    private selectionManager?: ISelectionManager;
    // Use stack of popup elements to handle multiple popups open at the same time e.g. when a popup opens another popup.
    private popupStack: Array<IPopup>;
    private preSelectedFolder = new WindreamIdentity();
    private identityObject!: WindreamIdentity;
    private dialog: any;
    private pubSub?: IPubSubHandler;
    private treeViewPathInput!: any;

    /**
     * Creates an instance of PopupHelper.
     * 
     * @param {JQueryStatic} jQueryLib The jQuery libary to use.
     * @param {*} foundationLib Foundation library to use.
     * @param {Logger} logger The logger.
     * @param {INotificationHelper} notificationHelper The notification helper.
     * @param {IUiComponentFactory} uiComponentFactory The ui component factory.
     * @memberof PopupHelper
     */
    public constructor(jQueryLib: JQueryStatic, foundationLib: any, logger: Logger,
        notificationHelper: INotificationHelper, uiComponentFactory: IUiComponentFactory) {
        this.foundationLib = foundationLib;
        this.jQueryLib = jQueryLib;
        this.logger = logger;
        this.notificationHelper = notificationHelper;
        this.uiComponentFactory = uiComponentFactory;
        this.popupStack = [];
        window.addEventListener('popstate', this.popstateEventHandler);
    }

    /**
     * Sets the current languageProvider.
     *
     * @param {ILanguageProvider} provider The language provider.
     *
     * @memberof PopupHelper
     */
    public setLanguageProvider(provider: ILanguageProvider): void {
        this.languageProvider = provider;
    }

    /**
     * Sets the selection manager.
     *
     * @param {ISelectionManager} selectionManager The selection manager.
     * @memberof PopupHelper
     */
    public setSelectionManager(selectionManager: ISelectionManager): void {
        this.selectionManager = selectionManager;
    }

    /**
     * Sets the ILoaderExtension.
     *
     * @param {ILoaderExtension} loaderExtension The ILoaderExtension.
     * @memberof PopupHelper
     */
    public setLoaderExtension(loaderExtension: ILoaderExtension): void {
        this.loaderExtension = loaderExtension;
    }

    /**
     * Sets the IServiceManager.
     *
     * @param {IServiceManager} serviceManager The service manager.
     * @memberof PopupHelper
     */
    public setServiceManager(serviceManager: IServiceManager): void {
        this.serviceManager = serviceManager;
    }

    /**
     * Sets the PubSubHandlerFactory in order to create PubSubHandlers.
     *
     * @param {IPubSubHandlerFactory} factory THe pub sub handler factory.
     * @memberof PopupHelper
     */
    public setPubSubHandlerFactory(factory: IPubSubHandlerFactory): void {
        this.pubSubHandlerFactory = factory;
    }

    /**
     * Updates the last opened popup instance.
     *
     * @param {IPopup} popupInstance The popup instance to use as last opened one.
     * @memberof PopupHelper
     */
    public updatePopupInstance(popupInstance: IPopup): void {
        if (popupInstance) {
            if (this.popupStack.length > 0) {
                // Updates the last element of the stack
                this.popupStack[this.popupStack.length - 1] = popupInstance;
            } else {
                // Push popup instance if stack doesn't contain any popups.
                // Necessary for closing popups correctly when browser back is clicked.
                this.popupStack.push(popupInstance);
            }
        }
    }

    /**
     * Updates the title of the given popup instance.
     *
     * @param {IPopup} popupInstance Popup instance to update title for.
     * @param {string} newTitle The new title.
     * @memberof PopupHelper
     */
    public updatePopupTitle(popupInstance: IPopup, newTitle: string): void {
        if (popupInstance) {
            popupInstance.setTitle(newTitle);
        }
    }

    /**
     * Opens a component popup.
     *
     * @param {IComponentPopupOptions} options The component popup options.
     * @returns {Promise<void>}
     * @memberof PopupHelper
     */
    public openComponentPopup(options: IComponentPopupOptions): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {

            if (!this.loaderExtension) {
                this.logger.error(this.className, 'openComponentPopup', 'The loaderExtension is not set.');
                reject(new Error('No LoaderExtension set'));
                return;
            }

            const randomId = Utils.Instance.getRandomString();
            const popupOptions: IPopupOptions = {
                body: `<div class="wd-custom-dialog-modal" id="wd-custom-dialog-modal-${randomId}"></div>`,
                closeOnClick: false,
                closeOnEsc: true,
                immediatelyOpen: false,
                size: options.size,
                title: options.title,
                destroyOnClose: false,
                destroyOnButtonClick: !!(options.okButton || options.cancelButton)
            };

            // OK button
            if (options.okButton) {
                if (!popupOptions.buttons) {
                    popupOptions.buttons = new Array<IPopupButtonOptions>();
                }

                const okButton: IPopupButtonOptions = {
                    label: options.okButton.title ? options.okButton.title : this.getLanguageProvider().get('framework.generic.ok')
                };
                // eslint-disable-next-line no-console
                okButton.callback = () => {
                    if (options.okButton && options.okButton.callback) {
                        options.okButton.callback();
                    }
                    resolve();
                };
                popupOptions.buttons.push(okButton);
            }

            // Cancel button
            if (options.cancelButton) {
                if (!popupOptions.buttons) {
                    popupOptions.buttons = new Array<IPopupButtonOptions>();
                }

                const cancelButton: IPopupButtonOptions = {
                    label: options.cancelButton.title ? options.cancelButton.title : this.getLanguageProvider().get('framework.generic.cancel')
                };
                // eslint-disable-next-line no-console
                cancelButton.callback = () => {
                    if (options.cancelButton && options.cancelButton.callback) {
                        options.cancelButton.callback();
                    }
                    resolve();
                };
                popupOptions.onClose = popupOptions.onClose || cancelButton.callback;
                popupOptions.buttons.push(cancelButton);
            }

            const modal = this.openPopup(popupOptions);
            this.popstateEventHandler(undefined, modal, false);
            const popupPubSubGuid = 'WINDREAM_COMPONENT_POPUP_' + randomId;

            if (this.pubSubHandlerFactory) {
                const pubSubHandler = this.pubSubHandlerFactory.create();

                if (options.components) {
                    if (options.components.length > 1) {
                        throw new Error('Found more than one component inside the popup options. Unfortunately only one component is supported at the moment.');
                    }

                    const componentOptions = options.components[0];

                    await this.loaderExtension.loadComponent({
                        component: componentOptions.component,
                        configuration: {
                            DisplayMode: Utils.Instance.deviceDetection.isApp() ? '3' : '1'
                        },
                        guid: popupPubSubGuid + '_' + componentOptions.component,
                        isTitleVisible: false,
                        name: { en: componentOptions.component },
                        position: '#wd-custom-dialog-modal-' + randomId,
                        style: null
                    }, pubSubHandler);

                    // Try to configure the PubSub                    
                    if (componentOptions.pubSub) {
                        const pubSubConfigs: PubSubConfig[] = [];

                        componentOptions.pubSub.forEach((pubSub) => {
                            const pubSubConfig: PubSubConfig = {
                                executeForEmptyData: pubSub.executeForEmptyData,
                                in: [],
                                out: []
                            };
                            if (pubSub.out) {
                                pubSub.out.forEach((outPubSub) => {
                                    pubSubConfig.in.push({
                                        componentGuid: popupPubSubGuid,
                                        parameter: outPubSub.key
                                    });
                                    pubSubConfig.out.push({
                                        componentGuid: popupPubSubGuid + '_' + componentOptions.component,
                                        parameter: outPubSub.key
                                    });
                                });
                            }
                            pubSubConfigs.push(pubSubConfig);
                        });

                        pubSubHandler.loadPubSub(pubSubConfigs as any);

                        componentOptions.pubSub.forEach((pubSub) => {
                            if (pubSub.out) {
                                pubSub.out.forEach((outPubSub) => {
                                    pubSubHandler.subscribe(popupPubSubGuid, outPubSub.key, (valuePromise: Promise<any>) => {
                                        valuePromise.then((value) => {
                                            outPubSub.callback(value);
                                        }).catch((err) => {
                                            this.logger.error(this.className, 'openComponentPopup', 'Error for out-PubSub: ' + outPubSub.key, err);
                                        });
                                    });
                                });
                            }
                        });
                    }

                    const body = modal.getElementById(`wd-custom-dialog-modal-${randomId}`);
                    if (body) {
                        // Having the height set in the app causes issues with the popup and the mobile keyboard
                        // TODO: Check if this is only relevant for the app or for mobile devices in general
                        if (!Utils.Instance.deviceDetection.isApp()) {
                            body.style.height = '66vh';

                            if (options.width) {
                                body.style.width = options.width;
                            }
                            if (options.height) {
                                body.style.height = options.height;
                            }
                        }
                        const container = <HTMLElement>body.querySelector('div');
                        if (container) {
                            container.style.height = '100%';
                            const componentDiv = <HTMLElement>container.querySelector('div');
                            if (componentDiv) {
                                componentDiv.style.height = '100%';
                            }
                        }
                    }

                    modal.onClosed = () => {
                        modal.destroy();
                        resolve();
                    };
                    modal.open();
                }
            }
        });
    }

    /**
     * Opens a windream file picker.
     *
     * @param {WindreamFilePickerPopupOptions} options The options.
     * @returns {Promise<WindreamIdentity[]>} A promise, resolving with windream identities.
     * @memberof PopupHelper
     */
    public openWindreamFilePickerPopup(options: WindreamFilePickerPopupOptions): Promise<WindreamIdentity[]> {
        return new Promise<WindreamIdentity[]>((resolve, reject) => {
            if (!this.loaderExtension || !this.pubSubHandlerFactory || !this.serviceManager || !this.languageProvider) {
                this.logger.info(this.className, 'openWindreamFilePickerPopup', 'Method called with undefined parameters');
                reject(new Error('Method called with undefined parameters'));
                return;
            }
            const filePickerPopup = new WindreamFilePickerPopup(document, this.languageProvider, this.jQueryLib, this.loaderExtension, this.pubSubHandlerFactory,
                this.serviceManager, this.logger, this.foundationLib, this.uiComponentFactory, this.selectionManager);
            filePickerPopup.render(options).then((data) => resolve(data)).catch((error) => reject(error));
        });
    }

    /**
     * Opens a preview popup for windream files.
     *
     * @param {WindreamPreviewPopupOptions} options The popup options.
     * @returns {Promise<void>} A promise, which will resolve if the popup was closed.
     * @memberof PopupHelper
     */
    public openWindreamPreviewPopup(options: WindreamPreviewPopupOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.loaderExtension || !this.pubSubHandlerFactory || !this.serviceManager || !this.languageProvider) {
                this.logger.info(this.className, 'openWindreamPreviewPopup', 'Method called with undefined parameters');
                reject(new Error('Method called with undefined parameters'));
                return;
            }
            const previewPopup = new WindreamPreviewPopup(document, this.languageProvider, this.jQueryLib,
                this.loaderExtension, this.foundationLib, this.uiComponentFactory, this.pubSubHandlerFactory);
            previewPopup.render(options).then(() => resolve()).catch((error) => reject(error));
        });
    }

    /**
     * Opens a path popup.
     *
     * @param {string} currentPath The current path.
     * @param {IPopupOptions} options The options.
     * @param {((path: string | WindreamIdentity | undefined) => void)} callback The callback.
     * @param {boolean} [preferIdentityAsCallbackValue] Whether the callback should contain the full identity instead of just a path.
     * @memberof PopupHelper
     */
    public openPathPopup(currentPath: string, options: IPopupOptions,
        callback: (path: string | undefined | WindreamIdentity) => void, preferIdentityAsCallbackValue?: boolean): void {
        const methodName = 'openPathPopup';
        if (!this.loaderExtension || !this.pubSubHandlerFactory || !this.serviceManager) {
            this.logger.info(this.className, methodName, 'Method called with undefined parameters');
            callback(undefined);
            return;
        }
        this.translateNLSProperties(options);
        currentPath = !currentPath || currentPath === '' ? '\\' : currentPath;
        // Diabled because we need to declare it here so it can be referenced later
        // eslint-disable-next-line prefer-const
        let modal: any;
        let newPath: string | undefined | WindreamIdentity;
        const modalHtml: HTMLDivElement = document.createElement('div');
        modalHtml.classList.add('reveal', 'medium', 'wd-modal');
        const modalContainer = document.createElement('div');
        modalContainer.classList.add('wd-modal-container');
        const modalContainerHeading = document.createElement('div');
        modalContainerHeading.classList.add('wd-modal-heading-container');

        // If title option is set, add title text to heading
        if (options.title) {
            const modalHeading: HTMLDivElement = document.createElement('div');
            modalHeading.classList.add('wd-modal-heading');
            modalHeading.innerHTML = `<h2>${Utils.Instance.escapeStringValue(options.title)}</h2>`;
            modalContainerHeading.appendChild(modalHeading);
        } else {
            modalContainerHeading.classList.add('no-title');
        }

        // Close button
        const modalCloseButton: HTMLButtonElement = document.createElement('button');
        modalCloseButton.innerHTML = '<span aria-hidden="true">&times;</span>';
        modalCloseButton.setAttribute('title', this.getLanguageProvider().get('framework.generic.close'));
        modalCloseButton.setAttribute('data-close', '');
        modalCloseButton.type = 'button';
        modalCloseButton.classList.add('close-button');
        modalContainerHeading.appendChild(modalCloseButton);
        modalContainer.appendChild(modalContainerHeading);
        const treeViewComponentContainerId = 'wd-popup-path-' + Utils.Instance.getRandomString();
        // Modal content
        const modalContent: HTMLDivElement = document.createElement('div');
        modalContent.classList.add('wd-modal-content');
        modalContainer.appendChild(modalContent);
        // Treeview content
        const treeViewContent: HTMLDivElement = document.createElement('div');
        treeViewContent.id = treeViewComponentContainerId;
        treeViewContent.style.height = 'calc(60vh - 3.2rem)';
        treeViewContent.style.overflowY = 'auto';
        modalContent.appendChild(treeViewContent);
        // Container
        const treeViewInputContainer: HTMLDivElement = document.createElement('div');
        treeViewInputContainer.classList.add('dx-field-item');
        // Label
        const treeViewInputLabel: HTMLDivElement = document.createElement('div');
        treeViewInputLabel.textContent = this.getLanguageProvider().get('framework.generic.popup.labels.path');
        treeViewInputLabel.classList.add('dx-field-item-label', 'dx-field-item-label-location-top');
        // Input
        let isKeyboardInput = false;
        this.treeViewPathInput = this.jQueryLib('<div></div>').dxTextBox({
            onValueChanged: (data) => {
                newPath = data.value;
                isKeyboardInput = true;
            },
            stylingMode: 'filled',
            value: currentPath,
            valueChangeEvent: 'change input'
        }).addClass('dx-field-item-content');
        treeViewInputContainer.appendChild(treeViewInputLabel);
        this.treeViewPathInput.appendTo(treeViewInputContainer);
        modalContent.appendChild(treeViewInputContainer);
        modalHtml.appendChild(modalContainer);
        // Modal footer
        const modalFooter: HTMLDivElement = document.createElement('div');
        modalFooter.classList.add('wd-modal-footer');
        const modalFooterButtons: HTMLDivElement = document.createElement('div');
        modalFooterButtons.classList.add('wd-button-group');
        modalFooter.appendChild(modalFooterButtons);
        // Close button
        modalCloseButton.addEventListener('click', () => {
            callback(newPath);
            modal.close();
            modal.destroy();
            modalHtml.remove();
        });
         // Create folder button
         this.jQueryLib('<div></div>').dxButton({
            onClick: () => {
                this.openCreateFolderPopup(this.identityObject);
            },
            stylingMode: 'text',
            text: this.getLanguageProvider().get('framework.generic.create'),
            hint: this.getLanguageProvider().get('framework.generic.create'),
            elementAttr: {
                class:'left-aligned'
            }
        }).appendTo(modalFooterButtons);
        // Ok button
        this.jQueryLib('<div></div>').dxButton({
            onClick: () => {
                let hadErrors = false;
                let pathNeedsCheck = false;
                if (newPath) {
                    if (isKeyboardInput && typeof newPath === 'string') {
                        const folderToCheck = new WindreamIdentity();
                        folderToCheck.entity = WindreamEntity.Folder;
                        folderToCheck.setLocationComplete(newPath);
                        if (this.serviceManager) {
                            pathNeedsCheck = true;
                            this.serviceManager.getServices().Directories.getDetails(new GetDetailsRequestOptions(folderToCheck))
                                .then(() => {
                                    // eslint-disable-next-line promise/no-callback-in-promise
                                    callback(newPath);
                                    modal.close();
                                    modal.destroy();
                                    modalHtml.remove();
                                }).catch((error) => {
                                    this.notificationHelper.error({
                                        body: this.getLanguageProvider().get('webservice.copy.folderNotAvailable'),
                                        title: this.getLanguageProvider().get('framework.generic.error')
                                    });
                                    this.logger.warn(this.className, methodName, 'The path is not valid', error);
                                    hadErrors = true;
                                });
                        } else {
                            this.logger.warn(this.className, methodName, 'The serviceManager is not set');
                            hadErrors = true;
                            callback(undefined);
                        }
                    } else {
                        callback(newPath);
                    }
                } else {
                    callback(currentPath);
                }
                if (!hadErrors && !pathNeedsCheck) {
                    modal.close();
                    modal.destroy();
                    modalHtml.remove();
                }
                return false;
            },
            stylingMode: 'text',
            text: this.getLanguageProvider().get('framework.generic.ok')
        }).appendTo(modalFooterButtons);

        // Cancel button
        this.jQueryLib('<div></div>').dxButton({
            onClick: () => {
                modal.close();
                modal.destroy();
                modalHtml.remove();
                callback(undefined);
            },
            stylingMode: 'text',
            text: this.getLanguageProvider().get('framework.generic.cancel')
        }).appendTo(modalFooterButtons);
        modalHtml.appendChild(modalFooter);
        modal = new this.foundationLib.Reveal(this.jQueryLib(modalHtml), {
            animationIn: 'scale-in-up',
            animationOut: 'scale-out-down',
            closeOnClick: typeof options.closeOnClick !== 'undefined' ? options.closeOnClick : true,
            closeOnEsc: options.closeOnEsc ? options.closeOnEsc : false // Filter out undefined
        });
        this.popstateEventHandler(undefined, modal, false);
        this.pubSub=this.pubSubHandlerFactory.create();
        const positionQuery = '#' + treeViewComponentContainerId;
        this.loaderExtension.loadComponent({
            component: 'com.windream.treeview',
            configuration: {
                DragDropEnabled: false
            },
            guid: 'WINDREAM_PATH_POPUP_TREE',
            isTitleVisible: false,
            name: { en: 'com.windream.treeview' },
            position: positionQuery,
            style: Style.default()
        }, this.pubSub).then(() => {
            const element = document.querySelector(positionQuery);
            if (element) {
                element.classList.add('windream-path-popup-tree');
            }
        }).catch((error) => {
            this.logger.warn(this.className, methodName, 'Can not load treeview', error);
        });
        // Set up PubSub configuration
        this.pubSub.loadPubSub([{
            in: [{
                componentGuid: 'WINDREAM_PATH_POPUP_TREE',
                parameter: 'UpdateFolderPath'
            }],
            out: [{
                componentGuid: 'WINDREAM_PATH_POPUP_TREE',
                parameter: 'FolderClicked'
            }]
        }, {
            in: [{
                componentGuid: 'WINDREAM_PATH_POPUP_TREE',
                parameter: 'SelectedFolderIdentity'
            }],
            out: [{
                componentGuid: 'WINDREAM_PATH_POPUP_TREE',
                parameter: 'UpdateInitialFolderPath'
            }]
        }, {
            in: [],
            out: [{
                componentGuid: 'WINDREAM_PATH_POPUP_TREE',
                parameter: 'RefreshTreeView'
            }]
        }
    ]);
        this.pubSub.subscribe('WINDREAM_PATH_POPUP_TREE', 'UpdateFolderPath', (folderIdentityPromise: Promise<WindreamIdentity[]>) => {
            folderIdentityPromise.then((folderIdentity) => {
                if (preferIdentityAsCallbackValue) {
                    this.jQueryLib(this.treeViewPathInput).dxTextBox({
                        value: folderIdentity[0].getLocationComplete()
                    });
                    newPath = folderIdentity[0];
                    isKeyboardInput = false;
                } else {
                    newPath = folderIdentity[0].getLocationComplete();
                    this.jQueryLib(this.treeViewPathInput).dxTextBox({
                        value: newPath
                    });
                    isKeyboardInput = false;
                }
                this.identityObject = folderIdentity[0];
            }).catch((error) => {
                this.logger.error(this.className, methodName, 'UpdateFolderPath pubsub catched error', error);
            });
        });
        this.preSelectedFolder.entity = WindreamEntity.Folder;
        this.preSelectedFolder.setLocationComplete(currentPath);
        this.serviceManager.getServices().Directories.getDetails(new GetDetailsRequestOptions(this.preSelectedFolder))
            .then((data) => {
                this.pubSub?.publish('WINDREAM_PATH_POPUP_TREE', 'UpdateInitialFolderPath', [{
                    id: data.id,
                    location: data.location,
                    name: data.name,
                    entity: WindreamEntity.Folder
                }]);
            }).catch((error) => {
                this.logger.warn(this.className, methodName, 'Can not preselect path', error);
            });
        // Destroy modal on close
        modal.$element.on('closed.zf.reveal', () => {
            if (typeof options.onClose === 'function') {
                options.onClose();
            }
            if (options.destroyOnClose) {
                modal.destroy();
                modalHtml.remove();
            }
            callback(undefined);
        });
        modal.open();
    }
    /**
     * Opens an index dialog popup for each Identity.
     *
     * @param {WindreamIdentity[]} windreamIdentities The windream documents.
     * @returns {Promise<void>} Promise to resolve when all identities have been indexed.
     * @memberof PopupHelper
     */
    public openIndexPopups(windreamIdentities: WindreamIdentity[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!windreamIdentities || windreamIdentities.length === 0) {
                resolve();
                return;
            }
            if (!this.loaderExtension) {
                this.logger.error(this.className, 'openIndexPopups', 'this.loaderExtension not yet set.');
                reject(new Error('No LoaderExtension set'));
                return;
            }
            if (!this.pubSubHandlerFactory) {
                this.logger.error(this.className, 'openIndexPopups', 'this.pubSubHandlerFactory not yet set.');
                reject(new Error('No PubSubHandlerFactory set'));
                return;
            }
            const randomId = Utils.Instance.getRandomString();
            const popupOptions: IPopupOptions = {
                body: `<div class="wd-index-dialog-modal" id="wd-index-dialog-modal-${randomId}"></div>`,
                closeOnClick: false,
                immediatelyOpen: false,
                size: 'large',
                title: this.getLanguageProvider().get('framework.generic.loading')
            };
            const modal = this.openPopup(popupOptions);
            this.popstateEventHandler(undefined, modal, false);
            modal.onClosed = () => {
                modal.destroy();
            };
            const pubsubGuid = 'WINDREAM_INDEX_' + randomId;
            const pubSub = this.pubSubHandlerFactory.create();

            this.loaderExtension.loadComponent({
                component: 'com.windream.index',
                configuration: {
                    DisplayMode: Utils.Instance.deviceDetection.isApp() ? '3' : '1',
                    MultiIndexingSupport: true
                },
                guid: pubsubGuid,
                isTitleVisible: false,
                name: { en: 'com.windream.index' },
                position: '#wd-index-dialog-modal-' + randomId,
                style: null
            }, pubSub).then(() => {
                pubSub.loadPubSub([{
                    in: [{
                        componentGuid: pubsubGuid,
                        parameter: 'CurrentWindreamIdentity'
                    }],
                    out: [{
                        componentGuid: pubsubGuid,
                        parameter: 'CurrentWindreamIdentity'
                    }]
                },
                {
                    in: [{
                        componentGuid: pubsubGuid,
                        parameter: 'ClosePopup'
                    }],
                    out: [{
                        componentGuid: pubsubGuid,
                        parameter: 'ClosePopup'
                    }]
                },
                {
                    in: [{
                        componentGuid: pubsubGuid,
                        parameter: 'DisplayedWindreamIdentity'
                    }],
                    out: [{
                        componentGuid: pubsubGuid,
                        parameter: 'DisplayedWindreamIdentity'
                    }]
                }]);
                pubSub.subscribe(pubsubGuid, 'ClosePopup', () => {
                    modal.close();
                });
                pubSub.subscribe(pubsubGuid, 'DisplayedWindreamIdentity', (identityPromise: Promise<WindreamIdentity>) => {
                    identityPromise.then((identity) => {
                        modal.setTitle(identity.name ?? 'Index dialog');
                    }).catch((err: Error) =>
                        this.logger.error(this.className, 'openIndexPopups', 'DisplayedWindreamIdentity pubsub encountered an error. ' + err?.message)
                    );
                });

                pubSub.publish(pubsubGuid, 'CurrentWindreamIdentity', windreamIdentities);

                // Reset the max height so that the flex box inside the modal dialog can work
                const body = modal.getElementById(`wd-index-dialog-modal-${randomId}`);
                const dialog = modal.getRootElement();
                if (dialog) {
                    dialog.classList.add('tall');
                }
                if (body) {
                    // Having the height set in the app causes issues with the popup and the mobile keyboard
                    // TODO: Check if this is only relevant for the app or for mobile devices in general
                    if (!Utils.Instance.deviceDetection.isApp()) {
                        body.style.height = '66vh';
                    }
                    const container = <HTMLElement>body.querySelector('div');
                    if (container) {
                        container.style.height = '100%';
                        const componentDiv = <HTMLElement>container.querySelector('div');
                        if (componentDiv) {
                            componentDiv.style.height = '100%';
                        }
                    }
                }

                modal.open();

            }).catch((err: Error) => {
                this.logger.error(this.className, 'openIndexPopups', 'this.loadComponent was not success.');
                reject(err);
                return;
            });
        });
    }

    /**
     * Opens a popup that prompts to save the current configuration.
     *
     * @param {() => void} okButtonCallback Callback for the OK button.
     * @param {() => void} cancelButtonCallback Callback for the cancel button.
     * @param {() => void} discardButtonCallback Callback for the discard button.
     *
     * @memberof PopupHelper
     */
    public openSavePopup(okButtonCallback: () => void, cancelButtonCallback: () => void, discardButtonCallback: () => void) {
        window.removeEventListener('popstate', this.closePopup.bind(this));
        const options: any = {};
        options.body = this.getLanguageProvider().get('framework.popuphelper.openSavePopup.body');
        options.title = this.getLanguageProvider().get('framework.popuphelper.openSavePopup.title');
        options.buttons = new Array<IPopupButtonOptions>();

        const okButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.yes')
        };
        okButton.callback = okButtonCallback;
        options.buttons.push(okButton);

        const discardButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.no')
        };
        discardButton.callback = discardButtonCallback;
        options.buttons.push(discardButton);

        const cancelButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.cancel')
        };
        cancelButton.callback = cancelButtonCallback;
        options.buttons.push(cancelButton);

        options.onClose = cancelButtonCallback;
        options.destroyOnClose = true;
        options.destroyOnButtonClick = true;

        return this.openPopup(options);
    }

    /**
     * Event handler for the closure of popus when going forward/backwards in the browser.
     *
     * @param {PopStateEvent} _event Popstate event.
     * @param {IPopup} modal The modal in case the state is handled from outside.
     * @param {boolean} closeAll Indicates if all open popus shall be closed - default is false, than only the last opened one is closed.
     *
     * @memberof PopupHelper
     */
    public popstateEventHandler = (_event?: PopStateEvent, modal?: IPopup, closeAll: boolean = true): void => {
        // Close the last opened popup
        let currentPopup = this.popupStack.pop();
        if (currentPopup && currentPopup.isOpen) {
            this.closePopup(currentPopup.$element);

            if (closeAll) {
                // Close all other open popups too
                currentPopup = this.popupStack.pop();
                while(currentPopup && currentPopup.isOpen) {
                    this.closePopup(currentPopup.$element);
                    currentPopup = this.popupStack.pop();
                }
            }
        } else if (modal) {
            window.addEventListener('popstate', (event) => {
                if (event) {
                    this.closePopup(modal.$element);
                }
            });
        }
    };


    /**
     * Displays a popup with 'ok' and 'cancel' buttons to ask for the user's confirmation.
     *
     * @static
     * @param {() => void} okButtonCallback         Callback to execute on 'ok' button click.
     * @param {() => void} cancelButtonCallback     Callback to execute on 'cancel' button click.
     * @param {IPopupOptions} [options]             Options to be passed to `openOkCancelPopup()`.
     *
     * @memberof PopupHelper
     */
    public openOkCancelPopup(okButtonCallback: () => void, cancelButtonCallback: () => void, options?: IPopupOptions) {
        if (options) {
            this.translateNLSProperties(options);
        }
        options = options || <IPopupOptions>{};

        options.title = options.title;
        options.body = options.body;
        options.buttons = new Array<IPopupButtonOptions>();

        const okButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.ok')
        };
        okButton.callback = okButtonCallback;
        options.buttons.push(okButton);

        const cancelButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.cancel')
        };
        cancelButton.callback = cancelButtonCallback;
        options.buttons.push(cancelButton);

        options.onClose = options.onClose || cancelButtonCallback;
        options.destroyOnClose = options.destroyOnClose || true;
        options.destroyOnButtonClick = options.destroyOnButtonClick || true;

        return this.openPopup(options);
    }


    /**
     * Displays a popup with 'yes' and 'no' buttons to ask for the user's confirmation.
     *
     * @static
     * @param {() => void} yesButtonCallback    Callback to execute on 'yes' button click.
     * @param {() => void} noButtonCallback     Callback to execute on 'no' button click.
     * @param {IPopupOptions} [options]         Options to be passed to `openPopup()`.
     *
     * @memberof PopupHelper
     */
    public openConfirmationPopup(yesButtonCallback: () => void, noButtonCallback: () => void, options?: IPopupOptions): any {
        if (options) {
            this.translateNLSProperties(options);
        }
        options = options || <IPopupOptions>{};

        options.body = options.body || this.getLanguageProvider().get('framework.popuphelper.openConfirmationPopup.title');
        options.title = options.title || this.getLanguageProvider().get('framework.popuphelper.openConfirmationPopup.title');
        options.buttons = new Array<IPopupButtonOptions>();

        const yesButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.yes')
        };
        yesButton.callback = yesButtonCallback;
        options.buttons.push(yesButton);

        const noButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.no')
        };
        noButton.callback = noButtonCallback;
        options.buttons.push(noButton);

        options.onClose = options.onClose || noButtonCallback;
        options.destroyOnClose = options.destroyOnClose || true;
        options.destroyOnButtonClick = options.destroyOnButtonClick || true;

        return this.openPopup(options);
    }


    /**
     * Displays a popup with an input field, 'ok' and 'cancel' buttons to ask for the user's input.
     *
     * @static
     * @param {(text: string) => void} okButtonCallback         Callback to execute on 'ok' button click.
     * @param {() => void} cancelButtonCallback     Callback to execute on 'cancel' button click.
     * @param {string} [defaultValue='']    Default value of the input box.
     * @param {IInputPopupOptions} [options]              Options to be passed to `openPopup()`.
     *
     * @memberof PopupHelper
     */
    public openInputBoxPopup(okButtonCallback: (text: string) => void, cancelButtonCallback: () => void, defaultValue: string = '', options?: IInputPopupOptions): any {
        if (options) {
            this.translateNLSProperties(options);
        }
        options = options || <IInputPopupOptions>{};
        options.wrapInForm = true;
        options.title = options.title;
        options.body = options.body;
        const inputBoxPopupContent = document.createElement('div');
        const textBox = new TextBox(inputBoxPopupContent);
        textBox.bootstrap();
        options.closeOnClick = typeof options.closeOnClick !== 'undefined' ? options.closeOnClick : true;
        if (options.placeholder) {
            textBox.setOptions({
                placeholderText: options.placeholder
            });
        }
        textBox.setValue(defaultValue);
        if (Utils.Instance.isHTMLElement(options.body)) {
            this.jQueryLib(options.body).append(inputBoxPopupContent);
        } else if (typeof (options.body) === 'function') {
            this.jQueryLib(options.body(this.jQueryLib)).appendTo(inputBoxPopupContent);
        } else {
            const newBody = document.createElement('div');
            newBody.innerHTML = options.body;
            this.jQueryLib(newBody).append(inputBoxPopupContent);
            options.body = newBody;
        }
        options.buttons = new Array<IPopupButtonOptions>();
        let currentInputValue = defaultValue;
        textBox.onValueChanged = (value) => {
            currentInputValue = value;
        };
        const okButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.ok')
        };
        okButton.callback = () => {
            okButtonCallback(currentInputValue);
        };
        options.buttons.push(okButton);

        const cancelButton: IPopupButtonOptions = {
            label: this.getLanguageProvider().get('framework.generic.cancel')
        };
        cancelButton.callback = cancelButtonCallback;
        options.buttons.push(cancelButton);

        options.onClose = options.onClose || cancelButtonCallback;
        options.destroyOnClose = options.destroyOnClose || true;
        options.destroyOnButtonClick = options.destroyOnButtonClick || true;
        return this.openPopup(options);
    }

    /**
     * Closes the given popup.
     * Parameter can be either native DOM element, a jQuery element or a query selector.
     *
     * @static
     * @param {*} popup Popup to close (native DOM element, jQuery element o query selector).
     *
     * @memberof PopupHelper
     */
    public closePopup(popup: any): void {
        let jQueryPopup: any;
        // Abort closing since the popup was already closed i.e. manually via foundation.
        if (!popup) {
            return;
        }
        if (popup instanceof Element) { // DOM Element
            // @ts-ignore - Ignore because of Foundation usage
            jQueryPopup = window['$'](popup);
            // @ts-ignore - Ignore because of Foundation usage
        } else if (popup instanceof window['$']) { // JQuery element
            jQueryPopup = popup;
        } else if (typeof popup === 'string') { // String, i.e. query selector
            // @ts-ignore - Ignore because of Foundation usage
            jQueryPopup = window['$'](popup);
        } else {
            throw new Error('Cannot handle parameter `popup`.');
        }
        window.removeEventListener('popstate', this.closePopup.bind(this));
        // @ts-ignore - Ignore because of Foundation usage
        if (window['$'](jQueryPopup) && window['$'](jQueryPopup)['data'] && window['$'](jQueryPopup)['data']('zfPlugin') && window['$'](jQueryPopup)['data']('zfPlugin')['close']) {
            // @ts-ignore - Ignore because of Foundation usage
            window['$'](jQueryPopup)['data']('zfPlugin')['close']();
        }
    }

    /**
     * Basic function to open a popup.
     *
     * @param {IPopupOptions} options Options to use with the popup.
     * @returns {IPopup} The popup.
     *
     * @memberof PopupHelper
     */
    public openPopup(options: IPopupOptions): IPopup {
        this.translateNLSProperties(options);
        const popup = new Popup(options, this.getLanguageProvider(), this.jQueryLib, this.foundationLib);

        if (typeof options.immediatelyOpen === 'undefined' || options.immediatelyOpen === true) {
            popup.open();
        }
        this.popupStack.push(popup);
        return popup;
    }

    /**
     * Returns the language provider.
     * Will throw if no is set.
     *
     * @private
     * @returns {ILanguageProvider} Current language provider.
     * @memberof PopupHelper
     */
    private getLanguageProvider(): ILanguageProvider {
        if (this.languageProvider) {
            return this.languageProvider;
        }
        this.logger.error(this.className, 'getLanguageProvider', 'No LanguageProvider set');
        throw new Error('No LanguageProvider set');
    }

    /**
     * Translate all properties which should use NLS.
     *
     * @private
     * @param {IPopupOptions} options The options.
     * @memberof PopupHelper
     */
    private translateNLSProperties(options: IPopupOptions): void {
        if (options.useNLS) {
            if (options.title) {
                options.title = this.languageProvider?.get(options.title);
            }
            if (typeof options.body === 'string' && this.languageProvider) {
                options.body = this.languageProvider.get(options.body);
            }
        }
        if (options.buttons) {
            options.buttons.forEach((button) => {
                if (button.useNLS && this.languageProvider) {
                    button.label = this.languageProvider.get(button.label);
                }
            });
        }
    }

     /**
      * Displays a popup dialog to create a new folder.
      *
      * @public
      * @param {WindreamIdentity} identity Object representing the folder in which the new folder will be created.
      * @memberof PopupHelper
      */
     public openCreateFolderPopup(identity: WindreamIdentity) {
        let dialog: IPopup;
        const languageProvider = DynamicWorkspace.Language.frameworkLanguageProvider;
        let folderName: any = languageProvider.get('webservice.create.label');

        const create =  () => {
            if (!folderName) {
                return;
            }
            this.createFolder(identity, folderName);
        };
        const close =  () => {
            this.dialog.close();
        };
        const buttons = [
            {
                buttonType: 2,
                callback: function () {
                    create();
                },
                classNames: 'okButton',
                label: languageProvider.get('framework.generic.ok'),
                buttonId: 'ok-button',
            },
            {
                buttonType: 1,
                callback: function () {
                    close();
                },
                classNames: '',
                label: languageProvider.get('framework.generic.cancel')
            }
        ];

        let textBoxContainer: JQuery<HTMLElement>; // Higher scope so that it can be accessed in onOpen callback
        this.dialog = DynamicWorkspace.Popup.openPopup(
            {
                body: function (jQuery) {
                    const container = jQuery('<div></div>');
                    textBoxContainer = jQuery('<div></div>');
                    textBoxContainer.appendTo(container);
                    const textBox = DynamicWorkspace.Ui.components.textBox(textBoxContainer[0]);
                    textBox.onValueChanged = function (newValue) {
                        folderName = newValue;
                            // Inside the onValueChanged function
                            if (dialog) {
                                const buttonId = buttons[0].buttonId;
                                if (buttonId) {
                                    const buttonSave: IPopupButton | undefined = dialog.getPopupButtonById(buttonId);
                                    if (buttonSave) {
                                        const value = newValue ?? '';
                                        if (value.trim() === '') {
                                            buttonSave.disable();
                                        } else {
                                            buttonSave.enable();
                                        }
                                    }
                                }
                            }

                        };
                    textBox.onEnterPressed = function () {
                        create();
                    };
                    textBox.bootstrap();
                    textBox.setValue(languageProvider.get('webservice.create.label'));
                    return container[0];
                },
                buttons: buttons,
                closeOnClick: false,
                destroyOnClose: true,
                title: languageProvider.get('webservice.create.label'),
                onOpen: function () {
                    if (!textBoxContainer) {
                        return;
                    }
                    // Find the input element within the textBoxContainer
                    const textBoxElement = textBoxContainer.find('.dx-texteditor-input-container .dx-texteditor-input')[0] as HTMLInputElement | null;
                    const focus = function () {
                        // Select everything but the extension
                        if (textBoxElement) {
                            textBoxElement.focus();
                            textBoxElement.setSelectionRange(0, textBoxElement.value.length); // Select everything
                        }
                    };
                    const focusTextBoxTimeOut = 520;
                    setTimeout(function () { // Wait for the popup to be placed inside the DOM and be visible
                        focus();
                    }, focusTextBoxTimeOut); // Increase the delay

                    // Remove the onblur event handler to allow tabbing
                    if (textBoxElement) {
                        textBoxElement.onblur = null;
                    }
                }
            }
        );
    }

    /**
     * Request to the web server to creates a new folder.
     *
     * @public
     * @param {WindreamIdentity} identity - The identity object representing the folder in which the new folder will be created.
     * @param {string} folderName - The name of the new folder to be created.
     * @memberof PopupHelper
     */
    public async createFolder(identity: WindreamIdentity, folderName: string) {
        if (folderName) {
            DynamicWorkspace.Services.Directories.createDirectory({
                identity: identity,
                folderName: folderName
            })
            .then((directoryDetails) => {
                if (directoryDetails) {
                    this.pubSub?.publish('WINDREAM_PATH_POPUP_TREE', 'RefreshTreeView', directoryDetails);
                    // Expand and select added folder
                    this.pubSub?.publish('WINDREAM_PATH_POPUP_TREE', 'UpdateInitialFolderPath', [{
                        id: directoryDetails.id,
                        location: directoryDetails.location,
                        name: directoryDetails.name,
                        entity: WindreamEntity.Folder
                    }]);
                    // Changing the Input Path Location
                    this.jQueryLib(this.treeViewPathInput).dxTextBox({
                        value: directoryDetails.getLocationComplete(),
                    });
                }
                this.dialog.close();
            })
            .catch(() => {
                this.dialog.close();
            });
        }
    }
}