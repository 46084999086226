import { IExtension, IExtensionProvider } from './interfaces';

/**
 * Provides extensions that can be provided by the framework.
 * 
 * @export
 * @class ExtensionProvider
 * @implements {IExtensionProvider}
 */
export class ExtensionProvider implements IExtensionProvider {

    /**
     * The id of the websocket extension.
     * 
     * @static
     * 
     * @memberof ExtensionProvider
     */
    public static WEBSOCKET = 'context_menu';

    /**
     * The id of the component loader extension.
     * 
     * @static
     * 
     * @memberof ExtensionProvider
     */
    public static COMPONENT_LOADER = 'component_loader';

    /**
     * The id of the user config extension.
     * 
     * @static
     * 
     * @memberof ExtensionProvider
     */
    public static USER_CONFIG = 'user_config';

    /**
     * The id of the index mask extension.
     * 
     * @static
     * 
     * @memberof ExtensionProvider
     */
    public static INDEX_MASK = 'index_mask';

    /**
     * The id of the template extension.
     * 
     * @static
     * 
     * @memberof ExtensionProvider
     */
    public static TEMPLATE = 'template';


    /**
     * The id of the storage extension. 
     * 
     * @static
     * @memberof ExtensionProvider
     */
    public static STORAGE = 'storage';

    private extensions: Map<string, any>;

    /**
     * Creates an instance of ExtensionProvider.
     * 
     * @memberof ExtensionProvider
     */
    public constructor() {
        this.extensions = new Map<string, any>();
    }

  /**
   * Gets the provider with the given name.
   * 
   * @param {string} providerName Name of the provider to provide.
   * @returns {*}
   * 
   * @memberof ExtensionProvider
   */
  public getExtension(extensionName: string): IExtension {
    if (this.extensions.has(extensionName)) {
      return this.extensions.get(extensionName);
    } else {
      throw new ReferenceError(`Extension ${extensionName} was not found.`);
    }
  }

  /**
   * Sets a provider for the given name.
   * 
   * @param {string} providerName Name of the provider.
   * @param {*} provider          The provider to add.
   * 
   * @memberof ExtensionProvider
   */
  public setExtension(extension: IExtension): void {
    this.extensions.set(extension.name, extension);
  }
}