import { WindreamEntity, WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from '../models';
import { Sorting } from './sorting';

/**
 * Represents fetch node request options.
 *
 * @export
 * @class FetchNodesRequestOptions
 * @extends {BaseRequestOptions}
 */
export class FetchNodesRequestOptions extends WindreamRequestOptions {

    /**
     * The sorting parameter.
     *
     * @type {FetchNodesDTO}
     * @memberof FetchNodesRequestOptions
     */
    public sorting?: Sorting;

    /**
     * Entity to filter by.
     *
     * @type {WindreamEntity}
     * @memberof FetchNodesRequestOptions
     */
    public entity?: WindreamEntity;

    /**
     * Values to fetch.
     *
     * @type {string[]}
     * @memberof FetchNodesRequestOptions
     */
    public values?: string[];

    /**
     * Creates an instance of FetchNodesRequestOptions.
     *
     * @param {WindreamIdentity} identity
     * @param {string[]} [values]
     * @param {Sorting} [sorting]
     * @param {WindreamEntity} [entity]
     * @memberof FetchNodesRequestOptions
     */
    public constructor(identity: WindreamIdentity, values?: string[], sorting?: Sorting, entity?: WindreamEntity) {
        super(identity);
        this.values = values;
        this.sorting = sorting;
        this.entity = entity;
    }
}