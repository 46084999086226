import { ItemResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { WindreamIdentityDetails } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { ResultAmountRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to perform a GetLastModifiedDocuments call on the windream webservice.
 * 
 * @export
 * @class GetLastModifiedDocuments
 * @extends {ServiceAction}
 */
export class GetLastModifiedDocuments extends ServiceAction {

  /**
   * Creates an instance of GetLastModifiedDocuments.
   * @param {IRequestExecutor} requestExecutor 
   * @param {GlobalConfig} globalConfig 
   * @param {Logger} logger 
   * @memberof GetLastModifiedDocuments
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'getLastModifiedDocuments';
  }

  /**
   * Last modified documents for the current user.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {ResultAmountRequestOptions} resultAmountRequestOptions The request options.
   * @returns {Promise<WindreamIdentityDetails[]>} A promise, which will resolve with the server response.
   * @memberof GetLastModifiedDocuments
   */
  public async do(resultAmountRequestOptions: ResultAmountRequestOptions): Promise<WindreamIdentityDetails[]> {
    return new Promise<WindreamIdentityDetails[]>((resolve, reject) => {
      const url = `${this.globalConfig.windreamWebServiceURL}/journal/GetLastModifiedDocuments`;
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', url, {
        NumberOfResults: resultAmountRequestOptions.maxAmountOfResults
      }), resultAmountRequestOptions.requestOptions).then((response: IServiceResponse<ItemResponseContainerDTO[]>) => {
        if (response.data && !response.data.find((document) => document.HasErrors)) {
          resolve(response.data.map((item) => WindreamIdentityDetails.fromDto(item.Item)));
        } else {
          this.logger.error('GetLastModifiedDocuments', 'do', 'Failed to get last modified documents', response);
          reject(new Error('Failed to get last modified documents'));
        }
      }).catch((err: Error) => {
        this.logger.error('GetLastModifiedDocuments', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}