import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * The UndoCheckOut action is used to undo a CheckOut within windream
 * 
 * @export
 * @class UndoCheckOut
 * @extends {ServiceAction}
 */
export class UndoCheckOut extends ServiceAction {
  /**
   * Name of the service action.
   * 
   * 
   * @memberof UndoCheckOut
   */
  public name = 'undoCheckOut';


  /**
   * Creates an instance of UndoCheckOut.
   * @param {IRequestExecutor} requestExecutor 
   * @param {GlobalConfig} globalConfig 
   * @param {Logger} logger 
   * @memberof UndoCheckOut
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'undoCheckOut';
  }

  /**
   * Undo a preceding check-out.
   * Resolvesk with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {WindreamRequestOptions} windreamRequestOptions The request options.
   * @returns {Promise<void>} A promise, which will resolve with the server response.
   * @memberof UndoCheckOut
   */
  public async do(windreamRequestOptions: WindreamRequestOptions): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/documents/UndoCheckOut', {
        Item: IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity)
      }), windreamRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
        if (response.data?.HasErrors) {
          this.logger.error('UndoCheckOut', 'do', 'Failed to undo check out', response);
          reject(new Error('Failed to undo check out'));
        } else {
          resolve();
        }
      }).catch((err: Error) => {
        this.logger.error('UndoCheckOut', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}