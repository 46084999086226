import { WindreamIdentity } from '../../common';
import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents copy request options.
 *
 * @export
 * @class CopyRequestOptions
 * @extends {BaseRequestOptions}
 */
export class CopyRequestOptions extends BaseRequestOptions {

    /**
     * The identity to copy.
     *
     * @type {WindreamIdentity}
     * @memberof CopyRequestOptions
     */
    public source: WindreamIdentity;

    /**
     * The copy target.
     *
     * @type {WindreamIdentity}
     * @memberof CopyRequestOptions
     */
    public target: WindreamIdentity;

    /**
     * Creates an instance of CopyRequestOptions.
     * @param {WindreamIdentity} source
     * @param {WindreamIdentity} target
     * @memberof CopyRequestOptions
     */
    public constructor(source: WindreamIdentity, target: WindreamIdentity) {
        super();
        if (!source) {
            throw new Error('Argument \'source\' is not valid');
        }
        if (!target) {
            throw new Error('Argument \'target\' is not valid');
        }
        this.source = source;
        this.target = target;
    }
}