import { Component} from 'typings/core';
import { Logger } from '../logging';
import { IContextMenuDataWebServiceHandler } from './interfaces';
import { ContextMenuData, ContextMenuEvaluationModel } from './models';
import { ContextMenu, ContextMenuItem, ContextMenuItemValidator } from '.';

/**
 * Creates context menus based on a windream menu.
 * 
 * @export
 * @abstract
 * @class ContextMenuCreator
 * @template T Type of the data used.
 */
export abstract class ContextMenuCreator<T> {
    protected webServiceHandler: IContextMenuDataWebServiceHandler;
    protected logger: Logger;
    private contextMenuItemValidator: ContextMenuItemValidator;

    /**
     * Creates an instance of ContextMenuCreator.
     * @param {IContextMenuDataWebServiceHandler} webServiceHandler 
     * @param {ContextMenuItemValidator} contextMenuItemValidator 
     * @param {Logger} logger 
     * @memberof ContextMenuCreator
     */
    public constructor(webServiceHandler: IContextMenuDataWebServiceHandler, contextMenuItemValidator: ContextMenuItemValidator, logger: Logger) {
        this.webServiceHandler = webServiceHandler;
        this.logger = logger;
        this.contextMenuItemValidator = contextMenuItemValidator;
    }

    /**
     * Returns the context menu instance with the given structure for the given data.
     * The returned menu is evaluated, meaning only valid entries are active.
     * 
     * @param {T[]} data Data to display context menu for.
     * @param {string} contextMenuId ID of the menu to get.
     * @param {Component} contextMenuSender Refers to the component that ContextMenu belongs to.
     * @returns {Promise<ContextMenu>} The evaluated context menu instance.
     * @memberof ContextMenuCreator
     */
    public async getContextMenu(data: T[], contextMenuId: string, contextMenuSender?: Component): Promise<ContextMenu> {
        return new Promise<ContextMenu>((resolve, reject) => {
            Promise.all([
                this.webServiceHandler.getContextMenuDataModel<T>(contextMenuId),
                this.getEvaluationModel(data)
            ]).then((values) => {
                const contextMenuData = values[0];
                if (!contextMenuData) {
                    reject(new Error('Unable to get context menu'));
                    return;
                }
                const evaluationModel = values[1];
                if (evaluationModel) {
                    const contextMenu = this.convertContextMenuDataToContextMenu(data, contextMenuData, evaluationModel, contextMenuSender);

                    resolve(contextMenu);
                } else {
                    // If no evaluation model is provided then no context menu should be created - return a new, empty contextmenu
                    // This case occurs for example for new DW internal entity "Favorites" (entity = -1)
                    resolve(new ContextMenu());
                }
            }).catch((err: Error) => {
                this.logger.error('ContextMenuCreator', 'getContextMenu', 'Unable to get context menu', err);
            });
        });
    }
    /**
     * Returns the evaluation model for the given data.
     * 
     * @protected
     * @param {T[]} data Data to get evaluation model for.
     * @returns {Promise<ContextMenuEvaluationModel | null>} The evaluation model or null in case no context menu can be evaluated for the given data.
     * @memberof ContextMenuCreator
     */
    protected abstract getEvaluationModel(data: T[]): Promise<ContextMenuEvaluationModel | null>;

    /**
     * Converts the context menu structure to a context menu instance.
     * Will evaluate the items and disable invalid items.
     * 
     * @private
     * @param {T[]} data Data associated with the context menu.
     * @param {ContextMenuData<T>[]} contextMenuData The context menu structure to use.
     * @param {ContextMenuEvaluationModel} evaluationModel The evaluation model to use.
     * @param {Component} contextMenuSender Refers to the component that ContextMenu belongs to.
     * @returns {ContextMenu} The context menu instance.
     * @memberof ContextMenuCreator
     */
    private convertContextMenuDataToContextMenu(data: T[], contextMenuData: ContextMenuData<T>[], evaluationModel: ContextMenuEvaluationModel,
        contextMenuSender?: Component): ContextMenu {
        const contextMenu = new ContextMenu();
        contextMenuData.forEach((menu) => {
            const contextMenuItem = this.createContextMenuItemFromContextMenuData(menu, data, evaluationModel, contextMenuSender);
            contextMenu.addItem(contextMenuItem);
        });
        return contextMenu;
    }

    /**
     * Converts the context menu structure to a context menu item instance.
     * Will evaluate the items and disable invalid items.
     * 
     * @private
     * @param {ContextMenuData<T>} contextMenuData The context menu structure to use.
     * @param {T[]} data Data associated with the context menu.
     * @param {ContextMenuEvaluationModel} evaluationModel The evaluation model to use.
     * @param {Component} contextMenuSender Refers to the component that ContextMenu belongs to.
     * @returns {ContextMenuItem} The context menu item instance.
     * @memberof ContextMenuCreator
     */
    private createContextMenuItemFromContextMenuData(contextMenuData: ContextMenuData<T>, data: T[], evaluationModel: ContextMenuEvaluationModel
        , contextMenuSender?: Component): ContextMenuItem {
        const contextMenuItem = new ContextMenuItem();
        if (contextMenuData.name && new RegExp('^[-]+$').test(contextMenuData.name)) { // Seperator
            // Since the data base does not allow multiple entries with the same name, the first divider has one dash, the second one has two, and so on.
            // Therefore we test for dividers using the regex, which checks if the name only consists of dashes.
            contextMenuItem.cssClass = 'divider';
        } else {
            contextMenuItem.text = contextMenuData.name;
        }
        contextMenuItem.click = () => {
            if (contextMenuData.onClick) {
                contextMenuData.onClick(data, contextMenuSender);
            }
        };
        contextMenuItem.isDisabled = !this.contextMenuItemValidator.isEntryEnabled<T>(evaluationModel, contextMenuData);

        contextMenuData.childs.forEach((childMenu) => {
            const childMenuItem = this.createContextMenuItemFromContextMenuData(childMenu, data, evaluationModel, contextMenuSender);
            contextMenuItem.addItem(childMenuItem);
        });
        return contextMenuItem;
    }
}