import { ModuleRegistrationType } from '../../../typings';
import { ToolbarAction } from '../../../typings/core';
import { IConfigLoader, ToolbarActionMetadata, ToolbarConfig } from '../config';
import { Logger } from '../dynamicWorkspace';
import { ILanguageManager, ILanguageProvider } from '../language';
import { ModuleRegistrationHelper } from '../loader';
import { IUiComponentFactory } from '../ui/components';
import { ToolbarErrorAction } from './errorAction';

/**
 * This loader will handle the correct loading of the toolbar action files.
 *
 * @export
 * @class ToolbarActionLoader
 */
export class ToolbarActionLoader {

    private loadedMetadata: Map<string, ToolbarActionMetadata>;
    private loadedLanguageProviders: Map<string, ILanguageProvider>;
    private loadedClassReferences: Map<string, ToolbarAction>;
    private languageManager: ILanguageManager;
    private configLoader: IConfigLoader;
    private logger: Logger;
    private currentDocument: Document;
    private readonly toolbarActionsBasePath = './toolbar/actions/';
    private readonly className = 'ToolbarActionLoader';
    private rootNode: HTMLElement;
    private moduleRegistrationHelper: ModuleRegistrationHelper;
    private toolbarConfig: ToolbarConfig;
    private uiComponentFactory: IUiComponentFactory;

    /**
     * Creates an instance of ToolbarActionLoader.
     * 
     * @param {IConfigLoader} configLoader The config loader.
     * @param {ILanguageManager} languageManager The language manager.
     * @param {Logger} logger The logger.
     * @param {Document} currentDocument The current document.
     * @param {HTMLElement} rootNode The root node.
     * @param {ModuleRegistrationHelper} moduleRegistrationHelper The module registration helper.
     * @param {ToolbarConfig} toolbarConfig The toolbar config.
     * @param {IUiComponentFactory} uiComponentFactory The ui component factory.
     * @memberof ToolbarActionLoader
     */
    public constructor(configLoader: IConfigLoader, languageManager: ILanguageManager,
        logger: Logger, currentDocument: Document, rootNode: HTMLElement,
        moduleRegistrationHelper: ModuleRegistrationHelper, toolbarConfig: ToolbarConfig, uiComponentFactory: IUiComponentFactory) {
        this.loadedMetadata = new Map<string, ToolbarActionMetadata>();
        this.loadedLanguageProviders = new Map<string, ILanguageProvider>();
        this.loadedClassReferences = new Map<string, ToolbarAction>();
        this.languageManager = languageManager;
        this.logger = logger;
        this.configLoader = configLoader;
        this.currentDocument = currentDocument;
        this.rootNode = rootNode;
        this.moduleRegistrationHelper = moduleRegistrationHelper;
        this.toolbarConfig = toolbarConfig;
        this.uiComponentFactory = uiComponentFactory;
    }

    /**
     * Gets the toolbar config.
     *
     * @returns {ToolbarConfig} The toolbar config.
     * @memberof ToolbarActionLoader
     */
    public getToolbarConfig(): ToolbarConfig {
        return this.toolbarConfig;
    }

    /**
     * Loads the specified toolbars.
     *
     * @param {string[]} toolbarActionsToLoad The toolbars to load.
     * @returns {Promise<void>} A promise, which will resolve after loading is done.
     * @memberof ToolbarActionLoader
     */
    public async load(toolbarActionsToLoad: string[]): Promise<void> {
        if (toolbarActionsToLoad.length === 0) {
            return Promise.resolve();
        }
        return new Promise<void>((resolve) => {
            const loadingPromises = new Array<Promise<any>>();
            for (const toolbarActionId of toolbarActionsToLoad) {
                const loadToolbarActionPromise = new Promise<void>((resolve, reject) => {
                    this.loadMetadata(toolbarActionId).then((metadata) => {
                        this.loadClassReference(metadata).then(() => resolve()).catch((error) => reject(error));
                    }).catch((error) => reject(error));
                });
                loadingPromises.push(loadToolbarActionPromise);
                loadingPromises.push(this.loadLanguageFile(toolbarActionId));
            };

            Promise.all(loadingPromises).then(() => resolve()).catch((error) => {
                this.logger.error(this.className, 'load', 'Failed to load toolbar', error);
                // Don't reject since then the whole loading would be bricked.
                resolve();
            });
        });
    }

    /**
     * Gets whether all toolbars are loaded.
     *
     * @returns {boolean} Whether the toolbars are loaded.
     * @memberof ToolbarActionLoader
     */
    public areAllToolbarsLoaded(): boolean {
        return this.toolbarConfig.actions && this.toolbarConfig.actions.length === this.loadedClassReferences.size;
    }

    /**
     * Gets the metadata for a toolbar action.
     *
     * @param {string} toolbarActionId The id of the toolbar action.
     * @returns {ToolbarActionMetadata | undefined} The metadata or undefined.
     * @memberof ToolbarActionLoader
     */
    public getMetadata(toolbarActionId: string): ToolbarActionMetadata | undefined {
        return this.loadedMetadata.get(toolbarActionId);
    }

    /**
     * Gets the class reference for a toolbar action.
     *
     * @param {string} toolbarActionId The id of the toolbar action.
     * @returns {ToolbarAction} The class reference.
     * @memberof ToolbarActionLoader
     */
    public getClassReference(toolbarActionId: string): ToolbarAction {
        const previousClassReference = this.loadedClassReferences.get(toolbarActionId);
        if (previousClassReference) {
            return previousClassReference;
        }
        return this.getErrorComponent();
    }

    /**
     * Gets the language provider for a toolbar action.
     *
     * @param {string} toolbarActionId The id of the toolbar action.
     * @returns {ILanguageProvider | undefined} The language provider or undefined.
     * @memberof ToolbarActionLoader
     */
    public getLanguageProvider(toolbarActionId: string): ILanguageProvider | undefined {
        return this.loadedLanguageProviders.get(toolbarActionId);
    }

    /**
     * Gets a new instance of the error component.
     *
     * @returns {ToolbarErrorAction} The component.
     * @memberof ToolbarActionLoader
     */
    public getErrorComponent(): ToolbarErrorAction {
        return new ToolbarErrorAction(this.languageManager.getLanguageProvider('framework'), this.uiComponentFactory);
    }

    /**
     * Loads the language file for a toolbar action.
     *
     * @private
     * @param {string} toolbarActionId The toolbar id.
     * @returns {Promise<ILanguageProvider>} A promise, which will resolve with a language provider.
     * @memberof ToolbarActionLoader
     */
    private async loadLanguageFile(toolbarActionId: string): Promise<ILanguageProvider> {
        return new Promise<ILanguageProvider>((resolve, reject) => {
            const previousLanguageProvider = this.loadedLanguageProviders.get(toolbarActionId);
            if (previousLanguageProvider) {
                resolve(previousLanguageProvider);
            } else {
                const languageFilePath = this.toolbarActionsBasePath + toolbarActionId + '/language/' + this.languageManager.getLanguageCultureName() + '/translation.json';
                this.languageManager.loadLanguageProviderFromUrl(languageFilePath).then((languageProvider) => {
                    this.loadedLanguageProviders.set(toolbarActionId, languageProvider);
                    resolve(languageProvider);
                }).catch((error) => reject(error));
            }
        });
    }

    /**
     * Loads metadata file for a specific toolbar action.
     *
     * @private
     * @param {string} toolbarActionId The id of the toolbar action.
     * @returns {Promise<ToolbarActionMetadata>} A promise, which will resolve with the metadata.
     * @memberof ToolbarActionLoader
     */
    private async loadMetadata(toolbarActionId: string): Promise<ToolbarActionMetadata> {
        return new Promise<ToolbarActionMetadata>((resolve, reject) => {
            const previousMetadata = this.loadedMetadata.get(toolbarActionId);
            if (previousMetadata) {
                resolve(previousMetadata);
            } else {
                this.configLoader.loadToolbarActionMetadata(toolbarActionId).then((metadata) => {
                    this.loadedMetadata.set(toolbarActionId, metadata);
                    resolve(metadata);
                }).catch((error) => reject(error));
            }
        });
    }

    /**
     * Load the class reference into the DOM.
     *
     * @private
     * @param {ToolbarActionMetadata} toolbarActionMetadata The metadata to load.
     * @returns {Promise<any>} A promise, which will resolve with the class reference.
     * @memberof ToolbarActionLoader
     */
    private async loadClassReference(toolbarActionMetadata: ToolbarActionMetadata): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const previousClassReference = this.loadedClassReferences.get(toolbarActionMetadata.id);
            if (previousClassReference) {
                resolve(previousClassReference);
            } else {
                const toolbarActiontFolderPath = this.toolbarActionsBasePath + toolbarActionMetadata.id + '/';
                const entryFileName = toolbarActionMetadata.entry || 'index.js';
                const entryFilePath = toolbarActiontFolderPath + entryFileName;
                const toolbarActionScriptTag = this.currentDocument.createElement('script');
                toolbarActionScriptTag.setAttribute('src', entryFilePath);
                this.rootNode.appendChild(toolbarActionScriptTag);
                toolbarActionScriptTag.addEventListener('load', () => {
                    const registration = this.moduleRegistrationHelper.getRegistration(toolbarActionMetadata.id, ModuleRegistrationType.ToolbarAction);
                    if (registration && registration.classReference) {
                        this.loadedClassReferences.set(toolbarActionMetadata.id, registration.classReference);
                        resolve(registration.classReference);
                    } else {
                        reject(new Error('Toolbar action class reference not found.'));
                    }
                });
            }
        });
    }
}