import { ArgumentError } from '../../../errors/argumentError';
import { IDropDownButton, IDropDownButtonEntry, IDropDownButtonOptions } from './interfaces';


/**
 * A button with a list of additional elements in a drop down menu.
 *
 * @export
 * @class DropDownButton
 * @implements {IDropDownButton}
 */
export class DropDownButton implements IDropDownButton {
  /**
   * Callback that is emitted whenever the user selects an option or clicks on the main button.
   *
   * @memberof DropDownButton
   */
  public onClick?: (entry: IDropDownButtonEntry) => void;


  /**
   * Drop down entry for the element in the main button.
   *
   * @private
   * @type {IDropDownButtonEntry}
   * @memberof DropDownButton
   */
  private mainButtonEntry?: IDropDownButtonEntry;
  private targetElement: HTMLElement;
  private buttonOptions: DevExpress.ui.dxDropDownButtonOptions;
  private instance?: DevExpress.ui.dxDropDownButton;
  private jQueryStatic: JQueryStatic;


  /**
   * Creates an instance of a DropDownButton.
   * @param {HTMLElement} targetElement Target element to render to.
   * @param {JQueryStatic} jQueryStatic Instance of jQuery.
   * @memberof DropDownButton
   */
  public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
    if (!targetElement) {
      throw new ReferenceError('The argument "targetElement" was null or undefined.');
    }

    this.targetElement = targetElement;
    this.jQueryStatic = jQueryStatic;

    this.buttonOptions = {
      displayExpr: 'text',
      icon: '',
      items: [],
      keyExpr: 'id',
      onButtonClick: (event: any) => {
        if (!event) {
          throw new Error('dxButton.onButtonClick: The event parameter was null or undefined.');
        }

        if (this.mainButtonEntry) {
          this.onClickTriggered(this.mainButtonEntry);
        }
      },
      onItemClick: (event: any) => {
        if (!event) {
          throw new Error('dxButton.onItemClick: The event parameter was null or undefined.');
        }

        this.onClickTriggered(event.itemData);
      },
      splitButton: true,
      stylingMode: 'text',
      text: '',
    };
  }


  /**
   * Bootstrap the component.
   *
   * @memberof DropDownButton
   */
  public bootstrap(): void {
    this.instance = this.getJQueryElement().dxDropDownButton(this.buttonOptions).dxDropDownButton('instance');
  }

  /**
   * Set the component options.
   *
   * @param {IDropDownButtonOptions} options
   * @memberof DropDownButton
   */
  public setOptions(options: IDropDownButtonOptions): void {
    // Create the DevExpress options object.
    const buttonOptions: DevExpress.ui.dxDropDownButtonOptions = {};

    if (!options.hasOwnProperty('entries') || !options.entries.length) {
      throw new ArgumentError('At least one drop down entry must be present.');
    }

    this.mainButtonEntry = options.entries[0];
    buttonOptions.icon = this.mainButtonEntry.icon;
    buttonOptions.text = this.mainButtonEntry.text;

    if (options.hasOwnProperty('isDisabled')) {
      buttonOptions.disabled = options.isDisabled;
    }
    if (options.hasOwnProperty('tabIndex')) {
      buttonOptions.tabIndex = options.tabIndex;
    }
    buttonOptions.items = options.entries.slice(1);

    const mergedOptions = {
      ...this.buttonOptions,
      ...buttonOptions,
    };
    this.buttonOptions = mergedOptions;

    // Set the options.
    this.getJQueryElement().dxDropDownButton(this.buttonOptions);
  }

  /**
   * Sets the validation state of the component.
   *
   * @param {boolean} isValid Whether the component state is valid or invalid.
   * @memberof DropDownButton
   */
  public setValidity(isValid: boolean): void {
    if (this.instance) {
      this.instance.option('isValid', isValid);
    }
  }

  /**
   * Set the disabled state of the component.
   *
   * @param {boolean} isDisabled Whether the component should be disabled.
   * @memberof DropDownButton
   */
  public setDisabled(isDisabled: boolean): void {
    if (this.instance) {
      this.instance.option('disabled', isDisabled);
    }
  }

  /**
   * Triggers the onClick event.
   *
   * @private
   * @param {IDropDownButtonEntry} entry
   * @memberof DropDownButton
   */
  private onClickTriggered(entry: IDropDownButtonEntry) {
    if (typeof this.onClick === 'function') {
      this.onClick(entry);
    }
  }

  /**
   * Gets the button instance.
   *
   * @private
   * @returns {JQuery<HTMLElement>}
   * @memberof Button
   */
  private getJQueryElement(): JQuery<HTMLElement> {
    if (!this.targetElement) {
      throw new Error('The target element was not set yet.');
    }

    return this.jQueryStatic(this.targetElement);
  }
}
