/**
 * Abstract class to handle navigations.
 * Will take care of hiding elements that do not fit into the view.
 * 
 * @export
 * @abstract
 * @class NavigationHandler
 */
export abstract class NavigationHandler {
    protected element: HTMLElement;
    private prefix: string;

    private isInitialized: boolean;

    /**
     * Creates an instance of NavigationHandler.
     * @param {HTMLElement} element 
     * @param {string} prefix 
     * @memberof NavigationHandler
     */
    protected constructor(element: HTMLElement, prefix: string) {
        this.element = element;
        this.prefix = prefix;
        this.isInitialized = false;

    }

    /**
     * Attaches the observer and resize listener to hide elements inside the dropdown.
     *
     * @protected
     * @memberof NavigationHandler
     */
    protected init(): void {
        if ('MutationObserver' in window) {
            // Add mutation listener to update bar with
            const observer = new MutationObserver((mutationList) => {
                for (const mutation of mutationList) {
                    const element = mutation.target as HTMLElement;
                    if (element.style.display !== 'none') {
                        this.adjustWidth();
                    }
                }
            });
            observer.observe(this.element.parentElement as HTMLElement, { attributes: true, attributeFilter: ['style'] });
        }

        if (window) {
            const resizeDebounceDelay = 50;
            let timeout: any;
            window.addEventListener('resize', () => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    this.adjustWidth();
                }, resizeDebounceDelay);
            });
        }

        this.element.addEventListener('click', (e: Event) => {
            const target = e.target as HTMLElement;
            if (target && target.matches(`#${this.prefix}-more-dropdown *`)) {
                this.closeMoreDropdown();
            }
        });
    }

    /**
     * Adjusts the width of the navigation by hiding items inside a dropdown.
     * 
     * @protected
     * 
     * @memberof NavigationHandler
     */
    protected adjustWidth(): void {
        if (!this.isInitialized) {
            this.isInitialized = true;
            // @ts-ignore - Disabled because of Foundation usage
            if (typeof window['$'] !== 'undefined') {
                // @ts-ignore - Disabled because of Foundation usage
                $(this.element).foundation();
            }

        }
        const list = this.element.querySelector('.wd-navigation-list');
        if (list) {
            // Reset hidden flags first
            const hiddenMainElements = this.element.querySelectorAll('.wd-navigation-list > .wd-navigation-item.hidden');
            for (let i = 0; i < hiddenMainElements.length; i++) {
                hiddenMainElements.item(i).classList.remove('hidden');
            }
            const visibleSubElements = this.element.querySelectorAll('.wd-sub-navigation > .wd-navigation-item:not(.hidden)');
            for (let i = 0; i < visibleSubElements.length; i++) {
                visibleSubElements.item(i).classList.add('hidden');
            }
            const moreContainer = this.element.querySelector('[data-wd-id="' + this.prefix + '-more-container"]');
            if (moreContainer) {
                moreContainer.classList.remove('hidden');
            }
            const allItems = this.element.querySelectorAll('.wd-navigation-list > .wd-navigation-item');

            let needsAdjustment = this.needsAdjustment(this.element, list);
            let breaker = 0; // Helper variable to prevent endless loops
            let elementHidden = false; // Used to determine if at least one item was hidden
            while (needsAdjustment && breaker++ < allItems.length) {
                const visibleElements = this.element.querySelectorAll('.wd-navigation-list > .wd-navigation-item:not(.hidden):not(.active)');
                if (visibleElements && visibleElements.length > 1) {
                    const lastVisibleElement = visibleElements.item(visibleElements.length - 1);
                    if (lastVisibleElement) {
                        // Hide element in main navigation
                        lastVisibleElement.classList.add('hidden');
                        // Show element in sub navigation
                        const id = lastVisibleElement.getAttribute('data-wd-id');
                        if (id) {
                            const subMenuElement = this.element.querySelector(`.wd-sub-navigation > [data-wd-id="${id}"]`);
                            if (subMenuElement) {
                                subMenuElement.classList.remove('hidden');
                            }
                        }
                    }
                    elementHidden = true;
                }
                needsAdjustment = this.needsAdjustment(this.element, list);
            }
            // Hide more-button if no element was hidden
            if (!elementHidden) {
                if (moreContainer) {
                    moreContainer.classList.add('hidden');
                }
            }
        }

        // Make sure navigation is visible when switching from small to medium when it was hidden previously via Foundation toggle
        // Otherwise, the navigation would be hidden event for medium and larger breakpoints
        // @ts-ignore - Ignore because of window usage
        if (this.element && window['Foundation'] && window['Foundation'].MediaQuery.atLeast('medium')) {
            this.element.style.display = '';
        }
    }

    /**
     * Checks whether the navigation needs adjustment in terms of hiding single elements or not.
     * 
     * @protected
     * @param {HTMLElement} container Container that must not be overflown. 
     * @param {Element} list List element that may overflow the container.
     * @returns {boolean} 
     * 
     * @memberof NavigationHandler
     */
    protected needsAdjustment(container: HTMLElement, list: Element): boolean {
        const navigationContainerOffset = 40;
        return container.offsetWidth < (list.scrollWidth + navigationContainerOffset) || container.offsetLeft + container.offsetWidth > window.innerWidth;
    }

    /**
     * Close the more dropdown pane.
     * 
     * @private
     * @memberof NavigationHandler
     */
    protected closeMoreDropdown(): void {
        const dropdownPane = this.element.querySelector<HTMLElement>('[id$="more-dropdown"]');
        if (dropdownPane) {
            // @ts-ignore - Disabled because of Foundation usage
            $(dropdownPane).foundation('close');
        }
    }

}