import { FullRootlineFetchNodesResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Navigation';
import { RootlineItem } from './rootlineItem';
import { WindreamIdentityDetails } from './windreamIdentityDetails';

/**
 * Represents the response of a fetch nodes call for a given identity.
 *
 * @export
 * @class FetchNodesContainer
 */
export class FetchNodesContainer {
    /**
     * Root line of the identity.
     *
     * @type {RootlineItem[]}
     * @memberof FetchNodesContainer
     */
    public rootline: RootlineItem[];
    /**
     * Child items of the identity.
     *
     * @type {WindreamIdentityDetails[]}
     * @memberof FetchNodesContainer
     */
    public children: WindreamIdentityDetails[];
    /**
     * The identity itself.
     *
     * @type {WindreamIdentityDetails}
     * @memberof FetchNodesContainer
     */
    public identity: WindreamIdentityDetails;

    /**
     * Creates an instance of FetchNodesContainer.
     *
     * @param {WindreamIdentityDetails} identity
     * @memberof FetchNodesContainer
     */
    public constructor(identity: WindreamIdentityDetails) {
        this.identity = identity;
        this.children = new Array<WindreamIdentityDetails>();
        this.rootline = new Array<RootlineItem>();
    }

    /**
     * Creates an instance from the given DTO.
     *
     * @static
     * @param {FullRootlineFetchNodesResponseContainerDTO} dto DTO to create from.
     * @returns {FetchNodesContainer}
     * @memberof FetchNodesContainer
     */
    public static fromDto(dto: FullRootlineFetchNodesResponseContainerDTO): FetchNodesContainer {
        const result = new FetchNodesContainer(WindreamIdentityDetails.fromDto(dto.Item));
        if (dto.Children) {
            result.children = dto.Children.map((item) => WindreamIdentityDetails.fromDto(item));
        }
        if (dto.Rootline) {
            result.rootline = dto.Rootline.map((item) => RootlineItem.fromDto(item));
        }
        return result;
    }
}