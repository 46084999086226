import { SubscriptionEventResponseDTO, SubscriptionEventsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Subscription';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HttpResourcePointer } from '../../dataProviders';
import { SubscriptionEvent } from '../models/subscriptionEvent';
import { SubscriptionEventOptions } from '../models/subscriptionEventOptions';
import { ServiceAction } from '../serviceAction';

/**
 * The GetSubscriptionEvents action is used get the subscription events.
 * 
 * @export
 * @class GetSubscriptionEvents
 * @extends {ServiceAction}
 */
export class GetSubscriptionEvents extends ServiceAction {

  /**
   * Name of the service action.
   * 
   * 
   * @memberof GetSubscriptionEvents
   */
  public name = 'getSubscriptionEvents';

  /**
   * Execute GetSubscriptionEvents.
   *
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @returns {Promise<void>} A promise, which will resolve with the server response.
   * @memberof GetSubscriptionEvents
   */
  public async do(): Promise<SubscriptionEventOptions> {
    return new Promise<SubscriptionEventOptions>((resolve, reject) => {
      this.requestExecutor.executeRequest(new HttpResourcePointer('GET', this.globalConfig.windreamWebServiceURL + '/subscriptions/GetSubscriptionEvents'))
      .then((response: IServiceResponse<SubscriptionEventsResponseContainerDTO>) => {
        if (response.data?.HasErrors) {
          this.logger.error('GetSubscriptionEvents', 'do', 'Failed to get subscriptions', response);
          reject('Failed to get subscriptions');
        } else {
          if (response.data && response.data.SubscriptionEvents) {
            resolve(this.convertResponse(response.data));
          }
        }
      }).catch((err: Error) => {
        this.logger.error('GetSubscriptionEvents', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }

    /**
     * Converts from the DTO model.
     *
     * @private
     * @param {SubscriptionEventResponseDTO} response The response.
     * @returns {SubscriptionEvent} The subscription event.
     * @memberof GetSubscriptionEvents
     */
    private convertResponse(response: SubscriptionEventsResponseContainerDTO): SubscriptionEventOptions {
      const subscription = new SubscriptionEventOptions([]);

      response.SubscriptionEvents.forEach((element: SubscriptionEventResponseDTO) => {
        const subsciptionEvent = new SubscriptionEvent(element.Id, element.Name, element.Description);
        subscription.subscriptionEvents.push(subsciptionEvent);
      });

    return subscription;
  }
}