/**
 * A class for user credentials like domain, username etc..
 *
 * @export
 * @class UserCredentials
 */
export class UserCredentials {

    /**
     * The domain of the user.
     *
     * @type {string}
     * @memberof UserCredentials
     */
    public domain?: string;


    /**
     * The name of the user.
     *
     * @type {string}
     * @memberof UserCredentials
     */
    public username?: string;


    /**
     * The authenticationToken of the user.
     *
     * @type {string}
     * @memberof UserCredentials
     */
    public authenticationToken?: string;

    /**
     * Whether the login is permanent or not.
     * 
     * @type {boolean}
     * @memberof UserCredentials
     */
    public staySignedIn?: boolean;

    /**
     * Whether the credentials are used to login once or not.
     * 
     * @type {boolean}
     * @memberof UserCredentials
     */
    public loginOnce?: boolean;
}