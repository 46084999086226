import { WindreamIdentity } from '../../dynamicWorkspace';

export class FileUploadResponse {

    /**
     * The error if an error has occurred.
     *
     * @type {Error}
     * @memberof FileUploadResponse
     */
    public error?: Error;

    /**
     * The identity.
     *
     * @type {WindreamIdentity}
     * @memberof FileUploadResponse
     */
    public identity: WindreamIdentity;


    /**
     * Determines whether indexing is required for the file.
     *
     * @type {boolean}
     * @memberof FileUploadResponse
     */
    public indexEventRequired: boolean;


    /**
     * Creates an instance of FileUploadResponse.
     *
     * @memberof FileUploadResponse
     */
    public constructor() {
        this.identity = new WindreamIdentity();
        this.indexEventRequired = false;
    }

}