import { DirectoryRightsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler';
import { WindreamEntity } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging/';
import { DirectoryRightsModel, IdentityRightsModelConverter } from '../../rights';
import { UpdateDirectoryRightsRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to get rights from an identity in the windream webservice.
 * 
 * @export
 * @class UpdateDirectoryRights
 * @extends {ServiceAction}
 */
export class UpdateDirectoryRights extends ServiceAction {
    private converter: IdentityRightsModelConverter;

    /**
     * Creates an instance of UpdateDirectoryRights.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @param {IdentityRightsModelConverter} converter 
     * @memberof UpdateDirectoryRights
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, converter: IdentityRightsModelConverter) {
        super(requestExecutor, globalConfig, logger);
        this.converter = converter;

        this.name = 'updateDirectoryRights';
    }

    /**
     * Updates righs for the given directory.
     * Resolves a promise with the converted server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {UpdateDirectoryRightsRequestOptions} updateDirectoryRightsRequestOptions The request options.
     * @returns {Promise<DirectoryRightsModel>} A promise, which will resolve with the directory rights model.
     * @memberof UpdateDirectoryRights
     */
    public async do(updateDirectoryRightsRequestOptions: UpdateDirectoryRightsRequestOptions): Promise<DirectoryRightsModel> {
        return new Promise<DirectoryRightsModel>((resolve, reject) => {
            if (updateDirectoryRightsRequestOptions.identity.entity !== WindreamEntity.Folder) {
                this.logger.error('UpdateDirectoryRights', 'do', 'Identity is not a directory', updateDirectoryRightsRequestOptions.identity);
                reject(new Error('Identity is not a directory'));
                return;
            }
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/directories/UpdateDirectoryRights`;
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', tempUrl,
                this.converter.convertDirectoryRightsToDto(updateDirectoryRightsRequestOptions.rightsModel,
                    updateDirectoryRightsRequestOptions.identity)), updateDirectoryRightsRequestOptions.requestOptions)
                .then((response: IServiceResponse<DirectoryRightsResponseContainerDTO>) => {
                    if (response && response.data && !response.data.HasErrors) {
                        resolve(this.converter.convertDirectoryRightsToModel(response.data));
                    } else {
                        this.logger.error('UpdateDirectoryRights', 'do', 'Failed to update rights', response);
                        reject(new Error('Failed to update rights'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('UpdateDirectoryRights', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}