import { Utils, WindreamIdentity } from '../common';
import { SubViewConfig } from '../loader';
import { Logger } from '../logging';
import { IAppFocusHandler, IWebBridgeHandler, WebBridgeEventTypes } from '../webBridge';


/**
 * Handles focus events with the app container using the WebBridge.
 *
 * @export
 * @class AppFocusHandler
 * @implements {IAppFocusHandler}
 */
export class AppFocusHandler implements IAppFocusHandler {
    private webBridgeHandler: IWebBridgeHandler;
    private lastSentDirectory: any | null;
    private lastSentSelectedIdentities: any[];
    private logger: Logger;

    private directoryFocusPerComponent: Map<string, WindreamIdentity>;
    private selectedIdentitiesPerComponent: Map<string, WindreamIdentity[]>;
    private currentlyActiveSubView?: SubViewConfig;

    /**
     * Creates an instance of AppFocusHandler.
     *
     * @param {IWebBridgeHandler} webBridgeHandler
     * @param {Logger} logger
     * @memberof AppFocusHandler
     */
    public constructor(webBridgeHandler: IWebBridgeHandler, logger: Logger) {
        this.webBridgeHandler = webBridgeHandler;
        this.logger = logger;
        this.lastSentDirectory = null;
        this.lastSentSelectedIdentities = [];

        this.directoryFocusPerComponent = new Map<string, WindreamIdentity>();
        this.selectedIdentitiesPerComponent = new Map<string, WindreamIdentity[]>();
    }

    /**
     * The sub view with the given config is now active.
     *
     * @param {SubViewConfig} subViewConfig Config of the active sub view.
     * @memberof AppFocusHandler
     */
    public subViewChanged(subViewConfig: SubViewConfig): void {
        this.currentlyActiveSubView = subViewConfig;
        const componentsOnActiveSubView = subViewConfig.components;
        componentsOnActiveSubView.forEach((componentGuid) => {
            const directoryFocusForComponent = this.directoryFocusPerComponent.get(componentGuid);
            if (directoryFocusForComponent) {
                this.publishDirectoryFocus(directoryFocusForComponent);
            }
            const selectedIdentitiesForComponent = this.selectedIdentitiesPerComponent.get(componentGuid);
            if (selectedIdentitiesForComponent) {
                this.publishSelectedIdentities(selectedIdentitiesForComponent);
            }
        });
        if (componentsOnActiveSubView.length === 0) {
            this.unsetFocus();
        }
    }

    /**
     * Removes focus from the currently focused element.
     * Publishes `SelectedFolderChanged` event via WebBridgeHandler.
     * Only publishes if another element has been selected before.
     *
     * @param {string} componentGuid GUID of the component that publishes.
     * @param {WindreamIdentity} identity Identity to publish.
     * @returns {void}
     * @memberof AppFocusHandler
     */
    public setDirectoryFocus(componentGuid: string, identity: WindreamIdentity): void {
        this.directoryFocusPerComponent.set(componentGuid, identity);
        // Trigger check whether component is on an active sub view
        if (this.currentlyActiveSubView) {
            this.subViewChanged(this.currentlyActiveSubView);
        }
    }


    /**
     * Removes focus from the currently focused element.
     * Publishes `UnsetFocus` event via WebBridgeHandler.
     * Only publishes if an element has been selected before.
     *
     * @returns {void}
     * @memberof AppFocusHandler
     */
    public unsetFocus(): void {
        if (this.lastSentDirectory === null) {
            this.logger.debug('AppFocusHandler', 'unsetFocus', 'Not publishing because no selection is present');
            return;
        }
        this.webBridgeHandler.publish(WebBridgeEventTypes.UnsetFocus, '');
        this.lastSentDirectory = null;
    }

    /**
     * Publishes `SelectedItemsChanged` event via WebBridgeHandler.
     * Only publishes if other items have been selected before.
     *
     * @param {string} componentGuid GUID of the component that publishes.
     * @param {WindreamIdentity[]} identities Identities to publish.
     * @returns {void}
     * @memberof AppFocusHandler
     */
    public setSelectedIdentities(componentGuid: string, identities: WindreamIdentity[]): void {
        this.selectedIdentitiesPerComponent.set(componentGuid, identities);
        // Trigger check whether component is on an active sub view
        if (this.currentlyActiveSubView) {
            this.subViewChanged(this.currentlyActiveSubView);
        }
    }

    /**
     * Publishes the given identity using the WebBridge.
     *
     * @private
     * @param {WindreamIdentity[]} identity Identity to publish.
     * @memberof AppFocusHandler
     */
    private publishDirectoryFocus(identity: WindreamIdentity): void {
        if (this.lastSentDirectory !== null && Utils.Instance.isDeepEqual(identity, this.lastSentDirectory)) {
            this.logger.debug('AppFocusHandler', 'publishDirectoryFocus', 'Not publishing because selection has not changed', [identity, this.lastSentDirectory]);
            return;
        }
        this.webBridgeHandler.publish(WebBridgeEventTypes.SelectedFolderChanged, JSON.stringify(identity));
        this.lastSentDirectory = identity;
    }

    /**
     * Publishes the given identities using the WebBridge.
     *
     * @private
     * @param {WindreamIdentity[]} identities Identities to publish.
     * @memberof AppFocusHandler
     */
    private publishSelectedIdentities(identities: WindreamIdentity[]): void {
        if (this.lastSentSelectedIdentities !== null && Utils.Instance.isDeepEqual(identities, this.lastSentSelectedIdentities)) {
            this.logger.debug('AppFocusHandler', 'publishSelectedIdentities', 'Not publishing because selection has not changed', [identities, this.lastSentSelectedIdentities]);
            return;
        }
        this.webBridgeHandler.publish(WebBridgeEventTypes.SelectedItemsChanged, JSON.stringify(identities));
        this.lastSentSelectedIdentities = identities;
    }
}