import { TextBox } from '../../../typings/ui';
import { DeviceDetection } from '../common';
import { DWCore, IUiComponentFactory, Utils } from '../dynamicWorkspace';
import { ITemplateExtension } from '../extensions/interfaces/iTemplateExtension';
import { ILanguageProvider } from '../language';
import { AboutRouter, RootRouter, RouteManager, SettingsRouter, ViewRouter } from '../router';
import { SystemSettingsRouter } from '../router/systemSettingsRouter';
import { IStorage } from '../storage';
import { IThemeManager } from '../themeManager';

/**
 * This class will handle the navigation panel within DW.
 *
 * @export
 * @class NavigationPanelHandler
 */
export class NavigationPanelHandler {
    private window: Window;
    private navigationPanelRootElement: HTMLElement;
    private systemSettingsRootAreaElement: HTMLElement | null = null;
    private languageProvider: ILanguageProvider;

    private _isCollapsed = false;
    public get isCollapsed() {
        return this._isCollapsed;
    }

    private _isAdmin = false;
    public set isAdmin(value: boolean) {
        this._isAdmin = value;
        if (this.systemSettingsRootAreaElement) {
            this.systemSettingsRootAreaElement.hidden = !this._isAdmin || Utils.Instance.deviceDetection.isMobile();
        }
    }

    private readonly autoCollapseTreshold = 500;
    private shrinkButtonElement: HTMLButtonElement | null = null;
    private navigationHeaderPanel: HTMLElement | null = null;
    private applicatioContainerElement: HTMLElement | null = null;
    private viewNavigationElement: HTMLElement | null = null;
    private viewSearchTextBox?: TextBox;
    private viewRouter: ViewRouter;
    private rootRouter: RootRouter;
    private aboutRouter: AboutRouter;
    private settingsRouter: SettingsRouter;
    private systemSettingsRouter: SystemSettingsRouter;
    private localStorage: IStorage;
    private themeManager: IThemeManager;
    private templateExtension: ITemplateExtension;
    private uiComponentFactory: IUiComponentFactory;
    private isUpdating = false;
    private isNavigationCollapsedByUser = false;

    /**
     * Executes if a search for views should occur.
     *
     * @memberof NavigationPanelHandler
     */
    public onSearchViews?: (searchTerm: string) => void;


    /**
     * Creates an instance of NavigationPanelHandler.
     * 
     * @param {Window} window The browser window instance.
     * @param {HTMLElement} navigationRootElement The navigation root.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {ViewRouter} viewRouter The view router.
     * @param {RootRouter} rootRouter The root router.
     * @param {AboutRouter} aboutRouter The about router.
     * @param {SettingsRouter} settingsRouter The settings router.
     * @param {SystemSettingsRouter} systemsSettingsRouter The system settings router.
     * @param {IThemeManager} themeManager The theme manager.
     * @param {ITemplateExtension} templateExtension The template extension.
     * @param {IStorage} localStorage Localstorage 
     * @param {IUiComponentFactory} uiComponentFactory The ui component factory. 
     * @memberof NavigationPanelHandler
     */
    public constructor(window: Window, navigationRootElement: HTMLElement, languageProvider: ILanguageProvider,
        viewRouter: ViewRouter, rootRouter: RootRouter, aboutRouter: AboutRouter, settingsRouter: SettingsRouter,
        systemsSettingsRouter: SystemSettingsRouter, themeManager: IThemeManager, templateExtension: ITemplateExtension, localStorage: IStorage, uiComponentFactory: IUiComponentFactory) {

        this.window = window;
        this.navigationPanelRootElement = navigationRootElement;
        this.languageProvider = languageProvider;
        this.viewRouter = viewRouter;
        this.rootRouter = rootRouter;
        this.aboutRouter = aboutRouter;
        this.settingsRouter = settingsRouter;
        this.localStorage = localStorage;
        this.themeManager = themeManager;
        this.templateExtension = templateExtension;
        this.uiComponentFactory = uiComponentFactory;
        this.systemSettingsRouter = systemsSettingsRouter;
        this.initNavigationPanel();
        if (!Utils.Instance.deviceDetection.isMobile()) {
            this.handleInitialNavigationState();
        }
    }

    /**
     * Init the navigation with language, a11y, events etc.
     *
     * @private
     * @memberof NavigationPanelHandler
     */
    private initNavigationPanel(): void {

        this.applicatioContainerElement = this.window.document.querySelector('.wd-application-container');

        // Navigation header panel
        this.navigationHeaderPanel = this.navigationPanelRootElement.querySelector<HTMLElement>('.wd-navigation-header-panel');
        if (this.navigationHeaderPanel) {
            const viewModel = {
                goHomeText: this.languageProvider.get('framework.header.goHome'),
                customConfig: this.themeManager.getTheme()
            };
            this.templateExtension.render(this.navigationHeaderPanel, require('./templates/navigationHeaderPanel.html'), viewModel, true);

            if (viewModel.customConfig.titleBackgroundColor) {
                this.navigationHeaderPanel.style.backgroundColor = viewModel.customConfig.titleBackgroundColor;
            }

            const pageTitleElement = this.navigationHeaderPanel.querySelector<HTMLElement>('.wd-page-title');
            if (pageTitleElement && viewModel.customConfig.titleTextColor) {
                pageTitleElement.style.color = viewModel.customConfig.titleTextColor;
            }
        }

        this.viewNavigationElement = this.navigationPanelRootElement.querySelector<HTMLElement>('#wd-view-navigation');

        const viewHeadingElement = this.navigationPanelRootElement.querySelector<HTMLHeadingElement>('.wd-view-navigation-view-heading');
        if (viewHeadingElement) {
            viewHeadingElement.innerText = this.languageProvider.get('framework.navigation.headings.views');
        }
        const optionsHeadingElement = this.navigationPanelRootElement.querySelector<HTMLHeadingElement>('.wd-view-navigation-options-heading');
        if (optionsHeadingElement) {
            optionsHeadingElement.innerText = this.languageProvider.get('framework.navigation.headings.options');
        }

        const isOnMobile = Utils.Instance.deviceDetection.isMobile();
        const viewSearchArea = this.navigationPanelRootElement.querySelector<HTMLElement>('.wd-navigation-panel-button-group');
        if (viewSearchArea) {
            viewSearchArea.hidden = isOnMobile;
        }
        if (isOnMobile) {
            const homeLink = this.navigationPanelRootElement.querySelector<HTMLElement>('a');
            if (homeLink) {
                // Don't redirect to start page for mobile.
                homeLink.addEventListener('click', (event) => {
                    event.preventDefault();
                });
            }
        }

        this.shrinkButtonElement = this.navigationPanelRootElement.querySelector<HTMLButtonElement>('.wd-shrink-navigation-button');
        if (this.shrinkButtonElement) {
            this.shrinkButtonElement.title = this.languageProvider.get('framework.navigation.collapseButton.collapse');
            this.shrinkButtonElement.addEventListener('click', () => {
                if (this.navigationPanelRootElement.classList.contains('collapsed')) {
                    this.localStorage.setItem('DynamicWorkspace-NavigationState', 'extended');
                    this.extend();
                } else {
                    this.localStorage.setItem('DynamicWorkspace-NavigationState', 'collapsed');
                    this.isNavigationCollapsedByUser = true;
                    this.collapse();
                }
            });
        }
        const viewSearchInputElement = this.navigationPanelRootElement.querySelector<HTMLInputElement>('.wd-view-search-input');
        if (viewSearchInputElement) {
            this.viewSearchTextBox = this.uiComponentFactory.textBox(viewSearchInputElement);
            this.viewSearchTextBox.setOptions({
                placeholderText: this.languageProvider.get('framework.navigation.search.input.placeHolder'),
                stylingMode: 'underlined',
                type: 'search'
            });
            this.viewSearchTextBox.bootstrap();
            this.viewSearchTextBox.onValueChanged = ((value) => {
                if (this.onSearchViews && typeof value === 'string') {
                    this.onSearchViews(value);
                }
            });
        }
        const settingsAreaElement = this.navigationPanelRootElement.querySelector<HTMLDivElement>('.wd-settings-button-group');
        if (settingsAreaElement) {
            this.settingsRouter.registerOnSettingsNavigation(() => {
                this.disableSettingsActiveIndicator();
                settingsAreaElement.classList.add('active');
            });
            settingsAreaElement.title = this.languageProvider.get('framework.navigation.settingsButton.title');
            settingsAreaElement.addEventListener('click', () => {

                RouteManager.navigate('/settings');
            });
            const settingsButton = settingsAreaElement.querySelector<HTMLButtonElement>('.wd-settings-button');
            if (settingsButton) {
                settingsButton.title = this.languageProvider.get('framework.navigation.settingsButton.title');
            }
            const settingsLabel = settingsAreaElement.querySelector<HTMLDivElement>('.wd-settings-label');
            if (settingsLabel) {
                settingsLabel.innerText = this.languageProvider.get('framework.navigation.settingsButton.title');
            }
        }
        const aboutAreaElement = this.navigationPanelRootElement.querySelector<HTMLDivElement>('.wd-about-button-group');
        if (aboutAreaElement) {
            this.aboutRouter.registerOnAboutNavigation(() => {
                this.disableSettingsActiveIndicator();
                aboutAreaElement.classList.add('active');
            });
            aboutAreaElement.title = this.languageProvider.get('framework.navigation.aboutButton.title');
            aboutAreaElement.addEventListener('click', () => {
                RouteManager.navigate('/about');
            });
            const aboutButton = aboutAreaElement.querySelector<HTMLButtonElement>('.wd-about-button');
            if (aboutButton) {
                aboutButton.title = this.languageProvider.get('framework.navigation.aboutButton.title');
            }
            const aboutLabel = aboutAreaElement.querySelector<HTMLDivElement>('.wd-about-label');
            if (aboutLabel) {
                aboutLabel.innerText = this.languageProvider.get('framework.navigation.aboutButton.title');
            }
        }
        this.systemSettingsRootAreaElement = this.navigationPanelRootElement.querySelector<HTMLDivElement>('.wd-system-settings-button-group');
        if (this.systemSettingsRootAreaElement) {
            this.systemSettingsRouter.registerOnSystemSettingsNavigation(() => {
                this.disableSettingsActiveIndicator();
                if (this.systemSettingsRootAreaElement) {
                    this.systemSettingsRootAreaElement.classList.add('active');
                }
            });
            this.systemSettingsRootAreaElement.title = this.languageProvider.get('framework.navigation.systemSettingsButton.title');
            this.systemSettingsRootAreaElement.addEventListener('click', () => {
                RouteManager.navigate('/systemsettings');
            });
            const systemSettingsButton = this.systemSettingsRootAreaElement.querySelector<HTMLButtonElement>('.wd-system-settings-button');
            if (systemSettingsButton) {
                systemSettingsButton.title = this.languageProvider.get('framework.navigation.systemSettingsButton.title');
            }
            const systemSettingsLabel = this.systemSettingsRootAreaElement.querySelector<HTMLDivElement>('.wd-system-settings-label');
            if (systemSettingsLabel) {
                systemSettingsLabel.innerText = this.languageProvider.get('framework.navigation.systemSettingsButton.title');
            }
        }
        this.rootRouter.registerOnRootNavigation(() => {
            if (aboutAreaElement) {
                aboutAreaElement.classList.remove('active');
            }
            if (settingsAreaElement) {
                settingsAreaElement.classList.remove('active');
            }
            if (this.systemSettingsRootAreaElement) {
                this.systemSettingsRootAreaElement.classList.remove('active');
            }
        });
        this.viewRouter.registerOnViewChange(() => {
            if (aboutAreaElement) {
                aboutAreaElement.classList.remove('active');
            }
            if (settingsAreaElement) {
                settingsAreaElement.classList.remove('active');
            }
            if (this.systemSettingsRootAreaElement) {
                this.systemSettingsRootAreaElement.classList.remove('active');
            }
            if (this.viewSearchTextBox) {
                this.viewSearchTextBox.setValue('');
            }
        });

        if (this.window) {
            if (!isOnMobile) {
                const resizeDebounceDelay = 50;
                let timeout: any;
                this.window.addEventListener('resize', () => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        this.handleAutoCollapse();
                    }, resizeDebounceDelay);
                });
            } else {
                // Swipe gesture not refined yet pretty simple
                let touchstartX = 0;
                let touchendX = 0;
                let timeTouchStart: number;
                let timeTouchEnd: number;

                // The time in milliseconds the movement should take, to be considered as a swipe
                const swipeTimeThreshold = 700;

                // The distance in pixels the movement should take, to be considered as a swipe
                const phoneSwipeDistanceThreshold = 200;
                const tabletSwipeDistanceThreshold = 150;
                const swipeDistanceThreshold = Utils.Instance.deviceDetection.isPhone() ? phoneSwipeDistanceThreshold : tabletSwipeDistanceThreshold;

                this.window.addEventListener('touchstart', (event) => {
                    touchstartX = event.changedTouches[0].screenX;
                    timeTouchStart = new Date().getTime();
                });
                this.window.addEventListener('touchend', (event) => {
                    touchendX = event.changedTouches[0].screenX;
                    timeTouchEnd = new Date().getTime();
                    if ((timeTouchEnd - timeTouchStart) < swipeTimeThreshold) {
                        if (Math.abs(touchstartX - touchendX) > swipeDistanceThreshold) {
                            // Should be a swipe gesture
                            if (touchendX > touchstartX) {
                                // Right swipe
                                this.navigationPanelRootElement.classList.add('wd-display-navigation-panel-mobile-like');
                            } else if (touchendX < touchstartX) {
                                // Left swipe
                                this.navigationPanelRootElement.classList.remove('wd-display-navigation-panel-mobile-like');
                            }
                        }
                    }
                });
            }
        }
    }

    /**
     * Disables the settings active indicator.
     *
     * @private
     * @memberof NavigationPanelHandler
     */
    private disableSettingsActiveIndicator(): void {
        const settingsAreaElement = this.navigationPanelRootElement.querySelector<HTMLDivElement>('.wd-settings-button-group');
        const aboutAreaElement = this.navigationPanelRootElement.querySelector<HTMLDivElement>('.wd-about-button-group');
        if (aboutAreaElement) {
            aboutAreaElement.classList.remove('active');
        }
        if (settingsAreaElement) {
            settingsAreaElement.classList.remove('active');
        }
        if (this.systemSettingsRootAreaElement) {
            this.systemSettingsRootAreaElement.classList.remove('active');
        }
    }

    /**
     * Collapse the navigation.
     *
     * @private
     * @memberof NavigationPanelHandler
     */
    private collapse(): void {
        if (this.shrinkButtonElement && !this.isCollapsed) {
            const shrinkButtonElementSpan = this.shrinkButtonElement.querySelector('span');
            this.navigationPanelRootElement.style.width = '';
            this.navigationPanelRootElement.classList.add('collapsed');
            this.shrinkButtonElement.title = this.languageProvider.get('framework.navigation.collapseButton.extend');
            shrinkButtonElementSpan?.classList.remove('angles-left');
            shrinkButtonElementSpan?.classList.add('angles-right');

            if (this.navigationHeaderPanel) {
                this.navigationHeaderPanel.classList.add('collapsed');
            }

            this._isCollapsed = true;
        }

    }

    /**
     * Extend the navigation.
     *
     * @private
     * @memberof NavigationPanelHandler
     */
    private extend(): void {
        if (this.shrinkButtonElement && this.isCollapsed) {
            const shrinkButtonElementSpan = this.shrinkButtonElement.querySelector('span');
            this.shrinkButtonElement.title = this.languageProvider.get('framework.navigation.collapseButton.collapse');
            this.navigationPanelRootElement.classList.remove('collapsed');
            shrinkButtonElementSpan?.classList.add('angles-left');
            shrinkButtonElementSpan?.classList.remove('angles-right');

            if (this.navigationHeaderPanel) {
                this.navigationHeaderPanel.classList.remove('collapsed');
            }
            this.isNavigationCollapsedByUser = false;
            this._isCollapsed = false;
        }
    }

    /**
     * Hides the complete navigation.
     *
     * @memberof NavigationPanelHandler
     */
    public hideNavigation(): void {
        this.navigationPanelRootElement.hidden = true;
    }

    /**
     *Shows the navigation.
     *
     * @memberof NavigationPanelHandler
     */
    public showNavigation(): void {
        this.navigationPanelRootElement.hidden = false;

        const activeViewElement = this.navigationPanelRootElement.querySelector<HTMLElement>('.wd-navigation-panel-item.active');
        if (activeViewElement && this.viewNavigationElement) {
            this.viewNavigationElement.scrollTo(
                {
                    top: activeViewElement.offsetTop
                });
        }
    }

    /**
    * Handel navigation state (collapsed or extended)
    *
    * @memberof NavigationPanelHandler
    */
    public handleInitialNavigationState(): void {
        const currentDevice = DeviceDetection.getCurrentDevice();
        const navState = this.localStorage.getItem('DynamicWorkspace-NavigationState');
        // Default behavior
        if (!navState) {
            switch (currentDevice) {
                case DWCore.Common.Devices.PHONE:
                    this.collapse(); // Collapse on mobile
                    break;
                case DWCore.Common.Devices.TABLET:
                case DWCore.Common.Devices.DESKTOP:
                    this.extend(); // Expand on tablet and desktop
                    break;
            }
        } else {
            if (navState === 'collapsed' && !this.isCollapsed) {
                this.collapse();
            } else if (navState === 'extended' && this.isCollapsed) {
                this.extend();
            }
        }
    }
    /**
     * Handles the auto-collapse feature.
     *
     * @memberof NavigationPanelHandler
     */
    public handleAutoCollapse(): void {
        if (this.applicatioContainerElement && this.navigationHeaderPanel && this.shrinkButtonElement) {
            const appWidth = this.applicatioContainerElement.clientWidth;
            if (appWidth > this.autoCollapseTreshold) {
                this.shrinkButtonElement.disabled = false;
                if (!this.isUpdating && !this.isNavigationCollapsedByUser) {
                    this.isUpdating = true;
                    this.extend();
                    this.isUpdating = false;
                }
            } else {
                this.shrinkButtonElement.disabled = true;
                if (!this.isUpdating && !this.isNavigationCollapsedByUser) {
                    this.isUpdating = true;
                    this.collapse();
                    this.isUpdating = false;
                }
            }
        }
    }
}