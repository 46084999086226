import { IClonable } from '../../common';
import { RIGHT_ENTITIES, RightModel } from '.';

/**
 * Model to reflect rights for an item in windream for a certain user or group.
 * 
 * @export
 * @class IdentityRightsModel
 */
export class IdentityRightsModel implements IClonable<IdentityRightsModel> {
    /**
     * Name of the user or group.
     * 
     * @type {string}
     * @memberof IdentityRightsModel
     */
    public name: string;

    /**
     * Domain of the user or group.
     * 
     * @type {string}
     * @memberof IdentityRightsModel
     */
    public domain: string;

    /**
     * Description of the user or group.
     * 
     * @type {string}
     * @memberof IdentityRightsModel
     */
    public description: string;

    /**
     * The entityof the right owner.
     * 
     * @type {RIGHT_ENTITIES}
     * @memberof IdentityRightsModel
     */
    public entity: RIGHT_ENTITIES;

    /**
     * The rights that are associated with the entry.
     * 
     * @type {RightModel[]}
     * @memberof IdentityRightsModel
     */
    public rights: RightModel[];

    /**
     * Creates an instance of IdentityRightsModel.
     * @param {string} name Name of the user or group
     * @param {string} domain Domain of the user or group,
     * @param {RIGHT_ENTITIES} entity Entity of the right owner.
     * @memberof IdentityRightsModel
     */
    public constructor(name: string, domain: string, entity: RIGHT_ENTITIES) {
        this.name = name;
        this.domain = domain;
        this.entity = entity;
        this.description = '';

        this.rights = new Array<RightModel>();
    }

    /**
     * Creates a clone of this instance.
     * 
     * @returns {IdentityRightsModel} The clone.
     * @memberof IdentityRightsModel
     */
    public clone(): IdentityRightsModel {
        const clone = new IdentityRightsModel(this.name, this.domain, this.entity);
        clone.description = this.description;
        clone.rights = this.rights.map((right) => right.clone());
        return clone;
    }
}