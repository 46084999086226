import { Utils } from '../../../common/utils';
import { IDataSourceItem, IItemCollectionDataSource } from '../common';
import { IRadioButtonGroup, IRadioButtonGroupOptions } from './interfaces';


/**
 * The base class for a radio button group.
 * 
 * @export
 * @class RadioButtonGroup
 * @implements {IRadioButtonGroup<T>}
 * @template T Value type that lies beyond.
 */
export class RadioButtonGroup<T> implements IRadioButtonGroup<T> {
    /**
     * The callback for the value changed event.
     * 
     * @memberof RadioButtonGroup
     */
    public onValueChanged?: (item: IDataSourceItem<T> | null) => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof RadioButtonGroup
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof RadioButtonGroup
     */
    public onBlur?: () => void;

    private targetElement: HTMLElement;
    private radioButtonGroupOptions: DevExpress.ui.dxRadioGroupOptions;
    private instance?: DevExpress.ui.dxRadioGroup;
    private jQueryStatic: JQueryStatic;
    private dataSource?: IItemCollectionDataSource<T>;


    /**
     * Creates an instance of RadioButtonGroup.
     * 
     * @param {HTMLElement} targetElement
     * @param {JQueryStatic} jQueryStatic
     * @memberof RadioButtonGroup
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;

        this.radioButtonGroupOptions = {
            activeStateEnabled: false,
            focusStateEnabled: false,
            itemTemplate: (itemData: IDataSourceItem<T>, _index, itemElement: DevExpress.core.dxElement) => {
                itemElement.text(itemData.displayValue || '');
            },
            onValueChanged: (event) => {
                if (!event) {
                    throw new Error('dxRadioGroup.onValueChanged: The event parameter was null or undefined.');
                } else {
                    this.onValueChangedTrigger(event.value);
                }
            },
            layout: 'vertical'
        };
    }


    /**
     * Bootstraps the radio button group.
     * 
     * @memberof RadioButtonGroup
     */
    public bootstrap(): void {
        this.instance = this.getJQueryElement().dxRadioGroup(this.radioButtonGroupOptions)
            .on('focus', () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            }).on('blur', () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            }).dxRadioGroup('instance');
    }


    /**
     * Sets the radio button group options.
     * 
     * @param {IButtonBoxOptions} options 
     * @memberof RadioButtonGroup
     */
    public setOptions(options: IRadioButtonGroupOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }

        // Create the DevExpress options object.
        const radioButtonGroupOptions: DevExpress.ui.dxRadioGroupOptions = {};

        if (options.hasOwnProperty('orientation')) {
            radioButtonGroupOptions.layout = options.orientation;
        }
        if (options.hasOwnProperty('tabIndex')) {
            radioButtonGroupOptions.tabIndex = options.tabIndex;
        }
        if (options.hasOwnProperty('focusStateEnabled')) {
            radioButtonGroupOptions.focusStateEnabled = options.focusStateEnabled;
        }
        if (options.hasOwnProperty('activeStateEnabled')) {
            radioButtonGroupOptions.activeStateEnabled = options.activeStateEnabled;
        }
        // Set the options.
        this.getJQueryElement().dxRadioGroup(radioButtonGroupOptions);
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof RadioButtonGroup
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof RadioButtonGroup
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('disabled', isDisabled);
        }
    }

    /**
     * Sets the current value.
     * 
     * @param {T} value
     * @memberof RadioButtonGroup
     */
    public setValue(value: T | null): void {
        if (!this.instance) {
            return;
        }

        let item;

        if (Utils.Instance.isArray(value) && value[0] !== undefined) {
            item = value === null ? null : this.dataSource?.items.find((item) => item.value === value[0]);
        } else {
            item = value === null ? null : this.dataSource?.items.find((item) => item.value === value);
        }

        this.instance.option('value', item);
    }

    /**
     * Sets the data source.
     * 
     * @param {IItemCollectionDataSource<T>} datasource 
     * @memberof RadioButtonGroup
     */
    public setDataSource(dataSource: IItemCollectionDataSource<T>): void {
        this.dataSource = dataSource;

        this.getJQueryElement().dxRadioGroup({
            items: this.dataSource.items
        });
    }


    /**
     * Triggers the onValueChanged event.
     * 
     * @protected
     * @param {(DataSourceItem<T> | null)} value 
     * @memberof RadioButtonGroup
     */
    protected onValueChangedTrigger(value: IDataSourceItem<T> | null): void {
        if (typeof this.onValueChanged === 'function') {
            this.onValueChanged(value);
        }
    }

    /**
     * Gets the radio button group instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof RadioButtonGroup
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }
}