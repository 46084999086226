import { TokenResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Authentication';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { AuthenticationModes } from '../../authentication';
import { HttpResourcePointer } from '../../dataProviders';
import { TokenRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * The GetAuthenticationToken service class.
 *
 * @export
 * @class GetAuthenticationToken
 * @extends {ServiceAction}
 */
export class GetAuthenticationToken extends ServiceAction {

    /**
     * Name of the service action.
     * 
     * 
     * @memberof GetAuthenticationToken
     */
    public name = 'getAuthenticationToken';

    /**
     * Gets the authentication token from the Webservice.
     * Resolves a promise with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {TokenRequestOptions} [tokenRequestOptions] The request options.
     * @returns {(Promise<string>)} A promise which will resolve with the token.
     * @memberof GetAuthenticationToken
     */
    public async do(tokenRequestOptions?: TokenRequestOptions): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const httpResourcePtr = new HttpResourcePointer('GET', this.globalConfig.windreamWebServiceURL + '/authentication/GetAuthenticationToken');
            // Set token if anonymous
            if (tokenRequestOptions && tokenRequestOptions.requestOptions &&
                tokenRequestOptions.requestOptions.authenticationMode === AuthenticationModes.Anonymous && tokenRequestOptions.token) {
                httpResourcePtr.httpHeaders.set('Authorization', 'Bearer ' + tokenRequestOptions.token);
            }

            this.requestExecutor.executeRequest(httpResourcePtr, tokenRequestOptions?.requestOptions)
                .then((response: IServiceResponse<TokenResponseContainerDTO>) => {
                    if (response && response.data && response.data.Token) {
                        resolve(response.data.Token);
                    } else {
                        this.logger.error('GetAuthenticationToken', 'do', 'Missing token in response execute request', response);
                        reject(new Error('Missing token in response execute request'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('GetAuthenticationToken', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}