import { IDataSourceItem, IItemCollectionDataSource } from '..';


/**
 * This class represents a data source for an items based component.
 * 
 * @export
 * @class ItemCollectionDataSource
 * @implements {IItemCollectionDataSource<T>}
 * @template T 
 */
export class ItemCollectionDataSource<T> implements IItemCollectionDataSource<T> {

    private selectedItemField?: IDataSourceItem<T> | undefined;

    /**
     * Gets the selected item.
     * 
     * @type {(IDataSourceItem<T> | undefined)}
     * @memberof ItemCollectionDataSource
     */
    public get selectedItem(): IDataSourceItem<T> | undefined {
        return this.selectedItemField;
    }

    /**
     * Sets the selected item.
     * 
     * @param {IDataSourceItem<T>} item 
     * @memberof ItemCollectionDataSource
     */
    public set selectedItem(item: IDataSourceItem<T> | undefined) {
        this.selectedItemField = item;
    }


    private itemsField: IDataSourceItem<T>[];

    /**
     * Gets the items.
     * 
     * @readonly
     * @type {IDataSourceItem<T>[]}
     * @memberof ItemCollectionDataSource
     */
    public get items(): IDataSourceItem<T>[] {
        return this.itemsField;
    }

    /**
     * Creates an instance of ItemCollectionDataSource.
     *
     * @memberof ItemCollectionDataSource
     */
    public constructor() {
        this.itemsField = new Array<IDataSourceItem<T>>();
    }


    /**
     * Adds the item to the items collection.
     * 
     * @param {IDataSourceItem<T>} item 
     * @memberof ItemCollectionDataSource
     */
    public add(item: IDataSourceItem<T>): void {
        if (!item) {
            throw new ReferenceError('The argument "item" was null or undefined.');
        }

        this.itemsField.push(item);
    }


    /**
     * Gets the item which matches with the given value.
     *
     * @param {T} value
     * @param {(value1: T, value2: T) => boolean} [equalFunction]
     * @returns {(IDataSourceItem<T> | undefined)}
     * @memberof ItemCollectionDataSource
     */
    public getItemByValue(value: T, equalFunction?: (value1: T, value2: T) => boolean): IDataSourceItem<T> | undefined {

        let result: IDataSourceItem<T> | undefined;

        if (this.items) {
            this.items.forEach((item) => {
                if (equalFunction) {
                    if (equalFunction(item.value, value)) {
                        result = item;
                        return;
                    }
                } else {
                    if (item.value === value) {
                        result = item;
                        return;
                    }
                }
            });
        }

        return result;
    }

}