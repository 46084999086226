import { IStyleManager } from '../ui';
import { ILicensePageHelper, ILicenseSeviceHelper, ILicenseTemplateHelper } from './index';

/**
 * This class provides helper functions for the license page.
 *
 * @export
 * @class LicensePageHelper
 * @implements {LicensePageHelper}
 */
export class LicensePageHelper implements ILicensePageHelper {

    private licenseServiceHelper: ILicenseSeviceHelper;
    private licenseTemplateHelper: ILicenseTemplateHelper;
    private targetElement: HTMLDivElement;
    private viewStyleManager: IStyleManager;

    /**
     * Creates an instance of LicensePopupHelper.
     * @param {JQueryStatic} jQueryLib
     * @param {FoundationSites.FoundationSitesStatic} foundationLib
     * @param {Document} currentDocument
     * @param {ILanguageProvider} languageProvider
     * @param {ILicenseSeviceHelper} licenseServiceHelper
     * @param {ILicenseTemplateHelper} licenseTemplateHelper
     * @memberof LicensePageHelper
     */
    public constructor(licenseServiceHelper: ILicenseSeviceHelper,
        licenseTemplateHelper: ILicenseTemplateHelper, targetElement: HTMLDivElement, viewStyleManager: IStyleManager) {
        this.targetElement = targetElement;
        this.licenseServiceHelper = licenseServiceHelper;
        this.licenseTemplateHelper = licenseTemplateHelper;
        this.viewStyleManager = viewStyleManager;
    }

    /**
     * Opens the about page.
     *
     * @param {boolean} [isLoggedIn] Whether the user is logged in.
     * @memberof LicensePageHelper
     */
    public renderAboutPage(isLoggedIn?: boolean): void {
        this.targetElement.innerHTML = '';
        this.viewStyleManager.updateStyle('wd-style-colors-0');
        this.licenseTemplateHelper.renderLicenseContent(this.targetElement, this.licenseServiceHelper, isLoggedIn);
    }
}