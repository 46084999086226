import { MemberResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { ICredentialManager } from '../../authentication/interfaces/iCredentialManager';
import { AuthenticationModes } from '../../authentication/models/authenticationModes';
import { UserDetails } from '../../authentication/userDetails';
import { Utils } from '../../common';
import { GlobalConfig } from '../../config/models';
import { HTTP_RESPONSE_CODES, HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { TokenRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * The login service class.
 *
 * @export
 * @class Login
 * @extends {ServiceAction}
 */
export class Login extends ServiceAction {

    /**
     * Name of the service action.
     * 
     * 
     * @memberof Login
     */
    public name = 'login';


    private credentialManager: ICredentialManager;


    /**
     * Creates an instance of Login.
     *
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global configuration.
     * @param {Logger} logger The logger.
     * @param {ICredentialManager} credentialManager The credential manager.
     * @memberof Login
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, credentialManager: ICredentialManager) {
        super(requestExecutor, globalConfig, logger);

        this.credentialManager = credentialManager;
    }

    /**
     * Execute the authentication of of given user.
     * Resolves a promise with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {TokenRequestOptions} [tokenRequestOptions] The request options.
     * @returns {Promise<UserDetails>} A promise which will resolve with the user details.
     * @memberof Login
     */
    public async do(tokenRequestOptions?: TokenRequestOptions): Promise<UserDetails> {
        return new Promise<UserDetails>((resolve, reject) => {

            const httpResourcePtr = new HttpResourcePointer('GET', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/members/GetMemberDetails');
            // Set token if anonymous
            if (tokenRequestOptions && tokenRequestOptions.requestOptions &&
                (tokenRequestOptions.requestOptions.authenticationMode === AuthenticationModes.Anonymous ||
                    tokenRequestOptions.requestOptions.authenticationMode === AuthenticationModes.MSAL) && tokenRequestOptions.token) {
                httpResourcePtr.httpHeaders.set('Authorization', 'Bearer ' + tokenRequestOptions.token);
            }
            this.requestExecutor.executeRequest(httpResourcePtr, tokenRequestOptions?.requestOptions)
                .then((response: IServiceResponse<MemberResponseContainerDTO>) => {
                    if (!response.data) {
                        return Promise.reject(new Error('No response data set'));
                    }
                    this.extractUserDetails(response.data)
                        .then((userDetails: UserDetails) => {
                            resolve(userDetails);
                        }).catch((err) => {
                            this.logger.error('Login', 'do', err.toString(), err);
                            const errorUserDetails = new UserDetails();
                            errorUserDetails.isValidUser = false;
                            resolve(errorUserDetails);
                        });
                }).catch((err) => {
                    if (err && err.statusCode && err.statusCode === HTTP_RESPONSE_CODES.NOT_AUTHORIZED) {
                        const errorUserDetails = new UserDetails();
                        errorUserDetails.isValidUser = false;
                        resolve(errorUserDetails);
                        return;
                    }
                    this.logger.error('Login', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }


    /**
     * Extracts the user details from the response.
     * 
     * @private
     * @param {MemberResponseContainerDTO} data Webservice response to parse.
     * @returns {Promise<UserDetails>} Promise to resolve with the extracted user details.
     * @async
     * 
     * @memberof Login
     */
    private async extractUserDetails(data: MemberResponseContainerDTO): Promise<UserDetails> {
        return new Promise<UserDetails>((resolve, reject) => {

            const resultObj = data;

            const userDetailsResult: UserDetails = new UserDetails();

            if (!resultObj) {
                reject(new ReferenceError('argument data is null or undefined'));

                this.credentialManager.clearAuthenticatedUser();
                this.credentialManager.clearCredentials();

                return;
            }

            if (!resultObj.Member) {
                reject(new ReferenceError('The property "Member" is null or undefined'));

                this.credentialManager.clearAuthenticatedUser();
                this.credentialManager.clearCredentials();

                return;
            }

            if (!resultObj.Member.MemberIdentifier) {
                reject(new ReferenceError('The property "MemberIdentifier" is null or undefined'));

                this.credentialManager.clearAuthenticatedUser();
                this.credentialManager.clearCredentials();

                return;
            }

            userDetailsResult.isValidUser = Utils.isDefined(resultObj.Member.MemberIdentifier.Name);

            if (userDetailsResult.isValidUser) {
                userDetailsResult.fullUsername = resultObj.Member.MemberIdentifier.FullName;
                userDetailsResult.isAdmin = resultObj.Member.IsAdmin;
                userDetailsResult.userName = resultObj.Member.MemberIdentifier.Name;
                userDetailsResult.domain = resultObj.Member.MemberIdentifier.Domain;
                userDetailsResult.id = resultObj.Member.MemberIdentifier.Id;

                this.credentialManager.setAuthenticatedUser(userDetailsResult);

                resolve(userDetailsResult);
            } else {
                this.credentialManager.clearAuthenticatedUser();
                this.credentialManager.clearCredentials();

                reject(new Error('Invalid user.'));
                return;
            }

        });

    }

}