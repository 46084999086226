import { CLICKPROGRESSION_MODES } from '../../../typings/ui';
import { IMasterTreeView, IMasterTreeViewItem, IMasterTreeViewOptions2 } from './index';

/**
 * DxMasterTreeview class.
 *
 * @export
 * @class DxMasterTreeView
 * @implements {IMasterTreeView<T>}
 * @template T  Datatype of the data each node represents.
 */
export class DxMasterTreeView<T> implements IMasterTreeView<T> {

    /**
     * Callback after context menu was opened.
     *
     * @memberof IMasterTreeView
     */
    public onContextMenuOpen?: (event: any) => void;

    /**
     * Callback after item was clicked.
     *
     * @memberof IMasterTreeView
     */
    public onSingleClick?: (data: IMasterTreeViewItem<T>) => void;

    /**
     * Callback after item was double clicked.
     *
     * @memberof IMasterTreeView
     */
    public onDoubleClick?: (data: IMasterTreeViewItem<T>) => void;

    /**
     * Callback after item was expanded.
     *
     * @memberof IMasterTreeView
     */
    public onLoadNodes?: (data: IMasterTreeViewItem<T>, success: (value?: IMasterTreeViewItem<T>[]) => void, fail: (reason?: any) => void) => void;

    /**
     * Callback after item was collapsed.
     *
     * @memberof DxMasterTreeView
     */
    public onItemCollapsed?: (data: IMasterTreeViewItem<T>) => void;

    /**
     * Callback after item was expanded.
     *
     * @memberof DxMasterTreeView
     */
    public onItemExpanded?: (data: IMasterTreeViewItem<T>) => void;

    /**
     * Callback after item was rendered.
     *
     * @memberof DxMasterTreeView
     */
    public onItemRendered?: (item: HTMLElement) => void;

    /**
     * Callback when item is being loaded.
     *
     * @memberof IMasterTreeView
     */
    public onLoad?: (success: (value?: IMasterTreeViewItem<T>[]) => void, fail: (reason?: any) => void) => void;

    private targetElement: HTMLElement;
    private jQueryStatic: JQueryStatic;
    private instance?: DevExpress.ui.dxTreeView;
    private options: IMasterTreeViewOptions2;
    private readonly doubleClickPrevTime = 350;
    private clickCount = 0;
    private singleClickTimer: any;
    private isInitialized: boolean = false;


    /**
     * Creates an instance of DxMasterTreeView.
     *
     * @param {HTMLElement} targetElement The target element.
     * @param {JQueryStatic} jQueryStatic JQuery.
     * @memberof DxMasterTreeView
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;
        this.options = {} as IMasterTreeViewOptions2;
    }


    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof DxMasterTreeView
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('disabled', isDisabled);
        }
    }

    /**
     * Sets the item template for this tree instance.
     *
     * @param {(itemData: IMasterTreeViewItem<T>) => void} itemTemplate The item template.
     * @memberof DxMasterTreeView
     */
    public setItemTemplate(itemTemplate: (itemData: IMasterTreeViewItem<T>) => void) {
        if (this.instance) {
            this.instance.option('itemTemplate', itemTemplate);
        } else {
            throw new Error('The tree view instance is undefined. Please call the bootstrap() method first.');
        }
    }

    /**
     * Bootstraps a tree view using DevExtreme.
     *
     * @memberof DxMasterTreeView
     */
    public bootstrap(): void {
        if (this.instance) {
            this.instance.dispose();
        }
        this.targetElement.classList.add('wd-master-tree');
        this.instance = this.getJQueryElement().dxTreeView({
            createChildren: async (parentNode: DevExpress.ui.dxTreeView.Node<T>) => {
                if (!parentNode) {
                    return new Promise<any>((resolve, reject) => {
                        if (this.onLoad) {
                            this.onLoad(resolve, reject);
                        }
                    });
                } else if (this.isInitialized) {
                    return new Promise<any>((resolve, reject) => {
                        if (parentNode.children && parentNode.children.length > 0) {
                            parentNode.children = [];
                        }
                        if (this.onLoadNodes && parentNode.itemData) {
                            this.onLoadNodes(parentNode.itemData, resolve, reject);
                        }
                    });
                }
            },
            dataStructure: 'plain',
            expandedExpr: 'isExpanded',
            focusStateEnabled: true,
            hasItemsExpr: 'hasChildren',
            itemTemplate: (itemData: IMasterTreeViewItem<T>) => {
                const result = document.createElement('div');
                result.classList.add('wd-treeview-item');
                result.classList.add('wd-ellipsis');
                result.classList.add('wd-drag-root-area');
                result.classList.add('wd-drag-area');
                result.setAttribute('title', itemData.name || '');
                result.setAttribute('draggable', 'true');
                if (itemData && typeof itemData.id !== 'undefined') {
                    result.setAttribute('id', itemData.id.toString());
                }

                const folderIcon = document.createElement('span');
                folderIcon.classList.add('wd-icon');
                folderIcon.classList.add('folder');
                folderIcon.classList.add('wd-drag-area');
                result.appendChild(folderIcon);

                const itemName = document.createElement('span');
                itemName.classList.add('wd-drag-area');
                itemName.innerText = itemData && itemData.name ? itemData.name : '';
                result.appendChild(itemName);

                return result;
            },
            onContentReady: () => {
                this.isInitialized = true;
            },
            onItemClick: (event) => {
                if (event && event.component && event.itemData && event.itemElement) {
                    // Stop internal DevExtreme event handling, so that we can expand programmatically. Required as doubleClick would always work and we want to configure this behavior.
                    if (event.event && typeof event.event.stopPropagation === 'function') {
                        event.event.stopPropagation();
                    }

                    const doubleClickCount = 2;
                    this.clickCount++;
                    if (this.clickCount === 1) {
                        this.singleClickTimer = setTimeout(() => {
                            // Single click
                            this.clickCount = 0;
                            // Pubsub
                            if (this.onSingleClick && event.itemData) {
                                this.onSingleClick(event.itemData);
                            }
                            // Only expand node if configured this way
                            if (this.options.clickProgression === CLICKPROGRESSION_MODES.SINGLE) {
                                // If already expanded, collapse it; otherwise, expand it
                                if (event.node?.expanded) {
                                    event.component?.collapseItem(event.itemElement);
                                } else {
                                    event.component?.expandItem(event.itemElement);
                                }
                            }
                        }, this.doubleClickPrevTime);
                    } else if (this.clickCount === doubleClickCount) {
                        // Double click
                        clearTimeout(this.singleClickTimer);
                        this.clickCount = 0;
                        // Pubsub
                        if (this.onDoubleClick) {
                            this.onDoubleClick(event.itemData);
                        }
                        // Only expand node if configured this way
                        if (this.options.clickProgression === CLICKPROGRESSION_MODES.DOUBLE) {
                            // If already expanded, collapse it; otherwise, expand it
                            if (event.node?.expanded) {
                                event.component?.collapseItem(event.itemElement);
                            } else {
                                event.component?.expandItem(event.itemElement);
                            }
                        }
                    }
                }
            },
            onItemCollapsed: (event) => {
                if (event && event.itemData) {
                    if (this.onItemCollapsed) {
                        this.onItemCollapsed(event.itemData);
                    }
                }
            },
            onItemExpanded: (event) => {
                if (event && event.itemData) {
                    if (this.onItemExpanded) {
                        this.onItemExpanded(event.itemData);
                    }
                }
            },
            onItemContextMenu: (event: DevExpress.ui.dxTreeView.ItemContextMenuEvent) => {
                this.showContextMenu(event);
            },
            rootValue: this.options.rootNodeId,
            selectByClick: true,
            selectedExpr: 'isSelected',
            selectionMode: 'single',
            virtualModeEnabled: true,
            useNativeScrolling: true
        }).dxTreeView('instance');
    }

    /**
     *  Sets the TreeView options.
     *
     * @param {IMasterTreeViewOptions2} options The options for the master tree.
     * @memberof DxMasterTreeView
     */
    public async setOptions(options: IMasterTreeViewOptions2): Promise<void> {
        if (!this.options) {
            throw new ReferenceError('The options were not set.');
        }
        this.options = options;
        return Promise.resolve();
    }

    /**
     * Expands the node.
     *
     * @param {number} nodeId The unique node id.
     * @memberof DxMasterTreeView
     */
    public expandNode(nodeId: number): void {
        this.instance?.expandItem(nodeId);
    }

    /**
     * Collapses the node.
     *
     * @param {number} nodeId The unique node id.
     * @memberof DxMasterTreeView
     */
    public collapseNode(nodeId: number): void {
        this.instance?.collapseItem(nodeId);
    }

    /**
     * Sets up context menu by invoking onContextMenuOpen callback.
     *
     * @private
     * @param {*} event DX event.
     * @memberof DxMasterTreeView
     */
    private showContextMenu(event: any): void {
        if (event && event.itemData) {
            if (this.onContextMenuOpen) {
                this.onContextMenuOpen(event);
            }
        }
    }

    /**
     * Gets the related jQuery element.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} The JQuery element.
     * @memberof TagBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.targetElement) {
            throw new ReferenceError('The target element was not set yet.');
        }
        return this.jQueryStatic(this.targetElement);
    }


    /**
    * Destroys the DxMasterTreeView instance, cleaning up resources.
    * 
    * @memberof DxMasterTreeView
    */
    public destroy(): void {
        if (this.instance) {
            this.instance.dispose();
        }
        this.targetElement.classList.remove('wd-master-tree');
        this.instance = undefined;
        this.isInitialized = false;
    }
}