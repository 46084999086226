import { ICredentialManager } from '../../authentication/interfaces/iCredentialManager';
import { GlobalConfig } from '../../config/models';
import { HttpResourcePointer } from '../../dataProviders/index';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { ServiceAction } from '../serviceAction';


/**
 * The logut service class.
 * 
 * @export
 * @class Logout
 * @extends {ServiceAction}
 */
export class Logout extends ServiceAction {

    /**
     * Name of the service action.
     * 
     * 
     * @memberof Logout
     */
    public name = 'logout';


    private credentialManager: ICredentialManager;


    /**
     * Creates an instance of Logout.
     *
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @param {ICredentialManager} credentialManager 
     * @memberof Logout
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, credentialManager: ICredentialManager) {
        super(requestExecutor, globalConfig, logger);

        this.credentialManager = credentialManager;
    }

    /**
     * Logouts the current user.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @returns {Promise<void>} A promise which will resolve if the logout is successful.
     * @memberof Logout
     */
    public async do(): Promise<void> {
        return new Promise<void>((resolve) => {
            const resourcePointer = new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/authentication/Logout');
            this.requestExecutor.executeRequest(resourcePointer, { preventFetchFromCache: true }).catch((error) => {
                this.logger.error('Logout', 'do', 'Failed to logout at web service', error);
            });
            this.credentialManager.clearAuthenticatedUser();
            // Callback instantly so that the logout is without delay
            resolve();
        });
    }
}