/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable capitalized-comments */
/// <reference path="../../node_modules/devextreme-dist/ts/dx.all.d.ts" />
/// <reference path="../../framework/node_modules/foundation-sites/dist/js/foundation.d.ts" />

import { DWCore } from '@windream/dw-core/dwCore';
import { DynamicWorkspace, MigrateFunction, ModuleRegistrationType } from 'typings';
import { Workbox } from 'workbox-window';
import { AjaxHandler } from './ajaxHandler/ajaxHandler';
import { IServiceResponse } from './ajaxHandler/interfaces/iServiceResponse';
import {
    AnonymousAuthenticationProvider, AuthenticationManager, AuthenticationModes, BasicHttpAuthenticationProvider, CredentialManager, IAuthenticationManager, ICredentialManager,
    UserDetails, WindreamTokenHttpAuthenticationProvider, WindowsAuthenticationProvider, WindreamTokenProvider
} from './authentication/index';
import { MsalTokenHttpAuthenticationProvider } from './authentication/msalTokenAuthentication';
import { Cache, CacheHashHandler, ICache, IMetadataStore, WindreamMetadataStore } from './caching';
import { DeviceDetection, DirectScriptExecutor, Utils, WindreamScriptExecutor } from './common';
import { ComponentInitializer, ComponentManager, ComponentLoader, IComponentManager, ComponentInjector, ComponentLoadManifestFactory } from './components';
import {
    ApplicationConfig, ComponentNameProvider, ConfigLoader, ConfigManager, CustomConfig, GlobalConfig, IConfigLoader, IConfigManager,
    IUserConfigManager, SystemConfig, ToolbarConfig, UserConfigManager, ViewConfig, ViewConfigMetaData
} from './config';
import { ContextMenuDataWebServiceHandler, ContextMenuItemValidator, WindreamContextMenuCreator } from './contextMenu';
import { CultureHelper } from './culture';
import { ConfigDataProvider, IResourcePointer, RequestOptions } from './dataProviders';
import { WindreamDataProvider } from './dataProviders';
import { IRequestExecutor } from './dataProviders/interfaces/iRequestExecutor';
import { RequestExecutor } from './dataProviders/requestExecutor';
import { ITemplateExtension, PublicApi } from './dynamicWorkspace';
import { EncryptionHelper } from './encryption/index';
import {
    ContextMenuExtension, ExtensionProvider, IExtensionProvider, ILoaderExtension, IndexMaskExtension,
    IStorageExtension, LoaderExtension, TemplateExtension, ToolbarExtension, UploadExtension, UserConfigExtension
} from './extensions';
import { StorageExtension } from './extensions/storageExtension';
import { CoreProvider } from './externalCore/coreProvider';
import { ExternalCoreError } from './externalCore/externalCoreError';
import { ExternalCoreErrorCode } from './externalCore/externalCoreErrorCode';
import { HotFix } from './hotfix';
import { IIndexedDBWrapper, IndexedDBWrapper } from './indexedDB';
import { IndexedDBWrapperFactory } from './indexedDB/indexedDBWrapperFactory';
import { IMultilingualTranslator, LanguageManager, MultilingualTranslator } from './language';
import { BrowserInspector } from './language/browserInspector';
import { IBrowserInspector } from './language/interfaces/iBrowserInspector';
import { LicensePageHelper, LicenseServiceHelper, LicenseTemplateHelper } from './license/index';
import { Changes, ComponentLifecycleManagerFactory, IComponentLifecycleManagerFactory, IViewLifecycleManagerFactory, ViewLifecycleManagerFactory } from './lifecycle';
import {
    ILayoutManagerFactory, ISubViewManager,
    IUiManager, IViewManager, LayoutManagerFactory, ModuleRegistrationHelper, SubViewManager, UiManager, ViewManager, ViewInitializationOptions
} from './loader';
import { SelectionManager, ViewStyleManager } from './loader';
import { JSNLogger, Logger, LogLevel } from './logging';
import { AuthenticationTypes, BaseLoginMask, LoginManager, LogoutPageHelper, WindreamLoginMask } from './login/index';
import { MSALLoginMask } from './login/msalLoginMask';
import { MemoryManager } from './memoryManager/memoryManager';
import { IdentityChangedHandler } from './messages/events';
import { IMessageBus } from './messages/iMessageBus';
import { MessageBus } from './messages/messageBus';
import { MessageProvider } from './messages/messageProvider';
import { PublicApi as PublicApiNew } from './publicApi';
import { AddEnableDisablePubSubOperation, IPubSubHandler, IPubSubHandlerFactory, PubSubHandlerFactory } from './pubSub';
import { AboutRouter, AuthCallbackRouter, EditRouter, RootRouter, RouteManager, RoutingUtils, SubViewRouter, ViewNavigationHandlerFactory, ViewRouter } from './router';
import { SettingsRouter } from './router/settingsRouter';
import { SystemViewRouter } from './router/systemViewRouter';
import { GetGlobalSharedSettings, GetStorageToken, GetSystemDetails, IServiceManager, ViewRequestOptions } from './services';
import { Login, Logout } from './services/authentication';
import { GetAuthenticationToken } from './services/authentication/getAuthenticationToken';
import { ServiceManager } from './services/serviceManager';
import { SharedSettingsLoader } from './shared/sharedSettingsLoader';
import { SharedSettingsProvider } from './shared/sharedSettingsProvider';
import { GlobalIdentitySharer, IIntentManager, IntentManager, MoveShareBehavior, ShareIntentHandler } from './sharing';
import { StaticPageHelper } from './staticPage';
import { LocalStorageWrapper } from './storage/localStorageWrapper';
import { StorageManager } from './storage/storageManager';
import { DxThemeBackend, IThemeBackend, ThemeManager } from './themeManager';
import { ToolbarActionLoader } from './toolbar/toolbarActionLoader';
import { InstantTransitionTriggerHandler, PopupTransitionTriggerHandler, TriggerHandler } from './triggerHandler';
import {
    ActiveBarHelperFactory, AppBarHandler, FileTransferManagerPopOverHandler, AppMenuHandlerFactory, ConfigUiHelper, DeviceMenuHandler, FoundationPanelHandler,
    IActiveBarHelperFactory, IAppBarHandler, IFileTransferManagerPopOverHandler, IConfigUiHelper, IPopupHelper, ISettingsHelper, ISkeletonHelper,
    IStyleManager, MenuBarHandlerFactory, NotificationHelper, PopupHelper, ProfileSettingsHelper, PubSubUiHelper, SettingsHelper, SkeletonHelper,
    SubViewNavigationHandlerFactory, ToolstripHandler, BaseFileTransferManagerHandler, FileTransferManagerHandler
} from './ui';
import { IUiComponentFactory } from './ui/components';
import { UiComponentFactory } from './ui/components/uiComponentFactory';
import { Version } from './version';
import { AppDownloadHandler, AppFocusHandler, AppIndexIntentHandler, IAppDownloadHandler, IAppFocusHandler, IWebBridgeHandler, WebBridgeEventTypes, WebBridgeHandler } from './webBridge';
import { IWebsocketProvider, WebsocketFactory, WindreamWebsocketProvider } from './websocket';


/**
 * Main class of the application.
 *
 * @export
 * @class Application
 */
export class Application {

    public name: string = 'Dynamic Workspace';

    /**
     * Determines whether an external core was loaded.
     *
     * @static
     * @returns {boolean} Whether an external core was loaded.
     * @memberof Application
     */
    public static hasExternalCore(): boolean {
        return !!(DynamicWorkspace && DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core);
    }

    private className = 'Application';
    private jQueryRef: JQueryStatic;
    private globalConfig!: GlobalConfig;
    private toolbarConfig!: ToolbarConfig;
    private applicationConfig?: ApplicationConfig;
    private readonly isHeaderLess: boolean = false;
    private systemConfig?: SystemConfig | null;
    private cultureHelper: CultureHelper;
    private rootElement: HTMLDivElement;
    private storedViewConfigs: Map<string, ViewConfig>;
    private currentlyDisplayedViewConfig?: ViewConfig;
    private identityChangedHandler: IdentityChangedHandler;

    // Hashing + Encryption
    private cacheHashHandler: CacheHashHandler;
    private encryptionHelper: EncryptionHelper;

    // StaticPageHelper
    private staticPageHelper!: StaticPageHelper;

    // RequestExecutor
    private requestExecutor: IRequestExecutor;


    private readonly coreProvider?: CoreProvider;

    // Authentication
    private authenticationManager!: IAuthenticationManager;
    private credentialManager: ICredentialManager;
    private loginManager?: LoginManager;
    private loginProvider?: DWCore.Authentication.LoginProvider;

    // Logging
    private logger: Logger;

    // IndexedDB
    private indexedDBWrapper: IIndexedDBWrapper;

    // Profile
    private profileSettingsHelper?: ProfileSettingsHelper;
    // Memory
    private memoryManager!: MemoryManager;

    // LifecycleManager
    private viewLifecycleManagerFactory!: IViewLifecycleManagerFactory;
    private componentLifecycleManagerFactory!: IComponentLifecycleManagerFactory;
    private extensionProvider: IExtensionProvider;

    // PubSub
    private pubSubHandlerFactory!: IPubSubHandlerFactory;
    private applicationPubSubHandler!: IPubSubHandler;

    // Loader
    private subViewManager!: ISubViewManager;
    private uiManager: IUiManager;
    private componentLoader!: ComponentLoader;
    private viewManager!: IViewManager;
    private layoutManagerFactory!: ILayoutManagerFactory;
    private componentManager!: IComponentManager;
    private componentInitializer?: ComponentInitializer;
    private componentLoadManifestFactory?: ComponentLoadManifestFactory;

    // Language
    private languageManager!: LanguageManager;
    private browserInspector: IBrowserInspector;
    private translator: IMultilingualTranslator;

    // Config
    private configLoader?: IConfigLoader;
    private configManager!: IConfigManager;
    private userConfigManager!: IUserConfigManager;

    // Cache
    private cache: ICache;
    private metadataStore!: IMetadataStore;

    // Websocket
    private indexMaskWebSocketHandler!: IWebsocketProvider;

    // WebBridge
    private webBridgeHandler: IWebBridgeHandler;
    private appFocusHandler: IAppFocusHandler;
    private appDownloadHandler: IAppDownloadHandler;

    // Ui
    private configUiHelper!: IConfigUiHelper;
    private popupHelper: IPopupHelper;
    private settingsHelper!: ISettingsHelper;
    private appBarHandler!: IAppBarHandler;
    private activeBarHelperFactory!: IActiveBarHelperFactory;
    private skeletonHelper: ISkeletonHelper;
    private uiComponentFactory: IUiComponentFactory;
    private fileTransferManagerHandler!: BaseFileTransferManagerHandler;
    private fileTransferManagerPopOverHandler!: IFileTransferManagerPopOverHandler;
    private licensePopupHelper?: LicensePageHelper;

    // Scripting
    private windreamScriptExecutor!: WindreamScriptExecutor;
    private directScriptExecutor!: DirectScriptExecutor;

    // Services
    private serviceManager!: IServiceManager;

    // Theming
    private themeBackend: IThemeBackend;
    private themeManager!: ThemeManager;

    // Sharing
    private intentManager: IIntentManager;

    // Shared
    private sharedSettingsProvider?: SharedSettingsProvider;

    private localStorageWrapper: LocalStorageWrapper;
    private viewStyleManager: IStyleManager;

    private isUserLoggedIn: boolean;

    // Router
    private authCallbackRouter?: AuthCallbackRouter;
    private viewRouter?: ViewRouter;
    private rootRouter?: RootRouter;
    private editRouter?: EditRouter;
    private aboutRouter?: AboutRouter;
    private settingsRouter?: SettingsRouter;
    private systemViewRouter?: SystemViewRouter;
    private configInClosingState?: boolean;

    private moduleRegistrationHelper: ModuleRegistrationHelper;

    // Toolbar
    private toolbarActionLoader!: ToolbarActionLoader;

    // User
    private coreUser?: DWCore.Member.User;

    // Messages
    private messageBus?: IMessageBus;
    private currentView: string = '';

    /**
     * Creates an instance of Application.
     *
     * @param {JQueryStatic} jQueryRef The jQuery reference.
     * @param {CoreProvider} coreProvider The core provider.
     * @memberof Application
     */
    public constructor(jQueryRef: JQueryStatic, coreProvider?: CoreProvider) {
        this.jQueryRef = jQueryRef;
        this.coreProvider = coreProvider;
        this.storedViewConfigs = new Map<string, ViewConfig>();
        this.moduleRegistrationHelper = new ModuleRegistrationHelper();
        this.isUserLoggedIn = false;
        this.encryptionHelper = new EncryptionHelper();
        this.cacheHashHandler = new CacheHashHandler(window, document, this.encryptionHelper);
        this.localStorageWrapper = new LocalStorageWrapper();
        this.identityChangedHandler = new IdentityChangedHandler();
        require('core-js/shim');
        // Logging
        this.logger = new Logger(LogLevel.Debug, new JSNLogger(), console);

        // Setup routing
        RoutingUtils.init(window);
        RouteManager.init(window, document, this.logger);
        this.setupRouting();

        this.webBridgeHandler = new WebBridgeHandler(this.logger, window);
        this.appFocusHandler = new AppFocusHandler(this.webBridgeHandler, this.logger);
        this.appDownloadHandler = new AppDownloadHandler(this.webBridgeHandler, this.logger);

        // Ui component factory
        this.uiComponentFactory = new UiComponentFactory($, this.logger, window);
        // PopupHelper
        // @ts-ignore - Ignore because of window usage
        this.popupHelper = new PopupHelper(window['$'], window['Foundation'], this.logger, NotificationHelper, this.uiComponentFactory);
        this.registerUnexpectedErrorHandler();
        this.registerUnloadHandler();
        // CultureHelper
        this.cultureHelper = new CultureHelper();
        this.browserInspector = new BrowserInspector(this.logger, navigator);
        const languageCodeOffset = 2;
        const browserLanguage = this.browserInspector.getBrowserLanguage().substr(0, languageCodeOffset);
        this.cultureHelper.setBrowserLanguage(browserLanguage);
        this.setLanguageTag(browserLanguage);
        this.translator = new MultilingualTranslator(this.logger, this.cultureHelper);

        // IndexedDB
        this.indexedDBWrapper = new IndexedDBWrapper(this.logger, 'DynamicWorkspace');

        // Loader
        this.uiManager = new UiManager(document.body, this.logger, $);

        this.skeletonHelper = new SkeletonHelper(this.logger, this.translator);
        this.extensionProvider = new ExtensionProvider();
        // Set up Template Extension
        const templateExtension = new TemplateExtension();
        this.extensionProvider.setExtension(templateExtension);
        // Authentication
        this.credentialManager = new CredentialManager();

        // Cache
        this.cache = new Cache(this.logger, this.encryptionHelper, document.location.origin);

        // TODO: Set indexedDB via DI or function
        this.cache.indexedDB = this.indexedDBWrapper;

        // RequestExecutor
        this.requestExecutor = new RequestExecutor(this.logger);
        this.requestExecutor.addDataProvider(new ConfigDataProvider(this.logger, new AjaxHandler(this.logger)));
        this.requestExecutor.addDataProvider(new WindreamDataProvider(this.logger, new AjaxHandler(this.logger)));
        this.requestExecutor.setCache(this.cache);

        this.intentManager = new IntentManager();

        const rootElement = document.querySelector<HTMLDivElement>('#root');
        if (rootElement) {
            this.rootElement = rootElement;
            if (DeviceDetection.isMobile()) {
                this.rootElement.classList.add('wd-mobile');
            }
        } else {
            this.logger.fatal(this.className, 'constructor', 'No element with ID `root` found.');
            throw new Error('No element with ID `root` found.');
        }
        this.viewStyleManager = new ViewStyleManager(this.rootElement);

        // Theming
        window.DevExpress.ui.themes.current('material.blue.light');
        this.themeBackend = new DxThemeBackend();

        if (RoutingUtils.getQueryParameter('header') === '0' || DeviceDetection.isApp()) {
            this.isHeaderLess = true;
            const bodyElement = document.querySelector('body') as HTMLElement;
            if (bodyElement) {
                bodyElement.classList.add('wd-frameless');
            }
        }


        // Manager to clear indexDB and localStorage
        this.memoryManager = new MemoryManager(this.logger);
        this.memoryManager.addIndexedDB(this.indexedDBWrapper);

        // Lifecycle
        this.componentLifecycleManagerFactory = new ComponentLifecycleManagerFactory(this.logger, this.uiManager, this.translator);
        this.viewLifecycleManagerFactory = new ViewLifecycleManagerFactory(this.skeletonHelper, this.logger);
        // Service worker
        this.setupServiceWorker();
    }


    /**
     * Initializes the application.
     *
     * @returns {*}  {Promise<void>}.
     * @memberof Application
     */
    public init(): Promise<void> {
        return new Promise<void>(async (resolve) => {

            const methodName = 'init';

            this.componentLoadManifestFactory = new ComponentLoadManifestFactory();

            // Config
            this.configLoader = new ConfigLoader(this.requestExecutor, this.logger, this.translator, this.componentLoadManifestFactory);

            // Load configs
            this.globalConfig = await this.configLoader.loadGlobalConfig();
            this.toolbarConfig = await this.configLoader.loadToolbarConfig();

            if (this.globalConfig.useExternalCore) {
                // External core
                await this.setupExternalCore();
            }
            this.configLoader.updateInternalComponents();

            const lastLocalCacheClear = this.localStorageWrapper.getItem('DynamicWorkspace-LastLocalCacheReset');
            if (lastLocalCacheClear) {
                this.cacheHashHandler.expandHashingMessage(lastLocalCacheClear);
            }

            HotFix.runOnStart();
            require('../sass/main.scss');

            // @ts-ignore - Ignore because of window usage
            if (typeof window['$'] === 'undefined') {
                // eslint-disable-next-line no-console
                console.error('jQuery not found in window');
            }
            // @ts-ignore - Ignore because of window usage
            if (typeof window['Foundation'] === 'undefined') {
                // eslint-disable-next-line no-console
                console.error('Foundation not found in window');
            }

            this.webBridgeHandler.subscribe('Framework', WebBridgeEventTypes.ClearCache, () => {
                if (this.memoryManager) {
                    this.memoryManager.clear().then(() => {
                        this.webBridgeHandler.publish(WebBridgeEventTypes.CacheCleared, 'cleared');
                    }).catch((error) => this.logger.error(this.className, methodName, 'Unable to reset all caches', error));
                } else {
                    this.webBridgeHandler.publish(WebBridgeEventTypes.CacheCleared, 'cleared');
                }
            });
            this.webBridgeHandler.subscribe('Framework', WebBridgeEventTypes.Navigate, (viewId) => {
                if (typeof viewId === 'string') {
                    RouteManager.navigate(ViewRouter.generateNavigationUrlForViewId(viewId), false);
                }
            });
            this.webBridgeHandler.subscribe('Framework', WebBridgeEventTypes.NavigateStateless, (viewId) => {
                if (typeof viewId === 'string') {
                    RouteManager.navigate(ViewRouter.generateNavigationUrlForViewId(viewId), true);
                }
            });
            this.webBridgeHandler.subscribe('Framework', WebBridgeEventTypes.Refresh, (changes: Changes) => {
                if (this.viewManager) {
                    this.viewManager.promoteChanges(changes);
                    this.logger.debug(this.className, 'constructor', 'Refreshing components triggered by WebBridge', changes);
                }
            });

            // Initialize IndexDB
            const success = await this.indexedDBWrapper.initializeDB('DynamicWorkspaceCache', 'hash').catch((err: Error) => {
                this.logger.debug(this.className, methodName, 'Unable to intialize IndexedDB', err);
                this.cache.indexedDB = null;
            });
            if (!success) {
                this.cache.indexedDB = null;
            }

            if (!Utils.Instance.isDefined(this.globalConfig.windreamWebServiceURL)) {
                this.logger.fatal(this.className, methodName, 'No web service URL defined in GlobalConfig', this.globalConfig);
            }
            let logLevel = this.globalConfig.logLevel;
            if (!logLevel) {
                logLevel = LogLevel.Error;
                this.logger.debug(this.className, methodName, 'No log level set in GlobalConfig, using default', this.globalConfig);
            }
            this.logger.setLogLevel(logLevel);
            this.cultureHelper.setAvailableLanguages(this.globalConfig.languages);
            this.requestExecutor.setLanguage(this.cultureHelper.getCurrentLanguage());
            // Publish the current language which has to be used in app.
            this.webBridgeHandler.publish(WebBridgeEventTypes.CurrentLanguage, JSON.stringify({
                availableLanguages: this.cultureHelper.availableLanguages,
                currentLanguage: this.cultureHelper.getCurrentLanguage()
            }));

            // This.generateNewApi();
            this.systemConfig = await this.configLoader.loadSystemConfig().catch((err: Error) => {
                this.logger.error(this.className, methodName, 'Failed to load SystemConfig', err);
                return Promise.reject(err);
            });
            this.extensionProvider.setExtension(new StorageExtension(this.logger, new StorageManager(this.localStorageWrapper), new IndexedDBWrapperFactory()));
            this.memoryManager.addStorage(this.localStorageWrapper);
            const lastResetKey = 'DynamicWorkspace-LastCacheReset';
            let currentLastReset: number | null = null;
            const currentStorageValue = this.localStorageWrapper.getItem(lastResetKey);
            if (currentStorageValue) {
                currentLastReset = parseInt(currentStorageValue, 10);
            }
            let lastResetDate: number | null = null;
            if (currentLastReset) {
                try {
                    lastResetDate = new Date(currentLastReset).getTime();
                } catch (err) {
                    lastResetDate = null;
                    this.logger.error(this.className, methodName, 'Unable to parse last reset date: ' + currentLastReset, err);
                }
            }

            if (this.systemConfig && this.systemConfig.lastCacheReset) {
                const systemConfigLastReset = this.systemConfig.lastCacheReset.getTime();
                const lastResetTimeString = systemConfigLastReset.toString();
                if (lastResetDate && systemConfigLastReset > lastResetDate) {
                    // Only change hashing if it's new otherwise the previous hash is still valid (same reset time)
                    this.cacheHashHandler.expandHashingMessage(lastResetTimeString);
                    this.localStorageWrapper.setItem(lastResetKey, lastResetTimeString);
                    this.memoryManager.clear().then(() => {
                        this.localStorageWrapper.setItem(lastResetKey, lastResetTimeString);
                        if (location) {
                            // @ts-ignore - The typings don't know any parameters for reload()...
                            location.reload(true);
                        }
                    }).catch((error) => this.logger.error(this.className, methodName, 'Unable to reset all caches', error));
                    return Promise.reject(new Error('Cache reset'));
                }
            }

            // this.generateNewApi();
            await this.loadUserLanguage();

            // Publish the current language which has to be used in app.
            DevExpress.localization.locale(this.cultureHelper.getCurrentLanguage());
            this.webBridgeHandler.publish(WebBridgeEventTypes.CurrentLanguage, JSON.stringify({
                availableLanguages: this.cultureHelper.availableLanguages,
                currentLanguage: this.cultureHelper.getCurrentLanguage()
            }));

            this.metadataStore = new WindreamMetadataStore(this.globalConfig, this.requestExecutor, this.cultureHelper);

            // Setup the authentication manager.
            let authenticationMode = AuthenticationModes.Basic;
            if (this.globalConfig.authenticationMode && this.globalConfig.authenticationMode !== authenticationMode) {
                if (DeviceDetection.isApp()) {
                    authenticationMode = AuthenticationModes.JWT;
                } else {
                    authenticationMode = this.globalConfig.authenticationMode;
                }
            }
            this.authenticationManager = new AuthenticationManager(authenticationMode);
            this.authenticationManager.addAuthenticationProvider(new AnonymousAuthenticationProvider());
            this.authenticationManager.addAuthenticationProvider(new BasicHttpAuthenticationProvider(this.credentialManager));
            this.authenticationManager.addAuthenticationProvider(new WindowsAuthenticationProvider());
            this.authenticationManager.setCurrentAuthenticationMode(authenticationMode);
            // Set the authentication manager to the request executor.
            this.requestExecutor.setAuthenticationManager(this.authenticationManager);

            // Logging
            this.logger.info(this.className, methodName, 'Global Config', this.globalConfig);

            // Config
            this.configManager = new ConfigManager(this.requestExecutor, this.globalConfig);

            let allComponents: string[] = [];

            if (Application.hasExternalCore() && DynamicWorkspace.Extensions.core.componentProvider) {
                allComponents = await DynamicWorkspace.Extensions.core.componentProvider.getAllComponents();
            } else {
                allComponents = this.globalConfig.availableComponents;
            }
            allComponents = allComponents.concat(this.configLoader.getInternalComponents());

            this.languageManager = new LanguageManager(this.requestExecutor, this.cultureHelper.getCurrentLanguage(),
                allComponents, this.logger, this.translator, $);
            this.toolbarActionLoader = new ToolbarActionLoader(this.configLoader, this.languageManager, this.logger, document, document.body,
                this.moduleRegistrationHelper, this.toolbarConfig, this.uiComponentFactory);
            this.extensionProvider.setExtension(new ToolbarExtension(this.toolbarActionLoader));

            await this.languageManager.init().catch(async (err: any) => {
                this.logger.fatal(this.className, methodName, err);
                this.rootElement.innerHTML = require('./staticPage/templates/fatalError.html');
                this.uiManager.appIsAvailable();
                return Promise.reject(err);
            });
            this.assert(this.languageManager, 'LanguageManager');
            this.assert(this.globalConfig, 'GlobalConfig');
            this.assert(this.metadataStore, 'MetadataStore');
            const frameworkLanguageProvider = this.languageManager.getLanguageProvider('framework');

            this.serviceManager = new ServiceManager(this.requestExecutor, this.globalConfig,
                this.logger, this.metadataStore, this.popupHelper, frameworkLanguageProvider, this.extensionProvider, this.identityChangedHandler);
            this.popupHelper.setServiceManager(this.serviceManager);

            const appIndexIntentHandler = new AppIndexIntentHandler(this.webBridgeHandler, this.logger, this.popupHelper, this.serviceManager);
            appIndexIntentHandler.init();

            this.staticPageHelper = new StaticPageHelper(frameworkLanguageProvider, this.rootElement);
            // Scripting
            this.directScriptExecutor = new DirectScriptExecutor(this.logger, window, frameworkLanguageProvider);

            // Script to be loaded before the loggin appears.
            const dwApplicationOnLoadPath = '/scripts/DWApplicationOnLoad.js';

            /**
             * Handles the script before the loggin appears.
             *
             * @param {DirectScriptExecutor} directScriptExecutor Called to handle scope problems.
             * @returns {Promise<void>} {Promise <void>}.
             * @memberof Application
             */
            const dwApplicationOnLoad = (directScriptExecutor: DirectScriptExecutor): Promise<void> => {
                return new Promise(async (resolve, reject) => {
                    const scriptResponse: Response = await fetch(dwApplicationOnLoadPath);

                    if (scriptResponse.ok) {
                        const scriptText = await scriptResponse.text();
                        directScriptExecutor.execute(scriptText, undefined, resolve);
                    } else {
                        reject(new Error('The script "DWApplicationOnLoad.js" was not found'));
                    }
                });
            };
            try {
                await dwApplicationOnLoad(this.directScriptExecutor);
            } catch (error) {
                this.logger.fatal('application', 'init', 'Failed to load DWApplicationOnLoad.js', error);
                this.staticPageHelper.renderError(require('./staticPage/templates/dwApplicationOnLoadFetchError.html'));
                this.uiManager.appIsAvailable();
                return;
            }

            this.windreamScriptExecutor = new WindreamScriptExecutor(this.serviceManager.getServices().DynamicWorkspace.getContextMenuScript,
                this.serviceManager.getServices().DynamicWorkspace.getScriptContent, this.logger, this.directScriptExecutor);

            this.themeManager = new ThemeManager(document, frameworkLanguageProvider, (this.extensionProvider.getExtension('storage') as IStorageExtension), this.logger, this.themeBackend);
            this.themeManager.registerThemes(this.globalConfig.availableThemes);
            this.themeManager.loadTheme();

            await this.loadCustomTheme();

            this.cultureHelper.init(frameworkLanguageProvider);
            const theme = this.themeManager.getTheme();
            if (theme.customLoadingAnimationHtml) {
                this.uiManager.setCustomLoadingAnimation(theme.customLoadingAnimationHtml);
            }
            this.uiManager.setLanguageProvider(frameworkLanguageProvider);
            this.uiComponentFactory.setCultureHelper(this.cultureHelper);
            this.uiComponentFactory.setLanguageProvider(frameworkLanguageProvider);

            // Licenses
            const licenseServiceHelper = new LicenseServiceHelper(this.requestExecutor, this.logger, this.serviceManager);
            const licenseTemplateHelper = new LicenseTemplateHelper(this.extensionProvider.getExtension('template') as TemplateExtension, this.cultureHelper.getCurrentLanguage(), frameworkLanguageProvider, $, this.requestExecutor, licenseServiceHelper, this.globalConfig);

            this.licensePopupHelper = new LicensePageHelper(licenseServiceHelper, licenseTemplateHelper, this.rootElement, this.viewStyleManager);

            const offlineBannerElement = document.querySelector('#wd-offline-banner');
            const appBarElement = document.querySelector<HTMLElement>('#wd-app-bar');
            const fileTransferManagerPopOverElement = document.querySelector<HTMLElement>('#wd-file-transfer-manager-pop-over');
            const menuBarHandlerRoot = document.querySelector<HTMLElement>('.top-bar-wrapper');
            this.activeBarHelperFactory = new ActiveBarHelperFactory();
            if (offlineBannerElement) {
                offlineBannerElement.innerHTML = `<span class="wd-icon warning" aria-hidden="true">
            </span>&nbsp;${frameworkLanguageProvider.get('framework.serviceworker.offline.noConnection')}`;
                if (!Utils.isLocalHost(window.location) && (this.globalConfig.windreamWebServiceURL && !Utils.isDomainWithinLocalHost(this.globalConfig.windreamWebServiceURL))) {
                    window.addEventListener('offline', () => {
                        // @ts-ignore - Ignored because of Foundation usage
                        window['Foundation'].Motion.animateIn($(offlineBannerElement), 'slide-in-down');
                    });
                    window.addEventListener('online', () => {
                        // @ts-ignore - Ignored because of Foundation usage
                        window['Foundation'].Motion.animateOut($(offlineBannerElement), 'slide-out-up');
                    });
                    if (navigator.onLine === false) {
                        // @ts-ignore - Ignored because of Foundation usage
                        window['Foundation'].Motion.animateIn($(offlineBannerElement), 'slide-in-down');
                    }
                }
            }

            // Trigger Handler
            const triggerHandler = new Array<TriggerHandler>();

            // PubSub
            this.pubSubHandlerFactory = new PubSubHandlerFactory(triggerHandler, this.logger, this.popupHelper);
            this.popupHelper.setPubSubHandlerFactory(this.pubSubHandlerFactory);
            this.applicationPubSubHandler = this.pubSubHandlerFactory.create();

            this.layoutManagerFactory = new LayoutManagerFactory(this.logger, this.applicationPubSubHandler, this.isHeaderLess);
            const logicComponentPanel = document.querySelector<HTMLElement>('#wd-side-panel-bottom');
            if (!logicComponentPanel) {
                this.logger.fatal(this.className, 'constructor', 'No element with ID `wd-side-panel-bottom` found.');
                throw new Error('Unable to find bottom side-panel');
            }
            if (this.viewRouter) {
                this.subViewManager = new SubViewManager(this.languageManager, this.rootElement, logicComponentPanel, this.layoutManagerFactory, this.logger, this.viewRouter);
                this.subViewManager.onSubViewChanged((subViewId) => {
                    const subViewConfig = this.viewManager.getCurrentViewConfig()?.subViews.find((subViewConfig) => subViewConfig.id === subViewId);
                    if (this.appFocusHandler && subViewConfig) {
                        this.appFocusHandler.subViewChanged(subViewConfig);
                    }
                });
            } else {
                this.logger.fatal(this.className, 'constructor', 'Failed to load subview manager because view router is missing.');
                throw new Error('Failed to load subview manager because view router is missing.');
            }

            if (fileTransferManagerPopOverElement) {
                this.fileTransferManagerHandler = new FileTransferManagerHandler(fileTransferManagerPopOverElement, this.extensionProvider.getExtension('template') as ITemplateExtension);
                this.fileTransferManagerHandler.PopOver = new FileTransferManagerPopOverHandler(fileTransferManagerPopOverElement, this.extensionProvider.getExtension('template') as ITemplateExtension);
                this.fileTransferManagerPopOverHandler = this.fileTransferManagerHandler.PopOver;
            }
            triggerHandler.push(new InstantTransitionTriggerHandler(this.subViewManager, this.logger));
            triggerHandler.push(new PopupTransitionTriggerHandler(this.subViewManager, this.popupHelper, this.logger));
            this.popupHelper.setLanguageProvider(frameworkLanguageProvider);

            const selectionManager = new SelectionManager(this.rootElement, document);
            selectionManager.onSelectionChange = (activeGuid: string | null) => {
                if (activeGuid === null) {
                    this.appFocusHandler.unsetFocus();
                }
            };
            this.popupHelper.setSelectionManager(selectionManager);

            // Messages
            let messageProvider: DWCore.Messages.MessageProvider | undefined = undefined;
            if (Application.hasExternalCore()) {
                messageProvider = DynamicWorkspace.Extensions.core.messageProvider;
            } else {
                messageProvider = new MessageProvider();
            }
            this.messageBus = new MessageBus(this.logger, messageProvider);

            const newApi = this.generateNewApi();

            const componentInjector = new ComponentInjector(document.body, document, this.logger, this.moduleRegistrationHelper);
            this.componentLoader = new ComponentLoader(this.configLoader, this.logger, this.languageManager, componentInjector);
            // Add Enable/Disable PubSub middleware.
            this.componentLoader.registerLoadComponentPipelineOperation(new AddEnableDisablePubSubOperation());

            this.componentInitializer = new ComponentInitializer(this.logger, this.componentLoader, componentInjector, this.moduleRegistrationHelper, this.generateApi(), newApi, this.skeletonHelper);
            this.componentManager = new ComponentManager(this.componentLifecycleManagerFactory, this.componentLoader, this.subViewManager, this.extensionProvider,
                this.uiManager, this.logger, this.skeletonHelper, this.toolbarActionLoader, this.uiComponentFactory, this.serviceManager, this.componentInitializer, this.componentLoadManifestFactory, allComponents);

            // Init Settings Helper
            this.settingsHelper = new SettingsHelper(this.rootElement, this.componentManager, this.applicationPubSubHandler, this.componentLifecycleManagerFactory,
                this.uiManager, this.extensionProvider, this.configLoader, this.toolbarActionLoader, this.logger, this.uiComponentFactory, this.serviceManager);

            // Init Foundation
            // @ts-ignore - Ignore because of Foundation usage
            $(document)['foundation']();
            // Re init foundation because somehow viewport break points are missing?
            // @ts-ignore - Ignore because of Foundation usage
            window['Foundation'].MediaQuery._reInit();
            this.webBridgeHandler.publish(WebBridgeEventTypes.ApplicationIsReady, 'ready');
            // Profilehelper
            this.extensionProvider.setExtension(new LoaderExtension(this.componentManager));
            this.popupHelper.setLoaderExtension(this.extensionProvider.getExtension('component_loader') as ILoaderExtension);

            this.profileSettingsHelper = new ProfileSettingsHelper(frameworkLanguageProvider,
                this.extensionProvider.getExtension('component_loader') as ILoaderExtension,
                this.pubSubHandlerFactory, this.logger, NotificationHelper, this.rootElement, this.viewStyleManager);
            this.profileSettingsHelper.onSettingsChanged = this.onSettingsChanged.bind(this);
            this.profileSettingsHelper.onOpenSettings = () => {
                if (this.appBarHandler) {
                    this.appBarHandler.toggleEditPossible(false);
                }
                if (this.appBarHandler && this.appBarHandler.viewNavigationHandler) {
                    this.appBarHandler.viewNavigationHandler.updateNavigation(null);
                    this.currentView = '';
                    // Set view title to top bar
                    const viewNameElement = document.getElementById('wd-active-view-name');
                    if (viewNameElement) {
                        viewNameElement.textContent = frameworkLanguageProvider.get('framework.navigation.settingsButton.title');
                    }
                }
            };
            // Render appBar
            if (appBarElement && menuBarHandlerRoot && this.viewRouter && this.rootRouter &&
                this.aboutRouter && this.settingsRouter) {
                const menuBarHelperFactory = new MenuBarHandlerFactory(menuBarHandlerRoot, frameworkLanguageProvider);
                const appMenuHandlerFactory = new AppMenuHandlerFactory(this.extensionProvider.getExtension('template') as ITemplateExtension);
                const navigationRootElement = document.querySelector<HTMLElement>('#wd-navigation-panel');
                if (!navigationRootElement) {
                    this.logger.fatal(this.className, 'constructor', 'No element with ID `wd-navigation-panel` found.');
                    throw new Error('No element with ID `wd-navigation-panel` found.');
                }
                this.appBarHandler = new AppBarHandler(appBarElement, this.generateApi(), this.extensionProvider.getExtension('template') as ITemplateExtension, this.activeBarHelperFactory,
                    appMenuHandlerFactory, menuBarHelperFactory, this.languageManager.getLanguageProvider('framework'), this.themeManager, this.subViewManager,
                    navigationRootElement, this.isHeaderLess, this.viewRouter, this.rootRouter, this.aboutRouter, this.settingsRouter, this.localStorageWrapper, this.uiComponentFactory);

                this.appBarHandler.render();
            } else {
                this.logger.fatal(this.className, 'constructor', 'No element with ID `wd-app-bar` found.');
                throw new Error('No element with ID `wd-app-bar` found.');
            }
            resolve();
        });
    }


    /**
     * Logs in the user.
     *
     * @private
     * @returns {Promise<UserDetails>} The logged-in user.
     * @memberof Application
     */
    public login(): Promise<UserDetails> {
        return new Promise<UserDetails>(async (resolve) => {

            this.initUserButton();

            if (Application.hasExternalCore()) {

                if (!this.loginProvider) {
                    this.loginProvider = DynamicWorkspace.Extensions.core.loginProvider;
                }

                this.assert(this.loginProvider, 'loginProvider');

                if (!this.coreUser) {
                    this.authCallbackRouter?.tryUpdateSessionStoragePreAuthUrl();
                    this.coreUser = await this.loginProvider.login();
                }
                if (this.coreUser) {
                    this.authCallbackRouter?.clearSessionStoragePreAuthUrl();
                    this.isUserLoggedIn = true;

                    if (this.appBarHandler) {
                        this.appBarHandler.toggleUserAppMenuContainerVisibility(true);
                    }

                    const userDetails = this.convertToUserDetails(this.coreUser);

                    this.credentialManager.setAuthenticatedUser(userDetails);
                    this.handleLogin(userDetails).then(() => {
                        resolve(userDetails);
                    }).catch((err: Error) => {
                        this.logger.error(this.className, 'login', 'Failed to login', err);
                    });
                }

            } else {
                const loginService = new Login(this.requestExecutor, this.globalConfig, this.logger, this.credentialManager);
                const logoutService = new Logout(this.requestExecutor, this.globalConfig, this.logger, this.credentialManager);
                const getSystemDetailsService = new GetSystemDetails(this.requestExecutor, this.globalConfig, this.logger);
                const getStorageToken = new GetStorageToken(this.requestExecutor, this.globalConfig, this.logger);
                const authenticationTokenService = new GetAuthenticationToken(this.requestExecutor, this.globalConfig, this.logger);
                let loginMask: BaseLoginMask;
                switch (this.globalConfig.authenticationMode) {
                    case AuthenticationModes.MSAL:
                        if (!window.isSecureContext) {
                            this.staticPageHelper.renderError(require('./staticPage/templates/msalRequiresSecureContext.html'));
                            this.uiManager.appIsAvailable();
                            return;
                        }
                        loginMask = new MSALLoginMask(loginService, this.logger, this.authenticationManager, this.credentialManager, this.globalConfig, getStorageToken);
                        break;
                    default:
                        loginMask = new WindreamLoginMask(loginService, authenticationTokenService, this.authenticationManager,
                            this.languageManager.getLanguageProvider('framework'), $, this.credentialManager, this.localStorageWrapper, this.logger, this.webBridgeHandler, getSystemDetailsService,
                            NotificationHelper, this.encryptionHelper, getStorageToken, this.indexedDBWrapper, this.staticPageHelper);
                        break;
                }
                this.loginManager = new LoginManager(logoutService, this.authenticationManager, loginMask, $, this.popupHelper, this.webBridgeHandler);
                this.loginManager.registerLoginCallback((userDetails, encryptionString) => {
                    if (this.isUserLoggedIn) {
                        return;
                    }
                    if (this.appBarHandler) {
                        this.appBarHandler.toggleUserAppMenuContainerVisibility(true);
                    }
                    this.isUserLoggedIn = true;
                    if (encryptionString) {
                        this.cache.enableEncryption(encryptionString);
                    }
                    if (this.authenticationManager) {
                        this.assert(this.loginManager, 'loginManager');

                        const token = userDetails.token ? userDetails.token : undefined;
                        const tokenProvider = new WindreamTokenProvider(this.loginManager, this.logger, token);

                        this.authenticationManager.addAuthenticationProvider(new WindreamTokenHttpAuthenticationProvider(tokenProvider));
                        this.authenticationManager.addAuthenticationProvider(new MsalTokenHttpAuthenticationProvider(tokenProvider));

                        if (token && this.globalConfig.windreamWebServiceURL && this.globalConfig.authenticationMode) {
                            this.logger.enableWebServiceLogging(this.globalConfig.windreamWebServiceURL, tokenProvider, this.globalConfig.authenticationMode);
                        }
                        this.serviceManager.setTokenProvider(tokenProvider);

                        // Init websocketHandler
                        this.indexMaskWebSocketHandler = new WindreamWebsocketProvider(new WebsocketFactory(), this.logger, tokenProvider);
                        const uploadExtension = new UploadExtension(this.globalConfig, new WindreamWebsocketProvider(new WebsocketFactory(), this.logger, tokenProvider), this.logger);
                        this.extensionProvider.setExtension(uploadExtension);
                        // Set up Index Mask Extension
                        const indexMaskExtension = new IndexMaskExtension(this.indexMaskWebSocketHandler);
                        this.extensionProvider.setExtension(indexMaskExtension);
                    }
                    this.handleLogin(userDetails).then(() => {
                        resolve(userDetails);
                    }).catch((err: Error) => {
                        this.logger.error(this.className, 'login', 'Failed to login', err);
                    });
                });
                this.loginManager.registerLogoutCallback(() => {
                    this.handleUserNameDisplay('', false);
                    if (this.appBarHandler) {
                        this.appBarHandler.toggleUserAppMenuContainerVisibility(false);
                    }
                    this.assert(this.viewStyleManager, 'ViewStyleManager');
                    this.assert(this.appBarHandler, 'AppBarHandler');
                    this.assert(this.subViewManager, 'SubViewManager');
                    this.assert(this.languageManager, 'LanguageManager');
                    this.uiManager.appIsLoading();
                    this.logger.disableWebServiceLogging();
                    this.isUserLoggedIn = false;
                    this.viewStyleManager.removeStyles();
                    this.appBarHandler.handleLogout();
                    this.subViewManager.clear();
                    this.memoryManager.clear().then(() => {
                        if (this.configUiHelper) {
                            this.configUiHelper.destroy();
                        }
                        if (this.profileSettingsHelper) {
                            this.profileSettingsHelper.updateViewConfigs([]);
                        }
                        this.uiManager.appIsAvailable();
                        this.uiManager.unlockView();
                        const logoutPageHelper = new LogoutPageHelper($, this.languageManager.getLanguageProvider('framework'));
                        logoutPageHelper.render(this.rootElement);
                    }).catch((error) => this.logger.error(this.className, 'login', 'Unable to reset all caches', error));
                });
                this.loginManager.renderLoginPage(this.rootElement, AuthenticationTypes.WindreamLike).then((displayLogin) => {
                    if (displayLogin) {
                        this.uiManager.appIsAvailable();
                        this.webBridgeHandler.publish(WebBridgeEventTypes.Login, 'Login');
                    }
                }).catch((error) => {
                    this.uiManager.appIsAvailable();
                    this.logger.error(this.className, 'login', 'Failed to render login page', error);
                });
            }
        });
    }

    /**
     * Setup the service worker.
     *
     * @private
     * @memberof Application
     */
    private setupServiceWorker(): void {
        const methodName = 'setupServiceWorker';
        if (window.isSecureContext && 'serviceWorker' in navigator) {
            let wasUpdatePopupOpenedOnce = false;
            // Take the browser language because service worker stuff is far too early
            this.browserInspector = new BrowserInspector(this.logger, navigator);
            const languageCodeOffset = 2;
            const browserLanguage = this.browserInspector.getBrowserLanguage().substring(0, languageCodeOffset);
            // Add update listener for broadcast update from service worker
            navigator.serviceWorker.addEventListener('message', async (event) => {
                if (event.data.meta === 'workbox-broadcast-update') {
                    if (!wasUpdatePopupOpenedOnce) {
                        wasUpdatePopupOpenedOnce = true;
                        // Needs to create those resources since service worker stuff must be executed before the DOM load event.
                        const frameWorkLanguageManager = new LanguageManager(this.requestExecutor, browserLanguage,
                            [], this.logger, this.translator, $);
                        frameWorkLanguageManager.init().then(() => {
                            // @ts-ignore - Ignore because of window usage
                            const frameWorkPopupHelper = new PopupHelper(window['$'], window['Foundation'], this.logger, NotificationHelper, this.uiComponentFactory);
                            const languageProvider = frameWorkLanguageManager.getLanguageProvider('framework');
                            frameWorkPopupHelper.setLanguageProvider(languageProvider);
                            frameWorkPopupHelper.openOkCancelPopup(() => {
                                window.location.reload();
                            }, () => {
                                // Don't do anything on cancel
                            }, {
                                title: languageProvider.get('framework.serviceworker.broadcastUpdate.title'),
                                body: languageProvider.get('framework.serviceworker.broadcastUpdate.body')
                            });
                        }).catch((error) => {
                            this.logger.warn(this.className, methodName, 'ServiceWorker can\'t display update broadcast popup', error);
                        });
                    }
                }
            });

            // Don't register the service worker
            // until the page has fully loaded
            window.addEventListener('load', () => {
                const wb = new Workbox('/sw.js');
                // Service worker got updated by service worker fingerprint
                const showSkipWaitingPrompt = async () => {
                    wb.addEventListener('controlling', () => {
                        window.location.reload();
                    });
                    if (!wasUpdatePopupOpenedOnce) {
                        wasUpdatePopupOpenedOnce = true;
                        // Needs to create those resources since service worker stuff must be executed before the DOM load event.
                        const frameWorkLanguageManager = new LanguageManager(this.requestExecutor, browserLanguage,
                            [], this.logger, this.translator, $);
                        frameWorkLanguageManager.init().then(() => {
                            // @ts-ignore - Ignore because of window usage
                            const frameWorkPopupHelper = new PopupHelper(window['$'], window['Foundation'], this.logger, NotificationHelper, this.uiComponentFactory);
                            const languageProvider = frameWorkLanguageManager.getLanguageProvider('framework');
                            frameWorkPopupHelper.setLanguageProvider(languageProvider);
                            frameWorkPopupHelper.openOkCancelPopup(() => {
                                wb.messageSkipWaiting();
                            }, () => {
                                // Don't do anything on cancel
                            }, {
                                title: languageProvider.get('framework.serviceworker.update.title'),
                                body: languageProvider.get('framework.serviceworker.update.body')
                            });

                        }).catch((error) => {
                            this.logger.warn(this.className, methodName, 'ServiceWorker can\'t display update popup', error);
                        });
                    }
                };

                // Add an event listener to detect when the registered
                // service worker has installed but is waiting to activate.
                wb.addEventListener('waiting', () => {
                    showSkipWaitingPrompt();
                });

                wb.addEventListener('activated', () => {
                    // Those files should be cached but not within the sw precache. This call will put them into the static cache.
                    const urlsToCache = [
                        './dynamicWorkspace.json',
                        './framework/js/index.js',
                        './framework-vendor/js/index.js',
                        './config/globalConfig.json',
                        './config/customConfig.json',
                        './config/toolbarConfig.json',
                        './legal/imprint/de/imprint.md',
                        './legal/imprint/en/imprint.md',
                        './scripts/DWApplicationOnLoad.js',
                        './scripts/DWIndexAfterSave.js',
                        './scripts/DWIndexBeforeSave.js',
                        './scripts/DWIndexOnLoad.js'
                    ];
                    // Send that list of URLs to your router in the service worker.
                    wb.messageSW({
                        type: 'CACHE_URLS',
                        payload: { urlsToCache },
                    });
                });

                wb.register().then((reg) => {
                    const scope = reg?.scope || '';
                    this.logger.debug(this.className, methodName, 'ServiceWorker registered for scope:', scope);
                }).catch((error) => {
                    this.logger.warn(this.className, methodName, 'ServiceWorker can\'t be registered for scope:', error);
                });
            });
        }
    }
    /**
     * Init the user button so it will display information.
     *
     * @private
     * @memberof Application
     */
    private initUserButton(): void {
        const frameworkLanguageProvider = this.languageManager.getLanguageProvider('framework');

        this.jQueryRef('#wd-user-btn').on('click', () => {
            this.jQueryRef('#wd-user-dropdown').toggleClass('show');
        }).attr('title', '');   // Empty string because no user tooltip is required
        this.jQueryRef('#wd-logout-link').on('click', (e: any) => {
            this.jQueryRef('#wd-user-dropdown').removeClass('show');
            e.preventDefault();

            // Reset the URL on logout
            RouteManager.resetUrl();

            if (this.loginProvider) {
                this.loginProvider.logout().then(() => {
                    this.handleLogoutLink(false);
                }).catch((error) => {
                    this.logger.info(this.className, 'initUserButton', 'Logout failed:', error);
                });
            } else {
                this.loginManager?.logout().then(() => {
                    this.logger.info(this.className, 'initUserButton', 'Logout successful');
                    this.handleLogoutLink(false);
                }).catch((error) => {
                    this.logger.info(this.className, 'initUserButton', 'Logout failed:', error);
                });
            }
        }).text(frameworkLanguageProvider.get('framework.userAccount.logoutlinktitle.title'))
            .attr('title', frameworkLanguageProvider.get('framework.userAccount.logoutlinktitle.title'));
    }

    /**
     * Handle the logout link to either hide or show it.
     *
     * @private
     * @param {boolean} shouldBeDisplayed Whether it should be hidden or displayed.
     * @memberof Application
     */
    private handleLogoutLink(shouldBeDisplayed: boolean): void {
        if (shouldBeDisplayed) {
            this.jQueryRef('#wd-logout-link').show();
        } else {
            this.jQueryRef('#wd-logout-link').hide();
        }
    }

    /**
     * Converts the given core user instance to user details.
     *
     * @private
     * @param {DWCore.Member.User} user The user instance.
     * @returns {UserDetails} The user details.
     * @memberof Application
     */
    private convertToUserDetails(user: DWCore.Member.User): UserDetails {
        if (!user) {
            throw new Error('application.convertToUserDetails(): Invalid argument "user": undefined');
        }

        const userDetails = new UserDetails();
        userDetails.domain = user.domain;
        userDetails.fullUsername = user.fullName;
        if (user.id) {
            userDetails.id = parseInt(user.id, 10);
        }
        userDetails.isAdmin = !!user.isAdmin;
        userDetails.isValidUser = true;
        userDetails.userName = user.name;

        return userDetails;
    }

    /**
     * The onSettingsChanged handler.
     *
     * @private
     * @param {ViewConfigMetaData[]} viewConfigs
     * @returns {*}  {Promise<void>}.
     * @memberof Application
     */
    private onSettingsChanged(viewConfigs: ViewConfigMetaData[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.assert(this.appBarHandler, 'AppBarHandler');
            if (this.applicationConfig) {
                this.applicationConfig.activeViews = viewConfigs;
                this.appBarHandler.viewNavigationHandler?.renderNavigation(viewConfigs, true).then(() => {
                    resolve();
                }, (err: Error) => {
                    this.logger.error(this.className, 'onSettingsChanged', 'Failed to update the navigation', err);
                    reject(err);
                });
            }
        });
    }

    /**
     * Generates the public API to reference globally.
     *
     * @returns {PublicApi}
     * @memberof Application
     */
    public generateApi(): PublicApi {
        // Handle execution of `ready()` function calls
        const readyCallbacks: (() => void)[] = new Array<() => void>();
        const isReady = false;
        const app = this;
        return {
            App: {
                get FocusHandler() {
                    return app.appFocusHandler;
                },
                get DownloadHandler() {
                    return app.appDownloadHandler;
                }
            },
            Authentication: {
                getCurrentUser: () => {
                    return this.credentialManager.getAuthenticatedUser();
                }
            },
            Config: {
                getGlobalConfig: () => {
                    this.assert(app.globalConfig, 'GlobalConfig');
                    return app.globalConfig;
                }
            },
            Culture: this.cultureHelper,
            Language: {
                /**
                 * Gets the framework language provider.
                 */
                getFrameworkTranslationProvider: () => {
                    this.assert(this.languageManager, 'LanguageManager');
                    return this.languageManager.getLanguageProvider('framework');
                },
                /**
                 * Provide the current language name.
                 */
                getLanguageCultureName: () => {
                    this.assert(this.languageManager, 'LanguageManager');
                    return this.languageManager.getLanguageCultureName();
                },
                /**
                 * Gets the language provider for a specific component.
                 * @param {string} componentName The component, which needs the provider.
                 */
                getLanguageProvider: (componentName: string) => {
                    this.assert(this.languageManager, 'LanguageManager');
                    return this.languageManager.getLanguageProvider(componentName);
                },
                /**
                 *  Gets a langauge provider form an url
                 *
                 * @param languageFileUrl The url.
                 * @returns
                 */
                loadLanguageProviderFromUrl: (languageFileUrl: string) => {
                    this.assert(this.languageManager, 'LanguageManager');
                    return this.languageManager.loadLanguageProviderFromUrl(languageFileUrl);
                },

                loadAllLanguageFiles: () => {
                    this.assert(this.languageManager, 'LanguageManager');
                    return this.languageManager.loadAllLanguageFiles();
                },
                getLanguageFileUrl: (componentId: string) => {
                    this.assert(this.languageManager, 'LanguageManager');
                    return this.languageManager.getLanguageFileUrl(componentId);
                }
            },
            Lifecycle: {
                disableEdit: () => {
                    this.assert(app.subViewManager, 'SubViewManager');
                    app.subViewManager.setEditable(false);
                },
                enableEdit: () => {
                    this.assert(app.subViewManager, 'SubViewManager');
                    app.subViewManager.setEditable(true);
                },
                /**
                 * Function to register any action in the browser in, that is not part of any component.
                 * Works similar to jQuery(document).ready().
                 * If the function is called after the Framework already initialized, it is executed directly.
                 * @param {Function} callback  Callback to execute once the Framework finished loading.
                 */
                ready: (callback: () => void) => {
                    if (!isReady) {
                        readyCallbacks.push(callback);
                    } else {
                        callback();
                    }
                },
                refresh: (changes: Changes) => {
                    this.assert(app.viewManager, 'ViewManager');
                    app.viewManager.promoteChanges(changes);
                }
            },
            FileTransferManagerHandler: {
                PopOver: {
                    render: (files: DWCore.Files.FileUpload[]) => {
                        return this.fileTransferManagerPopOverHandler.render(files);
                    },
                    updateProgress: (uuid, status, progress?) => {
                        return this.fileTransferManagerPopOverHandler.updateProgress(uuid, status, progress);
                    },
                }
            },
            Logger: this.logger,
            Memory: this.memoryManager,
            theme: this.themeManager as any,
            Version: Version.productVersion || '',
            /**
             * Notification Helper
             */
            Notification: NotificationHelper,
            /**
             * Popup Menu Helper
             */
            Popup: app.popupHelper,
            PubSub: {
                /**
                 * Publishes an event for a combination of guid and event name.
                 * Wraps the internal `PubSubHandler` object.
                 * See PubSub documentation for further information.
                 * @param {String} guid      Guid of the component.
                 * @param {String} eventName Name of the event to subscribe to.
                 * @param {Object} data      Data to send with the event.
                 */
                publish: (guid, eventName, data) => {
                    this.assert(app.applicationPubSubHandler, 'ApplicationPubSubHandler');
                    app.applicationPubSubHandler.publish(guid, eventName, data);
                },
                /**
                 * Registers a new configuration to the PubSubHandler.
                 * Wraps the internal `PubSubHandler` object.
                 * See PubSub documentation for further information.
                 * @param {Object} config Configuraiton to use.
                 */
                register: (config) => {
                    this.assert(app.applicationPubSubHandler, 'ApplicationPubSubHandler');
                    app.applicationPubSubHandler.loadPubSub(config);
                },
                /**
                 * Subscribes a callback to a combination of guid and event name.
                 * Wraps the internal `PubSubHandler` object.
                 * See PubSub documentation for further information.
                 * @param {String}   guid      Guid of the component.
                 * @param {String}   eventName Name of the event to subscribe to.
                 * @param {Function} callback  Callback to execute once a mapped event is published.
                 */
                subscribe: (guid, eventName, callback) => {
                    this.assert(this.applicationPubSubHandler, 'ApplicationPubSubHandler');
                    this.applicationPubSubHandler.subscribe(guid, eventName, callback);
                }
            },
            /**
             * The public request executor interface.
             */
            RequestExecutor: {
                executeRequest: (resourcePointer: IResourcePointer, options?: RequestOptions): Promise<IServiceResponse<any>> => {
                    return this.requestExecutor.executeRequest(resourcePointer, options);
                }
            },
            Ui: {
                /**
                 * Adds busy state to the application.
                 * Use `appIsIdle()` to remove this state.
                 */
                appIsBusy: () => this.uiManager.appIsBusy(),

                /**
                 * Removes busy state from the application.
                 */
                appIsIdle: () => this.uiManager.appIsIdle(),
                components: this.uiComponentFactory
            },
            /**
             * The Utils static class.
             */
            Utils: Utils,
            /**
             * The webbridge
             */
            WebBridge: this.webBridgeHandler,
            /**
             * The intent manager.
             */
            Intents: this.intentManager,

            /**
             * The script executor to execute custom scripts.
             */
            ScriptExecutor: {
                Direct: this.directScriptExecutor as any,
                Windream: this.windreamScriptExecutor as any
            },
            /**
             * Registers a component to the Lifecycle Manager.
             * Wraps the internal `LifecycleManager` object.
             * See Lifecycle Manager documentation for further information
             * @param {String} componentName Name of the component.
             * @param {Class}  component     Component to register.
             */
            /**
             * Provides the Windream Metadata Store that contains information about the windream server.
             */
            getWindreamMetadata: () => {
                this.assert(app.metadataStore, 'MetadataStore');
                return app.metadataStore;
            },
            register: (componentName, component, migrateFunction?: MigrateFunction) => {
                this.assert(app.componentLoader, 'ComponentLoader');
                this.moduleRegistrationHelper.registerModule({
                    classReference: component,
                    id: componentName,
                    migrationFunction: migrateFunction,
                    type: ModuleRegistrationType.Component
                });
            },
            /**
             * Services
             */
            get Services() {
                if (app.serviceManager) {
                    return app.serviceManager.getServices();
                } else {
                    throw new Error('Tried to access Services without being logged in.');
                }
            },
            /**
             * ServiceManager
             */
            get ServiceManager() {
                if (app.serviceManager) {
                    return app.serviceManager;
                } else {
                    throw new Error('Tried to access Services without being logged in.');
                }
            },
            /**
             * JQuery static instance.
             */
            get $() {
                return $;
            }
        };
    }

    /**
     * Setup the routing if the user is logged in i.e. view change etc.
     *
     * @private
     * @memberof Application
     */
    private setupRouting(): void {
        const methodName = 'setupRouting';
        const getViewId = (viewString: string) => {
            const viewIdFromAlias = this.applicationConfig?.activeViews.find((view: ViewConfigMetaData) => view.alias?.toLowerCase() === viewString.toLowerCase());
            if (viewIdFromAlias && viewIdFromAlias.alias) {
                return viewIdFromAlias.id;
            }
            return viewString;
        };
        const setupViewCallback = (newViewId: string, subViewId?: string) => {
            if (!this.isUserLoggedIn) {
                // Don't route to default views if user is not logged in.
                // Instead let the user login
                this.login().catch((error) => this.logger.fatal(this.className, methodName, 'Failed to login for setup view routing', error));;
                return;
            }
            if (this.appBarHandler && this.viewRouter) {
                if (this.configInClosingState) {
                    return;
                }
                this.configInClosingState = true;
                this.appBarHandler.leaveEditModeAsync().then(() => {
                    this.configInClosingState = false;
                    if (!this.viewRouter) {
                        return;
                    }
                    if (newViewId === '') {
                        if (this.applicationConfig && this.applicationConfig.activeViews && this.applicationConfig.activeViews[0] && this.applicationConfig.activeViews[0].id) {
                            newViewId = this.applicationConfig.activeViews[0].id;
                        } else {
                            this.logger.warn(this.className, methodName, 'Unable to find initial view', this.applicationConfig);
                        }
                    }
                    const fixedViewId = getViewId(newViewId);
                    // Don't route same view
                    if (this.currentView === fixedViewId) {
                        // But switch sub view or reset to base.
                        if (subViewId) {
                            this.subViewManager.switchSubView(subViewId, false, true);
                        } else if (this.subViewManager.hasNavigationHistory() &&
                            this.storedViewConfigs.has(fixedViewId)) {
                            this.subViewManager.resetNavigationHistory();
                            const storedViewConfig = this.storedViewConfigs.get(fixedViewId);
                            if (storedViewConfig) {
                                this.subViewManager.switchSubView(storedViewConfig.subViews[0].id, true, true);
                            }
                        }
                        return;
                    } else {
                        this.subViewManager.resetNavigationHistory();
                    }
                    this.uiManager.displayBusyStateIndicator(); // Prevent any interaction as long as the view is loading
                    this.setUpView(newViewId).then(() => {
                        this.currentView = getViewId(newViewId);
                        if (subViewId) {
                            this.subViewManager.switchSubView(subViewId);
                        }
                        this.uiManager.removeBusyStateIndicator();
                    }).catch((err: Error) => {
                        this.uiManager.removeBusyStateIndicator();
                        this.logger.error(this.className, methodName, 'Failed to setup view', err);
                    });
                }).catch((error) => {
                    this.configInClosingState = false;
                    this.logger.error(this.className, methodName, 'Failed to leave edit mode', error);
                });
            } else {
                this.logger.fatal(this.className, methodName, 'Routing dependencies not found');
            }
        };
        this.viewRouter = new ViewRouter(this.logger, window);
        this.viewRouter.registerOnViewChange(setupViewCallback);
        RouteManager.registerRouter(this.viewRouter);
        this.settingsRouter = new SettingsRouter();
        this.settingsRouter.registerOnSettingsNavigation((path) => {
            // !window.location.search.includes('?origin=landingPageLink') is a workaround for now since the routing path contains only path information on start up
            // The best case would be that the path contains the search string as well but this will break the view/edit routers since then the search string would be part of the view id.
            if ((!path.includes('?origin=landingPageLink') && !window.location.search.includes('?origin=landingPageLink')) &&  !this.isUserLoggedIn) {
                this.login().then(() => {
                    if (this.profileSettingsHelper) {
                        this.profileSettingsHelper.init();
                    }
                }).catch((error) => this.logger.fatal(this.className, methodName, 'Failed to login for settings routing', error));
            } else {
                if (this.profileSettingsHelper) {
                    this.profileSettingsHelper.init();
                }
            }
        });
        RouteManager.registerRouter(this.settingsRouter);
        this.aboutRouter = new AboutRouter();
        this.aboutRouter.registerOnAboutNavigation(async (path) => {
            // !window.location.search.includes('?origin=landingPageLink') is a workaround for now since the routing path contains only path information on start up
            // The best case would be that the path contains the search string as well but this will break the view/edit routers since then the search string would be part of the view id.
            if ((!path.includes('?origin=landingPageLink') && !window.location.search.includes('?origin=landingPageLink')) && !this.isUserLoggedIn) {
                try {
                    await this.login();
                } catch (error) {
                    this.logger.fatal(this.className, methodName, 'Failed to login for about routing', error);
                }
            }
            if (this.licensePopupHelper) {
                if (this.appBarHandler) {
                    this.appBarHandler.toggleEditPossible(false);
                }
                this.licensePopupHelper.renderAboutPage(this.isUserLoggedIn);
                if (this.appBarHandler && this.appBarHandler.viewNavigationHandler) {
                    this.appBarHandler.viewNavigationHandler.updateNavigation(null);
                    this.currentView = '';
                    // Set view title to top bar
                    const viewNameElement = document.getElementById('wd-active-view-name');
                    if (viewNameElement) {
                        const frameworkLanguageProvider = this.languageManager.getLanguageProvider('framework');
                        viewNameElement.textContent = frameworkLanguageProvider.get('framework.license.dropdown.title');
                    }
                }
            }
        });
        RouteManager.registerRouter(this.aboutRouter);

        this.systemViewRouter = new SystemViewRouter(this.logger);
        this.systemViewRouter.registerOnViewChange(setupViewCallback);
        RouteManager.registerRouter(this.systemViewRouter);

        this.rootRouter = new RootRouter();
        this.rootRouter.registerOnRootNavigation(() => {
            if (!this.isUserLoggedIn) {
                // Don't route to default views if user is not logged in.
                // Instead let the user login
                this.login().catch((error) => this.logger.fatal(this.className, methodName, 'Failed to login for root routing', error));;
                return;
            }
            let hashValue: string | null = null;
            // Legacy hash based root route
            if (this.viewRouter) {
                hashValue = this.viewRouter.getViewIdFromHash();
            }
            if (hashValue) {
                RouteManager.replaceCurrentPath(ViewRouter.generateNavigationUrlForViewId(hashValue));
                setupViewCallback(hashValue);
            } else if (this.applicationConfig && this.applicationConfig.activeViews &&
                this.applicationConfig.activeViews.length > 0) {
                setupViewCallback(this.applicationConfig.activeViews[0].id);
            } else {
                this.logger.fatal(this.className, methodName, 'Failed to load default view on root callback');
            }
        });
        RouteManager.registerRouter(this.rootRouter);

        // Add the auth-callback router. This one is used for OpenID connect authentication.
        this.authCallbackRouter = new AuthCallbackRouter(window);
        this.authCallbackRouter.registerOnAuthCallback(() => {
            // Go back to the login process to complete it when the auth-callback is called
            this.login().catch((error) => this.logger.fatal(this.className, methodName, 'Failed to login for auth callback routing', error));;
        });
        RouteManager.registerRouter(this.authCallbackRouter);

        this.editRouter = new EditRouter(this.logger);
        this.editRouter.registerOnEditChange(async (viewId) => {
            if (!this.isUserLoggedIn) {
                // Don't route to default views if user is not logged in.
                // Instead let the user login
                this.login().catch((error) => this.logger.fatal(this.className, methodName, 'Failed to login for edit routing', error));;
                return;
            }
            const displayErrorPage = () => {
                const languageProvider = this.languageManager.getLanguageProvider('framework');
                this.viewStyleManager.removeStyles();
                NotificationHelper.Instance.error({
                    body: languageProvider.get('framework.config.errorloadingviewconfiguration'),
                    title: languageProvider.get('framework.generic.error')
                });
                this.staticPageHelper.renderError(require('./staticPage/templates/viewNotFound.html'));
                this.uiManager.appIsAvailable();
            };
            const fixedId = getViewId(viewId);

            // Check if user is on mobile, if yes route to normal view.
            if (DeviceDetection.isMobile()) {
                RouteManager.navigate(ViewRouter.generateNavigationUrlForViewId(fixedId));
                return;
            }

            // Check if user is Admin
            if (!viewId.startsWith(ConfigLoader.STATIC_VIEW_PREFIX)) { // Do not fetch rights for static views
                let viewRights: DWCore.Views.ViewRights = DWCore.Views.ViewRights.Undefined;
                if (Application.hasExternalCore() && DynamicWorkspace.Extensions.core.viewProvider) {
                    viewRights = await DynamicWorkspace.Extensions.core.viewProvider.getViewRights(fixedId);

                } else {
                    viewRights = await this.serviceManager.getServices().DynamicWorkspace.getViewRights(new ViewRequestOptions(fixedId));
                }

                const canEditView = ((viewRights & DWCore.Views.ViewRights.Edit) === DWCore.Views.ViewRights.Edit);
                if (canEditView) {
                    if (this.currentView === fixedId) {
                        this.appBarHandler.enterEditModeAsync().then(() => {
                            // Do nothing since we are in editmode
                        }).catch((error) => {
                            this.logger.error(this.className, methodName, 'Failed to enter edit mode via router', error);
                        });
                    } else {
                        this.uiManager.displayBusyStateIndicator(); // Prevent any interaction as long as the view is loading
                        this.setUpView(fixedId, true).then(() => {
                            this.currentView = fixedId;
                            this.uiManager.removeBusyStateIndicator();
                            this.appBarHandler.enterEditModeAsync().then(() => {
                                // Do nothing since we are in editmode
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'Failed to enter edit mode via router', error);
                            });
                        }).catch((err: Error) => {
                            this.uiManager.removeBusyStateIndicator();
                            this.logger.error(this.className, methodName, 'Failed to setup view for edit', err);
                        });
                    }
                } else {
                    displayErrorPage();
                }
            }
        });
        RouteManager.registerRouter(this.editRouter);

        const subViewRouter = new SubViewRouter(this.logger, window);
        subViewRouter.registerOnSubViewChange((subViewId) => {
            if (!this.isUserLoggedIn) {
                // Don't route to default views if user is not logged in.
                // Instead let the user login
                this.login().catch((error) => this.logger.fatal(this.className, methodName, 'Failed to login for subview routing', error));;
                return;
            }
            if (this.viewRouter) {
                const currentViewId = this.viewRouter.getCurrentViewFromPath();
                if (currentViewId) {
                    setupViewCallback(currentViewId, subViewId);
                }
            }
        });
        RouteManager.registerRouter(subViewRouter);
    }

    private generateNewApi(): DynamicWorkspace {
        this.assert(this.globalConfig, 'GlobalConfig');
        this.assert(this.languageManager, 'LanguageManager');
        // this.assert(this.viewManager, 'ViewManager');
        this.assert(this.applicationPubSubHandler, 'ApplicationPubSubHandler');
        this.assert(this.metadataStore, 'MetadataStore');
        this.assert(this.directScriptExecutor, 'DirectScriptExecutor');
        this.assert(this.windreamScriptExecutor, 'WindreamScriptExecutor');
        this.assert(this.serviceManager, 'ServiceManager');
        this.assert(this.messageBus, 'MessageBus');
        const publicApiGenerator = new PublicApiNew(this.credentialManager.getAuthenticatedUser(), this.globalConfig, this.cultureHelper, this.intentManager,
            this.languageManager, this.viewManager, this.logger, Utils, this.appFocusHandler, this.applicationPubSubHandler, this.popupHelper,
            NotificationHelper, this.metadataStore, this.directScriptExecutor, this.windreamScriptExecutor, this.serviceManager, this.requestExecutor,
            this.uiComponentFactory, this.appDownloadHandler, this.moduleRegistrationHelper, this.fileTransferManagerHandler, this.messageBus, this.identityChangedHandler,
            this.sharedSettingsProvider);
        const api = publicApiGenerator.generate();

        // @ts-ignore - Ignore because of window usage
        window['DynamicWorkspace'] = api;
        if (this.componentInitializer) {
            this.componentInitializer.updateApi(api);
        }

        return api;
    }
    /**
     * Handles login of the user.
     * Is invoked every time a user logs into the application.
     *
     * @private
     * @param {UserDetails} userDetails User details received from the webservice.
     * @returns {Promise<void>} Promise to resolve after application was set up.
     * @async
     *
     * @memberof Application
     */
    private async handleLogin(userDetails: UserDetails): Promise<void> {
        const methodName = 'handleLogin';
        this.isUserLoggedIn = true;

        const userName = userDetails.fullUsername || '';
        this.handleUserNameDisplay(userName, true);
        this.appBarHandler.setUserInitials();
        this.handleLogoutLink(true);
        this.jQueryRef('body').addClass('wd-logged-in');

        this.generateNewApi();
        this.uiManager.appIsLoading();

        // Load global shared settings
        this.sharedSettingsProvider = new SharedSettingsProvider(this.languageManager.getLanguageProvider('framework'), this.logger);
        const getGlobalSharedSettingsService = new GetGlobalSharedSettings(this.requestExecutor, this.globalConfig, this.logger);
        const sharedSettingsLoader = new SharedSettingsLoader(getGlobalSharedSettingsService, this.logger);

        this.assert(this.configLoader, 'configLoader');

        // Load the application configuration
        return Promise.all([
            this.configLoader.loadApplicationConfig(),
            sharedSettingsLoader.loadGloabalSharedSettings()
        ]).then(([applicationConfig, sharedSettings]) => {
            this.applicationConfig = applicationConfig;
            if (this.applicationConfig.activeViews.length === 0
                && RoutingUtils.getCurrentUrl().toLowerCase().includes(ConfigLoader.SYSTEM_VIEW_PREFIX) === false
            ) {
                throw new Error('No active views found');
            }
            if (this.viewRouter) {
                this.viewRouter.activeViews = this.applicationConfig.activeViews;
            }
            this.uiManager.appIsLoading();

            if (sharedSettings && this.sharedSettingsProvider) {
                this.sharedSettingsProvider.setGlobalSettings(sharedSettings);
            }

            this.generateNewApi();

            if (Application.hasExternalCore()) {
                return Promise.resolve(true);
            } else {
                // Init windreamMetadataStore
                return this.metadataStore.init();
            }
        }).then(async () => {
            this.assert(this.applicationConfig, 'ApplicationConfig');
            this.assert(this.configLoader, 'configLoader');
            this.assert(this.languageManager, 'LanguageManager');
            this.assert(this.appBarHandler, 'AppBarHandler');
            this.assert(this.subViewManager, 'SubViewManager');
            this.assert(this.configManager, 'ConfigManager');
            this.assert(this.serviceManager, 'ServiceManager');
            this.assert(this.applicationPubSubHandler, 'ApplicationPubSubHandler');
            this.assert(this.componentManager, 'ComponentManager');
            this.assert(this.activeBarHelperFactory, 'ActiveBarHelperFactory');
            this.assert(this.componentLoader, 'ComponentLoader');
            this.assert(this.viewLifecycleManagerFactory, 'ViewLifecycleManagerFactory');
            this.assert(this.globalConfig, 'GlobalConfig');

            if (!Application.hasExternalCore()) {
                this.assert(this.windreamScriptExecutor, 'WindreamScriptExecutor');
                this.assert(this.metadataStore, 'MetaDataStore');
            }
            this.assert(this.toolbarActionLoader, 'ToolbarActionLoader');
            const views = this.applicationConfig.activeViews;
            if (this.profileSettingsHelper) {
                this.profileSettingsHelper.updateViewConfigs(views);
            }
            if (DeviceDetection.isApp()) {
                this.webBridgeHandler.publish(WebBridgeEventTypes.SendNavigation, JSON.stringify(views));
            }
            if (this.viewRouter && this.rootRouter && this.editRouter) {
                const viewNavigationHandlerFactory = new ViewNavigationHandlerFactory(this.applicationConfig, this.logger, this.popupHelper, this.configManager,
                    this.languageManager, this.extensionProvider.getExtension('template') as ITemplateExtension, this.viewRouter, this.rootRouter, this.editRouter);
                this.appBarHandler.setViewNavigationHandlerFactory(viewNavigationHandlerFactory);
            } else {
                this.logger.fatal(this.className, methodName, 'Missing view router or root router, entire navigation will not work.');
                throw new Error('Missing view router or root router, entire navigation will not work.');
            }
            const subViewNavigationHandlerFactory = new SubViewNavigationHandlerFactory(this.extensionProvider.getExtension('template') as ITemplateExtension,
                this.subViewManager, this.languageManager.getLanguageProvider('framework'));
            this.appBarHandler.setSubViewNavigationHandlerFactory(subViewNavigationHandlerFactory);
            this.appBarHandler.handleLogin(userDetails.isAdmin, views);

            // Init Config UI Helper
            const toolstripContainer = document.querySelector<HTMLElement>('#wd-toolstrip-container');
            if (!toolstripContainer) {
                this.logger.fatal(this.className, 'constructor', 'No element with ID `wd-toolstrip-container` found.');
                throw new Error('No element with ID `wd-toolstrip-container` found.');
            }

            const toolstripHandler = new ToolstripHandler(toolstripContainer, this.extensionProvider.getExtension('template') as ITemplateExtension, this.activeBarHelperFactory);
            const pubSubUiHelper = new PubSubUiHelper(this.applicationPubSubHandler, this.logger, this.componentManager, this.configLoader,
                this.popupHelper, this.languageManager, this.serviceManager, this.uiManager);

            // TODO: Make DeviceMenuHandler render its own HTML
            const deviceMenuContainer = document.querySelector<HTMLElement>('#wd-config-device-select');
            if (!deviceMenuContainer) {
                throw new Error('No element with ID `wd-config-device-select` found.');
            }
            const deviceMenuHandler = new DeviceMenuHandler(deviceMenuContainer, this.languageManager.getLanguageProvider('framework'), this.logger);

            this.assert(this.pubSubHandlerFactory, 'PubSubHandlerFactory');
            this.assert(this.staticPageHelper, 'StaticPageHelper');
            this.assert(this.componentLoadManifestFactory, 'ComponentLoadManifestFactory');
            this.viewManager = new ViewManager(this.viewLifecycleManagerFactory, this.componentLoader, this.uiManager,
                this.subViewManager, this.configLoader, this.pubSubHandlerFactory, this.languageManager, this.extensionProvider, this.staticPageHelper, this.componentManager,
                this.viewStyleManager, new ComponentNameProvider(), this.logger, this.toolbarActionLoader, this.componentLoadManifestFactory);
            if (this.configUiHelper) {
                this.configUiHelper.updateCurrentViewManager(this.viewManager);
            }

            this.configUiHelper = new ConfigUiHelper(this.viewManager, this.applicationPubSubHandler, this.subViewManager, this.configLoader,
                this.configManager, this.languageManager, this.metadataStore, this.popupHelper, document.body, this.componentManager,
                this.logger, this.appBarHandler, toolstripHandler, pubSubUiHelper, new FoundationPanelHandler(document.body, $, Foundation), deviceMenuHandler,
                this.translator, this.uiManager, this.sharedSettingsProvider, this.applicationConfig);

            this.appBarHandler.onToggleEdit = async (isEnabled: boolean) => {
                return new Promise<boolean>((resolve) => {
                    if (!this.configLoader) {
                        resolve(false);
                        return;
                    }
                    Promise.all([this.configLoader.getAllComponentConfigs(), this.languageManager.loadAllLanguageFiles()]).then(() => {
                        if (this.uiManager) {
                            this.uiManager.unlockView();
                        }
                        if (this.configUiHelper) {
                            // Check if the view has sub view container, otherwise it comes from an error/broken page.
                            const hasSubViews = !!this.rootElement.querySelector('.sub-view');
                            if (hasSubViews) {
                                return this.configUiHelper.toggleEdit().then(() => {
                                    resolve(!isEnabled);
                                }).catch((err: Error) => {
                                    this.logger.error(this.className, methodName, 'Failed to toggle edit on ConfigUiHelper', err);
                                    resolve(isEnabled);
                                });
                            } else if (this.currentlyDisplayedViewConfig) {
                                // Try to reinit the view since it's broken
                                this.setUpView(this.currentlyDisplayedViewConfig.id, isEnabled).then(() => {
                                    return this.configUiHelper.toggleEdit().then(() => {
                                        resolve(!isEnabled);
                                    }).catch((err: Error) => {
                                        this.logger.error(this.className, methodName, 'Failed to toggle edit on ConfigUiHelper', err);
                                        resolve(isEnabled);
                                    });
                                }).catch((error) => {
                                    this.logger.error(this.className, methodName, 'Failed to reinit view from error state', error);
                                });
                            }
                        }
                        return resolve(!isEnabled);
                    }).catch((error) => {
                        this.logger.error(this.className, methodName, 'Failed to toggle edit on ConfigUiHelper', error);
                        resolve(isEnabled);
                    });
                });
            };
            if (this.appBarHandler.menuBarHandler) {
                this.appBarHandler.menuBarHandler.onSave = this.configUiHelper.onSave.bind(this.configUiHelper);
                this.appBarHandler.onSave = this.configUiHelper.onSave.bind(this.configUiHelper);
                this.appBarHandler.menuBarHandler.onSettings = () => {
                    this.assert(this.appBarHandler, 'AppBarHandler');
                    this.assert(this.settingsHelper, 'SettingsHelper');
                    this.appBarHandler.menuBarHandler?.setEditModeEnabled(false); // Disable edit mode for settings panel
                    this.settingsHelper.init().catch((err) => {
                        this.logger.error(this.className, methodName, 'Failed to initialize SettingsHelper', err);
                    });
                };
            }
            this.configUiHelper.setupConfig();

            // Sharing
            const globalIdentitySharer = new GlobalIdentitySharer(document, this.languageManager.getLanguageProvider('framework'), this.logger);
            globalIdentitySharer.onIdentityShared = new MoveShareBehavior(this.logger, this.serviceManager, this.viewManager, NotificationHelper,
                this.languageManager.getLanguageProvider('framework'));
            globalIdentitySharer.init();
            this.intentManager.addIntentHandler(new ShareIntentHandler(globalIdentitySharer));

            this.userConfigManager = new UserConfigManager(
                this.viewManager,
                this.requestExecutor,
                this.configLoader,
                this.globalConfig,
                this.metadataStore,
                this.applicationPubSubHandler,
                this.popupHelper,
                this.logger,
                this.languageManager);
            this.userConfigManager.onUserConfigChanged((viewId, componentGuid, componentConfig) => {
                if (this.configLoader) {
                    this.configLoader.updateComponentSetting(viewId, componentGuid, componentConfig);
                }
            });

            this.extensionProvider.setExtension(new UserConfigExtension(this.userConfigManager, this.logger));
            if (!Application.hasExternalCore()) {
                const contextMenuDataWebServiceHandler = new ContextMenuDataWebServiceHandler(this.serviceManager, this.windreamScriptExecutor,
                    NotificationHelper, this.languageManager.getLanguageProvider('framework'));
                const windreamContextMenuCreator = new WindreamContextMenuCreator(contextMenuDataWebServiceHandler, new ContextMenuItemValidator(), this.logger, this.serviceManager);
                this.extensionProvider.setExtension(new ContextMenuExtension(windreamContextMenuCreator, this.viewManager));
            }
            this.generateNewApi();
            // Initialize the view on page load

            HotFix.runOnReady();
            // Skip intial promise loading for better routing since now everything is route based.
            if (RouteManager.navigate(window.location.pathname, true)) {
                // Make app available since some routers don't init a view
                this.uiManager.appIsAvailable();
                return Promise.resolve();
            } else {
                return Promise.reject('Could not find matching route for routers');
            }
        }).catch((err: Error) => {
            this.assert(this.staticPageHelper, 'StaticPageHelper');
            this.logger.error(this.className, methodName, 'Error while handling login', err);
            if (this.applicationConfig && this.applicationConfig.activeViews && this.applicationConfig.activeViews.length === 0) {
                // No views error
                this.staticPageHelper.render(this.rootElement, require('./staticPage/templates/noViews.html'));
            } else if (!this.applicationConfig) {
                // No ApplicationConfig means no Webservice available
                this.staticPageHelper.render(this.rootElement, require('./staticPage/templates/webserviceNotFoundErrorPage.html'));
            } else {
                // Unknown error
                this.staticPageHelper.render(this.rootElement, require('./staticPage/templates/unknownError.html'));
            }
            this.uiManager.appIsAvailable();
        });
    }

    /**
     * Handle the user name display in the header.
     *
     * @private
     * @param {string} userName The user name.
     * @param {boolean} shouldBeDisplayed Whether the name should be displayed or not.
     * @memberof Application
     */
    private handleUserNameDisplay(userName: string, shouldBeDisplayed: boolean): void {
        if (shouldBeDisplayed) {
            this.jQueryRef('.wd-user-name').text(userName);
        } else {
            this.jQueryRef('.wd-user-name').text('');
        }
    }

    /**
     * Sets up a new view.
     *
     * @private
     * @param {string} viewId  ID of the view to set up.
     * @param {boolean} [isInEditMode] Whether the view should be setup in edit mode.
     * @returns {Promise<ViewConfig>} Promise to resolve with the config of the set up view.
     * @async
     *
     * @memberof Application
     */
    private async setUpView(viewId: string, isInEditMode?: boolean): Promise<ViewConfig> {
        return new Promise<ViewConfig>((resolve, reject) => {
            const methodName = 'setUpView';
            this.assert(this.viewManager, 'ViewManager');
            this.uiManager.appIsLoading();
            this.rootElement.innerHTML = '';
            this.appFocusHandler.unsetFocus();
            // Get view id from alias.
            const viewIdFromAlias = this.applicationConfig?.activeViews.find((view: ViewConfigMetaData) => view.alias?.toLowerCase() === viewId.toLowerCase());
            if (viewIdFromAlias && viewIdFromAlias.alias) {
                viewId = viewIdFromAlias.id;
                // Since it's an alias we need to save the id within the state
                RouteManager.replaceCurrentPath(window.location.pathname,
                    true, true, ViewRouter.generateNavigationUrlForViewId(viewIdFromAlias.id));
            }
            const viewInitializationOptions = new ViewInitializationOptions();
            viewInitializationOptions.promoteLifecycleSteps = !isInEditMode;
            this.viewManager.initView(viewId, viewInitializationOptions, undefined).then(async (viewConfig: ViewConfig) => {
                this.currentlyDisplayedViewConfig = viewConfig;
                this.storedViewConfigs.set(viewConfig.id, viewConfig);
                HotFix.runOnViewChange();
                const frameworkLanguageProvider = this.languageManager.getLanguageProvider('framework');
                this.assert(this.userConfigManager, 'UserConfigManager');
                this.assert(this.languageManager, 'LanguageManager');
                this.assert(this.serviceManager, 'ServiceManager');
                HotFix.runOnViewChange();
                this.sharedSettingsProvider?.clearTempSettings();
                this.sharedSettingsProvider?.setViewSettings(viewConfig.settings);

                // Try to unlock the current (maybe locked) view.
                this.uiManager.unlockView();
                if (this.configUiHelper) {
                    this.configUiHelper.updateCurrentViewConfig(viewConfig);
                }
                this.userConfigManager.setViewId(viewConfig.id);
                const translatedViewName = frameworkLanguageProvider.getTranslationFromProperty(viewConfig.name);
                document.title = translatedViewName + ' - ' + this.themeManager.getTheme().browserTitle;
                // Set view title to top bar
                const viewNameElement = document.getElementById('wd-active-view-name');
                if (viewNameElement) {
                    viewNameElement.textContent = translatedViewName;
                }

                if (this.appBarHandler && this.appBarHandler.menuBarHandler) {
                    this.appBarHandler.menuBarHandler.setEditModeEnabled(true);
                }

                const subViewConfig = viewConfig.subViews[0];
                if (this.appFocusHandler && subViewConfig) {
                    this.appFocusHandler.subViewChanged(subViewConfig);
                }
                let canEditView = false;
                // Check if user is Admin
                if (!viewId.startsWith(ConfigLoader.STATIC_VIEW_PREFIX)) { // Do not fetch rights for static views

                    let viewRights: DWCore.Views.ViewRights = DWCore.Views.ViewRights.Undefined;
                    if (Application.hasExternalCore() && DynamicWorkspace.Extensions.core.viewProvider) {
                        viewRights = await DynamicWorkspace.Extensions.core.viewProvider.getViewRights(viewId);

                    } else {
                        viewRights = await this.serviceManager.getServices().DynamicWorkspace.getViewRights(new ViewRequestOptions(viewId));
                    }

                    canEditView = ((viewRights & DWCore.Views.ViewRights.Edit) === DWCore.Views.ViewRights.Edit);
                    if (this.appBarHandler) {
                        this.appBarHandler.toggleEditPossible(canEditView);
                    }
                }
                if (viewConfig.components.length === 0 && !isInEditMode) {
                    if (this.subViewManager) {
                        this.subViewManager.clear();
                    }
                    if (canEditView && !DeviceDetection.isMobile()) {
                        this.staticPageHelper.render(this.rootElement, require('./staticPage/templates/emptyViewCanEdit.html'));
                        const editButton = document.querySelector<HTMLDivElement>('.wd-enter-edit-mode-inline-button');
                        if (editButton && frameworkLanguageProvider) {
                            const editModeInlineButton = this.uiComponentFactory.button(editButton);
                            editModeInlineButton.bootstrap();
                            editModeInlineButton.setOptions({
                                text: frameworkLanguageProvider.get('framework.static.emptyView.editButton.title'),
                                stylingMode: 'contained'
                            });
                            editModeInlineButton.onClick = () => {
                                this.appBarHandler.enterEditModeAsync().then(() => {
                                    // Do nothing since we are in editmode
                                }).catch((error) => {
                                    this.logger.error(this.className, methodName, 'Failed to enter edit mode via router', error);
                                });
                            };
                        }
                    } else {
                        this.staticPageHelper.render(this.rootElement, require('./staticPage/templates/emptyView.html'));
                    }
                }
                this.uiManager.appIsAvailable();
                resolve(viewConfig);
            }).catch((err: Error) => {
                this.logger.error(this.className, 'setUpView', 'Error', err);
                if (this.appBarHandler && this.appBarHandler.menuBarHandler) {
                    this.appBarHandler.menuBarHandler.setEditModeEnabled(false);
                }
                reject(err);
            });
        });
    }

    /**
     * Append an ErrorHandler to window.onerror.
     * Append an ErrorHandler to window.onunhandledrejection.
     *
     * @private
     *
     * @memberof Application
     */
    private registerUnexpectedErrorHandler(): void {
        if (window) {
            const errorEventHandler = (errorEvent: string | ErrorEvent, uri: string, lineNumber: number, columnNumber: number) => {
                if (typeof errorEvent !== 'string') {
                    if (errorEvent.message.indexOf('SyntaxError') > -1) {
                        return;
                    }
                } else if (errorEvent.indexOf('SyntaxError') > -1) {
                    return;
                }
                let errorTitle = 'Error';
                if (this.languageManager) {
                    if (this.languageManager.getLanguageProvider('framework')) {
                        errorTitle = this.languageManager.getLanguageProvider('framework').get('framework.generic.error');
                    }
                }
                let errorBody = 'Unknown error occured';
                let dataToLog: any = errorEvent;
                let bodyToLog = 'Unknown error occured';

                if (typeof errorEvent === 'string') {
                    bodyToLog = errorEvent;
                } else if (errorEvent.error && errorEvent.error instanceof Error) {
                    errorBody = errorEvent.error.message;
                    dataToLog = errorEvent.error;
                }
                this.logger.fatalException('Uncaught Exception', bodyToLog, uri, lineNumber, columnNumber, dataToLog);
                NotificationHelper.Instance.error({ body: errorBody, title: errorTitle });
            };
            window.addEventListener('error', errorEventHandler.bind(this));

            const unhandledRejectionEventHandler = (err: PromiseRejectionEvent) => {
                let errorTitle = 'Error';
                if (this.languageManager) {
                    if (this.languageManager.getLanguageProvider('framework')) {
                        errorTitle = this.languageManager.getLanguageProvider('framework').get('framework.generic.error');
                    }
                }
                let errorBody = 'Unknown error occured';
                let dataToLog: any = err;
                let bodyToLog = 'Unknown error occured';

                if (typeof err.reason === 'string') {
                    bodyToLog = err.reason;
                } else if (err.reason && err.reason instanceof Error) {
                    errorBody = err.reason.message;
                    bodyToLog = err.reason.message;
                    dataToLog = err.reason;
                }

                this.logger.error('Application', 'Unhandled rejection', bodyToLog, dataToLog);
                NotificationHelper.Instance.error({ body: errorBody, title: errorTitle });
            };

            window.addEventListener('unhandledrejection', unhandledRejectionEventHandler.bind(this));
        }
    }
    /**
     * Append an EventHandler to unload and beforeunload.
     *
     * @private
     *
     * @memberof Application
     */
    private registerUnloadHandler(): void {
        const unloadDelay = 1000;
        if (window) {
            let timeout: any;
            if (!window.onunload) {
                window.onunload = () => {
                    clearTimeout(timeout);
                    if (this.viewManager) {
                        this.viewManager.unload();
                    }
                };
            }
            window.onbeforeunload = () => {
                // TODO cancel event if something needs user action
                timeout = setTimeout(() => {
                    // TODO save changes
                }, unloadDelay);
                // TODO return whether changes occured or not
            };

        }
    }

    /**
     * Loads and applies the custom theme if set in GlobalConfig.
     *
     * @private
     * @returns {Promise<void>} Promise to resolve when the custom theme has been set.
     * @memberof Application
     */
    private async loadCustomTheme(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.assert(this.globalConfig, 'GlobalConfig');
            if (this.globalConfig.customConfig && this.configLoader) {
                // Load custom config for themeing
                this.configLoader.loadCustomConfig().then((customConfig: CustomConfig) => {
                    this.themeManager?.setCustomConfig(customConfig);
                    resolve();
                }).catch((err) => {
                    this.logger.error(this.className, 'loadCustomTheme', 'Failed to get CustomConfig', err);
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    /**
     * Loads the user language and sets it to the CultureHelper instance.
     *
     * @private
     * @returns {Promise<void>} Promise to resolve after the language was set.
     * @memberof Application
     */
    private async loadUserLanguage(): Promise<void> {
        const injectedAppObject = DeviceDetection.getGloballyInjectedAppObject();
        if (injectedAppObject && injectedAppObject.currentAppLanguage) {
            const appLanguage = injectedAppObject.currentAppLanguage;
            this.cultureHelper.setUserLanguage(appLanguage);
            this.cultureHelper.setCurrentCulture(appLanguage);
            this.requestExecutor.setLanguage(this.cultureHelper.getCurrentLanguage());
            this.setLanguageTag(appLanguage);
            return Promise.resolve();
        } else {
            // TODO: Better capsulation
            return (this.extensionProvider.getExtension('storage') as IStorageExtension).load('com.windream.profile.settings', 'currentLanguage').then((userLanguage: string) => {
                this.logger.debug(this.className, 'loadUserLanguage', 'Got language', userLanguage);
                this.cultureHelper.setUserLanguage(userLanguage);
                this.cultureHelper.setCurrentCulture(userLanguage);
                this.requestExecutor.setLanguage(this.cultureHelper.getCurrentLanguage());
                this.setLanguageTag(userLanguage);
            }).catch((err: Error) => {
                this.logger.debug(this.className, 'loadUserLanguage', 'Failed to get user language, using fallback', err);
            });
        }
    }

    /**
     * Sets the current html language tag.
     *
     * @private
     * @param {string} currentLanguage
     * @memberof Application
     */
    private setLanguageTag(currentLanguage: string): void {
        document.documentElement.setAttribute('lang', currentLanguage);
    }

    private assert(what: any, msg?: string): asserts what {
        msg = msg ? `${msg} is not set` : 'Property not set';
        if (!Utils.Instance.isDefined(what)) {
            this.logger.error(this.className, 'assert', msg, what);
            throw new Error(msg);
        }
    }

    /**
     * Sets up the external core.
     *
     * @private
     * @returns {Promise<void>}
     * @memberof Application
     */
    private setupExternalCore(): Promise<void> {

        return new Promise<void>(async (resolve, reject) => {

            // Check whether a core provider exists.
            if (!this.coreProvider) {
                resolve();
                return;
            }

            // Try to load the external core.
            if (this.coreProvider) {
                let loadedCore: DWCore.Core | undefined;
                try {
                    loadedCore = await this.coreProvider.loadCore();
                } catch (error: any) {
                    if (error) {
                        const coreError = error as ExternalCoreError;
                        if (coreError.code && coreError.code === ExternalCoreErrorCode.notFound) {
                            resolve();
                            return;
                        }

                        if (error.message) {
                            // eslint-disable-next-line no-console
                            console.error(error.message);
                        }
                    }

                    // TODO: Show error page
                    // this.staticPageHelper.renderError(require('./staticPage/templates/externalCoreLoadingError.html'));
                    // this.uiManager.appIsAvailable();
                    reject(error);
                    return;
                }

                if (!loadedCore) {
                    reject(new ExternalCoreError('The loaded core is undefined.'));
                    return;
                }

                // Try to set the core instance and set it to the global window object.
                if (!DynamicWorkspace.Extensions) {
                    DynamicWorkspace.Extensions = {
                        core: loadedCore
                    };
                } else if (!DynamicWorkspace.Extensions.core) {
                    DynamicWorkspace.Extensions.core = loadedCore;
                }

                if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core) {
                    this.logger.debug(this.className, 'setupExternalCore()', '', 'Core loaded: ' + DynamicWorkspace.Extensions.core.name);
                }

                resolve();
                return;
            }
        });

    }

}