// External library does not need linting
// Code taken from https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
export class Base64 {

    public decode(s: string): string {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(s).split('').map(function (c) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    public encode(s: string): string {
        // First we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(s).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(_match, p1) {
                // @ts-ignore - Ignored because this is an external libraray
                return String.fromCharCode('0x' + p1);
        }));
    }

}