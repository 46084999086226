import { CacheData } from './cacheData';
/**
 * Represents the saved data inside the IndexedDB.
 * 
 * @export
 * @class IndexedDBCacheFormat
 */
export class IndexedDBCacheFormat {
    /**
     * The data within the cache.
     * 
     * @type {CacheData}
     * @memberof IndexedDBCacheFormat
     */
    public data?: CacheData | string;

    /**
     * Whether the entry is encrypted or not.
     *
     * @type {boolean}
     * @memberof IndexedDBCacheFormat
     */
    public isEncrypted: boolean = false;

    /**
     * The hash identifier.
     * 
     * @type {string}
     * @memberof IndexedDBCacheFormat
     */
    public hash?: string;
}