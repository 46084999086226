import { FormatHelper } from './formatHelper';

/**
 * Provides the file size helper, which will convert sizes into the correct display value.
 * 
 * @export
 * @class FileSizeFormatHelper
 * @extends {CultureInfo}
 */
export class FileSizeFormatHelper extends FormatHelper {

    /**
     * Gets the file size as a string.
     * 
     * @param {number} size The size which shall be converted.
     * @param {number} decimals The decimals count.
     * @returns {string} 
     * 
     * @memberof FileSizeFormatHelper
     */
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public getFileSizeString(size: number, decimals: number = 2): string {
        if (size === 0) {
            return '0 KB';
        }
        const k = 1000,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(size) / Math.log(k));
            // Return 1 KB if file is less than 1 KB large
            if (i === 0) {
                return '1 KB';
            }
        return parseFloat((size / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    }
}