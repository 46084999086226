import { Logger } from '../logging';
import { IPubSubHandlerArrayObject, IPubSubRecursionCheckSteps } from '../pubSub/interfaces';
import { PubSubLinkedNode, PubSubTickModel } from './models';


/**
 * This class will check if a recursion within pubsub occured.
 *
 * @export
 * @class PubSubRecursionChecker
 */
export class PubSubRecursionChecker {

    private pubSubRecursionCheckSteps: IPubSubRecursionCheckSteps;
    private mapping: IPubSubHandlerArrayObject | undefined;
    private readonly maxAllowedRecursions = 10;
    private readonly maxDelta = 10;
    private readonly minAmountOfFailedDeltas = 10;
    private readonly className = 'PubSubRecursionChecker';
    private logger: Logger;
    private nodes = new Array<PubSubLinkedNode<string>>();

    /**
     * Creates an instance of PubSubRecursionChecker.
     * 
     * @param {Logger} logger The logger.
     * @param {IPubSubHandlerArrayObject} [mapping] The mapping.
     * @memberof PubSubRecursionChecker
     */
    public constructor(logger: Logger, mapping?: IPubSubHandlerArrayObject) {
        this.pubSubRecursionCheckSteps = {};
        this.mapping = mapping;
        this.logger = logger;
    }

    /**
     * Adds a new recrusion check step.
     *
     * @param {string} subscriberGuid The subscriber guid.
     * @param {string} subscriberEvent The subscriber event.
     * @memberof PubSubRecursionChecker
     */
    public addRecursionCheckStep(subscriberGuid: string, subscriberEvent: string): void {
        if (!this.pubSubRecursionCheckSteps[subscriberGuid]) {
            this.pubSubRecursionCheckSteps[subscriberGuid] = {};
        }
        const tickModel = this.pubSubRecursionCheckSteps[subscriberGuid][subscriberEvent];
        if (!tickModel) {
            this.pubSubRecursionCheckSteps[subscriberGuid][subscriberEvent] = new PubSubTickModel();
        } else if (tickModel) {
            if (tickModel.tick(new Date().getTime()) > this.maxAllowedRecursions + 1) {
                const deltas = tickModel.getDeltas();
                let avgDelta = 0;
                for (let i = deltas.length - 1; i >= 0; i--) {
                    avgDelta += deltas[i];
                }
                avgDelta /= this.maxAllowedRecursions;
                if (avgDelta < this.maxDelta) {
                    if(tickModel.failedDelta() >= this.minAmountOfFailedDeltas) {
                        this.logger.info(this.className, 'addRecursionCheckStep', 'Found low delta, will check for recursion.');
                        // Delete every step since it found something suspicious
                        this.pubSubRecursionCheckSteps = {};
                        this.nodes.length = 0;
                        this.checkRecursion(subscriberGuid, subscriberGuid, subscriberEvent, subscriberEvent,
                            new PubSubLinkedNode<string>(subscriberGuid + subscriberEvent));
                        this.nodes.length = 0;
                    }
                } else {
                    // Reset this recursion step.
                    tickModel.reset();
                }
            }
        }
    }


    /**
     * Checks for a recursion in pubsub.
     *
     * @private
     * @param {string} subscriberGuid The subscriber guid.
     * @param {string} originalSubscriberGuid The original subscirber guid.
     * @param {string} subscriberEvent The subscriber event.
     * @param {string} originalSubscriberEvent The original subscriber event.
     * @param {PubSubLinkedNode<string>} rootNode The root node.
     * @param {number} [iterationCount=0] The iteration count.
     * @memberof PubSubRecursionChecker
     */
    private checkRecursion(subscriberGuid: string, originalSubscriberGuid: string, subscriberEvent: string, originalSubscriberEvent: string,
        rootNode: PubSubLinkedNode<string>, iterationCount: number = 0): void {
        if (this.mapping && subscriberGuid in this.mapping) {
            const allPublisherEvents = this.mapping[subscriberGuid];
            for (const eventName in allPublisherEvents) {
                if (eventName in allPublisherEvents) {
                    allPublisherEvents[eventName].forEach((subscriber) => {
                        if (subscriberGuid === originalSubscriberGuid && subscriberEvent === originalSubscriberEvent && iterationCount > 0) {
                            this.logger.error(this.className, 'checkRecursion', 'Pubsub connections encountered a recursion, please check the connections starting from: '
                                + originalSubscriberGuid);
                            throw new Error('Recursion occured in pubsub events');
                        } else {
                            const existingNode = this.nodes.find((node) => node.data === subscriber.guid + subscriber.eventName);
                            if (!existingNode) {
                                const childNode = new PubSubLinkedNode<string>(subscriber.guid + subscriber.eventName);
                                this.nodes.push(childNode);
                                rootNode.next.push(childNode);
                                this.checkRecursion(subscriber.guid, originalSubscriberGuid,
                                    subscriber.eventName, originalSubscriberEvent, childNode, ++iterationCount);
                            } else {
                                rootNode.next.push(existingNode);
                            }
                        }
                    });
                }
            }
        }
    }
}