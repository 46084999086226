import { SubViewType } from '../../../typings/core';
import { HotFix } from '../hotfix';
import { ISubViewManager } from '../loader';
import { Logger } from '../logging/logger';
import { IPopupHelper } from '../ui';
import { PopupStackItem } from './popupStackItem';
import { TriggerHandler } from './triggerHandler';

/**
 * The PopupTransitionTriggerHandler to handle popup transistions.
 * 
 * @export
 * @class PopupTransitionTriggerHandler
 * @extends {TriggerHandler}
 */
export class PopupTransitionTriggerHandler extends TriggerHandler {
  private subViewManager: ISubViewManager;
  private openPopups: Array<PopupStackItem>;
  private subViewContainer?: HTMLElement;
  private popupHelper: IPopupHelper;
  private className: string = 'PopupTransitionTriggerHandler';
  private readonly POPUP_ID = 'wd-sub-view-popup';

  /**
   * Creates an instance of PopupTransitionTriggerHandler.
   * 
   * @param {ISubViewManager} subViewManager The sub view manager - to get all required information of the sub view to display in the popups.
   * @param {IPopupHelper} popupHelper The popup helper - to handle sub view popups.
   * @param {Logger} logger The loggger.
   * 
   * @memberof PopupTransitionTriggerHandler
   */
  public constructor(subViewManager: ISubViewManager, popupHelper: IPopupHelper, logger: Logger) {
    super(logger);
    // Must start with a lower case character to avoid unwanted parsing in the WebService
    // See #71991
    this.triggerName = 'popupTransitionTriggerHandler';
    this.popupHelper = popupHelper;
    this.subViewManager = subViewManager;
    this.openPopups = [];
  }

  /**
   * Handels the trigger and displays the required popup.
   * 
   * @param {*} subViewId The new subview ID which was specified.
   * 
   * @memberof PopupTransitionTriggerHandler
   */
  public handleTrigger(subViewId: any): void {
    const methodName = 'handleTrigger';
    if (!this.subViewContainer) {
      // Container for popups is the parent of the first subview
      const subView = document.querySelector('.sub-view');
      if (subView) {
        this.subViewContainer = <HTMLElement>subView.parentNode;
      }

      if (!this.subViewContainer) {
        this.logger.error(this.className, methodName, 'No element with class `subview` found.');
      }
    }

    const subViewIdBeforePopup = this.subViewManager.getCurrentSubViewId();
    this.subViewManager.switchRequiresClosePopups = false;

    // Create the popup - combine current subViewId with base popup id to enable multiple popups with different ids
    const currPopupId = this.POPUP_ID + '-' + subViewId;
    if (this.openPopups.find((item) => item.subViewId === subViewId)) {
      // Don't open a new popup if there is already an opened popup for the same subview.
      this.logger.warn(this.className, methodName, 'A popup is already opened for sub view ID ' + subViewId);
      return;
    }
    const popup = this.popupHelper.openPopup({
      body: `<div id="${currPopupId}" class="wd-transition-popup"></div>`,
      destroyOnClose: true,
      destroyModalAlways: true,
      immediatelyOpen: false,
      onClose: () => {
        // Get the last added element from popup id stack - that's the one that was closed
        const closedItem = this.openPopups.pop();
        if (closedItem) {
          // Reset subview currently in popup back to subview container
          const popupNode = document.getElementById(closedItem.id);
          if (popupNode) {
            const oldPopupSubViewNode = popupNode.querySelector('.sub-view');
            if (oldPopupSubViewNode && this.subViewContainer) {
              this.subViewContainer.appendChild(oldPopupSubViewNode);
            }
          }

          // Switch back to last active subview
          if (closedItem.subViewIdBeforePopup) {
            if (closedItem.closedByReset) { // Closed by another transition
              if (closedItem.closedByResetToSubViewId !== closedItem.subViewIdBeforePopup) {
                this.subViewManager.hideSubView(closedItem.subViewIdBeforePopup);
              }
            } else { // Closed by close button or browser backdrop
              this.subViewManager.switchSubView(closedItem.subViewIdBeforePopup, true, undefined, closedItem.subViewTypeBeforePopup);
            }
          }
        }
      },
      size: 'large'
    });

    if (this.openPopups.length === 0 && subViewIdBeforePopup) {
      // Add the current sub view id to the history before opening the popup - if it is the first popup.
      // This ensures that the browser backdrop works everytime correct if it is pressed with open popups (more than once).
      this.subViewManager.addHistoryEntry(subViewIdBeforePopup);
    }

    // Add current popup info to stack to know all open popups and their order
    const subViewTypeBefore = this.openPopups.length > 0 ? SubViewType.Popup : SubViewType.View;
    this.openPopups.push(new PopupStackItem(currPopupId, popup, subViewId, subViewIdBeforePopup, subViewTypeBefore));

    const popupNode = document.getElementById(currPopupId);
    if (popupNode) {
      // Place subview into popup
      const newSubViewNode = this.subViewManager.getNodesPerSubView().get(subViewId);
      if (newSubViewNode) {
        popupNode.appendChild(newSubViewNode);
      } else {
        this.logger.warn(this.className, methodName, 'No subview found for ID ' + subViewId);
      }
    }

    const hotfixDelay = 500; // This delay has almost none impact of usability. Will fix http://teamsrv:8080/tfs/Windream/Windream/WebClient/_workitems?id=76673&fullScreen=false&_a=edit
    if (popup) {
      // TODO: make open etc. async
      popup.open();

      // Re-render charts in case of subview is loaded.
      setTimeout(() => {
        HotFix.runOnSubViewChange(subViewId);
        // TODO: let subviewManager render the whole popup thing since it's the manager.
        this.subViewManager.showSubView(subViewId, SubViewType.Popup);

        // TODO: Find workaround for gridstack cellheight inside popup
        // Resize cellheight will trigger resize in gridstack
        const element = document.querySelector('#' + currPopupId) as HTMLElement;
        const gridElement = element?.getElementsByClassName('grid grid-stack')[0] as any;
        gridElement?.gridstack?.cellHeight(gridElement.gridstack.getCellHeight() + 1);
      }, hotfixDelay);
    }
  }

  /**
   * Closes the popup.
   *
   * @protected
   * @param {string} subViewId ID of the sub view that will be displayed next.
   * @memberof PopupTransitionTriggerHandler
   */
    protected resetTrigger(subViewId: string): void {
      if (this.subViewManager.switchRequiresClosePopups) {
        // Reset was triggered by a direct transition - requires closing of all popups
        this.openPopups.forEach((item) => {
          item.closedByReset = true;
          item.closedByResetToSubViewId = subViewId;
          item.popup.close();
         });
        // Reset flags
        this.subViewManager.switchRequiresClosePopups = false;
      }
    }
}