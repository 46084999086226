import {
    DirectoryRightsDTO,
    DirectoryRightsResponseContainerDTO,
    DocumentRightsDTO,
    DocumentRightsResponseContainerDTO,
    MemberTypesDTO,
    UpdateDirectoryRightsDTO,
    UpdateDocumentRightsDTO
} from '../../typings/windreamWebService/Windream.WebService';
import { WindreamIdentity } from '../common';
import { IdentityMapper } from '../services';
import { DocumentRightsModel, IdentityRightsModel, RIGHT_ENTITIES, RightModel, RIGHTS } from './models';
import { DirectoryRightsModel } from '.';

/**
 * Converts DTO to model and model to DTO for rights in windream.
 * 
 * @export
 * @class IdentityRightsModelConverter
 */
export class IdentityRightsModelConverter {
    /**
     * Convert the given DTO to a model for documents.
     * 
     * @param {DocumentRightsResponseContainerDTO} dto DTO to convert.
     * @returns {DocumentRightsModel} The converted model.
     * @memberof IdentityRightsModelConverter
     */
    public convertDocumentRightsToModel(dto: DocumentRightsResponseContainerDTO): DocumentRightsModel {
        const result = new DocumentRightsModel();
        if (dto.DocumentRights) {
            dto.DocumentRights.forEach((user) => {
                const entity = user.Member.MemberType === MemberTypesDTO.User ? RIGHT_ENTITIES.USER : RIGHT_ENTITIES.GROUP;
                const entry = new IdentityRightsModel(user.Member.Name, user.Member.Domain, entity);
                entry.description = user.Member.FullName;
                entry.rights = this.createRightModelsForDocumentFromDto(user.Rights);
                result.documentRights.push(entry);
            });
        }
        return result;
    }

    /**
     * Convert the given DTO to a model for directories.
     * 
     * @param {DirectoryRightsResponseContainerDTO} dto DTO to convert.
     * @returns {DirectoryRightsModel} The converted model.
     * @memberof IdentityRightsModelConverter
     */
    public convertDirectoryRightsToModel(dto: DirectoryRightsResponseContainerDTO): DirectoryRightsModel {
        const result = new DirectoryRightsModel();
        if (dto.DirectoryRights) {
            dto.DirectoryRights.forEach((user) => {
                const entity = user.Member.MemberType === MemberTypesDTO.User ? RIGHT_ENTITIES.USER : RIGHT_ENTITIES.GROUP;
                const entry = new IdentityRightsModel(user.Member.Name, user.Member.Domain, entity);
                entry.description = user.Member.FullName;
                entry.rights = this.createRightModelsForDirectoryFromDto(user.Rights);
                result.directoryRights.push(entry);
            });
        }
        if (dto.InheritableDocumentRights) {
            dto.InheritableDocumentRights.forEach((user) => {
                const entity = user.Member.MemberType === MemberTypesDTO.User ? RIGHT_ENTITIES.USER : RIGHT_ENTITIES.GROUP;
                const entry = new IdentityRightsModel(user.Member.Name, user.Member.Domain, entity);
                entry.description = user.Member.FullName;
                entry.rights = this.createRightModelsForDocumentFromDto(user.Rights);
                result.inheritableDocumentRights.push(entry);
            });
        }
        if (dto.InheritableDirectoryRights) {
            dto.InheritableDirectoryRights.forEach((user) => {
                const entity = user.Member.MemberType === MemberTypesDTO.User ? RIGHT_ENTITIES.USER : RIGHT_ENTITIES.GROUP;
                const entry = new IdentityRightsModel(user.Member.Name, user.Member.Domain, entity);
                entry.description = user.Member.FullName;
                entry.rights = this.createRightModelsForDirectoryFromDto(user.Rights);
                result.inheritableDirectoryRights.push(entry);
            });
        }
        return result;
    }

    /**
     * Converts the given model to a DTO for documents.
     * 
     * @param {DocumentRightsModel} model Model to convert.
     * @param {WindreamIdentity} identity Identity the rights belong to.
     * @returns {UpdateDocumentRightsDTO} The converted DTO.
     * @memberof IdentityRightsModelConverter
     */
    public convertDocumentRightsToDto(model: DocumentRightsModel, identity: WindreamIdentity): UpdateDocumentRightsDTO {

        const item = IdentityMapper.getWebServiceIdentity(identity);
        const result: UpdateDocumentRightsDTO = {
            DocumentRights: [],
            Item: item
        };

        model.documentRights.forEach((row) => {
            let value = 0;
            row.rights.forEach((right) => {
                if (right.isSet) {
                    switch (right.right) {
                        case RIGHTS.FILE_WRITE: value += DocumentRightsDTO.FileWrite;
                            break;
                        case RIGHTS.INDEX_WRITE: value += DocumentRightsDTO.IndexWrite;
                            break;
                        case RIGHTS.PROHIBIT_DATA_READ: value += DocumentRightsDTO.ProhibitDataRead;
                            break;
                        case RIGHTS.READ: value += DocumentRightsDTO.Read;
                            break;
                        case RIGHTS.SET_RIGHT: value += DocumentRightsDTO.SetRight;
                            break;
                        case RIGHTS.SUBSCRIBE: value += DocumentRightsDTO.Subscribe;
                            break;
                    }
                }
            });
            result.DocumentRights.push({
                Member: {
                    Domain: row.domain,
                    MemberType: row.entity === RIGHT_ENTITIES.GROUP ? MemberTypesDTO.Group : MemberTypesDTO.User,
                    Name: row.name
                },
                Rights: value
            });
        });
        return result;
    }

    /**
     * Converts the given model to a DTO for directories.
     * 
     * @param {DirectoryRightsModel} model Model to convert.
     * @param {WindreamIdentity} identity Identity the rights belong to.
     * @returns {UpdateDirectoryRightsDTO} The converted DTO.
     * @memberof IdentityRightsModelConverter
     */
    public convertDirectoryRightsToDto(model: DirectoryRightsModel, identity: WindreamIdentity): UpdateDirectoryRightsDTO {
        const item = IdentityMapper.getWebServiceIdentity(identity);
        const result: UpdateDirectoryRightsDTO = {
            DirectoryRights: [],
            InheritableDirectoryRights: [],
            InheritableDocumentRights: [],
            Item: item
        };
        model.directoryRights.forEach((row) => {
            let value = 0;
            row.rights.forEach((right) => {
                if (right.isSet) {
                    switch (right.right) {
                        case RIGHTS.DIRECTORY_CREATE: value += DirectoryRightsDTO.DirectoryCreate;
                            break;
                        case RIGHTS.DIRECTORY_WRITE: value += DirectoryRightsDTO.DirectoryWrite;
                            break;
                        case RIGHTS.FILE_CREATE: value += DirectoryRightsDTO.FileCreate;
                            break;
                        case RIGHTS.INDEX_WRITE: value += DirectoryRightsDTO.IndexWrite;
                            break;
                        case RIGHTS.PROHIBIT_LIST_DIRECTORY: value += DirectoryRightsDTO.ProhibitListDirectory;
                            break;
                        case RIGHTS.READ: value += DirectoryRightsDTO.Read;
                            break;
                        case RIGHTS.SET_RIGHT: value += DirectoryRightsDTO.SetRight;
                            break;
                        case RIGHTS.SUBSCRIBE: value += DirectoryRightsDTO.Subscribe;
                            break;
                    }
                }
            });
            result.DirectoryRights.push({
                Member: {
                    Domain: row.domain,
                    MemberType: row.entity === RIGHT_ENTITIES.GROUP ? MemberTypesDTO.Group : MemberTypesDTO.User,
                    Name: row.name
                },
                Rights: value
            });
        });
        model.inheritableDocumentRights.forEach((row) => {
            let value = 0;
            row.rights.forEach((right) => {
                if (right.isSet) {
                    switch (right.right) {
                        case RIGHTS.FILE_WRITE: value += DocumentRightsDTO.FileWrite;
                            break;
                        case RIGHTS.INDEX_WRITE: value += DocumentRightsDTO.IndexWrite;
                            break;
                        case RIGHTS.PROHIBIT_DATA_READ: value += DocumentRightsDTO.ProhibitDataRead;
                            break;
                        case RIGHTS.READ: value += DocumentRightsDTO.Read;
                            break;
                        case RIGHTS.SET_RIGHT: value += DocumentRightsDTO.SetRight;
                            break;
                        case RIGHTS.SUBSCRIBE: value += DocumentRightsDTO.Subscribe;
                            break;
                    }
                }
            });
            result.InheritableDocumentRights.push({
                Member: {
                    Domain: row.domain,
                    MemberType: row.entity === RIGHT_ENTITIES.GROUP ? MemberTypesDTO.Group : MemberTypesDTO.User,
                    Name: row.name
                },
                Rights: value
            });
        });
        model.inheritableDirectoryRights.forEach((row) => {
            let value = 0;
            row.rights.forEach((right) => {
                if (right.isSet) {
                    switch (right.right) {
                        case RIGHTS.DIRECTORY_CREATE: value += DirectoryRightsDTO.DirectoryCreate;
                            break;
                        case RIGHTS.DIRECTORY_WRITE: value += DirectoryRightsDTO.DirectoryWrite;
                            break;
                        case RIGHTS.FILE_CREATE: value += DirectoryRightsDTO.FileCreate;
                            break;
                        case RIGHTS.INDEX_WRITE: value += DirectoryRightsDTO.IndexWrite;
                            break;
                        case RIGHTS.PROHIBIT_LIST_DIRECTORY: value += DirectoryRightsDTO.ProhibitListDirectory;
                            break;
                        case RIGHTS.READ: value += DirectoryRightsDTO.Read;
                            break;
                        case RIGHTS.SET_RIGHT: value += DirectoryRightsDTO.SetRight;
                            break;
                        case RIGHTS.SUBSCRIBE: value += DirectoryRightsDTO.Subscribe;
                            break;
                    }
                }
            });
            result.InheritableDirectoryRights.push({
                Member: {
                    Domain: row.domain,
                    MemberType: row.entity === RIGHT_ENTITIES.GROUP ? MemberTypesDTO.Group : MemberTypesDTO.User,
                    Name: row.name
                },
                Rights: value
            });
        });
        return result;
    }

    /**
     * Creates the model for the document rights.
     * 
     * @private
     * @param {DocumentRightsDTO} dto DTO to parse.
     * @returns {RightModel[]} The rights for the document.
     * @memberof IdentityRightsModelConverter
     */
    private createRightModelsForDocumentFromDto(dto: DocumentRightsDTO): RightModel[] {
        const result = [
            new RightModel(RIGHTS.READ, (dto & DocumentRightsDTO.Read) === DocumentRightsDTO.Read),
            new RightModel(RIGHTS.PROHIBIT_DATA_READ, (dto & DocumentRightsDTO.ProhibitDataRead) === DocumentRightsDTO.ProhibitDataRead),
            new RightModel(RIGHTS.INDEX_WRITE, (dto & DocumentRightsDTO.IndexWrite) === DocumentRightsDTO.IndexWrite),
            new RightModel(RIGHTS.FILE_WRITE, (dto & DocumentRightsDTO.FileWrite) === DocumentRightsDTO.FileWrite),
            new RightModel(RIGHTS.SUBSCRIBE, (dto & DocumentRightsDTO.Subscribe) === DocumentRightsDTO.Subscribe),
            new RightModel(RIGHTS.SET_RIGHT, (dto & DocumentRightsDTO.SetRight) === DocumentRightsDTO.SetRight)
        ];
        return result;
    }

    /**
     * Creates the model for the directory rights.
     * 
     * @private
     * @param {DocumentRightsDTO} dto DTO to parse.
     * @returns {RightModel[]} The rights for the directory.
     * @memberof IdentityRightsModelConverter
     */
    private createRightModelsForDirectoryFromDto(dto: DirectoryRightsDTO): RightModel[] {
        const result = [
            new RightModel(RIGHTS.READ, (dto & DirectoryRightsDTO.Read) === DirectoryRightsDTO.Read),
            new RightModel(RIGHTS.PROHIBIT_LIST_DIRECTORY, (dto & DirectoryRightsDTO.ProhibitListDirectory) === DirectoryRightsDTO.ProhibitListDirectory),
            new RightModel(RIGHTS.INDEX_WRITE, (dto & DirectoryRightsDTO.IndexWrite) === DirectoryRightsDTO.IndexWrite),
            new RightModel(RIGHTS.DIRECTORY_WRITE, (dto & DirectoryRightsDTO.DirectoryWrite) === DirectoryRightsDTO.DirectoryWrite),
            new RightModel(RIGHTS.FILE_CREATE, (dto & DirectoryRightsDTO.FileCreate) === DirectoryRightsDTO.FileCreate),
            new RightModel(RIGHTS.DIRECTORY_CREATE, (dto & DirectoryRightsDTO.DirectoryCreate) === DirectoryRightsDTO.DirectoryCreate),
            new RightModel(RIGHTS.SUBSCRIBE, (dto & DirectoryRightsDTO.Subscribe) === DirectoryRightsDTO.Subscribe),
            new RightModel(RIGHTS.SET_RIGHT, (dto & DirectoryRightsDTO.SetRight) === DirectoryRightsDTO.SetRight)
        ];
        return result;
    }
}