import { IIndexedDBWrapper } from '../indexedDB';
import { Logger } from '../logging';
import { IStorage, LocalStorageWrapper } from '../storage';

/**
 * Handles the clear funtionallity for storages.
 * 
 * @export
 * @class MemoryManager
 */
export class MemoryManager {
    private indexedDBWrapper = new Array<IIndexedDBWrapper>();
    private localStorageWrapper = new Array<IStorage>();
    private logger: Logger;


    /**
     * Creates an instance of MemoryManager.
     * @param {Logger} logger The logger.
     * @memberof MemoryManager
     */
    public constructor(logger: Logger) {
        this.logger = logger;
    }

    /**
     * Add indexedDB.
     * 
     * @param {IIndexedDBWrapper} indexedDBWrapper 
     * @memberof MemoryManager
     */
    public addIndexedDB(indexedDBWrapper: IIndexedDBWrapper): void {
        this.indexedDBWrapper.push(indexedDBWrapper);
    }

    /**
     * Add local storages.
     * 
     * @param {IStorage} localStorageWrapper 
     * @memberof MemoryManager
     */
    public addStorage(localStorageWrapper: IStorage): void {
        this.localStorageWrapper.push(localStorageWrapper);
    }

    /**
     * Clear all storages.
     *
     * @returns {Promise<void>} A promise, which will resolve when all storages are done being cleared.
     * @memberof MemoryManager
     */
    public clear(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.indexedDBWrapper.forEach((element) => {
                element.clear().catch((error) => {
                    this.logger.error('MemoryManager', 'clear', 'Can not delete indexedDB', error);
                });
            });
            this.localStorageWrapper.forEach((element) => {
                element.clear();
            });
            // Set last local cache reset.
            const localStorageWrapper = new LocalStorageWrapper();
            localStorageWrapper.setItem('DynamicWorkspace-LastLocalCacheReset', Date.now().toString());
            try {
                // You can only ever access a cache in JS if the context is secure.
                if (isSecureContext && caches && typeof caches.delete === 'function'
                    && typeof caches.keys === 'function') {
                    caches.keys().then((keyList) => {
                        return Promise.all(keyList.map((key) => {
                            return caches.delete(key);
                        })).then(() => {
                            if ('serviceWorker' in navigator) {
                                navigator.serviceWorker.getRegistration('./sw.js').then((registration) => {
                                    registration?.unregister();
                                    resolve();
                                }).catch((error) => {
                                    reject(error);
                                });
                            } else {
                                resolve();
                            }
                        }
                        ).catch((error) => reject(error));
                    }).catch((error) => reject(error));
                } else {
                    resolve();
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}