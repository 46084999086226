import { ViewConfigsResponseContainerDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { ApplicationConfig } from './models';
import { ViewConfigMetaDataFactory } from './viewConfigMetaDataFactory';

/**
 * This class provides a factory, which will generate ApplicationConfig objects from JSON objects or JSON strings.
 * 
 * @export
 * @class ApplicationConfigFactory
 */
export class ApplicationConfigFactory {

    /**
     * Converts the given application configuration DTO into an internal application configuration model.
     * 
     * @static
     * @param {ViewConfigsResponseContainerDTO} viewConfigsDTO The view configurations DTO, which should be converted into an internal view configurations model.
     * @returns {ApplicationConfig} 
     * @memberof ApplicationConfigFactory
     */
    public static convertFromDTO(viewConfigsDTO: ViewConfigsResponseContainerDTO): ApplicationConfig {
        const result = new ApplicationConfig();

        result.activeViews = viewConfigsDTO.ConfigData.map((value) => {
            return ViewConfigMetaDataFactory.convertFromDTO(value);
        });

        return result;
    }

    /**
     * Gets the ApplicationConfig from JSON.
     * 
     * @static
     * @param {(ViewConfigsResponseContainerDTO | string)} json The JSON, which should be transformed into a ApplicationConfig.
     * @returns {ApplicationConfig} 
     * @memberof ApplicationConfigFactory
     */
    public static fromJSON(json: ViewConfigsResponseContainerDTO | string): ApplicationConfig {

        let viewConfigsDTO: ViewConfigsResponseContainerDTO;
        if (typeof json === 'string') {
            viewConfigsDTO = JSON.parse(json);

        } else {
            viewConfigsDTO = json;
        }

        const result = new ApplicationConfig();

        result.activeViews = viewConfigsDTO.ConfigData.map((value) => {
            return ViewConfigMetaDataFactory.convertFromDTO(value);
        });

        return result;
    }

}