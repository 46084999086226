import { DWCore } from '@windream/dw-core/dwCore';
import { DEVICE_NAMES } from '../common/deviceDetection';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { IDeviceMenuHandler } from './index';

/**
 * Handler for the device selection menu.
 * 
 * @export
 * @class DeviceMenuHandler
 * @implements {IDeviceMenuHandler}
 */
export class DeviceMenuHandler implements IDeviceMenuHandler {
    public onDeviceChange?: (newDevice: DWCore.Common.Devices, switchDeviceCallback: () => void) => void;

    private container: HTMLElement;
    private currentDevice: DWCore.Common.Devices;
    private languageProvider: ILanguageProvider;
    private logger: Logger;

    private readonly className = 'DeviceMenuHandler';


    public constructor(container: HTMLElement, languageProvider: ILanguageProvider, logger: Logger) {
        this.container = container;
        this.languageProvider = languageProvider;
        this.logger = logger;

        this.currentDevice = DWCore.Common.Devices.DESKTOP;
    }


    /**
     * Initializes the device switch button.
     * 
     * @memberof DeviceMenuHandler
     */
    public init(): void {

        // Set the dropdown title.
        const dropDownButton = this.container.querySelector<HTMLButtonElement>('button[data-dropdown="devices-dropdown-list"]');
        if (dropDownButton) {
            this.updateTitle(dropDownButton, this.languageProvider.get(`framework.config.device.${dropDownButton.getAttribute('data-device')}`));
        }

        // Set the title for all dropdown items.
        const dropdownPane = this.container.querySelector<HTMLElement>('#devices-dropdown-list');
        if (dropdownPane) {
            const dropdownItems = dropdownPane.querySelectorAll<HTMLElement>('li[data-device]');
            if (dropdownItems && dropdownItems.length > 0) {
                const listItemCount = dropdownItems.length;
                for (let i = 0; i < listItemCount; i++) {
                    const tempListItem = dropdownItems.item(i);
                    if (tempListItem) {
                        this.updateTitle(tempListItem, this.languageProvider.get(`framework.config.device.${tempListItem.getAttribute('data-device')}`));
                    }
                }
            }
            dropdownPane.addEventListener('click', this.switchDeviceListener.bind(this));
        }
    }

    /**
     * Returns the currently selected device.
     * 
     * @returns {DWCore.Common.Devices} The currently selected device.
     * @memberof DeviceMenuHandler
     */
    public getCurrentDevice(): DWCore.Common.Devices {
        return this.currentDevice;
    }

    /**
     * Changes the menu to the given device.
     * 
     * @param {DWCore.Common.Devices} newDevice New device.
     * @memberof DeviceMenuHandler
     */
    public changeDevice(newDevice: DWCore.Common.Devices): void {
        this.currentDevice = newDevice;

        // Switch the drop-down buttons image.
        this.switchDropdownButtonToDevice(newDevice);


        const configDeviceSelect = this.container.querySelector('#wd-config-device-select');
        if (!configDeviceSelect) {
            this.logger.warn(this.className, 'changeDevice', 'No element with id "wd-config-device-select" found!');
            return;
        }
        // Toggle active classes
        if (configDeviceSelect) {
            const activeDeviceButton = configDeviceSelect.querySelector('.active');
            if (activeDeviceButton) {
                activeDeviceButton.classList.remove('active');
            }
        }
    }

    /**
     * Handles click to switch a device.
     * 
     * @private
     * @param {Event} e Event that emitted the device change.
     * @returns {void} 
     * 
     * @memberof DeviceMenuHandler
     */
    private switchDeviceListener(e: Event): void {
        const methodName: string = 'switchDevice';
        const eventTarget = <HTMLElement>e.target;

        // Check whether a switch device button was clicked
        // Needed because this function is bound to the whole document
        // Abort if click was targeted on anything else
        if (!(eventTarget && eventTarget.matches('#wd-config-device-select li[data-device]:not(.active), #wd-config-device-select li[data-device]:not(.active) *'))) {
            return;
        }

        // Recursivly check for parents with data-device set
        // Needed since icons inside the buttons are also click targets
        let _deviceId: number | null = null,
            _deviceIdRecursionCount = 0,
            _currentParent = eventTarget;
        const maxRecursionDepth = 5;

        while (!_deviceId && _deviceIdRecursionCount++ < maxRecursionDepth) {
            const _deviceIdAttr = _currentParent.getAttribute('data-device');
            if (_deviceIdAttr) {
                _deviceId = parseInt(_deviceIdAttr, 10);
            } else {
                if (_currentParent.parentElement) {
                    _currentParent = _currentParent.parentElement;
                }
            }
        }

        if (_deviceId === null) {
            this.logger.error(this.className, methodName, 'Invalid device selected:' + _deviceId);
            return;
        }

        const newDevice: DWCore.Common.Devices = _deviceId;

        if (this.onDeviceChange) {
            this.onDeviceChange(newDevice, () => this.changeDevice(newDevice));
        }
    }


    /**
     * Switches the dropdown button device.
     * 
     * @private
     * @param {DWCore.Common.Devices} device 
     * @memberof DeviceMenuHandler
     */
    private switchDropdownButtonToDevice(device: DWCore.Common.Devices): void {

        const dropDownButton = this.container.querySelector<HTMLButtonElement>('button[data-dropdown="devices-dropdown-list"]');
        if (!dropDownButton) {
            return;
        }

        this.updateDeviceImage(dropDownButton, device);
        this.updateDataDeviceAttribute(dropDownButton, device);
        this.updateDeviceLinkActiveState(device);

        const title = this.languageProvider.get(`framework.config.device.${device}`);
        this.updateTitle(dropDownButton, title);

        this.closeDropdownButton(dropDownButton);
    }


    /**
     * Updates the element's title.
     * 
     * @private
     * @param {HTMLElement} element 
     * @param {string} title 
     * @memberof DeviceMenuHandler
     */
    private updateTitle(element: HTMLElement, title: string): void {
        if (!element) {
            return;
        }

        element.setAttribute('title', title);
    }


    /**
     * Updates the element's data-device attribute.
     * 
     * @private
     * @param {HTMLElement} element 
     * @param {DWCore.Common.Devices} device 
     * @returns {void} 
     * @memberof DeviceMenuHandler
     */
    private updateDataDeviceAttribute(element: HTMLElement, device: DWCore.Common.Devices): void {
        if (!element) {
            return;
        }

        element.setAttribute('data-device', device.toString());
    }


    /**
     * Marks the desired device link as active.
     * 
     * @private
     * @param {DWCore.Common.Devices} device 
     * @memberof DeviceMenuHandler
     */
    private updateDeviceLinkActiveState(device: DWCore.Common.Devices): void {

        // At first remove the 'active' class from all device links.
        const allDeviceLinks = this.container.querySelectorAll<HTMLLIElement>('.wd-devices-dropdown-pane li[data-device]');
        if (allDeviceLinks && allDeviceLinks.length > 0) {
            const linkCount = allDeviceLinks.length;
            for (let i = 0; i < linkCount; i++) {
                const tempDeviceLink = allDeviceLinks.item(i);
                if (tempDeviceLink) {
                    tempDeviceLink.classList.remove('active');
                }
            }
        }

        // Then set the 'active' class to the desired device's link.
        const desiredDeviceLink = this.container.querySelector<HTMLLIElement>('.wd-devices-dropdown-pane li[data-device="' + device.toString() + '"]');
        if (desiredDeviceLink) {
            desiredDeviceLink.classList.add('active');
        }
    }


    /**
     * Updates the element's device image.
     * 
     * @private
     * @param {HTMLElement} element 
     * @param {DWCore.Common.Devices} device 
     * @memberof DeviceMenuHandler
     */
    private updateDeviceImage(element: HTMLElement, device: DWCore.Common.Devices): void {
        // Get the inner span element.
        const innerSpan = element.querySelector<HTMLSpanElement>('span');
        if (innerSpan) {

            // At first remove the old device class.
            DEVICE_NAMES.forEach((deviceName) => {
                innerSpan.classList.remove(deviceName);
            });

            // Then add the new device class.
            innerSpan.classList.add(DEVICE_NAMES[device]);
        }
    }


    /**
     * Closes the given dropdown button.
     * 
     * @private
     * @memberof DeviceMenuHandler
     */
    private closeDropdownButton(dropdownButton: HTMLButtonElement): void {
        if (!dropdownButton) {
            return;
        }

        const dropdownContentId = dropdownButton.getAttribute('aria-controls');
        if (dropdownContentId) {
            const dropdownContentRef = $('#' + dropdownContentId);
            // @ts-ignore - Disabled because of Foundation usage
            if (dropdownContentRef && $.fn.foundation) {
                // @ts-ignore - Disabled because of Foundation usage
                dropdownContentRef.foundation('close');
            }
        }
    }
}