import { HistoryResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HistoryContainer } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { AddHistoryRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform a AddDocumentHistoryEntry call on the windream webservice.
 * 
 * @export
 * @class AddDocumentHistoryEntry
 * @extends {ServiceAction}
 */
export class AddDocumentHistoryEntry extends ServiceAction {

  /**
   * Creates an instance of AddDocumentHistoryEntry.
   *
   * @param {IRequestExecutor} requestExecutor 
   * @param {GlobalConfig} globalConfig 
   * @param {Logger} logger 
   * @memberof AddDocumentHistoryEntry
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'addHistoryEntry';
  }

  /**
   * Adds new history entry for a specified document.
   * Resolves a promise with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {AddHistoryRequestOptions} addHistoryRequestOptions
   * @returns {Promise<HistoryContainer>}
   * @memberof AddDocumentHistoryEntry
   */
  public async do(addHistoryRequestOptions: AddHistoryRequestOptions): Promise<HistoryContainer> {
    return new Promise<HistoryContainer>((resolve, reject) => {

      const url = `${this.globalConfig.windreamWebServiceURL}/documents/AddHistoryEntry`;

      const resourcePointer = new HttpResourcePointer(
        'POST',
        url,
        {
          HistoryComment: addHistoryRequestOptions.comment,
          Item: IdentityMapper.getWebServiceIdentity(addHistoryRequestOptions.identity)
        }
      );

      this.requestExecutor.executeRequest(resourcePointer, addHistoryRequestOptions.requestOptions)
        .then((response: IServiceResponse<HistoryResponseContainerDTO>) => {
          if (response.data?.HasErrors || !response.data?.History) {
            this.logger.error('AddDocumentHistoryEntry', 'do', 'Failed to add history comment', response);
            reject(new Error('Failed to add history comment'));
          } else {
            resolve(HistoryContainer.fromDto(response.data));
          }
        }).catch((err: Error) => {
          this.logger.error('AddDocumentHistoryEntry', 'do', 'Failed to execute request', err);
          reject(err);
        });
    });
  }
}