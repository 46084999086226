import {
    CellData, DataCollection, HeaderData, IdentityRightsModel,
    RightModel, RIGHTS, RIGHT_ENTITIES, RowData, WindreamEntity, WindreamIdentity,
    PageNavigation as PageNavigationBase
} from '../../../typings/core';
import { SearchCondition, SearchGroup, SearchQuery, SearchSorting } from '../../../typings/search';
import { FetchNodesRequestOptions, HttpResourcePointer, SearchRequestOptions, Sorting, WindreamRequestOptions } from '../../../typings/services';
import { ContextMenu, ContextMenuItem, ContextMenuItemConfig } from '../../../typings/ui';
import { IdLocationNameIdentityDTO, ItemResponseDTO } from '../../typings/windreamWebService/Windream.WebService';
import { DirectoryResponseDTO } from '../../typings/windreamWebService/Windream.WebService.Directories';
import { DocumentResponseDTO } from '../../typings/windreamWebService/Windream.WebService.Documents';
import { WindreamAttribute } from '../caching';
import { CellData as ICellData, DataCollection as IDataCollection, HeaderData as IHeaderData, RowData as IRowData } from '../dataCollections';
import { HttpResourcePointer as IHttpResourcePointer } from '../dataProviders';
import {
    ContextMenu as IContextMenu, ContextMenuItem as IContextMenuItem, FetchNodesRequestOptions as IFetchNodesRequestOptions,
    FileDataSource as IFileDataSource, ILanguageProvider, IdentityMapper
} from '../dynamicWorkspace';
import { SearchQuery as ISearchQuery, SearchGroup as ISearchGroup, SearchSorting as ISearchSorting, SearchCondition as ISearchCondition } from '../interface/search';
import { PageNavigation } from '../pagination';
import { IdentityRightsModel as IIdentityRightsModel, RightModel as IRightModel } from '../rights';
import { SearchRequestOptions as ISearchRequestOptions, Sorting as ISorting, WindreamRequestOptions as IWindreamRequestOptions, FileDataSource } from '../services';
import { DataSourceItem, ItemCollectionDataSource } from '../ui/components';
import { DirectoryDetails, DocumentDetails, WindreamIdentityDetails } from './webService';
import { WindreamIdentity as IWindreamIdentity } from './windreamIdentity';

/**
 * Factory for common models used in the application.
 *
 * @export
 * @class ModelFactory
 */
export class ModelFactory {
    /**
     * Creates a WindreamIdentity instance.
     *
     * @returns {WindreamIdentity}
     * @memberof ModelFactory
     */
    public createWindreamIdentity(): WindreamIdentity {
        const result = new IWindreamIdentity();
        return result;
    }
    /**
     * Creates a WindreamIdentityDetails instance.
     *
     * @returns {WindreamIdentityDetails}
     * @memberof ModelFactory
     */
    public createWindreamIdentityDetails(): WindreamIdentityDetails {
        const result = new WindreamIdentityDetails();
        return result;
    }
    /**
     * Creates a WindreamIdentityDetails instance from the web service DTO.
     *
     * @param {(ItemResponseDTO | DocumentResponseDTO)} dto DTO to create from.
     * @returns {WindreamIdentityDetails}
     * @memberof ModelFactory
     */
    public createWindreamIdentityDetailsFromDto(dto: ItemResponseDTO | DocumentResponseDTO): WindreamIdentityDetails {
        const result = WindreamIdentityDetails.fromDto(dto);
        return result;
    }
    /**
     * Creates a DirectoryDetails instance.
     *
     * @returns {DirectoryDetails}
     * @memberof ModelFactory
     */
    public createDirectoryDetails(): DirectoryDetails {
        const result = new DirectoryDetails();
        return result;
    }
    /**
     * Creates a DirectoryDetails instance from the web service DTO.
     *
     * @param {DirectoryResponseDTO} dto DTO to create from.
     * @returns {DirectoryDetails}
     * @memberof ModelFactory
     */
    public createDirectoryDetailsFromDto(dto: DirectoryResponseDTO): DirectoryDetails {
        const result = DirectoryDetails.fromDto(dto);
        return result;
    }
    /**
     * Creates a DocumentDetails instance.
     *
     * @returns {DocumentDetails}
     * @memberof ModelFactory
     */
    public createDocumentDetails(): DocumentDetails {
        const result = new DocumentDetails();
        return result;
    }
    /**
     * Creates a DocumentDetails instance from the web service DTO.
     *
     * @param {DocumentResponseDTO} dto DTO to create from.
     * @returns {DocumentDetails}
     * @memberof ModelFactory
     */
    public createDocumentDetailsFromDto(dto: DocumentResponseDTO): DocumentDetails {
        const result = DocumentDetails.fromDto(dto);
        return result;
    }

    /**
     * Creates a DataSourceItem.
     *
     * @template T
     * @param {T} value The value.
     * @returns {DataSourceItem<T>} The data source item.
     * @memberof ModelFactory
     */
    public createDataSourceItem<T>(value: T): DataSourceItem<T> {
        return new DataSourceItem<T>(value);
    }

    /**
     * Creates a ItemCollectionDataSource.
     *
     * @template T
     * @returns {ItemCollectionDataSource<T>} The item collection.
     * @memberof ModelFactory
     */
    public createItemCollectionDataSource<T>(): ItemCollectionDataSource<T> {
        return new ItemCollectionDataSource<T>();
    }

    /**
     * Creates a WindreamAttribute.
     *
     * @returns {WindreamAttribute}
     * @memberof ModelFactory
     */
    public createWindreamAtrribte(): WindreamAttribute {
        return new WindreamAttribute();
    }

    /**
     * Creates a SearchQuery.
     *
     * @returns {ISearchQuery}
     * @memberof ModelFactory
     */
    public createSearchQuery(): SearchQuery {
        return new ISearchQuery() as SearchQuery;
    }
    /**
     * Creates a SearchSorting.
     *
     * @returns {ISearchSorting}
     * @memberof ModelFactory
     */
    public createSearchSorting(): SearchSorting {
        return new ISearchSorting() as any as SearchSorting;
    }

    /**
     * Creates a SearchGroup.
     *
     * @returns {SearchGroup}
     * @memberof ModelFactory
     */
    public createSearchGroup(): SearchGroup {
        return new ISearchGroup() as SearchGroup;
    }

    /**
     * Creates a SearchCondition.
     *
     * @returns {SearchCondition}
     * @memberof ModelFactory
     */
    public createSearchCondition(): SearchCondition {
        return new ISearchCondition() as SearchCondition;
    }

    /**
     * Creates a SearchQuery.
     *
     * @param {SearchQuery} searchQuery The SearchQuery.
     * @returns {SearchRequestOptions}
     * @memberof ModelFactory
     */
    public createSearchRequestOptions(searchQuery: SearchQuery): SearchRequestOptions {
        const result = new ISearchRequestOptions();
        result.searchQuery = searchQuery as ISearchQuery;

        return result as SearchRequestOptions;
    }

    /**
     * Creates RequestOptions.
     *
     * @param {WindreamIdentity} identity The identity.
     * @returns {WindreamRequestOptions}
     * @memberof ModelFactory
     */
    public createWindreamRequestOptions(identity: WindreamIdentity): WindreamRequestOptions {
        return new IWindreamRequestOptions(identity) as WindreamRequestOptions;
    }

    /**
     * Creates DataCollection.
     *
     * @template T
     * @returns {DataCollection<T>}
     * @memberof ModelFactory
     */
    public createDataCollection<T>(): DataCollection<T> {
        return new IDataCollection<T>() as DataCollection<T>;
    }

    /**
     * Creates HeaderData.
     *
     * @template T
     * @returns {DataCollection<T>}
     * @memberof ModelFactory
     */
    public createHeaderData<T>(): HeaderData<T> {
        return new IHeaderData<T>() as HeaderData<T>;
    }

    /**
     * Creates RowData.
     *
     * @template T
     * @returns {DataCollection<T>}
     * @memberof ModelFactory
     */
    public createRowData<T>(): RowData<T> {
        return new IRowData<T>() as RowData<T>;
    }

    /**
     * Creates CellData.
     *
     * @returns {DataCollection<T>}
     * @memberof ModelFactory
     */
    public createCellData(): CellData {
        return new ICellData() as CellData;
    }

    /**
     * Creates IdentityRightsModel.
     *
     * @param {string} name The name.
     * @param {string} domain The domain.
     * @param {RIGHT_ENTITIES} entity The entity.
     * @returns {IdentityRightsModel}
     * @memberof ModelFactory
     */
    public createIdentityRightsModel(name: string, domain: string, entity: RIGHT_ENTITIES): IdentityRightsModel {
        return new IIdentityRightsModel(name, domain, entity) as IdentityRightsModel;
    }

    /**
     * Creates RightModel.
     *
     * @param {RIGHTS} right The right.
     * @param {boolean} isSet Wheter it is set.
     * @returns {RightModel}
     * @memberof ModelFactory
     */
    public createRightModel(right: RIGHTS, isSet: boolean): RightModel {
        return new IRightModel(right, isSet) as RightModel;
    }

    /**
     * Creates a HttpResoucePointer.
     *
     * @param {string} action The action.
     * @param {string} uri The URI.
     * @param {Object} [parameter] The parameter object.
     * @param {Map<string, string>} [httpHeaders] The headers.
     * @returns {HttpResourcePointer}
     * @memberof ModelFactory
     */
    public createHttpResourcePointer(action: string, uri: string, parameter?: Object, httpHeaders?: Map<string, string>): HttpResourcePointer {
        return new IHttpResourcePointer(action, uri, parameter, httpHeaders) as HttpResourcePointer;
    }

    /**
     * Creates FetchNodesRequestOptions.
     *
     * @param {WindreamIdentity} identity The identity.
     * @param {string[]} [values] The values.
     * @param {Sorting} [sorting] The sorting model.
     * @param {WindreamEntity} [entity] The entity.
     * @returns {FetchNodesRequestOptions}
     * @memberof ModelFactory
     */
    public createFetchNodesRequestOptions(identity: WindreamIdentity, values?: string[], sorting?: Sorting, entity?: WindreamEntity): FetchNodesRequestOptions {
        return new IFetchNodesRequestOptions(identity, values, sorting as any as ISorting, entity) as FetchNodesRequestOptions;
    }

    /**
     * Creates FileDatasource.
     *
     * @returns {FileDataSource}
     * @memberof ModelFactory
     */
    public createFileDataSource(): FileDataSource {
        return new IFileDataSource() as FileDataSource;
    }

    /**
     * Creates ContextMenu.
     *
     * @returns {ContextMenu}
     * @memberof ModelFactory
     */
    public createContextMenu(): ContextMenu {
        return new IContextMenu() as ContextMenu;
    }

    /**
     *  Creates ContextMenuItem.
     *
     * @param {ContextMenuItemConfig} [config] The config.
     * @returns {ContextMenuItem}
     * @memberof ModelFactory
     */
    public createContextMenuItem(config?: ContextMenuItemConfig): ContextMenuItem {
        return new IContextMenuItem(config) as ContextMenuItem;
    }

    /**
     * Gets the webservice identity from a Windream identity.
     *
     * @param {WindreamIdentity} identity The Windream identity.
     * @returns {IdLocationNameIdentityDTO} The result identity.
     * @memberof ModelFactory
     */
    public getWebServiceIdentity(identity: WindreamIdentity): IdLocationNameIdentityDTO {
        return IdentityMapper.getWebServiceIdentity(identity);
    }

    /**
     * Gets the page navigation.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @returns {PageNavigationBase} A page navigation.
     * @memberof ModelFactory
     */
    public getPageNavigation(languageProvider: ILanguageProvider): PageNavigationBase {
        return new PageNavigation(languageProvider);
    }
}