import { HistoryResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HistoryContainer } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to perform a GetHistory call on the windream webservice.
 * 
 * @export
 * @class GetDocumentHistory
 * @extends {ServiceAction}
 */
export class GetDocumentHistory extends ServiceAction {

  /**
   * Creates an instance of GetDocumentHistory.
   * @param {IRequestExecutor} requestExecutor 
   * @param {GlobalConfig} globalConfig 
   * @param {Logger} logger 
   * @memberof GetDocumentHistory
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'getHistory';
  }

  /**
   * Gets the history for a specified document.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {WindreamRequestOptions} windreamRequestOptions The request options.
   * @returns {Promise<HistoryContainer>} A promise, which will resolve with the server response.
   * @memberof GetDocumentHistory
   */
  public async do(windreamRequestOptions: WindreamRequestOptions): Promise<HistoryContainer> {
    return new Promise<HistoryContainer>((resolve, reject) => {
      const url = `${this.globalConfig.windreamWebServiceURL}/documents/GetHistory`;
      const resourcePointer = new HttpResourcePointer(
        'POST',
        url,
        {
          Item: IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity)
        }
      );
      this.requestExecutor.executeRequest(resourcePointer, windreamRequestOptions.requestOptions)
        .then((response: IServiceResponse<HistoryResponseContainerDTO>) => {
          if (response.data?.HasErrors || !response.data?.History) {
            this.logger.error('GetDocumentHistory', 'do', 'Failed to get history', response);
            reject(new Error('Failed to get history'));
          } else {
            resolve(HistoryContainer.fromDto(response.data));
          }
        }).catch((err: Error) => {
          this.logger.error('GetDocumentHistory', 'do', 'Failed to execute request', err);
          reject(err);
        });

    });
  }
}