import { ToolbarActionMetadata } from './models';

export class ToolbarActionMetadataFactory {

    /**
     * Creates a ToolbarActionMetadata class from JSON or JSON like string.
     *
     * @static
     * @param {(ToolbarActionMetadata| string)} json The json.
     * @returns {ToolbarActionMetadata} The metadata class.
     * @memberof ToolbarActionMetadataFactory
     */
    public static fromJSON(json:  ToolbarActionMetadata| string): ToolbarActionMetadata {
        const jsonObject = typeof json === 'string' ? JSON.parse(json) : json;
        const toolbarActionMetadataObject: ToolbarActionMetadata = {
            author: jsonObject.author,
            description: jsonObject.description,
            supportedTypes: jsonObject.supportedTypes,
            entry: jsonObject.entry,
            id: jsonObject.id,
            name: jsonObject.name,
            version: jsonObject.version
        };
        return toolbarActionMetadataObject;
    }
}