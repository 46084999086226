import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { BinaryHttpResourcePointer, BinaryHttpResponseTypes } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';
import { IFileSaver } from './iFileSaver';

/**
 * The CheckOut action is used to CheckOut a document within windream
 * 
 * @export
 * @class CheckOut
 * @extends {ServiceAction}
 */
export class CheckOut extends ServiceAction {

    private _fileSaver: IFileSaver;

    /**
     * Creates an instance of CheckOut.
     * @param {IFileSaver} fileSaver 
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * 
     * @memberof CheckOut
     */
    public constructor(fileSaver: IFileSaver, requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'checkOut';
        this._fileSaver = fileSaver;
    }

    /**
     * Execute the check-out of a given document.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WindreamRequestOptions} windreamRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolve with the server response.
     * @memberof CheckOut
     */
    public async do(windreamRequestOptions: WindreamRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // TODO: Use IdentityMapper.getWebServiceIdentity(identity)
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/documents/Download?parameter.item.id=${windreamRequestOptions.identity.id}&parameter.flags=Checkout`;
            const httpResourcePtr = new BinaryHttpResourcePointer(
                'GET',
                tempUrl,
                BinaryHttpResponseTypes.ARRAYBUFFER,
                { Item: { Id: windreamRequestOptions.identity.id } }
            );
            this.requestExecutor.executeRequest(httpResourcePtr, windreamRequestOptions.requestOptions)
                .then((response: IServiceResponse<ArrayBuffer | any>) => {
                    if (response.data instanceof ArrayBuffer) {
                        // Success
                        try {
                            this._fileSaver.saveAs(response.data, windreamRequestOptions.identity.name || 'File');
                            resolve();
                        } catch (error) {
                            this.logger.error('CheckOut', 'do', 'Failed to save file',error);
                            reject(error);
                        }
                    } else {
                        // Failure
                        this.logger.error('CheckOut', 'do', 'Can\'t check out document');
                        reject(new Error('Can\'t check out document'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('CheckOut', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}