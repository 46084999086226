/**
 * Constants for grid sizes.
 * 
 * @export
 * @class GRID_SIZES
 */
export class GRID_SIZES {
    /**
     * Width of grid for phones.
     * 
     * @static
     * @memberof GRID_SIZES
     */
    public static readonly PHONE_WIDTH = 8;
    /**
     * Height of grid for phones.
     * 
     * @static
     * @memberof GRID_SIZES
     */
    public static readonly PHONE_HEIGHT = 24;
    /**
     * Width of grid for tablets. 
     * 
     * @static
     * @memberof GRID_SIZES
     */
    public static readonly TABLET_WIDTH = 24;
    /**
     * Height of grid for tablets.
     * 
     * @static
     * @memberof GRID_SIZES
     */
    public static readonly TABLET_HEIGHT = 24;
    /**
     * Width of grid for desktops.
     * 
     * @static
     * @memberof GRID_SIZES
     */
    public static readonly DESKTOP_WIDTH = 24;
    /**
     * Height of grid for desktops.
     * 
     * @static
     * @memberof GRID_SIZES
     */
    public static readonly DESKTOP_HEIGHT = 24;
}