import { WindreamEntity, WindreamIdentity } from '../common';
import { Logger } from '../logging';
import { IServiceManager, WindreamRequestOptions } from '../services';
import { IPopupHelper } from '../ui';
import { IWebBridgeHandler } from './interfaces';
import { WebBridgeEventTypes } from './index';

/**
 * Handles the index intents emitted by the WebBridgeHandler.
 *
 * @export
 * @class AppIndexIntentHandler
 */
export class AppIndexIntentHandler {
    private webBridgeHandler: IWebBridgeHandler;
    private logger: Logger;
    private popupHelper: IPopupHelper;
    private serviceManager: IServiceManager;


    /**
     * Creates an instance of AppIndexIntentHandler.
     *
     * @param {IWebBridgeHandler} webBridgeHandler
     * @param {Logger} logger
     * @param {IPopupHelper} popupHelper
     * @param {IServiceManager} serviceManager
     * @memberof AppIndexIntentHandler
     */
    public constructor(webBridgeHandler: IWebBridgeHandler, logger: Logger, popupHelper: IPopupHelper, serviceManager: IServiceManager) {
        this.webBridgeHandler = webBridgeHandler;
        this.logger = logger;
        this.popupHelper = popupHelper;
        this.serviceManager = serviceManager;
    }


    /**
     * Subscribes to the WebBridge event for the index intent.
     *
     * @memberof AppIndexIntentHandler
     */
    public init(): void {
        this.webBridgeHandler.subscribe('Framework', WebBridgeEventTypes.IndexIntent, (ids: number[]) => {
            this.handleIndexIntent(ids);
        });
    }

    /**
     * Handles the index intent.
     *
     * @private
     * @param {number[]} ids IDs received from the WebBridgeHandler.
     * @memberof AppIndexIntentHandler
     */
    private handleIndexIntent(ids: number[]): void {
        this.createIdentities(ids).then((identities) => {
            this.openIndexDialog(identities);
        }).catch((err: Error) => {
            this.logger.error('AppIndexIntentHandler', 'init', 'Unable to get full identity all IDs', err);
        });
    }


    /**
     * Creates identities from the given IDs.
     *
     * @private
     * @param {number[]} ids IDs to create identities for.
     * @returns {Promise<WindreamIdentity[]>} Identities of the elements with the given IDs.
     * @memberof AppIndexIntentHandler
     */
    private async createIdentities(ids: number[]): Promise<WindreamIdentity[]> {
        return Promise.all(ids.map(async (id) => {
            const tempIdentity = new WindreamIdentity();
            tempIdentity.id = id;
            // Uploads are always files
            tempIdentity.entity = WindreamEntity.Document;
            return new Promise<WindreamIdentity>((resolve, reject) => {
                this.serviceManager.getServices().Common.ensureFullIdentity(new WindreamRequestOptions(tempIdentity)).then((fullIdentity) => {
                    resolve(fullIdentity);
                }).catch((error) => {
                    this.logger.error('AppIndexIntentHandler', 'createIdentities', 'Unable to get full identity for ID', id);
                    reject(error);
                });
            });
        }));
    }

    /**
     * Opens the indexing dialogs for the given identities.
     *
     * @private
     * @param {WindreamIdentity[]} identities Identities to index.
     * @memberof AppIndexIntentHandler
     */
    private openIndexDialog(identities: WindreamIdentity[]): void {
        this.logger.debug('AppIndexIntentHandler', 'handleIndexIntent', 'Received index intent', identities);
        if (identities && identities.length > 0) {
            this.popupHelper.openIndexPopups(identities).then(() => {
                this.webBridgeHandler.publish(WebBridgeEventTypes.IndexingComplete, 'true');
            }).catch((err: Error) => {
                this.logger.error('AppIndexIntentHandler', 'openIndexDialog', 'Failed to index identity', err);
            });
        }
    }
}