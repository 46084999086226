/**
 * Enumeration for click progression modes
 * 
 * @export
 * @enum {number}
 */
export enum CLICKPROGRESSION_MODES {
    OFF = 1,
    SINGLE = 2,
    DOUBLE = 3
}