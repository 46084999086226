import { ViewConfig } from '../../config';

/**
 * Model class to reflect the application settings.
 * 
 * @export
 * @class ApplicationSettings
 */
export class ApplicationSettings {
  /**
   * All available view configurations.
   * 
   * @type {ViewConfig[]}
   * @memberof ApplicationSettings
   */
  public allViews: ViewConfig[];

  /**
   * Creates an instance of ApplicationSettings.
   * 
   * @memberof ApplicationSettings
   */
  public constructor() {
    this.allViews = new Array<ViewConfig>();
  }
}