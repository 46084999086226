import { ComponentConfig } from '../config';
import { ILanguageManager } from '../language';
import { Logger } from '../logging';
import { IComponentInfoTranslator } from './interfaces';

/**
 * Translates multilingual objects to to current user language.
 * 
 * @export
 * @class ComponentInfoTranslator
 * @implements {IComponentInfoTranslator}
 */
export class ComponentInfoTranslator implements IComponentInfoTranslator {
    private logger: Logger;
    private languageManager: ILanguageManager;
    private componentConfigs: Map<string, ComponentConfig>;

    /**
     * Creates an instance of ComponentInfoTranslator.
     * @param {Logger} logger 
     * @param {ILanguageManager} languageManager 
     * @param {Map<string, ComponentConfig>} [componentConfigs] 
     * @memberof ComponentInfoTranslator
     */
    public constructor(logger: Logger, languageManager: ILanguageManager, componentConfigs: Map<string, ComponentConfig>) {
        this.logger = logger;
        this.languageManager = languageManager;
        this.componentConfigs = componentConfigs;
    }

    /**
     * Returns the translation for the connection's name.
     * If no LanguageProvider for the component can be foud, the connection ID is returned.
     * If no translation can be found, the connection ID is returned.
     * 
     * @param {string} connectionId ID of the connection.
     * @param {string} componentId ID of the component.
     * @returns {string} 
     * @memberof ComponentInfoTranslator
     */
    public getTranslatedConnectionName(connectionId: string, componentId: string): string {
        const languageProviderForComponent = this.languageManager.getLanguageProvider(componentId);
        if (!languageProviderForComponent) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedConnectionName', 'No LanguageProvider for component found', componentId);
            return connectionId;
        }

        let connectionName = languageProviderForComponent.get(`__windream.pubSub.${connectionId}.name`);
        if (connectionName.includes('__windream.pubSub')) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedConnectionName', 'No translation found for connection name', connectionId);
            connectionName = connectionId;
        }
        return connectionName;
    }

    /**
     * Returns the translation for the connection's description.
     * If no LanguageProvider for the component can be foud, an empty string is returned.
     * If no translation can be found, the description from the ComponentConfig is returned.
     * If ComponentConfigs are not set, an empty string is returned.
     * If no ComponentConfig can be found, an empty string is returned.
     * If connection can be found inside ComponentConfig, an empty string is returned.
     * 
     * @param {string} connectionId ID of the connection.
     * @param {string} componentId ID of the component.
     * @returns {string} The translated description.
     * @memberof ComponentInfoTranslator
     */
    public getTranslatedConnectionDescription(connectionId: string, componentId: string): string {
        const languageProviderForComponent = this.languageManager.getLanguageProvider(componentId);
        if (!languageProviderForComponent) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedConnectionDescription', 'No LanguageProvider for component found', componentId);
            return '';
        }

        let connectionDescription = languageProviderForComponent.get(`__windream.pubSub.${connectionId}.description`);

        if (connectionDescription.includes('__windream.pubSub')) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedConnectionDescription', 'No translation found for connection name', connectionId);
            const componentConfig = this.componentConfigs.get(componentId);
            if (!componentConfig || !componentConfig.pubSub) {
                this.logger.warn('ComponentInfoTranslator', 'getTranslatedConnectionDescription', 'No or invalid ComponentConfig found for component', componentId);
                return '';
            }
            const connection = componentConfig.pubSub.in.concat(componentConfig.pubSub.out).find((connection) => connection.name === connectionId);
            if (connection) {
                connectionDescription = connection.description || '';
            } else {
                this.logger.warn('ComponentInfoTranslator', 'getTranslatedConnectionDescription', 'Connection not found in ComponentConfig', {componentId, componentConfig});
                return '';
            }
        }
        return connectionDescription;
    }

    /**
     * Returns the translation for the components's description.
     * If no LanguageProvider for the component can be foud, an empty string is returned.
     * If no translation can be found, the description from the ComponentConfig is returned.
     * If ComponentConfigs are not set, an empty string is returned.
     * If no ComponentConfig can be found, an empty string is returned.
     * 
     * @param {string} componentId ID of the component.
     * @returns {string} The translated description.
     * @memberof ComponentInfoTranslator
     */
    public getTranslatedComponentDescription(componentId: string): string {
        const languageProviderForComponent = this.languageManager.getLanguageProvider(componentId);
        if (!languageProviderForComponent) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedComponentDescription', 'No LanguageProvider for component found', componentId);
            return '';
        }

        let componentDescription = languageProviderForComponent.get('__windream.component.description');

        if (componentDescription.includes('__windream.component')) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedComponentDescription', 'No translation found for component description', componentId);
            const componentConfig = this.componentConfigs.get(componentId);
            if (!componentConfig) {
                this.logger.warn('ComponentInfoTranslator', 'getTranslatedComponentDescription', 'No ComponentConfig found for component', componentId);
                return '';
            }
            componentDescription = componentConfig.description || '';
        }
        return componentDescription;
    }

    /**
     * Returns the translation for the components's name.
     * If no LanguageProvider for the component can be foud, an empty string is returned.
     * If no translation can be found, the description from the ComponentConfig is returned.
     * If ComponentConfigs are not set, an empty string is returned.
     * If no ComponentConfig can be found, an empty string is returned.
     * 
     * @param {string} componentId ID of the component.
     * @returns {string} The translated description.
     * @memberof ComponentInfoTranslator
     */
    public getTranslatedComponentName(componentId: string): string {
        const languageProviderForComponent = this.languageManager.getLanguageProvider(componentId);
        if (!languageProviderForComponent) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedComponentName', 'No LanguageProvider for component found', componentId);
            return '';
        }

        let componentName = languageProviderForComponent.get('__windream.component.name');

        if (componentName.includes('__windream.component')) {
            this.logger.warn('ComponentInfoTranslator', 'getTranslatedComponentName', 'No translation found for component name', componentId);
            const componentConfig = this.componentConfigs.get(componentId);
            if (!componentConfig) {
                this.logger.warn('ComponentInfoTranslator', 'getTranslatedComponentName', 'No ComponentConfig found for component', componentId);
                return '';
            }
            componentName = componentConfig.name || '';

        }
        return componentName;
    }
}