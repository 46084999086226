import { WindreamAttribute } from '../caching';
import { Utils, ValueTypes, WindreamEntity } from '../common';
import { CultureHelper } from './cultureHelper';
import { FormatHelper } from './formatHelper';
import { NumberFormatOptions } from '.';

/**
 * Provides the file size helper, which will convert sizes into the correct display value.
 * 
 * @export
 * @class WindreamFormatter
 * @extends {FormatHelper}
 */
export class WindreamFormatter extends FormatHelper {
  private cultureHelper: CultureHelper;

  private defaultNumberFormatOptions: NumberFormatOptions;

  /**
   * Creates an instance of WindreamFormatter.
   * @param {CultureHelper} cultureHelper 
   * 
   * @memberof WindreamFormatter
   */
  public constructor(cultureHelper: CultureHelper) {
    super();

    this.cultureHelper = cultureHelper;

    this.defaultNumberFormatOptions = new NumberFormatOptions();
  }

  /**
   * Formats the given windream attribute with the given defaults.
   * If no matching format helper was found, the value is returned.
   * Returns an empty string if attribute value is not defined.
   * 
   * @param {WindreamAttribute} attribute Attribute to format.
   * @param {WindreamEntity} entity Entity of the object to get flags for.
   * @returns {string} Formatted attribute value.
   * 
   * @memberof WindreamFormatter
   */
  public format(attribute: WindreamAttribute, entity: WindreamEntity): string {
    if (!Utils.isDefined(attribute) || attribute.value === null) {
      return '';
    }

    switch (attribute.name) {
      case 'dwSize': // File size
        return this.cultureHelper.fileSizeFormatHelper.getFileSizeString(attribute.value as number);
      case 'dwFSFlags': // FS attribute flags
        return this.cultureHelper.windreamFsFlagsFormatHelper.getFsFlagsShort(attribute.value as number);
      case '##ObjectStatus##': // Status flags
        return this.cultureHelper.windreamStatusFormatHelper.getStatusShort(attribute.value as number, entity);
    }

    return this.formatValue(attribute.type, attribute.value, attribute.underlyingType);
  }


  /**
   * Formats the given value 
   *
   * @param {(ValueTypes | null)} valueType
   * @param {*} value
   * @param {(ValueTypes | null)} [valueUnderlyingType=null]
   * @returns {string}
   * @memberof WindreamFormatter
   */
  public formatValue(valueType: ValueTypes | null, value: any, valueUnderlyingType: ValueTypes | null = null): string {
    if (value === null) {
      return '';
    }

    switch (valueType) {
      case ValueTypes.Boolean: // Boolean values
        return this.cultureHelper.booleanFormatHelper.getBooleanString(value);
      case ValueTypes.Date: // Date values
        return this.cultureHelper.dateTimeFormatHelper.getFormattedDateFromDate(new Date(value));
      case ValueTypes.DateTime: // Date time values
        return this.cultureHelper.dateTimeFormatHelper.getFormattedDateTimeFromDate(new Date(value));
      case ValueTypes.Time: // Time values
        return this.cultureHelper.dateTimeFormatHelper.getFormattedTimeFromDate(new Date(value));
      case ValueTypes.Decimal:
      case ValueTypes.Double:
      case ValueTypes.Float:
      case ValueTypes.Int:
      case ValueTypes.Int64:
        return this.cultureHelper.numberFormatHelper.format(value, this.defaultNumberFormatOptions);
      case ValueTypes.Currency: {
        const currencyDecimals = 2;
        const currencyNumberFormatOptions = new NumberFormatOptions();
        currencyNumberFormatOptions.withThousandsSeperator = true;
        currencyNumberFormatOptions.decimals = currencyDecimals;

        return this.cultureHelper.numberFormatHelper.format(value, currencyNumberFormatOptions);
      }
      case ValueTypes.Vector:
        return this.cultureHelper.vectorFormatter.getVectorFormat(valueUnderlyingType, value);
      default: // Other values (strings)
        return value;
    }
  }

}