import { DocumentRightsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler';
import { WindreamEntity } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging/';
import { DocumentRightsModel, IdentityRightsModelConverter } from '../../rights';
import { UpdateDocumentRightsRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to get rights from an identity in the windream webservice.
 * 
 * @export
 * @class UpdateDocumentRights
 * @extends {ServiceAction}
 */
export class UpdateDocumentRights extends ServiceAction {
    private converter: IdentityRightsModelConverter;

    /**
     * Creates an instance of UpdateDocumentRights.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @param {IdentityRightsModelConverter} converter 
     * @memberof UpdateDocumentRights
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, converter: IdentityRightsModelConverter) {
        super(requestExecutor, globalConfig, logger);
        this.converter = converter;

        this.name = 'updateDocumentRights';
    }

    /**
     * Updates righs for the given document.
     * Resolves with the converted server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {UpdateDocumentRightsRequestOptions} updateDocumentRightsRequestOptions The request options.
     * @returns {Promise<DocumentRightsModel>} A promise, will resolve with the converted server reponse.
     * @memberof UpdateDocumentRights
     */
    public async do(updateDocumentRightsRequestOptions: UpdateDocumentRightsRequestOptions): Promise<DocumentRightsModel> {
        return new Promise<DocumentRightsModel>((resolve, reject) => {
            if (updateDocumentRightsRequestOptions.identity.entity !== WindreamEntity.Document) {
                this.logger.error('UpdateDocumentRights', 'do', 'Identity is not a document', updateDocumentRightsRequestOptions.identity);
                reject(new Error('Identity is not a document'));
                return;
            }
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/documents/UpdateDocumentRights`;
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', tempUrl,
                this.converter.convertDocumentRightsToDto(updateDocumentRightsRequestOptions.rightsModel,
                    updateDocumentRightsRequestOptions.identity)),
                updateDocumentRightsRequestOptions.requestOptions)
                .then((response: IServiceResponse<DocumentRightsResponseContainerDTO>) => {
                    if (response && response.data && !response.data.HasErrors) {
                        resolve(this.converter.convertDocumentRightsToModel(response.data));
                    } else {
                        this.logger.error('UpdateDocumentRights', 'do', 'Failed to update rights', response);
                        reject(new Error('Failed to update rights'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('UpdateDocumentRights', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}