import { ITreeLib, ITreeLibInstance } from './interfaces';
import { TreeLibNode } from './models';

/**
 * Class to handle the external tree library.
 *
 * @export
 * @class TreeLibHandler
 */
export class TreeLibHandler<T> {
    private treeLib: ITreeLib<T>;
    private jQuery: JQueryStatic;
    private treeLibInstance?: ITreeLibInstance<T>;

    /**
     * Creates an instance of TreeLibHandler.
     * @param {ITreeLib<T>} treeLib Tree library to use.
     * @param {*} jQuery jQuery libraray to use.
     *
     * @memberof TreeLibHandler
     */
    public constructor(treeLib: ITreeLib<T>, jQuery: JQueryStatic) {
        this.treeLib = treeLib;
        this.jQuery = jQuery;
    }

    /**
     * Destroys this instance.
     *
     * @memberof TreeLibHandler
     */
    public destroy(): void {
        if (this.treeLibInstance) {
            this.treeLibInstance.destroy();
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Initializes the tree with the given settings.
     *
     * @param {HTMLElement} targetElement Target element to initialize the tree in.
     * @param {object} settings Settings to pass to the library.
     *
     * @memberof TreeLibHandler
     */
    public initTree(targetElement: HTMLElement, settings: object): void {
        const element = this.jQuery(targetElement);
        element.addClass('ztree');
        this.treeLibInstance = this.treeLib.init(element, settings);
    }


    /**
     * Add draggable attribute to each node.
     *
     * @param {HTMLElement} targetElement
     * @memberof TreeLibHandler
     */
    public addDraggableAttribute(targetElement: HTMLElement): void {
        const element = this.jQuery(targetElement);
        element.find('[treenode]').attr('draggable', 'true');
    }

    /**
     * Adds the root node to the tree.
     *
     * @param {TreeLibNode} node Node to add.
     *
     * @memberof TreeLibHandler
     */
    public addRootNode(node: TreeLibNode<T>) {
        if (this.treeLibInstance) {
            this.treeLibInstance.addNodes(null, -1, node, true);
            this.treeLibInstance.expandNode(node, true, false, true, true);
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Adds nodes to the given parent node.
     *
     * @param {TreeLibNode} parentNode Parent node to add to.
     * @param {TreeLibNode[]} nodes Child nodes to add.
     *
     * @memberof TreeLibHandler
     */
    public addNodes(parentNode: TreeLibNode<T> | null, nodes: TreeLibNode<T>[]) {
        if (this.treeLibInstance) {
            const paretNodeElement = parentNode ? this.treeLibInstance.getNodeByParam('id', parentNode.id, null) : null;
            this.treeLibInstance.addNodes(paretNodeElement, -1, nodes, true);
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Removes child nodes for the given node.
     *
     * @param {TreeLibNode} node Node to remove the childs from.
     *
     * @memberof TreeLibHandler
     */
    public clearNodes(node: TreeLibNode<T>) {
        if (this.treeLibInstance) {
            const newNode: any = this.getNodeById(node.id);
            this.treeLibInstance.removeChildNodes(newNode);
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Returns the node with the given ID.
     *
     * @param {number} nodeId ID of the node to get.
     * @returns {*} The selected node.
     *
     * @memberof TreeLibHandler
     */
    public getNodeById(nodeId: number): any {
        if (this.treeLibInstance) {
            return this.treeLibInstance.getNodeByParam('id', nodeId);
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Returns the node with the given ID.
     *
     * @param {number} nodeId ID of the node to get.
     * @returns {*} The selected node.
     *
     * @memberof TreeLibHandler
     */
    public getNodeByTId(nodeId: string | number): any {
        if (this.treeLibInstance) {
            return this.treeLibInstance.getNodeByTId(nodeId);
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Expand and focus the given node.
     *
     * @param {TreeLibNode} node Node to focus.
     *
     * @memberof TreeLibHandler
     */
    public focusNode(node: TreeLibNode<T>) {
        if (this.treeLibInstance) {
            const treeNode = this.treeLibInstance.getNodeByParam('id', node.id);
            if (treeNode) {
                this.treeLibInstance.expandNode(treeNode, true, false, true, true);
                this.treeLibInstance.selectNode(treeNode);
            }
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Removes the expand icon from the node.
     *
     * @param {TreeLibNode} node Node to remove the icon from.
     *
     * @memberof TreeLibHandler
     */
    public disableExpandCross(node: TreeLibNode<T>) {
        if (this.treeLibInstance) {
            const treeNode = this.treeLibInstance.getNodesByParam('id', node.id);
            if (treeNode && treeNode.length > 0) {
                this.jQuery('#' + treeNode[0].tId).addClass('wd-disable-expand');
            }
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Adds the expand icon from the node.
     *
     * @param {TreeLibNode} node Node to add the icon to.
     *
     * @memberof TreeLibHandler
     */
    public enableExpandCross(node: TreeLibNode<T>) {
        if (this.treeLibInstance) {
            const treeNode = this.treeLibInstance.getNodesByParam('id', node.id);
            if (treeNode && treeNode.length > 0) {
                this.jQuery('#' + treeNode[0].tId).removeClass('wd-disable-expand');
            }
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Substitutes the expand icon with a loading animation.
     * 
     * @param {TreeLibNode<T>} node Node to add loading animation to.
     * 
     * @memberof TreeLibHandler
     */
    public setLoading(node: TreeLibNode<T>): void {
        if (this.treeLibInstance) {
            const treeNode = this.treeLibInstance.getNodesByParam('id', node.id);
            if (treeNode) {
                this.jQuery('#' + treeNode[0].tId).addClass('wd-loading');
            }
        } else {
            throw new Error('Tree not initialized.');
        }
    }

    /**
     * Substitutes the loading animation with a expand node.
     * 
     * @param {TreeLibNode<T>} node Node to remove loading animation to.
     * 
     * @memberof TreeLibHandler
     */
    public doneLoading(node: TreeLibNode<T>): void {
        if (this.treeLibInstance) {
            const treeNode = this.treeLibInstance.getNodesByParam('id', node.id);
            if (treeNode) {
                this.jQuery('#' + treeNode[0].tId).removeClass('wd-loading');
            }
        } else {
            throw new Error('Tree not initialized.');
        }
    }
}