import { Utils, WindreamIdentity } from '../../common';
import { IConfigMapping, IMappingHandler } from '../interfaces';

type PathMappingHandlerTypes = string | WindreamIdentity;
/**
 * Mapping handler to perform path mapping.
 * Simply replaces current value with new value.
 *
 * @export
 * @class PathMappingHandler
 * @implements {(IMappingHandler<PathMappingHandlerTypes, PathMappingHandlerTypes>)}
 */
export class PathMappingHandler implements IMappingHandler<PathMappingHandlerTypes, PathMappingHandlerTypes> {
    /**
     * Data types of configurations this handler can map.
     *
     * @memberof PathMappingHandler
     */
    private readonly dataTypes = ['DATATYPE_WINDREAM_PATH'];

    /**
     * Checks whether the mapping for the given configuration data type can be handled.
     *
     * @param {string} configDataType Data type of the configuration to map.
     * @param {string} mappingDataType Data type of the mapping to use.
     * @returns {boolean} Whether the configuration can be mapped by this handler.
     * @memberof PathMappingHandler
     */
    public canHandleMapping(configDataType: string, mappingDataType: string): boolean {
        return this.dataTypes.indexOf(configDataType) !== -1 && this.dataTypes.indexOf(mappingDataType) !== -1
            && configDataType === mappingDataType;
    }

    /**
     * Applies the mapping to the given input.
     *
     * @param {WindreamIdentity} input Configuration value to map.
     * @param {IConfigMapping<PathMappingHandlerTypes>} mapping Mapping informaton to use.
     * @returns {PathMappingHandlerTypes} The transformed value.
     * @memberof PathMappingHandler
     */
    public applyMapping(input: WindreamIdentity, mapping: IConfigMapping<PathMappingHandlerTypes>): PathMappingHandlerTypes {
        if (Utils.Instance.isDefined(mapping.newValue)) {
            if (typeof mapping.currentValue === 'string') {
                let identityPath = '';
                if (input.location && input.location !== '' && input.name !== '') {

                    if (input.location === '\\') {
                        if (input.name === '\\') {
                            identityPath = '\\';
                        } else {
                            identityPath = input.location + input.name;
                        }
                    } else {
                        if (input.name !== '\\') {
                            identityPath = input.location + '\\' + input.name;
                        }
                    }
                }
                if (identityPath === mapping.currentValue) {
                    return mapping.newValue;
                }
            } else if (mapping.currentValue && input.name === mapping.currentValue.name && input.location === mapping.currentValue.location) {
                return mapping.newValue;
            }
        }
        return input;
    }
}