import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HttpResourcePointer } from '../../dataProviders/httpResourcePointer';
import { BaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to load a usecase from the application.
 * 
 * @export
 * @class GetUseCases
 * @extends {ServiceAction}
 */
export class GetUseCases extends ServiceAction {
    /**
     * Name of the service action.
     * 
     * 
     * @memberof GetUseCases
     */
    public name = 'getUseCases';

    /**
     * Loads all use case configs.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {BaseRequestOptions} [baseRequestOptions] The request options.
     * @returns {Promise<string[]>} A promise, which will resolve with the server response.
     * @memberof GetUseCases
     */
    public async do(baseRequestOptions?: BaseRequestOptions): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer(
                'get',
                this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/views/GetUsecases'
            );

            this.requestExecutor.executeRequest(resourcePointer, baseRequestOptions?.requestOptions)
                .then((response: IServiceResponse<any>) => {
                    resolve(response.data.Usecases);
                }).catch((err: Error) => {
                    this.logger.error('GetUseCases', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}