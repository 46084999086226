import { TokenProvider } from '../authentication';
import { Logger } from '../logging';
import { IWebsocketFactory } from './interfaces';
import { WebsocketProvider } from './websocketProvider';

/**
 * WebsocketProvider implementation that can be used to perform requests against windream Web Service.
 * Will add windream related authentication.
 *
 * @export
 * @class WindreamWebsocketProvider
 * @extends {WebsocketProvider}
 */
export class WindreamWebsocketProvider extends WebsocketProvider {
    protected className: string = 'WindreamWebsocketProvider';
    private tokenProvider: TokenProvider;


    /**
     * Creates an instance of WindreamWebsocketProvider.
     *
     * @param {IWebsocketFactory} websocketFactory 
     * @param {Logger} logger
     * @param {TokenProvider} tokenProvider
     * @memberof WindreamWebsocketProvider
     */
    public constructor(websocketFactory: IWebsocketFactory, logger: Logger, tokenProvider: TokenProvider) {
        super(websocketFactory, logger);
        this.tokenProvider = tokenProvider;
    }

    /**
     * Adds authentication information to the given URL.
     * 
     * @protected
     * @param {string} url URL to add authentication information to.
     * @returns {Promise<string>} URL with appended authentication information.
     * @memberof WebsocketProvider
     */
    protected async addAuthenticationToUrl(url: string): Promise<string> {
        return this.tokenProvider.getToken().then(async (token: string) => {
            const delimiter = url.includes('?') ? '&' : '?'; // If there are already query parameter, use ampersand
            // Add the authentication token to the websocket URL.
            url += delimiter + 'access_token=' + token;

            return Promise.resolve(url);
        });
    }

}