import { Utils } from '../common';
import { Logger } from '../logging/logger';
import { IOperation, Pipeline } from '../pipeline';
import { PubSubConnectionHelper } from '../shared';
import { TriggerHandler } from '../triggerHandler/triggerHandler';
import { IPopupHelper, POPUP_TYPES } from '../ui';
import { IPubSubHandler, IPubSubHandlerArrayObject, ISubscriberData } from './interfaces';
import { PubSubRecursionChecker } from './pubSubRecursionChecker';
import { PublishParameter, PubSub, PubSubConfig, PubSubOperationParameter } from '.';
/**
 * The PubSubHandler class provides the functionality of the Publish–subscribe pattern, in order to ensure communication between two classes/functions.
 * To enable a communication "pipe" between the subscriber and the publisher two prerequisites must be met.
 * 
 * 1. The subscriber has to call the Subscribe-method and needs to specify the name of the event/action, the GUID of itself and a callback function with one argument.
 * 2. The publisher has to call the Publish-method and needs to specify the name of the event/action, the GUID of itself and the data, which shall be transmitted.
 * 
 * After all prerequisites are met the PubSub will ensure the communication based on the name of the event. In order to etablish a communication "pipe" the
 * name of the event must be identical between a Subscribe- and Publish-method call.
 * 
 * @exports PubSubHandler
 * @summary The PubSub class provides the functionality of the Publish–subscribe pattern.
 * @version 1.0.0
 */
export class PubSubHandler implements IPubSubHandler {
    private pubSub: PubSub;
    private pubSubMapping: IPubSubHandlerArrayObject;
    private triggerHandler: TriggerHandler[] = new Array<TriggerHandler>();
    private pubSubRecursionChecker?: PubSubRecursionChecker;
    private strictRecursionCheck: boolean;
    private logger: Logger;
    private popupHelper: IPopupHelper;
    private publishPipeline: Pipeline<PubSubOperationParameter>;


    /**
     * Creates an instance of PubSubHandler.
     * @param {TriggerHandler[]} triggerHandler Available TriggerHandler.
     * @param {Logger} logger Logger instance to use.
     * @param {IPopupHelper} popupHelper The popup helper.
     * @param {boolean} [strictRecursionCheck=false] Whether a strict recursion check should be applied.
     * @memberof PubSubHandler
     */
    public constructor(triggerHandler: TriggerHandler[], logger: Logger, popupHelper: IPopupHelper, strictRecursionCheck: boolean = false) {
        this.pubSubMapping = {};
        this.pubSub = new PubSub(logger);
        this.triggerHandler = triggerHandler;
        this.strictRecursionCheck = strictRecursionCheck;
        this.logger = logger;
        this.popupHelper = popupHelper;
        this.publishPipeline = new Pipeline<PubSubOperationParameter>();
    }

    /**
     * Registers the given pipeline operation.
     *
     * @param {IOperation<PubSubOperationParameter>} operation The pipeline operation.
     * @memberof PubSubHandler
     */
    public registerPublishPipelineOperation(operation: IOperation<PubSubOperationParameter>): void {
        if (!operation) {
            throw new Error('The argument "operation" must be defined.');
        }

        if (this.publishPipeline) {
            this.publishPipeline.register(operation);
        }
    }

    /**
     * Initializes the PubSubHandler with the given configuration.
     * 
     * @param {PubSubConfig[]} config Configuration to use.
     * 
     * @memberof PubSubHandler
     */
    public init(config: PubSubConfig[]): void {
        this.pubSub.reset();
        if (config) {
            this.pubSubMapping = {}; // Reset current mapping
            this.loadPubSub(config);
        }
    }
    /**
     * Set for each TriggerHandler the config.
     * 
     * @param {*} triggerConfig The triggers config.
     * 
     * @memberof PubSubHandler
     */
    public setTriggerHandler(triggerConfig: any): void {
        if (triggerConfig && this.triggerHandler) {
            this.triggerHandler.forEach((handler) => {
                handler.setTriggers(triggerConfig);
            });
        }
    }

    /**
     * This method shall be called, in order to establish a communication pipe.
     * @access public
     * @param {PubSubConfig[]} config The PubSub-config, with the in and out fields.
     */
    public loadPubSub(config: PubSubConfig[]): void {
        if (config) {
            config.forEach((dataset) => {
                if (dataset.out) {
                    dataset.out.forEach((publisher) => {
                        if (!this.pubSubMapping) {
                            this.pubSubMapping = {};
                        }
                        if (!this.pubSubMapping[publisher.componentGuid]) {
                            this.pubSubMapping[publisher.componentGuid] = {};
                        }
                        if (!this.pubSubMapping[publisher.componentGuid][publisher.parameter]) {
                            this.pubSubMapping[publisher.componentGuid][publisher.parameter] = [];
                        }

                        if (dataset.in) {
                            dataset.in.forEach((subscriber) => {
                                const subscription: ISubscriberData = {
                                    guid: subscriber.componentGuid,
                                    eventName: subscriber.parameter,
                                    executeForEmptyData: dataset.executeForEmptyData || false
                                };
                                if (!this.containsSubscriber(this.pubSubMapping[publisher.componentGuid][publisher.parameter], subscription)) {
                                    this.pubSubMapping[publisher.componentGuid][publisher.parameter].push(subscription);
                                }
                            });
                        }

                    });
                }

            });
            this.pubSubRecursionChecker = new PubSubRecursionChecker(this.logger, this.pubSubMapping);

        } else {
            throw new Error('config is empty');
        }

    }

    /**
     * Gets all subscriptions for a specific subscriber.
     *
     * @param {string} guid The guid of the subscriber.
     * @returns {string[]} All event names which the user has subscribed.
     * @memberof PubSubHandler
     */
    public getAllSubscriptionsSpecificEvents(guid: string): string[] {
        const arrayEventName = new Array<string>();
        for (const publisher in this.pubSubMapping) {
            if (typeof (this.pubSubMapping) === 'object') {
                for (const event in this.pubSubMapping[publisher]) {
                    if (Utils.Instance.isArray(this.pubSubMapping[publisher][event])) {
                        const pubSubArray = this.pubSubMapping[publisher][event];
                        for (const entry of pubSubArray) {
                            if (entry.guid === guid) {
                                arrayEventName.push(entry.eventName);
                            }
                        }
                    }
                }
            }
        }
        return Utils.makeArrayDistinctive(arrayEventName);
    }

    /**
     * Gets all publisher events for a specific publisher.
     *
     * @param {string} guid The guid of the publisher.
     * @returns {string[]} All event names of the publisher.
     * @memberof PubSubHandler
     */
    public getSpecificPublisherEvents(guid: string): string[] {
        const arrayEventName = new Array<string>();
        for (const publisher in this.pubSubMapping) {
            if (typeof (this.pubSubMapping) === 'object' && publisher === guid) {
                for (const event in this.pubSubMapping[publisher]) {
                    if (Utils.Instance.isArray(this.pubSubMapping[publisher][event])) {
                        arrayEventName.push(event);
                    }
                }
            }
        }
        return Utils.makeArrayDistinctive(arrayEventName);
    }
    /**
     * Subscribe to an specific event/action. If the event occurs the callback method will be invoked
     * @access public
     * @param {string} guid The guid of the subscriber.
     * @param {string} name The name of the event/action, which shall be subscribed.
     * @param {function} callback The callback function which will be called if a corresponding Publish-function call was executed.     
     * @throws Will throw an error if the name or callback is null.
     * @version 1.0.0
     * @example //Subscribe to an event with the name testEvent and will display a MessageBox when the callback was called.
     * pubSub.Subscribe("05066EA-CC1B-476C-A473-09E0AD013EB7","testEvent", (msg: Object) => {var data = msg; alert(data)});
     */
    public subscribe(guid: string, name: string, callback: (data: any) => void): void {
        this.pubSub.subscribe(guid + name, callback);
    }


    /**
     * Unsubscribe from an specific event/action.
     * @access public
     * @param {string} guid The guid of the subscriber.
     * @param {string} name The name of the event/action, which shall be unsubscribed.
     * @version 1.0.0
     * @example //Subscribe to an event with the name testEvent and will display a MessageBox when the callback was called.
     * pubSub.Unsubscribe("05066EA-CC1B-476C-A473-09E0AD013EB7","testEvent");
     */
    public unsubscribe(guid: string, name: string): void {
        this.pubSub.unsubscribe(guid + name);
    }

    /**
     * Publish a specific event and the corresponding data to all subscribers, which have the same event/action. Thus calling all callback functions.
     *
     * @param {string} guid The guid of the publisher.
     * @param {string} name The name of the event, which shall be published.
     * @param {(Object | null)} [value] The data, which shall be transmitted.
     * @throws {Error} Will throw an error if the publisher is part of a recursion, it will break the recursion.
     * @memberof PubSubHandler
     */
    public publish(guid: string, name: string, value?: Object | null): void {

        let subscriber: ISubscriberData[];
        if (this.pubSubMapping) {
            if (this.pubSubMapping[guid]) {
                if (this.pubSubMapping[guid][name]) {
                    subscriber = this.pubSubMapping[guid][name];
                    const arrayLength = subscriber.length;
                    for (let i = 0; i < arrayLength; i++) {
                        if (!subscriber[i].executeForEmptyData && PubSubConnectionHelper.isValueEmpty(value)) {
                            this.logger.debug('PubSubHandler', 'publish', 'Not publishing because of empty value', {
                                out: `${guid}:${name}`,
                                in: `${subscriber[i].guid}:${subscriber[i].eventName}`,
                                value: value
                            });
                            return;
                        }
                        if (this.strictRecursionCheck) {
                            if (this.pubSubRecursionChecker) {
                                try {
                                    this.pubSubRecursionChecker.addRecursionCheckStep(subscriber[i].guid, subscriber[i].eventName);
                                } catch (error) {
                                    this.popupHelper.openPopup({
                                        body: 'pubSubRecursion.popup.body',
                                        buttons: [{ label: 'framework.generic.ok', useNLS: true }],
                                        destroyOnButtonClick: true,
                                        title: 'pubSubRecursion.popup.title',
                                        type: POPUP_TYPES.WARNING,
                                        useNLS: true
                                    });
                                    throw error;
                                }
                            } else {
                                this.logger.error('PubSubHandler', 'pubish', 'Somehow recursion checker is missing but recursion check is active');
                            }
                        }

                        const publishPipelineParameter = new PubSubOperationParameter(
                            new PublishParameter(subscriber[i].guid, subscriber[i].eventName, value),
                            new PublishParameter(guid, name, value)
                        );
                        if (this.publishPipeline) {
                            if (this.publishPipeline.invoke(publishPipelineParameter)) {
                                this.pubSub.publish(publishPipelineParameter.in.guid + publishPipelineParameter.in.name, publishPipelineParameter.in.value);
                                // After
                                this.trigger(publishPipelineParameter.out.guid + publishPipelineParameter.out.name, publishPipelineParameter.in.guid + publishPipelineParameter.in.name);
                            } else {
                                this.logger.debug('PubSubHandler', 'publish', 'Not publishing because the publish pipeline has failed at some point.');
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Checks of the given array of PubSubs contains the given subscriber data.
     * 
     * @private
     * @param {ISubscriberData[]} pubSubArray Array to search.
     * @param {ISubscriberData} subscription Entry to find.
     * @returns {boolean} True of the array contains the entry, false otherwise.
     * 
     * @memberof PubSubHandler
     */
    private containsSubscriber(pubSubArray: ISubscriberData[], subscription: ISubscriberData): boolean {
        const arrayLength = pubSubArray.length;
        for (let i = 0; i < arrayLength; i++) {
            if (pubSubArray[i].eventName === subscription.eventName && pubSubArray[i].guid === subscription.guid) {
                return true;
            }
        }
        return false;
    }
    /**
     * Triggers the TriggerHandler
     * 
     * @private
     * @param {string} outId The Guid + EventName from the out connection
     * @param {string} inId The Guid + EventName from the in connection
     * 
     * @memberof PubSubHandler
     */
    private trigger(outId: string, inId: string) {
        if (this.triggerHandler) {
            this.triggerHandler.forEach((trigger) => {
                trigger.tryReset(outId, inId);
                trigger.tryTrigger(outId, inId);
            });
        }
    }
}