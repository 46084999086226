import { IServiceRequest } from '../ajaxHandler/interfaces/iServiceRequest';
import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { IDataProvider, IResourcePointer } from '.';


/**
 * The abstract base class for all data providers.
 * 
 * @export
 * @abstract
 * @class DataProvider
 */
export abstract class DataProvider implements IDataProvider {

    /**
     * Executes the request.
     * 
     * @abstract
     * @template T 
     * @param {IServiceRequest} request 
     * @returns {Promise<IServiceResponse<T>>} 
     * @memberof DataProvider
     */
    public abstract execute<T>(request: IServiceRequest): Promise<IServiceResponse<T>>;

    /**
     * Gets all supported schemes.
     * 
     * @abstract
     * @returns {string[]} 
     * @memberof DataProvider
     */
    public abstract getSupportedSchemes(): string[];

    /**
     * Gets the resource pointer schema.
     * 
     * @abstract
     * @memberof DataProvider
     */
    public abstract getResourcePointerSchema(): any;

    /**
     * Casts the resource pointer.
     * 
     * @abstract
     * @param {IResourcePointer} resourcePointer 
     * @returns {IResourcePointer} 
     * @memberof DataProvider
     */
    public abstract castResourcePointer(resourcePointer: IResourcePointer): IResourcePointer;

    /**
     * Creates the raw request.
     * 
     * @abstract
     * @param {IResourcePointer} resourcePointer 
     * @returns {XMLHttpRequest} 
     * @memberof DataProvider
     */
    public abstract createRawRequest(resourcePointer: IResourcePointer): XMLHttpRequest;

    /**
     * This handler will be called before the execution.
     * 
     * @param {IServiceRequest} request 
     * @returns {IServiceRequest} 
     * @memberof IDataProvider
     */
    protected beforeRequestHandler(request: IServiceRequest): IServiceRequest {
        return request;
    }


    /**
     * This handler will be called after the execution.
     * 
     * @protected
     * @template T 
     * @param {IServiceResponse<T>} response 
     * @returns {IServiceResponse<T>} 
     * @memberof DataProvider
     */
    protected afterRequestHandler<T>(response: IServiceResponse<T>): IServiceResponse<T> {
        return response;
    }

}