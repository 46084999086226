import { UseCaseConfigResponseDTO, ViewConfigResponseDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { Utils } from '../common';
import { DWCore, Position } from '../dynamicWorkspace';
import { ISharedSettings, ITriggerValue } from './interfaces';
import { ViewConfig } from './models';
import { PubSubConfigFactory } from './pubSubConfigFactory';
import { StyleFactory } from './styleFactory';
import { SubViewConfigFactory } from './subViewConfigFactory';
import { ComponentInstanceConfigFactory } from '.';

/**
 * This class provides a factory, which will generate ViewConfig classes from JSON objects or JSON strings.
 * 
 * @export
 * @class ViewConfigFactory
 */
export class ViewConfigFactory {

    /**
     * Converts the given view configuration DTO into an internal view configuration model.
     *
     * @access public
     * @param {string} viewConfigDTO The view configuration DTO, which should be converted into an internal view configuration model.
     * @returns {ViewConfig} The converted object.
     */
    public static convertFromDTO(viewConfigDTO: ViewConfigResponseDTO | UseCaseConfigResponseDTO): ViewConfig {
        const result = new ViewConfig(viewConfigDTO.Id);

        if (Utils.isDefined(viewConfigDTO.Device)) {
            result.device = viewConfigDTO.Device.valueOf();
        }
        result.author = viewConfigDTO.Author;
        result.description = viewConfigDTO.Description;
        result.modified = viewConfigDTO.Modified;
        result.name = viewConfigDTO.Name;
        result.style = StyleFactory.convertFromDTO(viewConfigDTO.Style);
        result.version = viewConfigDTO.Version;
        result.gridVersion = viewConfigDTO.GridVersion;
        result.icon = viewConfigDTO.Icon;
        result.isHiddenInNavigation = viewConfigDTO.IsHiddenInNavigation;
        result.settings = viewConfigDTO.Settings as ISharedSettings || {};
        result.contextMenu = viewConfigDTO.ContextMenu;

        if (Utils.isDefined(viewConfigDTO.TemplateId)) {
            result.templateId = viewConfigDTO.TemplateId;
        }

        if (Utils.isDefined(viewConfigDTO.Alias)) {
            result.alias = viewConfigDTO.Alias;
        }

        result.components = viewConfigDTO.Components.map((value) => {
            return ComponentInstanceConfigFactory.convertFromDTO(value);
        });

        if (!result.gridVersion || result.gridVersion !== '2.0') {

            const multiplierFactor = 2;
            result.components.forEach((element) => {
                if (element.componentType !== DWCore.Components.COMPONENT_TYPES.LOGICAL && element.position && element.position instanceof Position) {

                    if (Utils.Instance.isDefined(element.position.height)) {
                        element.position.height *= multiplierFactor;
                    }
                    if (Utils.Instance.isDefined(element.position.width)) {
                        element.position.width *= multiplierFactor;
                    }
                    if (Utils.Instance.isDefined(element.position.x)) {
                        element.position.x *= multiplierFactor;
                    }
                    if (Utils.Instance.isDefined(element.position.y)) {
                        element.position.y *= multiplierFactor;
                    }
                }
            });
        }

        result.pubSub = viewConfigDTO.PubSub.map((value) => {
            return PubSubConfigFactory.convertFromDTO(value);
        });

        result.subViews = viewConfigDTO.SubViews.map((value) => {
            return SubViewConfigFactory.convertFromDTO(value);
        });

        result.triggers = {};
        if (viewConfigDTO.Triggers) {
            for (const triggersKey in viewConfigDTO.Triggers) {
                if (triggersKey) {
                    result.triggers[triggersKey] = viewConfigDTO.Triggers[triggersKey].map((triggerValueDTO) => {
                        const triggerValue: ITriggerValue = {
                            inId: triggerValueDTO.InId,
                            outId: triggerValueDTO.OutId,
                            value: triggerValueDTO.Value
                        };
                        return triggerValue;
                    });
                }
            }
        }

        return result;
    }

}