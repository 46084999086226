import { WindreamAttribute as IWindreamAttribute, ValueTypes } from '../../../../typings/core';
import { AttributeResponseDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { TypeSpecificDetails } from './typeSpecificDetails';
import { VectorDetails } from './vectorDetails';
import { WindreamAttributeTypes } from './windreamAttributeTypes';

/**
 * Describes a windream attribute.
 * 
 * @export
 * @class WindreamAttribute
 */
export class WindreamAttribute implements IWindreamAttribute {

    /**
     * The type of the attribute (system, type-specific, virtual, etc).
     * 
     * @type {WindreamAttributeTypes}
     * @memberof WindreamAttribute
     */
    public attributeType!: WindreamAttributeTypes;

    /**
     * The displayname of this Attribute.
     * 
     * @type {string}
     * @memberof WindreamAttribute
     */
    public displayName!: string;

    /**
     * Whether the attribute is sortable or not.
     * 
     * @type {boolean}
     * @memberof WindreamAttribute
     */
    public isSortable!: boolean;

    /**
     * Whether the attribute is a system attribute or not.
     * 
     * @type {boolean}
     * @memberof WindreamAttribute
     */
    public isSystem!: boolean;

    /**
     * The name of this attribute.
     * 
     * @type {string}
     * @memberof WindreamAttribute
     */
    public name!: string;

    /**
     * The objectType id of this attribute.
     * 
     * @type {number}
     * @memberof WindreamAttribute
     */
    public objectTypeId!: number;

    /**
     * The type of this attribute.
     * 
     * @type {ValueTypes}
     * @memberof WindreamAttribute
     */
    public type!: ValueTypes;

    /**
     * The value of this attribute.
     * 
     * @type {(string | number | null | number[] | Date)}
     * @memberof WindreamAttribute
     */
    public value!: string | number | null | number[] | boolean | Date;

    /**
     * Underlying type for vector indices.
     *
     * @type {ValueTypes}
     * @memberof WindreamAttribute
     */
    public underlyingType?: ValueTypes;

    /**
     * The pre digit for fixed point type.
     *
     * @type {number}
     * @memberof WindreamAttribute
     */
    public preDigits?: number;

    /**
     * The pre digit for fixed point type.
     *
     * @type {number}
     * @memberof WindreamAttribute
     */
    public postDigits?: number;

    /**
     * Details of the vector for vectors.
     *
     * @type {VectorDetails}
     * @memberof WindreamAttribute
     */
    public vectorDetails?: VectorDetails;

    /**
     * Details specific for the related object type.
     *
     * @type {TypeSpecificDetails}
     * @memberof WindreamAttribute
     */
    public typeSpecificDetails?: TypeSpecificDetails;

    /**
     * Max size of the value.
     *
     * @type {number}
     * @memberof WindreamAttribute
     */
    public maxSize?: number;

    /**
     * Whether the attribute is always modifiable.
     *
     * @type {boolean}
     * @memberof WindreamAttribute
     */
    public alwaysModifiable?: boolean;

    /**
     * Name of the column.
     *
     * @type {string}
     * @memberof WindreamAttribute
     */
    public column!: string;

    /**
     * Whether the attribute is a fulltext.
     *
     * @type {boolean}
     * @memberof WindreamAttribute
     */
    public isFulltext?: boolean;

    /**
     * Creates an instance based on the Web Service DTO.
     *
     * @static
     * @param {AttributeResponseDTO} dto DTO to create from.
     * @returns {WindreamAttribute}
     * @memberof WindreamAttribute
     */
    public static fromDto(dto: AttributeResponseDTO): WindreamAttribute {
        const result = new WindreamAttribute();
        result.alwaysModifiable = dto.AlwaysModifiable !== null ? dto.AlwaysModifiable : undefined;
        if (dto.IsSystem) {
            result.attributeType = WindreamAttributeTypes.System;
        } else {
            result.attributeType = WindreamAttributeTypes.Undefined;
        }
        result.column = dto.Column;
        result.displayName = dto.DisplayName;
        result.isSortable = dto.IsSortable;
        result.isSystem = dto.IsSystem;
        result.maxSize = dto.MaxSize !== null ? dto.MaxSize : undefined;
        result.name = dto.Column;   // Take the column, because DW can only handle columns and not the index-name.
        result.postDigits = dto.PostDigits !== null ? dto.PostDigits : undefined;
        result.preDigits = dto.PreDigits !== null ? dto.PreDigits : undefined;
        result.type = dto.Type as number;
        result.typeSpecificDetails = dto.TypeSpecificDetails ? {
            onceEditable: dto.TypeSpecificDetails.OnceEditable
        } : undefined;
        result.underlyingType = dto.UnderlyingType !== null ? dto.UnderlyingType as number : undefined;
        // Do not convert dto.Value to Date if the value is not set - otherwise it will be converted to the default javascript date '01.01.1970'
        const isDateTimeIndex = <number>dto.Type === <number>ValueTypes.Date || <number>dto.Type === <number>ValueTypes.DateTime || <number>dto.Type === <number>ValueTypes.Time;
        if (isDateTimeIndex && (typeof dto.Value === 'string' || typeof dto.Value === 'number')) {
            result.value = new Date(dto.Value);
        } else {
            result.value = dto.Value;
        }
        result.vectorDetails = dto.VectorDetails ? {
            entriesLimit: dto.VectorDetails.EntriesLimit !== null ? dto.VectorDetails.EntriesLimit : undefined
        } : undefined;
        result.isFulltext = dto.IsFulltext !== null ? dto.IsFulltext : undefined;
        return result;
    }

}