import { ILanguageProvider } from '../language';
import { FormatHelper } from './formatHelper';
import { FS_FLAGS } from './fsFlags';

/**
 * The WindreamFsFlagsFormatHelper provides windream file system flags functionality.
 * 
 * @export
 * @class WindreamFsFlagsFormatHelper
 * @extends {FormatHelper}
 */
export class WindreamFsFlagsFormatHelper extends FormatHelper {
  private languageProvider: ILanguageProvider;

  /**
   * Creates an instance of WindreamFsFlagsFormatHelper.
   * @param {ILanguageProvider} languageProvider LanguageProvider instance to use.
   * 
   * @memberof WindreamFsFlagsFormatHelper
   */
  public constructor(languageProvider: ILanguageProvider) {
    super();

    this.languageProvider = languageProvider;
  }

  /**
   * Returns a formatted string to represent the windream file system flags of the document.
   * Result will always be a five character string in the format `rahsd`.
   * r = Readonly
   * a = Archived
   * h = Hidden
   * s = System
   * d = Directory
   * Flags that are not set are represented with a dash (`-`).
   * .
   * @param {number} flags The flags to represent as a string.
   * @returns {string} A string representing the flags.
   * 
   * @memberof WindreamFsFlagsFormatHelper
   */
  public getFsFlagsShort(flags: number): string {
    let attrStr: string = '';

    if ((flags & FS_FLAGS.READONLY) === FS_FLAGS.READONLY) { // ReadOnly attribute
      attrStr += 'r';
    } else {
      attrStr += '-';
    }
    if ((flags & FS_FLAGS.ARCHIVE) === FS_FLAGS.ARCHIVE) { // Archive attribute
      attrStr += 'a';
    } else {
      attrStr += '-';
    }
    if ((flags & FS_FLAGS.HIDDEN) === FS_FLAGS.HIDDEN) { // Hidden attribute
      attrStr += 'h';
    } else {
      attrStr += '-';
    }
    if ((flags & FS_FLAGS.SYSTEM) === FS_FLAGS.SYSTEM) { // System attribute
      attrStr += 's';
    } else {
      attrStr += '-';
    }
    if ((flags & FS_FLAGS.DIRECTORY) === FS_FLAGS.DIRECTORY) { // Directory attribute
      attrStr += 'd';
    } else {
      attrStr += '-';
    }
    return attrStr;
  }

  /**
   * Returns a translated string to represent the windream file system flags of the document.
   * Result will always be a list of translations of the flags that are set.
   * .
   * @param {number} flags The flags to represent as a string.
   * @returns {string} A string representing the flags.
   * 
   * @memberof WindreamFsFlagsFormatHelper
   */
  public getFsFlagsLong(flags: number): string {
    const attributes: string[] = new Array<string>();

    if ((flags & FS_FLAGS.READONLY) === FS_FLAGS.READONLY) { // ReadOnly attribute
      attributes.push(this.languageProvider.get('windream.fsFlags.readonly'));
    }
    if ((flags & FS_FLAGS.ARCHIVE) === FS_FLAGS.ARCHIVE) { // Archive attribute
      attributes.push(this.languageProvider.get('windream.fsFlags.archived'));
    }
    if ((flags & FS_FLAGS.HIDDEN) === FS_FLAGS.HIDDEN) { // Hidden attribute
      attributes.push(this.languageProvider.get('windream.fsFlags.hidden'));
    }
    if ((flags & FS_FLAGS.SYSTEM) === FS_FLAGS.SYSTEM) { // System attribute
      attributes.push(this.languageProvider.get('windream.fsFlags.system'));
    }
    if ((flags & FS_FLAGS.DIRECTORY) === FS_FLAGS.DIRECTORY) { // Directory attribute
      attributes.push(this.languageProvider.get('windream.fsFlags.directory'));
    }
    return attributes.join(', ');
  }
}