import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { HttpResourcePointer } from '../dataProviders';
import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';

/**
 * Class to load language files.
 * 
 * @export
 * @class LanguageLoader
 */
export class LanguageLoader {

    private static cachedLanguageFiles = new Map<string, any>();

    /**
     * Gets a specific translation file.
     * @param {string} url The url were the translation is stored.
     * @param {function} callback The callback, which will be called after the transmission was complete.
     */
    public static getLanguageFile(requestExecutor: IRequestExecutor, url: string, callback: (data: any) => void): void {
        if (requestExecutor && url && callback) {
            if (LanguageLoader.cachedLanguageFiles.has(url)) {
                callback(LanguageLoader.cachedLanguageFiles.get(url));
            } else {
                const ressourcePtr: HttpResourcePointer = new HttpResourcePointer('get', url);
                requestExecutor.executeRequest(ressourcePtr).then((response: IServiceResponse<string>) => {
                    if (response && response.data) {
                        LanguageLoader.cachedLanguageFiles.set(url, response.data);
                        // eslint-disable-next-line promise/no-callback-in-promise
                        callback(response.data);
                    }
                }).catch(() => {
                    // TODO: Log error
                    // eslint-disable-next-line promise/no-callback-in-promise
                    callback(null);
                });
            }
        } else {
            throw new Error('Can\'t load language file, because the call is invalid.');
        }
    }
}