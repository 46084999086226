import { IdentityMapper } from '..';
import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging/logger';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The UndoWorkLockDirectory action is used to undo a work lock within windream.
 * 
 * @export
 * @class UndoWorkLockDirectory
 * @extends {ServiceAction}
 */
export class UndoWorkLockDirectory extends ServiceAction {
  /**
   * Name of the service action.
   *
   * @memberof UndoWorkLockDirectory
   */
  public name = 'undoWorkLockDirectory';


  /**
   * Creates an instance of UndoWorkLock.
   * @param {IRequestExecutor} requestExecutor 
   * @param {GlobalConfig} globalConfig 
   * @param {Logger} logger 
   * @memberof UndoWorkLockDirectory
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'undoWorkLockDirectory';
  }

  /**
   * Undo a preceding work lock.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {WindreamRequestOptions} windreamRequestOptions The request options.
   * @returns {Promise<void>} A promise, wich will resolve with the server reponse.
   * @memberof UndoWorkLockDirectory
   */
  public async do(windreamRequestOptions: WindreamRequestOptions): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/directories/UndoWorkLock', {
        Item: IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity)
      }), windreamRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
        if (response.data?.HasErrors) {
          this.logger.error('UndoWorkLockDirectory', 'do', 'Failed to undo work lock', response);
          reject(new Error('Failed to undo work lock'));
        } else {
          resolve();
        }
      }).catch((err: Error) => {
        this.logger.error('UndoWorkLockDirectory', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}