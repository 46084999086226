import { PageButton as PageButtonBase, PageNavigation as PageNavigationBase } from '../../../typings/core';
import { Utils } from '../dynamicWorkspace';
import { ILanguageProvider } from '../language';
import { PageButton } from './pageButton';

/**
 * This class represents a page navigation.
 * 
 * @export
 * @class PageNavigation
 */
export class PageNavigation implements PageNavigationBase {

    /**
     * The language provider.
     * 
     * @type {ILanguageProvider}
     * @memberof PageNavigation
     */
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of Pagination.
     * 
     * @param {PublicApi} publicApi The public API.
     * @param {ILanguageProvider} languageProvider The language provider.
     */
    public constructor(languageProvider: ILanguageProvider) {
        this.languageProvider = languageProvider;
    }

    /**
     * Returns the summary string to display for the page navigation like '1-10 from 100'.
     * 
     * @param {number} resultCount The total number of results.
     * @param {number} resultsPerPage The number of results that are displayed per page.
     * @param {number} selectdPageIndex The index of the currently selected page - zero based!
     * @returns {string} An info text for the navigation.
     */
    public getNavigationInfoText(resultCount: number, resultsPerPage: number, selectdPageIndex: number): string {
        let info = this.languageProvider.get('pagination.noHits');
        if (resultCount === 1) {
            info = `1-1 ${Utils.Instance.escapeStringValue(this.languageProvider.get('pagination.pageInfo'))} ${resultCount}`;
        } else if (resultCount > 0) {
            const startPosition = resultsPerPage * selectdPageIndex + 1;
            let endPosition = startPosition + (resultsPerPage - 1);

            if (endPosition > resultCount) {
                // Add rest of results if on last page
                endPosition = startPosition + (resultCount % resultsPerPage) - 1;
            }

            info = `${startPosition}-${endPosition} ${Utils.Instance.escapeStringValue(this.languageProvider.get('pagination.pageInfo'))} ${resultCount}`;
        }
        return info;
    }

    /**
     * Generates an array of `PageButtonBase` instances representing the page navigation buttons.
     * The buttons include those for the first, last, and nearby pages, along with placeholders for skipped pages.
     *
     * @param {number} pageCount The number of pages that are available.
     * @param {number} selectdPageIndex The index of the currently selected page - zero based!
     * @returns {PageButtonBase[]} An array of `PageButtonBase` objects representing the page buttons for navigation.
     */
    public getPageButtons(pageCount: number, selectdPageIndex: number): PageButtonBase[] {
        const result = new Array<PageButtonBase>();
        const pageNumber2 = 2;
        const pageNumber3 = 3;
        const pageNumber4 = 4;
        const pageNumber5 = 5;

        // Initialize page indices
        const pageIndices: number[] = [];
        for (let i = 0; i < pageCount; i++) {
            pageIndices.push(i);
        }

        if (pageIndices.length <= pageNumber5) {
            // 1 - 5 buttons
            for (const index of pageIndices) {
                const isSelected = index === selectdPageIndex;
                const pageButton = new PageButton(isSelected, (index + 1).toString(), index);
                result.push(pageButton);
            }
        } else if (selectdPageIndex <= pageNumber2) {
            // First 4 buttons
            for (let i = 0; i <= pageNumber3; i++) {
                const isSelected = pageIndices[i] === selectdPageIndex;
                const pageButton = new PageButton(isSelected, (pageIndices[i] + 1).toString(), pageIndices[i]);
                result.push(pageButton);
            }

            const pageButtonDots = new PageButton(false, '...', -1);
            result.push(pageButtonDots);

            const pageButton = new PageButton(false, (pageIndices[pageIndices.length - 1] + 1).toString(), pageIndices[pageIndices.length - 1]);
            result.push(pageButton);

        } else if (selectdPageIndex >= pageIndices.length - pageNumber3) {
            // Last 4 buttons
            const pageButton = new PageButton(false, (pageIndices[0] + 1).toString(), pageIndices[0]);
            result.push(pageButton);

            const pageButtonDots = new PageButton(false, '...', -1);
            result.push(pageButtonDots);

            for (let i = pageIndices.length - pageNumber4; i <= pageIndices.length - 1; i++) {
                const isSelected = pageIndices[i] === selectdPageIndex;
                const pageButton = new PageButton(isSelected, (pageIndices[i] + 1).toString(), pageIndices[i]);
                result.push(pageButton);
            }
        } else {
            // All other page options
            const pageButton = new PageButton(false, (pageIndices[0] + 1).toString(), pageIndices[0]);
            result.push(pageButton);

            const pageButtonDots = new PageButton(false, '...', -1);
            result.push(pageButtonDots);

            for (let i = selectdPageIndex - 1; i <= selectdPageIndex + 1; i++) {
                const isSelected = pageIndices[i] === selectdPageIndex;
                const pageButton = new PageButton(isSelected, (pageIndices[i] + 1).toString(), pageIndices[i]);
                result.push(pageButton);
            }

            const pageButtonDots2 = new PageButton(false, '...', -1);
            result.push(pageButtonDots2);

            const pageButton2 = new PageButton(false, (pageIndices[pageIndices.length - 1] + 1).toString(), pageIndices[pageIndices.length - 1]);
            result.push(pageButton2);
        }

        return result;
    }
}