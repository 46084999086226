import { ConfigResponseContainerDTO, PubSubEditorConfigDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig, Position, PubSubEditorComponentConfig, PubSubEditorConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { PubSubEditorRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to load a PubSubEditorConfig from the framework.
 * 
 * @export
 * @class LoadPubSubEditorConfig
 * @extends {ServiceAction}
 */
export class LoadPubSubEditorConfig extends ServiceAction {


    /**
     * Creates an instance of LoadPubSubEditorConfig.
     * 
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @memberof LoadPubSubEditorConfig
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'loadPubSubEditorConfig';
    }

    /**
     * Load the PubSubEditorConfig for the given view-device-combination.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {PubSubEditorRequestOptions} pubSubEditorRequestOptions The request options.
     * @returns {Promise<PubSubEditorConfig>} A promise, which will resolve with the server response.
     * @memberof LoadPubSubEditorConfig
     */
    public async do(pubSubEditorRequestOptions: PubSubEditorRequestOptions): Promise<PubSubEditorConfig> {
        return new Promise<PubSubEditorConfig>(async (resolve, reject) => {

            if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core && DynamicWorkspace.Extensions.core.viewProvider) {
                const pubSubEditorConfig = await DynamicWorkspace.Extensions.core.viewProvider.getPubSubEditorConfig(pubSubEditorRequestOptions.viewId, pubSubEditorRequestOptions.device);
                if (pubSubEditorConfig.data) {
                    resolve(pubSubEditorConfig.data);
                    return;
                } else {
                    this.logger.error('LoadPubSubEditorConfig', 'do', 'Missing PubSub editor configuration data');
                    reject(new Error('Missing PubSub editor configuration data'));
                    return;
                }
            } else {
                this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/views/LoadPubSubEditorConfig', {
                    Device: pubSubEditorRequestOptions.device,
                    ViewID: pubSubEditorRequestOptions.viewId
                }), pubSubEditorRequestOptions.requestOptions).then((response: IServiceResponse<ConfigResponseContainerDTO<PubSubEditorConfigDTO>>) => {
                    if (response.data) {

                        // Cast response to internal model
                        const result = new PubSubEditorConfig();
                        response.data.ConfigData.Components.forEach((component) => {
                            try {
                                const comp = new PubSubEditorComponentConfig();
                                comp.guid = component.Guid;
                                const position = new Position();
                                position.x = component.Position.X;
                                position.y = component.Position.Y;
                                comp.position = position;
                                result.components.push(comp);
                            } catch (err) {
                                this.logger.error('LoadPubSubEditorConfig', 'do', 'Failed to parse component data', err);
                            }
                        });
                        result.device = response.data.ConfigData.Device;
                        result.viewId = response.data.ConfigData.ViewID;
                        resolve(result);
                    } else {
                        this.logger.error('LoadPubSubEditorConfig', 'do', 'Unable to get ConfigData', response);
                        reject(new Error('Unable to get ConfigData'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('LoadPubSubEditorConfig', 'do', 'Failed to execute request', err);
                    reject(err);
                });
            }
        });
    }
}