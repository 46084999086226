import { CustomConfig } from './models/customConfig';

/**
 * This class provides a factory, which will generate GlobalConfig objects from JSON objects or JSON strings.
 * @version 1.0
 * @access public
 */
export class CustomConfigFactory {
    /**
     * Gets the GlobalConfig from JSON.
     * @access public
     * @param {GlobalConfig | string} json The JSON, which should be transformed into a GlobalConfig.
     * @returns {GlobalConfig}
     * @throws {Error} The instance can not be created from the JSON.
     */
    public static fromJSON(json: CustomConfig | string): CustomConfig {
        const jsonObject = typeof json === 'string' ? JSON.parse(json) : json;
        const configObject: CustomConfig = {
            customCssFilePath: jsonObject.customCssFilePath,
            browserTitle: jsonObject.browserTitle,
            iconPath: jsonObject.iconPath,
            navigationBackgroundColor: jsonObject.navigationBackgroundColor,
            navigationTextColor: jsonObject.navigationTextColor,
            subTitle: jsonObject.subTitle,
            title: jsonObject.title,
            titleBackgroundColor: jsonObject.titleBackgroundColor,
            titleTextColor: jsonObject.titleTextColor,
            headerBackgroundColor: jsonObject.headerBackgroundColor,
            headerTextColor: jsonObject.headerTextColor,
            activeViewBackgroundColor: jsonObject.activeViewBackgroundColor,
            activeViewIconColor: jsonObject.activeViewIconColor,
            headerIconHoverColor: jsonObject.headerIconHoverColor,
            navigationHoverColor: jsonObject.navigationHoverColor
        };
        // Utils.deepEqual(configObject, json);
        return configObject;
    }
}