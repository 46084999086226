import { ToolbarConfig } from '.';

/**
 * This class provides a factory, which will generate ToolbarConfig objects from JSON objects or JSON strings.
 *
 * @export
 * @class ToolbarConfigFactory
 */
export class ToolbarConfigFactory {

    /**
     * Creates a ToolbarConfig class from JSON or JSON like string.
     *
     * @static
     * @param {(ToolbarConfig | string)} json The JSON, which should be transformed into a toolbar config.
     * @returns {ToolbarConfig} A toolbar config.
     * @memberof ToolbarConfigFactory
     */
    public static fromJSON(json: ToolbarConfig | string): ToolbarConfig {
        const jsonObject = typeof json === 'string' ? JSON.parse(json) : json;
        const configObject: ToolbarConfig = {
            actions: jsonObject.actions,
            displayOptions: jsonObject.displayOptions,
            orientation:  jsonObject.orientation
        };
        return configObject;
    }
}