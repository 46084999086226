/**
 * Helper to trigger native downloads.
 *
 * @export
 * @class NativeDownloadTrigger
 */
export class NativeDownloadTrigger {

    private iFrameDeletionTime: number = 120000;

    /**
     * Triggers a native download for the given URL.
     *
     * @param {string} url URL to download.
     * @param {string} [name=''] Name of the file.
     * @memberof NativeDownloadTrigger
     */
    public download(url: string, name: string = ''): void {
        const iframe = document.createElement('iframe');
        iframe.addEventListener('error', () => {
            const saveLink = document.createElement('a');
            saveLink.href = url;
            saveLink.download = name;
            try {
                const event = new MouseEvent('click');
                saveLink.dispatchEvent(event);
            } catch {
                // IE11
                const event = new CustomEvent('click');
                saveLink.dispatchEvent(event);
            }
        });
        iframe.setAttribute('sandbox', 'allow-downloads allow-scripts');
        iframe.src = url;
        iframe.hidden = true;

        document.body.appendChild(iframe);
        // Remove the iframe after a certain time since if the download didn't start it won't start at all. Once the download started the iframe can be deleted anytime so no need to check.
        setTimeout(() => { document.body.removeChild(iframe); }, this.iFrameDeletionTime);
    }
}