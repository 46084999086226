import { ApplicationConfig, IConfigManager } from '../config';
import { ITemplateExtension } from '../extensions';
import { ILanguageManager } from '../language';
import { Logger } from '../logging';
import { IPopupHelper } from '../ui';
import { EditRouter } from './editRouter';
import { INewViewHandler, IViewNavigationHandler, IViewNavigationHandlerFactory } from './interfaces';
import { RootRouter } from './rootRouter';
import { ViewRouter } from './viewRouter';
import { ViewNavigationHandler } from '.';

/**
 * Factory for creating IViewNavigationHandler instances.
 * 
 * @export
 * @class ViewNavigationHandlerFactory
 */
export class ViewNavigationHandlerFactory implements IViewNavigationHandlerFactory {
    private appConfig: ApplicationConfig;
    private logger: Logger;
    private popupHelper: IPopupHelper;
    private configManager: IConfigManager;
    private languageManager: ILanguageManager;
    private templateExtension: ITemplateExtension;
    private newViewHandler?: INewViewHandler;
    private viewRouter: ViewRouter;
    private rootRouter: RootRouter;
    private editRouter: EditRouter;

    /**
     * Creates an instance of ViewNavigationHandlerFactory.
     * @param {ApplicationConfig} appConfig
     * @param {Logger} logger
     * @param {IPopupHelper} popupHelper
     * @param {IConfigManager} configManager
     * @param {ILanguageManager} languageManager
     * @param {ITemplateExtension} templateExtension
     * @param {ViewRouter} viewRouter
     * @param {RootRouter} rootRouter
     * @param {EditRouter} editRouter
     * @param {INewViewHandler} [newViewHandler]
     * @memberof ViewNavigationHandlerFactory
     */
    public constructor(appConfig: ApplicationConfig, logger: Logger, popupHelper: IPopupHelper,
        configManager: IConfigManager, languageManager: ILanguageManager,
        templateExtension: ITemplateExtension, viewRouter: ViewRouter, rootRouter: RootRouter,
        editRouter: EditRouter, newViewHandler?: INewViewHandler) {

        this.appConfig = appConfig;
        this.logger = logger;
        this.popupHelper = popupHelper;
        this.configManager = configManager;
        this.languageManager = languageManager;
        this.templateExtension = templateExtension;
        this.newViewHandler = newViewHandler;
        this.viewRouter = viewRouter;
        this.rootRouter = rootRouter;
        this.editRouter = editRouter;
    }

    /**
     * Creates an instance of IViewNavigationHandler.
     * 
     * @param {HTMLElement} element Element to pass to the IViewNavigationHandler instance.
     * @returns {IViewNavigationHandler} 
     * @memberof ViewNavigationHandlerFactory
     */
    public create(element: HTMLElement): IViewNavigationHandler {
        return new ViewNavigationHandler(element, this.appConfig, this.logger, this.popupHelper, this.configManager, this.languageManager,
            this.templateExtension, this.viewRouter, this.rootRouter, this.editRouter, this.newViewHandler);
    }
}