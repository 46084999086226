import { ResponseContainerDTO } from 'typings/core';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HttpResourcePointer } from '../../dataProviders';
import { SubscriptionBulkRequestOptions } from '../models/subscriptionBulkRequestOptions';
import { ServiceAction } from '../serviceAction';

/**
 * The CreateSubscriptions action is used to create multiple subsciption with given event and element within windream
 * 
 * @export
 * @class CreateSubscriptions
 * @extends {ServiceAction}
 */
export class CreateSubscriptions extends ServiceAction {

  /**
   * Name of the service action.
   * 
   * 
   * @memberof CreateSubscriptions
   */
  public name = 'createSubscriptions';

  /**
   * Execute CreateSubscriptions of given event and document/folder.
   *
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {SubscriptionRequestOptions} subscriptionRequestOptions The request options.
   * @returns {Promise<ResponseContainerDTO>} A promise, which will resolve with the server response.
   * @memberof CreateSubscriptions
   */
  public async do(subscriptionRequestOptions: SubscriptionBulkRequestOptions): Promise<void> {

    return new Promise<void>((resolve, reject) => {
      const subscriptionOptionsDTO = subscriptionRequestOptions.subscriptions.map((subscription) => {
        return {
          Item: subscription.item,
          Flags: subscription.flags,
          Subscriber: subscription.subscriber,
          Event: {
            Id: subscription.event
          },
          Entity: subscription.entity
        };
      });
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/subscriptions/CreateSubscriptions',
        {
          Subscriptions: subscriptionOptionsDTO
        }
      ), subscriptionRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
        if (response.data?.HasErrors) {
          this.logger.error('CreateSubscriptions', 'do', 'Failed to create bulk subscription', response);
          reject('Failed to Failed to create bulk subscription');
        } else {
          if (response.data) {
            resolve();
          }
        }
      }).catch((err: Error) => {
        this.logger.error('CreateSubscription', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}