import { RowData, ValueTypes } from '../../../../typings/core';


/**
 * The HeaderData model.
 * 
 * @export
 * @class HeaderData
 * @template T 
 */
export class HeaderData<T> {

    /**
     * The name of the header.
     *
     * @type {string}
     * @memberof HeaderData
     */
    public name?: string;

    /**
     * The display value of this header.
     * 
     * @type {string}
     * @memberof HeaderData
     */
    public displayValue?: string;

    /**
     * The data object.
     * 
     * @type {T}
     * @memberof HeaderData
     */
    public dataObject?: T;

    /**
     * The onClick callback, which will be executed if the item was clicked.
     * 
     * 
     * @memberof HeaderData
     */
    public onClick?: (data: T) => void;

    /**
     * The sorting function.
     * 
     * 
     * @memberof HeaderData
     */
    public sorting?: (a: RowData<T>, b: RowData<T>) => number;

    /**
     * Whether the header is sortable or not.
     * 
     * @type {boolean}
     * @memberof HeaderData
     */
    public sortable?: boolean;


    /**
     * The contextmenu event occured.
     * 
     * 
     * @memberof HeaderData
     */
    public onContextmenu?: (event: Event, data: HeaderData<T>) => void;


    /**
     * Width of the column.
     * 
     * @type {(number | 'auto')}
     * @memberof HeaderData
     */
    public width?: number | 'auto';

    /**
     * The header date type.
     *
     * @memberof HeaderData
     */
    public valueType?: number | ValueTypes;
}