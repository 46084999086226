import { DWCore } from '@windream/dw-core/dwCore';

/**
 * Represents a component load manifest.
 *
 * @export
 * @class ComponentLoadManifest
 */
export class ComponentLoadManifest implements DWCore.Components.ComponentLoadManifest {

    /**
     * The id of the component.
     *
     * @type {string}
     * @memberof ComponentLoadManifest
     */
    public componentId: string;

    /**
     * The url file path for the config.
     *
     * @type {string}
     * @memberof ComponentLoadManifest
     */
    public componentConfigFileUrl: string;

    /**
     * The component base path.
     *
     * @type {string}
     * @memberof ComponentLoadManifest
     */
    public componentBasePath: string;

    /**
     * Creates an instance of ComponentLoadManifest.
     * 
     * @param {string} componentId The component id.
     * @param {string} componentConfigFileUrl The url file path for the config.
     * @param {string} componentBasePath The component base path.
     * @memberof ComponentLoadManifest
     */
    public constructor(componentId: string, componentConfigFileUrl: string, componentBasePath: string) {
        this.componentConfigFileUrl = componentConfigFileUrl;
        this.componentId = componentId;
        this.componentBasePath = componentBasePath;
    }
}