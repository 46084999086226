import { SharedSettingEntry } from 'typings/core';
import { LanguageProvider as ILanguageProvider } from '../../../typings/language';
import { ISharedSettings } from '../config';

import { Logger } from '../logging';

type SettingsTypes = 'variables' | 'choiceLists' | 'paths' | 'objectTypes';

/**
 * Implementation to provide settings in the public API.
 *
 * @export
 * @class SharedSettingsProvider
 */
export class SharedSettingsProvider {
    private globalSettings: ISharedSettings;
    private viewSettings: ISharedSettings;
    private tempSettings: ISharedSettings;
    private languageProvider: ILanguageProvider;
    private logger: Logger;

    /**
     * Creates an instance of SharedSettingsProvider.
     *
     * @param {ILanguageProvider} languageProvider Language Provider to use.
     * @param {Logger} logger Logger to use.
     * @memberof SharedSettingsProvider
     */
    public constructor(languageProvider: ILanguageProvider, logger: Logger) {
        this.languageProvider = languageProvider;
        this.logger = logger;
        this.globalSettings = {};
        this.viewSettings = {};
        this.tempSettings = {};
    }

    /**
     * Sets the global settings.
     *
     * @param {ISharedSettings} settings The settings to set.
     * @memberof SharedSettingsProvider
     */
    public setGlobalSettings(settings: ISharedSettings): void {
        this.globalSettings = settings || {};
    }

    /**
     * Sets the view settings.
     *
     * @param {ISharedSettings} settings The settings to set.
     * @memberof SharedSettingsProvider
     */
    public setViewSettings(settings: ISharedSettings): void {
        this.viewSettings = settings || {};
    }

    /**
     * Sets a temporary setting.
     *
     * @param {string} settingName Name of the setting.
     * @param {(string | number | boolean | null } value The value.
     * @memberof SharedSettingsProvider
     */
    public setTempSetting(settingName: string, value: string | number | boolean | null): void {
        if (!this.tempSettings.variables) {
            this.tempSettings.variables = {};
        }
        this.tempSettings.variables[settingName] = {
            // Use dummy language 'current'
            newValue: {
                value: {
                    current: value
                }
            } as any
        } as any;
    }

    /**
     * Clears the temporary settings.
     *
     * @memberof SharedSettingsProvider
     */
    public clearTempSettings(): void {
        this.tempSettings = {};
    }

    /**
     * Returns the translated value of a variable for the given setting.
     * Returns null if nothing is set.
     *
     * @param {string} settingName Name of the setting.
     * @returns {(string | number | boolean | null)} The setting's value.
     * @memberof SharedSettingsProvider
     */
    public get(settingName: string): string | number | boolean | null {
        const setting = this.getSetting('variables', settingName);
        if (setting) {
            if ((setting as any).value) {
                return this.languageProvider.getTranslationFromProperty((setting as any).value as any);
            }
            if (setting.newValue && setting.newValue.value) {
                return this.languageProvider.getTranslationFromProperty(setting.newValue.value as any);
            }
            return null;
        }
        this.logger.warn('SharedSettingsProvider', 'get', `No setting found for '${settingName}'`);
        return null;
    }

    /**
     * Returns the setting of the given type with the given name.
     * Will check in this order:
     * 1. Temporary settings
     * 2. View settings
     * 3. Global settings
     * Null if nothing is found.
     *
     * @private
     * @param {SettingsTypes} settingsType The type of the setting to get.
     * @param {string} settingName The name of the setting to get.
     * @returns {(SharedSettingEntry | null)} The setting, null if not found.
     * @memberof SharedSettingsProvider
     */
    private getSetting(settingsType: SettingsTypes, settingName: string): SharedSettingEntry | null {
        const tempSettingsForType = this.tempSettings[settingsType];
        if (tempSettingsForType && tempSettingsForType[settingName]) {
            return tempSettingsForType[settingName];
        }
        const viewSettingsForType = this.viewSettings[settingsType];
        if (viewSettingsForType && viewSettingsForType[settingName]) {
            return viewSettingsForType[settingName];
        }
        const globalSettingsForType = this.globalSettings[settingsType];
        if (globalSettingsForType && globalSettingsForType[settingName]) {
            return globalSettingsForType[settingName];
        }
        return null;
    }
}
