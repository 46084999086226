import { IClonable } from '../../common';
import { RIGHTS } from '.';

/**
 * Model to reflect a single right entry.
 * 
 * @export
 * @class RightModel
 */
export class RightModel implements IClonable<RightModel> {
    /**
     * Right that is reflected by the model.
     * 
     * @type {RIGHTS}
     * @memberof RightModel
     */
    public right: RIGHTS;
    /**
     * Whether the right is set.
     * 
     * @type {boolean}
     * @memberof RightModel
     */
    public isSet: boolean;

    /**
     * Creates an instance of RightModel.
     * @param {RIGHTS} right Right that is reflected by the model.
     * @param {boolean} isSet Whether the right is set.
     * @memberof RightModel
     */
    public constructor(right: RIGHTS, isSet: boolean) {
        this.right = right;
        this.isSet = isSet;
    }

    /**
     * Creates a clone of this instance.
     * 
     * @returns {RightModel} The clone.
     * @memberof RightModel
     */
    public clone(): RightModel {
        const clone = new RightModel(this.right, this.isSet);
        return clone;
    }
}