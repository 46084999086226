/**
 * PubSub definitions.
 *
 * @export
 * @class PubSubDefinitions
 */
export class PubSubDefinitions {

    public static PUBSUB_INPUT_SET_ENABLED_NAME = 'SetEnabled';
    public static PUBSUB_INPUT_SET_DISABLED_NAME = 'SetDisabled';

}