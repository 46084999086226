import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents request options for a view.
 *
 * @export
 * @class ViewRequestOptions
 * @extends {BaseRequestOptions}
 */
export class ViewRequestOptions extends BaseRequestOptions {

    /**
     * The view id.
     *
     * @type {string}
     * @memberof ViewRequestOptions
     */
    public viewId: string;

    /**
     * Creates an instance of ViewRequestOptions.
     * @param {string} viewId
     * @memberof ViewRequestOptions
     */
    public constructor(viewId: string) {
        super();
        if (typeof viewId !== 'string') {
            throw new Error('Argument \'viewId\' is not valid');
        }
        this.viewId = viewId;
    }
}