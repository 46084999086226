import { DWCore } from '@windream/dw-core/dwCore';
import { Utils } from '../common';
import { IComponentManager } from '../components';
import { ComponentConfig, IConfigLoader, PubSubEditorComponentConfig, PubSubEditorConfig, Style, ViewConfig } from '../config';
import { ILanguageManager, ILanguageProvider } from '../language';
import { IUiManager } from '../loader';
import { Logger } from '../logging';
import { IPubSubHandler, PubSubConfig } from '../pubSub';
import { IServiceManager, PubSubEditorRequestOptions, UpdatePubSubEditorConfigRequestOptions } from '../services';
import { IPubSubConnectionConfigWrapper, PubSubConnectionHelper } from '../shared';
import { IPopupHelper, IPopupOptions, IUiHelper } from './interfaces';
import { POPUP_TYPES, PubSubUiHelperUtil } from '.';

/**
 * Class to manage the connection between the framework and the PubSub configuration.
 * 
 * @export
 * @class PubSubUiHelper
 * @implements {IUiHelper}
 */
export class PubSubUiHelper implements IUiHelper {

    /**
     * Callback to execute when config changes.
     *
     * @memberof PubSubUiHelper
     */
    public onConfigChange?: (isDirty: boolean) => void;

    private readonly className = 'PubSubUiHelper';
    private readonly MAINFRAME_ELEMENT_ID = 'wd-pubsub-mainframe-container';
    private readonly ADD_ELEMENT_ID = 'wd-pubsub-add-container';
    private readonly ADD_COMPONENT_PANEL_ELEMENT_ID = 'wd-add-pubsub-panel';
    private readonly CONFIG_COMPONENT_ELEMENT_ID = 'wd-pubsub-config-panel';

    private pubSubHandler: IPubSubHandler;
    private logger: Logger;
    private componentManager: IComponentManager;
    private configLoader: IConfigLoader;
    private popupHelper: IPopupHelper;
    private languageProvider: ILanguageProvider;
    private languageManager: ILanguageManager;
    private serviceManager: IServiceManager;
    private uiManager: IUiManager;
    private currentViewConfig?: ViewConfig;
    private viewConfigBackup?: ViewConfig;
    private currentPubSubEditorConfig: PubSubEditorConfig | null;
    private pubSubEditorConfigBackup?: PubSubEditorConfig;
    private currentDevice?: DWCore.Common.Devices;
    private isInitialized: boolean;
    private isInitializing: boolean;
    private addConnectionPopup: any;
    private currentlyEditedConnectionIndex?: number;
    private activeSubViewId?: string;
    private isActive: boolean;


    /**
     * Creates an instance of PubSubUiHelper.
     *
     * @param {IPubSubHandler} pubSubHandler
     * @param {Logger} logger
     * @param {IComponentManager} componentManager
     * @param {IConfigLoader} configLoader
     * @param {IPopupHelper} popupHelper
     * @param {ILanguageManager} languageManager
     * @param {IServiceManager} serviceManager
     * @param {IUiManager} uiManager
     * @param {DWCore.Views.ViewProvider} [viewProvider]
     * @memberof PubSubUiHelper
     */
    public constructor(pubSubHandler: IPubSubHandler, logger: Logger, componentManager: IComponentManager, configLoader: IConfigLoader,
        popupHelper: IPopupHelper, languageManager: ILanguageManager, serviceManager: IServiceManager, uiManager: IUiManager) {
        this.pubSubHandler = pubSubHandler;
        this.logger = logger;
        this.componentManager = componentManager;
        this.configLoader = configLoader;
        this.popupHelper = popupHelper;
        this.languageProvider = languageManager.getLanguageProvider('framework');
        this.languageManager = languageManager;
        this.serviceManager = serviceManager;
        this.uiManager = uiManager;

        this.isInitialized = false;
        this.isInitializing = false;
        this.isActive = false;
        this.currentPubSubEditorConfig = null;
    }

    /**
     * Updates the used ViewConfig.
     * 
     * @param {ViewConfig} viewConfig 
     * @param {DWCore.Common.Devices} device 
     * @param {string} senderGuid The GUID of the component that triggered the publish.
     * @memberof PubSubUiHelper
     */
    public updateViewConfig(viewConfig: ViewConfig, device: DWCore.Common.Devices, senderGuid?: string): void {
        const isNewViewId = !this.currentViewConfig || !Utils.Instance.isDefined(this.currentDevice) || viewConfig.id !== this.currentViewConfig.id || device !== this.currentDevice;
        let pubSubChanged = false;
        try {
            pubSubChanged = !Utils.Instance.isDeepEqual(viewConfig.pubSub, this.currentViewConfig?.pubSub);
        } catch {
            pubSubChanged = true;
        }
        this.currentViewConfig = viewConfig;
        this.currentDevice = device;
        this.activeSubViewId = viewConfig.subViews[0].id;
        if (this.isActive && (senderGuid || !isNewViewId)) { // Only publish update if this was triggered internally, i.e. with a sender GUID
            this.publishCurrentConfig(senderGuid);
        }
        this.viewConfigBackup = Utils.Instance.deepClone(this.currentViewConfig);
        if (pubSubChanged && isNewViewId) {
            this.getPubSubEditorConfigForView(this.currentViewConfig.id, this.currentDevice).then((pubSubEditorConfig) => {
                this.currentPubSubEditorConfig = pubSubEditorConfig;
                this.pubSubEditorConfigBackup = Utils.Instance.deepClone(this.currentPubSubEditorConfig);

                if (this.isActive && (senderGuid || !isNewViewId)) { // Only publish update if this was triggered internally, i.e. with a sender GUID
                    this.publishCurrentConfig(senderGuid);
                }
                this.viewConfigBackup = Utils.Instance.deepClone(this.currentViewConfig);
            }).catch((err: Error) => {
                this.currentPubSubEditorConfig = null;
                this.logger.error(this.className, 'updateViewConfig', 'Failed to pre-fetch PubSubEditorConfig for new view', err);
            });
        }
    }

    /**
     * Gets the current view config.
     * 
     * @returns {ViewConfig | undefined} 
     * @memberof PubSubUiHelper
     */
    public getCurrentViewConfig(): ViewConfig | undefined {
        return this.currentViewConfig;
    }

    /**
     * Saves the current PubSubEditorConfig.
     * 
     * @returns {Promise<void>} Promise to resolve with the config after saving was successful.
     * @async
     * @memberof PubSubUiHelper
     */
    public async save(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.checkForInvalidComponents();
            if (!this.currentPubSubEditorConfig) { // Do not save if no config has been loaded to avoid saving an empty PubSub configuration
                resolve();
                return;
            }
            if (!Utils.Instance.isDefined(this.currentViewConfig) || !Utils.Instance.isDefined(this.currentDevice)) {
                this.logger.error(this.className, 'save', 'No ViewConfig or Device set', [this.currentViewConfig, this.currentDevice]);
                reject(new Error('No ViewConfig or Device set'));
                return;
            }
            this.serviceManager.getServices().DynamicWorkspace.updatePubSubEditorConfig(
                new UpdatePubSubEditorConfigRequestOptions(this.currentViewConfig.id, this.currentDevice, this.currentPubSubEditorConfig)
            ).then(() => {
                if (this.currentPubSubEditorConfig) {
                    this.pubSubEditorConfigBackup = Utils.Instance.deepClone(this.currentPubSubEditorConfig);
                }
                resolve();
            }).catch((error) => reject(error));
        });
    }

    /**
     * Switches the active SubView.
     * 
     * @param {string} subViewId ID of the now active SubView.
     * @memberof PubSubUiHelper
     */
    public switchSubView(subViewId: string): void {
        this.activeSubViewId = subViewId;
        this.pubSubHandler.publish(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'SwitchedSubView', this.activeSubViewId);
        this.pubSubHandler.publish(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID, 'SwitchedSubView', this.activeSubViewId);
        this.pubSubHandler.publish(PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID, 'SwitchedSubView', this.activeSubViewId);
        this.pubSubHandler.publish(PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID, 'SwitchedSubView', this.activeSubViewId);
    }

    /**
     * Shows the PubSub configuration.
     * 
     * @async
     * @returns {Promise<void>} Promise to resolve once the component is loaded and shown.
     * @memberof PubSubUiHelper
     */
    public async show(): Promise<void> {
        if (!Utils.Instance.isDefined(this.currentViewConfig) || !Utils.Instance.isDefined(this.currentDevice)) {
            this.logger.error(this.className, 'show', 'No ViewConfig or Device set', [this.currentViewConfig, this.currentDevice]);
            return Promise.reject(new Error('No ViewConfig or Device set'));
        }
        this.isActive = true;
        if (this.isInitializing) {
            return Promise.resolve();
        }
        // Avoid reloading if nothing has changed
        if (this.isInitialized && this.currentPubSubEditorConfig
            && this.currentPubSubEditorConfig.viewId === this.currentViewConfig.id && this.currentPubSubEditorConfig.device === this.currentDevice) {
            this.publishCurrentConfig();
            return Promise.resolve();
        }
        this.isInitializing = true;
        if (this.isInitialized) {
            this.uiManager.isLoading(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID);
            this.uiManager.isLoading(PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID);
            this.uiManager.isLoading(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID);
        }
        return this.getPubSubEditorConfigForView(this.currentViewConfig.id, this.currentDevice).then(async (pubSubEditorConfig) => {
            this.updatePubSubEditorConfig(pubSubEditorConfig);
            if (!this.isInitialized) {
                return Promise.all([this.loadMainComponent(), this.loadAddConnectionComponent(), this.loadAddComponentComponent(), this.loadConfigurationComponent()])
                    .then(async () => {
                        this.isInitialized = true;
                        this.isInitializing = false;
                        PubSubUiHelperUtil.addMainframeConfigurationPubSub(this.pubSubHandler);
                        return Promise.resolve();
                    })
                    .catch((err: Error) => {
                        this.isInitializing = false;
                        this.logger.error('PubSubUiHelper', 'show', 'Failed to load components', err);
                    });
            } else {
                this.isInitializing = false;
                this.publishCurrentConfig();
                this.uiManager.isAvailable(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID);
                this.uiManager.isAvailable(PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID);
                this.uiManager.isAvailable(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID);
                return Promise.resolve();
            }
        }).catch((err) => {
            this.isInitializing = false;
            this.logger.error('PubSubUiHelper', `show', 'Failed to load PubSubEditorConfig for view ${this.currentViewConfig?.id}`, err);
        });
    }

    /**
     * Hides the PubSub configuration.
     * 
     * @async
     * @returns {Promise<void>} Promise to resolve once the component is hidden.
     * @memberof PubSubUiHelper
     */
    public async hide(): Promise<void> {
        this.isActive = false;
        return Promise.resolve();
    }

    /**
     * Checks if the current configuration has changed.
     * 
     * @async
     * @returns {Promise<boolean>} Promise to resolve with whether the configuration has changed.
     * @memberof PubSubUiHelper
     */
    public async hasChanges(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.pubSubHandler.publish(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'HasChanges', (hasChanges: boolean) => {
                resolve(hasChanges);
            });
        });
    }

    /**
     * Updates the PubSubEditorConfig.
     * Publishes changes to each component.
     * 
     * @private
     * @param {PubSubEditorConfig} pubSubEditorConfig The config to use.
     * @param {string} senderGuid The GUID of the component that triggered the publish.
     * @memberof PubSubUiHelper
     */
    private updatePubSubEditorConfig(pubSubEditorConfig: PubSubEditorConfig, senderGuid?: string): void {
        this.currentPubSubEditorConfig = pubSubEditorConfig;
        this.pubSubEditorConfigBackup = Utils.Instance.deepClone(pubSubEditorConfig);
        this.publishCurrentConfig(senderGuid);
    }

    /**
     * Updates the whole config using the config wrapper.
     * Publishes changes to each component.
     * 
     * @private
     * @param {IPubSubConnectionConfigWrapper} config The new config to use.
     * @param {string} senderGuid The GUID of the component that triggered the publish.
     * @memberof PubSubUiHelper
     */
    private updateConfig(config: IPubSubConnectionConfigWrapper, senderGuid?: string): void {
        this.currentViewConfig = config.viewConfig;

        if (senderGuid === PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID) {
            // Only the Mainframe has this information
            this.currentPubSubEditorConfig = config.pubSubEditorConfig;
        }

        if (!senderGuid) { // If no GUID is set, the function was called from outside (i.e. ConfigUiHelper)
            this.viewConfigBackup = Utils.Instance.deepClone(config.viewConfig);
            this.pubSubEditorConfigBackup = Utils.Instance.deepClone(config.pubSubEditorConfig);
        }
        this.publishCurrentConfig(senderGuid);
    }

    /**
     * Updates the UI part of the config using the config wrapper.
     *  
     * @private
     * @param {IPubSubConnectionConfigWrapper} config
     * @param {string} [senderGuid]
     * @memberof PubSubUiHelper
     */
    private updateUIConfig(config: IPubSubConnectionConfigWrapper): void {
        this.currentViewConfig = config.viewConfig;
        this.currentPubSubEditorConfig = config.pubSubEditorConfig;
        this.handleConfigChange();
    }

    /**
     * Gets the PubSubEditorConfig for the given View and Device.
     * 
     * @async
     * @private
     * @param {string} viewId ID of the view to get PubSubEditorConfig for.
     * @param {DWCore.Common.Devices} device Device to get PubSubEditorConfig for.
     * @returns {Promise<PubSubEditorConfig>} Promise to resolve with the PubSubEditorConfig.
     * @memberof PubSubUiHelper
     */
    private async getPubSubEditorConfigForView(viewId: string, device: DWCore.Common.Devices): Promise<PubSubEditorConfig> {
        if (!Utils.Instance.isDefined(this.currentViewConfig) || !Utils.Instance.isDefined(this.currentDevice)) {
            this.logger.error(this.className, 'getPubSubEditorConfigForView', 'No ViewConfig or Device set', [this.currentViewConfig, this.currentDevice]);
            return Promise.reject(new Error('No ViewConfig or Device set'));
        }

        return new Promise<PubSubEditorConfig>((resolve, reject) => {
            this.serviceManager.getServices().DynamicWorkspace.loadPubSubEditorConfig(new PubSubEditorRequestOptions(viewId, device))
                .then((config) => {
                    // TODO: Remove once we are sure, that the information was set in the back-end.
                    config.device = device;
                    config.viewId = viewId;
                    resolve(config);
                }).catch((error) => reject(error));
        });

    }

    /**
     * Publishes changes to all components expect the sender GUID.
     * 
     * @private
     * @param {string} senderGuid The GUID of the component that triggered the publish.
     * @memberof PubSubUiHelper
     */
    private publishCurrentConfig(senderGuid?: string): void {
        const wrapper = {
            pubSubEditorConfig: this.currentPubSubEditorConfig,
            viewConfig: this.currentViewConfig
        } as IPubSubConnectionConfigWrapper;

        if (senderGuid !== PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID) {
            this.pubSubHandler.publish(PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID, 'UpdatedConfig', wrapper);
        }
        if (senderGuid !== PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID) {
            this.pubSubHandler.publish(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'UpdatedConfig', wrapper);
        }

        if (senderGuid !== PubSubUiHelperUtil.CONFIG_COMPONENT_GUID) {
            this.pubSubHandler.publish(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID, 'UpdatedConfig', wrapper);
        }

        if (senderGuid !== PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID) {
            this.pubSubHandler.publish(PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID, 'UpdatedConfig', wrapper);
        }

        this.handleConfigChange();

    }

    /**
     * Loads the PubSub Mainframe component into the DOM.
     * 
     * @async
     * @private
     * @returns {Promise<void>} Promise to resolve once the component is loaded.
     * @memberof PubSubUiHelper
     */
    private async loadMainComponent(): Promise<void> {
        const methodName = 'loadMainComponent';
        return new Promise<void>((resolve, reject) => {
            this.configLoader.getAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
                this.componentManager.addComponent({
                    component: 'com.windream.pubSub.mainframe',
                    configuration: {
                        activeSubViewId: this.activeSubViewId,
                        availableComponents: componentConfigs,
                        currentSettings: Utils.Instance.deepClone({
                            pubSubEditorConfig: this.currentPubSubEditorConfig,
                            viewConfig: this.currentViewConfig
                        }) as IPubSubConnectionConfigWrapper
                    },
                    guid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    isTitleVisible: false,
                    name: { en: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID },
                    position: `#${this.MAINFRAME_ELEMENT_ID}`,
                    style: Style.default()
                }, this.pubSubHandler)
                    .then(() => {
                        PubSubUiHelperUtil.addMainPubSub(this.pubSubHandler);
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'SavedConfig', (newConfigPromise: Promise<IPubSubConnectionConfigWrapper>) => {
                            newConfigPromise.then((newConfig) => {
                                this.updateConfig(newConfig, PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'SavedConfig pubsub catched error', error);
                            });
                        });
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'UIConfigChanged', (newConfigPromise: Promise<IPubSubConnectionConfigWrapper>) => {
                            newConfigPromise.then((newConfig) => {
                                this.updateUIConfig(newConfig);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'UIConfigChanged pubsub catched error', error);
                            });
                        });
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'NewConnectionIntent', (pubSubConfigPromise: Promise<PubSubConfig>) => {
                            pubSubConfigPromise.then((pubSubConfig) => {
                                this.openNewConnectionPopup(pubSubConfig);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'NewConnectionIntent pubsub catched error', error);
                            });
                        });
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'SelectedComponent', (outComponentGuidPromise: Promise<string>) => {
                            outComponentGuidPromise.then((outComponentGuid) => {
                                this.pubSubHandler.publish(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID, 'SelectedComponent', outComponentGuid);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'SelectedComponent pubsub catched error', error);
                            });
                        });
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'SelectedConnection', (guidsPromise: Promise<string[]>) => {
                            guidsPromise.then((guids) => {
                                this.pubSubHandler.publish(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID, 'SelectedConnection', guids);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'SelectedConnection pubsub catched error', error);
                            });
                        });
                        resolve();
                    }).catch((err: Error) => {
                        this.logger.error(this.className, 'loadMainComponent', 'Failed to load PubSub main component', err);
                        reject(err);
                    });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * Loads the pubsub sidepanel.
     * 
     * @private
     * @returns {Promise<void>} 
     * @memberof PubSubUiHelper
     */
    private async loadConfigurationComponent(): Promise<void> {
        const methodName = 'loadConfigurationComponent';
        return new Promise<void>((resolve, reject) => {
            this.configLoader.getAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
                this.componentManager.addComponent({
                    component: 'com.windream.pubSub.sidePanel',
                    configuration: {
                        activeSubViewId: this.activeSubViewId,
                        availableComponents: componentConfigs,
                        currentSettings: Utils.Instance.deepClone({
                            pubSubEditorConfig: this.currentPubSubEditorConfig,
                            viewConfig: this.currentViewConfig
                        }) as IPubSubConnectionConfigWrapper
                    },
                    guid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    isTitleVisible: false,
                    name: { en: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID },
                    position: `#${this.CONFIG_COMPONENT_ELEMENT_ID} .config-panel-content`,
                    style: Style.default()
                }, this.pubSubHandler)
                    .then(() => {
                        PubSubUiHelperUtil.addConfigurationPubSub(this.pubSubHandler);
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID, 'NewConnectionIntent', (connectionPromise: Promise<PubSubConfig>) => {
                            connectionPromise.then((connection) => {
                                this.openNewConnectionPopup(connection);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'NewConnectionIntent pubsub catched error', error);
                            });
                        });
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.CONFIG_COMPONENT_GUID, 'SavedConfig', (newConfigPromise: Promise<IPubSubConnectionConfigWrapper>) => {
                            newConfigPromise.then((newConfig) => {
                                this.updateConfig(newConfig, PubSubUiHelperUtil.CONFIG_COMPONENT_GUID);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'SavedConfig pubsub catched error', error);
                            });
                        });
                        resolve();
                    }).catch((err: Error) => {
                        this.logger.error(this.className, 'loadSidePanelComponent', 'Failed to load PubSub sidepanel component', err);
                        reject(err);
                    });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * Loads the PubSub Add Connection component into the DOM.
     * 
     * @private
     * @returns {Promise<void>} 
     * @memberof PubSubUiHelper
     */
    private async loadAddConnectionComponent(): Promise<void> {
        const methodName = 'loadAddConnectionComponent';
        return new Promise<void>((resolve, reject) => {
            // Show popup
            const popupOptions: IPopupOptions = {
                allowOverflow: true,
                body: `<div id="${this.ADD_ELEMENT_ID}"></div>`,
                buttons: [
                    {
                        buttonId: 'wd-pubsub-button-add-connection-button',
                        label: this.languageProvider.get('framework.generic.save')
                    },
                    {
                        attributes: [{ attribute: 'data-close', value: '' }],
                        label: this.languageProvider.get('framework.generic.cancel')
                    }
                ],
                immediatelyOpen: false,
                size: 'tiny',
                title: this.languageProvider.get('framework.config.pubSub.newConnection'),
            };
            this.addConnectionPopup = this.popupHelper.openPopup(popupOptions);
            this.configLoader.getAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
                this.componentManager.addComponent({
                    component: 'com.windream.pubSub.addConnection',
                    configuration: {
                        activeSubViewId: this.activeSubViewId,
                        availableComponents: componentConfigs,
                        currentSettings: Utils.Instance.deepClone({
                            pubSubEditorConfig: this.currentPubSubEditorConfig,
                            viewConfig: this.currentViewConfig
                        }) as IPubSubConnectionConfigWrapper
                    },
                    guid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    isTitleVisible: false,
                    name: { en: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID },
                    position: `#${this.ADD_ELEMENT_ID}`,
                    style: Style.default()
                }, this.pubSubHandler)
                    .then(() => {
                        PubSubUiHelperUtil.addAddConnectionPubSub(this.pubSubHandler);
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID, 'SavedConfig', (newConfigPromise: Promise<IPubSubConnectionConfigWrapper>) => {
                            newConfigPromise.then((newConfig) => {
                                this.handleNewConnection(newConfig.viewConfig, PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'SavedConfig pubsub catched error', error);
                            });
                        });
                        resolve();
                    }).catch((err: Error) => {
                        this.logger.error(this.className, 'loadAddComponent', 'Failed to load PubSub add component', err);
                        reject(err);
                    });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * Loads the PubSub Add Component component into the DOM.
     * 
     * @private
     * @returns {Promise<void>} 
     * @memberof PubSubUiHelper
     */
    private async loadAddComponentComponent(): Promise<void> {
        const methodName = 'loadAddComponentComponent';
        return new Promise<void>((resolve, reject) => {
            this.configLoader.getAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
                this.componentManager.addComponent({
                    component: 'com.windream.pubSub.addComponent',
                    configuration: {
                        activeSubViewId: this.activeSubViewId,
                        availableComponents: componentConfigs,
                        currentSettings: Utils.Instance.deepClone({
                            pubSubEditorConfig: this.currentPubSubEditorConfig,
                            viewConfig: this.currentViewConfig
                        }) as IPubSubConnectionConfigWrapper
                    },
                    guid: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID,
                    isTitleVisible: false,
                    name: { en: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID },
                    position: `#${this.ADD_COMPONENT_PANEL_ELEMENT_ID} .config-panel-content`,
                    style: Style.default()
                }, this.pubSubHandler)
                    .then(() => {
                        PubSubUiHelperUtil.addAddComponentPubSub(this.pubSubHandler);
                        this.pubSubHandler.subscribe(PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID, 'AddComponent', (componentPromise: Promise<PubSubEditorComponentConfig>) => {
                            componentPromise.then((component) => {
                                this.pubSubHandler.publish(PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'AddedComponent', component);
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'AddComponent pubsub catched error', error);
                            });
                        });
                        resolve();
                    }).catch((err: Error) => {
                        this.logger.error(this.className, 'loadAddComponent', 'Failed to load PubSub add drop component', err);
                        reject(err);
                    });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * Opens popup to add a new connection.
     * Will update current ViewConfig with new values.
     * 
     * @private
     * @param {PubSubConfig} connection Preselected connection.
     * @memberof PubSubUiHelper
     */
    private openNewConnectionPopup(connection: PubSubConfig): void {
        let addConnectionPopupTitle = this.languageProvider.get('framework.config.pubSub.newConnection');
        if (
            connection.in && connection.in.length > 0 && connection.in[0].componentGuid && connection.in[0].parameter
            && connection.out && connection.out.length > 0 && connection.out[0].componentGuid && connection.out[0].parameter
        ) { // Connection was already fully set, so it is an edit
            this.currentlyEditedConnectionIndex = this.currentViewConfig?.pubSub.findIndex((existingConnection) => {
                return connection.in[0].componentGuid === existingConnection.in[0].componentGuid && connection.in[0].parameter === existingConnection.in[0].parameter
                    && connection.out[0].componentGuid === existingConnection.out[0].componentGuid && connection.out[0].parameter === existingConnection.out[0].parameter;
            });
            if (typeof this.currentlyEditedConnectionIndex !== 'number' || this.currentlyEditedConnectionIndex === -1) {
                this.logger.error(this.className, 'openNewConnectionPopup', 'Not able to find connection to edit', connection);
            }
            addConnectionPopupTitle = this.languageProvider.get('framework.config.pubSub.editConnection');
        }
        this.pubSubHandler.publish(PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID, 'NewConnection', connection);

        this.popupHelper.updatePopupTitle(this.addConnectionPopup, addConnectionPopupTitle);
        // Normally current popup instance is set when calling 'popupHelper.openPopup' (this method creates a new popup).
        // But when using an existing popup and only call open directly on this object, the popup instance of popup helper
        // has to be updatet - otherwise e.g. closing the popup on back-button-click of browser won't work
        this.popupHelper.updatePopupInstance(this.addConnectionPopup);
        this.addConnectionPopup.open();
    }

    /**
     * Handles a new connection.
     * 
     * @private
     * @param {ViewConfig} newConfig New configuration to handle.
     * @param {string} senderGuid The GUID of the component that triggered the publish.
     * @memberof PubSubUiHelper
     */
    private handleNewConnection(newConfig: ViewConfig, senderGuid?: string): void {
        if (!Utils.Instance.isDefined(this.currentViewConfig) || !Utils.Instance.isDefined(this.currentDevice)) {
            this.logger.error(this.className, 'handleNewConnection', 'No ViewConfig or Device set', [this.currentViewConfig, this.currentDevice]);
            return;
        }
        this.updateViewConfig(newConfig, this.currentDevice, senderGuid);
        this.addConnectionPopup.close();
    }

    /**
     * Check for missing connections and open information popup if any exist.
     * 
     * @private
     * @memberof PubSubUiHelper
     */
    private checkForInvalidComponents(): void {
        const missingConnections = new Map<string, string[]>();
        this.configLoader.getAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
            const pubSubConnectionHelper = new PubSubConnectionHelper(componentConfigs);
            this.currentViewConfig?.components.forEach((componentInstanceConfig) => {
                const existingInConnectionsOfComponent = this.currentViewConfig?.pubSub.filter((connection) => {
                    return connection.in[0].componentGuid === componentInstanceConfig.guid;
                }).map((connection) => connection.in[0]);
                try {
                    if (!existingInConnectionsOfComponent) {
                        this.logger.error(this.className, 'handleNewConnection', 'No ViewConfig or Device set', [this.currentViewConfig, this.currentDevice]);
                        return;
                    }
                    const missingConnectionsForComponent = pubSubConnectionHelper.getMissingInConnectionsForComponent(existingInConnectionsOfComponent, componentInstanceConfig);
                    if (missingConnectionsForComponent.length) {
                        missingConnections.set(this.languageProvider.getTranslationFromProperty(componentInstanceConfig.name), missingConnectionsForComponent.map((connection) => {
                            // Get translation for connection name
                            const languageProviderForComponent = this.languageManager.getLanguageProvider(componentInstanceConfig.component);

                            if (!languageProviderForComponent) {
                                this.logger.warn(this.className, 'getNameForComponent', 'No LanguageProvider for component found', componentInstanceConfig);
                                return connection.name;
                            }

                            let connectionName = languageProviderForComponent.get(`__windream.pubSub.${connection.name}.name`);

                            if (connectionName.includes('__windream.pubSub.name')) {
                                this.logger.warn(this.className, 'getNameForComponent', 'No translation found for connection name', connection);
                                connectionName = connection.name;
                            }
                            return connectionName;
                        }));
                    }
                } catch (error) {
                    this.logger.warn(this.className, 'checkForInvalidComponents', 'Encountered an error for missing pubsub connections for: ' + componentInstanceConfig.component, error);
                }
            });
            if (missingConnections.size > 0) {
                let listString = '<ul class="no-bullet">';
                missingConnections.forEach((connections, componentName) => {
                    listString += '<li>';
                    listString += `<strong>${componentName}</strong>`;
                    listString += '<ul class="no-bullet">';
                    connections.forEach((connectionName) => {
                        listString += `<li>${connectionName}</li>`;
                    });
                    listString += '</ul>';
                    listString += '</li>';
                });
                listString += '</ul>';
                const popup = this.popupHelper.openPopup({
                    body: `
                        <p>${this.languageProvider.get('framework.config.pubSub.missingConnections.subTitle')}</p>
                        ${listString}
                        <p>${this.languageProvider.get('framework.config.pubSub.missingConnections.footer')}</p>
                    `,
                    buttons: [{
                        callback: () => popup.close(),
                        label: 'OK'
                    }],
                    destroyOnClose: true,
                    title: this.languageProvider.get('framework.config.pubSub.missingConnections.title'),
                    type: POPUP_TYPES.WARNING
                });
            }
        }).catch((err) => {
            this.logger.warn(this.className, 'checkForInvalidComponents', 'Error: ', err);
        });
    }


    /**
     * Handles a change in the configuration by emitting the `onConfigChange` callback
     * with the information of whether the configuration has changed.
     *
     * @private
     * @memberof PubSubUiHelper
     */
    private handleConfigChange(): void {
        if (this.onConfigChange) {
            const isDirty = this.isPubSubConfigDirty();
            this.onConfigChange(isDirty);
        }
    }


    /**
     * Checks wether the currently used configurations (ViewConfig and PubSubEditorConfig)
     * have been changed by comparing them to their corresponding backups.
     *
     * @private
     * @returns {boolean} Whether the configurations have changed.
     * @memberof PubSubUiHelper
     */
    private isPubSubConfigDirty(): boolean {
        // Basic checks to see if everything is there
        if (!this.viewConfigBackup || !this.currentViewConfig) {
            return true;
        }
        if (!this.pubSubEditorConfigBackup || !this.currentPubSubEditorConfig) {
            return true;
        }
        // Only compare pubSub and triggers properties as the other ones are not relevant for PubSub config
        // Check length first for better performance
        // Use stringify = true for better performance
        if (
            this.currentViewConfig.pubSub.length !== this.viewConfigBackup.pubSub.length
            || this.currentViewConfig.triggers.length !== this.viewConfigBackup.triggers.length
            || this.currentPubSubEditorConfig.components.length !== this.pubSubEditorConfigBackup.components.length
        ) {
            // If length differs there has to be a difference
            return true;
        }
        if (
            !Utils.Instance.isDeepEqual(this.currentViewConfig.pubSub, this.viewConfigBackup.pubSub, true)
            || !Utils.Instance.isDeepEqual(this.currentViewConfig.triggers, this.viewConfigBackup.triggers, true)
        ) {
            // PubSub or triggers are not equal
            return true;
        }
        if (!Utils.Instance.isDeepEqual(this.currentPubSubEditorConfig, this.pubSubEditorConfigBackup, true)) {
            // PubSubEditor config is not equal
            return true;
        }
        // If nothing differs, everything is equal
        return false;
    }
}