import { IAuthenticationManager } from './interfaces/iAuthenticationManager';
import { IAuthenticationProvider } from './interfaces/iAuthenticationProvider';
import { AuthenticationModes } from './models/authenticationModes';


/**
 * The abstract base class for all authentication managers.
 * 
 * @export
 * @class AuthenticationManager
 */
export class AuthenticationManager implements IAuthenticationManager {

    private authenticationProviders: Map<AuthenticationModes, IAuthenticationProvider>;
    private defaultAuthenticationMode: AuthenticationModes;
    private currentAuthenticationMode: AuthenticationModes;


    /**
     * Creates an instance of AuthenticationManager.
     *
     * @param {AuthenticationModes} defaultAuthenticationMode 
     * @memberof AuthenticationManager
     */
    public constructor(defaultAuthenticationMode: AuthenticationModes) {
        this.authenticationProviders = new Map<AuthenticationModes, IAuthenticationProvider>();

        this.defaultAuthenticationMode = defaultAuthenticationMode;

        this.currentAuthenticationMode = AuthenticationModes.Anonymous;
    }

    /**
     * Gets the default authentication mode.
     * 
     * @returns {AuthenticationModes} 
     * @memberof AuthenticationManager
     */
    public getDefaultAuthenticationMode(): AuthenticationModes {
        return this.defaultAuthenticationMode;
    }

    /**
     * Adds the authentication provider.
     * 
     * @param {IAuthenticationProvider} authenticationProvider 
     * @memberof RequestExecutor
     */
    public addAuthenticationProvider(authenticationProvider: IAuthenticationProvider): void {
        if (!authenticationProvider) {
            return;
        }

        const supportedAuthenticationMode = authenticationProvider.getSupportedAuthenticationMode();
        if (supportedAuthenticationMode && !this.authenticationProviders.has(supportedAuthenticationMode)) {
            this.authenticationProviders.set(supportedAuthenticationMode, authenticationProvider);
        }
    }


    /**
     * Gets the current authentication provider.
     * 
     * @returns {(IAuthenticationProvider | null)} 
     * @memberof RequestExecutor
     */
    public getCurrentAuthenticationProvider(): IAuthenticationProvider | null {
        const currentAuthenticationMode = this.getCurrentAuthenticationMode();
        if (currentAuthenticationMode && this.authenticationProviders.has(currentAuthenticationMode)) {
            const foundAuthenticationProvider = this.authenticationProviders.get(currentAuthenticationMode);
            if (foundAuthenticationProvider) {
                return foundAuthenticationProvider;
            }
        }

        return null;
    }


    /**
     * Resets the manager's authentication mode back to the default configuration.
     * 
     * @memberof AuthenticationManager
     */
    public resetAuthenticationModeToDefault(): void {
        this.currentAuthenticationMode = this.defaultAuthenticationMode;
    }


    /**
     * Sets the current authentication mode, which should be used.
     * 
     * @param {AuthenticationModes} authenticationMode 
     * @memberof AuthenticationManager
     */
    public setCurrentAuthenticationMode(authenticationMode: AuthenticationModes): void {
        this.currentAuthenticationMode = authenticationMode;
    }


    /**
     * Gets the current selected authentication mode.
     * 
     * @returns {AuthenticationModes} 
     * @memberof AuthenticationManager
     */
    public getCurrentAuthenticationMode(): AuthenticationModes {
        return this.currentAuthenticationMode;
    }

    /**
     * Returns the authentication provider to the given mode.
     * Returns null if no mathcing provider is set.
     *
     * @param {AuthenticationModes} authenticationMode Mode to get authentication provider for.
     * @returns {(IAuthenticationProvider | null)} Authentication provider for the given mode, null if invalid mode was given.
     * @memberof AuthenticationManager
     */
    public getAuthenticationProviderForMode(authenticationMode: AuthenticationModes): IAuthenticationProvider | null {
        const provider = this.authenticationProviders.get(authenticationMode);
        if (provider) {
            return provider;
        }
        return null;
    }

}