import { AttributeDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { DirectoryResponseContainerDTO, ExistingDirectoryDTO, UpdateDirectoryDTO } from '../../../typings/windreamWebService/Windream.WebService.Directories';
import { IServiceResponse } from '../../ajaxHandler/index';
import { DirectoryDetails } from '../../common/webService/directoryDetails';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders/httpResourcePointer';
import { IRequestExecutor } from '../../dataProviders/index';
import { Logger } from '../../logging';
import { IdentityMapper } from '../../services';
import { UpdateRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action for directory updates.
 *
 * @export
 * @class UpdateDirectory
 * @extends {ServiceAction}
 */
export class UpdateDirectory extends ServiceAction {

    /**
     * Creates an instance of UpdateDirectory.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof UpdateDirectory
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'updateDirectory';
    }

    /**
     * Updates a directory with new arttributes, name etc.
     *
     * @param {UpdateRequestOptions} updateRequestOptions The request options.
     * @returns {Promise<DirectoryDetails>} A promise, which will resolve if no error occured.
     * @memberof UpdateDirectory
     */
    public async do(updateRequestOptions: UpdateRequestOptions): Promise<DirectoryDetails> {
        return new Promise<DirectoryDetails>((resolve, reject) => {
            const existingDirectoryInformation = IdentityMapper.getWebServiceIdentity(updateRequestOptions.identity) as ExistingDirectoryDTO;
            if (updateRequestOptions.newLocation) {
                existingDirectoryInformation.Location = updateRequestOptions.newLocation;
            }
            if (updateRequestOptions.newName) {
                existingDirectoryInformation.Name = updateRequestOptions.newName;
            }
            if (updateRequestOptions.attributes) {
                const convertedAttributes = new Array<AttributeDTO>();
                updateRequestOptions.attributes.forEach((item) => {
                    convertedAttributes.push({
                        Name: item.name,
                        Value: item.value
                    });
                });
                existingDirectoryInformation.Attributes = convertedAttributes;
            }
            if (updateRequestOptions.objectType) {
                existingDirectoryInformation.ObjectType = {
                    Id: updateRequestOptions.objectType.id,
                    Name: updateRequestOptions.objectType.name
                };
            }
            const updateDirectoryInformation = {} as UpdateDirectoryDTO;
            updateDirectoryInformation.Item = existingDirectoryInformation;
            if (typeof updateRequestOptions.responseDetailsType === 'number') {
                updateDirectoryInformation.ResponseDetails = updateRequestOptions.responseDetailsType as number;
            }
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/directories/Update',
                updateDirectoryInformation), updateRequestOptions.requestOptions).then((response: IServiceResponse<DirectoryResponseContainerDTO>) => {
                    if (response.data?.HasErrors || !response.data?.Item) {
                        this.logger.error('UpdateDirectory', 'do', 'Updating directory failed', response);
                        reject(new Error('Updating directory failed'));
                    } else {
                        const result = DirectoryDetails.fromDto(response.data.Item);
                        resolve(result);
                    }
                }).catch((err: Error) => {
                    this.logger.error('UpdateDirectory', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}