import { HttpResponse } from '../../ajaxHandler/httpResponse';
import { TokenProvider } from '../../authentication';
import { Base64, WindreamEntity, WindreamIdentity } from '../../common';
import { GlobalConfig } from '../../config';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { IFileSaver } from '../documents';
import { CanPerformAction } from '../permissions';
import { ServiceAction } from '../serviceAction';
import { NativeDownloadTrigger } from './nativeDownloadTrigger';


/**
 * The Download base class.
 * 
 * @export
 * @class DownloadBase
 * @extends {ServiceAction}
 */
export abstract class DownloadBase extends ServiceAction {
    /**
     * Service to check whether the given action can be performed
     *
     * @protected
     * @type {CanPerformAction}
     * @memberof DownloadBase
     */
    protected canPerformActionService: CanPerformAction;

    /**
     * Authentication provider.
     *
     * @protected
     * @type {TokenProvider}
     * @memberof DownloadBase
     */
    protected tokenProvider?: TokenProvider;

    /**
     * Class name of this class.
     *
     * @protected
     * @type {string}
     * @memberof DownloadBase
     */
    protected className: string;

    /**
     * Native download trigger instance to use for native downloads.
     *
     * @protected
     * @type {NativeDownloadTrigger}
     * @memberof DownloadBase
     */
    protected nativeDownloadTrigger: NativeDownloadTrigger;

    /**
     * File saver instance to use for native downloads.
     *
     * @protected
     * @type {IFileSaver}
     * @memberof DownloadBase
     */
    protected fileSaver: IFileSaver;

    /**
     * Creates an instance of DownloadBase.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {CanPerformAction} canPerformActionService
     * @param {IFileSaver} fileSaver
     * @param {NativeDownloadTrigger} nativeDownloadTrigger
     * @param {TokenProvider} [tokenProvider]
     * @memberof DownloadBase
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, canPerformActionService: CanPerformAction,
        fileSaver: IFileSaver, nativeDownloadTrigger: NativeDownloadTrigger, tokenProvider?: TokenProvider) {
        super(requestExecutor, globalConfig, logger);

        this.nativeDownloadTrigger = nativeDownloadTrigger;
        this.canPerformActionService = canPerformActionService;
        this.tokenProvider = tokenProvider;
        this.fileSaver = fileSaver;
        this.name = 'download';
        this.className = 'DownloadBase';
    }

    /**
     * Returns the file name for the downloaded element.
     *
     * @protected
     * @param {HttpResponse} response Server response.
     * @param {WindreamIdentity} identity Identity that has been requested.
     * @returns {string} Name of the file.
     * @memberof DownloadBase
     */
    protected getFileNameFromResponse(response: HttpResponse<any>, identity: WindreamIdentity): string {
        const base64Util = new Base64();
        let fileName: string | null = null;
        const extension = identity.entity === WindreamEntity.Folder ? '.zip' : '';
        const contentDispositionHeader = response.httpHeaders.get('content-disposition');
        if (contentDispositionHeader) {
            const regexResult = /filename=\"?([^;\"]*)\"?;/.exec(contentDispositionHeader);
            if (regexResult && regexResult[1]) {
                const base64Result = /=\?utf-8\?B\?(.*)\?=/.exec(regexResult[1]);
                if (base64Result && base64Result[1]) { // Base 64 encoded (Chrome, Firefox, Edge)
                    fileName = base64Util.decode(base64Result[1]);
                } else { // IE 11
                    fileName = decodeURIComponent(regexResult[1]);
                }
            }
        }
        if (!fileName && identity && identity.name) {
            fileName = identity.name + extension;
        }

        if (!fileName) {
            fileName = 'Download' + extension;
        }

        return fileName;
    }

}