import { ModelFactory } from '../common';
import { GlobalConfig } from '../config';
import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../logging/logger';
import { IServiceAction } from './interfaces';


/**
 * Parent class of all service actions.
 * 
 * @export
 * @class ServiceAction
 * @implements {IServiceAction}
 */
export abstract class ServiceAction implements IServiceAction {

  /**
   * The request executor.
   * 
   * @type {IRequestExecutor}
   * @memberof ServiceAction
   */
  public requestExecutor: IRequestExecutor;
  /**
   * Name of the service action.
   * 
   * @type {string}
   * @memberof ServiceAction
   */
  public name: string;
  /**
   * GlobalConfig to use.
   * 
   * @protected
   * @type {GlobalConfig}
   * @memberof ServiceAction
   */
  protected globalConfig: GlobalConfig;
  /**
   * Logger instance to use.
   * 
   * @protected
   * @type {Logger}
   * @memberof ServiceAction
   */
  protected logger: Logger;

  protected modelFactory: ModelFactory;


  /**
   * Creates an instance of ServiceAction.
   *
   * @param {IRequestExecutor} requestExecutor The request executor.
   * @param {GlobalConfig} globalConfig GlobalConfig to use.
   * @param {Logger} logger Logger instance to use.
   * 
   * @memberof ServiceAction
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    this.requestExecutor = requestExecutor;
    this.globalConfig = globalConfig;
    this.logger = logger;
    this.name = 'ServiceAction';
    this.modelFactory = new ModelFactory();
  }

  /**
   * Method to execute with the service action.
   * 
   * @abstract
   * @param {...any[]} params 
   * 
   * @memberof ServiceAction
   */
  public abstract do(...params: any[]): Promise<any>;
}