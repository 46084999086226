import { DWCore } from '@windream/dw-core/dwCore';
import { Connection } from '.';

/**
 * This model describes a pubsub configuration.
 * 
 * @export
 * @class PubSubConfig
 */
export class PubSubConfig implements DWCore.PubSub.PubSubConfig {

    /**
     * The in connections.
     * 
     * @type {InConfig[]}
     * @memberof PubSubConfig
     */
    public in: Connection[];

    /**
     * The out connections.
     * 
     * @type {OutConfig[]}
     * @memberof PubSubConfig
     */
    public out: Connection[];

    /**
     * Whether the connection should be executed when data is empty.
     *
     * @type {boolean}
     * @memberof PubSubConfig
     */
    public executeForEmptyData?: boolean;

    /**
     * Creates an instance of PubSubConfig.
     *
     * @memberof PubSubConfig
     */
    public constructor() {
        this.in = new Array<Connection>();
        this.out = new Array<Connection>();
    }
}