import { AuthenticationModes, AuthenticationProvider } from '../index';


/**
 * Provides anonymous authentication by not setting headers.
 *
 * @export
 * @class AnonymousAuthenticationProvider
 * @extends {AuthenticationProvider}
 */
export class AnonymousAuthenticationProvider extends AuthenticationProvider {

    /**
     * Gets the supported authentication mode.
     *
     * @returns {AuthenticationModes}
     * @memberof AnonymousAuthenticationProvider
     */
    public getSupportedAuthenticationMode(): AuthenticationModes {
        return AuthenticationModes.Anonymous;
    }

    /**
     * Will execute before the request was sent.
     *
     * @param {XMLHttpRequest} rawRequest
     * @returns {Promise<XMLHttpRequest>}
     * @memberof AnonymousAuthenticationProvider
     */
    public async beforeRequestHandler(rawRequest: XMLHttpRequest): Promise<XMLHttpRequest> {
        return super.beforeRequestHandler(rawRequest);
    }

}