import { DWCore } from '@windream/dw-core/dwCore';

/**
 * The position data model for a component.
 * 
 * @export
 * @class Position
 */
export class Position implements DWCore.Components.Position {

    /**
     * The height of the component.
     * 
     * @type {number}
     * @memberof Position
     */
    public height?: number;

    /**
     * The width of the component.
     * 
     * @type {number}
     * @memberof Position
     */
    public width?: number;

    /**
     * The x coordinate of the component.
     * 
     * @type {number}
     * @memberof Position
     */
    public x?: number;

    /**
     * The y coordinate of the component.
     * 
     * @type {number}
     * @memberof Position
     */
    public y?: number;
}