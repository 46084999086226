import { CanPerformActionResponseContainerDTO, CanPerformActionResultDTO } from '../../../typings/windreamWebService/Windream.WebService.Permissions';
import { IServiceResponse } from '../../ajaxHandler/index';
import { Utils } from '../../common';
import { WindreamIdentity } from '../../common/windreamIdentity';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { PermissionResponse } from '../../shared/permissions';
import { IdentityMapper } from '../index';
import { PerformActionRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The CanPerformAction action is used to check if the current user is allowed to perform specific actions in windream.
 * 
 * @export
 * @class CanPerformAction
 * @extends {ServiceAction}
 */
export class CanPerformAction extends ServiceAction {

    /**
     * Creates an instance of CanPerformAction.
     *
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * 
     * @memberof CanPerformAction
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'canPerformAction';
    }

    /**
     * Checks if the user can perform the given action.
     * Resolves with the parsed server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {PerformActionRequestOptions} performActionRequestOptions The request options.
     * @returns {Promise<PermissionResponse>} A promise, which will resolve with the parsed server response.
     * @memberof CanPerformAction
     */
    public async do(performActionRequestOptions: PerformActionRequestOptions): Promise<PermissionResponse> {
        return new Promise<PermissionResponse>((resolve, reject) => {
            if (!Utils.Instance.isDefined(performActionRequestOptions.identity.entity)) {
                this.logger.error('CanPerformAction', 'do', 'Identity has no entity set', performActionRequestOptions.identity);
                return reject('Identity has no entity set');
            }
            const item = IdentityMapper.getWebServiceGetParameter(performActionRequestOptions.identity)
                + `&parameter.item.entity=${performActionRequestOptions.identity.entity.toString()}`;
            const url = `${this.globalConfig.windreamWebServiceURL}/permissions/CanPerformAction?${item}&parameter.actions=${performActionRequestOptions.actions.toString()}`;
            this.requestExecutor.executeRequest(new HttpResourcePointer('GET', url),
                performActionRequestOptions.requestOptions)
                .then((response: IServiceResponse<CanPerformActionResponseContainerDTO>) => {
                    if (response && response.data && !response.data.HasErrors) {
                        const result = this.convertResponse(response.data.Result);
                        resolve(result);
                    } else {
                        this.logger.error('CanPerformAction', 'do', 'Unable to check actions', response);
                        reject(new Error('Unable to check actions'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('CanPerformAction', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }

    /**
     * Converts server response into the internal model.
     *
     * @private
     * @param {CanPerformActionResultDTO} response Server response.
     * @returns {PermissionResponse} Response represented in the internal model.
     * @memberof CanPerformAction
     */
    private convertResponse(response: CanPerformActionResultDTO): PermissionResponse {

        const identity = new WindreamIdentity();
        identity.id = response.Item.Id;
        identity.name = response.Item.Name;
        identity.location = response.Item.Location;
        identity.entity = response.Item.Entity as number;
        const result = new PermissionResponse(identity, response.AllowedActions);


        return result;
    }
}