import { Logger } from '../logging';
import { IndexedDBWrapper } from '.';

/**
 * IndexedDBWrapperFactory provides the IndexedDBWrapper class.
 * 
 * @export
 * @class IndexedDBWrapperFactory
 */
export class IndexedDBWrapperFactory {

    /**
     * Gets a new instance of the IndexedDBWrapper.
     * 
     * @param {Logger} logger 
     * @param {string} databaseName 
     * @returns {IndexedDBWrapper} 
     * @memberof IndexedDBWrapperFactory
     */
    public create(logger: Logger, databaseName: string): IndexedDBWrapper {
        return new IndexedDBWrapper(logger, databaseName);
    }
}