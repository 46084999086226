import { AttributeDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { DocumentResponseContainerDTO, ExistingDocumentDTO, UpdateDocumentDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler/index';
import { DocumentDetails } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders/httpResourcePointer';
import { IRequestExecutor } from '../../dataProviders/index';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { UpdateDocumentRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action used to update a document
 *
 * @export
 * @class UpdateDocument
 * @extends {ServiceAction}
 */
export class UpdateDocument extends ServiceAction {

    /**
     * Creates an instance of UpdateDocument.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof UpdateDocument
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'updateDocument';
    }

    /**
     * Updates a document within windream.
     *
     * @param {UpdateDocumentRequestOptions} updateDocumentRequestOptions The request options.
     * @returns {Promise<DocumentDetails>} A promise, which will resolve with the server response.
     * @memberof UpdateDocument
     */
    public async do(updateDocumentRequestOptions: UpdateDocumentRequestOptions): Promise<DocumentDetails> {
        return new Promise<DocumentDetails>((resolve, reject) => {
            const existingDocumentInformation = IdentityMapper.getWebServiceIdentity(updateDocumentRequestOptions.identity) as ExistingDocumentDTO;
            if (updateDocumentRequestOptions.newLocation) {
                existingDocumentInformation.Location = updateDocumentRequestOptions.newLocation;
            }
            if (updateDocumentRequestOptions.newName) {
                existingDocumentInformation.Name = updateDocumentRequestOptions.newName;
            }
            if (updateDocumentRequestOptions.attributes) {
                const convertedAttributes = new Array<AttributeDTO>();
                updateDocumentRequestOptions.attributes.forEach((item) => {
                    convertedAttributes.push({
                        Name: item.name,
                        Value: item.value
                    });
                });
                existingDocumentInformation.Attributes = convertedAttributes;
            }
            if (updateDocumentRequestOptions.objectType) {
                existingDocumentInformation.ObjectType = {
                    Id: updateDocumentRequestOptions.objectType.id,
                    Name: updateDocumentRequestOptions.objectType.name
                };
            }
            const updateDocumentInformation = {} as UpdateDocumentDTO;
            updateDocumentInformation.Item = existingDocumentInformation;
            if (typeof updateDocumentRequestOptions.createNewVersion === 'boolean') {
                updateDocumentInformation.CreateNewVersion = updateDocumentRequestOptions.createNewVersion;
            }

            if (typeof updateDocumentRequestOptions.allowIntermediateSave === 'boolean') {
                updateDocumentInformation.AllowIntermediateSave = updateDocumentRequestOptions.allowIntermediateSave;
            } else {
                // Activate intermediate save by default
                updateDocumentInformation.AllowIntermediateSave = true;
            }
            if (typeof updateDocumentRequestOptions.responseDetailsType === 'number') {
                updateDocumentInformation.ResponseDetails = updateDocumentRequestOptions.responseDetailsType as number;
            }
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/documents/Update',
                updateDocumentInformation), updateDocumentRequestOptions.requestOptions).then((response: IServiceResponse<DocumentResponseContainerDTO>) => {
                    if (response.data?.HasErrors || !response.data?.Item) {
                        this.logger.error('UpdateDocument', 'do', 'Failed to update document', response);
                        reject(new Error('Failed to update document'));
                    } else {
                        resolve(DocumentDetails.fromDto(response.data.Item));
                    }
                }).catch((err: Error) => {
                    this.logger.error('UpdateDocument', 'do', 'Failed to execute request', err);
                    reject(err);
                });

        });
    }
}