import { IdentityChangedType } from '../../../../typings/events';
import { DirectoryResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Directories';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { IdentityChangedHandler } from '../../messages/events';
import { ServiceError } from '../common';
import { IdentityMapper } from '../identityMapper';
import { MoveRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Moves a directory within windream.
 *
 * @export
 * @class MoveDirectory
 * @extends {ServiceAction}
 */
export class MoveDirectory extends ServiceAction {

    private identityChangedHandler: IdentityChangedHandler;
    /**
     * Creates an instance of MoveDirectory.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {IdentityChangedHandler} identityChangedHandler The identity changed handler.
     * @memberof MoveDirectory
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger,
        identityChangedHandler: IdentityChangedHandler) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'moveDirectory';
        this.identityChangedHandler = identityChangedHandler;
    }

    /**
     * Move a directory within windream to its destination.
     *
     * @param {MoveRequestOptions} moveRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolve with the action reponse.
     * @memberof MoveDirectory
     */
    public async do(moveRequestOptions: MoveRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/directories/Move', {
                Item: IdentityMapper.getWebServiceIdentity(moveRequestOptions.source),
                Target: IdentityMapper.getWebServiceIdentity(moveRequestOptions.target),
                ResponseDetails: moveRequestOptions.responseDetailsType
            }), moveRequestOptions.requestOptions).then((response: IServiceResponse<DirectoryResponseContainerDTO>) => {
                if (response.data && response.data.HasErrors) {
                    this.logger.error('MoveDirectory', 'do', 'Couldn\'t move directory', response);
                    reject(new ServiceError('Couldn\'t move directory', response.data.Error?.ErrorCode || -1));
                } else {
                    if (response.data && response.data.Item) {
                        this.identityChangedHandler.distributeEvent({
                            type: IdentityChangedType.Move,
                            previous: moveRequestOptions.source,
                            current: this.modelFactory.createWindreamIdentityDetailsFromDto(response.data.Item)
                        });
                    }
                    resolve();
                }
            }).catch((err: Error) => {
                this.logger.error('MoveDirectory', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}