import { DWCore } from '@windream/dw-core/dwCore';
import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents update navigation settings request options.
 *
 * @export
 * @class UpdateNavigationSettingsRequestOptions
 * @extends {BaseRequestOptions}
 */
export class UpdateNavigationSettingsRequestOptions extends BaseRequestOptions {

    /**
     * The device.
     *
     * @type {DWCore.Common.Devices}
     * @memberof UpdateNavigationSettingsRequestOptions
     */
    public device: DWCore.Common.Devices;

    /**
     * The view Ids
     *
     * @type {string[]}
     * @memberof UpdateNavigationSettingsRequestOptions
     */
    public viewIds: string[];

    /**
     * Creates an instance of UpdateNavigationSettingsRequestOptions.
     * @param {string[]} viewIds
     * @param {DWCore.Common.Devices} device
     * @memberof UpdateNavigationSettingsRequestOptions
     */
    public constructor(viewIds: string[], device: DWCore.Common.Devices) {
        super();
        if (!viewIds) {
            throw new Error('Argument \'viewIds\' is not valid');
        }
        if (typeof device !== 'number') {
            throw new Error('Argument \'device\' is not valid');
        }
        this.viewIds = viewIds;
        this.device = device;
    }
}