import { ComponentInstanceConfigDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { Utils } from '../common/utils';
import { ComponentInstanceToolbarConfigFactory } from './componentInstanceToolbarConfigFactory';
import { ComponentInstanceConfig, Position } from './models';
import { StyleFactory } from './styleFactory';

/**
 * This class provides a factory, which will generate component instance configuration classes from DTO objects.
 * 
 * @export
 * @class ComponentInstanceConfigFactory
 */
export class ComponentInstanceConfigFactory {

    /**
     * Converts the given component instance configuration DTO into an internal component instance configuration model.
     * @access public
     * @param {ComponentInstanceConfigDTO} componentInstanceConfigDTO The component instance configuration DTO, which should be converted into an internal component instance configuration model.
     * @returns {ComponentInstanceConfig}
     */
    public static convertFromDTO(componentInstanceConfigDTO: ComponentInstanceConfigDTO): ComponentInstanceConfig {
        const result = new ComponentInstanceConfig(componentInstanceConfigDTO.Guid, componentInstanceConfigDTO.Component);

        result.component = componentInstanceConfigDTO.Component;

        if (componentInstanceConfigDTO.Configuration) {
            result.configuration = Utils.deepClone(componentInstanceConfigDTO.Configuration, true);
        }

        result.name = componentInstanceConfigDTO.Name;
        result.title = componentInstanceConfigDTO.Title ? componentInstanceConfigDTO.Title : {};
        result.isTitleVisible = componentInstanceConfigDTO.IsTitleVisible ? componentInstanceConfigDTO.IsTitleVisible : false;
        result.componentType = componentInstanceConfigDTO.ComponentType as number;

        result.position = new Position();
        result.position.x = componentInstanceConfigDTO.Position.X;
        result.position.y = componentInstanceConfigDTO.Position.Y;
        result.position.width = componentInstanceConfigDTO.Position.Width;
        result.position.height = componentInstanceConfigDTO.Position.Height;

        result.style = StyleFactory.convertFromDTO(componentInstanceConfigDTO.Style);
        result.version = componentInstanceConfigDTO.Version;
        result.toolbar = ComponentInstanceToolbarConfigFactory.convertFromDTO(componentInstanceConfigDTO.Toolbar);
        return result;
    }

}