import { IdentityChangedEvent, IdentityChangedEventListener } from '../../../../typings/events';

/**
 * The identity changed handler will inform event listener of changes which occured for a identity.
 *
 * @export
 * @class IdentityChangedHandler
 */
export class IdentityChangedHandler {
    private eventListeners = new Array<typeof IdentityChangedEventListener>();

    /**
     * Attaches event listeners to the identity changed event. 
     *
     * @param {typeof IdentityChangedEventListener} eventListener The event listener to attach.
     * @memberof IdentityChangedHandler
     */
    public attachEventListener(eventListener: typeof IdentityChangedEventListener): void {
        this.eventListeners.push(eventListener);
    }

    /**
     * Removes event listeners.
     *
     * @param {typeof IdentityChangedEventListener} eventListener The event listener to remove.
     * @memberof IdentityChangedHandler
     */
    public removeEventListener(eventListener: typeof IdentityChangedEventListener): void {
        const indexOfEventListener = this.eventListeners.findIndex((listener) => listener === eventListener);
        if (indexOfEventListener > -1) {
            this.eventListeners.splice(indexOfEventListener, 1);
        }
    }

    /**
     * Distribute events to all event listeners.
     *
     * @param {IdentityChangedEvent} event The event.
     * @memberof IdentityChangedHandler
     */
    public distributeEvent(event: IdentityChangedEvent): void {
        this.eventListeners.forEach((listener) => listener(event));
    }
}