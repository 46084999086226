import { DynamicWorkspaceEntity, ItemResponseContainerDTO } from 'typings/core';
import { ResponseContainerDTO } from '../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { MoveDocumentFlags, ResponseDetailsType, Utils, WindreamEntity, WindreamIdentity } from '../common';
import { WEBSERVICE_ERROR_CODES } from '../errors/webServiceErrorCodes';
import { ILanguageProvider } from '../language';
import { Changes, IChangePromoter } from '../lifecycle';
import { Logger } from '../logging';
import { IServiceManager, MoveBulkRequestOptions, MoveDocumentBulkRequestOptions } from '../services';
import { INotificationHelper } from '../ui';
import { IShareBehavior } from './interfaces';
/**
 * Behavior to move an identity into another one.
 *
 * @export
 * @class MoveShareBehavior
 * @implements {IShareBehavior}
 */
export class MoveShareBehavior implements IShareBehavior {
    private logger: Logger;
    private serviceManager: IServiceManager;
    private changePromoter: IChangePromoter;
    private notificationHelper: INotificationHelper;
    private languageProvider: ILanguageProvider;


    /**
     * Creates an instance of MoveShareBehavior.
     * @param {Logger} logger The application logger.
     * @param {IServiceManager} serviceManager The service manager.
     * @param {IChangePromoter} changePromoter The change promoter.
     * @param {INotificationHelper} notificationHelper The notification helper.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @memberof MoveShareBehavior
     */
    public constructor(logger: Logger, serviceManager: IServiceManager, changePromoter: IChangePromoter, notificationHelper: INotificationHelper, languageProvider: ILanguageProvider) {
        this.logger = logger;
        this.serviceManager = serviceManager;
        this.changePromoter = changePromoter;
        this.notificationHelper = notificationHelper;
        this.languageProvider = languageProvider;
    }

    /**
     * Perform the behavior action.
     *
     * @param {WindreamIdentity[]} identities Identities being shared.
     * @param {WindreamIdentity} target The target identity.
     * @returns {Promise<void>} Promise to resolve when the identity was moved.
     * @memberof IShareBehavior
     */
    public async do(identities: WindreamIdentity[], target: WindreamIdentity): Promise<void> {
        if (target.entity === <number>DynamicWorkspaceEntity.FavoritesContainer) {
            // Moved to the favorites part - add element to the favorites
            return new Promise<void>(async (resolve, reject) => {
                await this.serviceManager.getServices().Favorites.addFavorites({identities: identities}).then(() => {
                    this.emitChange();
                    resolve();
                }).catch((error) => {
                    this.logger.error('MoveShareBehavior', 'do', 'Cannot perform move behaviour action - failed to add to favorites', error);
                    reject(error);
                });
            });
        } else {
            const documentIdentities = identities.filter((identity: WindreamIdentity) => {
                return identity.entity === WindreamEntity.Document;
            });
            const folderIdentities = identities.filter((identity: WindreamIdentity) => {
                return identity.entity === WindreamEntity.Folder;
            });

            return new Promise<void>((resolve, reject) => {
                Promise.all([
                    this.moveDocuments(documentIdentities, target),
                    this.moveDirectories(folderIdentities, target)
                ]).then((identitiesPromise) => {
                    const movePromisesAmount = 2;
                    if (identitiesPromise.length === movePromisesAmount) {
                        this.emitChange();
                        this.handleMoveNotification(identities.length > 1, identities);
                    }

                    resolve();
                    // Due to irregular behavior in webservice, both of these scenarios exist.
                }).catch((error: IServiceResponse<ResponseContainerDTO> | IServiceResponse<ResponseContainerDTO[]>) => {
                    this.logger.error('MoveShareBehavior', 'do', 'Cannot perform move behaviour action', error);
                    this.handleMoveNotification(identities.length > 1, target, error);
                    reject(error);
                });
            });
        }

    }

    /**
     * Move documents within windream to its destination.
     *
     * @private
     * @param {WindreamIdentity[]} documentIdentities The document identities that shall be moved.
     * @param {WindreamIdentity} target The target identity of the move operation.
     * @returns {Promise<void>}
     * @memberof MoveShareBehavior
     */
    private async moveDocuments(documentIdentities: WindreamIdentity[], target: WindreamIdentity): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.serviceManager.getServices().Documents.moveDocumentBulk(new MoveDocumentBulkRequestOptions(
                documentIdentities, target, ResponseDetailsType.None, MoveDocumentFlags.Undefined))
                .then(() => {
                    resolve();
                }).catch((error) => {
                    this.logger.error('MoveShareBehavior', 'moveDocuments', 'Unable to move ' + documentIdentities.length + ' documents', error);
                    reject(error);
                });
        });
    }

    /**
     * Move directories within windream to its destination.
     *
     * @private
     * @param {WindreamIdentity[]} folderIdentities The folder identities that shall be moved.
     * @param {WindreamIdentity} target The target identity of the move operation.
     * @returns {Promise<void>}
     * @memberof MoveShareBehavior
     */
    private async moveDirectories(folderIdentities: WindreamIdentity[], target: WindreamIdentity): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.serviceManager.getServices().Directories.moveDirectoryBulk(
                new MoveBulkRequestOptions(folderIdentities, target, ResponseDetailsType.Always))
                .then((data) => {
                    resolve(data);
                }).catch((error) => {
                    this.logger.error('MoveShareBehavior', 'moveDirectories', 'Unable to move ' + folderIdentities.length + '  directories', error);
                    reject(error);
                });
        });
    }

    /**
     * Handles the notification for internal move.
     *
     * @private
     * @param {boolean} isBulk Indicates if the move operation is a bulk operation.
     * @param {WindreamIdentity} targetIdentities The identities that were moved.
     * @param {IServiceResponse<ResponseContainerDTO> | IServiceResponse<ResponseContainerDTO[]>} error The error object if an error occured during the move operation.
     * @memberof MoveShareBehavior
     */
    private handleMoveNotification(isBulk: boolean, targetIdentities: WindreamIdentity[] | any, error?: IServiceResponse<ResponseContainerDTO> | IServiceResponse<ResponseContainerDTO[]>): void {
        // TODO: Adjust webservice for consistency
        if (error && error.data) {
            // If multiple elements are moved unsuccessfully, webservice returns an array with an error object each.
            // BUT if one of these elements causes an already-existing-kind-of-error, a single non array error object is returned instead
            let errorArray = new Array<ResponseContainerDTO>();
            if (!Utils.Instance.isArray(error.data)) {
                errorArray.push(error.data);
            } else {
                errorArray = error.data;
            }
            const fileAlreadyExisting = errorArray.filter((error) => error.Error?.ErrorCode === WEBSERVICE_ERROR_CODES.DocumentAlreadyExisting).length > 0;
            const folderAlreadyExisting = errorArray.filter((error) => error.Error?.ErrorCode === WEBSERVICE_ERROR_CODES.InvalidTargetDirectoryError).length > 0;
            // Error messages that can be classified as already existing
            // Folder and file are being dragged and at least one is existing
            // This will in current state of webservice never happen, implemented for future
            if (fileAlreadyExisting && folderAlreadyExisting && isBulk) {
                this.notificationHelper.error({
                    body: this.languageProvider.get('webservice.move.atLeastOneElementAlreadyExists')
                });
            // File is being dragged and is existing
            } else if (fileAlreadyExisting && !isBulk) {
                this.notificationHelper.error({
                    body: this.languageProvider.get('webservice.move.fileAlreadyExists')
                });
            // Files are being dragged and at least one is existing
            } else if (fileAlreadyExisting && isBulk) {
                this.notificationHelper.error({
                    body: this.languageProvider.get('webservice.move.atLeastOneFileAlreadyExists')
                });
            // Folder is being dragged and is existing
            } else if (folderAlreadyExisting && !isBulk) {
                this.notificationHelper.error({
                    body: this.languageProvider.get('webservice.move.folderAlreadyExists')
                });
            // Folder are being dragged and at least one is existing
            } else if (folderAlreadyExisting && isBulk) {
                this.notificationHelper.error({
                    body: this.languageProvider.get('webservice.move.atLeastOneFolderAlreadyExists')
                });
            // Other error messages, such as being locked etc.
            } else if (!isBulk) {
                let itemName = '';
                const itemData = <ItemResponseContainerDTO>errorArray[0];
                if (itemData) {
                    itemName = itemData.Item.Name;
                }
                this.notificationHelper.error({
                    body: this.languageProvider.getWithFormat('webservice.move.error', itemName)
                });
            } else if (isBulk) {
                this.notificationHelper.error({
                    body: this.languageProvider.get('webservice.move.errorBulk')
                });
            }
        // No error object after success
        } else if (!isBulk) {
            this.notificationHelper.success({
                body: this.languageProvider.getWithFormat('webservice.move.success', targetIdentities[0].name)
            });
        } else if (isBulk) {
            this.notificationHelper.success({
                body: this.languageProvider.getWithFormat('webservice.move.successBulk', targetIdentities.length.toString())
            });
        }
    }

    /**
     * Emits the changes to the ViewManager.
     *
     * @private
     * @memberof MoveShareBehavior
     */
    private emitChange(): void {
        const changes = new Changes();
        this.changePromoter.promoteChanges(changes);
    }
}