/**
 * This class provides common response types as constants.
 * 
 * @export
 * @enum {number}
 */
export class BinaryHttpResponseTypes {

    /**
     * UNDEFINED
     * 
     * @static
     * @memberof BinaryHttpResponseTypes
     */
    public static readonly UNDEFINED = '';

    /**
     * ARRAYBUFFER
     * 
     * @static
     * @memberof BinaryHttpResponseTypes
     */
    public static readonly ARRAYBUFFER = 'arraybuffer';

    /**
     * BLOB
     * 
     * @static
     * @memberof BinaryHttpResponseTypes
     */
    public static readonly BLOB = 'blob';

    /**
     * DOCUMENT
     * 
     * @static
     * @memberof BinaryHttpResponseTypes
     */
    public static readonly DOCUMENT = 'document';

    /**
     * JSON
     * 
     * @static
     * @memberof BinaryHttpResponseTypes
     */
    public static readonly JSON = 'json';

    /**
     * TEXT
     * 
     * @static
     * @memberof BinaryHttpResponseTypes
     */
    public static readonly TEXT = 'text';

}