import { ILanguageManager } from '../language/interfaces/iLanguageManager';
import { IComponentLoadMetadata } from './interfaces/iComponentLoadMetadata';

/**
 * This class represents a component operation parameter.
 *
 * @export
 * @class ComponentOperationParameter
 */
export class ComponentOperationParameter {

    /**
     * The component data.
     *
     * @type {IComponentLoadMetadata}
     * @memberof ComponentOperationParameter
     */
    public componentData: IComponentLoadMetadata;

    /**
     * The PubSub event name.
     *
     * @type {string}
     * @memberof ComponentOperationParameter
     */
    public languageManager: ILanguageManager;


    /**
     * Creates an instance of ComponentOperationParameter.
     *
     * @param {IComponentLoadMetadata} componentData The component data.
     * @param {ILanguageManager} languageManager The language manager.
     * @memberof ComponentOperationParameter
     */
    public constructor(componentData: IComponentLoadMetadata, languageManager: ILanguageManager) {
        this.componentData = componentData;
        this.languageManager = languageManager;
    }

}