import { Logger } from '../../../typings';
import { ISharedSettings } from '../config';
import { GetGlobalSharedSettings } from '../services';

/**
 * Loads the shared settings.
 *
 * @export
 * @class SharedSettingsLoader
 */
export class SharedSettingsLoader {
    private globalService: GetGlobalSharedSettings;
    private logger: Logger;

    /**
     * Creates an instance of SharedSettingsLoader.
     *
     * @param {GetGlobalSharedSettings} globalService Service to use to load global shared settings.
     * @param {Logger} logger Logger to use.
     * @memberof SharedSettingsLoader
     */
    public constructor(globalService: GetGlobalSharedSettings, logger: Logger) {
        this.globalService = globalService;
        this.logger = logger;
    }

    /**
     * Loads the global shared settings.
     *
     * @returns {Promise<ISharedSettings>} Promise to resolve with the settings.
     * @memberof SharedSettingsLoader
     */
    public loadGloabalSharedSettings(): Promise<ISharedSettings> {

        if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core) {
            return Promise.resolve({});
        } else {
            return this.globalService.do().catch((err: Error) => {
                this.logger.error('SharedSettingsLoader', 'loadGlobalSharedSettings', 'Failed to get global shared settings', err);
                return Promise.reject(err);
            });
        }
    }
}