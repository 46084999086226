import { Utils, WindreamEntity } from '../common';
import { ContextMenuData, ContextMenuEvaluationModel } from './models';

/**
 * Provide methods to determine a context menu item's validity.
 * 
 * @export
 * @class ContextMenuItemValidator
 */
export class ContextMenuItemValidator {

    /**
     * Checks if the given entry is enabled.
     *
     * @template T
     * @param {ContextMenuEvaluationModel} evaluationModel The model.
     * @param {ContextMenuData<T>} contextMenuData The context menu data.
     * @returns {boolean} Whether the entry is enabled or not.
     * @memberof ContextMenuItemValidator
     */
    public isEntryEnabled<T>(evaluationModel: ContextMenuEvaluationModel, contextMenuData: ContextMenuData<T>): boolean {
        // Check single/multi selection
        if (
            !(
                (evaluationModel.identityCount === 1 && contextMenuData.singleSelect) // If context menu item is for single select, allow it when only one identity is selected
                || (evaluationModel.identityCount > 1 && contextMenuData.multiSelect) // If context menu item is for multi select, allow it when more than one identity is selected
            )
        ) {
            return false;
        }

        if (evaluationModel.identityCount > 1) { // For multi select, do not perform the following checks
            return true;
        }

        // Check entity
        if (
            !(
                (Utils.Instance.isDefined(contextMenuData.entity)) // If entity is defined
                && (
                    contextMenuData.entity === WindreamEntity.DocumentAndMap // Allow if context menu item identity allows documents and folders...
                    || contextMenuData.entity === evaluationModel.entity // ... or if entity matches the one of the identity
                )
            )
        ) {
            return false;
        }

        // Check flags
        if (!contextMenuData.activationStatus) { // If nothing is selected, entry is available
            return true;
        }

        // Check whether the activationStatus flags and the item flags are the same.
        if (Utils.Instance.isDefined(contextMenuData.activeIfStatusSet) && contextMenuData.activationStatus === evaluationModel.documentFlags) {
            return contextMenuData.activeIfStatusSet;
        }

        const evaluationDocumentFlags = evaluationModel.documentFlags || 0;
        const matchingFlags = contextMenuData.activationStatus & evaluationDocumentFlags;

        if (Utils.isDefined(contextMenuData.activeIfStatusSet)) {
            if (contextMenuData.considerAllFlags) {
                // All flags must match
                return matchingFlags === contextMenuData.activationStatus ? contextMenuData.activeIfStatusSet : !contextMenuData.activeIfStatusSet;
            } else {
                // One matching flag is enough
                return matchingFlags > 0 ? contextMenuData.activeIfStatusSet : !contextMenuData.activeIfStatusSet;
            }
        }

        return true; // Default enabled
    }
}