import { ViewConfigMetaData } from '.';

/**
 * This class is a data holder for the application config.
 * 
 * @export
 * @class ApplicationConfig
 */
export class ApplicationConfig {

    /**
     * The APPLICATION_GUID.
     * 
     * @static
     * @type {string}
     * @memberof ApplicationConfig
     */
    public static APPLICATION_GUID: string = '55555555-5555-5555-5555-555555555555';

    /**
     * The active views.
     * 
     * @type {ViewConfigMetaData[]}
     * @memberof ApplicationConfig
     */
    public activeViews: ViewConfigMetaData[] = new Array<ViewConfigMetaData>();
}