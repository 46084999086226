import { DWCore } from '@windream/dw-core/dwCore';

/**
 * Represents a toolbar action.
 *
 * @export
 * @class ComponentInstanceToolbarActionConfig
 */
export class ComponentInstanceToolbarActionConfig implements DWCore.Components.ComponentInstanceToolbarActionConfig {

    /**
     * The id of the action.
     *
     * @type {string}
     * @memberof ComponentInstanceToolbarActionConfig
     */
    public id: string;

    /**
     * The display mode of this action.
     *
     * @type {DWCore.Components.ToolbarActionDisplayMode}
     * @memberof ComponentInstanceToolbarActionConfig
     */
    public displayMode?: DWCore.Components.ToolbarActionDisplayMode;

    /**
     * Creates an instance of ComponentInstanceToolbarActionConfig.
     * 
     * @param {string} id The id of the action.
     * @memberof ComponentInstanceToolbarActionConfig
     */
    public constructor(id: string) {
        this.id = id;
    }
}