import { WindreamIdentity } from '../../common';
import { DocumentRightsModel } from '../../rights/models/documentRightsModel';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents update document request options.
 *
 * @export
 * @class UpdateDocumentRightsRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class UpdateDocumentRightsRequestOptions extends WindreamRequestOptions {

    /**
     * The rights model to set for the identity.
     *
     * @type {DocumentRightsModel}
     * @memberof UpdateDocumentRightsRequestOptions
     */
    public rightsModel: DocumentRightsModel;

    /**
     * Creates an instance of UpdateDocumentRightsRequestOptions.
     * @param {WindreamIdentity} identity
     * @param {DocumentRightsModel} rightsModel
     * @memberof UpdateDocumentRightsRequestOptions
     */
    public constructor(identity: WindreamIdentity, rightsModel: DocumentRightsModel) {
        super(identity);
        if (!rightsModel) {
            throw new Error('Argument \'rightsModel\' is not valid');
        }
        this.rightsModel = rightsModel;
    }
}