/**
 * Format options for numbers.
 *
 * @export
 * @class NumberFormatOptions
 */
export class NumberFormatOptions {


    /**
     * The ISO 4217 currency code.
     *
     * @type {string}
     * @memberof NumberFormatOptions
     */
    public currencyCode?: string;


    /**
     * Defines how many decimal places should be used. 
     * If 'undefined', then try to determine automatically the number of significant digits.
     *
     * @type {number}
     * @memberof NumberFormatOptions
     */
    public decimals?: number;


    /**
     * Defines whether a thousands seperator should be used.
     *
     * @type {boolean}
     * @memberof NumberFormatOptions
     */
    public withThousandsSeperator: boolean;


    /**
     * Creates an instance of NumberFormatOptions.
     *
     * @memberof NumberFormatOptions
     */
    public constructor() {
        this.withThousandsSeperator = false;
    }

}