/**
 * The class represents the http cache-control header.
 * 
 * @export
 * @class HttpCacheControlHeader
 */
export class HttpCacheControlHeader {

    /**
     * The private value.
     * 
     * @type {boolean}
     * @memberof HttpCacheControlHeader
     */
    public isPrivate: boolean | undefined;

    /**
     * The public value.
     * 
     * @type {boolean}
     * @memberof HttpCacheControlHeader
     */
    public isPublic: boolean | undefined;

    /**
     * The max-age value.
     * 
     * @type {number}
     * @memberof HttpCacheControlHeader
     */
    public maxAge: number | undefined;

    /**
     * The no-cache value.
     * 
     * @type {boolean}
     * @memberof HttpCacheControlHeader
     */
    public noCache: boolean | undefined;

    /**
     * The no-store value.
     * 
     * @type {boolean}
     * @memberof HttpCacheControlHeader
     */
    public noStore: boolean | undefined;

}