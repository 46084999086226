import { JL } from 'jsnlog';
import { Utils } from '../../common';
import { LogLevel } from '../enums/logLevel';
import { ILoggerInterface } from '../interfaces';


/**
 * Exports a Logger, which is using the http://jsnlog.com logger.
 *
 * @export
 * @class JSNLogger
 * @implements {LoggerInterface}
 */
export class JSNLogger implements ILoggerInterface {

    /**
     * The webservice URL.
     *
     * @private
     * @type {string}
     * @memberof JSNLogger
     */
    private webserviceURL?: string;
    /**
     * The current logger.
     *
     * @private
     * @type {JL.JSNLogLogger}
     * @memberof JSNLogger
     */
    private logger?: JL.JSNLogLogger;
    /**
     * The current log level.
     *
     * @private
     * @type {LogLevel}
     * @memberof JSNLogger
     */
    private currentLogLevel: LogLevel = LogLevel.Warn;

    /**
     * The ajax appender instance.
     *
     * @private
     * @type {JL.JSNLogAjaxAppender}
     * @memberof JSNLogger
     */
    private ajaxAppender?: JL.JSNLogAjaxAppender;

    /**
     * The last set authentication token.
     *
     * @private
     * @type {string}
     * @memberof JSNLogger
     */
    private authenticationToken?: string;

    private isWindowsAuthentication: boolean = false;

    /**
     * Initializes the logger instance.
     *
     * @param {IAuthenticationManager} authenticationManager
     * @param {string} webserviceURL
     * @param {LogLevel} logLevel
     * @memberof JSNLogger
     */
    public init(logLevel: LogLevel) {
        this.currentLogLevel = logLevel;

        const numericSeverity = this.getLogLevel(logLevel);

        this.logger = JL().setOptions({
            level: numericSeverity
        });
        this.logger.setOptions({ appenders: [JL.createConsoleAppender('consoleappender')] });

    }

    /**
     * Enables the web service logging.
     *
     * @param {string} webserviceURL
     * @param {boolean} isWindowsAuthentication
     * @memberof JSNLogger
     */
    public enableWebServiceLogging(webserviceURL: string, isWindowsAuthentication: boolean): void {
        this.webserviceURL = webserviceURL;
        this.initAjaxAppender();
        this.initAjaxAppenderAuthorization();
        this.isWindowsAuthentication = isWindowsAuthentication;
    }

    /**
     * Sets the log level.
     *
     * @param {LogLevel} logLevel The log level.
     * @memberof JSNLogger
     */
    public setLogLevel(logLevel: LogLevel) {
        this.currentLogLevel = logLevel;
        const numericSeverity = this.getLogLevel(logLevel);
        this.logger?.setOptions({
            level: numericSeverity
        });
    }
    /**
     * Logs a fatal log line.
     *
     * @param {string} origin The origin of the log line.
     * @param {*} message Error object / string.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public fatal(origin: string, message: any, token?: string): void {
        this.authenticationToken = token;
        this.logger?.fatal(this.createLogObject(origin, message));
    }
    /**
     * Logs a error log line.
     *
     * @param {string} origin The origin of the log line.
     * @param {*} message Error object / string.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public error(origin: string, message: any, token?: string): void {
        this.authenticationToken = token;
        this.logger?.error(this.createLogObject(origin, message));
    }
    /**
     * Logs a warn log line.
     *
     * @param {string} origin The origin of the log line.
     * @param {*} message Error object / string.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public warn(origin: string, message: any, token?: string): void {
        this.authenticationToken = token;
        this.logger?.warn(this.createLogObject(origin, message));
    }
    /**
     * Logs a info log line.
     *
     * @param {string} origin The origin of the log line.
     * @param {*} message Error object / string.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public info(origin: string, message: any, token?: string): void {
        this.authenticationToken = token;
        this.logger?.info(this.createLogObject(origin, message));
    }
    /**
     * Logs a debug log line.
     *
     * @param {string} origin The origin of the log line.
     * @param {*} message Error object / string.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public debug(origin: string, message: any, token?: string): void {
        this.authenticationToken = token;
        this.logger?.debug(this.createLogObject(origin, message));
    }
    /**
     * Logs a trace log line.
     *
     * @param {string} origin The origin of the log line.
     * @param {*} message Error object / string.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public trace(origin: string, message: any, token?: string): void {
        this.authenticationToken = token;
        this.logger?.trace(this.createLogObject(origin, message));
    }
    /**
     * Write a log line depending on the logLevel.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} origin The origin of the log line.
     * @param {*} message Error object / string.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public log(logLevel: LogLevel, origin: string, message: any, token?: string): void {
        this.authenticationToken = token;
        this.logger?.log(this.getLogLevel(logLevel), this.createLogObject(origin, message));
    }
    /**
     * Logs a fatal exception, which went uncaught.
     *
     * @param {string} msg The message.
     * @param {string} errorMsg The error message.
     * @param {string} url The url.
     * @param {number} lineNumber The line number.
     * @param {number} column The column.
     * @param {Error} errorObject The error object.
     * @param {string} [token] Authentication token to send with the request.
     *
     * @memberof JSNLogger
     */
    public fatalException(msg: string, errorMsg: string, url: string, lineNumber: number, column: number, errorObject: Error, token?: string): void {
        this.authenticationToken = token;
        this.logger?.fatalException({
            column: column,
            errorMsg: errorMsg,
            'line number': lineNumber,
            msg: msg,
            url: url
        }, errorObject);
    }

    /**
     * Inits a new AJAX appender.
     *
     * @private
     *
     * @memberof JSNLogger
     */
    private initAjaxAppender() {
        // Ajax logging
        this.ajaxAppender = JL.createAjaxAppender('ajaxappender');
        this.logger?.setOptions({ appenders: [this.ajaxAppender] });
    }


    /**
     * Initializes the AJAX authorization appender.
     *
     * @private
     * @param {any} userDetails
     *
     * @memberof JSNLogger
     */
    private initAjaxAppenderAuthorization() {
        const addRequestHeaders = (xhr: XMLHttpRequest) => {
            if (Utils.Instance.isDefined(this.authenticationToken)) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.authenticationToken);
            }
            if (this.isWindowsAuthentication) {
                xhr.withCredentials = true;
            }
        };

        this.ajaxAppender?.setOptions?.({
            beforeSend: addRequestHeaders,
            level: this.getLogLevel(this.currentLogLevel),
            url: this.webserviceURL + '/dynamicworkspace/logging/Log'
        });
    }


    /**
     * Gets the JSNLogLogger log level
     *
     * @private
     * @param {LogLevel} logLevel The windream log level.
     * @returns {number} The JSNLogLogger log level.
     *
     * @memberof JSNLogger
     */
    private getLogLevel(logLevel: LogLevel): number {
        switch (logLevel) {
            case LogLevel.Off:
                return JL.getOffLevel();
            case LogLevel.Fatal:
                return JL.getFatalLevel();
            case LogLevel.Error:
                return JL.getErrorLevel();
            case LogLevel.Warn:
                return JL.getWarnLevel();
            case LogLevel.Info:
                return JL.getInfoLevel();
            case LogLevel.Debug:
                return JL.getDebugLevel();
            case LogLevel.Trace:
                return JL.getTraceLevel();
            default:
                return JL.getOffLevel();
        }
    }
    /**
     * Creates a log object.
     *
     * @private
     * @param {string} origin The origin of the log line.
     * @param {*} message The error object/string.
     * @returns {Object} The log object.
     *
     * @memberof JSNLogger
     */
    private createLogObject(origin: string, message: any): Object {
        return { origin: origin, message: message };
    }
}