import { ComponentInstanceConfig } from '../config';
import { DWCore, IUiComponentFactory, ViewConfig } from '../dynamicWorkspace';
import { IExtensionProvider } from '../extensions';
import { IComponentLifecycleManagerFactory } from '../lifecycle';
import { ILayoutManager, ISubViewManager, IUiManager } from '../loader';
import { Logger } from '../logging';
import { MigrationComponentContainer } from '../migration';
import { IPubSubHandler, PubSubEventNameCollection } from '../pubSub';
import { IServiceManager } from '../services';
import { ComponentToolbarHandler, ToolbarActionLoader } from '../toolbar';
import { ISkeletonHelper } from '../ui';
import { MoreDropdownHandlerFactory } from '../ui/moreDropdownHandlerFactory';
import { ComponentInitializer } from './componentinitializer';
import { ComponentLoadManifestFactory } from './componentLoadManifestFactory';
import { IComponentLoader, IComponentManager } from './interfaces';
import { ComponentContainer } from './models';


/**
 * This class is responsible for adding components by loading them and setting up their lifecycle.
 * 
 * @export
 * @class ComponentManager
 */
export class ComponentManager implements IComponentManager {
  private componentLifecycleManagerFactory: IComponentLifecycleManagerFactory;
  private componentLoader: IComponentLoader;
  private subViewManager: ISubViewManager;
  private uiManager: IUiManager;
  private extensionProvider: IExtensionProvider;
  private logger: Logger;
  private className: string = 'ComponentManager';
  private skeletonHelper: ISkeletonHelper;
  private toolbarActionLoader: ToolbarActionLoader;
  private uiComponentFactory: IUiComponentFactory;
  private serviceManager: IServiceManager;
  private componentInitializer: ComponentInitializer;
  private brokenComponentsGuid: Array<string>;
  private allAvailableComponents: Array<string>;
  private readonly errorComponentId = 'com.windream.error';
  private readonly componentLoadManifestFactory: ComponentLoadManifestFactory;


  /**
   * Creates an instance of ComponentManager.
   * 
   * @param {IComponentLifecycleManagerFactory} componentLifecycleManagerFactory The componentLifecycleManagerFactory.
   * @param {IComponentLoader} componentLoader The component loader.
   * @param {ISubViewManager} subViewManager The subview manager.
   * @param {IExtensionProvider} extensionProvider The extension provider.
   * @param {IUiManager} uiManager The ui manager.
   * @param {Logger} logger The logger.
   * @param {ISkeletonHelper} skeletonHelper The skeleton helper.
   * @param {ToolbarActionLoader} toolbarActionLoader The toolbar action loader.
   * @param {IUiComponentFactory} uiComponentFactory The UI component factory.
   * @param {IServiceManager} serviceManager The service manager.
   * @param {ComponentInitializer} componentInitializer The componentInitializer.
   * @param {ComponentLoadManifestFactory} componentLoadManifestFactory The component load-manifest factory.
   * @param {Array<string>} allAvailableComponents All available components.
   * @memberof ComponentManager
   */
  public constructor(componentLifecycleManagerFactory: IComponentLifecycleManagerFactory, componentLoader: IComponentLoader, subViewManager: ISubViewManager,
    extensionProvider: IExtensionProvider, uiManager: IUiManager, logger: Logger, skeletonHelper: ISkeletonHelper, toolbarActionLoader: ToolbarActionLoader,
    uiComponentFactory: IUiComponentFactory, serviceManager: IServiceManager, componentInitializer: ComponentInitializer, componentLoadManifestFactory: ComponentLoadManifestFactory,
    allAvailableComponents: Array<string>) {

    this.componentLifecycleManagerFactory = componentLifecycleManagerFactory;
    this.componentLoader = componentLoader;
    this.subViewManager = subViewManager;
    this.extensionProvider = extensionProvider;
    this.uiManager = uiManager;
    this.logger = logger;
    this.skeletonHelper = skeletonHelper;
    this.toolbarActionLoader = toolbarActionLoader;
    this.uiComponentFactory = uiComponentFactory;
    this.serviceManager = serviceManager;
    this.componentInitializer = componentInitializer;
    this.brokenComponentsGuid = new Array<string>();
    this.componentLoadManifestFactory = componentLoadManifestFactory;
    this.allAvailableComponents = allAvailableComponents;
  }

  /**
   * Adds a Component to the current view.
   * 
   * @param {ComponentInstanceConfig} componentInstanceConfig Configuration to use for new component.
   * @param {IPubSubHandler} [pubSubHandler]  PubSub Handler to use, if not provided the view PubSubHandler is used.
   * @param {boolean} [renderComponent=true] Whether the component should be promoted to the render phase. Default is true.
   * @returns {Promise<ComponentContainer>}  Promise to resolve after component has been loaded.
   * @async
   * 
   * @memberof ComponentManager
   */
  public async addComponent(componentInstanceConfig: ComponentInstanceConfig, pubSubHandler: IPubSubHandler | null, renderComponent: boolean = true): Promise<ComponentContainer> {
    return new Promise<ComponentContainer>(async (resolve, reject) => {
      const loadManifest = await this.componentLoadManifestFactory.generateLoadManifestForId(componentInstanceConfig.component);
      this.componentLoader.loadComponentData(loadManifest).then(() => {
        this.componentInitializer.initializeComponent(loadManifest, this.subViewManager.getCurrentLayoutManagers(),
          componentInstanceConfig, this.isBrokenComponent(componentInstanceConfig.guid)).then((container) => {
            const componentContainer = this.createComponentContainer(container, componentInstanceConfig);
            if (renderComponent && pubSubHandler) {
              this.promoteComponent(componentContainer, pubSubHandler);
            } else {
              // Promote component to skeleton step.
              // TODO: Check if further promotion is necessary.
              componentContainer.componentLifecycleManager.init(componentInstanceConfig);
              componentContainer.componentLifecycleManager.skeleton(this.skeletonHelper, true);
            }
            resolve(componentContainer);
          }).catch((error) => {
            this.logger.error(this.className, 'addComponent', 'Failed to load component:' + error);
            this.brokenComponentsGuid.push(componentInstanceConfig.guid);
            reject(error);
          });
      }).catch((error) => {
        this.logger.error(this.className, 'addComponent', 'Failed to load component:' + error);
        this.brokenComponentsGuid.push(componentInstanceConfig.guid);
        reject(error);
      });
    });
  }


  /**
   * Creates an array of Component Containers.
   * Will create a new Component Lifecycle Manager for each component.
   * 
   * @param {MigrationComponentContainer[]} containers Component containers to use.
   * @param {ComponentInstanceConfig[]} componentInstanceConfigs  Component Instance Configs to use.
   * @returns {ComponentContainer[]}  List of Component Containers wrapping the given parameters.
   * 
   * @memberof ComponentManager
   */
  public createComponentContainers(containers: MigrationComponentContainer[], componentInstanceConfigs: ComponentInstanceConfig[]): ComponentContainer[] {
    const componentContainers = new Array<ComponentContainer>();
    componentInstanceConfigs.forEach((componentInstanceConfig) => {
      const componentContainer = containers.find((container) => {
        return container.component.guid === componentInstanceConfig.guid;
      });
      if (componentContainer) {
        componentContainers.push(this.createComponentContainer(componentContainer, componentInstanceConfig));
      } else {
        this.logger.warn(this.className, 'createComponentContainers',
          `No Component Instance found for ${componentInstanceConfig.guid} (${componentInstanceConfig.name})`, componentInstanceConfig);
      }
    });
    return componentContainers;
  }

  /**
   * Create a single ComponentContainer.
   * Will create a new Component Lifecycle Manager for the component.
   * 
   * @param {MigrationComponentContainer} container    Component container to use.
   * @param {ComponentInstanceConfig} componentInstanceConfig Component Instance Config to use.
   * @returns {ComponentContainer}    Component Container wrapping the given parameters.
   * 
   * @memberof ComponentManager
   */
  public createComponentContainer(container: MigrationComponentContainer, componentInstanceConfig: ComponentInstanceConfig): ComponentContainer {
    const moreDropdownHandlerFactory = new MoreDropdownHandlerFactory(document);
    const componentToolbarHandler = new ComponentToolbarHandler(container, this.toolbarActionLoader, document, moreDropdownHandlerFactory, this.logger, this.uiComponentFactory, this.serviceManager);
    const componentLifecycleManager = this.componentLifecycleManagerFactory.create(container.component,
      componentToolbarHandler);
    const componentContainer = new ComponentContainer(container, componentInstanceConfig, componentLifecycleManager, componentToolbarHandler);
    return componentContainer;
  }

  /**
   * Retrieve whether a component is broken and failed to load.
   *
   * @param {string} guid The guid to check.
   * @returns {boolean} Whether the component is broken or not.
   * @memberof ComponentManager
   */
  public isBrokenComponent(guid: string): boolean {
    return this.brokenComponentsGuid.indexOf(guid) > -1;
  }


  /**
   * Deinitialize the component manager.
   *
   * @memberof ComponentManager
   */
  public deinitialize(): void {
    this.componentInitializer.deinitialize();
  }

  /**
   * Sets the migration pubsubs.
   *
   * @param {Map<string, PubSubEventNameCollection>} pubSubMigration The pubsubs for migration.
   * @memberof ComponentManager
   */
  public setMigrationPubSubs(pubSubMigration: Map<string, PubSubEventNameCollection>): void {
    this.componentInitializer.setMigrationPubSubs(pubSubMigration);
  }

  /**
   * Reset all component templates.
   *
   * @param {ViewConfig} [viewConfig] The view config.
   * @returns {Promise<void>} A promise, which will resolve if the reset occured.
   * @memberof ComponentManager
   */
  public resetAllComponentTemplates(viewConfig?: ViewConfig): Promise<void> {
    viewConfig?.components.forEach((component) => {
      this.componentInitializer.resetComponentTemplate(component.guid);
    });
    return Promise.resolve();
  }

  /**
   * Adds components to the view.
   *
   * @param {Map<string, string[]>} componentsPerSubView The components.
   * @param {Map<string, Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>>} layoutManagersPerSubView The layout managers.
   * @param {ComponentInstanceConfig[]} componentConfigs The component configs.
   * @returns {Promise<MigrationComponentContainer[]>} A promise, which will resolve with the component containers.
   * @memberof ComponentManager
   */
  public async addComponents(componentsPerSubView: Map<string, string[]>, layoutManagersPerSubView: Map<string, Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>>,
    componentConfigs: ComponentInstanceConfig[]): Promise<MigrationComponentContainer[]> {
    return new Promise<MigrationComponentContainer[]>((resolve, reject) => {
      if (componentConfigs.length > 0) { // If there are components to be loaded, load each one
        // Iterate over all subviews
        const componentLoadingPromises = new Array<Promise<MigrationComponentContainer | null>>();
        componentsPerSubView.forEach((componentGuids: string[], subViewId: string) => {
          const layoutManagers = layoutManagersPerSubView.get(subViewId);
          const componentConfigsForSubView = componentConfigs.filter((componentInstanceConfig) => {
            return componentInstanceConfig.guid && componentGuids.indexOf(componentInstanceConfig.guid) !== -1;
          });

          componentConfigsForSubView.forEach((componentConfig) => {
            componentLoadingPromises.push(this.createComponent(componentConfig, subViewId, layoutManagers));
          });
        });
        Promise.all(componentLoadingPromises).then((components: (MigrationComponentContainer | null)[]) => {
          const validComponents = components.filter((component) => component !== null) as MigrationComponentContainer[];
          if (validComponents.length < componentConfigs.length) {
            const missingComponentsAmount = componentConfigs.length - validComponents.length;
            const missingComponents = componentConfigs.filter((componentConfig) => !validComponents.find((validComponent) => componentConfig.guid === validComponent.component.guid));
            this.logger.error(this.className, 'loadAllComponents', 'Could not load all components: ' + missingComponentsAmount + ' components are missing.', missingComponents);
            if (validComponents.length === 0) {
              // Reject because no components could be loaded.
              reject(new Error('Not a single component was loaded'));
            } else {
              resolve(validComponents);
            }
          } else {
            resolve(validComponents);
          }
        }).catch((err) => {
          this.logger.error(this.className, 'loadAllComponents', 'Failed to load components.', err);
          reject(err);
        });
      } else { // If no components should be loaded, directly fire callback
        resolve(new Array<MigrationComponentContainer>());
      }
    });
  }

  /**
   * Create a component from a instance.
   *
   * @private
   * @param {ComponentInstanceConfig} componentConfig The instance.
   * @param {string} subViewId The subview id.
   * @param {(Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | undefined)} layoutManagers All layout managers.
   * @returns {(Promise<MigrationComponentContainer | null>)} A promise, which will resolve with a component container or null.
   * @memberof ComponentManager
   */
  private createComponent(componentConfig: ComponentInstanceConfig,
    subViewId: string, layoutManagers: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | undefined): Promise<MigrationComponentContainer | null> {
      // Check component if it's even available in the globalconfig etc.
    if (this.allAvailableComponents.indexOf(componentConfig.component) === -1) {
      this.brokenComponentsGuid.push(componentConfig.guid);
      return new Promise<MigrationComponentContainer | null>(async (resolve, reject) => {
        // Load error component if the component is not in the allAvailableComponents array from globalconfig or extension.
        this.logger.error(this.className, 'addComponents', 'Failed to load component which is in view config but not within globalconfig: ' + componentConfig.component, componentConfig);
        const previousComponent = componentConfig.component;
        componentConfig.component = this.errorComponentId;
        this.createComponent(componentConfig, subViewId, layoutManagers).then((container) => {
          if (container && container.componentInstanceConfig) {
            container.componentInstanceConfig.component = previousComponent;
          }
          resolve(container);
        }).catch((errorComponentError) => {
          reject(errorComponentError);
        });
      });
    } else {
      return new Promise<MigrationComponentContainer | null>(async (resolve, reject) => {
        if (layoutManagers) {
          const loadManifest = await this.componentLoadManifestFactory.generateLoadManifestForId(componentConfig.component);
          this.componentLoader.loadComponentData(loadManifest).then(() => {
            this.componentInitializer.initializeComponent(loadManifest, layoutManagers,
              componentConfig, this.isBrokenComponent(componentConfig.guid)).then((container) => {
                this.createComponentContainer(container, componentConfig);
                resolve(container);
              }).catch((error) => {
                this.logger.error(this.className, 'addComponents', 'Failed to load component:' + error, componentConfig);
                this.brokenComponentsGuid.push(componentConfig.guid);
                const previousComponent = componentConfig.component;
                componentConfig.component = this.errorComponentId;
                this.createComponent(componentConfig, subViewId, layoutManagers).then((container) => {
                  if (container && container.componentInstanceConfig) {
                    container.componentInstanceConfig.component = previousComponent;
                  }
                  resolve(container);
                }).catch((errorComponentError) => {
                  reject(errorComponentError);
                });
              });
          }).catch((error) => {
            this.logger.error(this.className, 'addComponents', 'Failed to load component:' + error);
            this.brokenComponentsGuid.push(componentConfig.guid);
            const previousComponent = componentConfig.component;
            componentConfig.component = this.errorComponentId;
            this.createComponent(componentConfig, subViewId, layoutManagers).then((container) => {
              if (container && container.componentInstanceConfig) {
                container.componentInstanceConfig.component = previousComponent;
              }
              resolve(container);
            }).catch((errorComponentError) => {
              reject(errorComponentError);
            });
          });
        } else {
          this.logger.error(this.className, 'addComponents', 'Unable to find LayoutManager instance for subview:' + subViewId);
          this.brokenComponentsGuid.push(componentConfig.guid);
          reject(new Error('Unable to find LayoutManager instance for subview:' + subViewId));
        }
      });
    }
  }

  /**
   * Promotes the given component through all lifecycle steps.
   * 
   * @private
   * @param {ComponentContainer} componentContainer 
   * @param {IPubSubHandler} pubSubHandler 
   * 
   * @memberof ComponentManager
   */
  private promoteComponent(componentContainer: ComponentContainer, pubSubHandler: IPubSubHandler): void {
    componentContainer.componentLifecycleManager.init(componentContainer.componentInstanceConfig);
    componentContainer.componentLifecycleManager.bind(pubSubHandler);
    componentContainer.componentLifecycleManager.afterBind();
    componentContainer.componentLifecycleManager.extension(this.extensionProvider);
    componentContainer.componentLifecycleManager.render(this.uiManager);
    componentContainer.componentLifecycleManager.afterRender();
  }
}