import { Utils } from '../common/utils';
import { Logger } from '../logging/logger';
import { ITriggerData } from './interfaces/triggerData';
/**
 * The abstract TriggerHandler class, which provides the Trigger functionality
 * 
 * @export
 * @abstract
 * @class TriggerHandler
 */
export abstract class TriggerHandler {

    /**
     * The current logger.
     * 
     * @protected
     * @type {Logger}
     * @memberof TriggerHandler
     */
    protected logger: Logger;
    /**
     * The array, which contains the triggers.
     * 
     * @protected
     * @type {*}
     * @memberof TriggerHandler
     */
    protected triggers: ITriggerData[] = new Array<ITriggerData>();

    /**
     * The triggerName for the current trigger.
     * Must start with a lower case character to avoid unwanted parsing in the WebService
     * See #71991
     * 
     * @protected
     * @type {string}
     * @memberof TriggerHandler
     */
    protected triggerName: string = 'TriggerHandler';

    private isActive: boolean;
    private connectionsWithoutTrigger: ITriggerData[] = new Array<ITriggerData>();
    private allTriggers: ITriggerData[] = new Array<ITriggerData>();


    /**
     * Creates an instance of TriggerHandler.
     * @param {Logger} logger 
     * 
     * @memberof TriggerHandler
     */
    public constructor(logger: Logger) {
        this.logger = logger;
        this.isActive = false;
    }
    /**
     * Sets the current triggers
     * 
     * @param {*} triggers The triggers
     * 
     * @memberof TriggerHandler
     */
    public setTriggers(triggers: any): void {
        if (triggers) {
            if (triggers[this.triggerName] && Utils.Instance.isArray(triggers[this.triggerName])) {
                this.triggers = triggers[this.triggerName];
            }
            if (triggers['none']) {
                this.connectionsWithoutTrigger = triggers['none'];
            }
            this.allTriggers = Object.keys(triggers).reduce((prev, curr) => prev.concat(triggers[curr]), []);
        }
    }

    /**
     * Undos the actions being performed by the trigger execution.
     * Will not do anything if next transition is "none".
     *
     * @param {string} outId The Guid + EventName from the out connection
     * @param {string} inId The Guid + EventName from the in connection
     *
     * @memberof TriggerHandler
     */
    public tryReset(outId: string, inId: string): void {
        const isConnectionWithTrigger = this.connectionsWithoutTrigger.find((trigger) => trigger.inId === inId && trigger.outId === outId);
        const nextSubViewId = this.allTriggers.find((trigger) => trigger.inId === inId && trigger.outId === outId);
        if (!isConnectionWithTrigger && this.isActive && nextSubViewId) {
            this.resetTrigger(nextSubViewId.value);
            this.isActive = false;
        }
    }

    /**
     * Tries to trigger a trigger.
     * 
     * @param {string} outId The Guid + EventName from the out connection
     * @param {string} inId The Guid + EventName from the in connection
     * @returns {void}
     * 
     * @memberof TriggerHandler
     */
    public tryTrigger(outId: string, inId: string): void {
        if (!outId || !inId) {
            this.logger.info('TriggerHandler', 'tryTrigger', 'Can\'t trigger with the wrong parameters');
            return;
        }
        if (!this.triggers) {
            this.logger.info('TriggerHandler', 'tryTrigger', 'Triggers not yet loaded');
            return;
        }
        this.triggers.forEach((trigger) => {
            if (trigger.inId === inId && trigger.outId === outId) {
                this.handleTrigger(trigger.value);
                this.isActive = true;
            }
        });
    }
    /**
     * Triggers the current trigger.
     * 
     * @protected
     * @abstract
     * @param {*} value The value which was specified
     * 
     * @memberof TriggerHandler
     */
    protected abstract handleTrigger(value: any): void;

    /**
     * Resets the changes made by the trigger.
     *
     * @protected
     * @param {string} subViewId ID of the sub view that will be displayed next.
     * @memberof TriggerHandler
     */
    protected resetTrigger(_subViewId: string): void {
        // Default empty implementation. Overwrite if something needs to be done.
    };
}