import { IStorage } from './interfaces/iStorage';

/**
 * StorageManager class.
 * 
 * @export
 * @class StorageManager
 * @implements {IStorage}
 */
export class StorageManager implements IStorage {

    /**
     * The currently used wrapper.
     * 
     * @private
     * @type {IStorage}
     * @memberof StorageManager
     */
    private currentWrapper: IStorage;

    /**
     * Creates an instance of StorageManager.
     * 
     * @param {IStorage} wrapper
     * 
     * @memberof StorageManager
     */
    public constructor(wrapper: IStorage) {
        this.currentWrapper = wrapper;
    }
    /**
     * The clear() method of the Storage interface, when invoked, will empty all keys out of the storage.
     * 
     * @memberof StorageManager
     */
    public clear(): void {
        this.currentWrapper.clear();
    }
    /**
     * The getItem() method of the Storage interface, when passed a key name, will return that key's value.
     * 
     * @param {string} key
     * @returns {(string | null)}
     * 
     * @memberof StorageManager
     */
    public getItem(key: string): string | null {
        return this.currentWrapper.getItem(key);
    }
    /**
     * The key() method of the Storage interface, when passed a number n, returns the name of the nth key in the storage. The order of keys is user-agent defined, so you should not rely on it.
     * 
     * @param {number} index
     * @returns {(string | null)}
     * 
     * @memberof StorageManager
     */
    public key(index: number): string | null {
        return this.currentWrapper.key(index);
    }
    /**
     * The length read-only property of the Storage interface returns an integer representing the number of data items stored in the Storage object.
     * 
     * @type {number}
     * @memberof StorageManager
     */
    public get length(): number {
        return this.currentWrapper.length;
    }
    /**
     * The removeItem() method of the Storage interface, when passed a key name, will remove that key from the storage.
     * 
     * @param {string} key
     * 
     * @memberof StorageManager
     */
    public removeItem(key: string): void {
        this.currentWrapper.removeItem(key);
    }
    /**
     * The setItem() method of the Storage interface, when passed a key name and value, will add that key to the storage, or update that key's value if it already exists.
     * 
     * @param {string} key
     * @param {string} data
     * 
     * @memberof StorageManager
     */
    public setItem(key: string, data: string): void {
        this.currentWrapper.setItem(key, data);
    }
}