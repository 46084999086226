import * as fileSaver from 'file-saver';
import { IFileSaver } from './iFileSaver';


/**
 * This file saver implementation saves the given data as a file system object.
 * 
 * @export
 * @class FileSystemSaver
 * @implements {IFileSaver}
 */
export class FileSystemSaver implements IFileSaver {

    /**
     * This method is responsible for saving any kind of data as a file system object.
     * 
     * @param {*} data
     * @param {string} filename
     * 
     * @memberof FileSystemSaver
     */
    public saveAs(data: ArrayBuffer, filename: string) {

        const byteArray = new Uint8Array(data);
        const blob = new Blob([byteArray], { type: 'octet/stream' });

        fileSaver.saveAs(blob, filename);
    }

}