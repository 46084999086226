/**
 * Represents the response of the websocket server.
 * 
 * @export
 * @class WebsocketResponse
 */
export class WebsocketResponse {
  /**
   * Statuscode of the response.
   * 
   * @type {number}
   * @memberof WebsocketResponse
   */
  public status: number;
  /**
   * Hash of the response.
   * 
   * @type {number}
   * @memberof WebsocketResponse
   */
  public hash: number;
  /**
   * Response body.
   * 
   * @type {string}
   * @memberof WebsocketResponse
   */
  public message: string;

  /**
   * Creates an instance of WebsocketResponse.
   *
   * @param {string} message
   * @param {number} status
   * @param {number} hash
   * @memberof WebsocketResponse
   */
  public constructor(message: string, status: number, hash: number) {
    this.message = message;
    this.status = status;
    this.hash = hash;
  }
}