import { IRouter, RouteManager } from '.';

/**
 * Authentication callback router will handle the route for /auth-callback.
 *
 * @export
 * @class AuthCallbackRouter
 * @implements {IRouter}
 */
export class AuthCallbackRouter implements IRouter {

    public static readonly PRE_AUTH_URL_SESSION_STORAGE_KEY = 'DW_PRE_AUTH_URL';

    private readonly currentWindow: Window;
    private readonly authCallbackRoute = '/auth-callback';

    private onAuthCallbacks: (() => void)[];

    /**
     * Creates an instance of AuthCallbackRouter.
     * 
     * @memberof AuthCallbackRouter
     */
    public constructor(currentWindow: Window) {
        this.currentWindow = currentWindow;
        this.onAuthCallbacks = new Array<() => void>();
    }


    /**
     * Tries to update the session storage pre-auth URL with the current URL.
     *
     * @return {boolean} Whether the session storage could be updated with the current URL.
     * @memberof AuthCallbackRouter
     */
    public tryUpdateSessionStoragePreAuthUrl(): boolean {
        if (this.currentWindow && this.currentWindow.location && this.currentWindow.location.href && this.currentWindow.location.href.indexOf(this.authCallbackRoute) === -1) {
            this.currentWindow.sessionStorage.setItem(AuthCallbackRouter.PRE_AUTH_URL_SESSION_STORAGE_KEY, this.currentWindow.location.href);
            return true;
        }

        return false;
    }

    /**
     * Routes the specific route.
     *
     * @returns {boolean} Whether the navigation was a success.
     * @memberof AuthCallbackRouter
     */
    public route(): boolean {

        this.onAuthCallbacks.forEach((callback) => {
            callback();
        });

        return true;
    }

    /**
     * Gets the route pattern.
     *
     * @returns {RegExp} The route pattern.
     * @memberof RootRouter
     */
    public getRoutePattern(): RegExp {
        return new RegExp(`${this.authCallbackRoute}$`, 'i');
    }

    /**
     * Clears the session pre-auth URL.
     *
     * @private
     * @memberof AuthCallbackRouter
     */
    public clearSessionStoragePreAuthUrl(): void {
        // Get original (pre-auth) URL
        const preAuthUrl: string | null = this.getSessionStoragePreAuthUrl();
        if (preAuthUrl) {
            // Remove the auth-callback route from the url.
            RouteManager.replaceCurrentPath(preAuthUrl, true, false);
        }

        this.currentWindow.sessionStorage.removeItem(AuthCallbackRouter.PRE_AUTH_URL_SESSION_STORAGE_KEY);
    }

    /**
     * Gets the session storage pre-auth URL.
     *
     * @private
     * @return {(string | null)}
     * @memberof AuthCallbackRouter
     */
    private getSessionStoragePreAuthUrl(): string | null {
        return this.currentWindow.sessionStorage.getItem(AuthCallbackRouter.PRE_AUTH_URL_SESSION_STORAGE_KEY);
    }

    /**
     * Register a callback which will be called if a auth-callback navigation occured.
     *
     * @param {() => void} callback The callback.
     * @memberof AuthCallbackRouter
     */
    public registerOnAuthCallback(callback: () => void): void {
        this.onAuthCallbacks.push(callback);
    }
}