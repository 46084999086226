import { MigrationComponentContainer } from '../dynamicWorkspace';
import { MoreDropdownHandler } from './moreDropdownHandler';

/**
 * Factory to create MoreDropdownHandler instances.
 *
 * @export
 * @class MoreDropdownHandlerFactory
 */
export class MoreDropdownHandlerFactory {
    private currentDocument: Document;

    /**
     * Creates an instance of MoreDropdownHandlerFactory.
     * 
     * @param {Document} currentDocument The current Document instance.
     * @memberof MoreDropdownHandlerFactory
     */
    public constructor(currentDocument: Document) {
        this.currentDocument = currentDocument;
    }

    /**
     * Creates an instance of the MoreDropdownHandler.
     * @param {MigrationComponentContainer} componentContainer Toolbar parent component container.
     * @returns {MoreDropdownHandler} The instance created.
     * @memberof MoreDropdownHandlerFactory
     */
    public create(componentContainer: MigrationComponentContainer): MoreDropdownHandler {
        return new MoreDropdownHandler(componentContainer, this.currentDocument);
    }
}