import { WindreamIdentity } from '../common';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { IIdentitySharer, IIndentitySharerData, IShareBehavior } from './interfaces';


/**
 * Class to globally handle sharing of WindreamIdentities.
 * 
 * @export
 * @class GlobalIdentitySharer
 */
export class GlobalIdentitySharer implements IIdentitySharer {
    /**
     * Behavior to execute if an identity has been shared.
     * 
     * @type {IShareBehavior}
     * @memberof GlobalIdentitySharer
     */
    public onIdentityShared?: IShareBehavior;

    private dragGhost: HTMLElement | null;
    private currentlyDraggedItems: WindreamIdentity[] | null;
    private documentRef: Document;
    private languageProvider: ILanguageProvider;
    private logger: Logger;


    /**
     * Creates an instance of GlobalIdentitySharer.
     * @param {Document} documentRef 
     * @param {INotificationHelper} notificationHelper 
     * @param {ILanguageProvider} languageProvider 
     * @memberof GlobalIdentitySharer
     */
    public constructor(documentRef: Document, languageProvider: ILanguageProvider, logger: Logger) {
        this.documentRef = documentRef;
        this.languageProvider = languageProvider;
        this.logger = logger;

        this.currentlyDraggedItems = null;
        this.dragGhost = null;
    }

    /**
     * Intializes the instance.
     * Adds global dragend listener to notice when a drop was made on any non-handled target.
     * 
     * @memberof GlobalIdentitySharer
     */
    public init(): void {
        this.documentRef.addEventListener('dragend', () => {
            if (this.dragGhost) {
                this.dragGhost.remove();
                this.dragGhost = null;
            }
            this.currentlyDraggedItems = null;
        });
    }

    /**
     * Shares the given identity by using it as a reference for the next receive event.
     * 
     * @param {IIndentitySharerData} data Wrapped shared data.
     * @memberof GlobalIdentitySharer
     */
    public share(data: IIndentitySharerData): void {
        if (data && data.identities && data.identities.length > 0) {
            this.currentlyDraggedItems = data.identities;
            this.prepareEvent(data);
        }
    }

    /**
     * Triggers the receive for the currently shared identity.
     * 
     * @param {IIndentitySharerData} data Wrapped shared data.
     * @memberof GlobalIdentitySharer
     */
    public receive(data: IIndentitySharerData): void {
        if (data && data.identities && data.identities.length > 0) {
            this.executeDrop(data.identities[0]);
        }
    }

    /**
     * Executes the drop event and shares the identity using the callback.
     * 
     * @private
     * @param {WindreamIdentity} targetIdentity The drop target's identity.
     * @memberof GlobalIdentitySharer
     */
    private executeDrop(targetIdentity: WindreamIdentity): void {
        if (this.onIdentityShared && this.currentlyDraggedItems) {
            // This can be undefined, if e.g. listview is showing a search result and drop into current folder is happening.
            // In this case, do nothing.
            if (targetIdentity) {
                if (this.isValidDrop(targetIdentity) && this.currentlyDraggedItems.length > 0) {
                    this.onIdentityShared.do(this.currentlyDraggedItems, targetIdentity).then(() => {
                        if (this.currentlyDraggedItems) {
                            this.currentlyDraggedItems.length = 0;
                        }
                    }).catch((err: Error) => {
                        this.logger.error('GlobalIdentitySharer', 'executeDrop', 'Unable to execute internal drop', err);
                        if (this.currentlyDraggedItems) {
                            this.currentlyDraggedItems.length = 0;
                        }
                    });
                }
            }
        }
    }

    /**
     * Prepares the given event by setting required properties using the dataTransfer property. Also sets up drag ghosting.
     * 
     * @private
     * @param {DragEvent} event Event to prepare.
     * @memberof GlobalIdentitySharer
     */
    private prepareEvent(data: IIndentitySharerData): void {
        const minAmountIdentitiesToCompactMode = 5;
        this.dragGhost = this.documentRef.createElement('div');
        this.dragGhost.classList.add('wd-drag-ghost');
        const dataTransfer = data.event.dataTransfer;
        // Show items seperately
        if (data.identities.length < minAmountIdentitiesToCompactMode) {
            // Create table with two columns: icon + name
            const table = this.documentRef.createElement('table');
            const tableBody = this.documentRef.createElement('tbody');
            data.identities.forEach((element: WindreamIdentity) => {
                const tableRow = this.documentRef.createElement('tr');
                const tdIcon = this.documentRef.createElement('td');
                tdIcon.classList.add('wd-icon');
                tdIcon.classList.add('file');
                const tdLabel = this.documentRef.createElement('td');
                const nameElement = this.documentRef.createElement('div');
                nameElement.innerText = element.name || '';
                nameElement.classList.add('wd-drag-ghost-label');
                tdLabel.appendChild(nameElement);
                tableRow.appendChild(tdIcon);
                tableRow.appendChild(tdLabel);
                tableBody.appendChild(tableRow);
            });
            table.appendChild(tableBody);
            this.dragGhost.appendChild(table);
            this.dragGhost.classList.add('wd-drag-ghost-detailed');
            // Stack items
        } else {
            // Create a simple span instead
            const notification = this.documentRef.createElement('span');
            notification.innerText = this.languageProvider.getWithFormat('framework.share.move.notification', data.identities.length.toString());
            this.dragGhost.appendChild(notification);
            this.dragGhost.classList.add('wd-drag-ghost-compact');
        }
        this.documentRef.body.appendChild(this.dragGhost);
        if (dataTransfer) {
            dataTransfer.effectAllowed = 'move';
            dataTransfer.setData('text', data.identities.length.toString());
            // Not supported by IE11 and Safari
            // TODO: Find workarround
            if (typeof dataTransfer.setDragImage === 'function') {
                dataTransfer.setDragImage(this.dragGhost, 0, 0);
            }
        }
    }

    /**
     * Check source and target element.
     * Not allow move into self
     *
     * @private
     * @param {WindreamIdentity} targetIdentity
     * @returns {boolean}
     * @memberof GlobalIdentitySharer
     */
    private isValidDrop(targetIdentity: WindreamIdentity): boolean {
        if (this.currentlyDraggedItems) {
            this.currentlyDraggedItems.forEach((item: WindreamIdentity) => {
                if (targetIdentity.id && (item.id === targetIdentity.id) || (item.location === targetIdentity.location)) {
                    return false;
                }
            });
            return true;
        }
        return false;
    }
}