import { ConfigUiHelper } from '../ui';
import { RoutingUtils } from './routingUtils';
import { IRouter } from '.';

/**
 * Represents the root router listening only to / path.
 *
 * @export
 * @class RootRouter
 * @implements {IRouter}
 */
export class RootRouter implements IRouter {
    private onRootNavigationCallbacks: (() => void)[];

    /**
     * Creates an instance of RootRouter.
     * 
     * @memberof RootRouter
     */
    public constructor() {
        this.onRootNavigationCallbacks = new Array<() => void>();
    }

    /**
     * Routes the specific route.
     *
     * @param {string} _path The path.
     * @returns {boolean} Whether the navigation was a success.
     * @memberof RootRouter
     */
    public route(_path: string): boolean {
        this.onRootNavigationCallbacks.forEach((callback) => {
            callback();
        });
        if (ConfigUiHelper.hasUnsavedChanges()) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Gets the route pattern.
     *
     * @returns {RegExp} The route pattern.
     * @memberof RootRouter
     */
    public getRoutePattern(): RegExp {
        return new RegExp('^' + RoutingUtils.getApplicationBasePath(true) + '$|^' + RoutingUtils.getApplicationBasePath(true) + '\\?.*$');
    }

    /**
     * Register a callback which will be called if a root navigation occured.
     *
     * @param {() => void} callback The callback.
     * @memberof RootRouter
     */
    public registerOnRootNavigation(callback: () => void): void {
        this.onRootNavigationCallbacks.push(callback);
    }
}