import { Logger } from '../logging';
import { ISkeletonHelper } from '../ui';
import { IViewLifecycleManager, IViewLifecycleManagerFactory } from './interfaces';
import { ViewLifecycleManager } from './viewLifecycleManager';

/**
 * This factory provides instances of IViewLifecycleManager
 * 
 * @export
 * @class ViewLifecycleManagerFactory
 * @implements {IViewLifecycleManagerFactory}
 */
export class ViewLifecycleManagerFactory implements IViewLifecycleManagerFactory {
    private skeletonHelper: ISkeletonHelper;
    private logger: Logger;

    /**
     * Creates an instance of ViewLifecycleManagerFactory.
     * @param {ISkeletonHelper} skeletonHelper ISkeletonHelper instance to use.
     * @param {Logger} logger Logger instance to use.
     * @memberof ViewLifecycleManagerFactory
     */
    public constructor(skeletonHelper: ISkeletonHelper, logger: Logger) {
        this.skeletonHelper = skeletonHelper;
        this.logger = logger;
    }

    /**
     * Creates a new instance of IViewLifecycleManager
     * 
     * @returns {IViewLifecycleManager}
     * 
     * @memberof ViewLifecycleManagerFactory
     */
    public create(): IViewLifecycleManager {
        return new ViewLifecycleManager(this.skeletonHelper, this.logger);
    }

}