import { ResponseDetailsType, WindreamIdentity } from '../../common';
import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents basic move request options.
 *
 * @export
 * @class MoveRequestOptions
 * @extends {BaseRequestOptions}
 */
export class MoveRequestOptions extends BaseRequestOptions {

    /**
     * The source identity.
     *
     * @type {WindreamIdentity}
     * @memberof MoveRequestOptions
     */
    public source: WindreamIdentity;

    /**
     * The target identity.
     *
     * @type {WindreamIdentity}
     * @memberof MoveRequestOptions
     */
    public target: WindreamIdentity;

    /**
     * The reponse details type.
     *
     * @type {ResponseDetailsType}
     * @memberof MoveRequestOptions
     */
    public responseDetailsType: ResponseDetailsType;

    /**
     * Creates an instance of MoveRequestOptions.
     * @param {WindreamIdentity} source
     * @param {WindreamIdentity} target
     * @param {ResponseDetailsType} responseDetailstype
     * @memberof MoveRequestOptions
     */
    public constructor(source: WindreamIdentity, target: WindreamIdentity, responseDetailstype: ResponseDetailsType) {
        super();
        if (!source) {
            throw new Error('Argument \'source\' is not valid');
        }
        if (!target) {
            throw new Error('Argument \'target\' is not valid');
        }
        if (typeof responseDetailstype !== 'number') {
            throw new Error('Argument \'responseDetailsType\' is not valid');
        }
        this.source = source;
        this.target = target;
        this.responseDetailsType = responseDetailstype;
    }
}