/**
 * Provides routing utils i.e. getting the root etc.
 *
 * @export
 * @class RoutingUtils
 */
export class RoutingUtils {
  private static currentWindow: Window;

  /**
   * Init the routing utils with a specific window.
   *
   * @static
   * @param {Window} currentWindow The window.
   * @memberof RoutingUtils
   */
  public static init(currentWindow: Window): void {
    this.currentWindow = currentWindow;
  }

  /**
   * Returns the value of a certain parameter or null if the parameter does not exists.
   * 
   * @static
   * @param {string} parameterName The name of the parameter inside the url.
   * @returns {(string | null)} The value of the parameter or null.
   * @memberof RoutingUtils
   */
  public static getQueryParameter(parameterName: string): string | null {
    const parameterStartLength = RoutingUtils.currentWindow.location.search.indexOf('?');
    let query: string;
    if (parameterStartLength === -1) {
      return null;
    } else {
      query = RoutingUtils.currentWindow.location.search.substr(1);
    }
    const parameterPairs = query.split('&');
    for (const p of parameterPairs) {
      const pair = p.split('=');
      if (decodeURIComponent(pair[0]) === parameterName) {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  }

  /**
   * Returns the legacy query string from the hash instead of search.
   *
   * @static
   * @returns {(string | null)} The whole query string or null.
   * @memberof RoutingUtils
   */
  public static getLegacyQueryString(): string | null {
    const parameterStartLength = RoutingUtils.currentWindow.location.hash.indexOf('?');
    if (parameterStartLength === -1 && RoutingUtils.currentWindow.location.hash.length >= parameterStartLength + 1) {
      return null;
    } else {
      return RoutingUtils.currentWindow.location.hash && parameterStartLength > -1 ?
        RoutingUtils.currentWindow.location.hash.substring(parameterStartLength + 1) :
        null;
    }
  }

  /**
   * Returns the root of the application.
   * 
   * @static
   * @returns {string}  Root of application.
   * 
   * @memberof RoutingUtils
   */
  public static getApplicationRoot(): string {
    return RoutingUtils.currentWindow.location.origin;
  }

  /**
   * Gets the application base path without routing path.
   *
   * @static
   *  @param {boolean} [onlyPath] Whether to return only the path and discard the origin.
   * @returns {string} The base path with or without origin.
   * @memberof RoutingUtils
   */
  public static getApplicationBasePath(onlyPath?: boolean): string {
    let pathRoot: string;
    if (onlyPath) {
      pathRoot = RoutingUtils.currentWindow.location.pathname;
    } else {
      pathRoot = RoutingUtils.currentWindow.location.href;
    }
    pathRoot = pathRoot.replace(RoutingUtils.currentWindow.location.search, '');
    pathRoot = pathRoot.replace(RoutingUtils.currentWindow.location.hash, '');
    const match = (/^(.*)\/(view|edit|about|systemview|settings|auth-callback|#!.+)/i).exec(pathRoot);
    if (match && typeof match[1] === 'string') {
      pathRoot = match[1];
    }
    if (!pathRoot.endsWith('/')) {
      pathRoot = pathRoot + '/';
    }
    return pathRoot;
  }

  /**
   * Get current url.
   *
   * @static
   * @returns {string} The currentWindow.location.href.
   * @memberof RoutingUtils
   */
  public static getCurrentUrl(): string {
    return RoutingUtils.currentWindow.location.href;
  }
}