import { SystemSettingsSection, SystemSettingsSectionMetadata } from '../../../../../typings/core';
import { Popup } from '../../../../../typings/popup';
import { Button } from '../../../../../typings/ui';
import { ViewConfigMetaData, ILanguageProvider } from '../../../dynamicWorkspace';
import { RightsModel } from './models/rightsModel';
import { ViewMetadataUiPanel } from './ui/viewMetadataUiPanel';
import { ViewRightsUiPanel } from './ui/viewRightsUiPanel';
import { ViewSystemSettingsWebServiceRequests } from './viewSystemSettingsWebServiceRequests';

export class ViewSystemSettingsSection implements SystemSettingsSection {

    private className: string = 'ViewSystemSettingsSection';
    private languageProvider: ILanguageProvider;
    private header: string;
    private description: string;
    private isRendered: boolean = false;
    private viewList?: JQuery<HTMLElement>;
    private viewContainer?: JQuery<HTMLElement>;
    private availableViewConfigs: ViewConfigMetaData[];
    private currentlySelectedViewConfig?: ViewConfigMetaData;
    private currentlyOpenPopup?: Popup;
    private targetElement?: HTMLDivElement;
    private defaultAdminGroup?: RightsModel;
    private saveViewOderButton?: Button;
    private viewSystemSettingsWebServiceRequest: ViewSystemSettingsWebServiceRequests;

    /**
     * Initializes a new instance of ViewSystemSettingsSection.
     * @memberof ViewSystemSettingsSection
     */
    public constructor() {
        this.languageProvider = DynamicWorkspace.Language.getLanguageProvider('framework');
        this.header = this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.title');
        this.description = this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.description');
        this.viewSystemSettingsWebServiceRequest = new ViewSystemSettingsWebServiceRequests(DynamicWorkspace as any);
        this.availableViewConfigs = new Array<ViewConfigMetaData>();
    }

    /**
     * Gets the metadata of the view system settings section.
     *
     * @memberof ViewSystemSettingsSection
     * @returns {SystemSettingsSectionMetadata} The metadata of the view settings section.
     */
    public getMetadata(): SystemSettingsSectionMetadata {
        return {
            header: this.header,
            description: this.description,
            image: require('./img/sectionImage.png')
        };
    }
    /**
     * Renders the system settings section.
     *
     * @param {HTMLDivElement} targetElement The target element to render into.
     * @memberof ViewSystemSettingsSection
     */
    public render(targetElement: HTMLDivElement): void {
        if (!this.isRendered) {
            this.targetElement = targetElement;
            targetElement.innerHTML = '';

            // Render header elements
            DynamicWorkspace.$('<div class="wd-system-settings-caption"></div>').text(this.header).appendTo(targetElement);
            DynamicWorkspace.$('<div class="wd-system-settings-subcaption"></div>').text(this.description).appendTo(targetElement);

            const loadingIndicator = DynamicWorkspace.$('<div class="wd-system-settings-create-view-loading-indicator"></div>');
            loadingIndicator.appendTo(targetElement);
            loadingIndicator.dxLoadIndicator();
            // Load views
            Promise.all([this.viewSystemSettingsWebServiceRequest.loadAvailableViews(), this.viewSystemSettingsWebServiceRequest.loadDefaultAdminGroup()]).then((promiseData) => {
                this.availableViewConfigs = promiseData[0];
                this.defaultAdminGroup = promiseData[1];
                loadingIndicator.remove();
                // Render the view container
                this.renderViewContainer(targetElement);
                this.isRendered = true;
            }).catch(() => {
                if (this.targetElement) {
                    const errorPage = DynamicWorkspace.$('<div></div>');
                    errorPage.text(this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.failedToFetchData'));
                    errorPage.appendTo(this.targetElement);
                }
            });
        }
    }

    private refresh(): void {
        if (!this.targetElement) {
            return;
        }
        const loadingIndicator = DynamicWorkspace.$('<div class="wd-system-settings-create-view-loading-indicator"></div>');
        loadingIndicator.appendTo(this.targetElement);
        loadingIndicator.dxLoadIndicator();
        if (this.viewContainer) {
            DynamicWorkspace.$(this.viewContainer).hide();
        }
        // Load views
        Promise.all([this.viewSystemSettingsWebServiceRequest.loadAvailableViews(), this.viewSystemSettingsWebServiceRequest.loadDefaultAdminGroup()]).then((promiseData) => {
            this.availableViewConfigs = promiseData[0];
            this.defaultAdminGroup = promiseData[1];
            DynamicWorkspace.PubSub.publish('RefreshNavigation', 'RefreshNavigation', this.availableViewConfigs);
            if (this.targetElement) {
                if (this.viewList && this.viewContainer) {
                    DynamicWorkspace.$(this.viewList).dxList({
                        items: this.availableViewConfigs,
                        selectedItem: this.currentlySelectedViewConfig
                    });
                    DynamicWorkspace.$(this.viewContainer).show();
                    loadingIndicator.remove();
                }
            }
        }).catch(() => {
            if (this.targetElement) {
                const errorPage = DynamicWorkspace.$('<div></div>');
                errorPage.text(this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.failedToFetchData'));
                errorPage.appendTo(this.targetElement);
            }
        });
    }
    /**
     * Check wether the text is contained in this system settings section.
     * 
     * @param {string} text The text to search for in the system settings section.
     * @memberof SystemSettingsSection
     * @returns {boolean} True if the text is contained on this system settings section or not.
     * @memberof ViewSystemSettingsSection
     */
    public searchText(text: string): boolean {
        if (text) {
            const searchText = text.toLowerCase();
            return this.header.toLowerCase().includes(searchText) || this.description.toLowerCase().includes(searchText);
        }
        return true;
    }

    private renderPopupContent(jQuery: JQueryStatic, selectedView?: ViewConfigMetaData): HTMLElement {
        const container = jQuery('<div class="wd-create-view-popup"></div>')[0];

        let selectedTemplate: ViewConfigMetaData | undefined;
        let viewName: string | null;
        let viewDescription: string | null;
        let viewAlias: string | null;
        let showInNavigation = true;
        let availableInDevices = 7;
        const viewGroups = new Array<RightsModel>();
        const previousViewRights = new Array<RightsModel>();
        const checkCreateButtonState = () => {
            const createButton = jQuery('#wd-create-view-button').dxButton('instance');
            if (createButton) {
                const hasReadOrAdminRightsAtLeastOnce = viewGroups.find((rights) => rights.canAdministrate || rights.canRead);
                if (!selectedView) {
                    createButton.option('disabled', !hasReadOrAdminRightsAtLeastOnce || !viewName);
                } else {
                    let propertyWasChanged = false;
                    propertyWasChanged = selectedView.name[DynamicWorkspace.Language.current] !== viewName ||
                        selectedView.description[DynamicWorkspace.Language.current] !== viewDescription ||
                        selectedView.alias !== viewAlias ||
                        showInNavigation !== !selectedView.isHiddenInNavigation ||
                        availableInDevices !== selectedView.enabledDevices;
                    let rightsWereChanged = false;
                    if (viewGroups.length !== previousViewRights.length) {
                        rightsWereChanged = true;
                    } else {
                        previousViewRights.forEach((entry, index) => {
                            if (viewGroups[index]) {
                                if (!(entry.id === viewGroups[index].id && entry.canAdministrate === viewGroups[index].canAdministrate
                                    && entry.canRead === viewGroups[index].canRead)) {
                                    rightsWereChanged = true;
                                    return;
                                }
                            } else {
                                rightsWereChanged = true;
                                return;
                            }
                        });
                    }
                    if (hasReadOrAdminRightsAtLeastOnce && propertyWasChanged || rightsWereChanged) {
                        createButton.option('disabled', false);
                    } else {
                        createButton.option('disabled', true);
                    }
                }
            }
        };
        if (this.defaultAdminGroup) {
            viewGroups.push(this.defaultAdminGroup);
        }
        if (selectedView && typeof selectedView.enabledDevices === 'number') {
            availableInDevices = selectedView.enabledDevices;
        }
        const tabsElement = jQuery('<div></div>');

        const loadingIndicator = jQuery('<div></div>');
        loadingIndicator.appendTo(container);
        loadingIndicator.dxLoadIndicator();
        this.viewSystemSettingsWebServiceRequest.loadRightsForView(selectedView).then((viewRights) => {
            loadingIndicator.remove();
            if (viewRights) {
                viewGroups.length = 0;
                viewGroups.push(...viewRights);
                previousViewRights.length = 0;
                // Parse the results so no connection between objects exists
                previousViewRights.push(...JSON.parse(JSON.stringify(viewRights)));
            }
            jQuery(tabsElement).dxTabPanel({
                items: [{
                    title: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.tab.general'),
                    template: () => {
                        const tabContainer = jQuery('<div></div>')[0];
                        const viewMetadataUiPanel = new ViewMetadataUiPanel(DynamicWorkspace.$, this.languageProvider, DynamicWorkspace as any, this.viewSystemSettingsWebServiceRequest);
                        viewMetadataUiPanel.onViewAliasChanged = (newAlias) => {
                            viewAlias = newAlias;
                            checkCreateButtonState();
                        };
                        viewMetadataUiPanel.onViewDescriptionChanged = (newDescription) => {
                            viewDescription = newDescription;
                            checkCreateButtonState();
                        };
                        viewMetadataUiPanel.onViewNameChanged = (newName) => {
                            viewName = newName;
                            checkCreateButtonState();
                        };
                        viewMetadataUiPanel.onViewShowInNavigationChanged = (displayInNavigation) => {
                            showInNavigation = displayInNavigation;
                            checkCreateButtonState();
                        };
                        viewMetadataUiPanel.onViewTemplateChanged = (newTemplate) => {
                            selectedTemplate = newTemplate;
                            checkCreateButtonState();
                        };
                        viewMetadataUiPanel.render(tabContainer, selectedView);
                        // Popup will trigger this event on every child so just attach to it.
                        tabsElement.on('wd-create-view', () => {
                            if (viewName) {
                                let templateId = null;
                                if (selectedTemplate) {
                                    templateId = selectedTemplate.id;
                                }
                                this.viewSystemSettingsWebServiceRequest.createView(viewName, showInNavigation, viewDescription, viewAlias, templateId, availableInDevices, viewGroups).then(() => {
                                    this.refresh();
                                    DynamicWorkspace.Notification.success({
                                        body: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.toasts.success')
                                    });
                                    if (this.currentlyOpenPopup) {
                                        this.currentlyOpenPopup.destroy();
                                        this.currentlyOpenPopup = undefined;
                                    }
                                }).catch(() => {
                                    DynamicWorkspace.Notification.error({
                                        body: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.toasts.error')
                                    });
                                });
                            }
                        });
                        tabsElement.on('wd-edit-view', () => {
                            if (viewName && selectedView) {
                                this.viewSystemSettingsWebServiceRequest.editView(viewName, showInNavigation, viewDescription, viewAlias, availableInDevices, viewGroups, selectedView).then(() => {
                                    DynamicWorkspace.Notification.success({
                                        body: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.editView.toast.success')
                                    });
                                    this.refresh();
                                    if (this.currentlyOpenPopup) {
                                        this.currentlyOpenPopup.destroy();
                                        this.currentlyOpenPopup = undefined;
                                    }
                                }).catch(() => {
                                    DynamicWorkspace.Notification.error({
                                        body: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.editView.toast.error')
                                    });
                                });
                            }
                        });

                        return tabContainer;
                    }
                }, {
                    title: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.tab.rights'),
                    template: () => {
                        const tabContainer = jQuery('<div class="wd-create-view-popup-rights-tab"></div>')[0];
                        const viewRightsUiPanel = new ViewRightsUiPanel(DynamicWorkspace.$, this.languageProvider, DynamicWorkspace as any);
                        viewRightsUiPanel.render(tabContainer, viewGroups);
                        viewRightsUiPanel.onViewRightsChanged = () => {
                            checkCreateButtonState();
                        };
                        return tabContainer;
                    }
                },
                {
                    title: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.tab.devices'),
                    template: () => {
                        const tabContainer = jQuery('<div class="wd-system-settings-devices-tab"></div>')[0];
                        const phoneGroup = jQuery('<div></div>');
                        const showOnPhone = jQuery(`<span class="wd-create-view-popup-device-label">
                            ${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.deviceConfig.mobile')}</span>`);
                        const showOnPhoneSwitchTarget = jQuery('<div class="wd-system-settings-create-view-switch"></div>');
                        showOnPhoneSwitchTarget.appendTo(phoneGroup);
                        showOnPhone.appendTo(phoneGroup);
                        const phoneFlag = 4;
                        showOnPhoneSwitchTarget.dxSwitch({
                            value: (availableInDevices & phoneFlag) === phoneFlag,
                            onValueChanged: (event) => {
                                if (event.value) {
                                    availableInDevices |= phoneFlag;
                                } else {
                                    availableInDevices &= ~phoneFlag;
                                }
                                checkCreateButtonState();
                            }
                        });
                        phoneGroup.appendTo(tabContainer);
                        const tabletGroup = jQuery('<div></div>');
                        const showOnTablet = jQuery(`<span class="wd-create-view-popup-device-label">
                            ${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.deviceConfig.tablet')}</span>`);
                        const showOnTabletSwitchTarget = jQuery('<div class="wd-system-settings-create-view-switch"></div>');
                        showOnTabletSwitchTarget.appendTo(tabletGroup);
                        showOnTablet.appendTo(tabletGroup);
                        const tabletFlag = 2;
                        showOnTabletSwitchTarget.dxSwitch({
                            value: (availableInDevices & tabletFlag) === tabletFlag,
                            onValueChanged: (event) => {
                                if (event.value) {
                                    availableInDevices |= tabletFlag;
                                } else {
                                    availableInDevices &= ~tabletFlag;
                                }
                                checkCreateButtonState();
                            }
                        });
                        tabletGroup.appendTo(tabContainer);
                        const desktopGroup = jQuery('<div></div>');
                        const showOnDesktop = jQuery(`<span class="wd-create-view-popup-device-label">
                            ${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.deviceConfig.desktop')}</span>`);
                        const showOnDesktopSwitchTarget = jQuery('<div class="wd-system-settings-create-view-switch"></div>');
                        showOnDesktopSwitchTarget.appendTo(desktopGroup);
                        showOnDesktop.appendTo(desktopGroup);
                        const desktopFlag = 1;
                        showOnDesktopSwitchTarget.dxSwitch({
                            value: (availableInDevices & desktopFlag) === desktopFlag,
                            onValueChanged: (event) => {
                                if (event.value) {
                                    availableInDevices |= desktopFlag;
                                } else {
                                    availableInDevices &= ~desktopFlag;
                                }
                                checkCreateButtonState();
                            }
                        });
                        desktopGroup.appendTo(tabContainer);
                        return tabContainer;
                    }
                }]
            });
            tabsElement.appendTo(container);
        }).catch((error) => {
            DynamicWorkspace.Logger.error(this.className, 'renderPopupContent', 'Failed to load view rights', error);
        });
        return container;
    }
    /**
     * Renders the view container.
     * 
     * @param {HTMLElement} targetElement The target html element to render the view container to.
     * @memberof ViewSystemSettingsSection
     */
    private renderViewContainer(targetElement: HTMLElement): void {
        this.viewContainer = DynamicWorkspace.$('<div class="wd-system-settings-view-container"></div>');

        // Render view buttons
        const viewButtonContainer = DynamicWorkspace.$('<div class="wd-system-settings-view-button-group"></div>');

        const createViewButton = this.renderButton(viewButtonContainer, true, 'outlined', 'wd-icon add');
        const deleteViewButton = this.renderButton(viewButtonContainer, true, 'outlined', 'wd-icon delete');
        const editViewButton = this.renderButton(viewButtonContainer, true, 'outlined', 'wd-icon edit');
        createViewButton.setDisabled(false);
        createViewButton.onClick = () => {
            this.currentlyOpenPopup = DynamicWorkspace.Popup.openPopup({
                body: (jQuery) => this.renderPopupContent(jQuery),
                title: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.title'),
                buttons: [{
                    label: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.create'),
                    eventName: 'wd-create-view',
                    buttonId: 'wd-create-view-button'
                }],
                destroyOnClose: true,
                closeOnClick: false,
                size: 'large'
            });
        };
        deleteViewButton.onClick = () => {
            if (!this.currentlySelectedViewConfig) {
                return;
            }
            DynamicWorkspace.Popup.openOkCancelPopup(() => {
                if (this.currentlySelectedViewConfig) {
                    this.viewSystemSettingsWebServiceRequest.deleteView(this.currentlySelectedViewConfig.id).then(() => {
                        DynamicWorkspace.Notification.success({
                            body: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.deleteView.toasts.success')
                        });
                        this.refresh();
                    }).catch(() => {
                        DynamicWorkspace.Notification.error({
                            body: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.deleteView.toasts.error')
                        });
                    });
                }
            }, () => {
                // Nothing to cancel
            }, {
                body: this.languageProvider.getWithFormat('framework.systemSettings.viewSystemSettingsSection.popups.deleteView.description',
                    this.languageProvider.getTranslationFromProperty(this.currentlySelectedViewConfig.name)),
                title: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.deleteView.title'),
                destroyOnClose: true,
                closeOnClick: false
            });
        };
        editViewButton.onClick = () => {
            this.currentlyOpenPopup = DynamicWorkspace.Popup.openPopup({
                body: (jQuery) => this.renderPopupContent(jQuery, this.currentlySelectedViewConfig),
                title: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.editView.title'),
                buttons: [{
                    label: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.editView.edit'),
                    eventName: 'wd-edit-view',
                    buttonId: 'wd-create-view-button'
                }],
                destroyOnClose: true,
                closeOnClick: false,
                size: 'large'
            });
        };

        const topMostButton = this.renderButton(viewButtonContainer, true, 'outlined', 'wd-icon angle-double-up');
        const upButton = this.renderButton(viewButtonContainer, true, 'outlined', 'wd-icon angle-up');
        const downButton = this.renderButton(viewButtonContainer, true, 'outlined', 'wd-icon angle-down');
        const downMostButton = this.renderButton(viewButtonContainer, true, 'outlined', 'wd-icon angle-double-down');

        const checkButtonState = () => {
            if (this.availableViewConfigs) {
                const currentPositionIndex = this.availableViewConfigs.findIndex((entry) => entry === this.currentlySelectedViewConfig);
                topMostButton.setDisabled(currentPositionIndex < 1);
                upButton.setDisabled(currentPositionIndex < 1);
                downButton.setDisabled(this.availableViewConfigs.length === currentPositionIndex + 1);
                downMostButton.setDisabled(this.availableViewConfigs.length === currentPositionIndex + 1);
                deleteViewButton.setDisabled(currentPositionIndex < 0);
                editViewButton.setDisabled(currentPositionIndex < 0);
            }
        };
        topMostButton.onClick = () => {
            if (this.currentlySelectedViewConfig) {
                this.moveItem(this.currentlySelectedViewConfig, 'TopMost');
                checkButtonState();
            }
        };
        upButton.onClick = () => {
            if (this.currentlySelectedViewConfig) {
                this.moveItem(this.currentlySelectedViewConfig, 'Up');
                checkButtonState();
            }
        };
        downButton.onClick = () => {
            if (this.currentlySelectedViewConfig) {
                this.moveItem(this.currentlySelectedViewConfig, 'Down');
                checkButtonState();
            }
        };
        downMostButton.onClick = () => {
            if (this.currentlySelectedViewConfig) {
                this.moveItem(this.currentlySelectedViewConfig, 'DownMost');
                checkButtonState();
            }
        };
        // Render view list
        this.viewList = DynamicWorkspace.$('<div></div>').dxList({
            itemDragging: {
                allowReordering: true, // Previously allowItemReordering
            },
            items: this.availableViewConfigs,
            itemTemplate: (itemData: ViewConfigMetaData, _itemIndex, itemElement) => {
                itemElement.text(this.languageProvider.getTranslationFromProperty(itemData.name) + ' (' + itemData.id + ')');
            },
            selectionMode: 'single',
            onItemClick: (data) => {
                if (data.itemData) {
                    this.currentlySelectedViewConfig = data.itemData;
                    checkButtonState();
                }
            },
            onItemReordered: () => {
                if (this.saveViewOderButton) {
                    this.saveViewOderButton.setDisabled(false);
                }
            },
            useNativeScrolling: true
        });

        this.viewList.appendTo(this.viewContainer);
        viewButtonContainer.appendTo(this.viewContainer);
        this.viewContainer.appendTo(targetElement);
        const saveViewOrder = DynamicWorkspace.$('<div class="wd-system-settings-save-view-order-button"></div>');
        this.saveViewOderButton = DynamicWorkspace.Ui.components.button(saveViewOrder[0]);
        this.saveViewOderButton.bootstrap();
        this.saveViewOderButton.setOptions({
            text: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.saveViewOrder'),
            stylingMode: 'text',
            isDisabled: true
        });
        this.saveViewOderButton.onClick = () => {
            this.saveViewOderButton?.setDisabled(true);
            this.viewSystemSettingsWebServiceRequest.updateViewOrder(this.availableViewConfigs).then(() => {
                this.refresh();
                DynamicWorkspace.Notification.success({
                    body: this.languageProvider.get('framework.toastr.savesucess.body')
                });
            }).catch(() => {
                this.saveViewOderButton?.setDisabled(false);
                DynamicWorkspace.Notification.error({
                    body: this.languageProvider.get('framework.toastr.savefailed.body')
                });
            });
        };
        saveViewOrder.appendTo(targetElement);
    }

    /**
     * Renders a button.
     * 
     * @param {JQuery<HTMLElement>} target The target html element to render the button to.
     * @param {boolean} isDisabled Whether the button is disabled.
     * @param stylingMode The styling mode.
     * @param {string} icon The icon.
     * @returns {Button} The rendered button.
     * @memberof ViewSystemSettingsSection
     */
    private renderButton(target: JQuery<HTMLElement>, isDisabled: boolean, stylingMode: 'contained' | 'outlined' | 'text', icon: string): Button {
        const buttonTarget = DynamicWorkspace.$('<div></div>');
        buttonTarget.appendTo(target);
        const button = DynamicWorkspace.Ui.components.button(buttonTarget[0]);
        button.bootstrap();
        button.setOptions({
            isDisabled: isDisabled,
            stylingMode: stylingMode,
            icon: icon
        });
        return button;
    }
    /**
     * Move the view within the list, thus changing the order.
     *
     * @param {ViewConfigMetaData} viewConfigToMove The view config that shall be moved.
     * @param moveType The move type.
     * @memberof ViewSystemSettingsSection
     */
    private moveItem(viewConfigToMove: ViewConfigMetaData, moveType: 'TopMost' | 'Up' | 'Down' | 'DownMost'): void {
        if (this.availableViewConfigs) {
            const currentPositionIndex = this.availableViewConfigs.findIndex((entry) => entry === viewConfigToMove);
            let targetIndex = 0;
            switch (moveType) {
                case 'TopMost':
                    targetIndex = 0;
                    break;
                case 'Up':
                    targetIndex = currentPositionIndex - 1;
                    break;
                case 'Down':
                    targetIndex = currentPositionIndex + 1;
                    break;
                case 'DownMost':
                    targetIndex = this.availableViewConfigs.length - 1;
                    break;
            }
            DynamicWorkspace.Utils.moveArrayElement(this.availableViewConfigs, currentPositionIndex, targetIndex);
            if (this.saveViewOderButton) {
                this.saveViewOderButton.setDisabled(false);
            }
            if (this.viewList) {
                DynamicWorkspace.$(this.viewList).dxList({
                    items: this.availableViewConfigs,
                    selectedItem: this.currentlySelectedViewConfig
                });
                this.scrollItemIntoView(targetIndex);
            }
        }
    }
    /**
     * Moves the item with the given index into the view.
     *
     * @private
     * @param {number} index Index of the element to scroll to.
     * @memberof ViewSystemSettingsSection
     */
    private scrollItemIntoView(index: number): void {
        const itemHeight = this.viewList?.find('.dx-item').eq(index).height() || 0;
        const offsetTop = (itemHeight || 0) * index;
        const listHeight = this.viewList?.outerHeight() || 0;
        const middle = 2;
        const offset = (listHeight - itemHeight) / middle;
        if (typeof offsetTop !== 'undefined') {
            const targetPosition = Math.max(0, (offsetTop - offset));
            const scrollableContainer = this.viewList?.find('.dx-scrollable-container');
            scrollableContainer?.scrollTop(targetPosition);
        }
    }
}