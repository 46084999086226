import { MigrateFunction, ModuleRegistration as BaseModuleRegistration, ModuleRegistrationType } from '../../../../typings/index';

/**
 * Represents a module registration.
 *
 * @export
 * @class ModuleRegistration
 * @implements {BaseModuleRegistration}
 */
export class ModuleRegistration implements BaseModuleRegistration {

    /**
     * The id.
     *
     * @type {string}
     * @memberof ModuleRegistration
     */
    public id: string;

    /**
     * The class reference.
     *
     * @type {*}
     * @memberof ModuleRegistration
     */
    public classReference: any;

    /**
     * The type.
     *
     * @type {ModuleRegistrationType}
     * @memberof ModuleRegistration
     */
    public type: ModuleRegistrationType;

    /**
     * The migration function.
     *
     * @type {(MigrateFunction | undefined)}
     * @memberof ModuleRegistration
     */
    public migrationFunction?: MigrateFunction | undefined;

    /**
     * Creates an instance of ModuleRegistration.
     * 
     * @param {string} id The id.
     * @param {*} classReference The class reference.
     * @param {ModuleRegistrationType} type The type.
     * @param {(MigrateFunction | undefined)} migrationFunction The migration function.
     * @memberof ModuleRegistration
     */
    public constructor(id: string, classReference: any, type: ModuleRegistrationType,
        migrationFunction: MigrateFunction | undefined) {
        if (!id && typeof id !== 'string') {
            throw new Error('id parameter is faulty');
        }
        if (!classReference) {
            throw new Error('classReference parameter is faulty');
        }
        if (!type) {
            throw new Error('type parameter is faulty');
        }
        this.id = id;
        this.classReference = classReference;
        this.type = type;
        this.migrationFunction = migrationFunction;
    }
}