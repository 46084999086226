import { Utils } from '../common';
import { ComponentConfig, ComponentConfigToolbar } from './models';
/**
 * This class provides a factory, which will generate ComponentConfig classes from JSON objects or JSON strings.
 * 
 * @export
 * @class ComponentConfigFactory
 */
export class ComponentConfigFactory {
    /**
     * Gets the ComponentConfig from JSON.
     * @access public
     * @param {ComponentConfig | string} json The JSON, which should be transformed into a ComponentConfig.
     * @returns {ComponentConfig}
     * @throws {Error} The class can not be created from the JSON.
     */
    public static fromJSON(json: ComponentConfig | string): ComponentConfig {
        const jsonObject = typeof json === 'string' ? JSON.parse(json) : json;
        const componentConfigObject: ComponentConfig = {
            author: jsonObject.author,
            category: jsonObject.category,
            configuration: jsonObject.configuration,
            description: jsonObject.description,
            entry: jsonObject.entry,
            id: jsonObject.id,
            isDevPreview: jsonObject.isDevPreview || false,
            isLogic: jsonObject.isLogic || false,
            name: jsonObject.name,
            pubSub: jsonObject.pubSub,
            size: jsonObject.size,
            style: jsonObject.style,
            tags: jsonObject.tags,
            template: jsonObject.template,
            version: jsonObject.version,
            toolbar: jsonObject.toolbar
        };
        if(!componentConfigObject.toolbar) {
            componentConfigObject.toolbar = new ComponentConfigToolbar();
        }
        // Check if values are equal
        const valuesToIgnore = ['isDevPreview', 'isLogic']; // Values that are set by default and are notpresent in every JSON file
        valuesToIgnore.forEach((valueToIgnore) => {
            for (const key in jsonObject) {
                if (jsonObject.hasOwnProperty(key)) {
                    if (valueToIgnore.indexOf(key) !== -1) {
                        return;
                    }
                    Utils.Instance.deepEqual((componentConfigObject as any)[key], (jsonObject as any)[key]);
                }

            }
        });
        // Set id for pubsub connections if nobody did set one, so it can be used in the ui
        componentConfigObject?.pubSub?.in.forEach((inConnection) => {
            if (!inConnection.id) {
                inConnection.id = componentConfigObject.id + '.subscriber.' + inConnection.name;
            }
        });
        componentConfigObject?.pubSub?.out.forEach((outConnection) => {
            if (!outConnection.id) {
                outConnection.id = componentConfigObject.id + '.publisher.' + outConnection.name;
            }
        });
        return componentConfigObject;
    }
}