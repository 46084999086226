import { EncryptionHelper } from '../encryption/index';
import { IResourcePointer } from '.';


/**
 * An instance of this class can point to a specific resource.
 * 
 * @export
 * @class ResourcePointer
 * @implements {IResourcePointer}
 */
export class ResourcePointer implements IResourcePointer {

    /**
     * The scheme.
     * 
     * @type {string}
     * @memberof ResourcePointer
     */
    public scheme?: string;

    /**
     * The originalUri.
     * 
     * @type {string}
     * @memberof ResourcePointer
     */
    public originalUri: string;

    /**
     * The source uri.
     * 
     * @type {string}
     * @memberof ResourcePointer
     */
    public uri: string;

    /**
     * The used parameter.
     * 
     * @type {*}
     * @memberof ResourcePointer
     */
    public parameter: any;

    /**
     * The hash of this pointer.
     * 
     * @type {string}
     * @memberof ResourcePointer
     */
    public ptrHash: string;

    /**
     * The encryption helper.
     *
     * @protected
     * @type {EncryptionHelper}
     * @memberof ResourcePointer
     */
    protected encryptionHelper: EncryptionHelper;

    /**
     * The language.
     *
     * @protected
     * @type {string}
     * @memberof ResourcePointer
     */
    protected language?: string;

    /**
     * Creates an instance of ResourcePointer.
     * @param {string} uri 
     * @param {*} [parameter] 
     * 
     * @memberof ResourcePointer
     */
    public constructor(uri: string, parameter?: any) {
        this.encryptionHelper = new EncryptionHelper();
        this.originalUri = uri;

        this.uri = uri;
        this.parameter = parameter;

        this.ptrHash = this.calculateHash();
    }

    /**
     * Sets the language.
     *
     * @param {string} language The language.
     * @memberof ResourcePointer
     */
    public setLanguage(language: string): void {
        this.language = language;

        // Also update the hash.
        this.ptrHash = this.calculateHash();
    }

    /**
     * Calculates the hash.
     *
     * @returns {*}  {string} The hash.
     * @memberof ResourcePointer
     */
    public calculateHash(): string {
        let tempUri = '';
        if (this.uri) {
            tempUri = this.uri;
        }

        let tempParameter = '';
        if (this.parameter) {
            if (typeof (this.parameter) === 'string') {
                tempParameter = this.parameter;
            } else if (typeof (this.parameter) === 'object') {
                for (const key in this.parameter) {
                    if (this.parameter.hasOwnProperty(key)) {
                        const value = this.parameter[key];
                        tempParameter += key + value;
                    }
                }
            }
        }

        if (this.language) {
            return this.encryptionHelper.sha256(tempUri + tempParameter + this.language);
        } else {
            return this.encryptionHelper.sha256(tempUri + tempParameter);
        }
    }

}