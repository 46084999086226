import { IOperation } from './iOperation';

/**
 * The default implementation of a pipeline.
 *
 * @export
 * @class Pipeline
 * @implements {IOperation<T>}
 * @template T
 */
export class Pipeline<T> implements IOperation<T> {

    private readonly operations: IOperation<T>[];


    /**
     * Creates an instance of Pipeline.
     *
     * @memberof Pipeline
     */
    public constructor() {
        this.operations = [];
    }


    /**
     * Registers the given pipeline operation.
     *
     * @param {IOperation<T>} operation The pipeline operation.
     * @memberof Pipeline
     */
    public register(operation: IOperation<T>): void {
        this.operations.push(operation);
    }

    /**
     * Invokes every operation of the pipeline.
     *
     * @param {T} parameter The operation parameter.
     * @memberof Pipeline
     */
    public invoke(parameter: T): boolean {

        let succeeded = true;

        this.operations.forEach((operation) => {
            if (!operation.invoke(parameter)) {
                succeeded = false;
                return false;
            }
        });

        return succeeded;
    }

}