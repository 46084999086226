import { WEBSERVICE_ERROR_CODES } from 'typings/services';
import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HttpResourcePointer } from '../../dataProviders';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The Archive action is used to archive a document within windream
 * 
 * @export
 * @class Archive
 * @extends {ServiceAction}
 */
export class Archive extends ServiceAction {
  /**
   * Name of the service action.
   * 
   * 
   * @memberof Archive
   */
  public name = 'archive';

  /**
   * Execute archiving of given document.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {WindreamRequestOptions} windreamRequestOptions The request options.
   * @returns {Promise<IServiceResponse<ResponseContainerDTO>>} A promise, which will resolve with the server response.
   * @memberof Archive
   */
  public async do(windreamRequestOptions: WindreamRequestOptions): Promise<IServiceResponse<ResponseContainerDTO>> {
    return new Promise<IServiceResponse<ResponseContainerDTO>>((resolve, reject) => {
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/documents/Archive', {
        Item: IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity)
      }), windreamRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
        if (response.data?.HasErrors) {
          const errorCode = response.data.Error?.ErrorCode;
          if (errorCode === WEBSERVICE_ERROR_CODES.IsAlreadyArchived || errorCode === WEBSERVICE_ERROR_CODES.NotManuallyArchivable) {
            resolve(response);
          } else {
            this.logger.error('Archive', 'do', 'Failed to archive document', response);
            reject('Failed to archive document');
          }
        } else {
          resolve(response);
        }
      }).catch((err: Error) => {
        this.logger.error('Archive', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}