import { IPubSubHandler } from '../pubSub';

/**
 * Util class for the MappingUiHelper.
 * 
 * @export
 * @class MappingUiHelperUtil
 */
export class MappingUiHelperUtil {
    public static readonly className = 'MappingUiHelperUtil';
    public static readonly MAINFRAME_COMPONENT_GUID = 'WINDREAM_MAPPING_MAINFRAME';
    public static readonly COMPONENTPANEL_COMPONENT_GUID = 'WINDREAM_MAPPING_COMPONENTPANEL';
    public static readonly ACTION_COMPONENT_GUID = 'WINDREAM_MAPPING_ACTIONPANEL';

    /**
     * Adds PubSub connections that are used by the mapping mainframe.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof MappingUiHelperUtil
     */
    public static addMainframeConfigurationPubSub(pubSubHandler: IPubSubHandler): void {
        pubSubHandler.loadPubSub([
            {
                // Used in com.windream.mapping.mainframe when the Panel should be closed and changes exists
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges'
                }]
            }, {
                // Used in com.windream.mapping.mainframe when the Panel should be closed and changes exists, thus saving these changes
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_Save'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_Save'
                }]
            }, {
                // Used in com.windream.mapping.mainframe when the Panel should be closed and changes exists, thus discarding these changes.
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_DiscardConfig'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_DiscardConfig'
                }]
            }, {
                // Used in com.windream.mapping.mainframe when the view config changed so that config has new values
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }]
            }, {
                // Used in com.windream.mapping.mainframe when a change is made within the component
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }]
            }, {
                // Used in com.windream.mapping.actions when the "Apply" button is clicked
                in: [{
                    componentGuid: MappingUiHelperUtil.ACTION_COMPONENT_GUID,
                    parameter: 'ApplyMapping'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.ACTION_COMPONENT_GUID,
                    parameter: 'ApplyMapping'
                }]
            }, {
                // Used in com.windream.mapping.mainframe when the "Define Mapping" button is clicked
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'AddedMappingType'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.COMPONENTPANEL_COMPONENT_GUID,
                    parameter: 'AddedMappingType'
                }]
            }, {
                // Used in com.windream.mapping.mainframe when the "Export" button is clicked
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'ExportMapping'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.ACTION_COMPONENT_GUID,
                    parameter: 'ExportMapping'
                }]
            }, {
                // Used in com.windream.mapping.mainframe when the "Import" button is clicked
                in: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'ImportMapping'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.ACTION_COMPONENT_GUID,
                    parameter: 'ImportMapping'
                }]
            }, {
                // Used in com.windream.mapping.action when the "Saved" button is clicked
                in: [{
                    componentGuid: MappingUiHelperUtil.ACTION_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }],
                out: [{
                    componentGuid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }]
            }
        ]);
    }
}