import { StorageTokenResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { AuthenticationModes } from '../../authentication/index';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { TokenRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service for the StorageToken.
 *
 * @export
 * @class GetStorageToken
 * @extends {ServiceAction}
 */
export class GetStorageToken extends ServiceAction {

    /**
     * Creates an instance of GetStorageToken.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof GetStorageToken
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getStorageToken';
    }

    /**
     * Gets the storage token from the server.
     *
     * @param {TokenRequestOptions} [tokenRequestOptions] The request options.
     * @returns {Promise<string>} A promise which will resolve with the storage token.
     * @memberof GetStorageToken
     */
    public async do(tokenRequestOptions?: TokenRequestOptions): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const httpResourcePtr = new HttpResourcePointer('GET', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/security/GetStorageToken');

            // Set token if anonymous
            if (tokenRequestOptions && tokenRequestOptions.requestOptions &&
                tokenRequestOptions.requestOptions.authenticationMode === AuthenticationModes.Anonymous && tokenRequestOptions.token) {
                httpResourcePtr.httpHeaders.set('Authorization', 'Bearer ' + tokenRequestOptions.token);
            }

            this.requestExecutor.executeRequest(httpResourcePtr, tokenRequestOptions?.requestOptions)
                .then((response: IServiceResponse<StorageTokenResponseContainerDTO>) => {
                    if (response && response.data && response.data.StorageToken) {
                        resolve(response.data.StorageToken);
                    } else {
                        this.logger.error('GetStorageToken', 'do', 'Missing storage token in response execute request', response);
                        reject(new Error('Missing storage token in response execute request'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('GetStorageToken', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}