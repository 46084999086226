import { WindreamEntity } from '../../common';

/**
 * This evaluation model is used to determine what should happen to the context menu.
 *
 * @export
 * @class ContextMenuEvaluationModel
 */
export class ContextMenuEvaluationModel {

    /**
     * The identity flags.
     *
     * @type {number}
     * @memberof ContextMenuEvaluationModel
     */
    public documentFlags?: number;

    /**
     * The user which locked the identity.
     *
     * @type {string}
     * @memberof ContextMenuEvaluationModel
     */
    public currentLockedUser?: string;

    /**
     * Entity of the identity.
     * 
     * @type {WindreamEntity}
     * @memberof ContextMenuEvaluationModel
     */
    public entity?: WindreamEntity;

    /**
     * Number of identities.
     *
     * @type {number}
     * @memberof ContextMenuEvaluationModel
     */
    public identityCount: number;

    /**
     * Creates an instance of ContextMenuEvaluationModel.
     * 
     * @memberof ContextMenuEvaluationModel
     */
    public constructor() {
        this.identityCount = 0;
    }
}