import { IFileDataSource } from './iFileDataSource';


/**
 * A file object wrapper class.
 * 
 * @export
 * @class FileDataSource
 */
export class FileDataSource implements IFileDataSource {

    private _data?: File;


    /**
     * Sets the file data.
     * 
     * @param {File} data
     * 
     * @memberof FileDataSource
     */
    public setData(data: File) {
        this._data = data;
    }


    /**
     * Gets the file data as an FormData instance.
     * 
     * @returns {any}
     * 
     * @memberof FileDataSource
     */
    public getData(): any {
        const result = new FormData();
        if (this._data) {
            result.append('file', this._data);
        }

        return result;
    }

    /**
     * Gets the file.
     *
     * @returns {(File | null)} The file or null.
     * @memberof FileDataSource
     */
    public getFile(): File | null {
        if (this._data) {
            return this._data;
        } else {
            return null;
        }
    }

}