/**
 * The payload of a windream JWT token.
 *
 * @export
 * @class WindreamTokenPayload
 */
export class WindreamTokenPayload {

    /**
     * The subject of this token.
     *
     * @type {string}
     * @memberof WindreamTokenPayload
     */
    public sub?: string;

    /**
     * Not valid before (seconds since Unix epoch).
     *
     * @type {number}
     * @memberof WindreamTokenPayload
     */
    public nbf?: number;

    /**
     * Expiration time (seconds since Unix epoch).
     *
     * @type {number}
     * @memberof WindreamTokenPayload
     */
    public exp?: number;

    /**
     * Issued at (seconds since Unix epoch).
     *
     * @type {number}
     * @memberof WindreamTokenPayload
     */
    public iat?: number;

    /**
     * Issuer who created and signed this token.
     *
     * @type {string}
     * @memberof WindreamTokenPayload
     */
    public iss?: string;

    /**
     * The euc of this token.
     *
     * @type {string}
     * @memberof WindreamTokenPayload
     */
    public euc?: string;
}