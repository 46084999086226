/* eslint-disable max-classes-per-file */
import { SubViewType, ToolbarManager } from '../../../typings/core';
import { ComponentInstanceConfig } from '../config';
import { IExtensionProvider } from '../extensions';
import { IUiManager } from '../loader/interfaces/iUiManager';
import { IPubSubHandler } from '../pubSub/interfaces/iPubSubHandler';
import { ISkeletonHelper } from '../ui';
import { Changes } from './models';


/**
 * OnInit interface is a life cycle hook entrypoint, which will retrieve the currently used component confing file.
 * 
 * @export
 * @abstract
 * @class OnInit
 */
export abstract class OnInit {
    /**
     * Lifecycle hook entrypoint to be called when the component is initialized Pases the component's instance config.
     * 
     * @abstract
     * 
     * @memberof OnInit
     */
    public abstract wdOnInit(config: ComponentInstanceConfig): void;
}

/**
 * OnSkeleton interface is a life cycle hook entrypoint, which will be called when the component should render skeletons.
 * 
 * @export
 * @abstract
 * @class OnSkeleton
 */
export abstract class OnSkeleton {
    /**
     * Lifecycle hook entrypoint to be called after wdOnBind.
     * 
     * @abstract
     * @param {ISkeletonHelper} skeletonHelper ISkeletonHelper instance to use.
     * @param {boolean} isInConfigMode Whether the skeleton is displayed in config mode or as a default skeleton.
     * @memberof OnSkeleton
     */
    public abstract wdOnSkeleton(skeletonHelper: ISkeletonHelper, isInConfigMode: boolean): void;
}

/**
 * OnBind interface is a life cycle hook entrypoint, which will retrieve the currently used PubSubHandler.
 * 
 * @export
 * @abstract
 * @class OnBind
 */
export abstract class OnBind {
    /**
     * Lifecycle hook entrypoint to be called when the component is handed the PubSubHandler.
     * 
     * @abstract
     * 
     * @memberof OnBind
     */
    public abstract wdOnBind(pubSub: IPubSubHandler): void;
}

/**
 * OnAfterBind interface is a life cycle hook entrypoint, which will be called after wdOnBind.
 * 
 * @export
 * @abstract
 * @class OnAfterBind
 */
export abstract class OnAfterBind {

    /**
     * Lifecycle hook entrypoint to be called after wdOnBind.
     * 
     * @abstract
     * @memberof OnAfterBind
     */
    public abstract wdOnAfterBind(): void;
}

/**
 * OnToolbar interface is a life cycle hook entrypoint, which will be called after wdOnRender.
 *
 * @export
 * @abstract
 * @class OnToolbar
 */
export abstract class OnToolbar {

    /**
     * Lifecycle hook entrypoint to be called.
     *
     * @abstract
     * @param {ToolbarManager} toolbarManager The toolbar manager.
     * @memberof OnToolbar
     */
    public abstract wdOnToolbar(toolbarManager: ToolbarManager): void;
}

/**
 * OnRender interface is a life cycle hook entrypoint, which will be called when the component should render itself. Passes ExtensionProvider instance.
 * 
 * @export
 * @abstract
 * @class OnExtension
 */
export abstract class OnExtension {
    /**
     * Lifecycle hook entrypoint to be called when the component is handed the ExtensionProvider.
     * 
     * @abstract
     * 
     * @memberof OnExtension
     */
    public abstract wdOnExtension(extensionProvider: IExtensionProvider): void;
}

/**
 * OnRender interface is a life cycle hook entrypoint, which will be called when the component should render itself. Passes UiManager instance.
 * 
 * @export
 * @abstract
 * @class OnRender
 */
export abstract class OnRender {
    /**
     * Lifecycle hook entrypoint to be called when the component is rendered.
     * 
     * @abstract
     * 
     * @memberof OnRender
     */
    public abstract wdOnRender(uiManager: IUiManager): void;
}
/**
 * OnAfterRender interface is a life cycle hook entrypoint, which will be called after the component should render itself.
 * 
 * @export
 * @abstract
 * @class OnInit
 */
export abstract class OnAfterRender {
    /**
     * OnAfterRender interface is a life cycle hook entrypoint, which will be called after the component should render itself.
     * 
     * @abstract
     * 
     * @memberof OnAfterRender
     */
    public abstract wdOnAfterRender(): void;
}

/**
 * OnSubViewChanged is a life cycle hook entrypoint, which will be called if the subview was changed.
 *
 * @export
 * @abstract
 * @class OnSubViewChanged
 */
export abstract class OnSubViewChanged {

    /**
     * This life cycle hook entrypoint, which will be called if the subview was changed.
     *
     * @abstract
     * @param {boolean} isOnActiveSubView Whether this component is active on this subview.
     * @param {SubViewType} subViewType The sub view type.
     * @memberof OnSubViewChanged
     */
    public abstract wdOnSubViewChanged(isOnActiveSubView: boolean, subViewType: SubViewType): void;
}

/**
 * OnSubViewChanging is a life cyacle hook entrypoint, which will be called before a subview will be changed.
 *
 * @export
 * @abstract
 * @class OnSubViewChanging
 */
export abstract class OnSubViewChanging {

    /**
     * This life cycle hook entrypoint, which will be called before the subview will be changed.
     *
     * @abstract
     * @param {boolean} willBeActiveOnSubView Whether this component will be active on this subview.
     * @param {SubViewType} subViewType The sub view type.
     * @memberof OnSubViewChanging
     */
    public abstract wdOnSubViewChanging(willBeActiveOnSubView: boolean, subViewType: SubViewType): void;
}

/**
 * OnRefresh interface is a life cycle hook entrypoint, which will be called after the component should render itself.
 * 
 * @export
 * @abstract
 * @class OnRefresh
 */
export abstract class OnRefresh {
    /**
     * OnRefresh interface is a life cycle hook entrypoint, which will be called after something changed within another component.
     * 
     * @abstract
     * @param {Changes} changes Changes that occured.
     * @memberof OnRefresh
     */
    public abstract wdOnRefresh(changes: Changes): void;
}
/**
 * OnDestroy interface is a life cycle hook entrypoint, which will be called when the component should destroy itself.
 * 
 * @export
 * @abstract
 * @class OnDestroy
 */
export abstract class OnDestroy {
    /**
     * Lifecycle hook entrypoint to be called when the component is destroyed.
     * 
     * @abstract
     * 
     * @memberof OnDestroy
     */
    public abstract wdOnDestroy(): void;
}