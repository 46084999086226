import { SortDirection } from '../../typings/windreamWebService/Windream.WebService';


/**
 * A helper class containing utillity function for sorting collections.
 *
 * @export
 * @class SortHelper
 */
export class SortHelper {
    /**
     * Sorts any array by the specified property in ascending or descending order.
     *
     * @param {*} property Property in each entry to sort by.
     * @param {SortDirection} direction Order to sort in. This can be either ascending or descending.
     * @returns {Function}
     * @memberof SortHelper
     */
    public static sortArrayBy(property: any, direction: SortDirection): any {
        if (direction === SortDirection.Ascending) {
            return (x: any, y: any) => {
                return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
            };
        } else {
            return (x: any, y: any) => {
                return ((x[property] === y[property]) ? 0 : ((x[property] < y[property]) ? 1 : -1));
            };
        }
    }
}