import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents options for GetScriptRequestOptions.
 *
 * @export
 * @class GetScriptRequestOptions
 * @extends {BaseRequestOptions}
 */
export class GetScriptRequestOptions extends BaseRequestOptions {

    /**
     * The ID of the script which shall be retrieved.
     *
     * @type {string}
     * @memberof GetScriptRequestOptions
     */
    public scriptId: string;

    /**
     * Creates an instance of GetScriptRequestOptions.
     *
     * @param {string} scriptName
     * @memberof GetScriptRequestOptions
     */
    public constructor(scriptId: string) {
        super();
        if (typeof scriptId !== 'string') {
            throw new Error('Argument \'scriptId\' is not valid');
        }
        this.scriptId = scriptId;
    }
}