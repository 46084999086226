import { ContextMenu } from '../contextMenu';
import { WindreamContextMenuCreator } from '../contextMenu';
import { IViewManager } from '../loader';
import { IContextMenuExtension } from './interfaces';

/**
 * The context menu extension provides a context menu.
 * 
 * @export
 * @class ContextMenuExtension
 * @implements {IContextMenuExtension}
 */
export class ContextMenuExtension implements IContextMenuExtension {

  /**
   * The name of this extension.
   * 
   * @type {string}
   * @memberof ContextMenuExtension
   */
  public name: string = 'context_menu';

  /**
   * Windream context menu creator instance.
   * 
   * @type {WindreamContextMenuCreator}
   * @memberof ContextMenuExtension
   */
  public windreamContextMenuCreator: WindreamContextMenuCreator;
  private fallbackContextMenu?: ContextMenu;
  private viewManager: IViewManager;

  /**
   * Creates an instance of ContextMenuExtension.
   * @param {WindreamContextMenuCreator} windreamContextMenuCreator 
   * @memberof ContextMenuExtension
   */
  public constructor(windreamContextMenuCreator: WindreamContextMenuCreator, viewManager: IViewManager) {
    this.windreamContextMenuCreator = windreamContextMenuCreator;
    this.viewManager = viewManager;
  }

  /**
   * Set the fallback menu, which will be displayed if the websocket menu fails.
   * 
   * @param {ContextMenu} fallbackMenu The fallback.
   * 
   * @memberof ContextMenuExtension
   */
  public setFallbackMenu(fallbackMenu: ContextMenu) {
    this.fallbackContextMenu = fallbackMenu;
  }

  /**
   * Opens a context menu for the file with the given location.
   * Uses the Websocket Handler to reach out for the local websocket server in order to render native context menus.
   * @param {string} _locationComplete LocationComplete attribute of the file to open the context menu for.
   * @param {Event}  event            Event that triggered the context menu.
   */
  public openMenu(_locationComplete: string, event: Event | any): void {
    if (!this.fallbackContextMenu) {
      return;
    }

    // Check if the context menu would be empty
    const contextMenuItems = this.fallbackContextMenu.getItems();
    if (!contextMenuItems || contextMenuItems.length === 0) {
      return; // Do not open an empty context menu
    }

    // @ts-ignore - Ignore because of Foundation usage
    if (window['Foundation'] && window['Foundation']['ContextMenu']) {
      // @ts-ignore - Ignore because of Foundation usage
      new window['Foundation']['ContextMenu'](window['$'](event.target), {
        accessible: true,
        emptyEntryWillCloseMenu: false,
        position: event,
        single: true,
        structure: contextMenuItems
      });
    }
  }

  /**
   * Get context menu name from ViewConfig
   *
   * @public
   * @returns {string}
   * @memberof ContextMenuExtension
   */
  public getCurrentViewContextMenuName(): string {
    return this.viewManager.getCurrentViewContextMenuName();
  }
}