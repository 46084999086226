import { Culture } from '../../../typings';
import { VectorFormatter } from '../../../typings/formatter';
import { InvalidOperationError } from '../errors';
import { ILanguageProvider } from '../language';
import { BooleanFormatHelper } from './booleanFormatHelper';
import { CultureInfo } from './cultureInfo';
import { SortHelper } from './sortHelper';
import { VectorFormatHelper } from './vectorFormatHelper';
import { DateTimeFormatHelper, FileSizeFormatHelper, NumberFormatHelper, WindreamFormatter, WindreamFsFlagsFormatHelper, WindreamStatusFormatHelper } from '.';

/**
 * The CultureHelper will keep track of the currently used language and its helper.
 * 
 * @export
 * @class CultureHelper
 * @extends {CultureInfo}
 */
export class CultureHelper extends CultureInfo implements Culture{
    private readonly DEFAULT_LANGUAGE = 'en';
    private readonly DEFAULT_CURRENCY_CODE = 'EUR'; // TODO: Get the default currency from the configuration.


    /**
     * The currently used DateTimeFormatHelper.
     * 
     * @private
     * @type {DateTimeFormatHelper}
     * @memberof CultureHelper
     */
    private _dateTimeFormatHelper?: DateTimeFormatHelper;
    /**
     * Gets the DateTimeFormatHelper.
     * 
     * @readonly
     * @type {DateTimeFormatHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * 
     * @memberof CultureHelper
     */
    public get dateTimeFormatHelper(): DateTimeFormatHelper {
        if (!this.isInitialized || !this._dateTimeFormatHelper) {
            throw new InvalidOperationError('Cannot return format helper. Call `init` first.');
        }
        return this._dateTimeFormatHelper;
    }

    private _numberFormatHelper?: NumberFormatHelper;
    /**
     * Gets the number format helper.
     *
     * @readonly
     * @type {NumberFormatHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * 
     * @memberof CultureHelper
     */
    public get numberFormatHelper(): NumberFormatHelper {
        if (!this.isInitialized || !this._numberFormatHelper) {
            throw new InvalidOperationError('Cannot return format helper. Call `init` first.');
        }
        return this._numberFormatHelper;
    }

    private _booleanFormatHelper?: BooleanFormatHelper;
    /**
     * Gets the BooleanFormatHelper.
     * 
     * @readonly
     * @type {BooleanFormatHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * @memberof CultureHelper
     */
    public get booleanFormatHelper(): BooleanFormatHelper {
        if (!this.isInitialized || !this._booleanFormatHelper) {
            throw new InvalidOperationError('Cannot return format helper. Call `init` first.');
        }
        return this._booleanFormatHelper;
    }

    private _sortHelper?: SortHelper;
    /**
     * Gets the sort helper.
     * Sorts string/number values alphabetically.
     *
     * @readonly
     * @type {SortHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * 
     * @memberof CultureHelper
     */
    public get sortHelper(): SortHelper {
        if (!this.isInitialized || !this._sortHelper) {
            throw new InvalidOperationError('Cannot return sort helper. Call `init` first.');
        }
        return this._sortHelper;
    }

    private _fileSizeFormatHelper?: FileSizeFormatHelper;

    /**
     * Gets the FileSizeFormatHelper.
     * 
     * @readonly
     * @type {FileSizeFormatHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * @memberof CultureHelper
     */
    public get fileSizeFormatHelper(): FileSizeFormatHelper {
        if (!this.isInitialized || !this._fileSizeFormatHelper) {
            throw new InvalidOperationError('Cannot return format helper. Call `init` first.');
        }
        return this._fileSizeFormatHelper;
    }

    private _windreamFsFlagsFormatHelper?: WindreamFsFlagsFormatHelper;

    /**
     * Gets the WindreamFsFlagsFormatHelper.
     * 
     * @readonly
     * @type {WindreamFsFlagsFormatHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * @memberof CultureHelper
     */
    public get windreamFsFlagsFormatHelper(): WindreamFsFlagsFormatHelper {
        if (!this.isInitialized || !this._windreamFsFlagsFormatHelper) {
            throw new InvalidOperationError('Cannot return format helper. Call `init` first.');
        }
        return this._windreamFsFlagsFormatHelper;
    }

    private _VectorFormatter?: VectorFormatter;

    /**
     * Gets the VectorFormatter
     *
     * @readonly
     * @type {VectorFormatter}
     * @memberof CultureHelper
     */
    public get vectorFormatter(): VectorFormatter {
        if (!this.isInitialized || !this._VectorFormatter) {
            throw new InvalidOperationError('Cannot return vector format helper. Call `init` first.');
        }
        return this._VectorFormatter;
    }

    private _windreamStatusFormatHelper?: WindreamStatusFormatHelper;

    /**
     * Gets the WindreamStatusFormatHelper.
     * 
     * @readonly
     * @type {WindreamStatusFormatHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * @memberof CultureHelper
     */
    public get windreamStatusFormatHelper(): WindreamStatusFormatHelper {
        if (!this.isInitialized || !this._windreamStatusFormatHelper) {
            throw new InvalidOperationError('Cannot return format helper. Call `init` first.');
        }
        return this._windreamStatusFormatHelper;
    }

    private _windreamFormatter?: WindreamFormatter;

    /**
     * Gets the WindreamFormatter.
     * 
     * @readonly
     * @type {WindreamFsFlagsFormatHelper}
     * @throws {InvalidOperationError}  Will throw if init() was not called first.
     * @memberof CultureHelper
     */
    public get windreamFormatter(): WindreamFormatter {
        if (!this.isInitialized || !this._windreamFormatter) {
            throw new InvalidOperationError('Cannot return format helper. Call `init` first.');
        }
        return this._windreamFormatter;
    }

    private _browserLanguage?: string;
    /**
     * Gets the browser languages.
     * 
     * @readonly
     * @type {string | null}
     * @memberof CultureHelper
     */
    public get browserLanguage(): string | null {
        return this._browserLanguage || null;
    }

    private _availableLanguages?: string[];
    /**
     * Gets the available languages.
     * 
     * @readonly
     * @type {string[]}
     * @throws {InvalidOperationError}  Will throw if property is not set.
     * @memberof CultureHelper
     */
    public get availableLanguages(): string[] {
        if (!this._availableLanguages) {
            throw new InvalidOperationError('Cannot return available languages.');
        }
        return this._availableLanguages;
    }

    private _userLanguage?: string;
    /**
     * Gets the user language.
     * 
     * @readonly
     * @type {string | null}
     * @memberof CultureHelper
     */
    public get userLanguage(): string | null {
        return this._userLanguage || null;
    }

    private _isInitialized: boolean;
    /**
     * Whether the culutrehelper is initialized or not.
     *
     * @readonly
     * @type {boolean}
     * @memberof CultureHelper
     */
    public get isInitialized(): boolean {
        return this._isInitialized;
    }

    /**
     * Creates an instance of CultureHelper.
     * 
     * @memberof CultureHelper
     */
    public constructor() {
        super();
        this._isInitialized = false;

        this.setCurrentCulture(this.DEFAULT_LANGUAGE);
    }

    /**
     * Sets the language provider and initializes the format helper.
     * 
     * @param {ILanguageProvider} languageProvider LanguageProvider instance to use.
     * 
     * @memberof CultureHelper
     */
    public init(languageProvider: ILanguageProvider): void {
        this._dateTimeFormatHelper = new DateTimeFormatHelper();
        this._dateTimeFormatHelper.setCurrentCulture(this.getCurrentLanguage());
        this._numberFormatHelper = new NumberFormatHelper();
        this._numberFormatHelper.setCurrentCulture(this.getCurrentLanguage());
        this._booleanFormatHelper = new BooleanFormatHelper(languageProvider);
        this._sortHelper = new SortHelper();
        this._fileSizeFormatHelper = new FileSizeFormatHelper();
        this._windreamFsFlagsFormatHelper = new WindreamFsFlagsFormatHelper(languageProvider);
        this._windreamStatusFormatHelper = new WindreamStatusFormatHelper();
        this._windreamFormatter = new WindreamFormatter(this);
        this._VectorFormatter = new VectorFormatHelper(this._windreamFormatter) as VectorFormatter;
        this._isInitialized = true;
    }

    /**
     * Set the currentCulture tag.
     * 
     * @param {string} currentCultureTag
     * 
     * @memberof CultureHelper
     */
    public setCurrentCulture(currentCultureTag: string) {
        super.setCurrentCulture(currentCultureTag);
        this.changeCultureFormat(currentCultureTag);
    }

    /**
     * Sets the available languages.
     * 
     * @param {string[]} languages Available languages.
     * @memberof CultureHelper
     */
    public setAvailableLanguages(languages: string[]): void {
        if (languages.length === 0) {
            throw new InvalidOperationError('At least one language is required');
        }
        this._availableLanguages = languages;
    }

    /**
     * Sets the browser language.
     * 
     * @param {string} language The browser language.
     * @memberof CultureHelper
     */
    public setBrowserLanguage(language: string): void {
        this._browserLanguage = language;
    }

    /**
     * Sets the user language.
     * 
     * @param {string} language The user language.
     * @memberof CultureHelper
     */
    public setUserLanguage(language: string): void {
        this._userLanguage = language;
    }

    /**
     * Returns the current language to be used.
     * Takes into account the available languages in the following order:
     * 1. user configured language (if part of available languages)
     * 2. browser langauge (if part of available languages)
     * 3. fallback language (first defined available language)
     * 4. default language
     * 
     * @returns {string} The current language to be used as 2 character string, e.g. "en", "de".
     * @memberof CultureHelper
     */
    public getCurrentLanguage(): string {
        let currentLanguage: string;
        const fallbackLanguage = this.availableLanguages[0];
        if (this.userLanguage && this.availableLanguages.indexOf(this.userLanguage) !== -1) { // User setting
            currentLanguage = this.userLanguage;
        } else if (this.browserLanguage && this.availableLanguages.indexOf(this.browserLanguage) !== -1) { // Browser language
            currentLanguage = this.browserLanguage;
        } else if (fallbackLanguage) { // Fallback language defined in available languages
            currentLanguage = fallbackLanguage;
        } else { // Default language
            currentLanguage = this.DEFAULT_LANGUAGE;
        }

        return currentLanguage;
    }

    /**
     * Gets the currency code by the given culture string.
     *
     * @param {string} culture
     * @returns {string}
     * @memberof CultureHelper
     */
    public getCurrencyCodeByCulture(culture: string): string {
        if (!culture) {
            return this.DEFAULT_CURRENCY_CODE;
        }

        // TODO: Support more codes.
        switch (culture.toLowerCase()) {
            case 'en-us': return 'USD';
            default: return this.DEFAULT_CURRENCY_CODE;
        }
    }

    /**
     * Changes the culture format for all helper.
     * 
     * @private
     * @param {string} currentCultureTag
     * 
     * @memberof CultureHelper
     */
    private changeCultureFormat(currentCultureTag: string) {
        if (this.isInitialized) {
            if (this._dateTimeFormatHelper) {
                this._dateTimeFormatHelper.setCurrentCulture(currentCultureTag);
            }
            if (this._numberFormatHelper) {
                this._numberFormatHelper.setCurrentCulture(currentCultureTag);
            }
        }
    }
}