import { CultureInfo } from './cultureInfo';
/**
 * This class provides extension methods for formatting.
 * 
 * @export
 * @abstract
 * @class FormatHelper
 * @extends {CultureInfo}
 */
export abstract class FormatHelper extends CultureInfo {

}
