import { IComponent } from '../loader';
import { OnAfterBind, OnAfterRender, OnBind, OnDestroy, OnExtension, OnInit, OnRefresh, OnRender, OnSkeleton, OnSubViewChanged, OnSubViewChanging } from './lifecycleHooks';
import { LIFECYCLE_HOOKS, OnToolbar } from '.';

/**
 * Required for LifecycleReflector.
 */
// eslint-disable-next-line no-redeclare
export const Type = Function;
// eslint-disable-next-line no-redeclare
export interface Type<T> extends Function { new(...args: any[]): T; }

/**
 * The LifecycleReflector provides the functionality to check whether a component implemented a life cycle hook or not.
 * @version 1.0
 * @access public
 * @example 
 * class test implements OnBind {
 *      public wdOnBind(): void {
 *          alert("test");
 *      }
 * }
 * //Check if OnBind is implemented.
 * let result: boolean = LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnBind, test);
 */
export class LifecycleReflector {
    private static lifecycleInterfaces: InterfaceMap;
    private static lifecycleProperties: InterfacePropertiesMap;
    private static hooksAreMapped: boolean = false;
    /**
     * HasLifecycleHook checks whether a hook was correctly implemented.
     * @param hook {LIFECYCLE_HOOKS} The hook, which should be implemented into the token.
     * @param token {object} The token, which shall be checked. 
     * @returns {boolean} If the hook is implemented or not.
     */
    public static hasLifecycleHook(hook: LIFECYCLE_HOOKS, token: IComponent): boolean {
        this.initMapping();
        const lcProperty = this.lifecycleProperties[hook];
        return LifecycleReflector.hasFunction(token, lcProperty);
    }

    /**
     * Checks whether a hook was correctly implemented.
     * @param type {object} The component, which should have implemented the desired hook.
     * @param lcInterface {Type<any>} The interface, which provides the hook.
     * @param lcProperty {stirng} The property name of the hook.
     * @returns {boolean} If the hook is implemented or not.
     */
    public static hasFunction(type: IComponent, lcProperty: string): boolean {
        let retValue: boolean = false;
        if (type instanceof Type) {
            retValue = true;
        }
        // @ts-ignore - TODO: Check index signature
        if (typeof (type[lcProperty]) === 'function') {
            retValue = true;
        }
        return retValue;
    }

    /**
     * Init the Mapping between abstact hooks and property name.
     */
    private static initMapping() {
        if (this.hooksAreMapped === false) {
            if (!this.lifecycleInterfaces) {
                this.lifecycleInterfaces = {};
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnInit] = typeof (OnInit);
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnSkeleton] = OnSkeleton;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnBind] = OnBind;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnAfterBind] = OnAfterBind;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnRender] = OnRender;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnToolbar] = OnToolbar;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnAfterRender] = OnAfterRender;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnExtension] = OnExtension;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnRefresh] = OnRefresh;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnDestroy] = OnDestroy;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnSubViewChanged] = OnSubViewChanged;
                this.lifecycleInterfaces[LIFECYCLE_HOOKS.OnSubViewChanging] = OnSubViewChanging;
            }
            if (!this.lifecycleProperties) {
                this.lifecycleProperties = {};
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnInit] = 'wdOnInit';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnSkeleton] = 'wdOnSkeleton';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnBind] = 'wdOnBind';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnAfterBind] = 'wdOnAfterBind';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnRender] = 'wdOnRender';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnToolbar] = 'wdOnToolbar';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnAfterRender] = 'wdOnAfterRender';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnExtension] = 'wdOnExtension';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnRefresh] = 'wdOnRefresh';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnDestroy] = 'wdOnDestroy';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnSubViewChanged] = 'wdOnSubViewChanged';
                this.lifecycleProperties[LIFECYCLE_HOOKS.OnSubViewChanging] = 'wdOnSubViewChanging';
            }
            this.hooksAreMapped = true;
        }
    }
}

/**
 * Required for LifecycleReflector.
 */
interface InterfaceMap {
    [indexIdentifier: number]: any;
}
/**
 * Required for LifecycleReflector.
 */
interface InterfacePropertiesMap {
    [indexIdentifier: number]: string;
}