import { DWCore } from '@windream/dw-core/dwCore';

export class ComponentConfigToolbar {
  /**
   * Represents all default toolbar actions for this component.
   *
   * @type {string[]}
   * @memberof ComponentConfigToolbar
   */
  public defaultToolbarActions?: string[];

  /**
   * Represents all supported toolbar action types for this component.
   *
   * @type {string[]}
   * @memberof ComponentConfigToolbar
   */
  public supportedToolbarActionTypes?: string[];

  /**
   * Represents all supported toolbar action types for this component.
   *
   * @type {DWCore.Components.ToolbarActionDisplayMode}
   * @memberof ComponentConfigToolbar
   */
  public toolbarDisplayOption?: DWCore.Components.ToolbarActionDisplayMode;
}
