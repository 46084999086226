import { FullRootlineWSObjectIdentityDTO } from '../../../typings/windreamWebService/Windream.WebService.Navigation';
import { ObjectType } from '../../caching';
import { WindreamEntity } from '../../common';
import { WindreamIdentity } from '../windreamIdentity';

/**
 * Represents the rootline item of a fetch nodes call for a given identity.
 *
 * @export
 * @class RootlineItem
 */
export class RootlineItem extends WindreamIdentity {
    /**
     * Child items of the identity.
     *
     * @type {WindreamIdentity[]}
     * @memberof RootlineItem
     */
    public children: WindreamIdentity[];

    /**
     * Object type of the element.
     *
     * @type {ObjectType}
     * @memberof RootlineItem
     */
    public objectType: ObjectType;

    /**
     * Creates an instance of RootlineItem.
     *
     * @param {ObjectType} objectType
     * @memberof RootlineItem
     */
    public constructor(objectType: ObjectType) {
        super();
        this.objectType = objectType;
        this.children = new Array<WindreamIdentity>();
    }

    /**
     * Creates an instance from the given DTO.
     *
     * @static
     * @param {FullRootlineWSObjectIdentityDTO} dto DTO to create from.
     * @returns {RootlineItem}
     * @memberof RootlineItem
     */
    public static fromDto(dto: FullRootlineWSObjectIdentityDTO): RootlineItem {
        const result = new RootlineItem(new ObjectType(dto.ObjectType.Id, dto.ObjectType.Name, WindreamEntity.DocumentAndMap));
        result.id = dto.Id;
        result.name = dto.Name;
        result.location = dto.Location;
        result.entity = dto.Entity as number;
        if (dto.Children) {
            result.children = dto.Children.map((item) => WindreamIdentity.fromDto(item));
        }
        return result;
    }
}