import { AuthenticationModes, IAuthenticationManager, ICredentialManager, WindreamToken } from '../authentication/index';
import { UserCredentials } from '../authentication/userCredentials';
import { UserDetails } from '../authentication/userDetails';
import { Utils } from '../common';
import { Base64 } from '../common/base64';
import { RequestOptions } from '../dataProviders/requestOptions';
import { EncryptionHelper } from '../encryption/index';
import { IIndexedDBWrapper } from '../indexedDB';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { RouteManager } from '../router';
import { GetAuthenticationToken } from '../services/authentication/getAuthenticationToken';
import { BaseRequestOptions, GetStorageToken, GetSystemDetails, Login, TokenRequestOptions } from '../services/index';
import { StaticPageHelper } from '../staticPage';
import { IStorage } from '../storage/index';
import { INotificationHelper } from '../ui';
import { Version } from '../version';
import { IWebBridgeHandler, WebBridgeEventTypes } from '../webBridge/index';
import { BaseLoginMask } from './index';

/**
 * Provides the normal windream login mask.
 *
 * @export
 * @class WindreamLoginMask
 * @extends {BaseLoginMask}
 */
export class WindreamLoginMask extends BaseLoginMask {
    private jQueryStatic: JQueryStatic;
    private targetElement?: HTMLElement;
    private currentToken?: WindreamToken;
    private loginCallbackCollection: ((userDetails: UserDetails, encryptionSecret?: string) => void)[];
    private currentUserName: string = '';
    private currentPassword: string = '';
    private rememberLogin: boolean = false;
    private credentialManager: ICredentialManager;
    private loginService: Login;
    private tokenService: GetAuthenticationToken;
    private authenticationManager: IAuthenticationManager;
    private storageWrapper: IStorage;
    private languageProvider: ILanguageProvider;
    private logger: Logger;
    private webBridge: IWebBridgeHandler;
    private getSystemDetails: GetSystemDetails;
    private currentDomain: string = '';
    private webServiceDomain: string = '';
    private notificationHelper: INotificationHelper;
    private getStorageTokenAction: GetStorageToken;
    private readonly TOKEN_STORAGE_KEY = 'DynamicWorkspace-Token';
    private readonly STORAGE_ENCRYPTION_KEY = 'DynamicWorkspace-StorageKey';
    private readonly USER_DETAILS_KEY = 'DynamicWorkspace-UserDetails';
    private readonly CLASS_NAME = 'WindreamLoginMask';
    private encryptionHelper: EncryptionHelper;
    private indexedDBWrapper: IIndexedDBWrapper;
    private staticPageHelper: StaticPageHelper;

    /**
     * Creates an instance of WindreamLoginMask.
     * @param {Login} loginAction
     * @param {GetAuthenticationToken} tokenAction
     * @param {IAuthenticationManager} authenticationManager
     * @param {ILanguageProvider} languageProvider
     * @param {JQueryStatic} jQueryStatic
     * @param {ICredentialManager} credentialManager
     * @param {IStorage} storageWrapper
     * @param {Logger} logger
     * @param {IWebBridgeHandler} webBridge
     * @param {GetSystemDetails} getSystemDetails
     * @param {INotificationHelper} notificationHelper
     * @param {EncryptionHelper} encryptionHelper
     * @param {GetStorageToken} getStorageTokenAction
     * @param {IIndexedDBWrapper} indexedDBWrapper
     * @param {StaticPageHelper} staticPageHelper
     * @memberof WindreamLoginMask
     */
    public constructor(loginAction: Login, tokenAction: GetAuthenticationToken, authenticationManager: IAuthenticationManager,
        languageProvider: ILanguageProvider, jQueryStatic: JQueryStatic, credentialManager: ICredentialManager, storageWrapper: IStorage, logger: Logger,
        webBridge: IWebBridgeHandler, getSystemDetails: GetSystemDetails, notificationHelper: INotificationHelper, encryptionHelper: EncryptionHelper,
        getStorageTokenAction: GetStorageToken, indexedDBWrapper: IIndexedDBWrapper, staticPageHelper: StaticPageHelper) {
        super();
        this.jQueryStatic = jQueryStatic;
        this.loginCallbackCollection = new Array<(userDetails: UserDetails, encryptionString?: string) => void>();
        this.loginService = loginAction;
        this.credentialManager = credentialManager;
        this.authenticationManager = authenticationManager;
        this.storageWrapper = storageWrapper;
        this.tokenService = tokenAction;
        this.languageProvider = languageProvider;
        this.logger = logger;
        this.webBridge = webBridge;
        this.getSystemDetails = getSystemDetails;
        this.notificationHelper = notificationHelper;
        this.encryptionHelper = encryptionHelper;
        this.getStorageTokenAction = getStorageTokenAction;
        this.indexedDBWrapper = indexedDBWrapper;
        this.staticPageHelper = staticPageHelper;
    }

    /**
     * Render the login mask as page.
     *
     * @param {HTMLElement} targetElement The target for the login mask.
     * @returns {Promise<boolean>} A promise which will resolve with true if rendering occured or false if auto-login occured thus no rendering needed.
     * @memberof WindreamLoginMask
     */
    public async renderPage(targetElement: HTMLElement): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.checkSystemDetails().then(() => {
                this.getCredentialsFromStorage().then((storageDataStillValid) => {
                    if (!storageDataStillValid) {
                        const defaultAuthenticationMode = this.authenticationManager.getDefaultAuthenticationMode();
                        if (defaultAuthenticationMode === AuthenticationModes.Windows) {
                            // Login via windows authentication.
                            this.login(null, false).then((success) => {
                                if (!success) {
                                    this.authenticationManager.setCurrentAuthenticationMode(AuthenticationModes.JWT);
                                    this.showLoginMask(targetElement, typeof storageDataStillValid !== 'boolean').then(() => {
                                        resolve(true);
                                    }).catch((error) => {
                                        reject(error);
                                    });
                                } else {
                                    resolve(false);
                                }
                            }).catch(() => {
                                this.authenticationManager.setCurrentAuthenticationMode(AuthenticationModes.JWT);
                                this.showLoginMask(targetElement, typeof storageDataStillValid !== 'boolean').then(() => {
                                    resolve(true);
                                }).catch((error) => {
                                    reject(error);
                                });
                            });
                        } else {
                            this.showLoginMask(targetElement, typeof storageDataStillValid !== 'boolean').then(() => {
                                resolve(true);
                            }).catch((error) => {
                                reject(error);
                            });
                        }
                    } else {
                        resolve(false);
                    }
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    /**
     * Render the session timeout mask as popup.
     *
     * @param {HTMLElement} targetElement The target for the login mask.
     * @returns {Promise<void>}
     * @memberof WindreamLoginMask
     */
    public async renderSessionTimeoutPopup(targetElement: HTMLElement): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // Don't use credentials from storage since the popup is only used for session timeouts
            const defaultAuthenticationMode = this.authenticationManager.getDefaultAuthenticationMode();
            if (defaultAuthenticationMode === AuthenticationModes.Windows) {
                // Login via windows authentication.
                this.login(null, false).then((success) => {
                    if (!success) {
                        this.showLoginMask(targetElement, false, true).then(() => {
                            resolve();
                        }).catch((error) => {
                            reject(error);
                        });
                    } else {
                        resolve();
                    }
                }).catch(() => {
                    this.showLoginMask(targetElement, false, true).then(() => {
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                });
            } else {
                this.showLoginMask(targetElement, false, true).then(() => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }
    /**
     * Register a callback which will be called after successful login.
     *
     * @param {(userCredentials: UserDetails, encryptionSecret?: string) => void}callback The callback to call.
     * @memberof WindreamLoginMask
     */
    public registerLoginCallback(callback: (userDetails: UserDetails, encryptionSecret?: string) => void): void {
        this.loginCallbackCollection.push(callback);
    }

    /**
     * Destory the current login mask.
     *
     * @memberof WindreamLoginMask
     */
    public destroy(): void {
        this.currentUserName = '';
        this.currentPassword = '';
        if (this.targetElement) {
            this.targetElement.innerHTML = '';
        }
    }

    /**
     * Logout permanently from the application.
     *
     * @returns {Promise<boolean>}
     * @memberof WindreamLoginMask
     */
    public async logout(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            // Don't catch erros because its only the indexed database.
            this.deleteCredentialsFromStorage().then(() => resolve(true)).catch(() => resolve(true));
        });
    }

    /**
     * Render the login mask.
     *
     * @private
     * @param {HTMLElement} targetElement The target element to render into.
     * @param {boolean} isOffline Whether the login/browser is offline.
     * @param {boolean} [isInSessionTimeoutPopup] Whether the login mask is in a session timeout popup.
     * @memberof WindreamLoginMask
     */
    private renderLoginMask(targetElement: HTMLElement, isOffline: boolean, isInSessionTimeoutPopup?: boolean): void {
        this.targetElement = targetElement;
        this.destroy();
        const animationDuration = 300;
        // Diabled because we need to declare it here so it can be referenced later
        // eslint-disable-next-line prefer-const
        let markFormAsValid: () => void | undefined;
        const loginRootContainer = isInSessionTimeoutPopup ? this.jQueryStatic('<div class="wd-login-as-popup"></div>') :
            this.jQueryStatic('<div class="static-page"></div>');
        const groupBox = this.jQueryStatic('<div class="wd-login-box"></div>');
        const loadPanel = this.jQueryStatic('<div></div>').dxLoadPanel({
            shadingColor: 'rgba(0,0,0,0.1)',
            position: { of: '.wd-login-box' },
            visible: false,
            showIndicator: true,
            showPane: true,
            shading: true,
            closeOnOutsideClick: false
        });
        const domainIndicator = this.jQueryStatic('<div class="wd-login-box-domain-group-indicator"></div>').text(this.currentDomain).attr('title', this.currentDomain);
        const loginButton = this.jQueryStatic(this.jQueryStatic('<div></div>')).dxButton({
            text: this.languageProvider.get('framework.login.signInButton.title'),
            stylingMode: 'contained',
            type: 'default',
            useSubmitBehavior: true,
            disabled: true
        }).addClass('wd-login-box-remember-group-button');
        const signInHeader = this.jQueryStatic('<h2 class="wd-login-box-header"></h2>').text(this.languageProvider.get('framework.login.header'));
        const sessionTimeoutCallout = this.jQueryStatic('<div class="wd-login-session-timeout-callout callout warning"></div>').text(this.languageProvider.get('framework.login.session.timeout'));
        const loginRememberButtonGroup = this.jQueryStatic('<div class="wd-login-box-remember-group"></div>');
        const rememberDetailsCheckbox = this.jQueryStatic(this.jQueryStatic('<div class="wd-login-box-remember-credentials-checkbox wd-login-box-remember-group-button"></div>')).dxCheckBox({
            text: this.languageProvider.get('framework.login.rememberCheckBox.label'),
            onValueChanged: (data) => {
                this.rememberLogin = data.value;
            }
        });
        const userNameSpan = this.jQueryStatic('<span class="wd-login-box-textbox-span"></span>').text(this.languageProvider.get('framework.login.username.label'));
        const userNameInput = this.jQueryStatic(this.jQueryStatic('<div></div>')).dxTextBox({
            valueChangeEvent: 'change input',
            showClearButton: true,
            onValueChanged: (data) => {
                this.currentUserName = data.value;
                const indexOfBackSlash = this.currentUserName.indexOf('\\');
                if (indexOfBackSlash > 0 && this.currentUserName.indexOf('.\\') !== 0) {
                    this.currentDomain = this.currentUserName.substring(0, indexOfBackSlash);
                } else {
                    this.currentDomain = this.webServiceDomain;
                }
                domainIndicator.text(this.currentDomain).attr('title', this.currentDomain);
                this.jQueryStatic(loginButton).dxButton({
                    disabled: Utils.Instance.isNullOrEmptyString(this.currentUserName) || Utils.Instance.isNullOrEmptyString(this.currentPassword)
                });
                if (markFormAsValid) {
                    markFormAsValid();
                }
            }
        }).addClass('wd-login-box-textbox');
        if (isInSessionTimeoutPopup && this.currentToken) {
            this.jQueryStatic(userNameInput).dxTextBox({
                value: this.currentToken.header.username,
                readOnly: true
            });
        }
        const passwordSpan = this.jQueryStatic('<span class="wd-login-box-textbox-span"></span>').text(this.languageProvider.get('framework.login.password.label'));
        const passwordInput = this.jQueryStatic(this.jQueryStatic('<div class="wd-login-box-password-input"></div>')).dxTextBox({
            mode: 'password',
            buttons: [{
                name: 'password',
                location: 'after',
                options: {
                    icon: 'wd-icon eye-slashed',
                    type: 'default',
                    hint: this.languageProvider.get('framework.login.password.eye.shown'),
                    stylingMode: 'text',
                    onClick: (event) => {
                        const passwordInputBox = this.jQueryStatic(passwordInput).dxTextBox('instance');
                        passwordInputBox.option('mode', passwordInputBox.option('mode') === 'text' ? 'password' : 'text');
                        if (event.component) {
                            const isInTextMode = passwordInputBox.option('mode') === 'text';
                            event.component.option('icon', isInTextMode ? 'wd-icon eye' : 'wd-icon eye-slashed');
                            event.component.option('hint', isInTextMode ? this.languageProvider.get('framework.login.password.eye.hidden')
                                : this.languageProvider.get('framework.login.password.eye.shown'));
                        }
                    }
                }
            }, 'clear'],
            valueChangeEvent: 'change input',
            showClearButton: true,
            onValueChanged: (data) => {
                this.currentPassword = data.value;
                this.jQueryStatic(loginButton).dxButton({
                    disabled: Utils.Instance.isNullOrEmptyString(this.currentUserName) || Utils.Instance.isNullOrEmptyString(this.currentPassword)
                });
                if (markFormAsValid) {
                    markFormAsValid();
                }
            }
        }).addClass('wd-login-box-textbox');
        const errorContainer = this.jQueryStatic('<div class="wd-login-box-error-container"></div>').hide();
        const errorText = this.jQueryStatic('<p class="wd-login-invalid"></p>').text(this.languageProvider.get('framework.login.error.general'));
        const howToSignInToOtherDomainContainer = this.jQueryStatic('<div class="wd-login-box-domain-tutorial-container"></div>');
        const howToSignInToOtherDomainTutorialText = this.jQueryStatic('<p class="wd-login-box-domain-tutorial-text"></p>')
            .text(this.languageProvider.get('framework.login.domain.tutorial.text')).hide();
        const howToSignInToOtherDomainTutorialDomainExample = this.jQueryStatic('<b></b>').text(this.languageProvider.get('framework.login.domain.tutorial.format'));
        howToSignInToOtherDomainTutorialText.append(howToSignInToOtherDomainTutorialDomainExample);
        const howToSignInToOtherDomain = this.jQueryStatic('<a href class="wd-login-box-domain-tutorial-link"></a>')
            .text(this.languageProvider.get('framework.login.domain.tutorial.label')).on('click', (e: any) => {
                e.preventDefault();
                howToSignInToOtherDomainTutorialText.slideToggle(animationDuration);
            });
        howToSignInToOtherDomainContainer.append([howToSignInToOtherDomain, howToSignInToOtherDomainTutorialText]);
        const domainGroup = this.jQueryStatic('<div class="wd-login-box-domain-group"></div>');
        const domainLabel = this.jQueryStatic('<div class="wd-login-box-textbox-span wd-login-box-domain-group-label"></div>').text(this.languageProvider.get('framework.login.domain.label'));
        const markFormAsInvalid = (serverProblem: boolean) => {
            let currentErrorText = this.languageProvider.get('framework.login.error.general');
            if (serverProblem) {
                if (navigator && navigator.onLine) {
                    currentErrorText = this.languageProvider.get('framework.login.error.internalError');
                    this.notificationHelper.error({ body: this.languageProvider.get('framework.login.error.toast.internalError') });
                } else {
                    currentErrorText = this.languageProvider.get('framework.login.error.network');
                    this.notificationHelper.error({ body: this.languageProvider.get('framework.login.error.toast.network') });
                }
            } else {
                userNameSpan.addClass('wd-login-invalid');
                passwordSpan.addClass('wd-login-invalid');
                this.jQueryStatic(userNameInput).dxTextBox({ isValid: false });
                this.jQueryStatic(passwordInput).dxTextBox({ isValid: false });
            }
            this.jQueryStatic(errorText).text(currentErrorText);
            errorContainer.show(animationDuration);
            this.jQueryStatic(loginButton).dxButton({
                disabled: Utils.Instance.isNullOrEmptyString(this.currentUserName) || Utils.Instance.isNullOrEmptyString(this.currentPassword)
            });
        };
        markFormAsValid = () => {
            if (!this.jQueryStatic(userNameInput).dxTextBox('instance').option('isValid') ||
                !this.jQueryStatic(userNameInput).dxTextBox('instance').option('isValid')) {
                userNameSpan.removeClass('wd-login-invalid');
                passwordSpan.removeClass('wd-login-invalid');
                this.jQueryStatic(userNameInput).dxTextBox({ isValid: true });
                this.jQueryStatic(passwordInput).dxTextBox({ isValid: true });
                errorContainer.hide(animationDuration);
            }
        };
        const loginForm = this.jQueryStatic('<form name="login"></form>').on('submit', (e: any) => {
            e.preventDefault();
            if (!Utils.Instance.isNullOrEmptyString(this.currentUserName) && !Utils.Instance.isNullOrEmptyString(this.currentPassword)) {
                const credentials = new UserCredentials();
                if (isInSessionTimeoutPopup) {
                    credentials.staySignedIn = !!this.storageWrapper.getItem(this.USER_DETAILS_KEY);
                } else {
                    credentials.staySignedIn = this.rememberLogin;
                }
                const username = this.addDomainToUsername(this.currentUserName);
                credentials.username = username;
                credentials.authenticationToken = this.createLoginToken(username, this.currentPassword);
                this.jQueryStatic(loginButton).dxButton({
                    disabled: true
                });
                this.jQueryStatic(loadPanel).dxLoadPanel('instance').show();
                this.login(credentials, isOffline).then((success) => {
                    this.jQueryStatic(loadPanel).dxLoadPanel('instance').hide();
                    if (!Utils.Instance.isDefined(success)) {
                        markFormAsInvalid(true);
                    } else if (!success) {
                        // Throw errors
                        markFormAsInvalid(false);
                    } else {
                        this.destroy();
                    }
                }).catch((error) => {
                    this.logger.error(this.CLASS_NAME, 'renderLoginMask', 'Can not login:', error);
                });
            }
        });
        errorContainer.append(errorText);
        domainGroup.append([domainLabel, domainIndicator]);
        if (isInSessionTimeoutPopup) {
            const signInAsOtherUser = this.jQueryStatic(this.jQueryStatic('<div></div>')).dxButton({
                text: this.languageProvider.get('framework.login.signInAsOtherUser.title'),
                stylingMode: 'outlined',
                onClick: () => {
                    this.logout().then(() => {
                        if (location) {
                            location.reload();
                        }
                    }).catch((error) => {
                        this.logger.error(this.CLASS_NAME, 'renderLoginMask', 'Can\'t login as another user', error);
                    });
                }
            }).addClass('wd-login-box-remember-group-button wd-login-as-another-user-button');
            loginRememberButtonGroup.append([loginButton, signInAsOtherUser]);
            loginRememberButtonGroup.addClass('wd-login-box-remember-group-session-timeout');
            loginForm.append([signInHeader, sessionTimeoutCallout, userNameSpan, userNameInput, passwordSpan, passwordInput, errorContainer, domainGroup,
                loginRememberButtonGroup]);
        } else {
            loginRememberButtonGroup.append([rememberDetailsCheckbox, loginButton]);
            loginForm.append([signInHeader, userNameSpan, userNameInput, passwordSpan, passwordInput, errorContainer, domainGroup,
                howToSignInToOtherDomainContainer, loginRememberButtonGroup]);
        }

        groupBox.append(loginForm);

        if (!isInSessionTimeoutPopup) {
            const linkBottomContainer = this.jQueryStatic('<div class="wd-login-box-link-footer"></div>');

            const settingsLink = this.jQueryStatic('<a>', {
                text: this.languageProvider.get('framework.navigation.settingsButton.title'),
                title: this.languageProvider.get('framework.navigation.settingsButton.title'),
                href: '/settings',
                click: (event: Event) => {
                    event.preventDefault();
                    RouteManager.navigate('/settings?origin=landingPageLink');
                }
            });
            const aboutLink = this.jQueryStatic('<a>', {
                text: this.languageProvider.get('framework.navigation.aboutButton.title'),
                title: this.languageProvider.get('framework.navigation.aboutButton.title'),
                href: '/about',
                click: (event: Event) => {
                    event.preventDefault();
                    RouteManager.navigate('/about?origin=landingPageLink');
                }
            });
            linkBottomContainer.append([settingsLink, aboutLink]);
            groupBox.append(linkBottomContainer);
        }

        loginRootContainer.append([loadPanel, groupBox]);
        this.jQueryStatic(targetElement).append(loginRootContainer);
        // Set focus to username
        this.jQueryStatic(userNameInput).dxTextBox('instance').focus();
    }

    /**
     * Adds the domain to the username if necessary.
     *
     * @private
     * @param {string} userName The username.
     * @returns {string} The username with domain.
     * @memberof WindreamLoginMask
     */
    private addDomainToUsername(userName: string): string {
        const specialDomainIndicator = '.\\';
        let newUserName: string;
        if (userName.indexOf('\\') > -1 && userName.indexOf(specialDomainIndicator) !== 0) {
            newUserName = userName;
        } else if (userName.indexOf(specialDomainIndicator) === 0 && !Utils.Instance.isNullOrEmptyString(this.currentDomain)) {
            newUserName = this.currentDomain + '\\' + userName.substring(specialDomainIndicator.length);
        } else if (!Utils.Instance.isNullOrEmptyString(this.currentDomain)) {
            newUserName = this.currentDomain + '\\' + userName;
        } else {
            newUserName = userName;
        }
        return newUserName;
    }

    /**
     *  Display the login mask.
     *
     * @private
     * @param {HTMLElement} targetElement The target to render into.
     * @param {boolean} isOffline Whether the login/browser is offline.
     * @param {boolean} [isInPopup] Whether the mask is in a popup or not.
     * @returns {Promise<void>}
     * @memberof WindreamLoginMask
     */
    private async showLoginMask(targetElement: HTMLElement, isOffline: boolean, isInPopup?: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            this.renderLoginMask(targetElement, isOffline, isInPopup);
            resolve();
        });
    }

    /**
     * Login into the application.
     *
     * @private
     * @param {UserCredentials | null} credentials The credentials to try logging in or null if NTLM.
     * @param {boolean} isOffline Whether the login process should be done offline.
     * @returns {Promise<boolean | null>} Return a boolean when the login was a success or fail and null if an error occurred (network problem etc.)
     * @memberof WindreamLoginMask
     */
    private async login(credentials: UserCredentials | null, isOffline: boolean): Promise<boolean | null> {
        if (isOffline) {
            // Authenticate against the local encryption since we are offline
            return new Promise<boolean | null>((resolve) => {
                const secretKeyFromStorage = this.getStorageEncryptionKeyFromStorage();
                const userDataFromStorage = this.storageWrapper.getItem(this.USER_DETAILS_KEY);
                if (secretKeyFromStorage && userDataFromStorage) {
                    try {
                        const decryptedStorageDetails = this.encryptionHelper.decryptAES(userDataFromStorage, secretKeyFromStorage);
                        this.loginCallbackCollection.forEach((callback) => {
                            callback(UserDetails.deserialize(decryptedStorageDetails), secretKeyFromStorage);
                        });
                        resolve(true);
                    } catch {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            });
        } else {
            return new Promise<boolean | null>((resolve) => {
                if (credentials) {
                    this.credentialManager.setCredentials(credentials);
                }
                const loginRequestOptions = new BaseRequestOptions();
                const requestOptions = new RequestOptions();
                requestOptions.authenticationMode = credentials ? AuthenticationModes.Basic : AuthenticationModes.Windows;
                loginRequestOptions.requestOptions = requestOptions;
                this.loginService.do(loginRequestOptions).then((userDetails) => {
                    if (userDetails.isValidUser) {
                        this.tokenService.do(loginRequestOptions).then((token) => {
                            userDetails.token = token;
                            this.currentToken = new WindreamToken(token);
                            this.getStorageEncryptionKeyFromService(loginRequestOptions).then((storageToken) => {
                                this.saveOfflineStorageEncryptionKey(storageToken);
                                if (userDetails.token) {
                                    this.webBridge.publish(WebBridgeEventTypes.TokenUpdate, userDetails.token);
                                }
                                // eslint-disable-next-line promise/no-callback-in-promise
                                this.loginCallbackCollection.forEach((callback) => callback(userDetails, storageToken));
                                if (credentials && credentials.staySignedIn) {
                                    this.saveCredentialsToStorage(this.encryptionHelper.encryptAES(JSON.stringify(userDetails), storageToken), userDetails.token);
                                }
                                resolve(true);
                            }).catch((error) => {
                                this.logger.error(this.CLASS_NAME, 'login', 'Error fetching storage token', error);
                                this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                                resolve(null);
                            });
                        }).catch(() => {
                            this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                            resolve(null);
                        });
                    } else {
                        this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                        resolve(false);
                    }
                }).catch(() => {
                    this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                    resolve(null);
                });
            });
        }
    }

    /**
     * Refresh the token and check if it's still valid.
     *
     * @private
     * @param {string} token The token to check
     * @returns {(Promise<boolean | null>)} Whether the token is valid or not.
     * @memberof WindreamLoginMask
     */
    private async refreshToken(token: string): Promise<boolean | null> {
        return new Promise<boolean | null>((resolve) => {
            const loginRequestOptions = new TokenRequestOptions();
            const requestOptions = new RequestOptions();
            requestOptions.authenticationMode = AuthenticationModes.Anonymous;
            requestOptions.preventFetchFromCache = true;
            loginRequestOptions.requestOptions = requestOptions;
            loginRequestOptions.token = token;
            this.loginService.do(loginRequestOptions).then((userDetails) => {
                if (userDetails.isValidUser) {
                    this.tokenService.do(loginRequestOptions).then((token) => {
                        userDetails.token = token;
                        this.currentToken = new WindreamToken(token);
                        this.getStorageEncryptionKeyFromService(loginRequestOptions).then((storageToken) => {
                            // Overwrite global settings if a token is still stored, we will be using it instead
                            this.authenticationManager.setCurrentAuthenticationMode(AuthenticationModes.JWT);
                            if (userDetails.token) {
                                this.webBridge.publish(WebBridgeEventTypes.TokenUpdate, userDetails.token);
                            }
                            // eslint-disable-next-line promise/no-callback-in-promise
                            this.loginCallbackCollection.forEach((callback) => callback(userDetails, storageToken));
                            this.saveCredentialsToStorage(this.encryptionHelper.encryptAES(JSON.stringify(userDetails), storageToken), userDetails.token);
                            resolve(true);
                        }).catch((error) => {
                            this.logger.error(this.CLASS_NAME, 'refreshToken', 'Error fetching storage token', error);
                            this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                            resolve(null);
                        });
                    }).catch(() => {
                        this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                        resolve(null);
                    });
                } else {
                    this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                    resolve(false);
                }
            }).catch(() => {
                this.webBridge.publish(WebBridgeEventTypes.LoginFailed, 'LoginFailed');
                resolve(null);
            });
        });
    }

    /**
     * Create the login token in order to login.
     *
     * @private
     * @param {string} username The username.
     * @param {string} password The password.
     * @returns {string} The token.
     * @memberof WindreamLoginMask
     */
    private createLoginToken(username: string, password: string): string {
        const base64Helper = new Base64();
        const rawCredentials: string = username + ':' + password;
        return base64Helper.encode(rawCredentials);
    }

    /**
     * Check whether the browser has internet or not.
     *
     * @private
     * @param {Navigator} navigator The navigator.
     * @returns {boolean} Whether the browser has internet or not.
     * @memberof WindreamLoginMask
     */
    private isOnline(navigator: Navigator): boolean {
        if (!Utils.Instance.isDefined(navigator)) {
            return false;
        }
        return navigator.onLine;
    }

    /**
     * Gets the saved credentials from storage.
     *
     * @private
     * @returns {Promise<boolean>}
     * @memberof WindreamLoginMask
     */
    private async getCredentialsFromStorage(): Promise<boolean | null> {
        return new Promise<boolean | null>((resolve) => {
            // Windows Authentication can not use saved credentials
            if (this.authenticationManager.getDefaultAuthenticationMode() === AuthenticationModes.Windows) {
                this.deleteCredentialsFromStorage().then(() => resolve(false)).catch(() => resolve(false));
                return;
            }
            const savedToken = this.storageWrapper.getItem(this.TOKEN_STORAGE_KEY);
            if (savedToken) {
                if (!this.isOnline(navigator)) {
                    // Offline
                    resolve(null);
                } else if (typeof savedToken === 'string') {
                    this.refreshToken(savedToken).then((success) => {
                        if (success === null) {
                            if (savedToken) {
                                // Server not available render offline mode
                                resolve(null);
                            } else {
                                this.deleteCredentialsFromStorage().then(() => resolve(false)).catch(() => resolve(false));
                            }
                        } else {
                            if (!success) {
                                this.deleteCredentialsFromStorage().then(() => resolve(success)).catch(() => resolve(success));
                            } else {
                                resolve(success);
                            }
                        }
                    }).catch((error) => {
                        this.logger.error(this.CLASS_NAME, 'getCredentialsFromStorage', 'Can not auto login:', error);
                        this.deleteCredentialsFromStorage().then(() => resolve(false)).catch(() => resolve(false));
                    });
                } else {
                    this.logger.error(this.CLASS_NAME, 'getCredentialsFromStorage', 'Can not auto login: UserDetails missing a token');
                    resolve(false);

                }
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Save the credentials into the storage.
     *
     * @private
     * @param {string} userDetails The user details.
     * @memberof WindreamLoginMask
     */
    private saveCredentialsToStorage(userDetails: string, token: string | null): void {
        this.storageWrapper.setItem(this.USER_DETAILS_KEY, userDetails);
        if (token) {
            this.storageWrapper.setItem(this.TOKEN_STORAGE_KEY, token);
        }
    }


    /**
     * Delete the credentials from storage.
     *
     * @private
     * @returns {Promise<void>} A promise, which will resolve if the credentials are cleared.
     * @memberof WindreamLoginMask
     */
    private deleteCredentialsFromStorage(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.storageWrapper.removeItem(this.USER_DETAILS_KEY);
            this.storageWrapper.removeItem(this.TOKEN_STORAGE_KEY);
            this.indexedDBWrapper.clear().then(() => resolve()).catch(() => resolve());
        });
    }


    /**
     * Gets the offline encryption key for the storage key.
     *
     * @private
     * @returns {string} The PBKDF2 storage key.
     * @memberof WindreamLoginMask
     */
    private generateStorageEncryptionPasswordKey(): string {
        const valueToGenerateKeyFrom = this.currentUserName + this.currentPassword;
        return this.encryptionHelper.generatePBKDF2(valueToGenerateKeyFrom, 'Genky');
    }

    /**
     * Saves the storage encryption key for offline usage.
     *
     * @private
     * @param {string} key The key to save.
     * @memberof WindreamLoginMask
     */
    private saveOfflineStorageEncryptionKey(key: string): void {
        const storageEncryptionKey = this.encryptionHelper.encryptAES(key, this.generateStorageEncryptionPasswordKey());
        this.storageWrapper.setItem(this.STORAGE_ENCRYPTION_KEY, storageEncryptionKey);
    }

    /**
     * Gets the saved storage encryption key.
     *
     * @private
     * @returns {(string | undefined)} The storage encryption key or undefined if not available.
     * @memberof WindreamLoginMask
     */
    private getStorageEncryptionKeyFromStorage(): string | undefined {
        const keyFromStorage = this.storageWrapper.getItem(this.STORAGE_ENCRYPTION_KEY);
        if (keyFromStorage) {
            return this.encryptionHelper.decryptAES(keyFromStorage, this.generateStorageEncryptionPasswordKey());
        } else {
            return undefined;
        }
    }

    /**
     * Gets the storage encrpytion key from the windream service.
     *
     * @private
     * @param {TokenRequestOptions} tokenRequestOptions The request options for authentication.
     * @returns {Promise<string>} A promise which will resolve with the token.
     * @memberof WindreamLoginMask
     */
    private async getStorageEncryptionKeyFromService(tokenRequestOptions: TokenRequestOptions): Promise<string> {
        return this.getStorageTokenAction.do(tokenRequestOptions);
    }


    /**
     * Check the system details if they are met.
     *
     * @private
     * @returns {Promise<void>} A promise, which will resolve if the system details are ok.
     * @memberof WindreamLoginMask
     */
    private async checkSystemDetails(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.isOnline(navigator)) {
                this.getSystemDetails.do().then((webServiceData) => {
                    const semanticVersioningDots = 3;
                    if (!webServiceData.response.data.SystemDetails) {
                        // This can occur if the URL resolves to a local file i.e. if the webserviceurl is a hash only etc.
                        this.staticPageHelper.renderError(require('../staticPage/templates/webserviceNotFoundErrorPage.html'));
                        reject(new Error('Failed to fetch systemdetails'));
                        return;
                    }
                    this.currentDomain = webServiceData.response.data.SystemDetails.DefaultDomain;
                    this.webServiceDomain = webServiceData.response.data.SystemDetails.DefaultDomain;
                    let webServiceVersion = webServiceData.response.data.SystemDetails.WebserviceVersion;
                    if (webServiceVersion.split('.').length === semanticVersioningDots) {
                        webServiceVersion += '.0';
                    }
                    let minWebServiceVersion = Version.minWebServiceVersion;
                    if (minWebServiceVersion.split('.').length === semanticVersioningDots) {
                        minWebServiceVersion += '.0';
                    }
                    const minVersionAchieved = Utils.compareVersionString(webServiceVersion, minWebServiceVersion, true) > -1;
                    let maxVersionAchieved = true;
                    if (Version.maxWebServiceVersion) {
                        let maxWebServiceVersion = Version.maxWebServiceVersion;
                        if (maxWebServiceVersion.split('.').length === semanticVersioningDots) {
                            maxWebServiceVersion += '.0';
                        }
                        maxVersionAchieved = Utils.compareVersionString(webServiceVersion, maxWebServiceVersion, true) < 1;
                    }

                    if (minVersionAchieved && maxVersionAchieved) {
                        resolve();
                    } else {
                        if (!minVersionAchieved) {
                            this.staticPageHelper.renderError(require('../staticPage/templates/minWebServiceVersionError.html'));
                            reject(new Error('Minimum webservice version is not available.'));
                        } else if (!maxVersionAchieved) {
                            this.staticPageHelper.renderError(require('../staticPage/templates/maxWebServiceVersionError.html'));
                            reject(new Error('Over maximum webservice version.'));
                        }
                    }
                }).catch((error) => {
                    this.logger.error(this.CLASS_NAME, 'showLoginMask', 'Failed to fetch system details', error);
                    this.currentDomain = '';
                    this.webServiceDomain = '';
                    this.staticPageHelper.renderError(require('../staticPage/templates/webserviceNotFoundErrorPage.html'));
                    reject();
                });
            } else {
                this.currentDomain = '';
                this.webServiceDomain = '';
                // Resolve if offline since we won't use the webservice.
                resolve();
            }
        });
    }
}