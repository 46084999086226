
/**
 * Thrown by the runtime only when a null object is referenced.
 * 
 * @export
 * @class NullReferenceError
 * @extends {Error}
 */
export class NullReferenceError extends Error {

    /**
     * Creates an instance of NullReferenceError.
     * @param {string} [message] 
     * 
     * @memberof NullReferenceError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'NullReferenceError';
    }
}