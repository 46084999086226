import { DWCore } from '@windream/dw-core/dwCore';
import { PubSubEditorComponentConfig } from '.';

/**
 * This class is a data holder for PubSub editor data.
 * 
 * @export
 * @class PubSubEditorConfig
 */
export class PubSubEditorConfig implements DWCore.PubSub.PubSubEditorConfig {

    /**
     * The components array.
     * 
     * @type {PubSubEditorComponentConfig[]}
     * @memberof PubSubEditorConfig
     */
    public components: PubSubEditorComponentConfig[];

    /**
     * The ID of the view this configuration was created for.
     * 
     * @type {string}
     * @memberof PubSubEditorConfig
     */
    public viewId?: string;

    /**
     * The device this configuration was created for.
     * 
     * @type {DWCore.Common.Devices}
     * @memberof PubSubEditorConfig
     */
    public device?: DWCore.Common.Devices;


    /**
     * Creates an instance of PubSubEditorConfig.
     * @memberof PubSubEditorConfig
     */
    public constructor() {
        this.components = new Array<PubSubEditorComponentConfig>();
    }

}