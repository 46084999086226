import { SortDirection } from '../../../typings/search';
import { Utils } from '../dynamicWorkspace';
import { CultureInfo } from './cultureInfo';
import { SortOptions } from './sortOptions';

/**
 * The sort helper provides functionality to sort string/numbers alphabetically.
 *
 * @export
 * @class SortHelper
 */
export class SortHelper extends CultureInfo {

    /**
     * Compares the two given strings.
     *
     * @param {string} value1 The first value.
     * @param {string} value2 The second value.
     * @param {SortOptions} [sortOptions=new SortOptions()] The sorting options.
     * @returns {number} The result number being used for further sorting logic.
     * @memberof SortHelper
     */
    public compareStrings(value1: string, value2: string, sortOptions: SortOptions = new SortOptions()): number {
        if (Utils.isDefined(value1) && Utils.isDefined(value2)) {
            return sortOptions.sortAsc ? value1.localeCompare(value2, this.getCurrentCulture(), sortOptions) : value2.localeCompare(value1, this.getCurrentCulture(), sortOptions);
        }
        // At least one of the strings is not defined.
        // Check the possible cases.
        if (Utils.isDefined(value1) && !Utils.isDefined(value2)) {
            return sortOptions.sortAsc ? 1 : -1;
        } else if (!Utils.isDefined(value1) && Utils.isDefined(value2)) {
            return sortOptions.sortAsc ? -1 : 1;
        } else {
            return 0;
        }
    }

    /**
     * Sorts any array by the specified property in ascending or descending order.
     *
     * @param {*} property Property in each entry to sort by.
     * @param {SortDirection} direction Order to sort in. This can be either ascending or descending.
     * @returns {*}
     * @memberof SortHelper
     */
    public sortArrayBy(property: any, direction: SortDirection): any {
        if (direction === SortDirection.Ascending) {
            return (x: any, y: any) => {
                return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
            };
        } else {
            return (x: any, y: any) => {
                return ((x[property] === y[property]) ? 0 : ((x[property] < y[property]) ? 1 : -1));
            };
        }
    }
}