import { DWCore } from '@windream/dw-core/dwCore';
import { UseCaseConfigResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { ViewConfig, ViewConfigFactory } from '../../config';
import { HttpResourcePointer } from '../../dataProviders/httpResourcePointer';
import { UseCaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to load a use case from the application.
 * 
 * @export
 * @class LoadUseCaseConfig
 * @extends {ServiceAction}
 */
export class LoadUseCaseConfig extends ServiceAction {
    /**
     * Name of the service action.
     * 
     * 
     * @memberof LoadUseCaseConfig
     */
    public name = 'loadUseCaseConfig';

    /**
     * Load a usecase.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {UseCaseRequestOptions} useCaseRequestOptions The request options.
     * @returns {Promise<ViewConfig>} A promise, which will resolve with the server response.
     * @memberof LoadUseCaseConfig
     */
    public async do(useCaseRequestOptions: UseCaseRequestOptions): Promise<ViewConfig> {
        return new Promise<ViewConfig>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer(
                'POST',
                this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/views/LoadUsecaseConfig',
                // TODO: Change so that the whole use case, for all devices, is loaded
                { ViewID: useCaseRequestOptions.useCaseId, Device: DWCore.Common.Devices.DESKTOP }
            );
            this.requestExecutor.executeRequest(resourcePointer, useCaseRequestOptions.requestOptions)
                .then((response: IServiceResponse<UseCaseConfigResponseContainerDTO>) => {
                    if (response.data?.HasErrors || !response.data) {
                        this.logger.error('LoadUsecaseConfig', 'do', 'Failed to load use case', response);
                        reject(new Error('Failed to load use case'));
                    } else {
                        const result = ViewConfigFactory.convertFromDTO(response.data.ConfigData);
                        resolve(result);
                    }
                }).catch((err: Error) => {
                    this.logger.error('LoadUsecaseConfig', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}