import { GetAllUsersResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { UserIdentity } from '../../authentication';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { BaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Retruns a list of all users.
 *
 * @export
 * @class GetAllUsers
 * @extends {ServiceAction}
 */
export class GetAllUsers extends ServiceAction {

    /**
     * Creates an instance of GetAllUsers.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof GetAllUsers
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getAllUsers';
    }

    /**
     * Retruns a list of all users.
     *
     * @param {BaseRequestOptions} [baseRequestOptions] The request options.
     * @returns {Promise<UserIdentity[]>} A promise, which will resolve with the server response.
     * @memberof GetAllUsers
     */
    public async do(baseRequestOptions?: BaseRequestOptions): Promise<UserIdentity[]> {
        return new Promise<UserIdentity[]>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer('get', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/members/GetAllUsers');
            this.requestExecutor.executeRequest(resourcePointer, baseRequestOptions?.requestOptions)
                .then((response: IServiceResponse<GetAllUsersResponseContainerDTO>) => {
                    if (response.data && !response.data.HasErrors) {
                        resolve(response.data.Users
                            .map((value) => {
                                const user = new UserIdentity(value.Id, value.Name, value.Domain, value.FullName, value.EmailAddress);
                                return user;
                            }));
                    } else {
                        this.logger.error('GetAllUsers', 'do', 'Failed to execute request', response);
                        reject(new Error('Failed to execute request'));
                    }
                }).catch((err) => {
                    this.logger.error('GetAllUsers', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}