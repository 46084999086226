import { Button as IButton, ButtonOptions } from '../../../../../typings/ui';

/**
 * The base class for a button.
 * 
 * @export
 * @class Button
 */
export class Button implements IButton {

    /**
     * The callback for the click event.
     * 
     * @memberof Button
     */
    public onClick?: () => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof Button
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof Button
     */
    public onBlur?: () => void;

    private targetElement: HTMLElement;
    private buttonOptions: DevExpress.ui.dxButtonOptions;
    private instance?: DevExpress.ui.dxButton;
    private jQueryStatic: JQueryStatic;


    /**
     * Creates an instance of Button.
     *
     * @param {HTMLElement} targetElement
     * @param {JQueryStatic} jQueryStatic
     * @memberof Button
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;

        this.buttonOptions = {
            icon: '',
            onClick: (event) => {
                if (!event || !event.event) {
                    throw new Error('dxButton.onClick: The event parameter was null or undefined.');
                }
                this.onClickTriggered();
            },
            text: ''
        };
    }


    /**
     * Bootstraps the button.
     * 
     * @memberof Button
     */
    public bootstrap(): void {
        this.instance = this.getJQueryElement().dxButton(this.buttonOptions).dxButton('instance');
    }


    /**
     * Sets the button options.
     * 
     * @param {ButtonOptions} options The button options.
     * @memberof Button
     */
    public setOptions(options: ButtonOptions): void {
        // Create the DevExpress options object.
        const buttonOptions: DevExpress.ui.dxButtonOptions = {};

        if (options.hasOwnProperty('icon')) {
            buttonOptions.icon = options.icon;
        }
        if (options.hasOwnProperty('isDisabled')) {
            buttonOptions.disabled = options.isDisabled;
        }
        if (options.hasOwnProperty('text')) {
            buttonOptions.text = options.text;
        }
        if (options.hasOwnProperty('isSubmit')) {
            buttonOptions.useSubmitBehavior = options.isSubmit;
        }
        if (options.hasOwnProperty('stylingMode')) {
            buttonOptions.stylingMode = options.stylingMode;
        }
        if (options.hasOwnProperty('hint')) {
            buttonOptions.hint = options.hint;
        }
        if (options.hasOwnProperty('isIconPlacedRightSide')) {
            buttonOptions.rtlEnabled = options.isIconPlacedRightSide;
        }
        if (options.hasOwnProperty('width')) {
            buttonOptions.width = options.width;
        }
        if (options.hasOwnProperty('height')) {
            buttonOptions.height = options.height;
        }
        if (options.hasOwnProperty('tabIndex')) {
            buttonOptions.tabIndex = options.tabIndex;
        }
        // Set the options.
        this.getJQueryElement().dxButton(buttonOptions);
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof Button
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof Button
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('disabled', isDisabled);
        }
    }

    /**
     * Sets the text.
     *
     * @param {string} value Text to set.
     * @memberof Button
     */
    public setValue(value: string): void {
        if (this.instance) {
            this.instance.option('text', value);
        }
    }

    /**
     * Triggers the onClick event.
     *    
     * @protected
     * @memberof Button
     */
    protected onClickTriggered(): void {
        if (typeof this.onClick === 'function') {
            this.onClick();
        }
    }

    /**
     * Gets the button instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof Button
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }
}