/**
 * Sort options for string/number array.
 *
 * @export
 * @class SortOptions
 */
export class SortOptions {

    /**
     * The used numeric collation. 
     * base - strings that do not have the same base letters are considered unequal.
     *
     * @static
     * @type {string}
     * @memberof SortOptions
     */
    public static readonly sensitivity: string = 'base';

    /**
     * The numeric collation.
     *
     * @type {boolean}
     * @memberof SortOptions
     */
    public numeric: boolean = true;

    /**
     * The sort direction.
     *
     * @type {boolean}
     * @memberof SortOptions
     */
    public sortAsc: boolean = true;
}