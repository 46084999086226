import { MemberTypes } from '../../../../../../typings/core';

/**
 * The rights models for a view.
 *
 * @export
 * @class RightsModel
 */
export class RightsModel {

    /**
     * The id of the rights holder.
     *
     * @type {number}
     * @memberof RightsModel
     */
    public id: number;

    /**
     * The domain of the rights holder.
     *
     * @type {string}
     * @memberof RightsModel
     */
    public domain: string;

    /**
     * The name of the rights holder.
     *
     * @type {string}
     * @memberof RightsModel
     */
    public name: string;

    /**
     * The memberType of the rights holder.
     *
     * @type {MemberTypes}
     * @memberof RightsModel
     */
    public memberType: MemberTypes;

    /**
     * Whether the right holder can read the view.
     *
     * @type {boolean}
     * @memberof RightsModel
     */
    public canRead: boolean;

    /**
     * Whether the right holder can administrate the view.
     *
     * @type {boolean}
     * @memberof RightsModel
     */
    public canAdministrate: boolean;

    /**
     * Creates an instance of RightsModel.
     * @param {number} id The id of the rights holder.
     * @param {string} domain The domain of the rights holder.
     * @param {string} name The name of the rights holder.
     * @param {MemberTypes} memberType The membertype.
     * @param {boolean} canRead Whether the right holder can read the view,
     * @param {boolean} canAdministrate Whether the right holder can administrate the view.
     * @memberof RightsModel
     */
    public constructor(id: number, domain: string, name: string, memberType: MemberTypes,
        canRead: boolean, canAdministrate: boolean) {
        this.id = id;
        this.domain = domain;
        this.name = name;
        this.memberType = memberType;
        this.canRead = canRead;
        this.canAdministrate = canAdministrate;
    }

}