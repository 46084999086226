import { WindreamIdentity } from 'typings/core';
import { WEBSERVICE_ERROR_CODES } from 'typings/services';
import { DirectoryResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Directories';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { DirectoryDetails } from '../../common/webService';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { ILanguageProvider } from '../../language';
import { Logger } from '../../logging';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to create a new directory via the windream webservice.
 * 
 * @export
 * @class CreateDirectory
 * @extends {ServiceAction}
 */
export class CreateDirectory extends ServiceAction {
    private readonly languageProvider: ILanguageProvider;

  /**
   * Creates an instance of CreateDirectory.
   * @param {IRequestExecutor} requestExecutor - The request executor.
   * @param {GlobalConfig} globalConfig - The global configuration.
   * @param {Logger} logger - The logger.
   * @param {ILanguageProvider} languageProvider The language provider.
   * @memberof CreateDirectory
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, languageProvider: ILanguageProvider) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'createDirectory';

    this.languageProvider = languageProvider;
  }

  /**
   * Creates a new directory.
   * Resolves with the server response containing details of the created directory.
   * Overrides the `do()` method from the parent class ServiceAction.
   *
   * @param {WindreamIdentity} identity - The identity.
   * @param {string} folderName - The name of the new directory.
   * @returns {Promise<DirectoryDetails>} A promise resolving with the details of the created directory.
   * @memberof CreateDirectory
   */
  public async do(identity: WindreamIdentity, folderName: string): Promise<DirectoryDetails> {
    return new Promise<DirectoryDetails>(async (resolve, reject) => {

        const url = `${this.globalConfig.windreamWebServiceURL}/directories/Create`;

        const resourcePointer = new HttpResourcePointer(
            'POST',
            url,
            {
                Item: {
                    ObjectType: {
                        Id: 1
                    },
                    Location: `${identity.location}\\${identity.name}`,
                    Name: folderName
                },
                CreateTree: false,
                ResponseDetails: 'Always'
            }
        );

        this.requestExecutor.executeRequest(resourcePointer).then((response: IServiceResponse<DirectoryResponseContainerDTO>) => {
            if (!response.data || response.data.HasErrors) {
                if (response.data?.Error?.ErrorCode === WEBSERVICE_ERROR_CODES.ObjectAlreadyExists) {
                    DynamicWorkspace.Notification.error({
                        body: this.languageProvider.get('webservice.create.errorAlreadyExists'),
                        title: this.languageProvider.get('framework.generic.error')
                    });
                } else if (response.data?.Error?.Message.includes('Path too long')) {
                    DynamicWorkspace.Notification.error({
                        body: this.languageProvider.get('webservice.create.errorTooLong'),
                        title: this.languageProvider.get('framework.generic.error')
                    });
                } else {
                    DynamicWorkspace.Notification.error({
                        body: this.languageProvider.getWithFormat('webservice.create.error', folderName.toString()),
                        title: this.languageProvider.get('framework.generic.error')
                    });
                }
                this.logger.error('createFolder', 'create', 'Failed to create directory', [identity, response]);
                reject(new Error('Failed to create directory'));
            } else {
                DynamicWorkspace.Notification.success({
                    body: this.languageProvider.getWithFormat('webservice.create.success', response.data.Item.Name),
                    title: this.languageProvider.get('framework.generic.success')
                });
                const result = DirectoryDetails.fromDto(response.data.Item);
                resolve(result);
            }
        }).catch((err: Error) => {
            DynamicWorkspace.Notification.error({
                body: this.languageProvider.get('webservice.create.error'),
                title: this.languageProvider.get('framework.generic.error')
            });
            this.logger.error('createFolder', 'create', 'Failed to create directory', [identity, err]);
            reject(err);
        });

    });
  }
}