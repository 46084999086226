import { ConflictCodes, FileConflictDTO, FileUploadResponseDTO, UploadPreflightResponseDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { UploadResponse, WindreamIdentityDetails } from '../common';
import { FileSizeFormatHelper } from '../culture';
import { Logger, WindreamIdentity } from '../dynamicWorkspace';
import {
    FileUploadResponse,
    UploadFileSliceInfo, UploadPreflightResponse, WebSocketConnectionStatus, WebSocketFile,
    WebSocketUploadPreflightContainer, FileUploadContainer, WebSocketUploadProgress
} from './models';
import { FileConflict } from './models/fileConflict';
import { WindreamWebsocketProvider } from '.';

/**
 * Represents the web socket for an upload.
 *
 * @export
 * @class UploadWebSocket
 */
export class UploadWebSocket {

    private readonly UPLOAD_STATE_SEPERATOR = '##';
    private readonly UPLOAD_STATE_PREFLIGHT = 'preflight';
    private readonly UPLOAD_STATE_FILE_UPLOAD = 'fileupload';
    private readonly UPLOAD_STATE_ERROR = 'error';

    private readonly windreamWebSocketProvider: WindreamWebsocketProvider;
    private readonly uploadIntervalTickRate: number = 500;
    private readonly className: string = 'UploadWebSocket';
    private connectedWebSocket?: WebSocket;
    private uploadStartTime?: number;
    private pendingUploadFiles: Map<string, ((uploadResponse: FileUploadResponse) => void)>;
    private readonly milliSecondsForOneSecond: number = 1000;
    private readonly byteStepSize: number = 1024;
    // Buffer of 1 MB
    private readonly defaultBuffer: number = this.byteStepSize * this.byteStepSize;
    private readonly bufferGrowthMultiplicator: number = 0.5;
    private readonly defaultBufferGrowth: number = this.defaultBuffer * this.bufferGrowthMultiplicator;
    private readonly pittyKiloBytes: number = 56;
    private readonly pittyUploadSpeed: number = this.byteStepSize * this.pittyKiloBytes;
    private readonly logger: Logger;
    private webSocketConnectionStatus: WebSocketConnectionStatus = WebSocketConnectionStatus.Uninitialized;
    private dynamicBufferSize: number = this.defaultBuffer;
    private onConflictsResolved?: (() => void);

    /**
     * A callback function for the current upload progess.
     *
     * @memberof UploadWebSocket
     */
    public onProgress?: ((progress: WebSocketUploadProgress) => void);

    /**
     * A callback function for resolving upload preflight conflicts.
     *
     * @memberof UploadWebSocket
     */
    public onConflict?: ((conflicts: FileConflict[]) => void);

    /**
     * A callback function to handle upload errors.
     *
     * @memberof UploadWebSocket
     */
    public onError?: ((errorEvent: Event) => void);


    /**
     * Creates an instance of UploadWebSocket.
     *
     * @param {WindreamWebsocketProvider} windreamWebSocketProvider The windream web socket provider instance.
     * @param {Logger} logger The logger instance.
     * @memberof UploadWebSocket
     */
    public constructor(windreamWebSocketProvider: WindreamWebsocketProvider, logger: Logger) {
        this.windreamWebSocketProvider = windreamWebSocketProvider;
        this.pendingUploadFiles = new Map<string, ((uploadResponse: FileUploadResponse) => void)>();
        this.logger = logger;
    }

    /**
     * Connects the web socket instance for the given URL.
     *
     * @param {string} url The web socket URL.
     * @returns {Promise<void>}
     * @memberof UploadWebSocket
     */
    public async connect(url: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.windreamWebSocketProvider.getConnection(url).then((webSocket) => {
                this.connectedWebSocket = webSocket;
                this.initWebSocket(this.connectedWebSocket);
                resolve();
            }).catch((error) => reject(error));
        });
    }

    /**
     * Uploads the given files.
     *
     * @param {WebSocketFile[]} files The files to upload.
     * @returns {Promise<UploadResponse>} The response of the upload.
     * @memberof UploadWebSocket
     */
    public async upload(files: WebSocketFile[]): Promise<UploadResponse> {
        this.uploadStartTime = (new Date().getTime()) / this.milliSecondsForOneSecond;
        return new Promise<UploadResponse>((resolve, reject) => {
            if (files.length === 0) {
                reject(new Error('No files provided'));
            }
            this.checkWebSocketConnection().then(() => {
                this.processPreflight(files).then((filesWithoutConflict) => {
                    this.prepareUploadPromises(filesWithoutConflict).then((fileUploadPromises) => {
                        const preferStreamUpload = typeof File.prototype.stream === 'function';
                        if (preferStreamUpload) {
                            this.streamedUpload(filesWithoutConflict).catch((error) => reject(error));
                        } else {
                            this.blobUpload(filesWithoutConflict).catch((error) => reject(error));
                        }
                        Promise.all(fileUploadPromises).then((responses: FileUploadResponse[]) => {
                            if (responses && responses.length > 0) {

                                // TODO: Handle bulk uploads
                                const identityDetails = new WindreamIdentityDetails();
                                identityDetails.entity = responses[0].identity.entity;
                                identityDetails.id = responses[0].identity.id;
                                identityDetails.location = responses[0].identity.location;
                                identityDetails.name = responses[0].identity.name;

                                const uploadResponse = new UploadResponse(identityDetails, responses[0].indexEventRequired);
                                if (responses[0].error) {
                                    uploadResponse.error = responses[0].error;
                                }
                                resolve(uploadResponse);
                            }
                        }).catch((error) => reject(error));
                    }).catch((error) => reject(error));
                }).catch((error) => reject(error));
            }).catch((error) => reject(error));
        });
    }

    /**
     * Handles all given file conflicts.
     *
     * @private
     * @param {WebSocketFile[]} files The files.
     * @param {FileConflict[]} conflicts The file conflicts.
     * @returns {Promise<WebSocketFile[]>} The files to upload.
     * @memberof UploadWebSocket
     */
    private handleConflicts(files: WebSocketFile[], conflicts: FileConflict[]): Promise<WebSocketFile[]> {
        return new Promise<WebSocketFile[]>((resolve) => {
            if (!conflicts) {
                resolve(files);
                return;
            }

            conflicts.forEach((conflict) => {

                if (conflict.code === ConflictCodes.ModifiedIdentity && conflict.modifiedIdentity) {
                    const foundModifiedFile = files.filter((file) =>
                        file.identity && file.identity.location === conflict.identity.location && file.identity.name === conflict.identity.name);
                    if (foundModifiedFile.length === 1) {
                        foundModifiedFile[0].identity.location = conflict.modifiedIdentity.location;
                        foundModifiedFile[0].identity.name = conflict.modifiedIdentity.name;
                    }

                    conflict.isHandled = true;
                }

            });

            resolve(files);
        });
    }

    /**
     * Processes the upload preflight.
     *
     * @private
     * @param {WebSocketFile[]} files The files to upload.
     * @returns {Promise<Array<WebSocketFile>>} Returns all files that are ready to be uploaded.
     * @memberof UploadWebSocket
     */
    private processPreflight(files: WebSocketFile[]): Promise<WebSocketFile[]> {
        return new Promise<Array<WebSocketFile>>((resolve, reject) => {
            const uploadRequests = new Array<FileUploadContainer>();
            files.forEach((fileToUpload) => uploadRequests.push(fileToUpload.generateUploadContainer()));
            this.checkWebSocketConnection().then((webSocket) => {

                this.onConflict = async (conflicts: FileConflict[]) => {
                    files = await this.handleConflicts(files, conflicts);
                    if (this.onConflictsResolved) {
                        this.onConflictsResolved();
                    }
                };

                this.onConflictsResolved = () => {
                    resolve(files);
                };

                let message = JSON.stringify(new WebSocketUploadPreflightContainer(uploadRequests));
                message = this.addUploadStateToMessage(this.UPLOAD_STATE_PREFLIGHT, message);
                webSocket.send(message);

            }).catch((error) => reject(error));
        });
    }

    /**
     * Adds the upload state to the given message.
     *
     * @private
     * @param {string} uploadState The upload state that should be added.
     * @param {string} message The original message.
     * @returns {string} The original message with the added upload state.
     * @memberof UploadWebSocket
     */
    private addUploadStateToMessage(uploadState: string, message: string): string {
        return this.UPLOAD_STATE_SEPERATOR + uploadState + this.UPLOAD_STATE_SEPERATOR + message;
    }

    private prepareUploadPromises(files: WebSocketFile[]): Promise<Array<Promise<FileUploadResponse>>> {
        return new Promise<Array<Promise<FileUploadResponse>>>((resolve) => {
            // TODO: hier den return value haben und dann hinter im uplload mit resolven.
            const fileUploadPromises = new Array<Promise<FileUploadResponse>>();
            // Prepare files
            const uploadRequests = new Array<FileUploadContainer>();
            files.forEach((fileToUpload) => {
                const uploadContainer = fileToUpload.generateUploadContainer();
                const fileIdentifier = this.createFileIdentifier(uploadContainer.identity);
                uploadRequests.push(fileToUpload.generateUploadContainer());
                const promiseToResolve = new Promise<FileUploadResponse>((resolve, reject) => {
                    const callback = (uploadResponse: FileUploadResponse) => {
                        if (uploadResponse) {
                            resolve(uploadResponse);
                        } else {
                            reject(new Error('BLALBLA'));
                        }
                    };
                    this.pendingUploadFiles.set(fileIdentifier, callback);
                });
                fileUploadPromises.push(promiseToResolve);
            });
            resolve(fileUploadPromises);
        });
    }

    /**
     * Uploads the given files in streaming mode.
     *
     * @private
     * @param {WebSocketFile[]} files The files to upload.
     * @returns {Promise<void>}
     * @memberof UploadWebSocket
     */
    private async streamedUpload(files: WebSocketFile[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const fileToProcess = files.shift();
            const processFile = (fileToProcess?: WebSocketFile) => {
                if (!fileToProcess) {
                    resolve();
                    return;
                }
                this.checkWebSocketConnection().then((webSocket) => {
                    const fileUploadContainer = fileToProcess.generateUploadContainer();
                    let message = JSON.stringify(fileUploadContainer);
                    message = this.addUploadStateToMessage(this.UPLOAD_STATE_FILE_UPLOAD, message);
                    webSocket.send(message);
                    const stream = fileToProcess.file.stream();
                    const fileReader = stream.getReader();
                    let currentChunkSize = 0;
                    const currentChunks = new Array<Uint8Array>();
                    const readStream = (reader: ReadableStreamDefaultReader<any>, isDone?: boolean) => {
                        this.checkWebSocketConnection().then((_readStreamWebSocket) => {
                            if (currentChunkSize > this.dynamicBufferSize && !isDone) {
                                this.sendBlobBuffered(new Blob(currentChunks)).then(() => {
                                    currentChunks.length = 0;
                                    currentChunkSize = 0;
                                    readStream(reader);
                                }).catch((error) => reject(error));
                            } else if (isDone) {
                                reader.releaseLock();
                                stream.cancel();
                                if (currentChunks.length > 0) {
                                    this.sendBlobBuffered(new Blob(currentChunks)).then(() => {
                                        this.checkWebSocketConnection().then((_blobWebSocket) => {
                                            currentChunks.length = 0;
                                            currentChunkSize = 0;
                                            fileReader.releaseLock();
                                            stream.cancel();
                                            resolve();
                                        }).catch((error) => reject(error));
                                    }).catch((error) => reject(error));
                                } else {
                                    currentChunks.length = 0;
                                    currentChunkSize = 0;
                                    fileReader.releaseLock();
                                    stream.cancel();
                                    resolve();
                                }
                            } else {
                                reader.read().then((result) => {
                                    if (result.done) {
                                        readStream(reader, true);
                                    } else if (result.value) {
                                        currentChunkSize += result.value.byteLength;
                                        currentChunks.push(result.value);
                                        readStream(reader);
                                    }
                                }).catch((error) => reject(error));
                            }
                        }).catch((error) => reject(error));
                    };
                    readStream(fileReader);
                }).catch((error) => reject(error));
            };
            processFile(fileToProcess);
        });
    }

    /**
     * Uploads the given files in blob mode.
     *
     * @private
     * @param {WebSocketFile[]} files The files to upload.
     * @returns {Promise<void>}
     * @memberof UploadWebSocket
     */
    private async blobUpload(files: WebSocketFile[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const sendFilesRecursive = (fileToSend: WebSocketFile) => {
                this.checkWebSocketConnection().then((webSocket) => {
                    this.logger.debug(this.className, 'blobUpload', 'Upload begin at ' + this.uploadStartTime);

                    const fileUploadContainer = fileToSend.generateUploadContainer();
                    let message = JSON.stringify(fileUploadContainer);
                    message = this.addUploadStateToMessage(this.UPLOAD_STATE_FILE_UPLOAD, message);
                    webSocket.send(message);

                    this.sendBlobBuffered(fileToSend.file).then(() => {
                        this.checkWebSocketConnection().then((_blobWebSocket) => {
                            const nextFile = files.shift();
                            if (nextFile) {
                                sendFilesRecursive(nextFile);
                            } else {
                                resolve();
                            }
                        }).catch((error) => reject(error));
                    }).catch((error) => {
                        reject(error);
                    });
                }).catch((error) => reject(error));
            };
            const firstFile = files.shift();
            if (firstFile) {
                sendFilesRecursive(firstFile);
            } else {
                resolve();
            }
        });
    }

    /**
     * Initializes the given web socket.
     *
     * @private
     * @param {WebSocket} webSocket The web socket to initialize.
     * @memberof UploadWebSocket
     */
    private initWebSocket(webSocket: WebSocket): void {
        this.webSocketConnectionStatus = WebSocketConnectionStatus.Open;
        webSocket.binaryType = 'blob';
        webSocket.onerror = (errorEvent) => {
            if (this.onError) {
                this.onError(errorEvent);
            }
            this.webSocketConnectionStatus = WebSocketConnectionStatus.Error;
        };
        webSocket.onmessage = (message) => {

            let splittedMessage: string[] = [];
            if (message.data && typeof (message.data) === 'string') {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                splittedMessage = message.data.split(this.UPLOAD_STATE_SEPERATOR, 3);
            }

            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (splittedMessage && splittedMessage.length !== 3) {
                throw new Error('Invalid text message format received.');
            }

            const uploadState = splittedMessage[1];
            if (uploadState === this.UPLOAD_STATE_PREFLIGHT) {
                // Preflight
                const preflightResponse = this.createUploadPreflightResponseFromMessage(splittedMessage[2]);
                if (preflightResponse) {

                    // Try handle conflicts
                    if (preflightResponse.conflicts && preflightResponse.conflicts.length > 0) {
                        if (this.onConflict) {
                            this.onConflict(preflightResponse.conflicts);
                        }
                    } else {
                        // No conflicts, therefore call the "conflicts resolved" handler directly.
                        if (this.onConflictsResolved) {
                            this.onConflictsResolved();
                        }
                    }
                }
            } else if (uploadState === this.UPLOAD_STATE_FILE_UPLOAD) {
                // File uploaded
                const fileUploadResponse = this.createFileUploadResponseFromMessage(splittedMessage[2]);
                if (fileUploadResponse) {
                    const callback = this.pendingUploadFiles.get(this.createFileIdentifier(fileUploadResponse.identity));
                    if (callback) {
                        callback(fileUploadResponse);
                    }
                }
            } else if (uploadState === this.UPLOAD_STATE_ERROR) {
                throw new Error(splittedMessage[2]);
            } else {
                throw new Error('Unknown message prefix found: ' + uploadState);
            }
        };

        webSocket.onclose = (_event) => {
            this.webSocketConnectionStatus = WebSocketConnectionStatus.Closed;
        };
    }

    /**
     * Creates a file identifier.
     *
     * @private
     * @param {WindreamIdentity} identity The file identity.
     * @returns {string} The file identifier.
     * @memberof UploadWebSocket
     */
    private createFileIdentifier(identity: WindreamIdentity): string {
        return 'file:' + identity.location + identity.name;
    }

    /**
     * Creates an upload preflight response from the given message.
     *
     * @private
     * @param {string} message The message.
     * @returns {UploadPreflightResponse} The upload preflight response.
     * @memberof UploadWebSocket
     */
    private createUploadPreflightResponseFromMessage(message: string): UploadPreflightResponse {
        const response = new UploadPreflightResponse();

        const uploadPreflightResponseDTO = <UploadPreflightResponseDTO>JSON.parse(message);
        if (uploadPreflightResponseDTO && uploadPreflightResponseDTO.Conflicts) {
            uploadPreflightResponseDTO.Conflicts.forEach((conflictDTO: FileConflictDTO) => {
                if (conflictDTO) {
                    if (conflictDTO.Identity) {
                        const conflictIdentity = new WindreamIdentity();
                        conflictIdentity.entity = <number>conflictDTO.Identity.Entity;
                        conflictIdentity.id = conflictDTO.Identity.Id;
                        conflictIdentity.location = conflictDTO.Identity.Location;
                        conflictIdentity.name = conflictDTO.Identity.Name;

                        const fileConflict = new FileConflict(conflictIdentity, conflictDTO.Code);
                        fileConflict.isHandled = conflictDTO.IsHandled;

                        if (conflictDTO.ModifiedIdentity) {
                            fileConflict.modifiedIdentity = new WindreamIdentity();
                            fileConflict.modifiedIdentity.entity = <number>conflictDTO.ModifiedIdentity.Entity;
                            fileConflict.modifiedIdentity.id = conflictDTO.ModifiedIdentity.Id;
                            fileConflict.modifiedIdentity.location = conflictDTO.ModifiedIdentity.Location;
                            fileConflict.modifiedIdentity.name = conflictDTO.ModifiedIdentity.Name;
                        }

                        response.conflicts.push(fileConflict);
                    }
                }
            });
        }

        return response;
    }

    /**
     * Creates a file upload response from the given message.
     *
     * @private
     * @param {string} message The message.
     * @returns {FileUploadResponse} The file upload response.
     * @memberof UploadWebSocket
     */
    private createFileUploadResponseFromMessage(message: string): FileUploadResponse {
        const response = new FileUploadResponse();

        const fileUploadResponseDTO = <FileUploadResponseDTO>JSON.parse(message);
        if (fileUploadResponseDTO) {
            if (fileUploadResponseDTO.Identity) {
                response.identity.entity = <number>fileUploadResponseDTO.Identity.Entity;
                response.identity.id = fileUploadResponseDTO.Identity.Id;
                response.identity.location = fileUploadResponseDTO.Identity.Location;
                response.identity.name = fileUploadResponseDTO.Identity.Name;

                response.indexEventRequired = fileUploadResponseDTO.IndexEventRequired;
            }
        }

        return response;
    }

    /**
     * Sends the given file in buffered mode.
     *
     * @private
     * @param {(Blob | File)} file The file to upload.
     * @returns {Promise<void>}
     * @memberof UploadWebSocket
     */
    private sendBlobBuffered(file: Blob | File): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.sendDataSlice(0, this.defaultBuffer, file).then((uploadFileSlice) => {
                const interval = setInterval(() => {
                    this.checkWebSocketConnection().then((webSocket) => {
                        uploadFileSlice.uploadTimeFrame += this.uploadIntervalTickRate;
                        const leftOverBytes = webSocket.bufferedAmount;
                        this.adjustDynamicBufferSize(leftOverBytes, uploadFileSlice.uploadTimeFrame === this.uploadIntervalTickRate);
                        if (leftOverBytes === 0) {
                            this.sendDataSlice(uploadFileSlice.currentByteIndex, this.dynamicBufferSize, file).then((fileSlice) => {
                                uploadFileSlice = fileSlice;
                                if (fileSlice.isLastSlice) {
                                    clearInterval(interval);
                                    resolve();
                                    return;
                                }
                            }).catch((error) => {
                                clearInterval(interval);
                                reject(error);
                            });
                        }
                    }).catch((error) => {
                        clearInterval(interval);
                        reject(error);
                    });
                }, this.uploadIntervalTickRate);
            }).catch((error) => reject(error));
        });
    }

    /**
     * Adjustes the buffer size dynamically.
     *
     * @private
     * @param {number} leftOverBytes The remaining bytes to upload.
     * @param {boolean} isFirstInterval Determines whether it is the first interval.
     * @memberof UploadWebSocket
     */
    private adjustDynamicBufferSize(leftOverBytes: number, isFirstInterval: boolean): void {
        const methodName = 'adjustDynamicBufferSize';
        if (leftOverBytes > 0) {
            this.logger.trace(this.className, methodName, 'Slow down by:' + this.getBufferByteRateAsString(leftOverBytes));
            this.dynamicBufferSize -= leftOverBytes;
        } else if (isFirstInterval) {
            this.dynamicBufferSize += this.defaultBufferGrowth;
        }
        if (this.dynamicBufferSize < this.pittyUploadSpeed) {
            this.dynamicBufferSize = this.pittyUploadSpeed;
            this.logger.trace(this.className, methodName, 'Setting pitty speed since the buffer was not empty and the amount of buffered data is too vast');
        }
        this.logger.trace(this.className, methodName, 'Upload speed set to:' + this.getBufferByteRateAsString(this.dynamicBufferSize));
    }

    /**
     * Gets the buffered byte rate as string.
     *
     * @private
     * @param {number} buffer The buffer.
     * @returns {string} The buffer byte rate as string.
     * @memberof UploadWebSocket
     */
    private getBufferByteRateAsString(buffer: number): string {
        const fileSizeFormatHelper = new FileSizeFormatHelper();
        const intervalTicksPerSecond = this.milliSecondsForOneSecond / this.uploadIntervalTickRate;
        return fileSizeFormatHelper.getFileSizeString(buffer * intervalTicksPerSecond) + '/s';
    }

    /**
     * Sends a data slice.
     *
     * @private
     * @param {number} currentByteIndex The index of the current byte.
     * @param {number} bufferSize The size of the buffer.
     * @param {(File | Blob)} file The file to upload.
     * @returns {Promise<UploadFileSliceInfo>} Returns an upload file slice information.
     * @memberof UploadWebSocket
     */
    private sendDataSlice(currentByteIndex: number, bufferSize: number, file: File | Blob): Promise<UploadFileSliceInfo> {
        return new Promise<UploadFileSliceInfo>((resolve, reject) => {
            this.checkWebSocketConnection().then((webSocket) => {
                const size = file.size;
                // Make sure we stop at end of file
                const stop = Math.min(currentByteIndex + bufferSize - 1, size - 1);
                bufferSize = stop - currentByteIndex + 1;

                // Save if it is the last block of data to send
                const isLastBlock = (stop === size - 1);

                // Get blob and check his size
                const blob = file.slice(currentByteIndex, currentByteIndex + bufferSize);
                if (blob.size !== bufferSize) {
                    throw new Error('slice fail !: slice result size is ' + blob.size + '. Expected: ' + bufferSize);
                }
                webSocket.send(blob);
                currentByteIndex += bufferSize;
                resolve(new UploadFileSliceInfo(isLastBlock, currentByteIndex, blob));
            }).catch((error) => reject(error));
        });
    };

    /**
     * Checks the web socket connection.
     *
     * @private
     * @returns {Promise<WebSocket>} Returns the web socket instance.
     * @memberof UploadWebSocket
     */
    private checkWebSocketConnection(): Promise<WebSocket> {
        return new Promise<WebSocket>((resolve, reject) => {
            if (!this.connectedWebSocket) {
                reject(new Error('WebSocket not connected yet, use connect method first'));
                return;
            }
            switch (this.webSocketConnectionStatus) {
                case WebSocketConnectionStatus.Closed:
                    reject(new Error('WebSocket is closed, reconnect first'));
                    return;
                case WebSocketConnectionStatus.Error:
                    reject(new Error('WebSocket received an error recently, reconnect first'));
                    return;
            }
            resolve(this.connectedWebSocket);
        });
    }

}