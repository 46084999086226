import { IResourcePointer, RequestOptions } from '../dataProviders';
import { IServiceResponse } from './interfaces/iServiceResponse';


/**
 * This class represents the base class for all responses.
 * 
 * @export
 * @class ServiceResponse
 * @implements {IServiceResponse<T>}
 * @template T 
 */
export class ServiceResponse<T> implements IServiceResponse<T> {

    /**
     * The response data.
     * 
     * @type {T}
     * @memberof ServiceResponse
     */
    public data?: T;


    /**
     * The resource pointer.
     * 
     * @type {IResourcePointer}
     * @memberof ServiceResponse
     */
    public resourcePointer?: IResourcePointer;


    /**
     * The request options.
     * 
     * @type {RequestOptions}
     * @memberof ServiceResponse
     */
    public requestOptions?: RequestOptions;

}