import { WindreamEntity } from '../../common';
import { SearchAttributeFlags } from './searchAttributesFlags';
import { SearchGroup, SearchSorting } from '.';

/**
 * This class defines a search query.
 *
 * @export
 * @class SearchQuery
 */
export class SearchQuery {

  /**
   * Attribute flags of this search.
   * 
   * @type {SearchAttributeFlags}
   * @memberof SearchQuery
   */
  public attributeFlags?: SearchAttributeFlags;
  /**
   * Root group of the search condition.
   * 
   * @type {SearchGroup}
   * @memberof SearchQuery
   */
  public group?: SearchGroup;
  /**
   * Entity to search for.
   * 
   * @type {WindreamEntity}
   * @memberof SearchQuery
   */
  public entity: WindreamEntity;
  /**
   * Indices to retrieve.
   * 
   * @type {string[]}
   * @memberof SearchQuery
   */
  public indices?: string[];
  /**
   * Placeholder values to use.
   * 
   * @type {Map<string, any>}
   * @memberof SearchQuery
   */
  public placeholderValues: Map<string, any>;

  /**
   * Condition placeholder values to use.
   *
   * @type {Map<string, any>}
   * @memberof SearchQuery
   */
  public conditionPlaceholderValues: Map<string, any>;

  /**
   * The limit on how many results should be retrieved.
   *
   * @type {number}
   * @memberof SearchQuery
   */
  public resultLimit?: number;

  /**
   * The sorting of this search query.
   *
   * @type {SearchSorting}
   * @memberof SearchQuery
   */
  public sorting?: SearchSorting;

  /**
   * Creates an instance of SearchQuery.
   * @memberof SearchQuery
   */
  public constructor() {
    this.placeholderValues = new Map<string, any>();
    this.conditionPlaceholderValues = new Map<string, any>();
    this.entity = WindreamEntity.DocumentAndMap;  // Default
  }

}