import { Utils } from '../../../common';
import { INumberBox, INumberBoxOptions } from './interfaces';

/**
 * The base class for a number box.
 * 
 * @export
 * @class NumberBox
 */
export class NumberBox implements INumberBox {

    /**
     * The callback for the value changed event. Passes the current value as a number.
     * 
     * @memberof NumberBox
     */
    public onValueChanged?: (item: number | null) => void;
    /**
     * The callback for the enter pressed event. Passes the current value as a number.
     * 
     * @memberof NumberBox
     */
    public onEnterPressed?: (item: number | null) => void;

    /**
     * The callback for the key down event. Passes the current value as a number.
     *
     * @memberof NumberBox
     */
    public onKeyDown?: (value: number | null) => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof NumberBox
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof NumberBox
     */
    public onBlur?: () => void;

    /**
     * The callback for the onInitialized event.
     *
     * @memberof NumberBox
     */
    public onInitialized?: () => void;

    private targetElement: HTMLElement;
    private numberBoxOptions: DevExpress.ui.dxNumberBoxOptions;
    private instance?: DevExpress.ui.dxNumberBox;
    private jQueryStatic: JQueryStatic;


    /**
     * Creates an instance of NumberBox.
     *
     * @param {HTMLElement} targetElement
     * @param {JQueryStatic} jQueryStatic
     * @memberof NumberBox
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;

        this.numberBoxOptions = {
            mode: 'number',
            value: undefined,
            onFocusIn: () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            },
            onFocusOut: () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            },
            onEnterKey: (event: any) => {
                if (!event) {
                    throw new Error('dxNumberBox.onEnterKey: The event parameter was null or undefined.');
                }

                const value = this.getValue();
                if (!Utils.Instance.isDefined(value)) {
                    this.onEnterPressedTrigger(null);
                } else {
                    this.onEnterPressedTrigger(value);
                }
            },
            onKeyDown: (event) => {
                if (!event) {
                    throw new Error('dxNumberBox.onKeyDown: The event parameter was null or undefined.');
                }
                if (event && event.event) {
                    const keyCode = (event.event as any as KeyboardEvent).key;
                    if (keyCode && !Number.isNaN(Number(keyCode))) {
                        this.onKeyDownTrigger(Number(keyCode));
                    }
                }
            },
            onInitialized: () => {
                this.onInitializedTrigger();
            },
            onValueChanged: (event) => {
                if (event) {
                    this.onValueChangedTrigger(event.value);
                }
            },
            showClearButton: true,
            showSpinButtons: true,
            valueChangeEvent: 'keyup blur change input focusout'
        };
    }


    /**
     * Bootstraps the number box.
     * 
     * @memberof NumberBox
     */
    public bootstrap(): void {
        this.instance = this.getJQueryElement().dxNumberBox(this.numberBoxOptions).dxNumberBox('instance');
    }


    /**
     * Sets the number box options.
     * 
     * @param {INumberBoxOptions} options 
     * @memberof NumberBox
     */
    public setOptions(options: INumberBoxOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }

        // Create the DevExpress options object.
        const numberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {};

        if (options.hasOwnProperty('isReadOnly')) {
            numberBoxOptions.readOnly = options.isReadOnly;
        }
        if (options.hasOwnProperty('isDisabled')) {
            numberBoxOptions.disabled = options.isDisabled;
        }
        if (options.hasOwnProperty('placeholderText')) {
            numberBoxOptions.placeholder = options.placeholderText;
        }
        if (options.hasOwnProperty('isRequired')) {
            numberBoxOptions.elementAttr = { required: options.isRequired ? true : null };
        }
        if (options.hasOwnProperty('format')) {
            // Workaround because of chrome issue with number type in HTMLInputElement
            // Https://www.devexpress.com/Support/Center/Question/Details/T680247/numberbox-the-failed-to-read-the-selectionstart-property-from-htmlinputelement-error
            numberBoxOptions.mode = 'text';
            numberBoxOptions.format = options.format;
        }
        if (options.hasOwnProperty('tabIndex')) {
            numberBoxOptions.tabIndex = options.tabIndex;
        }
        // Set the options.
        const dxNumberBox = this.getJQueryElement().dxNumberBox(numberBoxOptions);
        // Set validator rules.
        if (options.isRequired) {
            dxNumberBox.dxValidator({ validationRules: [{ type: 'required' }] });
        } else {
            dxNumberBox.dxValidator({ validationRules: [] });
        }
    }

    /**
     * Sets the current value.
     * 
     * @param {(number | null)} value
     * @memberof NumberBox
     */
    public setValue(value: number | null): void {
        const convertedValue = value === null ? undefined : value;
        this.getJQueryElement().dxNumberBox({
            value: convertedValue
        });
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof NumberBox
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.numberBoxOptions.isValid = isValid;
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the hint text.
     *
     * @param {string} hint
     * @memberof NumberBox
     */
    public setHint(hint: string): void {
        if (this.instance) {
            this.numberBoxOptions.hint = hint;
            this.instance.option('hint', hint);
        }
    }

    /**
     * Sets the disabled state of the component.
     * Sets component internal to read only state for usability.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof NumberBox
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.numberBoxOptions.disabled = isDisabled;
            this.instance.option('readOnly', isDisabled);
        }
    }


    /**
     * Triggers the onValueChanged event.
     * 
     * @protected
     * @param {(number | null)} value 
     * @memberof NumberBox
     */
    protected onValueChangedTrigger(value: number | null): void {
        if (typeof this.onValueChanged === 'function') {
            this.onValueChanged(value);
        }
    }

    /**
     * Triggers the onEnterPressed event.
     * 
     * @protected
     * @param {(number | null)} value 
     * @memberof NumberBox
     */
    protected onEnterPressedTrigger(value: number | null): void {
        if (typeof this.onEnterPressed === 'function') {
            this.onEnterPressed(value);
        }
    }

    /**
     * Triggers the onKeyEvent event.
     *
     * @protected
     * @param {(number | null)} value The current value.
     * @memberof NumberBox
     */
    protected onKeyDownTrigger(value: number | null): void {
        if (typeof this.onKeyDown === 'function') {
            this.onKeyDown(value);
        }
    }

    /**
     * Triggers the onInitialized event.
     *    
     * @protected
     * @memberof NumberBox
     */
    protected onInitializedTrigger(): void {
        if (typeof this.onInitialized === 'function') {
            this.onInitialized();
        }
    }

    /**
     * Gets the number box instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof NumberBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }

    /**
     * Returns the current value.
     * 
     * @private
     * @returns {number} The current value.
     * @memberof NumberBox
     */
    private getValue(): number | undefined {
        if (!this.instance) {
            throw new Error('No instance found');
        }
        return this.instance.option('value');
    }

}