import { ViewConfigMetaData } from '../config';
import { Logger } from '../logging';
import { ConfigUiHelper } from '../ui';
import { IRouter, RoutingUtils } from '.';

/**
 * The router for view routing.
 *
 * @export
 * @class ViewRouter
 * @implements {IRouter}
 */
export class ViewRouter implements IRouter {
    private routeRegExp: RegExp;
    private catchRegExp: RegExp;
    private onViewChangeCallbacks: ((view: string) => void)[];
    private logger: Logger;
    private currentWindow: Window;
    private readonly HASH: string = '#!';
    private _activeViews: ViewConfigMetaData[] | null = null;

    /**
     * Creates an instance of ViewRouter.
     * 
     * @param {Logger} logger The logger.
     * @param {Window} currentWindow The window.
     * @memberof ViewRouter
     */
    public constructor(logger: Logger, currentWindow: Window) {
        this.routeRegExp = new RegExp('\/view\/[^\/]+$', 'i');
        this.catchRegExp = new RegExp('\/view\/([^\/]+)', 'i');
        this.onViewChangeCallbacks = new Array<(view: string) => void>();
        this.logger = logger;
        this.currentWindow = currentWindow;
        // Event listener to popstate so it closes dropdowns menu and other layouts   
        currentWindow.addEventListener('popstate', this.clickOnViewChange.bind(this));
        // Legacy hashbased navigation
        currentWindow.addEventListener('hashchange', () => {
            const newViewId = this.getViewIdFromHash();
            if (newViewId) {
                this.onViewChangeCallbacks.forEach((callback) => {
                    callback(newViewId);
                });
            }
        });
    }

    /**
     * Gets the current active views.
     *
     * @readonly
     * @type {(ViewConfigMetaData[] | null)}
     * @memberof ViewRouter
     */
    public get activeViews(): ViewConfigMetaData[] | null {
        return this._activeViews;
    }

    /**
     * Set the current active views.
     *
     * @memberof ViewRouter
     */
    public set activeViews(value: ViewConfigMetaData[] | null) {
        this._activeViews = value;
    }

    /**
     * Route the request.
     *
     * @param {string} path The path.
     * @param {*} [state] The state.
     * @returns {boolean} Whether the navigation was a success.
     * @memberof ViewRouter
     */
    public route(path: string, state?: any): boolean {
        if (typeof state === 'string') {
            path = state;
        }
        const catchingGroups = this.catchRegExp.exec(path);
        if (catchingGroups) {
            this.onViewChangeCallbacks.forEach((callback) => {
                callback(catchingGroups[1]);
            });
            if (ConfigUiHelper.hasUnsavedChanges()) {
                return false;
            } else {
                return true;
            }
        } else {
            this.logger.error('ViewRouter', 'route', 'Call was not routed because the catching group is somehow empty');
            return false;
        }
    }

    /**
     * Registers a click event to close things like dropdown menus (etc).
     * 
     * @memberof ViewRouter
     */
    private clickOnViewChange(): void {
        if (document) {
            // Click event
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
            });
            document.body.dispatchEvent(clickEvent);
        }
    }

    /**
     * Gets the current view from the path or null if not present.
     *
     * @returns {(string | null)} The view as string or null if not found.
     * @memberof ViewRouter
     */
    public getCurrentViewFromPath(): string | null {
        const catchingGroups = this.catchRegExp.exec(this.currentWindow.location.pathname);
        if (catchingGroups && catchingGroups.length > 1) {
            return catchingGroups[1];
        }
        return null;
    }

    /**
     * Generates the URL for a specific view.
     *
     * @static
     * @param {string} viewId The id of the view which will be generated.
     * @returns {string} The url.
     * @memberof ViewRouter
     */
    public static generateNavigationUrlForViewId(viewId: string): string {
        return RoutingUtils.getApplicationBasePath() + 'view/' + viewId;
    }

    /**
     * Gets the current route pattern.
     *
     * @returns {RegExp} The route pattern.
     * @memberof ViewRouter
     */
    public getRoutePattern(): RegExp {
        return this.routeRegExp;
    }

    /**
     * Register a on view change callback.
     *
     * @param {(view: string) => void} callback The callback.
     * @memberof ViewRouter
     */
    public registerOnViewChange(callback: (view: string) => void): void {
        this.onViewChangeCallbacks.push(callback);
    }

    /**
     * Gets the view id from a hash.
     *
     * @returns {(string | null)} The view as string or null if not found.
     * @memberof ViewRouter
     */
    public getViewIdFromHash(): string | null {
        if (this.currentWindow.location.hash.length > this.HASH.length) {
            const prefixFreeUrl = decodeURIComponent(this.currentWindow.location.hash.substr(this.HASH.length));
            const wronglyPlacedParametersIndex = prefixFreeUrl.indexOf('?');
            if (wronglyPlacedParametersIndex > 0) {
                return prefixFreeUrl.substr(0, wronglyPlacedParametersIndex);
            } else if (wronglyPlacedParametersIndex === -1) {
                return prefixFreeUrl;
            }
        }
        return null;
    }
}