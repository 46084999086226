import { Culture, Logger } from '../../../typings';
import { UiManager, DataCollectionDisplay as IDataCollectionDisplay, Component } from '../../../typings/core';
import { LanguageProvider } from '../../../typings/language';
import { DataCollectionData, MasterTableOptions } from '../../../typings/ui';
import { WindreamEntity, WindreamIdentity } from '../common';
import { ContextMenu, ContextMenuData, ContextMenuItem } from '../contextMenu';
import { IContextMenuExtension } from '../extensions';


/**
 * Master class for components that display a DataCollection.
 * 
 * @export
 * @abstract
 * @class DataCollectionDisplay
 * @template T 
 */
export abstract class DataCollectionDisplay<T> implements IDataCollectionDisplay<T> {

    /**
     * Callback for the element (data cell) under the mouse. This works only for the table display mode.
     *
     * @memberof DataCollectionDisplay
     */
    public onCellHover?: (element: HTMLElement, rowData: T, column: string) => void;

    /**
     * Toggle parent element button state.
     *
     * @type {boolean}  default: false
     * @memberof DataCollectionDisplay
     */
    public disableParentButtonState: boolean = false;
    /**
     * Language provider to use.
     * 
     * @protected
     * @type {LanguageProvider}
     * @memberof DataCollectionDisplay
     */
    protected languageProvider: LanguageProvider;
    /**
     * Culture helper to use.
     *
     * @protected
     * @type {Culture}
     * @memberof DataCollectionDisplay
     */
    protected cultureHelper: Culture;
    /**
     * UiManager to use.
     * 
     * @protected
     * @type {UiManager}
     * @memberof DataCollectionDisplay
     */
    protected uiManager?: UiManager;

    /**
     * ContextMenu extension to use.
     * 
     * @protected
     * @type {IContextMenuExtension}
     * @memberof DataCollectionDisplay
     */
    protected contextMenuExtension?: IContextMenuExtension;

    /**
     * Refers to the component that ContextMenu belongs to.
     *
     * @protected
     * @type {object}
     * @memberof DataCollectionDisplay
     */
    protected contextMenuSender?: Component;

    /**
     * The latest data that has been used to render.
     * 
     * @protected
     * @type {DataCollectionData<T>}
     * @memberof DataCollectionDisplay
     */
    protected currentData?: DataCollectionData<T>;

    /**
     * The root of the current shown data.
     *
     * @protected
     * @type {(WindreamIdentity | null)}
     * @memberof DataCollectionDisplay
     */
    protected currentIdentity?: WindreamIdentity | null;

    /**
     * ContextMenu to use.
     * 
     * @protected
     * @type {ContextMenu}
     * @memberof DataCollectionDisplay
     */
    protected contextMenu?: ContextMenu;

    private logger: Logger;
    private className = 'DataCollectionDisplay';

    private itemsInProcess: Map<number, WindreamIdentity>;


    /**
     * Creates an instance of DataCollectionDisplay.
     *
     * @param {LanguageProvider} languageProvider The language provider.
     * @param {Logger} logger The logger.
     * @param {Culture} cultureHelper The culture helper.
     * @memberof DataCollectionDisplay
     */
    public constructor(languageProvider: LanguageProvider, logger: Logger, cultureHelper: Culture) {
        this.languageProvider = languageProvider;
        this.logger = logger;
        this.cultureHelper = cultureHelper;

        this.itemsInProcess = new Map<number, WindreamIdentity>();
        this.initializeOnFavoriteClickHandler();
    }


    /**
     * Initializes the onFavoriteClick event handler.
     *
     * @memberof DataCollectionDisplay
     */
    public initializeOnFavoriteClickHandler(): void {

        this.onFavoriteClick = async (item: any, itemData: WindreamIdentity): Promise<void> => {

            return new Promise<void>(async (resolve, reject) => {

                if (item && typeof (itemData.id) === 'number' && !this.itemsInProcess.has(itemData.id)) {

                    this.itemsInProcess.set(itemData.id, itemData);

                    if (!item.isFavorite) {
                        await DynamicWorkspace.Services.Favorites.addFavorites({ identities: [itemData] }).then(() => {
                            item.isFavorite = true;
                            resolve();
                        }).catch((error) => {
                            item.isFavorite = false;
                            this.logger.error(this.className, 'onFavoriteClick', 'Failed to add favorites', error);
                            reject(error);
                        });
                    } else {
                        await DynamicWorkspace.Services.Favorites.removeFavorites({ identities: [itemData] }).then(() => {
                            item.isFavorite = false;
                            resolve();
                        }).catch((error) => {
                            item.isFavorite = true;
                            this.logger.error(this.className, 'onFavoriteClick', 'Failed to remove favorites', error);
                            reject(error);
                        });
                    }

                    if (typeof (itemData.id) === 'number' && this.itemsInProcess.has(itemData.id)) {
                        this.itemsInProcess.delete(itemData.id);
                    }
                } else {
                    resolve();
                }
            });
        };
    }


    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof DataCollectionDisplay
     */
    public abstract setDisabled(isDisabled: boolean): void;

    /**
     * Enable the websocket extension.
     *
     * @param {IContextMenuExtension} contextMenuExtension The ContextMenuExtension.
     * @param {Component} contextMenuSender Refers to the component that ContextMenu belongs to.
     *
     * @memberof DataCollectionDisplay<T>
     */
    public setContextMenuExtension(contextMenuExtension: IContextMenuExtension, contextMenuSender?: Component): void {
        this.contextMenuExtension = contextMenuExtension;
        this.contextMenuSender = contextMenuSender;
    }

    /**
     * Sets the UI Manager instance.
     * 
     * @param {UiManager} uiManager UI Manager instance to use.
     * @memberof DataCollectionDisplay
     */
    public setUiManager(uiManager: UiManager): void {
        this.uiManager = uiManager;
    }

    /**
     * Sets the table6 options.
     *
     * @abstract
     * @param {MasterTableOptions<T>} options
     * @memberof DataCollectionDisplay
     */
    public abstract setOptions(options: MasterTableOptions<T>): void;

    /**
     * Clear selected items.
     *
     * @abstract
     * @memberof DataCollectionDisplay
     */
    public abstract clearSelection(): void;

    /**
     * Renders the component.
     * 
     * @abstract
     * @param {(DataCollectionData<T> | null)} [data] Data to render with.
     * @memberof DataCollectionDisplay
     */
    public abstract render(data?: DataCollectionData<T> | null): void;

    /**
     * Set the root folder.
     *
     * @param {(WindreamIdentity | null)} identity The identity.
     * @memberof DataCollectionDisplay
     */
    public setCurrentIdentity(identity: WindreamIdentity | null): void {
        this.currentIdentity = identity;
    }

    /**
     * Destroys the collection, cleans the DOM.
     * 
     * @abstract
     * @memberof DataCollectionDisplay
     */
    public abstract destroy(): void;

    /**
     * Instruct the datacollection to repaint.
     *
     * @public
     * @returns {void}
     * @memberof DataCollectionDisplay
     */
    public abstract repaint(): void;

    /**
     * Callback when an item got a click on the favorite button besides it.
     *
     * @memberof DataCollectionDisplay
     */
    public onFavoriteClick?: (item: any, itemData: WindreamIdentity) => Promise<void>;

    /**
     *Sets the favorite style to the given element.
     *
     * @param {HTMLElement} element The element.
     * @param {string} tooltip The element tooltip.
     * @memberof DataCollectionDisplay
     */
    public setFavoriteStyle(element: HTMLElement, tooltip?: string): void {
        element.classList.add('wd-favorites-icon-selected');
        element.classList.add('fa-solid');
        element.classList.remove('fa-light');
        if (tooltip) {
            element.title = tooltip;
        } else {
            element.title = '';
        }
    }

    /**
     * Removes the favorite style from the given element.
     *
     * @private
     * @param {HTMLElement} element The element.
     * @param {string} tooltip The element tooltip.
     * @memberof DataCollectionDisplay
     */
    public removeFavoriteStyle(element: HTMLElement, tooltip?: string): void {
        element.classList.remove('wd-favorites-icon-selected');
        element.classList.add('fa-light');
        element.classList.remove('fa-solid');
        if (tooltip) {
            element.title = tooltip;
        } else {
            element.title = '';
        }
    }

    /**
     * Returns a `<span>` representing the file icon for the given data object if the object is a WindreamIdentity.
     * 
     * @protected
     * @param {T} dataObject Object to get the file icon for.
     * @returns {string} A `<span>` representing the icon or an empty string if no icon can be created.
     * @memberof DataCollectionDisplay
     */
    protected getFileIcon(dataObject: T): string {
        let icon: string = '';
        // @ts-ignore - TODO: Check index signature
        if (dataObject && 'entity' in dataObject && dataObject['entity'] === WindreamEntity.Document) {
            let fileType = '';
            // @ts-ignore - TODO: Check index signature
            if (dataObject['name']) {
                // @ts-ignore - TODO: Check index signature
                fileType = dataObject['name'].substr(dataObject['name'].lastIndexOf('.')).replace(/\./g, '').toLowerCase();
            }
            icon = `<span class="wd-icon file ${fileType}" title="${this.languageProvider.get('framework.filetypes.file')} ${fileType ? '(' + fileType + ')' : ''}"></span>`;
            // @ts-ignore - TODO: Check index signature
        } else if (dataObject && 'entity' in dataObject && dataObject['entity'] === WindreamEntity.Folder) {
            icon = `<span class="wd-icon folder" title="${this.languageProvider.get('framework.filetypes.folder')}"></span>`;
        }
        return icon;
    }

    /**
     * Setup the currently used context menu.
     *
     * @protected
     * @param {T[]} data The data object.
     *
     * @memberof DataCollectionDisplay
     */
    protected setupContextMenu(data: T[]) {
        if (!this.currentData) {
            return;
        }
        this.contextMenu = new ContextMenu();
        this.currentData.data.contextMenu.forEach((menu) => {
            const contextMenuItem = new ContextMenuItem();
            contextMenuItem.text = menu.name;
            if (menu.onClick) {
                contextMenuItem.click = () => {
                    if (menu.onClick) {
                        menu.onClick(data, this.contextMenuSender);
                    }
                };
            }
            if (menu.childs.length > 0) {
                this.addChildContextMenu(contextMenuItem, menu, data);
            }
            if (this.contextMenu) {
                this.contextMenu.addItem(contextMenuItem);
            }
        });
    }

    /**
     * Returns the context menu instance to use.
     * If the current data has a ContextMenuCreator set, it will be used.
     * Otherwise, the context menu will be used.
     * 
     * @protected
     * @param {T[]} data Data to get context menu instance for.
     * @returns {Promise<ContextMenu>} Promise to resolve with the context menu instance.
     * @memberof DataCollectionDisplay
     */
    protected async getContextMenuInstance(data: T[]): Promise<ContextMenu> {
        if (this.currentData && this.currentData.data.contextMenuCreator) {
            if (this.uiManager) {
                this.uiManager.appIsBusy();
            } else {
                this.logger.debug('DataCollectionDisplay', 'getContextMenuInstance', 'No UiManager set.');
            }
            const contextMenuName = this.contextMenuExtension?.getCurrentViewContextMenuName() ?? 'Standard';
            return this.currentData.data.contextMenuCreator.getContextMenu(data, contextMenuName, this.contextMenuSender).then(async (menu: ContextMenu) => {
                if (this.uiManager) {
                    this.uiManager.appIsIdle();
                } else {
                    this.logger.debug('DataCollectionDisplay', 'getContextMenuInstance', 'No UiManager set.');
                }
                return Promise.resolve(menu);
            }, async (err: Error) => {
                return Promise.reject(err);
            });
        } else if (this.contextMenu) {
            return Promise.resolve(this.contextMenu);
        }
        return Promise.reject(new Error('No context menu instance available'));
    }

    /**
     * Adds sub children into the context menu.
     *
     * @private
     * @param {ContextMenuItem} contextMenuItem The current parent.
     * @param {ContextMenuData<T>} menuModel The menuModel.
     * @param {T[]} data The data.
     *
     * @memberof DataCollectionDisplay
     */
    private addChildContextMenu(contextMenuItem: ContextMenuItem, menuModel: ContextMenuData<T>, data: T[]) {
        menuModel.childs.forEach((childMenu) => {
            const childMenuItem = new ContextMenuItem();
            childMenuItem.text = childMenu.name;
            childMenuItem.click = () => {
                if (childMenu.onClick) {
                    childMenu.onClick(data, this.contextMenuSender);
                }
            };
            contextMenuItem.addItem(childMenuItem);
            if (childMenu.childs.length > 0) {
                this.addChildContextMenu(childMenuItem, childMenu, data);
            }
        });
    }
}