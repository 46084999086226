import { Utils } from '../../../common';
import { DataSourceItem, IDataSourceItem, IItemCollectionDataSource, ItemCollectionDataSource } from '../common';
import { IComboBox, IComboBoxOptions } from '.';

/**
 * The base class for a combo box.
 *
 * @export
 * @class ComboBox
 */
export class ComboBox<T> implements IComboBox<T> {

    /**
     * The callback for the value changed event.
     *
     * @memberof ComboBox
     */
    public onValueChanged?: (item: IDataSourceItem<T> | null) => void;

    /**
     * The callback for when the box receives focus.
     *
     * @memberof ComboBox
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof ComboBox
     */
    public onBlur?: () => void;

    private targetElement: HTMLElement;
    private dataSource: IItemCollectionDataSource<T>;
    private selectBoxOptions: DevExpress.ui.dxSelectBoxOptions<DevExpress.ui.dxSelectBox>;
    private instance?: DevExpress.ui.dxSelectBox;
    private jQueryStatic: JQueryStatic;

    private options?: IComboBoxOptions;

    /**
     * Creates an instance of ComboBox.
     * 
     * @param {HTMLElement} targetElement The target element.
     * @param {Window} currentWindow The current window.
     * @param {JQueryStatic} jQueryStatic jQuery.
     * @memberof ComboBox
     */
    public constructor(targetElement: HTMLElement, currentWindow: Window, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;
        this.dataSource = new ItemCollectionDataSource<T>();

        // Close select list during popstate event since it's a view switch
        currentWindow.addEventListener('popstate', () => {
            if (this.instance) {
                this.instance.close();
            }
        });
        this.selectBoxOptions = {
            acceptCustomValue: true,
            dataSource: new DevExpress.data.DataSource({}),
            displayExpr: 'displayValue',
            itemTemplate: (itemData: DataSourceItem<T>) => {
                const template = this.jQueryStatic('<div>');
                if (itemData) {
                    template.text(itemData.displayValue || '');
                    template.attr('title', itemData.displayValue || '');
                    template.addClass('wd-ellipsis');
                }
                return template;
            },
            onContentReady: (event) => {
                setTimeout(() => {
                    // Set min width of dropdown
                    // See https://www.devexpress.com/Support/Center/Question/Details/T348316/dxselectbox-how-to-change-width-of-the-drop-down-window
                    if (this.options && this.options.dropdownMinWidth) {
                        event.component?.content()?.parent().css('min-width', this.options.dropdownMinWidth);
                    }
                });
            },
            onCustomItemCreating: (event: any) => {
                if (!event) {
                    throw new Error('dxSelectBox.onCustomItemCreating: The event parameter was null or undefined.');
                } else if (!this.dataSource) {
                    throw new Error('The control\'s datasource is null or undefined.');
                }

                if (!event.text) {
                    this.onValueChangedTrigger(null);
                    event.customItem = null; // This shall be used instead of return statement
                } else {
                    const tempItem = new DataSourceItem<T>(event.text);
                    // @ts-ignore - TODO: Fix toString not being part of T
                    tempItem.displayValue = tempItem.value ? tempItem.value.toString() : '';

                    this.onValueChangedTrigger(tempItem);
                    event.customItem = tempItem; // This shall be used instead of return statement
                }
            },
            onFocusIn: () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            },
            onFocusOut: () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            },
            // Hotfix: Enable native scrolling: https://supportcenter.devexpress.com/Ticket/Details/T405225/dxselectbox-how-to-enable-native-scrolling
            onOpened: (e) => {
                this.onFocusTrigger();
                if (e && e.component) {
                    const list = (e.component as any)._list;
                    if (list) {
                        list.option('useNativeScrolling', true);
                        list._scrollView.option('useNative', true);
                        list._$element.parent().parent().width(this.targetElement.clientWidth); // Avoid irregular width jumping while scrollbar dragging
                    }
                }
            },
            onValueChanged: (event) => {
                if (!event) {
                    throw new Error('dxSelectBox.onValueChanged: The event parameter was null or undefined.');
                } else if (!this.dataSource) {
                    throw new Error('The control\'s datasource is null or undefined.');
                }

                if (!Utils.Instance.isDefined(event.value)) {
                    this.onValueChangedTrigger(null);
                } else {
                    const selectedItem = this.dataSource.getItemByValue(event.value as T);
                    if (selectedItem) {
                        this.onValueChangedTrigger(selectedItem);
                    }
                }
            },
            value: null,
            valueExpr: 'value',
        };
    }


    /**
     * Bootstraps the combo box.
     *
     * @memberof ComboBox
     */
    public bootstrap(): void {
        this.targetElement.classList.add('wd-combo-box');
        this.instance = this.getJQueryElement().dxSelectBox(this.selectBoxOptions).dxSelectBox('instance');
    }


    /**
     * Sets the data source.
     *
     * @param {IItemCollectionDataSource<T>} datasource
     * @memberof ComboBox
     */
    public setDataSource(dataSource: IItemCollectionDataSource<T>): void {
        this.dataSource = dataSource;

        this.getJQueryElement().dxSelectBox({
            dataSource: this.createDxDataSource(),
        });
    }

    /**
     * Sets the combo box options.
     *
     * @param {IComboBoxOptions} options
     * @memberof ComboBox
     */
    public setOptions(options: IComboBoxOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }
        this.options = options;

        // Create the DevExpress options object.
        const selectBoxOptions: DevExpress.ui.dxSelectBoxOptions<DevExpress.ui.dxSelectBox> = {};

        if (options.hasOwnProperty('acceptCustomValue')) {
            selectBoxOptions.acceptCustomValue = options.acceptCustomValue;
        }
        if (options.hasOwnProperty('isReadOnly')) {
            selectBoxOptions.readOnly = options.isReadOnly;
        }
        if (options.hasOwnProperty('isDisabled')) {
            selectBoxOptions.disabled = options.isDisabled;
        }
        if (options.hasOwnProperty('isSearchModeEnabled')) {
            selectBoxOptions.searchEnabled = options.isSearchModeEnabled;

            if (options.hasOwnProperty('searchMode')) {
               selectBoxOptions.searchMode = options.searchMode;
            }
            if (options.hasOwnProperty('searchTimeout')) {
                selectBoxOptions.searchTimeout = options.searchTimeout;
            }
            if (options.hasOwnProperty('minSearchLength')) {
                selectBoxOptions.minSearchLength = options.minSearchLength;
            }
            if (options.hasOwnProperty('showDataBeforeSearch')) {
                selectBoxOptions.showDataBeforeSearch = options.showDataBeforeSearch;
            }
        }
        if (options.hasOwnProperty('placeholderText')) {
            selectBoxOptions.placeholder = options.placeholderText;
        }
        if (options.hasOwnProperty('noItemsPlaceholderText')) {
            selectBoxOptions.noDataText = options.noItemsPlaceholderText;
        }
        if (options.hasOwnProperty('showClearButton')) {
            selectBoxOptions.showClearButton = options.showClearButton;
        }
        if (options.hasOwnProperty('isGroupingEnabled')) {
            selectBoxOptions.grouped = options.isGroupingEnabled;
            if (this.instance) {
                this.instance.getDataSource().group('group');
            }
        }
        if (options.hasOwnProperty('tabIndex')) {
            selectBoxOptions.tabIndex = options.tabIndex;
        }
        // Update options
        this.selectBoxOptions = selectBoxOptions;

        // Set the options.
        const dxSelectBox = this.getJQueryElement().dxSelectBox(this.selectBoxOptions);

        // Set validator rules.
        if (options.isRequired) {
            dxSelectBox.dxValidator({ validationRules: [{ type: 'required' }] });
        } else {
            dxSelectBox.dxValidator({ validationRules: [] });
        }
    }


    /**
     * Sets the current selected item.
     *
     * @param {IDataSourceItem<T>} item
     * @param {boolean} [forceAdd] Determines whether the item should be added, if it not already exist.
     * @memberof ComboBox
     */
    public selectItem(item: IDataSourceItem<T>, forceAdd?: boolean): void {
        if (!item) {
            throw new ReferenceError('The argument "item" was null or undefined.');
        }

        if (!this.dataSource) {
            this.dataSource = new ItemCollectionDataSource<T>();
        }

        // Search the item within the datasource...
        const existingItem = this.dataSource.getItemByValue(item.value, Utils.isDeepEqual);
        if (existingItem) {
            // ...and use this item's value.
            this.dataSource.selectedItem = existingItem;

            this.setValue(existingItem.value);
        } else if (forceAdd) {
            const newItem: IDataSourceItem<T> = new DataSourceItem<T>(item.value);
            // @ts-ignore - TODO: Fix toString not being part of T
            newItem.displayValue = item.value ? item.value.toString() : '';
            this.dataSource.add(newItem);
        } else {
            this.setValue(item.value);
        }
    }


    /**
     * Sets the current value.
     * The given value needs to be part of the data source set earlier (passed by reference).
     *
     * @param {T} value
     * @memberof ComboBox
     */
    public setValue(value: T | null): void {
        this.getJQueryElement().dxSelectBox({
            value: value
        });
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof ComboBox
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the disabled state of the component.
     * Sets component internal to read only state for usability.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof ComboBox
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('readOnly', isDisabled);
        }
    }

    /**
     * Destroys this instance.
     *
     * @memberof ComboBox
     */
    public destroy(): void {
        if (this.instance) {
            this.instance.dispose();
        }
    }


    /**
     * Triggers the onValueChanged event.
     *
     * @protected
     * @param {(DataSourceItem<T> | null)} value
     * @memberof ComboBox
     */
    protected onValueChangedTrigger(value: IDataSourceItem<T> | null): void {
        if (typeof this.onValueChanged === 'function') {
            this.onValueChanged(value);
        }
    }


    /**
     * Triggers the onFOcus event.
     *
     * @protected
     * @memberof ComboBox
     */
    protected onFocusTrigger(): void {
        if (typeof this.onFocus === 'function') {
            this.onFocus();
        }
    }


    /**
     * Gets the combobox instance.
     *
     * @private
     * @returns {JQuery<HTMLElement>}
     * @memberof ComboBox
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.jQueryStatic) {
            throw new Error('jQuery was not loaded.');
        }

        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }

    /**
     * Creates a DevExtreme data source.
     *
     * @private
     * @returns
     * @memberof ComboBox
     */
    private createDxDataSource() {
        const dxDataSource = new DevExpress.data.DataSource({
            store: this.createCustomStore(),
        });

        if (this.selectBoxOptions.grouped) {
            dxDataSource.group('group');
        }

        return dxDataSource;
    }

    /**
     * Creates a custom store.
     *
     * @private
     * @returns {DevExpress.data.CustomStore} 
     * @memberof ComboBox
     */
    private createCustomStore(): DevExpress.data.CustomStore {
        return new DevExpress.data.CustomStore({

            loadMode: 'raw',

            load: (_loadOptions: DevExpress.data.LoadOptions<any>) => {
                if (this.dataSource.load) {
                    return this.dataSource.load();
                } else {
                    return new Promise((resolve) => {
                        resolve(this.dataSource.items);
                    });
                }
            }
        });
    }

}