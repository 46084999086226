import { Size } from './size';
import { Style } from './style';
import { ComponentConfigToolbar, Configuration, PubSubConfig } from '.';

/**
 * This class is a data holder for each component config.
 * 
 * @export
 * @class ComponentConfig
 */
export class ComponentConfig {

    /**
     * The name of the component.
     * 
     * @type {string}
     * @memberof ComponentConfig
     */
    public name: string;

    /**
     * The id of the component.
     * 
     * @type {string}
     * @memberof ComponentConfig
     */
    public id: string;

    /**
     * The description of the component.
     * 
     * @type {string}
     * @memberof ComponentConfig
     */
    public description?: string;

    /**
     * The version of the component.
     * 
     * @type {string}
     * @memberof ComponentConfig
     */
    public version?: string;

    /**
     * The author of the component.
     * 
     * @type {string}
     * @memberof ComponentConfig
     */
    public author?: string;

    /**
     * The pubsub config of the component.
     * 
     * @type {PubSubConfig}
     * @memberof ComponentConfig
     */
    public pubSub: PubSubConfig;

    /**
     * The configuration of the component.
     * 
     * @type {Configuration[]}
     * @memberof ComponentConfig
     */
    public configuration: Configuration[];

    /**
     * The entry point of the component.
     * 
     * @type {string}
     * @memberof ComponentConfig
     */
    public entry?: string;

    /**
     * The template of the component.
     * 
     * @type {string}
     * @memberof ComponentConfig
     */
    public template?: string;

    /**
     * The size of the component.
     * 
     * @type {Size}
     * @memberof ComponentConfig
     */
    public size?: Size;

    /**
     * The category or categories.
     *
     * @type {(string | string[])}
     * @memberof ComponentData
     */
    public category?: string | string[];

    /**
     * The tags of the component.
     * 
     * @type {string[]}
     * @memberof ComponentConfig
     */
    public tags: string[];

    /**
     * The style of the component.
     * 
     * @type {Style}
     * @memberof ComponentConfig
     */
    public style?: Style;

    /**
     * Whether the component is an invisible logic component.
     * If nothing is set in the JSON file, it is set to false.
     * 
     * 
     * @type {boolean}
     * @memberof ComponentConfig
     */
    public isLogic?: boolean;

    /**
     * Whether the component is a sneak peek preview or not.
     * If nothing is set in the JSON file, it is set to false.
     *
     * @type {boolean}
     * @memberof ComponentData
     */
    public isDevPreview?: boolean;

    /**
     * The toolbar config.
     *
     * @type {ComponentConfigToolbar}
     * @memberof ComponentConfig
     */
    public toolbar?: ComponentConfigToolbar;

    /**
     * Creates an instance of ComponentConfig.
     * 
     * @param {string} id
     * @param {string} name
     * @memberof ComponentConfig
     */
    public constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
        this.tags = new Array<string>();
        this.configuration = new Array<Configuration>();
        this.pubSub = new PubSubConfig();
        this.toolbar = new ComponentConfigToolbar();
    }
}