import { DWCore } from '@windream/dw-core/dwCore';

/**
 * The style model, which is used for each component.
 * 
 * @export
 * @class Style
 */
export class Style implements DWCore.Views.Style {

    /**
     * The style class for button background colors.
     * 
     * @type {string}
     * @memberof Style
     */
    public buttonColors?: string;


    /**
     * The style class for colors.
     *
     * @type {string}
     * @memberof Style
     */
    public colors?: string;


    /**
     * The default values.
     *
     * @static
     * @returns {Style}
     *
     * @memberof Style
     */
    public static default(): Style {
        return {
            buttonColors: 'wd-style-colors-0',
            colors: 'wd-style-colors-0'
        };
    }

}