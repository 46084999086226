/**
 * Represents the progress of an upload via web socket.
 *
 * @export
 * @class WebSocketUploadProgress
 */
export class WebSocketUploadProgress {

    /**
     * The upload speed.
     *
     * @type {number}
     * @memberof WebSocketUploadProgress
     */
    public speed: number;

    /**
     * The start time of the upload.
     *
     * @type {number}
     * @memberof WebSocketUploadProgress
     */
    public startTime: number;

    /**
     * The elapsed time of the upload.
     *
     * @type {number}
     * @memberof WebSocketUploadProgress
     */
    public elapsedTime: number;

    /**
     * The remaining time of the upload.
     *
     * @type {number}
     * @memberof WebSocketUploadProgress
     */
    public remainingTime: number;

    /**
     * The percentage of the upload.
     *
     * @type {number}
     * @memberof WebSocketUploadProgress
     */
    public percentage: number;

    /**
     * The already uploaded bytes of the upload.
     *
     * @type {number}
     * @memberof WebSocketUploadProgress
     */
    public uploadedBytes: number;

    /**
     * Creates an instance of WebSocketUploadProgress.
     *
     * @param {number} uploadedBytes The already uploaded bytes of the upload.
     * @param {number} totalBytes The total bytes of the upload.
     * @param {number} startTime The start time of the upload.
     * @memberof WebSocketUploadProgress
     */
    public constructor(uploadedBytes: number, totalBytes: number, startTime: number) {
        this.startTime = startTime;
        this.uploadedBytes = uploadedBytes;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.elapsedTime = (new Date().getTime()) / 1000 - startTime;
        this.speed = uploadedBytes / this.elapsedTime;
        this.remainingTime = (this.elapsedTime / uploadedBytes) * (totalBytes - uploadedBytes);
        this.percentage = (uploadedBytes / totalBytes) * 100;
    }
}