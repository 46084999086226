import { ConfigResponseContainerDTO, ViewConfigResponseDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig, ViewConfig, ViewConfigFactory } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { SaveviewRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform saving of views on the windream webservice.
 * 
 * @export
 * @class SaveView
 * @extends {ServiceAction}
 */
export class SaveView extends ServiceAction {
    /**
     * Creates an instance of SaveView.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global config.
     * @param {Logger} logger The logger.
     * @memberof SaveView
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'saveView';
    }

    /**
     * Saves a view.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {SaveviewRequestOptions} saveViewRequestOptions The request options.
     * @returns {Promise<ViewConfig>} A promise, which will resolve with the view config.
     * @memberof SaveView
     */
    public async do(saveViewRequestOptions: SaveviewRequestOptions): Promise<ViewConfig> {
        return new Promise<ViewConfig>((resolve, reject) => {
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/views/UpdateViewConfig', {
                ConfigData: saveViewRequestOptions.config,
                ViewID: saveViewRequestOptions.viewId
            }), saveViewRequestOptions.requestOptions).then((response: IServiceResponse<ConfigResponseContainerDTO<ViewConfigResponseDTO>>) => {
                if (response.data?.HasErrors || !response.data) {
                    this.logger.error('SaveView', 'do', 'Failed to save view config', response);
                    reject(new Error('Failed to save view config'));
                } else {
                    const viewConfig = ViewConfigFactory.convertFromDTO(response.data.ConfigData);
                    resolve(viewConfig);
                }
            }).catch((err: Error) => {
                this.logger.error('SaveView', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}