import { DWCore } from '@windream/dw-core/dwCore';
import { NotificationHelper, SharedSetting } from '../../../typings/core';
import { Utils } from '../common';
import { IComponentManager } from '../components';
import { ConfigMapper, IConfigMapping, Style, ViewConfig } from '../config';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { IPubSubHandler } from '../pubSub';
import { IConfigUiHelper, IPopupHelper, IUiHelper } from './interfaces';
import { MappingUiHelperUtil } from './mappingUiHelperUtil';

/**
 * Helper class to handle the mapping configuration.
 *
 * @export
 * @class MappingUiHelper
 */
export class MappingUiHelper implements IUiHelper {

    /**
     * Callback to execute when config changes.
     *
     * @memberof MappingUiHelper
     */
    public onConfigChange?: (isDirty: boolean) => void;

    private logger: Logger;
    private componentManager: IComponentManager;
    private pubSubHandler: IPubSubHandler;
    private configMapper: ConfigMapper;
    private languageProvider: ILanguageProvider;
    private popupHelper: IPopupHelper;
    private currentViewConfig?: ViewConfig;
    private viewConfigBackup?: ViewConfig;

    private configUiHelper?: IConfigUiHelper;

    private readonly className = 'MappingUiHelper';
    private isInitialized = false;
    private isInitializing = false;

    private MAINFRAME_ELEMENT_ID = 'wd-mapping-mainframe-container';
    private COMPONENTPANEL_ELEMENT_ID = 'wd-add-mapping-panel';
    private ACTIONPANEL_ELEMENT_ID = 'wd-mapping-action-panel';

    private notificationHelper: NotificationHelper;

    /**
     * Creates an instance of MappingUiHelper.
     *
     * @param {Logger} logger Logger instance to use.
     * @param {IComponentManager} componentManager Component Loader instance to use.
     * @param {IPubSubHandler} pubSubHandler PubSub Handler instance to use.
     * @param {ConfigMapper} configMapper Config Mapper instance to use.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {IPopupHelper} popupHelper The popup helper.
     * @param {NotificationHelper} notificationHelper The notification helper.
     * @memberof MappingUiHelper
     * @memberof MappingUiHelper
     */
    public constructor(logger: Logger, componentManager: IComponentManager, pubSubHandler: IPubSubHandler, configMapper: ConfigMapper,
        languageProvider: ILanguageProvider, popupHelper: IPopupHelper, notificationHelper: NotificationHelper) {
        this.logger = logger;
        this.componentManager = componentManager;
        this.pubSubHandler = pubSubHandler;
        this.configMapper = configMapper;
        this.languageProvider = languageProvider;
        this.popupHelper = popupHelper;
        this.notificationHelper = notificationHelper;
    }

    /**
     * Sets the configuration UI helper.
     *
     * @param {IConfigUiHelper} configUiHelper The configuration UI helper.
     * @memberof MappingUiHelper
     */
    public setConfigUiHelper(configUiHelper: IConfigUiHelper): void {
        this.configUiHelper = configUiHelper;
    }

    /**
     * Checks for possible changes.
     *
     * @returns {Promise<boolean>} Whethere there is pending changes.
     * @memberof MappingUiHelper
     */
    public async hasChanges(): Promise<boolean> {
        const hasChanges = !Utils.Instance.isDeepEqual(Utils.Instance.sortObjectPropertiesDeep(this.currentViewConfig), Utils.Instance.sortObjectPropertiesDeep(this.viewConfigBackup));
        return Promise.resolve(hasChanges);
    }
    /**
     * Returns the current view configuration.
     *
     * @returns {(ViewConfig | undefined)} The current view configuration.
     * @memberof MappingUiHelper
     */
    public getCurrentViewConfig(): ViewConfig | undefined {
        return this.currentViewConfig;
    }

    /**
     * Shows the configuration.
     *
     * @returns {Promise<void>} Promise to resolve when the configuration is fully loaded and shown.
     * @memberof MappingUiHelper
     */
    public async show(): Promise<void> {
        if (!Utils.Instance.isDefined(this.currentViewConfig)) {
            this.logger.error(this.className, 'show', 'No ViewConfig set', this.currentViewConfig);
            return Promise.reject(new Error('No ViewConfig set'));
        }
        if (this.isInitializing) {
            return Promise.resolve();
        }
        // Avoid reloading if nothing has changed
        if (this.isInitialized) {
            // Publish current config always if mapping UI is initialized.
            // Otherwise it can happen that the mapping UI isn't correctly updated and stays completly empty.
            this.publishCurrentConfig();
            return Promise.resolve();
        } else {
            this.isInitializing = true;
            return Promise.all([this.loadMainComponent(), this.loadPanelComponent(), this.loadActionComponent()])
                .then(async () => {
                    this.isInitialized = true;
                    this.isInitializing = false;
                    MappingUiHelperUtil.addMainframeConfigurationPubSub(this.pubSubHandler);
                    return Promise.resolve();
                })
                .catch((err: Error) => {
                    this.isInitializing = false;
                    this.logger.error('PubSubUiHelper', 'show', 'Failed to load components', err);
                });
        }
    }

    /**
     * Hides the configuration.
     *
     * @returns {Promise<void>} Promise to resolve when the configuration is fully hidden.
     * @memberof MappingUiHelper
     */
    public async hide(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Updates the used ViewConfig.
     * 
     * @param {ViewConfig} viewConfig THe new view config.
     * @param {DWCore.Common.Devices} _device The current device.
     * @param {string} _senderGuid The GUID of the component that triggered the publish.
     * @memberof MappingUiHelper
     */
    public updateViewConfig(viewConfig: ViewConfig, _device: DWCore.Common.Devices, _senderGuid?: string): void {
        const currentMapping = Utils.Instance.deepClone(this.currentViewConfig?.settings);
        const hasViewChanged = viewConfig.id !== this.currentViewConfig?.id;
        this.currentViewConfig = Utils.Instance.deepClone(viewConfig);
        this.viewConfigBackup = Utils.Instance.deepClone(viewConfig);

        // If the view did not change, keep current mapping info
        if (!hasViewChanged && currentMapping) {
            this.currentViewConfig.settings = currentMapping;
            this.viewConfigBackup.settings = currentMapping;
        }

        // Only publish new config to components if view changed
        if (hasViewChanged) {
            this.publishCurrentConfig();
        }
    }

    /**
     * Load the main component.
     *
     * @private
     * @returns {Promise<void>}
     * @memberof MappingUiHelper
     */
    private loadMainComponent(): Promise<void> {
        const methodName = 'loadMainComponent';
        return new Promise<void>((resolve, reject) => {
            this.componentManager.addComponent({
                component: 'com.windream.mapping.mainframe',
                configuration: {
                    viewConfig: this.currentViewConfig
                },
                guid: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                isTitleVisible: false,
                name: { en: MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID },
                position: `#${this.MAINFRAME_ELEMENT_ID}`,
                style: Style.default()
            }, this.pubSubHandler)
                .then(() => {
                    MappingUiHelperUtil.addMainframeConfigurationPubSub(this.pubSubHandler);
                    this.pubSubHandler.subscribe(MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'SavedConfig', (newConfigPromise: Promise<SharedSetting>) => {
                        newConfigPromise.then((newConfig) => {
                            if (this.currentViewConfig) {
                                this.currentViewConfig.settings = newConfig;
                                this.updateConfig(this.currentViewConfig, MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID);
                            }
                        }).catch((error) => {
                            this.logger.error(this.className, methodName, 'SavedConfig pubsub catched error', error);
                        });
                    });
                    resolve();
                }).catch((err: Error) => {
                    this.logger.error(this.className, 'loadMainComponent', 'Failed to load PubSub main component', err);
                    reject(err);
                });
        });
    }

    /**
     * Add the left panel component.
     *
     * @private
     * @returns {Promise<void>}
     * @memberof MappingUiHelper
     */
    private loadPanelComponent(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.componentManager.addComponent({
                component: 'com.windream.mapping.addMappingPanel',
                configuration: {
                    viewConfig: this.currentViewConfig
                },
                guid: MappingUiHelperUtil.COMPONENTPANEL_COMPONENT_GUID,
                isTitleVisible: false,
                name: { en: MappingUiHelperUtil.COMPONENTPANEL_COMPONENT_GUID },
                position: `#${this.COMPONENTPANEL_ELEMENT_ID} .config-panel-content`,
                style: Style.default()
            }, this.pubSubHandler)
                .then(() => {
                    resolve();
                }).catch((err: Error) => {
                    this.logger.error(this.className, 'loadPanelComponent', 'Failed to load PubSub main component', err);
                    reject(err);
                });
        });
    }

    /**
     * Applies the current mapping to the given view coniguration.
     *
     * @private
     * @param {ViewConfig} viewConfig The view configuration.
     * @memberof MappingUiHelper
     */
    private applyMappingToDeviceConfiguration(viewConfig: ViewConfig): void {
        if (this.currentViewConfig) {
            if (this.currentViewConfig.settings) {
                viewConfig.settings = this.currentViewConfig.settings;
            }
            // Work on the same reference without overwriting it later
            // Not needed for other devices, because switching requires saving and therefore reloading.
            if (viewConfig.device === this.currentViewConfig?.device) {
                const mappedConfig = this.applyMapping(this.currentViewConfig);

                this.updateConfig(mappedConfig, MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID);
                this.pubSubHandler.publish(MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'UpdatedConfig', this.currentViewConfig);
            } else {
                const mappedConfig = this.applyMapping(viewConfig);

                this.configUiHelper?.updateCurrentViewWithViewConfig(mappedConfig);
            }

        }
    }

    /**
     * Handles the apply mapping intent.
     *
     * @private
     * @memberof MappingUiHelper
     */
    private handleApplyMappingIntent(): void {
        if (this.configUiHelper) {

            // Apply the mappings to all device configurations.
            const promises = new Array<Promise<ViewConfig | undefined>>();

            promises.push(this.configUiHelper.getViewConfig(DWCore.Common.Devices.DESKTOP));
            promises.push(this.configUiHelper.getViewConfig(DWCore.Common.Devices.TABLET));
            promises.push(this.configUiHelper.getViewConfig(DWCore.Common.Devices.PHONE));

            Promise.all(promises).then((viewConfigurations: ViewConfig[]) => {
                viewConfigurations.forEach((viewConfig) => {
                    this.applyMappingToDeviceConfiguration(viewConfig);
                });
            }).then(() => {
                this.notificationHelper.success({
                    body: this.languageProvider.get('framework.toastr.mappingsuccess.body'),
                    title: this.languageProvider.get('framework.generic.success')
                });
            }).catch((err) => {
                this.logger.error(this.className, 'handleApplyMappingIntention', 'Failed to apply mappings to all view configurations.', err);
            });

        }
    }

    /**
     * Loads the mapping action panel as component.
     *
     * @private
     * @returns {Promise<void>}
     * @memberof MappingUiHelper
     */
    private loadActionComponent(): Promise<void> {
        const methodName = 'loadActionComponent';
        return new Promise<void>((resolve, reject) => {
            this.componentManager.addComponent({
                component: 'com.windream.mapping.actions',
                configuration: {
                    viewConfig: this.currentViewConfig
                },
                guid: MappingUiHelperUtil.ACTION_COMPONENT_GUID,
                isTitleVisible: false,
                name: { en: MappingUiHelperUtil.ACTION_COMPONENT_GUID },
                position: `#${this.ACTIONPANEL_ELEMENT_ID} .config-panel-content`,
                style: Style.default()
            }, this.pubSubHandler)
                .then(() => {
                    this.pubSubHandler.subscribe(MappingUiHelperUtil.ACTION_COMPONENT_GUID, 'ApplyMapping', (voidPromise: Promise<void>) => {
                        voidPromise.then(() => {
                            this.askToApplyMapping().then((shouldApplyMapping) => {
                                if (shouldApplyMapping) {
                                    this.handleApplyMappingIntent();
                                }
                            }).catch((error) => {
                                this.logger.error(this.className, methodName, 'Failed to ask to apply mapping', error);
                            });
                        }).catch((error) => {
                            this.logger.error(this.className, methodName, 'ApplyMapping pubsub catched error', error);
                        });
                    });
                    resolve();
                }).catch((err: Error) => {
                    this.logger.error(this.className, 'loadActionComponent', 'Failed to load PubSub main component', err);
                    reject(err);
                });
        });
    }
    /**
     * Updates the whole config.
     * Publishes changes to each component.
     * 
     * @private
     * @param {ViewConfig} config The new config to use.
     * @param {string} senderGuid The GUID of the component that triggered the publish.
     * @memberof MappingUiHelper
     */
    private updateConfig(config: ViewConfig, senderGuid?: string): void {
        this.currentViewConfig = config;

        this.publishCurrentConfig(senderGuid);
    }

    /**
     * Publishes changes to all components expect the sender GUID.
     * 
     * @private
     * @param {string} senderGuid The GUID of the component that triggered the publish.
     * @memberof MappingUiHelper
     */
    private publishCurrentConfig(senderGuid?: string): void {

        if (senderGuid !== MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID) {
            this.pubSubHandler.publish(MappingUiHelperUtil.MAINFRAME_COMPONENT_GUID, 'UpdatedConfig', this.currentViewConfig);
        }
        this.handleConfigChange();
    }


    /**
     * Handles a change in the configuration by emitting the `onConfigChange` callback
     * with the information of whether the configuration has changed.
     *
     * @private
     * @memberof MappingUiHelper
     */
    private handleConfigChange(): void {
        if (this.onConfigChange) {
            // Use stringify = true because otherwise the performance is way too bad as it runs initially
            const isDirty = !Utils.Instance.isDeepEqual(Utils.Instance.sortObjectPropertiesDeep(this.currentViewConfig), Utils.Instance.sortObjectPropertiesDeep(this.viewConfigBackup), true);
            this.onConfigChange(isDirty);
        }
    }

    /**
     * Prompts the user if he wants to apply the mapping.
     *
     * @private
     * @returns {Promise<boolean>} Promise to resolve with whether mapping should be applied.
     * @memberof MappingUiHelper
     */
    private async askToApplyMapping(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.popupHelper.openConfirmationPopup(() => {
                // Yes
                resolve(true);
            }, () => {
                // No
                resolve(false);
            }, {
                body: this.languageProvider.get('framework.mapping.confirmApply.body'),
                title: this.languageProvider.get('framework.mapping.confirmApply.title')
            });
        });
    }

    /**
     * Applies the current mapping using the Config Mapper.
     * Manipulates the passed view config.
     *
     * @private
     * @param {ViewConfig} viewConfig View config to apply mapping to.
     * @returns {ViewConfig} The view config with the applied mapping.
     * @memberof MappingUiHelper
     */
    private applyMapping(viewConfig: ViewConfig): ViewConfig {
        const mappings = new Array<IConfigMapping<any>>();
        if (viewConfig.settings.paths) {
            for (const key in viewConfig.settings.paths) {
                if (!viewConfig.settings.paths.hasOwnProperty(key)) {
                    continue;
                }
                const entry = Utils.deepClone(viewConfig.settings.paths[key]) as any as IConfigMapping<any>;
                entry.dataType = 'DATATYPE_WINDREAM_PATH';
                delete entry.displayValue;
                delete entry.isImported;
                mappings.push(entry);
            }
        }
        if (viewConfig.settings.objectTypes) {
            for (const key in viewConfig.settings.objectTypes) {
                if (!viewConfig.settings.objectTypes.hasOwnProperty(key)) {
                    continue;
                }
                const entry = Utils.deepClone(viewConfig.settings.objectTypes[key]) as any as IConfigMapping<any>;
                entry.dataType = 'DATATYPE_WINDREAM_OBJECTTYPE';
                delete entry.displayValue;
                delete entry.isImported;
                mappings.push(entry);
            }
        }
        if (viewConfig.settings.choiceLists) {
            for (const key in viewConfig.settings.choiceLists) {
                if (!viewConfig.settings.choiceLists.hasOwnProperty(key)) {
                    continue;
                }
                const entry = Utils.deepClone(viewConfig.settings.choiceLists[key]) as any as IConfigMapping<any>;
                entry.dataType = 'DATATYPE_WINDREAM_LIST';
                delete entry.displayValue;
                delete entry.isImported;
                mappings.push(entry);
            }
        }
        if (viewConfig.settings.indices) {
            for (const key in viewConfig.settings.indices) {
                if (!viewConfig.settings.indices.hasOwnProperty(key)) {
                    continue;
                }
                const entry = Utils.deepClone(viewConfig.settings.indices[key]) as any as IConfigMapping<any>;
                entry.dataType = 'DATATYPE_WINDREAM_COLUMN';
                delete entry.displayValue;
                delete entry.isImported;
                mappings.push(entry);
            }
        }
        // Required because currentValue and newValue are complex objects
        for (const mapping of mappings) {
            let complexCurrentValue;
            let complexNewValue;
            if (mapping.dataType === 'DATATYPE_WINDREAM_COLUMN') {
                complexNewValue = mapping.newValue;
                complexCurrentValue = mapping.currentValue;
            } else if (mapping.dataType === 'DATATYPE_WINDREAM_PATH') {
                complexNewValue = mapping.newValue;
                if (mapping.currentValue?.value) {
                    complexCurrentValue = mapping.currentValue?.value;
                } else {
                    complexCurrentValue = mapping.currentValue;
                }
            } else {
                complexNewValue = mapping.newValue?.value;
                complexCurrentValue = mapping.currentValue?.value;
            }
            mapping.newValue = complexNewValue;
            mapping.currentValue = complexCurrentValue;
        }
        // Make sure indied mappings are before object types array-wise.
        mappings.sort((a: any, b: any) => {
            return a.dataType.localeCompare(b.dataType);
        });
        viewConfig.components = viewConfig.components.map((component) => this.configMapper.applyMapping(component, mappings));
        return viewConfig;
    }
}