import { IdentityMapper } from '..';
import { CopyDocumentDTO, DocumentResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler';
import { DocumentDetails } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { CopyRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The Copy action is used to Copy a document within windream
 * 
 * @export
 * @class Copy
 * @extends {ServiceAction}
 */
export class Copy extends ServiceAction {

    /**
     * Creates an instance of Copy.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * 
     * @memberof Copy
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'copy';
    }

    /**
     * Creates a copy of a given document.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {CopyRequestOptions} copyRequestOptions The request options.
     * @returns {Promise<DocumentDetails>} A promise, which will resolve with the server response.
     * @memberof Copy
     */
    public async do(copyRequestOptions: CopyRequestOptions): Promise<DocumentDetails> {
        return new Promise<DocumentDetails>((resolve, reject) => {

            if (!copyRequestOptions.source) {
                reject(new Error('the argument \'sourceIdentity\' is null or undefined'));
            }
            if (!(copyRequestOptions.target && copyRequestOptions.target.name && copyRequestOptions.target.location)) {
                reject(new Error('the argument \'targetIdentity\' is null or undefined or invalid (name and location must be set)'));
            }
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/documents/Copy`;
            const httpResourcePtr = new HttpResourcePointer(
                'POST',
                tempUrl,
                {
                    Flags: 0,
                    Item: IdentityMapper.getWebServiceIdentity(copyRequestOptions.source),
                    ResponseDetails: 0,
                    Target: IdentityMapper.getWebServiceIdentity(copyRequestOptions.target)
                } as CopyDocumentDTO
            );
            this.requestExecutor.executeRequest(httpResourcePtr, copyRequestOptions.requestOptions)
                .then((response: IServiceResponse<DocumentResponseContainerDTO>) => {
                    if (response.data?.HasErrors || !response.data?.Item) {
                        this.logger.error('Copy', 'do', 'Can\'t copy document', response);
                        reject(new Error('Can\'t copy document'));
                    } else {
                        const result = DocumentDetails.fromDto(response.data.Item);
                        resolve(result);
                    }
                }).catch((err: Error) => {
                    this.logger.error('Copy', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}