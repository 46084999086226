import { UploadFlags } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { WindreamIdentity } from '../../dynamicWorkspace';
import { FileUploadContainer } from '.';


/**
 * Represents a WebSocketFile.
 *
 * @export
 * @class WebSocketFile
 */
export class WebSocketFile {

    /**
     * The identity.
     *
     * @type {WindreamIdentity}
     * @memberof WebSocketFile
     */
    public identity: WindreamIdentity;

    /**
     * The flags.
     *
     * @type {UploadFlags}
     * @memberof WebSocketFile
     */
    public flags: UploadFlags;

    /**
     * The file or a blob.
     *
     * @type {(File | Blob)}
     * @memberof WebSocketFile
     */
    public file: File | Blob;

    /**
     * Creates an instance of WebSocketFile.
     * 
     * @param {WindreamIdentity} identity The identity.
     * @param {(File | Blob))} file The file.
     * @param {UploadFlags} flags The flags.
     * @memberof WebSocketFile
     */
    public constructor(identity: WindreamIdentity, file: File | Blob, flags: UploadFlags) {
        this.identity = identity;
        this.file = file;
        this.flags = flags;
    }

    /**
     * Generates the upload container for this file.
     *
     * @returns {FileUploadContainer} The upload container.
     * @memberof WebSocketFile
     */
    public generateUploadContainer(): FileUploadContainer {
        return new FileUploadContainer(this.identity, this.file.size, this.flags);
    }
}