import { DynamicWorkspace } from '../../../../../../typings';
import { Member, MemberTypes } from '../../../../../../typings/core';
import { ILanguageProvider } from '../../../../dynamicWorkspace';
import { RightsModel } from '../models/rightsModel';

/**
 * The view rights ui for views.
 *
 * @export
 * @class ViewRightsUiPanel
 */
export class ViewRightsUiPanel {

    private jQuery: JQueryStatic;
    private languageProvider: ILanguageProvider;
    private publicApi: DynamicWorkspace;
    private readonly className = 'ViewMetadataUiPanel';

    /**
     * This callback is called once the rights have changed.
     *
     * @memberof ViewRightsUiPanel
     */
    public onViewRightsChanged?: (viewRights: Array<RightsModel>) => void;

    /**
     * Creates an instance of ViewRightsUiPanel.
     * @param {JQueryStatic} jQuery JQuery.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {DynamicWorkspace} publicApi The public api.
     * @memberof ViewRightsUiPanel
     */
    public constructor(jQuery: JQueryStatic, languageProvider: ILanguageProvider, publicApi: DynamicWorkspace) {
        this.jQuery = jQuery;
        this.languageProvider = languageProvider;
        this.publicApi = publicApi;
    }
    /**
     * Gets all users.
     *
     * @private
     * @returns {Promise<Member[]>} All users.
     * @memberof ViewRightsUiPanel
     */
    private async getAllUsers(): Promise<Member[]> {
        return new Promise<Member[]>((resolve, reject) => {
            this.publicApi.Services.Users.getAllUsers()
                .then((users) => {
                    const foundUsers = new Array<Member>();
                    users.forEach((user) => {
                        const foundUser = {
                            id: user.id,
                            name: user.name,
                            memberType: MemberTypes.user,
                            domain: user.domain,
                            fullName: user.fullName
                        } as Member;
                        foundUsers.push(foundUser);
                    });
                    resolve(foundUsers);
                }).catch((error) => reject(error));
        });
    }

    /**
     * Gets all user groups.
     *
     * @private
     * @returns {Promise<Member[]>} All users group.
     * @memberof ViewRightsUiPanel
     */
    private async getAllUserGroups(): Promise<Member[]> {
        return new Promise<Member[]>((resolve, reject) => {
            this.publicApi.Services.Users.getAllUserGroups().then((data) => resolve(data))
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Renders the ui into the target element.
     *
     * @param {HTMLElement} targetElement The target element.
     * @param {Array<RightsModel>} preselectedRightBearers The preselected rights.
     * @memberof ViewRightsUiPanel
     */
    public render(targetElement: HTMLElement, preselectedRightBearers: Array<RightsModel>): void {
        const loadingIndicator = this.jQuery('<div class="wd-system-settings-create-view-loading-indicator"></div>');
        loadingIndicator.appendTo(targetElement);
        loadingIndicator.dxLoadIndicator();
        Promise.all([this.getAllUserGroups(), this.getAllUsers()]).then((allMembers) => {
            // eslint-disable-next-line prefer-const
            let dxDataGridInstance: DevExpress.ui.dxDataGrid<any, any>;
            const allAvailAbleMembers = [...allMembers[0], ...allMembers[1]];
            const availableUsersAndGroups = new Array<RightsModel>();
            allAvailAbleMembers.forEach((member) => {
                if (!preselectedRightBearers.find((entry) => entry.id === member.id)) {
                    availableUsersAndGroups.push(new RightsModel(member.id, member.domain || '',
                        member.name, member.memberType, true, false));
                }
            });
            loadingIndicator.remove();
            const userSelectBox = this.jQuery('<div></div>');
            const dxSelectBox = userSelectBox.dxSelectBox({
                dataSource: new DevExpress.data.DataSource({
                    paginate: true,
                    store: availableUsersAndGroups
                }),
                displayExpr: (value: RightsModel): string => {
                    if (value) {
                        return value.name;
                    }
                    return '';
                },
                hint: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.selectBox.placeholder'),
                itemTemplate: (itemData: RightsModel) => {
                    const template = $('<div>');
                    if (itemData) {
                        const entityElement = $('<div class="wd-rights-member-entity">');
                        if (entityElement && itemData.memberType === MemberTypes.user) {
                            entityElement.append('<span class="wd-icon user" aria-hidden="true">');
                        } else if (itemData.memberType === MemberTypes.group) {
                            entityElement.append('<span class="wd-icon user-group" aria-hidden="true">');
                        }
                        template.append(entityElement);
                        template.append($('<div class="wd-rights-member-name">').text(itemData.name));
                        template.append($('<div class="wd-rights-member-details">').text(itemData.domain + '\\' + itemData.name));
                    }
                    return template;
                },
                onValueChanged: (data) => {
                    const component = data.component;
                    if (component) {
                        component.instance().option('open', false);
                        component.instance().option('value', '');
                    }
                    if (data.value) {
                        preselectedRightBearers.push(data.value);
                        const index = availableUsersAndGroups.findIndex((rights) => rights === data.value);
                        if(index > -1 && dxSelectBox) {
                            availableUsersAndGroups.splice(index, 1);
                            dxSelectBox.option('dataSource', new DevExpress.data.DataSource({
                                paginate: true,
                                store: availableUsersAndGroups
                            }));
                        }
                        if (this.onViewRightsChanged) {
                            this.onViewRightsChanged(preselectedRightBearers);
                        }
                    }
                    if (dxDataGridInstance) {
                        dxDataGridInstance.option('dataSource', preselectedRightBearers);
                    }
                },
                placeholder: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.selectBox.placeholder'),
                searchEnabled: true,
                searchExpr: ['name', 'fullName', 'domain']
            }).dxSelectBox('instance');
            userSelectBox.appendTo(targetElement);
            const columns = [
                {
                    allowFiltering: false, allowEditing: false, caption:
                        this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.name'), dataField: 'name',
                    dataType: 'string', minWidth: 150, fixed: true
                },
                {
                    allowFiltering: false, allowEditing: false, caption:
                        this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.domain'), dataField: 'domain', dataType: 'string', minWidth: 150
                },
                {
                    allowFiltering: false,
                    dataField: 'memberType', groupCellTemplate: (element, info) => {
                        const icon = info.value === MemberTypes.user ? 'user' : 'user-group';
                        const translation = info.value === MemberTypes.user ?
                            this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.user') :
                            this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.userGroup');
                        return element[0].innerHTML = `<span class="wd-icon ${icon}" aria-hidden="true"></span>&nbsp;${translation}`;
                    }, groupIndex: 0
                }, {
                    allowFiltering: false,
                    caption: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.rights.read'),
                    dataField: 'canRead',
                    dataType: 'boolean',
                    allowEditing: true
                },
                {
                    allowFiltering: false,
                    caption: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.rights.administrate'),
                    dataField: 'canAdministrate',
                    dataType: 'boolean',
                    allowEditing: true
                }
            ] as DevExpress.ui.dxDataGridColumn[];
            const rightsTable = this.jQuery('<div></div>');
            dxDataGridInstance = rightsTable.dxDataGrid({
                allowColumnResizing: true,
                columnAutoWidth: true,
                columnFixing: {
                    enabled: true
                },
                onEditorPreparing: (preparingEvent) => {
                    if (preparingEvent.dataField === 'canRead' && preparingEvent.row) {
                        const rowData = preparingEvent.row.data as RightsModel;
                        preparingEvent.cancel = rowData.canAdministrate;
                    }
                    if (preparingEvent.dataField === 'canAdministrate' && preparingEvent.row) {
                        const rowData = preparingEvent.row.data as RightsModel;
                        if(rowData.canAdministrate && preselectedRightBearers.filter((right) => right.canAdministrate).length === 1) {
                            preparingEvent.editorOptions.disabled= true;
                        }
                    }
                },
                onRowUpdated: () => {
                    if (this.onViewRightsChanged) {
                        this.onViewRightsChanged(preselectedRightBearers);
                    }
                },
                editing: {
                    allowUpdating: true,
                    mode: 'cell'
                },
                height: '40vh',
                columns: columns,
                dataSource: preselectedRightBearers,
                filterRow: { visible: false },
                groupPanel: {
                    allowColumnDragging: false,
                    visible: false
                },
                grouping: {
                    contextMenuEnabled: false
                },
                noDataText: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.noData'),
                scrolling: {
                    useNative: true
                },
                showBorders: true
            }).dxDataGrid('instance');
            rightsTable.appendTo(targetElement);
        }).catch((error) => {
            const errorSpan = this.jQuery(`<span>${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.rights.errorFetching')}</span>`);
            errorSpan.appendTo(targetElement);
            this.publicApi.Logger.error(this.className, 'render', 'Failed to load users', error);
        });
    }
}