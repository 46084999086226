import { IWebsocketFactory } from './interfaces';

/**
 * Factory to create Websocket connections.
 * 
 * @export
 * @class WebsocketFactory
 * @implements {IWebsocketFactory}
 */
export class WebsocketFactory implements IWebsocketFactory {
    /**
     * Creates a new Websocket connection to the given URL.
     * 
     * @param {string} url URL to create Websocket connection to.
     * @returns {WebSocket} The Websocket connection.
     * 
     * @memberof WebsocketFactory
     */
    public create(url: string): WebSocket {
        if (window.location.protocol === 'https:' && url.indexOf('wss://') === -1 && url.indexOf('ws://') >= 0) {
            url = url.replace('ws://', 'wss://');
        }
        return new WebSocket(url);
    }
}