import { Utils } from '../common';
import { ITemplateExtension } from '../extensions';
import { ILanguageProvider } from '../language';
import { ISubViewManager, SubViewConfig } from '../loader';
import { IAppMenuHandler, ISubViewNavigationHandler } from './interfaces';
import { AppMenuItem } from './models';
import { NavigationHandler } from './navigationHandler';

/**
 * Class for handling the sub view navigation.
 * 
 * @export
 * @class SubViewNavigationHandler
 */
export class SubViewNavigationHandler extends NavigationHandler implements ISubViewNavigationHandler {
    /**
     * Prefix for navigation list items inside the DOM.
     * 
     * @static
     * 
     * @memberof SubViewNavigationHandler
     */
    public static ITEM_PREFIX = 'wd-sub-view-navigation-item';

    /**
     * Callback to execute when add sub view is clicked.
     * 
     * @memberof SubViewNavigationHandler
     */
    public onAddSubView?: () => void;
    /**
     * Callback to execute when a sub view is clicked.
     * 
     * @memberof SubViewNavigationHandler
     */
    public onSelect?: (subViewId: string) => void;
    /**
     * Callback to execute when rename sub view is clicked.
     * 
     * @memberof SubViewNavigationHandler
     */
    public onRename?: (subViewId: string) => void;
    /**
     * Callback to execute when delete sub view is clicked.
     * 
     * @memberof SubViewNavigationHandler
     */
    public onDelete?: (subViewId: string) => void;

    private templateExtension: ITemplateExtension;
    private subViewManager: ISubViewManager;
    private appMenuHandler: IAppMenuHandler;
    private languageProvider: ILanguageProvider;
    private eventListener?: (e: Event) => void;
    private renameMenuItem: AppMenuItem;
    private removeMenuItem: AppMenuItem;
    private subViews: SubViewConfig[];
    private actviceSubViewId?: string;

    /**
     * Creates an instance of SubViewNavigationHandler.
     * 
     * @param {HTMLElement} element 
     * @param {ITemplateExtension} templateExtension 
     * @memberof SubViewNavigationHandler
     */
    public constructor(element: HTMLElement, templateExtension: ITemplateExtension, subViewManager: ISubViewManager, appMenuHandler: IAppMenuHandler, languageProvider: ILanguageProvider) {
        super(element, SubViewNavigationHandler.ITEM_PREFIX);

        this.init();

        this.element = element;
        this.templateExtension = templateExtension;
        this.subViewManager = subViewManager;
        this.languageProvider = languageProvider;

        this.appMenuHandler = appMenuHandler;

        this.subViews = new Array<SubViewConfig>();

        // Initialize root AppMenu items
        this.renameMenuItem = new AppMenuItem(this.languageProvider.get('framework.config.subViews.rename'));
        this.renameMenuItem.children = new Array<AppMenuItem>();
        this.removeMenuItem = new AppMenuItem(this.languageProvider.get('framework.config.subViews.delete'));
        this.removeMenuItem.children = new Array<AppMenuItem>();

        this.appMenuHandler.addMenuItems([this.removeMenuItem, this.renameMenuItem]);
    }

    /**
     * Renders the empty sub view navigation.
     * 
     * @memberof SubViewNavigationHandler
     */
    public render(): void {
        this.templateExtension.render(this.element, require('./templates/subViewNavigation.html'), {
            prefix: SubViewNavigationHandler.ITEM_PREFIX,
            subViews: this.subViews
        }, true);

        this.templateExtension.addHelper('ifSubViewIsActive', (subViewId: string, options: any) => {
            if (subViewId === this.subViewManager.getCurrentSubViewId()) {
                return options.fn(this);
            } else {
                return options.inverse(this);
            }
        }, this.element);
        this.adjustWidth();
    }

    /**
     * Updates the view of the sub view navigation with the given sub views.
     * 
     * @param {SubViewConfig[]} subViews Sub views to render.
     * @param {string} [activeViewId] ID of the currently active sub view. If omitted, first sub view will be used.
     * @memberof SubViewNavigationHandler
     */
    public update(subViews: SubViewConfig[], activeViewId?: string): void {
        // Avoid overriding the array to not destroy binding in the view
        this.subViews.length = 0;
        this.subViews.push(...subViews);
        this.actviceSubViewId = activeViewId || this.subViews[0].id;
        this.templateExtension.render(this.element, require('./templates/subViewNavigation.html'), {
            subViews: this.subViews
        }, true);
        this.addEventListener();

        // Reset previous views
        this.renameMenuItem.children.length = 0;
        this.removeMenuItem.children.length = 0;

        this.subViews.forEach((subView) => {
            const renameSubViewMenuItem = new AppMenuItem(subView.name);
            renameSubViewMenuItem.onClick = () => {
                if (this.onRename) {
                    this.onRename(subView.id);
                }
            };
            if (this.renameMenuItem.children) {
                this.renameMenuItem.children.push(renameSubViewMenuItem);
            }
            const deleteSubViewMenuItem = new AppMenuItem(subView.name);
            deleteSubViewMenuItem.onClick = () => {
                if (this.onDelete) {
                    this.onDelete(subView.id);
                }
            };
            if (subView.id === this.actviceSubViewId) {
                deleteSubViewMenuItem.isDisabled = true;
            }
            this.removeMenuItem.children.push(deleteSubViewMenuItem);
        });
        this.appMenuHandler.render();
        this.adjustWidth();
        if (this.actviceSubViewId) {
            this.setActive(this.actviceSubViewId);
        }
    }

    /**
     * Clears the element.
     * 
     * @memberof SubViewNavigationHandler
     */
    public clear(): void {
        this.element.innerHTML = '';
        if (this.eventListener) {
            this.element.removeEventListener('click', this.eventListener);
        }
    }

    /**
     * Registers the event handler to the element.
     * 
     * @private
     * @memberof SubViewNavigationHandler
     */
    private addEventListener(): void {
        if (this.eventListener) {
            this.element.removeEventListener('click', this.eventListener);
        }
        this.element.addEventListener('click', this.eventListener = (e: Event) => {
            const itemType = Utils.getWdAttribute(e.target as HTMLElement, 'type');
            if (itemType === 'addSubView') { // Add new sub view
                if (this.onAddSubView) {
                    e.preventDefault();
                    this.onAddSubView();
                }
            } else if (itemType !== 'more') { // Select a sub view
                const itemId = Utils.getWdAttribute(e.target as HTMLElement, 'id');
                if (itemId) {
                    e.preventDefault();
                    this.setActive(itemId);
                    // Adjust width in case now active view is inside the more-dropdown
                    this.adjustWidth();
                    if (this.onSelect) {
                        this.onSelect(itemId);
                    }
                }
            }
        });
    }

    /** 
     * Sets the sub view with the given ID to ative state.
     * Remove active state from previously active element.
     * 
     * @private
     * @param {string} subViewId ID of the sub view to set active.
     * @memberof SubViewNavigationHandler
     */
    private setActive(subViewId: string): void {
        const oldItem = this.element.querySelector<HTMLElement>('li.active');
        if (oldItem) {
            oldItem.classList.remove('active');
        }
        const newItem = this.element.querySelector<HTMLElement>(`li[data-wd-id="${subViewId}"]`);
        if (newItem) {
            newItem.classList.add('active');
        }
        this.actviceSubViewId = subViewId;
    }
}