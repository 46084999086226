/**
 * Model to represent a group inside the navigation.
 * 
 * @export
 * @class NavigationGroup
 */
export class NavigationGroup {
    /**
     * The ID of the view that is visible in the URL.
     * 
     * @type {string}
     * @memberof NavigationGroup
     */
    public id: string;
    /**
     * The path of the view.
     * 
     * @type {string}
     * @memberof NavigationGroup
     */
    public path: string;
    /**
     * The name of the view that is visible in the navigation.
     * 
     * @type {string}
     * @memberof NavigationGroup
     */
    public name: string;

    /**
     * Whether the view is currently active.
     * 
     * @type {boolean}
     * @memberof NavigationGroup
     */
    public isActive: boolean;

    /**
     * Whether this navigation group should be displayed.
     *
     * @type {boolean}
     * @memberof NavigationGroup
     */
    public isDisplayed: boolean;

    /**
     * The icon of the navigation.
     *
     * @type {string}
     * @memberof NavigationGroup
     */
    public icon?: string;

    /**
     *Creates an instance of NavigationGroup.
     *
     * @param {string} id The id.
     * @param {string} path The path.
     * @param {string} name The name.
     * @param {boolean} isDisplayed Whether it should display this navigation group.
     * @param {string} icon The icon of this navigation group.
     * @memberof NavigationGroup
     */
    public constructor(id: string, path: string, name: string, isDisplayed: boolean, icon?: string) {
        this.id = id;
        this.path = path;
        this.name = name;
        this.isActive = false;
        this.isDisplayed = isDisplayed;
        this.icon = icon;
    }
}