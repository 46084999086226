import { IdentityChangedType } from '../../../../typings/events';
import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { DirectoryResponseContainerDTO, MoveDirectoryDTO } from '../../../typings/windreamWebService/Windream.WebService.Directories';
import { IServiceResponse } from '../../ajaxHandler';
import { WindreamIdentity } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { IdentityChangedHandler } from '../../messages/events';
import { IdentityMapper } from '../identityMapper';
import { MoveBulkRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Moves directories within windream.
 *
 * @export
 * @class MoveDirectoryBulk
 * @extends {ServiceAction}
 */
export class MoveDirectoryBulk extends ServiceAction {

    private identityChangedHandler: IdentityChangedHandler;
    /**
     * Creates an instance of MoveDirectoryBulk.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {IdentityChangedHandler} identityChangedHandler The identity changed handler.
     * @memberof MoveDirectoryBulk
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger,
        identityChangedHandler: IdentityChangedHandler) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'moveDirectoryBulk';
        this.identityChangedHandler = identityChangedHandler;
    }

    /**
     * Move directories within windream to its destination.
     *
     * @param {MoveBulkRequestOptions} moveRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolves with the server response.
     * @memberof MoveDirectoryBulk
     */
    public async do(moveRequestOptions: MoveBulkRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const tempSourceDirectoryDTOs = new Array<MoveDirectoryDTO>();
            moveRequestOptions.sourceIdentities.forEach((identity: WindreamIdentity) => {
                const tempIdentityDTO = {
                    Item: IdentityMapper.getWebServiceIdentity(identity),
                    ResponseDetails: moveRequestOptions.responseDetails ? moveRequestOptions.responseDetails : 0,
                    Target: IdentityMapper.getWebServiceIdentity(moveRequestOptions.targetDirectory)
                } as MoveDirectoryDTO;
                tempIdentityDTO.Target.Entity = <number>moveRequestOptions.targetEntity;
                tempSourceDirectoryDTOs.push(tempIdentityDTO);
            });
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/directories/MoveBulk',
                tempSourceDirectoryDTOs
            ), moveRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO[]>) => {
                const responseErrorData = response.data?.filter((element: DirectoryResponseContainerDTO) => {
                    if (!element.HasErrors) {
                        const identityDetails = this.modelFactory.createWindreamIdentityDetailsFromDto(element.Item);
                        const previousIdentity = moveRequestOptions.sourceIdentities.find((identity) => identity.id === identityDetails.id);
                        if (previousIdentity) {
                            this.identityChangedHandler.distributeEvent({
                                type: IdentityChangedType.Move,
                                previous: previousIdentity,
                                current: identityDetails
                            });
                        }
                    }
                    return element.HasErrors;
                });
                if (responseErrorData && responseErrorData.length > 0) {
                    // TODO: Use responseErrorData for specific reporting.
                    this.logger.error('MoveDirectoryBulk', 'do', 'unable to move directories', responseErrorData);
                    reject(new Error('Unable to move directories'));
                } else {
                    resolve();
                }
            }).catch((error) => {
                this.logger.error('MoveDirectoryBulk', 'do', 'Failed to execute request', error);
                reject(error);
            });

        });
    }
}