import { DWCore, Logger, Utils } from '../dynamicWorkspace';
import { IMessageBus } from './iMessageBus';

/**
 * The default implementation of the message bus interface.
 *
 * @export
 * @class MessageBus
 * @implements {IMessageBus}
 */
export class MessageBus implements IMessageBus {

    private readonly logger: Logger;
    private readonly messageProvider: DWCore.Messages.MessageProvider;
    private readonly subscriptions: Map<string, (message: DWCore.Messages.Message) => void>;


    /**
     * Creates an instance of MessageBus.
     *
     * @param {Logger} logger The logger.
     * @param {DWCore.Messages.MessageProvider} messageProvider The message provider.
     * @memberof MessageBus
     */
    public constructor(logger: Logger, messageProvider: DWCore.Messages.MessageProvider) {
        this.logger = logger;
        this.messageProvider = messageProvider;

        if (!this.messageProvider) {
            this.logger.warn('MessageBus', 'constructor', 'Message provider', 'No message provider was found. The message bus will not be available!');
        } else {
            this.messageProvider.onMessage = this.onMessageHandler.bind(this);
        }

        this.subscriptions = new Map<string, (message: DWCore.Messages.Message) => void>();
    }


    /**
     * Subscribes the given message handler to the specified topic.
     *
     * @param {string} subscriberId The subscriber identifier.
     * @param {string} topic The topic.
     * @param {(message: DWCore.Messages.Message) => void} messageHandler The message handler.
     * @returns {Promise<void>}
     * @memberof MessageBus
     */
    public subscribe(subscriberId: string, topic: string, messageHandler: (message: DWCore.Messages.Message) => void): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (Utils.isStringNullOrWhitespace(subscriberId)) {
                reject('Invalid subscriber identifier: not defined');
                return;
            } else if (Utils.isStringNullOrWhitespace(topic)) {
                reject('Invalid topic: not defined');
                return;
            } else if (!messageHandler) {
                reject('Invalid message handler: not defined');
                return;
            } else if (typeof (messageHandler) !== 'function') {
                reject('Invalid message handler: no function');
                return;
            } else if (this.subscriptions.has(topic)) {
                reject(`${topic} already subscribed`);
                return;
            } else if (!this.messageProvider) {
                reject('No message provider was found. The message bus will not be available!');
                return;
            }

            const subscriptionKey = this.createSubscriptionKey(subscriberId, topic);
            if (!this.subscriptions.has(subscriptionKey)) {
                this.subscriptions.set(subscriptionKey, messageHandler);

                await this.messageProvider.subscribe(subscriberId, topic);
            }

            resolve();
        });
    }

    /**
     * Unsubscribes from the specified topic.
     *
     * @param {string} subscriberId The subscriber identifier.
     * @param {string} topic The topic.
     * @memberof MessageBus
     */
    public unsubscribe(subscriberId: string, topic: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (Utils.isStringNullOrWhitespace(subscriberId)) {
                reject('Invalid subscriber identifier: not defined');
                return;
            } else if (Utils.isStringNullOrWhitespace(topic)) {
                reject('Invalid topic: not defined');
                return;
            } else if (!this.messageProvider) {
                reject('No message provider was found. The message bus will not be available!');
                return;
            }

            const subscriptionKey = this.createSubscriptionKey(subscriberId, topic);
            if (this.subscriptions.has(subscriptionKey)) {
                await this.messageProvider.unsubscribe(subscriberId, topic);

                this.subscriptions.delete(subscriptionKey);
            }

            resolve();
        });
    }

    /**
     * Creates a subscription key.
     *
     * @private
     * @param {string} subscriberId The subscriber identifier.
     * @param {string} topic The topic.
     * @memberof MessageBus
     */
    private createSubscriptionKey(subscriberId: string, topic: string) {
        return subscriberId + '_' + topic;
    }

    /**
     * The "onMessage" event handler.
     *
     * @private
     * @param {DWCore.Messages.Message} message The message.
     * @memberof MessageBus
     */
    private onMessageHandler(message: DWCore.Messages.Message): void {
        if (!Utils.isDefined(message)) {
            throw new Error('Invalid message: not defined');
        } else if (Utils.isStringNullOrWhitespace(message.subscriberId)) {
            throw new Error('Invalid message: subscriber identifier not defined');
        } else if (Utils.isStringNullOrWhitespace(message.topic)) {
            throw new Error('Invalid message: message topic not defined');
        }

        const subscriptionKey = this.createSubscriptionKey(message.subscriberId, message.topic);
        if (this.subscriptions.has(subscriptionKey)) {
            const eventHandler = this.subscriptions.get(subscriptionKey);
            if (eventHandler) {
                eventHandler(message);
            }
        }
    }

}