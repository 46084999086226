import { PubSubConfigDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { Connection, PubSubConfig } from '../pubSub';

/**
 * This class provides a factory, which will generate PubSubConfig classes from PubSub configuration DTO objects.
 * 
 * @export
 * @class PubSubConfigFactory
 */
export class PubSubConfigFactory {

    /**
     * Converts the given PubSub configuration DTO into an internal PubSub configuration model.
     * @access public
     * @param {PubSubConfigDTO} pubSubConfigDTO The PubSub configuration DTO, which should be converted into an internal PubSub configuration model.
     * @returns {PubSubConfig}
     */
    public static convertFromDTO(pubSubConfigDTO: PubSubConfigDTO): PubSubConfig {
        const result = new PubSubConfig();

        if (pubSubConfigDTO.In) {
            result.in = pubSubConfigDTO.In.map((value) => {
                const connection = new Connection(value.Parameter, value.ComponentGuid);
                return connection;
            });
        }

        if (pubSubConfigDTO.Out) {
            result.out = pubSubConfigDTO.Out.map((value) => {
                const connection = new Connection(value.Parameter, value.ComponentGuid);
                return connection;
            });
        }

        result.executeForEmptyData = pubSubConfigDTO.ExecuteForEmptyData;

        return result;
    }

}