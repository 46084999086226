import { ThirdPartyLicense, WindreamLicense } from '../index';

/**
 * Represents the license details.
 *
 * @export
 * @class LicenseDetails
 */
export class LicenseDetails {

    /**
     * The third party licenses.
     *
     * @type {ThirdPartyLicense[]}
     * @memberof LicenseDetails
     */
    public thirdPartyLicenses: ThirdPartyLicense[];

    /**
     * The current windream license.
     *
     * @type {WindreamLicense}
     * @memberof LicenseDetails
     */
    public windreamLicense: WindreamLicense;

    /**
     * The version of the product.
     *
     * @type {string}
     * @memberof LicenseDetails
     */
    public productVersionString: string;

    /**
     * The current webservice version.
     *
     * @type {string}
     * @memberof LicenseDetails
     */
    public windreamWebServiceVersion?: string;


    /**
     * The build identifier.
     *
     * @type {(string | number | null)}
     * @memberof LicenseDetails
     */
    public buildIdentifier: string | number | null;

    /**
     * Creates an instance of LicenseDetails.
     * 
     * @param {ThirdPartyLicense[]} thirdPartyLicenses The third party licenses.
     * @param {WindreamLicense} windreamLicense The current windream license.
     * @param {string} productVersionString The version of the product.
     * @param {(string | number | null)} buildIdentifier The build identifier of the product.
     * @memberof LicenseDetails
     */
    public constructor(thirdPartyLicenses: ThirdPartyLicense[], windreamLicense: WindreamLicense,
        productVersionString: string, buildIdentifier: string | number | null) {
        this.thirdPartyLicenses = thirdPartyLicenses;
        this.windreamLicense = windreamLicense;
        this.productVersionString = productVersionString;
        this.buildIdentifier = buildIdentifier;
    }
}