import { ComponentInstanceToolbarConfigDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import {
    ComponentInstanceToolbarActionConfigFactory,
    ComponentInstanceToolbarConfig,
    ComponentInstanceToolbarDisplayOptionsConfigFactory,
    ComponentInstanceToolbarOrientationConfigFactory
} from '.';

/**
 * This class provides a factory, which will generate component instance toolbar configuration classes from DTO objects.
 *
 * @export
 * @class ComponentInstanceToolbarConfigFactory
 */
export class ComponentInstanceToolbarConfigFactory {

    /**
     * Convert the object from a DTO.
     *
     * @static
     * @param {ComponentInstanceToolbarConfigDTO} toolbarConfigDto The toolbar config.
     * @returns {(ComponentInstanceToolbarConfig | undefined)} The component instance config or undefined.
     * @memberof ComponentInstanceToolbarConfigFactory
     */
    public static convertFromDTO(toolbarConfigDto: ComponentInstanceToolbarConfigDTO): ComponentInstanceToolbarConfig | undefined {
        const result = new ComponentInstanceToolbarConfig();
        if (toolbarConfigDto) {
            result.actions = ComponentInstanceToolbarActionConfigFactory.convertFromDTO(toolbarConfigDto.Actions);
            result.enabled = !!toolbarConfigDto.Enabled;
            result.displayMode = ComponentInstanceToolbarDisplayOptionsConfigFactory.convertFromDTO(toolbarConfigDto.DisplayMode);
            result.orientation = ComponentInstanceToolbarOrientationConfigFactory.convertFromDTO(toolbarConfigDto.Orientation);
            return result;
        } else {
            return undefined;
        }
    }
}