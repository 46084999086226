import { Utils } from '../common';
import { ApplicationConfig, IConfigManager, ViewConfigMetaData } from '../config';
import { ITemplateExtension } from '../extensions';
import { ILanguageManager, ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { APP_ICONS } from '../shared';
import { IPopupHelper, NotificationHelper, NotificationOptions } from '../ui';
import { NavigationHandler } from '../ui/navigationHandler';
import { EditRouter } from './editRouter';
import { INewViewHandler, IViewNavigationHandler } from './interfaces';
import { NavigationGroup, ViewNavigationViewModel } from './models';
import { RootRouter } from './rootRouter';
import { RouteManager } from './routeManager';
import { ViewRouter } from './viewRouter';

/**
 * Navigation Handler class to render and update the navigation.
 *
 * @exports ViewNavigationHandler
 * @version 1.0.0
 */
export class ViewNavigationHandler extends NavigationHandler implements IViewNavigationHandler {
  /**
   * Prefix for navigation list items inside the DOM.
   * 
   * @static
   * 
   * @memberof ViewNavigationHandler
   */
  public static ITEM_PREFIX = 'wd-navigation-item';

  protected currentViewId: string | null;

  private readonly className = 'ViewNavigationHandler';

  private navigationElement: HTMLElement;
  private logger: Logger;
  private popupHelper: IPopupHelper;
  private configManager: IConfigManager;
  private languageManager: ILanguageManager;
  private languageProvider: ILanguageProvider;
  private currentAppConfig: ApplicationConfig;
  private templateExtension: ITemplateExtension;
  private newViewHandler?: INewViewHandler;
  private viewModel: ViewNavigationViewModel;
  private viewRouter: ViewRouter;
  private rootRouter: RootRouter;
  private editRouter: EditRouter;
  private activeViewIdBeforeSearch: string = '';

  /**
   * Creates an instance of ViewNavigationHandler.
   * Will call `renderNavigation()` with the given callback.
   * Will register `updateNavigation()` to the `Router.onViewChange`-event.
   *
   * @param {HTMLElement}  navigationElement Element to render the navigation into.
   * @param {ApplicationConfig} appConfig Application config.
   * @param {Logger} logger      The current logger.
   * @param {IPopupHelper} popupHelper      The current popupHelper.
   * @param {IConfigManager} configManager      The current config manager.
   * @param {ILanguageManager} languageManager      The current language manager.
   * @param {ITemplateExtension} templateExtension The template extension to use.
   * @param {ViewRouter} viewRouter The view router to use.
   * @param {RootRouter} rootRouter The root router to use.
   * @param {EditRouter} editRouter The edit router to use.
   * @param {INewViewHandler} [newViewHandler] NewViewHandler instance to use.
   *
   * @memberof ViewNavigationHandler
   */
  public constructor(navigationElement: HTMLElement, appConfig: ApplicationConfig, logger: Logger, popupHelper: IPopupHelper,
    configManager: IConfigManager, languageManager: ILanguageManager,
    templateExtension: ITemplateExtension, viewRouter: ViewRouter, rootRouter: RootRouter, editRouter: EditRouter, newViewHandler?: INewViewHandler) {

    super(navigationElement, ViewNavigationHandler.ITEM_PREFIX);

    this.init();

    this.currentAppConfig = Utils.Instance.deepClone(appConfig);
    this.languageManager = languageManager;
    this.languageProvider = this.languageManager.getLanguageProvider('framework');
    this.configManager = configManager;
    this.popupHelper = popupHelper;
    this.logger = logger;
    this.navigationElement = navigationElement;
    this.currentViewId = viewRouter.getCurrentViewFromPath();
    this.templateExtension = templateExtension;
    this.newViewHandler = newViewHandler;
    this.viewRouter = viewRouter;
    this.rootRouter = rootRouter;
    this.editRouter = editRouter;
    if (this.newViewHandler) {
      this.newViewHandler.onAddView = this.addItem.bind(this);
    }
    this.templateExtension.addHelper('getTranslationForNavigation', (key: string) => this.languageProvider.get(key), this.navigationElement);
    this.viewRouter.registerOnViewChange((newViewId) => {
      this.updateNavigation(newViewId);
    });
    this.rootRouter.registerOnRootNavigation(() => {
      // Legacy for hash in route
      const currentHash = this.viewRouter.getCurrentViewFromPath();
      if (currentHash) {
        this.updateNavigation(currentHash);
      } else if (appConfig.activeViews.length > 0) {
        this.updateNavigation(appConfig.activeViews[0].id);
      } else {
        logger.error(this.className, 'constructor', 'Switch to default view failed no active views found.');
      }
    });
    this.editRouter.registerOnEditChange((viewId) => {
      this.updateNavigation(viewId);
    });
    this.viewModel = new ViewNavigationViewModel(ViewNavigationHandler.ITEM_PREFIX);
  }

  /**
   * Renames a view in the navigation.
   * Only affects the UI.
   * 
   * @param {string} viewId ID of the view to rename.
   * @param {string} newName The new name.
   * @memberof ViewNavigationHandler
   */
  public renameView(viewId: string, newName: string): void {
    const item = this.viewModel.items.find((item) => item.id === viewId);
    if (item) {
      item.name = newName;
      this.templateExtension.render(this.navigationElement, require('./templates/viewNavigation.html'), this.viewModel, true);
    } else {
      throw new Error(`View with ID '${viewId}' not found`);
    }
  }

  /**
   * Updates the alias for the given view.
   * Only affects the navigation URLs.
   * If new alias is an empty string, the ID will be used for the URL.
   *
   * @param {string} viewId ID of the view to update the alias.
   * @param {string} [newAlias] The new alias. Empty if omitted.
   * @memberof ViewNavigationHandler
   */
  public updateViewAlias(viewId: string, newAlias?: string): void {
    const configItem = this.currentAppConfig.activeViews.find((item) => item.id === viewId);
    if (configItem) {
      if (newAlias) {
        configItem.alias = newAlias;
      } else {
        delete configItem.alias;
      }
    } else {
      throw new Error(`View with ID '${viewId}' not found`);
    }
    const viewModelItem = this.viewModel.items.find((item) => item.id === viewId);
    if (viewModelItem) {
      viewModelItem.path = this.getPath(viewId);
      this.templateExtension.render(this.navigationElement, require('./templates/viewNavigation.html'), this.viewModel, true);
    } else {
      throw new Error(`View with ID '${viewId}' not found`);
    }
  }

  /**
   * Removes a view.
   *
   * @param {string} viewId The view, which shall be deleted.
   * @param {() => void} [successCallback] The callback which will be executed if deletion was successful.
   * 
   * @memberof ViewNavigationHandler
   */
  public removeView(viewId: string, successCallback?: () => void): void {
    this.popupHelper.openConfirmationPopup(() => {
      // TODO: Move webservice call into SettingsComponent
      this.configManager.deleteViewConfig(viewId, this.currentAppConfig).then(() => {
        const index = this.currentAppConfig.activeViews.findIndex((view) => view.id === viewId);
        if (index > -1) {
          this.currentAppConfig.activeViews.splice(index, 1);
        }
        // Remove visible items and items located in the "more"-dropdown
        const elements = this.navigationElement.querySelectorAll(`[data-wd-id="${ViewNavigationHandler.ITEM_PREFIX}-view-${viewId}"]`);
        if (elements && elements.length > 0) {
          for (let i = 0; i < elements.length; i++) {
            elements.item(i).remove();
          }
          this.adjustWidth();
        } else {
          this.logger.error(this.className, 'removeView', 'Failed to remove view from navigation');
        }
        const notificationOptions = new NotificationOptions();
        notificationOptions.body = this.languageProvider.get('framework.navigationhandler.removeView.success');
        NotificationHelper.Instance.success(notificationOptions);
        if (successCallback) {
          successCallback();
        }
      }).catch((err: Error) => {
        this.logger.error(this.className, 'removeView', 'Cannot remove view', err);
        const notificationOptions = new NotificationOptions();
        notificationOptions.body = this.languageProvider.get('framework.navigationhandler.removeView.error');
        NotificationHelper.Instance.error(notificationOptions);
      });
    }, () => {
      // Do nothing on close
    }, {
      body: this.languageProvider.get('framework.navigationhandler.removeView.deletePopup.body'),
      title: this.languageProvider.get('framework.navigationhandler.removeView.deletePopup.title')
    });
  }

  /**
   * Get the changed translated viewname.
   *
   * @param {ViewConfigMetaData} viewConfig View to get name for.
   * @returns {string} The translated name.
   * @memberof ViewNavigationHandler
   */
  public getViewTranslation(viewConfig: ViewConfigMetaData): string {
    if (viewConfig.name) {
      return this.languageProvider.getTranslationFromProperty(viewConfig.name);
    } else {
      this.logger.warn(this.className, 'createViewModelFromViewConfig', 'Skip view', 'Invalid view configuration found (no name set): ' + viewConfig);
      return viewConfig.id;
    }
  }

  /**
   * Search for a view in the navigation.
   *
   * @param {string} searchTerm The search term.
   * @memberof ViewNavigationHandler
   */
  public searchViews(searchTerm: string): void {
    let viewModelToUse: ViewNavigationViewModel;
    if (Utils.Instance.isNullOrEmptyString(searchTerm)) {
      viewModelToUse = this.viewModel;
      viewModelToUse.items.forEach((view) => {
        if (view.id === this.activeViewIdBeforeSearch) {
          view.isActive = true;
        }
      });
    } else {
      const searchTermLowerCase = searchTerm.toLocaleLowerCase();
      const filteredViews = this.viewModel.items.filter((view) => {
        return view.name.toLocaleLowerCase().indexOf(searchTermLowerCase) > -1 ||
          view.id.toLocaleLowerCase().indexOf(searchTermLowerCase) > -1;
      });
      viewModelToUse = new ViewNavigationViewModel(this.viewModel.prefix);
      viewModelToUse.items = filteredViews;
    }
    // Disable active status if in settings/about panel
    viewModelToUse.items.forEach((view) => {
      view.isActive = !!this.currentViewId && view.isActive;
      if (view.isActive) {
        this.activeViewIdBeforeSearch = view.id;
      }
    });
    // Remove this attribute beforehand since the handlebars databinding doesn't work for years anymore, so that handlebars rerender
    this.navigationElement.removeAttribute('data-wd-rendered');
    if (Utils.Instance.isNullOrEmptyString(searchTerm)) {
      this.templateExtension.render(this.navigationElement, require('./templates/viewNavigation.html'), this.viewModel, true);
    } else {
      this.templateExtension.render(this.navigationElement, require('./templates/searchViewNavigation.html'), viewModelToUse, true);
    }
    this.adjustWidth();
    const links = this.navigationElement.querySelectorAll('a');
    // Disable it because its not a for of array
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', (event) => {
        if (event.currentTarget && event.currentTarget instanceof HTMLElement
          && event.currentTarget.tagName.toLocaleLowerCase() === 'a') {
          event.preventDefault();
          const url = event.currentTarget.getAttribute('href');
          if (url) {
            if (!this.currentViewId || url !== ViewRouter.generateNavigationUrlForViewId(this.currentViewId)) {
              RouteManager.navigate(url);
            }
          } else {
            this.logger.error(this.className, 'renderNavigation', 'Link is broken and does not have href');
          }
        }
      });
    }
  }
  /**
   * Renders the navigation into the navigation element.
   * 
   * @param {ViewConfigMetaData[]} viewConfigs Views to render navigation for.
   * @param {boolean} settingsChangedRerender Whether the views needs to rerender because the order has changed.
   * @returns {Promise<void>}  Promise to resolve once the navigation is rendered.
   * @async
   * @memberof ViewNavigationHandler
   */
  public async renderNavigation(viewConfigs: ViewConfigMetaData[], settingsChangedRerender?: boolean): Promise<void> {
    this.viewModel.items.length = 0;
    viewConfigs.forEach((viewConfig: ViewConfigMetaData | null) => {
      if (viewConfig) {
        const isActive = (viewConfig.id === this.currentViewId) || (viewConfig.alias === this.currentViewId) && !settingsChangedRerender;
        this.viewModel.items.push(this.createViewModelFromViewConfig(viewConfig, isActive));
        if (isActive) {
          this.currentViewId = viewConfig.id;
        }
      }
    });
    this.templateExtension.render(this.navigationElement, require('./templates/viewNavigation.html'), this.viewModel, true);
    this.adjustWidth();
    const links = this.navigationElement.querySelectorAll('a');
    // Disable it because its not a for of array
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', (event) => {
        if (event.currentTarget && event.currentTarget instanceof HTMLElement
          && event.currentTarget.tagName.toLocaleLowerCase() === 'a') {
          event.preventDefault();
          const url = event.currentTarget.getAttribute('href');
          if (url) {
            if (!this.currentViewId || url !== ViewRouter.generateNavigationUrlForViewId(this.currentViewId)) {
              RouteManager.navigate(url);
            }
          } else {
            this.logger.error(this.className, 'renderNavigation', 'Link is broken and does not have href');
          }
        }
      });
    }
    // NOTE: Removed for beta release. :remove-add-settings-menu
    // > const addViewContainer = this.navigationElement.querySelector<HTMLLIElement>('.wd-add-view-item');
    // > if (addViewContainer && this.newViewHandler) {
    // >   this.newViewHandler.render(addViewContainer);
    // > }
  }

  /**
   * Updates the navigation.
   * Removes active class from previously active view.
   * Adds active class to currently active view.
   *
   * @param {string} newViewId ID of the new view.
   *
   * @memberof ViewNavigationHandler
   */
  public updateNavigation(newViewId: string | null): void {
    // Close the more drop down if it was open
    this.closeMoreDropdown();
    // Remove active class from previously active element
    const currentlyActiveNavigationItem = this.navigationElement.querySelector('.active');
    if (currentlyActiveNavigationItem) {
      currentlyActiveNavigationItem.classList.remove('active');
      const currentlyActiveNavigationItemLink = currentlyActiveNavigationItem.querySelector('a');
      if (currentlyActiveNavigationItemLink) {
        const dataHrefAttr = currentlyActiveNavigationItemLink.getAttribute('data-href');
        if (dataHrefAttr) {
          currentlyActiveNavigationItemLink.setAttribute('href', dataHrefAttr);
        }
      }
    }
    // Add active class to new active element
    let newActiveNavigationItem: HTMLElement | null = null;
    if (newViewId === '') {
      newActiveNavigationItem = this.navigationElement.querySelector(`[data-wd-id^="${ViewNavigationHandler.ITEM_PREFIX}-view"]:first-child`);
    } else if (newViewId !== null) {
      const viewFromAlias = this.currentAppConfig.activeViews.find((view) => !!view.alias && view.alias.toLowerCase() === newViewId.toLowerCase());
      if (viewFromAlias) {
        newActiveNavigationItem = this.navigationElement.querySelector(`[data-wd-id="${ViewNavigationHandler.ITEM_PREFIX}-view-${viewFromAlias.id}"]`);
      } else {
        newActiveNavigationItem = this.navigationElement.querySelector(`[data-wd-id="${ViewNavigationHandler.ITEM_PREFIX}-view-${newViewId}"]`);
      }
    }
    if (newActiveNavigationItem) {
      newActiveNavigationItem.classList.add('active');
    }
    // Adjust width in case now active view is inside the more-dropdown
    this.adjustWidth();
    this.currentViewId = newViewId;
  }

  /**
   * Clears the DOM Handler by deleting everything in the navigation element.
   *
   * @memberof ViewNavigationHandler
   */
  public clear(): void {
    this.navigationElement.innerHTML = '';
  }

  /**
   * Destroys the Navigation Handler.
   * Calls `clear()`.
   *
   * @memberof ViewNavigationHandler
   */
  public destroy(): void {
    this.clear();
  }


  /**
   * Creates a view model item from the given ViewConfig.
   * 
   * @private
   * @param {ViewConfigMetaData} viewConfig The config to create the item from.
   * @param {boolean} [isActive=false] Whether the item is active.
   * @returns {NavigationGroup} The view model item.
   * @memberof ViewNavigationHandler
   */
  private createViewModelFromViewConfig(viewConfig: ViewConfigMetaData, isActive: boolean = false): NavigationGroup {
    const iconSet = this.getViewIcon(viewConfig.icon);
    const navigationGroup = new NavigationGroup(viewConfig.id, this.getPath(viewConfig.id), this.getViewTranslation(viewConfig), !viewConfig.isHiddenInNavigation, iconSet);
    navigationGroup.isActive = isActive;
    return navigationGroup;
  }

  /**
   * Adds a single item to the navigation.
   * Re-renders the navigation.
   * 
   * @private
   * @async
   * @param {ViewConfigMetaData} viewConfig View config to add item for.
   * @returns {Promise<void>} Promise to resolve after adding was complete.
   * @memberof ViewNavigationHandler
   */
  private async addItem(viewConfig: ViewConfigMetaData): Promise<void> {
    this.viewModel.items.push(this.createViewModelFromViewConfig(viewConfig));
    this.templateExtension.render(this.navigationElement, require('./templates/viewNavigation.html'), this.viewModel, true);
    return Promise.resolve();
  }

  /**
   * Returns the path to be used in the view model for the given view.
   *
   * @private
   * @param {string} viewId ID of the view to get the path for.
   * @returns {string} The path to use.
   * @memberof ViewNavigationHandler
   */
  private getPath(viewId: string): string {
    // Find view to check if the application config contains an alias
    const view = this.currentAppConfig.activeViews.find((view) => view.id === viewId);
    if (view) {
      if (view.alias) {
        return ViewRouter.generateNavigationUrlForViewId(view.alias);
      }
    }
    // Use view ID as fallback
    return ViewRouter.generateNavigationUrlForViewId(viewId);
  }

  /**
   * Updates the view icon in the navigation.
   * 
   * @param {string} viewId ID of the view of which the icon shall be updated.
   * @param {string} newIcon The new icon.
   * @memberof ViewNavigationHandler
   */
  public updateViewIcon(viewId: string, newIcon: string): void {
    const item = this.viewModel.items.find((item) => item.id === viewId);
    if (item) {
      item.icon = this.getViewIcon(newIcon);
      this.templateExtension.render(this.navigationElement, require('./templates/viewNavigation.html'), this.viewModel, true);
    } else {
      throw new Error(`View with ID '${viewId}' not found`);
    }
  }

  /**
   * Gets the icon for the UI from the icon enumeration by specified key.
   * 
   * @param {string} viewIconKey The name of the icon in the icon set enumeration.
   * @returns {string} The icon that can be displayed in the UI for the specified key - default one if key was not found. 
   */
  private getViewIcon(viewIconKey: string): string {
    // @ts-ignore - Ignore because ENUM doesn't have an index signature
    let icon = APP_ICONS[viewIconKey] as string;
    if (!icon) {
      icon = APP_ICONS.DEFAULT_VIEW;
    }
    return icon;
  }
}