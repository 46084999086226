import { MappingEntry, SharedSettingEntry } from '../../../../typings/core';
import { Utils } from '../../common';
import { SearchCondition, SearchGroup, SearchQuery } from '../../interface/search';
import { IConfigMapping, IMappingHandler } from '../interfaces';

/**
 * Mapping handler to apply mapping to a search configuration.
 * Replaces path and object type conditions.
 *
 * @export
 * @class SearchQueryMappingHandler
 * @implements {IMappingHandler<SearchQuery, string | number>}
 */
export class SearchQueryMappingHandler implements IMappingHandler<SearchQuery, string | number> {
    /**
     * Data types of configurations this handler can map.
     *
     * @memberof SearchQueryMappingHandler
     */
    private readonly dataTypes = ['DATATYPE_WINDREAM_SEARCH'];


    /**
     * Checks whether the mapping for the given configuration data type can be handled.
     *
     * @param {string} configDataType Data type of the configuration to map.
     * @param {string} mappingDataType Data type of the mapping to use.
     * @returns {boolean} Whether the configuration can be mapped by this handler.
     * @memberof SubstitutionMappingHandler
     */
    public canHandleMapping(configDataType: string, mappingDataType: string): boolean {
        return this.dataTypes.indexOf(configDataType) !== -1
            && ['DATATYPE_WINDREAM_PATH', 'DATATYPE_WINDREAM_OBJECTTYPE', 'DATATYPE_WINDREAM_COLUMN'].indexOf(mappingDataType) !== -1;
    }

    /**
     * Applies the mapping to the given input.
     *
     * @param {SearchQuery} input Configuration value to map.
     * @param {IConfigMapping<string | number> | SharedSettingEntry} mapping Mapping informaton to use.
     * @returns {SearchQuery} The transformed value.
     * @memberof SearchQueryMappingHandler
     */
    public applyMapping(input: SearchQuery, mapping: IConfigMapping<string | number | MappingEntry> | SharedSettingEntry): SearchQuery {
        if (mapping.dataType === 'DATATYPE_WINDREAM_PATH' && Utils.Instance.isDefined(mapping.currentValue) && Utils.Instance.isDefined(mapping.newValue)) {
            return this.applyMappingForPath(input, mapping.currentValue.toString(), mapping.newValue.toString());
        }
        if (mapping.dataType === 'DATATYPE_WINDREAM_COLUMN' && Utils.Instance.isDefined(mapping.currentValue) && Utils.Instance.isDefined(mapping.newValue)) {
            return this.applyMappingForIndex(input, mapping.currentValue as MappingEntry, mapping.newValue as MappingEntry);
        }
        if (mapping.dataType === 'DATATYPE_WINDREAM_OBJECTTYPE' && Utils.Instance.isDefined(mapping.currentValue) && Utils.Instance.isDefined(mapping.newValue)) {
            return this.applyMappingForObjectType(input, mapping.currentValue as number, mapping.newValue as number);
        }
        return input;
    }

    /**
     * Replaces the given path value with the new path value within the given search query.
     *
     * @private
     * @param {SearchQuery} searchQuery Query to replace the path.
     * @param {string} oldValue The old path value.
     * @param {string} newValue The new path value.
     * @returns {SearchQuery} The query with the adjusted path value.
     * @memberof SearchQueryMappingHandler
     */
    private applyMappingForPath(searchQuery: SearchQuery, oldValue: string, newValue: string): SearchQuery {
        const replacePathRecursive = (group: SearchGroup, oldValue: string, newValue: string) => {
            group.conditions?.forEach((condition) => {
                if (this.isCondition(condition)) {
                    if (condition.column === 'SubObjects' && condition.value === oldValue) {
                        condition.value = newValue;
                    }
                } else {
                    replacePathRecursive(condition, oldValue, newValue);
                }
            });
        };

        if (searchQuery.group) {
            replacePathRecursive(searchQuery.group, oldValue, newValue);
        }
        return searchQuery;
    }

    /**
     * Replaces the given object type value with the new object type value within the given search query.
     *
     * @private
     * @param {SearchQuery} searchQuery Query to replace the object type.
     * @param {number} oldValue The old object type ID value.
     * @param {number} newValue The new object type ID value.
     * @returns {SearchQuery} The query with the adjusted object type value.
     * @memberof SearchQueryMappingHandler
     */
    private applyMappingForObjectType(searchQuery: SearchQuery, oldValue: number, newValue: number): SearchQuery {
        const replacePathRecursive = (group: SearchGroup, oldValue: number, newValue: number) => {
            group.conditions?.forEach((condition) => {
                if (this.isCondition(condition)) {
                    if (condition.column === 'dwObjectTypeID' && condition.value === oldValue) {
                        condition.value = newValue;
                    }
                } else {
                    replacePathRecursive(condition, oldValue, newValue);
                }
            });
        };

        if (searchQuery.group) {
            replacePathRecursive(searchQuery.group, oldValue, newValue);
        }
        return searchQuery;
    }


    /**
     * Replaces the given index with the new index within the given search query.
     *
     * @private
     * @param {SearchQuery} searchQuery Query to replace the object type.
     * @param {number} oldValue The old object type ID value.
     * @param {number} newValue The new object type ID value.
     * @returns {SearchQuery} The query with the adjusted object type value.
     * @memberof SearchQueryMappingHandler
     */
    private applyMappingForIndex(searchQuery: SearchQuery, oldValue: MappingEntry, newValue: MappingEntry): SearchQuery {
        const replacePathRecursive = (group: SearchGroup, oldValue: MappingEntry, newValue: MappingEntry) => {
            group.conditions?.forEach((condition) => {
                const convertedNewValue = typeof newValue.value === 'number' ? newValue.value : Number.parseInt(newValue.value, 10);
                if (this.isCondition(condition)) {
                    if (condition.column === oldValue.techName) {
                        condition.column = newValue.techName;
                        condition.columnObjectTypeId = convertedNewValue;
                    }
                } else {
                    replacePathRecursive(condition, oldValue, newValue);
                }
            });
        };

        if (searchQuery.group) {
            replacePathRecursive(searchQuery.group, oldValue, newValue);
        }
        return searchQuery;
    }

    /**
     * Checks wether the given object is a SearchCondition.
     *
     * @private
     * @param {SearchCondition | SearchGroup} condition Object to check.
     * @returns {condition is SearchCondition} Whether the given object is a SearchCondition.
     * @memberof SearchQueryMappingHandler
     */
    private isCondition(condition: SearchCondition | SearchGroup): condition is SearchCondition {
        return Utils.Instance.isDefined((condition as SearchCondition).value) && Utils.Instance.isDefined((condition as SearchCondition).column);
    }
}