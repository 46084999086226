import { PublicApi } from './dynamicWorkspace';

declare const DynamicWorkspace: PublicApi;
/**
 * Class to apply temporary hotfixes.
 * IMPORTANT: Do always include a link to the Bug that is being solved and add as much comments as possible.
 *
 * @export
 * @class HotFix
 */
export class HotFix {
    /**
     * Runs before everything else.
     *
     * @static
     * @memberof HotFix
     */
    public static runOnStart(): void {
        // Prevent sub context menus from overflowing screen on bottom of page
        // Solves http://teamsrv:8080/tfs/Windream/Windream/_workitems/edit/67572
        // Might be removed once we switch from Foundation ContextMenu to DX ContextMenu
        const outerHeight = $(window).outerHeight();
        if (outerHeight) {
            $('body').on('show.zf.dropdownmenu', '.context.menu', (_event: any, $subMenu: any) => {
                if ($subMenu.offset().top + $subMenu.outerHeight() > outerHeight + window.scrollY) {
                    // Sub menu would overflow on bottom of page
                    $subMenu.css({
                        top: -($subMenu.outerHeight() - $subMenu.offsetParent().outerHeight() - 1)
                    });
                }
            });
        }

    }

    /**
     * Runs after the application has been set up, before the first view is loaded.
     *
     * @static
     * @memberof HotFix
     */
    public static runOnReady(): void {
        // Nothing here
    }


    /**
     * Runs every time a new view has been initialized.
     *
     * @static
     * @memberof HotFix
     */
    public static runOnViewChange(): void {
        this.repaintDxWidgetsOnSubViewChange();
        this.repaintChartWidgetsOnSubViewChange();
    }

    /**
     * Runs every time a new subview has been initialized.
     *
     * @static
     * @memberof HotFix
     */

    /**
     * Runs every time a new subview has been initialized.
     *
     * @static
     * @param {string} subviewId The active subview.
     * @memberof HotFix
     */
    public static runOnSubViewChange(subviewId: string): void {
        this.repaintDxWidgetsOnSubViewChange();
        this.repaintChartWidgetsOnSubViewChange();
        this.refreshPreviewComponentIFrameDisplay(subviewId);
    }

    /**
     * Every time a sub view becomes visible, call repaint() for each DX Widget.
     * Every time a sub view becomes visible, call repaint() for each Chartist Widget.
     * Solves http://teamsrv:8080/tfs/Windream/Windream/_workitems/edit/74787.
     * Solves http://teamsrv:8080/tfs/Windream/Windream/_workitems?id=67662&_a=edit
     *
     * @private
     * @static
     * @memberof HotFix
     */
    private static repaintDxWidgetsOnSubViewChange(): void {
       // Try to trigger repaint on every widget
       $('.sub-view.active .dx-widget').each(function () {
            try {
                // @ts-ignore - Ignore because of jQuery syntax
                const instance = $(this).dxDataGrid('instance');
                if (instance) {
                    instance.repaint();
                }
            } catch {
                // Do nothing here since we won't react to this hotfix
            }
        });
    }

    /**
     * Every time a sub view becomes visible, call repaint() for each Chartist/DevExtreme Widget.
     * Solves http://teamsrv:8080/tfs/Windream/Windream/_workitems?id=67662&_a=edit
     *
     * @private
     * @static
     * @memberof HotFix
     */
    private static repaintChartWidgetsOnSubViewChange(): void {
        // Lifecycle
        try {
            const charts = document.querySelectorAll('.ct-chart');
            for (let i = 0; i < charts.length; i++) {
                const element = charts.item(i);
                (element as any).__chartist__?.update();
            }
        } catch (err) {
            DynamicWorkspace.Logger.debug('Hotfix', 'repaintChartistWidgetsOnSubViewChange', 'Cannot repaint chart', err);
        }
        // PieChart
        try {
            const charts = document.querySelectorAll('.ct-chart-pie');
            for (let i = 0; i < charts.length; i++) {
                const element = charts.item(i);
                (element.parentElement as any)?.__chartist__?.update();
            }
        } catch (err) {
            DynamicWorkspace.Logger.debug('Hotfix', 'repaintChartistWidgetsOnSubViewChange', 'Cannot repaint PieChart', err);
        }

        // BarChart
        try {
            const charts = document.querySelectorAll('.dx-bar-chart');
            for (let i = 0; i < charts.length; i++) {
                const element = charts.item(i);
                // @ts-ignore - Ignore because of jQuery syntax
                const instance = $(element).dxChart('instance');
                if (instance) {
                    instance.render();
                }
            }
        } catch (err) {
            DynamicWorkspace.Logger.debug('Hotfix', 'repaintChartistWidgetsOnSubViewChange', 'Cannot repaint BarChart', err);
        }
    }

    /**
     * Try to refresh preview iframe so that it won't render grayscale.
     *
     * @private
     * @static
     * @param {string} subviewId The active subview.
     * @memberof HotFix
     */
    private static refreshPreviewComponentIFrameDisplay(subviewId: string): void {
        const queryString = `[data-sub-view-id="${subviewId}"] [data-component="com.windream.preview"] .wd-preview-container iframe`;
        const iFramesToUpdate =  <HTMLElement[]>[].slice.call(document.querySelectorAll(queryString));
        iFramesToUpdate.forEach((iFrame) => {
            iFrame.style.display = 'initial';
        });
    }
}