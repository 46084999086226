import { DWCore } from '@windream/dw-core/dwCore';

/**
 * Represents the toolbar config file.
 *
 * @export
 * @class ToolbarConfig
 */
export class ToolbarConfig {
  /**
   * All available actions.
   *
   * @type {string[]}
   * @memberof ToolbarConfig
   */
  public actions: string[];

  /**
   * All available display options.
   *
   * @type {DWCore.Components.ToolbarActionDisplayMode}
   * @memberof ToolbarConfig
   */
  public displayOptions: DWCore.Components.ToolbarActionDisplayMode;

  /**
 * All available orientation options.
 *
 * @type {DWCore.Components.ToolbarOrientation}
 * @memberof ToolbarConfig
 */
  public orientation: DWCore.Components.ToolbarOrientation;

  /**
   * Creates an instance of ToolbarConfig.
   *
   * @param {string[]} actions The available actions.
   * @param {DWCore.Components.ToolbarActionDisplayMode} displayOptions The available displayOptions.
   * @param {DWCore.Components.ToolbarOrientation} orientation The toolbar orientation.
   * @memberof ToolbarConfig
   */
  public constructor(actions: string[], displayOptions: DWCore.Components.ToolbarActionDisplayMode, orientation: DWCore.Components.ToolbarOrientation) {
    this.actions = actions;
    this.displayOptions = displayOptions;
    this.orientation = orientation;
  }
}
