import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HttpResourcePointer } from '../../dataProviders';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The CreateNewVersion action is used to create a new  document within windream
 * 
 * @export
 * @class CreateNewVersion
 * @extends {ServiceAction}
 */
export class CreateNewVersion extends ServiceAction {
    /**
     * Name of the service action.
     * 
     * 
     * @memberof CreateNewVersion
     */
    public name = 'createNewVersion';

    /**
     * Creates a new version of given document.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WindreamRequestOptions} windreamRequestOptions The request options.
     * @returns {Promise<void>} A promise, which resolves with the server reponse.
     * @memberof CreateNewVersion
     */
    public async do(windreamRequestOptions: WindreamRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer(
                'POST',
                this.globalConfig.windreamWebServiceURL + '/documents/Update',
                {
                    CreateNewVersion: true,
                    Item: IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity)
                }
            );
            this.requestExecutor.executeRequest(resourcePointer, windreamRequestOptions.requestOptions)
                .then((response: IServiceResponse<ResponseContainerDTO>) => {
                    if (response.data?.HasErrors) {
                        this.logger.error('CreateNewVersion', 'do', 'Can\'t create new version', response);
                        reject(new Error('Can\'t create new version'));
                    } else {
                        resolve();
                    }
                }).catch((err: Error) => {
                    this.logger.error('CreateNewVersion', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}