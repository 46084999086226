import { GetBase64ImageDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform a GetBase64Image on the windream webservice.
 * 
 * @export
 * @class GetBase64Image
 * @extends {ServiceAction}
 */
export class GetBase64Image extends ServiceAction {

  /**
   * Creates an instance of GetBase64Image.
   *
   * @param {IRequestExecutor} requestExecutor The request executor.
   * @param {GlobalConfig} globalConfig The global configuration.
   * @param {Logger} logger The logger.
   * @memberof GetBase64Image
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'getBase64Image';
  }

  /**
   * Gets Base64 representation for a specified image.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {WindreamRequestOptions} windreamRequestOptions The request options.
   * @returns {Promise<GetBase64ImageDTO>} A promise, which will resolve with the server response.
   * @memberof GetBase64Image
   */
  public async do(windreamRequestOptions: WindreamRequestOptions): Promise<GetBase64ImageDTO> {
    return new Promise<GetBase64ImageDTO>((resolve, reject) => {
      const url = `${this.globalConfig.windreamWebServiceURL}/dynamicworkspace/utils/GetBase64Image`;
      const item = IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity);
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', url, {
        Item: item,
      }), windreamRequestOptions.requestOptions).then((response: IServiceResponse<GetBase64ImageDTO>) => {
        if (!response.data || response.data.HasErrors) {
          this.logger.error('GetBase64Image', 'do', 'Failed to getBase64 image', response);
          reject(new Error('Failed to getBase64 image'));
        } else {
          resolve(response.data);
        }
      }).catch((err) => {
        this.logger.error('GetBase64Image', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}