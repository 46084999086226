import { UserDetails } from '../authentication/index';

/**
 * Represents the minimum requirements for a login mask.
 *
 * @export
 * @abstract
 * @class BaseLoginMask
 */
export abstract class BaseLoginMask {

    /**
     * Render the login mask as a page into the traget element.
     *
     * @abstract
     * @param {HTMLElement} targetElement The target to render into.
        * @returns {Promise<boolean>} A promise which will resolve with true if rendering occured or false if auto-login occured thus no rendering needed.
     * @memberof BaseLoginMask
     */
    public abstract renderPage(targetElement: HTMLElement): Promise<boolean>;

    /**
     * Render the session timeout mask as a popup into the traget element.
     *
     * @abstract
     * @param {HTMLElement} targetElement The target to render into.
     * @returns {Promise<void>}
     * @memberof BaseLoginMask
     */
    public abstract renderSessionTimeoutPopup(targetElement: HTMLElement): Promise<void>;

    /**
     * Register a login callback which should be called if the login was successful.
     *
     * @abstract
     * @param {(userCredentials: UserDetails, encryptionSecret?: string) => void} callback The callback to call.
     * @memberof BaseLoginMask
     */
    public abstract registerLoginCallback(callback: (userCredentials: UserDetails, encryptionSecret?: string) => void): void;

    /**
     * Logout from the application.
     *
     * @abstract
     * @returns {Promise<boolean>}
     * @memberof BaseLoginMask
     */
    public abstract logout(): Promise<boolean>;

    /**
     * Destroy the view.
     *
     * @abstract
     * @memberof BaseLoginMask
     */
    public abstract destroy(): void;
}