import { LifecycleParameterDTO, LifecyclePeriodBases, LifecyclePeriodNorms, LifecyclePeriodUnits } from '../../../typings/windreamWebService/Windream.WebService.Documents';

/**
 * Represents the details of a lifecycle period.
 * 
 * @export
 * @class LifecyclePeriodDetails
 */
export class LifecyclePeriodDetails {
    /**
     * Year which indicates infinite period duration.
     * 
     * @static
     * @memberof LifecyclePeriodDetails
     */
    public static readonly INFINITE_YEAR = 9999;

    /**
     * Year which indicates impossible period.
     * 
     * @static
     * @memberof LifecyclePeriodDetails
     */
    public static readonly IS_NOT_POSSIBLE_YEAR = 1899;

    /**
     * The periods start date.
     * 
     * @type {Date}
     * @memberof LifecyclePeriodDetails
     */
    public start: Date;

    /**
     * The periods end date.
     * 
     * @type {Date}
     * @memberof LifecyclePeriodDetails
     */
    public end: Date;

    /**
     * The periods norm value.
     * 
     * @type {LifecyclePeriodNorms}
     * @memberof LifecyclePeriodDetails
     */
    public norm: LifecyclePeriodNorms;

    /**
     * The periods norm unit,
     * 
     * @type {LifecyclePeriodUnits}
     * @memberof LifecyclePeriodDetails
     */
    public normUnit: LifecyclePeriodUnits;

    /**
     * The periods norm amount.
     * 
     * @type {number}
     * @memberof LifecyclePeriodDetails
     */
    public normAmount: number;

    /**
     * The norm period base
     * 
     * @type {LifecyclePeriodBases}
     * @memberof LifecyclePeriodDetails
     */
    public normPeriodBase: LifecyclePeriodBases;

    /**
     * Whether the period can be manually edited.
     * 
     * @type {boolean}
     * @memberof LifecyclePeriodDetails
     */
    public isManualEdit: boolean;

    /**
     * Whether the period has been changed manually.
     * 
     * @type {boolean}
     * @memberof LifecyclePeriodDetails
     */
    public isManualChanged: boolean;

    /**
     * Whether the period has infinite duration.
     * 
     * @type {boolean}
     * @memberof LifecyclePeriodDetails
     */
    public isInfinite: boolean;

    /**
     * Whether the period is possible.
     * 
     * @type {boolean}
     * @memberof LifecyclePeriodDetails
     */
    public isPossible: boolean;

    /**
     * Creates an instance of LifecyclePeriodDetails.
     * @param {LifecycleParameterDTO} details The lifecycle details.
     * @memberof LifecyclePeriodDetails
     */
    public constructor(details: LifecycleParameterDTO) {
        this.end = new Date(details.End);
        this.start = new Date(details.Start);
        this.norm = details.Norm;
        this.normUnit = details.Unit;
        this.normAmount = details.Period;
        this.normPeriodBase = details.PeriodBase;
        this.isManualEdit = details.IsManualEdit;
        this.isManualChanged = details.IsManualChanged;
        this.isInfinite = this.end.getFullYear() === LifecyclePeriodDetails.INFINITE_YEAR;
        this.isPossible = this.end.getFullYear() !== LifecyclePeriodDetails.IS_NOT_POSSIBLE_YEAR;
    }
}