import { IAuthenticationProvider } from './interfaces/iAuthenticationProvider';
import { AuthenticationModes } from './models/authenticationModes';


/**
 * The abstract base class for all authentication providers.
 * 
 * @export
 * @abstract
 * @class AuthenticationProvider
 */
export abstract class AuthenticationProvider implements IAuthenticationProvider {

    /**
     * Gets the supported authentication mode.
     * 
     * @abstract
     * @returns {AuthenticationModes} 
     * @memberof AuthenticationProvider
     */
    public abstract getSupportedAuthenticationMode(): AuthenticationModes;

    /**
     * This handler will be called before the execution.
     * 
     * @async
     * @param {XMLHttpRequest} rawRequest 
     * @returns {Promise<XMLHttpRequest>} 
     * @memberof AuthenticationProvider
     */
    public async beforeRequestHandler(rawRequest: XMLHttpRequest): Promise<XMLHttpRequest> {
        return Promise.resolve(rawRequest);
    }


    /**
     * This handler will be called after the execution.
     * 
     * @template T 
     * @param {XMLHttpRequest} rawRequest 
     * @returns {XMLHttpRequest} 
     * @memberof AuthenticationProvider
     */
    public afterRequestHandler(rawRequest: XMLHttpRequest): XMLHttpRequest {
        return rawRequest;
    }

    /**
     * Handles the case that a request has been rejected because authorization failed.
     *
     * @async
     * @returns {Promsise<boolean>} Promise to resolve with whether the request should be replayed afterwards.
     * @memberof AuthenticationProvider
     */
    public async handleInvalidAuthentication(): Promise<boolean> {
        return Promise.resolve(false);
    }
}