import { DeviceDetection } from '../common';
import { ComponentConfig, ComponentInstanceConfig, DWCore, Logger } from '../dynamicWorkspace';
import { ILayoutManager, ModuleRegistrationHelper } from '../loader';
import { IComponentInjectionContainer } from './interfaces';

/**
 * The component injector injects the script file for a component into the DOM.
 *
 * @export
 * @class ComponentInjector
 */
export class ComponentInjector {
    private rootContainerElement: HTMLElement;
    private currentDocument: Document;
    private injectionContainers: Map<string, IComponentInjectionContainer>;
    private logger: Logger;
    private moduleRegistrationHelper: ModuleRegistrationHelper;
    private readonly className = 'ComponentInjector';

    /**
     * Creates an instance of ComponentInjector.
     * 
     * @param {HTMLElement} rootContainerElement The root element.
     * @param {Document} currentDocument The current document.
     * @param {Logger} logger The logger.
     * @param {ModuleRegistrationHelper} moduleRegistrationHelper The registration helper.
     * @memberof ComponentInjector
     */
    public constructor(rootContainerElement: HTMLElement, currentDocument: Document,
        logger: Logger, moduleRegistrationHelper: ModuleRegistrationHelper) {
        this.rootContainerElement = rootContainerElement;
        this.currentDocument = currentDocument;
        this.injectionContainers = new Map<string, IComponentInjectionContainer>();
        this.logger = logger;
        this.moduleRegistrationHelper = moduleRegistrationHelper;
    }

    /**
     * Injects the script of a component into the DOM.
     *
     * @param {string} entryScriptFileUrl The URL to the script.
     * @param {string} componentId The id of the component.
     * @returns {Promise<void>} A promise, which will resolve if the script was injected.
     * @memberof ComponentInjector
     */
    public async injectComponentScript(entryScriptFileUrl: string, componentId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const existingScriptTag = <HTMLScriptElement>this.rootContainerElement.querySelector(`script[src="${entryScriptFileUrl}"]`);
            if (!existingScriptTag) {
                const componentScriptTag = this.currentDocument.createElement('script');
                componentScriptTag.addEventListener('load', () => {
                    this.moduleRegistrationHelper.waitForComponentRegistration(componentId).then(() => resolve()).catch((error) => {
                        this.logger.fatal(this.className, 'injectComponentScript', 'Timeout while loading script for component: ' + componentId, error);
                        reject(error);
                    });
                });
                componentScriptTag.addEventListener('error', (errorEvent) => {
                    reject(errorEvent.error);
                });
                componentScriptTag.setAttribute('src', entryScriptFileUrl);
                this.rootContainerElement.appendChild(componentScriptTag);
            } else {
                this.moduleRegistrationHelper.waitForComponentRegistration(componentId).then(() => resolve()).catch((error) => {
                    this.logger.fatal(this.className, 'injectComponentScript', 'Timeout while loading script for component: ' + componentId, error);
                    reject(error);
                });
            }
        });
    }

    /**
     * Remove all injected component references.
     *
     * @memberof ComponentInjector
     */
    public removeAllInjectedComponents(): void {
        this.injectionContainers.clear();
    }

    /**
     * Injects a component container into the DOM.
     *
     * @param {ComponentInstanceConfig} componentInstanceConfig The instance config to use.
     * @param {ComponentConfig} componentConfig The component config.
     * @param {(ILayoutManager | undefined)} layoutManager The layout manager to use, undefined if a query selector location should be used.
     * @returns {Promise<IComponentInjectionContainer>} The injected component.
     * @memberof ComponentInjector
     */
    public async injectComponentContainer(componentInstanceConfig: ComponentInstanceConfig,
        componentConfig: ComponentConfig, layoutManager: ILayoutManager | undefined): Promise<IComponentInjectionContainer> {
        // Generate container
        const element = this.currentDocument.createElement('div');
        const toolbarContainer = this.currentDocument.createElement('div');
        toolbarContainer.classList.add('component-toolbar');
        this.setToolbarOrientation(toolbarContainer, componentInstanceConfig);
        if (componentInstanceConfig.position === null || typeof componentInstanceConfig.position === 'object') { // Position is object, assume grid component
            if (!layoutManager) {
                this.logger.info(this.className, 'injectComponentContainer', 'Attempted to log into grid but no LayoutManager instance was provided.');
                throw new Error('Attempted to log into grid but no LayoutManager instance was provided.');
            }
            layoutManager.renderContainer(componentInstanceConfig, componentConfig, element, toolbarContainer);
        } else if (typeof componentInstanceConfig.position === 'string') { // Position is string, assume query selector
            const gridItem = this.currentDocument.createElement('div');
            gridItem.setAttribute('data-guid', componentInstanceConfig.guid);
            gridItem.setAttribute('data-component', componentInstanceConfig.component);
            gridItem.appendChild(element);

            element.classList.add('component-content');

            if (componentInstanceConfig.position) {
                const targetElement = this.rootContainerElement.querySelector(componentInstanceConfig.position.toString());
                if (targetElement) {
                    targetElement.appendChild(gridItem);
                } else {
                    throw new Error('Element "' + componentInstanceConfig.position + '" was not found.');
                }
            }
        }
        const componentInjectionContainer = {
            componentContainer: element,
            toolbarElement: toolbarContainer
        } as IComponentInjectionContainer;
        this.injectionContainers.set(componentInstanceConfig.guid, componentInjectionContainer);
        return componentInjectionContainer;
    }

    /**
     * Get the injection container for a speicfic component.
     *
     * @param {string} guid The guid.
     * @returns {(IComponentInjectionContainer | undefined)} The container or undefined if not injected.
     * @memberof ComponentInjector
     */
    public getInjectionContainer(guid: string): IComponentInjectionContainer | undefined {
        return this.injectionContainers.get(guid);
    }

    /**
     * Sets the orientation of the toolbar.
     *
     * @private
     * @param {HTMLDivElement} toolbarContainer The toolbar container.
     * @param {ComponentInstanceConfig} componentInstanceConfig The instance config.
     * @memberof ComponentInjector
     */
    private setToolbarOrientation(toolbarContainer: HTMLDivElement, componentInstanceConfig: ComponentInstanceConfig): void {
        switch (componentInstanceConfig.toolbar?.orientation) {
            case DWCore.Components.ToolbarOrientation.Automatically:
                if (DeviceDetection.isPhone()) {
                    toolbarContainer.classList.add('component-toolbar-orientation-right');
                } else {
                    toolbarContainer.classList.add('component-toolbar-orientation-left');
                }
                break;
            case DWCore.Components.ToolbarOrientation.Left:
                toolbarContainer.classList.add('component-toolbar-orientation-left');
                break;
            case DWCore.Components.ToolbarOrientation.Right:
                toolbarContainer.classList.add('component-toolbar-orientation-right');
                break;
        }
    }
}