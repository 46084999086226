import { DeviceDetection } from '../common';
import { CustomConfig } from '../config/models/customConfig';
import { IStorageExtension, StorageDataModel } from '../extensions';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { ITheme, IThemeBackend, IThemeManager } from './interfaces';

/**
 * Handles the themeing funtionallity.
 * 
 * @export
 * @class ThemeManager
 */
export class ThemeManager implements IThemeManager {
    public readonly availableThemes: ITheme[];
    private readonly className = 'ThemeManager';
    private readonly documentAttributeThemeKey = 'data-wd-theme';
    private readonly defaultThemeId = 'material.blue.compact';
    private readonly defaultMobileThemeId = 'material.blue.light';
    private readonly componentStorageName = 'com.windream.profile.settings';
    private readonly themeStorageId = 'currentTheme';
    private backend: IThemeBackend;
    private logger: Logger;
    private storageExtension: IStorageExtension;
    private customConfig?: CustomConfig;
    private documentRef: Document;
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of ThemeManager.
     * @param {Document} documentRef
     * @param {ILanguageProvider} languageProvider
     * @memberof ThemeManager
     */
    public constructor(documentRef: Document, languageProvider: ILanguageProvider, storageExtension: IStorageExtension, logger: Logger,
        backend: IThemeBackend) {
        this.documentRef = documentRef;
        this.languageProvider = languageProvider;
        this.logger = logger;
        this.storageExtension = storageExtension;
        this.backend = backend;
        this.availableThemes = new Array();

        // Always add the default compact and mobile theme.
        this.registerTheme(this.defaultThemeId, 'Default');
        this.registerTheme(this.defaultMobileThemeId, 'Mobile');
    }

    /**
     * Loads and applies the last used theme.
     *
     * @memberof ThemeManager
     */
    public loadTheme() {
        this.loadThemeFromStorage()
            .then((id) => this.changeTheme(id))
            .catch((err) => this.logger.error(
                this.className,
                'loadTheme',
                'Failed to load theme from browser storage',
                err));
    }

    /**
     * Change the application theme by a theme ID.
     * 
     * The current theme will not be changed if the specified ID is invalid.
     *
     * @param {string} id ID of the theme to change to.
     * @memberof ThemeManager
     */
    public changeTheme(id: string) {
        const newTheme = this.availableThemes.find((theme: ITheme) => theme.id === id);
        if (newTheme) {
            this.documentRef.documentElement.setAttribute(this.documentAttributeThemeKey, newTheme.id);
            this.saveThemeToStorage(newTheme.id)
                .then(() => this.backend.setActiveTheme(newTheme.id))
                .catch((err) => this.logger.error(
                    this.className,
                    'changeTheme',
                    'Failed to save theme to browser storage',
                    err));
        } else {
            this.logger.error(
                this.className,
                'changeTheme',
                `No registered theme found with ID: ${id}`);
        }
    }

    /**
     * Get the currently active theme.
     *
     * @returns {Theme} Theme object that is active.
     * @memberof ThemeManager
     */
    public currentTheme(): ITheme {
        return this.availableThemes.find(
            (theme) => theme.id === this.backend.getActiveTheme()
        ) || this.availableThemes[0];
    }

    /**
     * Sets the current custom configuration.
     * Will apply the given themeing by applying styles to the DOM.
     * 
     * @param {CustomConfig} customConfig Custom configuraiton to set.
     * @memberof ThemeManager
     */
    public setCustomConfig(customConfig: CustomConfig): void {
        this.customConfig = customConfig;

        this.applyCustomTheme(this.getTheme());
    }

    /**
     * Returns the currently used theme.
     *
     * @returns {CustomConfig}
     * @memberof ThemeManager
     */
    // eslint-disable-next-line complexity
    public getTheme(): CustomConfig {
        const defaultConfig = this.getDefaultConfig();
        if (!this.customConfig) {
            return defaultConfig;
        }

        if (typeof this.customConfig.browserTitle === 'string') {
            defaultConfig.browserTitle = this.customConfig.browserTitle;
        }
        if (typeof this.customConfig.title === 'string') {
            defaultConfig.title = this.customConfig.title;
        }
        if (typeof this.customConfig.subTitle === 'string') {
            defaultConfig.subTitle = this.customConfig.subTitle;
        }
        if (this.customConfig.navigationBackgroundColor !== '' && (typeof (this.customConfig.navigationBackgroundColor) !== 'undefined')) {
            defaultConfig.navigationBackgroundColor = this.customConfig.navigationBackgroundColor;
        }
        if (this.customConfig.titleBackgroundColor !== '' && (typeof (this.customConfig.titleBackgroundColor) !== 'undefined')) {
            defaultConfig.titleBackgroundColor = this.customConfig.titleBackgroundColor;
        }
        if (this.customConfig.titleTextColor !== '' && (typeof (this.customConfig.titleTextColor) !== 'undefined')) {
            defaultConfig.titleTextColor = this.customConfig.titleTextColor;
        }
        if (this.customConfig.navigationTextColor !== '' && (typeof (this.customConfig.navigationTextColor) !== 'undefined')) {
            defaultConfig.navigationTextColor = this.customConfig.navigationTextColor;
        }
        if (this.customConfig.iconPath !== '' && (typeof (this.customConfig.iconPath) !== 'undefined')) {
            defaultConfig.iconPath = this.customConfig.iconPath;
        }
        if (this.customConfig.customCssFilePath !== '' && (typeof (this.customConfig.customCssFilePath) !== 'undefined')) {
            defaultConfig.customCssFilePath = this.customConfig.customCssFilePath;
        }
        if (this.customConfig.headerBackgroundColor !== '' && (typeof (this.customConfig.headerBackgroundColor) !== 'undefined')) {
            defaultConfig.headerBackgroundColor = this.customConfig.headerBackgroundColor;
        }
        if (this.customConfig.headerTextColor !== '' && (typeof (this.customConfig.headerTextColor) !== 'undefined')) {
            defaultConfig.headerTextColor = this.customConfig.headerTextColor;
        }
        if (this.customConfig.activeViewBackgroundColor !== '' && (typeof (this.customConfig.activeViewBackgroundColor) !== 'undefined')) {
            defaultConfig.activeViewBackgroundColor = this.customConfig.activeViewBackgroundColor;
        }
        if (this.customConfig.activeViewIconColor !== '' && (typeof (this.customConfig.activeViewIconColor) !== 'undefined')) {
            defaultConfig.activeViewIconColor = this.customConfig.activeViewIconColor;
        }
        if (this.customConfig.headerIconHoverColor !== '' && (typeof (this.customConfig.headerIconHoverColor) !== 'undefined')) {
            defaultConfig.headerIconHoverColor = this.customConfig.headerIconHoverColor;
        }
        if (this.customConfig.navigationHoverColor !== '' && (typeof (this.customConfig.navigationHoverColor) !== 'undefined')) {
            defaultConfig.navigationHoverColor = this.customConfig.navigationHoverColor;
        }
        return defaultConfig;
    }

    /**
     * Register all themes in the specified array.
     *
     * @private
     * @param {ITheme[]} [themes] Theme instances to register.
     * @memberof ThemeManager
     */
    public registerThemes(themes?: ITheme[]): void {
        if (themes) {
            themes.forEach((theme) => {
                this.registerTheme(theme.id, theme.name);
            });
        }
    }

    /**
     * Register a new theme with the theme manager.
     *
     * A theme must be registered before it can be used.
     * 
     * @private
     * @param {string} id ID of the theme. Used for theme selection.
     * @param {string} name User visible name of the theme.
     * @memberof ThemeManager
     */
    private registerTheme(id: string, name: string) {
        const theme: ITheme = {
            id,
            name,
        };
        this.availableThemes.push(theme);
    }

    /**
     * Load the theme ID from browser storage, that was selected by the user.
     * 
     * If the user never changed their theme, then the default one will be used.
     *
     * @private
     * @returns A promise containing the ID of the theme that was retrieved from browser storage.
     * @memberof ThemeManager
     */
    private async loadThemeFromStorage() {
        let currentThemeId: string = this.defaultThemeId;

        try {
            currentThemeId = await this.storageExtension.load(this.componentStorageName, this.themeStorageId);
        } catch (error) {
            // Select the mobile theme if we detect that we are on a phone or tablet.
            if (DeviceDetection.isMobile()) {
                currentThemeId = this.defaultMobileThemeId;
            }

            this.logger.info(this.className, 'loadThemeFromStorage', `No theme set. Falling back to default: ${currentThemeId}`);
        }

        return currentThemeId;
    }

    /**
     * Save a theme ID to the browser storage.
     *
     * @private
     * @param {string} id ID of the theme to save.
     * @returns Promise of the async save operation.
     * @memberof ThemeManager
     */
    private async saveThemeToStorage(id: string) {
        const storageDataModel = new StorageDataModel(this.themeStorageId, id);
        return this.storageExtension.save(this.componentStorageName, storageDataModel);
    }

    /**
     * Applies the theme based on the given custom config.
     * 
     * @private
     * @param {CustomConfig} customConfig Configuration to apply. 
     * @memberof ThemeManager
     */
    private applyCustomTheme(customConfig: CustomConfig): void {
        if (typeof customConfig.browserTitle === 'string') {
            this.documentRef.title = customConfig.browserTitle;
        }

        // Indicate usage of custom theme
        this.documentRef.body.classList.add('wd-custom-theme');

        // Load external styles
        if (customConfig.customCssFilePath) {
            const customCssLinkElement = this.documentRef.createElement('link');
            customCssLinkElement.setAttribute('rel', 'stylesheet');
            customCssLinkElement.setAttribute('type', 'text/css');
            customCssLinkElement.setAttribute('href', customConfig.customCssFilePath);
            this.documentRef.head.appendChild(customCssLinkElement);
        }

        const themeColorElement = this.documentRef.querySelector('meta[name=\'theme-color\']');
        const appleMobileWebAppTitleElement = this.documentRef.querySelector('meta[name=\'apple-mobile-web-app-title\']');
        if (themeColorElement && customConfig.headerBackgroundColor) {
            themeColorElement.setAttribute('content', customConfig.headerBackgroundColor);
        }
        if (appleMobileWebAppTitleElement && typeof customConfig.browserTitle === 'string') {
            appleMobileWebAppTitleElement.setAttribute('content', customConfig.browserTitle);
        }

        // Apply styles that override default styles
        const customStyle = this.documentRef.createElement('style');
        customStyle.type = 'text/css';
        const stylesViewNames = `.wd-custom-theme .wd-top-bar .wd-navigation-bar .wd-navigation-list > li > a { color: ${customConfig.headerTextColor}  }`;
        const stylesViewHeaderHover = `.wd-custom-theme .wd-top-bar .wd-navigation-bar .wd-navigation-list > li > a:hover { background-color: ${customConfig.headerIconHoverColor}  }`;
        const stylesAddViewHover = ` .wd-custom-theme .wd-top-bar .wd-navigation-bar .wd-navigation-list > li a:hover { background-color: ${customConfig.headerIconHoverColor} }
            .wd-custom-theme .wd-top-bar .wd-navigation-bar .wd-navigation-list > li button:hover { background-color: ${customConfig.headerIconHoverColor} }`;
        const stylesUserIconHover = `.wd-custom-theme .wd-top-bar .wd-app-bar-content:not(.alternate) .wd-icon-button:hover { background-color: ${customConfig.headerIconHoverColor}  }`;
        const navigationViewTextColor = `.wd-navigation-panel-list a { color:${customConfig.navigationTextColor} !important}`;
        const navigationViewIconColor = `.wd-navigation-panel .wd-icon { color:${customConfig.navigationTextColor} !important}`;
        const navigationHover = `.wd-navigation-panel-list a:hover { background-color: ${customConfig.navigationHoverColor} !important}`;
        // eslint-disable-next-line max-len
        const navigationSettingsButtonHover = `.wd-navigation-panel .wd-navigation-panel-lower-button-group .wd-settings-button-group:hover {background-color: ${customConfig.navigationHoverColor} !important}`;
        // eslint-disable-next-line max-len
        const navigationAboutButtonHover = `.wd-navigation-panel .wd-navigation-panel-lower-button-group .wd-about-button-group:hover {background-color: ${customConfig.navigationHoverColor} !important}`;
        const shrinkButtonHover = `.wd-navigation-panel .wd-navigation-panel-button-group .wd-shrink-navigation-button:hover {background-color: ${customConfig.navigationHoverColor} !important}`;
        const activeViewBroderColor = `.wd-navigation-panel .active {border-color: ${customConfig.activeViewIconColor} !important}`;
        const activeViewIconColor = `.wd-navigation-panel .active .wd-icon {color: ${customConfig.activeViewIconColor} !important}`;
        const activeViewBackgroundColor = `.wd-navigation-panel .active {background-color: ${customConfig.activeViewBackgroundColor} !important}`;
        const navigationSeparatorFontcolor = `.wd-navigation-panel.collapsed .wd-view-navigation-separator {background-color: ${customConfig.navigationTextColor} !important}`;
        customStyle.appendChild(this.documentRef.createTextNode(stylesViewNames));
        customStyle.appendChild(this.documentRef.createTextNode(stylesViewHeaderHover));
        customStyle.appendChild(this.documentRef.createTextNode(stylesAddViewHover));
        customStyle.appendChild(this.documentRef.createTextNode(stylesUserIconHover));
        customStyle.appendChild(this.documentRef.createTextNode(navigationViewTextColor));
        customStyle.appendChild(this.documentRef.createTextNode(navigationViewIconColor));
        customStyle.appendChild(this.documentRef.createTextNode(navigationHover));
        customStyle.appendChild(this.documentRef.createTextNode(navigationSettingsButtonHover));
        customStyle.appendChild(this.documentRef.createTextNode(navigationAboutButtonHover));
        customStyle.appendChild(this.documentRef.createTextNode(shrinkButtonHover));
        customStyle.appendChild(this.documentRef.createTextNode(activeViewBroderColor));
        customStyle.appendChild(this.documentRef.createTextNode(activeViewIconColor));
        customStyle.appendChild(this.documentRef.createTextNode(activeViewBackgroundColor));
        customStyle.appendChild(this.documentRef.createTextNode(navigationSeparatorFontcolor));
        this.documentRef.head.appendChild(customStyle);
    }

    /**
     * Returns the default config.
     *
     * @private
     * @returns {CustomConfig}
     * @memberof ThemeManager
     */
    public getDefaultConfig(): CustomConfig {
        const defaultConfig = new CustomConfig();
        defaultConfig.activeViewBackgroundColor = '#b2b2b2';
        defaultConfig.activeViewIconColor = '#024c84';
        defaultConfig.browserTitle = this.languageProvider.get('framework.header.headerTitle');
        defaultConfig.customCssFilePath = '';
        defaultConfig.headerBackgroundColor = 'transparent';
        defaultConfig.headerIconHoverColor = '#d1d1d1';
        defaultConfig.headerTextColor = '#494949';
        defaultConfig.iconPath = './framework/assets/product_icon.png';
        defaultConfig.navigationBackgroundColor = '#e7e7e7';
        defaultConfig.navigationHoverColor = '#d1d1d1';
        defaultConfig.navigationTextColor = '#494949';
        defaultConfig.subTitle = this.languageProvider.get('framework.header.subTitle');
        defaultConfig.title = this.languageProvider.get('framework.header.title');
        defaultConfig.titleBackgroundColor = '#e7e7e7';
        defaultConfig.titleTextColor = '#494949';
        return defaultConfig;
    }

}