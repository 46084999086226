import { DWCore } from '@windream/dw-core/dwCore';
import { IConfigTranslation } from '../interfaces';
import { Style } from './style';
import { ComponentInstanceConfigConfiguration, ComponentInstanceToolbarConfig, Position } from '.';

/**
 * This class is a data holder for each component config.
 * 
 * @export
 * @class ComponentInstanceConfig
 */
export class ComponentInstanceConfig implements DWCore.Components.ComponentInstanceConfig {
    /**
     * The component name.
     * 
     * @type {string}
     * @memberof ComponentInstanceConfig
     */
    public component: string;

    /**
     * The component guid.
     * 
     * @type {string}
     * @memberof ComponentInstanceConfig
     */
    public guid: string;

    /**
     * The name of this component.
     * 
     * @type {IConfigTranslation<string>}
     * @memberof ComponentInstanceConfig
     */
    public name: IConfigTranslation<string>;

    /**
     * The Title of the component that is displayed in the header.
     * 
     * @type {IConfigTranslation<string>}
     * @memberof ComponentInstanceConfig
     */
    public title?: IConfigTranslation<string>;

    /**
     * Flag that indicates if the title text should be shown.
     * 
     * @type {boolean}
     * @memberof ComponentConfigurationComponent
     */
    public isTitleVisible?: boolean;

    /**
     * The configuration of this component.
     * 
     * @type {*}
     * @memberof ComponentInstanceConfig
     */
    public configuration: any; // TODO?: Use ComponentInstanceConfigConfiguration, but this requires reworking tests

    /**
     * The position of this component.
     * 
     * @type {(Position | string | null)}
     * @memberof ComponentInstanceConfig
     */
    public position?: Position | string | null;

    /**
     * The style of this component.
     * 
     * @type {(Style | null)}
     * @memberof ComponentInstanceConfig
     */
    public style?: Style | null;

    /**
     * The component instance config version.
     *
     * @type {string}
     * @memberof ComponentInstanceConfig
     */
    public version?: string;

    /**
     * The type of this specific component i.e. logic component.
     *
     * @type {DWCore.Components.COMPONENT_TYPES}
     * @memberof ComponentInstanceConfig
     */
    public componentType?: DWCore.Components.COMPONENT_TYPES;

    /**
     * The toolbar config.
     *
     * @type {ComponentInstanceToolbarConfig}
     * @memberof ComponentInstanceConfig
     */
    public toolbar?: ComponentInstanceToolbarConfig;

    /**
     * Creates an instance of ComponentInstanceConfig.
     *
     * @param {string} guid
     * @param {string} component
     * @memberof ComponentInstanceConfig
     */
    public constructor(guid: string, component: string) {
        this.guid = guid;
        this.component = component;
        this.name = {};
        this.configuration = new ComponentInstanceConfigConfiguration();
        this.toolbar = new ComponentInstanceToolbarConfig();
    }
}