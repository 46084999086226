import { ValueTypes } from '../../../../typings/core';

/**
 * The CellData model.
 * 
 * @export
 * @class CellData
 */
export class CellData {

    /**
     * The display value of this cell.
     * 
     * @type {string}
     * @memberof CellData
     */
    public displayValue?: string;

    /**
     * Tooltip of this cell.
     * 
     * @type {string}
     * @memberof CellData
     */
    public tooltip?: string;

    /**
     * The value of this cell.
     *
     * @type {(string | number | Date)}
     * @memberof CellData
     */
    public value?: string | number | Date;

    /**
     * Type of the value of this cell.
     * 
     * @type {ValueTypes}
     * @memberof CellData
     */
    public valueType?: ValueTypes;

}