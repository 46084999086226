import { Utils } from '../common';
import { InConfig } from '../config/models/inConfig';
import { OutConfig } from '../config/models/outConfig';

/**
 * Utility class to determine if data types for PubSub connections match.
 * 
 * @export
 * @class DataTypeChecker
 */
export class DataTypeChecker {
    /**
     * Mapping of hierarchical PubSub datatypes.
     * 
     * @static
     * 
     * @memberof DataTypeChecker
     */
    public static readonly dataTypeMapping = {
        ASYNC_DATATYPE_STRING: [
            'DATATYPE_STRING'
        ],
        DATATYPE_NUMBER: [
            'DATATYPE_INTEGER'
        ],
        DATATYPE_WINDREAM_IDENTITY: [
            'DATATYPE_WINDREAM_FILE',
            'DATATYPE_WINDREAM_DIR'
        ]
    } as {
            [dataType: string]: string[];
        };

    /**
     * Dynamic data type that matches all other datatypes.
     * 
     * @static
     * @memberof DataTypeChecker
     */
    public static readonly DATATYPE_ANY = 'DATATYPE_ANY';


    /**
     * Checks if the given data types match.
     *
     * @static
     * @param {OutConfig} outDataType The out data type.
     * @param {InConfig} inDataType The in data type.
     * @returns {boolean} Whether it can connect or not.
     * @memberof DataTypeChecker
     */
    public static checkDataTypeMatch(outDataType: OutConfig, inDataType: InConfig): boolean {
        return !!outDataType.dataType && DataTypeChecker.checkDataTypeMatchWithString(inDataType, outDataType.dataType);
    }

    /**
     * Checks if the given data types match.
     *
     * @static
     * @param {InConfig} inDataType The in data type.
     * @param {string} dataType The data type.
     * @returns {boolean} Whether it can connect or not.
     * @memberof DataTypeChecker
     */
    public static checkDataTypeMatchWithString(inDataType: InConfig, dataType: string): boolean {
        if (Utils.Instance.isArray(inDataType.dataType)) {
            return !!((dataType === DataTypeChecker.DATATYPE_ANY ||
                inDataType.dataType.find((compatibleConnection) => compatibleConnection === DataTypeChecker.DATATYPE_ANY) ||
                inDataType.dataType.find((compatibleConnection) => compatibleConnection === dataType)) ||
                inDataType.dataType.find((compatibleConnection) => DataTypeChecker.dataTypeMapping[compatibleConnection] &&
                    DataTypeChecker.dataTypeMapping[compatibleConnection].indexOf(dataType) !== -1));
        } else {
            return !!(
                (dataType === inDataType.dataType || // Direct match
                    dataType === DataTypeChecker.DATATYPE_ANY || // Match via universal data type
                    inDataType.dataType === DataTypeChecker.DATATYPE_ANY) ||
                    (inDataType.dataType && DataTypeChecker.dataTypeMapping[inDataType.dataType] && DataTypeChecker.dataTypeMapping[inDataType.dataType].indexOf(dataType) !== -1)
                );
        }
    }
}