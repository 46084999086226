import { AuthenticationModes, TokenProvider } from '../authentication';
import { LogLevel } from './enums/logLevel';
import { ILoggerInterface } from './interfaces';

/**
 * The Logger class is used for logging purposes.
 *
 * @export
 * @class Logger
 */
export class Logger {

    private readonly CONTENT_LENGTH_THRESHOLD: number = 100;

    private logLevel: LogLevel;
    private logger: ILoggerInterface;
    private consoleInstance: Console;
    private tokenProvider?: TokenProvider;
    private authenticationMode?: AuthenticationModes;

    /**
     * Creates an instance of Logger.
     *
     * @param {LogLevel} logLevel 
     * @param {ILoggerInterface} logger 
     * @param {Console} console
     * @memberof Logger
     */
    public constructor(logLevel: LogLevel, logger: ILoggerInterface, consoleInstance: Console) {
        this.logLevel = logLevel;
        this.logger = logger;
        this.consoleInstance = consoleInstance;
        this.logger.init(this.logLevel);
    }

    /**
     * Enables the web service logging.
     *
     * @param {string} webserviceURL
     * @param {TokenProvider} tokenProvider
     * @param {AuthenticationModes} authenticationMode
     * @memberof Logger
     */
    public enableWebServiceLogging(webserviceURL: string, tokenProvider: TokenProvider, authenticationMode: AuthenticationModes) {
        this.tokenProvider = tokenProvider;
        this.authenticationMode = authenticationMode;
        this.logger.enableWebServiceLogging(webserviceURL, authenticationMode === AuthenticationModes.Windows);
    }

    /**
     * Disables the web service logging.
     *
     * @memberof Logger
     */
    public disableWebServiceLogging() {
        this.tokenProvider = undefined;
    }

    /**
     * Write a fatal log line.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @param {*} [content=null] The content object which should be logged.
     *
     * @memberof Logger
     */
    public fatal(className: string, methodName: string, caption: string, content: any = null): void {
        if (this.logLevel < LogLevel.Fatal) {
            return;
        }
        const origin = this.createOriginString(className, methodName, caption);
        this.consoleInstance.error(origin, content);
        this.getAuthenticationToken().then((token) => {
            // No token means no provider was set
            if (token) {
                this.logger.fatal(origin, this.createMessage(content), token);
            }
        }).catch((err: Error) => {
            this.consoleInstance.error(this.createOriginString('Logger', 'fatal', 'Failed to get token'), err);
        });
    }
    /**
     * Write a error log line.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @param {*} [content=null] The content object which should be logged.
     *
     * @memberof Logger
     */
    public error(className: string, methodName: string, caption: string, content: any = null): void {
        if (this.logLevel < LogLevel.Error) {
            return;
        }
        const origin = this.createOriginString(className, methodName, caption);
        this.consoleInstance.error(origin, content);
        this.getAuthenticationToken().then((token) => {
            // No token means no provider was set
            if (token) {
                this.logger.error(origin, this.createMessage(content), token);
            }
        }).catch((err: Error) => {
            this.consoleInstance.error(this.createOriginString('Logger', 'error', 'Failed to get token'), err);
        });
    }
    /**
     * Write a warn log line.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @param {*} [content=null] The content object which should be logged.
     *
     * @memberof Logger
     */
    public warn(className: string, methodName: string, caption: string, content: any = null): void {
        if (this.logLevel < LogLevel.Warn) {
            return;
        }
        const origin = this.createOriginString(className, methodName, caption);
        this.consoleInstance.warn(origin, content);
        this.getAuthenticationToken().then((token) => {
            // No token means no provider was set
            if (token) {
                this.logger.warn(origin, this.createMessage(content), token);
            }
        }).catch((err: Error) => {
            this.consoleInstance.error(this.createOriginString('Logger', 'warn', 'Failed to get token'), err);
        });
    }
    /**
     * Write a info log line.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @param {*} [content=null] The content object which should be logged.
     *
     * @memberof Logger
     */
    public info(className: string, methodName: string, caption: string, content: any = null): void {
        if (this.logLevel < LogLevel.Info) {
            return;
        }
        const origin = this.createOriginString(className, methodName, caption);
        this.consoleInstance.info(origin, content);
        this.getAuthenticationToken().then((token) => {
            // No token means no provider was set
            if (token) {
                this.logger.info(origin, this.createMessage(content), token);
            }
        }).catch((err: Error) => {
            this.consoleInstance.error(this.createOriginString('Logger', 'info', 'Failed to get token'), err);
        });
    }
    /**
     * Write a debug log line.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @param {*} [content=null] The content object which should be logged.
     *
     * @memberof Logger
     */
    public debug(className: string, methodName: string, caption: string, content: any = null): void {
        if (this.logLevel < LogLevel.Debug) {
            return;
        }
        const origin = this.createOriginString(className, methodName, caption);
        this.consoleInstance.debug(origin, content);
        this.getAuthenticationToken().then((token) => {
            // No token means no provider was set
            if (token) {
                this.logger.debug(origin, this.createMessage(content), token);
            }
        }).catch((err: Error) => {
            this.consoleInstance.error(this.createOriginString('Logger', 'debug', 'Failed to get token'), err);
        });
    }
    /**
     * Write a trace log line.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @param {*} [content=null] The content object which should be logged.
     *
     * @memberof Logger
     */
    public trace(className: string, methodName: string, caption: string, content: any = null): void {
        if (this.logLevel < LogLevel.Trace) {
            return;
        }
        const origin = this.createOriginString(className, methodName, caption);
        this.consoleInstance.trace(origin, content);
        this.getAuthenticationToken().then((token) => {
            // No token means no provider was set
            if (token) {
                this.logger.trace(origin, this.createMessage(content), token);
            }
        }).catch((err: Error) => {
            this.consoleInstance.error(this.createOriginString('Logger', 'trace', 'Failed to get token'), err);
        });
    }
    /**
     * Write a log line depending on the logLevel.
     *
     * @param {LogLevel} logLevel The logLevel of this call.
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @param {*} [content=null] The content object which should be logged.
     *
     * @memberof Logger
     */
    public log(logLevel: LogLevel, className: string, methodName: string, caption: string, content: any = null): void {
        if (logLevel === LogLevel.Trace) {
            this.trace(className, methodName, caption, content);
        } else if (logLevel === LogLevel.Debug) {
            this.debug(className, methodName, caption, content);
        } else if (logLevel === LogLevel.Info) {
            this.info(className, methodName, caption, content);
        } else if (logLevel === LogLevel.Warn) {
            this.warn(className, methodName, caption, content);
        } else if (logLevel === LogLevel.Error) {
            this.error(className, methodName, caption, content);
        } else if (logLevel === LogLevel.Fatal) {
            this.fatal(className, methodName, caption, content);
        }
    }

    /**
     * Logs a fatal exception, which went uncaught.
     *
     * @param {string} msg The message.
     * @param {string} errorMsg The error message.
     * @param {string} url The url.
     * @param {number} lineNumber The line number.
     * @param {number} column The column.
     * @param {Error} errorObject The error object.
     *
     * @memberof Logger
     */
    public fatalException(msg: string, errorMsg: string, url: string, lineNumber: number, column: number, errorObject: Error): void {
        this.getAuthenticationToken().then((token) => {
            // No token means no provider was set
            if (token) {
                this.logger.fatalException(msg, errorMsg, url, lineNumber, column, errorObject, token);
            } else {
                this.fatal('Unknown', 'FatalException', msg + ' ' + errorMsg, errorObject);
            }

        }).catch((err: Error) => {
            this.consoleInstance.error(this.createOriginString('Logger', 'fatalException', 'Failed to get token'), err);
        });
    }

    /**
     * Sets the log level of the logger.
     *
     * @param {LogLevel} logLevel The log level.
     * @memberof Logger
     */
    public setLogLevel(logLevel: LogLevel) {
        this.logLevel = logLevel;
        this.logger.setLogLevel(logLevel);
    }

    /**
     * Creates the origin identifier.
     *
     * @private
     * @param {string} className The className of this call.
     * @param {string} methodName The methodName of this call.
     * @param {string} caption The caption of this call.
     * @returns {string} The originString.
     *
     * @memberof Logger
     */
    private createOriginString(className: string, methodName: string, caption: string): string {
        return className + ':' + methodName + ':' + caption;
    }

    /**
     * Creates the message which should be logged.
     * 
     * @private
     * @param {*} content The content object which should be logged.
     * @returns {string}
     * 
     * @memberof Logger
     */
    private createMessage(content: any): string {
        if (!content) {
            return '';
        }

        let result: string = '';
        if (typeof content === 'string') {
            result = content;
        } else {
            try {
                result = JSON.stringify(content);

                if (result && result.length > this.CONTENT_LENGTH_THRESHOLD) {
                    result = result.substr(0, this.CONTENT_LENGTH_THRESHOLD);
                }
            } catch (error) {
                if (error && error.message) {
                    result = error.message;
                }
            }
        }

        return result;
    }

    /**
     * Gets the authentication token.
     *
     * @async
     * @private
     * @returns {Promise<string>} Promise to resolve with the token,
     * @memberof Logger
     */
    private async getAuthenticationToken(): Promise<string | undefined> {
        if (this.authenticationMode === AuthenticationModes.Windows || !this.tokenProvider) {
            return Promise.resolve(undefined);
        }
        return this.tokenProvider.getToken();
    }
}