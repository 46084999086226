import { INotificationHelper } from './interfaces';
import { NotificationOptions } from './models/notificationOptions';

/**
 * The notification helper is used in order to display notifications.
 *
 * @export
 * @class NotificationHelper
 * @implements {INotificationHelper}
 */
export class NotificationHelper implements INotificationHelper {
  private static instance: INotificationHelper = new NotificationHelper();
  private static readonly longToastDisplayTime = 6000;

  /**
   * Creates an instance of NotificationHelper.
   *
   * @memberof NotificationHelper
   */
  private constructor() {
    /** Required for singelton. */
  }

  /**
   * Gets the singleton instance.
   *
   * @readonly
   * @static
   * @type {INotificationHelper}
   * @memberof NotificationHelper
   */
  public static get Instance(): INotificationHelper {
    return NotificationHelper.instance;
  }

  /**
   * Use the Instance singleton's implementation instead of this static method.
   *
   * Creates a success notification.
   *
   * @param {NotificationOptions} options The notification options.
   * @deprecated 
   * 
   * @memberof NotificationHelper
   */
  public static success(options: NotificationOptions): void {
    NotificationHelper.Instance.success(options);
  }

  /**
   * Use the Instance singleton's implementation instead of this static method.
   *
   * Creates a info notification.
   *
   * @param {NotificationOptions} options The notification options.
   * @deprecated 
   * 
   * @memberof NotificationHelper
   */
  public static info(options: NotificationOptions): void {
    NotificationHelper.Instance.info(options);
  }

  /**
   * Use the Instance singleton's implementation instead of this static method.
   *
   * Creates a warning notification.
   *
   * @param {NotificationOptions} options The notification options.
   * @deprecated
   * 
   * @memberof NotificationHelper
   */
  public static warning(options: NotificationOptions): void {
    NotificationHelper.Instance.warning(options);
  }

  /**
   * Use the Instance singleton's implementation instead of this static method.
   *
   * Creates a error notification.
   *
   * @param {NotificationOptions} options The notification options.
   * @deprecated
   * 
   * @memberof NotificationHelper
   */
  public static error(options: NotificationOptions): void {
    NotificationHelper.Instance.error(options);
  }

  /**
   * Creates a success notification.
   *
   * @param {NotificationOptions} options The notification options.
   *
   * @memberof NotificationHelper
   */
  public success(options: NotificationOptions): void {
    this.displayToast(this.createMessageFromNotificationOptions(options), 'success');
  }

  /**
   * Creates a info notification.
   *
   * @param {NotificationOptions} options The notification options.
   *
   * @memberof NotificationHelper
   */
  public info(options: NotificationOptions): void {
    this.displayToast(this.createMessageFromNotificationOptions(options), 'info');
  }

  /**
   * Creates a warning notification.
   *
   * @param {NotificationOptions} options The notification options.
   *
   * @memberof NotificationHelper
   */
  public warning(options: NotificationOptions): void {
    this.displayToast(this.createMessageFromNotificationOptions(options), 'warning');
  }

  /**
   * Creates a error notification.
   *
   * @param {NotificationOptions} options The notification options.
   *
   * @memberof NotificationHelper
   */
  public error(options: NotificationOptions): void {
    this.displayToast(this.createMessageFromNotificationOptions(options), 'error');
  }

  /**
   * Creates the message from the notification options.
   *
   * @private
   * @param {NotificationOptions} options The options.
   * @returns {string} The message.
   * @memberof NotificationHelper
   */
  private createMessageFromNotificationOptions(options: NotificationOptions): string {
    let message = '';
    if (options.title) {
      message = options.title;
    }
    if (options.body && options.title) {
      message += ': ';
    }
    if (options.body) {
      message += options.body;
    }
    return message;
  }

  /**
   * Display a toast.
   *
   * @private
   * @param {string} message The message to display.
   * @param {('success' | 'info' | 'warning' | 'error')} type The type of the toast.
   * @memberof NotificationHelper
   */
  private displayToast(message: string, type: 'success' | 'info' | 'warning' | 'error'): void {
    // @ts-ignore Because of window check
    if (window['DevExpress'] && window['DevExpress'].ui) {
      // Material Design: Between 4 and 10 seconds
      window['DevExpress'].ui.notify(message, type, NotificationHelper.longToastDisplayTime);
    }
  }
}