import { IPubSubHandler, PubSubConfig } from '../pubSub';

/**
 * Util class for the PubSubUiHelper.
 * 
 * @export
 * @class PubSubUiHelperUtil
 */
export class PubSubUiHelperUtil {
    public static readonly className = 'PubSubUiHelper';
    public static readonly MAINFRAME_COMPONENT_GUID = 'WINDREAM_PUBSUB_MAINFRAME';
    public static readonly ADD_CONNECTION_COMPONENT_GUID = 'WINDREAM_PUBSUB_ADD_CONNECTION';
    public static readonly ADD_COMPONENT_COMPONENT_GUID = 'WINDREAM_PUBSUB_ADD_COMPONENT';
    public static readonly CONFIG_COMPONENT_GUID = 'WINDREAM_PUBSUB_CONFIGURATION';

    /**
     * Adds PubSub connections that are used by the PubSub configuration.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof PubSubUiHelperUtil
     */
    public static addMainPubSub(pubSubHandler: IPubSubHandler): void {
        pubSubHandler.loadPubSub([
            {
                // Used in com.windream.pubSub.mainframe when the Panel should be closed and changes exists
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges'
                }]
            }, {
                // Used in com.windream.configuration when the Panel should be closed and changes exists, thus saving these changes.
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_SavePubSub'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_SavePubSub'
                }]
            }, {
                // Used in com.windream.pubSub.mainframe when the Panel should be closed and changes exists, thus discarding these changes.
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_DiscardConfig'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'HasChanges_DiscardConfig'
                }]
            }, {
                // Used in com.windream.pubSub.mainframe when a new connection should be created from the Mainframe, passes PubSubConfig
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'NewConnectionIntent'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'NewConnectionIntent'
                }]
            }, {
                // Used in com.windream.pubSub.mainframe when a new connection should be created from the Mainframe
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'AddedComponent'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'AddedComponent'
                }]
            }, {
                // Used in com.windream.pubSub.mainframe when the view config changed so that PubSub config has new values
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }]
            }, {
                // Used in com.windream.pubSub.mainframe when the view config changed so that PubSub config has new values
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'SwitchedSubView'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'SwitchedSubView'
                }]
            }, {
                // Used in com.windream.pubSub.mainframe when a change is made within the component
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }]
            },
            {
                // Used in com.windream.pubSub.mainframe when a change is made within the component
                in: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'UIConfigChanged'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                    parameter: 'UIConfigChanged'
                }]
            }
        ].map((connection: PubSubConfig) => {
            connection.executeForEmptyData = true;
            return connection;
        }));
    }

    /**
     * Adds PubSub connections that are used by the PubSub configuration.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof PubSubUiHelperUtil
     */
    public static addAddConnectionPubSub(pubSubHandler: IPubSubHandler): void {
        pubSubHandler.loadPubSub([
            {
                // Used in com.windream.pubSub.addConnection when the view config changed so that PubSub config has new values
                in: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }]
            }, {
                // Used in com.windream.pubSub.addConnection when save is clicked inside Add component
                in: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }]
            }, {
                // Used in com.windream.pubSub.addConnection when a new connection should be created, passes out component GUID
                in: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'NewConnection'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'NewConnection'
                }]
            }, {
                // Used in com.windream.pubSub.add when saving occured
                in: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'SwitchedSubView'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.ADD_CONNECTION_COMPONENT_GUID,
                    parameter: 'SwitchedSubView'
                }]
            }
        ].map((connection: PubSubConfig) => {
            connection.executeForEmptyData = true;
            return connection;
        }));
    }

    /**
     * Adds PubSub connections that are used by the PubSub Configuration.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof PubSubUiHelperUtil
     */
    public static addConfigurationPubSub(pubSubHandler: IPubSubHandler): void {
        pubSubHandler.loadPubSub([
            {
                // Used in com.windream.pubSub.sidepanel when the view config changed so that PubSub config has new values
                in: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'UpdatedConfig'
                }]
            }, {
                // Used in com.windream.pubSub.sidepanel when a new connection should be created, passes PubSubConfig
                in: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'NewConnectionIntent'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'NewConnectionIntent'
                }]
            }, {
                // Used in com.windream.pubSub.sidepanel when a change is made within the component
                in: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'SavedConfig'
                }]
            }, {
                // Used in com.windream.pubSub.add when saving occured
                in: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'SwitchedSubView'
                }],
                out: [{
                    componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                    parameter: 'SwitchedSubView'
                }]
            }
        ].map((connection: PubSubConfig) => {
            connection.executeForEmptyData = true;
            return connection;
        }));
    }

    /**
     * Adds PubSub connections that are used by the PubSub Add Component Panel.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof PubSubUiHelperUtil
     */
    public static addAddComponentPubSub(pubSubHandler: IPubSubHandler): void {
        pubSubHandler.loadPubSub([{
            // Used in com.windream.pubSub.addComponent when the view config changed so that PubSub config has new values
            in: [{
                componentGuid: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID,
                parameter: 'UpdatedConfig'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID,
                parameter: 'UpdatedConfig'
            }]
        }, {
            // Used in com.windream.pubSub.addComponent when a component is selected within the mainframe
            in: [{
                componentGuid: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID,
                parameter: 'AddComponent'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID,
                parameter: 'AddComponent'
            }]
        }, {
            // Used in com.windream.pubSub.add when saving occured
            in: [{
                componentGuid: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID,
                parameter: 'SwitchedSubView'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.ADD_COMPONENT_COMPONENT_GUID,
                parameter: 'SwitchedSubView'
            }]
        }].map((connection: PubSubConfig) => {
            connection.executeForEmptyData = true;
            return connection;
        }));
    }

    /**
     * Adds PubSub connections between Mainframe and Configuration components.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof PubSubUiHelperUtil
     */
    public static addMainframeConfigurationPubSub(pubSubHandler: IPubSubHandler): void {
        // PubSub connections used for comunication between Mainframe and Configuration
        pubSubHandler.loadPubSub([{
            // Used when a component is selected within the mainframe
            in: [{
                componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                parameter: 'SelectedComponent'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                parameter: 'SelectComponent'
            }]
        }, {
            // Used when a connection is selected within the mainframe
            in: [{
                componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                parameter: 'SelectedComponent'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                parameter: 'SelectComponent'
            }]
        }, {
            // Used when nothing is selected (aka deselection) within the mainframe
            in: [{
                componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                parameter: 'Deselected'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                parameter: 'Deselect'
            }]
        }, {
            // Used when a component is selected within the configuration
            in: [{
                componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                parameter: 'SelectedComponent'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                parameter: 'SelectComponent'
            }]
        }, {
            // Used when a connection is selected within the configuration
            in: [{
                componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                parameter: 'SelectedConnection'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                parameter: 'SelectConnection'
            }]
        }, {
            // Used when a connection is selected within the configuration
            in: [{
                componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                parameter: 'SelectedConnection'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                parameter: 'SelectConnection'
            }]
        },
        {
            // Used when nothing is selected (aka deselection) within the configuration
            in: [{
                componentGuid: PubSubUiHelperUtil.MAINFRAME_COMPONENT_GUID,
                parameter: 'Deselected'
            }],
            out: [{
                componentGuid: PubSubUiHelperUtil.CONFIG_COMPONENT_GUID,
                parameter: 'Deselect'
            }]
        }
        ].map((connection: PubSubConfig) => {
            connection.executeForEmptyData = true;
            return connection;
        }));
    }
}