import { ResponseDetailsType, WindreamEntity, WindreamIdentity } from '../../common';
import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents move bulk request options.
 *
 * @export
 * @class MoveBulkRequestOptions
 * @extends {BaseRequestOptions}
 */
export class MoveBulkRequestOptions extends BaseRequestOptions {

    /**
     * The source identities.
     *
     * @type {WindreamIdentity[]}
     * @memberof MoveBulkRequestOptions
     */
    public sourceIdentities: WindreamIdentity[];

    /**
     * The target directory.
     *
     * @type {WindreamIdentity}
     * @memberof MoveBulkRequestOptions
     */
    public targetDirectory: WindreamIdentity;

    /**
     * The response details.
     *
     * @type {ResponseDetailsType}
     * @memberof MoveBulkRequestOptions
     */
    public responseDetails: ResponseDetailsType;

    /**
     * The target entity.
     *
     * @private
     * @type {WindreamEntity}
     * @memberof MoveBulkRequestOptions
     */
    public targetEntity: WindreamEntity;

    /**
     * Creates an instance of MoveBulkRequestOptions.
     * @param {WindreamIdentity[]} sourceIdentities
     * @param {WindreamIdentity} targetDirectory
     * @param {ResponseDetailsType} responseDetails
     * @memberof MoveBulkRequestOptions
     */
    public constructor(sourceIdentities: WindreamIdentity[], targetDirectory: WindreamIdentity,
        responseDetails: ResponseDetailsType) {
        super();
        if (!sourceIdentities) {
            throw new Error('Argument \'sourceIdentities\' is not valid');
        }
        if (!targetDirectory) {
            throw new Error('Argument \'targetDirectory\' is not valid');
        }
        if (typeof responseDetails !== 'number') {
            throw new Error('Argument \'responseDetails\' is not valid');
        }
        this.sourceIdentities = sourceIdentities;
        this.targetDirectory = targetDirectory;
        this.responseDetails = responseDetails;
        this.targetEntity = typeof targetDirectory.entity === 'undefined' ?  WindreamEntity.Folder : targetDirectory.entity;
    }
}