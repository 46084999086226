import { Utils } from '../common';
import { Logger } from '../logging/logger';
import { IPubSubArrayObject } from './interfaces/pubSubArrayObject';
import { PubSubModel } from './pubSubModel';
/**
 * The PubSub class provides the functionality of the Publish–subscribe pattern, in order to ensure communication between two classes/functions.
 * To enable a communication "pipe" between the subscriber and the publisher two prerequisites must be met.
 * 
 * 1. The subscriber has to call the Subscribe-method and needs to specify the name of the event/action and a callback function with one argument.
 * 2. The publisher has to call the Publish-method and needs to specify the name of the event/action and the data, which shall be transmitted.
 * 
 * After all prerequisites are met the PubSub will ensure the communication based on the name of the event. In order to etablish a communication "pipe" the
 * name of the event must be identical between a Subscribe- and Publish-method call.
 * Furthermore a Unsubscribe call is also possible, which terminates the communication "pipe" and deletes the subscription. 
 * @exports PubSub
 * @summary The PubSub class provides the functionality of the Publish–subscribe pattern.
 * @version 1.0.0
 * @example  
 * //Create instance of PubSub
 * var pubSub = new PubSub();
 * //Subscribe to testEvent
 * pubSub.Subscribe("testEvent", (msg: Object) => {var data = msg;}, this);
 * //The testEvent will be executed and the callback method will be called
 * pubSub.Publish("testEvent", "data");
 */
export class PubSub {

    // Contains every subscriber
    private subscriberArray: IPubSubArrayObject;
    private logger: Logger;
    private className: string = 'PubSub';


    /**
     * Creates an instance of PubSub.
     * @param {Logger} logger Logger instance to use.
     * 
     * @memberof PubSub
     */
    public constructor(logger: Logger) {
        this.subscriberArray = {};
        this.logger = logger;
    }

    /**
     * Subscribe to an specific event/action. If the event occurs the callback method will be invoked
     * @access public
     * @param {string} name The name of the event/action, which shall be subscribed.
     * @param {function} callback The callback function which will be called if a corresponding Publish-function call was executed.     
     * @throws Will throw an error if the name or callback is null.
     * @version 1.0.0
     * @example //Subscribe to an event with the name testEvent and will display a MessageBox when the callback was called.
     * pubSub.Subscribe("testEvent", (msg: Object) => {var data = msg; alert(data)}, this);
     */
    public subscribe(name: string, callback: (data?: Object | null) => void) {
        if (!name || !callback) {
            throw new Error('Can not subscribe with undefined parameters.');
        }

        if (!this.subscriberArray[name]) {
            this.subscriberArray[name] = [];
        }
        this.subscriberArray[name].push(new PubSubModel(name, callback));
    }


    /**
     * Unsubscribe from an specific event/action. Does not throw an error if the specific event name is not available.
     * @access public
     * @param {string} name The name of the event/action, which shall be unsubscribed.
     * @version 1.0.0
     * @example //Unsubscribe the event testEvent
     * pubSub.Unsubscribe("testEvent");
     */
    public unsubscribe(name: string) {
        if (name in this.subscriberArray) {
            const currentSubscriber: PubSubModel[] = this.subscriberArray[name];
            for (let _i = 0; _i < currentSubscriber.length; _i++) {
                currentSubscriber.splice(_i, 1);
            }
            this.subscriberArray[name] = currentSubscriber;
        }
    }

    /**
     * Publish a specific event and the corresponding data to all subscribers, which have the same event/action. Thus calling all callback functions.
     *
     * @param {string} name The name of the event, which shall be published.
     * @param {(Object | null)} [value] The data, which shall be transmitted.
     * @memberof PubSub
     */
    public publish(name: string, value?: Object | null) {
        if (name in this.subscriberArray) {
            const currentSubscriber: PubSubModel[] = this.subscriberArray[name];
            currentSubscriber.forEach((element) => {
                if (typeof (element.callback) === 'function') {
                    try {
                        if (!Utils.Instance.isPromise(value)) {
                            if (Utils.isDefined(value) && typeof value === 'object') {
                                // Create deep copy
                                this.hasFunctions(value);
                                value = this.cloneValue(value);
                            }
                            element.callback(Promise.resolve(value));
                        } else {
                            const wrappedPromise = new Promise((resolve, reject) => {
                                if (Utils.Instance.isPromise(value)) {
                                    value.then((data) => {
                                        if (Utils.isDefined(data) && typeof data === 'object') {
                                            // Create deep copy
                                            this.hasFunctions(data);
                                            data = this.cloneValue(data);
                                        }
                                        resolve(data);
                                    }).catch((error) => {
                                        reject(error);
                                    });
                                }
                            });
                            element.callback(wrappedPromise);
                        }
                    } catch (exception) {
                        this.logger.error(this.className, 'publish', 'Error during publish', exception);
                    }
                }
            });
        }
    }

    /**
     * Resets the subscriber array.
     * 
     * 
     * @memberof PubSub
     */
    public reset(): void {
        this.subscriberArray = {};
    }

    /**
     * Checks if the given object contains a function and emits a console debug message.
     * 
     * @private
     * @param {Object} value Value to check for functions.
     * @returns {boolean} Whether the value contains a function.
     * 
     * @memberof PubSub
     */
    private hasFunctions(value: Object): boolean {
        for (const i in value) {
            // @ts-ignore - TODO: Check index signature
            if (typeof value[i] === 'function') {
                this.logger.debug(this.className, 'hasFunctions', `Property '${i}' is a function. Only plain data objects are allowed.`, value);
                return true;
            }
        }
        return false;
    }

    /**
     * Returns a clone of the given value.
     * Will clone using `clone()` function if available.
     * Will use `Utils.deepClone` otherwise.
     * 
     * @private
     * @param {Object} value 
     * @returns {Object} 
     * 
     * @memberof PubSub
     */
    private cloneValue(value: Object): Object {
        if (Utils.isClonable(value)) { // Clone using clone method if possible
            return value.clone();
        } else { // Use Utils.deepClone as fallback (no methods or properties will be copied)
            return Utils.deepClone(value);
        }
    }
}
