import { Logger } from '../logging';
import { IWebBridgeHandler, IWebBridgePubSub } from './interfaces';
import { WebBridgeDataTypes, WebBridgeEventTypes, WebBridgePubSubModel } from './models';
import { WebBridgeCommandConstructor } from './webBridgeCommandConstructor';

/**
 * The WebBridgeHandler will communicate with the window.onMessage and the application.
 * 
 * @export
 * @class WebBridgeHandler
 */
export class WebBridgeHandler implements  IWebBridgeHandler {
    private subscriberArray: IWebBridgePubSub;
    private logger: Logger;
    private className: string = 'WebBridgeHandler';
    private currentWindow: Window;

    /**
     * Creates an instance of WebBridgeHandler.
     * @param {Logger} logger The logger.
     * @param {Window} window The current window.
     * @memberof WebBridgeHandler
     */
    public constructor(logger: Logger, window: Window) {
        this.subscriberArray = {};
        this.logger = logger;
        this.currentWindow = window;
        // See https://stackoverflow.com/questions/41160221/react-native-webview-postmessage-does-not-work
        // For iOS
        this.currentWindow.addEventListener('message',  this.handleMessageEvent.bind(this));
        // For Android
        this.currentWindow.document.addEventListener('message',  this.handleMessageEvent.bind(this));
    }

    /**
     * Subscribe to a specific event.
     * 
     * @param {string} guid The currently used guid of the subscribing component.
     * @param {WebBridgeEventTypes} eventType The eventype.
     * @param {(data?: Object) => void} callback The callback method.
     * @memberof WebBridgeHandler
     */
    public subscribe(guid: string, eventType: WebBridgeEventTypes, callback: (data?: Object) => void) {
        if (!eventType || !callback) {
            throw new Error('Can not subscribe with undefined parameters.');
        }

        if (!this.subscriberArray[eventType]) {
            this.subscriberArray[eventType] = [];
        }
        this.subscriberArray[eventType].push(new WebBridgePubSubModel(guid,callback));
    }

    /**
     * Publish a command to the window.
     * 
     * @param {WebBridgeEventTypes} eventType The eventype.
     * @param {WebBridgeDataTypes} dataType The datatype.
     * @param {string} value The value to transmit.
     * @memberof WebBridgeHandler
     */
    public publish(eventType: WebBridgeEventTypes, value: string) {
        // TODO: Change if more datatypes are available
        const dataType = WebBridgeDataTypes.string;
        const command = WebBridgeCommandConstructor.getCommandString(eventType, dataType, value);
        // TODO: select origin first instead of * before progressing !!security!!
        this.currentWindow.postMessage(command, '*');
    }


    /**
     * Handles incomming message event.
     * Extracts payload and invokes publishing to subscribers.
     * 
     * @private
     * @param {MessageEvent} nativeEvent The events parameter.
     * @memberof WebBridgeHandler
     */
    private handleMessageEvent(nativeEvent: MessageEvent): void {
        // TODO: check origin first before progressing !!security!!
        // ParseCommands
        if (typeof nativeEvent.data === 'string') {
            const response = WebBridgeCommandConstructor.getReponseObject(nativeEvent.data);
            if (response) {
                this.distributeRepsonse(response.eventType, response.value);
            }
        }
    }

    /**
     * Distribute the reponse of the window to all subscribers.
     * 
     * @private
     * @param {WebBridgeEventTypes} eventType The eventtype.
     * @param {*} value The value.
     * @memberof WebBridgeHandler
     */
    private distributeRepsonse(eventType: WebBridgeEventTypes, value: any) {
        if (eventType in this.subscriberArray) {
            const currentSubscriber: WebBridgePubSubModel[] = this.subscriberArray[eventType];
            currentSubscriber.forEach((element) => {
                if (typeof (element.callback) === 'function') {
                    try {
                        element.callback(value);
                    } catch (exception) {
                        this.logger.error(this.className, 'distributeRepsonse', 'Error during distributeRepsonse', exception);
                    }
                }
            });
        }
    }
}