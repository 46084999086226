import { UploadFlags } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { WindreamIdentity } from '../../common/windreamIdentity';

/**
 * Represents a webSocket preflight file upload.
 *
 * @export
 * @class FileUploadContainer
 */
export class FileUploadContainer {

    /**
     * The identity.
     *
     * @type {WindreamIdentity}
     * @memberof FileUploadContainer
     */
    public identity: WindreamIdentity;

    /**
     * The file size in bytes.
     *
     * @type {number}
     * @memberof FileUploadContainer
     */
    public size: number;

    /**
     * The flags.
     *
     * @type {UploadFlags}
     * @memberof FileUploadContainer
     */
    public flags: UploadFlags;


    /**
     * Creates an instance of FileUploadContainer.
     *
     * @param {WindreamIdentity} identity The identity of the file that should be uploaded.
     * @param {number} size The size of the file that should be uploaded.
     * @param {UploadFlags} flags The upload flags.
     * @memberof FileUploadContainer
     */
    public constructor(identity: WindreamIdentity, size: number, flags: UploadFlags) {
        this.identity = identity;
        this.size = size;
        this.flags = flags;
    }

}