import { ILanguageProvider, ITemplateExtension } from '../../dynamicWorkspace';
import { TabPanelPage } from '../../ui/models/tabPanelPage';
import { ILicenseSeviceHelper } from '../interfaces';
import { LicenseDetails } from './licenseDetails';
import { WindreamLicense } from './windreamLicense';

/**
 * This class represents a license related tab panel page.
 *
 * @export
 * @abstract
 * @class LicenseTabPanelPage
 */
export abstract class LicenseTabPanelPage extends TabPanelPage {

    /**
     * The license details.
     *
     * @protected
     * @type {LicenseDetails}
     * @memberof LicenseTabPanelPage
     */
    protected licenseDetails: LicenseDetails;

    private licenseSeviceHelper: ILicenseSeviceHelper;


    /**
     * Creates an instance of LicenseTabPanelPage.
     *
     * @param {ITemplateExtension} templateExtension The template extension.
     * @param {string} currentLanguage The current language.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {ILicenseSeviceHelper} licenseServiceHelper The license service helper.
     * @memberof LicenseTabPanelPage
     */
    public constructor(templateExtension: ITemplateExtension, currentLanguage: string, languageProvider: ILanguageProvider, licenseServiceHelper: ILicenseSeviceHelper) {
        super(templateExtension, currentLanguage, languageProvider);

        this.licenseSeviceHelper = licenseServiceHelper;
        this.licenseDetails = new LicenseDetails([], new WindreamLicense('', '', ''), '', '');
    }


    /**
     * Initializes the tab panel page.
     *
     * @returns {Promise<void>}
     * @memberof LicenseTabPanelPage
     */
    public async initialize(): Promise<void> {
        await super.initialize();

        this.licenseDetails = await this.licenseSeviceHelper.getLicenseDetails();
        return Promise.resolve();
    }

}