/**
 * Model that represents a use case.
 * 
 * @export
 * @class UseCase
 */
export class UseCase {
    /**
     * Name of the use case.
     * 
     * @type {string}
     * @memberof UseCase
     */
    public name: string;
    /**
     * ID of the use case.
     * 
     * @type {string}
     * @memberof UseCase
     */
    public id: string;

    /**
     * Creates an instance of UseCase.
     * @param {string} name 
     * @param {string} id 
     * @memberof UseCase
     */
    public constructor(name: string, id: string) {
        this.name = name;
        this.id = id;
    }
}