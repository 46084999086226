/**
 * Model to represent a user.
 * 
 * @export
 * @class UserIdentity
 */
export class UserIdentity {
    /**
     * Name of the user.
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    public name: string;
    /**
     * Domain of the user.
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    public domain?: string;
    /**
     * ID of the user.
     * 
     * @type {number}
     * @memberof UserIdentity
     */
    public id: number;

    /**
     * Fullname of the user.
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    public fullName?: string;

    /**
     * Email of the user.
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    public emailAddress?: string;

    /**
     * Creates an instance of UserIdentity.
     *
     * @param {number} id
     * @param {string} name
     * @param {string} [domain]
     * @param {string} [fullName]
     * @memberof UserIdentity
     */
    public constructor(id: number, name: string, domain?: string, fullName?: string, emailAddress?: string) {
        this.id = id;
        this.name = name;
        this.domain = domain;
        this.fullName = fullName || name;
        this.emailAddress = emailAddress;
    }

    /**
     * Splits the given combination of domain and user name  and returns both values seperately.
     * 
     * @param {string} input Combination of domain and user name to split.
     * @returns {{domain?: string, name: string}} 
     * 
     * @memberof Utils
     */
    public static extractDomainAndUserName(input: string): { domain?: string, name: string } {
        let domain: string | undefined;
        let name: string;

        let searchString: string;

        // Look for \-syntax
        searchString = '\\';
        let stop = input.indexOf(searchString);
        if (stop > -1) {
            // Found
            domain = input.substr(0, stop);
            name = input.substr(stop + searchString.length, input.length - stop - searchString.length);
            return {
                domain,
                name
            };
        }

        // Look for @-syntax
        searchString = '@';
        stop = input.indexOf(searchString);
        if (stop > -1) {
            // Found
            domain = input.substr(stop + searchString.length);
            name = input.substr(0, stop);
            return {
                domain,
                name
            };
        }

        // No domain found
        return {
            domain: undefined,
            name: input
        };
    }

    /**
     * Joins the given user name and domain and returns a single string.
     * 
     * @returns {string} Joined user name and domain.
     * 
     * @memberof Utils
     */
    public toString(): string {
        if (!this.domain) {
            return this.name ? this.name : '';
        }
        return `${this.domain}\\${this.name}`;
    }
}