import { IDatePicker, IDatePickerOptions } from '.';

/**
 * Provides a wrapper for a date picker.
 *
 * @export
 * @class DatePicker
 * @implements {IDatePicker}
 */
export class DatePicker implements IDatePicker {

    /**
     * A value was selected send value as callback.
     *
     * @memberof DatePicker
     */
    public onValueChanged?: (data: Date | null, event: any) => void;

    /**
     * Enter has been pressed
     *
     * @memberof DatePicker
     */
    public onEnterPressed?: (data: Date | null) => void;
    /**
     * Callback to execute on focus.
     *
     * @memberof DatePicker
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof DatePicker
     */
    public onBlur?: () => void;

    private targetElement: HTMLElement;
    private jQueryStatic: JQueryStatic | undefined;
    private dateBoxOptions: DevExpress.ui.dxDateBoxOptions;
    private instance?: DevExpress.ui.dxDateBox;

    /**
     * Creates an instance of DatePicker.
     * @param {HTMLElement} targetElement The target element.
     * @param {JQueryStatic} jQueryStatic JQuery.
     * @memberof DatePicker
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.jQueryStatic = jQueryStatic;
        this.targetElement = targetElement;
        this.dateBoxOptions = {
            applyValueMode: 'instantly',
            dropDownButtonTemplate: () => {
                return jQueryStatic('<span>', {
                    class: 'wd-icon calendar-days'
                });
            },
            onClosed: () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            },
            onEnterKey: (event: any) => {
                const value = this.getValue();
                if (!event) {
                    throw new Error('dxDateBox.onEnterKey: The event parameter was null or undefined.');
                }
                if (this.onEnterPressed) {
                    this.onEnterPressed(value);
                }
            },
            onFocusIn: () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            },
            onFocusOut: () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            },
            onOpened: () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            },
            onValueChanged: (event: any) => {
                if (!event) {
                    throw new Error('dxDateBox.onValueChanged: The event parameter was null or undefined.');
                } else {
                    const selectedItem = event.value;
                    if (this.onValueChanged) {
                        this.onValueChanged(selectedItem, event);
                    }
                }
            },
            opened: false,
            pickerType: 'calendar',
            showClearButton: true,
            showDropDownButton: true,
            useMaskBehavior: true,
            width: 'inherit'
        };
    }

    /**
     * Bootstrap the date box.
     *
     * @memberof DatePicker
     */
    public bootstrap(): void {
        this.instance = this.getJQueryElement().dxDateBox(this.dateBoxOptions).dxDateBox('instance');
    }

    /**
     * Set options to the date box.
     *
     * @param {IDatePickerOptions} options The options.
     * @memberof DatePicker
     */
    public setOptions(options: IDatePickerOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }
        const dateBoxOptions: DevExpress.ui.dxDateBoxOptions = {};
        if (options.name) {
            dateBoxOptions.name = options.name;
        }
        if (options.hasOwnProperty('opened')) {
            dateBoxOptions.opened = options.opened;
        }
        if (options.hasOwnProperty('placeholder')) {
            dateBoxOptions.placeholder = options.placeholder;
        }
        if (options.hasOwnProperty('type')) {
            dateBoxOptions.type = options.type;
        }
        if (options.hasOwnProperty('disabled')) {
            dateBoxOptions.disabled = options.isDisabled;
        }
        if (options.hasOwnProperty('showClearButton')) {
            dateBoxOptions.showClearButton = options.showClearButton;
        }
        if (options.hasOwnProperty('isValid')) {
            dateBoxOptions.isValid = options.isValid;
        }
        if (options.hasOwnProperty('hint')) {
            dateBoxOptions.hint = options.hint;
        }
        if (options.hasOwnProperty('min')) {
            dateBoxOptions.min = options.min;
        }
        if (options.hasOwnProperty('max')) {
            dateBoxOptions.max = options.max;
        }
        if (options.hasOwnProperty('acceptCustomValue')) {
            dateBoxOptions.acceptCustomValue = options.acceptCustomValue;
        }
        if (options.hasOwnProperty('openOnFieldClick')) {
            dateBoxOptions.openOnFieldClick = options.openOnFieldClick;
        }
        if (options.hasOwnProperty('tabIndex')) {
            dateBoxOptions.tabIndex = options.tabIndex;
        }
        // Update options
        Object.assign(this.dateBoxOptions, dateBoxOptions);

        // Set the options.
        this.getJQueryElement().dxDateBox(this.dateBoxOptions);
    }

    /**
     * Sets the value for the date box.
     *
     * @param {(Date | undefined)} value The value.
     * @memberof DatePicker
     */
    public setValue(value: Date | null): void {
        const acceptedDates = value ? value : undefined;
        if (this.instance) {
            this.instance.option('value', acceptedDates);
        }
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof DatePicker
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Gets the validity state.
     *
     * @memberof DatePicker
     */
    public getValidity(): boolean | undefined {
        return this.instance?.option('isValid');
    }

    /**
     * Sets the disabled state of the component.
     * Sets component internal to read only state for usability.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof DatePicker
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('readOnly', isDisabled);
        }
    }

    /**
     * Gets the targetElement as JQueryElement.
     *
     * @private
     * @returns {JQuery<HTMLElement>} targetElement as JQueryElement.
     * @memberof DatePicker
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.jQueryStatic) {
            throw new Error('jQuery was not loaded.');
        }
        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }
        return this.jQueryStatic(this.targetElement);
    }

    /**
     * Gets the componen'ts current set value
     *
     * @private
     * @returns {Date}
     * @memberof DatePicker
     */
    private getValue(): Date | null {
        if (!this.instance) {
            throw new Error('No instance found');
        } else {
            const currentValue = this.instance.option('value');
            if (currentValue) {
                if (typeof (currentValue) === 'string' || typeof (currentValue) === 'number') {
                    return new Date(currentValue);
                }
                return currentValue;
            } else {
                return null;
            }
        }
    }
}
