import { HttpResourcePointer as IHttpResourcePointer } from './../../../typings/services';
import { ResourcePointer } from './resourcePointer';

/**
 * An instance of this class can point to a specific  HTTP-resource.
 * 
 * @export
 * @class HttpResourcePointer
 * @extends {ResourcePointer}
 */
export class HttpResourcePointer extends ResourcePointer implements IHttpResourcePointer {

    /**
     * The action which will be executed.
     * 
     * @type {string}
     * @memberof HttpResourcePointer
     */
    public action: string;

    /**
     * Map of all http headers.
     * 
     * @type {Map<string, string>}
     * @memberof HttpResourcePointer
     */
    public httpHeaders: Map<string, string>;

    /**
     * Creates an instance of HttpResourcePointer.
     * @param {string} action 
     * @param {string} uri 
     * @param {Object} [parameter] 
     * @param {Map<string, string>} [httpHeaders] 
     * 
     * @memberof HttpResourcePointer
     */
    public constructor(action: string, uri: string, parameter?: Object, httpHeaders?: Map<string, string>) {
        super(uri, parameter);

        this.scheme = 'http';

        this.action = action;
        this.httpHeaders = httpHeaders || new Map<string, string>();

        this.ptrHash = this.calculateHash();
    }


    /**
     * Calculates the hash.
     *
     * @returns {*}  {string} The hash.
     * @memberof ResourcePointer
     */
    public calculateHash(): string {
        let tempUri = '';
        if (this.uri) {
            tempUri = this.uri;
        }

        if (this.parameter && typeof (this.parameter) !== 'string') {
            this.parameter = JSON.stringify(this.parameter);
        }

        let tempAction = '';
        if (this.action) {
            tempAction = this.action;
        }

        if (this.language) {
            return this.encryptionHelper.sha256(tempUri + this.parameter + tempAction + this.language);
        } else {
            return this.encryptionHelper.sha256(tempUri + this.parameter + tempAction);
        }
    }


    /**
     * Determines whether the both instances are equal.
     * 
     * @static
     * @param {HttpResourcePointer} resourcePointer1 
     * @param {HttpResourcePointer} resourcePointer2 
     * @returns {boolean} 
     * @memberof HttpResourcePointer
     */
    public static equals(resourcePointer1: HttpResourcePointer, resourcePointer2: HttpResourcePointer): boolean {
        if (resourcePointer1 === resourcePointer2) {
            return true;
        }

        if (!resourcePointer1 || !resourcePointer2) {
            return false;
        }

        return resourcePointer1.uri === resourcePointer2.uri &&
            resourcePointer1.scheme === resourcePointer2.scheme &&
            resourcePointer1.parameter === resourcePointer2.parameter;
    }

}