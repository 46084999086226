import { HistoryResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { HistoryEntry } from './historyEntry';

/**
 * Container for entries of a windream identity's history.
 *
 * @export
 * @class HistoryContainer
 */
export class HistoryContainer {
    /**
     * Whether new entries can be added.
     *
     * @type {boolean}
     * @memberof HistoryContainer
     */
    public canAddHistory: boolean;
    /**
     * History entries.
     *
     * @type {HistoryEntry[]}
     * @memberof HistoryContainer
     */
    public entries: HistoryEntry[];

    /**
     * Creates an instance of HistoryContainer.
     *
     * @param {boolean} canAddHistory
     * @memberof HistoryContainer
     */
    public constructor(canAddHistory: boolean) {
        this.canAddHistory = canAddHistory;
        this.entries = new Array<HistoryEntry>();
    }

    /**
     * Creates an instance based on the DTO.
     *
     * @static
     * @param {HistoryResponseContainerDTO} dto DTO to create from.
     * @returns {HistoryContainer}
     * @memberof HistoryContainer
     */
    public static fromDto(dto: HistoryResponseContainerDTO): HistoryContainer {
        const result = new HistoryContainer(dto.CanAddNewEntries);
        result.entries = dto.History.map((item) => HistoryEntry.fromDto(item));
        return result;
    }
}