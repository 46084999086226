import { StyleDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { Style } from './models';

/**
 * This class provides a factory, which will generate style classes from style DTO objects.
 * 
 * @export
 * @class StyleFactory
 */
export class StyleFactory {

    /**
     * Converts the given style DTO into an internal style model.
     * @access public
     * @param {StyleDTO} styleDTO The style DTO.
     * @returns {Style}
     */
    public static convertFromDTO(styleDTO: StyleDTO): Style {
        const result = new Style();

        result.buttonColors = styleDTO.ButtonColors;
        result.colors = styleDTO.Colors;

        // TODO: Remove once WebService typing is correct
        if (!result.buttonColors) {
            // @ts-ignore - Ignored because typing is wrong
            result.buttonColors = styleDTO.buttonColors;
        }
        if (!result.colors) {
            // @ts-ignore - Ignored because typing is wrong
            result.colors = styleDTO.colors;
        }

        return result;
    }

}