import { IClonable } from '../../common';
import { IdentityRightsModel } from '.';

/**
 * Model to reflect rights for a document in windream.
 * 
 * @export
 * @class DocumentRightsModel
 */
export class DocumentRightsModel implements IClonable<DocumentRightsModel> {
    /**
     * Rights of the document.
     * 
     * @type {string}
     * @memberof DocumentRightsModel
     */
    public documentRights: IdentityRightsModel[];

    /**
     * Creates an instance of DocumentRightsModel.
     * @memberof DocumentRightsModel
     */
    public constructor() {
        this.documentRights = new Array<IdentityRightsModel>();
    }

    /**
     * Creates a clone of this instance.
     * 
     * @returns {DocumentRightsModel} The clone.
     * @memberof DocumentRightsModel
     */
    public clone(): DocumentRightsModel {
        const clone = new DocumentRightsModel();
        clone.documentRights = this.documentRights.map((right) => right.clone());
        return clone;
    }
}