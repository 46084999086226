import { ITemplateExtension } from '../extensions';
import { IAppMenuHandler, IAppMenuHandlerFactory } from './interfaces';
import { AppMenuHandler } from '.';

/**
 * Factory for creating IAppMenuHandler instances.
 * 
 * @class AppMenuHandlerFactory
 * @implements {IAppMenuHandlerFactory}
 */
export class AppMenuHandlerFactory implements IAppMenuHandlerFactory {
    private templateExtension: ITemplateExtension;

    /**
     * Creates an instance of AppMenuHandlerFactory.
     * @param {ITemplateExtension} templateExtension 
     * @memberof AppMenuHandlerFactory
     */
    public constructor(templateExtension: ITemplateExtension) {
        this.templateExtension = templateExtension;
    }

    /**
     * Creates an instance of IAppMenuHandler.
     * 
     * @param {HTMLElement} element Element to pass to the IAppMenuHandler instance.
     * @returns {IAppMenuHandler} 
     * @memberof AppMenuHandlerFactory
     */
    public create(element: HTMLElement): IAppMenuHandler {
        return new AppMenuHandler(element, this.templateExtension);
    }
}