import { DocumentResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { DocumentDetails } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to perform a GetVersions call on the windream webservice.
 * 
 * @export
 * @class GetVersions
 * @extends {ServiceAction}
 */
export class GetVersions extends ServiceAction {

  /**
   * Creates an instance of GetVersions.
   * @param {IRequestExecutor} requestExecutor 
   * @param {GlobalConfig} globalConfig 
   * @param {Logger} logger 
   * @memberof GetVersions
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'getVersions';
  }

  /**
   * Get all versions for a specified document.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {WindreamRequestOptions} windreamRequestOptions The request options.
   * @returns {Promise<DocumentDetails[]>} A promise, which will resolve with the server response.
   * @memberof GetVersions
   */
  public async do(windreamRequestOptions: WindreamRequestOptions): Promise<DocumentDetails[]> {
    return new Promise<DocumentDetails[]>((resolve, reject) => {
      const url = `${this.globalConfig.windreamWebServiceURL}/documents/GetVersions`;
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', url, {
        Item: IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity)
      }), windreamRequestOptions.requestOptions).then((response: IServiceResponse<DocumentResponseContainerDTO[]>) => {
        if (response.data && response.data.length >= 0 && !response.data.find((version) => version.HasErrors)) {
          resolve(response.data.map((item) => DocumentDetails.fromDto(item.Item)));
        } else {
          this.logger.error('GetVersions', 'do', 'Failed to get versions', response);
          reject(new Error('Failed to get versions'));
        }
      }).catch((err: Error) => {
        this.logger.error('GetVersions', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}