/**
 * Request options for file system saving.
 *
 * @export
 * @class SaveToFileSystemRequestOptions
 */
export class SaveToFileSystemRequestOptions {

    /**
     * The buffer to save.
     *
     * @type {ArrayBuffer}
     * @memberof SaveToFileSystemRequestOptions
     */
    public buffer: ArrayBuffer;

    /**
     * The file name.
     *
     * @type {string}
     * @memberof SaveToFileSystemRequestOptions
     */
    public fileName: string;

    /**
     * Creates an instance of SaveToFileSystemRequestOptions.
     * 
     * @param {ArrayBuffer} buffer The buffer to save.
     * @param {string} fileName The file name.
     * @memberof SaveToFileSystemRequestOptions
     */
    public constructor(buffer: ArrayBuffer, fileName: string) {
        if (!buffer) {
            throw new Error('Argument \'buffer\' is not valid');
        }
        if (!fileName) {
            throw new Error('Argument \'fileName\' is not valid');
        }
        this.buffer = buffer;
        this.fileName = fileName;
    }
}