import { WindreamFilePickerPopupOptions as BasePopupOptions } from '../../../../typings/popup';

/**
 * File picker popup options.
 *
 * @export
 * @class WindreamFilePickerPopupOptions
 * @implements {BasePopupOptions}
 */
export class WindreamFilePickerPopupOptions implements BasePopupOptions {

    /**
     * The start up path.
     *
     * @type {string}
     * @memberof WindreamFilePickerPopupOptions
     */
    public startUpPath?: string;

    /**
     * Whether mutliselect is allowed or not.
     *
     * @type {boolean}
     * @memberof WindreamFilePickerPopupOptions
     */
    public allowMultiSelect?: boolean;

    /**
     * Whether to allow upload within the popup.
     *
     * @type {boolean}
     * @memberof WindreamFilePickerPopupOptions
     */
    public allowUpload?: boolean;
}