/**
 * Provides token functionality.
 *
 * @export
 * @abstract
 * @class TokenProvider
 */
export abstract class TokenProvider {
    /**
     * Whether the current token has been marked as invalid.
     *
     * @protected
     * @type {boolean}
     * @memberof TokenProvider
     */
    protected isMarkedAsInvalid: boolean = true;

    /**
     * Gets the current token, if the token is expired it will prompt for credentials to re-authenticate.
     *
     * @abstract
     * @returns {Promise<string>} A promise, which will resolve with the token.
     * @memberof TokenProvider
     */
    public abstract getToken(): Promise<string>;

    /**
     * Will only check if the current token is still valid, use getToken in order to get a new token.
     *
     * @abstract
     * @returns {boolean} Whether the token is expired.
     * @memberof TokenProvider
     */
    public abstract isTokenExpired(): boolean;

    /**
     * Marks the current token as invalid.
     *
     * @abstract
     * @memberof TokenProvider
     */
    public abstract markTokenAsInvalid(): Promise<void>;
}