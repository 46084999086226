import * as Handlebars from 'handlebars';
import { ILanguageProvider } from '../language';
import { IStaticPageHelper } from './interfaces';

/**
 * Renders a HTML-Template into a HTMLElement.
 * 
 * @export
 * @class StaticPageHelper
 */
export class StaticPageHelper implements IStaticPageHelper {
    private languageProvider: ILanguageProvider;
    private rootNode: HTMLElement;

    /**
     * Creates an instance of StaticPageHelper.
     * @param {ILanguageProvider} languageProvider The languageProvider
     * 
     * @memberof StaticPageHelper
     */
    public constructor(languageProvider: ILanguageProvider, rootNode: HTMLElement) {
        this.languageProvider = languageProvider;
        this.rootNode = rootNode;
        Handlebars.registerHelper('getTranslation', (key: string) => {
            return this.languageProvider.get(key);
        });
    }

    /**
     * Render the HTML template into the target.
     * 
     * @param {(HTMLElement)} target The target.
     * @param {string} template The HTML template.
     * @param {*} [dataModel] The data model which is used by the template.
     * 
     * @memberof StaticPageHelper
     */
    public render(target: HTMLElement, template: string, dataModel?: any): void {
        template = this.wrapTemplate(template);

        const handelbarsTemplateDelegate = Handlebars.compile(template);
        target.innerHTML = handelbarsTemplateDelegate(dataModel);
    }


    /**
     * Render the HTML error page into the rootNode.
     * 
     * @param {string} template
     * @param {*} [dataModel]
     * 
     * @memberof StaticPageHelper
     */
    public renderError(template: string, dataModel?: any): void {
        template = this.wrapTemplate(template);
        const handelbarsTemplateDelegate = Handlebars.compile(template);
        this.rootNode.innerHTML = handelbarsTemplateDelegate(dataModel);
    }

    /**
     *  Wraps the HTML template into a styleable div.
     * 
     * @private
     * @param {string} template
     * @returns {string}
     * 
     * @memberof StaticPageHelper
     */
    private wrapTemplate(template: string): string {
        return `
        <div class="static-page">
            <div class="static-page-content">${template}</div>
        </div>`;
    }
}