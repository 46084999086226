import * as msal from '@azure/msal-browser';
import { AuthenticationModes, IAuthenticationManager, ICredentialManager, UserCredentials, UserDetails } from '../authentication';
import { RequestOptions } from '../dataProviders/requestOptions';
import { GlobalConfig, Logger, Utils } from '../dynamicWorkspace';
import { GetStorageToken } from '../services/dynamicWorkspace/getStorageToken';
import { Login } from '../services/index';
import { TokenRequestOptions } from '../services/models/tokenRequestOptions';
import { BaseLoginMask } from './baseLoginMask';


/**
 * The MSAL (Azure) based implementation of the login mask.
 *
 * @export
 * @class MSALLoginMask
 * @implements {BaseLoginMask}
 */
export class MSALLoginMask implements BaseLoginMask {

    private readonly logger: Logger;
    private readonly className = 'MSALLoginMask';
    private readonly authenticationManager: IAuthenticationManager;
    private readonly credentialManager: ICredentialManager;
    private readonly globalConfig: GlobalConfig;
    private readonly msalApp: msal.PublicClientApplication;
    private readonly getStorageTokenAction: GetStorageToken;
    private homeTenantId?: string;
    private currentAccount: msal.AccountInfo | undefined;
    private loginAction: Login;
    private loginCallbackCollection: ((userDetails: UserDetails, encryptionSecret?: string) => void)[];


    /**
     * Creates an instance of MSALLoginMask.
     *
     * @param {Login} loginAction The login service action.
     * @param {Logger} logger The logger.
     * @param {IAuthenticationManager} authenticationManager The authentication manager.
     * @param {ICredentialManager} credentialManager The credential manager.
     * @param {GlobalConfig} globalConfig The global configuration.
     * @param {GetStorageToken} getStorageTokenAction The service action to get a storage token.
     * @memberof MSALLoginMask
     */
    public constructor(loginAction: Login,
        logger: Logger,
        authenticationManager: IAuthenticationManager,
        credentialManager: ICredentialManager,
        globalConfig: GlobalConfig,
        getStorageTokenAction: GetStorageToken) {

        this.loginCallbackCollection = new Array<(userDetails: UserDetails, encryptionString?: string) => void>();
        this.loginAction = loginAction;
        this.logger = logger;
        this.authenticationManager = authenticationManager;
        this.credentialManager = credentialManager;
        this.globalConfig = globalConfig;
        this.getStorageTokenAction = getStorageTokenAction;

        if (!Utils.Instance.isDefined(this.globalConfig.msal)) {
            this.logger.fatal(this.className, 'constructor', 'Missing "msal" setting inside the global configuration, but MSAL is active.');
            throw new Error('Missing "msal" setting inside the global configuration, but MSAL is active.');
        } else if (!Utils.Instance.isDefined(this.globalConfig.msal.clientId)) {
            this.logger.fatal(this.className, 'constructor', 'Missing "msal.clientId" setting inside the global configuration, but MSAL is active.');
            throw new Error('Missing "msal.clientId" setting inside the global configuration, but MSAL is active.');
        } else if (!Utils.Instance.isDefined(this.globalConfig.msal.tenantId)) {
            this.logger.fatal(this.className, 'constructor', 'Missing "msal.tenantId" setting inside the global configuration, but MSAL is active.');
            throw new Error('Missing "msal.tenantId" setting inside the global configuration, but MSAL is active.');
        } else if (!Utils.Instance.isDefined(this.globalConfig.msal.authority)) {
            this.logger.fatal(this.className, 'constructor', 'Invalid "authority", but MSAL is active.');
            throw new Error('Invalid "authority", but MSAL is active.');
        }

        this.homeTenantId = this.globalConfig.msal.tenantId;

        this.msalApp = new msal.PublicClientApplication({
            auth: {
                clientId: this.globalConfig.msal.clientId,
                authority: this.globalConfig.msal.authority,
                redirectUri: window.origin + '/auth-callback'
            }
        });
    }


    /**
     * Triggers the MSAL login process.
     *
     * @param {HTMLElement} _targetElement
     * @return {Promise<boolean>}
     * @memberof MSALLoginMask
     */
    public async renderPage(_targetElement: HTMLElement): Promise<boolean> {
        return this.login();
    }

    /**
     * Handles the session timeout.
     *
     * @param {HTMLElement} _targetElement
     * @return {Promise<void>}
     * @memberof MSALLoginMask
     */
    public renderSessionTimeoutPopup(_targetElement: HTMLElement): Promise<void> {
        // Do nothing
        return Promise.resolve();
    }

    /**
     * Registers a login callback handler.
     *
     * @param {((userCredentials: UserDetails, encryptionSecret?: string | undefined) => void)} callback The login callback handler.
     * @memberof MSALLoginMask
     */
    public registerLoginCallback(callback: (userCredentials: UserDetails, encryptionSecret?: string | undefined) => void): void {
        this.loginCallbackCollection.push(callback);
    }

    /**
     * Logs out the user.
     *
     * @return {Promise<boolean>}
     * @memberof MSALLoginMask
     */
    public logout(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {

            if (!Utils.Instance.isDefined(this.globalConfig.msal) || !Utils.Instance.isDefined(this.globalConfig.msal.authority) ||
                !Utils.Instance.isDefined(this.globalConfig.msal.clientId)) {
                this.logger.fatal(this.className, 'logout', 'No MSAL authority declared but MSAL is active; abort logout.');
                reject(false);
                return;
            }

            // You can select which account application should sign out
            let logoutRequest: msal.EndSessionRequest | undefined = undefined;
            if (this.currentAccount && this.currentAccount.homeAccountId) {
                logoutRequest = {
                    account: this.msalApp.getAccountByHomeId(this.currentAccount.homeAccountId)
                };
            }

            this.msalApp.logoutRedirect(logoutRequest);

            resolve(true);
        });
    }

    /**
     * Destroys the login mask.
     *
     * @memberof MSALLoginMask
     */
    public destroy(): void {
        // DO nothing
    }

    /**
     * Logs in the user.
     *
     * @private
     * @return {Promise<boolean>}
     * @memberof MSALLoginMask
     */
    private login(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (!Utils.Instance.isDefined(this.globalConfig.msal) || !Utils.Instance.isDefined(this.globalConfig.msal.authority) ||
                !Utils.Instance.isDefined(this.globalConfig.msal.clientId)) {
                this.logger.fatal(this.className, 'login', 'No MSAL authority declared but MSAL is active; abort login.');
                reject(false);
                return;
            }

            // Reset current account to undefined. Will be set again later.
            this.currentAccount = undefined;

            const scopes = Utils.Instance.isArray(this.globalConfig.msal.scopes) ? this.globalConfig.msal.scopes : ['User.ReadWrite'];
            const loginRequest = {
                scopes: scopes
            };

            this.msalApp.handleRedirectPromise().then((response) => {

                if (response !== null && response.account) {
                    this.currentAccount = response.account;
                } else {

                    // Get all CURRENTLY SIGNED IN accounts of the user.
                    const currentAccounts = this.msalApp.getAllAccounts();

                    if (currentAccounts.length === 0) {
                        // No accounts signed-in, attempt to sign a user in
                        this.msalApp.loginRedirect(loginRequest);
                        return;
                    } else if (currentAccounts.length === 1) {
                        // A single signed-in account was found, therefore use it.
                        this.currentAccount = currentAccounts[0];
                    } else if (currentAccounts.length > 1) {
                        // Multiple signed-in accounts were found.
                        // Try to find the "home-tenant" account and use it.
                        this.logger.info(this.className, 'login', 'Found multiple signed-in accounts, therefore try to use the home-tenant account.');
                        this.currentAccount = currentAccounts.find((acount) => acount.tenantId === this.homeTenantId);
                    }
                }

                if (!this.currentAccount) {
                    this.logger.error(this.className, 'login', 'Can not find a valid signed-in acount');
                    resolve(false);
                    return;
                }

                // Display signed-in user content, call API, etc.
                this.logger.info(this.className, 'login', 'MSAL Account:', this.currentAccount.homeAccountId);

                this.msalApp.setActiveAccount(this.currentAccount);

                this.msalApp.acquireTokenSilent(loginRequest).then((authResult: msal.AuthenticationResult) => {
                    // Login msal token and resolve callback to application.ts
                    return this.loginWithToken(authResult);

                }).catch((error) => {
                    // Getting a token silently failed, therefore try to get it interactively.

                    this.logger.info(this.className, 'login', 'Can not acquire a token silently, therefore try to get it interactively.', error);

                    return this.login();
                });
            }).catch((error) => {
                this.logger.error(this.className, 'login', 'Can not init msal', error);
                reject(error);
            });
        });
    }

    /**
     * Logs in the msal token and get user details of account. 
     * 
     * @private
     * @return {Promise<boolean>}
     * @memberof MSALLoginMask
     */
    private loginWithToken(authResult: msal.AuthenticationResult): Promise<boolean> {
        return new Promise<boolean>((resolve) => {

            this.authenticationManager.setCurrentAuthenticationMode(AuthenticationModes.MSAL);

            // Create and set credentials of currently logged in user
            const userCredentials = new UserCredentials();
            userCredentials.username = authResult && authResult.account ? authResult.account.name : '';
            userCredentials.authenticationToken = authResult.idToken;

            this.credentialManager.setCredentials(userCredentials);

            // Create request options for login-service - calls the GetMemberDetails endpoint with msal token
            const loginRequestOptions = new TokenRequestOptions();
            const requestOptions = new RequestOptions();
            requestOptions.authenticationMode = AuthenticationModes.Anonymous;
            loginRequestOptions.token = authResult.idToken;
            loginRequestOptions.requestOptions = requestOptions;

            // Get user details for current msal token
            this.loginAction.do(loginRequestOptions).then(async (userDetails) => {
                if (userDetails.isValidUser) {
                    userDetails.token = authResult.idToken;

                    // Get storage token.
                    const storageToken = await this.getStorageTokenAction.do(loginRequestOptions);

                    // eslint-disable-next-line promise/no-callback-in-promise
                    this.loginCallbackCollection.forEach((callback) => callback(userDetails, storageToken));

                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    }
}