/**
 * Constant GUIDs to use for internal PubSub events.
 * 
 * @export
 * @class PubSubConstants
 */
export class PubSubConstants {
    /**
     * GUID to use for the style configuration.
     * 
     * @static
     * @type {string}
     * @memberof PubSubConstants
     */
    public static STYLES_ID: string = 'WINDREAM_CONFIGURATION_STYLES';
}