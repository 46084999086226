import { WindreamIdentity } from '../../common';
import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents request options for windream.
 *
 * @export
 * @class WindreamRequestOptions
 * @extends {BaseRequestOptions}
 */
export class WindreamRequestOptions extends BaseRequestOptions {

    /**
     * The windream identity.
     *
     * @type {WindreamIdentity}
     * @memberof WindreamRequestOptions
     */
    public identity: WindreamIdentity;

    /**
     * Creates an instance of WindreamRequestOptions.
     * @param {WindreamIdentity} identity
     * @memberof WindreamRequestOptions
     */
    public constructor(identity: WindreamIdentity) {
        super();
        if (!identity) {
            throw new Error('Argument \'identity\' is not valid');
        }
        this.identity = identity;
    }
}