import { HttpResourcePointer, IRequestExecutor, RequestOptions } from '../../../src/dataProviders';
import { ILanguageProvider, ITemplateExtension, Utils } from '../../dynamicWorkspace';
import { TabPanelPage } from '../../ui/models/tabPanelPage';

export class ImprintTabPanelPage extends TabPanelPage {

    private showdown = require('showdown');
    private markdownConverter: any;
    private requestExecutor: IRequestExecutor;


    /**
     * Creates an instance of ImprintTabPanelPage.
     *
     * @param {ITemplateExtension} templateExtension The template extension.
     * @param {string} currentLanguage The current language.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @memberof ImprintTabPanelPage
     */
    public constructor(templateExtension: ITemplateExtension, currentLanguage: string, languageProvider: ILanguageProvider, requestExecutor: IRequestExecutor) {
        super(templateExtension, currentLanguage, languageProvider);

        this.requestExecutor = requestExecutor;

        this.markdownConverter = new this.showdown.Converter();
        this.markdownConverter.setOption('simpleLineBreaks', true);
    }


    /**
     * Gets the title.
     *
     * @abstract 
     * @returns {string} The title.
     * @memberof ImprintTabPanelPage
     */
    public getTitle(): string {
        return this.languageProvider.get('framework.legal.imprint.title');
    }

    /**
     * Renders the content.
     *
     * @abstract
     * @param {HTMLElement} element The target element.
     * @memberof ImprintTabPanelPage
     */
    public async renderContent(element: HTMLElement): Promise<void> {

        let imprintRawText = '';

        const imprintUrl = './legal/imprint/' + this.currentLanguage + '/imprint.md';
        const resourcePointer = new HttpResourcePointer('GET', imprintUrl);

        const requestOptions = new RequestOptions();
        requestOptions.preventFetchFromCache = true;

        const imprintServiceResponse = await this.requestExecutor.executeRequest(resourcePointer, requestOptions);
        if (imprintServiceResponse && imprintServiceResponse.data) {
            imprintRawText = imprintServiceResponse.data;
        }
        const tabPanel = document.createElement('div');
        const contentWrapper = document.createElement('div');
        contentWrapper.classList.add('wd-imprint-description-area');
        tabPanel.classList.add('wd-license-windream-tab', 'wd-license-popup-text');
        contentWrapper.innerHTML = this.markdownConverter.makeHtml(Utils.Instance.escapeStringValue(imprintRawText));
        tabPanel.appendChild(contentWrapper);
        element.appendChild(tabPanel);

        return Promise.resolve();
    }

}