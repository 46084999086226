import { ToolbarManager as BaseToolbarManager } from '../../../typings/core';
import { ToolbarDatasetHandler } from './toolbarDatasetHandler';

/**
 * Manager class which will be provided to each component.
 *
 * @export
 * @class ToolbarManager
 * @implements {BaseToolbarManager}
 */
export class ToolbarManager implements BaseToolbarManager {

    /**
     * The dataset handler.
     *
     * @type {ToolbarDatasetHandler}
     * @memberof ToolbarManager
     */
    public toolbarDatasetHandler: ToolbarDatasetHandler;

    /**
     * Creates an instance of ToolbarManager.
     * 
     * @param {ToolbarDatasetHandler} toolbarDatasetHandler The toolbar dataset handler.
     * @memberof ToolbarManager
     */
    public constructor(toolbarDatasetHandler: ToolbarDatasetHandler) {
        this.toolbarDatasetHandler = toolbarDatasetHandler;
    }
}