import * as Handlebars from 'handlebars';
import { Utils } from '../common';
import { UndefinedReferenceError } from '../errors';
import { ILanguageProvider } from '../language';
import { IMasterTreeViewData, IMasterTreeViewOptions } from './interfaces';
import { TreeItem, TreeLibNode } from './models';
import { TreeLibHandler } from '.';

/**
 * Class to represent a simple Tree View.
 *
 * @export
 * @abstract
 * @class MasterTreeView
 * @template T Datatype of the data each node represents.
 */
export abstract class MasterTreeView<T> {
  protected treeLibHandler?: TreeLibHandler<T>;
  protected languageProvider: ILanguageProvider;


  private targetElement: HTMLElement;
  private options: IMasterTreeViewOptions;

  private defaultOptions: IMasterTreeViewOptions = {
    canShare: false,
    isAjax: false,
    showFiles: true
  };

  private treeLibOptions = {
    callback: {
      onClick: (event: MouseEvent, _treeId: any, treeNode: any) => {
        this.internalClick(event, treeNode);
      },
      onCollapse: (event: MouseEvent, _treeId: any, treeNode: any) => {
        this.internalCollapse(event, treeNode);
      },
      onDblClick: (event: MouseEvent, _treeId: any, treeNode: any) => {
        // Filter out normal double clicks without TreeData
        if (treeNode) {
          this.internalDblClick(event, treeNode);
        }
      },
      onExpand: (event: MouseEvent, _treeId: any, treeNode: any) => {
        this.internalExpand(event, treeNode);
      }
    },
    data: {
      keep: {
        leaf: true,
        parent: true
      },
      simpleData: {
        enable: true,
        idKey: 'id',
        pIdKey: 'parentId',
        rootPId: 0
      },
      view: {
        expandSpeed: 'fast'
      }
    }
  };

  /**
   * Creates an instance of MasterTreeView.
   * @param {HTMLElement} targetElement The target element which will be used to render the tree.
   * @param {ILanguageProvider} languageProvider Framework language provider to use.
   * @param {IMasterTreeViewOptions} [options] The tree options.
   *
   * @memberof MasterTreeView
   */
  public constructor(
    targetElement: HTMLElement,
    languageProvider: ILanguageProvider,
    options?: IMasterTreeViewOptions
  ) {
    this.targetElement = targetElement;
    this.languageProvider = languageProvider;
    this.options = { ...this.defaultOptions, ...options };
  }

  /**
   * Destroy this instance.
   *
   * @memberof MasterTreeView
   */
  public destroy(): void {
    if (this.treeLibHandler) {
      this.treeLibHandler.destroy();
    }
    this.targetElement.innerHTML = '';
  }

  /**
   * Renders the tree into the target element.
   *
   * @param {IMasterTreeViewData<T>} data Data to render the tree with.
   *
   * @memberof MasterTreeView
   */
  public render(data?: IMasterTreeViewData<T>): void {
    if (!this.treeLibHandler) {
      require('./vendor/zTree/css/zTreeStyle.css');
      require('./vendor/zTree/jquery.ztree.core.js');

      this.setupHtml(data && data.id ? data.id : Utils.Instance.getRandomString());
      const list = this.targetElement.querySelector('ul');
      // @ts-ignore - Ignore because of zTree usage
      if ($ && $.fn && $.fn.zTree && list) {
        // @ts-ignore - Ignore because of zTree usage
        this.treeLibHandler = new TreeLibHandler<T>($.fn.zTree, $);
        this.treeLibHandler.initTree(list, this.treeLibOptions);
      } else {
        throw new UndefinedReferenceError('Libraries or target element not found to set up tree.');
      }
    }

    if (data && data.data) {
      if (Utils.Instance.isArray(data.data)) {
        data.data.forEach((treeItem: TreeItem<T>) => this.updateTreeData(treeItem));
      } else {
        this.updateTreeData(data.data);
      }
    }

    if (this.options.canShare) {
      this.treeLibHandler.addDraggableAttribute(this.targetElement);
    }
  }

  /**
   * Expands and focusses the given node.
   *
   * @param {TreeItem<T>} node Node to expand and focus.
   *
   * @memberof MasterTreeView
   */
  public focusNode(node: TreeItem<T>): void {
    this.treeLibHandler?.focusNode(TreeLibNode.create(node));
  }

  /**
   * Returns the node with the given ID or null if no node is found.
   *
   * @param {number} id ID to search.
   * @returns {(TreeItem<T> | null)} Node with the given ID or null if nothing was found.
   *
   * @memberof MasterTreeView
   */
  public getNodeById(id: number): TreeItem<T> | null {
    return this.treeLibHandler?.getNodeById(id);
  }

  /**
   * Returns the node with the given ID or null if no node is found.
   *
   * @param {number} id ID to search.
   * @returns {(TreeItem<T> | null)} Node with the given ID or null if nothing was found.
   *
   * @memberof MasterTreeView
   */
  public getNodeByTId(id: string | number): TreeItem<T> | null {
    return this.treeLibHandler?.getNodeByTId(id);
  }

  /**
   * Adds the given nodes below the given parent node.
   *
   * @param {TreeItem<T> | null} parentNode Parent node to add to; if null nodes will be added to the root.
   * @param {TreeItem<T>[]} nodes Nodes to add.
   *
   * @memberof MasterTreeView
   */
  public addNodes(parentNode: TreeItem<T> | null, nodes: TreeItem<T>[]): void {
    this.treeLibHandler?.addNodes(parentNode ? TreeLibNode.create(parentNode) : null, nodes.map((child: TreeItem<T>) => TreeLibNode.create(child, parentNode ? parentNode.id : undefined)));
    if (this.options.canShare) {
      this.treeLibHandler?.addDraggableAttribute(this.targetElement);
    }
  }

  /**
   * Clears all children nodes beneath a given parent node.
   * 
   * @param {TreeLibNode<T>} node 
   * @memberof MasterTreeView
   */
  public clearNodes(parentNode: TreeItem<T>): void {
    this.treeLibHandler?.clearNodes(TreeLibNode.create(parentNode));
  }

  /**
   * Function to execute when a node is clicked.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was clicked.
   *
   * @memberof MasterTreeView
   */
  protected abstract click?(event: MouseEvent, treeItem: TreeItem<T>): void;

  /**
   * Function to execute when a node is collapsed.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was collapsed.
   *
   * @memberof MasterTreeView
   */
  protected abstract collapse?(event: MouseEvent, treeItem: TreeItem<T>): void;

  /**
   * Function to execute when a node is expanded.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was expanded.
   *
   * @memberof MasterTreeView
   */
  protected abstract expand?(event: MouseEvent, treeItem: TreeItem<T>): void;

  /**
   * Function to execute when a node is double click.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was double clicked.
   *
   * @memberof MasterTreeView
   */
  protected abstract dblClick?(event: MouseEvent, treeItem: TreeItem<T>): void;

  /**
   * Renders the tree by adding the items.
   *
   * @private
   * @param {TreeItem<T>} data Items to add.
   *
   * @memberof MasterTreeView
   */
  private updateTreeData(data: TreeItem<T>) {
    const parseTreeItem = (item: TreeItem<T>, _parentId?: number | null) => {
      let children = new Array<TreeLibNode<T>>();

      // If no childs are present, remove expand cross
      if (!this.options.isAjax && item.children && item.children.length === 0) {
        item.children = undefined;
        this.treeLibHandler?.disableExpandCross(TreeLibNode.create(item));
      }

      if (item.children) {
        // Convert children to TreeLibNodes
        children = item.children.map((child: TreeItem<T>) => TreeLibNode.create(child, item.id));
      }
      this.treeLibHandler?.addNodes(TreeLibNode.create(item), children);

      if (item.children) {
        item.children.forEach((child: TreeItem<T>) => parseTreeItem(child, item.id));
      }
      // Check if there are no children  if AJAX is false (since they will not be added on expand)
      // This can only be used for root nodes as the children are not rendered into the DOM directly
      // For children on level 1 and below there is a corresponding call in internalExpand()
      if (!this.options.isAjax && item.children && item.children.length === 0) {
        this.treeLibHandler?.disableExpandCross(TreeLibNode.create(item));
      }
    };
    this.treeLibHandler?.addRootNode(TreeLibNode.create(data));
    parseTreeItem(data, null);
  }

  /**
   * Sets up the HTML by rendering the template.
   *
   * @private
   * @param {string} id ID to use inside the HTML.
   *
   * @memberof MasterTreeView
   */
  private setupHtml(id: string): void {
    const template = require('./template/masterTreeViewTemplate.html');
    const handelbarsTemplateDelegate = Handlebars.compile(template);
    this.targetElement.innerHTML = handelbarsTemplateDelegate({
      id
    });
  }

  /**
   * Internally handles the expand event.
   * Will invoke public click function if defined.
   *
   * @private
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was clicked.
   *
   * @memberof MasterTreeView
   */
  private internalClick(event: MouseEvent, treeItem: TreeItem<T>): void {
    if (this.click) {
      this.click(event, treeItem);
    }
  }
  /**
   * Internally handles the double click event.
   * Will invoke public double click function if defined.
   *
   * @private
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was double clicked.
   *
   * @memberof MasterTreeView
   */
  private internalDblClick(event: MouseEvent, treeItem: TreeItem<T>): void {
    if (this.dblClick) {
      this.dblClick(event, treeItem);
    }
  }

  /**
   * Internally handles the collapse event.
   * Will invoke public collapse function if defined.
   *
   * @private
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was expanded.
   *
   * @memberof MasterTreeView
   */
  private internalCollapse(event: MouseEvent, treeItem: TreeItem<T>): void {
    if (this.collapse) {
      this.collapse(event, treeItem);
    }
  }

  /**
   * Internally handles the expand event.
   * Will invoke public expand function if defined.
   *
   * @private
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was expanded.
   *
   * @memberof MasterTreeView
   */
  private internalExpand(event: MouseEvent, treeItem: TreeItem<T>): void {
    // Check if there are no children for children if AJAX is false (since they will not be added on expand)
    // For root nodes this is done in updateTreeData()
    if (!this.options.isAjax && treeItem.children) {
      treeItem.children.forEach((child: TreeItem<T>) => {
        if (child.children && child.children.length === 0) {
          this.treeLibHandler?.disableExpandCross(TreeLibNode.create(child));
        } else if (child.children && child.children.length > 0) {
          this.treeLibHandler?.enableExpandCross(TreeLibNode.create(child));
        }
      });
    }

    if (this.expand) {
      this.expand(event, treeItem);
    }
  }
}