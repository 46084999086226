/**
 * 	Base class for exceptions that occur or are targeted at environments outside the runtime.
 * 
 * @export
 * @class ExternalError
 * @extends {Error}
 */
export class ExternalError extends Error {

    /**
     * Creates an instance of ExternalError.
     * @param {string} [message] 
     * 
     * @memberof ExternalError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'ExternalError';
    }
}