import { SEARCH_RELATIONS, SearchCondition } from '.';

/**
 * This class defines a group of search conditions and their kind of relation.
 *
 * @export
 * @class SearchGroup
 */
export class SearchGroup {
  /**
   * Conditions of this search group.
   * 
   * @type {((SearchCondition | SearchGroup)[])}
   * @memberof SearchGroup
   */
  public conditions: (SearchCondition | SearchGroup)[];
  /**
   * Relation between the conditions within this group.
   * 
   * @type {SEARCH_RELATIONS}
   * @memberof SearchGroup
   */
  public relation?: SEARCH_RELATIONS;

  /**
   * Creates an instance of SearchGroup.
   * 
   * @memberof SearchGroup
   */
  public constructor() {
    this.conditions = new Array<SearchCondition | SearchGroup>();
  }
}