import { Logger } from '../logging';
import { IIndexedDBWrapper } from './interfaces';

/**
 * The IndexedDBWrapper wraps the indexedDB from the browser.
 * 
 * @export
 * @class IndexedDBWrapper
 * @implements {IIndexedDBWrapper}
 */
export class IndexedDBWrapper implements IIndexedDBWrapper {


    /**
     * Whether the indexedDB is available or not.
     * 
     * @readonly
     * @type {boolean}
     * @memberof IndexedDBWrapper
     */
    public get indexedDBAvailable(): boolean {
        return this._indexedDBAvailable;
    }
    private _indexedDBAvailable: boolean = false;
    private logger: Logger;
    private dbs?: IDBFactory;
    private currentDatabase?: IDBDatabase;
    private currentObjectStoreName?: string;
    private databaseAvailable: boolean = false;
    private className: string = 'IndexedDBWrapper';
    private databaseName: string;
    /**
     * Creates an instance of IndexedDBWrapper.
     * @param {Logger} logger 
     * 
     * @memberof IndexedDBWrapper
     */
    public constructor(logger: Logger, databaseName: string) {
        this.logger = logger;
        this.databaseName = databaseName;
        if ('indexedDB' in window && window.indexedDB !== undefined) {
            this._indexedDBAvailable = true;
        } else {
            this.logger.info(this.className, 'constructor', 'IndexedDB not available');
        }
    }

    /**
     * Intializes the IndexedDB.
     * 
     * @param {string} objectStoreName  Name of the object store to initialize.
     * @param {string} primaryKeyIdentifier Name of the primary identifier to use.
     * @returns {Promise<boolean>}  Promise to resolve with true on success.
     * @async
     * 
     * @memberof IndexedDBWrapper
     */
    public async initializeDB(objectStoreName: string, primaryKeyIdentifier: string): Promise<boolean> {
        const maxRecursiveDepth = 10;
        return new Promise<boolean>((resolve, reject) => {
            if (this.indexedDBAvailable) {
                const recursiveCallback = (versionnumber: number): void => {
                    this.addNewObjectStore(objectStoreName, primaryKeyIdentifier, (success: boolean) => {
                        if (success) {
                            resolve(true);
                        } else {
                            reject(new Error('Unable to create object store with given name.'));
                        }
                    }, recursiveCallback, ++versionnumber);
                };
                recursiveCallback(maxRecursiveDepth * -1);
            } else {
                reject(new Error('No IndexedDB available.'));
            }
        });
    }

    /**
     * Adds the data into the database.
     * 
     * @param {*} data The data.
     * @param {(success: boolean) => void} callback Whether it was successful or not.
     * 
     * @memberof IndexedDBWrapper
     */
    public add(data: any, callback: (success: boolean) => void): void {
        if (this.indexedDBAvailable && this.databaseAvailable) {
            if (!this.currentObjectStoreName) {
                this.logger.error(this.className, 'add','No current object store name set');
                callback(false);
                return;
            }
            if (!this.currentDatabase) {
                this.logger.error(this.className, 'add','No current data base set');
                callback(false);
                return;
            }
            const transaction = this.currentDatabase.transaction(this.currentObjectStoreName, 'readwrite');
            const store: IDBObjectStore = transaction.objectStore(this.currentObjectStoreName);
            store.add(data);
            transaction.oncomplete = () => {
                callback(true);
            };
            transaction.onerror = (e: any) => {
                this.logger.error(this.className, 'add','The IndexedDB can not add an entry', e);
                callback(false);
            };
        } else {
            callback(false);
        }
    }

    /**
     * Gets the data from the database.
     * 
     * @param {string} identifier The indentifier.
     * @param {(data: any) => void} callback The callback for the data.
     * 
     * @memberof IndexedDBWrapper
     */
    public get(identifier: string, callback: (data: any) => void) {
        if (this.indexedDBAvailable && this.databaseAvailable) {
            if (!this.currentObjectStoreName) {
                this.logger.error(this.className, 'add','No current object store name set');
                callback(false);
                return;
            }
            if (!this.currentDatabase) {
                this.logger.error(this.className, 'add','No current data base set');
                callback(false);
                return;
            }
            const transaction: IDBTransaction = this.currentDatabase.transaction(this.currentObjectStoreName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(this.currentObjectStoreName);
            const request: IDBRequest = store.get(identifier);
            transaction.oncomplete = () => {
                callback(request.result);
            };
            transaction.onerror = (e: any) => {
                this.logger.error(this.className, 'get','The IndexedDB can not get the data.', e);
                callback(null);
            };
        } else {
            callback(null);
        }
    }

    /**
     * Puts the data into the database.
     * 
     * @param {Object} data The data.
     * @param {(success: boolean) => void} callback Whether it was successful or not.
     * 
     * @memberof IndexedDBWrapper
     */
    public put(data: Object, callback: (success: boolean) => void) {
        if (this.indexedDBAvailable && this.databaseAvailable) {
            if (!this.currentObjectStoreName) {
                this.logger.error(this.className, 'add','No current object store name set');
                callback(false);
                return;
            }
            if (!this.currentDatabase) {
                this.logger.error(this.className, 'add','No current data base set');
                callback(false);
                return;
            }
            const transaction: IDBTransaction = this.currentDatabase.transaction(this.currentObjectStoreName, 'readwrite');
            const store: IDBObjectStore = transaction.objectStore(this.currentObjectStoreName);
            store.put(data);
            transaction.oncomplete = () => {
                callback(true);
            };
            transaction.onerror = (e: any) => {
                this.logger.error(this.className, 'put','The IndexedDB can not put the data into the database.', e);
                callback(false);
            };
        } else {
            callback(false);
        }
    }

    /**
     * Deletes a dataset within the database.
     * 
     * @param {string} identifier The identifier.
     * @param {(success: boolean) => void} callback Whether it was successful or not.
     * 
     * @memberof IndexedDBWrapper
     */
    public delete(identifier: string, callback: (success: boolean) => void) {
        if (this.indexedDBAvailable && this.databaseAvailable) {
            if (!this.currentObjectStoreName) {
                this.logger.error(this.className, 'add','No current object store name set');
                callback(false);
                return;
            }
            if (!this.currentDatabase) {
                this.logger.error(this.className, 'add','No current data base set');
                callback(false);
                return;
            }
            const transaction: IDBTransaction = this.currentDatabase.transaction(this.currentObjectStoreName, 'readwrite');
            const store: IDBObjectStore = transaction.objectStore(this.currentObjectStoreName);
            store.delete(identifier);
            transaction.oncomplete = () => {
                callback(true);
            };
            transaction.onerror = (e: any) => {
                this.logger.error(this.className, 'delete','IndexedDB can not delete entry.', e);
                callback(false);
            };
        } else {
            callback(false);
        }
    }

    /**
     * Clear the current cache.
     * 
     * @returns {Promise<boolean>} Whether it was successful or not.
     * @async
     * 
     * @memberof IndexedDBWrapper
     */
    public async clear(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (this.indexedDBAvailable) {
                if (!this.currentObjectStoreName) {
                    this.logger.error(this.className, 'add','No current object store name set');
                    reject(new Error('No current object store name set.'));
                    return;
                }
                if (!this.currentDatabase) {
                    this.logger.error(this.className, 'add','No current data base set');
                    reject(new Error('No current data base set.'));
                    return;
                }
                const transaction: IDBTransaction = this.currentDatabase.transaction(this.currentObjectStoreName, 'readwrite');
                transaction.oncomplete = () => {
                    resolve(true);
                };
                transaction.onerror = (e: any) => {
                    this.logger.error(this.className, 'clear','The IndexedDB can not be cleared.', e);
                    reject(e);
                };
                const store: IDBObjectStore = transaction.objectStore(this.currentObjectStoreName);
                store.clear();
            } else {
                reject(new Error('The indexedDB is not available.'));
            }
        });
    }
    /**
     * Close the database.
     * 
     * 
     * @memberof IndexedDBWrapper
     */
    public close() {
        if (this.indexedDBAvailable && this.databaseAvailable && this.currentDatabase) {
            this.databaseAvailable = false;
            this.currentDatabase.close();
        }
    }

    /**
     * Adds a new objectstore for the database.
     * 
     * @private
     * @param {string} objectStoreName 
     * @param {string} primaryKeyIdentifier 
     * @param {(isAvailable: boolean) => void} successCallback 
     * @param {(versionNumber: number) => void} failedCallback 
     * @param {number} versionNumber 
     * 
     * @memberof IndexedDBWrapper
     */
    private addNewObjectStore(objectStoreName: string, primaryKeyIdentifier: string, successCallback: (isAvailable: boolean) => void,
        failedCallback: (versionNumber: number) => void, versionNumber: number) {

        if (this.indexedDBAvailable) {

            this.currentObjectStoreName = objectStoreName;
            this.dbs = window.indexedDB;
            let request: IDBOpenDBRequest;
            try {
                if (versionNumber <= 0) {
                    request = this.dbs.open(this.databaseName);
                } else {
                    request = this.dbs.open(this.databaseName, versionNumber);
                }
                request.onupgradeneeded = (e: any) => {
                    const db = e.target.result;
                    db.createObjectStore(this.currentObjectStoreName, { keyPath: primaryKeyIdentifier });
                };
                request.onsuccess = () => {
                    this.currentDatabase = request.result;

                    if (this.currentDatabase.objectStoreNames.contains(objectStoreName)) {
                        this.databaseAvailable = true;
                        successCallback(true);
                    } else {
                        this.databaseAvailable = false;
                        this.currentDatabase.close();
                        failedCallback(this.currentDatabase.version);
                    }
                };
                request.onerror = (e: any) => {
                    successCallback(false);
                    this.logger.error(this.className, 'addNewObjectStore','Can not add the objectstore', e);
                };
                request.onblocked = (e: any) => {
                    successCallback(false);
                    this.logger.error(this.className, 'addNewObjectStore','Can not access the IndexedDB', e);
                };
            } catch (err) {
                // Privacy settings deny access etc.
                this.logger.error(this.className, 'addNewObjectStore','Can not access the IndexedDB because of some privacy settings', err);
                successCallback(false);
            }
        } else {
            this.logger.warn(this.className, 'addNewObjectStore', 'window.IndexedDB is not defined');
            successCallback(false);
        }
    }
}