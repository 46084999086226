import { ITrigger } from '../config/index';
import { PubSubConfig } from '../pubSub/index';
import { MigrationComponentContainer } from './index';

/**
 * This class will help with migration regarding components.
 *
 * @export
 * @class MigrationHelper
 */
export class MigrationHelper {

    /**
     * Migrates the triggers for new pubsub names.
     *
     * @static
     * @param {ITrigger} triggers The triggers to migrate.
     * @param {MigrationComponentContainer[]} migrationComponentContainer The components to migrate.
     * @returns {ITrigger}
     * @memberof MigrationHelper
     */
    public static migrateTriggers(triggers: ITrigger, migrationComponentContainer: MigrationComponentContainer[]): ITrigger {
        for (const triggerNames in triggers) {
            if (typeof triggers[triggerNames] !== 'undefined') {
                for (const trigger of triggers[triggerNames]) {
                    migrationComponentContainer.forEach((connection) => {
                        if (connection.migrationObject) {
                            connection.migrationObject.publisherEvents.forEach((pubsubEvent) => {
                                trigger.outId = trigger.outId.replace(connection.component.guid + pubsubEvent.previousName, connection.component.guid + pubsubEvent.newName);
                            });
                            connection.migrationObject.subscriberEvents.forEach((pubsubEvent) => {
                                trigger.inId = trigger.inId.replace(connection.component.guid + pubsubEvent.previousName, connection.component.guid + pubsubEvent.newName);
                            });
                        }
                    });
                }
            }
        }
        return triggers;
    }

    /**
     * Migrate a pubsub config into a new one with migrated event names.
     *
     * @static
     * @param {PubSubConfig[]} pubSubConfig The pubsub config.
     * @param {MigrationComponentContainer[]} migrationComponentContainer The components to migrate.
     * @returns {PubSubConfig[]} Migrated pubsub config.
     * @memberof MigrationHelper
     */
    public static migratePubSubConfig(pubSubConfig: PubSubConfig[], migrationComponentContainer: MigrationComponentContainer[]): PubSubConfig[] {
        migrationComponentContainer.forEach((container) => {
            // Rename all events
            if (container.migrationObject) {
                for (const publisherEvent of container.migrationObject.publisherEvents) {
                    if (publisherEvent.newName !== publisherEvent.previousName) {
                        for (const publisher of pubSubConfig) {
                            for (const connection of publisher.out) {
                                if (connection.componentGuid === container.migrationObject.componentInstanceConfig.guid && connection.parameter === publisherEvent.previousName) {
                                    connection.parameter = publisherEvent.newName;
                                }
                            }
                        }
                    }
                }
                for (const subscriberEvent of container.migrationObject.subscriberEvents) {
                    if (subscriberEvent.newName !== subscriberEvent.previousName) {
                        for (const subscriber of pubSubConfig) {
                            for (const connection of subscriber.in) {
                                if (connection.componentGuid === container.migrationObject.componentInstanceConfig.guid && connection.parameter === subscriberEvent.previousName) {
                                    connection.parameter = subscriberEvent.newName;
                                }
                            }
                        }
                    }
                }
            }
        });
        if (migrationComponentContainer.length > 0) {
            return MigrationHelper.makePubSubConfigDistinctive(pubSubConfig);
        } else {
            return pubSubConfig;
        }
    }

    /**
     * Deletes a self connected pubsub connection, since framework doesn't support them anymore.
     *
     * @static
     * @param {PubSubConfig[]} pubSubConfig The pubsub config to modify.
     * @returns {PubSubConfig[]} Returns the pubsub config without self connected pubsub connections.
     * @memberof MigrationHelper
     */
    public static deleteSelfConnectedPubSubs(pubSubConfig: PubSubConfig[]): PubSubConfig[] {
        const newPubSubConfig = new Array<PubSubConfig>();
        pubSubConfig.forEach((entry) => {
            const newOutConnections = entry.out.filter((outConnection) => {
                return entry.in.find((connection) => connection.componentGuid !== outConnection.componentGuid);
            });
            const newInConnections = entry.in.filter((inConnection) => {
                return entry.out.find((connection) => connection.componentGuid !== inConnection.componentGuid);
            });
            if (newOutConnections.length > 0 && newInConnections.length > 0) {
                entry.out = newOutConnections;
                entry.in = newInConnections;
                newPubSubConfig.push(entry);
            }
        });
        return newPubSubConfig;
    }

    /**
     * Filter any duplicate event name.
     *
     * @private
     * @static
     * @param {PubSubConfig[]} pubSubConfig The pubsub config to make distinctive.
     * @returns {PubSubConfig[]} The distinctive pubsub config.
     * @memberof MigrationHelper
     */
    private static makePubSubConfigDistinctive(pubSubConfig: PubSubConfig[]): PubSubConfig[] {
        const duplicatePublisherMap = new Map<string, PubSubConfig>();
        // Eliminate all duplicates
        pubSubConfig.forEach((config) => {
            for (const publisher of config.out) {
                for (const subscriber of config.in) {
                    duplicatePublisherMap.set(publisher.componentGuid + publisher.parameter + subscriber.componentGuid + subscriber.parameter, config);
                }
            }
        });
        const newPubSubConfig = new Array<PubSubConfig>();
        duplicatePublisherMap.forEach((config) => {
            newPubSubConfig.push(config);
        });
        return newPubSubConfig;
    }
}