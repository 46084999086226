import { LifecycleResponseDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { LifecyclePeriodDetails } from './lifecyclePeriodDetails';

/**
 * Model to represent the lifecycle details of a document.
 * 
 * @export
 * @class LifecycleDetails
 */
export class LifecycleDetails {
    /**
     * The details about the archived perdiod.
     * 
     * @type {Date}
     * @memberof LifecycleDetails
     */
    public archived: LifecyclePeriodDetails;

    /**
     * The details about the editable perdiod.
     * 
     * @type {Date}
     * @memberof LifecycleDetails
     */
    public editable: LifecyclePeriodDetails;

    /**
     * Creates an instance of LifecycleDetails.
     * @param {LifecycleResponseContainerDTO} lifecycleDetails The lifecycle details.
     * @memberof LifecycleDetails
     */
    public constructor(lifecycleDetails: LifecycleResponseDTO) {
        this.archived = new LifecyclePeriodDetails(lifecycleDetails.Archived);
        this.editable = new LifecyclePeriodDetails(lifecycleDetails.Editable);
    }
}