import { DWCore } from '@windream/dw-core/dwCore';
import { Utils } from '../common';
import { ComponentConfig, ComponentInstanceConfig, IConfigTranslation, Position, Style } from '../config';
import { ILanguageManager } from '../language';
import { Logger } from '../logging';
import { IPubSubHandler } from '../pubSub';
import { ILayoutManager } from './interfaces';
import { GridConfig, LayoutConfiguration } from './models';

/**
 * Layout Manager to handle layouts that contain components within the DOM.
 * 
 * @export
 * @abstract
 * @class LayoutManager
 * @implements {ILayoutManager}
 */
export abstract class LayoutManager implements ILayoutManager {
  /**
   * Callback to execute when the configuration has changed.
   *
   * @memberof LayoutManager
   */
  public set onLayoutChange(callback: (newLayout: LayoutConfiguration) => void) {
    this._onLayoutChange.push(callback);
  }

  protected GRID_CONFIG: Map<DWCore.Common.Devices, GridConfig>;
  protected logger: Logger;
  protected languageManager: ILanguageManager;
  protected rootNode: HTMLElement;
  protected pubSubHandler: IPubSubHandler;
  protected layoutNode: HTMLElement;
  protected containerNodes: HTMLElement[];
  protected isInitialized: boolean = false;
  protected isEditable: boolean = false;
  protected componentHeaderTemplate!: string;
  protected componentEditableHeaderTemplate!: string;
  protected componentErrorEditableHeaderTemplate!: string;

  private _onLayoutChange: ((newLayout: LayoutConfiguration) => void)[];

  /**
   * Creates an instance of LayoutManager.
   * @param {ILanguageManager} languageManager 
   * @param {HTMLElement} root 
   * @param {Logger} logger 
   * @param {IPubSubHandler} pubSubHandler 
   * @memberof LayoutManager
   */
  public constructor(languageManager: ILanguageManager, root: HTMLElement, logger: Logger, pubSubHandler: IPubSubHandler) {
    // Prepare constant Device-GridConfig-Mapping
    this.GRID_CONFIG = new Map<DWCore.Common.Devices, GridConfig>();
    this.logger = logger;
    this.languageManager = languageManager;

    this.pubSubHandler = pubSubHandler;
    this.initPubSubListeners();
    this.containerNodes = new Array<HTMLElement>();

    this._onLayoutChange = new Array<(newLayout: LayoutConfiguration) => void>();

    this.setComponentHeaderTemplate();

    if (root) {
      root.innerHTML = ''; // Remove everything from root
      this.rootNode = root;
    } else {
      throw new Error('No element with id "root" found.');
    }

    // Will be overwritten by prepareLayout implementations
    this.layoutNode = document.createElement('div');

    this.init();
  }


  /**
   * Prepares the layout by added a grid node to the root element.
   *
   * @param {DWCore.Common.Devices} device  Device to render for.
   *
   * @memberof LayoutManager
   */
  public abstract prepareLayout(device: DWCore.Common.Devices): void;

  /**
   * Renders a container for the given component.
   * Adds relevant attributes to the element so that the grid can be used.
   * Wraps the given element in a separate container.
   *
   * @param {ComponentInstanceConfig} componentInstanceConfig Configuration for the component instance.
   * @param {ComponentConfig} componentConfig The component config.
   * @param {HTMLElement} element Container to render the component in.
   * @param {HTMLDivElement} toolbarcontainer The toolbar container.
   * @param {(newComponentInstanceConfig: ComponentInstanceConfig) => void} [callback] Callback to be called after loading with updated configuration (i.e. new position).
   * @memberof LayoutManager
   */
  public abstract renderContainer(componentInstanceConfig: ComponentInstanceConfig,
    componentConfig: ComponentConfig, element: HTMLElement, toolbarcontainer: HTMLDivElement,
    callback?: (newComponentInstanceConfig: ComponentInstanceConfig) => void): void;

  /**
   * Initialize the layout by invoking Gridstack if Edit-Mode is active.
   * Will also disable the grid if invoked and Edit-Mode is not acive.
   */
  public abstract initializeLayout(): void;

  /**
   * Sets editable property.
   * 
   * @param {boolean} editable Whether the layout is editable.
   */
  public abstract setEditable(editable: boolean): void;


  /**
   * Adds a style based on whether the component has pending changes.
   *
   * @param {string} componentGuid GUID of the component to set style.
   * @param {boolean} hasPendingChanges Whether the style should be applied.
   * @memberof LayoutManager
   */
  public setPendingChangesFlag(componentGuid: string, hasPendingChanges: boolean): void {
    if (!componentGuid) {
      return;
    }

    const pendingChangesClass = 'wd-has-changes';
    const componentContainer = this.layoutNode.querySelector('div[data-guid="' + componentGuid + '"]');
    if (componentContainer) {
      if (hasPendingChanges) {
        componentContainer.classList.add(pendingChangesClass);
      } else {
        componentContainer.classList.remove(pendingChangesClass);
      }
    }
  }


  /**
   * This method is used to update the visual styles of a container.
   *
   * @param {string} componentGuid  GUID of the component to update.
   * @param {string} styleClassName Name of the new style.
   *
   * @memberof LayoutManager
   */
  public updateComponentStyle(componentGuid: string, styleClassName: string): void {

    if (!componentGuid || !styleClassName) {
      return;
    }

    const componentContainer = this.layoutNode.querySelector('div[data-guid="' + componentGuid + '"]');
    if (componentContainer) {
      const gridStackItemContent = componentContainer.querySelector('.grid-stack-item-content');
      if (gridStackItemContent && gridStackItemContent.className) {

        const desiredStyleType = this.getStyleType(styleClassName);

        // At first remove the old style
        const definedClasses = gridStackItemContent.className.split(' ');
        if (definedClasses && definedClasses.length > 0) {
          definedClasses.forEach((cssClass: string) => {

            // Check whether it is a wd-style class and also get the wd-style type.
            const styleType = this.getStyleType(cssClass);
            if (styleType && styleType !== '' && styleType === desiredStyleType) {
              // Remove the old matching style class
              if (gridStackItemContent) {
                gridStackItemContent.classList.remove(cssClass);
              }
            }
          });
        }

        // Then set the new style
        gridStackItemContent.classList.add(styleClassName);
      }
    }
  }

  /**
   * This method returns the style which matches with the given style type.
   *
   * @param {string} componentGuid  GUID of the component which should provide the style.
   * @param {string} styleType      The type of the style (wd-style-colors, etc.).
   * @returns {string}              The desired style.
   *
   * @memberof ILayoutManager
   */
  public getComponentStyle(componentGuid: string, styleType: string): string {
    if (!componentGuid || !styleType || !styleType.startsWith('wd-style-')) {
      return '';
    }

    let result = '';

    const element = this.layoutNode.querySelector('[data-guid="' + componentGuid + '"]');
    if (!element) {
      throw new Error(`No element for component with GUID ${componentGuid} found.`);
    } else {
      const gridStackItemContent = element.querySelector('.grid-stack-item-content');
      if (gridStackItemContent && gridStackItemContent.className) {
        // Search the matching style
        const definedClasses = gridStackItemContent.className.split(' ');
        if (definedClasses && definedClasses.length > 0) {
          definedClasses.forEach((cssClass: string) => {

            // Check whether it is a wd-style class and also get the wd-style type.
            if (cssClass.startsWith(styleType)) {
              result = cssClass;
            }
          });
        }
      }
    }

    return result;
  }

  /**
   * Returns only the style part of a full style class name.
   * Example: wd-style-colors-0 --> wd-style-colors
   *
   * @param {string} styleClassName
   * @returns {string}
   *
   * @memberof LayoutManager
   */
  public getStyleType(styleClassName: string): string {
    if (!styleClassName || !styleClassName.startsWith('wd-style-')) {
      return '';
    }

    return styleClassName.substring(0, styleClassName.lastIndexOf('-'));
  }


  /**
   * Checks if the LayoutManager contains the component with the given GUID.
   * 
   * @param {string} componentGuid GUID of the component.
   * @returns {boolean} Whether the LayoutManager contains the component.
   * @memberof LayoutManager
   */
  public hasComponent(componentGuid: string): boolean {
    const componentNode = this.rootNode.querySelector(`[data-guid="${componentGuid}"]`);
    return !!componentNode;
  }

  /**
   * Removes a component from the grid.
   * 
   * @param {string} componentGuid GUID of the component to remove.
   * 
   * @memberof LayoutManager
   */
  public abstract removeComponent(componentGuid: string): void;

  /**
   * Updates the component title in the component header.
   *
   * @param {string} componentGuid  Component GUID to update.
   * @param {string} newName        New name to display.
   * @param {string} newTitle       New title to display.
   *
   * @memberof LayoutManager
   */
  public updateComponentTitle(componentGuid: string, newName: string, newTitle: string, isVisible: boolean) {
    const element = this.layoutNode.querySelector('[data-guid="' + componentGuid + '"]');

    if (element) {
      if (isVisible) {
        element.setAttribute('data-title-visible', 'true');
      } else {
        element.setAttribute('data-title-visible', 'false');
      }

      const titleElement = element.querySelector('.header-default .component-title');
      if (titleElement) {
        titleElement.textContent = newTitle;
      }

      const nameElement = element.querySelector('.header-editable .component-title');
      if (nameElement) {
        nameElement.textContent = newName;
      }
    } else {
      throw new Error(`No element for component with GUID ${componentGuid} found.`);
    }
  }

  /**
   * Sets enabled property.
   * If the Layout is disabled, no new containers can be added.
   * 
   * @param {boolean} enabled Whether the layout is enabled.
   * @memberof LayoutManager
   */
  public setEnabled(enabled: boolean): void {
    if (enabled) {
      this.rootNode.classList.remove('layout-disabled');
    } else {
      this.rootNode.classList.add('layout-disabled');
    }
  }

  /**
   * Clears the DOM by emptying the root node.
   *
   *
   * @memberof LayoutManager
   */
  public clear(): void {
    this.rootNode.innerHTML = '';
  }

  /**
   * Returns the current positions of each component inside the layout.
   * 
   * @abstract
   * @returns {LayoutConfiguration} The current layout configuration.
   * @memberof LayoutManager
   */
  public abstract getComponentPositions(): LayoutConfiguration;

  /**
   * Destroy the LayoutManager and deletes everything in the root node.
   * 
   * @abstract
   * @memberof LayoutManager
   */
  public abstract destroy(): void;

  /**
   * Initialize the LayoutManager.
   * 
   * @protected
   * @abstract
   * @memberof LayoutManager
   */
  protected abstract init(): void;

  /**
   * Adds a new component via Drag&Drop.
   * 
   * @private
   * @param {string} componentId 
   * @param {Position} position 
   * @returns {void} 
   * @memberof LayoutManager
   */
  protected addComponentDragDrop(componentId: string, position: Position): void {
    if (!this.pubSubHandler) {
      return;
    }

    const newComponentConfig: ComponentInstanceConfig = {
      component: componentId,
      configuration: {},
      guid: Utils.Instance.getUUID(),
      isTitleVisible: false,
      name: {},
      position: position,
      style: Style.default(),
      title: {},
    };

    this.pubSubHandler.publish('WINDREAM_ADD_COMPONENT', 'AddComponentDragDrop', newComponentConfig);
  }


  /**
   * Initializes the required PubSub event listeners.
   * 
   * @private
   * @memberof LayoutManager
   */
  protected initPubSubListeners(): void {
    if (!this.pubSubHandler) {
      return;
    }

    // Handle the ComponentAdded event.
    this.pubSubHandler.subscribe('WINDREAM_ADD_COMPONENT', 'ComponentAdded', () => {
      this.updateLayout();
    });
  }

  /**
   * Sets the template for a component header.
   * 
   * @private
   * @returns {void} 
   * 
   * @memberof LayoutManager
   */
  protected setComponentHeaderTemplate(): void {
    if (!this.languageManager) {
      return;
    }
    const languageProvider = this.languageManager.getLanguageProvider('framework');
    this.componentEditableHeaderTemplate = `
          <div class="controls">
              <button class="remove-component-btn button" title="${languageProvider.get('framework.componentHeaderTemplate.remove')}">
              <span class="wd-icon delete" aria-hidden="true"></span>
              </button>
              <button class="edit-config-btn button" title="${languageProvider.get('framework.componentHeaderTemplate.configure')}">
              <span class="wd-icon cog" aria-hidden="true"></span>
              </button>
          </div>
      `;
    this.componentErrorEditableHeaderTemplate = `
      <div class="controls">
          <button class="remove-component-btn button" title="${languageProvider.get('framework.componentHeaderTemplate.remove')}">
          <span class="wd-icon delete" aria-hidden="true"></span>
          </button>
      </div>
  `;

    // Insert custom action controls in this template...
    this.componentHeaderTemplate = `
          <div class="controls">
          </div>
      `;
  }

  /**
   * Generates a component header and adds it as a child of the specified grid item.
   *
   * @protected
   * @param {HTMLDivElement} gridItem DOM grid item element to add the header to.
   * @param {boolean} isEditableHeader Create an edit-mode header if true, default header otherwise.
   * @param {boolean} isConfigurable Whether the component is configurable or not.
   * @memberof LayoutManager
   */
  protected addComponentHeader(gridItem: HTMLDivElement, isEditableHeader: boolean, isConfigurable: boolean) {
    const languageProvider = this.languageManager.getLanguageProvider('framework');
    const componentHeader = document.createElement('div');

    const componentTitle = document.createElement('h3');
    componentTitle.classList.add('component-title');
    componentHeader.appendChild(componentTitle);

    const pendingChangesIcon = document.createElement('span');
    pendingChangesIcon.classList.add('wd-has-changes-icon');
    pendingChangesIcon.title = languageProvider.get('framework.componentHeaderTemplate.hasPendingChanges');
    componentHeader.appendChild(pendingChangesIcon);

    if (isEditableHeader && !isConfigurable) {
      componentHeader.innerHTML += this.componentEditableHeaderTemplate;
      componentHeader.classList.add('component-header', 'header-editable');
    } else if (isConfigurable && isEditableHeader) {
      componentHeader.innerHTML += this.componentErrorEditableHeaderTemplate;
      componentHeader.classList.add('component-header', 'header-editable');
    } else {
      componentHeader.innerHTML += this.componentHeaderTemplate;
      componentHeader.classList.add('component-header', 'header-default');
    }

    gridItem.appendChild(componentHeader);
  }

  /**
   * Gets the translated string from the specified property or an empty string if either
   * the translation failed or the property is null.
   * 
   * @protected
   * @param property Translation property to get the translation of.
   * 
   * @memberof LayoutManager
   */
  protected getTranslatedOrEmpty(property?: IConfigTranslation<string>): string {
    const languageProvider = this.languageManager.getLanguageProvider('framework');
    let result = '';
    try {
      if (property) {
        result = languageProvider.getTranslationFromProperty(property);
      }
    } catch (error) {
      result = '';
    }

    return result;
  }

  /**
   * Executes after adding components via PubSub to update the layout.
   * 
   * @protected
   * @abstract
   * @memberof LayoutManager
   */
  protected abstract updateLayout(): void;


  /**
   * Executes all registered onLayoutChange callbacks with the given layout.
   *
   * @protected
   * @param {LayoutConfiguration} newLayout New layout to invoke callbacks with.
   * @memberof LayoutManager
   */
  protected executeOnLayoutChange(newLayout: LayoutConfiguration): void {
    this._onLayoutChange.forEach((callback) => {
      callback(newLayout);
    });
  }
}