import { DynamicWorkspace } from '../../../../../../typings';
import { ILanguageProvider, ViewConfigMetaData } from '../../../../dynamicWorkspace';
import { ViewSystemSettingsWebServiceRequests } from '../viewSystemSettingsWebServiceRequests';

/**
 * Represents the panel for the general view meta data.
 *
 * @export
 * @class ViewMetadataUiPanel
 */
export class ViewMetadataUiPanel {

    private jQuery: JQueryStatic;
    private languageProvider: ILanguageProvider;
    private publicApi: DynamicWorkspace;
    private viewSystemSettingsWebServiceRequests: ViewSystemSettingsWebServiceRequests;
    private readonly className = 'ViewMetadataUiPanel';

    /**
     * Will be called if view name has changed.
     *
     * @memberof ViewMetadataUiPanel
     */
    public onViewNameChanged?: (viewName: string) => void;

    /**
     * Will be called if view alias has changed.
     *
     * @memberof ViewMetadataUiPanel
     */
    public onViewAliasChanged?: (viewAlias: string) => void;

    /**
     * Will be called if view description has changed.
     *
     * @memberof ViewMetadataUiPanel
     */
    public onViewDescriptionChanged?: (viewDescription: string) => void;

    /**
     * Will be called if view template has changed.
     *
     * @memberof ViewMetadataUiPanel
     */
    public onViewTemplateChanged?: (selectedTemplate: ViewConfigMetaData | undefined) => void;

    /**
     * Will be called if view should be displayed in navigation has changed.
     *
     * @memberof ViewMetadataUiPanel
     */
    public onViewShowInNavigationChanged?: (showInNavigation: boolean) => void;

    /**
     * Creates an instance of ViewMetadataUiPanel.
     * 
     * @param {JQueryStatic} jQuery JQuery.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {DynamicWorkspace} publicApi The public api.
     * @param {ViewSystemSettingsWebServiceRequests} viewWebServiceRequestHelper Webservice request helper class.
     * @memberof ViewMetadataUiPanel
     */
    public constructor(jQuery: JQueryStatic, languageProvider: ILanguageProvider,
        publicApi: DynamicWorkspace, viewWebServiceRequestHelper: ViewSystemSettingsWebServiceRequests) {
        this.jQuery = jQuery;
        this.languageProvider = languageProvider;
        this.publicApi = publicApi;
        this.viewSystemSettingsWebServiceRequests = viewWebServiceRequestHelper;
    }

    /**
     * Renders the ui for view metadata.
     *
     * @param {HTMLElement} targetElement The target to render into.
     * @param {ViewConfigMetaData} [selectedViewConfigMetadata] The selected view config to edit.
     * @memberof ViewMetadataUiPanel
     */
    public render(targetElement: HTMLElement, selectedViewConfigMetadata?: ViewConfigMetaData): void {
        const viewNameHeader = this.jQuery(`<span>${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.viewName')}</span>`);
        viewNameHeader.appendTo(targetElement);
        const viewNameTextBoxTarget = this.jQuery('<div></div>');
        viewNameTextBoxTarget.appendTo(targetElement);
        const viewNameTextbox = this.publicApi.Ui.components.textBox(viewNameTextBoxTarget[0]);
        viewNameTextbox.bootstrap();
        viewNameTextbox.onValueChanged = (value) => {
            if (this.onViewNameChanged && typeof value === 'string') {
                this.onViewNameChanged(value);
            }
        };
        const viewAliasHeader = this.jQuery(`<span>${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.alias')}</span>`);
        viewAliasHeader.appendTo(targetElement);
        const viewAliasTextBoxTarget = this.jQuery('<div></div>');
        viewAliasTextBoxTarget.appendTo(targetElement);
        const viewAliasTextbox = this.publicApi.Ui.components.textBox(viewAliasTextBoxTarget[0]);
        viewAliasTextbox.bootstrap();
        viewAliasTextbox.onValueChanged = (value) => {
            if (this.onViewAliasChanged && typeof value === 'string') {
                this.onViewAliasChanged(value);
            }
        };
        const viewDescriptionHeader = this.jQuery(`<span>${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.description')}</span>`);;
        viewDescriptionHeader.appendTo(targetElement);
        const descriptionTextAreaTarget = this.jQuery('<div></div>');
        descriptionTextAreaTarget.appendTo(targetElement);
        const descriptionTextArea = this.publicApi.Ui.components.textArea(descriptionTextAreaTarget[0]);
        descriptionTextArea.bootstrap();
        descriptionTextArea.onValueChanged = (value) => {
            if (this.onViewDescriptionChanged && typeof value === 'string') {
                this.onViewDescriptionChanged(value);
            }
        };

        if (!selectedViewConfigMetadata) {
            const templateHeader = this.jQuery(`<span>${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.template')}</span>`);
            templateHeader.appendTo(targetElement);
            const templateSelectionTarget = this.jQuery('<div></div>');
            templateSelectionTarget.appendTo(targetElement);
            const loadingIndicator = this.jQuery('<div></div>');
            loadingIndicator.appendTo(targetElement);
            loadingIndicator.dxLoadIndicator();
            this.viewSystemSettingsWebServiceRequests.loadAvailableTemplates().then((templates: (ViewConfigMetaData | string)[]) => {
                templates.unshift(this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.noTemplate'));
                const selectBox = this.publicApi.Ui.components.selectBox(templateSelectionTarget[0]);
                selectBox.bootstrap({
                    displayExpr: (itemData: ViewConfigMetaData | string) => {
                        if (typeof itemData === 'string') {
                            return itemData;
                        }
                        return this.languageProvider.getTranslationFromProperty(itemData.name);
                    },

                    items: templates,
                    value: templates[0] || null,
                    itemTemplate: (itemData: ViewConfigMetaData | string) => {
                        if (typeof itemData === 'string') {
                            return itemData;
                        }
                        return this.languageProvider.getTranslationFromProperty(itemData.name);
                    },
                    onValueChanged: (event: any) => {
                        if (typeof event.value === 'string') {
                            if (this.onViewTemplateChanged) {
                                this.onViewTemplateChanged(undefined);
                            }
                            return;
                        }
                        if (this.onViewTemplateChanged) {
                            this.onViewTemplateChanged(event.value);
                        }
                    }
                });
                loadingIndicator.remove();
            }).catch((error) => {
                loadingIndicator.remove();
                this.publicApi.Notification.error({
                    body: this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.toasts.templateError')
                });
                this.publicApi.Logger.error(this.className, 'render', 'Failed to load templates', error);
            });
        }
        const showInNavigationeHeader = this.jQuery(`<span>${this.languageProvider.get('framework.systemSettings.viewSystemSettingsSection.popups.createView.showInNavigation')}</span>`);
        showInNavigationeHeader.appendTo(targetElement);
        const showInNavigationeSwitchTarget = this.jQuery('<div class="wd-system-settings-create-view-switch"></div>');
        showInNavigationeSwitchTarget.appendTo(targetElement);
        showInNavigationeSwitchTarget.dxSwitch({
            value: true,
            onValueChanged: (event) => {
                if (this.onViewShowInNavigationChanged) {
                    this.onViewShowInNavigationChanged(event.value);
                }
            }
        });
        if (selectedViewConfigMetadata) {
            try {
                viewNameTextbox.setValue(this.languageProvider.getTranslationFromProperty(selectedViewConfigMetadata.name));
            } catch (error) {
                this.publicApi.Logger.error(this.className, 'render', 'Failed to set view name', error);
            }
            if (selectedViewConfigMetadata.alias) {
                viewAliasTextbox.setValue(selectedViewConfigMetadata.alias);
            }
            try {
                descriptionTextArea.setValue(this.languageProvider.getTranslationFromProperty(selectedViewConfigMetadata.description));
            } catch (error) {
                this.publicApi.Logger.error(this.className, 'render', 'Failed to set view description', error);
            }

            const showInNavigationeSwitchTargetInstance = showInNavigationeSwitchTarget.dxSwitch('instance');
            showInNavigationeSwitchTargetInstance.option('value', !selectedViewConfigMetadata.isHiddenInNavigation);
        }

    }


}