import { SubViewType } from '../../../typings/core';
import { Utils } from '../common';
import { IComponent } from '../components';
import { ComponentInstanceConfig } from '../config';
import { IExtensionProvider } from '../extensions';
import { IMultilingualTranslator } from '../language';
import { IUiManager } from '../loader/interfaces/iUiManager';
import { Logger } from '../logging';
import { IPubSubHandler } from '../pubSub/interfaces/iPubSubHandler';
import { ComponentToolbarHandler, ToolbarManager } from '../toolbar';
import { ISkeletonHelper } from '../ui';
import { IComponentLifecycleManager } from './interfaces/iComponentLifecycleManager';
import { LifecycleReflector } from './lifecycleReflector';
import { Changes, LIFECYCLE_HOOKS, OnAfterBind, OnAfterRender, OnBind, OnDestroy, OnExtension, OnInit, OnRefresh, OnRender, OnSkeleton, OnSubViewChanged, OnSubViewChanging, OnToolbar } from '.';


/**
 * Lifecycle manager for a single component.
 * 
 * @export
 * @class ComponentLifecycleManager
 * @implements {IComponentLifecycleManager}
 */
export class ComponentLifecycleManager implements IComponentLifecycleManager {

    /**
     * The reference to the component, for which this instance 
     * 
     * @private
     * @type {IComponent}
     * @memberof ComponentLifecycleManager
     */
    private componentRef: IComponent;

    private logger: Logger;
    private uiManager: IUiManager;
    private translator: IMultilingualTranslator;
    private componentToolbarHandler: ComponentToolbarHandler;

    /**
     * Creates an instance of ComponentLifecycleManager.
     * 
     * @param {IComponent} componentRef The component reference.
     * @param {Logger} logger The logger.
     * @param {IUiManager} uiManager The ui manager.
     * @param {IMultilingualTranslator} translator The translator.
     * @param {ComponentToolbarHandler} componentToolbarHandler The component toolbar handler.
     * @memberof ComponentLifecycleManager
     */
    public constructor(componentRef: IComponent, logger: Logger, uiManager: IUiManager,
        translator: IMultilingualTranslator, componentToolbarHandler: ComponentToolbarHandler) {
        if (!componentRef) {
            throw new ReferenceError('missing argument \'componentRef\'');
        }
        this.componentRef = componentRef;
        this.logger = logger;
        this.uiManager = uiManager;
        this.translator = translator;
        this.componentToolbarHandler = componentToolbarHandler;
    }


    /**
     * Promotes the component into the init-Step.
     * 
     * @param {ComponentInstanceConfig} componentConfig The currently used componentConfig.
     * @returns {boolean} Whether the component has this hook or not.
     * 
     * @memberof ComponentLifecycleManager
     */
    public init(componentConfig: ComponentInstanceConfig): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnInit, this.componentRef)) {
            try {
                // TODO: Better checking for internal components
                // Remove this check once internal components like the Configuration do not use Map anymore
                let componentConfigToUse = componentConfig;
                if (componentConfigToUse.guid && !componentConfigToUse.guid.includes('WINDREAM')) {
                    componentConfigToUse = Utils.Instance.deepClone(componentConfig) as ComponentInstanceConfig;
                }
                (<any>this.componentRef as OnInit).wdOnInit(this.translator.getTranslatedComponentInstanceConfig(componentConfigToUse));
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'init', 'Cannot call wdOnInit on component', err);
                this.displayError();
            }
        }
        return false;
    }

    /**
     * Promotes the component into the wdOnSkeleton-Step.
     * @param {ISkeletonHelper} skeletonHelper ISkeletonHelper instance to use.
     * @param {boolean} isInConfigMode Whether the skeleton is displayed in config mode or as a default skeleton.
     *
     * @returns {boolean}
     * @memberof ComponentLifecycleManager
     */
    public skeleton(skeletonHelper: ISkeletonHelper, isInConfigMode: boolean): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnSkeleton, this.componentRef)) {
            try {
                (<any>this.componentRef as OnSkeleton).wdOnSkeleton(skeletonHelper, isInConfigMode);
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'skeleton', 'Cannot call wdOnSkeleton on component', err);
                this.displayError();
            }
        }
        return false;
    }

    /**
     * Executes the components wdOnSubViewChanging-Step.
     *
     * @param {boolean} willBeActiveOnSubView Whether the component will be active on this subview or not.
     * @param {SubViewType} subViewType The sub view type.
     * @return {boolean}  Whether the component has this hook or not.
     * @memberof ComponentLifecycleManager
     */
    public subViewChanging(willBeActiveOnSubView: boolean, subViewType: SubViewType): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnSubViewChanging, this.componentRef)) {
            try {
                (<any>this.componentRef as OnSubViewChanging).wdOnSubViewChanging(willBeActiveOnSubView, subViewType);
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'subViewChanging', 'Cannot call wdOnSubViewChanging on component', err);
                this.displayError();
            }
        }
        return false;
    }

    /**
     * Executes the components wdOnSubViewChanged-Step.
     *
     * @param {boolean} isActiveOnSubView Whether the component is active on this subview or not.
     * @param {SubViewType} subViewType The sub view type.
     * @return {boolean}  Whether the component has this hook or not.
     * @memberof ComponentLifecycleManager
     */
    public subViewChanged(isActiveOnSubView: boolean, subViewType: SubViewType): boolean {
        try {
            this.componentToolbarHandler.repaintToolbar();
        } catch (err) {
            this.logger.error('ComponentLifecycleManager', 'subViewChanged', 'Cannot call re-render toolbar', err);
        }
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnSubViewChanged, this.componentRef)) {
            try {
                (<any>this.componentRef as OnSubViewChanged).wdOnSubViewChanged(isActiveOnSubView, subViewType);
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'subViewChanged', 'Cannot call wdOnSubViewChanged on component', err);
                this.displayError();
            }
        }
        return false;
    }

    /**
     * Promotes the component into the wdOnBind-Step.
     * 
     * @param {PubSubHandler} pubsub The currently used PubSub.
     * @returns {boolean} Whether the component has this hook or not.
     * 
     * @memberof ComponentLifecycleManager
     */
    public bind(pubsub: IPubSubHandler): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnBind, this.componentRef)) {
            try {
                (<any>this.componentRef as OnBind).wdOnBind(pubsub);
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'bind', 'Cannot call wdOnBind on component', err);
                this.displayError();
            }
        }
        return false;
    }


    /**
     * Promotes the component into the wdOnAfterBind-Step.
     * 
     * @returns {boolean} Whether the component has this hook or not.
     * @memberof ComponentLifecycleManager
     */
    public afterBind(): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnAfterBind, this.componentRef)) {
            try {
                (<any>this.componentRef as OnAfterBind).wdOnAfterBind();
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'afterBind', 'Cannot call wdOnAfterBind on component', err);
                this.displayError();
            }
        }
        return false;
    }
    /**
     * Promotes the component into the wdOnAfterRender-Step.
     * 
     * @returns {boolean} Whether the component has this hook or not.
     * @memberof ComponentLifecycleManager
     */
    public afterRender(): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnAfterRender, this.componentRef)) {
            try {
                (<any>this.componentRef as OnAfterRender).wdOnAfterRender();
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'afterRender', 'Cannot call wdOnAfterRender on component', err);
                this.displayError();
            }
        }
        return false;
    }
    /**
     * Promotes the component into the wdOnExtension-Step.
     * 
     * @param {IExtensionProvider} extensionProvider The currently used providerProvider.
     * @returns {boolean} Whether the component has this hook or not.
     * 
     * @memberof ComponentLifecycleManager
     */
    public extension(extensionProvider: IExtensionProvider): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnExtension, this.componentRef)) {
            try {
                (<any>this.componentRef as OnExtension).wdOnExtension(extensionProvider);
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'extension', 'Cannot call wdOnExtension on component', err);
                this.displayError();
            }
        }
        return false;
    }


    /**
     * Promotes the component into the wdOnRender-Step.
     * 
     * @param {IUiManager} uiManager The currently used UiManager.
     * @returns {boolean} Whether the component has this hook or not.
     * 
     * @memberof ComponentLifecycleManager
     */
    public render(uiManager: IUiManager): boolean {
        try {
            this.componentToolbarHandler.renderToolbar();
        } catch (err) {
            this.logger.error('ComponentLifecycleManager', 'render', 'Cannot render toolbar', err);
        }
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnRender, this.componentRef)) {
            try {
                (<any>this.componentRef as OnRender).wdOnRender(uiManager);
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'render', 'Cannot call wdOnRender on component', err);
                this.displayError();
            }
        }
        return false;
    }

    /**
     * Promotes the component into the wdOnToolbar-Step.
     *
     * @returns {boolean} Whether the component has this hook or not.
     * @memberof ComponentLifecycleManager
     */
    public toolbar(): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnToolbar, this.componentRef)) {
            try {
                (<any>this.componentRef as OnToolbar).wdOnToolbar(new ToolbarManager(this.componentToolbarHandler.createToolbarDatasetHandler()));
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'toolbar', 'Cannot call wdOnToolbar on component', err);
                this.displayError();
            }
        }
        return false;
    }

    /**
     * Executes the components wdOnRefresh-Step.
     * 
     * @param {Changes} changes Changes that occured.
     * @returns {boolean} Whether the component has this hook or not.
     * 
     * @memberof ComponentLifecycleManager
     */
    public refresh(changes: Changes): boolean {
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnRefresh, this.componentRef)) {
            try {
                (<any>this.componentRef as OnRefresh).wdOnRefresh(changes);
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'refresh', 'Cannot call wdOnRefresh on component', err);
                this.displayError();
            }
        }
        return false;
    }


    /**
     * Promotes the component into the wdOnDestroy-Step.
     * 
     * @returns {boolean} Whether the component has this hook or not.
     * 
     * @memberof ComponentLifecycleManager
     */
    public destroy(): boolean {
        try {
            this.componentToolbarHandler.clearToolbar();
        } catch (err) {
            this.logger.error('ComponentLifecycleManager', 'destroy', 'Cannot destroy toolbar', err);
        }
        if (LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnDestroy, this.componentRef)) {
            try {
                (<any>this.componentRef as OnDestroy).wdOnDestroy();
                return true;
            } catch (err) {
                this.logger.error('ComponentLifecycleManager', 'destroy', 'Cannot call wdOnDestroy on component', err);
                this.displayError();
            }
        }
        return false;
    }

    /**
     * Displays an error in the component.
     * 
     * @private
     * 
     * @memberof ComponentLifecycleManager
     */
    private displayError(): void {
        this.uiManager.displayError(this.componentRef.guid);
    }
}