import { Actions } from '../../../typings/windreamWebService/Windream.WebService.Permissions';
import { WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents perform action request options.
 *
 * @export
 * @class PerformActionRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class PerformActionRequestOptions extends WindreamRequestOptions {

    /**
     * The actions.
     *
     * @type {Actions}
     * @memberof PerformActionRequestOptions
     */
    public actions: Actions;

    /**
     * Creates an instance of PerformActionRequestOptions.
     * @param {WindreamIdentity} identity
     * @param {Actions} actions
     * @memberof PerformActionRequestOptions
     */
    public constructor(identity: WindreamIdentity, actions: Actions) {
        super(identity);
        if (typeof actions !== 'number') {
            throw new Error('Argument \'actions\' is not valid');
        }
        this.actions = actions;
    }
}