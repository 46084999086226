import { DirectoryResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Directories';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { AttributeFlags, WindreamEntity } from '../../common';
import { DirectoryDetails } from '../../common/webService/directoryDetails';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { GetDetailsRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform a GetDirectoryDetails call on the windream webservice.
 * 
 * @export
 * @class GetDirectoryDetails
 * @extends {ServiceAction}
 */
export class GetDirectoryDetails extends ServiceAction {

  /**
   * Creates an instance of GetDirectoryDetails.
   * @param {IRequestExecutor} requestExecutor The request executor.
   * @param {GlobalConfig} globalConfig  The global config.
   * @param {Logger} logger The logger.
   * @memberof GetDirectoryDetails
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'getDetails';
  }

  /**
   * Gets details for a specified directory.
   * Resolves a promise with the server response. 
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {GetDetailsRequestOptions} requestOptions The request options.
   * @returns {Promise<DirectoryDetails>} A promise, which will resolve with the directory reponse.
   * @memberof GetDirectoryDetails
   */
  public async do(requestOptions: GetDetailsRequestOptions): Promise<DirectoryDetails> {
    return new Promise<DirectoryDetails>((resolve, reject) => {
      if (requestOptions.identity.entity === WindreamEntity.Document) {
        this.logger.error('GetDirectoryDetails', 'do', 'Invalid arguments', 'Invalid entity found: Document');
      }
      const url = `${this.globalConfig.windreamWebServiceURL}/directories/GetDetails`;
      const item = IdentityMapper.getWebServiceIdentity(requestOptions.identity);
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', url, {
        AttributeFlags: requestOptions.attributeFlags ||  AttributeFlags.Default,
        Item: item,
        Values: requestOptions.values || []
      }), requestOptions.requestOptions).then((response: IServiceResponse<DirectoryResponseContainerDTO>) => {
        if (response.data && !response.data.HasErrors && response.data.Item) {
          const result = DirectoryDetails.fromDto(response.data.Item);
          resolve(result);
        } else {
          this.logger.error('GetDirectoryDetails', 'do', 'Reponse has errors', response);
          reject(new Error('Failed to execute request'));
        }
      }).catch((err: Error) => {
        this.logger.error('GetDirectoryDetails', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}