import { ToolbarActionDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { ComponentInstanceToolbarActionConfig } from '.';


/**
 * Transform the toolbar config from the webservice to a local class.
 *
 * @export
 * @class ComponentInstanceToolbarActionConfigFactory
 */
export class ComponentInstanceToolbarActionConfigFactory {

    /**
     * Converts the DTO into a DW class.
     *
     * @static
     * @param {(ToolbarActionDTO[] | undefined)} toolbarConfigDto The DTO to convert.
     * @returns {Array<ComponentInstanceToolbarActionConfig>} The converted DTO.
     * @memberof ComponentInstanceToolbarActionConfigFactory
     */
    public static convertFromDTO(toolbarConfigDto: ToolbarActionDTO[] | undefined): Array<ComponentInstanceToolbarActionConfig> {
        const result = new Array<ComponentInstanceToolbarActionConfig>();
        if (toolbarConfigDto && toolbarConfigDto.length > 0) {
            toolbarConfigDto.forEach((action) => {
                result.push({
                    id: action.ID,
                    displayMode: action.DisplayMode as any
                });
            });
        }
        return result;
    }
}