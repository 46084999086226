/**
 * The model class for a outgoing PubSub event configuration.
 * 
 * @export
 * @class OutConfig
 */
export class OutConfig {

    /**
     * The name of the PubSub event.
     *
     * @type {string}
     * @memberof OutConfig
     */
    public name: string;

    /**
     * The description of the PubSub event.
     *
     * @type {string}
     * @memberof OutConfig
     */
    public description?: string;

    /**
     * The data type of the PubSub event.
     *
     * @type {string}
     * @memberof OutConfig
     */
    public dataType?: string;

    /**
     * The display value of the PubSub event.
     *
     * @type {string}
     * @memberof OutConfig
     */
    public displayValue?: string;

    /**
     * State to check if PubSub output event is required.
     *
     * @type {boolean}
     * @memberof OutConfig
     */
    public isRequired?: boolean;

    /**
     * The id of this publisher.
     *
     * @type {string}
     * @memberof OutConfig
     */
    public id?: string;

    /**
     * Creates an instance of OutConfig.
     *
     * @param {string} name
     * @memberof OutConfig
     */
    public constructor(name: string) {
        this.name = name;
    }
}