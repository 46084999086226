import { DWCore } from '@windream/dw-core/dwCore';
import { IInjectObject } from './interfaces';
const MobileDetect = require('mobile-detect');


/**
 * List of strings representing device names.
 */
export const DEVICE_NAMES = [
    'desktop',
    'tablet',
    'phone'
];


/**
 * List of all available platforms.
 *
 * @export
 * @enum {number}
 */
export enum PLATFORMS {
    IOS = 'ios',
    ANDROID = 'android'
}

/**
 * The DeviceDetection class provides certain detection functions.
 * 
 * @export
 * @class DeviceDetection
 */
export class DeviceDetection {

    /**
     * Gets the current mobileDetect instance.
     * 
     * @private
     * 
     * @memberof DeviceDetection
     */
    private static mobileDetect = new MobileDetect(typeof window !== 'undefined' ? window.navigator.userAgent : '');

    /**
     * Gets whether the current platform is a phone or not.
     * 
     * @returns {boolean}
     * 
     * @memberof DeviceDetection
     */
    public static isPhone(): boolean {
        // The information coming from the app (if any) has higher priority
        const globallyInjectedAppObject = this.getGloballyInjectedAppObject();
        if (globallyInjectedAppObject && globallyInjectedAppObject.deviceType) {
            return globallyInjectedAppObject.deviceType === DWCore.Common.Devices.PHONE;
        }
        return DeviceDetection.mobileDetect.phone() ? true : false;
    }

    /**
     * Gets whether the current platform is a tablet or not.
     * 
     * @returns {boolean}
     * 
     * @memberof DeviceDetection
     */
    public static isTablet(): boolean {
        // The information coming from the app (if any) has higher priority
        const globallyInjectedAppObject = this.getGloballyInjectedAppObject();
        if (globallyInjectedAppObject && globallyInjectedAppObject.deviceType) {
            return globallyInjectedAppObject.deviceType === DWCore.Common.Devices.TABLET;
        }
        return DeviceDetection.mobileDetect.tablet() ? true : false;
    }

    /**
     * Gets whether the current platform is on a mobile display or not.
     * 
     * @returns {boolean}
     * 
     * @memberof DeviceDetection
     */
    public static isMobile(): boolean {
        // The information coming from the app (if any) has higher priority
        const globallyInjectedAppObject = this.getGloballyInjectedAppObject();
        if (globallyInjectedAppObject && globallyInjectedAppObject.deviceType) {
            return globallyInjectedAppObject.deviceType === DWCore.Common.Devices.TABLET || globallyInjectedAppObject.deviceType === DWCore.Common.Devices.PHONE;
        }
        return DeviceDetection.mobileDetect.mobile() ? true : false;
    }

    /**
     * Returns the ENUM entry for the current device.
     * 
     * @static
     * @returns {DWCore.Common.Devices}
     * 
     * @memberof DeviceDetection
     */
    public static getCurrentDevice(): DWCore.Common.Devices {
        if (DeviceDetection.isTablet()) {
            return DWCore.Common.Devices.TABLET;
        } else if (DeviceDetection.isPhone()) {
            return DWCore.Common.Devices.PHONE;
        } else {
            return DWCore.Common.Devices.DESKTOP;
        }
    }

    /**
     * Returns a string representing the given device.
     * 
     * @static
     * @param {DWCore.Common.Devices} device  Device to get the string for.
     * @returns {string}
     * 
     * @memberof DeviceDetection
     */
    public static getDeviceName(device: DWCore.Common.Devices): string {
        return DEVICE_NAMES[device];
    }

    /**
     * Checks whether the applications runs inside any mobile app container.
     * 
     * @static
     * @returns {boolean} True if application runs sinide any mobile app container.
     * @memberof DeviceDetection
     */
    public static isApp(): boolean {
        // @ts-ignore - Window usage required for testing
        return typeof window.MobileApp !== 'undefined';
    }

    /**
     * Gets the globally injected app object if it's set.
     *
     * @static
     * @returns {(IInjectObject | undefined)} The injected object or undefined if not set.
     * @memberof DeviceDetection
     */
    public static getGloballyInjectedAppObject(): IInjectObject | undefined {
        if (DeviceDetection.isApp()) {
            // @ts-ignore - Ignore because of window usage
            return window['MobileApp'] as IInjectObject;
        }
        return undefined;
    }
}