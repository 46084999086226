import { DWCore } from '@windream/dw-core/dwCore';
import { SubViewType } from '../../../typings/core';
import { Utils } from '../common';
import { HotFix } from '../hotfix';
import { ILanguageManager } from '../language';
import { Logger } from '../logging';
import { RouteManager, SubViewRouter, ViewRouter } from '../router';
import { ILayoutManager, ILayoutManagerFactory, ISubViewManager } from './interfaces';
import { SubViewConfig } from './models';
/**
 * Subview Manager to manage subviews.
 * 
 * @export
 * @class SubViewManager
 * @implements {ISubViewManager}
 */
export class SubViewManager implements ISubViewManager {
  // Map to save the LayoutManagers per SubView
  // First level are the SubView-GUIDs
  // Second level are the types of LayoutManager
  private layoutManagers: Map<string, Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>>;
  private subViewConfigs: Map<string, SubViewConfig>;
  private logicComponentRootElement: HTMLElement;
  private uiComponentRootElement: HTMLElement;
  private uiComponentSubViewNodes: Map<string, HTMLElement>;
  private logicComponentSubViewNodes: Map<string, HTMLElement>;
  private activeSubView?: string;
  private languageManager: ILanguageManager;
  private className: string = 'SubViewManager';
  private layoutManagerFactory: ILayoutManagerFactory;
  private logger: Logger;
  private subViewHistory: string[];
  private onSubViewChangedFns: ((subViewId: string, subViewType: SubViewType) => void)[] = new Array<(subViewId: string, subViewType: SubViewType) => void>();
  private onSubViewChangingFns: ((subViewId: string, subViewType: SubViewType) => void)[] = new Array<(subViewId: string, subViewType: SubViewType) => void>();
  private viewRouter: ViewRouter;

  /**
   * Indicates if the current switch of the active subview requires a closing of open popups.
   * Used for transition handler to close all popups on a direct transition.
   *
   * @returns {boolean}
   * @memberof SubViewManager
   */
  public switchRequiresClosePopups: boolean;

  /**
   * Creates an instance of SubViewManager.
   * 
   * @param {ILanguageManager} languageManager The language manager.
   * @param {HTMLElement} uiComponentRootElement The ui root element.
   * @param {HTMLElement} logicComponentRootElement The logic component root element.
   * @param {ILayoutManagerFactory} layoutManagerFactory The layout manager factory.
   * @param {Logger} logger The logger.
   * @param {ViewRouter} viewRouter The view router.
   * @memberof SubViewManager
   */
  public constructor(languageManager: ILanguageManager, uiComponentRootElement: HTMLElement, logicComponentRootElement: HTMLElement,
    layoutManagerFactory: ILayoutManagerFactory, logger: Logger, viewRouter: ViewRouter) {
    this.logger = logger;
    this.layoutManagers = new Map<string, Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>>();
    this.uiComponentSubViewNodes = new Map<string, HTMLElement>();
    this.logicComponentSubViewNodes = new Map<string, HTMLElement>();
    this.subViewConfigs = new Map<string, SubViewConfig>();
    this.languageManager = languageManager;
    this.uiComponentRootElement = uiComponentRootElement;
    uiComponentRootElement.innerHTML = ''; // Remove everything from root
    logicComponentRootElement.innerHTML = '';
    this.logicComponentRootElement = logicComponentRootElement;
    this.layoutManagerFactory = layoutManagerFactory;
    this.subViewHistory = new Array<string>();
    this.viewRouter = viewRouter;

    this.switchRequiresClosePopups = false;
  }

  /**
   * Creates all subviews out of given configuration.
   * 
   * @param {SubViewConfig[]} subViewConfigs List of subview configurations to use.
   * 
   * @memberof SubViewManager
   */
  public createSubViews(subViewConfigs: SubViewConfig[]): void {
    if (subViewConfigs.length === 0) {
      this.logger.error(this.className, 'createSubViews', 'No subview configurations found. Make sure validateSubViewConfig is used.');
      return;
    }

    this.subViewConfigs = new Map<string, SubViewConfig>();
    this.layoutManagers = new Map<string, Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>>();

    subViewConfigs.forEach((viewConfig: SubViewConfig) => {
      this.createSubView(viewConfig);
    });

    // Set first subview active
    this.activeSubView = subViewConfigs[0].id;
    if (this.activeSubView) {
      this.showSubView(this.activeSubView, SubViewType.View);
    } else {
      this.logger.error(this.className, 'createSubViews', 'First SubView has no ID set.', subViewConfigs[0]);
    }
  }

  /**
   * Initialize each subview by initializing each LayoutManager instance.
   * 
   * @memberof SubViewManager
   */
  public initializeSubViews(): void {
    this.layoutManagers.forEach((layoutManagerPerType: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>) => {
      layoutManagerPerType.forEach((layoutManager: ILayoutManager) => {
        layoutManager.initializeLayout();
      });
    });
  }

  /**
   * Register a callback which will be trigger if the subview changed.
   *
   * @param {(subViewId: string, subViewType: SubViewType) => void} callback The callback.
   * @memberof SubViewManager
   */
  public onSubViewChanged(callback: (subViewId: string, subViewType: SubViewType) => void): void {
    this.onSubViewChangedFns.push(callback);
  }

  /**
   * Register a callback which will  trigger before the subview changed.
   *
   * @param {(subViewId: string, subViewType: SubViewType) => void} callback The callback.
   * @memberof SubViewManager
   */
  public onSubViewChanging(callback: (subViewId: string, subViewType: SubViewType) => void): void {
    this.onSubViewChangingFns.push(callback);
  }

  /**
   * Adds a single subview.
   * Invokes `prepareGrid` and `initializeGrid` on the LayoutManager instance.
   * Directly sets new LayoutManager instance into edit mode.
   * 
   * @param {SubViewConfig} subViewConfig Configuration to use.
   * @param {DWCore.Common.Devices} device Device to add the subview for.
   * 
   * @memberof SubViewManager
   */
  public addSubView(subViewConfig: SubViewConfig, device: DWCore.Common.Devices): void {
    if (!subViewConfig.id) {
      this.logger.error(this.className, 'addSubView', 'New SubView has no ID set.', subViewConfig);
      return;
    }
    this.createSubView(subViewConfig);
    const newLayoutManagers = this.layoutManagers.get(subViewConfig.id);
    if (newLayoutManagers) {
      newLayoutManagers.forEach((layoutManager: ILayoutManager) => {
        layoutManager.prepareLayout(device);
        layoutManager.initializeLayout();
        layoutManager.setEditable(true);
      });
    }
  }


  /**
   * Returns a Map of all subviews and an array of their component GUIDs.
   * Key is the subview ID.
   * Value is the array of component GUIDs.
   * 
   * @returns {Map<string, string[]>} Map of all subviews and their component GUIDs.
   * 
   * @memberof SubViewManager
   */
  public getComponentsPerSubView(): Map<string, string[]> {
    const componentsPerSubView = new Map<string, string[]>();

    this.subViewConfigs.forEach((subViewConfig: SubViewConfig) => {
      if (!subViewConfig.id) {
        this.logger.error(this.className, 'getComponentsPerSubView', 'SubView has no ID set.', subViewConfig);
        return;
      }
      componentsPerSubView.set(subViewConfig.id, subViewConfig.components);
    });

    return componentsPerSubView;
  }


  /**
   * Returns a Map of all subviews and their HTML elements.
   * Key is the subview ID.
   * Value is the div container of the subview.
   * 
   * @returns {Map<string, HTMLElement>} Map of all subviews and their HTML elements.
   * 
   * @memberof SubViewManager
   */
  public getNodesPerSubView(): Map<string, HTMLElement> {
    return this.uiComponentSubViewNodes;
  }

  /**
   * Returns a Map of all subviews and their LayoutManager instances.
   * Key is the subview ID.
   * Value is the LayoutManager instance.
   * 
   * @param {DWCore.Components.COMPONENT_TYPES} type The type of LayoutManagers to get per SubView.
   * @returns {Map<string, ILayoutManager>} Map of all subviews and their LayoutManager instances.
   * @memberof SubViewManager
   */
  public getLayoutManagerPerSubView(type: DWCore.Components.COMPONENT_TYPES): Map<string, ILayoutManager> {
    const layoutManagersOfType = new Map<string, ILayoutManager>();
    this.layoutManagers.forEach((layoutManagerPerType: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>, subViewId: string) => {
      const layoutManager = layoutManagerPerType.get(type);
      if (layoutManager) {
        layoutManagersOfType.set(subViewId, layoutManager);
      }
    });
    return layoutManagersOfType;
  }

  /**
   * Returns a Map of all subviews and their LayoutManager instances.
   * 
   * @returns {Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>>} Map of all subviews and their LayoutManager instances.
   * @memberof SubViewManager
   */
  public getLayoutManagersPerSubView(): Map<string, Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>> {
    return this.layoutManagers;
  }

  /**
   * Prepare all subviews by calling `prepareGrid` for each LayoutManager instance.
   * 
   * @param {DWCore.Common.Devices} device Device to prepare for.
   * 
   * @memberof SubViewManager
   */
  public prepareSubViews(device: DWCore.Common.Devices): void {
    this.layoutManagers.forEach((layoutManagers: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>) => {
      layoutManagers.forEach((layoutManager: ILayoutManager) => {
        layoutManager.prepareLayout(device);
      });
    });
  }

  /**
   * Destroys the ViewManager.
   * Invokes `clear`.
   * 
   * @memberof SubViewManager
   */
  public destroy(): void {
    this.clear();
  }

  /**
   * Reset the navigation history.
   *
   * @memberof SubViewManager
   */
  public resetNavigationHistory(): void {
    this.subViewHistory.length = 0;
  }

  /**
   * Whether navigation occured inside the subview manager or not.
   *
   * @returns {boolean} True if navigation occured, otherwise false.
   * @memberof SubViewManager
   */
  public hasNavigationHistory(): boolean {
    return this.subViewHistory.length > 0;
  }

  /**
   * Goes to the previous displayed subview.
   *
   * @memberof SubViewManager
   */
  public goBack(): void {
    RouteManager.back();
  }

  /**
   * Destroys each LayoutManager.
   * Removes everything from the root node.
   * 
   * @memberof SubViewManager
   */
  public clear(): void {
    this.subViewHistory.length = 0;
    if (this.layoutManagers && this.layoutManagers.size > 0) {
      this.layoutManagers.forEach((layoutManagers: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>) => {
        layoutManagers.forEach((layoutManager: ILayoutManager) => {
          layoutManager.destroy();
        });
      });
      this.layoutManagers.clear();
    }
    // Only clear the subviews and static pages
    const nodes = <Node[]>[].slice.call(this.uiComponentRootElement.querySelectorAll('#root > div.sub-view[data-sub-view-id]'));
    nodes.forEach((element) => {
      this.uiComponentRootElement.removeChild(element);
    });

    const staticPages = <Node[]>[].slice.call(this.uiComponentRootElement.querySelectorAll('#root > div.static-page'));
    staticPages.forEach((element) => {
      this.uiComponentRootElement.removeChild(element);
    });
  }

  /**
   * Sets all LayoutManager instances editable state.
   * 
   * @param {boolean} editable  State to set the editable state.
   * 
   * @memberof SubViewManager
   */
  public setEditable(editable: boolean): void {
    this.layoutManagers.forEach((layoutManagers: Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>) => {
      layoutManagers.forEach((layoutManager: ILayoutManager) => {
        layoutManager.setEditable(editable);
      });
    });
  }

  /**
   * Switch the currently active subview to the given one.
   * 
   * @param {string} subViewId Id of the subview to switch to.
   * @param {boolean} [preventHistoryEntry] If a history entry should be prevented.
   * @param {boolean} [triggeredByRouter] Whether the switch was triggered by a router.
   * @param {SubViewType} [subViewType] The type of the subview to which the application shall switch - necessary to know for multiple popups with mirror components.
   * 
   * @memberof SubViewManager
   */
  public switchSubView(subViewId: string, preventHistoryEntry?: boolean, triggeredByRouter?: boolean, subViewType?: SubViewType): void {
    if (subViewId === this.activeSubView) {
      this.logger.info(this.className, 'switchSubView', 'Can\'t switch to the same subview.');
      return;
    }
    if (this.uiComponentSubViewNodes.has(subViewId)) {
      // Disable currently active subview
      if (this.activeSubView) {
        this.hideSubView(this.activeSubView);
      }
      if (!preventHistoryEntry) {
        this.manageHistoryEntry(subViewId, triggeredByRouter);
      }
      // Activate new subview
      this.showSubView(subViewId, subViewType ? subViewType : SubViewType.View);
      HotFix.runOnSubViewChange(subViewId);
    } else {
      this.logger.warn(this.className, 'switchSubView', `SubView with ID ${subViewId} not found.`);
    }
  }

  /**
   * Hides the given subview.
   * 
   * @private
   * @param {string} subViewId The id of the subview which should be hidden.
   * 
   * @memberof SubViewManager
   */
  public hideSubView(subViewId: string): void {
    const uiComponentSubViewNode: HTMLElement | undefined = this.uiComponentSubViewNodes.get(subViewId);
    if (uiComponentSubViewNode) {
      uiComponentSubViewNode.classList.remove('active');
    } else {
      this.logger.warn(this.className, 'hideSubView', 'Unable to find UI node for SubView with ID' + subViewId);
    }
    const logicComponentSubViewNode: HTMLElement | undefined = this.logicComponentSubViewNodes.get(subViewId);
    if (logicComponentSubViewNode) {
      logicComponentSubViewNode.classList.remove('active');
    } else {
      this.logger.warn(this.className, 'hideSubView', 'Unable to find Logic node for SubView with ID' + subViewId);
    }
  }
  /**
   * Shows the given subview.
   *
   * @param {string} subViewId The sub view id.
   * @param {SubViewType} subViewType The sub view type to switch to.
   * @memberof SubViewManager
   */
  public showSubView(subViewId: string, subViewType: SubViewType): void {
    this.onSubViewChangingFns.forEach((callback) => {
      callback(subViewId, subViewType);
    });
    const uiComponentSubViewNode: HTMLElement | undefined = this.uiComponentSubViewNodes.get(subViewId);
    if (uiComponentSubViewNode) {
      uiComponentSubViewNode.classList.add('active');
    } else {
      this.logger.warn(this.className, 'hideSubView', 'Unable to find UI node for SubView with ID' + subViewId);
    }
    const logicComponentSubViewNode: HTMLElement | undefined = this.logicComponentSubViewNodes.get(subViewId);
    if (logicComponentSubViewNode) {
      logicComponentSubViewNode.classList.add('active');
    } else {
      this.logger.warn(this.className, 'hideSubView', 'Unable to find Logic node for SubView with ID' + subViewId);
    }
    this.activeSubView = subViewId;
    // Always reinit subviews since gridstack doesn't use compact() anymore.
    const layoutManager = this.getCurrentLayoutManager(DWCore.Components.COMPONENT_TYPES.UI);
    if (layoutManager) {
      layoutManager.initializeLayout();
    }
    this.onSubViewChangedFns.forEach((callback) => {
      callback(subViewId, subViewType);
    });
  }

  /**
   * Returns the LayoutManager for the currently active SubView of the given type.
   * 
   * @param {DWCore.Components.COMPONENT_TYPES} type Type to get LayoutManager for.
   * @returns {(ILayoutManager | null)} The LayoutManager instance for the given type and the currently active SubView.
   * @memberof SubViewManager
   */
  public getCurrentLayoutManager(type: DWCore.Components.COMPONENT_TYPES): ILayoutManager | null {
    if (!this.activeSubView) {
      return null;
    }
    const layoutManagersForActiveSubView = this.layoutManagers.get(this.activeSubView);
    if (!layoutManagersForActiveSubView) {
      return null;
    }
    return layoutManagersForActiveSubView.get(type) || null;
  }

  /**
   * Returns the LayoutManager for the currently active SubView.
   * 
   * @returns {(Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | null)} The LayoutManager instance for the currently active SubView.
   * @memberof SubViewManager
   */
  public getCurrentLayoutManagers(): Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager> | null {
    if (!this.activeSubView) {
      return null;
    }
    if (this.layoutManagers) {
      return this.layoutManagers.get(this.activeSubView) || null;
    }
    // Return null if no subview was found, so that unrelated components like the profile can still be loaded.
    return null;
  }

  /**
   * Returns the ID of the currently active subview.
   * 
   * @returns {(string | null)} The id of the active subview, null if no subview is active.
   * 
   * @memberof SubViewManager
   */
  public getCurrentSubViewId(): string | null {
    if (!this.activeSubView) {
      return null;
    }
    return this.activeSubView;
  }

  /**
   * Validates given list of SubViewConfig objects.
   * Will return a list with a default entry if given list is invalid, otherwise the given list will be returned.
   * 
   * @param {SubViewConfig[]} subViewConfigs List of SubViewConfig objects to validate.
   * @returns {SubViewConfig[]}  Valid list of SubViewConfig objects.
   * 
   * @memberof SubViewManager
   */
  public validateSubViewConfigs(subViewConfigs: SubViewConfig[]): SubViewConfig[] {
    if (!subViewConfigs || subViewConfigs.length === 0) {
      this.logger.debug(this.className, 'validateSubViewConfigs', 'Given subview configuration is invalid. Returning default configuration.');
      const languageProvider = this.languageManager.getLanguageProvider('framework');
      return [{ name: `${languageProvider.get('framework.config.subViews.subView')} 1`, id: Utils.Instance.getUUID(), components: [] }];
    } else {
      return subViewConfigs;
    }
  }

  /**
   * Creates a LayoutManager instance for the given subview.
   * Also creates HTML elements to nest the subview.
   * 
   * @private
   * @param {SubViewConfig} subViewConfig List of subview configurations to use.
   * 
   * @memberof SubViewManager
   */
  private createLayoutManagerForSubView(subViewConfig: SubViewConfig): void {
    if (!subViewConfig.id) {
      this.logger.error(this.className, 'createLayoutManagerForSubView', 'SubView has no ID set.', subViewConfig);
      return;
    }

    const subViewNodes = new Map<DWCore.Components.COMPONENT_TYPES, HTMLElement>();
    const uiComponentSubViewNode = document.createElement('div');
    uiComponentSubViewNode.classList.add('sub-view');
    uiComponentSubViewNode.setAttribute('data-sub-view-id', subViewConfig.id);
    subViewNodes.set(DWCore.Components.COMPONENT_TYPES.UI, uiComponentSubViewNode);
    this.uiComponentRootElement.appendChild(uiComponentSubViewNode);
    this.uiComponentSubViewNodes.set(subViewConfig.id, uiComponentSubViewNode);

    const logicComponentSubViewNode = document.createElement('div');
    logicComponentSubViewNode.classList.add('sub-view');
    logicComponentSubViewNode.setAttribute('data-sub-view-id', subViewConfig.id);
    subViewNodes.set(DWCore.Components.COMPONENT_TYPES.LOGICAL, logicComponentSubViewNode);
    this.logicComponentRootElement.appendChild(logicComponentSubViewNode);
    this.logicComponentSubViewNodes.set(subViewConfig.id, logicComponentSubViewNode);


    const layoutManagerPerType = new Map<DWCore.Components.COMPONENT_TYPES, ILayoutManager>();
    // Create one LayoutManager for each entry of COMPONENT_TYPES

    const componentTypes: number[] = [0, 1];  // 0 = UI, 1 = LOGICAL
    for (const type in componentTypes) {
      if (!isNaN(Number(type))) {
        const typeAsNumber = parseInt(type, 10);
        const node = subViewNodes.get(typeAsNumber);
        if (!node) {
          throw new Error(`No DOM element found for COMPONENT_TYPE=${typeAsNumber} for SubView ${subViewConfig.id}`);
        }
        const layoutManagerForType = this.layoutManagerFactory.create(typeAsNumber, this.languageManager, node);
        layoutManagerPerType.set(typeAsNumber, layoutManagerForType);
      }
    }
    this.layoutManagers.set(subViewConfig.id, layoutManagerPerType);

  }


  /**
   * Create a single subview out of the given configuration.
   * 
   * @param {SubViewConfig} subViewConfig Configuration of the subview to use.
   * 
   * @memberof SubViewManager
   */
  private createSubView(subViewConfig: SubViewConfig): void {
    if (!subViewConfig.id) {
      this.logger.error(this.className, 'createSubView', 'SubView has no ID set.', subViewConfig);
      return;
    }
    this.subViewConfigs.set(subViewConfig.id, subViewConfig);
    this.createLayoutManagerForSubView(subViewConfig);
  }

  /**
   * Adds a history entry for the given subview id it is not already the last entry of the history.
   * Required to ensure correct history in case of browser backdrop and open popups.
   * 
   * @param {string} subViewId The sub view id.
   * @memberof SubViewManager
   */
  public addHistoryEntry(subViewId: string): void {
    if (this.subViewHistory.length === 0 || this.subViewHistory[this.subViewHistory.length - 1] !== subViewId) {
        this.manageHistoryEntry(subViewId);
    }
  }

  /**
   * Manages history entries.
   *
   * @private
   * @param {string} subViewId The sub view id.
   * @param {boolean} [triggeredByRouter] Whether the switch was triggered by a router.
   * @memberof SubViewManager
   */
  private manageHistoryEntry(subViewId: string, triggeredByRouter?: boolean): void {
    let currentView = this.viewRouter.getCurrentViewFromPath();
    if (!currentView && this.viewRouter.activeViews && this.viewRouter.activeViews.length > 0) {
      currentView = this.viewRouter.activeViews[0].alias || this.viewRouter.activeViews[0].id;
    }
    if (currentView) {
      if (this.subViewHistory.length > 0) {
        if (this.subViewHistory.length > 1) {
          // Check if it's backwards or forwards.
          const backwardsOffset = 2;
          if (this.subViewHistory[this.subViewHistory.length - backwardsOffset] === subViewId && triggeredByRouter) {
            this.subViewHistory.pop();
          } else {
            RouteManager.replaceOrPushState(null, '', SubViewRouter.generateSubViewUrl(currentView, subViewId));
            this.subViewHistory.push(subViewId);
          }
        } else if (this.subViewHistory[1] !== subViewId) {
          // One entry which differs from the previous one so its a forward call.
          RouteManager.replaceOrPushState(null, '', SubViewRouter.generateSubViewUrl(currentView, subViewId));
          this.subViewHistory.push(subViewId);
        }
      } else {
        // No entry yet push normally.
        RouteManager.replaceOrPushState(null, '', SubViewRouter.generateSubViewUrl(currentView, subViewId));
        this.subViewHistory.push(subViewId);
      }
    } else {
      this.logger.error(this.className, 'switchSubView', 'Failed to retrieve view for sub view.');
    }
  }
}