import { IUserConfigManager } from '../config';
import { Logger } from '../logging';
import { IUserConfigExtension } from './interfaces';

/**
 * The userconfig extension provides the configuration for a single config part.
 * 
 * @export
 * @class UserConfigExtension
 * @implements {IUserConfigExtension}
 */
export class UserConfigExtension implements IUserConfigExtension {
  /**
   * Name of the extension.
   * 
   * @type {string}
   * @memberof UserConfigExtension
   */
  public name: string = 'user_config';

  private userConfigManager: IUserConfigManager;
  private logger: Logger;

  /**
   * Creates an instance of UserConfigExtension.
   * @param {IUserConfigManager} userConfigManager 
   * @param {Logger} logger 
   * 
   * @memberof UserConfigExtension
   */
  public constructor(userConfigManager: IUserConfigManager, logger: Logger) {
    this.userConfigManager = userConfigManager;
    this.logger = logger;
  }


  /**
   * Saves the user specific configuration for a given component on a given view.
   * Will only perform the save if the function has not been called within the next `DEBOUNCE_DELAY` milliseconds.
   * 
   * @param {string} componentGuid  GUID of the component.
   * @param {*} componentConfig     Configuration to save.
   * @returns {Promise<boolean>}  Promise to resolve on success.
   * @async
   * 
   * @memberof UserConfigExtension
   */
  public async saveComponentConfig(componentGuid: string, componentConfig: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userConfigManager.saveComponentConfig(componentGuid, componentConfig, (success: boolean, newConfig: any) => {
        if (success) {
          resolve(newConfig);
        } else {
          this.logger.error('UserConfigExtension', 'saveComponentConfig', 'Failed to save configuration' + componentConfig);
          reject(new Error('Failed to save configuration.'));
        }
      });
    });
  }

  /**
   * Opens a popup using the PopupHelper to edit a single configuration.
   * Will load the com.windream.configuration component with the `configurationName` parameter, causing it to render only a single configuration.
   * 
   * @param {string} componentGuid      GUID of the component to edit.
   * @param {string} configurationName  Name of the configuration to change.
   * @param {(success: boolean, newConfig?: any) => void} [callback]
   * @returns {Promise<boolean>}
   * @async
   * 
   * @memberof UserConfigExtension
   */
  public async openConfigPopup(componentGuid: string, configurationName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userConfigManager.openConfigPopup(componentGuid, configurationName, (success: boolean, newConfig: any) => {
        if (success) {
          resolve(newConfig);
        } else {
          this.logger.error('UserConfigExtension', 'saveComponentConfig', 'Failed to save configuration' + configurationName);
          reject(new Error('Failed to save configuration.'));
        }
      });
    });
  }
}