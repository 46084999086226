import { IThemeBackend } from '../interfaces/';

/**
 * Backend for DevExpress theming.
 *
 * @export
 * @class DxThemeBackend
 * @implements {IThemeBackend}
 */
export class DxThemeBackend implements IThemeBackend {
    /**
     * Get the currently active theme using DevExpress UI theming.
     *
     * @type {string}
     * @memberof DxThemeBackend
     */
    public getActiveTheme(): string {
        return DevExpress.ui.themes.current();
    }

    /**
     * Set the currently active theme using DevExpress UI theming.
     *
     * @memberof DxThemeBackend
     */
    public setActiveTheme(themeId: string) {
        DevExpress.ui.themes.current(themeId);
    }
}