
/**
 * Thrown by methods when in an invalid state.
 * 
 * @export
 * @class InvalidOperationError
 * @extends {Error}
 */
export class InvalidOperationError extends Error {

    /**
     * Creates an instance of InvalidOperationError.
     * @param {string} [message] 
     * 
     * @memberof InvalidOperationError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'InvalidOperationError';
    }
}