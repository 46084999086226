import { IDataSourceItem } from '../interfaces/iDataSourceItem';

/**
 * This class represents a data source item.
 *
 * @export
 * @class DataSourceItem
 * @template T
 */
export class DataSourceItem<T> implements IDataSourceItem<T> {

    /**
     * Id
     *
     * @private
     * @type {number}
     * @memberof DataSourceItem
     */
    public id?: number;

    /**
     * Display value.
     *
     * @type {string}
     * @memberof DataSourceItem
     */
    public displayValue?: string;

    /**
     * The value.
     *
     * @type {T}
     * @memberof DataSourceItem
     */
    public value: T;

    /**
     * Value to group by.
     *
     * @type {string}
     * @memberof DataSourceItem
     */
    public group?: string;


    /**
     * Creates an instance of DataSourceItem.
     *
     * @param {T} value
     * @memberof DataSourceItem
     */
    public constructor(value: T) {
        this.value = value;
    }
}