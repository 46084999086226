import { ContextMenuResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { BaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to perform a GetContextMenu on the windream webservice.
 *
 * @export
 * @class GetContextMenu
 * @extends {ServiceAction}
 */
export class GetContextMenu extends ServiceAction {

    /**
     * Creates an instance of GetContextMenu.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof GetContextMenu
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getContextMenu';
    }

    /**
     * Gets the contextmenu structure from windream.
     *
     * @param {BaseRequestOptions} [baseRequestOptions] The request options.
     * @returns {Promise<ContextMenuResponseContainerDTO>} A promise, which will resolve with the server response.
     * @memberof GetContextMenu
     */
    public async do(baseRequestOptions?: BaseRequestOptions): Promise<ContextMenuResponseContainerDTO> {
        return new Promise<ContextMenuResponseContainerDTO>((resolve, reject) => {
            const url = `${this.globalConfig.windreamWebServiceURL}/dynamicworkspace/contextmenu/GetContextMenu`;
            const resourcePointer = new HttpResourcePointer(
                'get',
                url
            );
            this.requestExecutor.executeRequest(resourcePointer, baseRequestOptions?.requestOptions)
                .then((response: IServiceResponse<ContextMenuResponseContainerDTO>) => {
                    if (!response.data) {
                        this.logger.error('GetContextMenu', 'do', 'Failed to get context menu', response);
                        reject(new Error('Failed to get context menu'));
                    } else {
                        resolve(response.data);
                    }
                }).catch((err: Error) => {
                    this.logger.error('getContextMenu', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}