import { MemberTypes } from './memberTypes';

/**
 * Model to represent a windream member.
 * 
 * @export
 * @class Member
 */
export class Member {

    /**
     * Type of the member.
     *
     * @type {MemberTypes}
     * @memberof Member
     */
    public memberType: MemberTypes;

    /**
     * Name of the member.
     * 
     * @type {string}
     * @memberof Member
     */
    public name: string;

    /**
     * Domain of the member.
     * 
     * @type {string}
     * @memberof Member
     */
    public domain?: string;

    /**
     * ID of the member.
     * 
     * @type {number}
     * @memberof Member
     */
    public id: number;

    /**
     * Fullname of the member.
     * 
     * @type {string}
     * @memberof Member
     */
    public fullName: string;

    /**
     * Creates an instance of Member.
     * @param {number} id
     * @param {string} name
     * @param {MemberTypes} memberType
     * @param {string} [domain]
     * @param {string} [fullName]
     * @memberof Member
     */
    public constructor(id: number, name: string, memberType: MemberTypes, domain?: string, fullName?: string) {
        this.id = id;
        this.name = name;
        this.memberType = memberType;
        this.domain = domain;
        this.fullName = fullName || name;
    }

    /**
     * Returns the full qualified name.
     *
     * @readonly
     * @type {string}
     * @memberof Member
     */
    get fullQualifiedName(): string {
        return this.domain ? this.domain + '\\' + this.name : this.name;
    }

}