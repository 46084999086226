import { WindreamIdentity } from '../common';
import { ContextMenuItem } from '.';

/**
 * The ContextMenu class is responsible for creating a context menu.
 * 
 * @export
 * @class ContextMenu
 */
export class ContextMenu {

    private _parentIdentity?: WindreamIdentity;
    private _items: ContextMenuItem[];

    /**
     * Creates an instance of ContextMenu.
     * 
     * @memberof ContextMenu
     */
    public constructor() {
        this._items = new Array<ContextMenuItem>();
    }

    /**
     * Gets the parent identity.
     * 
     * @returns 
     * 
     * @memberof ContextMenu
     */
    public getParentIdentity(): WindreamIdentity | undefined {
        return this._parentIdentity;
    }

    /**
     * Sets the parent identity.
     * 
     * @param {WindreamIdentity} parentIdentity 
     * 
     * @memberof ContextMenu
     */
    public setParentIdentity(parentIdentity: WindreamIdentity) {
        this._parentIdentity = parentIdentity;
    }

    /**
     * Adds an item to this menu.
     * 
     * @param {ContextMenuItem} item 
     * 
     * @memberof ContextMenu
     */
    public addItem(item: ContextMenuItem) {
        this._items.push(item);
    }

    /**
     * Get all items.
     * 
     * @returns {ContextMenuItem[]} 
     * 
     * @memberof ContextMenu
     */
    public getItems(): ContextMenuItem[] {
        return this._items;
    }
}