import { IIdentitySharer, IIntentHandler } from './interfaces';
import { SHARE_INTENTS } from './models';
import { IIndentitySharerData } from '.';

/**
 * Handle share intents.
 * 
 * @export
 * @class ShareIntentHandler
 * @implements {IIntentHandler}
 */
export class ShareIntentHandler implements IIntentHandler {
    private globalIdentitySharer: IIdentitySharer;

    /**
     * Creates an instance of ShareIntentHandler.
     * @param {IIdentitySharer} globalIdentitySharer IdentitySharer instance to use.
     * @memberof ShareIntentHandler
     */
    public constructor(globalIdentitySharer: IIdentitySharer) {
        this.globalIdentitySharer = globalIdentitySharer;
    }

    /**
     * Checks whether the given intent can be handled.
     * 
     * @param {SHARE_INTENTS} intentType Type of the intent.
     * @returns {boolean} Whether the intent can be handled.
     * @memberof ShareIntentHandler
     */
    public canHandleIntent(intentType: SHARE_INTENTS): boolean {
        return intentType === SHARE_INTENTS.SHARE || intentType === SHARE_INTENTS.RECEIVE;
    }

    /**
     * Handles the intent.
     * 
     * @param {SHARE_INTENTS} intentType Type of the intent.
     * @param {IIndentitySharerData} data Data associated with the intent.
     * @memberof ShareIntentHandler
     */
    public handleIntent(intentType: SHARE_INTENTS, data: IIndentitySharerData): void {
        if (!data.event || !data.identities) {
            throw new ReferenceError('Invalid data object passed');
        }
        if (intentType === SHARE_INTENTS.RECEIVE) {
            this.globalIdentitySharer.receive(data);
        } else if (intentType === SHARE_INTENTS.SHARE) {
            this.globalIdentitySharer.share(data);
        }
    }
}