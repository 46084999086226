import { AddFavoritesRequest } from '../../../../typings/services';
import { IdLocationNameEntityIdentityDTO, ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { AddToFavoritesRequestDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { ServiceError } from '../common';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to add favorites for windream.
 *
 * @export
 * @class AddFavorites
 * @extends {ServiceAction}
 */
export class AddFavorites extends ServiceAction {

    /**
     * Creates an instance of AddFavorites.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global config.
     * @param {Logger} logger The logger.
     * @memberof AddFavorites
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'addFavorites';
    }

    /**
     * Adds the favorites to windream.
     *
     * @param {FavoritesRequest} favoritesRequest The favorites request.
     * @returns {Promise<void>} A promise, which will resolve if the favorites created successful.
     * @memberof AddFavorites
     */
    public async do(favoritesRequest: AddFavoritesRequest): Promise<void> {
        return new Promise<void>((resolve, reject) => {

            const parameter: AddToFavoritesRequestDTO = {
                Items: []
            };
            favoritesRequest.identities?.forEach((identity) => {
                const currentIdentity: IdLocationNameEntityIdentityDTO = {
                    Entity: <number>identity.entity,
                    Id: identity.id ?? 0,
                    Location: identity.location ?? '',
                    Name: identity.name ?? ''
                };
                parameter.Items.push(currentIdentity);
            });

            const httpResourcePointer = new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/favorites/AddToFavorites', parameter);

            this.requestExecutor.executeRequest(httpResourcePointer, favoritesRequest.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
                if (response.data) {
                    if (response.data.HasErrors) {
                        this.logger.error('AddFavorites', 'do', 'Couldn\'t create favorites', response.data);
                        reject(new ServiceError('Couldn\'t create favorites', -1));
                    } else {
                        resolve();
                    }
                }
            }).catch((err: Error) => {
                this.logger.error('AddFavorites', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}