import { UploadFlags } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { WindreamIdentity } from '../../common';
import { FileDataSource } from '../documents';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents upload request options.
 *
 * @export
 * @class UploadRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class UploadRequestOptions extends WindreamRequestOptions {

    /**
     * The data to upload.
     *
     * @type {FileDataSource}
     * @memberof UploadRequestOptions
     */
    public data: FileDataSource;

    /**
     * Flags to apply.
     * Falls back to "CreateObject,ReturnIndexingDetails" if omitted.
     *
     * @type {UploadFlags}
     * @memberof UploadRequestOptions
     */
    public flags?: UploadFlags;

    /**
     * Creates an instance of UploadRequestOptions.
     * @param {WindreamIdentity} identity
     * @param {FileDataSource} data
     * @memberof UploadRequestOptions
     */
    public constructor(identity: WindreamIdentity, data: FileDataSource) {
        super(identity);
        if (!data) {
            throw new Error('Argument \'data\' is not valid');
        }
        this.data = data;
    }
}