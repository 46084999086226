import { GetScriptResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { GetScriptRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform a GetScriptContent on the windream webservice.
 * 
 * @export
 * @class GetScriptContent
 * @extends {ServiceAction}
 */
export class GetScriptContent extends ServiceAction {

    /**
     * Creates an instance of GetScriptContent.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @memberof GetScriptContent
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'getScriptContent';
    }

    /**
     * Gets the script's content as string.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {GetScriptRequestOptions} getScriptRequestOptions The request options.
     * @returns {Promise<GetScriptResponseContainerDTO>} A promise, which will resolve with the server response.
     * @memberof GetScriptContent
     */
    public async do(getScriptRequestOptions: GetScriptRequestOptions): Promise<GetScriptResponseContainerDTO> {
        return new Promise<GetScriptResponseContainerDTO>((resolve, reject) => {
            const url = `${this.globalConfig.windreamWebServiceURL}/dynamicworkspace/scripts/GetScriptContent?parameter.id=${getScriptRequestOptions.scriptId}`;
            this.requestExecutor.executeRequest(new HttpResourcePointer('GET', url),
                getScriptRequestOptions.requestOptions)
                .then((response: IServiceResponse<GetScriptResponseContainerDTO>) => {
                    if (!response.data || response.data.HasErrors) {
                        this.logger.error('GetScriptContent', 'do', 'Failed to get script content', response);
                        reject(new Error('Failed to get script content'));
                    } else {
                        resolve(response.data);
                    }
                }).catch((err) => {
                    this.logger.error('GetScriptContent', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}