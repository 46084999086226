import { ToolbarAction } from '../../../typings/core';
import { DeviceDetection } from '../common';
import { ToolbarActionMetadata } from '../config';
import { DWCore, ILanguageProvider, Utils } from '../dynamicWorkspace';
import { Logger } from '../logging';
import { MigrationComponentContainer } from '../migration';
import { IServiceManager } from '../services';
import { IUiComponentFactory } from '../ui/components';
import { MoreDropdownHandler } from '../ui/moreDropdownHandler';
import { MoreDropdownHandlerFactory } from '../ui/moreDropdownHandlerFactory';
import { ToolbarActionLoader, ToolbarDatasetHandler } from '.';

/**
 * Handles the toolbar for a component.
 *
 * @export
 * @class ComponentToolbarHandler
 */
export class ComponentToolbarHandler {
    private componentContainer: MigrationComponentContainer;
    private toolbarActionLoader: ToolbarActionLoader;
    private moreDropdownHandlerFactory: MoreDropdownHandlerFactory;
    private currentDocument: Document;
    private toolbarActionInstances: Array<ToolbarAction>;
    private logger: Logger;
    private uiComponentFactory: IUiComponentFactory;
    private serviceManager: IServiceManager;
    private moreDropdownHandler?: MoreDropdownHandler;
    private readonly className = 'ComponentToolbarHandler';

    /**
     * Creates an instance of ComponentToolbarHandler.
     * 
     * @param {MigrationComponentContainer} componentContainer The component container.
     * @param {ToolbarActionLoader} toolbarActionLoader The toolbar action loader.
     * @param {Document} currentDocument The current document.
     * @param {MoreDropdownHandlerFactory} moreDropdownHandlerFactory The MoreDropdownHandler factory.
     * @param {Logger} logger The logger.
     * @param {IUiComponentFactory} uiComponentFactory The UI component factory.
     * @param {IServiceManager} serviceManager The service manager.
     * @memberof ComponentToolbarHandler
     */
    public constructor(componentContainer: MigrationComponentContainer,
        toolbarActionLoader: ToolbarActionLoader,
        currentDocument: Document,
        moreDropdownHandlerFactory: MoreDropdownHandlerFactory,
        logger: Logger,
        uiComponentFactory: IUiComponentFactory,
        serviceManager: IServiceManager,
        ) {

        this.componentContainer = componentContainer;
        this.toolbarActionLoader = toolbarActionLoader;
        this.currentDocument = currentDocument;
        this.moreDropdownHandlerFactory = moreDropdownHandlerFactory;
        this.toolbarActionInstances = new Array<ToolbarAction>();
        this.logger = logger;
        this.uiComponentFactory = uiComponentFactory;
        this.serviceManager = serviceManager;
    }

    /**
     * Renders the toolbar.
     *
     * @memberof ComponentToolbarHandler
     */
    public renderToolbar(): void {
        if (this.componentContainer.componentInstanceConfig.toolbar && this.componentContainer.componentInstanceConfig.toolbar.enabled
            && this.componentContainer.componentInstanceConfig.toolbar.actions) {
            this.componentContainer.componentInstanceConfig.toolbar.actions.forEach((action) => {
                if (this.componentContainer.toolbarElement && action.id) {
                    const targetActionContainer = this.currentDocument.createElement('div');
                    const targetActionContainerWrapper = this.currentDocument.createElement('div');
                    targetActionContainerWrapper.classList.add('wd-toolbar-action');
                    this.setToolbarActionStyle(action.displayMode, targetActionContainerWrapper);
                    targetActionContainerWrapper.appendChild(targetActionContainer);
                    this.componentContainer.toolbarElement.appendChild(targetActionContainerWrapper);
                    try {
                        const languageProvider = this.toolbarActionLoader.getLanguageProvider(action.id);
                        if (languageProvider) {

                            // Render the tooltip
                            const toolbarMetadata = this.toolbarActionLoader.getMetadata(action.id);
                            if (toolbarMetadata) {
                                this.renderTooltip(targetActionContainerWrapper, languageProvider, toolbarMetadata);
                            }

                            // Any since we are creating a new class.
                            const classReference = this.toolbarActionLoader.getClassReference(action.id) as any;
                            const classInstance: ToolbarAction = new classReference(languageProvider, DynamicWorkspace);
                            classInstance.render(targetActionContainer);
                            this.toolbarActionInstances.push(classInstance);
                        } else {
                            const errorComponent = this.toolbarActionLoader.getErrorComponent();
                            errorComponent.render(targetActionContainer);
                            this.logger.error(this.className, 'renderToolbar', 'Failed to load language provider');
                        }
                    } catch (error) {
                        const errorComponent = this.toolbarActionLoader.getErrorComponent();
                        errorComponent.render(targetActionContainer);
                        this.logger.error(this.className, 'renderToolbar', 'Error while loading component', error);
                    }
                }
            });
        }
        if (this.componentContainer.toolbarElement) {
            const isHidden = !(this.componentContainer.componentInstanceConfig.toolbar &&
                this.componentContainer.componentInstanceConfig.toolbar.enabled &&
                this.componentContainer.componentInstanceConfig.toolbar.actions &&
                this.componentContainer.componentInstanceConfig.toolbar.actions.length > 0);
            this.componentContainer.toolbarElement.hidden = isHidden;
            if (!isHidden) { // Only add more dropdown if there are actions
                this.moreDropdownHandler = this.moreDropdownHandlerFactory.create(this.componentContainer);
                this.moreDropdownHandler.init();
            }
        }
    }

    /**
     * Set toolbar action style.
     * 
     * @param {DWCore.Components.ToolbarActionDisplayMode} actionDisplayMode .
     * @param {HTMLDivElement} targetActionContainerWrapper .
     * 
     * @memberof ComponentToolbarHandler
     */
    public setToolbarActionStyle(actionDisplayMode: DWCore.Components.ToolbarActionDisplayMode | undefined, targetActionContainerWrapper: HTMLDivElement): void {
        switch (actionDisplayMode) {
            case DWCore.Components.ToolbarActionDisplayMode.Automatically: // Automatic
                // Inherit style from general toolbar config
                this.setToolbarStyle(targetActionContainerWrapper);
                break;
            case DWCore.Components.ToolbarActionDisplayMode.Icon:  // Icon only
                targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-only');
                break;
            case DWCore.Components.ToolbarActionDisplayMode.Text: // Text only
                targetActionContainerWrapper.classList.add('wd-toolbar-action-text-only');
                break;
            case DWCore.Components.ToolbarActionDisplayMode.IconAndText:  // Icon + Text
                targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-text');
                break;
            default:
                targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-text');
                break;
        }
    }

    /**
     * Set toolbar style.
     *
     * @param {HTMLDivElement} targetActionContainerWrapper targed HTML element.
     * @memberof ComponentToolbarHandler
     */
    public setToolbarStyle(targetActionContainerWrapper: HTMLDivElement): void {
        switch (this.componentContainer.componentInstanceConfig.toolbar?.displayMode) {
            case DWCore.Components.ToolbarActionDisplayMode.Automatically: // Automatic
                // Default config based on device type 
                // PC + Tablette: Icon + Text
                // Smartphone: Icon
                if (DeviceDetection.isPhone()){
                    targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-only');
                } else {
                    targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-text');
                }
                break;
            case DWCore.Components.ToolbarActionDisplayMode.Icon: // Icon only
                targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-only');
                break;
            case DWCore.Components.ToolbarActionDisplayMode.Text: // Text only
                targetActionContainerWrapper.classList.add('wd-toolbar-action-text-only');
                break;
            case DWCore.Components.ToolbarActionDisplayMode.IconAndText:  // Icon + Text
                targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-text');
                break;
            default:
                targetActionContainerWrapper.classList.add('wd-toolbar-action-icon-text');
                break;
        }
    }

    /**
     * Re-renders the toolbar, e.g. if the sub view was changed.
     *
     * @memberof ComponentToolbarHandler
     */
    public repaintToolbar(): void {
        if (this.moreDropdownHandler) {
            this.moreDropdownHandler.adjustWidth();
        }
    }

    /**
     * Destroys the toolbar.
     *
     * @memberof ComponentToolbarHandler
     */
    public clearToolbar(): void {
        if (this.componentContainer.toolbarElement) {
            this.componentContainer.toolbarElement.innerHTML = '';
        }
        this.toolbarActionInstances.forEach((action) => {
            try {
                action.destroy();
            } catch {
                this.logger.error(this.className, 'clearToolbar', 'Failed to destroy toolbar action');
            }
        });
        this.toolbarActionInstances.length = 0;
        this.moreDropdownHandler?.destroy();
        this.moreDropdownHandler = undefined;
    }

    /**
     * Create the toolbar dataset handler.
     *
     * @returns {ToolbarDatasetHandler} The handler.
     * @memberof ComponentToolbarHandler
     */
    public createToolbarDatasetHandler(): ToolbarDatasetHandler {
        const toolbarDatasetHandler = new ToolbarDatasetHandler(this.toolbarActionInstances, this.logger, this.serviceManager);
        toolbarDatasetHandler.onDataSetChanged = () => {
            this.moreDropdownHandler?.closeMoreDropdown();
        };
        return toolbarDatasetHandler;
    }

    /**
     * Renders the tooltip.
     *
     * @protected
     * @param {HTMLDivElement} targetElement The target element.
     * @param {string} languageProvider The language provider.
     * @param {ToolbarActionMetadata} toolbarActionMetadata The toolbar action metadata.
     * @memberof ComponentToolbarHandler
     */
    protected renderTooltip(targetElement: HTMLDivElement, languageProvider: ILanguageProvider, toolbarActionMetadata: ToolbarActionMetadata): void {
        // Create tooltip
        const tooltip = this.uiComponentFactory.tooltip(targetElement);
        if (tooltip) {
            let title = '';
            let description = '';
            if (languageProvider) {
                title = languageProvider.get('label', toolbarActionMetadata.name);
                description = languageProvider.get('description', toolbarActionMetadata.description);
            }
            const tooltipContent = `<div>
                                        <h3>${Utils.escapeStringValue(title)}</h3>
                                        ${description}
                                    </div>`;
            tooltip.render(tooltipContent);
        }
    }
}