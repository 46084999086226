import { SearchConditionDTO } from '../../../typings/windreamWebService/Windream.WebService.Search';
import { SearchGroup } from './searchGroup';
import { SEARCH_RELATIONS } from './searchRelations';


/**
 * This class defines the recursion parameter.
 *
 * @export
 * @class SearchModelConverterRecursionParameter
 */
export class SearchModelConverterRecursionParameter {

    /**
     * The amount of possible left brackets.
     *
     * @type {number}
     * @memberof SearchModelConverterRecursionParameter
     */
    public leftBrackets: number;

    /**
     * The relation.
     *
     * @type {SEARCH_RELATIONS}
     * @memberof SearchModelConverterRecursionParameter
     */
    public relation: SEARCH_RELATIONS;

    /**
     * The last converted search condition.
     *
     * @type {SearchConditionDTO}
     * @memberof SearchModelConverterRecursionParameter
     */
    public lastConvertedSearchCondition?: SearchConditionDTO;

    /**
     * The placeholder values.
     *
     * @type {(Map<string, string | number | Date | boolean>)}
     * @memberof SearchModelConverterRecursionParameter
     */
    public placeholderValues: Map<string, string | number | Date | boolean>;

    /**
     * The condition placeholder values.
     *
     * @type {Map<string, SearchGroup>}
     * @memberof SearchModelConverterRecursionParameter
     */
    public conditionPlaceholderValues: Map<string, SearchGroup>;


    /**
     * Creates an instance of SearchModelConverterRecursionParameter.
     * @memberof SearchModelConverterRecursionParameter
     */
    public constructor() {
        this.leftBrackets = 0;
        this.relation = SEARCH_RELATIONS.Undefined;
        this.placeholderValues = new Map<string, string | number | Date | boolean>();
        this.conditionPlaceholderValues = new Map<string, SearchGroup>();
    }

}