import { ArgumentError } from './argumentError';
/**
 * 	Thrown by methods that do not allow an argument to be undefined.
 * 
 * @export
 * @class ArgumentUndefinedError
 * @extends {Error}
 */
export class ArgumentUndefinedError extends ArgumentError {

    /**
     * Creates an instance of ArgumentUndefinedError.
     * @param {string} [message] 
     * 
     * @memberof ArgumentUndefinedError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'ArgumentUndefinedError';
    }
}