import { IRouter } from '.';

/**
 * System settings router will handle the route for /systemsettings.
 *
 * @export
 * @class SystemSettingsRouter
 * @implements {IRouter}
 */
export class SystemSettingsRouter implements IRouter {

    private onSystemSettingsNavigationCallbacks: ((path: string) => void)[];

    /**
     * Creates an instance of SystemSettingsRouter.
     * 
     * @memberof SystemSettingsRouter
     */
    public constructor() {
        this.onSystemSettingsNavigationCallbacks = new Array<() => void>();
    }

    /**
     * Routes the specific route.
     *
     * @param {string} path The url path to route.
     * @returns {boolean} Whether the navigation was a success.
     * @memberof SystemSettingsRouter
     */
    public route(path: string): boolean {
        this.onSystemSettingsNavigationCallbacks.forEach((callback) => {
            callback(path);
        });
        return true;
    }

    /**
     * Gets the route pattern.
     *
     * @returns {RegExp} The route pattern.
     * @memberof SystemSettingsRouter
     */
    public getRoutePattern(): RegExp {
        return new RegExp('\/systemsettings$|\/systemsettings\?.*$', 'i');
    }

    /**
     * Register a callback which will be called if a system settings navigation occured.
     *
     * @param {(path: string) => void} callback The callback.
     * @memberof SystemSettingsRouter
     */
    public registerOnSystemSettingsNavigation(callback: (path: string) => void): void {
        this.onSystemSettingsNavigationCallbacks.push(callback);
    }
}