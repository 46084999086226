import { Utils } from '../common';
import { ITemplateExtension } from '../extensions';
import { IToolstripHandler } from './interfaces';
import { IActiveBarHelperFactory, ToolstripOption } from '.';

/**
 * Class that handles the toolstrip.
 * 
 * @export
 * @class ToolstripHandler
 * @implements {IToolstripHandler}
 */
export class ToolstripHandler implements IToolstripHandler {

    private element: HTMLElement;
    private templateExtension: ITemplateExtension;
    private activeBarHelperFactory: IActiveBarHelperFactory;

    private options: ToolstripOption[];
    private isInitialized: boolean;

    /**
     * Creates an instance of ToolstripHandler.
     * @param {HTMLElement} element 
     * @param {ITemplateExtension} templateExtension 
     * @param {IActiveBarHelperFactory} activeBarHelperFactory 
     * @memberof ToolstripHandler
     */
    public constructor(element: HTMLElement, templateExtension: ITemplateExtension, activeBarHelperFactory: IActiveBarHelperFactory) {
        this.element = element;
        this.templateExtension = templateExtension;
        this.activeBarHelperFactory = activeBarHelperFactory;

        this.options = new Array<ToolstripOption>();
        this.isInitialized = false;
    }

    /**
     * Renders the toolstrip.
     * 
     * @param {ToolstripOption[]} options Options used to render.
     * @memberof ToolstripHandler
     */
    public render(options: ToolstripOption[]): void {
        this.options.length = 0;
        this.options.push(...options);
        this.templateExtension.render(this.element, require('./templates/toolstrip.html'), this.options, true);
        this.activeBarHelperFactory.create(this.element);

        if (!this.isInitialized) {
            // Only add event listener once
            this.element.addEventListener('click', (e: Event) => {
                const _index = Utils.getWdAttribute(e.target as HTMLElement, 'index');
                if (_index) {
                    const index = parseInt(_index, 10);
                    const option = this.options[index];
                    if (option && typeof option.onClick === 'function') {
                        option.onClick();
                    }
                    this.updateActiveIndex(index);
                }
            });
        }
        this.isInitialized = true;
    }

    /**
     * Updates the active index.
     * De-selects previously active option. Selects option with given index.
     * Re-renders the toolstrip.
     * 
     * @param {number} index Index of the new option to select.
     * @memberof ToolstripHandler
     */
    public updateActiveIndex(index: number): void {
        const previouslySelectedOption = this.options.find((option) => option.selected);
        if (previouslySelectedOption) {
            previouslySelectedOption.selected = false;
        }
        const newSelectedOption = this.options[index];
        if (newSelectedOption) {
            newSelectedOption.selected = true;
        }
        this.templateExtension.render(this.element, require('./templates/toolstrip.html'), this.options, true);
    }
}