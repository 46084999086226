import { PublishParameter } from './publishParameter';

/**
 * This class represents a PubSub operation parameter.
 *
 * @export
 * @class PubSubOperationParameter
 */
export class PubSubOperationParameter {

    /**
     * The input publish parameter.
     *
     * @type {PublishParameter}
     * @memberof PubSubOperationParameter
     */
    public in: PublishParameter;

    /**
     * The output publish parameter.
     *
     * @type {PublishParameter}
     * @memberof PubSubOperationParameter
     */
    public out: PublishParameter;


    /**
     * Creates an instance of PubSubOperationParameter.
     *
     * @param {PublishParameter} inParameter The input publish parameter.
     * @param {PublishParameter} outParameter The output publish parameter.
     * @memberof PubSubOperationParameter
     */
    public constructor(inParameter: PublishParameter, outParameter: PublishParameter) {
        this.in = inParameter;
        this.out = outParameter;
    }

}