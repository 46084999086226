import { Utils } from '../common';
import { ComponentConfig, ComponentInstanceConfig, IConfigTranslation } from '../config';
import { CultureHelper } from '../culture';
import { Logger } from '../logging';
import { IMultilingualTranslator } from './interfaces';

/**
 * Translates multilingual objects to to current user language.
 * 
 * @export
 * @class MultilingualTranslator
 * @implements {IMultilingualTranslator}
 */
export class MultilingualTranslator implements IMultilingualTranslator {
    private logger: Logger;
    private cultureHelper: CultureHelper;

    private componentConfigs?: Map<string, ComponentConfig>;
    /**
     * Creates an instance of MultilingualTranslator.
     * @param {Logger} logger 
     * @param {CultureHelper} cultureHelper 
     * @memberof MultilingualTranslator
     */
    public constructor(logger: Logger, cultureHelper: CultureHelper) {
        this.logger = logger;
        this.cultureHelper = cultureHelper;
    }

    /**
     * Sets the list of available component configurations.
     * 
     * @param {Map<string, ComponentConfig>} componentConfigs List of available component configurations.
     * @memberof MultilingualTranslator
     */
    public setComponentConfigs(componentConfigs: Map<string, ComponentConfig>): void {
        this.componentConfigs = componentConfigs;
    }

    /**
     * Returns the given component instance config with all multilingual values mapped to the current language using the LanguageProvider.
     * If the component passed is not part of the available components or if available components have not been set yet, the incommong instance config is returned.
     * 
     * @param {ComponentInstanceConfig} componentInstanceConfig Component instance config to translate.
     * @returns {ComponentInstanceConfig} The translated component instance config.
     * @memberof MultilingualTranslator
     */
    public getTranslatedComponentInstanceConfig(componentInstanceConfig: ComponentInstanceConfig): ComponentInstanceConfig {
        if (!this.componentConfigs) {
            this.logger.warn('MultilingualTranslator', 'getTranslatedComponentInstanceConfig', 'No ComponentConfigs set. Returning incomming config', componentInstanceConfig);
            return componentInstanceConfig;
        }
        if (!componentInstanceConfig.component) {
            this.logger.warn('MultilingualTranslator', 'getTranslatedComponentInstanceConfig', 'No ComponentInstsanceConfig has no component defined', componentInstanceConfig);
            return componentInstanceConfig;
        }
        const componentConfig = this.componentConfigs.get(componentInstanceConfig.component);
        if (!componentConfig) {
            this.logger.warn('MultilingualTranslator', 'getTranslatedComponentInstanceConfig', 'No ComponentConfig found for component. Returning incomming config', componentInstanceConfig);
            return componentInstanceConfig;
        }

        const translatedComponentInstanceConfig = Utils.Instance.deepClone<ComponentInstanceConfig>(componentInstanceConfig);

        // Translate each configuration if it is a multilingual one.
        componentConfig.configuration.forEach((configuration) => {
            if (configuration.isMultilingual) {
                if (translatedComponentInstanceConfig.configuration && configuration.name && componentInstanceConfig.configuration[configuration.name]) {
                    const value = translatedComponentInstanceConfig.configuration[configuration.name];
                    if (this.isMultilingualValue(value)) {
                        translatedComponentInstanceConfig.configuration[configuration.name] = this.getTranslationFromProperty<any>(value);
                    } else if (typeof value === 'object' && Object.keys(value).length === 0) {
                        translatedComponentInstanceConfig.configuration[configuration.name] = undefined;
                    }
                }
            }
        });
        return translatedComponentInstanceConfig;
    }

    /**
     * Gets the translation string or undefined from a config translation.
     * 
     * @template T The type of the property.
     * @param {IConfigTranslation<T>} property The property to translate.
     * @returns {T} The property value in the current language.
     * @memberof MultilingualTranslator
     */
    public getTranslationFromProperty<T>(property: IConfigTranslation<T>): T {
        // Get preferred language if it exist
        if (typeof property[this.cultureHelper.getCurrentLanguage()] !== 'undefined' && !this.isEmptyValue(property[this.cultureHelper.getCurrentLanguage()])) {
            return property[this.cultureHelper.getCurrentLanguage()];
        }
        // Check available languages in order of preference
        for (const language of this.cultureHelper.availableLanguages) {
            if (typeof property[language] !== 'undefined' && !this.isEmptyValue(property[language])) {
                return property[language];
            }
        }
        // If no available language exist, take any non-empty value
        for (const key in property) {
            if (typeof property[key] !== 'undefined' && !this.isEmptyValue(property[key])) {
                return property[key];
            }
        }
        // If no non-empty value exists, take any value
        for (const key in property) {
            if (typeof property[key] !== 'undefined') {
                return property[key];
            }
        }
        throw new Error('No translation found');
    }

    /**
     * Checks whether the given value is a multilingual value.
     * 
     * @param {*} value Value to check.
     * @returns {value is IConfigTranslation<any>} Whether the value is a multilingual value.
     * @memberof MultilingualTranslator
     */
    public isMultilingualValue(value: any): value is IConfigTranslation<any> {
        return typeof value === 'object' && Object.keys(value).length > 0;
    }

    /**
     * Checks if the given value is empty or not.
     * 
     * @private
     * @param {*} value Value to check.
     * @returns {boolean} Whether the value is empty.
     * @memberof MultilingualTranslator
     */
    private isEmptyValue(value: any): boolean {
        if (typeof value === 'string' && value === '') {
            return true;
        }
        return false;
    }
}