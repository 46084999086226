import { DWCore } from '@windream/dw-core/dwCore';
import { SubViewType } from '../../../typings/core';
import { DeviceDetection } from '../common/deviceDetection';
import {
    ComponentLoadManifestFactory, IComponentNameProvider,
    IComponentLoader, ComponentContainer, IComponentManager, ComponentLoadManifest
} from '../components';
import { ComponentInstanceConfig, IConfigLoader, ViewConfig } from '../config';
import { IExtensionProvider } from '../extensions';
import { ILanguageManager } from '../language';
import { Changes, IChangePromoter, IViewLifecycleManager, IViewLifecycleManagerFactory } from '../lifecycle';
import { Logger } from '../logging/logger';
import { MigrationComponentContainer, MigrationHelper } from '../migration';
import { IPubSubHandler, IPubSubHandlerFactory, PubSubConfig, PubSubEventNameCollection } from '../pubSub';
import { IStaticPageHelper } from '../staticPage';
import { ToolbarActionLoader } from '../toolbar/toolbarActionLoader';
import { IStyleManager, NotificationHelper } from '../ui';
import { IViewManager } from './interfaces';
import { ISubViewManager, IUiManager, ViewInitializationOptions } from '.';

/**
 * View manager that handles a view.
 * 
 * @export
 * @class ViewManager
 * @implements {IViewManager}
 */
export class ViewManager implements IViewManager, IChangePromoter {
    private componentNameProvider: IComponentNameProvider;
    private currentViewConfig?: ViewConfig;
    private componentLoader: IComponentLoader;
    private subViewManager: ISubViewManager;
    private configLoader: IConfigLoader;
    private languageManager: ILanguageManager;
    private uiManager: IUiManager;
    private viewLifecycleManagerFactory: IViewLifecycleManagerFactory;
    private currentViewLifecycleManager?: IViewLifecycleManager;
    private pubSubHandlerFactory: IPubSubHandlerFactory;
    private currentViewPubSubHandler?: IPubSubHandler;
    private extensionProvider: IExtensionProvider;
    private className: string = 'ViewManager';
    private staticPageHelper: IStaticPageHelper;
    private componentManager: IComponentManager;
    private logger: Logger;
    private styleManager: IStyleManager;
    private toolbarActionLoader: ToolbarActionLoader;
    private componentLoadManifestFactory: ComponentLoadManifestFactory;
    private currentComponentContainers = new Array<ComponentContainer>();


    /**
     * Creates an instance of ViewManager.
     * @param {IViewLifecycleManagerFactory} viewLifecycleManagerFactory 
     * @param {IComponentLoader} componentLoader 
     * @param {IUiManager} uiManager 
     * @param {ISubViewManager} subViewManager 
     * @param {IConfigLoader} configLoader 
     * @param {IPubSubHandlerFactory} pubSubHandlerFactory 
     * @param {ILanguageManager} languageManager 
     * @param {IExtensionProvider} extensionProvider 
     * @param {IStaticPageHelper} staticPageHelper 
     * @param {IComponentManager} componentManager 
     * @param {IStyleManager} styleManager 
     * @param {Logger} logger 
     * @param {ToolbarActionLoader} toolbarActionLoader
     * @param {ComponentLoadManifestFactory} componentLoadManifestFactory
     * @memberof ViewManager
     */
    public constructor(viewLifecycleManagerFactory: IViewLifecycleManagerFactory, componentLoader: IComponentLoader,
        uiManager: IUiManager, subViewManager: ISubViewManager, configLoader: IConfigLoader, pubSubHandlerFactory: IPubSubHandlerFactory,
        languageManager: ILanguageManager, extensionProvider: IExtensionProvider, staticPageHelper: IStaticPageHelper, componentManager: IComponentManager,
        styleManager: IStyleManager, componentNameProvider: IComponentNameProvider, logger: Logger, toolbarActionLoader: ToolbarActionLoader,
        componentLoadManifestFactory: ComponentLoadManifestFactory) {

        this.componentLoader = componentLoader;
        this.subViewManager = subViewManager;
        this.configLoader = configLoader;
        this.pubSubHandlerFactory = pubSubHandlerFactory;
        this.languageManager = languageManager;
        this.uiManager = uiManager;
        this.viewLifecycleManagerFactory = viewLifecycleManagerFactory;
        this.extensionProvider = extensionProvider;
        this.staticPageHelper = staticPageHelper;
        this.logger = logger;
        this.componentManager = componentManager;
        this.styleManager = styleManager;
        this.toolbarActionLoader = toolbarActionLoader;
        this.componentNameProvider = componentNameProvider;
        this.componentLoadManifestFactory = componentLoadManifestFactory;
        this.subViewManager.onSubViewChanged((subViewId, subViewType) => this.onSubViewChanged(subViewId, subViewType));
        this.subViewManager.onSubViewChanging((subViewId, subViewType) => this.onSubViewChanging(subViewId, subViewType));
    }

    /**
     * Adds a component name to the list of used component names.
     * 
     * @param {string} componentName 
     * 
     * @memberof ViewManager
     */
    public addUsedComponentName(componentName: string) {
        this.componentNameProvider.addUsedComponentName(componentName);
    }

    /**
     * Returns a unique name for the component.
     * 
     * @param {string} componentName 
     * @returns {string} 
     * 
     * @memberof ViewManager
     */
    public getUniqueComponentName(componentName: string): string {
        const result = this.componentNameProvider.getUniqueComponentName(componentName);
        this.addUsedComponentName(result);
        return result;
    }


    /**
     * Returns all used component names.
     * 
     * @returns {string[]} 
     * 
     * @memberof ViewManager
     */
    public getUsedComponentNames(): string[] {
        return this.componentNameProvider.getUsedComponentNames();
    }

    /**
     * Removes a used component name.
     * 
     * @param {string} componentName 
     * 
     * @memberof ViewManager
     */
    public removeUsedComponentName(componentName: string) {
        this.componentNameProvider.removeUsedComponentName(componentName);
    }

    /**
     * Promotes the given changes to each component on the view.
     * 
     * @param {Change} changes Changes that occured.
     * @memberof ViewManager
     */
    public promoteChanges(changes: Changes): void {
        if (!this.currentViewLifecycleManager) {
            this.logger.error(this.className, 'promoteChanges', 'No current ViewLifecycleManager set');
            return;
        }
        this.currentViewLifecycleManager.refreshAll(changes).catch((err: Error) => {
            this.logger.error('ViewManager', 'promoteChanges', 'Failed to promote changes', err);
        });
    }

    /**
     * Initializes the view with the given ID.
     * 
     * @param {string} viewId  ID of the view to initialize.
     * @param {ViewInitializationOptions} options The view initialization options.
     * @param {DWCore.Common.Devices} [device]    Device to init the view for. Will be automatically set to current device if omitted.
     * @returns {Promise<ViewConfig>}  Promise to resolve with the view Config of the initialized view.
     * @async
     * 
     * @memberof ViewManager
     */
    public async initView(viewId: string, options: ViewInitializationOptions, device?: DWCore.Common.Devices): Promise<ViewConfig> {
        const currentDevice: DWCore.Common.Devices = device || DeviceDetection.getCurrentDevice(); // Automatically determine current device if parameter has been omitted.
        // Destroy the previous lifecycle
        if (this.currentViewLifecycleManager) {
            this.currentViewLifecycleManager.destroyAll().catch((err: Error) => {
                this.logger.error('ViewManager', 'initView', 'Failed to destroy all components', err);
            });
        }
        this.subViewManager.clear();
        this.componentManager.deinitialize();

        this.uiManager.appIsLoading();

        // Load configuration
        return this.configLoader.loadViewConfig(viewId, currentDevice).then(async (viewConfig: ViewConfig) => {
            return this.setupView(viewConfig, currentDevice, options);
        }).catch(async (err: Error) => {
            const languageProvider = this.languageManager.getLanguageProvider('framework');
            this.styleManager.removeStyles();
            NotificationHelper.Instance.error({
                body: languageProvider.get('framework.config.errorloadingviewconfiguration'),
                title: languageProvider.get('framework.generic.error')
            });
            this.staticPageHelper.renderError(require('../staticPage/templates/viewNotFound.html'));
            this.uiManager.appIsAvailable();
            return Promise.reject(err);
        });
    }

    /**
     * Removes a Component Instance from the view.
     * 
     * @param {string} guid GUID of the Component Instance to remove.
     * 
     * @memberof ViewManager
     */
    public removeComponent(guid: string): void {
        if (!this.currentViewLifecycleManager) {
            this.logger.error(this.className, 'removeComponent', 'No current ViewLifecycleManager set');
            return;
        }
        this.currentViewLifecycleManager.removeComponentByGuid(guid);
    }

    /**
     * Adds a Component to the current view.
     * 
     * @param {ComponentInstanceConfig} componentInstanceConfig Configuration to use for new component.
     * @param {IPubSubHandler} [pubSubHandler]  PubSub Handler to use, if not provided the view PubSubHandler is used.
     * @param {boolean} [renderComponent=true] Whether the component should be promoted to the render phase. Default is true.
     * @returns {Promise<boolean>}  Promise to resolve after component has been loaded.
     * @async
     * 
     * @memberof ViewManager
     */
    public async addComponent(componentInstanceConfig: ComponentInstanceConfig, pubSubHandler?: IPubSubHandler, renderComponent: boolean = true): Promise<boolean> {
        const pubSubHandlerToUse = pubSubHandler || this.currentViewPubSubHandler;
        if (!pubSubHandlerToUse) {
            this.logger.error(this.className, 'addComponent', 'No PubSubHandler to use set');
            return Promise.resolve(false);
        }
        return this.componentManager.addComponent(componentInstanceConfig, pubSubHandlerToUse, renderComponent).then((componentContainer: ComponentContainer) => {
            if (!this.currentViewLifecycleManager) {
                this.logger.error(this.className, 'addComponent', 'No current ViewLifecycleManager set');
                return false;
            }
            this.currentViewLifecycleManager.addComponents([componentContainer]);
            return true;
        });
    }

    /**
     * Updates current view PubSub Handler with new configuration.
     * 
     * @param {PubSubConfig[]} pubSubConfig   New configuration to use.
     * @param {*} triggers  Trigger configuration to use.
     * 
     * @memberof ViewManager
     */
    public updateViewPubSubHandler(pubSubConfig: PubSubConfig[], triggers: any): void {
        if (!this.currentViewPubSubHandler) {
            this.logger.error(this.className, 'updateViewPubSubHandler', 'No current PubSubHandler for view set');
            return;
        }
        this.currentViewPubSubHandler.init(pubSubConfig);
        this.currentViewPubSubHandler.setTriggerHandler(triggers);
    }

    /**
     * Re-initializes the component with the given GUID.
     * Should only be used for components within the view.
     * 
     * @param {string} guid
     * @param {ComponentInstanceConfig} componentInstanceConfig GUID of the component to re-initialize.
     * 
     * @memberof ViewManager
     */
    public reInitViewComponentByGuid(guid: string, componentInstanceConfig: ComponentInstanceConfig): void {
        if (!this.currentViewLifecycleManager) {
            this.logger.error(this.className, 'reInitViewComponentByGuid', 'No current ViewLifecycleManager set');
            return;
        }
        this.currentViewLifecycleManager.updateComponentInstanceConfigByGuid(guid, componentInstanceConfig, true, this.currentViewPubSubHandler, this.uiManager, this.extensionProvider);
    }

    /**
     * Re-initializes the current view by performing lifecycle steps for each component.
     *
     *
     * @memberof ViewManager
     */
    public reInitView(): void {
        if (!this.currentViewLifecycleManager) {
            this.logger.error(this.className, 'reInitView', 'No current ViewLifecycleManager set');
            return;
        }
        this.currentViewLifecycleManager.destroyAll()
            // Re-set templates
            .then(async () => this.componentManager.resetAllComponentTemplates(this.currentViewConfig))
            .then(async () => {
                if (!this.currentViewLifecycleManager) {
                    this.logger.error(this.className, 'reInitView', 'No current ViewLifecycleManager set');
                    return;
                }
                return this.currentViewLifecycleManager.promoteAll(this.currentViewPubSubHandler, this.uiManager, this.extensionProvider);
            }).catch((err: Error) => {
                NotificationHelper.Instance.error({ body: this.languageManager.getLanguageProvider('framework').get('framework.component.resetTemplatesErrors') });
                this.logger.error('ViewManager', 'reInitView', 'Failed to re-set component templates', err);
            });
    }

    /**
     * Updates the config of this view.
     * 
     * @param {ViewConfig} viewConfig New view configuration to use.
     * 
     * @memberof ViewManager
     */
    public updateViewConfig(viewConfig: ViewConfig): void {
        if (!this.currentViewLifecycleManager) {
            this.logger.error(this.className, 'updateViewConfig', 'No current ViewLifecycleManager set');
            return;
        }
        viewConfig.components.forEach((component: ComponentInstanceConfig) => {
            if (!this.currentViewLifecycleManager) {
                this.logger.error(this.className, 'updateViewConfig', 'No current ViewLifecycleManager set');
                return;
            }
            if (!component.guid) {
                this.logger.error(this.className, 'updateViewConfig', 'ComponentInstanceConfig has no GUID set', component);
                return;
            }
            this.currentViewLifecycleManager.updateComponentInstanceConfigByGuid(component.guid, component, false);
        });
        this.componentNameProvider.clearUsedComponentNames();
        viewConfig.components.forEach((componentInstanceConfig) => {
            // Add the new component name to the already used names
            for (const lang in componentInstanceConfig.name) {
                if (!componentInstanceConfig.name.hasOwnProperty(lang)) {
                    continue;
                }
                const name = componentInstanceConfig.name[lang];
                this.addUsedComponentName(name);
            }
        });
    }

    /**
     * Applies the given style to the view.
     * Will remove any previously set style.
     * 
     * @param {string} styleClassName Style to apply.
     * @memberof ViewManager
     */
    public updateLayoutStyle(styleClassName: string): void {
        this.styleManager.updateStyle(styleClassName);
    }

    /**
     * Returns the current view config.
     * 
     * @returns {(ViewConfig | null)}
     * 
     * @memberof ViewManager
     */
    public getCurrentViewConfig(): ViewConfig | null {
        if (!this.currentViewConfig) {
            return null;
        }
        return this.currentViewConfig;
    }

    /**
     * Unloads the current view.
     * 
     * 
     * @memberof ViewManager
     */
    public unload(): void {
        if (this.currentViewLifecycleManager) {
            this.currentViewLifecycleManager.destroyAll().catch((err: Error) => {
                this.logger.error('ViewManager', 'unload', 'Failed to destroy all components', err);
            });
        }
    }


    /**
     * Display skeletons for each component.
     * Will destroy the components beforehand.
     * @param {boolean} isInConfigMode Whether the skeleton is displayed in config mode or as a default skeleton.
     *
     * @memberof ViewManager
     */
    public showSkeletons(isInConfigMode: boolean): void {
        if (!this.currentViewLifecycleManager) {
            this.logger.error(this.className, 'showSkeletons', 'No current ViewLifecycleManager set');
            return;
        }
        this.currentViewLifecycleManager.destroyAll().then(async () => {
            if (!this.currentViewLifecycleManager) {
                this.logger.error(this.className, 'showSkeletons', 'No current ViewLifecycleManager set');
                return;
            }
            return this.currentViewLifecycleManager.showSkeletons(isInConfigMode).catch((err: Error) => {
                this.logger.error('ViewManager', 'promoteChanges', 'Failed to show skeletons', err);
            });
        }).catch((err: Error) => {
            this.logger.error('ViewManager', 'showSkeletons', 'Failed to destroy components', err);
        });
    }

    /**
     * Removes the overlay container from every component (loading, error, placeholder).
     *
     * @memberof ViewManager
     */
    public removeAllComponentOverlayContainers(): void {
        if (!this.currentViewConfig) {
            this.logger.error(this.className, 'removeAllComponentOverlayContainers', 'No current ViewConfig set');
            return;
        }
        this.currentViewConfig.components.forEach((component) => {
            if (!component.guid) {
                this.logger.error(this.className, 'updateViewConfig', 'ComponentInstanceConfig has no GUID set', component);
                return;
            }
            this.uiManager.removeError(component.guid);
            this.uiManager.removePlaceholder(component.guid);
            this.uiManager.isAvailable(component.guid);
            this.uiManager.removeBanner(component.guid);
        });
    }

    /**
     * Trigger the on subview changed lifecycle.
     *
     * @private
     * @param {string} activeSubViewId The current active subView.
     * @param {SubViewType} subViewType The sub view type.
     * @memberof ViewManager
     */
    private onSubViewChanged(activeSubViewId: string, subViewType: SubViewType): void {
        if (this.currentViewLifecycleManager && this.currentViewConfig) {
            const currentSubView = this.currentViewConfig.subViews.find((subViewConfig) => subViewConfig.id === activeSubViewId);
            if (currentSubView) {
                this.currentViewLifecycleManager.subViewChanged(currentSubView.components, subViewType).then(() => {
                    this.currentComponentContainers.forEach((componentContainer) => {
                        // Render toolbar again after subview change.
                        componentContainer.componentToolbarHandler.repaintToolbar();
                    });
                }).catch((err) => {
                    this.logger.error(this.className, 'onSubViewChanged', 'Failed to rerender toolbars', err);
                });
            }
        }
    }

    /**
     * Trigger the on subview changing lifecycle.
     *
     * @private
     * @param {string} nextActiveSubViewId The next active subView.
     * @param {SubViewType} subViewType The sub view type.
     * @memberof ViewManager
     */
    private onSubViewChanging(nextActiveSubViewId: string, subViewType: SubViewType): void {
        if (this.currentViewLifecycleManager && this.currentViewConfig) {
            const currentSubView = this.currentViewConfig.subViews.find((subViewConfig) => subViewConfig.id === nextActiveSubViewId);
            if (currentSubView) {
                this.currentViewLifecycleManager.subViewChanging(currentSubView.components, subViewType).catch((err) => {
                    this.logger.error(this.className, 'onSubViewChanging', 'Failed to call subViewChanging', err);
                });
            }
        }
    }

    /**
     * Sets up the view with the given configuration by loading components and setting up everything.
     * 
     * @private
     * @param {ViewConfig} viewConfig ViewConfig to load.
     * @param {DWCore.Common.Devices} currentDevice The current device to load for.
     * @returns {Promise<ViewConfig>} The view configuration.
     * @async
     * 
     * @memberof ViewManager
     */
    private async setupView(viewConfig: ViewConfig, currentDevice: DWCore.Common.Devices, options: ViewInitializationOptions): Promise<ViewConfig> {
        return new Promise<ViewConfig>((resolve, reject) => {
            this.logger.info(this.className, 'setupView', 'View Config', viewConfig);
            this.currentViewLifecycleManager = this.viewLifecycleManagerFactory.create();
            this.currentViewPubSubHandler = this.pubSubHandlerFactory.create(true);
            this.currentComponentContainers.length = 0;

            this.currentViewConfig = viewConfig;
            // Set up used component names
            this.componentNameProvider.clearUsedComponentNames();
            let toolbarsToLoad = new Array<string>();
            this.currentViewConfig.components.forEach((component) => {
                // Add the new component name to the already used names
                for (const lang in component.name) {
                    if (!component.name.hasOwnProperty(lang)) {
                        continue;
                    }
                    const name = component.name[lang];
                    this.addUsedComponentName(name);
                }
                if (component.toolbar && component.toolbar.actions) {
                    component.toolbar.actions.forEach((toolbar) => {
                        toolbarsToLoad.push(toolbar.id);
                    });
                }
            });
            toolbarsToLoad = toolbarsToLoad.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            const componentsToLoad = this.componentLoader.getRequiredComponentsToPrefetch(this.currentViewConfig);
            const componentsToLoadManifests: Map<string, ComponentLoadManifest> = new Map<string, ComponentLoadManifest>();

            componentsToLoad.forEach(async (component) => {
                componentsToLoadManifests.set(component, await this.componentLoadManifestFactory.generateLoadManifestForId(component));
            });

            // Load components and toolbars at the same time to improve performance
            Promise.all([this.componentLoader.loadComponentsData(Array.from(componentsToLoadManifests.values())),
            this.toolbarActionLoader.load(toolbarsToLoad)]).then(() => {
                if (!this.currentViewConfig || !this.currentViewPubSubHandler) {
                    reject(new Error('Failed to setup view'));
                    return;
                }
                // Make sure ViewConfigs are valid
                this.currentViewConfig.subViews = this.subViewManager.validateSubViewConfigs(this.currentViewConfig.subViews);
                // Load Views
                this.subViewManager.createSubViews(this.currentViewConfig.subViews);
                this.subViewManager.prepareSubViews(currentDevice);

                // Configure Lifecycle Manager
                this.currentViewPubSubHandler.loadPubSub(this.currentViewConfig.pubSub);
                this.currentViewConfig.pubSub = MigrationHelper.deleteSelfConnectedPubSubs(this.currentViewConfig.pubSub);
                this.currentViewPubSubHandler.setTriggerHandler(this.currentViewConfig.triggers);

                // Set initial style
                if (this.currentViewConfig?.style?.colors) {
                    this.updateLayoutStyle(this.currentViewConfig.style.colors);
                } else { // Default
                    this.updateLayoutStyle('');
                }
                // Get all pubsub event names
                const allPubSubEventNames = new Map<string, PubSubEventNameCollection>();
                this.currentViewConfig.components.forEach((component) => {
                    if (!component.guid) {
                        this.logger.error(this.className, 'setupView', 'ComponentInstanceConfig has no GUID set', component);
                        return;
                    }
                    if (!this.currentViewPubSubHandler) {
                        this.logger.error(this.className, 'setupView', 'No current PubSubHandler for the view found');
                        return;
                    }
                    allPubSubEventNames.set(component.guid, new PubSubEventNameCollection(component.guid,
                        this.currentViewPubSubHandler.getSpecificPublisherEvents(component.guid), this.currentViewPubSubHandler.getAllSubscriptionsSpecificEvents(component.guid)));
                });
                this.componentManager.setMigrationPubSubs(allPubSubEventNames);
                // Load components
                this.componentManager.addComponents(this.subViewManager.getComponentsPerSubView(), this.subViewManager.getLayoutManagersPerSubView(), this.currentViewConfig.components)
                    .then((migratedInstances: MigrationComponentContainer[]) => {
                        this.subViewManager.initializeSubViews();
                        if (!this.currentViewConfig) {
                            this.logger.error(this.className, 'setupView', 'No current ViewConfig found');
                            return;
                        }
                        if (!this.currentViewPubSubHandler) {
                            this.logger.error(this.className, 'setupView', 'No current PubSubHandler for the view found');
                            return;
                        }
                        if (!this.currentViewLifecycleManager) {
                            this.logger.error(this.className, 'setupView', 'No current LifecycleManager for the view found');
                            return;
                        }
                        this.currentViewConfig.pubSub = MigrationHelper.migratePubSubConfig(this.currentViewConfig.pubSub, migratedInstances);
                        this.currentViewConfig.triggers = MigrationHelper.migrateTriggers(this.currentViewConfig.triggers, migratedInstances);
                        this.currentViewPubSubHandler.init(this.currentViewConfig.pubSub);
                        const componentContainers = this.componentManager.createComponentContainers(migratedInstances, this.currentViewConfig.components);
                        this.currentComponentContainers.push(...componentContainers);
                        this.currentViewLifecycleManager.addComponents(componentContainers);
                        if (options && options.promoteLifecycleSteps) {
                            this.currentViewLifecycleManager.promoteAll(this.currentViewPubSubHandler, this.uiManager, this.extensionProvider).then(() => {
                                this.currentComponentContainers.forEach((componentContainer) => {
                                    // Render toolbar again after all lifecycle steps.
                                    componentContainer.componentToolbarHandler.repaintToolbar();
                                });
                            }).catch((err) => {
                                this.logger.error(this.className, 'setupView', 'Failed to promote all components for view: ' + viewConfig.id, err);
                            });
                        }

                        this.uiManager.appIsAvailable();
                        resolve(viewConfig);
                    }).catch((err) => {
                        this.logger.error(this.className, 'setupView', 'Failed to setup view: ' + viewConfig.id, err);
                        reject(err);
                    });
            }).catch((error) => {
                this.logger.error(this.className, 'setupView', 'Failed to setup view: ' + viewConfig.id, error);
                reject(error);
            });
        });
    }

    /**
     * Get context menu name from ViewConfig
     *
     * @public
     * @returns {string}
     * @memberof ViewManager
     */
    public getCurrentViewContextMenuName(): string {
        const currentViewConfig = this.getCurrentViewConfig();
        return currentViewConfig?.contextMenu ?? 'Standard';
    }
}