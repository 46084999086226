/**
 * The class reflector will check if a uncertain class is a reflection of a typed class i.e. same properties and methods.
 *
 * @export
 * @class ClassReflector
 * @template T
 */
export class ClassReflector<T extends object> {

    private classToReflect: T | undefined;
    private interfacePropsToReflect: Map<(keyof T), string> | undefined;

    /**
     * Creates an instance of ClassReflector.
     * @param {T | undefined} classToReflect The base class which the reflection will be based on. Can be undefined if interfacePropsToReflect are provided.
     * @param {Map<(keyof T), string> | undefined} interfacePropsToReflect A map of properties / methods with type of the reflected interface. Can be undefined if classToReflect is provided.
     * @memberof ClassReflector
     */
    public constructor(classToReflect: T | undefined, interfacePropsToReflect: Map<(keyof T), string> | undefined) {
        this.classToReflect = classToReflect;
        this.interfacePropsToReflect = interfacePropsToReflect;
    }

    /**
     * Whether the class is a reflection of a certain class / interface.
     *
     * @param {*} classToCheck The class to check.
     * @returns {classToCheck is T} Whether the class is of the checked class / interface.
     * @memberof ClassReflector
     */
    public isReflectionOfClass(classToCheck: any): classToCheck is T {
        if (this.classToReflect) {
            // Check each key of the base class that shall be reflected
            for (const key in this.classToReflect) {
                if (classToCheck[key] === undefined) {
                    return false;
                }
            }
            return true;
        } else if (this.interfacePropsToReflect) {
            // Check each key / type of the interface that shall be reflected
            let result = true;
            this.interfacePropsToReflect.forEach((value, key) => {
                if (classToCheck[key] === undefined || typeof classToCheck[key] !== value) {
                    result = false;
                }
            });
            return result;
        }
        return false;
    }
}