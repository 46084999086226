import { IAjaxHandler } from '../ajaxHandler/interfaces/iAjaxHandler';
import { IHttpResponse } from '../ajaxHandler/interfaces/iHttpResponse';
import { IServiceRequest } from '../ajaxHandler/interfaces/iServiceRequest';
import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { Utils } from '../common/utils';
import { Logger } from '../logging/logger';
import { IDataProvider } from './interfaces';
import { HttpCacheControlHeader } from './models/httpCacheControlHeader';
import { ResourcePointer } from './resourcePointer';
import { DataProvider, HTTP_RESPONSE_CODES, HttpResourcePointer, IHttpResourcePointer } from '.';
import { isNumber } from 'util';


/**
 * This class provides specific methods which are needed to get data from http services.
 * 
 * @export
 * @class HttpDataProvider
 * @implements {IDataProvider}
 */
export class HttpDataProvider extends DataProvider implements IDataProvider {

    /**
     * The logger.
     * 
     * @protected
     * @type {Logger}
     * @memberof HttpDataProvider
     */
    protected logger: Logger;

    /**
     * The current className.
     * 
     * @protected
     * @type {string}
     * @memberof HttpDataProvider
     */
    protected className: string = 'HttpDataProvider';

    /**
     * The raw request handler.
     * 
     * @private
     * @type {IAjaxHandler}
     * @memberof HttpDataProvider
     */
    private ajaxHandler: IAjaxHandler;


    /**
     * Creates an instance of HttpDataProvider.
     *
     * @param {Logger} logger 
     * @param {IAjaxHandler} ajaxHandler 
     * @memberof HttpDataProvider
     */
    public constructor(logger: Logger, ajaxHandler: IAjaxHandler) {
        super();

        if (!logger) {
            throw new ReferenceError('The argument \"logger\" is null or undefined.');
        } else if (!ajaxHandler) {
            throw new ReferenceError('The argument \"ajaxHandler\" is null or undefined.');
        }

        this.logger = logger;
        this.ajaxHandler = ajaxHandler;
    }


    /**
     * Creates a raw request.
     * 
     * @returns {XMLHttpRequest} 
     * @memberof HttpDataProvider
     */
    public createRawRequest(resourcePointer: IHttpResourcePointer): XMLHttpRequest {
        if (!this.ajaxHandler) {
            throw new Error('HttpDataProvider.createRawRequest(): No raw request handler was set.');
        }

        return this.ajaxHandler.createRawRequest(resourcePointer);
    }


    /**
     * Gets the supported schemes.
     * 
     * @returns {string[]} 
     * 
     * @memberof HttpDataProvider
     */
    public getSupportedSchemes(): string[] {
        return new Array<string>('http', 'https', 'http-blob');
    }


    /**
     * Executes a request.
     * 
     * @template T 
     * @param {IServiceRequest} request 
     * @returns {Promise<IServiceResponse<T>>} 
     * @async
     * 
     * @memberof HttpDataProvider
     */
    public async execute<T>(request: IServiceRequest): Promise<IServiceResponse<T>> {

        return new Promise<IServiceResponse<T>>((resolve, reject) => {

            try {
                // Before request handler
                const processedRequest = this.beforeRequestHandler(request);

                // Execute the request
                this.ajaxHandler.execute<T>(processedRequest).then((response: IServiceResponse<T>) => {
                    // After request handler
                    const processedResponse = this.afterRequestHandler(response as IHttpResponse<T>);
                    resolve(processedResponse);
                }).catch((reason) => {
                    reject(reason);
                });

            } catch (error) {
                reject(error);
            }

        });
    }


    /**
     * Gets the resource pointer schema.
     * 
     * @returns 
     * 
     * @memberof HttpDataProvider
     */
    public getResourcePointerSchema() {
        return HttpResourcePointer;
    }

    /**
     * Cast the resoure pointer into a HTTPResourcePointer.
     * 
     * @param {ResourcePointer} resourcePointer 
     * @returns {ResourcePointer} 
     * 
     * @memberof HttpDataProvider
     */
    public castResourcePointer(resourcePointer: ResourcePointer): ResourcePointer {
        const _httpRourcePointer = <HttpResourcePointer>resourcePointer;
        return new HttpResourcePointer(_httpRourcePointer.action, _httpRourcePointer.uri, _httpRourcePointer.parameter, _httpRourcePointer.httpHeaders);
    }


    /**
     * Extracts and sets the cache-control related values of the response instance.
     * 
     * @template T 
     * @param {IHttpResponse<T>} response 
     * @returns {IHttpResponse<T>} 
     * @memberof HttpDataProvider
     */
    public getCacheControlHeader<T>(response: IHttpResponse<T>): HttpCacheControlHeader | undefined {

        let cacheControlHeader: HttpCacheControlHeader | undefined;

        if (response.httpHeaders.has('cache-control')) {

            cacheControlHeader = new HttpCacheControlHeader();

            const cacheControlHeaderValue = response.httpHeaders.get('cache-control');
            if (cacheControlHeaderValue) {

                const splittedHeaderValues = cacheControlHeaderValue.split(',');

                // Parse the header values.
                for (const currentValuePart of splittedHeaderValues) {

                    // Try to get no-store.
                    if (currentValuePart.indexOf('no-store') !== -1) {
                        cacheControlHeader.noStore = true;
                    }

                    // Try to get no-cache.
                    if (currentValuePart.indexOf('no-cache') !== -1) {
                        cacheControlHeader.noCache = true;
                    }

                    // Try to get public.
                    if (currentValuePart.indexOf('public') !== -1) {
                        cacheControlHeader.isPublic = true;
                    }

                    // Try to get private.
                    if (currentValuePart.indexOf('private') !== -1) {
                        cacheControlHeader.isPrivate = true;
                    }

                    // Try to get max-age.
                    if (currentValuePart.indexOf('max-age') !== -1) {
                        const maxAgeParts = currentValuePart.split('=');
                        if (maxAgeParts && maxAgeParts.length > 1) {
                            const maxAge = parseInt(maxAgeParts[1], 10);
                            if (isNumber(maxAge)) {
                                cacheControlHeader.maxAge = maxAge;
                            }
                        }
                    }

                }
            }
        }

        return cacheControlHeader;
    }


    /**
     * This handler will be called before the execution.
     * 
     * @protected
     * @param {IServiceRequest} request 
     * @returns {IServiceRequest} 
     * @memberof HttpDataProvider
     */
    protected beforeRequestHandler(request: IServiceRequest): IServiceRequest {
        const processedRequest = super.beforeRequestHandler(request);

        if (processedRequest && processedRequest.resourcePointer && !this.isValidUri(request.resourcePointer.uri)) {
            throw new Error('HttpDataProvider.execute(): Invalid Uri found.');
        }

        const httpRessourcePtr = processedRequest.resourcePointer as IHttpResourcePointer;
        if (httpRessourcePtr) {

            // Try to add the entity tag.
            if (processedRequest.options && processedRequest.options.wdTag) {
                httpRessourcePtr.httpHeaders.set('If-None-Match', '\"' + processedRequest.options.wdTag + '\"');
            }
        }

        return processedRequest;
    }


    /**
     * This handler will be called after the execution.
     * 
     * @protected
     * @param {IHttpResponse} response 
     * @returns {IHttpResponse} 
     * @memberof HttpDataProvider
     */
    protected afterRequestHandler<T>(response: IHttpResponse<T>): IHttpResponse<T> {

        const processedResponse = super.afterRequestHandler(response) as IHttpResponse<T>;
        if (processedResponse) {

            // Try to get the entity tag from the response.
            if (processedResponse.httpHeaders.has('etag')) {
                const eTag = processedResponse.httpHeaders.get('etag');
                if (eTag) {
                    processedResponse.entityTag = eTag.substring(1, eTag.length - 1);
                }
            }

            // Try to get the cache-control header.
            const cacheControlHeader = this.getCacheControlHeader(processedResponse);
            if (!cacheControlHeader) {
                processedResponse.shouldBeCached = false;
            } else {

                if (cacheControlHeader.noStore) {
                    processedResponse.shouldBeCached = false;
                } else {

                    // Try to set the time-to-live in milliseconds.
                    if (Utils.isDefined(cacheControlHeader.maxAge) && cacheControlHeader.maxAge) {
                        const milliSecondsFactor = 1000;
                        processedResponse.timeToLive = cacheControlHeader.maxAge * milliSecondsFactor;
                    }

                    // Pessimistic behavior.
                    processedResponse.shouldBeCached = false;
                    processedResponse.notModified = false;

                    if (processedResponse.statusCode === HTTP_RESPONSE_CODES.SUCCESS) {
                        processedResponse.shouldBeCached = true;
                    } else if (processedResponse.statusCode === HTTP_RESPONSE_CODES.NOT_MODIFIED) {
                        processedResponse.notModified = true;
                        processedResponse.shouldBeCached = true;
                    }
                }

            }

        }

        return processedResponse;
    }


    /**
     * Check whether the uri is a valid url.
     * 
     * @private
     * @param {string} uri The uri.
     * @returns {boolean} Whehter this uri is a valid url.
     * 
     * @memberof HttpDataProvider
     */
    private isValidUri(uri: string): boolean {
        if (!uri) {
            return false;
        }

        const protocol = /^\w+:/.exec(uri);

        if (protocol === null) { // No protocol means it is not not http(s)
            return true;
        } else if (protocol.length === 1) {
            // Return true if protocol is http(s)
            return protocol[0].match(/https?:/) !== null || protocol[0].match(/file?:/) !== null;
        }

        return false;
    }

}