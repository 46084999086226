import { AttributeFlags, ToolbarAction, ToolbarDataset, ToolbarDatasetEntitiy, ToolbarDatasetHandler as BaseToolbarDatasetHandler, WindreamEntity } from '../../../typings/core';
import { GetDetailsRequestOptions, Logger, Utils } from '../dynamicWorkspace';
import { IServiceManager } from '../services/interfaces';

/**
 * Manages the datasets for toolbar actions.
 *
 * @export
 * @class ToolbarDatasetHandler
 * @implements {BaseToolbarDatasetHandler}
 */
export class ToolbarDatasetHandler implements BaseToolbarDatasetHandler {
    /**
     * Set a callback to execute when the data set changed.
     *
     * @memberof ToolbarDatasetHandler
     */
    public set onDataSetChanged(cb: () => void) {
        this.onDataSetChangedCbs.push(cb);
    };
    private toolbarActionInstances: Array<ToolbarAction>;
    private logger: Logger;
    private serviceManager: IServiceManager;
    private onDataSetChangedCbs: (() => void)[];

    /**
     * Creates an instance of ToolbarDatasetHandler.
     * 
     * @param {Array<ToolbarAction>} toolbarActionInstances The toolbar instances.
     * @param {Logger} logger The logger.
     * @param {IServiceManager} serviceManager The service manager.
     * @memberof ToolbarDatasetHandler
     */
    public constructor(toolbarActionInstances: Array<ToolbarAction>, logger: Logger, serviceManager: IServiceManager) {
        this.toolbarActionInstances = toolbarActionInstances;
        this.logger = logger;
        this.serviceManager = serviceManager;

        this.onDataSetChangedCbs = new Array<() => void>();
    }

    /**
     * Set the current dataset to all actions.
     *
     * @param {ToolbarDataset} dataset The dataset to set.
     * @memberof ToolbarDatasetHandler
     */
    public setDataset(dataset: ToolbarDataset): void {
        if (this.toolbarActionInstances) {
            this.beforePublish(dataset).then((processedDataset) => {
                this.publishDataset(processedDataset);
            }).catch((error) => {
                this.logger.error('ToolbarDatasetHandler', 'setDataset', 'Unable to set the dataset.', error);
            });
        }
        this.onDataSetChangedCbs.forEach((cb) => {
            cb();
        });
    }

    /**
     * Sets the toolbar to disabled.
     *
     * @param {boolean} isDisabled Whether the toolbar is disabled.
     * @memberof ToolbarDatasetHandler
     */
    public setDisabled(isDisabled: boolean): void {
        if(this.toolbarActionInstances) {
            this.toolbarActionInstances.forEach((action) => {
                if(typeof action.setDisabled === 'function') {
                    action.setDisabled(isDisabled);
                }
            });
        }
    }

    /**
     * Publishes the toolbar dataset.
     *
     * @private
     * @param {ToolbarDataset} dataset The toolbar dataset.
     * @memberof ToolbarDatasetHandler
     */
    private publishDataset(dataset: ToolbarDataset): void {
        if (this.toolbarActionInstances) {
            this.toolbarActionInstances.forEach((action) => {
                try {
                    action.setDataset(dataset);
                } catch (error) {
                    this.logger.error('ToolbarDatasetHandler', 'publishDataset', 'Failed to publish dataset', error);
                }
            });
        }
    }

    /**
     * Runs before a dataset is getting published. 
     *
     * @private
     * @param {ToolbarDataset} dataset The original dataset.
     * @returns {*}  {Promise<ToolbarDataset>} The process dataset.
     * @memberof ToolbarDatasetHandler
     */
    private beforePublish(dataset: ToolbarDataset): Promise<ToolbarDataset> {
        return new Promise((resolve, reject) => {

            if (!dataset || !dataset.data) {
                resolve(dataset);
                return;
            }

            if (Utils.Instance.isArray(dataset.data) && dataset.data.length === 1 &&
                (dataset.entity === ToolbarDatasetEntitiy.WindreamIdentities || dataset.entity === ToolbarDatasetEntitiy.WindreamFiles || dataset.entity === ToolbarDatasetEntitiy.WindreamFolders)) {

                // Try to add windream object status details.
                this.tryAddObjectStatus(dataset).then((objectStatusDataset) => {
                    resolve(objectStatusDataset);
                    return;
                }).catch((error) => {
                    reject(error);
                    return;
                });
            } else {
                resolve(dataset);
            }
        });
    }

    /**
     * Tries to add the object status.
     *
     * @private
     * @param {ToolbarDataset} dataset The original dataset.
     * @return {*}  {Promise<ToolbarDataset>} The dataset with object status.
     * @memberof ToolbarDatasetHandler
     */
    private tryAddObjectStatus(dataset: ToolbarDataset): Promise<ToolbarDataset> {
        return new Promise((resolve, reject) => {
            // Get the current object status from windream
            const columns = ['##ObjectStatus##'];
            const flags = AttributeFlags.Virtual;
            const identity = dataset.data[0];
            const requestOptions = new GetDetailsRequestOptions(identity);
            requestOptions.values = columns;
            requestOptions.attributeFlags = flags;
            if (identity.entity === WindreamEntity.Folder) {
                this.serviceManager.getServices().Directories.getDetails(requestOptions)
                    .then((detailsResponse) => {
                        dataset.data[0].objectStatus = detailsResponse.attributes[0].value;
                        resolve(dataset);
                        return;
                    }).catch((error) => {
                        reject(error);
                        return;
                    });
            } else if (identity.entity === WindreamEntity.Document) {
                this.serviceManager.getServices().Documents.getDetails(requestOptions)
                    .then((detailsResponse) => {
                        dataset.data[0].objectStatus = detailsResponse.attributes[0].value;
                        resolve(dataset);
                        return;
                    }).catch((error) => {
                        reject(error);
                        return;
                    });
            } else {
                resolve(dataset);
            }
        });
    }

}