import { IRouter } from '.';

/**
 * This router provides the /settings route.
 *
 * @export
 * @class SettingsRouter
 * @implements {IRouter}
 */
export class SettingsRouter implements IRouter {

    private onSettingsNavigationCallbacks: ((path: string) => void)[];


    /**
     * Creates an instance of SettingsRouter.
     * 
     * @memberof SettingsRouter
     */
    public constructor() {
        this.onSettingsNavigationCallbacks = new Array<() => void>();
    }

    /**
     * Route the specific settings route.
     *
     * @param {string} path The url path to route.
     * @returns {boolean} Whether the route was routed with success.
     * @memberof SettingsRouter
     */
    public route(path: string): boolean {
        this.onSettingsNavigationCallbacks.forEach((callback) => {
            callback(path);
        });
        return true;
    }

    /**
     * Gets the route pattern
     *
     * @returns {RegExp} The route pattern.
     * @memberof SettingsRouter
     */
    public getRoutePattern(): RegExp {
        return new RegExp('\/settings$|\/settings\?.*$', 'i');
    }

    /**
     * Register navigation callbacks which will be called on routing.
     *
     * @param {(path: string) => void} callback Callback to register.
     * @memberof SettingsRouter
     */
    public registerOnSettingsNavigation(callback: (path: string) => void): void {
        this.onSettingsNavigationCallbacks.push(callback);
    }
}