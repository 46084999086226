
/**
 * Thrown by the runtime only when an array is indexed improperly.
 * 
 * @export
 * @class IndexOutOfRangeError
 * @extends {Error}
 */
export class IndexOutOfRangeError extends Error {

    /**
     * Creates an instance of IndexOutOfRangeError.
     * @param {string} [message] 
     * 
     * @memberof IndexOutOfRangeError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'IndexOutOfRangeError';
    }
}