import { ViewConfig } from '../../config';
import { ViewRequestOptions } from './viewRequestOptions';

/**
 * Represents save view request options.
 *
 * @export
 * @class SaveviewRequestOptions
 * @extends {ViewRequestOptions}
 */
export class SaveviewRequestOptions extends ViewRequestOptions {

    /**
     * The view config.
     *
     * @type {ViewConfig}
     * @memberof SaveviewRequestOptions
     */
    public config: ViewConfig;

    /**
     * Creates an instance of SaveviewRequestOptions.
     * @param {string} viewId
     * @param {ViewConfig} config
     * @memberof SaveviewRequestOptions
     */
    public constructor(viewId: string, config: ViewConfig) {
        super(viewId);
        if (!config) {
            throw new Error('Argument \'config\' is not valid');
        }
        this.config = config;
    }
}