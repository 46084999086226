/**
 * This class represents all required parameters to publish something via the PubSub.
 *
 * @export
 * @class PublishParameter
 */
export class PublishParameter {

    /**
     * The component identifier.
     *
     * @type {string}
     * @memberof PublishParameter
     */
    public guid: string;

    /**
     * The PubSub event name.
     *
     * @type {string}
     * @memberof PublishParameter
     */
    public name: string;

    /**
     * The value to publish.
     *
     * @type {(Object | null)}
     * @memberof PublishParameter
     */
    public value?: Object | null;


    /**
     * Creates an instance of PublishParameter.
     *
     * @param {string} guid The component identifier.
     * @param {string} name The PubSub event name.
     * @param {(Object | null)} [value] The value to publish.
     * @memberof PublishParameter
     */
    public constructor(guid: string, name: string, value?: Object | null) {
        this.guid = guid;
        this.name = name;
        this.value = value;
    }

}