import { HistoryItemResponseDTO } from '../../../typings/windreamWebService/Windream.WebService';

/**
 * A single entry within a windream identities history.
 *
 * @export
 * @class HistoryEntry
 */
export class HistoryEntry {
    /**
     * The entry's date.
     *
     * @type {string}
     * @memberof HistoryEntry
     */
    public date: string;
    /**
     * The entry's creator.
     *
     * @type {string}
     * @memberof HistoryEntry
     */
    public creatorName: string;
    /**
     * The entry's action.
     *
     * @type {string}
     * @memberof HistoryEntry
     */
    public action: string;
    /**
     * The entry's comment.
     *
     * @type {string}
     * @memberof HistoryEntry
     */
    public comment: string;

    /**
     * Creates an instance of HistoryEntry.
     *
     * @param {string} date
     * @param {string} creatorName
     * @param {string} action
     * @param {string} comment
     * @memberof HistoryEntry
     */
    public constructor(date: string, creatorName: string, action: string, comment: string) {
        this.date = date;
        this.creatorName = creatorName;
        this.action = action;
        this.comment = comment;
    }

    /**
     * Creates an instance based on the DTO.
     *
     * @static
     * @param {HistoryItemResponseDTO} dto DTO to create from.
     * @returns {HistoryEntry}
     * @memberof HistoryEntry
     */
    public static fromDto(dto: HistoryItemResponseDTO): HistoryEntry {
        const result = new HistoryEntry(dto.HistoryDate, dto.HistoryCreatorName, dto.HistoryAction, dto.HistoryComment);

        return result;
    }
}