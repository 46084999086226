/**
 * This class is a data holder for the custom application config.
 * 
 * @export
 * @class CustomConfig
 */
export class CustomConfig {

    /**
     * The browser title of the page.
     *
     * @type {(string | null)}
     * @memberof CustomConfig
     */
    public browserTitle?: string | null;

    /**
     * The main title of the page.
     *
     * @type {(string | null)}
     * @memberof CustomConfig
     */
    public title?: string | null;

    /**
     * The subTitle of the page.
     *
     * @type {(string | null)}
     * @memberof CustomConfig
     */
    public subTitle?: string | null;

    /**
     * The navigation background color of the app bar.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public navigationBackgroundColor?: string;

    /**
     * The navigation text color of the app bar.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public navigationTextColor?: string;

    /**
     * The navigation hover color.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public navigationHoverColor?: string;

    /**
     * The header background color.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public headerBackgroundColor?: string;

    /**
     * The header text color.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public headerTextColor?: string;

    /**
     * The header icon hover color.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public headerIconHoverColor?: string;

    /**
     * Active view background color.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public activeViewBackgroundColor?: string;

    /**
     * Active view text color.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public activeViewIconColor?: string;

    /**
     * The title blackground color of the app bar.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public titleBackgroundColor?: string;

    /**
     * The title text color of the app bar.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public titleTextColor?: string;

    /**
     * The path to the custom icon.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public iconPath?: string;

    /**
     * The path to a custom css file.
     *
     * @type {string}
     * @memberof CustomConfig
     */
    public customCssFilePath?: string;
}