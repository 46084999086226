import { UploadDocumentResponseContainerDTO, UploadFlags } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { UploadResponse, Utils } from '../../common';
import { GlobalConfig } from '../../config';
import { BinaryHttpResourcePointer, BinaryHttpResponseTypes } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { IExtensionProvider, UploadExtension } from '../../extensions';
import { Logger } from '../../logging/logger';
import { WebSocketFile } from '../../websocket';
import { UploadRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Uploads a given file.
 * 
 * @export
 * @class Upload
 * @extends {ServiceAction}
 */
export class Upload extends ServiceAction {

    private extensionProvider: IExtensionProvider;

    /**
     * Creates an instance of Upload.
     * 
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The gloval config.
     * @param {Logger} logger The logger.
     * @param {IExtensionProvider} extensionProvider The extension provider.
     * @memberof Upload
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, extensionProvider: IExtensionProvider) {
        super(requestExecutor, globalConfig, logger);
        this.extensionProvider = extensionProvider;
        this.name = 'upload';
    }

    /**
     * Execute the upload of a given document.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {UploadRequestOptions} uploadRequestOptions The request options.
     * @returns {Promise<UploadResponse>} A promise, which will resolve with the server response.
     * @memberof Upload
     */
    public async do(uploadRequestOptions: UploadRequestOptions): Promise<UploadResponse> {

        if (typeof uploadRequestOptions.data.getFile !== 'function' || !uploadRequestOptions.data.getFile()) {
            // Try to use fallback
            return this.doFallbackUpload(uploadRequestOptions);
        }

        return new Promise<UploadResponse>((resolve, reject) => {

            const fileToUpload = uploadRequestOptions.data.getFile();

            let uploadExtension: UploadExtension | null = null;
            try {
                uploadExtension = this.extensionProvider.getExtension('upload') as UploadExtension;
            } catch (error) {
                this.logger.error('Upload', 'do', 'Failed to retrieve upload extension', error);
            }
            // TODO: Reenable this once we actually completed the whole WebSocket Conflict management etc.
            const webSocketUploadEnabled = false;
            if (uploadExtension && fileToUpload && webSocketUploadEnabled) {
                uploadExtension.connect().then((websocket) => {
                    // TODO: Create more files if bulk is supported.
                    let uploadFlags = uploadRequestOptions.flags;
                    if (!uploadFlags) {
                        // No flags were set, therefore use the default flags.
                        uploadFlags = UploadFlags.CreateObject | UploadFlags.ReturnIndexingDetails;
                    }
                    const webSocketFile = new WebSocketFile(uploadRequestOptions.identity, fileToUpload, uploadFlags);
                    websocket.upload([webSocketFile]).then((response: UploadResponse) => {
                        resolve(response);
                    }).catch((error) => reject(error));
                }).catch((error) => {
                    this.logger.error('Upload', 'do', 'Failed to connect upload web socket', error);
                    this.doFallbackUpload(uploadRequestOptions).then((response) => resolve(response))
                        .catch((error) => reject(error));
                });
            } else {
                this.doFallbackUpload(uploadRequestOptions).then((response) => resolve(response))
                    .catch((error) => reject(error));
            }
        });
    }

    /**
     * The fallback upload method.
     *
     * @private
     * @param {UploadRequestOptions} uploadRequestOptions The request options.
     * @returns {Promise<UploadResponse>} A promise, which will resolve with the server response.
     * @memberof Upload
     */
    private doFallbackUpload(uploadRequestOptions: UploadRequestOptions): Promise<UploadResponse> {
        return new Promise<UploadResponse>((resolve, reject) => {
            let param = '';
            if (typeof uploadRequestOptions.identity.id === 'number') {
                param = `parameter.item.id=${uploadRequestOptions.identity.id}`;
            }
            if (typeof uploadRequestOptions.identity.name === 'string') {
                const encodedName = encodeURIComponent(uploadRequestOptions.identity.name);
                if (param !== '') {
                    param += '&';
                }
                param += `parameter.item.name=${encodedName}`;
            }
            if (typeof uploadRequestOptions.identity.location === 'string') {
                const encodedLocation = encodeURIComponent(uploadRequestOptions.identity.location);
                if (param !== '') {
                    param += '&';
                }
                param += `parameter.item.location=${encodedLocation}`;
            }
            if (param === '') {
                throw new Error('Given identity is invalid');
            }

            let tempUrl = `${this.globalConfig.windreamWebServiceURL}/documents/Upload?${param}`;
            const flags = ['ReturnIndexingDetails'];
            if (!uploadRequestOptions.flags) {
                flags.push('CreateObject'); // Create new object on the fly and also return indexing details within the response
            } else {
                if ((uploadRequestOptions.flags & UploadFlags.CheckIn) === UploadFlags.CheckIn) {
                    flags.push('CheckIn');
                }
                if ((uploadRequestOptions.flags & UploadFlags.CreateNewVersion) === UploadFlags.CreateNewVersion) {
                    flags.push('CreateNewVersion');
                }
                if ((uploadRequestOptions.flags & UploadFlags.CreateObject) === UploadFlags.CreateObject) {
                    flags.push('CreateObject');
                }
                if ((uploadRequestOptions.flags & UploadFlags.UseDefaultLocation) === UploadFlags.UseDefaultLocation) {
                    flags.push('UseDefaultLocation');
                }
                if ((uploadRequestOptions.flags & UploadFlags.CreateTree) === UploadFlags.CreateTree) {
                    flags.push('CreateTree');
                }
            }
            tempUrl += `&parameter.flags=${flags.join(',')}`;
            const binaryHttpResourcePtr = new BinaryHttpResourcePointer(
                'POST',
                tempUrl,
                BinaryHttpResponseTypes.JSON,
                uploadRequestOptions.data.getData());
            this.requestExecutor.executeRequest(binaryHttpResourcePtr, uploadRequestOptions.requestOptions)
                .then((response: IServiceResponse<UploadDocumentResponseContainerDTO | null>) => {
                    if (Utils.isDefined(response) && response.data && !response.data.HasErrors) {
                        resolve(UploadResponse.fromDto(response.data));
                    } else {
                        this.logger.error('Upload', 'do', 'Failed to upload document', response);
                        reject(new Error('Failed to upload document'));
                    }
                }).catch((err: Error) => {
                    this.logger.error('Upload', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}