import { Utils } from '../dynamicWorkspace';
import { ComponentLoadManifest } from '.';


/**
 * This factory will create the component load manifest.
 *
 * @export
 * @class ComponentLoadManifestFactory
 */
export class ComponentLoadManifestFactory {

    /**
     * Creates the load manifest for a component.
     *
     * @param {string} componentId The component id.
     * @returns {ComponentLoadManifest} The load manifest.
     * @memberof ComponentLoadManifestFactory
     */
    public generateLoadManifestForId(componentId: string): Promise<ComponentLoadManifest> {
        return new Promise<ComponentLoadManifest>(async (resolve) => {
            const componentsBasePath = Utils.getComponentsBasePath(componentId);
            const componentFolderPath: string = `${componentsBasePath}/${componentId}/`;
            const remoteConfigLocation: string = componentFolderPath + 'windream-component.json';
            const componentLoadManifest: ComponentLoadManifest = {
                componentId: componentId,
                componentBasePath: componentsBasePath + '/',
                componentConfigFileUrl: remoteConfigLocation
            };

            resolve(componentLoadManifest);
        });
    }
}