import { WindreamIdentity } from '../../common';
import { FileDataSource } from '../documents';
import { WindreamRequestOptions } from './windreamRequestOptions';


/**
 * Represents windream file request options.
 *
 * @export
 * @class WindreamFileRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class WindreamFileRequestOptions extends WindreamRequestOptions {

    /**
     * The file data source.
     *
     * @type {FileDataSource}
     * @memberof WindreamFileRequestOptions
     */
    public file: FileDataSource;

    /**
     * Creates an instance of WindreamFileRequestOptions.
     * @param {WindreamIdentity} identity
     * @param {FileDataSource} file
     * @memberof WindreamFileRequestOptions
     */
    public constructor(identity: WindreamIdentity, file: FileDataSource) {
        super(identity);
        if (!file) {
            throw new Error('Argument \'file\' is not valid');
        }
        this.file = file;
    }
}