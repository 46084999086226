/**
 * Represents a model which is used to check for recursion via deltas.
 *
 * @export
 * @class PubSubTickModel
 */
export class PubSubTickModel {
    private previousTicks: number[];
    private failedDeltaChecks: number;

    /**
     * Creates an instance of PubSubTickModel.
     * 
     * @memberof PubSubTickModel
     */
    public constructor() {
        this.previousTicks = new Array<number>();
        this.failedDeltaChecks = 0;
    }

    /**
     * Let the model tick.
     *
     * @param {number} tick The current tick.
     * @returns {number} The amount of stored ticks.
     * @memberof PubSubTickModel
     */
    public tick(tick: number): number {
        this.previousTicks.push(tick);
        return this.previousTicks.length;
    }

    /**
     * Get deltas for the ticks.
     *
     * @returns {number[]} The deltas.
     * @memberof PubSubTickModel
     */
    public getDeltas(): number[] {
        const deltas = new Array<number>();
        for (let i = this.previousTicks.length - 1; i > 1; i--) {
            const j = i - 1;
            if (j > -1) {
                deltas.push(this.previousTicks[i] - this.previousTicks[j]);
            }
        }
        return deltas;
    }

    /**
     * Called if a delta check failed.
     *
     * @returns {number} The amount of failed delta check since the last reset.
     * @memberof PubSubTickModel
     */
    public failedDelta(): number {
        this.failedDeltaChecks += 1;
        return this.failedDeltaChecks;
    }

    /**
     * Reset the model.
     *
     * @memberof PubSubTickModel
     */
    public reset(): void {
        this.previousTicks.length = 0;
        this.failedDeltaChecks = 0;
    }
}