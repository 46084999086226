import { GetScriptResponseContainerDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { Logger } from '../logging';
import { GetContextMenuScript, GetScriptContent, GetScriptRequestOptions } from '../services';
import { IDirectScriptExecutor, IWindreamScriptExecutor } from './interfaces';
import { SCRIPT_SOURCE } from './scriptSource';

/**
 * Loads and executes scripts.
 * 
 * @export
 * @class WindreamScriptExecutor
 * @implements {IWindreamScriptExecutor}
 */
export class WindreamScriptExecutor implements IWindreamScriptExecutor {
    private contextMenuScriptService: typeof GetContextMenuScript.prototype.do;
    private remoteScriptService: typeof GetScriptContent.prototype.do;
    private directScriptExecutor: IDirectScriptExecutor;
    private logger: Logger;
    private scriptContentMap: Map<string, string>;

    /**
     * Creates an instance of WindreamScriptExecutor.
     * @param {typeof GetContextMenuScript.prototype.do} contextMenuScriptService
     * @param {typeof GetScript.prototype.do} remoteScriptService
     * @param {Logger} logger
     * @param {IDirectScriptExecutor} directScriptExecutor
     * @memberof WindreamScriptExecutor
     */
    public constructor(contextMenuScriptService: typeof GetContextMenuScript.prototype.do, remoteScriptService: typeof GetScriptContent.prototype.do,
        logger: Logger, directScriptExecutor: IDirectScriptExecutor) {
        this.contextMenuScriptService = contextMenuScriptService;
        this.remoteScriptService = remoteScriptService;
        this.logger = logger;
        this.directScriptExecutor = directScriptExecutor;
        this.scriptContentMap = new Map<string, string>();
    }

    /**
     * Executes the script with the given name.
     * 
     * @param {SCRIPT_SOURCE} source Source of the script.
     * @param {string} scriptName Name of the script.
     * @param {any[]} [args] Arguments to pass to the script.
     * @param {(result: any) => void} [callback] Optional callback to pass over. Used when the script executes the callback multiple times.
     * @param {(object)} [options] Options to pass to the script.
     * @returns {Promise<void>} Promise to resolve after the script has been executed and its callback function has been called.
     * @memberof WindreamScriptExecutor
     */
    public async execute(source: SCRIPT_SOURCE, scriptName: string, args?: any[], callback?: (result: any) => void, options?: object): Promise<any> {
        return this.ensureScriptIsLoaded(source, scriptName)
            .then(async (scriptContent: string) => this.directScriptExecutor.execute(scriptContent, args, callback, options))
            .catch((err: Error) => this.logger.error('WindreamScriptExecutor', 'execute', 'Failed to execute script', err));
    }

    /**
     * Makes sure the script with the given name has been loaded and inserted.
     * 
     * @private
     * @param {SCRIPT_SOURCE} source Source of the script.
     * @param {string} scriptName Name of the script to load.
     * @returns {Promise<string>} Promise to resolve with the script content.
     * @memberof WindreamScriptExecutor
     */
    private async ensureScriptIsLoaded(source: SCRIPT_SOURCE, scriptName: string): Promise<string> {
        const scriptKey = this.generateScriptKey(scriptName);
        const scriptContent = this.scriptContentMap.get(scriptKey);
        if (scriptContent) { // Resolve immideately if script has already been loaded before
            return Promise.resolve(scriptContent);
        }

        return this.getScript(source, scriptName);
    }

    /**
     * Creates a key for the given script name.
     * 
     * @private
     * @param {string} scriptName Name of the script to get the key for.
     * @returns {string} The key for the script.
     * @memberof WindreamScriptExecutor
     */
    private generateScriptKey(scriptName: string): string {
        return scriptName.toLowerCase().replace(/[^a-z]+/g, '');
    }

    /**
     * Gets the script form the server using the given service.
     * 
     * @private
     * @param {SCRIPT_SOURCE} source Source of the script.
     * @param {string} scriptName Name of the script to get.
     * @returns {Promise<string>} Promise to resolve with the script contents.
     * @memberof WindreamScriptExecutor
     */
    private async getScript(source: SCRIPT_SOURCE, scriptName: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let servicePromise: Promise<GetScriptResponseContainerDTO>;
            if (source === SCRIPT_SOURCE.ContextMenu) {
                servicePromise = this.contextMenuScriptService(new GetScriptRequestOptions(scriptName));
            } else {
                servicePromise = this.remoteScriptService(new GetScriptRequestOptions(scriptName));
            }
            servicePromise.then((data) => resolve(data.Script))
                .catch((error) => {
                    this.logger.error('WindreamScriptExecutor', 'getScript', 'Failed to get script', error);
                    reject(error);
                });
        });
    }
}