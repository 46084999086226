import { DWCore } from '@windream/dw-core/dwCore';
import { ViewConfig } from '../../dynamicWorkspace';

/**
 * This class represents a view.
 * 
 * @export
 * @class View
 */
export class View {

    /**
     * The identifier of the view.
     * 
     * @type {string}
     * @memberof View
     */
    public id: string;


    /**
     * The device specific view configurations.
     *
     * @type {Map<DWCore.Common.Devices, ViewConfig>}
     * @memberof View
     */
    public configurations: Map<DWCore.Common.Devices, ViewConfig>


    /**
     * Creates an instance of View.
     *
     * @param {string} identifier The view identifier.
     * @memberof View
     */
    public constructor(identifier: string) {
        this.id = identifier;
        this.configurations = new Map<DWCore.Common.Devices, ViewConfig>();
    }

}