import { WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents the request options for add history.
 *
 * @export
 * @class AddHistoryRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class AddHistoryRequestOptions extends WindreamRequestOptions {

    /**
     * The comment to add.
     *
     * @type {string}
     * @memberof AddHistoryRequestOptions
     */
    public comment: string;

    /**
     * Creates an instance of AddHistoryRequestOptions.
     * @param {WindreamIdentity} identity
     * @param {string} comment
     * @memberof AddHistoryRequestOptions
     */
    public constructor(identity: WindreamIdentity, comment: string) {
        super(identity);
        if (typeof comment !== 'string') {
            throw new Error('Argument \'comment\' is not valid');
        }
        this.comment = comment;
    }
}