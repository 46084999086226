import { MoveDocumentFlags, ResponseDetailsType, WindreamIdentity } from '../../common';
import { MoveRequestOptions } from './moveRequestOptions';

/**
 * Represents the move document request options.
 *
 * @export
 * @class MoveDocumentRequestOptions
 * @extends {MoveRequestOptions}
 */
export class MoveDocumentRequestOptions extends MoveRequestOptions {

    /**
     * The documents flags.
     *
     * @type {MoveDocumentFlags}
     * @memberof MoveDocumentRequestOptions
     */
    public flags: MoveDocumentFlags;

    /**
     * Creates an instance of MoveDocumentRequestOptions.
     * @param {WindreamIdentity} source
     * @param {WindreamIdentity} target
     * @param {MoveDocumentFlags} flags
     * @param {ResponseDetailsType} responseDetails
     * @memberof MoveDocumentRequestOptions
     */
    public constructor(source: WindreamIdentity, target: WindreamIdentity, flags: MoveDocumentFlags, responseDetails: ResponseDetailsType) {
        super(source, target, responseDetails);
        if (typeof flags !== 'number') {
            throw new Error('Argument \'flags\' is not valid');
        }
        this.flags = flags;
    }
}