import { ComponentInstanceConfig } from '../config';
import { Utils } from '../dynamicWorkspace';
import { IMultilingualTranslator } from '../language';
import { LIFECYCLE_HOOKS, LifecycleReflector } from '../lifecycle';
import { Logger } from '../logging';
import { ISkeletonHelper } from './interfaces';
import { SKELETON_TYPES } from '.';

/**
 * Helper to handle skeletons.
 * 
 * @export
 * @class SkeletonHelper
 * @implements {ISkeletonHelper}
 */
export class SkeletonHelper implements ISkeletonHelper {

    private logger: Logger;
    private multilingualTranslator: IMultilingualTranslator;


    /**
     * Creates an instance of SkeletonHelper.
     * @param {Logger} logger 
     * @param {IMultilingualTranslator} multilingualTranslator 
     * @memberof SkeletonHelper
     */
    public constructor(logger: Logger, multilingualTranslator: IMultilingualTranslator) {
        this.logger = logger;
        this.multilingualTranslator = multilingualTranslator;
    }


    /**
     * Ensures that the given component instance contains a wdOnSkeleton lifecycle step.
     * Will add a default implementation if the step is not implemented.
     * 
     * @param {*} componentInstance Instance to add step to.
     * @param {ComponentInstanceConfig} componentInstanceConfig  Use this instance to get component name
     * @param {HTMLElement} element Element the instance is rendered into.
     * @param {boolean} isInConfigMode Whether it's in the config mode or not.
     * @param {boolean} isWithErrors Whether the component has errors and should display another skeleton.
     *
     * @memberof SkeletonHelper
     */
    public addDefaultSkeleton(componentInstance: any, componentInstanceConfig: ComponentInstanceConfig, element: HTMLElement, _isInConfigMode: boolean, isWithErrors: boolean): void {
        if (!LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnSkeleton, componentInstance)) {
            componentInstance['wdOnSkeleton'] = (_skeletonHelper: ISkeletonHelper, isInConfigMode: boolean) => {
                this.showDefaultSkeleton(element, componentInstanceConfig, isInConfigMode, isWithErrors);
            };
        }
        if (!LifecycleReflector.hasLifecycleHook(LIFECYCLE_HOOKS.OnDestroy, componentInstance)) {
            componentInstance['wdOnDestroy'] = () => {
                this.clearDefaultSkeleton(element);
            };
        }
    }
    /**
     * Displays the given skeleton within the given element.
     * 
     * @param {SKELETON_TYPES} _skeletonType Skeleton type to display.
     * @param {HTMLElement} element Element to render the skeleton into.
     * @memberof SkeletonHelper
     */
    public showSkeleton(skeletonType: SKELETON_TYPES, element: HTMLElement): void {
        // TODO: Implement skeletons
        switch (skeletonType) {
            case SKELETON_TYPES.TABLE: {
                element.innerHTML = require('./templates/skeletons/tableSkeleton.html');
                break;
            }
            case SKELETON_TYPES.CHART: {
                element.innerHTML = require('./templates/skeletons/chartSkeleton.html');
                break;
            }
            case SKELETON_TYPES.LIST: {
                element.innerHTML = require('./templates/skeletons/listSkeleton.html');
                break;
            }
            case SKELETON_TYPES.FORM: {
                element.innerHTML = require('./templates/skeletons/formSkeleton.html');
                break;
            }
            default: {
                this.logger.warn('SkeletonHelper', 'showSkeleton', 'Invalid skeleton type should be used: ' + skeletonType);
                break;
            }
        }
    }

    /**
     * Ensures that the given component instance contains a wdOnSkeleton lifecycle step.
     * Will show a default skeleton if the step is not implemented.
     * 
     * @param {ComponentInstanceConfig} componentInstanceConfig InstanceConfig to add step to.
     * @param {HTMLElement} element Element the instanceConfig is rendered into.
     * @param {boolean} isInConfigMode Whether it's in the config mode or not.
     * @param {boolean} [hasErrors] Whether the component has errors and should display another skeleton.
     *
     * @memberof SkeletonHelper
     */
    protected showDefaultSkeleton(element: HTMLElement, componentInstanceConfig: ComponentInstanceConfig, isInConfigMode: boolean, hasErrors?: boolean) {
        if (element.parentElement && !element.parentElement.querySelector('.wd-skeleton.wd-skeleton-default')) {
            let componentName = componentInstanceConfig.component;
            const templateDiv = document.createElement('div');
            templateDiv.innerHTML = require('./templates/skeletons/defaultSkeleton.html');
            if (templateDiv.firstChild) {
                (templateDiv.firstChild as HTMLElement).hidden = true;
                element.parentElement.appendChild(templateDiv.firstChild);
                const image = element.parentElement.querySelector('img');
                if (image) {
                    try {
                        image.setAttribute('alt', this.multilingualTranslator.getTranslationFromProperty(componentInstanceConfig.name));
                    } catch (err) {
                        this.logger.warn('SkeletonHelper', 'showDefaultSkeleton', 'No translation found for component name', err);
                    }

                    if (hasErrors) {
                        componentName = 'com.windream.error';
                    }

                    const componentBasePath = Utils.getComponentBasePath(componentName);
                    image.src = `${componentBasePath}/img/component_preview.png`;
                }
            }
        }
        if (element.parentElement) {
            this.toggleSkeletonVisbility(element.parentElement, isInConfigMode);
        }
    }

    /**
     * Clear the default skeleton from the HTMLElement.
     *
     * @protected
     * @param {HTMLElement} element The target element.
     * @memberof SkeletonHelper
     */
    protected clearDefaultSkeleton(element: HTMLElement) {
        if (element.parentElement) {
            this.toggleSkeletonVisbility(element.parentElement, false);
        }
    }

    /**
     * Toggles whether the skeleton is visibile or not.
     *
     * @private
     * @param {HTMLElement} element The element which cotains the skeleton
     * @param {boolean} isVisibile Whether the skeleton is visibile or not.
     * @memberof SkeletonHelper
     */
    private toggleSkeletonVisbility(element: HTMLElement, isVisibile: boolean) {
        const skeletonElement = element.querySelector('.wd-skeleton.wd-skeleton-default');
        const componentElement = element.querySelector('.component-content');
        if (skeletonElement && componentElement) {
            (skeletonElement as HTMLElement).hidden = !isVisibile;
            (componentElement as HTMLElement).hidden = isVisibile;
        }
    }
}