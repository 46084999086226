/* eslint-disable max-lines */
import { DWCore } from '@windream/dw-core/dwCore';
import { Application } from '../application';
import { IMetadataStore, ObjectType } from '../caching';
import { PubSubConstants, Utils } from '../common';
import { DEVICE_NAMES, DeviceDetection } from '../common/deviceDetection';
import { IComponentManager } from '../components';
import {
  ApplicationConfig, ColumnsMappingHandler, ComponentInstanceToolbarActionConfig, ComponentInstanceToolbarConfig,
  ConfigMapper,
  IConfigLoader, IConfigManager, IConfigTranslation, ObjectTypeMappingHandler, PathMappingHandler, SearchQueryMappingHandler, SubstitutionMappingHandler, View
} from '../config';
import { ComponentConfig, ComponentInstanceConfig, ViewConfig } from '../config';
import { Style } from '../config/models/style';
import { WEBSERVICE_APPLICATION_ERROR_CODES } from '../errors';
import { ILanguageManager, ILanguageProvider, IMultilingualTranslator } from '../language';
import { IUiManager, ViewInitializationOptions } from '../loader';
import { ISubViewManager, IViewManager, LayoutConfiguration, SubViewConfig } from '../loader';
import { Logger } from '../logging';
import { Connection, IPubSubHandler, PubSubConfig } from '../pubSub';
import { EditRouter, RouteManager, ViewRouter } from '../router';
import { ServiceError } from '../services';
import { SharedSettingsProvider } from '../shared';
import { IConfigUiHelper } from './interfaces';
import {
  CONFIG_MODES, ConfigUiHelperUtil, IAppBarHandler, IDeviceMenuHandler, IPanelHandler, IPopupHelper, IPubSubUiHelper,
  IToolstripHandler, NotificationHelper, ToolstripOption, MappingUiHelper
} from '.';

/**
 *
 * Helper class to manage the edit mode and configuration of the framework.
 * Binds to UI events to switch into the editing mode.
 * Renders configuraion components and ensures proper PubSub binding.
 *
 * @export
 * @class ConfigUiHelper
 */
export class ConfigUiHelper implements IConfigUiHelper {

  /**
   * Callback to execute when the navigation was made editable or un-editable.
   *
   *
   * @memberof ConfigUiHelper
   */
  public makeNavigationEditable?: (state: Boolean) => void;

  protected viewManager: IViewManager;

  private pubSubHandler: IPubSubHandler;
  private subViewManager: ISubViewManager;
  private configLoader: IConfigLoader;
  private configManager: IConfigManager;
  private frameworkLanguageProvider: ILanguageProvider;
  private languageManager: ILanguageManager;
  private metaDataStore: IMetadataStore;
  private className: string = 'ConfigUiHelper';
  private currentDevice: DWCore.Common.Devices;
  private popupHelper: IPopupHelper;
  private componentManager: IComponentManager;
  private appBarHandler: IAppBarHandler;
  private toolstripHandler: IToolstripHandler;
  private pubSubUiHelper: IPubSubUiHelper;
  private mappingUiHelper?: MappingUiHelper;
  private panelHandler: IPanelHandler;
  private deviceMenuHandler: IDeviceMenuHandler;
  private multilingualTranslator: IMultilingualTranslator;
  private uiManager: IUiManager;
  private sharedSettingsProvider?: SharedSettingsProvider;
  private applicationConfig: ApplicationConfig;
  private viewConfigBackup?: ViewConfig;
  private currentlyEditedComponentGuid?: string;
  private configActive: boolean;
  private waitForSavePromise?: Promise<void>;
  private isPubSubDirty: boolean = false;
  private isMappingDirty: boolean = false;
  // Array of components that have been added but not saved yet, used to properly set the discard possible state
  private pendingComponentInstanceConfigs: ComponentInstanceConfig[];

  private currentView?: View;

  // Event listener
  private configPanelListener: any;
  private removeComponentListener: any;
  private selectViewListener: any;
  private logger: Logger;
  private rootContainer: HTMLElement;
  private componentConfigs: Map<string, ComponentConfig>;
  private configMode: CONFIG_MODES;
  private isSaveInProgress: boolean = false;
  private configMapper: ConfigMapper;

  private toolStripOptions: ToolstripOption[];


  /**
   * Creates an instance of ConfigUiHelper.
   * @param {IViewManager} viewManager The view manager.
   * @param {IPubSubHandler} pubSubHandler The pubsub handler.
   * @param {ISubViewManager} subViewManager The subview manager.
   * @param {IConfigLoader} configLoader The configuration loader.
   * @param {IConfigManager} configManager The configuration manager.
   * @param {ILanguageManager} languageManager The language manager.
   * @param {IMetadataStore} metadataStore The metadata store.
   * @param {IPopupHelper} popupHelper The popup helper.
   * @param {HTMLElement} rootContainer The html root container element.
   * @param {IComponentManager} componentManager The component manager.
   * @param {Logger} logger The logger.
   * @param {IAppBarHandler} appBarHandler The appbar handler.
   * @param {IToolstripHandler} toolstripHandler The toolstrip handler.
   * @param {IPubSubUiHelper} pubSubUiHelper The pubsub UI helper.
   * @param {IPanelHandler} panelHandler The panel handler.
   * @param {IDeviceMenuHandler} deviceMenuHandler The device menu handler.
   * @param {IMultilingualTranslator} multilingualTranslator The multilingual translator.
   * @param {IUiManager} uiManager The UI manager.
   * @param {SharedSettingsProvider} sharedSettingsProvider The shared settings provider.
   * @param {ApplicationConfig} applicationConfig The application configuration.
   * @memberof ConfigUiHelper
   */
  public constructor(viewManager: IViewManager, pubSubHandler: IPubSubHandler, subViewManager: ISubViewManager,
    configLoader: IConfigLoader, configManager: IConfigManager, languageManager: ILanguageManager,
    metadataStore: IMetadataStore, popupHelper: IPopupHelper, rootContainer: HTMLElement, componentManager: IComponentManager, logger: Logger,
    appBarHandler: IAppBarHandler, toolstripHandler: IToolstripHandler, pubSubUiHelper: IPubSubUiHelper,
    panelHandler: IPanelHandler, deviceMenuHandler: IDeviceMenuHandler, multilingualTranslator: IMultilingualTranslator, uiManager: IUiManager,
    sharedSettingsProvider: SharedSettingsProvider | undefined, applicationConfig: ApplicationConfig) {
    this.viewManager = viewManager;
    this.pubSubHandler = pubSubHandler;
    this.subViewManager = subViewManager;
    this.configLoader = configLoader;
    this.configManager = configManager;
    this.metaDataStore = metadataStore;
    this.popupHelper = popupHelper;
    this.logger = logger;
    this.languageManager = languageManager;
    this.frameworkLanguageProvider = languageManager.getLanguageProvider('framework');
    this.currentDevice = DeviceDetection.getCurrentDevice();
    this.rootContainer = rootContainer;
    this.componentManager = componentManager;
    this.appBarHandler = appBarHandler;
    this.toolstripHandler = toolstripHandler;
    this.pubSubUiHelper = pubSubUiHelper;
    this.panelHandler = panelHandler;
    this.deviceMenuHandler = deviceMenuHandler;
    this.multilingualTranslator = multilingualTranslator;
    this.componentConfigs = new Map<string, ComponentConfig>();
    this.uiManager = uiManager;
    this.sharedSettingsProvider = sharedSettingsProvider;
    this.applicationConfig = applicationConfig;

    this.configMode = CONFIG_MODES.NONE;
    this.configActive = false;

    this.pendingComponentInstanceConfigs = new Array<ComponentInstanceConfig>();
    this.configMapper = new ConfigMapper(this.logger);
    this.configMapper.addMappingHandler(new SubstitutionMappingHandler());
    this.configMapper.addMappingHandler(new SearchQueryMappingHandler());
    this.configMapper.addMappingHandler(new ColumnsMappingHandler());
    this.configMapper.addMappingHandler(new ObjectTypeMappingHandler());
    this.configMapper.addMappingHandler(new PathMappingHandler());

    if (!Application.hasExternalCore()) {
      this.mappingUiHelper = new MappingUiHelper(this.logger, this.componentManager, this.pubSubHandler, this.configMapper,
        this.languageManager.getLanguageProvider('framework'), this.popupHelper, NotificationHelper);
      this.mappingUiHelper.setConfigUiHelper(this);
    }

    this.toolStripOptions = [];
  }

  /**
   * Whether the config has unsaved changes.
   *
   * @static
   * @type {boolean}
   * @memberof ConfigUiHelper
   */
  private static configHasUnsavedChanges: boolean = false;

  /**
   * Gets whether the config has unsaved changes.
   *
   * @static
   * @returns {boolean} A value indicating if the configuration has changes.
   * @memberof ConfigUiHelper
   */
  public static hasUnsavedChanges(): boolean {
    return ConfigUiHelper.configHasUnsavedChanges;
  }

  /**
   * Sets up configuration of components.
   * Binds events to UI elements (buttons).
   * Sets up (on-the-fly) PubSub configuration for communication between UI Manager and configuration component (com.windream.configuration).
   *
   * @returns {void}
   *
   * @memberof ConfigUiHelper
   */
  public setupConfig(): void {
    const methodName = 'setupConfig';
    // Initialize the device switch button.
    this.deviceMenuHandler.init();
    this.deviceMenuHandler.onDeviceChange = this.onSwitchDevice.bind(this);

    this.pubSubUiHelper.onConfigChange = (isDirty: boolean) => {
      this.isPubSubDirty = isDirty;
      this.displayDirtyState();
      const pubSubViewConfig = this.pubSubUiHelper.getCurrentViewConfig();
      if (pubSubViewConfig) {
        this.updateCurrentViewWithViewConfig(pubSubViewConfig);
      }
    };

    if (this.mappingUiHelper) {
      this.mappingUiHelper.onConfigChange = (isDirty: boolean) => {
        this.isMappingDirty = isDirty;
        this.displayDirtyState();
        let mappingUiHelperConfig;
        if (this.mappingUiHelper) {
          mappingUiHelperConfig = this.mappingUiHelper.getCurrentViewConfig();
        }
        if (mappingUiHelperConfig && !Utils.Instance.isDeepEqual(this.getCurrentViewConfig().components, mappingUiHelperConfig?.components)) {
          this.getCurrentViewConfig().components = Utils.Instance.deepClone(mappingUiHelperConfig.components);
          // Flag each component as dirty if config changed
          this.getCurrentViewConfig().components.forEach((component) => {
            this.flagComponentAsDirty(component);
          });
          // If components did change, mapping has been applied
          if (this.currentlyEditedComponentGuid) {
            const mappedConfigOfActiveComponent = mappingUiHelperConfig.components.find((component) => component.guid === this.currentlyEditedComponentGuid);
            if (mappedConfigOfActiveComponent) {
              this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'SelectedComponent', {
                currentSettings: mappedConfigOfActiveComponent || {},
                viewConfig: this.getCurrentViewConfig() || {}
              });
            }
          }
        }
      };
    }

    this.addAllConfigPubSub();
    // PubSub for live editing of styles
    this.pubSubHandler.subscribe(PubSubConstants.STYLES_ID, 'SelectedStyle', (stylePromise: Promise<string>) => {
      stylePromise.then((style) => {
        this.handleStyleChange(style);
      }).catch((error) => {
        this.logger.error(this.className, methodName, 'Style editing pubsub catched an error', error);
      });
    });

    // PubSub for discarding triggeredfrom the configuraiton component
    this.pubSubHandler.subscribe(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'DiscardCurrentConfig', () => {
      this.discardCurrentlyEdited();
    });

    // PubSub for detecting changes in the view
    this.pubSubHandler.subscribe(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'ViewHasChanged', (newConfigPromise: Promise<ViewConfig>) => {
      if (this.configMode !== CONFIG_MODES.VIEW || !this.configActive) {
        return;
      }
      newConfigPromise.then((newConfig) => {
        const hasChanged = this.hasViewConfigPropertiesChanged(newConfig);
        this.renderSubViewNavigation(newConfig.subViews);
        this.updateView(newConfig);
        this.displayDirtyState();
        this.setDiscardPossible(hasChanged);
      }).catch((error) => {
        this.logger.error(this.className, methodName, 'View has changed pubsub catched an error', error);
      });
    });

    // PubSub for detecting changes in the component beding edited
    this.pubSubHandler.subscribe(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'ComponentHasChanged', (newConfigPromise: Promise<ComponentInstanceConfig>) => {
      if (this.configMode !== CONFIG_MODES.COMPONENT || !this.configActive) {
        return;
      }
      newConfigPromise.then((newConfig) => {
        this.flagComponentAsDirty(newConfig);
        this.updateComponent(newConfig);
        this.displayDirtyState();
      }).catch((error) => {
        this.logger.error(this.className, 'setupConfig', 'ComponentHasChanged pubsub catched an error', error);
      });
    });

    if (this.appBarHandler.subViewNavigationHandler) {
      this.appBarHandler.subViewNavigationHandler.onAddSubView = this.addSubView.bind(this);
      this.appBarHandler.subViewNavigationHandler.onDelete = this.deleteSubView.bind(this);
      this.appBarHandler.subViewNavigationHandler.onRename = this.renameSubView.bind(this);
      this.appBarHandler.subViewNavigationHandler.onSelect = (subViewId: string) => {
        this.clearComponentHighlight();
        this.pubSubUiHelper.switchSubView(subViewId);
        this.subViewManager.switchSubView(subViewId, true);
        this.toolstripHandler.updateActiveIndex(0);
        this.appBarHandler.subViewNavigationHandler?.update(this.getCurrentViewConfig().subViews, subViewId);
        this.rootContainer.classList.remove('pubsub-active');
        this.rootContainer.classList.remove('mapping-active');
        this.openViewConfig();
      };
    }
  }

  /**
   * Saves the current view Config and grid.
   *
   * @returns {Promise<void>} Promise that resolves once saving is complete.
   * @memberof ConfigUiHelper
   */
  public onSave(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if currently configured component/view is valid
      this.isConfigurationComponentValid()
        .then((isValid) => {
          if (!isValid) {
            this.logger.debug('ConfigUiHelper', 'onSave', 'Current configuration is not valid');
            reject(new Error('Current configuration is not valid'));
            return;
          }

          this.updateLayout();
          this.handleSaveIntent().then(() => resolve()).catch((err) => {
            this.logger.error('ConfigUiHelper', 'onSave', 'Failed to save ViewConfig', err);
            reject(err ?? new Error('Failed to save ViewConfig'));
          });
        }).catch((err) => {
          this.logger.error('ConfigUiHelper', 'onSave', 'Cannot determine whether current configuration is valid', err);
          reject(err ?? new Error('Cannot determine whether current configuration is valid'));
        });
    });
  }

  /**
   * Removes a given component from the current view.
   * Removes the component from the DOM.
   * Deletes all PubSub connections of that component.
   *
   * @private
   * @param {string} guid GUID of the component to remove.
   * @param {boolean} [isSilent=false] Whether to display a success toast.
   *
   * @memberof ConfigUiHelper
   */
  public removeComponent(guid: string, isSilent = false): void {
    // Remove the component from the sub-views.
    this.removeComponentFromSubViews(guid);

    // Remove component from configuration
    this.getCurrentViewConfig().components = this.getCurrentViewConfig().components.filter((componentInstanceConfig: ComponentInstanceConfig) => {
      if (componentInstanceConfig.guid === guid) {
        // This component should be removed
        for (const lang in componentInstanceConfig.name) {
          if (!componentInstanceConfig.name.hasOwnProperty(lang)) {
            continue;
          }
          const name = componentInstanceConfig.name[lang];
          this.viewManager.removeUsedComponentName(name);
        }
        return false;
      }

      return true;
    });

    // Destroy component
    this.viewManager.removeComponent(guid);

    // Remove component from DOM
    const currentLayoutManagers = this.subViewManager.getCurrentLayoutManagers();
    if (currentLayoutManagers) {
      currentLayoutManagers.forEach((layoutManager) => {
        if (layoutManager.hasComponent(guid)) {
          layoutManager.removeComponent(guid);
        }
      });
    } else {
      throw new Error('Failed to remove component from DOM. No LayoutManager selected.');
    }

    // Clear config components if current component was displayed
    // Bypass validility check in order to be able to get back to view config
    if (this.currentlyEditedComponentGuid === guid) {
      delete this.currentlyEditedComponentGuid;
      this.openViewConfig(true);
    }

    const newPubSubs = new Array<PubSubConfig>();

    this.getCurrentViewConfig().pubSub.forEach((pubSub: PubSubConfig) => {
      const newPubSub = {
        executeForEmptyData: pubSub.executeForEmptyData,
        in: new Array<Connection>(),
        out: new Array<Connection>()
      };
      newPubSub.in = pubSub.in.filter((inParameter: Connection) => {
        return inParameter.componentGuid !== guid;
      });

      newPubSub.out = pubSub.out.filter((outParameter: Connection) => {
        return outParameter.componentGuid !== guid;
      });

      if (newPubSub.in.length > 0 && newPubSub.out.length > 0) {
        newPubSubs.push(newPubSub);
      }
    });

    // Clear triggers
    for (const triggerName in this.getCurrentViewConfig().triggers) {
      if (!this.getCurrentViewConfig().triggers.hasOwnProperty(triggerName)) {
        continue;
      }
      const triggers = this.getCurrentViewConfig().triggers[triggerName];
      this.getCurrentViewConfig().triggers[triggerName] = triggers.filter((trigger) => {
        return !(trigger.inId.startsWith(guid) || trigger.outId.startsWith(guid));
      });
    }

    this.getCurrentViewConfig().pubSub = newPubSubs;

    // Re-init view PubSub with new configuration
    this.viewManager.updateViewPubSubHandler(this.getCurrentViewConfig().pubSub, this.getCurrentViewConfig().triggers);

    // Send update view config to other UI helpers
    this.updateOtherHelpers();

    this.displayDirtyState();

    if (!isSilent) {
      NotificationHelper.Instance.success({
        body: this.frameworkLanguageProvider.get('framework.toastr.componentremoved.body'),
        title: this.frameworkLanguageProvider.get('framework.generic.success')
      });
    }

    // Remove component from list of unsaved components
    const index = this.pendingComponentInstanceConfigs.findIndex((component) => component.guid === guid);
    if (index === -1) {
      this.pendingComponentInstanceConfigs.splice(index, 1);
    }
  }

  /**
   * Parses the event and calls `toggleEditMode`.
   * Handles classes of clicked button and the body.
   *
   * @returns {Promise<boolean>} Promise to resolve with whether edit mode was left or not.
   * @async
   *
   * @memberof ConfigUiHelper
   */
  public async toggleEdit(): Promise<boolean> {
    if (this.configActive) { // Edit is already active, deactivate
      return this.closeConfig();
    } else { // Edit is not active, activate
      return this.openConfig();
    }
  }

  /**
   * Render the navigation to switch between subviews in the edit mode.
   *
   * @param {SubViewConfig[]} subViewConfigs The subview configurations used to update the subview navigation.
   *
   * @memberof ConfigUiHelper
   */
  public renderSubViewNavigation(subViewConfigs: SubViewConfig[]): void {
    const currentSubViewId = this.subViewManager.getCurrentSubViewId();
    this.appBarHandler.subViewNavigationHandler?.update(subViewConfigs, currentSubViewId || undefined);
  }

  /**
   * Destroys the Config UI Helper and unbinds events.
   */
  public destroy(): void {
    this.removeAllEventListener();
    // Reset edit
    this.rootContainer.classList.remove('edit-active');
    this.rootContainer.classList.remove(...DEVICE_NAMES.map((device) => `edit-device-${device}`));
    this.configActive = false;
    if (this.makeNavigationEditable) {
      this.makeNavigationEditable(false);
    }
    // Clear DOM
    this.resetConfigurationPanels();
  }

  /**
   * Updates the used view configuration with the new one.
   * Updates the subview navigation.
   *
   * @param {ViewConfig} viewConfig The current view config.
   *
   * @memberof ConfigUiHelper
   */
  public updateCurrentViewConfig(viewConfig: ViewConfig): void {

    this.updateCurrentViewWithViewConfig(viewConfig);

    this.appBarHandler.updateCurrentViewName(this.frameworkLanguageProvider.getTranslationFromProperty(viewConfig.name));

    // Set backup
    this.viewConfigBackup = Utils.Instance.deepClone(this.getCurrentViewConfig());
    this.logger.debug(this.className, 'updateCurrentViewConfig', 'Setting backup for current view config', this.getCurrentViewConfig());

    // Set up rendering subview navigation
    this.renderSubViewNavigation(this.getCurrentViewConfig().subViews);

    // Reset ToolstripHandler
    this.toolstripHandler.updateActiveIndex(0);

    // Send update view config to other UI helpers
    this.updateOtherHelpers();

    // Reset configuration panels
    // This has to be done last so the PubSub callback will have the correct value for currentViewConfig
    this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'SelectedView', viewConfig);

    // Set subview editable if edit mode is active
    if (this.configActive) {
      this.subViewManager.setEditable(true);
    }
  }

  /**
   * Sets the given device specific view configuration to the view.
   *
   * @private
   * @param {ViewConfig} viewConfig The view configuration.
   * @memberof ConfigUiHelper
   */
  public updateCurrentViewWithViewConfig(viewConfig: ViewConfig): void {
    if (!Utils.isDefined(this.currentView) || this.currentView.id !== viewConfig.id) {
      this.currentView = new View(viewConfig.id);
    }
    if (Utils.isDefined(viewConfig.device)) {
      this.currentView.configurations.set(viewConfig.device, viewConfig);
    } else {
      this.logger.debug(this.className, 'updateCurrentViewWithViewConfig', 'The device of the given view configuration is not defined.', viewConfig);
    }
  }

  /**
   * Gets the view configuration of the specified device.
   *
   * @param {DWCore.Common.Devices} device The device of the desired view configuration.
   * @returns {(ViewConfig | undefined)} The found view configuration.
   * @memberof ConfigUiHelper
   */
  public getViewConfig(device: DWCore.Common.Devices): Promise<ViewConfig | undefined> {
    return new Promise<ViewConfig | undefined>((resolve, reject) => {
      if (this.currentView) {

        if (this.currentView.configurations.has(device)) {
          resolve(this.currentView.configurations.get(device));
        } else {
          this.configLoader.loadViewConfig(this.currentView.id, device).then((viewConfig) => {
            this.updateCurrentViewWithViewConfig(viewConfig);
            resolve(viewConfig);
          }).catch((err: Error) => {
            this.logger.debug('ConfigUiHelper', 'getViewConfig', 'Cannot load the view configuration.', err);
            reject(err);
          });
        }
      } else {
        reject(new Error('No current view set.'));
      }
    });
  }

  /**
   * Sets the view configuration.
   *
   * @param {DWCore.Common.Devices} device The device of the view configuration.
   * @param {ViewConfig} viewConfig The view configuration.
   * @memberof ConfigUiHelper
   */
  public setViewConfig(device: DWCore.Common.Devices, viewConfig: ViewConfig): void {
    if (this.currentView) {
      this.currentView.configurations.set(device, viewConfig);
    }
  }

  /**
   * Updates the used view Manager with the new one.
   *
   * @param {IViewManager} viewManager  New view Manager to use.
   *
   * @memberof ConfigUiHelper
   */
  public updateCurrentViewManager(viewManager: IViewManager): void {
    this.viewManager = viewManager;
  }


  /**
   * Opens the configuration for the view.
   *
   * @protected
   * @param {boolean} [forceOpen] Whether the opening of the configuration should be forced.
   * @memberof ConfigUiHelper
   */
  protected openViewConfig(forceOpen?: boolean): void {

    const _openViewConfig = () => {
      this.configMode = CONFIG_MODES.VIEW;
      this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'SelectedView', this.getCurrentViewConfig());
      // Check whether discard is possible, i.e. because view has been edited earlier already
      const isDiscardPossible = this.hasViewConfigPropertiesChanged(this.getCurrentViewConfig());
      this.setDiscardPossible(isDiscardPossible);

      // Style: Colors
      let tempStyle = this.getCurrentViewConfig().style?.colors;
      if (!tempStyle) {
        tempStyle = 'wd-style-colors-15';
      }
      this.pubSubHandler.publish(PubSubConstants.STYLES_ID, 'UpdateOpenConfigSelectedStyle', tempStyle);

      // Deselect any selected component
      this.clearComponentHighlight();
    };

    if (!forceOpen) {
      // Check if currently configured component/view is valid
      this.isConfigurationComponentValid().then((isValid) => {
        if (isValid) {
          _openViewConfig();
        } else {
          this.logger.debug('ConfigUiHelper', 'openViewConfig', 'Current configuration is not valid');
        }
      }).catch((err: Error) => {
        this.logger.debug('ConfigUiHelper', 'openViewConfig', 'Cannot determine whether current configuration is valid', err);
      });
    } else {
      _openViewConfig();
    }

  }

  /**
   * Adds the given components to the current view.
   *
   * @protected
   * @param {string[]} componentIds List of component IDs to add.
   * @param {Map<string, ComponentConfig>} componentConfigs Matching component configurations.
   *
   * @memberof ConfigUiHelper
   */
  protected addComponents(componentIds: string[], componentConfigs: Map<string, ComponentConfig>): void {
    const methodName: string = 'addComponents';

    componentIds.forEach((componentId: string) => {
      const componentConfig: ComponentConfig | undefined = componentConfigs.get(componentId);
      if (!componentConfig) {
        this.logger.error(this.className, methodName, 'No component config found for component' + componentId);
        return;
      }
      const componentName = {} as IConfigTranslation<string>;
      componentName[this.languageManager.getLanguageCultureName()] = this.getNameForComponent(componentConfig);

      const newComponentConfig: ComponentInstanceConfig = {
        component: componentId,
        componentType: componentConfig.isLogic ? DWCore.Components.COMPONENT_TYPES.LOGICAL : DWCore.Components.COMPONENT_TYPES.UI,
        configuration: {},
        guid: Utils.Instance.getUUID(),
        isTitleVisible: false,
        name: componentName,
        position: null,
        style: Style.default(),
        title: { de: this.getNameForComponent(componentConfig) },
        version: componentConfig.version
      };

      this.addComponent(newComponentConfig);
    });
  }

  /**
   * Returns the current view config.
   *
   * @protected
   * @returns {ViewConfig} The current view configuration.
   * @memberof ConfigUiHelper
   */
  protected getCurrentViewConfig(): ViewConfig {
    let currentViewConfig: ViewConfig | undefined;
    if (this.currentView) {
      if (Utils.isDefined(this.currentDevice)) {
        if (this.currentView.configurations.has(this.currentDevice)) {
          currentViewConfig = this.currentView?.configurations.get(this.currentDevice);
        } else {
          throw new Error('ConfigUiHelper.getCurrentViewConfig(): No configuration for the current device was found.');
        }
      } else {
        throw new Error('ConfigUiHelper.getCurrentViewConfig(): No current device set.');
      }
    } else {
      throw new Error('ConfigUiHelper.getCurrentViewConfig(): No current view set.');
    }

    if (currentViewConfig) {
      return currentViewConfig;
    } else {
      throw new Error('ConfigUiHelper.getCurrentViewConfig(): No current view configuration available.');
    }
  }

  /**
   * Gets the current view identifier.
   *
   * @private
   * @returns {string} The current view identifier.
   * @memberof ConfigUiHelper
   */
  private getCurrentViewIdentifier(): string {
    if (this.currentView) {
      return this.currentView.id;
    } else {
      throw new Error('ConfigUiHelper.getCurrentViewConfig(): No current view set.');
    }
  }

  /**
   * Toggles edit mode on or off.
   * Sets class to the button that called this function.
   * Invokes lifcecycle callbacks from the public API.
   * Loads current view's config into `currentViewConfig` object.
   *
   * @async --exclude=
   * @param {boolean} enableEdit  Whether to enable or disable the edit mode.
   * @returns {Promise<boolean>} Promise to resolve with whether edit mode was left or not.
   * @memberof ConfigUiHelper
   */
  private async toggleEditMode(enableEdit: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      /**
       * Deactivate edit state.
       * Remove active class from button.
       * Disable edit of lifecycle/grid.
       * Jump back do desktop device.
       * 
       * @param {boolean} hasSaved Indicates if the changes were saved and edit mode can be leaved.
       */
      const deactivateEdit = (hasSaved: boolean) => {
        // Reset to desktop configuration, if it is something else
        const deviceSwitchPromise = new Promise<void>((resolve) => {
          this.viewManager.updateViewPubSubHandler(this.getCurrentViewConfig().pubSub, this.getCurrentViewConfig().triggers);
          if (this.currentDevice !== DWCore.Common.Devices.DESKTOP) {
            this.switchDevice(DWCore.Common.Devices.DESKTOP).then(() => {
              // Reactivate default device button
              this.deviceMenuHandler.changeDevice(DWCore.Common.Devices.DESKTOP);
              this.currentDevice = DWCore.Common.Devices.DESKTOP;
              resolve();
            }).catch((err: Error) => {
              this.logger.error(this.className, 'toggleEditMode', 'Failed to switch device', err);
            });
          } else {
            resolve();
          }
        });

        deviceSwitchPromise.then(() => {
          // Switch back to first subview
          this.subViewManager.resetNavigationHistory();
          this.subViewManager.switchSubView(this.getCurrentViewConfig().subViews[0].id, true);
          // Re-render subview navigation
          this.renderSubViewNavigation(this.getCurrentViewConfig().subViews);

          this.waitForSave().then(() => {
            // This should only happen when the saving was successful
            // Execute callback
            if (hasSaved) {
              // Do not leave edit mode if configuration component is not valid
              // Trigger leave edit mode
              this.leaveEditMode();
              resolve(true);
            } else {
              resolve(false);
            }
          }).catch((err: Error) => {
            this.logger.error(this.className, 'toggleEditMode', 'Failed to wait for save', err);
            reject(err);
          });
        }).catch((err: Error) => {
          this.logger.error(this.className, 'toggleEditMode', 'Failed to switch device', err);
        });
      };
      // Enable edit mode
      if (enableEdit) {
        this.uiManager.displayBusyStateIndicator();
        ConfigUiHelper.configHasUnsavedChanges = this.isConfigDirty();
        this.setupToolstripHandler();
        // Remove error containers
        this.viewManager.removeAllComponentOverlayContainers();
        // Load skeletons
        this.viewManager.showSkeletons(true);
        this.registerLayoutManagerChangeCallbacks();
        Promise.all([
          this.loadConfigurationComponent(),
          this.loadAddComponent()
        ]).then(() => {
          this.openViewConfig();
          // Re-render subview navigation
          this.renderSubViewNavigation(this.getCurrentViewConfig().subViews);
          this.subViewManager.setEditable(true);
          // Reset ToolstripHandler
          this.toolstripHandler.updateActiveIndex(0);
          // Execute callback
          resolve(true);
        }).catch((err: Error) => {
          this.logger.error(this.className, 'toggleEditMode', 'Failed to load edit mode components', err);
          reject(err);
        });
      } else { // Disable edit mode
        const pubSubUiManagerViewConfig = this.pubSubUiHelper.getCurrentViewConfig();
        if (pubSubUiManagerViewConfig) {
          this.getCurrentViewConfig().pubSub = pubSubUiManagerViewConfig.pubSub;
          this.getCurrentViewConfig().triggers = pubSubUiManagerViewConfig.triggers;

          this.checkEverythingForChanges(deactivateEdit);
        } else {
          this.logger.error(this.className, 'toggleEditMode', 'PubSubUiHelper has invalid ViewConfig');
        }
      }
    });
  }

  /**
   * Disable pubsub button in toolstrip if components have errors.
   *
   * @private
   * @returns {void}
   * @memberof ConfigUiHelper
   */
  private setupToolstripHandler(): void {

    this.toolStripOptions.length = 0;
    const hasErrors = this.getCurrentViewConfig().components.filter((component) => this.componentManager.isBrokenComponent(component.guid));

    // Components
    const componentsOption = new ToolstripOption(this.frameworkLanguageProvider.get('framework.header.toolstrip.components'), 'components');
    componentsOption.selected = true;
    componentsOption.onClick = () => {
      Promise.all([
        this.pubSubUiHelper.hide(),
        this.mappingUiHelper ? this.mappingUiHelper.hide() : Promise.resolve()
      ]).catch((err: Error) => {
        this.logger.error(this.className, 'setupToolstripHandler', 'Failed to hide another config', err);
      });
      this.rootContainer.classList.remove('pubsub-active');
      this.rootContainer.classList.remove('mapping-active');
    };
    this.toolStripOptions.push(componentsOption);

    // Connections
    // If there are components with errors the PubSub configuration is not available
    if (!hasErrors || hasErrors.length === 0) {
      const pubSubOption = new ToolstripOption(this.frameworkLanguageProvider.get('framework.header.toolstrip.connections'), 'connection');
      pubSubOption.onClick = () => {
        Promise.all([
          this.mappingUiHelper ? this.mappingUiHelper.hide() : Promise.resolve()
        ]).then(() => {
          this.pubSubUiHelper.show();
        }).catch((err: Error) => {
          this.logger.error(this.className, 'setupToolstripHandler', 'Failed to hide another config', err);
        });

        this.rootContainer.classList.add('pubsub-active');
        this.rootContainer.classList.remove('mapping-active');
      };
      this.toolStripOptions.push(pubSubOption);
    }

    // Mappings
    if (this.mappingUiHelper) {
      const mappingOptions = new ToolstripOption(this.frameworkLanguageProvider.get('framework.header.toolstrip.mappings'), 'modify');
      mappingOptions.onClick = () => {
        this.pubSubUiHelper.hide().catch((err: Error) => {
          this.logger.error(this.className, 'setupToolstripHandler', 'Failed to hide PubSub config', err);
        }).then(() => {
          return this.mappingUiHelper ? this.mappingUiHelper.show() : Promise.resolve();
        }).catch((err: Error) => {
          this.logger.error(this.className, 'setupToolstripHandler', 'Failed to show mapping config', err);
        });
        this.rootContainer.classList.add('mapping-active');
        this.rootContainer.classList.remove('pubsub-active');
      };
      this.toolStripOptions.push(mappingOptions);
    }

    // Render all options
    this.toolstripHandler.render(this.toolStripOptions);
  }

  /**
   * Wait for the saving to occur.
   * Will poll for a save promise five times every 10 ms.
   * If a promise is found, the resolve/reject will be passed to the promise this function returns.
   * If no promise is found, we can assume that no saving was made and the returned promise will resolve.
   *
   * @private
   * @returns {Promise<void>} Promise to resolve after saving was made or if no saving was performed during a specified period.
   * @memberof ConfigUiHelper
   */
  private async waitForSave(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const maxTries = 5;
      const triesTimeout = 10;
      let currentTries = 0;

      const wait = () => {
        setTimeout(() => {
          if (!this.waitForSavePromise) {
            if (++currentTries <= maxTries) {
              // Try again
              wait();
            } else { // If nothing is found, save was not triggered (i.e. no changes were made)
              resolve();
            }
          } else {
            this.waitForSavePromise.then(resolve, reject).catch((error: Error) => {
              this.logger.error(this.className, 'waitForSave', 'Error during waiting for saving', error);
            });
          }
        }, triesTimeout);
      };
      wait();
    });
  }

  /**
   * Removes the component with the given GUID from the current view configuration's sub-views-collection.
   *
   * @private
   * @param {string} guid The component's identifier.
   * @memberof ConfigUiHelper
   */
  private removeComponentFromSubViews(guid: string): void {
    if (!guid) {
      this.logger.error(this.className, 'removeComponentFromSubViews', 'Remove component from sub-views', 'The given component GUID was null or undefined.');
      return;
    } else if (!this.getCurrentViewConfig()) {
      this.logger.error(this.className, 'removeComponentFromSubViews', 'Remove component from sub-views', 'The current view config is null or undefined.');
      return;
    } else if (!this.getCurrentViewConfig().subViews) {
      this.logger.error(this.className, 'removeComponentFromSubViews', 'Remove component from sub-views', 'The current view config\'s sub-views collection is null or undefined.');
      return;
    }

    // Remove the component from the sub-views-collections.
    for (const subViewConfig of this.getCurrentViewConfig().subViews) {
      if (subViewConfig) {
        subViewConfig.components = subViewConfig.components.filter((guidInSubView: string) => {
          return guidInSubView !== guid;
        });
      }
    }
  }

  /**
   * Registers on change callback for .
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private registerLayoutManagerChangeCallbacks(): void {
    const subViewLayoutManagers = this.subViewManager.getLayoutManagersPerSubView();
    if (subViewLayoutManagers) {
      subViewLayoutManagers.forEach((layoutManagers) => {
        layoutManagers.forEach((manager) => {
          manager.onLayoutChange = (_newLayout: LayoutConfiguration) => {
            this.updateLayout();
            this.displayDirtyState();
          };
        });
      });
    }
  }

  /**
   * Updates the component positions inside the layouts and writing them into the current ViewConfig model.
   * Does not save the configuration.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private updateLayout(): void {
    const currentLayoutManagers = this.subViewManager.getCurrentLayoutManagers();
    if (currentLayoutManagers) {
      currentLayoutManagers.forEach((layoutManager) => {
        const gridConfiguration = layoutManager.getComponentPositions();
        for (const guid in gridConfiguration) {
          if (gridConfiguration.hasOwnProperty(guid)) {
            // Update current view Config
            this.getCurrentViewConfig().components.forEach((component, index) => {
              if (component.guid !== guid) {
                return;
              }
              this.getCurrentViewConfig().components[index].position = gridConfiguration[guid];
            });
          }
        }
      });
    }
  }

  /**
   * Discards the changes and resets to the saved configuration.
   * Emits corresponding PubSub event to be handled by the configuration component.
   *
   * @private
   * @returns {Promise<ViewConfig>} Promise to resolve with the new ViewConfig.
   * @async
   *
   * @memberof ConfigUiHelper
   */
  private async discard(): Promise<ViewConfig> {

    this.updateCurrentViewWithViewConfig(this.viewConfigBackup ? Utils.Instance.deepClone(this.viewConfigBackup) : this.getCurrentViewConfig());

    this.updateOtherHelpers();
    // Reset PubSub editor dirty flag
    this.isPubSubDirty = false;

    // Reset mapping editor dirty flag
    this.isMappingDirty = false;

    // Set UI flag for all components to false
    const layoutManagers = this.subViewManager.getCurrentLayoutManagers();
    if (layoutManagers) {
      const hasChanges = false;
      this.getCurrentViewConfig().components.forEach((component) => {
        layoutManagers.forEach((layoutManager) => {
          layoutManager.setPendingChangesFlag(component.guid, hasChanges);
        });
      });
    }
    // Set UI flag for view to false
    this.appBarHandler.currentViewHasChanges(false);
    // Reset name in navigation
    const viewName = this.frameworkLanguageProvider.getTranslationFromProperty(this.getCurrentViewConfig().name);
    this.appBarHandler.updateCurrentViewName(viewName);
    this.appBarHandler.viewNavigationHandler?.renameView(this.getCurrentViewConfig().id, viewName);
    // Reset alias to update the navigation links
    const alias = this.getCurrentViewConfig().alias;
    this.appBarHandler.viewNavigationHandler?.updateViewAlias(this.getCurrentViewConfig().id, alias);

    // Reset un-saved components
    this.pendingComponentInstanceConfigs.length = 0;
    ConfigUiHelper.configHasUnsavedChanges = false;

    const viewInitializationOptions = new ViewInitializationOptions();
    viewInitializationOptions.promoteLifecycleSteps = true;
    return this.viewManager.initView(this.getCurrentViewConfig().id, viewInitializationOptions, this.currentDevice);
  }


  /**
   * Highlights the component.
   * Skeleton image will be displayed colorful.
   *
   * @private
   * @param {string} componentGuid The guid of the component that shall be highlighted.
   * @memberof ConfigUiHelper
   */
  private highlightComponent(componentGuid: string): void {
    const componentRootElement = this.rootContainer.querySelector(`div[data-guid='${componentGuid}']`);
    this.clearComponentHighlight();

    if (componentRootElement) {
      componentRootElement.classList.add('edit');
    }
  }


  /**
   * Remove the component highlight.
   * Skeleton image will be displayed in grayscale.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private clearComponentHighlight(): void {
    // Remove active filter for other components
    const activeSkeletons = this.rootContainer.querySelectorAll<HTMLDivElement>('div[data-guid].edit');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < activeSkeletons.length; i++) {
      activeSkeletons.item(i).classList.remove('edit');
    }
  }

  /**
   * Loads configuration component (com.windream.configuration) into the panel if it not already exists there.
   * Configuration type is 'view'.
   * If component already exists, the component to be configured is changed via PubSub (set up in `setupConfig()`).
   * @param {Event} e Event that opened the panel (click event).
   */
  private openComponentConfig(e: Event): void {
    const methodName: string = 'openComponentConfig';
    // Only check HTMLElements and exclude SVGElements etc.
    if (e.target instanceof HTMLElement) {
      const clickTarget = e.target;

      // Get component guid.
      let guid: string | null = null;
      const amountOfParents = 5;
      const tempRootElement = Utils.parentsUntil(clickTarget, (element: HTMLElement) => element.classList.contains('grid-stack-item'), amountOfParents);
      guid = tempRootElement.getAttribute('data-guid');

      if (!guid) {
        return;
      }

      /**
       * Function to render configuration component for a component.
       */
      const _openComponentConfig = () => {
        this.configMode = CONFIG_MODES.COMPONENT;
        // Get current component configuration from view configuraiton by GUID
        const componentInstanceConfig = this.getAllComponentInstanceConfigsFlat(this.getCurrentViewConfig().components).find((component) => {
          return component.guid === guid;
        });

        if (!componentInstanceConfig) {
          this.logger.error(this.className, methodName, 'No component instance config found for GUID: ' + guid);
          return;
        }

        if (this.componentConfigs.size === 0) {
          this.logger.error(this.className, 'openComponentConfig', 'No ComponentConfig found', this.componentConfigs);
        }

        const componentConfig = this.componentConfigs.get(componentInstanceConfig.component);
        if (!componentConfig) {
          this.logger.warn(this.className, methodName, 'No component config found for:' +
            this.frameworkLanguageProvider.getTranslationFromProperty(componentInstanceConfig.name));
          NotificationHelper.Instance.error({
            body: this.frameworkLanguageProvider.getWithFormat('errors.componentNotFound', this.frameworkLanguageProvider.getTranslationFromProperty(componentInstanceConfig.name)),
          });
          return;
        }
        this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'SelectedComponent', {
          currentSettings: componentInstanceConfig || {},
          viewConfig: this.getCurrentViewConfig() || {}
        });

        if (this.viewConfigBackup) {
          const componentInstanceConfigBackup = this.getAllComponentInstanceConfigsFlat(this.viewConfigBackup.components).find((component) => {
            return component.guid === guid;
          });
          // Check whether discard is possible, i.e. because component has been edited earlier already
          const isDiscardPossible = !!componentInstanceConfigBackup && !Utils.Instance.isDeepEqual(componentInstanceConfigBackup, componentInstanceConfig);
          this.setDiscardPossible(isDiscardPossible);
        }

        // Update styles in the open configuration
        const currentLayotManager = this.subViewManager.getCurrentLayoutManager(componentConfig.isLogic ? DWCore.Components.COMPONENT_TYPES.LOGICAL : DWCore.Components.COMPONENT_TYPES.UI);
        if (currentLayotManager) {
          // Style: Colors
          const styleType = 'wd-style-colors';
          const tempStyle = currentLayotManager.getComponentStyle(componentInstanceConfig.guid, styleType);
          this.pubSubHandler.publish(PubSubConstants.STYLES_ID, 'UpdateOpenConfigSelectedStyle', tempStyle);
        }

        if (guid) {
          this.currentlyEditedComponentGuid = guid;
        }
      };
      const componentHasErrors = this.getCurrentViewConfig().components.find((component) => {
        return component.guid === guid && this.componentManager.isBrokenComponent(component.guid);
      });
      if (componentHasErrors) {
        NotificationHelper.Instance.error({
          body: this.frameworkLanguageProvider.getWithFormat('errors.componentNotFound', componentHasErrors.component),
        });
        return;
      }
      // Remove highlighting when view config is selected
      if (clickTarget.matches('.root, .wd-mainframe *') || clickTarget.matches('.grid, .grid-stack *')) {
        this.clearComponentHighlight();
        e.stopPropagation();
      }

      // Check whether a component's config button or skeleton area or skeleton icon was clicked
      // Needed because this function is bound to the whole document
      // Abort if click was targeted on anything else
      if (
        !clickTarget.matches('.edit-config-btn, .edit-config-btn *, .wd-skeleton, .wd-skeleton img')
      ) {
        return;
      }
      if (guid) {
        this.highlightComponent(guid);
      }

      // Check if currently configured component/view is valid
      this.isConfigurationComponentValid().then((isValid) => {
        if (isValid) {
          _openComponentConfig();
        } else {
          this.logger.debug('ConfigUiHelper', 'openComponentConfig', 'Current configuration is not valid');
        }
      }).catch((err: Error) => {
        this.logger.debug('ConfigUiHelper', 'openComponentConfig', 'Cannot determine whether current configuration is valid', err);
      });
    }
  }

  /**
   * Handles the save intent.
   *
   * @private
   * @returns {Promise<void>}
   * @memberof ConfigUiHelper
   */
  protected handleSaveIntent(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      // Save all device configurations.
      const promises = new Array<Promise<ViewConfig | undefined>>();

      promises.push(this.getViewConfig(DWCore.Common.Devices.DESKTOP));
      promises.push(this.getViewConfig(DWCore.Common.Devices.TABLET));
      promises.push(this.getViewConfig(DWCore.Common.Devices.PHONE));

      const currentViewConfig = this.getCurrentViewConfig();
      Promise.all(promises).then((viewConfigurations: ViewConfig[]) => {

        // Order the view configurations so that the current view configuration is the last within the array.
        const viewConfigurationsWithoutCurrent = viewConfigurations.filter((viewConfig) => viewConfig.device !== currentViewConfig.device);
        if (viewConfigurationsWithoutCurrent.length !== 2) {
          reject(new Error('Invalid number of view configurations were found.'));
        }
        viewConfigurations.forEach((config) => {
          // Check the gridVersion of the view and update if it is undefined or lower than 2.0.
          const gridVersion = this.configLoader.getGridVersion();
          if (gridVersion && config.gridVersion !== gridVersion) {
            config.gridVersion = gridVersion;
          };
        });
        // Save all view configurations (without the current view configuration) and at last, save the current view configuration.
        this.configManager.updateViewConfig(viewConfigurationsWithoutCurrent[0].id, viewConfigurationsWithoutCurrent[0].device, viewConfigurationsWithoutCurrent[0]).then((viewConfig) => {


          // Update ViewConfig in cache
          this.configLoader.updateCachedViewConfig(viewConfig, viewConfig.device);

          this.configManager.updateViewConfig(viewConfigurationsWithoutCurrent[1].id, viewConfigurationsWithoutCurrent[1].device, viewConfigurationsWithoutCurrent[1]).then((viewConfig) => {
            // Update ViewConfig in cache
            this.configLoader.updateCachedViewConfig(viewConfig, viewConfig.device);

            this.saveCurrentViewConfig().then(() => {
              resolve();
            }).catch((err) => {
              this.logger.error(this.className, 'handleSaveIntent', 'Failed to update view configuration.', err);
              reject(err);
            });
          }).catch((err) => {
            this.logger.error(this.className, 'handleSaveIntent', 'Failed to update view configuration.', err);
            NotificationHelper.Instance.error({
              body: this.frameworkLanguageProvider.get('framework.toastr.savefailed.body')
            });
            reject(err);
          });
        }).catch((err) => {
          this.logger.error(this.className, 'handleSaveIntent', 'Failed to update view configuration.', err);
          NotificationHelper.Instance.error({
            body: this.frameworkLanguageProvider.get('framework.toastr.savefailed.body')
          });
          reject(err);
        });

      }).catch((err) => {
        this.logger.error(this.className, 'handleSaveIntent', 'Failed to update all view configurations.', err);
        NotificationHelper.Instance.error({
          body: this.frameworkLanguageProvider.get('framework.toastr.savefailed.body')
        });
        reject(err);
      });
    });
  }

  /**
   * Checks the configuration component whether changes were made and opens a confirmation prompt.
   *
   * @private
   * @param {(hasSaved: boolean) => void} cb Callback to execute when everything was ok.
   * @param {() => void} cancelCb Callback to execute when canceling.
   * @memberof ConfigUiHelper
   */
  private checkEverythingForChanges(cb: (hasSaved: boolean) => void, cancelCb?: () => void): void {
    let callbackInvocationCount = 0;
    let desiredCallbackInvocationCount = 0;
    const tryToInvokeCallback = (hasSaved: boolean) => {
      if (++callbackInvocationCount === desiredCallbackInvocationCount) {
        cb(hasSaved);
      }
    };

    const isConfigChanged = this.isConfigDirty();

    if (isConfigChanged) {
      this.popupHelper.openSavePopup(() => {
        this.uiManager.displayBusyStateIndicator();
        this.updateLayout();
        // Save
        if (isConfigChanged) {
          desiredCallbackInvocationCount++;
          this.isConfigurationComponentValid().then((isValid: boolean) => {
            if (isValid) {
              this.handleSaveIntent().then(() => {
                tryToInvokeCallback(true);
              }).catch((err) => {
                this.logger.error(this.className, 'checkEverythingForChanges', 'Failed to save ViewConfig', err);
              });
            } else {
              tryToInvokeCallback(false);
            }
          }).catch((err: Error) => {
            tryToInvokeCallback(false);
            this.logger.error(this.className, 'checkEverythingForChanges', 'Failed to get information whether config component is valid', err);
          });
        }
      },
        () => {
          // Cancel
          if (cancelCb) {
            cancelCb();
          }
        },
        () => {
          // Discard
          // eslint-disable-next-line promise/no-callback-in-promise
          this.discard().then(() => cb(true)).catch((err: Error) => {
            this.logger.error(this.className, 'checkEverythingForChanges', 'Failed to discard changes', err);
          });
        });
    } else {
      cb(true);
    }
  }

  /**
   * Dispatches the event when a component is deleted.
   * @param {string} e Event name.
   * @private
   * @memberof ConfigUiHelper
   */
  private dispatchtRemoveComponentEvent(e: string): void {
    const componentRemovedEvent = new Event(e);
    document.dispatchEvent(componentRemovedEvent);
  }


  /**
   * Handles click to remove a component.
   *
   * @private
   * @param {Event} e Click event that was emitted.
   *
   * @memberof ConfigUiHelper
   */
  private removeComponentClick(e: Event): void {
    // Check whether a component's remove button was clicked
    if (e.target && (<Element>e.target).matches('.remove-component-btn, .remove-component-btn *')) {
      const clickTarget = (<Element>e.target);
      const maxRecursionDepth = 6;
      let guid: string | null = null,
        _guidRecursionCount = 0,
        _currentParent = clickTarget;

      while (!guid && _guidRecursionCount++ < maxRecursionDepth) {
        if (_currentParent.getAttribute('data-guid')) {
          guid = _currentParent.getAttribute('data-guid');
        } else {
          if (_currentParent.parentElement) {
            _currentParent = _currentParent.parentElement;
          }
        }
      }

      if (typeof guid !== 'string' && !guid) {
        this.logger.error('ConfigUiHelper', 'removeComponentClick', 'GUID not found when removing component');
        return;
      }

      const itemName = 'DynamicWorkspace-DeleteComponentDoNotShowAgain';
      const componentRemovedEventName = 'componentRemoved';
      /**
       * Used to set and get the status of 'do not show this message again'.
       */
      const _setDoNotShowAgain = (value: boolean) => {
        localStorage.setItem(itemName, value.toString());
      },
        _getDoNotShowAgain = () => {
          const value = localStorage.getItem(itemName) || 'false';
          return value === 'true';
        };

      if (_getDoNotShowAgain()) { // If message has been disabled, delete immideately
        this.removeComponent(guid);
        this.dispatchtRemoveComponentEvent(componentRemovedEventName);
      } else { // Otherwise ask for confirmation
        let deleteInstantly = false;
        const componentToRemoveName = this.getCurrentViewConfig().components.find((component) => component.guid === guid) as ComponentInstanceConfig;
        this.popupHelper.openConfirmationPopup(() => {
          if (typeof guid !== 'string' && !guid) {
            this.logger.error('ConfigUiHelper', 'removeComponentClick', 'GUID not found when removing component');
            return;
          }
          // Yes (delete)
          this.removeComponent(guid);
          this.dispatchtRemoveComponentEvent(componentRemovedEventName);
          _setDoNotShowAgain(deleteInstantly);
        },
          () => {
            // No (do nothing)
          },
          {
            body: (jQuery) => {
              const containerElement = jQuery('<div></div>');
              const paragraphElement = jQuery('<p></p>').text(this.frameworkLanguageProvider.getWithFormat('framework.deletecomponentmodal.body',
                this.multilingualTranslator.getTranslationFromProperty(componentToRemoveName.name)));
              containerElement.append(paragraphElement);
              const checkboxElement = jQuery('<div></div>').dxCheckBox({
                hint: this.frameworkLanguageProvider.get('framework.deletecomponentmodal.alwaysdelete'),
                onValueChanged: (newValue) => {
                  deleteInstantly = !!newValue.value;
                },
                text: this.frameworkLanguageProvider.get('framework.deletecomponentmodal.alwaysdelete')
              });
              containerElement.append(checkboxElement);
              return containerElement[0];
            },
            destroyOnClose: true,
            title: this.frameworkLanguageProvider.get('framework.deletecomponentmodal.title')
          }
        );
      }
    }
  }

  /**
   * Handles click to select view.
   * Will select view if empty grid area is clicked. Will do nothing otherwise.
   *
   * @private
   * @param {Event} e Event that emitted.
   * @memberof ConfigUiHelper
   */
  private selectViewClick(e: Event): void {
    if (this.configMode === CONFIG_MODES.NONE) { // Do nothing if config is not active yet
      return;
    }

    const target = e.target as HTMLElement;
    if (target.matches('.grid, .wd-mainframe')) {
      this.openViewConfig();
    }
  }

  /**
   * Save current state of view Configuration by using the Config Manager.
   *
   * @private
   * @returns {Promise<void>} Promise to resolve on success.
   * @memberof ConfigUiHelper
   */
  private async saveCurrentViewConfig(): Promise<void> {

    // Prevent UI interactions while saving is in progress
    if (this.isSaveInProgress) {
      this.logger.debug(this.className, 'saveViewConfig', 'Not saving, because save is already in progress');
      return Promise.resolve();
    }
    this.uiManager.displayBusyStateIndicator();
    this.isSaveInProgress = true;

    this.waitForSavePromise = new Promise<void>((resolve, reject) => {

      const viewConfig = this.getCurrentViewConfig();
      if (!viewConfig) {
        reject(new Error('The current view configuration could not be found.'));
      }

      // Save data via ConfigManager and PubSubUiHelper
      const pubSubUiHelperViewConfig = this.pubSubUiHelper.getCurrentViewConfig();
      if (!pubSubUiHelperViewConfig) {
        reject(new Error('Failed to get ViewConfig fom PubSubUiHelper'));
        return;
      }

      if (this.mappingUiHelper) {
        const mappingUiHelperViewConfig = this.mappingUiHelper.getCurrentViewConfig();
        if (!mappingUiHelperViewConfig) {
          reject(new Error('Failed to get ViewConfig fom MappingUiHelper'));
          return;
        }

        if (!viewConfig.settings) {
          viewConfig.settings = {};
        }

        viewConfig.settings.choiceLists = mappingUiHelperViewConfig.settings.choiceLists;
        viewConfig.settings.indices = mappingUiHelperViewConfig.settings.indices;
        viewConfig.settings.objectTypes = mappingUiHelperViewConfig.settings.objectTypes;
        viewConfig.settings.paths = mappingUiHelperViewConfig.settings.paths;

        // Add configurations coming from the mapping
        mappingUiHelperViewConfig.components.forEach((componentFromMapping) => {
          const componentFromView = viewConfig.components.find((component) => component.guid === componentFromMapping.guid);
          if (componentFromView) {
            if (!Utils.Instance.isDeepEqual(componentFromView.configuration, componentFromMapping.configuration)) {
              this.logger.debug(this.className, 'saveViewConfig', 'Applying config from MapingUiHelper for component', componentFromMapping);
              // Only update configuration so that other properties (e.g. position) are taken in their latest version
              componentFromView.configuration = componentFromMapping.configuration;
            }
          };
        });
      }

      viewConfig.pubSub = pubSubUiHelperViewConfig.pubSub;
      viewConfig.triggers = pubSubUiHelperViewConfig.triggers;
      viewConfig.subViews = this.getCurrentViewConfig().subViews;

      this.updateCurrentViewWithViewConfig(viewConfig);

      this.logger.info(this.className, 'saveViewConfig', 'Saving config for device', { viewConfig });
      Promise.all([
        this.configManager.updateViewConfig(viewConfig.id, viewConfig.device, viewConfig),
        this.pubSubUiHelper.save()
      ]).then((responses) => {
        const newViewConfig = responses[0];
        // Ignore modified flag as the value from the server is always newer
        const viewConfigWithoutModified = Utils.Instance.deepClone(viewConfig);
        viewConfigWithoutModified.modified = '';
        const newViewConfigWithoutModified = Utils.Instance.deepClone(newViewConfig);
        newViewConfigWithoutModified.modified = '';
        if (!Utils.Instance.isDeepEqual(viewConfigWithoutModified, newViewConfigWithoutModified)) {
          this.logger.info(this.className, 'saveViewConfig', 'View config response is not equal to sent data', { viewConfig, newViewConfig });

          // Update view in Configuration component
          if (this.configMode === CONFIG_MODES.VIEW) {
            this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'SelectedView', newViewConfig);
          }
        }
        NotificationHelper.Instance.success({
          body: this.frameworkLanguageProvider.get('framework.toastr.savesucess.body'),
          title: this.frameworkLanguageProvider.get('framework.generic.success')
        });

        this.updateCurrentViewWithViewConfig(newViewConfig);

        // Update ViewConfig in cache
        this.configLoader.updateCachedViewConfig(newViewConfig, newViewConfig.device);
        // Reset PubSub
        this.viewManager.updateViewPubSubHandler(newViewConfig.pubSub, newViewConfig.triggers);

        // Update alias to update the navigation links
        const alias = newViewConfig.alias;
        this.appBarHandler.viewNavigationHandler?.updateViewAlias(this.getCurrentViewIdentifier(), alias);

        this.viewManager.updateViewConfig(newViewConfig);

        // Update skeletons
        this.viewManager.showSkeletons(true);

        // Set backup
        this.viewConfigBackup = Utils.Instance.deepClone(newViewConfig);

        this.updateOtherHelpers();

        // Disable discard
        this.setDiscardPossible(false);
        ConfigUiHelper.configHasUnsavedChanges = false;
        // Reset PubSub editor dirty flag
        this.isPubSubDirty = false;

        // Reset mapping editor dirty flag
        this.isMappingDirty = false;

        // Set UI flag for all components to false
        const layoutManagers = this.subViewManager.getCurrentLayoutManagers();
        if (layoutManagers) {
          const hasChanges = false;
          this.getCurrentViewConfig().components.forEach((component) => {
            layoutManagers.forEach((layoutManager) => {
              layoutManager.setPendingChangesFlag(component.guid, hasChanges);
            });
          });
        }
        // Set UI flag for view to false
        this.appBarHandler.currentViewHasChanges(false);

        // Update view in Application
        this.updateApplicationConfig(newViewConfig);

        // Notify user if alias has been modified by the server
        if (viewConfig.alias !== newViewConfig.alias) {
          NotificationHelper.Instance.warning({
            body: this.frameworkLanguageProvider.get('framework.editMode.aliasAlreadyTaken')
          });
        } else if (viewConfig.alias) {
          RouteManager.replaceState(null, '', EditRouter.generateEditUrlForViewId(viewConfig.alias));
        }

        // Reset un-saved components
        this.pendingComponentInstanceConfigs.length = 0;

        this.uiManager.removeBusyStateIndicator();
        this.isSaveInProgress = false;
        resolve();
      }).catch((err: Error) => {
        if ((err as ServiceError).errorCode === WEBSERVICE_APPLICATION_ERROR_CODES.InvalidViewAlias) {
          NotificationHelper.Instance.error({
            body: this.frameworkLanguageProvider.get('framework.editMode.aliasInvalid'),
            title: this.frameworkLanguageProvider.get('framework.generic.error')
          });
        } else {
          NotificationHelper.Instance.error({
            body: this.frameworkLanguageProvider.get('framework.toastr.savefailed.body'),
            title: this.frameworkLanguageProvider.get('framework.generic.error')
          });
        }
        this.logger.error('ConfigUiHelper', 'saveViewConfig', 'Failed to save view config.', err);
        this.uiManager.removeBusyStateIndicator();
        this.isSaveInProgress = false;
        reject(err);
      });
    });
    return this.waitForSavePromise;
  }

  /**
   * Updates the application configuration.
   *
   * @private
   * @param {ViewConfig} viewConfig The view configuration.
   * @memberof ConfigUiHelper
   */
  private updateApplicationConfig(viewConfig: ViewConfig): void {
    // Update view information in cached ApplicationConfig
    const viewInApplicationConfig = this.applicationConfig.activeViews.find((view) => view.id === viewConfig.id);
    if (viewInApplicationConfig) {
      viewInApplicationConfig.name = viewConfig.name;
      viewInApplicationConfig.alias = viewConfig.alias;
    }

    // Update shared settings
    this.sharedSettingsProvider?.setViewSettings(viewConfig.settings);
  }

  /**
   * Leave edit mode.
   * Initialize all components so that they can be operated.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private leaveEditMode(): void {
    // Disable edit state of UI
    this.subViewManager.setEditable(false);
    const currentViewConfig = this.getCurrentViewConfig();
    if (currentViewConfig) {
      RouteManager.replaceOrPushState(null, '', ViewRouter.generateNavigationUrlForViewId(currentViewConfig.alias ? currentViewConfig.alias : currentViewConfig.id));
    }

    // Reset view configuration change indicators
    this.pendingComponentInstanceConfigs.length = 0;
    ConfigUiHelper.configHasUnsavedChanges = false;

    const viewInitializationOptions = new ViewInitializationOptions();
    viewInitializationOptions.promoteLifecycleSteps = true;
    this.viewManager.initView(currentViewConfig.id, viewInitializationOptions, this.currentDevice).catch((err: Error) => {
      this.logger.error(this.className, 'leaveEditMode', 'Failed to initialize the view', err);
    });
  }

  /**
   * Returns a flat list of component instance configurations.
   * Resolves nesting in container components.
   *
   * @private
   * @param {ComponentInstanceConfig[]} componentInstanceConfigs Nested list of component instance configurations.
   * @returns {ComponentInstanceConfig[]} Flat list of component instance configurations.
   * @memberof ConfigUiHelper
   */
  private getAllComponentInstanceConfigsFlat(componentInstanceConfigs: ComponentInstanceConfig[]): ComponentInstanceConfig[] {
    let allComponentInstanceConfigs: ComponentInstanceConfig[] = new Array<ComponentInstanceConfig>();

    componentInstanceConfigs.forEach((componentInstanceConfig: ComponentInstanceConfig) => {
      allComponentInstanceConfigs.push(componentInstanceConfig);

      if (componentInstanceConfig.component.indexOf('.container.') !== -1) {
        // TODO: Adjust selector if there are more container components than Tabs.
        if (componentInstanceConfig.configuration.TabContainers && Utils.Instance.isArray(componentInstanceConfig.configuration.TabContainers)) {
          allComponentInstanceConfigs = allComponentInstanceConfigs.concat(componentInstanceConfig.configuration.TabContainers);
        }
      }
    });

    return allComponentInstanceConfigs;
  }

  /**
   * Reset the configuration panels for PubSub and Component Configuration.
   *
   * @private
   *
   * @memberof ConfigUiHelper
   */
  private resetConfigurationPanels(): void {
    const componentConfigPanelContent = this.rootContainer.querySelector('#wd-component-config-panel .config-panel-content');
    if (componentConfigPanelContent) {
      componentConfigPanelContent.innerHTML = '';
    }
    // Delete all modals related to the configuration
    const modals = this.rootContainer.querySelectorAll('.wd-configuration-modal');
    for (let i = 0; i < modals.length; i++) {
      const modal = modals.item(i);
      const overlay = modal.parentElement;
      if (overlay && overlay.classList.contains('reveal-overlay')) {
        this.rootContainer.removeChild(overlay);
      }
    }
    // Reset state of currently edited component, as configuration is not visible and no component is being edited anymore
    delete this.currentlyEditedComponentGuid;
  }

  /**
   * Updates a component configuration by applying the changes to the current ViewConfig model.
   * Does not save the configuration.
   *
   * @private
   * @param {ComponentInstanceConfig} config Component config to update.
   *
   * @memberof ConfigUiHelper
   */
  private updateComponent(config: ComponentInstanceConfig): void {

    const index = this.getCurrentViewConfig().components.findIndex((component) => component.guid === config.guid);
    if (index !== -1) {
      this.getCurrentViewConfig().components[index] = config;
    }

    // Get latest updates from PubSub configuration
    const pubSubUiManagerViewConfig = this.pubSubUiHelper.getCurrentViewConfig();
    if (pubSubUiManagerViewConfig) {
      this.getCurrentViewConfig().pubSub = pubSubUiManagerViewConfig.pubSub;
      this.getCurrentViewConfig().triggers = pubSubUiManagerViewConfig.triggers;
    } else {
      this.logger.error(this.className, 'updateComponent', 'PubSubUiHelper has invalid ViewConfig');
    }
    this.updateOtherHelpers();

    // Update component title
    const componentConfig = this.componentConfigs.get(config.component);
    if (componentConfig) {
      const layoutManager = this.subViewManager.getCurrentLayoutManager(componentConfig.isLogic ? DWCore.Components.COMPONENT_TYPES.LOGICAL : DWCore.Components.COMPONENT_TYPES.UI);
      if (layoutManager) {
        let title = '';
        if (this.multilingualTranslator.isMultilingualValue(config.title)) {
          title = this.multilingualTranslator.getTranslationFromProperty(config.title);
        }

        const isTitleVisible = Utils.Instance.isDefined(config.isTitleVisible) ? config.isTitleVisible : false;
        layoutManager.updateComponentTitle(config.guid, this.multilingualTranslator.getTranslationFromProperty(config.name),
          title, isTitleVisible);
      } else {
        this.logger.error(this.className, 'updateComponent', `No LayoutManager found for component '${config.name}'`, config);
      }
    } else {
      this.logger.error(this.className, 'updateComponent', `No ComponentConfig found for component '${config.component}'`, config);
    }
  }

  /**
   * Updates the view configuration by applying the given configuration to the current model.
   * Does not save the configuration.
   *
   * @private
   * @param {ViewConfig} viewConfig View configuration to update.
   * @memberof ConfigUiHelper
   */
  private updateView(viewConfig: ViewConfig): void {
    if (!Utils.Instance.isDeepEqual(this.getCurrentViewConfig().name, viewConfig.name)) { // Update navigation if view name changed
      const viewName = this.appBarHandler.viewNavigationHandler?.getViewTranslation(viewConfig);
      if (viewName) {
        this.appBarHandler.viewNavigationHandler?.renameView(viewConfig.id, viewName);
        this.appBarHandler.updateCurrentViewName(viewName);
        // Update alias to update the navigation links
        const alias = viewConfig.alias;
        this.appBarHandler.viewNavigationHandler?.updateViewAlias(this.getCurrentViewIdentifier(), alias);
      }
    }

    if (!Utils.Instance.isDeepEqual(this.getCurrentViewConfig().icon, viewConfig.icon)) { // Update navigation if view icon changed
      this.appBarHandler.viewNavigationHandler?.updateViewIcon(viewConfig.id, viewConfig.icon);
    }

    this.getCurrentViewConfig().name = viewConfig.name;
    this.getCurrentViewConfig().alias = viewConfig.alias;
    this.getCurrentViewConfig().description = viewConfig.description;
    this.getCurrentViewConfig().style = viewConfig.style;
    this.getCurrentViewConfig().icon = viewConfig.icon;
    this.getCurrentViewConfig().settings = viewConfig.settings;
    this.getCurrentViewConfig().subViews = viewConfig.subViews;
    this.getCurrentViewConfig().contextMenu = viewConfig.contextMenu;
    // Get latest updates from PubSub configuration
    const pubSubUiManagerViewConfig = this.pubSubUiHelper.getCurrentViewConfig();
    if (pubSubUiManagerViewConfig) {
      this.getCurrentViewConfig().pubSub = pubSubUiManagerViewConfig.pubSub;
      this.getCurrentViewConfig().triggers = pubSubUiManagerViewConfig.triggers;
    } else {
      this.logger.error(this.className, 'updateView', 'PubSubUiHelper has invalid ViewConfig');
    }

    this.updateOtherHelpers();
  }


  /**
   * Adds a component using the specified component instance config.
   *
   * @private
   * @param {ComponentInstanceConfig} componentInstanceConfig The component instance configuration to add.
   * @memberof ConfigUiHelper
   */
  private addComponent(componentInstanceConfig: ComponentInstanceConfig): void {
    const methodName: string = 'addComponent';

    // Add default toolbars
    const componentConfig: ComponentConfig | undefined = this.componentConfigs.get(componentInstanceConfig.component);
    if (componentConfig && componentConfig.toolbar) {
      if (!componentInstanceConfig.toolbar) {
        componentInstanceConfig.toolbar = new ComponentInstanceToolbarConfig();
        componentInstanceConfig.toolbar.displayMode = DWCore.Components.ToolbarActionDisplayMode.Automatically;
        componentInstanceConfig.toolbar.enabled = false;
        componentInstanceConfig.toolbar.orientation = DWCore.Components.ToolbarOrientation.Automatically;
      }
      componentInstanceConfig.toolbar.actions = new Array<ComponentInstanceToolbarActionConfig>();
      componentConfig.toolbar.defaultToolbarActions?.forEach((defaultAction) => {
        componentInstanceConfig.toolbar!.actions!.push({
          id: defaultAction,
          displayMode: DWCore.Components.ToolbarActionDisplayMode.Automatically
        });
      });
    }
    // Load component
    this.viewManager.addComponent(componentInstanceConfig, undefined, false).then(() => {
      // Add component to view config (components)
      this.getCurrentViewConfig().components.push(componentInstanceConfig);

      // Add component to view config (subview)
      let indexOfActiveSubView = -1;
      this.getCurrentViewConfig().subViews.forEach((subViewConfig: SubViewConfig, index: number) => {
        if (subViewConfig.id === this.subViewManager.getCurrentSubViewId()) {
          indexOfActiveSubView = index;
        }
      });
      if (indexOfActiveSubView > -1) {
        this.getCurrentViewConfig().subViews[indexOfActiveSubView].components.push(componentInstanceConfig.guid);
      } else {
        this.logger.error(this.className, methodName, 'No active subview set!');
      }

      NotificationHelper.Instance.success({
        body: this.frameworkLanguageProvider.get('framework.toastr.componentadded.body'),
        title: this.frameworkLanguageProvider.get('framework.generic.success')
      });

      // Add the new component name to the already used names
      for (const lang in componentInstanceConfig.name) {
        if (!componentInstanceConfig.name.hasOwnProperty(lang)) {
          continue;
        }
        const name = componentInstanceConfig.name[lang];
        this.viewManager.addUsedComponentName(name);
      }

      // Send component added information.
      this.pubSubHandler.publish(ConfigUiHelperUtil.ADD_COMPONENT_GUID, 'ComponentAdded');

      // Send update view config to other UI helpers
      this.updateOtherHelpers();

      this.pendingComponentInstanceConfigs.push(Utils.Instance.deepClone(componentInstanceConfig));

      this.displayDirtyState();
    }).catch((err: Error) => {
      this.logger.error(this.className, methodName, 'Failed to add component', err);
    });
  }


  /**
   * Adds PubSub connections required for configuration component.
   *
   * @private
   *
   * @memberof ConfigUiHelper
   */
  private addAllConfigPubSub(): void {
    ConfigUiHelperUtil.addAddPubSub(this.pubSubHandler);
    ConfigUiHelperUtil.addConfigurationPubSub(this.pubSubHandler);
  }

  /**
   * Deletes the given subview.
   *
   * @private
   * @param {string} id The subview which will be deleted.
   *
   * @memberof ConfigUiHelper
   */
  private deleteSubView(id: string) {
    const subviewToDelete = this.getCurrentViewConfig().subViews.find((subview) => subview.id === id) as SubViewConfig;
    this.popupHelper.openConfirmationPopup(() => {
      // Delete
      this.getCurrentViewConfig().subViews.forEach((subView, index) => {
        if (subView.id === id) {
          // Splice before removeComponent so that all view config promise updates are including the removed subview; each removeComponent() call updates the viewconfig async...
          this.getCurrentViewConfig().subViews.splice(index, 1);
          this.getCurrentViewConfig().components.forEach((instance) => {
            // Delete used components
            if (subView.components.indexOf(instance.guid) >= 0) {
              this.removeComponent(instance.guid, true);
            }
          });
          // Re-render subview navigation
          this.renderSubViewNavigation(this.getCurrentViewConfig().subViews);
          // Update config in UI helpers
          this.updateOtherHelpers();
          this.displayDirtyState();
          return;
        }
      });
    }, () => {
      // Do nothing
    }, {
      body: this.frameworkLanguageProvider.getWithFormat('framework.deleteSubViewModal.body', subviewToDelete.name || ''),
      title: this.frameworkLanguageProvider.get('framework.deleteSubViewModal.title')
    });
  }

  /**
   * Renames the given subview.
   *
   * @private
   * @param {string} id The subview which will be changed.
   *
   * @memberof ConfigUiHelper
   */
  private renameSubView(id: string) {
    let index = 0;
    this.getCurrentViewConfig().subViews.forEach((subView) => {
      if (subView.id === id) {
        const currentIndex = index;
        this.popupHelper.openInputBoxPopup((text: string) => {
          subView.name = text.trim() || `${this.frameworkLanguageProvider.get('framework.config.subViews.subView')} ${(this.getCurrentViewConfig().subViews.length + 1)}`;
          this.getCurrentViewConfig().subViews.splice(currentIndex, 1, subView);
          // Re-render subview navigation
          this.renderSubViewNavigation(this.getCurrentViewConfig().subViews);
          // Update config in other UI helpers
          this.updateOtherHelpers();
          this.displayDirtyState();
        }, () => {
          // Do nothing
        }, subView.name || '', {
          body: '',
          closeOnClick: false,
          placeholder: this.frameworkLanguageProvider.get('framework.config.subViews.placeholder.rename'),
          title: this.frameworkLanguageProvider.get('framework.config.subViews.rename')
        });
        return;
      }
      index++;
    });
  }

  /**
   * Prompt the user when creating a new sub view.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private addSubView(): void {
    const promptForName = (previousValue: string = '') => {
      this.popupHelper.openInputBoxPopup((input: string) => {
        // Validate name
        if (this.getCurrentViewConfig().subViews.find((subView) => subView.name === input)) {
          // Name is already taken
          const popup = this.popupHelper.openPopup({
            body: this.frameworkLanguageProvider.get('framework.config.subViews.nameTaken.body'),
            buttons: [{
              callback: () => {
                popup.close();
                promptForName(input);
              },
              label: this.frameworkLanguageProvider.get('framework.generic.ok')
            }],
            closeOnClick: false,
            title: this.frameworkLanguageProvider.get('framework.config.subViews.nameTaken.title')
          });
        } else {
          // Name is valid
          const newSubViewConfig = {
            components: [],
            id: Utils.Instance.getUUID(),
            name: input.trim() || `${this.frameworkLanguageProvider.get('framework.config.subViews.subView')} ${(this.getCurrentViewConfig().subViews.length + 1)}`
          };
          // Add subview to view configuration
          this.getCurrentViewConfig().subViews.push(newSubViewConfig);
          // Add subview to ViewManager
          this.subViewManager.addSubView(newSubViewConfig, this.currentDevice);
          // Re-render subview navigation
          this.renderSubViewNavigation(this.getCurrentViewConfig().subViews);
          // Update config in other UI helpers
          this.updateOtherHelpers();
          this.displayDirtyState();
        }
      },
        () => {
          // Do nothing on cancel
        },
        previousValue,
        {
          body: '',
          closeOnClick: false,
          placeholder: this.frameworkLanguageProvider.get('framework.config.subViews.placeholder.add'),
          title: this.frameworkLanguageProvider.get('framework.config.subViews.add')
        });
    };
    promptForName();
  }

  /**
   * Fetches all ComponentConfigs using ConfigLoader.
   * Will only fetch once and then cache the results.
   *
   * @private
   * @returns {Promise<Map<string, ComponentConfig>>} Promise to resolve with all ComponentConfigs.
   * @memberof ConfigUiHelper
   */
  private async ensureAllComponentConfigs(): Promise<Map<string, ComponentConfig>> {
    return new Promise<Map<string, ComponentConfig>>(async (resolve) => {
      if (this.componentConfigs && this.componentConfigs.size > 0) {
        resolve(this.componentConfigs);
        return;
      } else {
        this.componentConfigs = await this.configLoader.getAllComponentConfigs();
        resolve(this.componentConfigs);
        return;
      }
    });
  }

  /**
   * Loads the configuration component into the side panel if it is not already present.
   *
   * @private
   * @returns {Promise<void>} Promise to resolve when the component has been loaded.
   * @memberof ConfigUiHelper
   */
  private async loadConfigurationComponent(): Promise<void> {
    return new Promise<void>((resolve) => {
      const configPanel = this.rootContainer.querySelector('#wd-component-config-panel');
      let componentPanelContent: Element | null = null;
      if (configPanel) {
        componentPanelContent = configPanel.querySelector('.config-panel-content');
      }

      if (componentPanelContent && !componentPanelContent.innerHTML) { // Config component not yet loaded

        this.ensureAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
          this.configMapper.setComponentConfigs(componentConfigs);
          const objectTypes = new Array<ObjectType>();
          this.metaDataStore.objectTypes.forEach((objectType: ObjectType) => {
            objectTypes.push(objectType);
          });

          this.componentManager.addComponent({
            component: 'com.windream.configuration',
            configuration: {
              availableAttributes: this.metaDataStore.availableAttributes,
              availableComponents: componentConfigs,
              availableObjectTypes: objectTypes,
              type: 'view'
            },
            guid: ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID,
            isTitleVisible: false,
            name: { en: 'com.windream.componentPanel' },
            position: '#wd-component-config-panel .config-panel-content',
            style: Style.default()
          }, this.pubSubHandler).then(() => {
            resolve();
          }).catch((err: Error) => {
            this.logger.error(this.className, 'loadConfigurationComponent', 'Failed to load Configuration component', err);
          });
        }).catch((err) => {
          this.logger.error(this.className, 'loadConfigurationComponent', 'Failed to load all ComponentConfigs', err);
        });
      } else {
        resolve();
      }
    });
  }

  /**
   * Checks whether the configuration component in its current state is valid.
   *
   * @private
   * @returns {Promise<boolean>} Promise to resolve with whether the component is valid or not.
   * @memberof ConfigUiHelper
   */
  protected async isConfigurationComponentValid(): Promise<boolean> {
    if (!this.configActive) {
      return Promise.resolve(true);
    }
    return new Promise<boolean>((resolve) => {
      this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'IsConfigValid', (isValid: boolean) => {
        resolve(isValid);
      });
    });
  }

  /**
   * Loads the "add components" component into the side panel if it is not already present.
   *
   * @private
   * @returns {Promise<void>} Promise to resolve when the component has been loaded.
   * @memberof ConfigUiHelper
   */
  private async loadAddComponent(): Promise<void> {
    const methodName = 'loadAddComponent';
    return new Promise<void>((resolve, reject) => {
      const configPanel = this.rootContainer.querySelector('#wd-add-component-panel');
      let componentPanelContent: Element | null = null;
      if (configPanel) {
        componentPanelContent = configPanel.querySelector('.config-panel-content');
      }

      if (componentPanelContent && !componentPanelContent.innerHTML) { // Config component not yet loaded
        this.ensureAllComponentConfigs().then((componentConfigs: Map<string, ComponentConfig>) => {
          if (componentConfigs.size === 0) {
            this.logger.error(this.className, 'loadAddComponent', 'No ComponentConfig found', this.componentConfigs);
            reject(new Error('No ComponentConfig found'));
          }
          this.componentManager.addComponent({
            component: 'com.windream.componentPanel',
            configuration: {
              availableComponents: componentConfigs,
              currentComponents: this.getCurrentViewConfig().components
            },
            guid: ConfigUiHelperUtil.ADD_COMPONENT_GUID,
            isTitleVisible: false,
            name: { en: 'com.windream.componentPanel' },
            position: '#wd-add-component-panel .config-panel-content',
            style: Style.default()
          }, this.pubSubHandler).then(() => {
            resolve();
          }).catch((err: Error) => {
            this.logger.error(this.className, 'loadAddComponent', 'Failed to add ComponentPanel component', err);
          });

          // Subscribe to the AddComponent event that is published when the com.windream.componentPanel component saved changes
          this.pubSubHandler.subscribe(ConfigUiHelperUtil.ADD_COMPONENT_GUID, 'AddComponents', (componentIdsPromise: Promise<string[]>) => {
            componentIdsPromise.then((componentIds) => {
              this.addComponents(componentIds, componentConfigs);
            }).catch((error) => {
              this.logger.error(this.className, methodName, 'AddComponents pubsub catched error', error);
            });
          });

          this.pubSubHandler.subscribe(ConfigUiHelperUtil.ADD_COMPONENT_GUID, 'LogicComponentDragStart', () => {
            const layoutManager = this.subViewManager.getCurrentLayoutManager(DWCore.Components.COMPONENT_TYPES.UI);
            if (layoutManager) {
              layoutManager.setEnabled(false);
            } else {
              this.logger.error(this.className, 'loadAddComponent', 'Failed to get LayoutManager for UI components.');
            }
          });

          this.pubSubHandler.subscribe(ConfigUiHelperUtil.ADD_COMPONENT_GUID, 'UiComponentDragStart', () => {
            const layoutManager = this.subViewManager.getCurrentLayoutManager(DWCore.Components.COMPONENT_TYPES.LOGICAL);
            if (layoutManager) {
              layoutManager.setEnabled(false);
            } else {
              this.logger.error(this.className, 'loadAddComponent', 'Failed to get LayoutManager for Logic components.');
            }
          });

          this.pubSubHandler.subscribe(ConfigUiHelperUtil.ADD_COMPONENT_GUID, 'ComponentDragStop', () => {
            // Re-enable LayoutManagers
            const layoutManagers = this.subViewManager.getCurrentLayoutManagers();
            if (layoutManagers) {
              layoutManagers.forEach((layoutManager) => {
                layoutManager.setEnabled(true);
              });
            } else {
              this.logger.error(this.className, 'loadAddComponent', 'Failed to get LayoutManagers.');
            }
          });

          // Subscribe to the AddComponentDragDrop event that is published when the LayoutManager receives a new component via Drag&Drop.
          this.pubSubHandler.subscribe(ConfigUiHelperUtil.ADD_COMPONENT_GUID, 'AddComponentDragDrop', (componentInstanceConfigPromise: Promise<ComponentInstanceConfig>) => {
            componentInstanceConfigPromise.then((componentInstanceConfig) => {
              if (!componentInstanceConfig) {
                return;
              }

              const componentConfig: ComponentConfig | undefined = this.componentConfigs.get(componentInstanceConfig.component);
              // Check whether a unique component name must be found for the given instance config.
              if (componentInstanceConfig.name || Object.keys(componentInstanceConfig.name).length === 0) {
                if (!componentConfig) {
                  this.logger.error(this.className, 'WINDREAM_ADD_COMPONENT - AddComponentDragDrop', 'No component config found for component' + componentInstanceConfig.component);
                  return;
                }
                componentInstanceConfig.name[this.languageManager.getLanguageCultureName()] = this.getNameForComponent(componentConfig);
                componentInstanceConfig.version = componentInstanceConfig.version ? componentInstanceConfig.version : componentConfig.version;
              }
              if (componentConfig) {
                componentInstanceConfig.componentType = componentConfig.isLogic ? DWCore.Components.COMPONENT_TYPES.LOGICAL : DWCore.Components.COMPONENT_TYPES.UI;
              }
              this.addComponent(componentInstanceConfig);
              // Re-enable LayoutManagers
              const layoutManagers = this.subViewManager.getCurrentLayoutManagers();
              if (layoutManagers) {
                layoutManagers.forEach((layoutManager) => {
                  layoutManager.setEnabled(true);
                });
              } else {
                this.logger.error(this.className, 'loadAddComponent', 'Failed to get LayoutManagers.');
              }
            }).catch((error) => {
              this.logger.error(this.className, methodName, 'AddComponentDragDrop pubsub catched error', error);
            });
          });
        }).catch((err) => {
          this.logger.error(this.className, 'loadAddComponent', 'Failed to load all ComponentConfigs', err);
        });
      } else {
        resolve();
      }
    });
  }


  /**
   * Prompts for changes and switches the device if no changes are pending.
   *
   * @private
   * @param {DWCore.Common.Devices} newDevice The new device.
   * @param {() => void} deviceChangedCb Callback to execute if device has actually changed.
   * @memberof ConfigUiHelper
   */
  private onSwitchDevice(newDevice: DWCore.Common.Devices, deviceChangedCb: () => void): void {
    this.checkEverythingForChanges(() => {

      const currentViewConfig = this.getCurrentViewConfig();

      this.switchDevice(newDevice).catch((err: Error) => {
        this.logger.error(this.className, 'onSwitchDevice', 'Failed to switch device', err);
      });

      const pubSubUiManagerViewConfig = this.pubSubUiHelper.getCurrentViewConfig();
      if (pubSubUiManagerViewConfig) {
        currentViewConfig.pubSub = pubSubUiManagerViewConfig.pubSub;
        currentViewConfig.triggers = pubSubUiManagerViewConfig.triggers;
      } else {
        this.logger.error(this.className, 'onSwitchDevice', 'PubSubUiHelper has invalid ViewConfig');
      }

      deviceChangedCb();
    });
  }

  /**
   * Switches the device and loads the corresponding ViewConfig.
   *
   * @private
   * @param {DWCore.Common.Devices} newDevice The new device.
   * @returns {Promise<void>} Promise to resolve after the device switches.
   * @memberof ConfigUiHelper
   */
  private async switchDevice(newDevice: DWCore.Common.Devices): Promise<void> {
    return new Promise<void>((resolve) => {
      this.currentDevice = newDevice;
      // Switch to component edit mode (not PubSub or mapping)
      this.rootContainer.classList.remove('pubsub-active');
      this.rootContainer.classList.remove('mapping-active');

      this.rootContainer.classList.remove(...DEVICE_NAMES.map((device) => `edit-device-${device}`));
      this.rootContainer.classList.add(`edit-device-${DEVICE_NAMES[newDevice]}`);

      // Load configuration for selected device
      const viewInitializationOptions = new ViewInitializationOptions();
      viewInitializationOptions.promoteLifecycleSteps = false;
      this.viewManager.initView(this.getCurrentViewIdentifier(), viewInitializationOptions, newDevice).then((newViewConfig: ViewConfig) => {

        this.updateCurrentViewWithViewConfig(newViewConfig);

        this.updateCurrentViewConfig(newViewConfig);
        // Directly set new current instance into edit mode
        this.subViewManager.setEditable(true);
        // Update skeletons
        this.viewManager.showSkeletons(true);
        this.registerLayoutManagerChangeCallbacks();
        resolve();
      }).catch((err: Error) => {
        this.logger.error(this.className, 'switchDevice', 'Failed to init view', err);
      });
    });
  }

  /**
   * Returns a translated, new unique name for the given component.
   *
   * @private
   * @param {ComponentConfig} component Component to get name for.
   * @returns {string} The new unique name.
   * @memberof ConfigUiHelper
   */
  private getNameForComponent(component: ComponentConfig): string {
    const languageProviderForComponent = this.languageManager.getLanguageProvider(component.id);

    if (!languageProviderForComponent) {
      this.logger.warn(this.className, 'getNameForComponent', 'No LanguageProvider for component found', component);
      return component.name;
    }

    let componentName = languageProviderForComponent.get('__windream.component.name');

    if (componentName.includes('__windream.component.name')) {
      this.logger.warn(this.className, 'getNameForComponent', 'No translation found for component name', component);
      componentName = component.name;
    }
    const uniqueComponentName = this.viewManager.getUniqueComponentName(componentName);
    return uniqueComponentName;
  }


  /**
   * Discards the pending changes in the currently edited element (view or component).
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private discardCurrentlyEdited(): void {
    if (!this.viewConfigBackup) {
      this.logger.error(this.className, 'discardCurrentlyEdited', 'No ViewConfig backup available', this.viewConfigBackup);
      return;
    }
    let backupStyle: string | null = null;
    if (this.configMode === CONFIG_MODES.VIEW) {
      this.updateView(Utils.Instance.deepClone(this.viewConfigBackup));
      this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'SelectedView', this.getCurrentViewConfig());
      if (this.getCurrentViewConfig().style) {
        backupStyle = this.getCurrentViewConfig().style?.colors || null;
      }
    } else if (this.configMode === CONFIG_MODES.COMPONENT && this.currentlyEditedComponentGuid) {
      const backup = this.viewConfigBackup.components.find((component) => component.guid === this.currentlyEditedComponentGuid)
        // If component is not within ViewConfigBackup, look it up in un-saved components
        || this.pendingComponentInstanceConfigs.find((component) => component.guid === this.currentlyEditedComponentGuid);
      if (backup) {
        const index = this.getCurrentViewConfig().components.findIndex((component) => component.guid === this.currentlyEditedComponentGuid);
        if (typeof index === 'number') {
          this.getCurrentViewConfig().components[index] = Utils.Instance.deepClone(backup);
          this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'SelectedComponent', {
            currentSettings: this.getCurrentViewConfig().components[index] || {},
            viewConfig: this.getCurrentViewConfig() || {}
          });
        }
        // Set UI flag for this components to false
        const layoutManager = this.subViewManager.getCurrentLayoutManager(DWCore.Components.COMPONENT_TYPES.UI);
        if (layoutManager) {
          const hasChanges = false;
          layoutManager.setPendingChangesFlag(this.currentlyEditedComponentGuid, hasChanges);
          // Reset style
          const style = backup.style;
          if (style) {
            backupStyle = style?.colors || null;
          }
          // Apply backup (i.e. name)
          this.updateComponent(backup);
        }
      } else {
        this.logger.error(this.className, 'discardCurrentlyEdited', `No backup found for component with GUID ${this.currentlyEditedComponentGuid}`, this.viewConfigBackup);
      }
    }
    if (backupStyle) {
      this.handleStyleChange(backupStyle);
      this.pubSubHandler.publish(PubSubConstants.STYLES_ID, 'UpdateOpenConfigSelectedStyle', backupStyle);
    }
    this.setDiscardPossible(false);
    // Reset PubSub editor dirty state
    this.isPubSubDirty = false;

    // Reset mapping editor dirty flag
    this.isMappingDirty = false;
    this.displayDirtyState();
  }

  /**
   * Publishes the given information about whether it is possible to discard in the current situation.
   *
   * @private
   * @param {boolean} isPossible Whether it is possible to discard.
   * @memberof ConfigUiHelper
   */
  private setDiscardPossible(isPossible: boolean): void {
    this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'IsDiscardPossible', isPossible);
  }


  /**
   * Checks if the current configuration is dirty and displays
   * the state using the currently used AppBarHandler instance.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private displayDirtyState(): void {
    const isDirty = this.isConfigDirty();
    ConfigUiHelper.configHasUnsavedChanges = isDirty && this.configActive;
    this.appBarHandler.currentViewHasChanges(isDirty);
  }


  /**
   * Checks wether the view properties have been checked.
   * Components, PubSub, trigger, etc. are not included.
   *
   * @private
   * @param {ComponentInstanceConfig} newConfig Configuration of the view to check for changes.
   * @returns {boolean} Whether the config has changed.
   * @memberof ConfigUiHelper
   */
  private hasViewConfigPropertiesChanged(newConfig: ViewConfig): boolean {
    if (!this.viewConfigBackup) {
      this.logger.error(this.className, 'hasViewConfigPropertiesChanged', 'No ViewConfig backup available', this.viewConfigBackup);
      return false;
    }
    let hasChanged = false;
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(this.viewConfigBackup.name, newConfig.name);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(this.viewConfigBackup.alias, newConfig.alias);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(this.viewConfigBackup.description, newConfig.description);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(this.viewConfigBackup.style, newConfig.style);
    hasChanged = hasChanged || this.viewConfigBackup.icon !== newConfig.icon;
    hasChanged = hasChanged || this.viewConfigBackup.alias !== newConfig.alias;
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(this.viewConfigBackup.settings, newConfig.settings);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(this.viewConfigBackup.subViews, newConfig.subViews);

    return hasChanged;
  }


  /**
   * Checks whether the configuration for the given component has been changed compared to the
   * current backup of the view configuration.
   *
   * @private
   * @param {ComponentInstanceConfig} newConfig Configuration of the component to check for changes.
   * @returns {boolean} Whether the config has changed.
   * @memberof ConfigUiHelper
   */
  private hasComponentConfigPropertiesChanged(newConfig: ComponentInstanceConfig): boolean {
    if (!this.viewConfigBackup) {
      this.logger.error(this.className, 'hasComponentConfigPropertiesChanged', 'No ViewConfig backup available', this.viewConfigBackup);
      return false;
    }
    const backup = this.viewConfigBackup.components.find((component) => component.guid === newConfig.guid)
      // If component is not within ViewConfigBackup, look it up in un-saved components
      || this.pendingComponentInstanceConfigs.find((component) => component.guid === newConfig.guid);

    if (!backup) {
      this.logger.error(this.className, 'hasComponentConfigPropertiesChanged', 'No backup found for new configuration', newConfig);
      return true;
    }
    let hasChanged = false;
    // Do not check position as this might change when moving components around.
    // These changes, however, are not handled here but in the ViewConfig itself.
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.component, newConfig.component);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.configuration, newConfig.configuration);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.guid, newConfig.guid);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.isTitleVisible, newConfig.isTitleVisible);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.name, newConfig.name);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.style, newConfig.style);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.title, newConfig.title);
    hasChanged = hasChanged || !Utils.Instance.isDeepEqual(backup.version, newConfig.version);
    return hasChanged;
  }


  /**
   * Checks whether the currently used view configuration in its current state
   * has any changes by comparing it to the backup.
   * This will check deeply for any property of the ViewConfig.
   *
   * @private
   * @returns {boolean} Whether the view configuration has any changes.
   * @memberof ConfigUiHelper
   */
  private isViewConfigDirty(): boolean {
    // Use stringify to ensure arrays have the same order
    return !Utils.Instance.isDeepEqual(Utils.Instance.sortObjectPropertiesDeep(this.getCurrentViewConfig()), Utils.Instance.sortObjectPropertiesDeep(this.viewConfigBackup), true);
  }

  /**
   * Checks whether the currently used configuration is dirty.
   * This includes the view config and the PubSub configuration.
   *
   * @private
   * @returns {boolean} Whether the configuration has any changes.
   * @memberof ConfigUiHelper
   */
  private isConfigDirty(): boolean {
    return this.isViewConfigDirty() || this.isPubSubDirty || this.isMappingDirty;
  }

  /**
   * Applies the given style for the view/component being edited.
   *
   * @private
   * @param {string} style Style class name to apply.
   * @memberof ConfigUiHelper
   */
  private handleStyleChange(style: string): void {
    if (this.configMode === CONFIG_MODES.COMPONENT) {
      const currentLayotManager = this.subViewManager.getCurrentLayoutManager(DWCore.Components.COMPONENT_TYPES.UI);
      if (currentLayotManager && this.currentlyEditedComponentGuid) {
        currentLayotManager.updateComponentStyle(this.currentlyEditedComponentGuid, style);
      }
    } else if (this.configMode === CONFIG_MODES.VIEW) {
      this.viewManager.updateLayoutStyle(style);
    }
  }

  /**
   * Attach every necessary event listener.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private attachAllEventListener(): void {
    this.rootContainer.addEventListener('click', this.configPanelListener = (e: Event) => this.openComponentConfig(e));
    this.rootContainer.addEventListener('click', this.removeComponentListener = (e: Event) => this.removeComponentClick(e));
    this.rootContainer.addEventListener('click', this.selectViewListener = (e: Event) => this.selectViewClick(e));
  }

  /**
   * Remove every event listener.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private removeAllEventListener(): void {
    this.rootContainer.removeEventListener('click', this.configPanelListener);
    this.rootContainer.removeEventListener('click', this.removeComponentListener);
    this.rootContainer.removeEventListener('click', this.selectViewListener);
  }

  /**
   * Updates view config on other UI helpers.
   * - PubSubUiHelper.
   * - MappingUiHelper.
   *
   * @private
   * @memberof ConfigUiHelper
   */
  private updateOtherHelpers(): void {
    this.pubSubUiHelper.updateViewConfig(this.getCurrentViewConfig(), this.currentDevice);
    // Always update the view config for the configuration component as well.
    this.pubSubHandler.publish(ConfigUiHelperUtil.CONFIGURATION_COMPONENT_GUID, 'ViewConfigUpdated', this.getCurrentViewConfig());
    if (this.mappingUiHelper) {
      this.mappingUiHelper.updateViewConfig(this.getCurrentViewConfig(), this.currentDevice);
    }
  }

  /**
   * Flags the given component as pening changes if there are any.
   *
   * @private
   * @param {ComponentInstanceConfig} newConfig New component instance configuration of component to flag.
   * @memberof ConfigUiHelper
   */
  private flagComponentAsDirty(newConfig: ComponentInstanceConfig): void {
    const hasChanged = this.hasComponentConfigPropertiesChanged(newConfig);
    const layoutManagers = this.subViewManager.getCurrentLayoutManagers();
    if (layoutManagers) {
      layoutManagers.forEach((layoutManager) => {
        layoutManager.setPendingChangesFlag(newConfig.guid, hasChanged);
      });
      this.setDiscardPossible(hasChanged);
    } else {
      this.logger.error(this.className, 'flagComponentAsDirty', 'No LayoutManager found to mark pending changes', layoutManagers);
    }
  }

  /**
   * Will open the config or resolve instantly if the config is already open.
   *
   * @returns {Promise<boolean>} A promise which will resolve with true if config is opened.
   * @memberof ConfigUiHelper
   */
  private async openConfig(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.configActive) {
        resolve(true);
        return;
      }
      const currentViewConfig = this.getCurrentViewConfig();
      if (currentViewConfig) {
        RouteManager.replaceOrPushState(null, '', EditRouter.generateEditUrlForViewId(currentViewConfig.alias ? currentViewConfig.alias : currentViewConfig.id));

        this.updateCurrentViewWithViewConfig(currentViewConfig);
      }
      // Set the device switch back to desktop.
      this.deviceMenuHandler.changeDevice(DWCore.Common.Devices.DESKTOP);
      this.rootContainer.classList.add('edit-active');
      this.rootContainer.classList.add(`edit-device-${DEVICE_NAMES[0]}`);
      this.attachAllEventListener();
      Promise.all([
        this.panelHandler.showSidePanels(),
        this.toggleEditMode(true)
      ]).then(() => {
        this.configActive = true;
        if (window) {
          const enterEditEvent = new Event('wd.enteredit', {
            bubbles: true,
          });
          window.dispatchEvent(enterEditEvent);
        }
        this.uiManager.removeBusyStateIndicator();
        resolve(true);
      }).catch((err: Error) => {
        this.uiManager.removeBusyStateIndicator();
        this.logger.error(this.className, 'toggleEdit', 'Failed to toggle edit mode to active', err);
      });
    });
  }

  /**
   * Will close the config or instantly resolve if the config is already closed.
   *
   * @returns {Promise<boolean>} A promise which will resolve with true if config is closed.
   * @memberof ConfigUiHelper
   */
  private async closeConfig(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this.configActive) {
        resolve(true);
        return;
      }
      this.toggleEditMode(false)
        .then((leaveEditMode: boolean) => {
          if (leaveEditMode) {
            this.removeAllEventListener();
            this.panelHandler.hideSidePanels().catch((err: Error) => {
              this.logger.error(this.className, 'toggleEdit', 'Failed to hide side panels', err);
            });
            this.rootContainer.classList.remove('edit-active');
            this.rootContainer.classList.remove(...DEVICE_NAMES.map((device) => `edit-device-${device}`));
            this.configActive = false;
            this.pubSubUiHelper.hide().catch((err: Error) => {
              this.logger.error(this.className, 'toggleEdit', 'Failed to hide PubSub config', err);
            });
            this.rootContainer.classList.remove('pubsub-active');
            this.mappingUiHelper ? this.mappingUiHelper.hide().catch((err: Error) => {
              this.logger.error(this.className, 'toggleEdit', 'Failed to hide mapping config', err);
            }) : Promise.resolve();
            this.rootContainer.classList.remove('mapping-active');
          }
          if (window) {
            const leaveEditEvent = new Event('wd.leaveedit', {
              bubbles: true,
            });
            window.dispatchEvent(leaveEditEvent);
          }
          this.uiManager.removeBusyStateIndicator();
          ConfigUiHelper.configHasUnsavedChanges = false;
          resolve(leaveEditMode);
        }).catch((err: Error) => {
          this.logger.error(this.className, 'toggleEdit', 'Failed to toggle edit mode to inactive', err);
          this.uiManager.removeBusyStateIndicator();
        });
    });
  }

}