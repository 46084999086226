import { BinaryHttpResponseTypes, IBinaryHttpResourcePointer, IHttpResourcePointer } from '../dataProviders';
import { Logger } from '../logging/logger';
import { HttpResponse } from './httpResponse';
import { IAjaxHandler } from './interfaces/iAjaxHandler';
import { IHttpResponse } from './interfaces/iHttpResponse';
import { IServiceRequest } from './interfaces/iServiceRequest';


/**
 * An Ajax handler based on JQuery.
 *
 * @export
 * @class JQueryAjaxHandler
 * @implements {IAjaxHandler}
 */
export class AjaxHandler implements IAjaxHandler {

    private readonly HTTP_STATUS_OK = 200;
    private readonly HTTP_STATUS_NOT_MODIFIED = 304;
    private readonly HTTP_STATUS_CONFLICT = 409;

    private readonly READY_STATE_REQUEST_FINISHED = 4;  // Request finished and response is ready

    private logger: Logger;

    /**
     * Creates an instance of AjaxHandler.
     * 
     * @param {Logger} logger 
     * @memberof AjaxHandler
     */
    public constructor(logger: Logger) {
        if (!logger) {
            throw new ReferenceError('The argument\"logger\" is null or undefined.');
        }
        this.logger = logger;
    }


    /**
     * Creates a new raw request.
     * 
     * @param {IResourcePointer} resourcePointer
     * @returns {XMLHttpRequest}
     * @memberof AjaxHandler
     */
    public createRawRequest(resourcePointer: IHttpResourcePointer): XMLHttpRequest {

        if (!resourcePointer) {
            throw new ReferenceError('The argument \"resourcePointer\" is null or undefined.');
        }

        const rawRequest = new XMLHttpRequest();
        rawRequest.open(resourcePointer.action, resourcePointer.uri, true);

        const binaryResourcePointer = resourcePointer as IBinaryHttpResourcePointer;
        if (binaryResourcePointer && binaryResourcePointer.responseType) {
            rawRequest.responseType = <any>binaryResourcePointer.responseType;
        }

        return rawRequest;
    }

    /**
     * Executes the request.
     * 
     * @template T 
     * @param {IServiceRequest} request 
     * @returns {Promise<IHttpResponse<T>>} 
     * @async
     * 
     * @memberof AjaxHandler
     */
    public async execute<T>(request: IServiceRequest): Promise<IHttpResponse<T>> {

        return new Promise<IHttpResponse<T>>((resolve, reject) => {
            if (!request) {
                reject('The argument \"request\" was null or undefined.');
            }

            if (!request.rawRequest) {
                reject('The raw request instance is null or undefined.');
            }

            let tempRawRequest: XMLHttpRequest = request.rawRequest;

            // Set request headers
            tempRawRequest = this.setRequestHeaders(tempRawRequest, request.resourcePointer as IHttpResourcePointer);

            try {
                tempRawRequest.onreadystatechange = () => {

                    if (tempRawRequest.readyState === this.READY_STATE_REQUEST_FINISHED) {

                        const response = new HttpResponse<T>();
                        response.statusCode = tempRawRequest.status;
                        response.resourcePointer = request.resourcePointer;

                        // Try to get all response headers.
                        const allResponseHeaders = this.getResponseHeaders(tempRawRequest);
                        if (allResponseHeaders && allResponseHeaders.size > 0) {
                            response.httpHeaders = allResponseHeaders;
                        }

                        if (tempRawRequest.status === this.HTTP_STATUS_NOT_MODIFIED) {
                            resolve(response);
                        } else if (tempRawRequest.status === this.HTTP_STATUS_OK) {
                            try {
                                response.data = this.getResponse(tempRawRequest);
                            } catch (error) {
                                this.logger.error('AjaxHandler', 'execute', 'Failed to parse response: ', error);
                                response.data = tempRawRequest.response;
                                reject(response);
                            }

                            resolve(response);
                        } else if (tempRawRequest.status === this.HTTP_STATUS_CONFLICT) {
                            response.data = tempRawRequest.response;
                            reject(response);
                        } else {
                            // On fail
                            try {
                                response.data = this.getResponse(tempRawRequest);
                            } catch (error) {
                                this.logger.error('AjaxHandler', 'execute', 'Failed to parse response: ', error);
                                response.data = tempRawRequest.response;
                            }

                            reject(response);
                        }
                    }

                };
            } catch (error) {
                this.logger.error('AjaxHandler', 'execute', 'Failed to execute: ', error);

                reject(error);
            }

            tempRawRequest.send(request.resourcePointer.parameter);
        });

    }


    /**
     * Gets all response headers from the raw response as Map<string, string>.
     * 
     * @private
     * @param {XMLHttpRequest} tempRawRequest 
     * @returns {Map<string, string>} 
     * @memberof AjaxHandler
     */
    private getResponseHeaders(tempRawRequest: XMLHttpRequest): Map<string, string> {

        const resultResponseHeaders = new Map<string, string>();

        const allResponseHeaders = tempRawRequest.getAllResponseHeaders();
        if (!allResponseHeaders) {
            return resultResponseHeaders;
        }

        const headerPairs = allResponseHeaders.split('\u000d\u000a');
        for (let i = 0, len = headerPairs.length; i < len; i++) {
            const headerPair = headerPairs[i];
            const index = headerPair.indexOf('\u003a\u0020');
            if (index > 0) {
                const key = headerPair.substring(0, index);
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                const val = headerPair.substring(index + 2);
                resultResponseHeaders.set(key, val);
            }
        }

        return resultResponseHeaders;
    }


    /**
     * Gets the response from the raw request.
     * 
     * @private
     * @param {XMLHttpRequest} rawRequest 
     * @returns {*} 
     * @memberof AjaxHandler
     */
    private getResponse(rawRequest: XMLHttpRequest): any {

        if (!rawRequest) {
            throw new ReferenceError('The argument \"rawRequest\" was null or undefined.');
        }

        if (rawRequest.responseType && rawRequest.responseType === BinaryHttpResponseTypes.ARRAYBUFFER) {
            return rawRequest.response;
        }

        const contentType = rawRequest.getResponseHeader('content-type') || '';

        if (contentType.indexOf('json') !== -1) {
            if (typeof (rawRequest.response) === 'string') {
                return JSON.parse(rawRequest.response);
            } else {
                return rawRequest.response;
            }
        } else {
            return rawRequest.response;
        }
    }


    /**
     *  Sets all specified request headers.
     * 
     * @private
     * @param {XMLHttpRequest} rawRequest 
     * @param {IHttpResourcePointer} resourcePointer 
     * @returns {XMLHttpRequest} 
     * @memberof AjaxHandler
     */
    private setRequestHeaders(rawRequest: XMLHttpRequest, resourcePointer: IHttpResourcePointer): XMLHttpRequest {

        const tempRawRequest = rawRequest;

        if (tempRawRequest && resourcePointer && resourcePointer.httpHeaders) {
            resourcePointer.httpHeaders.forEach((value, key) => {
                tempRawRequest.setRequestHeader(key, value);
            });
        }

        return tempRawRequest;
    }

}