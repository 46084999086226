import * as Handlebars from 'handlebars';

// @ts-ignore - Ignored because typings are not available
import HandlebarsBinding from 'handlebars.binding';

import { Utils } from '../common';
import { ITemplateExtension } from './interfaces';

/**
 * The template extension provides a handelbar/tempalte engine.
 * 
 * @export
 * @class TemplateExtension
 * @implements {ITemplateExtension}
 */
export class TemplateExtension implements ITemplateExtension {

  /**
   * The nmae of this extension.
   * 
   * @type {string}
   * @memberof TemplateExtension
   */
  public name: string = 'template';

  private handlebarInstances: Map<string, typeof Handlebars>;

  /**
   * Creates an instance of TemplateExtension.
   * @memberof TemplateExtension
   */
  public constructor() {
    this.handlebarInstances = new Map<string, typeof Handlebars>();
  }


  /**
   * Render the HTML template into the target.
   * 
   * @param {(HTMLElement)} target The target.
   * @param {string} template The HTML template.
   * @param {object} [dataModel] The data model which is used by the template.
   * @param {boolean} [isBindingEnabled=false] Whether one-way-binding should be enabled.
   * 
   * @memberof TemplateExtension
   */
  public render(target: HTMLElement, template: string, dataModel?: object, isBindingEnabled = false): void {
    if (isBindingEnabled) {
      const handlebarsInstance = this.getInstanceFromElement(target);
      const hasRendered = Utils.getWdAttribute(target, 'rendered', 0);
      if (hasRendered) {
        if ('update' in handlebarsInstance) {
          // @ts-ignore - Update is provided by handlebars binding
          handlebarsInstance.update();
        } else {
          throw new Error('Failed to update already rendered template.');
        }
      } else {
        const compiledTemplate = handlebarsInstance.compile(template)(dataModel);
        if (!('parseHTML' in handlebarsInstance)) {
          throw new Error('`parseHTML()` not available in Handlebars instance.');
        }
        // @ts-ignore - Typings miss parseHTML
        const nodes: HTMLElement[] = handlebarsInstance.parseHTML(compiledTemplate);
        target.innerHTML = '';
        nodes.forEach((node) => target.appendChild(node));
        target.setAttribute('data-wd-rendered', 'true');
      }
    } else {
      target.innerHTML = this.compile(template, dataModel);
    }
  }

  /**
   * Parses the template and returns it as string.
   * 
   * @param {string} template The HTML template.
   * @param {object} [dataModel] The data model which is used by the template.
   * @param {HTMLElement} [targetElement] The target element which has enabled data binding.
   * 
   * @memberof TemplateExtension
   */
  public compile(template: string, dataModel?: object, targetElement?: HTMLElement): string {
    if (targetElement) {
      const handlebarsInstance = this.getInstanceFromElement(targetElement);
      return handlebarsInstance.compile(template)(dataModel);
    } else {
      const handelbarsTemplateDelegate = Handlebars.compile(template);
      return handelbarsTemplateDelegate(dataModel);
    }
  }

  /**
   * Adds a helper to the templating engine.
   * 
   * @param {string} name Helper name.
   * @param {(...args: any[]) => void} helper Helper function.
   * @param {HTMLElement} [element] Optional element to attach helper to a specific instance if binding is used for rendering.
   * @memberof TemplateExtension
   */
  public addHelper(name: string, helper: (...args: any[]) => void, element?: HTMLElement): void {
    if (element) {
      const handlebarsInstance = this.getInstanceFromElement(element);
      handlebarsInstance.registerHelper(name, helper);
    } else {
      Handlebars.registerHelper(name, helper);
    }
  }

  /**
   * Returns the handle bars instance associated with the element.
   * Will create a new instance if none exists yet.
   * 
   * @private
   * @param {HTMLElement} element Element to get instance for.
   * @returns {typeof Handlebars} Handlebars instance for the element.
   * @memberof TemplateExtension
   */
  private getInstanceFromElement(element: HTMLElement): typeof Handlebars {
    const id = Utils.getWdAttribute(element, 'template-id', 1);
    let handlebarsInstance: typeof Handlebars;
    if (id && this.handlebarInstances.has(id)) {
      return handlebarsInstance = this.handlebarInstances.get(id) as typeof Handlebars;
    } else {
      const newId = Utils.Instance.getRandomString();
      element.setAttribute('data-wd-template-id', newId);
      handlebarsInstance = HandlebarsBinding(Handlebars.create());
      this.handlebarInstances.set(newId, handlebarsInstance);
      return handlebarsInstance;
    }
  }
}