
/**
 * The ObjectType class represents the windream ObjectType model.
 * 
 * @export
 * @class ObjectType
 */
export class ObjectType {

    /**
     * The id of this ObjectType.
     * 
     * @type {number}
     * @memberof ObjectType
     */
    public id: number;

    /**
     * The name of this ObjectType.
     * 
     * @type {string}
     * @memberof ObjectType
     */
    public name: string;

    /**
     * The display name.
     *
     * @type {string}
     * @memberof ObjectType
     */
    public displayName?: string;

    /**
     * The entity of this ObjectType.
     * 
     * @type {number}
     * @memberof ObjectType
     */
    public entity: number;

    /**
     * Determines whether the object type is assignable.
     *
     * @type {boolean}
     * @memberof ObjectType
     */
    public isAssignable?: boolean;

    /**
     * Determines whether the object type is searchable.
     *
     * @type {boolean}
     * @memberof ObjectType
     */
    public isSearchable?: boolean;

    /**
     * Creates an instance of ObjectType.
     * 
     * @param {number} id
     * @param {string} name
     * @param {number} enttiy
     * @memberof ObjectType
     */
    public constructor(id: number, name: string, entity: number) {
        this.id = id;
        this.name = name;
        this.entity = entity;
    }
}