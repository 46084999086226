/* eslint-disable max-lines */
/**
 * Available icons for the view, component, ... configurations.
 * 
 * @export
 * @enum {number}
 */
export enum APP_ICONS {
    // Manually added default icons
    DEFAULT_BUTTON = 'fa-sharp fa-light fa-info-circle',
    DEFAULT_VIEW = 'fa-sharp fa-light fa-th',
    // Manually added icons that were not created by the script 'get_fontawesome_icons_as_enum.py' but which are required for compatibilty
    // Icons from old VIEW_ICONS and BUTTON_ICONS
    ARCHIVE = 'fa-sharp fa-light fa-archive',
	BALANCE_SCALE = 'fa-sharp fa-light fa-balance-scale',
	COGS = 'fa-sharp fa-light fa-cogs',
	DOLLAR = 'fa-sharp fa-light fa-dollar-sign',
	EURO = 'fa-sharp fa-light fa-euro-sign',
    PUND = 'fa-sharp fa-light fa-pound-sign',
	PROJECT_DIAGRAM = 'fa-sharp fa-light fa-project-diagram',
	RANDOM = 'fa-sharp fa-light fa-random',
    SEARCH = 'fa-sharp fa-light fa-search',
	SHOPPING_CART = 'fa-sharp fa-light fa-shopping-cart',
	START = 'fa-sharp fa-light fa-star',
	FRIENDS = 'fa-sharp fa-light fa-user-friends',
	SIGN = 'fa-sharp fa-light fa-sign',
    LOOP = 'fa-sharp fa-light fa-retweet',
	QR_CODE = 'fa-sharp fa-light fa-qrcode',
    EDIT = 'fa-sharp fa-light fa-edit',
	FILEEDIT = 'fa-sharp fa-light fa-pen',
	SAVE = 'fa-sharp fa-light fa-save',
	PAPERPLANE = 'fa-sharp fa-light fa-paper-plane',
	REDO = 'fa-sharp fa-light fa-redo',
	LEFTARROW = 'fa-sharp fa-light fa-arrow-left',
	DIAGRAM = 'fa-sharp fa-light fa-project-diagram',
	TASKS = 'fa-sharp fa-light fa-tasks',
	PDF = 'fa-sharp fa-light fa-file-pdf',
	FileCheck = 'fa-sharp fa-light fa-file-contract',
	FileSearch = 'fa-sharp fa-light fa-search-plus',
    // All fontawesome icons generated by the script 'get_fontawesome_icons_as_enum.py'
    A = 'fa-sharp fa-light fa-a',
    ABACUS = 'fa-sharp fa-light fa-abacus',
    ACCENT_GRAVE = 'fa-sharp fa-light fa-accent-grave',
    ACORN = 'fa-sharp fa-light fa-acorn',
    ADDRESS_BOOK = 'fa-sharp fa-light fa-address-book',
    ADDRESS_CARD = 'fa-sharp fa-light fa-address-card',
    AIRPLAY = 'fa-sharp fa-light fa-airplay',
    AIR_CONDITIONER = 'fa-sharp fa-light fa-air-conditioner',
    ALARM_CLOCK = 'fa-sharp fa-light fa-alarm-clock',
    ALARM_EXCLAMATION = 'fa-sharp fa-light fa-alarm-exclamation',
    ALARM_PLUS = 'fa-sharp fa-light fa-alarm-plus',
    ALARM_SNOOZE = 'fa-sharp fa-light fa-alarm-snooze',
    ALBUM = 'fa-sharp fa-light fa-album',
    ALBUM_CIRCLE_PLUS = 'fa-sharp fa-light fa-album-circle-plus',
    ALBUM_CIRCLE_USER = 'fa-sharp fa-light fa-album-circle-user',
    ALBUM_COLLECTION = 'fa-sharp fa-light fa-album-collection',
    ALBUM_COLLECTION_CIRCLE_PLUS = 'fa-sharp fa-light fa-album-collection-circle-plus',
    ALBUM_COLLECTION_CIRCLE_USER = 'fa-sharp fa-light fa-album-collection-circle-user',
    ALICORN = 'fa-sharp fa-light fa-alicorn',
    ALIEN = 'fa-sharp fa-light fa-alien',
    ALIEN_8BIT = 'fa-sharp fa-light fa-alien-8bit',
    ALIGN_CENTER = 'fa-sharp fa-light fa-align-center',
    ALIGN_JUSTIFY = 'fa-sharp fa-light fa-align-justify',
    ALIGN_LEFT = 'fa-sharp fa-light fa-align-left',
    ALIGN_RIGHT = 'fa-sharp fa-light fa-align-right',
    ALIGN_SLASH = 'fa-sharp fa-light fa-align-slash',
    ALT = 'fa-sharp fa-light fa-alt',
    AMPERSAND = 'fa-sharp fa-light fa-ampersand',
    AMP_GUITAR = 'fa-sharp fa-light fa-amp-guitar',
    ANCHOR = 'fa-sharp fa-light fa-anchor',
    ANCHOR_CIRCLE_CHECK = 'fa-sharp fa-light fa-anchor-circle-check',
    ANCHOR_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-anchor-circle-exclamation',
    ANCHOR_CIRCLE_XMARK = 'fa-sharp fa-light fa-anchor-circle-xmark',
    ANCHOR_LOCK = 'fa-sharp fa-light fa-anchor-lock',
    ANGEL = 'fa-sharp fa-light fa-angel',
    ANGLE = 'fa-sharp fa-light fa-angle',
    ANGLES_DOWN = 'fa-sharp fa-light fa-angles-down',
    ANGLES_LEFT = 'fa-sharp fa-light fa-angles-left',
    ANGLES_RIGHT = 'fa-sharp fa-light fa-angles-right',
    ANGLES_UP = 'fa-sharp fa-light fa-angles-up',
    ANGLES_UP_DOWN = 'fa-sharp fa-light fa-angles-up-down',
    ANGLE_90 = 'fa-sharp fa-light fa-angle-90',
    ANGLE_DOWN = 'fa-sharp fa-light fa-angle-down',
    ANGLE_LEFT = 'fa-sharp fa-light fa-angle-left',
    ANGLE_RIGHT = 'fa-sharp fa-light fa-angle-right',
    ANGLE_UP = 'fa-sharp fa-light fa-angle-up',
    ANKH = 'fa-sharp fa-light fa-ankh',
    ANT = 'fa-sharp fa-light fa-ant',
    APARTMENT = 'fa-sharp fa-light fa-apartment',
    APERTURE = 'fa-sharp fa-light fa-aperture',
    APOSTROPHE = 'fa-sharp fa-light fa-apostrophe',
    APPLE_CORE = 'fa-sharp fa-light fa-apple-core',
    APPLE_WHOLE = 'fa-sharp fa-light fa-apple-whole',
    ARCHWAY = 'fa-sharp fa-light fa-archway',
    ARROWS_CROSS = 'fa-sharp fa-light fa-arrows-cross',
    ARROWS_DOWN_TO_LINE = 'fa-sharp fa-light fa-arrows-down-to-line',
    ARROWS_DOWN_TO_PEOPLE = 'fa-sharp fa-light fa-arrows-down-to-people',
    ARROWS_FROM_DOTTED_LINE = 'fa-sharp fa-light fa-arrows-from-dotted-line',
    ARROWS_FROM_LINE = 'fa-sharp fa-light fa-arrows-from-line',
    ARROWS_LEFT_RIGHT = 'fa-sharp fa-light fa-arrows-left-right',
    ARROWS_LEFT_RIGHT_TO_LINE = 'fa-sharp fa-light fa-arrows-left-right-to-line',
    ARROWS_MAXIMIZE = 'fa-sharp fa-light fa-arrows-maximize',
    ARROWS_MINIMIZE = 'fa-sharp fa-light fa-arrows-minimize',
    ARROWS_REPEAT = 'fa-sharp fa-light fa-arrows-repeat',
    ARROWS_REPEAT_1 = 'fa-sharp fa-light fa-arrows-repeat-1',
    ARROWS_RETWEET = 'fa-sharp fa-light fa-arrows-retweet',
    ARROWS_ROTATE = 'fa-sharp fa-light fa-arrows-rotate',
    ARROWS_ROTATE_REVERSE = 'fa-sharp fa-light fa-arrows-rotate-reverse',
    ARROWS_SPIN = 'fa-sharp fa-light fa-arrows-spin',
    ARROWS_SPLIT_UP_AND_LEFT = 'fa-sharp fa-light fa-arrows-split-up-and-left',
    ARROWS_TO_CIRCLE = 'fa-sharp fa-light fa-arrows-to-circle',
    ARROWS_TO_DOT = 'fa-sharp fa-light fa-arrows-to-dot',
    ARROWS_TO_DOTTED_LINE = 'fa-sharp fa-light fa-arrows-to-dotted-line',
    ARROWS_TO_EYE = 'fa-sharp fa-light fa-arrows-to-eye',
    ARROWS_TO_LINE = 'fa-sharp fa-light fa-arrows-to-line',
    ARROWS_TURN_RIGHT = 'fa-sharp fa-light fa-arrows-turn-right',
    ARROWS_TURN_TO_DOTS = 'fa-sharp fa-light fa-arrows-turn-to-dots',
    ARROWS_UP_DOWN = 'fa-sharp fa-light fa-arrows-up-down',
    ARROWS_UP_DOWN_LEFT_RIGHT = 'fa-sharp fa-light fa-arrows-up-down-left-right',
    ARROWS_UP_TO_LINE = 'fa-sharp fa-light fa-arrows-up-to-line',
    ARROW_DOWN = 'fa-sharp fa-light fa-arrow-down',
    ARROW_DOWN_1_9 = 'fa-sharp fa-light fa-arrow-down-1-9',
    ARROW_DOWN_9_1 = 'fa-sharp fa-light fa-arrow-down-9-1',
    ARROW_DOWN_ARROW_UP = 'fa-sharp fa-light fa-arrow-down-arrow-up',
    ARROW_DOWN_A_Z = 'fa-sharp fa-light fa-arrow-down-a-z',
    ARROW_DOWN_BIG_SMALL = 'fa-sharp fa-light fa-arrow-down-big-small',
    ARROW_DOWN_FROM_ARC = 'fa-sharp fa-light fa-arrow-down-from-arc',
    ARROW_DOWN_FROM_BRACKET = 'fa-sharp fa-light fa-arrow-down-from-bracket',
    ARROW_DOWN_FROM_DOTTED_LINE = 'fa-sharp fa-light fa-arrow-down-from-dotted-line',
    ARROW_DOWN_FROM_LINE = 'fa-sharp fa-light fa-arrow-down-from-line',
    ARROW_DOWN_LEFT = 'fa-sharp fa-light fa-arrow-down-left',
    ARROW_DOWN_LEFT_AND_ARROW_UP_RIGHT_TO_CENTER = 'fa-sharp fa-light fa-arrow-down-left-and-arrow-up-right-to-center',
    ARROW_DOWN_LONG = 'fa-sharp fa-light fa-arrow-down-long',
    ARROW_DOWN_RIGHT = 'fa-sharp fa-light fa-arrow-down-right',
    ARROW_DOWN_SHORT_WIDE = 'fa-sharp fa-light fa-arrow-down-short-wide',
    ARROW_DOWN_SMALL_BIG = 'fa-sharp fa-light fa-arrow-down-small-big',
    ARROW_DOWN_SQUARE_TRIANGLE = 'fa-sharp fa-light fa-arrow-down-square-triangle',
    ARROW_DOWN_TO_ARC = 'fa-sharp fa-light fa-arrow-down-to-arc',
    ARROW_DOWN_TO_BRACKET = 'fa-sharp fa-light fa-arrow-down-to-bracket',
    ARROW_DOWN_TO_DOTTED_LINE = 'fa-sharp fa-light fa-arrow-down-to-dotted-line',
    ARROW_DOWN_TO_LINE = 'fa-sharp fa-light fa-arrow-down-to-line',
    ARROW_DOWN_TO_SQUARE = 'fa-sharp fa-light fa-arrow-down-to-square',
    ARROW_DOWN_TRIANGLE_SQUARE = 'fa-sharp fa-light fa-arrow-down-triangle-square',
    ARROW_DOWN_UP_ACROSS_LINE = 'fa-sharp fa-light fa-arrow-down-up-across-line',
    ARROW_DOWN_UP_LOCK = 'fa-sharp fa-light fa-arrow-down-up-lock',
    ARROW_DOWN_WIDE_SHORT = 'fa-sharp fa-light fa-arrow-down-wide-short',
    ARROW_DOWN_Z_A = 'fa-sharp fa-light fa-arrow-down-z-a',
    ARROW_LEFT = 'fa-sharp fa-light fa-arrow-left',
    ARROW_LEFT_FROM_ARC = 'fa-sharp fa-light fa-arrow-left-from-arc',
    ARROW_LEFT_FROM_BRACKET = 'fa-sharp fa-light fa-arrow-left-from-bracket',
    ARROW_LEFT_FROM_LINE = 'fa-sharp fa-light fa-arrow-left-from-line',
    ARROW_LEFT_LONG = 'fa-sharp fa-light fa-arrow-left-long',
    ARROW_LEFT_LONG_TO_LINE = 'fa-sharp fa-light fa-arrow-left-long-to-line',
    ARROW_LEFT_TO_ARC = 'fa-sharp fa-light fa-arrow-left-to-arc',
    ARROW_LEFT_TO_BRACKET = 'fa-sharp fa-light fa-arrow-left-to-bracket',
    ARROW_LEFT_TO_LINE = 'fa-sharp fa-light fa-arrow-left-to-line',
    ARROW_POINTER = 'fa-sharp fa-light fa-arrow-pointer',
    ARROW_PROGRESS = 'fa-sharp fa-light fa-arrow-progress',
    ARROW_RIGHT = 'fa-sharp fa-light fa-arrow-right',
    ARROW_RIGHT_ARROW_LEFT = 'fa-sharp fa-light fa-arrow-right-arrow-left',
    ARROW_RIGHT_FROM_ARC = 'fa-sharp fa-light fa-arrow-right-from-arc',
    ARROW_RIGHT_FROM_BRACKET = 'fa-sharp fa-light fa-arrow-right-from-bracket',
    ARROW_RIGHT_FROM_LINE = 'fa-sharp fa-light fa-arrow-right-from-line',
    ARROW_RIGHT_LONG = 'fa-sharp fa-light fa-arrow-right-long',
    ARROW_RIGHT_LONG_TO_LINE = 'fa-sharp fa-light fa-arrow-right-long-to-line',
    ARROW_RIGHT_TO_ARC = 'fa-sharp fa-light fa-arrow-right-to-arc',
    ARROW_RIGHT_TO_BRACKET = 'fa-sharp fa-light fa-arrow-right-to-bracket',
    ARROW_RIGHT_TO_CITY = 'fa-sharp fa-light fa-arrow-right-to-city',
    ARROW_RIGHT_TO_LINE = 'fa-sharp fa-light fa-arrow-right-to-line',
    ARROW_ROTATE_LEFT = 'fa-sharp fa-light fa-arrow-rotate-left',
    ARROW_ROTATE_RIGHT = 'fa-sharp fa-light fa-arrow-rotate-right',
    ARROW_TREND_DOWN = 'fa-sharp fa-light fa-arrow-trend-down',
    ARROW_TREND_UP = 'fa-sharp fa-light fa-arrow-trend-up',
    ARROW_TURN_DOWN = 'fa-sharp fa-light fa-arrow-turn-down',
    ARROW_TURN_DOWN_LEFT = 'fa-sharp fa-light fa-arrow-turn-down-left',
    ARROW_TURN_DOWN_RIGHT = 'fa-sharp fa-light fa-arrow-turn-down-right',
    ARROW_TURN_LEFT = 'fa-sharp fa-light fa-arrow-turn-left',
    ARROW_TURN_LEFT_DOWN = 'fa-sharp fa-light fa-arrow-turn-left-down',
    ARROW_TURN_LEFT_UP = 'fa-sharp fa-light fa-arrow-turn-left-up',
    ARROW_TURN_RIGHT = 'fa-sharp fa-light fa-arrow-turn-right',
    ARROW_TURN_UP = 'fa-sharp fa-light fa-arrow-turn-up',
    ARROW_UP = 'fa-sharp fa-light fa-arrow-up',
    ARROW_UP_1_9 = 'fa-sharp fa-light fa-arrow-up-1-9',
    ARROW_UP_9_1 = 'fa-sharp fa-light fa-arrow-up-9-1',
    ARROW_UP_ARROW_DOWN = 'fa-sharp fa-light fa-arrow-up-arrow-down',
    ARROW_UP_A_Z = 'fa-sharp fa-light fa-arrow-up-a-z',
    ARROW_UP_BIG_SMALL = 'fa-sharp fa-light fa-arrow-up-big-small',
    ARROW_UP_FROM_ARC = 'fa-sharp fa-light fa-arrow-up-from-arc',
    ARROW_UP_FROM_BRACKET = 'fa-sharp fa-light fa-arrow-up-from-bracket',
    ARROW_UP_FROM_DOTTED_LINE = 'fa-sharp fa-light fa-arrow-up-from-dotted-line',
    ARROW_UP_FROM_GROUND_WATER = 'fa-sharp fa-light fa-arrow-up-from-ground-water',
    ARROW_UP_FROM_LINE = 'fa-sharp fa-light fa-arrow-up-from-line',
    ARROW_UP_FROM_SQUARE = 'fa-sharp fa-light fa-arrow-up-from-square',
    ARROW_UP_FROM_WATER_PUMP = 'fa-sharp fa-light fa-arrow-up-from-water-pump',
    ARROW_UP_LEFT = 'fa-sharp fa-light fa-arrow-up-left',
    ARROW_UP_LEFT_FROM_CIRCLE = 'fa-sharp fa-light fa-arrow-up-left-from-circle',
    ARROW_UP_LONG = 'fa-sharp fa-light fa-arrow-up-long',
    ARROW_UP_RIGHT = 'fa-sharp fa-light fa-arrow-up-right',
    ARROW_UP_RIGHT_AND_ARROW_DOWN_LEFT_FROM_CENTER = 'fa-sharp fa-light fa-arrow-up-right-and-arrow-down-left-from-center',
    ARROW_UP_RIGHT_DOTS = 'fa-sharp fa-light fa-arrow-up-right-dots',
    ARROW_UP_RIGHT_FROM_SQUARE = 'fa-sharp fa-light fa-arrow-up-right-from-square',
    ARROW_UP_SHORT_WIDE = 'fa-sharp fa-light fa-arrow-up-short-wide',
    ARROW_UP_SMALL_BIG = 'fa-sharp fa-light fa-arrow-up-small-big',
    ARROW_UP_SQUARE_TRIANGLE = 'fa-sharp fa-light fa-arrow-up-square-triangle',
    ARROW_UP_TO_ARC = 'fa-sharp fa-light fa-arrow-up-to-arc',
    ARROW_UP_TO_BRACKET = 'fa-sharp fa-light fa-arrow-up-to-bracket',
    ARROW_UP_TO_DOTTED_LINE = 'fa-sharp fa-light fa-arrow-up-to-dotted-line',
    ARROW_UP_TO_LINE = 'fa-sharp fa-light fa-arrow-up-to-line',
    ARROW_UP_TRIANGLE_SQUARE = 'fa-sharp fa-light fa-arrow-up-triangle-square',
    ARROW_UP_WIDE_SHORT = 'fa-sharp fa-light fa-arrow-up-wide-short',
    ARROW_UP_Z_A = 'fa-sharp fa-light fa-arrow-up-z-a',
    ASTERISK = 'fa-sharp fa-light fa-asterisk',
    AT = 'fa-sharp fa-light fa-at',
    ATOM = 'fa-sharp fa-light fa-atom',
    ATOM_SIMPLE = 'fa-sharp fa-light fa-atom-simple',
    AUDIO_DESCRIPTION = 'fa-sharp fa-light fa-audio-description',
    AUDIO_DESCRIPTION_SLASH = 'fa-sharp fa-light fa-audio-description-slash',
    AUSTRAL_SIGN = 'fa-sharp fa-light fa-austral-sign',
    AVOCADO = 'fa-sharp fa-light fa-avocado',
    AWARD = 'fa-sharp fa-light fa-award',
    AWARD_SIMPLE = 'fa-sharp fa-light fa-award-simple',
    AXE = 'fa-sharp fa-light fa-axe',
    AXE_BATTLE = 'fa-sharp fa-light fa-axe-battle',
    B = 'fa-sharp fa-light fa-b',
    BABY = 'fa-sharp fa-light fa-baby',
    BABY_CARRIAGE = 'fa-sharp fa-light fa-baby-carriage',
    BACKPACK = 'fa-sharp fa-light fa-backpack',
    BACKWARD = 'fa-sharp fa-light fa-backward',
    BACKWARD_FAST = 'fa-sharp fa-light fa-backward-fast',
    BACKWARD_STEP = 'fa-sharp fa-light fa-backward-step',
    BACON = 'fa-sharp fa-light fa-bacon',
    BACTERIA = 'fa-sharp fa-light fa-bacteria',
    BACTERIUM = 'fa-sharp fa-light fa-bacterium',
    BADGE = 'fa-sharp fa-light fa-badge',
    BADGER_HONEY = 'fa-sharp fa-light fa-badger-honey',
    BADGE_CHECK = 'fa-sharp fa-light fa-badge-check',
    BADGE_DOLLAR = 'fa-sharp fa-light fa-badge-dollar',
    BADGE_PERCENT = 'fa-sharp fa-light fa-badge-percent',
    BADGE_SHERIFF = 'fa-sharp fa-light fa-badge-sheriff',
    BADMINTON = 'fa-sharp fa-light fa-badminton',
    BAGEL = 'fa-sharp fa-light fa-bagel',
    BAGS_SHOPPING = 'fa-sharp fa-light fa-bags-shopping',
    BAGUETTE = 'fa-sharp fa-light fa-baguette',
    BAG_SEEDLING = 'fa-sharp fa-light fa-bag-seedling',
    BAG_SHOPPING = 'fa-sharp fa-light fa-bag-shopping',
    BAG_SHOPPING_MINUS = 'fa-sharp fa-light fa-bag-shopping-minus',
    BAG_SHOPPING_PLUS = 'fa-sharp fa-light fa-bag-shopping-plus',
    BAHAI = 'fa-sharp fa-light fa-bahai',
    BAHT_SIGN = 'fa-sharp fa-light fa-baht-sign',
    BALLOON = 'fa-sharp fa-light fa-balloon',
    BALLOONS = 'fa-sharp fa-light fa-balloons',
    BALLOT = 'fa-sharp fa-light fa-ballot',
    BALLOT_CHECK = 'fa-sharp fa-light fa-ballot-check',
    BALL_PILE = 'fa-sharp fa-light fa-ball-pile',
    BAN = 'fa-sharp fa-light fa-ban',
    BANANA = 'fa-sharp fa-light fa-banana',
    BANDAGE = 'fa-sharp fa-light fa-bandage',
    BANGLADESHI_TAKA_SIGN = 'fa-sharp fa-light fa-bangladeshi-taka-sign',
    BANJO = 'fa-sharp fa-light fa-banjo',
    BAN_BUG = 'fa-sharp fa-light fa-ban-bug',
    BAN_PARKING = 'fa-sharp fa-light fa-ban-parking',
    BAN_SMOKING = 'fa-sharp fa-light fa-ban-smoking',
    BARCODE = 'fa-sharp fa-light fa-barcode',
    BARCODE_READ = 'fa-sharp fa-light fa-barcode-read',
    BARCODE_SCAN = 'fa-sharp fa-light fa-barcode-scan',
    BARS = 'fa-sharp fa-light fa-bars',
    BARS_FILTER = 'fa-sharp fa-light fa-bars-filter',
    BARS_PROGRESS = 'fa-sharp fa-light fa-bars-progress',
    BARS_SORT = 'fa-sharp fa-light fa-bars-sort',
    BARS_STAGGERED = 'fa-sharp fa-light fa-bars-staggered',
    BASEBALL = 'fa-sharp fa-light fa-baseball',
    BASEBALL_BAT_BALL = 'fa-sharp fa-light fa-baseball-bat-ball',
    BASKETBALL = 'fa-sharp fa-light fa-basketball',
    BASKETBALL_HOOP = 'fa-sharp fa-light fa-basketball-hoop',
    BASKET_SHOPPING = 'fa-sharp fa-light fa-basket-shopping',
    BASKET_SHOPPING_MINUS = 'fa-sharp fa-light fa-basket-shopping-minus',
    BASKET_SHOPPING_PLUS = 'fa-sharp fa-light fa-basket-shopping-plus',
    BASKET_SHOPPING_SIMPLE = 'fa-sharp fa-light fa-basket-shopping-simple',
    BAT = 'fa-sharp fa-light fa-bat',
    BATH = 'fa-sharp fa-light fa-bath',
    BATTERY_BOLT = 'fa-sharp fa-light fa-battery-bolt',
    BATTERY_EMPTY = 'fa-sharp fa-light fa-battery-empty',
    BATTERY_EXCLAMATION = 'fa-sharp fa-light fa-battery-exclamation',
    BATTERY_FULL = 'fa-sharp fa-light fa-battery-full',
    BATTERY_HALF = 'fa-sharp fa-light fa-battery-half',
    BATTERY_LOW = 'fa-sharp fa-light fa-battery-low',
    BATTERY_QUARTER = 'fa-sharp fa-light fa-battery-quarter',
    BATTERY_SLASH = 'fa-sharp fa-light fa-battery-slash',
    BATTERY_THREE_QUARTERS = 'fa-sharp fa-light fa-battery-three-quarters',
    BED = 'fa-sharp fa-light fa-bed',
    BED_BUNK = 'fa-sharp fa-light fa-bed-bunk',
    BED_EMPTY = 'fa-sharp fa-light fa-bed-empty',
    BED_FRONT = 'fa-sharp fa-light fa-bed-front',
    BED_PULSE = 'fa-sharp fa-light fa-bed-pulse',
    BEE = 'fa-sharp fa-light fa-bee',
    BEER_MUG = 'fa-sharp fa-light fa-beer-mug',
    BEER_MUG_EMPTY = 'fa-sharp fa-light fa-beer-mug-empty',
    BELL = 'fa-sharp fa-light fa-bell',
    BELLS = 'fa-sharp fa-light fa-bells',
    BELL_CONCIERGE = 'fa-sharp fa-light fa-bell-concierge',
    BELL_EXCLAMATION = 'fa-sharp fa-light fa-bell-exclamation',
    BELL_ON = 'fa-sharp fa-light fa-bell-on',
    BELL_PLUS = 'fa-sharp fa-light fa-bell-plus',
    BELL_RING = 'fa-sharp fa-light fa-bell-ring',
    BELL_SCHOOL = 'fa-sharp fa-light fa-bell-school',
    BELL_SCHOOL_SLASH = 'fa-sharp fa-light fa-bell-school-slash',
    BELL_SLASH = 'fa-sharp fa-light fa-bell-slash',
    BENCH_TREE = 'fa-sharp fa-light fa-bench-tree',
    BEZIER_CURVE = 'fa-sharp fa-light fa-bezier-curve',
    BICYCLE = 'fa-sharp fa-light fa-bicycle',
    BILLBOARD = 'fa-sharp fa-light fa-billboard',
    BINARY = 'fa-sharp fa-light fa-binary',
    BINARY_CIRCLE_CHECK = 'fa-sharp fa-light fa-binary-circle-check',
    BINARY_LOCK = 'fa-sharp fa-light fa-binary-lock',
    BINARY_SLASH = 'fa-sharp fa-light fa-binary-slash',
    BINOCULARS = 'fa-sharp fa-light fa-binoculars',
    BIN_BOTTLES = 'fa-sharp fa-light fa-bin-bottles',
    BIN_BOTTLES_RECYCLE = 'fa-sharp fa-light fa-bin-bottles-recycle',
    BIN_RECYCLE = 'fa-sharp fa-light fa-bin-recycle',
    BIOHAZARD = 'fa-sharp fa-light fa-biohazard',
    BIRD = 'fa-sharp fa-light fa-bird',
    BITCOIN_SIGN = 'fa-sharp fa-light fa-bitcoin-sign',
    BLANKET = 'fa-sharp fa-light fa-blanket',
    BLANKET_FIRE = 'fa-sharp fa-light fa-blanket-fire',
    BLENDER = 'fa-sharp fa-light fa-blender',
    BLENDER_PHONE = 'fa-sharp fa-light fa-blender-phone',
    BLINDS = 'fa-sharp fa-light fa-blinds',
    BLINDS_OPEN = 'fa-sharp fa-light fa-blinds-open',
    BLINDS_RAISED = 'fa-sharp fa-light fa-blinds-raised',
    BLOCK = 'fa-sharp fa-light fa-block',
    BLOCK_BRICK = 'fa-sharp fa-light fa-block-brick',
    BLOCK_BRICK_FIRE = 'fa-sharp fa-light fa-block-brick-fire',
    BLOCK_QUESTION = 'fa-sharp fa-light fa-block-question',
    BLOCK_QUOTE = 'fa-sharp fa-light fa-block-quote',
    BLOG = 'fa-sharp fa-light fa-blog',
    BLUEBERRIES = 'fa-sharp fa-light fa-blueberries',
    BOLD = 'fa-sharp fa-light fa-bold',
    BOLT = 'fa-sharp fa-light fa-bolt',
    BOLT_AUTO = 'fa-sharp fa-light fa-bolt-auto',
    BOLT_LIGHTNING = 'fa-sharp fa-light fa-bolt-lightning',
    BOLT_SLASH = 'fa-sharp fa-light fa-bolt-slash',
    BOMB = 'fa-sharp fa-light fa-bomb',
    BONE = 'fa-sharp fa-light fa-bone',
    BONE_BREAK = 'fa-sharp fa-light fa-bone-break',
    BONG = 'fa-sharp fa-light fa-bong',
    BOOK = 'fa-sharp fa-light fa-book',
    BOOKMARK = 'fa-sharp fa-light fa-bookmark',
    BOOKMARK_SLASH = 'fa-sharp fa-light fa-bookmark-slash',
    BOOKS = 'fa-sharp fa-light fa-books',
    BOOKS_MEDICAL = 'fa-sharp fa-light fa-books-medical',
    BOOK_ARROW_RIGHT = 'fa-sharp fa-light fa-book-arrow-right',
    BOOK_ARROW_UP = 'fa-sharp fa-light fa-book-arrow-up',
    BOOK_ATLAS = 'fa-sharp fa-light fa-book-atlas',
    BOOK_BIBLE = 'fa-sharp fa-light fa-book-bible',
    BOOK_BLANK = 'fa-sharp fa-light fa-book-blank',
    BOOK_BOOKMARK = 'fa-sharp fa-light fa-book-bookmark',
    BOOK_CIRCLE_ARROW_RIGHT = 'fa-sharp fa-light fa-book-circle-arrow-right',
    BOOK_CIRCLE_ARROW_UP = 'fa-sharp fa-light fa-book-circle-arrow-up',
    BOOK_COPY = 'fa-sharp fa-light fa-book-copy',
    BOOK_FONT = 'fa-sharp fa-light fa-book-font',
    BOOK_HEART = 'fa-sharp fa-light fa-book-heart',
    BOOK_JOURNAL_WHILLS = 'fa-sharp fa-light fa-book-journal-whills',
    BOOK_MEDICAL = 'fa-sharp fa-light fa-book-medical',
    BOOK_OPEN = 'fa-sharp fa-light fa-book-open',
    BOOK_OPEN_COVER = 'fa-sharp fa-light fa-book-open-cover',
    BOOK_OPEN_READER = 'fa-sharp fa-light fa-book-open-reader',
    BOOK_QURAN = 'fa-sharp fa-light fa-book-quran',
    BOOK_SECTION = 'fa-sharp fa-light fa-book-section',
    BOOK_SKULL = 'fa-sharp fa-light fa-book-skull',
    BOOK_SPARKLES = 'fa-sharp fa-light fa-book-sparkles',
    BOOK_TANAKH = 'fa-sharp fa-light fa-book-tanakh',
    BOOK_USER = 'fa-sharp fa-light fa-book-user',
    BOOMBOX = 'fa-sharp fa-light fa-boombox',
    BOOT = 'fa-sharp fa-light fa-boot',
    BOOTH_CURTAIN = 'fa-sharp fa-light fa-booth-curtain',
    BOOT_HEELED = 'fa-sharp fa-light fa-boot-heeled',
    BORDER_ALL = 'fa-sharp fa-light fa-border-all',
    BORDER_BOTTOM = 'fa-sharp fa-light fa-border-bottom',
    BORDER_BOTTOM_RIGHT = 'fa-sharp fa-light fa-border-bottom-right',
    BORDER_CENTER_H = 'fa-sharp fa-light fa-border-center-h',
    BORDER_CENTER_V = 'fa-sharp fa-light fa-border-center-v',
    BORDER_INNER = 'fa-sharp fa-light fa-border-inner',
    BORDER_LEFT = 'fa-sharp fa-light fa-border-left',
    BORDER_NONE = 'fa-sharp fa-light fa-border-none',
    BORDER_OUTER = 'fa-sharp fa-light fa-border-outer',
    BORDER_RIGHT = 'fa-sharp fa-light fa-border-right',
    BORDER_TOP = 'fa-sharp fa-light fa-border-top',
    BORDER_TOP_LEFT = 'fa-sharp fa-light fa-border-top-left',
    BORE_HOLE = 'fa-sharp fa-light fa-bore-hole',
    BOTTLE_BABY = 'fa-sharp fa-light fa-bottle-baby',
    BOTTLE_DROPLET = 'fa-sharp fa-light fa-bottle-droplet',
    BOTTLE_WATER = 'fa-sharp fa-light fa-bottle-water',
    BOWLING_BALL = 'fa-sharp fa-light fa-bowling-ball',
    BOWLING_BALL_PIN = 'fa-sharp fa-light fa-bowling-ball-pin',
    BOWLING_PINS = 'fa-sharp fa-light fa-bowling-pins',
    BOWL_CHOPSTICKS = 'fa-sharp fa-light fa-bowl-chopsticks',
    BOWL_CHOPSTICKS_NOODLES = 'fa-sharp fa-light fa-bowl-chopsticks-noodles',
    BOWL_FOOD = 'fa-sharp fa-light fa-bowl-food',
    BOWL_HOT = 'fa-sharp fa-light fa-bowl-hot',
    BOWL_RICE = 'fa-sharp fa-light fa-bowl-rice',
    BOWL_SCOOP = 'fa-sharp fa-light fa-bowl-scoop',
    BOWL_SCOOPS = 'fa-sharp fa-light fa-bowl-scoops',
    BOWL_SOFT_SERVE = 'fa-sharp fa-light fa-bowl-soft-serve',
    BOWL_SPOON = 'fa-sharp fa-light fa-bowl-spoon',
    BOW_ARROW = 'fa-sharp fa-light fa-bow-arrow',
    BOX = 'fa-sharp fa-light fa-box',
    BOXES_PACKING = 'fa-sharp fa-light fa-boxes-packing',
    BOXES_STACKED = 'fa-sharp fa-light fa-boxes-stacked',
    BOXING_GLOVE = 'fa-sharp fa-light fa-boxing-glove',
    BOX_ARCHIVE = 'fa-sharp fa-light fa-box-archive',
    BOX_BALLOT = 'fa-sharp fa-light fa-box-ballot',
    BOX_CHECK = 'fa-sharp fa-light fa-box-check',
    BOX_CIRCLE_CHECK = 'fa-sharp fa-light fa-box-circle-check',
    BOX_DOLLAR = 'fa-sharp fa-light fa-box-dollar',
    BOX_HEART = 'fa-sharp fa-light fa-box-heart',
    BOX_OPEN = 'fa-sharp fa-light fa-box-open',
    BOX_OPEN_FULL = 'fa-sharp fa-light fa-box-open-full',
    BOX_TAPED = 'fa-sharp fa-light fa-box-taped',
    BOX_TISSUE = 'fa-sharp fa-light fa-box-tissue',
    BRACKETS_CURLY = 'fa-sharp fa-light fa-brackets-curly',
    BRACKETS_ROUND = 'fa-sharp fa-light fa-brackets-round',
    BRACKETS_SQUARE = 'fa-sharp fa-light fa-brackets-square',
    BRACKET_CURLY = 'fa-sharp fa-light fa-bracket-curly',
    BRACKET_CURLY_RIGHT = 'fa-sharp fa-light fa-bracket-curly-right',
    BRACKET_ROUND = 'fa-sharp fa-light fa-bracket-round',
    BRACKET_ROUND_RIGHT = 'fa-sharp fa-light fa-bracket-round-right',
    BRACKET_SQUARE = 'fa-sharp fa-light fa-bracket-square',
    BRACKET_SQUARE_RIGHT = 'fa-sharp fa-light fa-bracket-square-right',
    BRAILLE = 'fa-sharp fa-light fa-braille',
    BRAIN = 'fa-sharp fa-light fa-brain',
    BRAIN_ARROW_CURVED_RIGHT = 'fa-sharp fa-light fa-brain-arrow-curved-right',
    BRAIN_CIRCUIT = 'fa-sharp fa-light fa-brain-circuit',
    BRAKE_WARNING = 'fa-sharp fa-light fa-brake-warning',
    BRAZILIAN_REAL_SIGN = 'fa-sharp fa-light fa-brazilian-real-sign',
    BREAD_LOAF = 'fa-sharp fa-light fa-bread-loaf',
    BREAD_SLICE = 'fa-sharp fa-light fa-bread-slice',
    BREAD_SLICE_BUTTER = 'fa-sharp fa-light fa-bread-slice-butter',
    BRIDGE = 'fa-sharp fa-light fa-bridge',
    BRIDGE_CIRCLE_CHECK = 'fa-sharp fa-light fa-bridge-circle-check',
    BRIDGE_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-bridge-circle-exclamation',
    BRIDGE_CIRCLE_XMARK = 'fa-sharp fa-light fa-bridge-circle-xmark',
    BRIDGE_LOCK = 'fa-sharp fa-light fa-bridge-lock',
    BRIDGE_SUSPENSION = 'fa-sharp fa-light fa-bridge-suspension',
    BRIDGE_WATER = 'fa-sharp fa-light fa-bridge-water',
    BRIEFCASE = 'fa-sharp fa-light fa-briefcase',
    BRIEFCASE_ARROW_RIGHT = 'fa-sharp fa-light fa-briefcase-arrow-right',
    BRIEFCASE_BLANK = 'fa-sharp fa-light fa-briefcase-blank',
    BRIEFCASE_MEDICAL = 'fa-sharp fa-light fa-briefcase-medical',
    BRIGHTNESS = 'fa-sharp fa-light fa-brightness',
    BRIGHTNESS_LOW = 'fa-sharp fa-light fa-brightness-low',
    BRING_FORWARD = 'fa-sharp fa-light fa-bring-forward',
    BRING_FRONT = 'fa-sharp fa-light fa-bring-front',
    BROCCOLI = 'fa-sharp fa-light fa-broccoli',
    BROOM = 'fa-sharp fa-light fa-broom',
    BROOM_BALL = 'fa-sharp fa-light fa-broom-ball',
    BROOM_WIDE = 'fa-sharp fa-light fa-broom-wide',
    BROWSER = 'fa-sharp fa-light fa-browser',
    BROWSERS = 'fa-sharp fa-light fa-browsers',
    BRUSH = 'fa-sharp fa-light fa-brush',
    BUCKET = 'fa-sharp fa-light fa-bucket',
    BUG = 'fa-sharp fa-light fa-bug',
    BUGS = 'fa-sharp fa-light fa-bugs',
    BUG_SLASH = 'fa-sharp fa-light fa-bug-slash',
    BUILDING = 'fa-sharp fa-light fa-building',
    BUILDINGS = 'fa-sharp fa-light fa-buildings',
    BUILDING_CIRCLE_ARROW_RIGHT = 'fa-sharp fa-light fa-building-circle-arrow-right',
    BUILDING_CIRCLE_CHECK = 'fa-sharp fa-light fa-building-circle-check',
    BUILDING_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-building-circle-exclamation',
    BUILDING_CIRCLE_XMARK = 'fa-sharp fa-light fa-building-circle-xmark',
    BUILDING_COLUMNS = 'fa-sharp fa-light fa-building-columns',
    BUILDING_FLAG = 'fa-sharp fa-light fa-building-flag',
    BUILDING_LOCK = 'fa-sharp fa-light fa-building-lock',
    BUILDING_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-building-magnifying-glass',
    BUILDING_MEMO = 'fa-sharp fa-light fa-building-memo',
    BUILDING_NGO = 'fa-sharp fa-light fa-building-ngo',
    BUILDING_SHIELD = 'fa-sharp fa-light fa-building-shield',
    BUILDING_UN = 'fa-sharp fa-light fa-building-un',
    BUILDING_USER = 'fa-sharp fa-light fa-building-user',
    BUILDING_WHEAT = 'fa-sharp fa-light fa-building-wheat',
    BULLDOZER = 'fa-sharp fa-light fa-bulldozer',
    BULLHORN = 'fa-sharp fa-light fa-bullhorn',
    BULLSEYE = 'fa-sharp fa-light fa-bullseye',
    BULLSEYE_ARROW = 'fa-sharp fa-light fa-bullseye-arrow',
    BULLSEYE_POINTER = 'fa-sharp fa-light fa-bullseye-pointer',
    BUOY = 'fa-sharp fa-light fa-buoy',
    BUOY_MOORING = 'fa-sharp fa-light fa-buoy-mooring',
    BURGER = 'fa-sharp fa-light fa-burger',
    BURGER_CHEESE = 'fa-sharp fa-light fa-burger-cheese',
    BURGER_FRIES = 'fa-sharp fa-light fa-burger-fries',
    BURGER_GLASS = 'fa-sharp fa-light fa-burger-glass',
    BURGER_LETTUCE = 'fa-sharp fa-light fa-burger-lettuce',
    BURGER_SODA = 'fa-sharp fa-light fa-burger-soda',
    BURRITO = 'fa-sharp fa-light fa-burrito',
    BURST = 'fa-sharp fa-light fa-burst',
    BUS = 'fa-sharp fa-light fa-bus',
    BUSINESS_TIME = 'fa-sharp fa-light fa-business-time',
    BUS_SCHOOL = 'fa-sharp fa-light fa-bus-school',
    BUS_SIMPLE = 'fa-sharp fa-light fa-bus-simple',
    BUTTER = 'fa-sharp fa-light fa-butter',
    C = 'fa-sharp fa-light fa-c',
    CABIN = 'fa-sharp fa-light fa-cabin',
    CABINET_FILING = 'fa-sharp fa-light fa-cabinet-filing',
    CABLE_CAR = 'fa-sharp fa-light fa-cable-car',
    CACTUS = 'fa-sharp fa-light fa-cactus',
    CADUCEUS = 'fa-sharp fa-light fa-caduceus',
    CAKE_CANDLES = 'fa-sharp fa-light fa-cake-candles',
    CAKE_SLICE = 'fa-sharp fa-light fa-cake-slice',
    CALCULATOR = 'fa-sharp fa-light fa-calculator',
    CALCULATOR_SIMPLE = 'fa-sharp fa-light fa-calculator-simple',
    CALENDAR = 'fa-sharp fa-light fa-calendar',
    CALENDARS = 'fa-sharp fa-light fa-calendars',
    CALENDAR_ARROW_DOWN = 'fa-sharp fa-light fa-calendar-arrow-down',
    CALENDAR_ARROW_UP = 'fa-sharp fa-light fa-calendar-arrow-up',
    CALENDAR_CHECK = 'fa-sharp fa-light fa-calendar-check',
    CALENDAR_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-calendar-circle-exclamation',
    CALENDAR_CIRCLE_MINUS = 'fa-sharp fa-light fa-calendar-circle-minus',
    CALENDAR_CIRCLE_PLUS = 'fa-sharp fa-light fa-calendar-circle-plus',
    CALENDAR_CIRCLE_USER = 'fa-sharp fa-light fa-calendar-circle-user',
    CALENDAR_CLOCK = 'fa-sharp fa-light fa-calendar-clock',
    CALENDAR_DAY = 'fa-sharp fa-light fa-calendar-day',
    CALENDAR_DAYS = 'fa-sharp fa-light fa-calendar-days',
    CALENDAR_EXCLAMATION = 'fa-sharp fa-light fa-calendar-exclamation',
    CALENDAR_HEART = 'fa-sharp fa-light fa-calendar-heart',
    CALENDAR_IMAGE = 'fa-sharp fa-light fa-calendar-image',
    CALENDAR_LINES = 'fa-sharp fa-light fa-calendar-lines',
    CALENDAR_LINES_PEN = 'fa-sharp fa-light fa-calendar-lines-pen',
    CALENDAR_MINUS = 'fa-sharp fa-light fa-calendar-minus',
    CALENDAR_PEN = 'fa-sharp fa-light fa-calendar-pen',
    CALENDAR_PLUS = 'fa-sharp fa-light fa-calendar-plus',
    CALENDAR_RANGE = 'fa-sharp fa-light fa-calendar-range',
    CALENDAR_STAR = 'fa-sharp fa-light fa-calendar-star',
    CALENDAR_USERS = 'fa-sharp fa-light fa-calendar-users',
    CALENDAR_WEEK = 'fa-sharp fa-light fa-calendar-week',
    CALENDAR_XMARK = 'fa-sharp fa-light fa-calendar-xmark',
    CAMCORDER = 'fa-sharp fa-light fa-camcorder',
    CAMERA = 'fa-sharp fa-light fa-camera',
    CAMERA_CCTV = 'fa-sharp fa-light fa-camera-cctv',
    CAMERA_MOVIE = 'fa-sharp fa-light fa-camera-movie',
    CAMERA_POLAROID = 'fa-sharp fa-light fa-camera-polaroid',
    CAMERA_RETRO = 'fa-sharp fa-light fa-camera-retro',
    CAMERA_ROTATE = 'fa-sharp fa-light fa-camera-rotate',
    CAMERA_SECURITY = 'fa-sharp fa-light fa-camera-security',
    CAMERA_SLASH = 'fa-sharp fa-light fa-camera-slash',
    CAMERA_VIEWFINDER = 'fa-sharp fa-light fa-camera-viewfinder',
    CAMERA_WEB = 'fa-sharp fa-light fa-camera-web',
    CAMERA_WEB_SLASH = 'fa-sharp fa-light fa-camera-web-slash',
    CAMPFIRE = 'fa-sharp fa-light fa-campfire',
    CAMPGROUND = 'fa-sharp fa-light fa-campground',
    CANDLE_HOLDER = 'fa-sharp fa-light fa-candle-holder',
    CANDY = 'fa-sharp fa-light fa-candy',
    CANDY_BAR = 'fa-sharp fa-light fa-candy-bar',
    CANDY_CANE = 'fa-sharp fa-light fa-candy-cane',
    CANDY_CORN = 'fa-sharp fa-light fa-candy-corn',
    CANNABIS = 'fa-sharp fa-light fa-cannabis',
    CANNON = 'fa-sharp fa-light fa-cannon',
    CAN_FOOD = 'fa-sharp fa-light fa-can-food',
    CAPSULES = 'fa-sharp fa-light fa-capsules',
    CAR = 'fa-sharp fa-light fa-car',
    CARAVAN = 'fa-sharp fa-light fa-caravan',
    CARAVAN_SIMPLE = 'fa-sharp fa-light fa-caravan-simple',
    CARDS = 'fa-sharp fa-light fa-cards',
    CARDS_BLANK = 'fa-sharp fa-light fa-cards-blank',
    CARD_CLUB = 'fa-sharp fa-light fa-card-club',
    CARD_DIAMOND = 'fa-sharp fa-light fa-card-diamond',
    CARD_HEART = 'fa-sharp fa-light fa-card-heart',
    CARD_SPADE = 'fa-sharp fa-light fa-card-spade',
    CARET_DOWN = 'fa-sharp fa-light fa-caret-down',
    CARET_LEFT = 'fa-sharp fa-light fa-caret-left',
    CARET_RIGHT = 'fa-sharp fa-light fa-caret-right',
    CARET_UP = 'fa-sharp fa-light fa-caret-up',
    CARROT = 'fa-sharp fa-light fa-carrot',
    CARS = 'fa-sharp fa-light fa-cars',
    CART_ARROW_DOWN = 'fa-sharp fa-light fa-cart-arrow-down',
    CART_ARROW_UP = 'fa-sharp fa-light fa-cart-arrow-up',
    CART_CIRCLE_ARROW_DOWN = 'fa-sharp fa-light fa-cart-circle-arrow-down',
    CART_CIRCLE_ARROW_UP = 'fa-sharp fa-light fa-cart-circle-arrow-up',
    CART_CIRCLE_CHECK = 'fa-sharp fa-light fa-cart-circle-check',
    CART_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-cart-circle-exclamation',
    CART_CIRCLE_PLUS = 'fa-sharp fa-light fa-cart-circle-plus',
    CART_CIRCLE_XMARK = 'fa-sharp fa-light fa-cart-circle-xmark',
    CART_FLATBED = 'fa-sharp fa-light fa-cart-flatbed',
    CART_FLATBED_BOXES = 'fa-sharp fa-light fa-cart-flatbed-boxes',
    CART_FLATBED_EMPTY = 'fa-sharp fa-light fa-cart-flatbed-empty',
    CART_FLATBED_SUITCASE = 'fa-sharp fa-light fa-cart-flatbed-suitcase',
    CART_MINUS = 'fa-sharp fa-light fa-cart-minus',
    CART_PLUS = 'fa-sharp fa-light fa-cart-plus',
    CART_SHOPPING = 'fa-sharp fa-light fa-cart-shopping',
    CART_SHOPPING_FAST = 'fa-sharp fa-light fa-cart-shopping-fast',
    CART_XMARK = 'fa-sharp fa-light fa-cart-xmark',
    CAR_BATTERY = 'fa-sharp fa-light fa-car-battery',
    CAR_BOLT = 'fa-sharp fa-light fa-car-bolt',
    CAR_BUILDING = 'fa-sharp fa-light fa-car-building',
    CAR_BUMP = 'fa-sharp fa-light fa-car-bump',
    CAR_BURST = 'fa-sharp fa-light fa-car-burst',
    CAR_BUS = 'fa-sharp fa-light fa-car-bus',
    CAR_CIRCLE_BOLT = 'fa-sharp fa-light fa-car-circle-bolt',
    CAR_GARAGE = 'fa-sharp fa-light fa-car-garage',
    CAR_MIRRORS = 'fa-sharp fa-light fa-car-mirrors',
    CAR_ON = 'fa-sharp fa-light fa-car-on',
    CAR_REAR = 'fa-sharp fa-light fa-car-rear',
    CAR_SIDE = 'fa-sharp fa-light fa-car-side',
    CAR_SIDE_BOLT = 'fa-sharp fa-light fa-car-side-bolt',
    CAR_TILT = 'fa-sharp fa-light fa-car-tilt',
    CAR_TUNNEL = 'fa-sharp fa-light fa-car-tunnel',
    CAR_WASH = 'fa-sharp fa-light fa-car-wash',
    CAR_WRENCH = 'fa-sharp fa-light fa-car-wrench',
    CASH_REGISTER = 'fa-sharp fa-light fa-cash-register',
    CASSETTE_BETAMAX = 'fa-sharp fa-light fa-cassette-betamax',
    CASSETTE_TAPE = 'fa-sharp fa-light fa-cassette-tape',
    CASSETTE_VHS = 'fa-sharp fa-light fa-cassette-vhs',
    CASTLE = 'fa-sharp fa-light fa-castle',
    CAT = 'fa-sharp fa-light fa-cat',
    CAT_SPACE = 'fa-sharp fa-light fa-cat-space',
    CAULDRON = 'fa-sharp fa-light fa-cauldron',
    CEDI_SIGN = 'fa-sharp fa-light fa-cedi-sign',
    CENT_SIGN = 'fa-sharp fa-light fa-cent-sign',
    CERTIFICATE = 'fa-sharp fa-light fa-certificate',
    CHAIR = 'fa-sharp fa-light fa-chair',
    CHAIR_OFFICE = 'fa-sharp fa-light fa-chair-office',
    CHALKBOARD = 'fa-sharp fa-light fa-chalkboard',
    CHALKBOARD_USER = 'fa-sharp fa-light fa-chalkboard-user',
    CHAMPAGNE_GLASS = 'fa-sharp fa-light fa-champagne-glass',
    CHAMPAGNE_GLASSES = 'fa-sharp fa-light fa-champagne-glasses',
    CHARGING_STATION = 'fa-sharp fa-light fa-charging-station',
    CHART_AREA = 'fa-sharp fa-light fa-chart-area',
    CHART_BAR = 'fa-sharp fa-light fa-chart-bar',
    CHART_BULLET = 'fa-sharp fa-light fa-chart-bullet',
    CHART_CANDLESTICK = 'fa-sharp fa-light fa-chart-candlestick',
    CHART_COLUMN = 'fa-sharp fa-light fa-chart-column',
    CHART_GANTT = 'fa-sharp fa-light fa-chart-gantt',
    CHART_KANBAN = 'fa-sharp fa-light fa-chart-kanban',
    CHART_LINE = 'fa-sharp fa-light fa-chart-line',
    CHART_LINE_DOWN = 'fa-sharp fa-light fa-chart-line-down',
    CHART_LINE_UP = 'fa-sharp fa-light fa-chart-line-up',
    CHART_LINE_UP_DOWN = 'fa-sharp fa-light fa-chart-line-up-down',
    CHART_MIXED = 'fa-sharp fa-light fa-chart-mixed',
    CHART_MIXED_UP_CIRCLE_CURRENCY = 'fa-sharp fa-light fa-chart-mixed-up-circle-currency',
    CHART_MIXED_UP_CIRCLE_DOLLAR = 'fa-sharp fa-light fa-chart-mixed-up-circle-dollar',
    CHART_NETWORK = 'fa-sharp fa-light fa-chart-network',
    CHART_PIE = 'fa-sharp fa-light fa-chart-pie',
    CHART_PIE_SIMPLE = 'fa-sharp fa-light fa-chart-pie-simple',
    CHART_PIE_SIMPLE_CIRCLE_CURRENCY = 'fa-sharp fa-light fa-chart-pie-simple-circle-currency',
    CHART_PIE_SIMPLE_CIRCLE_DOLLAR = 'fa-sharp fa-light fa-chart-pie-simple-circle-dollar',
    CHART_PYRAMID = 'fa-sharp fa-light fa-chart-pyramid',
    CHART_RADAR = 'fa-sharp fa-light fa-chart-radar',
    CHART_SCATTER = 'fa-sharp fa-light fa-chart-scatter',
    CHART_SCATTER_3D = 'fa-sharp fa-light fa-chart-scatter-3d',
    CHART_SCATTER_BUBBLE = 'fa-sharp fa-light fa-chart-scatter-bubble',
    CHART_SIMPLE = 'fa-sharp fa-light fa-chart-simple',
    CHART_SIMPLE_HORIZONTAL = 'fa-sharp fa-light fa-chart-simple-horizontal',
    CHART_TREE_MAP = 'fa-sharp fa-light fa-chart-tree-map',
    CHART_USER = 'fa-sharp fa-light fa-chart-user',
    CHART_WATERFALL = 'fa-sharp fa-light fa-chart-waterfall',
    CHECK = 'fa-sharp fa-light fa-check',
    CHECK_DOUBLE = 'fa-sharp fa-light fa-check-double',
    CHECK_TO_SLOT = 'fa-sharp fa-light fa-check-to-slot',
    CHEESE = 'fa-sharp fa-light fa-cheese',
    CHEESE_SWISS = 'fa-sharp fa-light fa-cheese-swiss',
    CHERRIES = 'fa-sharp fa-light fa-cherries',
    CHESS = 'fa-sharp fa-light fa-chess',
    CHESS_BISHOP = 'fa-sharp fa-light fa-chess-bishop',
    CHESS_BISHOP_PIECE = 'fa-sharp fa-light fa-chess-bishop-piece',
    CHESS_BOARD = 'fa-sharp fa-light fa-chess-board',
    CHESS_CLOCK = 'fa-sharp fa-light fa-chess-clock',
    CHESS_CLOCK_FLIP = 'fa-sharp fa-light fa-chess-clock-flip',
    CHESS_KING = 'fa-sharp fa-light fa-chess-king',
    CHESS_KING_PIECE = 'fa-sharp fa-light fa-chess-king-piece',
    CHESS_KNIGHT = 'fa-sharp fa-light fa-chess-knight',
    CHESS_KNIGHT_PIECE = 'fa-sharp fa-light fa-chess-knight-piece',
    CHESS_PAWN = 'fa-sharp fa-light fa-chess-pawn',
    CHESS_PAWN_PIECE = 'fa-sharp fa-light fa-chess-pawn-piece',
    CHESS_QUEEN = 'fa-sharp fa-light fa-chess-queen',
    CHESS_QUEEN_PIECE = 'fa-sharp fa-light fa-chess-queen-piece',
    CHESS_ROOK = 'fa-sharp fa-light fa-chess-rook',
    CHESS_ROOK_PIECE = 'fa-sharp fa-light fa-chess-rook-piece',
    CHESTNUT = 'fa-sharp fa-light fa-chestnut',
    CHEVRONS_DOWN = 'fa-sharp fa-light fa-chevrons-down',
    CHEVRONS_LEFT = 'fa-sharp fa-light fa-chevrons-left',
    CHEVRONS_RIGHT = 'fa-sharp fa-light fa-chevrons-right',
    CHEVRONS_UP = 'fa-sharp fa-light fa-chevrons-up',
    CHEVRON_DOWN = 'fa-sharp fa-light fa-chevron-down',
    CHEVRON_LEFT = 'fa-sharp fa-light fa-chevron-left',
    CHEVRON_RIGHT = 'fa-sharp fa-light fa-chevron-right',
    CHEVRON_UP = 'fa-sharp fa-light fa-chevron-up',
    CHF_SIGN = 'fa-sharp fa-light fa-chf-sign',
    CHILD = 'fa-sharp fa-light fa-child',
    CHILDREN = 'fa-sharp fa-light fa-children',
    CHILD_COMBATANT = 'fa-sharp fa-light fa-child-combatant',
    CHILD_DRESS = 'fa-sharp fa-light fa-child-dress',
    CHILD_REACHING = 'fa-sharp fa-light fa-child-reaching',
    CHIMNEY = 'fa-sharp fa-light fa-chimney',
    CHOPSTICKS = 'fa-sharp fa-light fa-chopsticks',
    CHURCH = 'fa-sharp fa-light fa-church',
    CIRCLE = 'fa-sharp fa-light fa-circle',
    CIRCLES_OVERLAP = 'fa-sharp fa-light fa-circles-overlap',
    CIRCLE_0 = 'fa-sharp fa-light fa-circle-0',
    CIRCLE_1 = 'fa-sharp fa-light fa-circle-1',
    CIRCLE_2 = 'fa-sharp fa-light fa-circle-2',
    CIRCLE_3 = 'fa-sharp fa-light fa-circle-3',
    CIRCLE_4 = 'fa-sharp fa-light fa-circle-4',
    CIRCLE_5 = 'fa-sharp fa-light fa-circle-5',
    CIRCLE_6 = 'fa-sharp fa-light fa-circle-6',
    CIRCLE_7 = 'fa-sharp fa-light fa-circle-7',
    CIRCLE_8 = 'fa-sharp fa-light fa-circle-8',
    CIRCLE_9 = 'fa-sharp fa-light fa-circle-9',
    CIRCLE_A = 'fa-sharp fa-light fa-circle-a',
    CIRCLE_AMPERSAND = 'fa-sharp fa-light fa-circle-ampersand',
    CIRCLE_ARROW_DOWN = 'fa-sharp fa-light fa-circle-arrow-down',
    CIRCLE_ARROW_DOWN_LEFT = 'fa-sharp fa-light fa-circle-arrow-down-left',
    CIRCLE_ARROW_DOWN_RIGHT = 'fa-sharp fa-light fa-circle-arrow-down-right',
    CIRCLE_ARROW_LEFT = 'fa-sharp fa-light fa-circle-arrow-left',
    CIRCLE_ARROW_RIGHT = 'fa-sharp fa-light fa-circle-arrow-right',
    CIRCLE_ARROW_UP = 'fa-sharp fa-light fa-circle-arrow-up',
    CIRCLE_ARROW_UP_LEFT = 'fa-sharp fa-light fa-circle-arrow-up-left',
    CIRCLE_ARROW_UP_RIGHT = 'fa-sharp fa-light fa-circle-arrow-up-right',
    CIRCLE_B = 'fa-sharp fa-light fa-circle-b',
    CIRCLE_BOLT = 'fa-sharp fa-light fa-circle-bolt',
    CIRCLE_BOOKMARK = 'fa-sharp fa-light fa-circle-bookmark',
    CIRCLE_BOOK_OPEN = 'fa-sharp fa-light fa-circle-book-open',
    CIRCLE_C = 'fa-sharp fa-light fa-circle-c',
    CIRCLE_CALENDAR = 'fa-sharp fa-light fa-circle-calendar',
    CIRCLE_CAMERA = 'fa-sharp fa-light fa-circle-camera',
    CIRCLE_CARET_DOWN = 'fa-sharp fa-light fa-circle-caret-down',
    CIRCLE_CARET_LEFT = 'fa-sharp fa-light fa-circle-caret-left',
    CIRCLE_CARET_RIGHT = 'fa-sharp fa-light fa-circle-caret-right',
    CIRCLE_CARET_UP = 'fa-sharp fa-light fa-circle-caret-up',
    CIRCLE_CHECK = 'fa-sharp fa-light fa-circle-check',
    CIRCLE_CHEVRON_DOWN = 'fa-sharp fa-light fa-circle-chevron-down',
    CIRCLE_CHEVRON_LEFT = 'fa-sharp fa-light fa-circle-chevron-left',
    CIRCLE_CHEVRON_RIGHT = 'fa-sharp fa-light fa-circle-chevron-right',
    CIRCLE_CHEVRON_UP = 'fa-sharp fa-light fa-circle-chevron-up',
    CIRCLE_D = 'fa-sharp fa-light fa-circle-d',
    CIRCLE_DASHED = 'fa-sharp fa-light fa-circle-dashed',
    CIRCLE_DIVIDE = 'fa-sharp fa-light fa-circle-divide',
    CIRCLE_DOLLAR = 'fa-sharp fa-light fa-circle-dollar',
    CIRCLE_DOLLAR_TO_SLOT = 'fa-sharp fa-light fa-circle-dollar-to-slot',
    CIRCLE_DOT = 'fa-sharp fa-light fa-circle-dot',
    CIRCLE_DOWN = 'fa-sharp fa-light fa-circle-down',
    CIRCLE_DOWN_LEFT = 'fa-sharp fa-light fa-circle-down-left',
    CIRCLE_DOWN_RIGHT = 'fa-sharp fa-light fa-circle-down-right',
    CIRCLE_E = 'fa-sharp fa-light fa-circle-e',
    CIRCLE_ELLIPSIS = 'fa-sharp fa-light fa-circle-ellipsis',
    CIRCLE_ELLIPSIS_VERTICAL = 'fa-sharp fa-light fa-circle-ellipsis-vertical',
    CIRCLE_ENVELOPE = 'fa-sharp fa-light fa-circle-envelope',
    CIRCLE_EURO = 'fa-sharp fa-light fa-circle-euro',
    CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-circle-exclamation',
    CIRCLE_EXCLAMATION_CHECK = 'fa-sharp fa-light fa-circle-exclamation-check',
    CIRCLE_F = 'fa-sharp fa-light fa-circle-f',
    CIRCLE_G = 'fa-sharp fa-light fa-circle-g',
    CIRCLE_GF = 'fa-sharp fa-light fa-circle-gf',
    CIRCLE_H = 'fa-sharp fa-light fa-circle-h',
    CIRCLE_HALF = 'fa-sharp fa-light fa-circle-half',
    CIRCLE_HALF_STROKE = 'fa-sharp fa-light fa-circle-half-stroke',
    CIRCLE_HEART = 'fa-sharp fa-light fa-circle-heart',
    CIRCLE_I = 'fa-sharp fa-light fa-circle-i',
    CIRCLE_INFO = 'fa-sharp fa-light fa-circle-info',
    CIRCLE_J = 'fa-sharp fa-light fa-circle-j',
    CIRCLE_K = 'fa-sharp fa-light fa-circle-k',
    CIRCLE_L = 'fa-sharp fa-light fa-circle-l',
    CIRCLE_LEFT = 'fa-sharp fa-light fa-circle-left',
    CIRCLE_LOCATION_ARROW = 'fa-sharp fa-light fa-circle-location-arrow',
    CIRCLE_M = 'fa-sharp fa-light fa-circle-m',
    CIRCLE_MICROPHONE = 'fa-sharp fa-light fa-circle-microphone',
    CIRCLE_MICROPHONE_LINES = 'fa-sharp fa-light fa-circle-microphone-lines',
    CIRCLE_MINUS = 'fa-sharp fa-light fa-circle-minus',
    CIRCLE_N = 'fa-sharp fa-light fa-circle-n',
    CIRCLE_NODES = 'fa-sharp fa-light fa-circle-nodes',
    CIRCLE_NOTCH = 'fa-sharp fa-light fa-circle-notch',
    CIRCLE_O = 'fa-sharp fa-light fa-circle-o',
    CIRCLE_P = 'fa-sharp fa-light fa-circle-p',
    CIRCLE_PARKING = 'fa-sharp fa-light fa-circle-parking',
    CIRCLE_PAUSE = 'fa-sharp fa-light fa-circle-pause',
    CIRCLE_PHONE = 'fa-sharp fa-light fa-circle-phone',
    CIRCLE_PHONE_FLIP = 'fa-sharp fa-light fa-circle-phone-flip',
    CIRCLE_PHONE_HANGUP = 'fa-sharp fa-light fa-circle-phone-hangup',
    CIRCLE_PLAY = 'fa-sharp fa-light fa-circle-play',
    CIRCLE_PLUS = 'fa-sharp fa-light fa-circle-plus',
    CIRCLE_Q = 'fa-sharp fa-light fa-circle-q',
    CIRCLE_QUARTER = 'fa-sharp fa-light fa-circle-quarter',
    CIRCLE_QUARTERS = 'fa-sharp fa-light fa-circle-quarters',
    CIRCLE_QUARTER_STROKE = 'fa-sharp fa-light fa-circle-quarter-stroke',
    CIRCLE_QUESTION = 'fa-sharp fa-light fa-circle-question',
    CIRCLE_R = 'fa-sharp fa-light fa-circle-r',
    CIRCLE_RADIATION = 'fa-sharp fa-light fa-circle-radiation',
    CIRCLE_RIGHT = 'fa-sharp fa-light fa-circle-right',
    CIRCLE_S = 'fa-sharp fa-light fa-circle-s',
    CIRCLE_SMALL = 'fa-sharp fa-light fa-circle-small',
    CIRCLE_SORT = 'fa-sharp fa-light fa-circle-sort',
    CIRCLE_SORT_DOWN = 'fa-sharp fa-light fa-circle-sort-down',
    CIRCLE_SORT_UP = 'fa-sharp fa-light fa-circle-sort-up',
    CIRCLE_STAR = 'fa-sharp fa-light fa-circle-star',
    CIRCLE_STERLING = 'fa-sharp fa-light fa-circle-sterling',
    CIRCLE_STOP = 'fa-sharp fa-light fa-circle-stop',
    CIRCLE_T = 'fa-sharp fa-light fa-circle-t',
    CIRCLE_THREE_QUARTERS = 'fa-sharp fa-light fa-circle-three-quarters',
    CIRCLE_THREE_QUARTERS_STROKE = 'fa-sharp fa-light fa-circle-three-quarters-stroke',
    CIRCLE_TRASH = 'fa-sharp fa-light fa-circle-trash',
    CIRCLE_U = 'fa-sharp fa-light fa-circle-u',
    CIRCLE_UP = 'fa-sharp fa-light fa-circle-up',
    CIRCLE_UP_LEFT = 'fa-sharp fa-light fa-circle-up-left',
    CIRCLE_UP_RIGHT = 'fa-sharp fa-light fa-circle-up-right',
    CIRCLE_USER = 'fa-sharp fa-light fa-circle-user',
    CIRCLE_V = 'fa-sharp fa-light fa-circle-v',
    CIRCLE_VIDEO = 'fa-sharp fa-light fa-circle-video',
    CIRCLE_W = 'fa-sharp fa-light fa-circle-w',
    CIRCLE_WAVEFORM_LINES = 'fa-sharp fa-light fa-circle-waveform-lines',
    CIRCLE_WIFI = 'fa-sharp fa-light fa-circle-wifi',
    CIRCLE_WIFI_CIRCLE_WIFI = 'fa-sharp fa-light fa-circle-wifi-circle-wifi',
    CIRCLE_X = 'fa-sharp fa-light fa-circle-x',
    CIRCLE_XMARK = 'fa-sharp fa-light fa-circle-xmark',
    CIRCLE_Y = 'fa-sharp fa-light fa-circle-y',
    CIRCLE_YEN = 'fa-sharp fa-light fa-circle-yen',
    CIRCLE_Z = 'fa-sharp fa-light fa-circle-z',
    CITRUS = 'fa-sharp fa-light fa-citrus',
    CITRUS_SLICE = 'fa-sharp fa-light fa-citrus-slice',
    CITY = 'fa-sharp fa-light fa-city',
    CLAPPERBOARD = 'fa-sharp fa-light fa-clapperboard',
    CLAPPERBOARD_PLAY = 'fa-sharp fa-light fa-clapperboard-play',
    CLARINET = 'fa-sharp fa-light fa-clarinet',
    CLAW_MARKS = 'fa-sharp fa-light fa-claw-marks',
    CLIPBOARD = 'fa-sharp fa-light fa-clipboard',
    CLIPBOARD_CHECK = 'fa-sharp fa-light fa-clipboard-check',
    CLIPBOARD_LIST = 'fa-sharp fa-light fa-clipboard-list',
    CLIPBOARD_LIST_CHECK = 'fa-sharp fa-light fa-clipboard-list-check',
    CLIPBOARD_MEDICAL = 'fa-sharp fa-light fa-clipboard-medical',
    CLIPBOARD_PRESCRIPTION = 'fa-sharp fa-light fa-clipboard-prescription',
    CLIPBOARD_QUESTION = 'fa-sharp fa-light fa-clipboard-question',
    CLIPBOARD_USER = 'fa-sharp fa-light fa-clipboard-user',
    CLOCK = 'fa-sharp fa-light fa-clock',
    CLOCK_DESK = 'fa-sharp fa-light fa-clock-desk',
    CLOCK_EIGHT = 'fa-sharp fa-light fa-clock-eight',
    CLOCK_EIGHT_THIRTY = 'fa-sharp fa-light fa-clock-eight-thirty',
    CLOCK_ELEVEN = 'fa-sharp fa-light fa-clock-eleven',
    CLOCK_ELEVEN_THIRTY = 'fa-sharp fa-light fa-clock-eleven-thirty',
    CLOCK_FIVE = 'fa-sharp fa-light fa-clock-five',
    CLOCK_FIVE_THIRTY = 'fa-sharp fa-light fa-clock-five-thirty',
    CLOCK_FOUR_THIRTY = 'fa-sharp fa-light fa-clock-four-thirty',
    CLOCK_NINE = 'fa-sharp fa-light fa-clock-nine',
    CLOCK_NINE_THIRTY = 'fa-sharp fa-light fa-clock-nine-thirty',
    CLOCK_ONE = 'fa-sharp fa-light fa-clock-one',
    CLOCK_ONE_THIRTY = 'fa-sharp fa-light fa-clock-one-thirty',
    CLOCK_ROTATE_LEFT = 'fa-sharp fa-light fa-clock-rotate-left',
    CLOCK_SEVEN = 'fa-sharp fa-light fa-clock-seven',
    CLOCK_SEVEN_THIRTY = 'fa-sharp fa-light fa-clock-seven-thirty',
    CLOCK_SIX = 'fa-sharp fa-light fa-clock-six',
    CLOCK_SIX_THIRTY = 'fa-sharp fa-light fa-clock-six-thirty',
    CLOCK_TEN = 'fa-sharp fa-light fa-clock-ten',
    CLOCK_TEN_THIRTY = 'fa-sharp fa-light fa-clock-ten-thirty',
    CLOCK_THREE = 'fa-sharp fa-light fa-clock-three',
    CLOCK_THREE_THIRTY = 'fa-sharp fa-light fa-clock-three-thirty',
    CLOCK_TWELVE = 'fa-sharp fa-light fa-clock-twelve',
    CLOCK_TWELVE_THIRTY = 'fa-sharp fa-light fa-clock-twelve-thirty',
    CLOCK_TWO = 'fa-sharp fa-light fa-clock-two',
    CLOCK_TWO_THIRTY = 'fa-sharp fa-light fa-clock-two-thirty',
    CLONE = 'fa-sharp fa-light fa-clone',
    CLOSED_CAPTIONING = 'fa-sharp fa-light fa-closed-captioning',
    CLOSED_CAPTIONING_SLASH = 'fa-sharp fa-light fa-closed-captioning-slash',
    CLOTHES_HANGER = 'fa-sharp fa-light fa-clothes-hanger',
    CLOUD = 'fa-sharp fa-light fa-cloud',
    CLOUDS = 'fa-sharp fa-light fa-clouds',
    CLOUDS_MOON = 'fa-sharp fa-light fa-clouds-moon',
    CLOUDS_SUN = 'fa-sharp fa-light fa-clouds-sun',
    CLOUD_ARROW_DOWN = 'fa-sharp fa-light fa-cloud-arrow-down',
    CLOUD_ARROW_UP = 'fa-sharp fa-light fa-cloud-arrow-up',
    CLOUD_BINARY = 'fa-sharp fa-light fa-cloud-binary',
    CLOUD_BOLT = 'fa-sharp fa-light fa-cloud-bolt',
    CLOUD_BOLT_MOON = 'fa-sharp fa-light fa-cloud-bolt-moon',
    CLOUD_BOLT_SUN = 'fa-sharp fa-light fa-cloud-bolt-sun',
    CLOUD_CHECK = 'fa-sharp fa-light fa-cloud-check',
    CLOUD_DRIZZLE = 'fa-sharp fa-light fa-cloud-drizzle',
    CLOUD_EXCLAMATION = 'fa-sharp fa-light fa-cloud-exclamation',
    CLOUD_FOG = 'fa-sharp fa-light fa-cloud-fog',
    CLOUD_HAIL = 'fa-sharp fa-light fa-cloud-hail',
    CLOUD_HAIL_MIXED = 'fa-sharp fa-light fa-cloud-hail-mixed',
    CLOUD_MEATBALL = 'fa-sharp fa-light fa-cloud-meatball',
    CLOUD_MINUS = 'fa-sharp fa-light fa-cloud-minus',
    CLOUD_MOON = 'fa-sharp fa-light fa-cloud-moon',
    CLOUD_MOON_RAIN = 'fa-sharp fa-light fa-cloud-moon-rain',
    CLOUD_MUSIC = 'fa-sharp fa-light fa-cloud-music',
    CLOUD_PLUS = 'fa-sharp fa-light fa-cloud-plus',
    CLOUD_QUESTION = 'fa-sharp fa-light fa-cloud-question',
    CLOUD_RAIN = 'fa-sharp fa-light fa-cloud-rain',
    CLOUD_RAINBOW = 'fa-sharp fa-light fa-cloud-rainbow',
    CLOUD_SHOWERS = 'fa-sharp fa-light fa-cloud-showers',
    CLOUD_SHOWERS_HEAVY = 'fa-sharp fa-light fa-cloud-showers-heavy',
    CLOUD_SHOWERS_WATER = 'fa-sharp fa-light fa-cloud-showers-water',
    CLOUD_SLASH = 'fa-sharp fa-light fa-cloud-slash',
    CLOUD_SLEET = 'fa-sharp fa-light fa-cloud-sleet',
    CLOUD_SNOW = 'fa-sharp fa-light fa-cloud-snow',
    CLOUD_SUN = 'fa-sharp fa-light fa-cloud-sun',
    CLOUD_SUN_RAIN = 'fa-sharp fa-light fa-cloud-sun-rain',
    CLOUD_WORD = 'fa-sharp fa-light fa-cloud-word',
    CLOUD_XMARK = 'fa-sharp fa-light fa-cloud-xmark',
    CLOVER = 'fa-sharp fa-light fa-clover',
    CLUB = 'fa-sharp fa-light fa-club',
    COCONUT = 'fa-sharp fa-light fa-coconut',
    CODE = 'fa-sharp fa-light fa-code',
    CODE_BRANCH = 'fa-sharp fa-light fa-code-branch',
    CODE_COMMIT = 'fa-sharp fa-light fa-code-commit',
    CODE_COMPARE = 'fa-sharp fa-light fa-code-compare',
    CODE_FORK = 'fa-sharp fa-light fa-code-fork',
    CODE_MERGE = 'fa-sharp fa-light fa-code-merge',
    CODE_PULL_REQUEST = 'fa-sharp fa-light fa-code-pull-request',
    CODE_PULL_REQUEST_CLOSED = 'fa-sharp fa-light fa-code-pull-request-closed',
    CODE_PULL_REQUEST_DRAFT = 'fa-sharp fa-light fa-code-pull-request-draft',
    CODE_SIMPLE = 'fa-sharp fa-light fa-code-simple',
    COFFEE_BEAN = 'fa-sharp fa-light fa-coffee-bean',
    COFFEE_BEANS = 'fa-sharp fa-light fa-coffee-beans',
    COFFEE_POT = 'fa-sharp fa-light fa-coffee-pot',
    COFFIN = 'fa-sharp fa-light fa-coffin',
    COFFIN_CROSS = 'fa-sharp fa-light fa-coffin-cross',
    COIN = 'fa-sharp fa-light fa-coin',
    COINS = 'fa-sharp fa-light fa-coins',
    COIN_BLANK = 'fa-sharp fa-light fa-coin-blank',
    COIN_FRONT = 'fa-sharp fa-light fa-coin-front',
    COIN_VERTICAL = 'fa-sharp fa-light fa-coin-vertical',
    COLON = 'fa-sharp fa-light fa-colon',
    COLON_SIGN = 'fa-sharp fa-light fa-colon-sign',
    COLUMNS_3 = 'fa-sharp fa-light fa-columns-3',
    COMET = 'fa-sharp fa-light fa-comet',
    COMMA = 'fa-sharp fa-light fa-comma',
    COMMAND = 'fa-sharp fa-light fa-command',
    COMMENT = 'fa-sharp fa-light fa-comment',
    COMMENTS = 'fa-sharp fa-light fa-comments',
    COMMENTS_DOLLAR = 'fa-sharp fa-light fa-comments-dollar',
    COMMENTS_QUESTION = 'fa-sharp fa-light fa-comments-question',
    COMMENTS_QUESTION_CHECK = 'fa-sharp fa-light fa-comments-question-check',
    COMMENT_ARROW_DOWN = 'fa-sharp fa-light fa-comment-arrow-down',
    COMMENT_ARROW_UP = 'fa-sharp fa-light fa-comment-arrow-up',
    COMMENT_ARROW_UP_RIGHT = 'fa-sharp fa-light fa-comment-arrow-up-right',
    COMMENT_CAPTIONS = 'fa-sharp fa-light fa-comment-captions',
    COMMENT_CHECK = 'fa-sharp fa-light fa-comment-check',
    COMMENT_CODE = 'fa-sharp fa-light fa-comment-code',
    COMMENT_DOLLAR = 'fa-sharp fa-light fa-comment-dollar',
    COMMENT_DOTS = 'fa-sharp fa-light fa-comment-dots',
    COMMENT_EXCLAMATION = 'fa-sharp fa-light fa-comment-exclamation',
    COMMENT_HEART = 'fa-sharp fa-light fa-comment-heart',
    COMMENT_IMAGE = 'fa-sharp fa-light fa-comment-image',
    COMMENT_LINES = 'fa-sharp fa-light fa-comment-lines',
    COMMENT_MEDICAL = 'fa-sharp fa-light fa-comment-medical',
    COMMENT_MIDDLE = 'fa-sharp fa-light fa-comment-middle',
    COMMENT_MIDDLE_TOP = 'fa-sharp fa-light fa-comment-middle-top',
    COMMENT_MINUS = 'fa-sharp fa-light fa-comment-minus',
    COMMENT_MUSIC = 'fa-sharp fa-light fa-comment-music',
    COMMENT_PEN = 'fa-sharp fa-light fa-comment-pen',
    COMMENT_PLUS = 'fa-sharp fa-light fa-comment-plus',
    COMMENT_QUESTION = 'fa-sharp fa-light fa-comment-question',
    COMMENT_QUOTE = 'fa-sharp fa-light fa-comment-quote',
    COMMENT_SLASH = 'fa-sharp fa-light fa-comment-slash',
    COMMENT_SMILE = 'fa-sharp fa-light fa-comment-smile',
    COMMENT_SMS = 'fa-sharp fa-light fa-comment-sms',
    COMMENT_TEXT = 'fa-sharp fa-light fa-comment-text',
    COMMENT_XMARK = 'fa-sharp fa-light fa-comment-xmark',
    COMPACT_DISC = 'fa-sharp fa-light fa-compact-disc',
    COMPASS = 'fa-sharp fa-light fa-compass',
    COMPASS_DRAFTING = 'fa-sharp fa-light fa-compass-drafting',
    COMPASS_SLASH = 'fa-sharp fa-light fa-compass-slash',
    COMPRESS = 'fa-sharp fa-light fa-compress',
    COMPRESS_WIDE = 'fa-sharp fa-light fa-compress-wide',
    COMPUTER = 'fa-sharp fa-light fa-computer',
    COMPUTER_CLASSIC = 'fa-sharp fa-light fa-computer-classic',
    COMPUTER_MOUSE = 'fa-sharp fa-light fa-computer-mouse',
    COMPUTER_MOUSE_SCROLLWHEEL = 'fa-sharp fa-light fa-computer-mouse-scrollwheel',
    COMPUTER_SPEAKER = 'fa-sharp fa-light fa-computer-speaker',
    CONTAINER_STORAGE = 'fa-sharp fa-light fa-container-storage',
    CONVEYOR_BELT = 'fa-sharp fa-light fa-conveyor-belt',
    CONVEYOR_BELT_ARM = 'fa-sharp fa-light fa-conveyor-belt-arm',
    CONVEYOR_BELT_BOXES = 'fa-sharp fa-light fa-conveyor-belt-boxes',
    CONVEYOR_BELT_EMPTY = 'fa-sharp fa-light fa-conveyor-belt-empty',
    COOKIE = 'fa-sharp fa-light fa-cookie',
    COOKIE_BITE = 'fa-sharp fa-light fa-cookie-bite',
    COPY = 'fa-sharp fa-light fa-copy',
    COPYRIGHT = 'fa-sharp fa-light fa-copyright',
    CORN = 'fa-sharp fa-light fa-corn',
    CORNER = 'fa-sharp fa-light fa-corner',
    COUCH = 'fa-sharp fa-light fa-couch',
    COURT_SPORT = 'fa-sharp fa-light fa-court-sport',
    COW = 'fa-sharp fa-light fa-cow',
    COWBELL = 'fa-sharp fa-light fa-cowbell',
    COWBELL_CIRCLE_PLUS = 'fa-sharp fa-light fa-cowbell-circle-plus',
    CRAB = 'fa-sharp fa-light fa-crab',
    CRATE_APPLE = 'fa-sharp fa-light fa-crate-apple',
    CRATE_EMPTY = 'fa-sharp fa-light fa-crate-empty',
    CREDIT_CARD = 'fa-sharp fa-light fa-credit-card',
    CREDIT_CARD_BLANK = 'fa-sharp fa-light fa-credit-card-blank',
    CREDIT_CARD_FRONT = 'fa-sharp fa-light fa-credit-card-front',
    CRICKET_BAT_BALL = 'fa-sharp fa-light fa-cricket-bat-ball',
    CROISSANT = 'fa-sharp fa-light fa-croissant',
    CROP = 'fa-sharp fa-light fa-crop',
    CROP_SIMPLE = 'fa-sharp fa-light fa-crop-simple',
    CROSS = 'fa-sharp fa-light fa-cross',
    CROSSHAIRS = 'fa-sharp fa-light fa-crosshairs',
    CROSSHAIRS_SIMPLE = 'fa-sharp fa-light fa-crosshairs-simple',
    CROW = 'fa-sharp fa-light fa-crow',
    CROWN = 'fa-sharp fa-light fa-crown',
    CRUTCH = 'fa-sharp fa-light fa-crutch',
    CRUTCHES = 'fa-sharp fa-light fa-crutches',
    CRUZEIRO_SIGN = 'fa-sharp fa-light fa-cruzeiro-sign',
    CRYSTAL_BALL = 'fa-sharp fa-light fa-crystal-ball',
    CUBE = 'fa-sharp fa-light fa-cube',
    CUBES = 'fa-sharp fa-light fa-cubes',
    CUBES_STACKED = 'fa-sharp fa-light fa-cubes-stacked',
    CUCUMBER = 'fa-sharp fa-light fa-cucumber',
    CUPCAKE = 'fa-sharp fa-light fa-cupcake',
    CUP_STRAW = 'fa-sharp fa-light fa-cup-straw',
    CUP_STRAW_SWOOSH = 'fa-sharp fa-light fa-cup-straw-swoosh',
    CUP_TOGO = 'fa-sharp fa-light fa-cup-togo',
    CURLING_STONE = 'fa-sharp fa-light fa-curling-stone',
    CUSTARD = 'fa-sharp fa-light fa-custard',
    D = 'fa-sharp fa-light fa-d',
    DAGGER = 'fa-sharp fa-light fa-dagger',
    DASH = 'fa-sharp fa-light fa-dash',
    DATABASE = 'fa-sharp fa-light fa-database',
    DEER = 'fa-sharp fa-light fa-deer',
    DEER_RUDOLPH = 'fa-sharp fa-light fa-deer-rudolph',
    DELETE_LEFT = 'fa-sharp fa-light fa-delete-left',
    DELETE_RIGHT = 'fa-sharp fa-light fa-delete-right',
    DEMOCRAT = 'fa-sharp fa-light fa-democrat',
    DESKTOP = 'fa-sharp fa-light fa-desktop',
    DESKTOP_ARROW_DOWN = 'fa-sharp fa-light fa-desktop-arrow-down',
    DHARMACHAKRA = 'fa-sharp fa-light fa-dharmachakra',
    DIAGRAM_CELLS = 'fa-sharp fa-light fa-diagram-cells',
    DIAGRAM_LEAN_CANVAS = 'fa-sharp fa-light fa-diagram-lean-canvas',
    DIAGRAM_NESTED = 'fa-sharp fa-light fa-diagram-nested',
    DIAGRAM_NEXT = 'fa-sharp fa-light fa-diagram-next',
    DIAGRAM_PREDECESSOR = 'fa-sharp fa-light fa-diagram-predecessor',
    DIAGRAM_PREVIOUS = 'fa-sharp fa-light fa-diagram-previous',
    DIAGRAM_PROJECT = 'fa-sharp fa-light fa-diagram-project',
    DIAGRAM_SANKEY = 'fa-sharp fa-light fa-diagram-sankey',
    DIAGRAM_SUBTASK = 'fa-sharp fa-light fa-diagram-subtask',
    DIAGRAM_SUCCESSOR = 'fa-sharp fa-light fa-diagram-successor',
    DIAGRAM_VENN = 'fa-sharp fa-light fa-diagram-venn',
    DIAL = 'fa-sharp fa-light fa-dial',
    DIAL_HIGH = 'fa-sharp fa-light fa-dial-high',
    DIAL_LOW = 'fa-sharp fa-light fa-dial-low',
    DIAL_MAX = 'fa-sharp fa-light fa-dial-max',
    DIAL_MED = 'fa-sharp fa-light fa-dial-med',
    DIAL_MED_LOW = 'fa-sharp fa-light fa-dial-med-low',
    DIAL_MIN = 'fa-sharp fa-light fa-dial-min',
    DIAL_OFF = 'fa-sharp fa-light fa-dial-off',
    DIAMOND = 'fa-sharp fa-light fa-diamond',
    DIAMONDS_4 = 'fa-sharp fa-light fa-diamonds-4',
    DIAMOND_EXCLAMATION = 'fa-sharp fa-light fa-diamond-exclamation',
    DIAMOND_HALF = 'fa-sharp fa-light fa-diamond-half',
    DIAMOND_HALF_STROKE = 'fa-sharp fa-light fa-diamond-half-stroke',
    DIAMOND_TURN_RIGHT = 'fa-sharp fa-light fa-diamond-turn-right',
    DICE = 'fa-sharp fa-light fa-dice',
    DICE_D10 = 'fa-sharp fa-light fa-dice-d10',
    DICE_D12 = 'fa-sharp fa-light fa-dice-d12',
    DICE_D20 = 'fa-sharp fa-light fa-dice-d20',
    DICE_D4 = 'fa-sharp fa-light fa-dice-d4',
    DICE_D6 = 'fa-sharp fa-light fa-dice-d6',
    DICE_D8 = 'fa-sharp fa-light fa-dice-d8',
    DICE_FIVE = 'fa-sharp fa-light fa-dice-five',
    DICE_FOUR = 'fa-sharp fa-light fa-dice-four',
    DICE_ONE = 'fa-sharp fa-light fa-dice-one',
    DICE_SIX = 'fa-sharp fa-light fa-dice-six',
    DICE_THREE = 'fa-sharp fa-light fa-dice-three',
    DICE_TWO = 'fa-sharp fa-light fa-dice-two',
    DINOSAUR = 'fa-sharp fa-light fa-dinosaur',
    DIPLOMA = 'fa-sharp fa-light fa-diploma',
    DISC_DRIVE = 'fa-sharp fa-light fa-disc-drive',
    DISEASE = 'fa-sharp fa-light fa-disease',
    DISPLAY = 'fa-sharp fa-light fa-display',
    DISPLAY_ARROW_DOWN = 'fa-sharp fa-light fa-display-arrow-down',
    DISPLAY_CHART_UP = 'fa-sharp fa-light fa-display-chart-up',
    DISPLAY_CHART_UP_CIRCLE_CURRENCY = 'fa-sharp fa-light fa-display-chart-up-circle-currency',
    DISPLAY_CHART_UP_CIRCLE_DOLLAR = 'fa-sharp fa-light fa-display-chart-up-circle-dollar',
    DISPLAY_CODE = 'fa-sharp fa-light fa-display-code',
    DISPLAY_MEDICAL = 'fa-sharp fa-light fa-display-medical',
    DISPLAY_SLASH = 'fa-sharp fa-light fa-display-slash',
    DISTRIBUTE_SPACING_HORIZONTAL = 'fa-sharp fa-light fa-distribute-spacing-horizontal',
    DISTRIBUTE_SPACING_VERTICAL = 'fa-sharp fa-light fa-distribute-spacing-vertical',
    DITTO = 'fa-sharp fa-light fa-ditto',
    DIVIDE = 'fa-sharp fa-light fa-divide',
    DNA = 'fa-sharp fa-light fa-dna',
    DOG = 'fa-sharp fa-light fa-dog',
    DOG_LEASHED = 'fa-sharp fa-light fa-dog-leashed',
    DOLLAR_SIGN = 'fa-sharp fa-light fa-dollar-sign',
    DOLLY = 'fa-sharp fa-light fa-dolly',
    DOLLY_EMPTY = 'fa-sharp fa-light fa-dolly-empty',
    DOLPHIN = 'fa-sharp fa-light fa-dolphin',
    DONG_SIGN = 'fa-sharp fa-light fa-dong-sign',
    DONUT = 'fa-sharp fa-light fa-donut',
    DOOR_CLOSED = 'fa-sharp fa-light fa-door-closed',
    DOOR_OPEN = 'fa-sharp fa-light fa-door-open',
    DOVE = 'fa-sharp fa-light fa-dove',
    DOWN = 'fa-sharp fa-light fa-down',
    DOWNLOAD = 'fa-sharp fa-light fa-download',
    DOWN_FROM_BRACKET = 'fa-sharp fa-light fa-down-from-bracket',
    DOWN_FROM_DOTTED_LINE = 'fa-sharp fa-light fa-down-from-dotted-line',
    DOWN_FROM_LINE = 'fa-sharp fa-light fa-down-from-line',
    DOWN_LEFT = 'fa-sharp fa-light fa-down-left',
    DOWN_LEFT_AND_UP_RIGHT_TO_CENTER = 'fa-sharp fa-light fa-down-left-and-up-right-to-center',
    DOWN_LONG = 'fa-sharp fa-light fa-down-long',
    DOWN_RIGHT = 'fa-sharp fa-light fa-down-right',
    DOWN_TO_BRACKET = 'fa-sharp fa-light fa-down-to-bracket',
    DOWN_TO_DOTTED_LINE = 'fa-sharp fa-light fa-down-to-dotted-line',
    DOWN_TO_LINE = 'fa-sharp fa-light fa-down-to-line',
    DO_NOT_ENTER = 'fa-sharp fa-light fa-do-not-enter',
    DRAGON = 'fa-sharp fa-light fa-dragon',
    DRAW_CIRCLE = 'fa-sharp fa-light fa-draw-circle',
    DRAW_POLYGON = 'fa-sharp fa-light fa-draw-polygon',
    DRAW_SQUARE = 'fa-sharp fa-light fa-draw-square',
    DREIDEL = 'fa-sharp fa-light fa-dreidel',
    DRONE = 'fa-sharp fa-light fa-drone',
    DRONE_FRONT = 'fa-sharp fa-light fa-drone-front',
    DROPLET = 'fa-sharp fa-light fa-droplet',
    DROPLET_DEGREE = 'fa-sharp fa-light fa-droplet-degree',
    DROPLET_PERCENT = 'fa-sharp fa-light fa-droplet-percent',
    DROPLET_SLASH = 'fa-sharp fa-light fa-droplet-slash',
    DRUM = 'fa-sharp fa-light fa-drum',
    DRUMSTICK = 'fa-sharp fa-light fa-drumstick',
    DRUMSTICK_BITE = 'fa-sharp fa-light fa-drumstick-bite',
    DRUM_STEELPAN = 'fa-sharp fa-light fa-drum-steelpan',
    DRYER = 'fa-sharp fa-light fa-dryer',
    DRYER_HEAT = 'fa-sharp fa-light fa-dryer-heat',
    DUCK = 'fa-sharp fa-light fa-duck',
    DUMBBELL = 'fa-sharp fa-light fa-dumbbell',
    DUMPSTER = 'fa-sharp fa-light fa-dumpster',
    DUMPSTER_FIRE = 'fa-sharp fa-light fa-dumpster-fire',
    DUNGEON = 'fa-sharp fa-light fa-dungeon',
    E = 'fa-sharp fa-light fa-e',
    EAR = 'fa-sharp fa-light fa-ear',
    EARTH_AFRICA = 'fa-sharp fa-light fa-earth-africa',
    EARTH_AMERICAS = 'fa-sharp fa-light fa-earth-americas',
    EARTH_ASIA = 'fa-sharp fa-light fa-earth-asia',
    EARTH_EUROPE = 'fa-sharp fa-light fa-earth-europe',
    EARTH_OCEANIA = 'fa-sharp fa-light fa-earth-oceania',
    EAR_DEAF = 'fa-sharp fa-light fa-ear-deaf',
    EAR_LISTEN = 'fa-sharp fa-light fa-ear-listen',
    EAR_MUFFS = 'fa-sharp fa-light fa-ear-muffs',
    ECLIPSE = 'fa-sharp fa-light fa-eclipse',
    EGG = 'fa-sharp fa-light fa-egg',
    EGGPLANT = 'fa-sharp fa-light fa-eggplant',
    EGG_FRIED = 'fa-sharp fa-light fa-egg-fried',
    EJECT = 'fa-sharp fa-light fa-eject',
    ELEPHANT = 'fa-sharp fa-light fa-elephant',
    ELEVATOR = 'fa-sharp fa-light fa-elevator',
    ELLIPSIS = 'fa-sharp fa-light fa-ellipsis',
    ELLIPSIS_STROKE = 'fa-sharp fa-light fa-ellipsis-stroke',
    ELLIPSIS_STROKE_VERTICAL = 'fa-sharp fa-light fa-ellipsis-stroke-vertical',
    ELLIPSIS_VERTICAL = 'fa-sharp fa-light fa-ellipsis-vertical',
    EMPTY_SET = 'fa-sharp fa-light fa-empty-set',
    ENGINE = 'fa-sharp fa-light fa-engine',
    ENGINE_WARNING = 'fa-sharp fa-light fa-engine-warning',
    ENVELOPE = 'fa-sharp fa-light fa-envelope',
    ENVELOPES = 'fa-sharp fa-light fa-envelopes',
    ENVELOPES_BULK = 'fa-sharp fa-light fa-envelopes-bulk',
    ENVELOPE_CIRCLE_CHECK = 'fa-sharp fa-light fa-envelope-circle-check',
    ENVELOPE_DOT = 'fa-sharp fa-light fa-envelope-dot',
    ENVELOPE_OPEN = 'fa-sharp fa-light fa-envelope-open',
    ENVELOPE_OPEN_DOLLAR = 'fa-sharp fa-light fa-envelope-open-dollar',
    ENVELOPE_OPEN_TEXT = 'fa-sharp fa-light fa-envelope-open-text',
    EQUALS = 'fa-sharp fa-light fa-equals',
    ERASER = 'fa-sharp fa-light fa-eraser',
    ESCALATOR = 'fa-sharp fa-light fa-escalator',
    ETHERNET = 'fa-sharp fa-light fa-ethernet',
    EURO_SIGN = 'fa-sharp fa-light fa-euro-sign',
    EXCAVATOR = 'fa-sharp fa-light fa-excavator',
    EXCLAMATION = 'fa-sharp fa-light fa-exclamation',
    EXPAND = 'fa-sharp fa-light fa-expand',
    EXPAND_WIDE = 'fa-sharp fa-light fa-expand-wide',
    EXPLOSION = 'fa-sharp fa-light fa-explosion',
    EYE = 'fa-sharp fa-light fa-eye',
    EYES = 'fa-sharp fa-light fa-eyes',
    EYE_DROPPER = 'fa-sharp fa-light fa-eye-dropper',
    EYE_DROPPER_FULL = 'fa-sharp fa-light fa-eye-dropper-full',
    EYE_DROPPER_HALF = 'fa-sharp fa-light fa-eye-dropper-half',
    EYE_EVIL = 'fa-sharp fa-light fa-eye-evil',
    EYE_LOW_VISION = 'fa-sharp fa-light fa-eye-low-vision',
    EYE_SLASH = 'fa-sharp fa-light fa-eye-slash',
    F = 'fa-sharp fa-light fa-f',
    FACE_ANGRY = 'fa-sharp fa-light fa-face-angry',
    FACE_ANGRY_HORNS = 'fa-sharp fa-light fa-face-angry-horns',
    FACE_ANGUISHED = 'fa-sharp fa-light fa-face-anguished',
    FACE_ANXIOUS_SWEAT = 'fa-sharp fa-light fa-face-anxious-sweat',
    FACE_ASTONISHED = 'fa-sharp fa-light fa-face-astonished',
    FACE_AWESOME = 'fa-sharp fa-light fa-face-awesome',
    FACE_BEAM_HAND_OVER_MOUTH = 'fa-sharp fa-light fa-face-beam-hand-over-mouth',
    FACE_CLOUDS = 'fa-sharp fa-light fa-face-clouds',
    FACE_CONFOUNDED = 'fa-sharp fa-light fa-face-confounded',
    FACE_CONFUSED = 'fa-sharp fa-light fa-face-confused',
    FACE_COWBOY_HAT = 'fa-sharp fa-light fa-face-cowboy-hat',
    FACE_DIAGONAL_MOUTH = 'fa-sharp fa-light fa-face-diagonal-mouth',
    FACE_DISAPPOINTED = 'fa-sharp fa-light fa-face-disappointed',
    FACE_DISGUISE = 'fa-sharp fa-light fa-face-disguise',
    FACE_DIZZY = 'fa-sharp fa-light fa-face-dizzy',
    FACE_DOTTED = 'fa-sharp fa-light fa-face-dotted',
    FACE_DOWNCAST_SWEAT = 'fa-sharp fa-light fa-face-downcast-sweat',
    FACE_DROOLING = 'fa-sharp fa-light fa-face-drooling',
    FACE_EXHALING = 'fa-sharp fa-light fa-face-exhaling',
    FACE_EXPLODE = 'fa-sharp fa-light fa-face-explode',
    FACE_EXPRESSIONLESS = 'fa-sharp fa-light fa-face-expressionless',
    FACE_EYES_XMARKS = 'fa-sharp fa-light fa-face-eyes-xmarks',
    FACE_FEARFUL = 'fa-sharp fa-light fa-face-fearful',
    FACE_FLUSHED = 'fa-sharp fa-light fa-face-flushed',
    FACE_FROWN = 'fa-sharp fa-light fa-face-frown',
    FACE_FROWN_OPEN = 'fa-sharp fa-light fa-face-frown-open',
    FACE_FROWN_SLIGHT = 'fa-sharp fa-light fa-face-frown-slight',
    FACE_GLASSES = 'fa-sharp fa-light fa-face-glasses',
    FACE_GRIMACE = 'fa-sharp fa-light fa-face-grimace',
    FACE_GRIN = 'fa-sharp fa-light fa-face-grin',
    FACE_GRIN_BEAM = 'fa-sharp fa-light fa-face-grin-beam',
    FACE_GRIN_BEAM_SWEAT = 'fa-sharp fa-light fa-face-grin-beam-sweat',
    FACE_GRIN_HEARTS = 'fa-sharp fa-light fa-face-grin-hearts',
    FACE_GRIN_SQUINT = 'fa-sharp fa-light fa-face-grin-squint',
    FACE_GRIN_SQUINT_TEARS = 'fa-sharp fa-light fa-face-grin-squint-tears',
    FACE_GRIN_STARS = 'fa-sharp fa-light fa-face-grin-stars',
    FACE_GRIN_TEARS = 'fa-sharp fa-light fa-face-grin-tears',
    FACE_GRIN_TONGUE = 'fa-sharp fa-light fa-face-grin-tongue',
    FACE_GRIN_TONGUE_SQUINT = 'fa-sharp fa-light fa-face-grin-tongue-squint',
    FACE_GRIN_TONGUE_WINK = 'fa-sharp fa-light fa-face-grin-tongue-wink',
    FACE_GRIN_WIDE = 'fa-sharp fa-light fa-face-grin-wide',
    FACE_GRIN_WINK = 'fa-sharp fa-light fa-face-grin-wink',
    FACE_HAND_OVER_MOUTH = 'fa-sharp fa-light fa-face-hand-over-mouth',
    FACE_HAND_PEEKING = 'fa-sharp fa-light fa-face-hand-peeking',
    FACE_HAND_YAWN = 'fa-sharp fa-light fa-face-hand-yawn',
    FACE_HEAD_BANDAGE = 'fa-sharp fa-light fa-face-head-bandage',
    FACE_HOLDING_BACK_TEARS = 'fa-sharp fa-light fa-face-holding-back-tears',
    FACE_HUSHED = 'fa-sharp fa-light fa-face-hushed',
    FACE_ICICLES = 'fa-sharp fa-light fa-face-icicles',
    FACE_KISS = 'fa-sharp fa-light fa-face-kiss',
    FACE_KISS_BEAM = 'fa-sharp fa-light fa-face-kiss-beam',
    FACE_KISS_CLOSED_EYES = 'fa-sharp fa-light fa-face-kiss-closed-eyes',
    FACE_KISS_WINK_HEART = 'fa-sharp fa-light fa-face-kiss-wink-heart',
    FACE_LAUGH = 'fa-sharp fa-light fa-face-laugh',
    FACE_LAUGH_BEAM = 'fa-sharp fa-light fa-face-laugh-beam',
    FACE_LAUGH_SQUINT = 'fa-sharp fa-light fa-face-laugh-squint',
    FACE_LAUGH_WINK = 'fa-sharp fa-light fa-face-laugh-wink',
    FACE_LYING = 'fa-sharp fa-light fa-face-lying',
    FACE_MASK = 'fa-sharp fa-light fa-face-mask',
    FACE_MEH = 'fa-sharp fa-light fa-face-meh',
    FACE_MEH_BLANK = 'fa-sharp fa-light fa-face-meh-blank',
    FACE_MELTING = 'fa-sharp fa-light fa-face-melting',
    FACE_MONOCLE = 'fa-sharp fa-light fa-face-monocle',
    FACE_NAUSEATED = 'fa-sharp fa-light fa-face-nauseated',
    FACE_NOSE_STEAM = 'fa-sharp fa-light fa-face-nose-steam',
    FACE_PARTY = 'fa-sharp fa-light fa-face-party',
    FACE_PENSIVE = 'fa-sharp fa-light fa-face-pensive',
    FACE_PERSEVERING = 'fa-sharp fa-light fa-face-persevering',
    FACE_PLEADING = 'fa-sharp fa-light fa-face-pleading',
    FACE_POUTING = 'fa-sharp fa-light fa-face-pouting',
    FACE_RAISED_EYEBROW = 'fa-sharp fa-light fa-face-raised-eyebrow',
    FACE_RELIEVED = 'fa-sharp fa-light fa-face-relieved',
    FACE_ROLLING_EYES = 'fa-sharp fa-light fa-face-rolling-eyes',
    FACE_SAD_CRY = 'fa-sharp fa-light fa-face-sad-cry',
    FACE_SAD_SWEAT = 'fa-sharp fa-light fa-face-sad-sweat',
    FACE_SAD_TEAR = 'fa-sharp fa-light fa-face-sad-tear',
    FACE_SALUTING = 'fa-sharp fa-light fa-face-saluting',
    FACE_SCREAM = 'fa-sharp fa-light fa-face-scream',
    FACE_SHUSH = 'fa-sharp fa-light fa-face-shush',
    FACE_SLEEPING = 'fa-sharp fa-light fa-face-sleeping',
    FACE_SLEEPY = 'fa-sharp fa-light fa-face-sleepy',
    FACE_SMILE = 'fa-sharp fa-light fa-face-smile',
    FACE_SMILE_BEAM = 'fa-sharp fa-light fa-face-smile-beam',
    FACE_SMILE_HALO = 'fa-sharp fa-light fa-face-smile-halo',
    FACE_SMILE_HEARTS = 'fa-sharp fa-light fa-face-smile-hearts',
    FACE_SMILE_HORNS = 'fa-sharp fa-light fa-face-smile-horns',
    FACE_SMILE_PLUS = 'fa-sharp fa-light fa-face-smile-plus',
    FACE_SMILE_RELAXED = 'fa-sharp fa-light fa-face-smile-relaxed',
    FACE_SMILE_TEAR = 'fa-sharp fa-light fa-face-smile-tear',
    FACE_SMILE_TONGUE = 'fa-sharp fa-light fa-face-smile-tongue',
    FACE_SMILE_UPSIDE_DOWN = 'fa-sharp fa-light fa-face-smile-upside-down',
    FACE_SMILE_WINK = 'fa-sharp fa-light fa-face-smile-wink',
    FACE_SMILING_HANDS = 'fa-sharp fa-light fa-face-smiling-hands',
    FACE_SMIRKING = 'fa-sharp fa-light fa-face-smirking',
    FACE_SPIRAL_EYES = 'fa-sharp fa-light fa-face-spiral-eyes',
    FACE_SUNGLASSES = 'fa-sharp fa-light fa-face-sunglasses',
    FACE_SURPRISE = 'fa-sharp fa-light fa-face-surprise',
    FACE_SWEAR = 'fa-sharp fa-light fa-face-swear',
    FACE_THERMOMETER = 'fa-sharp fa-light fa-face-thermometer',
    FACE_THINKING = 'fa-sharp fa-light fa-face-thinking',
    FACE_TIRED = 'fa-sharp fa-light fa-face-tired',
    FACE_TISSUE = 'fa-sharp fa-light fa-face-tissue',
    FACE_TONGUE_MONEY = 'fa-sharp fa-light fa-face-tongue-money',
    FACE_TONGUE_SWEAT = 'fa-sharp fa-light fa-face-tongue-sweat',
    FACE_UNAMUSED = 'fa-sharp fa-light fa-face-unamused',
    FACE_VIEWFINDER = 'fa-sharp fa-light fa-face-viewfinder',
    FACE_VOMIT = 'fa-sharp fa-light fa-face-vomit',
    FACE_WEARY = 'fa-sharp fa-light fa-face-weary',
    FACE_WOOZY = 'fa-sharp fa-light fa-face-woozy',
    FACE_WORRIED = 'fa-sharp fa-light fa-face-worried',
    FACE_ZANY = 'fa-sharp fa-light fa-face-zany',
    FACE_ZIPPER = 'fa-sharp fa-light fa-face-zipper',
    FALAFEL = 'fa-sharp fa-light fa-falafel',
    FAMILY = 'fa-sharp fa-light fa-family',
    FAMILY_DRESS = 'fa-sharp fa-light fa-family-dress',
    FAMILY_PANTS = 'fa-sharp fa-light fa-family-pants',
    FAN = 'fa-sharp fa-light fa-fan',
    FAN_TABLE = 'fa-sharp fa-light fa-fan-table',
    FARM = 'fa-sharp fa-light fa-farm',
    FAUCET = 'fa-sharp fa-light fa-faucet',
    FAUCET_DRIP = 'fa-sharp fa-light fa-faucet-drip',
    FAX = 'fa-sharp fa-light fa-fax',
    FEATHER = 'fa-sharp fa-light fa-feather',
    FEATHER_POINTED = 'fa-sharp fa-light fa-feather-pointed',
    FENCE = 'fa-sharp fa-light fa-fence',
    FERRIS_WHEEL = 'fa-sharp fa-light fa-ferris-wheel',
    FERRY = 'fa-sharp fa-light fa-ferry',
    FIELD_HOCKEY_STICK_BALL = 'fa-sharp fa-light fa-field-hockey-stick-ball',
    FILE = 'fa-sharp fa-light fa-file',
    FILES = 'fa-sharp fa-light fa-files',
    FILES_MEDICAL = 'fa-sharp fa-light fa-files-medical',
    FILE_ARROW_DOWN = 'fa-sharp fa-light fa-file-arrow-down',
    FILE_ARROW_UP = 'fa-sharp fa-light fa-file-arrow-up',
    FILE_AUDIO = 'fa-sharp fa-light fa-file-audio',
    FILE_BINARY = 'fa-sharp fa-light fa-file-binary',
    FILE_CAD = 'fa-sharp fa-light fa-file-cad',
    FILE_CERTIFICATE = 'fa-sharp fa-light fa-file-certificate',
    FILE_CHART_COLUMN = 'fa-sharp fa-light fa-file-chart-column',
    FILE_CHART_PIE = 'fa-sharp fa-light fa-file-chart-pie',
    FILE_CHECK = 'fa-sharp fa-light fa-file-check',
    FILE_CIRCLE_CHECK = 'fa-sharp fa-light fa-file-circle-check',
    FILE_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-file-circle-exclamation',
    FILE_CIRCLE_INFO = 'fa-sharp fa-light fa-file-circle-info',
    FILE_CIRCLE_MINUS = 'fa-sharp fa-light fa-file-circle-minus',
    FILE_CIRCLE_PLUS = 'fa-sharp fa-light fa-file-circle-plus',
    FILE_CIRCLE_QUESTION = 'fa-sharp fa-light fa-file-circle-question',
    FILE_CIRCLE_XMARK = 'fa-sharp fa-light fa-file-circle-xmark',
    FILE_CODE = 'fa-sharp fa-light fa-file-code',
    FILE_CONTRACT = 'fa-sharp fa-light fa-file-contract',
    FILE_CSV = 'fa-sharp fa-light fa-file-csv',
    FILE_DASHED_LINE = 'fa-sharp fa-light fa-file-dashed-line',
    FILE_DOC = 'fa-sharp fa-light fa-file-doc',
    FILE_EPS = 'fa-sharp fa-light fa-file-eps',
    FILE_EXCEL = 'fa-sharp fa-light fa-file-excel',
    FILE_EXCLAMATION = 'fa-sharp fa-light fa-file-exclamation',
    FILE_EXPORT = 'fa-sharp fa-light fa-file-export',
    FILE_GIF = 'fa-sharp fa-light fa-file-gif',
    FILE_HEART = 'fa-sharp fa-light fa-file-heart',
    FILE_IMAGE = 'fa-sharp fa-light fa-file-image',
    FILE_IMPORT = 'fa-sharp fa-light fa-file-import',
    FILE_INVOICE = 'fa-sharp fa-light fa-file-invoice',
    FILE_INVOICE_DOLLAR = 'fa-sharp fa-light fa-file-invoice-dollar',
    FILE_JPG = 'fa-sharp fa-light fa-file-jpg',
    FILE_LINES = 'fa-sharp fa-light fa-file-lines',
    FILE_LOCK = 'fa-sharp fa-light fa-file-lock',
    FILE_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-file-magnifying-glass',
    FILE_MEDICAL = 'fa-sharp fa-light fa-file-medical',
    FILE_MINUS = 'fa-sharp fa-light fa-file-minus',
    FILE_MOV = 'fa-sharp fa-light fa-file-mov',
    FILE_MP3 = 'fa-sharp fa-light fa-file-mp3',
    FILE_MP4 = 'fa-sharp fa-light fa-file-mp4',
    FILE_MUSIC = 'fa-sharp fa-light fa-file-music',
    FILE_PDF = 'fa-sharp fa-light fa-file-pdf',
    FILE_PEN = 'fa-sharp fa-light fa-file-pen',
    FILE_PLUS = 'fa-sharp fa-light fa-file-plus',
    FILE_PLUS_MINUS = 'fa-sharp fa-light fa-file-plus-minus',
    FILE_PNG = 'fa-sharp fa-light fa-file-png',
    FILE_POWERPOINT = 'fa-sharp fa-light fa-file-powerpoint',
    FILE_PPT = 'fa-sharp fa-light fa-file-ppt',
    FILE_PRESCRIPTION = 'fa-sharp fa-light fa-file-prescription',
    FILE_SHIELD = 'fa-sharp fa-light fa-file-shield',
    FILE_SIGNATURE = 'fa-sharp fa-light fa-file-signature',
    FILE_SLASH = 'fa-sharp fa-light fa-file-slash',
    FILE_SPREADSHEET = 'fa-sharp fa-light fa-file-spreadsheet',
    FILE_SVG = 'fa-sharp fa-light fa-file-svg',
    FILE_USER = 'fa-sharp fa-light fa-file-user',
    FILE_VECTOR = 'fa-sharp fa-light fa-file-vector',
    FILE_VIDEO = 'fa-sharp fa-light fa-file-video',
    FILE_WAVEFORM = 'fa-sharp fa-light fa-file-waveform',
    FILE_WORD = 'fa-sharp fa-light fa-file-word',
    FILE_XLS = 'fa-sharp fa-light fa-file-xls',
    FILE_XMARK = 'fa-sharp fa-light fa-file-xmark',
    FILE_XML = 'fa-sharp fa-light fa-file-xml',
    FILE_ZIP = 'fa-sharp fa-light fa-file-zip',
    FILE_ZIPPER = 'fa-sharp fa-light fa-file-zipper',
    FILL = 'fa-sharp fa-light fa-fill',
    FILL_DRIP = 'fa-sharp fa-light fa-fill-drip',
    FILM = 'fa-sharp fa-light fa-film',
    FILMS = 'fa-sharp fa-light fa-films',
    FILM_CANISTER = 'fa-sharp fa-light fa-film-canister',
    FILM_SIMPLE = 'fa-sharp fa-light fa-film-simple',
    FILM_SLASH = 'fa-sharp fa-light fa-film-slash',
    FILTER = 'fa-sharp fa-light fa-filter',
    FILTERS = 'fa-sharp fa-light fa-filters',
    FILTER_CIRCLE_DOLLAR = 'fa-sharp fa-light fa-filter-circle-dollar',
    FILTER_CIRCLE_XMARK = 'fa-sharp fa-light fa-filter-circle-xmark',
    FILTER_LIST = 'fa-sharp fa-light fa-filter-list',
    FILTER_SLASH = 'fa-sharp fa-light fa-filter-slash',
    FINGERPRINT = 'fa-sharp fa-light fa-fingerprint',
    FIRE = 'fa-sharp fa-light fa-fire',
    FIREPLACE = 'fa-sharp fa-light fa-fireplace',
    FIRE_BURNER = 'fa-sharp fa-light fa-fire-burner',
    FIRE_EXTINGUISHER = 'fa-sharp fa-light fa-fire-extinguisher',
    FIRE_FLAME = 'fa-sharp fa-light fa-fire-flame',
    FIRE_FLAME_CURVED = 'fa-sharp fa-light fa-fire-flame-curved',
    FIRE_FLAME_SIMPLE = 'fa-sharp fa-light fa-fire-flame-simple',
    FIRE_HYDRANT = 'fa-sharp fa-light fa-fire-hydrant',
    FIRE_SMOKE = 'fa-sharp fa-light fa-fire-smoke',
    FISH = 'fa-sharp fa-light fa-fish',
    FISHING_ROD = 'fa-sharp fa-light fa-fishing-rod',
    FISH_BONES = 'fa-sharp fa-light fa-fish-bones',
    FISH_COOKED = 'fa-sharp fa-light fa-fish-cooked',
    FISH_FINS = 'fa-sharp fa-light fa-fish-fins',
    FLAG = 'fa-sharp fa-light fa-flag',
    FLAG_CHECKERED = 'fa-sharp fa-light fa-flag-checkered',
    FLAG_PENNANT = 'fa-sharp fa-light fa-flag-pennant',
    FLAG_SWALLOWTAIL = 'fa-sharp fa-light fa-flag-swallowtail',
    FLAG_USA = 'fa-sharp fa-light fa-flag-usa',
    FLASHLIGHT = 'fa-sharp fa-light fa-flashlight',
    FLASK = 'fa-sharp fa-light fa-flask',
    FLASK_GEAR = 'fa-sharp fa-light fa-flask-gear',
    FLASK_ROUND_POISON = 'fa-sharp fa-light fa-flask-round-poison',
    FLASK_ROUND_POTION = 'fa-sharp fa-light fa-flask-round-potion',
    FLASK_VIAL = 'fa-sharp fa-light fa-flask-vial',
    FLATBREAD = 'fa-sharp fa-light fa-flatbread',
    FLATBREAD_STUFFED = 'fa-sharp fa-light fa-flatbread-stuffed',
    FLOPPY_DISK = 'fa-sharp fa-light fa-floppy-disk',
    FLOPPY_DISKS = 'fa-sharp fa-light fa-floppy-disks',
    FLOPPY_DISK_CIRCLE_ARROW_RIGHT = 'fa-sharp fa-light fa-floppy-disk-circle-arrow-right',
    FLOPPY_DISK_CIRCLE_XMARK = 'fa-sharp fa-light fa-floppy-disk-circle-xmark',
    FLOPPY_DISK_PEN = 'fa-sharp fa-light fa-floppy-disk-pen',
    FLORIN_SIGN = 'fa-sharp fa-light fa-florin-sign',
    FLOWER = 'fa-sharp fa-light fa-flower',
    FLOWER_DAFFODIL = 'fa-sharp fa-light fa-flower-daffodil',
    FLOWER_TULIP = 'fa-sharp fa-light fa-flower-tulip',
    FLUTE = 'fa-sharp fa-light fa-flute',
    FLUX_CAPACITOR = 'fa-sharp fa-light fa-flux-capacitor',
    FLYING_DISC = 'fa-sharp fa-light fa-flying-disc',
    FOLDER = 'fa-sharp fa-light fa-folder',
    FOLDERS = 'fa-sharp fa-light fa-folders',
    FOLDER_ARROW_DOWN = 'fa-sharp fa-light fa-folder-arrow-down',
    FOLDER_ARROW_UP = 'fa-sharp fa-light fa-folder-arrow-up',
    FOLDER_BOOKMARK = 'fa-sharp fa-light fa-folder-bookmark',
    FOLDER_CHECK = 'fa-sharp fa-light fa-folder-check',
    FOLDER_CLOSED = 'fa-sharp fa-light fa-folder-closed',
    FOLDER_GEAR = 'fa-sharp fa-light fa-folder-gear',
    FOLDER_GRID = 'fa-sharp fa-light fa-folder-grid',
    FOLDER_HEART = 'fa-sharp fa-light fa-folder-heart',
    FOLDER_IMAGE = 'fa-sharp fa-light fa-folder-image',
    FOLDER_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-folder-magnifying-glass',
    FOLDER_MEDICAL = 'fa-sharp fa-light fa-folder-medical',
    FOLDER_MINUS = 'fa-sharp fa-light fa-folder-minus',
    FOLDER_MUSIC = 'fa-sharp fa-light fa-folder-music',
    FOLDER_OPEN = 'fa-sharp fa-light fa-folder-open',
    FOLDER_PLUS = 'fa-sharp fa-light fa-folder-plus',
    FOLDER_TREE = 'fa-sharp fa-light fa-folder-tree',
    FOLDER_USER = 'fa-sharp fa-light fa-folder-user',
    FOLDER_XMARK = 'fa-sharp fa-light fa-folder-xmark',
    FONDUE_POT = 'fa-sharp fa-light fa-fondue-pot',
    FONT = 'fa-sharp fa-light fa-font',
    FONT_CASE = 'fa-sharp fa-light fa-font-case',
    FOOTBALL = 'fa-sharp fa-light fa-football',
    FOOTBALL_HELMET = 'fa-sharp fa-light fa-football-helmet',
    FORK = 'fa-sharp fa-light fa-fork',
    FORKLIFT = 'fa-sharp fa-light fa-forklift',
    FORK_KNIFE = 'fa-sharp fa-light fa-fork-knife',
    FORT = 'fa-sharp fa-light fa-fort',
    FORWARD = 'fa-sharp fa-light fa-forward',
    FORWARD_FAST = 'fa-sharp fa-light fa-forward-fast',
    FORWARD_STEP = 'fa-sharp fa-light fa-forward-step',
    FRAME = 'fa-sharp fa-light fa-frame',
    FRANC_SIGN = 'fa-sharp fa-light fa-franc-sign',
    FRENCH_FRIES = 'fa-sharp fa-light fa-french-fries',
    FROG = 'fa-sharp fa-light fa-frog',
    FUNCTION = 'fa-sharp fa-light fa-function',
    FUTBOL = 'fa-sharp fa-light fa-futbol',
    G = 'fa-sharp fa-light fa-g',
    GALAXY = 'fa-sharp fa-light fa-galaxy',
    GALLERY_THUMBNAILS = 'fa-sharp fa-light fa-gallery-thumbnails',
    GAMEPAD = 'fa-sharp fa-light fa-gamepad',
    GAMEPAD_MODERN = 'fa-sharp fa-light fa-gamepad-modern',
    GAME_BOARD = 'fa-sharp fa-light fa-game-board',
    GAME_BOARD_SIMPLE = 'fa-sharp fa-light fa-game-board-simple',
    GAME_CONSOLE_HANDHELD = 'fa-sharp fa-light fa-game-console-handheld',
    GAME_CONSOLE_HANDHELD_CRANK = 'fa-sharp fa-light fa-game-console-handheld-crank',
    GARAGE = 'fa-sharp fa-light fa-garage',
    GARAGE_CAR = 'fa-sharp fa-light fa-garage-car',
    GARAGE_OPEN = 'fa-sharp fa-light fa-garage-open',
    GARLIC = 'fa-sharp fa-light fa-garlic',
    GAS_PUMP = 'fa-sharp fa-light fa-gas-pump',
    GAS_PUMP_SLASH = 'fa-sharp fa-light fa-gas-pump-slash',
    GAUGE = 'fa-sharp fa-light fa-gauge',
    GAUGE_CIRCLE_BOLT = 'fa-sharp fa-light fa-gauge-circle-bolt',
    GAUGE_CIRCLE_MINUS = 'fa-sharp fa-light fa-gauge-circle-minus',
    GAUGE_CIRCLE_PLUS = 'fa-sharp fa-light fa-gauge-circle-plus',
    GAUGE_HIGH = 'fa-sharp fa-light fa-gauge-high',
    GAUGE_LOW = 'fa-sharp fa-light fa-gauge-low',
    GAUGE_MAX = 'fa-sharp fa-light fa-gauge-max',
    GAUGE_MIN = 'fa-sharp fa-light fa-gauge-min',
    GAUGE_SIMPLE = 'fa-sharp fa-light fa-gauge-simple',
    GAUGE_SIMPLE_HIGH = 'fa-sharp fa-light fa-gauge-simple-high',
    GAUGE_SIMPLE_LOW = 'fa-sharp fa-light fa-gauge-simple-low',
    GAUGE_SIMPLE_MAX = 'fa-sharp fa-light fa-gauge-simple-max',
    GAUGE_SIMPLE_MIN = 'fa-sharp fa-light fa-gauge-simple-min',
    GAVEL = 'fa-sharp fa-light fa-gavel',
    GEAR = 'fa-sharp fa-light fa-gear',
    GEARS = 'fa-sharp fa-light fa-gears',
    GEAR_CODE = 'fa-sharp fa-light fa-gear-code',
    GEAR_COMPLEX = 'fa-sharp fa-light fa-gear-complex',
    GEAR_COMPLEX_CODE = 'fa-sharp fa-light fa-gear-complex-code',
    GEM = 'fa-sharp fa-light fa-gem',
    GENDERLESS = 'fa-sharp fa-light fa-genderless',
    GHOST = 'fa-sharp fa-light fa-ghost',
    GIF = 'fa-sharp fa-light fa-gif',
    GIFT = 'fa-sharp fa-light fa-gift',
    GIFTS = 'fa-sharp fa-light fa-gifts',
    GIFT_CARD = 'fa-sharp fa-light fa-gift-card',
    GINGERBREAD_MAN = 'fa-sharp fa-light fa-gingerbread-man',
    GLASS = 'fa-sharp fa-light fa-glass',
    GLASSES = 'fa-sharp fa-light fa-glasses',
    GLASSES_ROUND = 'fa-sharp fa-light fa-glasses-round',
    GLASS_CITRUS = 'fa-sharp fa-light fa-glass-citrus',
    GLASS_EMPTY = 'fa-sharp fa-light fa-glass-empty',
    GLASS_HALF = 'fa-sharp fa-light fa-glass-half',
    GLASS_WATER = 'fa-sharp fa-light fa-glass-water',
    GLASS_WATER_DROPLET = 'fa-sharp fa-light fa-glass-water-droplet',
    GLOBE = 'fa-sharp fa-light fa-globe',
    GLOBE_POINTER = 'fa-sharp fa-light fa-globe-pointer',
    GLOBE_SNOW = 'fa-sharp fa-light fa-globe-snow',
    GLOBE_STAND = 'fa-sharp fa-light fa-globe-stand',
    GLOBE_WIFI = 'fa-sharp fa-light fa-globe-wifi',
    GOAL_NET = 'fa-sharp fa-light fa-goal-net',
    GOLF_BALL_TEE = 'fa-sharp fa-light fa-golf-ball-tee',
    GOLF_CLUB = 'fa-sharp fa-light fa-golf-club',
    GOLF_FLAG_HOLE = 'fa-sharp fa-light fa-golf-flag-hole',
    GOPURAM = 'fa-sharp fa-light fa-gopuram',
    GRADUATION_CAP = 'fa-sharp fa-light fa-graduation-cap',
    GRAMOPHONE = 'fa-sharp fa-light fa-gramophone',
    GRAPES = 'fa-sharp fa-light fa-grapes',
    GRATE = 'fa-sharp fa-light fa-grate',
    GRATE_DROPLET = 'fa-sharp fa-light fa-grate-droplet',
    GREATER_THAN = 'fa-sharp fa-light fa-greater-than',
    GREATER_THAN_EQUAL = 'fa-sharp fa-light fa-greater-than-equal',
    GRID = 'fa-sharp fa-light fa-grid',
    GRID_2 = 'fa-sharp fa-light fa-grid-2',
    GRID_2_PLUS = 'fa-sharp fa-light fa-grid-2-plus',
    GRID_4 = 'fa-sharp fa-light fa-grid-4',
    GRID_5 = 'fa-sharp fa-light fa-grid-5',
    GRID_DIVIDERS = 'fa-sharp fa-light fa-grid-dividers',
    GRID_HORIZONTAL = 'fa-sharp fa-light fa-grid-horizontal',
    GRID_ROUND = 'fa-sharp fa-light fa-grid-round',
    GRID_ROUND_2 = 'fa-sharp fa-light fa-grid-round-2',
    GRID_ROUND_2_PLUS = 'fa-sharp fa-light fa-grid-round-2-plus',
    GRID_ROUND_4 = 'fa-sharp fa-light fa-grid-round-4',
    GRID_ROUND_5 = 'fa-sharp fa-light fa-grid-round-5',
    GRILL = 'fa-sharp fa-light fa-grill',
    GRILL_FIRE = 'fa-sharp fa-light fa-grill-fire',
    GRILL_HOT = 'fa-sharp fa-light fa-grill-hot',
    GRIP = 'fa-sharp fa-light fa-grip',
    GRIP_DOTS = 'fa-sharp fa-light fa-grip-dots',
    GRIP_DOTS_VERTICAL = 'fa-sharp fa-light fa-grip-dots-vertical',
    GRIP_LINES = 'fa-sharp fa-light fa-grip-lines',
    GRIP_LINES_VERTICAL = 'fa-sharp fa-light fa-grip-lines-vertical',
    GRIP_VERTICAL = 'fa-sharp fa-light fa-grip-vertical',
    GROUP_ARROWS_ROTATE = 'fa-sharp fa-light fa-group-arrows-rotate',
    GUARANI_SIGN = 'fa-sharp fa-light fa-guarani-sign',
    GUITAR = 'fa-sharp fa-light fa-guitar',
    GUITARS = 'fa-sharp fa-light fa-guitars',
    GUITAR_ELECTRIC = 'fa-sharp fa-light fa-guitar-electric',
    GUN = 'fa-sharp fa-light fa-gun',
    GUN_SLASH = 'fa-sharp fa-light fa-gun-slash',
    GUN_SQUIRT = 'fa-sharp fa-light fa-gun-squirt',
    H = 'fa-sharp fa-light fa-h',
    H1 = 'fa-sharp fa-light fa-h1',
    H2 = 'fa-sharp fa-light fa-h2',
    H3 = 'fa-sharp fa-light fa-h3',
    H4 = 'fa-sharp fa-light fa-h4',
    H5 = 'fa-sharp fa-light fa-h5',
    H6 = 'fa-sharp fa-light fa-h6',
    HAMMER = 'fa-sharp fa-light fa-hammer',
    HAMMER_BRUSH = 'fa-sharp fa-light fa-hammer-brush',
    HAMMER_CRASH = 'fa-sharp fa-light fa-hammer-crash',
    HAMMER_WAR = 'fa-sharp fa-light fa-hammer-war',
    HAMSA = 'fa-sharp fa-light fa-hamsa',
    HAND = 'fa-sharp fa-light fa-hand',
    HANDCUFFS = 'fa-sharp fa-light fa-handcuffs',
    HANDS = 'fa-sharp fa-light fa-hands',
    HANDSHAKE = 'fa-sharp fa-light fa-handshake',
    HANDSHAKE_ANGLE = 'fa-sharp fa-light fa-handshake-angle',
    HANDSHAKE_SIMPLE = 'fa-sharp fa-light fa-handshake-simple',
    HANDSHAKE_SIMPLE_SLASH = 'fa-sharp fa-light fa-handshake-simple-slash',
    HANDSHAKE_SLASH = 'fa-sharp fa-light fa-handshake-slash',
    HANDS_ASL_INTERPRETING = 'fa-sharp fa-light fa-hands-asl-interpreting',
    HANDS_BOUND = 'fa-sharp fa-light fa-hands-bound',
    HANDS_BUBBLES = 'fa-sharp fa-light fa-hands-bubbles',
    HANDS_CLAPPING = 'fa-sharp fa-light fa-hands-clapping',
    HANDS_HOLDING = 'fa-sharp fa-light fa-hands-holding',
    HANDS_HOLDING_CHILD = 'fa-sharp fa-light fa-hands-holding-child',
    HANDS_HOLDING_CIRCLE = 'fa-sharp fa-light fa-hands-holding-circle',
    HANDS_HOLDING_DIAMOND = 'fa-sharp fa-light fa-hands-holding-diamond',
    HANDS_HOLDING_DOLLAR = 'fa-sharp fa-light fa-hands-holding-dollar',
    HANDS_HOLDING_HEART = 'fa-sharp fa-light fa-hands-holding-heart',
    HANDS_PRAYING = 'fa-sharp fa-light fa-hands-praying',
    HAND_BACK_FIST = 'fa-sharp fa-light fa-hand-back-fist',
    HAND_BACK_POINT_DOWN = 'fa-sharp fa-light fa-hand-back-point-down',
    HAND_BACK_POINT_LEFT = 'fa-sharp fa-light fa-hand-back-point-left',
    HAND_BACK_POINT_RIBBON = 'fa-sharp fa-light fa-hand-back-point-ribbon',
    HAND_BACK_POINT_RIGHT = 'fa-sharp fa-light fa-hand-back-point-right',
    HAND_BACK_POINT_UP = 'fa-sharp fa-light fa-hand-back-point-up',
    HAND_DOTS = 'fa-sharp fa-light fa-hand-dots',
    HAND_FINGERS_CROSSED = 'fa-sharp fa-light fa-hand-fingers-crossed',
    HAND_FIST = 'fa-sharp fa-light fa-hand-fist',
    HAND_HEART = 'fa-sharp fa-light fa-hand-heart',
    HAND_HOLDING = 'fa-sharp fa-light fa-hand-holding',
    HAND_HOLDING_BOX = 'fa-sharp fa-light fa-hand-holding-box',
    HAND_HOLDING_CIRCLE_DOLLAR = 'fa-sharp fa-light fa-hand-holding-circle-dollar',
    HAND_HOLDING_DOLLAR = 'fa-sharp fa-light fa-hand-holding-dollar',
    HAND_HOLDING_DROPLET = 'fa-sharp fa-light fa-hand-holding-droplet',
    HAND_HOLDING_HAND = 'fa-sharp fa-light fa-hand-holding-hand',
    HAND_HOLDING_HEART = 'fa-sharp fa-light fa-hand-holding-heart',
    HAND_HOLDING_MAGIC = 'fa-sharp fa-light fa-hand-holding-magic',
    HAND_HOLDING_MEDICAL = 'fa-sharp fa-light fa-hand-holding-medical',
    HAND_HOLDING_SEEDLING = 'fa-sharp fa-light fa-hand-holding-seedling',
    HAND_HOLDING_SKULL = 'fa-sharp fa-light fa-hand-holding-skull',
    HAND_HORNS = 'fa-sharp fa-light fa-hand-horns',
    HAND_LIZARD = 'fa-sharp fa-light fa-hand-lizard',
    HAND_LOVE = 'fa-sharp fa-light fa-hand-love',
    HAND_MIDDLE_FINGER = 'fa-sharp fa-light fa-hand-middle-finger',
    HAND_PEACE = 'fa-sharp fa-light fa-hand-peace',
    HAND_POINTER = 'fa-sharp fa-light fa-hand-pointer',
    HAND_POINT_DOWN = 'fa-sharp fa-light fa-hand-point-down',
    HAND_POINT_LEFT = 'fa-sharp fa-light fa-hand-point-left',
    HAND_POINT_RIBBON = 'fa-sharp fa-light fa-hand-point-ribbon',
    HAND_POINT_RIGHT = 'fa-sharp fa-light fa-hand-point-right',
    HAND_POINT_UP = 'fa-sharp fa-light fa-hand-point-up',
    HAND_SCISSORS = 'fa-sharp fa-light fa-hand-scissors',
    HAND_SPARKLES = 'fa-sharp fa-light fa-hand-sparkles',
    HAND_SPOCK = 'fa-sharp fa-light fa-hand-spock',
    HAND_WAVE = 'fa-sharp fa-light fa-hand-wave',
    HANUKIAH = 'fa-sharp fa-light fa-hanukiah',
    HARD_DRIVE = 'fa-sharp fa-light fa-hard-drive',
    HASHTAG = 'fa-sharp fa-light fa-hashtag',
    HASHTAG_LOCK = 'fa-sharp fa-light fa-hashtag-lock',
    HAT_BEACH = 'fa-sharp fa-light fa-hat-beach',
    HAT_CHEF = 'fa-sharp fa-light fa-hat-chef',
    HAT_COWBOY = 'fa-sharp fa-light fa-hat-cowboy',
    HAT_COWBOY_SIDE = 'fa-sharp fa-light fa-hat-cowboy-side',
    HAT_SANTA = 'fa-sharp fa-light fa-hat-santa',
    HAT_WINTER = 'fa-sharp fa-light fa-hat-winter',
    HAT_WITCH = 'fa-sharp fa-light fa-hat-witch',
    HAT_WIZARD = 'fa-sharp fa-light fa-hat-wizard',
    HEADING = 'fa-sharp fa-light fa-heading',
    HEADPHONES = 'fa-sharp fa-light fa-headphones',
    HEADPHONES_SIMPLE = 'fa-sharp fa-light fa-headphones-simple',
    HEADSET = 'fa-sharp fa-light fa-headset',
    HEAD_SIDE = 'fa-sharp fa-light fa-head-side',
    HEAD_SIDE_BRAIN = 'fa-sharp fa-light fa-head-side-brain',
    HEAD_SIDE_COUGH = 'fa-sharp fa-light fa-head-side-cough',
    HEAD_SIDE_COUGH_SLASH = 'fa-sharp fa-light fa-head-side-cough-slash',
    HEAD_SIDE_GEAR = 'fa-sharp fa-light fa-head-side-gear',
    HEAD_SIDE_GOGGLES = 'fa-sharp fa-light fa-head-side-goggles',
    HEAD_SIDE_HEADPHONES = 'fa-sharp fa-light fa-head-side-headphones',
    HEAD_SIDE_HEART = 'fa-sharp fa-light fa-head-side-heart',
    HEAD_SIDE_MASK = 'fa-sharp fa-light fa-head-side-mask',
    HEAD_SIDE_MEDICAL = 'fa-sharp fa-light fa-head-side-medical',
    HEAD_SIDE_VIRUS = 'fa-sharp fa-light fa-head-side-virus',
    HEART = 'fa-sharp fa-light fa-heart',
    HEART_CIRCLE_BOLT = 'fa-sharp fa-light fa-heart-circle-bolt',
    HEART_CIRCLE_CHECK = 'fa-sharp fa-light fa-heart-circle-check',
    HEART_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-heart-circle-exclamation',
    HEART_CIRCLE_MINUS = 'fa-sharp fa-light fa-heart-circle-minus',
    HEART_CIRCLE_PLUS = 'fa-sharp fa-light fa-heart-circle-plus',
    HEART_CIRCLE_XMARK = 'fa-sharp fa-light fa-heart-circle-xmark',
    HEART_CRACK = 'fa-sharp fa-light fa-heart-crack',
    HEART_HALF = 'fa-sharp fa-light fa-heart-half',
    HEART_HALF_STROKE = 'fa-sharp fa-light fa-heart-half-stroke',
    HEART_PULSE = 'fa-sharp fa-light fa-heart-pulse',
    HEAT = 'fa-sharp fa-light fa-heat',
    HELICOPTER = 'fa-sharp fa-light fa-helicopter',
    HELICOPTER_SYMBOL = 'fa-sharp fa-light fa-helicopter-symbol',
    HELMET_BATTLE = 'fa-sharp fa-light fa-helmet-battle',
    HELMET_SAFETY = 'fa-sharp fa-light fa-helmet-safety',
    HELMET_UN = 'fa-sharp fa-light fa-helmet-un',
    HEXAGON = 'fa-sharp fa-light fa-hexagon',
    HEXAGON_CHECK = 'fa-sharp fa-light fa-hexagon-check',
    HEXAGON_DIVIDE = 'fa-sharp fa-light fa-hexagon-divide',
    HEXAGON_EXCLAMATION = 'fa-sharp fa-light fa-hexagon-exclamation',
    HEXAGON_IMAGE = 'fa-sharp fa-light fa-hexagon-image',
    HEXAGON_MINUS = 'fa-sharp fa-light fa-hexagon-minus',
    HEXAGON_PLUS = 'fa-sharp fa-light fa-hexagon-plus',
    HEXAGON_VERTICAL_NFT = 'fa-sharp fa-light fa-hexagon-vertical-nft',
    HEXAGON_VERTICAL_NFT_SLANTED = 'fa-sharp fa-light fa-hexagon-vertical-nft-slanted',
    HEXAGON_XMARK = 'fa-sharp fa-light fa-hexagon-xmark',
    HIGHLIGHTER = 'fa-sharp fa-light fa-highlighter',
    HIGHLIGHTER_LINE = 'fa-sharp fa-light fa-highlighter-line',
    HIGH_DEFINITION = 'fa-sharp fa-light fa-high-definition',
    HILL_AVALANCHE = 'fa-sharp fa-light fa-hill-avalanche',
    HILL_ROCKSLIDE = 'fa-sharp fa-light fa-hill-rockslide',
    HIPPO = 'fa-sharp fa-light fa-hippo',
    HOCKEY_MASK = 'fa-sharp fa-light fa-hockey-mask',
    HOCKEY_PUCK = 'fa-sharp fa-light fa-hockey-puck',
    HOCKEY_STICKS = 'fa-sharp fa-light fa-hockey-sticks',
    HOCKEY_STICK_PUCK = 'fa-sharp fa-light fa-hockey-stick-puck',
    HOLLY_BERRY = 'fa-sharp fa-light fa-holly-berry',
    HONEY_POT = 'fa-sharp fa-light fa-honey-pot',
    HOOD_CLOAK = 'fa-sharp fa-light fa-hood-cloak',
    HORIZONTAL_RULE = 'fa-sharp fa-light fa-horizontal-rule',
    HORSE = 'fa-sharp fa-light fa-horse',
    HORSE_HEAD = 'fa-sharp fa-light fa-horse-head',
    HORSE_SADDLE = 'fa-sharp fa-light fa-horse-saddle',
    HOSE = 'fa-sharp fa-light fa-hose',
    HOSE_REEL = 'fa-sharp fa-light fa-hose-reel',
    HOSPITAL = 'fa-sharp fa-light fa-hospital',
    HOSPITALS = 'fa-sharp fa-light fa-hospitals',
    HOSPITAL_USER = 'fa-sharp fa-light fa-hospital-user',
    HOTDOG = 'fa-sharp fa-light fa-hotdog',
    HOTEL = 'fa-sharp fa-light fa-hotel',
    HOT_TUB_PERSON = 'fa-sharp fa-light fa-hot-tub-person',
    HOURGLASS = 'fa-sharp fa-light fa-hourglass',
    HOURGLASS_CLOCK = 'fa-sharp fa-light fa-hourglass-clock',
    HOURGLASS_END = 'fa-sharp fa-light fa-hourglass-end',
    HOURGLASS_HALF = 'fa-sharp fa-light fa-hourglass-half',
    HOURGLASS_START = 'fa-sharp fa-light fa-hourglass-start',
    HOUSE = 'fa-sharp fa-light fa-house',
    HOUSE_BLANK = 'fa-sharp fa-light fa-house-blank',
    HOUSE_BUILDING = 'fa-sharp fa-light fa-house-building',
    HOUSE_CHIMNEY = 'fa-sharp fa-light fa-house-chimney',
    HOUSE_CHIMNEY_BLANK = 'fa-sharp fa-light fa-house-chimney-blank',
    HOUSE_CHIMNEY_CRACK = 'fa-sharp fa-light fa-house-chimney-crack',
    HOUSE_CHIMNEY_HEART = 'fa-sharp fa-light fa-house-chimney-heart',
    HOUSE_CHIMNEY_MEDICAL = 'fa-sharp fa-light fa-house-chimney-medical',
    HOUSE_CHIMNEY_USER = 'fa-sharp fa-light fa-house-chimney-user',
    HOUSE_CHIMNEY_WINDOW = 'fa-sharp fa-light fa-house-chimney-window',
    HOUSE_CIRCLE_CHECK = 'fa-sharp fa-light fa-house-circle-check',
    HOUSE_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-house-circle-exclamation',
    HOUSE_CIRCLE_XMARK = 'fa-sharp fa-light fa-house-circle-xmark',
    HOUSE_CRACK = 'fa-sharp fa-light fa-house-crack',
    HOUSE_DAY = 'fa-sharp fa-light fa-house-day',
    HOUSE_FIRE = 'fa-sharp fa-light fa-house-fire',
    HOUSE_FLAG = 'fa-sharp fa-light fa-house-flag',
    HOUSE_FLOOD_WATER = 'fa-sharp fa-light fa-house-flood-water',
    HOUSE_FLOOD_WATER_CIRCLE_ARROW_RIGHT = 'fa-sharp fa-light fa-house-flood-water-circle-arrow-right',
    HOUSE_HEART = 'fa-sharp fa-light fa-house-heart',
    HOUSE_LAPTOP = 'fa-sharp fa-light fa-house-laptop',
    HOUSE_LOCK = 'fa-sharp fa-light fa-house-lock',
    HOUSE_MEDICAL = 'fa-sharp fa-light fa-house-medical',
    HOUSE_MEDICAL_CIRCLE_CHECK = 'fa-sharp fa-light fa-house-medical-circle-check',
    HOUSE_MEDICAL_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-house-medical-circle-exclamation',
    HOUSE_MEDICAL_CIRCLE_XMARK = 'fa-sharp fa-light fa-house-medical-circle-xmark',
    HOUSE_MEDICAL_FLAG = 'fa-sharp fa-light fa-house-medical-flag',
    HOUSE_NIGHT = 'fa-sharp fa-light fa-house-night',
    HOUSE_PERSON_LEAVE = 'fa-sharp fa-light fa-house-person-leave',
    HOUSE_PERSON_RETURN = 'fa-sharp fa-light fa-house-person-return',
    HOUSE_SIGNAL = 'fa-sharp fa-light fa-house-signal',
    HOUSE_TREE = 'fa-sharp fa-light fa-house-tree',
    HOUSE_TSUNAMI = 'fa-sharp fa-light fa-house-tsunami',
    HOUSE_TURRET = 'fa-sharp fa-light fa-house-turret',
    HOUSE_USER = 'fa-sharp fa-light fa-house-user',
    HOUSE_WATER = 'fa-sharp fa-light fa-house-water',
    HOUSE_WINDOW = 'fa-sharp fa-light fa-house-window',
    HRYVNIA_SIGN = 'fa-sharp fa-light fa-hryvnia-sign',
    HUNDRED_POINTS = 'fa-sharp fa-light fa-hundred-points',
    HURRICANE = 'fa-sharp fa-light fa-hurricane',
    HYDRA = 'fa-sharp fa-light fa-hydra',
    HYPHEN = 'fa-sharp fa-light fa-hyphen',
    I = 'fa-sharp fa-light fa-i',
    ICE_CREAM = 'fa-sharp fa-light fa-ice-cream',
    ICE_SKATE = 'fa-sharp fa-light fa-ice-skate',
    ICICLES = 'fa-sharp fa-light fa-icicles',
    ICONS = 'fa-sharp fa-light fa-icons',
    ID_BADGE = 'fa-sharp fa-light fa-id-badge',
    ID_CARD = 'fa-sharp fa-light fa-id-card',
    ID_CARD_CLIP = 'fa-sharp fa-light fa-id-card-clip',
    IGLOO = 'fa-sharp fa-light fa-igloo',
    IMAGE = 'fa-sharp fa-light fa-image',
    IMAGES = 'fa-sharp fa-light fa-images',
    IMAGES_USER = 'fa-sharp fa-light fa-images-user',
    IMAGE_LANDSCAPE = 'fa-sharp fa-light fa-image-landscape',
    IMAGE_POLAROID = 'fa-sharp fa-light fa-image-polaroid',
    IMAGE_POLAROID_USER = 'fa-sharp fa-light fa-image-polaroid-user',
    IMAGE_PORTRAIT = 'fa-sharp fa-light fa-image-portrait',
    IMAGE_SLASH = 'fa-sharp fa-light fa-image-slash',
    IMAGE_USER = 'fa-sharp fa-light fa-image-user',
    INBOX = 'fa-sharp fa-light fa-inbox',
    INBOXES = 'fa-sharp fa-light fa-inboxes',
    INBOX_FULL = 'fa-sharp fa-light fa-inbox-full',
    INBOX_IN = 'fa-sharp fa-light fa-inbox-in',
    INBOX_OUT = 'fa-sharp fa-light fa-inbox-out',
    INDENT = 'fa-sharp fa-light fa-indent',
    INDIAN_RUPEE_SIGN = 'fa-sharp fa-light fa-indian-rupee-sign',
    INDUSTRY = 'fa-sharp fa-light fa-industry',
    INDUSTRY_WINDOWS = 'fa-sharp fa-light fa-industry-windows',
    INFINITY = 'fa-sharp fa-light fa-infinity',
    INFO = 'fa-sharp fa-light fa-info',
    INHALER = 'fa-sharp fa-light fa-inhaler',
    INPUT_NUMERIC = 'fa-sharp fa-light fa-input-numeric',
    INPUT_PIPE = 'fa-sharp fa-light fa-input-pipe',
    INPUT_TEXT = 'fa-sharp fa-light fa-input-text',
    INTEGRAL = 'fa-sharp fa-light fa-integral',
    INTERROBANG = 'fa-sharp fa-light fa-interrobang',
    INTERSECTION = 'fa-sharp fa-light fa-intersection',
    ISLAND_TROPICAL = 'fa-sharp fa-light fa-island-tropical',
    ITALIC = 'fa-sharp fa-light fa-italic',
    I_CURSOR = 'fa-sharp fa-light fa-i-cursor',
    J = 'fa-sharp fa-light fa-j',
    JACK_O_LANTERN = 'fa-sharp fa-light fa-jack-o-lantern',
    JAR = 'fa-sharp fa-light fa-jar',
    JAR_WHEAT = 'fa-sharp fa-light fa-jar-wheat',
    JEDI = 'fa-sharp fa-light fa-jedi',
    JET_FIGHTER = 'fa-sharp fa-light fa-jet-fighter',
    JET_FIGHTER_UP = 'fa-sharp fa-light fa-jet-fighter-up',
    JOINT = 'fa-sharp fa-light fa-joint',
    JOYSTICK = 'fa-sharp fa-light fa-joystick',
    JUG = 'fa-sharp fa-light fa-jug',
    JUG_BOTTLE = 'fa-sharp fa-light fa-jug-bottle',
    JUG_DETERGENT = 'fa-sharp fa-light fa-jug-detergent',
    K = 'fa-sharp fa-light fa-k',
    KAABA = 'fa-sharp fa-light fa-kaaba',
    KAZOO = 'fa-sharp fa-light fa-kazoo',
    KERNING = 'fa-sharp fa-light fa-kerning',
    KEY = 'fa-sharp fa-light fa-key',
    KEYBOARD = 'fa-sharp fa-light fa-keyboard',
    KEYBOARD_BRIGHTNESS = 'fa-sharp fa-light fa-keyboard-brightness',
    KEYBOARD_BRIGHTNESS_LOW = 'fa-sharp fa-light fa-keyboard-brightness-low',
    KEYBOARD_DOWN = 'fa-sharp fa-light fa-keyboard-down',
    KEYBOARD_LEFT = 'fa-sharp fa-light fa-keyboard-left',
    KEYNOTE = 'fa-sharp fa-light fa-keynote',
    KEY_SKELETON = 'fa-sharp fa-light fa-key-skeleton',
    KEY_SKELETON_LEFT_RIGHT = 'fa-sharp fa-light fa-key-skeleton-left-right',
    KHANDA = 'fa-sharp fa-light fa-khanda',
    KIDNEYS = 'fa-sharp fa-light fa-kidneys',
    KIP_SIGN = 'fa-sharp fa-light fa-kip-sign',
    KITCHEN_SET = 'fa-sharp fa-light fa-kitchen-set',
    KITE = 'fa-sharp fa-light fa-kite',
    KIT_MEDICAL = 'fa-sharp fa-light fa-kit-medical',
    KIWI_BIRD = 'fa-sharp fa-light fa-kiwi-bird',
    KIWI_FRUIT = 'fa-sharp fa-light fa-kiwi-fruit',
    KNIFE = 'fa-sharp fa-light fa-knife',
    KNIFE_KITCHEN = 'fa-sharp fa-light fa-knife-kitchen',
    L = 'fa-sharp fa-light fa-l',
    LACROSSE_STICK = 'fa-sharp fa-light fa-lacrosse-stick',
    LACROSSE_STICK_BALL = 'fa-sharp fa-light fa-lacrosse-stick-ball',
    LAMBDA = 'fa-sharp fa-light fa-lambda',
    LAMP = 'fa-sharp fa-light fa-lamp',
    LAMP_DESK = 'fa-sharp fa-light fa-lamp-desk',
    LAMP_FLOOR = 'fa-sharp fa-light fa-lamp-floor',
    LAMP_STREET = 'fa-sharp fa-light fa-lamp-street',
    LANDMARK = 'fa-sharp fa-light fa-landmark',
    LANDMARK_DOME = 'fa-sharp fa-light fa-landmark-dome',
    LANDMARK_FLAG = 'fa-sharp fa-light fa-landmark-flag',
    LANDMARK_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-landmark-magnifying-glass',
    LAND_MINE_ON = 'fa-sharp fa-light fa-land-mine-on',
    LANGUAGE = 'fa-sharp fa-light fa-language',
    LAPTOP = 'fa-sharp fa-light fa-laptop',
    LAPTOP_ARROW_DOWN = 'fa-sharp fa-light fa-laptop-arrow-down',
    LAPTOP_BINARY = 'fa-sharp fa-light fa-laptop-binary',
    LAPTOP_CODE = 'fa-sharp fa-light fa-laptop-code',
    LAPTOP_FILE = 'fa-sharp fa-light fa-laptop-file',
    LAPTOP_MEDICAL = 'fa-sharp fa-light fa-laptop-medical',
    LAPTOP_MOBILE = 'fa-sharp fa-light fa-laptop-mobile',
    LAPTOP_SLASH = 'fa-sharp fa-light fa-laptop-slash',
    LARI_SIGN = 'fa-sharp fa-light fa-lari-sign',
    LASSO = 'fa-sharp fa-light fa-lasso',
    LASSO_SPARKLES = 'fa-sharp fa-light fa-lasso-sparkles',
    LAYER_GROUP = 'fa-sharp fa-light fa-layer-group',
    LAYER_MINUS = 'fa-sharp fa-light fa-layer-minus',
    LAYER_PLUS = 'fa-sharp fa-light fa-layer-plus',
    LEAF = 'fa-sharp fa-light fa-leaf',
    LEAFY_GREEN = 'fa-sharp fa-light fa-leafy-green',
    LEAF_HEART = 'fa-sharp fa-light fa-leaf-heart',
    LEAF_MAPLE = 'fa-sharp fa-light fa-leaf-maple',
    LEAF_OAK = 'fa-sharp fa-light fa-leaf-oak',
    LEFT = 'fa-sharp fa-light fa-left',
    LEFT_FROM_BRACKET = 'fa-sharp fa-light fa-left-from-bracket',
    LEFT_FROM_LINE = 'fa-sharp fa-light fa-left-from-line',
    LEFT_LONG = 'fa-sharp fa-light fa-left-long',
    LEFT_LONG_TO_LINE = 'fa-sharp fa-light fa-left-long-to-line',
    LEFT_RIGHT = 'fa-sharp fa-light fa-left-right',
    LEFT_TO_BRACKET = 'fa-sharp fa-light fa-left-to-bracket',
    LEFT_TO_LINE = 'fa-sharp fa-light fa-left-to-line',
    LEMON = 'fa-sharp fa-light fa-lemon',
    LESS_THAN = 'fa-sharp fa-light fa-less-than',
    LESS_THAN_EQUAL = 'fa-sharp fa-light fa-less-than-equal',
    LIFE_RING = 'fa-sharp fa-light fa-life-ring',
    LIGHTBULB = 'fa-sharp fa-light fa-lightbulb',
    LIGHTBULB_CFL = 'fa-sharp fa-light fa-lightbulb-cfl',
    LIGHTBULB_CFL_ON = 'fa-sharp fa-light fa-lightbulb-cfl-on',
    LIGHTBULB_DOLLAR = 'fa-sharp fa-light fa-lightbulb-dollar',
    LIGHTBULB_EXCLAMATION = 'fa-sharp fa-light fa-lightbulb-exclamation',
    LIGHTBULB_EXCLAMATION_ON = 'fa-sharp fa-light fa-lightbulb-exclamation-on',
    LIGHTBULB_GEAR = 'fa-sharp fa-light fa-lightbulb-gear',
    LIGHTBULB_MESSAGE = 'fa-sharp fa-light fa-lightbulb-message',
    LIGHTBULB_ON = 'fa-sharp fa-light fa-lightbulb-on',
    LIGHTBULB_SLASH = 'fa-sharp fa-light fa-lightbulb-slash',
    LIGHTHOUSE = 'fa-sharp fa-light fa-lighthouse',
    LIGHTS_HOLIDAY = 'fa-sharp fa-light fa-lights-holiday',
    LIGHT_CEILING = 'fa-sharp fa-light fa-light-ceiling',
    LIGHT_EMERGENCY = 'fa-sharp fa-light fa-light-emergency',
    LIGHT_EMERGENCY_ON = 'fa-sharp fa-light fa-light-emergency-on',
    LIGHT_SWITCH = 'fa-sharp fa-light fa-light-switch',
    LIGHT_SWITCH_OFF = 'fa-sharp fa-light fa-light-switch-off',
    LIGHT_SWITCH_ON = 'fa-sharp fa-light fa-light-switch-on',
    LINES_LEANING = 'fa-sharp fa-light fa-lines-leaning',
    LINE_COLUMNS = 'fa-sharp fa-light fa-line-columns',
    LINE_HEIGHT = 'fa-sharp fa-light fa-line-height',
    LINK = 'fa-sharp fa-light fa-link',
    LINK_HORIZONTAL = 'fa-sharp fa-light fa-link-horizontal',
    LINK_HORIZONTAL_SLASH = 'fa-sharp fa-light fa-link-horizontal-slash',
    LINK_SIMPLE = 'fa-sharp fa-light fa-link-simple',
    LINK_SIMPLE_SLASH = 'fa-sharp fa-light fa-link-simple-slash',
    LINK_SLASH = 'fa-sharp fa-light fa-link-slash',
    LIPS = 'fa-sharp fa-light fa-lips',
    LIRA_SIGN = 'fa-sharp fa-light fa-lira-sign',
    LIST = 'fa-sharp fa-light fa-list',
    LIST_CHECK = 'fa-sharp fa-light fa-list-check',
    LIST_DROPDOWN = 'fa-sharp fa-light fa-list-dropdown',
    LIST_MUSIC = 'fa-sharp fa-light fa-list-music',
    LIST_OL = 'fa-sharp fa-light fa-list-ol',
    LIST_RADIO = 'fa-sharp fa-light fa-list-radio',
    LIST_TIMELINE = 'fa-sharp fa-light fa-list-timeline',
    LIST_TREE = 'fa-sharp fa-light fa-list-tree',
    LIST_UL = 'fa-sharp fa-light fa-list-ul',
    LITECOIN_SIGN = 'fa-sharp fa-light fa-litecoin-sign',
    LOADER = 'fa-sharp fa-light fa-loader',
    LOBSTER = 'fa-sharp fa-light fa-lobster',
    LOCATION_ARROW = 'fa-sharp fa-light fa-location-arrow',
    LOCATION_ARROW_UP = 'fa-sharp fa-light fa-location-arrow-up',
    LOCATION_CHECK = 'fa-sharp fa-light fa-location-check',
    LOCATION_CROSSHAIRS = 'fa-sharp fa-light fa-location-crosshairs',
    LOCATION_CROSSHAIRS_SLASH = 'fa-sharp fa-light fa-location-crosshairs-slash',
    LOCATION_DOT = 'fa-sharp fa-light fa-location-dot',
    LOCATION_DOT_SLASH = 'fa-sharp fa-light fa-location-dot-slash',
    LOCATION_EXCLAMATION = 'fa-sharp fa-light fa-location-exclamation',
    LOCATION_MINUS = 'fa-sharp fa-light fa-location-minus',
    LOCATION_PEN = 'fa-sharp fa-light fa-location-pen',
    LOCATION_PIN = 'fa-sharp fa-light fa-location-pin',
    LOCATION_PIN_LOCK = 'fa-sharp fa-light fa-location-pin-lock',
    LOCATION_PIN_SLASH = 'fa-sharp fa-light fa-location-pin-slash',
    LOCATION_PLUS = 'fa-sharp fa-light fa-location-plus',
    LOCATION_QUESTION = 'fa-sharp fa-light fa-location-question',
    LOCATION_SMILE = 'fa-sharp fa-light fa-location-smile',
    LOCATION_XMARK = 'fa-sharp fa-light fa-location-xmark',
    LOCK = 'fa-sharp fa-light fa-lock',
    LOCK_A = 'fa-sharp fa-light fa-lock-a',
    LOCK_HASHTAG = 'fa-sharp fa-light fa-lock-hashtag',
    LOCK_KEYHOLE = 'fa-sharp fa-light fa-lock-keyhole',
    LOCK_KEYHOLE_OPEN = 'fa-sharp fa-light fa-lock-keyhole-open',
    LOCK_OPEN = 'fa-sharp fa-light fa-lock-open',
    LOCUST = 'fa-sharp fa-light fa-locust',
    LOLLIPOP = 'fa-sharp fa-light fa-lollipop',
    LOVESEAT = 'fa-sharp fa-light fa-loveseat',
    LUCHADOR_MASK = 'fa-sharp fa-light fa-luchador-mask',
    LUNGS = 'fa-sharp fa-light fa-lungs',
    LUNGS_VIRUS = 'fa-sharp fa-light fa-lungs-virus',
    M = 'fa-sharp fa-light fa-m',
    MACE = 'fa-sharp fa-light fa-mace',
    MAGNET = 'fa-sharp fa-light fa-magnet',
    MAGNIFYING_GLASS = 'fa-sharp fa-light fa-magnifying-glass',
    MAGNIFYING_GLASS_ARROWS_ROTATE = 'fa-sharp fa-light fa-magnifying-glass-arrows-rotate',
    MAGNIFYING_GLASS_ARROW_RIGHT = 'fa-sharp fa-light fa-magnifying-glass-arrow-right',
    MAGNIFYING_GLASS_CHART = 'fa-sharp fa-light fa-magnifying-glass-chart',
    MAGNIFYING_GLASS_DOLLAR = 'fa-sharp fa-light fa-magnifying-glass-dollar',
    MAGNIFYING_GLASS_LOCATION = 'fa-sharp fa-light fa-magnifying-glass-location',
    MAGNIFYING_GLASS_MINUS = 'fa-sharp fa-light fa-magnifying-glass-minus',
    MAGNIFYING_GLASS_MUSIC = 'fa-sharp fa-light fa-magnifying-glass-music',
    MAGNIFYING_GLASS_PLAY = 'fa-sharp fa-light fa-magnifying-glass-play',
    MAGNIFYING_GLASS_PLUS = 'fa-sharp fa-light fa-magnifying-glass-plus',
    MAGNIFYING_GLASS_WAVEFORM = 'fa-sharp fa-light fa-magnifying-glass-waveform',
    MAILBOX = 'fa-sharp fa-light fa-mailbox',
    MAILBOX_FLAG_UP = 'fa-sharp fa-light fa-mailbox-flag-up',
    MANAT_SIGN = 'fa-sharp fa-light fa-manat-sign',
    MANDOLIN = 'fa-sharp fa-light fa-mandolin',
    MANGO = 'fa-sharp fa-light fa-mango',
    MANHOLE = 'fa-sharp fa-light fa-manhole',
    MAP = 'fa-sharp fa-light fa-map',
    MAP_LOCATION = 'fa-sharp fa-light fa-map-location',
    MAP_LOCATION_DOT = 'fa-sharp fa-light fa-map-location-dot',
    MAP_PIN = 'fa-sharp fa-light fa-map-pin',
    MARKER = 'fa-sharp fa-light fa-marker',
    MARS = 'fa-sharp fa-light fa-mars',
    MARS_AND_VENUS = 'fa-sharp fa-light fa-mars-and-venus',
    MARS_AND_VENUS_BURST = 'fa-sharp fa-light fa-mars-and-venus-burst',
    MARS_DOUBLE = 'fa-sharp fa-light fa-mars-double',
    MARS_STROKE = 'fa-sharp fa-light fa-mars-stroke',
    MARS_STROKE_RIGHT = 'fa-sharp fa-light fa-mars-stroke-right',
    MARS_STROKE_UP = 'fa-sharp fa-light fa-mars-stroke-up',
    MARTINI_GLASS = 'fa-sharp fa-light fa-martini-glass',
    MARTINI_GLASS_CITRUS = 'fa-sharp fa-light fa-martini-glass-citrus',
    MARTINI_GLASS_EMPTY = 'fa-sharp fa-light fa-martini-glass-empty',
    MASK = 'fa-sharp fa-light fa-mask',
    MASKS_THEATER = 'fa-sharp fa-light fa-masks-theater',
    MASK_FACE = 'fa-sharp fa-light fa-mask-face',
    MASK_SNORKEL = 'fa-sharp fa-light fa-mask-snorkel',
    MASK_VENTILATOR = 'fa-sharp fa-light fa-mask-ventilator',
    MATTRESS_PILLOW = 'fa-sharp fa-light fa-mattress-pillow',
    MAXIMIZE = 'fa-sharp fa-light fa-maximize',
    MEAT = 'fa-sharp fa-light fa-meat',
    MEDAL = 'fa-sharp fa-light fa-medal',
    MEGAPHONE = 'fa-sharp fa-light fa-megaphone',
    MELON = 'fa-sharp fa-light fa-melon',
    MELON_SLICE = 'fa-sharp fa-light fa-melon-slice',
    MEMO = 'fa-sharp fa-light fa-memo',
    MEMORY = 'fa-sharp fa-light fa-memory',
    MEMO_CIRCLE_CHECK = 'fa-sharp fa-light fa-memo-circle-check',
    MEMO_CIRCLE_INFO = 'fa-sharp fa-light fa-memo-circle-info',
    MEMO_PAD = 'fa-sharp fa-light fa-memo-pad',
    MENORAH = 'fa-sharp fa-light fa-menorah',
    MERCURY = 'fa-sharp fa-light fa-mercury',
    MERGE = 'fa-sharp fa-light fa-merge',
    MESSAGE = 'fa-sharp fa-light fa-message',
    MESSAGES = 'fa-sharp fa-light fa-messages',
    MESSAGES_DOLLAR = 'fa-sharp fa-light fa-messages-dollar',
    MESSAGES_QUESTION = 'fa-sharp fa-light fa-messages-question',
    MESSAGE_ARROW_DOWN = 'fa-sharp fa-light fa-message-arrow-down',
    MESSAGE_ARROW_UP = 'fa-sharp fa-light fa-message-arrow-up',
    MESSAGE_ARROW_UP_RIGHT = 'fa-sharp fa-light fa-message-arrow-up-right',
    MESSAGE_BOT = 'fa-sharp fa-light fa-message-bot',
    MESSAGE_CAPTIONS = 'fa-sharp fa-light fa-message-captions',
    MESSAGE_CHECK = 'fa-sharp fa-light fa-message-check',
    MESSAGE_CODE = 'fa-sharp fa-light fa-message-code',
    MESSAGE_DOLLAR = 'fa-sharp fa-light fa-message-dollar',
    MESSAGE_DOTS = 'fa-sharp fa-light fa-message-dots',
    MESSAGE_EXCLAMATION = 'fa-sharp fa-light fa-message-exclamation',
    MESSAGE_HEART = 'fa-sharp fa-light fa-message-heart',
    MESSAGE_IMAGE = 'fa-sharp fa-light fa-message-image',
    MESSAGE_LINES = 'fa-sharp fa-light fa-message-lines',
    MESSAGE_MEDICAL = 'fa-sharp fa-light fa-message-medical',
    MESSAGE_MIDDLE = 'fa-sharp fa-light fa-message-middle',
    MESSAGE_MIDDLE_TOP = 'fa-sharp fa-light fa-message-middle-top',
    MESSAGE_MINUS = 'fa-sharp fa-light fa-message-minus',
    MESSAGE_MUSIC = 'fa-sharp fa-light fa-message-music',
    MESSAGE_PEN = 'fa-sharp fa-light fa-message-pen',
    MESSAGE_PLUS = 'fa-sharp fa-light fa-message-plus',
    MESSAGE_QUESTION = 'fa-sharp fa-light fa-message-question',
    MESSAGE_QUOTE = 'fa-sharp fa-light fa-message-quote',
    MESSAGE_SLASH = 'fa-sharp fa-light fa-message-slash',
    MESSAGE_SMILE = 'fa-sharp fa-light fa-message-smile',
    MESSAGE_SMS = 'fa-sharp fa-light fa-message-sms',
    MESSAGE_TEXT = 'fa-sharp fa-light fa-message-text',
    MESSAGE_XMARK = 'fa-sharp fa-light fa-message-xmark',
    METEOR = 'fa-sharp fa-light fa-meteor',
    METER = 'fa-sharp fa-light fa-meter',
    METER_BOLT = 'fa-sharp fa-light fa-meter-bolt',
    METER_DROPLET = 'fa-sharp fa-light fa-meter-droplet',
    METER_FIRE = 'fa-sharp fa-light fa-meter-fire',
    MICROCHIP = 'fa-sharp fa-light fa-microchip',
    MICROCHIP_AI = 'fa-sharp fa-light fa-microchip-ai',
    MICROPHONE = 'fa-sharp fa-light fa-microphone',
    MICROPHONE_LINES = 'fa-sharp fa-light fa-microphone-lines',
    MICROPHONE_LINES_SLASH = 'fa-sharp fa-light fa-microphone-lines-slash',
    MICROPHONE_SLASH = 'fa-sharp fa-light fa-microphone-slash',
    MICROPHONE_STAND = 'fa-sharp fa-light fa-microphone-stand',
    MICROSCOPE = 'fa-sharp fa-light fa-microscope',
    MICROWAVE = 'fa-sharp fa-light fa-microwave',
    MILL_SIGN = 'fa-sharp fa-light fa-mill-sign',
    MINIMIZE = 'fa-sharp fa-light fa-minimize',
    MINUS = 'fa-sharp fa-light fa-minus',
    MISTLETOE = 'fa-sharp fa-light fa-mistletoe',
    MITTEN = 'fa-sharp fa-light fa-mitten',
    MOBILE = 'fa-sharp fa-light fa-mobile',
    MOBILE_BUTTON = 'fa-sharp fa-light fa-mobile-button',
    MOBILE_NOTCH = 'fa-sharp fa-light fa-mobile-notch',
    MOBILE_RETRO = 'fa-sharp fa-light fa-mobile-retro',
    MOBILE_SCREEN = 'fa-sharp fa-light fa-mobile-screen',
    MOBILE_SCREEN_BUTTON = 'fa-sharp fa-light fa-mobile-screen-button',
    MOBILE_SIGNAL = 'fa-sharp fa-light fa-mobile-signal',
    MOBILE_SIGNAL_OUT = 'fa-sharp fa-light fa-mobile-signal-out',
    MONEY_BILL = 'fa-sharp fa-light fa-money-bill',
    MONEY_BILLS = 'fa-sharp fa-light fa-money-bills',
    MONEY_BILLS_SIMPLE = 'fa-sharp fa-light fa-money-bills-simple',
    MONEY_BILL_1 = 'fa-sharp fa-light fa-money-bill-1',
    MONEY_BILL_1_WAVE = 'fa-sharp fa-light fa-money-bill-1-wave',
    MONEY_BILL_SIMPLE = 'fa-sharp fa-light fa-money-bill-simple',
    MONEY_BILL_SIMPLE_WAVE = 'fa-sharp fa-light fa-money-bill-simple-wave',
    MONEY_BILL_TRANSFER = 'fa-sharp fa-light fa-money-bill-transfer',
    MONEY_BILL_TREND_UP = 'fa-sharp fa-light fa-money-bill-trend-up',
    MONEY_BILL_WAVE = 'fa-sharp fa-light fa-money-bill-wave',
    MONEY_BILL_WHEAT = 'fa-sharp fa-light fa-money-bill-wheat',
    MONEY_CHECK = 'fa-sharp fa-light fa-money-check',
    MONEY_CHECK_DOLLAR = 'fa-sharp fa-light fa-money-check-dollar',
    MONEY_CHECK_DOLLAR_PEN = 'fa-sharp fa-light fa-money-check-dollar-pen',
    MONEY_CHECK_PEN = 'fa-sharp fa-light fa-money-check-pen',
    MONEY_FROM_BRACKET = 'fa-sharp fa-light fa-money-from-bracket',
    MONEY_SIMPLE_FROM_BRACKET = 'fa-sharp fa-light fa-money-simple-from-bracket',
    MONITOR_WAVEFORM = 'fa-sharp fa-light fa-monitor-waveform',
    MONKEY = 'fa-sharp fa-light fa-monkey',
    MONUMENT = 'fa-sharp fa-light fa-monument',
    MOON = 'fa-sharp fa-light fa-moon',
    MOON_CLOUD = 'fa-sharp fa-light fa-moon-cloud',
    MOON_OVER_SUN = 'fa-sharp fa-light fa-moon-over-sun',
    MOON_STARS = 'fa-sharp fa-light fa-moon-stars',
    MOPED = 'fa-sharp fa-light fa-moped',
    MORTAR_PESTLE = 'fa-sharp fa-light fa-mortar-pestle',
    MOSQUE = 'fa-sharp fa-light fa-mosque',
    MOSQUITO = 'fa-sharp fa-light fa-mosquito',
    MOSQUITO_NET = 'fa-sharp fa-light fa-mosquito-net',
    MOTORCYCLE = 'fa-sharp fa-light fa-motorcycle',
    MOUND = 'fa-sharp fa-light fa-mound',
    MOUNTAIN = 'fa-sharp fa-light fa-mountain',
    MOUNTAINS = 'fa-sharp fa-light fa-mountains',
    MOUNTAIN_CITY = 'fa-sharp fa-light fa-mountain-city',
    MOUNTAIN_SUN = 'fa-sharp fa-light fa-mountain-sun',
    MOUSE_FIELD = 'fa-sharp fa-light fa-mouse-field',
    MP3_PLAYER = 'fa-sharp fa-light fa-mp3-player',
    MUG = 'fa-sharp fa-light fa-mug',
    MUG_HOT = 'fa-sharp fa-light fa-mug-hot',
    MUG_MARSHMALLOWS = 'fa-sharp fa-light fa-mug-marshmallows',
    MUG_SAUCER = 'fa-sharp fa-light fa-mug-saucer',
    MUG_TEA = 'fa-sharp fa-light fa-mug-tea',
    MUG_TEA_SAUCER = 'fa-sharp fa-light fa-mug-tea-saucer',
    MUSHROOM = 'fa-sharp fa-light fa-mushroom',
    MUSIC = 'fa-sharp fa-light fa-music',
    MUSIC_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-music-magnifying-glass',
    MUSIC_NOTE = 'fa-sharp fa-light fa-music-note',
    MUSIC_NOTE_SLASH = 'fa-sharp fa-light fa-music-note-slash',
    MUSIC_SLASH = 'fa-sharp fa-light fa-music-slash',
    MUSTACHE = 'fa-sharp fa-light fa-mustache',
    N = 'fa-sharp fa-light fa-n',
    NAIRA_SIGN = 'fa-sharp fa-light fa-naira-sign',
    NARWHAL = 'fa-sharp fa-light fa-narwhal',
    NESTING_DOLLS = 'fa-sharp fa-light fa-nesting-dolls',
    NETWORK_WIRED = 'fa-sharp fa-light fa-network-wired',
    NEUTER = 'fa-sharp fa-light fa-neuter',
    NEWSPAPER = 'fa-sharp fa-light fa-newspaper',
    NFC = 'fa-sharp fa-light fa-nfc',
    NFC_LOCK = 'fa-sharp fa-light fa-nfc-lock',
    NFC_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-nfc-magnifying-glass',
    NFC_PEN = 'fa-sharp fa-light fa-nfc-pen',
    NFC_SIGNAL = 'fa-sharp fa-light fa-nfc-signal',
    NFC_SLASH = 'fa-sharp fa-light fa-nfc-slash',
    NFC_TRASH = 'fa-sharp fa-light fa-nfc-trash',
    NOSE = 'fa-sharp fa-light fa-nose',
    NOTDEF = 'fa-sharp fa-light fa-notdef',
    NOTE = 'fa-sharp fa-light fa-note',
    NOTEBOOK = 'fa-sharp fa-light fa-notebook',
    NOTES = 'fa-sharp fa-light fa-notes',
    NOTES_MEDICAL = 'fa-sharp fa-light fa-notes-medical',
    NOTE_MEDICAL = 'fa-sharp fa-light fa-note-medical',
    NOTE_STICKY = 'fa-sharp fa-light fa-note-sticky',
    NOT_EQUAL = 'fa-sharp fa-light fa-not-equal',
    NUMBER_0 = 'fa-sharp fa-light fa-0',
    NUMBER_00 = 'fa-sharp fa-light fa-00',
    NUMBER_1 = 'fa-sharp fa-light fa-1',
    NUMBER_2 = 'fa-sharp fa-light fa-2',
    NUMBER_3 = 'fa-sharp fa-light fa-3',
    NUMBER_360_DEGREES = 'fa-sharp fa-light fa-360-degrees',
    NUMBER_4 = 'fa-sharp fa-light fa-4',
    NUMBER_5 = 'fa-sharp fa-light fa-5',
    NUMBER_6 = 'fa-sharp fa-light fa-6',
    NUMBER_7 = 'fa-sharp fa-light fa-7',
    NUMBER_8 = 'fa-sharp fa-light fa-8',
    NUMBER_9 = 'fa-sharp fa-light fa-9',
    O = 'fa-sharp fa-light fa-o',
    OBJECTS_ALIGN_BOTTOM = 'fa-sharp fa-light fa-objects-align-bottom',
    OBJECTS_ALIGN_CENTER_HORIZONTAL = 'fa-sharp fa-light fa-objects-align-center-horizontal',
    OBJECTS_ALIGN_CENTER_VERTICAL = 'fa-sharp fa-light fa-objects-align-center-vertical',
    OBJECTS_ALIGN_LEFT = 'fa-sharp fa-light fa-objects-align-left',
    OBJECTS_ALIGN_RIGHT = 'fa-sharp fa-light fa-objects-align-right',
    OBJECTS_ALIGN_TOP = 'fa-sharp fa-light fa-objects-align-top',
    OBJECTS_COLUMN = 'fa-sharp fa-light fa-objects-column',
    OBJECT_EXCLUDE = 'fa-sharp fa-light fa-object-exclude',
    OBJECT_GROUP = 'fa-sharp fa-light fa-object-group',
    OBJECT_INTERSECT = 'fa-sharp fa-light fa-object-intersect',
    OBJECT_SUBTRACT = 'fa-sharp fa-light fa-object-subtract',
    OBJECT_UNGROUP = 'fa-sharp fa-light fa-object-ungroup',
    OBJECT_UNION = 'fa-sharp fa-light fa-object-union',
    OCTAGON = 'fa-sharp fa-light fa-octagon',
    OCTAGON_CHECK = 'fa-sharp fa-light fa-octagon-check',
    OCTAGON_DIVIDE = 'fa-sharp fa-light fa-octagon-divide',
    OCTAGON_EXCLAMATION = 'fa-sharp fa-light fa-octagon-exclamation',
    OCTAGON_MINUS = 'fa-sharp fa-light fa-octagon-minus',
    OCTAGON_PLUS = 'fa-sharp fa-light fa-octagon-plus',
    OCTAGON_XMARK = 'fa-sharp fa-light fa-octagon-xmark',
    OCTOPUS = 'fa-sharp fa-light fa-octopus',
    OIL_CAN = 'fa-sharp fa-light fa-oil-can',
    OIL_CAN_DRIP = 'fa-sharp fa-light fa-oil-can-drip',
    OIL_TEMPERATURE = 'fa-sharp fa-light fa-oil-temperature',
    OIL_WELL = 'fa-sharp fa-light fa-oil-well',
    OLIVE = 'fa-sharp fa-light fa-olive',
    OLIVE_BRANCH = 'fa-sharp fa-light fa-olive-branch',
    OM = 'fa-sharp fa-light fa-om',
    OMEGA = 'fa-sharp fa-light fa-omega',
    ONION = 'fa-sharp fa-light fa-onion',
    OPTION = 'fa-sharp fa-light fa-option',
    ORNAMENT = 'fa-sharp fa-light fa-ornament',
    OTTER = 'fa-sharp fa-light fa-otter',
    OUTDENT = 'fa-sharp fa-light fa-outdent',
    OUTLET = 'fa-sharp fa-light fa-outlet',
    OVEN = 'fa-sharp fa-light fa-oven',
    OVERLINE = 'fa-sharp fa-light fa-overline',
    P = 'fa-sharp fa-light fa-p',
    PAGE = 'fa-sharp fa-light fa-page',
    PAGER = 'fa-sharp fa-light fa-pager',
    PAGE_CARET_DOWN = 'fa-sharp fa-light fa-page-caret-down',
    PAGE_CARET_UP = 'fa-sharp fa-light fa-page-caret-up',
    PAINTBRUSH = 'fa-sharp fa-light fa-paintbrush',
    PAINTBRUSH_FINE = 'fa-sharp fa-light fa-paintbrush-fine',
    PAINTBRUSH_PENCIL = 'fa-sharp fa-light fa-paintbrush-pencil',
    PAINT_ROLLER = 'fa-sharp fa-light fa-paint-roller',
    PALETTE = 'fa-sharp fa-light fa-palette',
    PALLET = 'fa-sharp fa-light fa-pallet',
    PALLET_BOX = 'fa-sharp fa-light fa-pallet-box',
    PALLET_BOXES = 'fa-sharp fa-light fa-pallet-boxes',
    PANCAKES = 'fa-sharp fa-light fa-pancakes',
    PANEL_EWS = 'fa-sharp fa-light fa-panel-ews',
    PANEL_FIRE = 'fa-sharp fa-light fa-panel-fire',
    PANORAMA = 'fa-sharp fa-light fa-panorama',
    PAN_FOOD = 'fa-sharp fa-light fa-pan-food',
    PAN_FRYING = 'fa-sharp fa-light fa-pan-frying',
    PAPERCLIP = 'fa-sharp fa-light fa-paperclip',
    PAPERCLIP_VERTICAL = 'fa-sharp fa-light fa-paperclip-vertical',
    PAPER_PLANE = 'fa-sharp fa-light fa-paper-plane',
    PAPER_PLANE_TOP = 'fa-sharp fa-light fa-paper-plane-top',
    PARACHUTE_BOX = 'fa-sharp fa-light fa-parachute-box',
    PARAGRAPH = 'fa-sharp fa-light fa-paragraph',
    PARAGRAPH_LEFT = 'fa-sharp fa-light fa-paragraph-left',
    PARTY_BELL = 'fa-sharp fa-light fa-party-bell',
    PARTY_HORN = 'fa-sharp fa-light fa-party-horn',
    PASSPORT = 'fa-sharp fa-light fa-passport',
    PASTE = 'fa-sharp fa-light fa-paste',
    PAUSE = 'fa-sharp fa-light fa-pause',
    PAW = 'fa-sharp fa-light fa-paw',
    PAW_CLAWS = 'fa-sharp fa-light fa-paw-claws',
    PAW_SIMPLE = 'fa-sharp fa-light fa-paw-simple',
    PEACE = 'fa-sharp fa-light fa-peace',
    PEACH = 'fa-sharp fa-light fa-peach',
    PEANUT = 'fa-sharp fa-light fa-peanut',
    PEANUTS = 'fa-sharp fa-light fa-peanuts',
    PEAPOD = 'fa-sharp fa-light fa-peapod',
    PEAR = 'fa-sharp fa-light fa-pear',
    PEDESTAL = 'fa-sharp fa-light fa-pedestal',
    PEGASUS = 'fa-sharp fa-light fa-pegasus',
    PEN = 'fa-sharp fa-light fa-pen',
    PENCIL = 'fa-sharp fa-light fa-pencil',
    PENCIL_MECHANICAL = 'fa-sharp fa-light fa-pencil-mechanical',
    PENCIL_SLASH = 'fa-sharp fa-light fa-pencil-slash',
    PEN_CIRCLE = 'fa-sharp fa-light fa-pen-circle',
    PEN_CLIP = 'fa-sharp fa-light fa-pen-clip',
    PEN_CLIP_SLASH = 'fa-sharp fa-light fa-pen-clip-slash',
    PEN_FANCY = 'fa-sharp fa-light fa-pen-fancy',
    PEN_FANCY_SLASH = 'fa-sharp fa-light fa-pen-fancy-slash',
    PEN_FIELD = 'fa-sharp fa-light fa-pen-field',
    PEN_LINE = 'fa-sharp fa-light fa-pen-line',
    PEN_NIB = 'fa-sharp fa-light fa-pen-nib',
    PEN_NIB_SLASH = 'fa-sharp fa-light fa-pen-nib-slash',
    PEN_PAINTBRUSH = 'fa-sharp fa-light fa-pen-paintbrush',
    PEN_RULER = 'fa-sharp fa-light fa-pen-ruler',
    PEN_SLASH = 'fa-sharp fa-light fa-pen-slash',
    PEN_SWIRL = 'fa-sharp fa-light fa-pen-swirl',
    PEN_TO_SQUARE = 'fa-sharp fa-light fa-pen-to-square',
    PEOPLE = 'fa-sharp fa-light fa-people',
    PEOPLE_ARROWS = 'fa-sharp fa-light fa-people-arrows',
    PEOPLE_CARRY_BOX = 'fa-sharp fa-light fa-people-carry-box',
    PEOPLE_DRESS = 'fa-sharp fa-light fa-people-dress',
    PEOPLE_DRESS_SIMPLE = 'fa-sharp fa-light fa-people-dress-simple',
    PEOPLE_GROUP = 'fa-sharp fa-light fa-people-group',
    PEOPLE_LINE = 'fa-sharp fa-light fa-people-line',
    PEOPLE_PANTS = 'fa-sharp fa-light fa-people-pants',
    PEOPLE_PANTS_SIMPLE = 'fa-sharp fa-light fa-people-pants-simple',
    PEOPLE_PULLING = 'fa-sharp fa-light fa-people-pulling',
    PEOPLE_ROBBERY = 'fa-sharp fa-light fa-people-robbery',
    PEOPLE_ROOF = 'fa-sharp fa-light fa-people-roof',
    PEOPLE_SIMPLE = 'fa-sharp fa-light fa-people-simple',
    PEPPER = 'fa-sharp fa-light fa-pepper',
    PEPPER_HOT = 'fa-sharp fa-light fa-pepper-hot',
    PERCENT = 'fa-sharp fa-light fa-percent',
    PERIOD = 'fa-sharp fa-light fa-period',
    PERSON = 'fa-sharp fa-light fa-person',
    PERSON_ARROW_DOWN_TO_LINE = 'fa-sharp fa-light fa-person-arrow-down-to-line',
    PERSON_ARROW_UP_FROM_LINE = 'fa-sharp fa-light fa-person-arrow-up-from-line',
    PERSON_BIKING = 'fa-sharp fa-light fa-person-biking',
    PERSON_BIKING_MOUNTAIN = 'fa-sharp fa-light fa-person-biking-mountain',
    PERSON_BOOTH = 'fa-sharp fa-light fa-person-booth',
    PERSON_BREASTFEEDING = 'fa-sharp fa-light fa-person-breastfeeding',
    PERSON_BURST = 'fa-sharp fa-light fa-person-burst',
    PERSON_CANE = 'fa-sharp fa-light fa-person-cane',
    PERSON_CARRY_BOX = 'fa-sharp fa-light fa-person-carry-box',
    PERSON_CHALKBOARD = 'fa-sharp fa-light fa-person-chalkboard',
    PERSON_CIRCLE_CHECK = 'fa-sharp fa-light fa-person-circle-check',
    PERSON_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-person-circle-exclamation',
    PERSON_CIRCLE_MINUS = 'fa-sharp fa-light fa-person-circle-minus',
    PERSON_CIRCLE_PLUS = 'fa-sharp fa-light fa-person-circle-plus',
    PERSON_CIRCLE_QUESTION = 'fa-sharp fa-light fa-person-circle-question',
    PERSON_CIRCLE_XMARK = 'fa-sharp fa-light fa-person-circle-xmark',
    PERSON_DIGGING = 'fa-sharp fa-light fa-person-digging',
    PERSON_DOLLY = 'fa-sharp fa-light fa-person-dolly',
    PERSON_DOLLY_EMPTY = 'fa-sharp fa-light fa-person-dolly-empty',
    PERSON_DOTS_FROM_LINE = 'fa-sharp fa-light fa-person-dots-from-line',
    PERSON_DRESS = 'fa-sharp fa-light fa-person-dress',
    PERSON_DRESS_BURST = 'fa-sharp fa-light fa-person-dress-burst',
    PERSON_DRESS_FAIRY = 'fa-sharp fa-light fa-person-dress-fairy',
    PERSON_DRESS_SIMPLE = 'fa-sharp fa-light fa-person-dress-simple',
    PERSON_DROWNING = 'fa-sharp fa-light fa-person-drowning',
    PERSON_FAIRY = 'fa-sharp fa-light fa-person-fairy',
    PERSON_FALLING = 'fa-sharp fa-light fa-person-falling',
    PERSON_FALLING_BURST = 'fa-sharp fa-light fa-person-falling-burst',
    PERSON_FROM_PORTAL = 'fa-sharp fa-light fa-person-from-portal',
    PERSON_HALF_DRESS = 'fa-sharp fa-light fa-person-half-dress',
    PERSON_HARASSING = 'fa-sharp fa-light fa-person-harassing',
    PERSON_HIKING = 'fa-sharp fa-light fa-person-hiking',
    PERSON_MILITARY_POINTING = 'fa-sharp fa-light fa-person-military-pointing',
    PERSON_MILITARY_RIFLE = 'fa-sharp fa-light fa-person-military-rifle',
    PERSON_MILITARY_TO_PERSON = 'fa-sharp fa-light fa-person-military-to-person',
    PERSON_PINBALL = 'fa-sharp fa-light fa-person-pinball',
    PERSON_PRAYING = 'fa-sharp fa-light fa-person-praying',
    PERSON_PREGNANT = 'fa-sharp fa-light fa-person-pregnant',
    PERSON_RAYS = 'fa-sharp fa-light fa-person-rays',
    PERSON_RIFLE = 'fa-sharp fa-light fa-person-rifle',
    PERSON_RUNNING = 'fa-sharp fa-light fa-person-running',
    PERSON_RUNNING_FAST = 'fa-sharp fa-light fa-person-running-fast',
    PERSON_SEAT = 'fa-sharp fa-light fa-person-seat',
    PERSON_SEAT_RECLINED = 'fa-sharp fa-light fa-person-seat-reclined',
    PERSON_SHELTER = 'fa-sharp fa-light fa-person-shelter',
    PERSON_SIGN = 'fa-sharp fa-light fa-person-sign',
    PERSON_SIMPLE = 'fa-sharp fa-light fa-person-simple',
    PERSON_SKATING = 'fa-sharp fa-light fa-person-skating',
    PERSON_SKIING = 'fa-sharp fa-light fa-person-skiing',
    PERSON_SKIING_NORDIC = 'fa-sharp fa-light fa-person-skiing-nordic',
    PERSON_SKI_JUMPING = 'fa-sharp fa-light fa-person-ski-jumping',
    PERSON_SKI_LIFT = 'fa-sharp fa-light fa-person-ski-lift',
    PERSON_SLEDDING = 'fa-sharp fa-light fa-person-sledding',
    PERSON_SNOWBOARDING = 'fa-sharp fa-light fa-person-snowboarding',
    PERSON_SNOWMOBILING = 'fa-sharp fa-light fa-person-snowmobiling',
    PERSON_SWIMMING = 'fa-sharp fa-light fa-person-swimming',
    PERSON_THROUGH_WINDOW = 'fa-sharp fa-light fa-person-through-window',
    PERSON_TO_DOOR = 'fa-sharp fa-light fa-person-to-door',
    PERSON_TO_PORTAL = 'fa-sharp fa-light fa-person-to-portal',
    PERSON_WALKING = 'fa-sharp fa-light fa-person-walking',
    PERSON_WALKING_ARROW_LOOP_LEFT = 'fa-sharp fa-light fa-person-walking-arrow-loop-left',
    PERSON_WALKING_ARROW_RIGHT = 'fa-sharp fa-light fa-person-walking-arrow-right',
    PERSON_WALKING_DASHED_LINE_ARROW_RIGHT = 'fa-sharp fa-light fa-person-walking-dashed-line-arrow-right',
    PERSON_WALKING_LUGGAGE = 'fa-sharp fa-light fa-person-walking-luggage',
    PERSON_WALKING_WITH_CANE = 'fa-sharp fa-light fa-person-walking-with-cane',
    PESETA_SIGN = 'fa-sharp fa-light fa-peseta-sign',
    PESO_SIGN = 'fa-sharp fa-light fa-peso-sign',
    PHONE = 'fa-sharp fa-light fa-phone',
    PHONE_ARROW_DOWN_LEFT = 'fa-sharp fa-light fa-phone-arrow-down-left',
    PHONE_ARROW_RIGHT = 'fa-sharp fa-light fa-phone-arrow-right',
    PHONE_ARROW_UP_RIGHT = 'fa-sharp fa-light fa-phone-arrow-up-right',
    PHONE_FLIP = 'fa-sharp fa-light fa-phone-flip',
    PHONE_HANGUP = 'fa-sharp fa-light fa-phone-hangup',
    PHONE_INTERCOM = 'fa-sharp fa-light fa-phone-intercom',
    PHONE_MISSED = 'fa-sharp fa-light fa-phone-missed',
    PHONE_OFFICE = 'fa-sharp fa-light fa-phone-office',
    PHONE_PLUS = 'fa-sharp fa-light fa-phone-plus',
    PHONE_ROTARY = 'fa-sharp fa-light fa-phone-rotary',
    PHONE_SLASH = 'fa-sharp fa-light fa-phone-slash',
    PHONE_VOLUME = 'fa-sharp fa-light fa-phone-volume',
    PHONE_XMARK = 'fa-sharp fa-light fa-phone-xmark',
    PHOTO_FILM = 'fa-sharp fa-light fa-photo-film',
    PHOTO_FILM_MUSIC = 'fa-sharp fa-light fa-photo-film-music',
    PI = 'fa-sharp fa-light fa-pi',
    PIANO = 'fa-sharp fa-light fa-piano',
    PIANO_KEYBOARD = 'fa-sharp fa-light fa-piano-keyboard',
    PICKAXE = 'fa-sharp fa-light fa-pickaxe',
    PICKLEBALL = 'fa-sharp fa-light fa-pickleball',
    PIE = 'fa-sharp fa-light fa-pie',
    PIG = 'fa-sharp fa-light fa-pig',
    PIGGY_BANK = 'fa-sharp fa-light fa-piggy-bank',
    PILLS = 'fa-sharp fa-light fa-pills',
    PINATA = 'fa-sharp fa-light fa-pinata',
    PINBALL = 'fa-sharp fa-light fa-pinball',
    PINEAPPLE = 'fa-sharp fa-light fa-pineapple',
    PIPE = 'fa-sharp fa-light fa-pipe',
    PIPE_CIRCLE_CHECK = 'fa-sharp fa-light fa-pipe-circle-check',
    PIPE_COLLAR = 'fa-sharp fa-light fa-pipe-collar',
    PIPE_SECTION = 'fa-sharp fa-light fa-pipe-section',
    PIPE_SMOKING = 'fa-sharp fa-light fa-pipe-smoking',
    PIPE_VALVE = 'fa-sharp fa-light fa-pipe-valve',
    PIZZA = 'fa-sharp fa-light fa-pizza',
    PIZZA_SLICE = 'fa-sharp fa-light fa-pizza-slice',
    PLACE_OF_WORSHIP = 'fa-sharp fa-light fa-place-of-worship',
    PLANE = 'fa-sharp fa-light fa-plane',
    PLANET_MOON = 'fa-sharp fa-light fa-planet-moon',
    PLANET_RINGED = 'fa-sharp fa-light fa-planet-ringed',
    PLANE_ARRIVAL = 'fa-sharp fa-light fa-plane-arrival',
    PLANE_CIRCLE_CHECK = 'fa-sharp fa-light fa-plane-circle-check',
    PLANE_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-plane-circle-exclamation',
    PLANE_CIRCLE_XMARK = 'fa-sharp fa-light fa-plane-circle-xmark',
    PLANE_DEPARTURE = 'fa-sharp fa-light fa-plane-departure',
    PLANE_ENGINES = 'fa-sharp fa-light fa-plane-engines',
    PLANE_LOCK = 'fa-sharp fa-light fa-plane-lock',
    PLANE_PROP = 'fa-sharp fa-light fa-plane-prop',
    PLANE_SLASH = 'fa-sharp fa-light fa-plane-slash',
    PLANE_TAIL = 'fa-sharp fa-light fa-plane-tail',
    PLANE_UP = 'fa-sharp fa-light fa-plane-up',
    PLANE_UP_SLASH = 'fa-sharp fa-light fa-plane-up-slash',
    PLANT_WILT = 'fa-sharp fa-light fa-plant-wilt',
    PLATE_UTENSILS = 'fa-sharp fa-light fa-plate-utensils',
    PLATE_WHEAT = 'fa-sharp fa-light fa-plate-wheat',
    PLAY = 'fa-sharp fa-light fa-play',
    PLAY_PAUSE = 'fa-sharp fa-light fa-play-pause',
    PLUG = 'fa-sharp fa-light fa-plug',
    PLUG_CIRCLE_BOLT = 'fa-sharp fa-light fa-plug-circle-bolt',
    PLUG_CIRCLE_CHECK = 'fa-sharp fa-light fa-plug-circle-check',
    PLUG_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-plug-circle-exclamation',
    PLUG_CIRCLE_MINUS = 'fa-sharp fa-light fa-plug-circle-minus',
    PLUG_CIRCLE_PLUS = 'fa-sharp fa-light fa-plug-circle-plus',
    PLUG_CIRCLE_XMARK = 'fa-sharp fa-light fa-plug-circle-xmark',
    PLUS = 'fa-sharp fa-light fa-plus',
    PLUS_LARGE = 'fa-sharp fa-light fa-plus-large',
    PLUS_MINUS = 'fa-sharp fa-light fa-plus-minus',
    PODCAST = 'fa-sharp fa-light fa-podcast',
    PODIUM = 'fa-sharp fa-light fa-podium',
    PODIUM_STAR = 'fa-sharp fa-light fa-podium-star',
    POLICE_BOX = 'fa-sharp fa-light fa-police-box',
    POLL_PEOPLE = 'fa-sharp fa-light fa-poll-people',
    POMPEBLED = 'fa-sharp fa-light fa-pompebled',
    POO = 'fa-sharp fa-light fa-poo',
    POOL_8_BALL = 'fa-sharp fa-light fa-pool-8-ball',
    POOP = 'fa-sharp fa-light fa-poop',
    POO_STORM = 'fa-sharp fa-light fa-poo-storm',
    POPCORN = 'fa-sharp fa-light fa-popcorn',
    POPSICLE = 'fa-sharp fa-light fa-popsicle',
    POTATO = 'fa-sharp fa-light fa-potato',
    POT_FOOD = 'fa-sharp fa-light fa-pot-food',
    POWER_OFF = 'fa-sharp fa-light fa-power-off',
    PRESCRIPTION = 'fa-sharp fa-light fa-prescription',
    PRESCRIPTION_BOTTLE = 'fa-sharp fa-light fa-prescription-bottle',
    PRESCRIPTION_BOTTLE_MEDICAL = 'fa-sharp fa-light fa-prescription-bottle-medical',
    PRESCRIPTION_BOTTLE_PILL = 'fa-sharp fa-light fa-prescription-bottle-pill',
    PRESENTATION_SCREEN = 'fa-sharp fa-light fa-presentation-screen',
    PRETZEL = 'fa-sharp fa-light fa-pretzel',
    PRINT = 'fa-sharp fa-light fa-print',
    PRINT_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-print-magnifying-glass',
    PRINT_SLASH = 'fa-sharp fa-light fa-print-slash',
    PROJECTOR = 'fa-sharp fa-light fa-projector',
    PUMP = 'fa-sharp fa-light fa-pump',
    PUMPKIN = 'fa-sharp fa-light fa-pumpkin',
    PUMP_MEDICAL = 'fa-sharp fa-light fa-pump-medical',
    PUMP_SOAP = 'fa-sharp fa-light fa-pump-soap',
    PUZZLE = 'fa-sharp fa-light fa-puzzle',
    PUZZLE_PIECE = 'fa-sharp fa-light fa-puzzle-piece',
    PUZZLE_PIECE_SIMPLE = 'fa-sharp fa-light fa-puzzle-piece-simple',
    Q = 'fa-sharp fa-light fa-q',
    QRCODE = 'fa-sharp fa-light fa-qrcode',
    QUESTION = 'fa-sharp fa-light fa-question',
    QUOTES = 'fa-sharp fa-light fa-quotes',
    QUOTE_LEFT = 'fa-sharp fa-light fa-quote-left',
    QUOTE_RIGHT = 'fa-sharp fa-light fa-quote-right',
    R = 'fa-sharp fa-light fa-r',
    RABBIT = 'fa-sharp fa-light fa-rabbit',
    RABBIT_RUNNING = 'fa-sharp fa-light fa-rabbit-running',
    RACCOON = 'fa-sharp fa-light fa-raccoon',
    RACQUET = 'fa-sharp fa-light fa-racquet',
    RADAR = 'fa-sharp fa-light fa-radar',
    RADIATION = 'fa-sharp fa-light fa-radiation',
    RADIO = 'fa-sharp fa-light fa-radio',
    RADIO_TUNER = 'fa-sharp fa-light fa-radio-tuner',
    RAINBOW = 'fa-sharp fa-light fa-rainbow',
    RAINDROPS = 'fa-sharp fa-light fa-raindrops',
    RAM = 'fa-sharp fa-light fa-ram',
    RAMP_LOADING = 'fa-sharp fa-light fa-ramp-loading',
    RANKING_STAR = 'fa-sharp fa-light fa-ranking-star',
    RAYGUN = 'fa-sharp fa-light fa-raygun',
    RECEIPT = 'fa-sharp fa-light fa-receipt',
    RECORD_VINYL = 'fa-sharp fa-light fa-record-vinyl',
    RECTANGLE = 'fa-sharp fa-light fa-rectangle',
    RECTANGLES_MIXED = 'fa-sharp fa-light fa-rectangles-mixed',
    RECTANGLE_AD = 'fa-sharp fa-light fa-rectangle-ad',
    RECTANGLE_BARCODE = 'fa-sharp fa-light fa-rectangle-barcode',
    RECTANGLE_CODE = 'fa-sharp fa-light fa-rectangle-code',
    RECTANGLE_HISTORY = 'fa-sharp fa-light fa-rectangle-history',
    RECTANGLE_HISTORY_CIRCLE_PLUS = 'fa-sharp fa-light fa-rectangle-history-circle-plus',
    RECTANGLE_HISTORY_CIRCLE_USER = 'fa-sharp fa-light fa-rectangle-history-circle-user',
    RECTANGLE_LIST = 'fa-sharp fa-light fa-rectangle-list',
    RECTANGLE_PRO = 'fa-sharp fa-light fa-rectangle-pro',
    RECTANGLE_TERMINAL = 'fa-sharp fa-light fa-rectangle-terminal',
    RECTANGLE_VERTICAL = 'fa-sharp fa-light fa-rectangle-vertical',
    RECTANGLE_VERTICAL_HISTORY = 'fa-sharp fa-light fa-rectangle-vertical-history',
    RECTANGLE_WIDE = 'fa-sharp fa-light fa-rectangle-wide',
    RECTANGLE_XMARK = 'fa-sharp fa-light fa-rectangle-xmark',
    RECYCLE = 'fa-sharp fa-light fa-recycle',
    REEL = 'fa-sharp fa-light fa-reel',
    REFLECT_BOTH = 'fa-sharp fa-light fa-reflect-both',
    REFLECT_HORIZONTAL = 'fa-sharp fa-light fa-reflect-horizontal',
    REFLECT_VERTICAL = 'fa-sharp fa-light fa-reflect-vertical',
    REFRIGERATOR = 'fa-sharp fa-light fa-refrigerator',
    REGISTERED = 'fa-sharp fa-light fa-registered',
    REPEAT = 'fa-sharp fa-light fa-repeat',
    REPEAT_1 = 'fa-sharp fa-light fa-repeat-1',
    REPLY = 'fa-sharp fa-light fa-reply',
    REPLY_ALL = 'fa-sharp fa-light fa-reply-all',
    REPLY_CLOCK = 'fa-sharp fa-light fa-reply-clock',
    REPUBLICAN = 'fa-sharp fa-light fa-republican',
    RESTROOM = 'fa-sharp fa-light fa-restroom',
    RESTROOM_SIMPLE = 'fa-sharp fa-light fa-restroom-simple',
    RETWEET = 'fa-sharp fa-light fa-retweet',
    RHOMBUS = 'fa-sharp fa-light fa-rhombus',
    RIBBON = 'fa-sharp fa-light fa-ribbon',
    RIGHT = 'fa-sharp fa-light fa-right',
    RIGHT_FROM_BRACKET = 'fa-sharp fa-light fa-right-from-bracket',
    RIGHT_FROM_LINE = 'fa-sharp fa-light fa-right-from-line',
    RIGHT_LEFT = 'fa-sharp fa-light fa-right-left',
    RIGHT_LEFT_LARGE = 'fa-sharp fa-light fa-right-left-large',
    RIGHT_LONG = 'fa-sharp fa-light fa-right-long',
    RIGHT_LONG_TO_LINE = 'fa-sharp fa-light fa-right-long-to-line',
    RIGHT_TO_BRACKET = 'fa-sharp fa-light fa-right-to-bracket',
    RIGHT_TO_LINE = 'fa-sharp fa-light fa-right-to-line',
    RING = 'fa-sharp fa-light fa-ring',
    RINGS_WEDDING = 'fa-sharp fa-light fa-rings-wedding',
    RING_DIAMOND = 'fa-sharp fa-light fa-ring-diamond',
    ROAD = 'fa-sharp fa-light fa-road',
    ROAD_BARRIER = 'fa-sharp fa-light fa-road-barrier',
    ROAD_BRIDGE = 'fa-sharp fa-light fa-road-bridge',
    ROAD_CIRCLE_CHECK = 'fa-sharp fa-light fa-road-circle-check',
    ROAD_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-road-circle-exclamation',
    ROAD_CIRCLE_XMARK = 'fa-sharp fa-light fa-road-circle-xmark',
    ROAD_LOCK = 'fa-sharp fa-light fa-road-lock',
    ROAD_SPIKES = 'fa-sharp fa-light fa-road-spikes',
    ROBOT = 'fa-sharp fa-light fa-robot',
    ROBOT_ASTROMECH = 'fa-sharp fa-light fa-robot-astromech',
    ROCKET = 'fa-sharp fa-light fa-rocket',
    ROCKET_LAUNCH = 'fa-sharp fa-light fa-rocket-launch',
    ROLLER_COASTER = 'fa-sharp fa-light fa-roller-coaster',
    ROTATE = 'fa-sharp fa-light fa-rotate',
    ROTATE_EXCLAMATION = 'fa-sharp fa-light fa-rotate-exclamation',
    ROTATE_LEFT = 'fa-sharp fa-light fa-rotate-left',
    ROTATE_REVERSE = 'fa-sharp fa-light fa-rotate-reverse',
    ROTATE_RIGHT = 'fa-sharp fa-light fa-rotate-right',
    ROUTE = 'fa-sharp fa-light fa-route',
    ROUTER = 'fa-sharp fa-light fa-router',
    ROUTE_HIGHWAY = 'fa-sharp fa-light fa-route-highway',
    ROUTE_INTERSTATE = 'fa-sharp fa-light fa-route-interstate',
    RSS = 'fa-sharp fa-light fa-rss',
    RUBLE_SIGN = 'fa-sharp fa-light fa-ruble-sign',
    RUG = 'fa-sharp fa-light fa-rug',
    RUGBY_BALL = 'fa-sharp fa-light fa-rugby-ball',
    RULER = 'fa-sharp fa-light fa-ruler',
    RULER_COMBINED = 'fa-sharp fa-light fa-ruler-combined',
    RULER_HORIZONTAL = 'fa-sharp fa-light fa-ruler-horizontal',
    RULER_TRIANGLE = 'fa-sharp fa-light fa-ruler-triangle',
    RULER_VERTICAL = 'fa-sharp fa-light fa-ruler-vertical',
    RUPEE_SIGN = 'fa-sharp fa-light fa-rupee-sign',
    RUPIAH_SIGN = 'fa-sharp fa-light fa-rupiah-sign',
    RV = 'fa-sharp fa-light fa-rv',
    S = 'fa-sharp fa-light fa-s',
    SACK = 'fa-sharp fa-light fa-sack',
    SACK_DOLLAR = 'fa-sharp fa-light fa-sack-dollar',
    SACK_XMARK = 'fa-sharp fa-light fa-sack-xmark',
    SAILBOAT = 'fa-sharp fa-light fa-sailboat',
    SALAD = 'fa-sharp fa-light fa-salad',
    SALT_SHAKER = 'fa-sharp fa-light fa-salt-shaker',
    SANDWICH = 'fa-sharp fa-light fa-sandwich',
    SATELLITE = 'fa-sharp fa-light fa-satellite',
    SATELLITE_DISH = 'fa-sharp fa-light fa-satellite-dish',
    SAUSAGE = 'fa-sharp fa-light fa-sausage',
    SAXOPHONE = 'fa-sharp fa-light fa-saxophone',
    SAXOPHONE_FIRE = 'fa-sharp fa-light fa-saxophone-fire',
    SCALE_BALANCED = 'fa-sharp fa-light fa-scale-balanced',
    SCALE_UNBALANCED = 'fa-sharp fa-light fa-scale-unbalanced',
    SCALE_UNBALANCED_FLIP = 'fa-sharp fa-light fa-scale-unbalanced-flip',
    SCALPEL = 'fa-sharp fa-light fa-scalpel',
    SCALPEL_LINE_DASHED = 'fa-sharp fa-light fa-scalpel-line-dashed',
    SCANNER_GUN = 'fa-sharp fa-light fa-scanner-gun',
    SCANNER_IMAGE = 'fa-sharp fa-light fa-scanner-image',
    SCANNER_KEYBOARD = 'fa-sharp fa-light fa-scanner-keyboard',
    SCANNER_TOUCHSCREEN = 'fa-sharp fa-light fa-scanner-touchscreen',
    SCARECROW = 'fa-sharp fa-light fa-scarecrow',
    SCARF = 'fa-sharp fa-light fa-scarf',
    SCHOOL = 'fa-sharp fa-light fa-school',
    SCHOOL_CIRCLE_CHECK = 'fa-sharp fa-light fa-school-circle-check',
    SCHOOL_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-school-circle-exclamation',
    SCHOOL_CIRCLE_XMARK = 'fa-sharp fa-light fa-school-circle-xmark',
    SCHOOL_FLAG = 'fa-sharp fa-light fa-school-flag',
    SCHOOL_LOCK = 'fa-sharp fa-light fa-school-lock',
    SCISSORS = 'fa-sharp fa-light fa-scissors',
    SCREENCAST = 'fa-sharp fa-light fa-screencast',
    SCREEN_USERS = 'fa-sharp fa-light fa-screen-users',
    SCREWDRIVER = 'fa-sharp fa-light fa-screwdriver',
    SCREWDRIVER_WRENCH = 'fa-sharp fa-light fa-screwdriver-wrench',
    SCRIBBLE = 'fa-sharp fa-light fa-scribble',
    SCROLL = 'fa-sharp fa-light fa-scroll',
    SCROLL_OLD = 'fa-sharp fa-light fa-scroll-old',
    SCROLL_TORAH = 'fa-sharp fa-light fa-scroll-torah',
    SCRUBBER = 'fa-sharp fa-light fa-scrubber',
    SCYTHE = 'fa-sharp fa-light fa-scythe',
    SD_CARD = 'fa-sharp fa-light fa-sd-card',
    SD_CARDS = 'fa-sharp fa-light fa-sd-cards',
    SEAL = 'fa-sharp fa-light fa-seal',
    SEAL_EXCLAMATION = 'fa-sharp fa-light fa-seal-exclamation',
    SEAL_QUESTION = 'fa-sharp fa-light fa-seal-question',
    SEAT_AIRLINE = 'fa-sharp fa-light fa-seat-airline',
    SECTION = 'fa-sharp fa-light fa-section',
    SEEDLING = 'fa-sharp fa-light fa-seedling',
    SEMICOLON = 'fa-sharp fa-light fa-semicolon',
    SEND_BACK = 'fa-sharp fa-light fa-send-back',
    SEND_BACKWARD = 'fa-sharp fa-light fa-send-backward',
    SENSOR = 'fa-sharp fa-light fa-sensor',
    SENSOR_CLOUD = 'fa-sharp fa-light fa-sensor-cloud',
    SENSOR_FIRE = 'fa-sharp fa-light fa-sensor-fire',
    SENSOR_ON = 'fa-sharp fa-light fa-sensor-on',
    SENSOR_TRIANGLE_EXCLAMATION = 'fa-sharp fa-light fa-sensor-triangle-exclamation',
    SERVER = 'fa-sharp fa-light fa-server',
    SHAPES = 'fa-sharp fa-light fa-shapes',
    SHARE = 'fa-sharp fa-light fa-share',
    SHARE_ALL = 'fa-sharp fa-light fa-share-all',
    SHARE_FROM_SQUARE = 'fa-sharp fa-light fa-share-from-square',
    SHARE_NODES = 'fa-sharp fa-light fa-share-nodes',
    SHEEP = 'fa-sharp fa-light fa-sheep',
    SHEET_PLASTIC = 'fa-sharp fa-light fa-sheet-plastic',
    SHEKEL_SIGN = 'fa-sharp fa-light fa-shekel-sign',
    SHELVES = 'fa-sharp fa-light fa-shelves',
    SHELVES_EMPTY = 'fa-sharp fa-light fa-shelves-empty',
    SHIELD = 'fa-sharp fa-light fa-shield',
    SHIELD_CAT = 'fa-sharp fa-light fa-shield-cat',
    SHIELD_CHECK = 'fa-sharp fa-light fa-shield-check',
    SHIELD_CROSS = 'fa-sharp fa-light fa-shield-cross',
    SHIELD_DOG = 'fa-sharp fa-light fa-shield-dog',
    SHIELD_EXCLAMATION = 'fa-sharp fa-light fa-shield-exclamation',
    SHIELD_HALVED = 'fa-sharp fa-light fa-shield-halved',
    SHIELD_HEART = 'fa-sharp fa-light fa-shield-heart',
    SHIELD_KEYHOLE = 'fa-sharp fa-light fa-shield-keyhole',
    SHIELD_MINUS = 'fa-sharp fa-light fa-shield-minus',
    SHIELD_PLUS = 'fa-sharp fa-light fa-shield-plus',
    SHIELD_QUARTERED = 'fa-sharp fa-light fa-shield-quartered',
    SHIELD_SLASH = 'fa-sharp fa-light fa-shield-slash',
    SHIELD_VIRUS = 'fa-sharp fa-light fa-shield-virus',
    SHIELD_XMARK = 'fa-sharp fa-light fa-shield-xmark',
    SHIP = 'fa-sharp fa-light fa-ship',
    SHIRT = 'fa-sharp fa-light fa-shirt',
    SHIRT_LONG_SLEEVE = 'fa-sharp fa-light fa-shirt-long-sleeve',
    SHIRT_RUNNING = 'fa-sharp fa-light fa-shirt-running',
    SHIRT_TANK_TOP = 'fa-sharp fa-light fa-shirt-tank-top',
    SHISH_KEBAB = 'fa-sharp fa-light fa-shish-kebab',
    SHOE_PRINTS = 'fa-sharp fa-light fa-shoe-prints',
    SHOP = 'fa-sharp fa-light fa-shop',
    SHOP_LOCK = 'fa-sharp fa-light fa-shop-lock',
    SHOP_SLASH = 'fa-sharp fa-light fa-shop-slash',
    SHOVEL = 'fa-sharp fa-light fa-shovel',
    SHOVEL_SNOW = 'fa-sharp fa-light fa-shovel-snow',
    SHOWER = 'fa-sharp fa-light fa-shower',
    SHOWER_DOWN = 'fa-sharp fa-light fa-shower-down',
    SHREDDER = 'fa-sharp fa-light fa-shredder',
    SHRIMP = 'fa-sharp fa-light fa-shrimp',
    SHUFFLE = 'fa-sharp fa-light fa-shuffle',
    SHUTTERS = 'fa-sharp fa-light fa-shutters',
    SHUTTLECOCK = 'fa-sharp fa-light fa-shuttlecock',
    SHUTTLE_SPACE = 'fa-sharp fa-light fa-shuttle-space',
    SICKLE = 'fa-sharp fa-light fa-sickle',
    SIDEBAR = 'fa-sharp fa-light fa-sidebar',
    SIDEBAR_FLIP = 'fa-sharp fa-light fa-sidebar-flip',
    SIGMA = 'fa-sharp fa-light fa-sigma',
    SIGNAL = 'fa-sharp fa-light fa-signal',
    SIGNAL_BARS = 'fa-sharp fa-light fa-signal-bars',
    SIGNAL_BARS_FAIR = 'fa-sharp fa-light fa-signal-bars-fair',
    SIGNAL_BARS_GOOD = 'fa-sharp fa-light fa-signal-bars-good',
    SIGNAL_BARS_SLASH = 'fa-sharp fa-light fa-signal-bars-slash',
    SIGNAL_BARS_WEAK = 'fa-sharp fa-light fa-signal-bars-weak',
    SIGNAL_FAIR = 'fa-sharp fa-light fa-signal-fair',
    SIGNAL_GOOD = 'fa-sharp fa-light fa-signal-good',
    SIGNAL_SLASH = 'fa-sharp fa-light fa-signal-slash',
    SIGNAL_STREAM = 'fa-sharp fa-light fa-signal-stream',
    SIGNAL_STREAM_SLASH = 'fa-sharp fa-light fa-signal-stream-slash',
    SIGNAL_STRONG = 'fa-sharp fa-light fa-signal-strong',
    SIGNAL_WEAK = 'fa-sharp fa-light fa-signal-weak',
    SIGNATURE = 'fa-sharp fa-light fa-signature',
    SIGNATURE_LOCK = 'fa-sharp fa-light fa-signature-lock',
    SIGNATURE_SLASH = 'fa-sharp fa-light fa-signature-slash',
    SIGNS_POST = 'fa-sharp fa-light fa-signs-post',
    SIGN_HANGING = 'fa-sharp fa-light fa-sign-hanging',
    SIGN_POST = 'fa-sharp fa-light fa-sign-post',
    SIGN_POSTS = 'fa-sharp fa-light fa-sign-posts',
    SIGN_POSTS_WRENCH = 'fa-sharp fa-light fa-sign-posts-wrench',
    SIM_CARD = 'fa-sharp fa-light fa-sim-card',
    SIM_CARDS = 'fa-sharp fa-light fa-sim-cards',
    SINK = 'fa-sharp fa-light fa-sink',
    SIREN = 'fa-sharp fa-light fa-siren',
    SIREN_ON = 'fa-sharp fa-light fa-siren-on',
    SITEMAP = 'fa-sharp fa-light fa-sitemap',
    SKELETON = 'fa-sharp fa-light fa-skeleton',
    SKELETON_RIBS = 'fa-sharp fa-light fa-skeleton-ribs',
    SKI_BOOT = 'fa-sharp fa-light fa-ski-boot',
    SKI_BOOT_SKI = 'fa-sharp fa-light fa-ski-boot-ski',
    SKULL = 'fa-sharp fa-light fa-skull',
    SKULL_COW = 'fa-sharp fa-light fa-skull-cow',
    SKULL_CROSSBONES = 'fa-sharp fa-light fa-skull-crossbones',
    SLASH = 'fa-sharp fa-light fa-slash',
    SLASH_BACK = 'fa-sharp fa-light fa-slash-back',
    SLASH_FORWARD = 'fa-sharp fa-light fa-slash-forward',
    SLEIGH = 'fa-sharp fa-light fa-sleigh',
    SLIDER = 'fa-sharp fa-light fa-slider',
    SLIDERS = 'fa-sharp fa-light fa-sliders',
    SLIDERS_SIMPLE = 'fa-sharp fa-light fa-sliders-simple',
    SLIDERS_UP = 'fa-sharp fa-light fa-sliders-up',
    SLOT_MACHINE = 'fa-sharp fa-light fa-slot-machine',
    SMOG = 'fa-sharp fa-light fa-smog',
    SMOKE = 'fa-sharp fa-light fa-smoke',
    SMOKING = 'fa-sharp fa-light fa-smoking',
    SNAKE = 'fa-sharp fa-light fa-snake',
    SNOOZE = 'fa-sharp fa-light fa-snooze',
    SNOWFLAKE = 'fa-sharp fa-light fa-snowflake',
    SNOWFLAKES = 'fa-sharp fa-light fa-snowflakes',
    SNOWFLAKE_DROPLETS = 'fa-sharp fa-light fa-snowflake-droplets',
    SNOWMAN = 'fa-sharp fa-light fa-snowman',
    SNOWMAN_HEAD = 'fa-sharp fa-light fa-snowman-head',
    SNOWPLOW = 'fa-sharp fa-light fa-snowplow',
    SNOW_BLOWING = 'fa-sharp fa-light fa-snow-blowing',
    SOAP = 'fa-sharp fa-light fa-soap',
    SOCKS = 'fa-sharp fa-light fa-socks',
    SOFT_SERVE = 'fa-sharp fa-light fa-soft-serve',
    SOLAR_PANEL = 'fa-sharp fa-light fa-solar-panel',
    SOLAR_SYSTEM = 'fa-sharp fa-light fa-solar-system',
    SORT = 'fa-sharp fa-light fa-sort',
    SORT_DOWN = 'fa-sharp fa-light fa-sort-down',
    SORT_UP = 'fa-sharp fa-light fa-sort-up',
    SPA = 'fa-sharp fa-light fa-spa',
    SPACE_STATION_MOON = 'fa-sharp fa-light fa-space-station-moon',
    SPACE_STATION_MOON_CONSTRUCTION = 'fa-sharp fa-light fa-space-station-moon-construction',
    SPADE = 'fa-sharp fa-light fa-spade',
    SPAGHETTI_MONSTER_FLYING = 'fa-sharp fa-light fa-spaghetti-monster-flying',
    SPARKLE = 'fa-sharp fa-light fa-sparkle',
    SPARKLES = 'fa-sharp fa-light fa-sparkles',
    SPEAKER = 'fa-sharp fa-light fa-speaker',
    SPEAKERS = 'fa-sharp fa-light fa-speakers',
    SPELL_CHECK = 'fa-sharp fa-light fa-spell-check',
    SPIDER = 'fa-sharp fa-light fa-spider',
    SPIDER_BLACK_WIDOW = 'fa-sharp fa-light fa-spider-black-widow',
    SPIDER_WEB = 'fa-sharp fa-light fa-spider-web',
    SPINNER = 'fa-sharp fa-light fa-spinner',
    SPINNER_SCALE = 'fa-sharp fa-light fa-spinner-scale',
    SPINNER_THIRD = 'fa-sharp fa-light fa-spinner-third',
    SPLIT = 'fa-sharp fa-light fa-split',
    SPLOTCH = 'fa-sharp fa-light fa-splotch',
    SPOON = 'fa-sharp fa-light fa-spoon',
    SPORTSBALL = 'fa-sharp fa-light fa-sportsball',
    SPRAY_CAN = 'fa-sharp fa-light fa-spray-can',
    SPRAY_CAN_SPARKLES = 'fa-sharp fa-light fa-spray-can-sparkles',
    SPRINKLER = 'fa-sharp fa-light fa-sprinkler',
    SPRINKLER_CEILING = 'fa-sharp fa-light fa-sprinkler-ceiling',
    SQUARE = 'fa-sharp fa-light fa-square',
    SQUARE_0 = 'fa-sharp fa-light fa-square-0',
    SQUARE_1 = 'fa-sharp fa-light fa-square-1',
    SQUARE_2 = 'fa-sharp fa-light fa-square-2',
    SQUARE_3 = 'fa-sharp fa-light fa-square-3',
    SQUARE_4 = 'fa-sharp fa-light fa-square-4',
    SQUARE_5 = 'fa-sharp fa-light fa-square-5',
    SQUARE_6 = 'fa-sharp fa-light fa-square-6',
    SQUARE_7 = 'fa-sharp fa-light fa-square-7',
    SQUARE_8 = 'fa-sharp fa-light fa-square-8',
    SQUARE_9 = 'fa-sharp fa-light fa-square-9',
    SQUARE_A = 'fa-sharp fa-light fa-square-a',
    SQUARE_AMPERSAND = 'fa-sharp fa-light fa-square-ampersand',
    SQUARE_ARROW_DOWN = 'fa-sharp fa-light fa-square-arrow-down',
    SQUARE_ARROW_DOWN_LEFT = 'fa-sharp fa-light fa-square-arrow-down-left',
    SQUARE_ARROW_DOWN_RIGHT = 'fa-sharp fa-light fa-square-arrow-down-right',
    SQUARE_ARROW_LEFT = 'fa-sharp fa-light fa-square-arrow-left',
    SQUARE_ARROW_RIGHT = 'fa-sharp fa-light fa-square-arrow-right',
    SQUARE_ARROW_UP = 'fa-sharp fa-light fa-square-arrow-up',
    SQUARE_ARROW_UP_LEFT = 'fa-sharp fa-light fa-square-arrow-up-left',
    SQUARE_ARROW_UP_RIGHT = 'fa-sharp fa-light fa-square-arrow-up-right',
    SQUARE_A_LOCK = 'fa-sharp fa-light fa-square-a-lock',
    SQUARE_B = 'fa-sharp fa-light fa-square-b',
    SQUARE_BOLT = 'fa-sharp fa-light fa-square-bolt',
    SQUARE_C = 'fa-sharp fa-light fa-square-c',
    SQUARE_CARET_DOWN = 'fa-sharp fa-light fa-square-caret-down',
    SQUARE_CARET_LEFT = 'fa-sharp fa-light fa-square-caret-left',
    SQUARE_CARET_RIGHT = 'fa-sharp fa-light fa-square-caret-right',
    SQUARE_CARET_UP = 'fa-sharp fa-light fa-square-caret-up',
    SQUARE_CHECK = 'fa-sharp fa-light fa-square-check',
    SQUARE_CHEVRON_DOWN = 'fa-sharp fa-light fa-square-chevron-down',
    SQUARE_CHEVRON_LEFT = 'fa-sharp fa-light fa-square-chevron-left',
    SQUARE_CHEVRON_RIGHT = 'fa-sharp fa-light fa-square-chevron-right',
    SQUARE_CHEVRON_UP = 'fa-sharp fa-light fa-square-chevron-up',
    SQUARE_CODE = 'fa-sharp fa-light fa-square-code',
    SQUARE_D = 'fa-sharp fa-light fa-square-d',
    SQUARE_DASHED = 'fa-sharp fa-light fa-square-dashed',
    SQUARE_DASHED_CIRCLE_PLUS = 'fa-sharp fa-light fa-square-dashed-circle-plus',
    SQUARE_DIVIDE = 'fa-sharp fa-light fa-square-divide',
    SQUARE_DOLLAR = 'fa-sharp fa-light fa-square-dollar',
    SQUARE_DOWN = 'fa-sharp fa-light fa-square-down',
    SQUARE_DOWN_LEFT = 'fa-sharp fa-light fa-square-down-left',
    SQUARE_DOWN_RIGHT = 'fa-sharp fa-light fa-square-down-right',
    SQUARE_E = 'fa-sharp fa-light fa-square-e',
    SQUARE_ELLIPSIS = 'fa-sharp fa-light fa-square-ellipsis',
    SQUARE_ELLIPSIS_VERTICAL = 'fa-sharp fa-light fa-square-ellipsis-vertical',
    SQUARE_ENVELOPE = 'fa-sharp fa-light fa-square-envelope',
    SQUARE_EXCLAMATION = 'fa-sharp fa-light fa-square-exclamation',
    SQUARE_F = 'fa-sharp fa-light fa-square-f',
    SQUARE_FRAGILE = 'fa-sharp fa-light fa-square-fragile',
    SQUARE_FULL = 'fa-sharp fa-light fa-square-full',
    SQUARE_G = 'fa-sharp fa-light fa-square-g',
    SQUARE_H = 'fa-sharp fa-light fa-square-h',
    SQUARE_HEART = 'fa-sharp fa-light fa-square-heart',
    SQUARE_I = 'fa-sharp fa-light fa-square-i',
    SQUARE_INFO = 'fa-sharp fa-light fa-square-info',
    SQUARE_J = 'fa-sharp fa-light fa-square-j',
    SQUARE_K = 'fa-sharp fa-light fa-square-k',
    SQUARE_KANBAN = 'fa-sharp fa-light fa-square-kanban',
    SQUARE_L = 'fa-sharp fa-light fa-square-l',
    SQUARE_LEFT = 'fa-sharp fa-light fa-square-left',
    SQUARE_LIST = 'fa-sharp fa-light fa-square-list',
    SQUARE_M = 'fa-sharp fa-light fa-square-m',
    SQUARE_MINUS = 'fa-sharp fa-light fa-square-minus',
    SQUARE_N = 'fa-sharp fa-light fa-square-n',
    SQUARE_NFI = 'fa-sharp fa-light fa-square-nfi',
    SQUARE_O = 'fa-sharp fa-light fa-square-o',
    SQUARE_P = 'fa-sharp fa-light fa-square-p',
    SQUARE_PARKING = 'fa-sharp fa-light fa-square-parking',
    SQUARE_PARKING_SLASH = 'fa-sharp fa-light fa-square-parking-slash',
    SQUARE_PEN = 'fa-sharp fa-light fa-square-pen',
    SQUARE_PERSON_CONFINED = 'fa-sharp fa-light fa-square-person-confined',
    SQUARE_PHONE = 'fa-sharp fa-light fa-square-phone',
    SQUARE_PHONE_FLIP = 'fa-sharp fa-light fa-square-phone-flip',
    SQUARE_PHONE_HANGUP = 'fa-sharp fa-light fa-square-phone-hangup',
    SQUARE_PLUS = 'fa-sharp fa-light fa-square-plus',
    SQUARE_POLL_HORIZONTAL = 'fa-sharp fa-light fa-square-poll-horizontal',
    SQUARE_POLL_VERTICAL = 'fa-sharp fa-light fa-square-poll-vertical',
    SQUARE_Q = 'fa-sharp fa-light fa-square-q',
    SQUARE_QUARTERS = 'fa-sharp fa-light fa-square-quarters',
    SQUARE_QUESTION = 'fa-sharp fa-light fa-square-question',
    SQUARE_QUOTE = 'fa-sharp fa-light fa-square-quote',
    SQUARE_R = 'fa-sharp fa-light fa-square-r',
    SQUARE_RIGHT = 'fa-sharp fa-light fa-square-right',
    SQUARE_RING = 'fa-sharp fa-light fa-square-ring',
    SQUARE_ROOT = 'fa-sharp fa-light fa-square-root',
    SQUARE_ROOT_VARIABLE = 'fa-sharp fa-light fa-square-root-variable',
    SQUARE_RSS = 'fa-sharp fa-light fa-square-rss',
    SQUARE_S = 'fa-sharp fa-light fa-square-s',
    SQUARE_SHARE_NODES = 'fa-sharp fa-light fa-square-share-nodes',
    SQUARE_SLIDERS = 'fa-sharp fa-light fa-square-sliders',
    SQUARE_SLIDERS_VERTICAL = 'fa-sharp fa-light fa-square-sliders-vertical',
    SQUARE_SMALL = 'fa-sharp fa-light fa-square-small',
    SQUARE_STAR = 'fa-sharp fa-light fa-square-star',
    SQUARE_T = 'fa-sharp fa-light fa-square-t',
    SQUARE_TERMINAL = 'fa-sharp fa-light fa-square-terminal',
    SQUARE_THIS_WAY_UP = 'fa-sharp fa-light fa-square-this-way-up',
    SQUARE_U = 'fa-sharp fa-light fa-square-u',
    SQUARE_UP = 'fa-sharp fa-light fa-square-up',
    SQUARE_UP_LEFT = 'fa-sharp fa-light fa-square-up-left',
    SQUARE_UP_RIGHT = 'fa-sharp fa-light fa-square-up-right',
    SQUARE_USER = 'fa-sharp fa-light fa-square-user',
    SQUARE_V = 'fa-sharp fa-light fa-square-v',
    SQUARE_VIRUS = 'fa-sharp fa-light fa-square-virus',
    SQUARE_W = 'fa-sharp fa-light fa-square-w',
    SQUARE_X = 'fa-sharp fa-light fa-square-x',
    SQUARE_XMARK = 'fa-sharp fa-light fa-square-xmark',
    SQUARE_Y = 'fa-sharp fa-light fa-square-y',
    SQUARE_Z = 'fa-sharp fa-light fa-square-z',
    SQUID = 'fa-sharp fa-light fa-squid',
    SQUIRREL = 'fa-sharp fa-light fa-squirrel',
    STAFF = 'fa-sharp fa-light fa-staff',
    STAFF_SNAKE = 'fa-sharp fa-light fa-staff-snake',
    STAIRS = 'fa-sharp fa-light fa-stairs',
    STAMP = 'fa-sharp fa-light fa-stamp',
    STANDARD_DEFINITION = 'fa-sharp fa-light fa-standard-definition',
    STAPLER = 'fa-sharp fa-light fa-stapler',
    STAR = 'fa-sharp fa-light fa-star',
    STARFIGHTER = 'fa-sharp fa-light fa-starfighter',
    STARFIGHTER_TWIN_ION_ENGINE = 'fa-sharp fa-light fa-starfighter-twin-ion-engine',
    STARFIGHTER_TWIN_ION_ENGINE_ADVANCED = 'fa-sharp fa-light fa-starfighter-twin-ion-engine-advanced',
    STARS = 'fa-sharp fa-light fa-stars',
    STARSHIP = 'fa-sharp fa-light fa-starship',
    STARSHIP_FREIGHTER = 'fa-sharp fa-light fa-starship-freighter',
    STAR_AND_CRESCENT = 'fa-sharp fa-light fa-star-and-crescent',
    STAR_CHRISTMAS = 'fa-sharp fa-light fa-star-christmas',
    STAR_EXCLAMATION = 'fa-sharp fa-light fa-star-exclamation',
    STAR_HALF = 'fa-sharp fa-light fa-star-half',
    STAR_HALF_STROKE = 'fa-sharp fa-light fa-star-half-stroke',
    STAR_OF_DAVID = 'fa-sharp fa-light fa-star-of-david',
    STAR_OF_LIFE = 'fa-sharp fa-light fa-star-of-life',
    STAR_SHARP = 'fa-sharp fa-light fa-star-sharp',
    STAR_SHARP_HALF = 'fa-sharp fa-light fa-star-sharp-half',
    STAR_SHARP_HALF_STROKE = 'fa-sharp fa-light fa-star-sharp-half-stroke',
    STAR_SHOOTING = 'fa-sharp fa-light fa-star-shooting',
    STEAK = 'fa-sharp fa-light fa-steak',
    STEERING_WHEEL = 'fa-sharp fa-light fa-steering-wheel',
    STERLING_SIGN = 'fa-sharp fa-light fa-sterling-sign',
    STETHOSCOPE = 'fa-sharp fa-light fa-stethoscope',
    STOCKING = 'fa-sharp fa-light fa-stocking',
    STOMACH = 'fa-sharp fa-light fa-stomach',
    STOP = 'fa-sharp fa-light fa-stop',
    STOPWATCH = 'fa-sharp fa-light fa-stopwatch',
    STOPWATCH_20 = 'fa-sharp fa-light fa-stopwatch-20',
    STORE = 'fa-sharp fa-light fa-store',
    STORE_LOCK = 'fa-sharp fa-light fa-store-lock',
    STORE_SLASH = 'fa-sharp fa-light fa-store-slash',
    STRAWBERRY = 'fa-sharp fa-light fa-strawberry',
    STREET_VIEW = 'fa-sharp fa-light fa-street-view',
    STRETCHER = 'fa-sharp fa-light fa-stretcher',
    STRIKETHROUGH = 'fa-sharp fa-light fa-strikethrough',
    STROOPWAFEL = 'fa-sharp fa-light fa-stroopwafel',
    SUBSCRIPT = 'fa-sharp fa-light fa-subscript',
    SUBTITLES = 'fa-sharp fa-light fa-subtitles',
    SUBTITLES_SLASH = 'fa-sharp fa-light fa-subtitles-slash',
    SUITCASE = 'fa-sharp fa-light fa-suitcase',
    SUITCASE_MEDICAL = 'fa-sharp fa-light fa-suitcase-medical',
    SUITCASE_ROLLING = 'fa-sharp fa-light fa-suitcase-rolling',
    SUN = 'fa-sharp fa-light fa-sun',
    SUNGLASSES = 'fa-sharp fa-light fa-sunglasses',
    SUNRISE = 'fa-sharp fa-light fa-sunrise',
    SUNSET = 'fa-sharp fa-light fa-sunset',
    SUN_BRIGHT = 'fa-sharp fa-light fa-sun-bright',
    SUN_CLOUD = 'fa-sharp fa-light fa-sun-cloud',
    SUN_DUST = 'fa-sharp fa-light fa-sun-dust',
    SUN_HAZE = 'fa-sharp fa-light fa-sun-haze',
    SUN_PLANT_WILT = 'fa-sharp fa-light fa-sun-plant-wilt',
    SUPERSCRIPT = 'fa-sharp fa-light fa-superscript',
    SUSHI = 'fa-sharp fa-light fa-sushi',
    SUSHI_ROLL = 'fa-sharp fa-light fa-sushi-roll',
    SWAP = 'fa-sharp fa-light fa-swap',
    SWAP_ARROWS = 'fa-sharp fa-light fa-swap-arrows',
    SWATCHBOOK = 'fa-sharp fa-light fa-swatchbook',
    SWORD = 'fa-sharp fa-light fa-sword',
    SWORDS = 'fa-sharp fa-light fa-swords',
    SWORDS_LASER = 'fa-sharp fa-light fa-swords-laser',
    SWORD_LASER = 'fa-sharp fa-light fa-sword-laser',
    SWORD_LASER_ALT = 'fa-sharp fa-light fa-sword-laser-alt',
    SYMBOLS = 'fa-sharp fa-light fa-symbols',
    SYNAGOGUE = 'fa-sharp fa-light fa-synagogue',
    SYRINGE = 'fa-sharp fa-light fa-syringe',
    T = 'fa-sharp fa-light fa-t',
    TABLE = 'fa-sharp fa-light fa-table',
    TABLET = 'fa-sharp fa-light fa-tablet',
    TABLETS = 'fa-sharp fa-light fa-tablets',
    TABLET_BUTTON = 'fa-sharp fa-light fa-tablet-button',
    TABLET_RUGGED = 'fa-sharp fa-light fa-tablet-rugged',
    TABLET_SCREEN = 'fa-sharp fa-light fa-tablet-screen',
    TABLET_SCREEN_BUTTON = 'fa-sharp fa-light fa-tablet-screen-button',
    TABLE_CELLS = 'fa-sharp fa-light fa-table-cells',
    TABLE_CELLS_COLUMN_LOCK = 'fa-sharp fa-light fa-table-cells-column-lock',
    TABLE_CELLS_COLUMN_UNLOCK = 'fa-sharp fa-light fa-table-cells-column-unlock',
    TABLE_CELLS_LARGE = 'fa-sharp fa-light fa-table-cells-large',
    TABLE_CELLS_LOCK = 'fa-sharp fa-light fa-table-cells-lock',
    TABLE_CELLS_ROW_LOCK = 'fa-sharp fa-light fa-table-cells-row-lock',
    TABLE_CELLS_ROW_UNLOCK = 'fa-sharp fa-light fa-table-cells-row-unlock',
    TABLE_CELLS_UNLOCK = 'fa-sharp fa-light fa-table-cells-unlock',
    TABLE_COLUMNS = 'fa-sharp fa-light fa-table-columns',
    TABLE_LAYOUT = 'fa-sharp fa-light fa-table-layout',
    TABLE_LIST = 'fa-sharp fa-light fa-table-list',
    TABLE_PICNIC = 'fa-sharp fa-light fa-table-picnic',
    TABLE_PIVOT = 'fa-sharp fa-light fa-table-pivot',
    TABLE_ROWS = 'fa-sharp fa-light fa-table-rows',
    TABLE_TENNIS_PADDLE_BALL = 'fa-sharp fa-light fa-table-tennis-paddle-ball',
    TABLE_TREE = 'fa-sharp fa-light fa-table-tree',
    TACHOGRAPH_DIGITAL = 'fa-sharp fa-light fa-tachograph-digital',
    TACO = 'fa-sharp fa-light fa-taco',
    TAG = 'fa-sharp fa-light fa-tag',
    TAGS = 'fa-sharp fa-light fa-tags',
    TALLY = 'fa-sharp fa-light fa-tally',
    TALLY_1 = 'fa-sharp fa-light fa-tally-1',
    TALLY_2 = 'fa-sharp fa-light fa-tally-2',
    TALLY_3 = 'fa-sharp fa-light fa-tally-3',
    TALLY_4 = 'fa-sharp fa-light fa-tally-4',
    TAMALE = 'fa-sharp fa-light fa-tamale',
    TANK_WATER = 'fa-sharp fa-light fa-tank-water',
    TAPE = 'fa-sharp fa-light fa-tape',
    TARP = 'fa-sharp fa-light fa-tarp',
    TARP_DROPLET = 'fa-sharp fa-light fa-tarp-droplet',
    TAXI = 'fa-sharp fa-light fa-taxi',
    TAXI_BUS = 'fa-sharp fa-light fa-taxi-bus',
    TEDDY_BEAR = 'fa-sharp fa-light fa-teddy-bear',
    TEETH = 'fa-sharp fa-light fa-teeth',
    TEETH_OPEN = 'fa-sharp fa-light fa-teeth-open',
    TELESCOPE = 'fa-sharp fa-light fa-telescope',
    TEMPERATURE_ARROW_DOWN = 'fa-sharp fa-light fa-temperature-arrow-down',
    TEMPERATURE_ARROW_UP = 'fa-sharp fa-light fa-temperature-arrow-up',
    TEMPERATURE_EMPTY = 'fa-sharp fa-light fa-temperature-empty',
    TEMPERATURE_FULL = 'fa-sharp fa-light fa-temperature-full',
    TEMPERATURE_HALF = 'fa-sharp fa-light fa-temperature-half',
    TEMPERATURE_HIGH = 'fa-sharp fa-light fa-temperature-high',
    TEMPERATURE_LIST = 'fa-sharp fa-light fa-temperature-list',
    TEMPERATURE_LOW = 'fa-sharp fa-light fa-temperature-low',
    TEMPERATURE_QUARTER = 'fa-sharp fa-light fa-temperature-quarter',
    TEMPERATURE_SNOW = 'fa-sharp fa-light fa-temperature-snow',
    TEMPERATURE_SUN = 'fa-sharp fa-light fa-temperature-sun',
    TEMPERATURE_THREE_QUARTERS = 'fa-sharp fa-light fa-temperature-three-quarters',
    TENGE_SIGN = 'fa-sharp fa-light fa-tenge-sign',
    TENNIS_BALL = 'fa-sharp fa-light fa-tennis-ball',
    TENT = 'fa-sharp fa-light fa-tent',
    TENTS = 'fa-sharp fa-light fa-tents',
    TENT_ARROWS_DOWN = 'fa-sharp fa-light fa-tent-arrows-down',
    TENT_ARROW_DOWN_TO_LINE = 'fa-sharp fa-light fa-tent-arrow-down-to-line',
    TENT_ARROW_LEFT_RIGHT = 'fa-sharp fa-light fa-tent-arrow-left-right',
    TENT_ARROW_TURN_LEFT = 'fa-sharp fa-light fa-tent-arrow-turn-left',
    TENT_DOUBLE_PEAK = 'fa-sharp fa-light fa-tent-double-peak',
    TERMINAL = 'fa-sharp fa-light fa-terminal',
    TEXT = 'fa-sharp fa-light fa-text',
    TEXT_HEIGHT = 'fa-sharp fa-light fa-text-height',
    TEXT_SIZE = 'fa-sharp fa-light fa-text-size',
    TEXT_SLASH = 'fa-sharp fa-light fa-text-slash',
    TEXT_WIDTH = 'fa-sharp fa-light fa-text-width',
    THERMOMETER = 'fa-sharp fa-light fa-thermometer',
    THETA = 'fa-sharp fa-light fa-theta',
    THOUGHT_BUBBLE = 'fa-sharp fa-light fa-thought-bubble',
    THUMBS_DOWN = 'fa-sharp fa-light fa-thumbs-down',
    THUMBS_UP = 'fa-sharp fa-light fa-thumbs-up',
    THUMBTACK = 'fa-sharp fa-light fa-thumbtack',
    THUMBTACK_SLASH = 'fa-sharp fa-light fa-thumbtack-slash',
    TICK = 'fa-sharp fa-light fa-tick',
    TICKET = 'fa-sharp fa-light fa-ticket',
    TICKETS = 'fa-sharp fa-light fa-tickets',
    TICKETS_AIRLINE = 'fa-sharp fa-light fa-tickets-airline',
    TICKETS_PERFORATED = 'fa-sharp fa-light fa-tickets-perforated',
    TICKETS_SIMPLE = 'fa-sharp fa-light fa-tickets-simple',
    TICKET_AIRLINE = 'fa-sharp fa-light fa-ticket-airline',
    TICKET_PERFORATED = 'fa-sharp fa-light fa-ticket-perforated',
    TICKET_SIMPLE = 'fa-sharp fa-light fa-ticket-simple',
    TILDE = 'fa-sharp fa-light fa-tilde',
    TIMELINE = 'fa-sharp fa-light fa-timeline',
    TIMELINE_ARROW = 'fa-sharp fa-light fa-timeline-arrow',
    TIMER = 'fa-sharp fa-light fa-timer',
    TIRE = 'fa-sharp fa-light fa-tire',
    TIRE_FLAT = 'fa-sharp fa-light fa-tire-flat',
    TIRE_PRESSURE_WARNING = 'fa-sharp fa-light fa-tire-pressure-warning',
    TIRE_RUGGED = 'fa-sharp fa-light fa-tire-rugged',
    TOGGLE_LARGE_OFF = 'fa-sharp fa-light fa-toggle-large-off',
    TOGGLE_LARGE_ON = 'fa-sharp fa-light fa-toggle-large-on',
    TOGGLE_OFF = 'fa-sharp fa-light fa-toggle-off',
    TOGGLE_ON = 'fa-sharp fa-light fa-toggle-on',
    TOILET = 'fa-sharp fa-light fa-toilet',
    TOILETS_PORTABLE = 'fa-sharp fa-light fa-toilets-portable',
    TOILET_PAPER = 'fa-sharp fa-light fa-toilet-paper',
    TOILET_PAPER_BLANK = 'fa-sharp fa-light fa-toilet-paper-blank',
    TOILET_PAPER_BLANK_UNDER = 'fa-sharp fa-light fa-toilet-paper-blank-under',
    TOILET_PAPER_CHECK = 'fa-sharp fa-light fa-toilet-paper-check',
    TOILET_PAPER_SLASH = 'fa-sharp fa-light fa-toilet-paper-slash',
    TOILET_PAPER_UNDER = 'fa-sharp fa-light fa-toilet-paper-under',
    TOILET_PAPER_UNDER_SLASH = 'fa-sharp fa-light fa-toilet-paper-under-slash',
    TOILET_PAPER_XMARK = 'fa-sharp fa-light fa-toilet-paper-xmark',
    TOILET_PORTABLE = 'fa-sharp fa-light fa-toilet-portable',
    TOMATO = 'fa-sharp fa-light fa-tomato',
    TOMBSTONE = 'fa-sharp fa-light fa-tombstone',
    TOMBSTONE_BLANK = 'fa-sharp fa-light fa-tombstone-blank',
    TOOLBOX = 'fa-sharp fa-light fa-toolbox',
    TOOTH = 'fa-sharp fa-light fa-tooth',
    TOOTHBRUSH = 'fa-sharp fa-light fa-toothbrush',
    TORII_GATE = 'fa-sharp fa-light fa-torii-gate',
    TORNADO = 'fa-sharp fa-light fa-tornado',
    TOWER_BROADCAST = 'fa-sharp fa-light fa-tower-broadcast',
    TOWER_CELL = 'fa-sharp fa-light fa-tower-cell',
    TOWER_CONTROL = 'fa-sharp fa-light fa-tower-control',
    TOWER_OBSERVATION = 'fa-sharp fa-light fa-tower-observation',
    TRACTOR = 'fa-sharp fa-light fa-tractor',
    TRADEMARK = 'fa-sharp fa-light fa-trademark',
    TRAFFIC_CONE = 'fa-sharp fa-light fa-traffic-cone',
    TRAFFIC_LIGHT = 'fa-sharp fa-light fa-traffic-light',
    TRAFFIC_LIGHT_GO = 'fa-sharp fa-light fa-traffic-light-go',
    TRAFFIC_LIGHT_SLOW = 'fa-sharp fa-light fa-traffic-light-slow',
    TRAFFIC_LIGHT_STOP = 'fa-sharp fa-light fa-traffic-light-stop',
    TRAILER = 'fa-sharp fa-light fa-trailer',
    TRAIN = 'fa-sharp fa-light fa-train',
    TRAIN_SUBWAY = 'fa-sharp fa-light fa-train-subway',
    TRAIN_SUBWAY_TUNNEL = 'fa-sharp fa-light fa-train-subway-tunnel',
    TRAIN_TRACK = 'fa-sharp fa-light fa-train-track',
    TRAIN_TRAM = 'fa-sharp fa-light fa-train-tram',
    TRAIN_TUNNEL = 'fa-sharp fa-light fa-train-tunnel',
    TRANSFORMER_BOLT = 'fa-sharp fa-light fa-transformer-bolt',
    TRANSGENDER = 'fa-sharp fa-light fa-transgender',
    TRANSPORTER = 'fa-sharp fa-light fa-transporter',
    TRANSPORTER_1 = 'fa-sharp fa-light fa-transporter-1',
    TRANSPORTER_2 = 'fa-sharp fa-light fa-transporter-2',
    TRANSPORTER_3 = 'fa-sharp fa-light fa-transporter-3',
    TRANSPORTER_4 = 'fa-sharp fa-light fa-transporter-4',
    TRANSPORTER_5 = 'fa-sharp fa-light fa-transporter-5',
    TRANSPORTER_6 = 'fa-sharp fa-light fa-transporter-6',
    TRANSPORTER_7 = 'fa-sharp fa-light fa-transporter-7',
    TRANSPORTER_EMPTY = 'fa-sharp fa-light fa-transporter-empty',
    TRASH = 'fa-sharp fa-light fa-trash',
    TRASH_ARROW_UP = 'fa-sharp fa-light fa-trash-arrow-up',
    TRASH_CAN = 'fa-sharp fa-light fa-trash-can',
    TRASH_CAN_ARROW_UP = 'fa-sharp fa-light fa-trash-can-arrow-up',
    TRASH_CAN_CHECK = 'fa-sharp fa-light fa-trash-can-check',
    TRASH_CAN_CLOCK = 'fa-sharp fa-light fa-trash-can-clock',
    TRASH_CAN_LIST = 'fa-sharp fa-light fa-trash-can-list',
    TRASH_CAN_PLUS = 'fa-sharp fa-light fa-trash-can-plus',
    TRASH_CAN_SLASH = 'fa-sharp fa-light fa-trash-can-slash',
    TRASH_CAN_UNDO = 'fa-sharp fa-light fa-trash-can-undo',
    TRASH_CAN_XMARK = 'fa-sharp fa-light fa-trash-can-xmark',
    TRASH_CHECK = 'fa-sharp fa-light fa-trash-check',
    TRASH_CLOCK = 'fa-sharp fa-light fa-trash-clock',
    TRASH_LIST = 'fa-sharp fa-light fa-trash-list',
    TRASH_PLUS = 'fa-sharp fa-light fa-trash-plus',
    TRASH_SLASH = 'fa-sharp fa-light fa-trash-slash',
    TRASH_UNDO = 'fa-sharp fa-light fa-trash-undo',
    TRASH_XMARK = 'fa-sharp fa-light fa-trash-xmark',
    TREASURE_CHEST = 'fa-sharp fa-light fa-treasure-chest',
    TREE = 'fa-sharp fa-light fa-tree',
    TREES = 'fa-sharp fa-light fa-trees',
    TREE_CHRISTMAS = 'fa-sharp fa-light fa-tree-christmas',
    TREE_CITY = 'fa-sharp fa-light fa-tree-city',
    TREE_DECIDUOUS = 'fa-sharp fa-light fa-tree-deciduous',
    TREE_DECORATED = 'fa-sharp fa-light fa-tree-decorated',
    TREE_LARGE = 'fa-sharp fa-light fa-tree-large',
    TREE_PALM = 'fa-sharp fa-light fa-tree-palm',
    TRIANGLE = 'fa-sharp fa-light fa-triangle',
    TRIANGLE_EXCLAMATION = 'fa-sharp fa-light fa-triangle-exclamation',
    TRIANGLE_INSTRUMENT = 'fa-sharp fa-light fa-triangle-instrument',
    TRIANGLE_PERSON_DIGGING = 'fa-sharp fa-light fa-triangle-person-digging',
    TRICYCLE = 'fa-sharp fa-light fa-tricycle',
    TRICYCLE_ADULT = 'fa-sharp fa-light fa-tricycle-adult',
    TRILLIUM = 'fa-sharp fa-light fa-trillium',
    TROPHY = 'fa-sharp fa-light fa-trophy',
    TROPHY_STAR = 'fa-sharp fa-light fa-trophy-star',
    TROWEL = 'fa-sharp fa-light fa-trowel',
    TROWEL_BRICKS = 'fa-sharp fa-light fa-trowel-bricks',
    TRUCK = 'fa-sharp fa-light fa-truck',
    TRUCK_ARROW_RIGHT = 'fa-sharp fa-light fa-truck-arrow-right',
    TRUCK_BOLT = 'fa-sharp fa-light fa-truck-bolt',
    TRUCK_CLOCK = 'fa-sharp fa-light fa-truck-clock',
    TRUCK_CONTAINER = 'fa-sharp fa-light fa-truck-container',
    TRUCK_CONTAINER_EMPTY = 'fa-sharp fa-light fa-truck-container-empty',
    TRUCK_DROPLET = 'fa-sharp fa-light fa-truck-droplet',
    TRUCK_FAST = 'fa-sharp fa-light fa-truck-fast',
    TRUCK_FIELD = 'fa-sharp fa-light fa-truck-field',
    TRUCK_FIELD_UN = 'fa-sharp fa-light fa-truck-field-un',
    TRUCK_FIRE = 'fa-sharp fa-light fa-truck-fire',
    TRUCK_FLATBED = 'fa-sharp fa-light fa-truck-flatbed',
    TRUCK_FRONT = 'fa-sharp fa-light fa-truck-front',
    TRUCK_LADDER = 'fa-sharp fa-light fa-truck-ladder',
    TRUCK_MEDICAL = 'fa-sharp fa-light fa-truck-medical',
    TRUCK_MONSTER = 'fa-sharp fa-light fa-truck-monster',
    TRUCK_MOVING = 'fa-sharp fa-light fa-truck-moving',
    TRUCK_PICKUP = 'fa-sharp fa-light fa-truck-pickup',
    TRUCK_PLANE = 'fa-sharp fa-light fa-truck-plane',
    TRUCK_PLOW = 'fa-sharp fa-light fa-truck-plow',
    TRUCK_RAMP = 'fa-sharp fa-light fa-truck-ramp',
    TRUCK_RAMP_BOX = 'fa-sharp fa-light fa-truck-ramp-box',
    TRUCK_RAMP_COUCH = 'fa-sharp fa-light fa-truck-ramp-couch',
    TRUCK_TOW = 'fa-sharp fa-light fa-truck-tow',
    TRUCK_UTENSILS = 'fa-sharp fa-light fa-truck-utensils',
    TRUMPET = 'fa-sharp fa-light fa-trumpet',
    TTY = 'fa-sharp fa-light fa-tty',
    TTY_ANSWER = 'fa-sharp fa-light fa-tty-answer',
    TUGRIK_SIGN = 'fa-sharp fa-light fa-tugrik-sign',
    TURKEY = 'fa-sharp fa-light fa-turkey',
    TURKISH_LIRA_SIGN = 'fa-sharp fa-light fa-turkish-lira-sign',
    TURNTABLE = 'fa-sharp fa-light fa-turntable',
    TURN_DOWN = 'fa-sharp fa-light fa-turn-down',
    TURN_DOWN_LEFT = 'fa-sharp fa-light fa-turn-down-left',
    TURN_DOWN_RIGHT = 'fa-sharp fa-light fa-turn-down-right',
    TURN_LEFT = 'fa-sharp fa-light fa-turn-left',
    TURN_LEFT_DOWN = 'fa-sharp fa-light fa-turn-left-down',
    TURN_LEFT_UP = 'fa-sharp fa-light fa-turn-left-up',
    TURN_RIGHT = 'fa-sharp fa-light fa-turn-right',
    TURN_UP = 'fa-sharp fa-light fa-turn-up',
    TURTLE = 'fa-sharp fa-light fa-turtle',
    TV = 'fa-sharp fa-light fa-tv',
    TV_MUSIC = 'fa-sharp fa-light fa-tv-music',
    TV_RETRO = 'fa-sharp fa-light fa-tv-retro',
    TYPEWRITER = 'fa-sharp fa-light fa-typewriter',
    T_REX = 'fa-sharp fa-light fa-t-rex',
    U = 'fa-sharp fa-light fa-u',
    UFO = 'fa-sharp fa-light fa-ufo',
    UFO_BEAM = 'fa-sharp fa-light fa-ufo-beam',
    UMBRELLA = 'fa-sharp fa-light fa-umbrella',
    UMBRELLA_BEACH = 'fa-sharp fa-light fa-umbrella-beach',
    UMBRELLA_SIMPLE = 'fa-sharp fa-light fa-umbrella-simple',
    UNDERLINE = 'fa-sharp fa-light fa-underline',
    UNICORN = 'fa-sharp fa-light fa-unicorn',
    UNIFORM_MARTIAL_ARTS = 'fa-sharp fa-light fa-uniform-martial-arts',
    UNION = 'fa-sharp fa-light fa-union',
    UNIVERSAL_ACCESS = 'fa-sharp fa-light fa-universal-access',
    UNLOCK = 'fa-sharp fa-light fa-unlock',
    UNLOCK_KEYHOLE = 'fa-sharp fa-light fa-unlock-keyhole',
    UP = 'fa-sharp fa-light fa-up',
    UPLOAD = 'fa-sharp fa-light fa-upload',
    UP_DOWN = 'fa-sharp fa-light fa-up-down',
    UP_DOWN_LEFT_RIGHT = 'fa-sharp fa-light fa-up-down-left-right',
    UP_FROM_BRACKET = 'fa-sharp fa-light fa-up-from-bracket',
    UP_FROM_DOTTED_LINE = 'fa-sharp fa-light fa-up-from-dotted-line',
    UP_FROM_LINE = 'fa-sharp fa-light fa-up-from-line',
    UP_LEFT = 'fa-sharp fa-light fa-up-left',
    UP_LONG = 'fa-sharp fa-light fa-up-long',
    UP_RIGHT = 'fa-sharp fa-light fa-up-right',
    UP_RIGHT_AND_DOWN_LEFT_FROM_CENTER = 'fa-sharp fa-light fa-up-right-and-down-left-from-center',
    UP_RIGHT_FROM_SQUARE = 'fa-sharp fa-light fa-up-right-from-square',
    UP_TO_BRACKET = 'fa-sharp fa-light fa-up-to-bracket',
    UP_TO_DOTTED_LINE = 'fa-sharp fa-light fa-up-to-dotted-line',
    UP_TO_LINE = 'fa-sharp fa-light fa-up-to-line',
    USB_DRIVE = 'fa-sharp fa-light fa-usb-drive',
    USER = 'fa-sharp fa-light fa-user',
    USERS = 'fa-sharp fa-light fa-users',
    USERS_BETWEEN_LINES = 'fa-sharp fa-light fa-users-between-lines',
    USERS_GEAR = 'fa-sharp fa-light fa-users-gear',
    USERS_LINE = 'fa-sharp fa-light fa-users-line',
    USERS_MEDICAL = 'fa-sharp fa-light fa-users-medical',
    USERS_RAYS = 'fa-sharp fa-light fa-users-rays',
    USERS_RECTANGLE = 'fa-sharp fa-light fa-users-rectangle',
    USERS_SLASH = 'fa-sharp fa-light fa-users-slash',
    USERS_VIEWFINDER = 'fa-sharp fa-light fa-users-viewfinder',
    USER_ALIEN = 'fa-sharp fa-light fa-user-alien',
    USER_ASTRONAUT = 'fa-sharp fa-light fa-user-astronaut',
    USER_BEARD_BOLT = 'fa-sharp fa-light fa-user-beard-bolt',
    USER_BOUNTY_HUNTER = 'fa-sharp fa-light fa-user-bounty-hunter',
    USER_CHECK = 'fa-sharp fa-light fa-user-check',
    USER_CHEF = 'fa-sharp fa-light fa-user-chef',
    USER_CLOCK = 'fa-sharp fa-light fa-user-clock',
    USER_COWBOY = 'fa-sharp fa-light fa-user-cowboy',
    USER_CROWN = 'fa-sharp fa-light fa-user-crown',
    USER_DOCTOR = 'fa-sharp fa-light fa-user-doctor',
    USER_DOCTOR_HAIR = 'fa-sharp fa-light fa-user-doctor-hair',
    USER_DOCTOR_HAIR_LONG = 'fa-sharp fa-light fa-user-doctor-hair-long',
    USER_DOCTOR_MESSAGE = 'fa-sharp fa-light fa-user-doctor-message',
    USER_GEAR = 'fa-sharp fa-light fa-user-gear',
    USER_GRADUATE = 'fa-sharp fa-light fa-user-graduate',
    USER_GROUP = 'fa-sharp fa-light fa-user-group',
    USER_GROUP_CROWN = 'fa-sharp fa-light fa-user-group-crown',
    USER_GROUP_SIMPLE = 'fa-sharp fa-light fa-user-group-simple',
    USER_HAIR = 'fa-sharp fa-light fa-user-hair',
    USER_HAIR_BUNS = 'fa-sharp fa-light fa-user-hair-buns',
    USER_HAIR_LONG = 'fa-sharp fa-light fa-user-hair-long',
    USER_HAIR_MULLET = 'fa-sharp fa-light fa-user-hair-mullet',
    USER_HEADSET = 'fa-sharp fa-light fa-user-headset',
    USER_HELMET_SAFETY = 'fa-sharp fa-light fa-user-helmet-safety',
    USER_HOODIE = 'fa-sharp fa-light fa-user-hoodie',
    USER_INJURED = 'fa-sharp fa-light fa-user-injured',
    USER_LARGE = 'fa-sharp fa-light fa-user-large',
    USER_LARGE_SLASH = 'fa-sharp fa-light fa-user-large-slash',
    USER_LOCK = 'fa-sharp fa-light fa-user-lock',
    USER_MAGNIFYING_GLASS = 'fa-sharp fa-light fa-user-magnifying-glass',
    USER_MINUS = 'fa-sharp fa-light fa-user-minus',
    USER_MUSIC = 'fa-sharp fa-light fa-user-music',
    USER_NINJA = 'fa-sharp fa-light fa-user-ninja',
    USER_NURSE = 'fa-sharp fa-light fa-user-nurse',
    USER_NURSE_HAIR = 'fa-sharp fa-light fa-user-nurse-hair',
    USER_NURSE_HAIR_LONG = 'fa-sharp fa-light fa-user-nurse-hair-long',
    USER_PEN = 'fa-sharp fa-light fa-user-pen',
    USER_PILOT = 'fa-sharp fa-light fa-user-pilot',
    USER_PILOT_TIE = 'fa-sharp fa-light fa-user-pilot-tie',
    USER_PLUS = 'fa-sharp fa-light fa-user-plus',
    USER_POLICE = 'fa-sharp fa-light fa-user-police',
    USER_POLICE_TIE = 'fa-sharp fa-light fa-user-police-tie',
    USER_ROBOT = 'fa-sharp fa-light fa-user-robot',
    USER_ROBOT_XMARKS = 'fa-sharp fa-light fa-user-robot-xmarks',
    USER_SECRET = 'fa-sharp fa-light fa-user-secret',
    USER_SHAKESPEARE = 'fa-sharp fa-light fa-user-shakespeare',
    USER_SHIELD = 'fa-sharp fa-light fa-user-shield',
    USER_SLASH = 'fa-sharp fa-light fa-user-slash',
    USER_TAG = 'fa-sharp fa-light fa-user-tag',
    USER_TIE = 'fa-sharp fa-light fa-user-tie',
    USER_TIE_HAIR = 'fa-sharp fa-light fa-user-tie-hair',
    USER_TIE_HAIR_LONG = 'fa-sharp fa-light fa-user-tie-hair-long',
    USER_UNLOCK = 'fa-sharp fa-light fa-user-unlock',
    USER_VISOR = 'fa-sharp fa-light fa-user-visor',
    USER_VNECK = 'fa-sharp fa-light fa-user-vneck',
    USER_VNECK_HAIR = 'fa-sharp fa-light fa-user-vneck-hair',
    USER_VNECK_HAIR_LONG = 'fa-sharp fa-light fa-user-vneck-hair-long',
    USER_XMARK = 'fa-sharp fa-light fa-user-xmark',
    UTENSILS = 'fa-sharp fa-light fa-utensils',
    UTENSILS_SLASH = 'fa-sharp fa-light fa-utensils-slash',
    UTILITY_POLE = 'fa-sharp fa-light fa-utility-pole',
    UTILITY_POLE_DOUBLE = 'fa-sharp fa-light fa-utility-pole-double',
    V = 'fa-sharp fa-light fa-v',
    VACUUM = 'fa-sharp fa-light fa-vacuum',
    VACUUM_ROBOT = 'fa-sharp fa-light fa-vacuum-robot',
    VALUE_ABSOLUTE = 'fa-sharp fa-light fa-value-absolute',
    VAN_SHUTTLE = 'fa-sharp fa-light fa-van-shuttle',
    VAULT = 'fa-sharp fa-light fa-vault',
    VECTOR_CIRCLE = 'fa-sharp fa-light fa-vector-circle',
    VECTOR_POLYGON = 'fa-sharp fa-light fa-vector-polygon',
    VECTOR_SQUARE = 'fa-sharp fa-light fa-vector-square',
    VENT_DAMPER = 'fa-sharp fa-light fa-vent-damper',
    VENUS = 'fa-sharp fa-light fa-venus',
    VENUS_DOUBLE = 'fa-sharp fa-light fa-venus-double',
    VENUS_MARS = 'fa-sharp fa-light fa-venus-mars',
    VEST = 'fa-sharp fa-light fa-vest',
    VEST_PATCHES = 'fa-sharp fa-light fa-vest-patches',
    VIAL = 'fa-sharp fa-light fa-vial',
    VIALS = 'fa-sharp fa-light fa-vials',
    VIAL_CIRCLE_CHECK = 'fa-sharp fa-light fa-vial-circle-check',
    VIAL_VIRUS = 'fa-sharp fa-light fa-vial-virus',
    VIDEO = 'fa-sharp fa-light fa-video',
    VIDEO_ARROW_DOWN_LEFT = 'fa-sharp fa-light fa-video-arrow-down-left',
    VIDEO_ARROW_UP_RIGHT = 'fa-sharp fa-light fa-video-arrow-up-right',
    VIDEO_PLUS = 'fa-sharp fa-light fa-video-plus',
    VIDEO_SLASH = 'fa-sharp fa-light fa-video-slash',
    VIHARA = 'fa-sharp fa-light fa-vihara',
    VIOLIN = 'fa-sharp fa-light fa-violin',
    VIRUS = 'fa-sharp fa-light fa-virus',
    VIRUSES = 'fa-sharp fa-light fa-viruses',
    VIRUS_COVID = 'fa-sharp fa-light fa-virus-covid',
    VIRUS_COVID_SLASH = 'fa-sharp fa-light fa-virus-covid-slash',
    VIRUS_SLASH = 'fa-sharp fa-light fa-virus-slash',
    VOICEMAIL = 'fa-sharp fa-light fa-voicemail',
    VOLCANO = 'fa-sharp fa-light fa-volcano',
    VOLLEYBALL = 'fa-sharp fa-light fa-volleyball',
    VOLUME = 'fa-sharp fa-light fa-volume',
    VOLUME_HIGH = 'fa-sharp fa-light fa-volume-high',
    VOLUME_LOW = 'fa-sharp fa-light fa-volume-low',
    VOLUME_OFF = 'fa-sharp fa-light fa-volume-off',
    VOLUME_SLASH = 'fa-sharp fa-light fa-volume-slash',
    VOLUME_XMARK = 'fa-sharp fa-light fa-volume-xmark',
    VR_CARDBOARD = 'fa-sharp fa-light fa-vr-cardboard',
    W = 'fa-sharp fa-light fa-w',
    WAFFLE = 'fa-sharp fa-light fa-waffle',
    WAGON_COVERED = 'fa-sharp fa-light fa-wagon-covered',
    WALKER = 'fa-sharp fa-light fa-walker',
    WALKIE_TALKIE = 'fa-sharp fa-light fa-walkie-talkie',
    WALLET = 'fa-sharp fa-light fa-wallet',
    WAND = 'fa-sharp fa-light fa-wand',
    WAND_MAGIC = 'fa-sharp fa-light fa-wand-magic',
    WAND_MAGIC_SPARKLES = 'fa-sharp fa-light fa-wand-magic-sparkles',
    WAND_SPARKLES = 'fa-sharp fa-light fa-wand-sparkles',
    WAREHOUSE = 'fa-sharp fa-light fa-warehouse',
    WAREHOUSE_FULL = 'fa-sharp fa-light fa-warehouse-full',
    WASHING_MACHINE = 'fa-sharp fa-light fa-washing-machine',
    WATCH = 'fa-sharp fa-light fa-watch',
    WATCH_APPLE = 'fa-sharp fa-light fa-watch-apple',
    WATCH_CALCULATOR = 'fa-sharp fa-light fa-watch-calculator',
    WATCH_FITNESS = 'fa-sharp fa-light fa-watch-fitness',
    WATCH_SMART = 'fa-sharp fa-light fa-watch-smart',
    WATER = 'fa-sharp fa-light fa-water',
    WATERMELON_SLICE = 'fa-sharp fa-light fa-watermelon-slice',
    WATER_ARROW_DOWN = 'fa-sharp fa-light fa-water-arrow-down',
    WATER_ARROW_UP = 'fa-sharp fa-light fa-water-arrow-up',
    WATER_LADDER = 'fa-sharp fa-light fa-water-ladder',
    WAVE = 'fa-sharp fa-light fa-wave',
    WAVEFORM = 'fa-sharp fa-light fa-waveform',
    WAVEFORM_LINES = 'fa-sharp fa-light fa-waveform-lines',
    WAVES_SINE = 'fa-sharp fa-light fa-waves-sine',
    WAVE_PULSE = 'fa-sharp fa-light fa-wave-pulse',
    WAVE_SINE = 'fa-sharp fa-light fa-wave-sine',
    WAVE_SQUARE = 'fa-sharp fa-light fa-wave-square',
    WAVE_TRIANGLE = 'fa-sharp fa-light fa-wave-triangle',
    WEBHOOK = 'fa-sharp fa-light fa-webhook',
    WEIGHT_HANGING = 'fa-sharp fa-light fa-weight-hanging',
    WEIGHT_SCALE = 'fa-sharp fa-light fa-weight-scale',
    WHALE = 'fa-sharp fa-light fa-whale',
    WHEAT = 'fa-sharp fa-light fa-wheat',
    WHEAT_AWN = 'fa-sharp fa-light fa-wheat-awn',
    WHEAT_AWN_CIRCLE_EXCLAMATION = 'fa-sharp fa-light fa-wheat-awn-circle-exclamation',
    WHEAT_AWN_SLASH = 'fa-sharp fa-light fa-wheat-awn-slash',
    WHEAT_SLASH = 'fa-sharp fa-light fa-wheat-slash',
    WHEELCHAIR = 'fa-sharp fa-light fa-wheelchair',
    WHEELCHAIR_MOVE = 'fa-sharp fa-light fa-wheelchair-move',
    WHISKEY_GLASS = 'fa-sharp fa-light fa-whiskey-glass',
    WHISKEY_GLASS_ICE = 'fa-sharp fa-light fa-whiskey-glass-ice',
    WHISTLE = 'fa-sharp fa-light fa-whistle',
    WIFI = 'fa-sharp fa-light fa-wifi',
    WIFI_EXCLAMATION = 'fa-sharp fa-light fa-wifi-exclamation',
    WIFI_FAIR = 'fa-sharp fa-light fa-wifi-fair',
    WIFI_SLASH = 'fa-sharp fa-light fa-wifi-slash',
    WIFI_WEAK = 'fa-sharp fa-light fa-wifi-weak',
    WIND = 'fa-sharp fa-light fa-wind',
    WINDOW = 'fa-sharp fa-light fa-window',
    WINDOW_FLIP = 'fa-sharp fa-light fa-window-flip',
    WINDOW_FRAME = 'fa-sharp fa-light fa-window-frame',
    WINDOW_FRAME_OPEN = 'fa-sharp fa-light fa-window-frame-open',
    WINDOW_MAXIMIZE = 'fa-sharp fa-light fa-window-maximize',
    WINDOW_MINIMIZE = 'fa-sharp fa-light fa-window-minimize',
    WINDOW_RESTORE = 'fa-sharp fa-light fa-window-restore',
    WINDSOCK = 'fa-sharp fa-light fa-windsock',
    WIND_TURBINE = 'fa-sharp fa-light fa-wind-turbine',
    WIND_WARNING = 'fa-sharp fa-light fa-wind-warning',
    WINE_BOTTLE = 'fa-sharp fa-light fa-wine-bottle',
    WINE_GLASS = 'fa-sharp fa-light fa-wine-glass',
    WINE_GLASS_CRACK = 'fa-sharp fa-light fa-wine-glass-crack',
    WINE_GLASS_EMPTY = 'fa-sharp fa-light fa-wine-glass-empty',
    WON_SIGN = 'fa-sharp fa-light fa-won-sign',
    WORM = 'fa-sharp fa-light fa-worm',
    WREATH = 'fa-sharp fa-light fa-wreath',
    WREATH_LAUREL = 'fa-sharp fa-light fa-wreath-laurel',
    WRENCH = 'fa-sharp fa-light fa-wrench',
    WRENCH_SIMPLE = 'fa-sharp fa-light fa-wrench-simple',
    X = 'fa-sharp fa-light fa-x',
    XMARK = 'fa-sharp fa-light fa-xmark',
    XMARKS_LINES = 'fa-sharp fa-light fa-xmarks-lines',
    XMARK_LARGE = 'fa-sharp fa-light fa-xmark-large',
    XMARK_TO_SLOT = 'fa-sharp fa-light fa-xmark-to-slot',
    X_RAY = 'fa-sharp fa-light fa-x-ray',
    Y = 'fa-sharp fa-light fa-y',
    YEN_SIGN = 'fa-sharp fa-light fa-yen-sign',
    YIN_YANG = 'fa-sharp fa-light fa-yin-yang',
    Z = 'fa-sharp fa-light fa-z'
}