import { ILanguageProvider } from '../language';
import { IAppMenuHandler, IMenuBarHandler } from './interfaces';
import { AppMenuItem } from './models';

/**
 * Helper class to render and handle the menu bar.
 * 
 * @export
 * @class MenuBarHandler
 * @implements {IMenuBarHandler}
 */
export class MenuBarHandler implements IMenuBarHandler {
  /**
   * Callback to perform when the save button is pressed.
   * Returns a Promise that resolves once saving is complete.
   * 
   * 
   * @memberof MenuBarHandler
   */
  public onSave?: () => Promise<void>;
  /**
   * Callback to perform when the edit mode is enabled.
   * 
   * 
   * @memberof MenuBarHandler
   */
  public onEnableEdit?: () => void;
  /**
   * Callback to perform when the edit mode is disabled.
   * 
   * 
   * @memberof MenuBarHandler
   */
  public onDisableEdit?: () => Promise<boolean>;
  /**
   * Callback to perform when the settings button is pressed.
   * 
   * 
   * @memberof MenuBarHandler
   */
  public onSettings?: () => void;

  // Constructor parameters
  private element: HTMLElement;
  private languageProvider: ILanguageProvider;
  private rootElement: HTMLElement;
  private appMenuHandler: IAppMenuHandler;
  private button?: HTMLElement;
  private isEditModeEnabled: boolean;
  private enableEditMenuItem?: AppMenuItem;

  // Event handler
  private clickHandler?: (e: MouseEvent) => void;

  /**
   * Creates an instance of MenuBarHandler.
   * 
   * @param {HTMLElement} element HTML element to render into.
   * @param {HTMLElement} rootElement Root HTML element to render into.
   * @param {TemplateExtension} templateExtension TemplateExtension instance to use.
   * @param {ILanguageProvider} languageProvider LanguageProvider instance to use.
   * @param {Logger} logger Logger instance to use.
   * 
   * @memberof MenuBarHandler
   */
  public constructor(element: HTMLElement, rootElement: HTMLElement, languageProvider: ILanguageProvider, appMenuHandler: IAppMenuHandler) {
    this.element = element;
    this.languageProvider = languageProvider;
    this.rootElement = rootElement;
    this.appMenuHandler = appMenuHandler;
    this.isEditModeEnabled = false;
  }

  /**
   * Sets whether the edit mode can be entered.
   * 
   * @param {boolean} isEnabled Whether the edit mode can be entered.
   * @memberof MenuBarHandler
   */
  public setEditModeEnabled(isEnabled: boolean): void {
    this.isEditModeEnabled = isEnabled;

    if (this.enableEditMenuItem) {
      this.enableEditMenuItem.isDisabled = !this.isEditModeEnabled;
      this.appMenuHandler.render();
    }
  }

  /**
   * Initializes the menu bar.
   * 
   * @param {boolean} isAdmin Whether the current user is admin.
   * @memberof MenuBarHandler
   */
  public init(isAdmin: boolean): void {
    const enableEditMenuItem = new AppMenuItem(this.languageProvider.get('framework.header.buttongroup.edit.title'));
    enableEditMenuItem.title = this.languageProvider.get('framework.header.buttongroup.edit.tooltip');
    enableEditMenuItem.onClick = () => {
      if (this.isEditModeEnabled) {
        this.saveExecute(this.onEnableEdit);
      }
    };
    this.enableEditMenuItem = enableEditMenuItem;
    if (isAdmin) {
      // NOTE: Removed for beta release. :remove-add-settings-menu
      // > const settingsMenuItem = new AppMenuItem(this.languageProvider.get('framework.header.buttongroup.settings.title'));
      // > settingsMenuItem.title = this.languageProvider.get('framework.header.buttongroup.settings.tooltip');
      // > settingsMenuItem.onClick = () => this.saveExecute(this.onSettings);
      // > this.appMenuHandler.addMenuItems([enableEditMenuItem, settingsMenuItem]);
    }
    this.appMenuHandler.addMenuItems([enableEditMenuItem]);
    this.appMenuHandler.render();
  }

  /**
   * Destroy the MenuBarHandler instance.
   * Resets HTML.
   * 
   * @memberof MenuBarHandler
   */
  public destroy(): void {
    this.appMenuHandler.clear();
    if (this.clickHandler) {
      this.element.removeEventListener('click', this.clickHandler);
    }
    this.element.innerHTML = '';
    // Remove add component button
    if (this.button && this.rootElement.contains(this.button)) {
      this.rootElement.removeChild(this.button);
    }
  }

  /**
   * Savely executes a function by checking if it exists and is executable.
   * 
   * @private
   * @param {() => void} [fn] Function to execute after checking.
   * 
   * @memberof MenuBarHandler
   */
  private saveExecute(fn?: () => Promise<boolean> | void): Promise<boolean> | void {
    if (fn && typeof fn === 'function') {
      return fn();
    }
  }
}