import { ExternalError } from './externalError';
/**
 * Thrown by the runtime only when an http request failed.
 * 
 * @export
 * @class HttpRequestError
 * @extends {Error}
 */
export class HttpRequestError extends ExternalError {

    /**
     * The http error code.
     * 
     * @type {number}
     * @memberof HttpRequestError
     */
    public status: number;

    /**
     * Creates an instance of HttpRequestError.
     * @param {number} status 
     * @param {string} [message] 
     * 
     * @memberof HttpRequestError
     */
    public constructor(status: number, message?: string) {
        super(message);
        this.name = 'HttpRequestError';
        this.status = status;
    }
}