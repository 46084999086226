import { Utils } from '../common';
import { Logger } from '../logging';
import { IConfigMapping, IMappingHandler } from './interfaces';
import { ComponentConfig, ComponentInstanceConfig } from './models';

/**
 * Handler to apply mapping to a component.
 *
 * @export
 * @class ConfigMapper
 */
export class ConfigMapper {
    private mappingHandlers: IMappingHandler<any, any>[];
    private componentConfigs: Map<string, ComponentConfig>;
    private logger: Logger;

    /**
     * Creates an instance of ConfigMapper.
     *
     * @param {Logger} logger Logger instance to use.
     * @memberof ConfigMapper
     */
    public constructor(logger: Logger) {
        this.mappingHandlers = [];
        this.componentConfigs = new Map<string, ComponentConfig>();
        this.logger = logger;
    }

    /**
     * Adds a new handler to the mapper.
     *
     * @param {IMappingHandler<any, any>} handler The handler to add.
     * @memberof ConfigMapper
     */
    public addMappingHandler(handler: IMappingHandler<any, any>): void {
        this.mappingHandlers.push(handler);
    }

    /**
     * Sets the component configs to use.
     *
     * @param {Map<string, ComponentConfig>} configs Configs to use.
     * @memberof ConfigMapper
     */
    public setComponentConfigs(configs: Map<string, ComponentConfig>): void {
        configs.forEach((config, id) => {
            this.componentConfigs.set(id, config);
        });
    }


    /**
     * Applies the given mapping for the given component config.
     * Returns the component instance config passed when no component config is found for the component ID.
     *
     * @param {ComponentInstanceConfig} componentInstanceConfig Component instance config that contains the values to map.
     * @param {IConfigMapping<any>[]} mappings The mapping information.
     * @returns {ComponentInstanceConfig} The given component instance config with the mapped configuration values.
     * @memberof ConfigMapper
     */
    public applyMapping(componentInstanceConfig: ComponentInstanceConfig, mappings: IConfigMapping<any>[]): ComponentInstanceConfig {
        const componentConfig = this.componentConfigs.get(componentInstanceConfig.component);
        if (!componentConfig) {
            this.logger.error('ConfigMapper', 'applyMapping', `No component config found for ID '${componentInstanceConfig.component}`);
            return componentInstanceConfig;
        }
        mappings.forEach((mapping) => {
            // Multiple mappings may be applied for the same configuration, e.g. search configurations must handle object types and paths
            this.applyMappingForConfiguration(componentConfig, componentInstanceConfig, mapping);
        });

        return componentInstanceConfig;
    }

    /**
     * Applies the given mapping for the given component config.
     * Will look up a matching handler based on their data types.
     *
     * @param {ComponentConfig} componentConfiguration Definition of the configuration to map. 
     * @param {ComponentInstanceConfig} componentInstanceConfig Component instance config that contains the values to map.
     * @param {IConfigMapping<any>} mapping The mapping information.
     * @returns {ComponentInstanceConfig} The given component instance config with the mapped configuration values.
     * @memberof ConfigMapper
     */
    private applyMappingForConfiguration(componentConfiguration: ComponentConfig, componentInstanceConfig: ComponentInstanceConfig, mapping: IConfigMapping<any>): ComponentInstanceConfig {
        componentConfiguration.configuration.forEach((configuration) => {
            for (const handler of this.mappingHandlers) {
                if (!Utils.Instance.isDefined(mapping.newValue) || mapping.newValue === '') {
                    continue;
                }
                if (configuration.dataType && mapping.dataType && handler.canHandleMapping(configuration.dataType, mapping.dataType)) {
                    if (Utils.Instance.isDefined(componentInstanceConfig.configuration[configuration.name])) {
                        componentInstanceConfig.configuration[configuration.name] = handler.applyMapping(componentInstanceConfig.configuration[configuration.name], mapping);
                    }
                }
            }
        });
        return componentInstanceConfig;
    }
}