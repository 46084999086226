import { UserGroupsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { Member, MemberTypes } from '../../members';
import { BaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Retruns a list of all user groups.
 *
 * @export
 * @class GetAllUserGroups
 * @extends {ServiceAction}
 */
export class GetAllUserGroups extends ServiceAction {

    /**
     * Creates an instance of GetAllUserGroups.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof GetAllUserGroups
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getAllUserGroups';
    }

    /**
     * Retruns a list of all user groups..
     *
     * @param {BaseRequestOptions} [baseRequestOptions] The request options.
     * @returns {Promise<Member[]>} A promise, which will resolve with the user groups.
     * @memberof GetAllUserGroups
     */
    public async do(baseRequestOptions?: BaseRequestOptions): Promise<Member[]> {
        return new Promise<Member[]>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer('get', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/members/GetAllUserGroups');
            this.requestExecutor.executeRequest(resourcePointer, baseRequestOptions?.requestOptions)
                .then((response: IServiceResponse<UserGroupsResponseContainerDTO>) => {
                    if (response.data && !response.data.HasErrors) {
                        resolve(response.data.Groups
                            .map((value) => {
                                const group = new Member(value.Id, value.Name, MemberTypes.group, value.Domain, value.FullName);
                                return group;
                            }));
                    } else {
                        this.logger.error('GetAllUserGroups', 'do', 'Failed to retrieve all user groups', response);
                        reject(new Error('Failed to retrieve all user groups'));
                    }
                }).catch((err) => {
                    this.logger.error('GetAllUserGroups', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}