/**
 * A collection of all pubsub event names for a component.
 *
 * @export
 * @class PubSubEventNameCollection
 */
export class PubSubEventNameCollection {

    /**
     * All publisher event names.
     *
     * @type {string[]}
     * @memberof PubSubEventNameCollection
     */
    public publisherEventNames: string[];

    /**
     * All subscriber event names.
     *
     * @type {string[]}
     * @memberof PubSubEventNameCollection
     */
    public subscriberEventNames: string[];

    /**
     * The guid of the component.
     *
     * @type {string}
     * @memberof PubSubEventNameCollection
     */
    public readonly guid: string;

    /**
     * Creates an instance of PubSubEventNameCollection.
     * @param {string} guid
     * @param {string[]} publisherEventNames
     * @param {string[]} subscriberEventNames
     * @memberof PubSubEventNameCollection
     */
    public constructor(guid: string, publisherEventNames: string[], subscriberEventNames: string[]) {
        this.guid = guid;
        this.publisherEventNames = publisherEventNames;
        this.subscriberEventNames = subscriberEventNames;
    }
}