/**
 * Representation of the system configuration.
 * 
 * @export
 * @class SystemConfig
 */
export class SystemConfig {
    /**
     * Date of the last server side cache reset.
     * 
     * @type {(Date | null)}
     * @memberof SystemConfig
     */
    public lastCacheReset: Date | null = null;
}