import { IPanelHandler } from './interfaces';

/**
 * Class to manage the side panels using Foundation's MotionUi.
 * 
 * @export
 * @class FoundationPanelHandler
 * @implements {IPanelHandler}
 */
export class FoundationPanelHandler implements IPanelHandler {

    private leftPanel: HTMLElement;
    private rightPanel: HTMLElement;
    private bottomPanel: HTMLElement;
    private jQueryLib: JQueryStatic;
    private foundationLib: FoundationSites.FoundationSitesStatic;

    /**
     * Creates an instance of FoundationPanelHandler.
     * @param {HTMLElement} rootContainer Root container that contains the panels.
     * @param {JQueryStatic} jQueryLib jQuery library to use.
     * @param {*} foundationLib Foundation library to use.
     * @memberof FoundationPanelHandler
     */
    public constructor(rootContainer: HTMLElement, jQueryLib: JQueryStatic, foundationLib: FoundationSites.FoundationSitesStatic) {
        this.foundationLib = foundationLib;
        this.jQueryLib = jQueryLib;

        const leftPanel = rootContainer.querySelector<HTMLElement>('#wd-side-panel-left');
        if (!leftPanel) {
            throw new Error('Unable to find left panel');
        }
        this.leftPanel = leftPanel;

        const rightPanel = rootContainer.querySelector<HTMLElement>('#wd-side-panel-right');
        if (!rightPanel) {
            throw new Error('Unable to find right panel');
        }
        this.rightPanel = rightPanel;

        const bottomPanel = rootContainer.querySelector<HTMLElement>('#wd-side-panel-bottom');
        if (!bottomPanel) {
            throw new Error('Unable to find bottom panel');
        }
        this.bottomPanel = bottomPanel;
    }


    /**
     * Shows all panels.
     * 
     * @returns {Promise<void>} Promise to resolve once all panels are shown.
     * @memberof FoundationPanelHandler
     */
    public async showSidePanels(): Promise<void> {
        return Promise.all([
            this.showPanel(this.leftPanel, 'slide-in-left'),
            this.showPanel(this.rightPanel, 'slide-in-right')
        ]).then(async () => this.showPanel(this.bottomPanel, 'slide-in-up'));
    }

    /**
     * Hides all panels.
     * 
     * @returns {Promise<void>} Promise to resolve once all panels are hidden.
     * @memberof FoundationPanelHandler
     */
    public async hideSidePanels(): Promise<void> {
        return Promise.all([
            this.hidePanel(this.leftPanel, 'slide-out-left'),
            this.hidePanel(this.rightPanel, 'slide-out-right'),
            this.hidePanel(this.bottomPanel, 'slide-out-down')
        ]).then(async () => Promise.resolve());
    }

    /**
     * Shows the given panel.
     * 
     * @private
     * @param {HTMLElement} panel Panel to show.
     * @param {string} animation Animation to use.
     * @returns {Promise<void>} Promise to resolve once the panel is shown.
     * @memberof FoundationPanelHandler
     */
    private async showPanel(panel: HTMLElement, animation: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.foundationLib.Motion.animateIn(this.jQueryLib(panel), animation, resolve);
        });
    }

    /**
     * Hides the given panel.
     * 
     * @private
     * @param {HTMLElement} panel Panel to hide.
     * @param {string} animation Animation to use.
     * @returns {Promise<void>} Promise to resolve once the panel is hidden.
     * @memberof FoundationPanelHandler
     */
    private async hidePanel(panel: HTMLElement, animation: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.foundationLib.Motion.animateOut(this.jQueryLib(panel), animation, resolve);
        });
    }
}