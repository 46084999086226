import { InConfig, OutConfig } from '.';

/**
 * This model describes a pubsub configuration.
 * 
 * @export
 * @class PubSubConfig
 */
export class PubSubConfig {

    /**
     * The in connections.
     * 
     * @type {InConfig[]}
     * @memberof PubSubConfig
     */
    public in: InConfig[];

    /**
     * The out connections.
     * 
     * @type {OutConfig[]}
     * @memberof PubSubConfig
     */
    public out: OutConfig[];

    /**
     * Whether to display component in PubSub pannel or not.
     * 
     * @type {boolean}
     * @memberof PubSubConfig
     */
    public displayInPubSubPannel?: boolean;

    /**
     * Creates an instance of PubSubConfig.
     *
     * @memberof PubSubConfig
     */
    public constructor() {
        this.in = new Array<InConfig>();
        this.out = new Array<OutConfig>();
        this.displayInPubSubPannel = true;
    }
}