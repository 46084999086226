
/**
 * Provides version information.
 *
 * @export
 * @class Version
 */
export class Version {

    /**
     * The product version.
     *
     * @static
     * @type {(string | null)} The product version.
     * @memberof Version
     */
    public static productVersion: string | null = require('../../application/dynamicWorkspace.json').version;

    /**
     * The product version.
     *
     * @static
     * @type {string} The framework version.
     * @memberof Version
     */
    public static frameworkVersion: string = require('../framework.json').version;

    /**
     * The build identifier.
     *
     * @static
     * @type {(string | number | null)} The build identifier or null if not an official build.
     * @memberof Version
     */
    public static buildIdentifier: string | number | null = require('../../application/dynamicWorkspace.json').buildIdentifier;

    /**
     * The minimal webservice version.
     *
     * @static
     * @type {string}
     * @memberof Version
     */
    public static minWebServiceVersion: string = require('../../application/dynamicWorkspace.json').minWebServiceVersion;

    /**
     * The maximal web service.
     *
     * @static
     * @type {(string | null)}
     * @memberof Version
     */
    public static maxWebServiceVersion: string | null = require('../../application/dynamicWorkspace.json').maxWebServiceVersion;

}