import { IActiveBarHelper } from '.';

/**
 * Helper class to handle active state via bar.
 * 
 * @export
 * @class ActiveBarHelper
 */
export class ActiveBarHelper implements IActiveBarHelper {
    private element: HTMLElement;
    private bar?: HTMLElement;

    /**
     * Creates an instance of ActiveBarHelper.
     * @param {HTMLElement} element 
     * @memberof ActiveBarHelper
     */
    public constructor(element: HTMLElement) {
        this.element = element;
        this.renderBar();
    }

    /**
     * Sets the position of the bar to match the currently active element.
     * 
     * @memberof ActiveBarHelper
     */
    public updatePosition(): void {
        const initiallyActiveElement = this.element.querySelector<HTMLElement>('.active');
        if (initiallyActiveElement) {
            this.setBarPosition(initiallyActiveElement);
        }
    }

    /**
     * Renders the bar into the element.
     * Adds event listener for 'resize' event.
     * Adds event listener for 'wd.enteredit' event.
     * 
     * 
     * @private
     * @memberof ActiveBarHelper
     */
    private renderBar(): void {
        this.bar = document.createElement('div');
        this.bar.classList.add('wd-highlight-bar');
        this.element.appendChild(this.bar);

        if ('MutationObserver' in window) {
            // Add mutation listener to update bar position
            const observer = new MutationObserver((mutationList) => {
                for (const mutation of mutationList) {
                    const element = mutation.target as HTMLElement;
                    if (mutation.type === 'childList') { // Complete re-rendering, i.e. new child nodes
                        const initiallyActiveElement = this.element.querySelector<HTMLElement>('.active');
                        if (initiallyActiveElement) {
                            this.setBarPosition(initiallyActiveElement);
                            return;
                        }
                    } else { // Attribute modification, i.e. shift of active class
                        if ((
                                element.classList.contains('wd-navigation-item')
                                || element.nodeName === 'LI'
                            ) && element.classList.contains('active')) {
                                this.setBarPosition(element);
                            return;
                        }
                    }
                }
            });
            observer.observe(this.element, { attributes: true, subtree: true, attributeFilter: ['class'], childList: true });


            if (window) {
                // Listen to edit state changes.
                window.addEventListener('wd.enteredit', () => {
                    this.updatePosition();
                }, true);

                const resizeDebounceDelay = 50;
                let timeout: any;
                window.addEventListener('resize', () => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        this.updatePosition();
                    }, resizeDebounceDelay);
                });
            }
        }
        // Set initially active element
        this.updatePosition();
    }

    /**
     * Manage state of highlight bar.
     * 
     * @private
     * @param {HTMLElement} activeElement Active element to highlight.
     * @returns {void} 
     * @memberof NavigationBarHandler
     */
    private setBarPosition(activeElement: HTMLElement): void {
        if (this.bar) {
            const x = activeElement.offsetLeft;
            const width = activeElement.offsetWidth;
            this.bar.style.transform = `translateX(${x}px)`;
            this.bar.style.width = `${width}px`;
        }
    }
}