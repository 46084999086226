import { AttributeResponseDTO, ObjectTypeResponseContainerDTO } from '../../typings/windreamWebService/Windream.WebService';
import { GetAllAttributesResponseContainerDTO } from '../../typings/windreamWebService/Windream.WebService.Attributes';
import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../config';
import { CultureHelper } from '../culture';
import { HttpResourcePointer } from '../dataProviders';
import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';
import { IMetadataStore } from './interfaces';
import { ObjectType, ObjectTypeModelConverter, WindreamAttribute, WindreamAttributeTypes } from './models';


/**
 * Helper to fetch and store meta data from the windream server.
 * Instances of this class are created with a callback parameter. This function will be called
 * once all necessary information has been fetched and is available via public variables.
 *
 * @export
 * @class WindreamMetadataStore
 */
export class WindreamMetadataStore implements IMetadataStore {

  /**
   * All available attributes within windream.
   * 
   * @type {WindreamAttribute[]}
   * @memberof WindreamMetadataStore
   */
  public availableAttributes: WindreamAttribute[];

  /**
   * All object types within windream.
   * 
   * @type {Map<number, ObjectType>}
   * @memberof WindreamMetadataStore
   */
  public objectTypes: Map<number, ObjectType>;

  /**
   * An array with all system attributes.
   *
   * @type {WindreamAttribute[]}
   * @memberof WindreamMetadataStore
   */
  public systemAttributes: WindreamAttribute[];

  /**
   * A map with object type IDs as key and a related attributes array as value.
   *
   * @type {Map<number, WindreamAttribute[]>}
   * @memberof WindreamMetadataStore
   */
  public typeSpecificAttributes: Map<number, WindreamAttribute[]>;

  /**
   * An array with all relational attributes.
   * 
   * @type {WindreamAttribute[]}@memberof WindreamMetadataStore
   * @memberof WindreamMetadataStore
   */
  public relationalAttributes: WindreamAttribute[];

  /**
   * List of all virtual attributes.
   * 
   * @type {WindreamAttribute[]}@memberof IMetadataStore
   */
  public virtualAttributes: WindreamAttribute[];

  /**
   * Gets the list of excluded system attribute names. 
   * 
   * @type {string[]}
   * @memberof WindreamMetadataStore
   */
  public readonly excludedSystemAttributeNames: string[] = ['decAccessTime', 'decChangedTime', 'dwAccessListDBID', 'dwAccessListID', 'dwArchiveDate', 'dwArchivePeriod',
    'dwCatalogID', 'dwChange_Time', 'dwCreation_Time', 'dwCreatorID', 'dwDocDBID', 'dwEditDate', 'dwEditPeriod', 'dwFlags', 'dwFlags2', 'dwFSFlags', 'dwObjectTypeDBID',
    'dwObjectTypeID', 'dwOwnerDBID', 'dwOwnerID', 'dwParentDBID', 'dwParentID', 'dwTypeAssign_Time', 'dwTypeAssignDate', 'dwUserFlags', 'dwVersionID', 'dwVersionNumber',
    'dwWorkLockUserDBID', 'dwWorkLockUserID', 'ProcessID', 'szHash-Value', 'szReference', 'szShortName', 'szUpperLongName', 'szUpperShortName', 'szWM_Store', 'Text'];
  private requestExecutor: IRequestExecutor;
  private globalConfig: GlobalConfig;
  private cultureHelper: CultureHelper;

  /**
   * Creates an instance of WindreamMetadataStore.
   * @param {GlobalConfig} globalConfig
   * @param {IRequestExecutor} requestExecutor
   * @param {CultureHelper} cultureHelper
   * @memberof WindreamMetadataStore
   */
  public constructor(globalConfig: GlobalConfig, requestExecutor: IRequestExecutor, cultureHelper: CultureHelper) {
    this.requestExecutor = requestExecutor;
    this.globalConfig = globalConfig;
    this.cultureHelper = cultureHelper;
    this.typeSpecificAttributes = new Map<number, WindreamAttribute[]>();
    this.systemAttributes = new Array<WindreamAttribute>();
    this.relationalAttributes = new Array<WindreamAttribute>();
    this.virtualAttributes = new Array<WindreamAttribute>();
    this.availableAttributes = new Array<WindreamAttribute>();
    this.objectTypes = new Map<number, ObjectType>();
  }

  /**
   * Intialize loading of all objec types and attributes;
   *
   * @returns {Promise<boolean>}  Promise to resolve with true.
   * @async
   *
   * @memberof WindreamMetadataStore
   */
  public async init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Promise.all([this.fetchObjectTypes(), this.fetchAvailableAttributes()])
        .then(() => resolve(true)).catch((error) => reject(error));
    });
  }

  /**
   * Removes attributes that are not compatible for the search.
   *
   * @param {WindreamAttribute[]} attributes Attributes to filter.
   * @returns {WindreamAttribute[]} The filtered list without any search-incompatible attributes.
   * @memberof IMetadataStore
   */
  public filterForSearch(attributes: WindreamAttribute[]): WindreamAttribute[] {
    const blacklistedAttributeNames = [
      'SubObjects' // See http://teamsrv:8080/tfs/Windream/Windream/_workitems/edit/76383
    ];
    return attributes.filter((attribute) => {
      return blacklistedAttributeNames.map((attributeName) => attributeName.toLowerCase()).indexOf(attribute.name.toLowerCase()) === -1;
    }).filter((attribute) => {
      return !(attribute.name.startsWith('##') && attribute.name.endsWith('##'));  // See http://teamsrv:8080/tfs/Windream/Windream/_workitems/edit/76383
    });
  }

  /**
   * Asynchronously fetches all object types using the windream Web API.
   *
   * @returns {Promise<Map<number, string>>} Promise to resolve with the data fetched.
   * @async
   *
   * @memberof WindreamMetadataStore
   */
  public async fetchObjectTypes(): Promise<Map<number, ObjectType>> {
    return new Promise<Map<number, ObjectType>>((resolve, reject) => {

      this.requestExecutor.executeRequest(new HttpResourcePointer('GET', this.globalConfig.windreamWebServiceURL + '/objecttypes/GetObjectTypes'))
        .then((response: IServiceResponse<ObjectTypeResponseContainerDTO[]>) => {
          if (response && response.data && response.data.length) {
            this.objectTypes = new Map<number, ObjectType>();
            response.data.sort((a, b) => {
              if (a.Item.Id === 1 || b.Item.Id === 1) {
                return 0;
              } else {
                return this.cultureHelper.sortHelper.compareStrings(a.Item.Name.toLowerCase(), b.Item.Name.toLowerCase());
              }
            });

            const objectTypeModelConverter = new ObjectTypeModelConverter();
            response.data.forEach((objectType) => {
              const type = objectTypeModelConverter.convertFromDTO(objectType);
              this.objectTypes.set(type.id, type);
            });

            resolve(this.objectTypes);
          } else {
            reject(new Error('Failed to get object types'));
          }
        }).catch((err: Error) => {
          reject(err);
        });
    });
  }

  /**
   * Asynchronously fetches all available attributes using the windream Webservice.
   *
   * @returns {Promise<WindreamAttribute[]>} Promise to resolve with the fetched attributes.
   * @async
   *
   * @memberof WindreamMetadataStore
   */
  public async fetchAvailableAttributes(): Promise<WindreamAttribute[]> {
    return new Promise<WindreamAttribute[]>((resolve, reject) => {
      this.requestExecutor.executeRequest(new HttpResourcePointer('GET', this.globalConfig.windreamWebServiceURL + '/attributes/GetAllAttributes'))
        .then((response: IServiceResponse<GetAllAttributesResponseContainerDTO>) => {

          if (response) {
            const resultData = response.data;
            if (!resultData) {
              reject(false);
            } else {
              this.availableAttributes = new Array<WindreamAttribute>();

              // Process object type specific attributes
              if (resultData.TypeSpecificAttributes) {
                resultData.TypeSpecificAttributes.forEach((objectType) => {
                  if (objectType.Item && objectType.Item.Attributes && objectType.Item.Attributes.length > 0) {

                    const tempTypeSpecificAttributes = this.convertAttributes(WindreamAttributeTypes.TypeSpecific, objectType.Item.Attributes, objectType.Item.Id);
                    this.typeSpecificAttributes.set(objectType.Item.Id, tempTypeSpecificAttributes);

                    tempTypeSpecificAttributes.forEach((tempAttribute) => {
                      this.availableAttributes.push(tempAttribute);
                    });
                  }
                });

              }

              // Process stystem attributes
              if (resultData.SystemAttributes) {
                resultData.SystemAttributes.forEach((systemAttribute) => {
                  const tempWindreamAttribute = this.convertAttribute(WindreamAttributeTypes.System, systemAttribute);
                  this.availableAttributes.push(tempWindreamAttribute);
                  this.systemAttributes.push(tempWindreamAttribute);
                });
              }

              // Process relational attributes
              if (resultData.RelationalAttributes) {
                resultData.RelationalAttributes.forEach((relationalAttribute) => {
                  const tempWindreamAttribute = this.convertAttribute(WindreamAttributeTypes.Relational, relationalAttribute);
                  this.availableAttributes.push(tempWindreamAttribute);
                  this.relationalAttributes.push(tempWindreamAttribute);
                });
              }

              // Process virtual attributes
              if (resultData.VirtualAttributes) {
                resultData.VirtualAttributes.forEach((virtualAttribute) => {
                  const tempWindreamAttribute = this.convertAttribute(WindreamAttributeTypes.Virtual, virtualAttribute);
                  this.availableAttributes.push(tempWindreamAttribute);
                  this.virtualAttributes.push(tempWindreamAttribute);
                });
              }

              resolve(this.availableAttributes);
            }
          }

        }).catch((err: Error) => {
          reject(err);
        });
    });
  }

  /**
   * Gets the systemAttributes with filter.
   * 
   * @returns {WindreamAttribute[]} 
   * @memberof WindreamMetadataStore
   */
  public filterSystemAttributes(): WindreamAttribute[] {
    return this.systemAttributes.filter((attribute) => {
      return this.excludedSystemAttributeNames.indexOf(attribute.name) === -1;
    });
  }
  /**
   * Converts a given Web Servie attribute type to the internal used attribute type.
   * 
   * @private
   * @param {WindreamAttributeTypes} attributeType 
   * @param {Windream.Attributes.DTO.AttributeResponseDTO} attribute 
   * @param {number} [objectTypeId] 
   * @returns {WindreamAttribute} 
   * @memberof WindreamMetadataStore
   */
  private convertAttribute(attributeType: WindreamAttributeTypes, attribute: AttributeResponseDTO, objectTypeId?: number): WindreamAttribute {

    const result = WindreamAttribute.fromDto(attribute);
    result.objectTypeId = typeof objectTypeId === 'number' ? objectTypeId : 0;
    result.attributeType = attributeType;
    return result;
  }

  /**
   * Converts a given array of Web Servie attribute types to an array of the internal used attribute types.
   * 
   * @private
   * @param {WindreamAttributeTypes} attributeType 
   * @param {Windream.Attributes.DTO.AttributeResponseDTO[]} attributes 
   * @param {number} [objectTypeId] 
   * @returns {WindreamAttribute[]} 
   * @memberof WindreamMetadataStore
   */
  private convertAttributes(attributeType: WindreamAttributeTypes, attributes: AttributeResponseDTO[], objectTypeId?: number): WindreamAttribute[] {

    const result: WindreamAttribute[] = [];

    attributes.forEach((attribute: AttributeResponseDTO) => {
      result.push(this.convertAttribute(attributeType, attribute, objectTypeId));
    });

    return result;
  }

}