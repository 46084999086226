import { ILanguageProvider, Utils } from '../dynamicWorkspace';
import { IPopupButtonOptions } from './interfaces';
import { IPopup } from './interfaces/iPopup';
import { IPopupButton } from './interfaces/iPopupButton';
import { IPopupOptions } from './interfaces/iPopupOptions';
import { POPUP_TYPES } from './models';
import { PopupButton } from './popupButton';

export class Popup implements IPopup {

    /**
     * The closed event handler.
     *
     * @memberof Popup
     */
    public onClosed?: (e?: Event) => void;

    /**
     * The wrapped root element.
     *
     * @type {JQuery<HTMLElement>} The wrapped element.
     * @memberof Popup
     */
    public $element?: JQuery<HTMLElement>;

    /**
     * The popup options.
     *
     * @type {IPopupOptions}
     * @memberof Popup
     */
    public options: IPopupOptions;

    private languageProvider: ILanguageProvider;
    private jQuery: JQueryStatic;
    private rootElement?: HTMLElement;
    private modal: any;
    private modalContent?: HTMLDivElement;
    private foundationLib: any;
    private buttons: Map<string, IPopupButton>;
    private isPopupOpen: boolean;


    /**
     * Determines whether the popup is currently open.
     *
     * @readonly
     * @type {boolean}
     * @memberof Popup
     */
    public get isOpen(): boolean {
        return this.isPopupOpen;
    }


    /**
     * Creates an instance of Popup.
     *
     * @param {IPopupOptions} options The popup options.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {JQueryStatic} jQuery The jQuery reference.
     * @param {*} foundationLib Foundation.
     * @memberof Popup
     */
    public constructor(options: IPopupOptions, languageProvider: ILanguageProvider, jQuery: JQueryStatic, foundationLib: any) {
        this.isPopupOpen = false;
        this.options = options;
        this.languageProvider = languageProvider;
        this.jQuery = jQuery;
        this.foundationLib = foundationLib;

        this.buttons = new Map<string, IPopupButton>();

        this.rootElement = document.createElement(this.options.wrapInForm ? 'form' : 'div');

        this.$element = this.jQuery(this.rootElement);

        let popupSize = this.options.size;
        if (!popupSize) {
            popupSize = 'medium';     // Default
        }

        const modalContainer = document.createElement('div');
        modalContainer.classList.add('wd-modal-container');
        this.rootElement.classList.add('wd-modal', 'reveal', popupSize);
        if (this.options.allowOverflow) {
            this.rootElement.classList.add('wd-allow-overflow');
        }
        if (this.options.displayBodyOnly) {
            this.rootElement.classList.add('wd-modal-body-only');
        }
        modalContainer.classList.add('wd-modal-container');
        const modalHeadingContainer = document.createElement('div');
        modalHeadingContainer.classList.add('wd-modal-heading-container');
        modalContainer.appendChild(modalHeadingContainer);
        // If title option is set, add title text to heading
        if (this.options.title && !this.options.displayBodyOnly) {
            const modalHeading: HTMLDivElement = document.createElement('div');
            // Add a11y by referencing heading
            const modalHeadingId = `wd-modal-heading-${Utils.Instance.getRandomString()}`;
            this.rootElement.setAttribute('aria-labelledby', modalHeadingId);
            modalHeading.classList.add('wd-modal-heading');
            modalHeading.innerHTML = `<h2 id="${modalHeadingId}">${Utils.Instance.escapeStringValue(this.options.title)}</h2>`;
            modalHeadingContainer.appendChild(modalHeading);
        } else {
            this.rootElement.classList.add('no-title');
        }

        if (!this.options.isModal) {
            // Close button
            const modalCloseButton = document.createElement('button');
            modalCloseButton.innerHTML = '<span aria-hidden="true">&times;</span>';
            modalCloseButton.setAttribute('title', this.languageProvider.get('framework.generic.close'));
            modalCloseButton.setAttribute('data-close', '');
            modalCloseButton.type = 'button';
            modalCloseButton.classList.add('close-button');
            modalCloseButton.onclick = () => {
                this.close();
            };
            modalHeadingContainer.appendChild(modalCloseButton);
        }

        // Modal content
        this.modalContent = document.createElement('div');
        this.modalContent.classList.add('wd-modal-content');
        if (Utils.Instance.isHTMLElement(this.options.body)) {
            this.jQuery(this.options.body).appendTo(this.modalContent);
        } else if (typeof (this.options.body) === 'function') {
            this.jQuery(this.options.body(this.jQuery)).appendTo(this.modalContent);
        } else {
            this.modalContent.innerHTML = this.options.body;
        }
        // Add icons if defined
        if (typeof this.options.type === 'number') {
            const modalContentIconContainer: HTMLDivElement = document.createElement('div');
            modalContentIconContainer.classList.add('wd-modal-icon-content-container');
            modalContentIconContainer.appendChild(this.getIconForType(this.options.type));
            this.rootElement.classList.add('has-icon');
            modalContentIconContainer.appendChild(this.modalContent);
            modalContainer.appendChild(modalContentIconContainer);
        } else {
            modalContainer.appendChild(this.modalContent);
        }
        this.rootElement.appendChild(modalContainer);

        if (this.options.buttons && !this.options.displayBodyOnly) {
            // Modal footer
            const modalFooter: HTMLDivElement = document.createElement('div');
            modalFooter.classList.add('wd-modal-footer');

            const modalFooterButtons: HTMLDivElement = document.createElement('div');
            modalFooterButtons.classList.add('wd-button-group');
            if (this.options.stackButtons) {
                modalFooterButtons.classList.add('wd-stack-item');
            }
            modalFooter.appendChild(modalFooterButtons);

            // Create buttons
            this.options.buttons.forEach((buttonOptions: IPopupButtonOptions) => {
                const buttonTargetElement = document.createElement('div');
                const popupButton = new PopupButton(buttonOptions, this, buttonTargetElement, this.jQuery);
                popupButton.$element.appendTo(modalFooterButtons);

                // If the button has an identifier, a reference should be saved for later access.
                if (buttonOptions.buttonId) {
                    this.buttons.set(buttonOptions.buttonId, popupButton);
                }
            });
            this.rootElement.appendChild(modalFooter);
        }

        if (this.foundationLib.Reveal) {
            this.modal = new this.foundationLib.Reveal(this.jQuery(this.rootElement), {
                animationIn: 'scale-in-up',
                animationOut: 'scale-out-down',
                closeOnClick: typeof this.options.closeOnClick !== 'undefined' ? this.options.closeOnClick : true,
                closeOnEsc: this.options.closeOnEsc ? this.options.closeOnEsc : false // Filter out undefined
            });
        }

        // Destroy modal on close
        this.$element.on('closed.zf.reveal', () => {
            if (typeof this.options.onClose === 'function') {
                this.options.onClose();
            }
            if (this.options.destroyOnClose) {
                this.destroy(this.options.destroyModalAlways);
            }
        });
        this.$element.on('open.zf.reveal', () => {
            if (typeof this.options.onOpen === 'function') {
                this.options.onOpen();
            }
        });
        if (this.options.wrapInForm) {
            this.rootElement.addEventListener('submit', (event) => {
                event.stopPropagation();
                event.preventDefault();
                if (this.options.buttons) {
                    if (this.options.buttons.length > 0) {
                        const buttonCallback = this.options.buttons[0].callback;
                        if (buttonCallback) {
                            buttonCallback(this.options.buttons[0].label);
                        }
                    }
                }
                if (this.options.destroyOnClose) {
                    this.destroy();
                }
            });
        }
        if (this.options.isTopMost) {
            const desiredIndex = 2000;
            if (this.$element && this.$element.parent()) {
                this.$element.parent().css('z-index', desiredIndex);
            }
        }
    }


    /**
     * Gets called after a popup button was clicked.
     *
     * @param {IPopupButton} button The clicked button.
     * @memberof Popup
     */
    public onButtonClicked(button: IPopupButton): void {
        if (this.options.destroyOnButtonClick) {
            this.destroy();
        }

        if (button.options && button.options.eventName) {
            if (this.modalContent && this.modalContent.firstChild) {
                this.jQuery(this.modalContent.firstChild).children().trigger(button.options.eventName, button.$element);
            }
        }
    }

    /**
     * Closes the popup.
     *
     * @memberof Popup
     */
    public close(): void {
        if (this.modal) {
            this.modal.close();
            this.isPopupOpen = false;
            if (this.onClosed) {
                this.onClosed();
            }
        }
    }

    /**
     * Toggles the open/closed state of a modal.
     *
     * @memberof Popup
     */
    public toggle(): void {
        if (this.modal) {
            this.modal.toggle();

            this.isPopupOpen = !this.isPopupOpen;
        }
    }

    /**
     * Destroys the popup.
     *
     * @param {boolean} destroyModalAlways Whether the modal (reveal element) should be destroyed even if not active.
     * @memberof Popup
     */
    public destroy(destroyModalAlways?: boolean): void {
        if (this.modal && (this.modal.isActive || destroyModalAlways)) {
            this.modal.destroy();
        }

        if (this.rootElement) {
            this.rootElement.remove();
            this.rootElement = undefined;
        }

        this.isPopupOpen = false;

        this.buttons.clear();
    }

    /**
     * Opens the popup.
     *
     * @memberof Popup
     */
    public open(): void {
        if (this.modal) {
            this.modal.open();

            this.isPopupOpen = true;
        }
    }

    /**
     * Gets the root element.
     *
     * @returns {HTMLElement} The root element.
     * @memberof Popup
     */
    public getRootElement(): HTMLElement | undefined {
        return this.rootElement;
    }

    /**
     * Gets an element by the given identifier.
     *
     * @param {string} id The element identifier.
     * @returns {(HTMLElement | undefined)} The found element.
     * @memberof Popup
     */
    public getElementById(id: string): HTMLElement | undefined {
        if (this.rootElement) {
            return <HTMLElement>this.rootElement.querySelector('#' + id);
        }
        return undefined;
    }

    /**
     * Sets the title.
     *
     * @param {string} title The title.
     * @memberof Popup
     */
    public setTitle(title: string): void {
        if (this.$element && this.$element[0]) {
            const modalElement = this.$element[0] as HTMLElement;
            const titleElement = modalElement.querySelector<HTMLElement>('.wd-modal-heading h2');
            if (titleElement) {
                titleElement.textContent = title;
            }
        }
    }

    /**
     * Gets a popup button by the given identifier.
     *
     * @param {string} id The button identifier.
     * @returns {(IPopupButton | undefined)} The button instance.
     * @memberof Popup
     */
    public getPopupButtonById(id: string): IPopupButton | undefined {
        if (this.buttons && this.buttons.has(id)) {
            return this.buttons.get(id);
        }
        return undefined;
    }

    /**
     * Returns a HTML Element representing the given popup type.
     *
     * @private
     * @param {POPUP_TYPES} type Type to get the icon for.
     * @returns {HTMLElement}   HTML Element representing the icon.
     *
     * @memberof PopupHelper
     */
    private getIconForType(type: POPUP_TYPES): HTMLElement {
        const icon = document.createElement('span');
        icon.classList.add('wd-icon');
        switch (type) {
            case POPUP_TYPES.CRITICAL:
                icon.classList.add('critical-image');
                icon.title = this.languageProvider.get('framework.generic.critical');
                break;
            case POPUP_TYPES.INFO:
                icon.classList.add('info-image');
                icon.title = this.languageProvider.get('framework.generic.info');
                break;
            case POPUP_TYPES.QUESTION:
                icon.classList.add('help-image');
                icon.title = this.languageProvider.get('framework.generic.question');
                break;
            case POPUP_TYPES.WARNING:
                icon.classList.add('warning-image');
                icon.title = this.languageProvider.get('framework.generic.warning');
                break;
        }
        return icon;
    }

}