/**
 * Represents a pubsub name migration object.
 *
 * @export
 * @class PubSubNameMigration
 */
export class PubSubNameMigration {

    /**
     * The new name for the event.
     *
     * @type {string}
     * @memberof PubSubNameMigration
     */
    public newName: string;
    private _previousName: string;

    /**
     * Gets the previous name.
     *
     * @readonly
     * @type {string}
     * @memberof PubSubNameMigration
     */
    public get previousName(): string {
        return this._previousName;
    }

    /**
     * Creates an instance of PubSubNameMigration.
     * @param {string} previousName
     * @memberof PubSubNameMigration
     */
    public constructor(previousName: string) {
        this._previousName = previousName;
        this.newName = previousName;
    }
}