import { AppMenuItem } from '.';

/**
 * View model for the AppMenu.
 * 
 * @export
 * @class AppMenuViewModel
 */
export class AppMenuViewModel {
    /**
     * Menu items of the AppMenu.
     * 
     * @type {AppMenuItem[]}
     * @memberof AppMenuViewModel
     */
    public menuItems: AppMenuItem[];

    /**
     * Unique ID of the AppMenu.
     * 
     * @type {string}
     * @memberof AppMenuViewModel
     */
    public uuid: string;

    /**
     * Creates an instance of AppMenuViewModel.
     *
     * @param {string} uuid
     * @memberof AppMenuViewModel
     */
    public constructor(uuid: string) {
        this.uuid = uuid;
        this.menuItems = new Array<AppMenuItem>();
    }
}