import { MoveDocumentFlags, ResponseDetailsType, WindreamIdentity } from '../../common';
import { MoveBulkRequestOptions } from './moveBulkRequestOptions';

/**
 * Represents move document bulk request options.
 *
 * @export
 * @class MoveDocumentBulkRequestOptions
 * @extends {MoveBulkRequestOptions}
 */
export class MoveDocumentBulkRequestOptions extends MoveBulkRequestOptions {

    /**
     * The flags.
     *
     * @type {MoveDocumentFlags}
     * @memberof MoveDocumentBulkRequestOptions
     */
    public flags: MoveDocumentFlags;

    /**
     * Creates an instance of MoveDocumentBulkRequestOptions.
     * @param {WindreamIdentity[]} sourceIdentities
     * @param {WindreamIdentity} targetDirectory
     * @param {ResponseDetailsType} responseDetails
     * @param {MoveDocumentFlags} flags
     * @memberof MoveDocumentBulkRequestOptions
     */
    public constructor(sourceIdentities: WindreamIdentity[], targetDirectory: WindreamIdentity,
        responseDetails: ResponseDetailsType, flags: MoveDocumentFlags) {
        super(sourceIdentities, targetDirectory, responseDetails);
        if (typeof flags !== 'number') {
            throw new Error('Argument \'flags\' is not valid');
        }
        this.flags = flags;
    }
}