import { SortingDirection } from './sortingDirection';

/**
 * Sorting options.
 *
 * @export
 * @class Sorting
 */
export class Sorting {
    /**
     * Attribute to sort by.
     *
     * @type {string}
     * @memberof Sorting
     */
    public attributeName: string;
    /**
     * Direction to sort.
     *
     * @type {SortingDirection}
     * @memberof Sorting
     */
    public direction: SortingDirection;

    /**
     * Creates an instance of Sorting.
     *
     * @param {string} attributeName
     * @param {SortingDirection} direction
     * @memberof Sorting
     */
    public constructor(attributeName: string, direction: SortingDirection) {
        this.attributeName = attributeName;
        this.direction = direction;
    }
}