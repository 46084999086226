
/**
 * Base class for all argument exceptions.
 * 
 * @export
 * @class ArgumentError
 * @extends {Error}
 */
export class ArgumentError extends Error {

    /**
     * Creates an instance of ArgumentError.
     * @param {string} [message] 
     * 
     * @memberof ArgumentError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'ArgumentError';
    }
}