import { GridStack, GridStackOptions, GridStackWidget } from 'gridstack/dist/es5/gridstack';
import { Utils } from '../common';
import { ComponentConfig, ComponentInstanceConfig, Position, Style } from '../config';
import { LayoutConfiguration, LayoutManager } from '.';
const GridStackImport: any = require('gridstack/dist/es5/gridstack');


/**
 * LayoutManager that displays a single row of entries.
 * 
 * @export
 * @class RowLayoutManager
 * @extends {LayoutManager}
 */
export class RowLayoutManager extends LayoutManager {
    private className: string = 'RowLayoutManager';
    private gridAttrPrefix: string = 'gs-';
    private isBatchUpdateActive: boolean = false;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private logicGridColumnsAmount: number = 6;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private logicalGridCellHeight: number = 90;


    /**
     * Removes a component from the grid.
     * 
     * @param {string} componentGuid GUID of the component to remove.
     * 
     * @memberof LayoutManager
     */
    public removeComponent(componentGuid: string): void {
        const gridItem = this.layoutNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (gridItem) {
            this.getGridInstance()?.removeWidget(<HTMLElement>gridItem);
        } else {
            throw new Error(`No element for component with GUID ${componentGuid} found.`);
        }
    }

    /**
     * Prepares the layout by added a grid node to the root element.
     *
     * @memberof LayoutManager
     */
    public prepareLayout(): void {
        this.rootNode.innerHTML = '';

        const gridWrapper = document.createElement('div');
        gridWrapper.classList.add('grid-wrapper', 'grid-logic');

        this.layoutNode = document.createElement('div');
        this.layoutNode.classList.add('grid');
        // Prevent anything from being dropped into the main grid expect Girdstack items
        this.layoutNode.addEventListener('drop', (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        });

        this.layoutNode.setAttribute(this.gridAttrPrefix + 'w', '6');
        gridWrapper.appendChild(this.layoutNode);

        this.rootNode.appendChild(gridWrapper);

        this.containerNodes = new Array<HTMLElement>();
        this.isInitialized = false;
    }

    /**
     * Renders a container for the given component.
     * Adds relevant attributes to the element so that the grid can be used.
     * Wraps the given element in a separate container.
     *
     * @param {ComponentInstanceConfig} componentInstanceConfig Configuration for the component instance.
     * @param {ComponentConfig} componentConfig The component config.
     * @param {HTMLElement} element  Container to render the component in.
     * @param {HTMLDivElement} _toolbarcontainer The toolbar container.
     * @param {(newComponentInstanceConfig: ComponentInstanceConfig) => void} [callback]  Callback to be called after loading with updated configuration (i.e. new position).
     * @memberof RowLayoutManager
     */
    // tslint:disable-next-line:cyclomatic-complexity No need to refactor just for error handling
    public renderContainer(componentInstanceConfig: ComponentInstanceConfig,
        componentConfig: ComponentConfig, element: HTMLElement, _toolbarcontainer: HTMLDivElement,
        callback?: (newComponentInstanceConfig: ComponentInstanceConfig) => void): void {
        const gridItem = document.createElement('div');
        if (componentInstanceConfig.guid) {
            gridItem.setAttribute('data-guid', componentInstanceConfig.guid);
        } else {
            this.logger.error(this.className, 'renderContainer', 'ComponentInstanceCOnfig has no GUID set', componentInstanceConfig);
        }
        if (componentInstanceConfig.component) {
            gridItem.setAttribute('data-component', componentInstanceConfig.component);
        } else {
            this.logger.error(this.className, 'renderContainer', 'ComponentInstanceCOnfig has no component set', componentInstanceConfig);
        }
        gridItem.classList.add('grid-stack-item', 'wd-logic-component');

        const backgroundElement = document.createElement('div');
        backgroundElement.classList.add('grid-stack-item-content');

        element.classList.add('component-content');

        // Try to set the component's colors
        if (componentInstanceConfig && componentInstanceConfig.style) {
            if (!componentInstanceConfig.style) {
                componentInstanceConfig.style = Style.default();
            }
            backgroundElement.classList.add(componentInstanceConfig.style.colors ? componentInstanceConfig.style.colors : '');
        }

        this.containerNodes.push(gridItem);
        gridItem.setAttribute(this.gridAttrPrefix + 'maxH', '1');
        gridItem.setAttribute(this.gridAttrPrefix + 'maxW', '1');
        gridItem.setAttribute(this.gridAttrPrefix + 'minH', '1');
        gridItem.setAttribute(this.gridAttrPrefix + 'minW', '1');
        if (componentInstanceConfig.position) { // If position is set, use it to render the component there
            if (!this.isInitialized) { // If grid is not intialized, render containers into DOM
                if (typeof componentInstanceConfig.position !== 'string') {
                    if (Utils.Instance.isDefined(componentInstanceConfig.position.x)) {
                        gridItem.setAttribute(this.gridAttrPrefix + 'x', componentInstanceConfig.position.x.toString());
                    }
                    if (Utils.Instance.isDefined(componentInstanceConfig.position.y)) {
                        gridItem.setAttribute(this.gridAttrPrefix + 'y', componentInstanceConfig.position.y.toString());
                    }
                    gridItem.setAttribute(this.gridAttrPrefix + 'w', '1');
                    gridItem.setAttribute(this.gridAttrPrefix + 'h', '1');
                }
                this.layoutNode.appendChild(gridItem);
            } else { // If grid is initialized, use Gridstack API
                const position = <Position>componentInstanceConfig.position;

                const gridStackWidget: GridStackWidget = {
                    autoPosition: false,
                    x: position.x,
                    y: position.y,
                    w: position.width,
                    h: position.height,
                };
                this.getGridInstance()?.off('added');
                this.getGridInstance()?.addWidget(<any>gridItem, gridStackWidget);
                this.updateLayout();
                this.getGridInstance()?.on('added', (event: any, items: any) => this.addedItemsToGrid(event, items));
            }
        } else { // If position is not set (i.e. null), the component was added after page load (i.e. new component) and should be placed using Gridstack API
            const defaultSize = 2; // Size to use if default width or height are not defined
            const defaultWidth = componentConfig.size?.defaultWidth || defaultSize;
            const defaultHeight = componentConfig.size?.defaultHeight || defaultSize;

            const gridStackWidget: GridStackWidget = {
                autoPosition: true,
                x: undefined,
                y: undefined,
                w: defaultWidth,
                h: defaultHeight
            };
            const newComponentElement = this.getGridInstance()?.addWidget(<any>gridItem, gridStackWidget);

            if (!newComponentElement) {
                return;
            }

            // After placing it, read the new position and give it back using the callback
            const newPosition = new Position();
            const height = newComponentElement.getAttribute(this.gridAttrPrefix + 'h');
            if (!height) {
                throw new Error('Failed to read height property for component.');
            }
            newPosition.height = parseInt(height, 10);
            const width = newComponentElement.getAttribute(this.gridAttrPrefix + 'w');
            if (!width) {
                throw new Error('Failed to read width property for component.');
            }
            newPosition.width = parseInt(width, 10);
            const x = newComponentElement.getAttribute(this.gridAttrPrefix + 'x');
            if (!x) {
                throw new Error('Failed to read x property for component.');
            }
            newPosition.x = parseInt(x, 10);
            const y = newComponentElement.getAttribute(this.gridAttrPrefix + 'y');
            if (!y) {
                throw new Error('Failed to read y property for component.');
            }
            newPosition.y = parseInt(y, 10);

            const newComponentInstanceConfig = componentInstanceConfig;
            newComponentInstanceConfig.position = newPosition;

            if (callback && typeof callback === 'function') {
                callback(newComponentInstanceConfig);
            }
        }

        const hasErrors = componentConfig.id === 'com.windream.error';
        // Add header to component
        this.addComponentHeader(backgroundElement, true, hasErrors);

        backgroundElement.appendChild(element);
        gridItem.appendChild(backgroundElement);

        if (componentInstanceConfig.guid) {
            const fallbackTitle = '<Title>';
            const fallbackName = '<Name>';
            const title = hasErrors ?
                (Utils.Instance.isDefined(componentInstanceConfig.component) ? componentInstanceConfig.component : fallbackTitle) : this.getTranslatedOrEmpty(componentInstanceConfig.title);
            const name = hasErrors ?
                (Utils.Instance.isDefined(componentInstanceConfig.component) ? componentInstanceConfig.component : fallbackName) : this.getTranslatedOrEmpty(componentInstanceConfig.name);
            const isTitleVisible = false;
            this.updateComponentTitle(componentInstanceConfig.guid, name,
                title, isTitleVisible);
        } else {
            this.logger.error(this.className, 'renderContainer', 'ComponentInstanceCOnfig has no GUID set', componentInstanceConfig);
        }
    }

    /**
     * Initialize the layout by invoking Gridstack if Edit-Mode is active.
     * Will also disable the grid if invoked and Edit-Mode is not acive.
     */
    public initializeLayout(): void {
        if (!this.isInitialized) { // If editable and grid was not already initialized, initialize it

            const options: GridStackOptions = {
                acceptWidgets: '.wd-logic-component',
                cellHeight: this.logicalGridCellHeight,
                column: this.logicGridColumnsAmount,
                disableResize: true,
                draggable: {
                    appendTo: 'body',
                    handle: '.header-editable',
                    scroll: false
                },
                float: false,
                margin: '4px 3px',
                sizeToContent: false
            };

            const grid = GridStackImport.GridStack.init(options, this.layoutNode);

            grid.on('dragstop resizestop', (_e: any, _ui: any) => {
                // Note: When the events are fired, the gs-* attributes are not yet updated!
            });

            grid.on('dropped', () => {
                // Fix drop issue for GridStack@1.2.1: If a component was dragged and dropped, two instead of one element were added.

                // Search for invalid items.
                const invalidItems = this.layoutNode.querySelectorAll('.grid-stack-item:not([data-guid])');

                // Try to remove all found invalid items.
                if (invalidItems) {
                    for (let i = 0; i < invalidItems.length; i++) {
                        invalidItems.item(i).remove();
                    }
                }
            });

            grid.on('added', (_event: any, items: any) => this.addedItemsToGrid(_event, items));

            this.isInitialized = true;
        }

        this.setEditable(this.isEditable);
    }

    /**
     * Sets editable property.
     * 
     * @param {boolean} editable Whether the layout is editable.
     */
    public setEditable(editable: boolean) {
        this.isEditable = editable;

        if (this.isEditable) { // If editable, enable it
            this.getGridInstance()?.enableMove(true, true);
            this.layoutNode.setAttribute('data-editable', 'true');
        } else { // If not editable, disable it
            this.getGridInstance()?.enableMove(false, false);
            this.layoutNode.setAttribute('data-editable', 'false');
        }
    }

    /**
     * Destroy the Layout Manager and deletes everything in the root node.
     */
    public destroy(): void {
        try {
            this.getGridInstance()?.destroy();
        } catch (err) { // If no instance is found, nothing is there to destroy
            this.logger.debug(this.className, 'destroy', 'Grid instance already destroyed', err);
        }
        this.clear();
    }


    /**
     * Returns the current positions of each component inside the layout.
     * 
     * @returns {LayoutConfiguration} The current layout configuration.
     * @memberof GridLayoutManager
     */
    public getComponentPositions(): LayoutConfiguration {
        const configuration = {} as LayoutConfiguration;

        this.containerNodes.forEach((gridItem: HTMLElement) => {
            const guid = gridItem.getAttribute('data-guid');

            const attrHeight = gridItem.getAttribute(this.gridAttrPrefix + 'h') || '0';
            const attrWidth = gridItem.getAttribute(this.gridAttrPrefix + 'w') || '0';
            const attrX = gridItem.getAttribute(this.gridAttrPrefix + 'x') || '0';
            const attrY = gridItem.getAttribute(this.gridAttrPrefix + 'y') || '0';

            if (guid) {
                configuration[guid] = {
                    height: parseInt(attrHeight, 10),
                    width: parseInt(attrWidth, 10),
                    x: parseInt(attrX, 10),
                    y: parseInt(attrY, 10)
                };
            } else {
                this.logger.warn(this.className, 'getGridConfiguration', 'Grid Item has no "data-guid" attribute!', gridItem);
            }
        });

        return configuration;
    }

    /**
     * Intialize the layout.
     * 
     * @protected
     * @memberof RowLayoutManager
     */
    protected init() {
        this.isBatchUpdateActive = false;
    }

    /**
     * Updates the layout after changes were made.
     * 
     * @protected
     * @memberof RowLayoutManager
     */
    protected updateLayout(): void {
        if (this.isBatchUpdateActive === true) {
            this.getGridInstance()?.commit();
            this.isBatchUpdateActive = false;
        }
    }

    /**
     * Returns the current GridStack instance.
     * 
     * @private
     * @returns {GridStack | null} Current GridStack instance.
     * 
     * @memberof LayoutManager
     */
    private getGridInstance(): GridStack | null {
        const instance = GridStackImport.GridStack.init(undefined, this.layoutNode);
        if (!instance) {
            this.logger.warn(this.className, 'getGridInstance', 'GridStack instance not found.');
            return null;
        }
        return instance;
    }


    /**
     * Function which will handle the added items on the grid.
     *
     * @private
     * @param {*} _event The event.
     * @param {*} items The items which were added.
     * @memberof RowLayoutManager
     */
    private addedItemsToGrid(_event: any, items: any): void {
        if (Utils.Instance.isArray(items)) {
            const itemsCount = items.length;
            for (let i = 0; i < itemsCount; i++) {

                const item = items[i];
                if (!item) {
                    continue;
                }

                const componentId = item.el.getAttribute('data-wd-component-id');
                if (!componentId) {
                    continue;
                }

                const position: Position = {
                    height: item.height,
                    width: item.width,
                    x: item.x,
                    y: item.y
                };

                if (componentId) {
                    const grid = this.getGridInstance();

                    if (grid) {
                        grid.batchUpdate();
                        this.isBatchUpdateActive = true;

                        grid.removeWidget(item.el);

                        this.addComponentDragDrop(componentId, position);
                    }
                }
            }
        }
    }
}
