import { SubViewType } from '../../../typings/core';
import { IPopup } from '../ui/interfaces/iPopup';

/**
 * The PopupStackItem describes an opened sub view popup.
 * 
 * @export
 * @class PopupStackItem
 */
export class PopupStackItem {
    /**
     * Will become true if triggered by resetTrigger.
     * Used to remember the state between resetTrigger and the popup's onClose callback.
     *
     * @type {boolean}
     * @memberof PopupStackItem
     */
    public closedByReset: boolean;

    /**
     * The id of the sub view that will become visible by the transition that triggered the reset.
     * Used to remember the state between resetTrigger and the popup's onClose callback.
     * 
     * @type {string | null}
     * @memberof PopupStackItem
     */
    public closedByResetToSubViewId: string | null;

    /**
     * The popup id.
     * 
     * @type {string}
     * @memberof PopupStackItem
     */
    public readonly id: string;

    /**
     * The popup instance.
     * 
     * @type {IPopup}
     * @memberof PopupStackItem
     */
    public readonly popup: IPopup;

    /**
     * The id of the subview that is displayed in the popup.
     * 
     * @type {string}
     * @memberof PopupStackItem
     */
    public readonly subViewId: string;

    /**
     * The id of the sub view that was opened before the popup.
     * 
     * @type {string | null}
     * @memberof PopupStackItem
     */
    public readonly subViewIdBeforePopup: string | null;

    /**
     * The type of the sub view that was opened before the popup.
     * 
     * @type {SubViewType}
     * @memberof PopupStackItem
     */
    public readonly subViewTypeBeforePopup: SubViewType;

    /**
     * Creates an instance of PopupStackItem.
     * 
     * @param {string} id The id of the popup.
     * @param {IPopup} popup The popup instance.
     * @param {string} subViewId The id of the subview that is displayed in the popup.
     * @param {(string| null)} subViewIdBeforePopup The id of the sub view that was opened before.
     * @param {SubViewType} subViewTypeBeforePopup The type of the sub view that was opened before the popup.
     * @memberof PopupStackItem
     */
    public constructor(id: string, popup: IPopup, subViewId: string, subViewIdBeforePopup: string| null, subViewTypeBeforePopup: SubViewType) {
        this.closedByReset = false;
        this.closedByResetToSubViewId = null;
        this.id = id;
        this.popup = popup;
        this.subViewId = subViewId;
        this.subViewIdBeforePopup = subViewIdBeforePopup;
        this.subViewTypeBeforePopup = subViewTypeBeforePopup;
    }
}