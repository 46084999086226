import { Base64 } from '../common/index';
import { WindreamTokenHeader, WindreamTokenPayload } from './index';

/**
 * The windream token.
 *
 * @export
 * @class WindreamToken
 */
export class WindreamToken {
    /**
     * Represents the header of this token.
     *
     * @type {WindreamTokenHeader}
     * @memberof WindreamToken
     */
    public header: WindreamTokenHeader;

    /**
     * Represents the payload of this token.
     *
     * @type {WindreamTokenPayload}
     * @memberof WindreamToken
     */
    public payload: WindreamTokenPayload;
    /**
     * Creates an instance of WindreamToken, will throw an error if token is not in a valid format.
     * @param {string} tokenString
     * @memberof WindreamToken
     */
    public constructor(tokenString: string) {
        if (!tokenString) {
            throw new Error('No token passed');
        }
        const base64 = new Base64();
        const parts = tokenString.split('.');
        const validTokenPathCount = 3;
        if (parts.length !== validTokenPathCount) {
            throw new Error('Received token is not in a valid format');
        }
        try {
            this.header = JSON.parse(base64.decode(tokenString.split('.')[0]));
        } catch {
            throw new Error('Received token has invalid header');
        }
        try {
            this.payload = JSON.parse(base64.decode(tokenString.split('.')[1]));
        } catch {
            throw new Error('Received token has invalid payload');
        }

    }
}