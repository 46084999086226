import { SharedSettingsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig, ISharedSettings } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { BaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform saving of views on the windream webservice.
 * 
 * @export
 * @class GetGlobalSharedSettings
 * @extends {ServiceAction}
 */
export class GetGlobalSharedSettings extends ServiceAction {
    /**
     * Creates an instance of GetGlobalSharedSettings.
     *
     * @param {IRequestExecutor} requestExecutor Request Executor instance to use.
     * @param {GlobalConfig} globalConfig Global Config instance to use.
     * @param {Logger} logger Logger instance to use.
     * @memberof GetGlobalSharedSettings
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'getGlobalSharedSettings';
    }

    /**
     * Gets the global shared settings..
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {BaseRequestOptions} [requestOptions] Request options to apply.
     * @returns {Promise<ISharedSettings>} A promise, which will resolve with the settings.
     * @memberof GetGlobalSharedSettings
     */
    public async do(requestOptions?: BaseRequestOptions): Promise<ISharedSettings> {
        return new Promise<ISharedSettings>((resolve, reject) => {
            this.requestExecutor.executeRequest(new HttpResourcePointer('GET', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/shared/GetSettings'), requestOptions?.requestOptions)
                .then((response: IServiceResponse<SharedSettingsResponseContainerDTO>) => {
                    if (response.data?.HasErrors || !response.data) {
                        this.logger.error('GetGlobalSharedSettings', 'do', 'Failed to get global shared settings', response);
                        reject(new Error('Failed to get global shared settings'));
                    } else {
                        resolve(response.data.Settings as ISharedSettings || {});
                    }
                }).catch((err: Error) => {
                    this.logger.error('GetGlobalSharedSettings', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}