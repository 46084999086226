import { PlaceholderConfig } from 'typings/core';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging/logger';
import { IUiManager } from './interfaces';

/**
 * UI Manager to handle component display state.
 * @exports UiManager
 * @version 1.0.0
 * @example //Create new instance
 * let uiManager = new UiManager();
 */
export class UiManager implements IUiManager {
    private rootNode: HTMLElement;
    private lockContainerNode?: HTMLElement;
    private className: string = 'UiManager';
    private logger: Logger;
    private jQueryRef: JQueryStatic;
    private languageProvider?: ILanguageProvider;

    // Loading
    private APP_LOADING_CONTAINER_ID: string = 'APP_LOADING_CONTAINER'; // Id of the loading container in the Maps below
    private readonly MIN_LOADING_TIME: number = 300; // Minimum duration the loading screen is visible
    private loadingContainerPerComponent: Map<string, Element> = new Map<string, Element>();
    private loadingStartTimePerComponent: Map<string, Date> = new Map<string, Date>();
    private errorContainerPerComponent: Map<string, Element> = new Map<string, Element>();
    private placeholderContainerPerComponent: Map<string, Element> = new Map<string, Element>();
    private bannerContainerPerComponent: Map<string, Element> = new Map<string, Element>();
    private readonly loadingContainerHeight: number = 50;
    private timeoutsMap: Map<string, number> = new Map<string, number>();
    private customLoadingAnimationHtml?: string;

    /**
     * Generates a new instance of the UiManager.
     * Searches for the initial loading container (`.loading-container`) and will use this as a template for all other loading containers.
     * Adds intial loading container to the Map of loading containers per component with the ID `APP_LOADING_CONTAINER_ID`.
     * 
     * @param {HTMLElement} rootNode
     * @param {Logger} logger
     * @param {JQueryStatic} jQueryRef
     * @memberof UiManager
     */
    public constructor(rootNode: HTMLElement, logger: Logger, jQueryRef: JQueryStatic) {
        this.jQueryRef = jQueryRef;
        this.logger = logger;
        this.rootNode = rootNode;
    }

    /**
     * Sets whole application in loading state.
     * Invokes `setLoading` with `rootNode` as `node`.
     */
    public appIsLoading(): void {
        this.rootNode.classList.add('is-loading');
        this.setLoading(this.rootNode, this.APP_LOADING_CONTAINER_ID);
    }

    /**
     * Sets whole application in available state.
     * Invokes `removeLoading` with `rootNode` as `node`.
     */
    public appIsAvailable(): void {
        this.rootNode.classList.remove('is-loading');
        this.removeLoading(this.rootNode, this.APP_LOADING_CONTAINER_ID);
    }

    /**
     * Sets single component, and its mirror counterpart if it is being mirrored, in loading state.
     * Removes any banners, errors and loading containers for the component, and its mirror counterpart if it is being mirrored.
     * Invokes `setLoading` with the component container (queried by `data-guid` attribute) as `node`.
     *
     * @param {string} componentGuid GUID of the component to set into loading state.
     */
    public isLoading(componentGuid: string): void {
        this.isAvailable(componentGuid, true);
        this.removeError(componentGuid);
        this.removeBanner(componentGuid);
        this.removePlaceholder(componentGuid);

        const mirrorComponentGridStackContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentGridStackContainer) {
            const mirrorTargetGuid = mirrorComponentGridStackContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                this.setLoading(mirrorComponentGridStackContainer, mirrorTargetGuid);
            }
        }

        const componentGridStackContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentGridStackContainer) {
            this.setLoading(componentGridStackContainer, componentGuid);
        } else {
            this.logger.warn(this.className, 'isLoading', 'No component grid stack container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Sets single component, and its mirror counterpart if it is being mirrored, in available state.
     * Invokes `removeLoading` with the component, and its mirror counterpart if it is being mirrored, container (queried by `data-guid` attribute) as `node`.
     * @param {string} componentGuid GUID of the component to set into available state.
     * @param {boolean} [force=false] Whether to remove the container immediately.
     * @memberof UiManager
     */
    public isAvailable(componentGuid: string, force = false): void {
        const mirrorComponentGridStackContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentGridStackContainer) {
            const mirrorTargetGuid = mirrorComponentGridStackContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                // Try to remove loading from element above
                this.removeLoading(mirrorComponentGridStackContainer, mirrorTargetGuid, force);
            }
        }

        const componentGridStackContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentGridStackContainer) {
            // Remove error first
            this.removeLoading(componentGridStackContainer, componentGuid, force);
        } else {
            this.logger.warn(this.className, 'isAvailable', 'No component grid stack container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Add a skeleton to a component, and its mirror counterpart if it is being mirrored.
     * 
     * @param {string} componentGuid The guid of the component.
     * @memberof UiManager
     */
    public addSkeleton(componentGuid: string): void {
        const mirrorComponentContent = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentContent) {
            mirrorComponentContent.classList.add('skeleton');
        }

        const componentContent = this.rootNode.querySelector('[data-guid="' + componentGuid + '"] > .component-content');
        if (componentContent) {
            componentContent.classList.add('skeleton');
        } else {
            this.logger.warn(this.className, 'addSkeleton', 'No component container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Shows an error for the given component, and its mirror counterpart if it is being mirrored.
     * Removes any banners, errors and loading containers for the component, and its mirror counterpart if it is being mirrored.
     * 
     * @param {string} componentGuid 
     * 
     * @memberof UiManager
     */
    public displayError(componentGuid: string): void {
        this.isAvailable(componentGuid, true);
        this.removePlaceholder(componentGuid);
        this.removeBanner(componentGuid);
        this.removeError(componentGuid);

        const mirrorComponentContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentContainer) {
            const mirrorTargetGuid = mirrorComponentContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                this.setError(mirrorComponentContainer as HTMLElement, mirrorTargetGuid);
            }
        }

        const componentContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentContainer) {
            this.setError(componentContainer as HTMLElement, componentGuid);
        } else {
            this.logger.warn(this.className, 'displayError', 'No component container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Remove the error message from a specific component, and its mirror counterpart if it is being mirrored.
     *
     * @param {string} componentGuid The GUID of the component.
     * @memberof UiManager
     */
    public removeError(componentGuid: string): void {
        const mirrorComponentGridStackContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentGridStackContainer) {
            const mirrorTargetGuid = mirrorComponentGridStackContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                // Remove error first
                this.removeErrorContainer(mirrorComponentGridStackContainer, mirrorTargetGuid);
            }
        }

        const componentGridStackContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentGridStackContainer) {
            // Remove error first
            this.removeErrorContainer(componentGridStackContainer, componentGuid);
        } else {
            this.logger.warn(this.className, 'removeError', 'No component grid stack container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Display placeholder container for the given component, and its mirror counterpart if it is being mirrored.
     * Removes any banners, errors and loading containers for the component, and its mirror counterpart if it is being mirrored.
     *
     * @param {string} componentGuid GUID of the component to render placeholder for.
     * @param {string | PlaceholderConfig} placeholder The placeholder to be rendered.
     * @memberof UiManager
     */
    public displayPlaceholder(componentGuid: string, placeholder: string | PlaceholderConfig): void {
        this.removeError(componentGuid);
        this.isAvailable(componentGuid, true);
        this.removeBanner(componentGuid);
        this.removePlaceholder(componentGuid);

        const mirrorComponentGridStackContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentGridStackContainer) {
            const mirrorTargetGuid = mirrorComponentGridStackContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                this.setPlaceholderContainer(mirrorComponentGridStackContainer, mirrorTargetGuid, placeholder);
            }
        }

        const componentGridStackContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentGridStackContainer) {
            this.setPlaceholderContainer(componentGridStackContainer, componentGuid, placeholder);
        } else {
            this.logger.warn(this.className, 'isAvailable', 'No component grid stack container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Display banner for the given component, and its mirror counterpart if it is being mirrored.
     * Removes any placeholders, errors and loading containers for the component, and its mirror counterpart if it is being mirrored.
     *
     * @param {string} componentGuid GUID of the component to render banner for.
     * @param {string} text Text to render
     * @memberof UiManager
     */
    public displayBanner(componentGuid: string, text: string): void {
        this.removeError(componentGuid);
        this.isAvailable(componentGuid, true);
        this.removePlaceholder(componentGuid);
        this.removeBanner(componentGuid);

        const mirrorComponentGridStackContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentGridStackContainer) {
            const mirrorTargetGuid = mirrorComponentGridStackContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                this.setBannerContainer(mirrorComponentGridStackContainer, mirrorTargetGuid, text);
            }
        }
        const componentGridStackContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentGridStackContainer) {
            this.setBannerContainer(componentGridStackContainer, componentGuid, text);
        } else {
            this.logger.warn(this.className, 'isAvailable', 'No component grid stack container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Removes the banner for the given component, and its mirror counterpart if it is being mirrored.
     *
     * @param {string} componentGuid GUID of the component to remove banner for.
     * @memberof UiManager
     */
    public removeBanner(componentGuid: string): void {
        const mirrorComponentGridStackContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentGridStackContainer) {
            const mirrorTargetGuid = mirrorComponentGridStackContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                this.removeBannerContainer(mirrorComponentGridStackContainer, mirrorTargetGuid);
            }
        }

        const componentGridStackContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentGridStackContainer) {
            this.removeBannerContainer(componentGridStackContainer, componentGuid);
        } else {
            this.logger.warn(this.className, 'removeError', 'No component grid stack container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Removes the placeholder for the given component, and its mirror counterpart if it is being mirrored.
     *
     * @param {string} componentGuid GUID of the component to remove placeholder for.
     * @memberof UiManager
     */
    public removePlaceholder(componentGuid: string): void {
        const mirrorComponentGridStackContainer = this.rootNode.querySelector('[data-steal-guid="' + componentGuid + '"]');
        if (mirrorComponentGridStackContainer) {
            const mirrorTargetGuid = mirrorComponentGridStackContainer.getAttribute('data-guid');
            if (mirrorTargetGuid) {
                this.removePlaceholderContainer(mirrorComponentGridStackContainer, mirrorTargetGuid);
            }
        }

        const componentGridStackContainer = this.rootNode.querySelector('[data-guid="' + componentGuid + '"]');
        if (componentGridStackContainer) {
            // Remove error first
            this.removePlaceholderContainer(componentGridStackContainer, componentGuid);
        } else {
            this.logger.warn(this.className, 'removeError', 'No component grid stack container found for GUID: ' + componentGuid);
        }
    }

    /**
     * Destroys the UI Manager.
     */
    public destroy(): void {
        // Re-insert HTML of loading container
        this.appIsLoading();
    }

    /**
     * Adds busy state to the application.
     * Use `appIsIdle()` to remove this state.
     * 
     * @memberof UiManager
     */
    public appIsBusy(): void {
        this.rootNode.classList.add('wd-busy');
    }

    /**
     * Display the busy state indicator and disable every click on sub elements.
     *
     * @memberof UiManager
     */
    public displayBusyStateIndicator(): void {
        const busyStateIndicatorContainer = this.rootNode.querySelector<HTMLElement>('.wd-busy-indicator-container');
        if (busyStateIndicatorContainer) {
            busyStateIndicatorContainer.hidden = false;
            this.rootNode.setAttribute('aria-busy', 'true');
        }
    }

    /**
     * Remove the busy state indicator.
     *
     * @memberof UiManager
     */
    public removeBusyStateIndicator(): void {
        const busyStateIndicatorContainer = this.rootNode.querySelector<HTMLElement>('.wd-busy-indicator-container');
        if (busyStateIndicatorContainer) {
            busyStateIndicatorContainer.hidden = true;
            this.rootNode.removeAttribute('aria-busy');
        }
    }

    /**
     * Removes busy state from the application.
     * 
     * @memberof UiManager
     */
    public appIsIdle(): void {
        this.rootNode.classList.remove('wd-busy');
    }

    /**
     * Sets the LanguageProvider instance to use.
     *
     * @param {ILanguageProvider} languageProvider
     * @memberof UiManager
     */
    public setLanguageProvider(languageProvider: ILanguageProvider): void {
        this.languageProvider = languageProvider;
    }

    /**
     * Locks the current view.
     *
     * @param {string} reason The reason why the view has been locked.
     * @memberof UiManager
     */
    public lockView(reason: string): void {
        // Hide all sub views
        const subViews = document.querySelectorAll('.sub-view');
        if (subViews && subViews.length > 0) {
            subViews.forEach((subView) => {
                subView.classList.add('hidden');
            });
        }

        // Show the view lock container
        this.showViewLockContainer(reason);
    }

    /**
     * Unlocks the current view.
     *
     * @memberof UiManager
     */
    public unlockView(): void {
        // Show all sub views
        const subViews = document.querySelectorAll('.sub-view');
        if (subViews && subViews.length > 0) {
            subViews.forEach((subView) => {
                subView.classList.remove('hidden');
            });
        }

        // Hide the view lock container
        this.hideViewLockContainer();
    }

    /**
     * Sets the custom loading animation.
     *
     * @param {string} htmlString The html string.
     * @memberof UiManager
     */
    public setCustomLoadingAnimation(htmlString: string): void {
        this.customLoadingAnimationHtml = htmlString;
    }

    /**
     * Shows the view lock container.
     *
     * @private
     * @param {string} reason
     * @memberof UiManager
     */
    private showViewLockContainer(reason: string): void {
        // Add the view lock container.
        if (this.lockContainerNode) {
            this.logger.warn('uiManager', 'showViewLockContainer', 'View lock container exists.', 'Can not show the view lock container, because another lock container already exists.');
            return;
        }

        if (this.rootNode) {
            this.lockContainerNode = document.createElement('div');
            this.lockContainerNode.classList.add('wd-placeholder-container');
            this.lockContainerNode.innerHTML = reason;
            this.rootNode.append(this.lockContainerNode);
        }
    }

    /**
     * Hides the view lock container.
     *
     * @private
     * @memberof UiManager
     */
    private hideViewLockContainer(): void {
        if (this.lockContainerNode) {
            this.lockContainerNode.remove();
            delete this.lockContainerNode;
        }
    }

    /**
     * Sets the placeholder container to the given node with the given text.
     * Adds `has-placeholder` class to the corresponding container.
     *
     * @private
     * @param {Element} node Node to add the placeholder to.
     * @param {string} id GUID of the component.
     * @param {string | PlaceholderConfig} placeholder the placeholder.
     * @memberof UiManager
     */
    private setPlaceholderContainer(node: Element, id: string, placeholder: string | PlaceholderConfig): void {
        if (!this.placeholderContainerPerComponent.get(id)) {
            const placeholderContainer = this.createPlaceholderContainer(placeholder);
            this.placeholderContainerPerComponent.set(id, placeholderContainer);

            const target = this.getGridItemContentContainer(node);
            target.appendChild(placeholderContainer);
            target.classList.add('has-placeholder');
            target.setAttribute('aria-hidden', 'true');
        }
    }

    /**
     * Sets the banner container to the given node with the given text.
     * Adds `has-banner` class to the corresponding container.
     *
     * @private
     * @param {Element} node Node to add the banner to.
     * @param {string} id GUID of the component.
     * @param {string} text Text of the banner.
     * @memberof UiManager
     */
    private setBannerContainer(node: Element, id: string, text: string): void {
        if (!this.bannerContainerPerComponent.get(id)) {
            const bannerContainer = this.createBannerContainer(text);
            this.bannerContainerPerComponent.set(id, bannerContainer);

            const target = this.getGridItemContentContainer(node);
            const componentContentContainer = target.querySelector('.component-content');
            target.insertBefore(bannerContainer, componentContentContainer);
            target.classList.add('has-banner');
        }
    }

    /**
     * Removes the banner from the given node.
     *
     * @private
     * @param {Element} node Node to add the banner to.
     * @param {string} id GUID of the component.
     * @memberof UiManager
     */
    private removeBannerContainer(node: Element, id: string): void {
        if (this.bannerContainerPerComponent.has(id)) {
            const bannerContainer = this.bannerContainerPerComponent.get(id);
            if (bannerContainer) {
                bannerContainer.remove();
            }
            this.bannerContainerPerComponent.delete(id);

            const target = this.getGridItemContentContainer(node);
            target.classList.remove('has-banner');
        }
    }

    /**
     * Removes the placeholder from the given node.
     *
     * @private
     * @param {Element} node Node to add the placeholder to.
     * @param {string} id GUID of the component.
     * @memberof UiManager
     */
    private removePlaceholderContainer(node: Element, id: string): void {
        if (this.placeholderContainerPerComponent.has(id)) {
            const placeholderContainer = this.placeholderContainerPerComponent.get(id);
            if (placeholderContainer) {
                placeholderContainer.remove();
            }
            this.placeholderContainerPerComponent.delete(id);

            const target = this.getGridItemContentContainer(node);
            target.classList.remove('has-placeholder');
            target.removeAttribute('aria-hidden');
        }
    }

    /**
     * Removes error container from the given node.
     * 
     * @protected
     * @param {Element} node Node to remove error container from.
     * @param {string} id GUID of the component the container belongs to.
     * 
     * @memberof UiManager
     */
    private removeErrorContainer(node: Element, id: string) {
        if (this.errorContainerPerComponent.has(id)) {
            const errorContainer = this.errorContainerPerComponent.get(id);
            if (errorContainer) {
                errorContainer.remove();
            }
            this.errorContainerPerComponent.delete(id);

            const target = this.getGridItemContentContainer(node);
            target.classList.remove('error');
            target.removeAttribute('aria-hidden');
        }
    }

    /**
     * Renders a loading container into the given node.
     * Also handles ARIA attributes accordingly.
     * Adds newly created loading container into the `loadingContainerPerComponent` Map.
     * Adds time of loading start into the `loadingStartTimePerComponent` Map.
     * @param {Element} node Container to render the loading container into.
     * @param {string}  id   ID that is used in the Maps. GUID for components, `APP_LOADING_CONTAINER_ID` for whole application.
     */
    private setLoading(node: Element, id: string) {
        if (!this.loadingContainerPerComponent.get(id)) {
            let loadingContainer: HTMLElement;
            if (id === this.APP_LOADING_CONTAINER_ID) {
                loadingContainer = this.createAppLoadingContainer();
            } else {
                loadingContainer = this.createComponentLoadingContainer();
            }
            this.loadingContainerPerComponent.set(id, loadingContainer);

            const target = this.getGridItemContentContainer(node);
            target.appendChild(loadingContainer);
            target.classList.add('loading');
            target.setAttribute('aria-hidden', 'true');

            this.loadingStartTimePerComponent.set(id, new Date());
        }
    }

    /**
     * Removes the loading container from the given node.
     * Also handles ARIA attributes accordingly.
     * Removes loading container from the `loadingContainerPerComponent` Map.
     * Removes time of loading start from the `loadingStartTimePerComponent` Map.
     * Removing may be delayed so that the total duration of the loading process will take `MIN_LOADING_TIME` milliseconds.
     * @param {Element} node Container to remove the loading container from.
     * @param {string}  id   ID that is used in the Maps. GUID for components, `APP_LOADING_CONTAINER_ID` for whole application.
     * @param {boolean} [force=false] Whether to force the container to be removed immideately.
     * @memberof UiManager
     */
    private removeLoading(node: Element, id: string, force: boolean = false) {
        if (this.loadingContainerPerComponent.get(id)) {
            const loadingStartTime = this.loadingStartTimePerComponent.get(id) || new Date();
            // Make loading screen visible for at least MIN_LOADING_TIME ms
            const timeDiff: number = (new Date()).getMilliseconds() - loadingStartTime.getMilliseconds(),

                hide = () => {
                    if (this.loadingContainerPerComponent.has(id)) {
                        const loadingContainer = this.loadingContainerPerComponent.get(id);
                        if (loadingContainer) {
                            loadingContainer.remove();
                        }
                    }

                    this.loadingContainerPerComponent.delete(id);

                    const target = this.getGridItemContentContainer(node);
                    target.classList.remove('loading');
                    target.removeAttribute('aria-hidden');
                };
            const previousTimeout = this.timeoutsMap.get(id);
            if (previousTimeout) {
                clearTimeout(previousTimeout);
            }
            if (timeDiff > this.MIN_LOADING_TIME || force) {
                hide();
            } else {
                this.timeoutsMap.set(id, setTimeout(hide, this.MIN_LOADING_TIME - timeDiff) as unknown as number);
            }
        }
    }

    /**
     * Creates a loading container for single components.
     * Will use DevExtreme to create a loading indicator..
     * Creates a new element from a template string otherwise.
     * @return {HTMLElement} Created loading container.
     */
    private createComponentLoadingContainer(): HTMLElement {
        const loadingContainerWidth = this.loadingContainerHeight;
        const loadingContainer = document.createElement('div');
        const spinnerContainer = document.createElement('div');
        loadingContainer.classList.add('loading-container');
        loadingContainer.appendChild(spinnerContainer);
        this.jQueryRef(spinnerContainer).dxLoadIndicator({
            height: this.loadingContainerHeight,
            width: loadingContainerWidth
        });

        return loadingContainer;
    }

    /**
     * Creates a loading container for the app.
     * Will use the template cloned into `loadingContainerTemplate` if available.
     * Creates a new element from a template string otherwise.
     * @return {HTMLElement} Created loading container.
     */
    private createAppLoadingContainer(): HTMLElement {
        const loadingContainer = document.createElement('div');
        loadingContainer.classList.add('loading-container');
        if (this.customLoadingAnimationHtml) {
            loadingContainer.innerHTML = this.customLoadingAnimationHtml;
        } else {
            loadingContainer.innerHTML = require('./resources/loading.html');
        }
        return loadingContainer;
    }

    /**
     * Sets the error container for the given node.
     * 
     * @private
     * @param {HTMLElement} node Node to add error container to.
     * @param {string} id GUID of the component the container belongs to.
     * 
     * @memberof UiManager
     */
    private setError(node: HTMLElement, id: string): void {
        const errorContainer = this.createErrorContainer();
        this.errorContainerPerComponent.set(id, errorContainer);

        const target = this.getGridItemContentContainer(node);
        target.appendChild(errorContainer);
        target.classList.add('error');
        target.setAttribute('aria-hidden', 'true');
    }

    /**
     * Creates an error container.
     * 
     * @private
     * @returns {HTMLElement} Generated error container.
     * 
     * @memberof UiManager
     */
    private createErrorContainer(): HTMLElement {
        const errorContainer = document.createElement('div');
        errorContainer.classList.add('error-container');
        errorContainer.innerHTML = require('./resources/error.html');
        const content = errorContainer.querySelector<HTMLElement>('h2');
        if (content && this.languageProvider) {
            content.textContent = this.languageProvider.get('framework.generic.anErrorOccured');
        }
        return errorContainer;
    }

    /**
     * Creates a placeholder container with the given text.
     *
     * @private
     * @param {string | PlaceholderConfig} [placeholder] The placeholder to be rendered. If omitted, default text will be used.
     * @returns {HTMLElement} The container.
     * @memberof UiManager
     */
    private createPlaceholderContainer(placeholder?: string | PlaceholderConfig): HTMLElement {
        const container = document.createElement('div');
        container.classList.add('wd-placeholder-container');
        if (typeof (placeholder) === 'string') {
            placeholder = placeholder as string;
            const content = document.createElement('p');
            if (!placeholder) {
                if (this.languageProvider) {
                    placeholder = this.languageProvider.get('framework.dataCollection.noData');
                } else {
                    placeholder = 'No data to display.';
                    this.logger.warn(this.className, 'createPlaceholderContainer', 'No LanguageProvider instance set');
                }
            }
            content.textContent = placeholder;
            container.appendChild(content);
        } else {
            placeholder = placeholder as PlaceholderConfig;
            const content = document.createElement('div');
            content.classList.add('placeholder-content');
            if (placeholder) {
                if (placeholder.imageSrc) {
                    const imageContent = document.createElement('img');
                    imageContent.src = placeholder.imageSrc;
                    content.appendChild(imageContent);
                }

                if (placeholder.text) {
                    const textContent = document.createElement('p');
                    textContent.textContent = placeholder.text;
                    content.appendChild(textContent);
                }

                if (placeholder.htmlElement) {
                    content.appendChild(placeholder.htmlElement);
                }
            }

            container.appendChild(content);
        }

        return container;
    }


    /**
     * Creates a banner container with the given text.
     *
     * @private
     * @param {string} text Text to render into.
     * @returns {HTMLElement} The container.
     * @memberof UiManager
     */
    private createBannerContainer(text: string): HTMLElement {
        const container = document.createElement('div');
        container.classList.add('wd-banner-container');
        const content = document.createElement('p');
        content.textContent = text;
        container.appendChild(content);

        return container;
    }

    /**
     * Returns the target container to append container for loading, error or placeholder and add CSS classes.
     * Returns the body element if the given container is the body.
     * Returns the element itself if the element to look for is not present.
     *
     * @private
     * @param {Element} node Container to look in.
     * @returns {Element} Element to append container to.
     * @memberof UiManager
     */
    private getGridItemContentContainer(node: Element): Element {
        const target = node.querySelector('.grid-stack-item-content');
        if (node === document.body) {
            return node;
        } else if (target) {
            return target;
        } else {
            this.logger.debug(this.className, 'setError', 'Failed to find .grid-stack-item-content in node:', node);
            return node;
        }
    }
}