import { ITextArea, ITextAreaOptions } from './interfaces';


/**
 * The base class for a text area.
 * 
 * @export
 * @class TextArea
 */
export class TextArea implements ITextArea {

    /**
     * The callback for the value changed event. Passes the current value as a string.
     * 
     * @memberof TextArea
     */
    public onValueChanged?: (item: string) => void;
    /**
     * The callback for the enter pressed event. Passes the current value as a string.
     * 
     * @memberof TextArea
     */
    public onEnterPressed?: (item: string) => void;

    /**
     * The callback for the key down event. Passes the current value as a string.
     *
     * @memberof TextArea
     */
    public onKeyDown?: (item: string) => void;

    /**
     * The callback for the onInitialized event.
     *
     * @memberof TextArea
     */
    public onInitialized?: () => void;

    /**
     * Callback to execute on focus.
     *
     * @memberof TextArea
     */
    public onFocus?: () => void;

    /**
     * Callback to execute on blur.
     *
     * @memberof TextArea
     */
    public onBlur?: () => void;

    private targetElement: HTMLElement;
    private textBoxOptions: DevExpress.ui.dxTextAreaOptions;
    private instance?: DevExpress.ui.dxTextArea;
    private jQueryStatic: JQueryStatic;

    /**
     * Creates an instance of TextArea.
     * @param {HTMLElement} targetElement
     * @param {JQueryStatic} jQueryStatic
     * @memberof TextArea
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;

        this.textBoxOptions = {
            value: '',
            onFocusIn: () => {
                if (this.onFocus) {
                    this.onFocus();
                }
            },
            onFocusOut: () => {
                if (this.onBlur) {
                    this.onBlur();
                }
            },
            onKeyUp: (event: any) => {
                if (!event) {
                    throw new Error('dxTextArea.onKeyUp: The event parameter was null or undefined.');
                }

                const value = this.getValue();
                if (!value) {
                    this.onValueChangedTrigger('');
                } else {
                    this.onValueChangedTrigger(value);
                }
            },
            onChange: () => {
                const value = this.getValue();
                if (!value) {
                    this.onValueChangedTrigger('');
                } else {
                    this.onValueChangedTrigger(value);
                }
            },
            onInput: () => {
                const value = this.getValue();
                if (!value) {
                    this.onValueChangedTrigger('');
                } else {
                    this.onValueChangedTrigger(value);
                }
            },
            onEnterKey: (event: any) => {
                if (!event) {
                    throw new Error('dxTextArea.onEnterKey: The event parameter was null or undefined.');
                }

                const value = this.getValue();
                if (!value) {
                    this.onEnterPressedTrigger('');
                } else {
                    this.onEnterPressedTrigger(value);
                }
            },
            onKeyDown: (event) => {
                if (!event) {
                    throw new Error('dxTextArea.onKeyDown: The event parameter was null or undefined.');
                }
                const value = this.getValue();
                if (!value) {
                    this.onKeyDownTrigger('');
                } else {
                    this.onKeyDownTrigger(value);
                }
            },
            onInitialized: () => {
                this.onInitializedTrigger();
            }
        };
    }


    /**
     * Bootstraps the text area.
     * 
     * @memberof TextArea
     */
    public bootstrap(): void {
        this.instance = this.getJQueryElement().dxTextArea(this.textBoxOptions).dxTextArea('instance');
    }


    /**
     * Sets the text area options.
     * 
     * @param {ITextAreaOptions} options 
     * @memberof TextArea
     */
    public setOptions(options: ITextAreaOptions): void {
        if (!options) {
            throw new ReferenceError('The argument "options" was null or undefined.');
        }

        // Create the DevExpress options object.
        const textAreaOptions: DevExpress.ui.dxTextAreaOptions = {};

        if (options.hasOwnProperty('isReadOnly')) {
            textAreaOptions.readOnly = options.isReadOnly;
        }
        if (options.hasOwnProperty('isDisabled')) {
            textAreaOptions.disabled = options.isDisabled;
        }
        if (options.hasOwnProperty('placeholderText')) {
            textAreaOptions.placeholder = options.placeholderText;
        }
        if (options.hasOwnProperty('tabIndex')) {
            textAreaOptions.tabIndex = options.tabIndex;
        }
        // Set the options.
        const dxTextArea = this.getJQueryElement().dxTextArea(textAreaOptions);
        // Set validator rules.
        if (options.isRequired) {
            dxTextArea.dxValidator({ validationRules: [{ type: 'required' }] });
        } else {
            dxTextArea.dxValidator({ validationRules: [] });
        }
    }

    /**
     * Sets the current value.
     * 
     * @param {string} value
     * @memberof TextArea
     */
    public setValue(value: string): void {
        this.getJQueryElement().dxTextArea({
            value: value
        });
    }

    /**
     * Sets the validity state.
     *
     * @param {boolean} isValid Whether the component is in a valid state.
     * @memberof TextArea
     */
    public setValidity(isValid: boolean): void {
        if (this.instance) {
            this.instance.option('isValid', isValid);
        }
    }

    /**
     * Sets the disabled state of the component.
     * Sets component internal to read only state for usability.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof TextArea
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.instance) {
            this.instance.option('readOnly', isDisabled);
        }
    }


    /**
     * Triggers the onValueChanged event.
     * 
     * @protected
     * @param {string)} value 
     * @memberof TextArea
     */
    protected onValueChangedTrigger(value: string): void {
        if (typeof this.onValueChanged === 'function') {
            this.onValueChanged(value);
        }
    }

    /**
     * Triggers the onEnterPressed event.
     * 
     * @protected
     * @param {string)} value 
     * @memberof TextArea
     */
    protected onEnterPressedTrigger(value: string): void {
        if (typeof this.onEnterPressed === 'function') {
            this.onEnterPressed(value);
        }
    }

    /**
     * Triggers the onKeyEvent event.
     *
     * @protected
     * @param {string} value The current value.
     * @memberof TextArea
     */
    protected onKeyDownTrigger(value: string): void {
        if (typeof this.onKeyDown === 'function') {
            this.onKeyDown(value);
        }
    }

    /**
     * Triggers the onInitialized event.
     *    
     * @protected
     * @memberof TextArea
     */
    protected onInitializedTrigger(): void {
        if (typeof this.onInitialized === 'function') {
            this.onInitialized();
        }
    }

    /**
     * Gets the text area instance.
     * 
     * @private
     * @returns {JQuery<HTMLElement>} 
     * @memberof TextArea
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.jQueryStatic) {
            throw new Error('jQuery was not loaded.');
        }

        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }

    /**
     * Returns the current value.
     * 
     * @private
     * @returns {string} The current value.
     * @memberof TextArea
     */
    private getValue(): string {
        if (!this.instance) {
            throw new Error('No instance found');
        }
        // TODO: Find better way to access actual current value
        // @ts-ignore - Ignore because of DX Usage
        const inputElement = this.instance._input() as JQuery<HTMLInputElement>;
        if (!inputElement) {
            throw new Error('No input element found');
        }
        const value = inputElement.val() as string;
        return value;

    }

}