import { DocumentResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { AttributeFlags } from '../../common';
import { DocumentDetails } from '../../common/webService';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { GetDetailsRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to perform a document GetDetails call on the windream webservice.
 * 
 * @export
 * @class GetDocumentDetails
 * @extends {ServiceAction}
 */
export class GetDocumentDetails extends ServiceAction {

  /**
   * Creates an instance of GetDocumentDetails.
   * @param {IRequestExecutor} requestExecutor 
   * @param {GlobalConfig} globalConfig 
   * @param {Logger} logger 
   * @memberof GetDocumentDetails
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
    super(requestExecutor, globalConfig, logger);

    this.name = 'getDetails';
  }

  /**
   * Gets details for a specified document.
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {GetDetailsRequestOptions} getDetailsRequestOptions The request options.
   * @returns {Promise<DocumentDetails>} A promise, which will resolve with the server reponse.
   * @memberof GetDocumentDetails
   */
  public async do(getDetailsRequestOptions: GetDetailsRequestOptions): Promise<DocumentDetails> {
    return new Promise<DocumentDetails>((resolve, reject) => {
      const url = `${this.globalConfig.windreamWebServiceURL}/documents/GetDetails`;
      const item = IdentityMapper.getWebServiceIdentity(getDetailsRequestOptions.identity);
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', url, {
        AttributeFlags: getDetailsRequestOptions.attributeFlags || AttributeFlags.Default,
        Item: item,
        Values: getDetailsRequestOptions.values || []
      }), getDetailsRequestOptions.requestOptions).then((response: IServiceResponse<DocumentResponseContainerDTO>) => {
        if (response.data?.HasErrors || !response.data?.Item) {
          this.logger.error('GetDetails', 'do', 'Failed to get details for this identity', response);
          reject(new Error('Failed to get details for this identity'));
        } else {
          const result = DocumentDetails.fromDto(response.data.Item);
          resolve(result);
        }
      }).catch((err: Error) => {
        this.logger.error('GetDetails', 'do', 'Failed to execute request', err);
        reject(err);
      });
    });
  }
}