import { IdentityMapper } from '..';
import { DirectoryRightsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler';
import { WindreamEntity } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging/';
import { DirectoryRightsModel, IdentityRightsModelConverter } from '../../rights';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to get rights from an identity in the windream webservice.
 * 
 * @export
 * @class GetDirectoryRights
 * @extends {ServiceAction}
 */
export class GetDirectoryRights extends ServiceAction {
    private converter: IdentityRightsModelConverter;

    /**
     * Creates an instance of GetDirectoryRights.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @param {IdentityRightsModelConverter} converter 
     * @memberof GetDirectoryRights
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, converter: IdentityRightsModelConverter) {
        super(requestExecutor, globalConfig, logger);
        this.converter = converter;

        this.name = 'getDirectoryRights';
    }

    /**
     * Gets righs for the given directory.
     * Resolves a promise with the converted server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WindreamRequestOptions} windreamRequestOptions The request options.
     * @returns {Promise<DirectoryRightsModel>} A promise, which will resolve with a directory rights model.
     * @memberof GetDirectoryRights
     */
    public async do(windreamRequestOptions: WindreamRequestOptions): Promise<DirectoryRightsModel> {
        return new Promise<DirectoryRightsModel>((resolve, reject) => {
            if (windreamRequestOptions.identity.entity !== WindreamEntity.Folder) {
                this.logger.error('GetDirectoryRights', 'do', 'Identity is not a directory', windreamRequestOptions.identity);
                reject(new Error('Identity is not a directory'));
                return;
            }
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/directories/GetDirectoryRights?${IdentityMapper.getWebServiceGetParameter(windreamRequestOptions.identity)}`;
            this.requestExecutor.executeRequest(new HttpResourcePointer('GET', tempUrl, {
                Item: IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity)
            }), windreamRequestOptions.requestOptions).then((response: IServiceResponse<DirectoryRightsResponseContainerDTO>) => {
                if (response && response.data && !response.data.HasErrors) {
                    resolve(this.converter.convertDirectoryRightsToModel(response.data));
                } else {
                    this.logger.error('GetDirectoryRights', 'do', 'Failed to get rights', response);
                    reject(new Error('Failed to get rights'));
                }
            }).catch((err: Error) => {
                this.logger.error('GetDirectoryRights', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}