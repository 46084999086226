import { ModuleRegistrationType } from '../../../typings';
import { ModuleRegistration } from '.';

/**
 * Registration helper for module registrations.
 *
 * @export
 * @class ModuleRegistrationHelper
 */
export class ModuleRegistrationHelper {
    private componentRegistrations: Map<string, ModuleRegistration>;
    private toolbarActionRegistrations: Map<string, ModuleRegistration>;
    private waitingComponentRegisters: Map<string, ((registration: ModuleRegistration) => void)[]>;
    private readonly registrationTimeout = 5000;

    /**
     * Creates an instance of ModuleRegistrationHelper.
     * 
     * @memberof ModuleRegistrationHelper
     */
    public constructor() {
        this.componentRegistrations = new Map<string, ModuleRegistration>();
        this.toolbarActionRegistrations = new Map<string, ModuleRegistration>();
        this.waitingComponentRegisters = new Map<string, ((registration: ModuleRegistration) => void)[]>();
    }

    /**
     * Register a module.
     *
     * @param {ModuleRegistration} registration The registration.
     * @memberof ModuleRegistrationHelper
     */
    public registerModule(registration: ModuleRegistration): void {
        switch (registration.type) {
            case ModuleRegistrationType.Component:
                this.componentRegistrations.set(registration.id, registration);
                const registrationWaiters = this.waitingComponentRegisters.get(registration.id);
                if (registrationWaiters) {
                    registrationWaiters.forEach((waiter) => {
                        waiter(registration);
                    });
                    this.waitingComponentRegisters.delete(registration.id);
                }
                break;
            case ModuleRegistrationType.ToolbarAction:
                this.toolbarActionRegistrations.set(registration.id, registration);
                break;
        }
    }

    /**
     * Gets a registration.
     *
     * @param {string} id The id.
     * @param {ModuleRegistrationType} type The type.
     * @returns {(ModuleRegistration | undefined)} The module registration or undefined.
     * @memberof ModuleRegistrationHelper
     */
    public getRegistration(id: string, type: ModuleRegistrationType): ModuleRegistration | undefined {
        switch (type) {
            case ModuleRegistrationType.Component:
                return this.componentRegistrations.get(id);
            case ModuleRegistrationType.ToolbarAction:
                return this.toolbarActionRegistrations.get(id);
        }
    }

    /**
     * Waits for a component registration to occur.
     *
     * @param {string} id The id of the component.
     * @returns {Promise<ModuleRegistration>} A promise, which will resolve with the module registration.
     * @memberof ModuleRegistrationHelper
     */
    public waitForComponentRegistration(id: string): Promise<ModuleRegistration> {
        return new Promise<ModuleRegistration>((resolve, reject) => {
            const registration = this.componentRegistrations.get(id);
            if (registration) {
                resolve(registration);
            } else {
                let waiterList = this.waitingComponentRegisters.get(id);
                let resolvedWaiting = false;
                const waiterFunction = (registration: ModuleRegistration) => {
                    if (!resolvedWaiting) {
                        resolvedWaiting = true;
                        resolve(registration);
                    }
                };
                if (!waiterList) {
                    waiterList = [];
                }
                waiterList.push(waiterFunction);
                this.waitingComponentRegisters.set(id, waiterList);
                setTimeout(() => {
                    const moduleRegistrationMarkedForTimeout = this.componentRegistrations.get(id);
                    if (moduleRegistrationMarkedForTimeout) {
                        waiterFunction(moduleRegistrationMarkedForTimeout);
                        this.waitingComponentRegisters.delete(moduleRegistrationMarkedForTimeout.id);
                    } else {
                        this.waitingComponentRegisters.delete(id);
                        reject(new Error('Timeout of module registration: ' + id));
                    }
                }, this.registrationTimeout);
            }
        });
    }
}