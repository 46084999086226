import { DWCore } from '@windream/dw-core/dwCore';

/**
 * Represents a single connection inside the PubSub configuraiton.
 * 
 * @export
 * @class Connection
 */
export class Connection implements DWCore.PubSub.Connection {
    /**
     * Parametername which is published or subscribed.
     * 
     * @type {string}
     * @memberof Conncetion
     */
    public parameter: string;
    /**
     * ID of the component that publishes or subscribes.
     * 
     * @type {string}
     * @memberof Conncetion
     */
    public componentGuid: string;

    /**
     * Creates an instance of Connection.
     *
     * @param {string} parameter
     * @param {string} componentGuid
     * @memberof Connection
     */
    public constructor(parameter: string, componentGuid: string) {
        this.parameter = parameter;
        this.componentGuid = componentGuid;
    }
}