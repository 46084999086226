import { ExternalCoreErrorCode } from './externalCoreErrorCode';


/**
 * This class represents an external core error.
 *
 * @export
 * @class ExternalCoreError
 * @extends {Error}
 */
export class ExternalCoreError extends Error {

    /**
     * The code.
     *
     * @type {ExternalCoreErrorCode}
     * @memberof ExternalCoreError
     */
    public code?: ExternalCoreErrorCode;


    /**
     * Creates an instance of ExternalCoreError.
     *
     * @param {string} message The message.
     * @param {number} [code=0] The code.
     * @memberof ExternalCoreError
     */
    public constructor(message: string, code: ExternalCoreErrorCode = ExternalCoreErrorCode.undefined) {
        super(message);

        this.code = code;
    }

}