import { LifecycleResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { LifecycleDetails } from './lifecycleDetails';

/**
 * Represents a lifecycle container containing lifecycle information about the document and its objecttype (if it differs).
 * 
 * @export
 * @class LifecycleDetailsContainer
 */
export class LifecycleDetailsContainer {
    /**
     * Lifecycle details of the document.
     * 
     * @type {LifecycleDetails}
     * @memberof LifecycleDetailsContainer
     */
    public document: LifecycleDetails;

    /**
     * Lifecycle details of the object type if it differs from the document's.
     * 
     * @type {(LifecycleDetails | null)}
     * @memberof LifecycleDetailsContainer
     */
    public objectType: LifecycleDetails | null;

    /**
     * Whether the document is archived.
     * 
     * @type {boolean}
     * @memberof LifecycleDetailsContainer
     */
    public isArchived: boolean;

    /**
     * Creates an instance of LifecycleDetailsContainer.
     * @param {LifecycleResponseContainerDTO} container 
     * @memberof LifecycleDetailsContainer
     */
    public constructor(container: LifecycleResponseContainerDTO) {
        this.document = new LifecycleDetails(container.Document);
        this.objectType = container.ObjectType ? new LifecycleDetails(container.ObjectType) : null;
        this.isArchived = this.document.archived.start < new Date();
    }
}