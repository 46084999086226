import { IComponentNameProvider } from '.';

/**
 * This class is a provider for component names.
 * 
 * @export
 * @class ComponentNameProvider
 */
export class ComponentNameProvider implements IComponentNameProvider {

    private _usedComponentNames: string[];

    /**
     * Creates an instance of ComponentNameProvider.
     * 
     * @memberof ComponentNameProvider
     */
    public constructor() {
        this._usedComponentNames = new Array<string>();
    }


    /**
     * Clear all used component names.
     * 
     * 
     * @memberof ComponentNameProvider
     */
    public clearUsedComponentNames() {
        this._usedComponentNames.length = 0;
    }


    /**
     * Add a used component name.
     * 
     * @param {string} componentName 
     * @returns 
     * 
     * @memberof ComponentNameProvider
     */
    public addUsedComponentName(componentName: string): void {
        if (!componentName) {
            return;
        }
        if (this._usedComponentNames.indexOf(componentName) === -1) {
            this._usedComponentNames.push(componentName);
        }
    }


    /**
     * Remove a used component name.
     * 
     * @param {string} componentName 
     * @returns 
     * 
     * @memberof ComponentNameProvider
     */
    public removeUsedComponentName(componentName: string): void {
        if (!componentName || this._usedComponentNames.indexOf(componentName) === -1) {
            return;
        }
        this._usedComponentNames.splice(this._usedComponentNames.indexOf(componentName), 1);
    }


    /**
     * Gets the used component names.
     *
     * @returns {string[]} List of currently used component names.
     * @memberof ComponentNameProvider
     */
    public getUsedComponentNames(): string[] {
        return this._usedComponentNames;
    }


    /**
     * Get a unique component name.
     * 
     * @param {string} componentName 
     * @returns 
     * 
     * @memberof ComponentNameProvider
     */
    public getUniqueComponentName(componentName: string): string {
        // Filter components by name
        const foundComponents = this._usedComponentNames.filter((value) => {
            if (value.length <= componentName.length) {
                return false;
            }
            const indexPosition = value.indexOf(componentName);
            if (indexPosition === -1) {
                return false;
            }
            if (indexPosition + componentName.length <= value.length && indexPosition === 0) {
                const searchTerm = value.substr(indexPosition, componentName.length);
                return searchTerm === componentName;
            }
            return false;
        });

        let maxValue: number = 0;

        // Iterate through all found names
        for (const foundComponent of foundComponents) {

            // Try to extract a number at the end of the string
            const matches = foundComponent.match(/\d+$/);
            if (matches) {
                const foundValue: number = parseInt(matches[0], 10);
                if (foundValue > maxValue) {
                    maxValue = foundValue;
                }
            }
        }

        // Increment the highest found value
        maxValue++;

        return componentName + maxValue;
    }

}