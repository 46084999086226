import { TreeItem } from '.';

/**
 * Class that represents elements inside the tree that map to the external library.
 *
 * @export
 * @class TreeLibNode
 * @template T
 */
export class TreeLibNode<T> {

  /**
   * The id of this node.
   * 
   * @type {number}
   * @memberof TreeLibNode
   */
  public id: number;

  /**
   * The parent id of this node.
   * 
   * @type {(number | undefined)}
   * @memberof TreeLibNode
   */
  public parentId: number | undefined;

  /**
   * The name of this node.
   * 
   * @type {string}
   * @memberof TreeLibNode
   */
  public name: string;

  /**
   * Whether this node is a parent or not.
   * 
   * @type {boolean}
   * @memberof TreeLibNode
   */
  public isParent: boolean;

  /**
   * The node id.
   * 
   * @readonly
   * @type {number}
   * @memberof TreeLibNode
   */
  public get wdId(): number {
    return this.id;
  }

  /**
   * The data.
   * 
   * @type {T}
   * @memberof TreeLibNode
   */
  public data?: T;

  /**
   * The tree id of this node.
   * 
   * @type {number}
   * @memberof TreeLibNode
   */
  public tId?: number;

  /**
   * Creates an instance of TreeLibNode.
   * @param {number} id 
   * @param {string} name 
   * @param {boolean} isParent 
   * @param {number} [parentId] 
   * 
   * @memberof TreeLibNode
   */
  public constructor(id: number, name: string, isParent: boolean, parentId?: number) {
    this.id = id;
    this.name = name;
    this.isParent = isParent;
    this.parentId = parentId;
  }

  /**
   * Creates a TreeLibNode based on a TreeItem.
   *
   * @static
   * @template T Datatype of the data each node represents.
   * @param {TreeItem<T>} data Tree Item that should be converted.
   * @param {number} [parentId] ID of the parent node.
   * @returns {TreeLibNode<T>} Converted node.
   *
   * @memberof TreeLibNode
   */
  public static create<T>(data: TreeItem<T>, parentId?: number): TreeLibNode<T> {
    const isParent = !!(data.children);
    if (!data.id || !data.name) {
      throw new Error('Invalid data passed');
    }
    const node = new TreeLibNode<T>(data.id, data.name, isParent, parentId);
    if (data.data) {
      node.data = data.data;
    }
    return node;
  }

  /**
   * Whether the Node has parent or not.
   * 
   * @returns {boolean} 
   * 
   * @memberof TreeLibNode
   */
  public hasParent(): boolean {
    return typeof this.parentId === 'number' && this.parentId > 0;
  }
}