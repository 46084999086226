import { PageButton as PageButtonBase } from '../../../typings/core';
/**
 * This class represents a page button.
 * 
 * @export
 * @class PageButton
 */
export class PageButton implements PageButtonBase {

    /**
     * Whether the button is currently selected.
     * 
     * @type {boolean}
     * @memberof PageButton
     */
    public isSelected: boolean;

    /**
     * The text to be displayed on the button.
     * 
     * @type {string}
     * @memberof PageButton
     */
    public display: string;

    /**
     * The target page index (zero based) associated with the button.
     * 
     * @type {number}
     * @memberof PageButton
     */
    public targetIndex: number;

    /**
     * Whether the button is currently selected.
     * 
     * @type {boolean}
     * @memberof PageButton
     */
    public isPlaceholder: boolean;

    /**
     * Creates an instance of PageButton.
     * 
     * @param {boolean} isSelected Whether the button is currently selected.
     * @param {string} display The text to be displayed on the button.
     * @param {number} targetIndex The target page index (zero based) associated with the button - set to -1 for placeholder buttons.
     * @memberof PageButton
     */
    public constructor(isSelected: boolean, display: string, targetIndex: number) {
        this.isSelected = isSelected;
        this.display = display;
        this.targetIndex = targetIndex;
        this.isPlaceholder = targetIndex === -1;
    }
}
