import { WindreamIdentity } from '../../../../typings/core';
import { RemoveFavoritesRequest } from '../../../../typings/services';
import { IdLocationNameEntityIdentityDTO, ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { RemoveFromFavoritesRequestDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { ServiceError } from '../common';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to remove favorites from windream.
 *
 * @export
 * @class RemoveFavorites
 * @extends {ServiceAction}
 */
export class RemoveFavorites extends ServiceAction {


    /**
     * Creates an instance of RemoveFavorites.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global config.
     * @param {Logger} logger The logger.
     * @memberof RemoveFavorites
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'removeFavorites';
    }

    /**
     * Removes the favorites from windream.
     *
     * @param {FavoritesRequest} favoritesRequest The request.
     * @returns {Promise<void>} A promise, which will resolve if the favorites were removed correctly.
     * @memberof RemoveFavorites
     */
    public async do(favoritesRequest: RemoveFavoritesRequest): Promise<void> {
        return new Promise<void>((resolve, reject) => {

            const parameter: RemoveFromFavoritesRequestDTO = {
                Items: []
            };
            favoritesRequest.identities?.forEach((identity: WindreamIdentity) => {
                const currentIdentity: IdLocationNameEntityIdentityDTO = {
                    Entity: <number>identity.entity,
                    Id: identity.id ?? 0,
                    Location: identity.location ?? '',
                    Name: identity.name ?? ''
                };
                parameter.Items.push(currentIdentity);
            });

            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/favorites/RemoveFromFavorites',
                parameter), favoritesRequest.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
                    if (response.data) {
                        if (response.data.HasErrors) {
                            this.logger.error('RemoveFavorites', 'do', 'Couldn\'t remove favorites', response);
                            reject(new ServiceError('Couldn\'t remove favorites', -1));
                        } else {
                            resolve();
                        }
                    }
                }).catch((err: Error) => {
                    this.logger.error('RemoveFavorites', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}