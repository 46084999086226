/**
 * Creates an instance of subscription event.
 *
 * @export
 * @class SubscriptionOptions
 */
export class SubscriptionEvent {
    /**
     * The subscription event id.
     *
     * @type {number}
     * @memberof SubscriptionEvent
     */
    public id: number;

    /**
     * The subscription event name.
     *
     * @type {string}
     * @memberof SubscriptionEvent
     */
    public name: string;

    /**
     * The subscription event description.
     *
     * @type {string}
     * @memberof SubscriptionEvent
     */
    public description: string;

    /**
     * Creates an instance of SubscriptionEvent.
     *
     * @param {number} id The subscription event id.
     * @param {string} name The subscription event name.
     * @param {string} description The subscription event description.
     * @memberof SubscriptionEvent
     */
    public constructor(id: number, name: string, description: string) {
        this.id = id;
        this.name = name;
        this.description = description;
    }
}