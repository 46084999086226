import { UploadDocumentResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { WindreamIdentityDetails } from './windreamIdentityDetails';

/**
 * Represents the response of an upload.
 *
 * @export
 * @class UploadResponse
 */
export class UploadResponse {

    /**
     * The error if an error has occurred.
     *
     * @type {Error}
     * @memberof UploadResponse
     */
    public error?: Error;

    /**
     * Whether an indexing is required.
     *
     * @type {boolean}
     * @memberof UploadResponse
     */
    public isIndexEventRequired: boolean;

    /**
     * Identity of the uploaded document.
     *
     * @type {WindreamIdentityDetails}
     * @memberof UploadResponse
     */
    public identity: WindreamIdentityDetails;

    /**
     *Creates an instance of UploadResponse.
     * @param {WindreamIdentityDetails} identity
     * @param {boolean} isIndexEventRequired
     * @memberof UploadResponse
     */
    public constructor(identity: WindreamIdentityDetails, isIndexEventRequired: boolean) {
        this.isIndexEventRequired = isIndexEventRequired;
        this.identity = identity;
    }

    /**
     * Creates an isntance from the given DTO.
     *
     * @static
     * @param {UploadDocumentResponseContainerDTO} dto DTO to create from.
     * @returns {UploadResponse}
     * @memberof UploadResponse
     */
    public static fromDto(dto: UploadDocumentResponseContainerDTO): UploadResponse {
        const identity = WindreamIdentityDetails.fromDto(dto.Item);
        const result = new UploadResponse(identity, dto.IndexingDetails.IndexEventRequired);
        return result;
    }
}