import { DWCore } from '@windream/dw-core/dwCore';
import { ComponentInstanceToolbarActionConfig } from '.';

/**
 * Component instance toolbar config.
 *
 * @export
 * @class ComponentInstanceToolbarConfig
 */
export class ComponentInstanceToolbarConfig implements DWCore.Components.ComponentInstanceToolbarConfig {

    /**
     * Represents all toolbar actions for this component.
     *
     * @type {ComponentInstanceToolbarActionConfig[]}
     * @memberof ComponentInstanceToolbarConfig
     */
    public actions?: ComponentInstanceToolbarActionConfig[];

    /**
     * Whether the toolbar is enabled.
     *
     * @type {boolean}
     * @memberof ComponentInstanceToolbarConfig
     */
    public enabled?: boolean;

    /**
     * The default display mode for all toolbar actions.
     *
     * @type {DWCore.Components.ToolbarActionDisplayMode}
     * @memberof ComponentInstanceToolbarConfig
     */
    public displayMode?: DWCore.Components.ToolbarActionDisplayMode;

    /**
     * The orientation of the toolbar.
     *
     * @type {DWCore.Components.ToolbarOrientation}
     * @memberof ComponentInstanceToolbarConfig
     */
    public orientation?: DWCore.Components.ToolbarOrientation;
}