import ProgressBar from 'devextreme/ui/progress_bar';
import { DWCore } from '../dynamicWorkspace';
import { ITemplateExtension } from '../extensions';
import { IFileTransferManagerPopOverHandler } from './interfaces';


/**
 * Class to File Transfer Manager Pop-Over.
 * 
 * @export
 * @class FileTransferManagerPopOverHandler
 */
export class FileTransferManagerPopOverHandler implements IFileTransferManagerPopOverHandler {

    private expanded: boolean = true;
    private element: HTMLElement;
    private content!: HTMLElement;
    private templateExtension: ITemplateExtension;
    private uploadProgressList: any[] = [];
    public eventListener!: (e: Event) => void;

    /**
     * Creates an instance of Transfer Manager Pop-Over.
     * 
     * @param {HTMLElement} element
     * @param {ITemplateExtension} templateExtension
     * @param {ILanguageProvider} languageProvider
     * 
     * @memberof FileTransferManagerPopOverHandler
     */
    public constructor(element: HTMLElement, templateExtension: ITemplateExtension) {
        this.element = element;
        this.templateExtension = templateExtension;
    }

    /**
     * Renders the Transfer Manager Pop-Over.
     * 
     * @param {FileUpload[]} files The files list.
     * 
     * @memberof FileTransferManagerPopOverHandler
     */
    public render(files: DWCore.Files.FileUpload[]): void {
        this.templateExtension.render(this.element, require('./templates/fileTransferManagerPopOver.html'));
        this.createPopOver();
        const closeBtn = this.element.querySelector('#close-pop-over');
        const expandCollapesBtn = this.element.querySelector('#expand-collapse-content');

        if (closeBtn) {
            closeBtn.addEventListener('click', this.eventListener = () => {
                this.element.innerHTML = '';
                this.element.removeAttribute('data-wd-template-id');
                this.element.removeAttribute('data-wd-rendered');
            });
        }

        if (expandCollapesBtn) {
            expandCollapesBtn.addEventListener('click', this.eventListener = () => {
                const popOverContent = this.element.querySelector('#pop-over-content');
                if (this.expanded) {
                    popOverContent?.classList.remove('wd-file-transfer-manager-pop-over-content-expanded');
                    popOverContent?.classList.add('wd-file-transfer-manager-pop-over-content-collapsed');
                    expandCollapesBtn?.classList.remove('dx-icon-chevrondown');
                    expandCollapesBtn?.classList.add('dx-icon-chevronup');
                    this.expanded = !this.expanded;
                } else {
                    popOverContent?.classList.remove('wd-file-transfer-manager-pop-over-content-collapsed');
                    popOverContent?.classList.add('wd-file-transfer-manager-pop-over-content-expanded');
                    expandCollapesBtn?.classList.remove('dx-icon-chevronup');
                    expandCollapesBtn?.classList.add('dx-icon-chevrondown');
                    this.expanded = !this.expanded;
                }
            });
        }

        files.forEach((file: DWCore.Files.FileUpload) => {
            this.createFileUploadElement(file);
        });
    }

    /**
     * Creates the pop over.
     * 
     * @memberof FileTransferManagerPopOverHandler
     */
    private createPopOver() {
        const container = document.createElement('div');
        container.classList.add('wd-file-transfer-manager-pop-over-container');

        const topBar = document.createElement('div');
        topBar.classList.add('wd-file-transfer-manager-pop-over-top-bar');

        const topBarTitle = document.createElement('div');
        topBarTitle.classList.add('wd-file-transfer-manager-pop-over-top-bar-title');
        topBarTitle.innerText = 'File Transfer Manager';
        topBar.appendChild(topBarTitle);

        const topBarActions = document.createElement('div');
        topBarActions.classList.add('wd-file-transfer-manager-pop-over-top-bar-actions');

        const expandCollapseIcon = document.createElement('i');
        expandCollapseIcon.id = 'expand-collapse-content';
        expandCollapseIcon.classList.add('dx-icon-chevrondown');
        topBarActions.appendChild(expandCollapseIcon);

        const closeIcon = document.createElement('i');
        closeIcon.id = 'close-pop-over';
        closeIcon.classList.add('dx-icon-remove');
        topBarActions.appendChild(closeIcon);

        topBar.appendChild(topBarActions);
        container.appendChild(topBar);

        const content = document.createElement('div');
        content.id = 'pop-over-content';
        content.classList.add('wd-file-transfer-manager-pop-over-content-expanded');
        this.content = content;

        container.appendChild(content);
        this.element.appendChild(container);
    }


    /**
     * Creates a HTML upload element for a given file.
     * 
     * @param {FileUpload} file The file.
     * 
     * @memberof FileTransferManagerPopOverHandler
     */
    private createFileUploadElement(file: DWCore.Files.FileUpload) {
        const fileUploadElementContainer = document.createElement('div');
        fileUploadElementContainer.id = file.uuid;

        const titleDiv = document.createElement('div');
        titleDiv.innerText = file.fileName;

        const progressStatusDiv = document.createElement('div');

        const progressBarDiv = document.createElement('div');
        const progressBar = new ProgressBar(progressBarDiv, {
            max: 100,
            min: 0,
            showStatus: true,
        });

        this.uploadProgressList.push({ uuid: file.uuid, progressBar: progressBar });

        progressStatusDiv.appendChild(progressBarDiv);
        progressStatusDiv.appendChild(this.setProgress(progressBar, file.status, file.progress));
        fileUploadElementContainer.appendChild(titleDiv);
        fileUploadElementContainer.appendChild(progressStatusDiv);
        fileUploadElementContainer.classList.add('file-upload-progress');

        this.content.appendChild(fileUploadElementContainer);
    }

    /**
     * Updates the progres and/or status of an uploaded file.
     * 
     * @param {string} uuid The uuid of the file upload.
     * @param {string} status The status of the file upload.
     * @param {number} progress The progress of the file upload.
     * 
     * @memberof FileTransferManagerPopOverHandler
     */
    public updateProgress(uuid: string, status: string, progress?: number) {

        this.uploadProgressList.forEach((uploadProgress: any) => {
            if (uploadProgress.uuid === uuid) {
                uploadProgress.progressBar.element().parentNode.appendChild(this.setProgress(uploadProgress.progressBar, status, progress));
            }
        });
    }

    /**
     * Sets the progres and/or status of an uploaded file.
     * 
     * @param {ProgressBar} progressBar The progress bar of the file upload.
     * @param {string} status The status of the file upload.
     * @param {number} progress The progress of the file upload.
     * 
     * @memberof FileTransferManagerPopOverHandler
     */
    private setProgress(progressBar: ProgressBar, status: string, progress?: number): HTMLElement {
        const uploadSatusDiv = document.createElement('div');
        uploadSatusDiv.classList.add('file-upload-status');
        switch (status) {
            case 'inProgress':
                progressBar.option('visible', true);
                progressBar.option('value', progress);
                break;
            case 'done':
                progressBar.option('visible', false);
                uploadSatusDiv.innerText = 'Upload succeded';
                break;
            case 'error':
                progressBar.option('visible', false);
                uploadSatusDiv.innerText = 'Upload failed';
                break;
        }
        return uploadSatusDiv;
    }
}
