import { Logger } from '../logging';
import { AuthenticationTypes, ILoginManager } from '../login/index';
import { TokenProvider } from './tokenProvider';
import { WindreamToken } from './index';

/**
 * This class will provide a token which can be used for windream services.
 *
 * @export
 * @class WindreamTokenProvider
 * @extends {TokenProvider}
 */
export class WindreamTokenProvider extends TokenProvider {

    private currentToken?: string;
    private loginManager: ILoginManager;
    private logger: Logger;
    private loginInProgress: boolean;
    private tokenResolveQueue: ((value?: string | PromiseLike<string>) => void)[];
    private readonly className = 'WindreamTokenProvider';

    /**
     * Grace period for token expiration because of latency.
     *
     * @private
     * @memberof WindreamTokenProvider
     */
    private readonly latencyGracePeriod = 20;
    private readonly millisecondsFactor = 1000;

    /**
     * Creates an instance of WindreamTokenProvider.
     *
     * @param {ILoginManager} loginManager The loginManager.
     * @param {Logger} logger The logger.
     * @param {string} [currentToken] The current token, leave empty for login popup asking for credentials.
     * @memberof WindreamTokenProvider
     */
    public constructor(loginManager: ILoginManager, logger: Logger, currentToken?: string) {
        super();
        this.isMarkedAsInvalid = false;
        this.currentToken = currentToken;
        this.loginManager = loginManager;
        this.logger = logger;
        this.loginInProgress = false;
        this.tokenResolveQueue = new Array<(value?: string | PromiseLike<string>) => void>();
        this.loginManager.registerLoginCallback((userDetails) => {
            if (typeof userDetails.token === 'string') {
                this.currentToken = userDetails.token;
                this.isMarkedAsInvalid = false;
            } else {
                this.logger.fatal(this.className, 'constructor', 'Can not get new token');
            }
            this.loginInProgress = false;
            for (const resolve of this.tokenResolveQueue) {
                resolve(this.currentToken);
            }
            this.tokenResolveQueue.length = 0;
        });
    }

    /**
     * Gets the current token, if the token is expired it will prompt for credentials to re-authenticate.
     *
     * @returns {Promise<string>} A promise, which will resolve with the token.
     * @memberof WindreamTokenProvider
     */
    public async getToken(): Promise<string> {
        const tokenExpired = this.isTokenExpired();
        if (!this.loginInProgress && tokenExpired) {
            this.loginInProgress = true;
            this.loginManager.renderSessionTimeoutPopup(AuthenticationTypes.WindreamLike).catch((error) => {
                this.logger.error(this.className, 'getToken', 'Can\'t display login popup', error);
            });
        }
        return new Promise<string>((resolve, reject) => {
            if (tokenExpired) {
                this.tokenResolveQueue.push(resolve);
            } else {
                if (this.currentToken) {
                    resolve(this.currentToken);
                } else {
                    this.logger.error(this.className, 'getToken', 'The current token is null or undefined.');
                    reject(new Error('The current token is null or undefined.'));
                }
            }
        });
    }

    /**
     * Will only check if the current token is still valid, use getToken in order to get a new token.
     *
     * @returns {boolean} Whether the token is expired.
     * @memberof WindreamTokenProvider
     */
    public isTokenExpired(): boolean {
        if (this.isMarkedAsInvalid) {
            return true;
        }

        try {
            if (this.currentToken) {
                const token = new WindreamToken(this.currentToken);
                if (!token.payload.exp) {
                    throw new Error('Token payload has no exp');
                }
                return Date.now() > ((token.payload.exp - this.latencyGracePeriod) * this.millisecondsFactor);
            } else {
                return true;
            }
        } catch (error) {
            this.logger.error(this.className, 'isTokenExpired', `Token is misformed (${this.currentToken})`, error);
            return true;
        }
    }

    /**
     * Marks the current token as invalid.
     *
     * @async
     * @memberof WindreamTokenProvider
     */
    public async markTokenAsInvalid(): Promise<void> {
        this.isMarkedAsInvalid = true;
    }
}