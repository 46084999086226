import { IFileTransferManagerPopOverHandler } from './iFileTransferManagerPopOverHandler';

/**
 * Class to handle file transfer manager.
 * 
 * @export
 * @class BaseFileTransferManagerHandler
 */
export abstract class BaseFileTransferManagerHandler {

    /**
     * The pop over for the file transfer manager.
     * 
     * @memberof BaseFileTransferManagerHandler
     */
    PopOver: IFileTransferManagerPopOverHandler;

    constructor(popOpver: IFileTransferManagerPopOverHandler){
        this.PopOver = popOpver;
    }
}