import { Position } from '../../config';

/**
 * Configuration for the layout.
 * 
 * @export
 * @class LayoutConfiguration
 */
export class LayoutConfiguration {
    [guid: string]: Position;
}