import { PubSubConstants } from '../common';
import { IPubSubHandler, PubSubConfig } from '../pubSub';

/**
 * Util class for the ConfigUiHelper.
 * 
 * @export
 * @class ConfigUiHelperUtil
 */
export class ConfigUiHelperUtil {
    public static readonly className = 'ConfigUiHelperUtil';
    public static readonly CONFIGURATION_COMPONENT_GUID = 'WINDREAM_CONFIGURATION';
    public static readonly ADD_COMPONENT_GUID = 'WINDREAM_ADD_COMPONENT';

    /**
     * Adds PubSub connections that are used by the configuration component.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof ConfigUiHelperUtil
     */
    public static addConfigurationPubSub(pubSubHandler: IPubSubHandler): void {
        pubSubHandler.loadPubSub([{
            // Used in com.windream.configuration when saving component configuration
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'SavedConfig'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'SavedConfig'
            }]
        }, {
            // Used in com.windream.configuration when the view config was updated
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'ViewConfigUpdated'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'ViewConfigUpdated'
            }]
        },{
            // Used in com.windream.configuration when selection another component to configure
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'SelectedComponent'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'SelectedComponent'
            }]
        }, {
            // Used in com.windream.configuration when the changes inside the configuration component should be reverted
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'DiscardConfigComponent'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'DiscardConfigComponent'
            }]
        }, {
            // Used in com.windream.configuration when the discard button has been clicked
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'DiscardCurrentConfig'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'DiscardCurrentConfig'
            }]
        }, {
            // Used in com.windream.configuration to determine whether the discard button is enabled
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'IsDiscardPossible'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'IsDiscardPossible'
            }]
        }, {
            // Used in com.windream.configuration to determine whether the discard button is enabled
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'HasPendingChanges'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'HasPendingChanges'
            }]
        }, {
            // Used in com.windream.configuration when the view configuration should be visible.
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'SelectedView'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'SelectedView'
            }]
        }, {
            // Used in com.windream.configuration to check if the form is valid.
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'IsConfigValid'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'IsConfigValid'
            }]
        }, {
            // Used in com.windream.configuration when changes were made.
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'ComponentHasChanged'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'ComponentHasChanged'
            }]
        }, {
            // Used in com.windream.configuration when changes were made.
            in: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'ViewHasChanged'
            }],
            out: [{
                componentGuid: this.CONFIGURATION_COMPONENT_GUID,
                parameter: 'ViewHasChanged'
            }]
        }, {
            in: [{
                componentGuid: PubSubConstants.STYLES_ID,
                parameter: 'SelectedStyle'
            }],
            out: [{
                componentGuid: PubSubConstants.STYLES_ID,
                parameter: 'SelectedStyle'
            }]
        }, {
            in: [{
                componentGuid: PubSubConstants.STYLES_ID,
                parameter: 'UpdateOpenConfigSelectedStyle'
            }],
            out: [{
                componentGuid: PubSubConstants.STYLES_ID,
                parameter: 'UpdateOpenConfigSelectedStyle'
            }]
        }].map((connection: PubSubConfig) => {
            connection.executeForEmptyData = true;
            return connection;
        }));
    }

    /**
     * Adds PubSub connections that are used by the "add components" component.
     * 
     * @static
     * @param {IPubSubHandler} pubSubHandler PubSubHandler to use.
     * @memberof ConfigUiHelperUtil
     */
    public static addAddPubSub(pubSubHandler: IPubSubHandler): void {
        pubSubHandler.loadPubSub([{
            // Used in com.windream.componentPanel when adding a component
            in: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'AddComponents'
            }],
            out: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'AddComponents'
            }]
        }, {
            // Used in com.windream.componentPanel when a UI component is being dragged
            in: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'UiComponentDragStart'
            }],
            out: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'UiComponentDragStart'
            }]
        }, {
            // Used in com.windream.componentPanel when a Logic component is being dragged
            in: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'LogicComponentDragStart'
            }],
            out: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'LogicComponentDragStart'
            }]
        }, {
            // Used in com.windream.componentPanel when a component is not dragged anymore
            in: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'ComponentDragStop'
            }],
            out: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'ComponentDragStop'
            }]
        }, {
            // Used inside the LayoutManager when adding a component by Drag&Drop.
            in: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'AddComponentDragDrop'
            }],
            out: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'AddComponentDragDrop'
            }]
        }, {
            // Used inside the LayoutManager when adding a component by Drag&Drop.
            in: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'ComponentAdded'
            }],
            out: [{
                componentGuid: this.ADD_COMPONENT_GUID,
                parameter: 'ComponentAdded'
            }]
        }].map((connection: PubSubConfig) => {
            connection.executeForEmptyData = true;
            return connection;
        }));
    }
}