/**
 * The WebBridgePubSubModel.
 * 
 * @export
 * @class WebBridgePubSubModel
 */
export class WebBridgePubSubModel {
    /**
     * The callback which will be called if something will be published.
     * 
     * @memberof WebBridgePubSubModel
     */
    public callback: (data?: Object) => void;

    /**
     * The guid of the subscriber.
     * 
     * @type {string}
     * @memberof WebBridgePubSubModel
     */
    public guid: string;

    /**
     * Creates an instance of WebBridgePubSubModel.
     * @param {string} guid 
     * @param {(data?: Object) => void} callback 
     * @memberof WebBridgePubSubModel
     */
    constructor(guid: string, callback: (data?: Object) => void) {
        this.callback = callback;
        this.guid = guid;
    }
}