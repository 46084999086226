import { ILanguageProvider } from '../language';
import { IAppMenuHandler, IMenuBarHandler, IMenuBarHandlerFactory } from './interfaces';
import { MenuBarHandler } from '.';

/**
 * Factory for creating IMenuBarHandler instances.
 * 
 * @class MenuBarHandlerFactory
 * @implements {IMenuBarHandlerFactory}
 */
export class MenuBarHandlerFactory implements IMenuBarHandlerFactory {
    private rootElement: HTMLElement;
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of MenuBarHandlerFactory.
     * 
     * @param {HTMLElement} rootElement 
     * @param {ILanguageProvider} languageProvider 
     * @memberof MenuBarHandlerFactory
     */
    public constructor(rootElement: HTMLElement, languageProvider: ILanguageProvider) {
        this.rootElement = rootElement;
        this.languageProvider = languageProvider;
    }

    /**
     * Creates an instance of IMenuBarHandler.
     * 
     * @param {HTMLElement} element Element to pass to the IMenuBarHandler instance.
     * @param {IAppMenuHandler} appMenuHandler IAppMenuHandler instance to pass to the IMenuBarHandler instance.
     * @returns {IMenuBarHandler} 
     * @memberof MenuBarHandlerFactory
     */
    public create(element: HTMLElement, appMenuHandler: IAppMenuHandler): IMenuBarHandler {
        return new MenuBarHandler(element, this.rootElement, this.languageProvider, appMenuHandler);
    }
}