import { Logger } from '../logging';
import { ConfigUiHelper } from '../ui';
import { IRouter, RoutingUtils } from '.';

/**
 * The router for view routing.
 *
 * @export
 * @class SystemViewRouter
 * @implements {IRouter}
 */
export class SystemViewRouter implements IRouter {
    private routeRegExp: RegExp;
    private catchRegExp: RegExp;
    private onViewChangeCallbacks: ((view: string) => void)[];
    private logger: Logger;

    /**
     * Creates an instance of SystemViewRouter.
     * @param {Logger} logger The logger.
     * @memberof SystemViewRouter
     */
    public constructor(logger: Logger) {
        this.routeRegExp = new RegExp('\/systemview\/[^\/]+$', 'i');
        this.catchRegExp = new RegExp('\/systemview\/([^\/]+)', 'i');
        this.onViewChangeCallbacks = new Array<(view: string) => void>();
        this.logger = logger;
    }

    /**
     * Route the request.
     *
     * @param {string} path The path.
     * @param {*} [state] The state.
     * @returns {boolean} Whether the navigation was a success.
     * @memberof SystemViewRouter
     */
    public route(path: string, state?: any): boolean {
        if (typeof state === 'string') {
            path = state;
        }
        const catchingGroups = this.catchRegExp.exec(path);
        if (catchingGroups) {
            this.onViewChangeCallbacks.forEach((callback) => {
                callback(catchingGroups[1]);
            });
            if (ConfigUiHelper.hasUnsavedChanges()) {
                return false;
            } else {
                return true;
            }
        } else {
            this.logger.error('SystemViewRouter', 'route', 'Call was not routed because the catching group is somehow empty');
            return false;
        }
    }

    /**
     * Gets the current route pattern.
     *
     * @returns {RegExp} The route pattern.
     * @memberof SystemViewRouter
     */
    public getRoutePattern(): RegExp {
        return this.routeRegExp;
    }

    /**
     * Register a on view change callback.
     *
     * @param {(view: string) => void} setupViewCallback The callback.
     * @memberof SystemViewRouter
     */
    public registerOnViewChange(setupViewCallback: (view: string) => void): void {
        const callback = (view: string): void => {
            if (RoutingUtils.getCurrentUrl().toLowerCase().includes('header=0')) {
                setupViewCallback(view);
                return;
            }

            const url = new URL(RoutingUtils.getCurrentUrl());
            url.searchParams.set('header', '0');
            window.location.href = url.toString();
        };

        this.onViewChangeCallbacks.push(callback);
    }
}