import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { BinaryHttpResourcePointer, BinaryHttpResponseTypes } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { WindreamFileRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * The CheckIn action is used to CheckIn a document within windream
 * 
 * @export
 * @class CheckIn
 * @extends {ServiceAction}
 */
export class CheckIn extends ServiceAction {

    /**
     * Creates an instance of CheckIn.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global config.
     * @param {Logger} logger The logger.
     * 
     * @memberof CheckIn
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'checkIn';
    }

    /**
     * Execute the check-in of a given document.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WindreamFileRequestOptions} windreamFileRequestOptions The request options.
     * @returns {Promise<void>} A promise, resolves with server response.
     * @memberof CheckIn
     */
    public async do(windreamFileRequestOptions: WindreamFileRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!windreamFileRequestOptions.file) {
                reject(new Error('the argument \'data\' is null or undefined'));
            }

            if (!windreamFileRequestOptions.identity) {
                reject(new Error('the argument \'documentIdentity\' is null or undefined'));
            }
            // TODO: Use IdentityMapper.getWebServiceIdentity(identity)
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/documents/Upload?parameter.item.id=${windreamFileRequestOptions.identity.id}&parameter.flags=CheckIn`;
            const httpResourcePtr = new BinaryHttpResourcePointer(
                'POST',
                tempUrl,
                BinaryHttpResponseTypes.ARRAYBUFFER,
                windreamFileRequestOptions.file.getData()
            );

            this.requestExecutor.executeRequest(httpResourcePtr, windreamFileRequestOptions.requestOptions)
                .then((response: IServiceResponse<ResponseContainerDTO>) => {
                    if (response.data?.HasErrors) {
                        this.logger.error('CheckIn', 'do', 'Check in failed', response);
                        reject(new Error('Check in failed'));
                    } else {
                        resolve();
                    }

                }).catch((reason) => {
                    this.logger.error('CheckIn', 'do', 'Failed to execute request', reason);
                    reject(reason);
                });
        });
    }
}