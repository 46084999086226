import { Utils, ValueTypes } from '../common';
import { FormatHelper } from './formatHelper';
import { WindreamFormatter } from './windreamFormatter';

/**
 * Class to format a vector index.
 *
 * @export
 * @class VectorFormatHelper
 * @extends {FormatHelper}
 */
export class VectorFormatHelper extends FormatHelper {

    private readonly windreamFormatter: WindreamFormatter;


    /**
     * Creates an instance of VectorFormatHelper.
     *
     * @param {WindreamFormatter} windreamFormatter
     * @memberof VectorFormatHelper
     */
    public constructor(windreamFormatter: WindreamFormatter) {
        super();

        this.windreamFormatter = windreamFormatter;
    }


    /**
     * Formats vector values into readable format.
     *
     * @param {ValueTypes} valueType
     * @param {*} value
     * @returns {string}
     * @memberof VectorFormatHelper
     */
    public getVectorFormat(valueType: ValueTypes | null, value: any): string {
        if (value === null || !Utils.Instance.isArray(value)) {
            return '';
        }

        let result = '';

        const maxSeparatorIndex = value.length - 1;
        value.forEach((tempValue, tempIndex) => {
            result += this.windreamFormatter.formatValue(valueType, tempValue);
            if (tempIndex < maxSeparatorIndex) {
                result += ', ';
            }
        });

        return result;
    }
}