import { ChoiceListsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.ChoiceLists';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { ChoiceListItemCollectionModel } from '../../shared';
import { BaseRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Retruns a list of all choicelists.
 *
 * @export
 * @class GetAllUsers
 * @extends {ServiceAction}
 */
export class GetChoiceLists extends ServiceAction {
    /**
     * Creates an instance of GetChoiceLists.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @memberof GetChoiceLists
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getChoiceLists';
    }

    /**
     * Get all Choicelists.
     *
     * @param {BaseRequestOptions} [getChoiceListsRequestOptions] The request options.
     * @returns {Promise<ChoiceListItemCollectionModel[]>} A promise which will resolve with the ChoiceListItemCollectionModel array.
     * @memberof GetChoiceLists
     */
    public async do(getChoiceListsRequestOptions?: BaseRequestOptions): Promise<ChoiceListItemCollectionModel[]> {
        return new Promise<ChoiceListItemCollectionModel[]>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer('get', this.globalConfig.windreamWebServiceURL + '/choicelists/GetChoiceLists');

            this.requestExecutor.executeRequest(resourcePointer, getChoiceListsRequestOptions?.requestOptions)
                .then((response: IServiceResponse<ChoiceListsResponseContainerDTO>) => {
                    if (response.data?.ChoiceLists) {
                        const choiceLists = response.data.ChoiceLists.map((choiceList) => {
                            return new ChoiceListItemCollectionModel(choiceList.Id, choiceList.Name, choiceList.Type as number, choiceList.Items);
                        });
                        resolve(choiceLists);
                    } else {
                        this.logger.error('GetChoiceLists', 'do', 'Error in getting data', response);
                        reject(new Error('Error in getting data'));
                    }
                }).catch((error) => {
                    this.logger.error('GetChoiceLists', 'do', 'Failed to execute request', error);
                    reject(error);
                });
        });
    }
}