import { IStorage } from './interfaces/iStorage';

/**
 * LocalStorageWrapper class.
 * 
 * @export
 * @class LocalStorageWrapper
 * @implements {IStorage}
 */
export class LocalStorageWrapper implements IStorage {
    private storage: Storage;
    private storageAvailable: boolean;

    /**
     * Creates an instance of LocalStorageWrapper.
     * 
     * @memberof LocalStorageWrapper
     */
    public constructor() {
        this.storageAvailable = (!!window.localStorage);
        this.storage = window.localStorage;
    }
    /**
     * The clear() method of the Storage interface, when invoked, will empty all keys out of the storage.
     * 
     * @memberof LocalStorageWrapper
     */
    public clear(): void {
        if (!this.storageAvailable) {
            return;
        }
        this.storage.clear();
    }
    /**
     * The getItem() method of the Storage interface, when passed a key name, will return that key's value.
     * 
     * @param {string} key
     * @returns {(string | null)}
     * 
     * @memberof LocalStorageWrapper
     */
    public getItem(key: string): string | null {
        if (!this.storageAvailable) {
            return null;
        }
        return this.storage.getItem(key);
    }
    /**
     * The key() method of the Storage interface, when passed a number n, returns the name of the nth key in the storage. The order of keys is user-agent defined, so you should not rely on it.
     * 
     * @param {number} index
     * @returns {(string | null)}
     * 
     * @memberof LocalStorageWrapper
     */
    public key(index: number): string | null {
        if (!this.storageAvailable) {
            return null;
        }
        return this.storage.key(index);
    }
    /**
     * The length read-only property of the Storage interface returns an integer representing the number of data items stored in the Storage object.
     * 
     * @type {number}
     * @memberof LocalStorageWrapper
     */
    public get length(): number {
        if (!this.storageAvailable) {
            return 0;
        }
        return this.storage.length;
    }
    /**
     * The removeItem() method of the Storage interface, when passed a key name, will remove that key from the storage.
     * 
     * @param {string} key
     * 
     * @memberof LocalStorageWrapper
     */
    public removeItem(key: string): void {
        if (!this.storageAvailable) {
            return;
        }
        this.storage.removeItem(key);
    }
    /**
     * The setItem() method of the Storage interface, when passed a key name and value, will add that key to the storage, or update that key's value if it already exists.
     * 
     * @param {string} key
     * @param {string} data
     * 
     * @memberof LocalStorageWrapper
     */
    public setItem(key: string, data: string): void {
        if (!this.storageAvailable) {
            return;
        }
        this.storage.setItem(key, data);
    }
}