/**
 * Represents the web socket connection status.
 *
 * @export
 * @enum {number}
 */
export enum WebSocketConnectionStatus {

    /**
     * The web socket is not yet fully initialized.
     */
    Uninitialized = 0,

    /**
     * Web socket is open.
     */
    Open = 1,

    /**
     * Web socket is closed.
     */
    Closed = 2,

    /**
     * Web socket is in an error state.
     */
    Error = 3
}