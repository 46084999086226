import { HttpResponse, ServiceResponse } from '../ajaxHandler';
import { ICacheableResponse } from '../ajaxHandler/interfaces/iCacheableResponse';
import { IServiceResponse } from '../ajaxHandler/interfaces/iServiceResponse';
import { ServiceRequest } from '../ajaxHandler/serviceRequest';
import { IAuthenticationProvider } from '../authentication';
import { IAuthenticationManager } from '../authentication/interfaces/iAuthenticationManager';
import { CacheData, ICache } from '../caching';
import { Utils } from '../common';
import { Logger } from '../logging';
import { IRequestExecutor } from './interfaces/iRequestExecutor';
import { HTTP_RESPONSE_CODES, IDataProvider, IResourcePointer, RequestOptions } from '.';


/**
 * This class executes a given request.
 * 
 * @export
 * @class RequestExecutor
 */
export class RequestExecutor implements IRequestExecutor {

    protected cache?: ICache;
    protected authenticationManager?: IAuthenticationManager;
    protected dataProviders: Map<string, IDataProvider>;
    protected currentLanguage?: string;
    private logger: Logger;

    /**
     * Creates an instance of RequestExecutor.
     * 
     * @param {Logger} logger The logger.
     * @memberof RequestExecutor
     */
    public constructor(logger: Logger) {
        this.dataProviders = new Map<string, IDataProvider>();
        this.logger = logger;
    }


    /**
     * Sets the authentication manager.
     * 
     * @param {IAuthenticationManager} authenticationManager 
     * @memberof RequestExecutor
     */
    public setAuthenticationManager(authenticationManager: IAuthenticationManager): void {
        if (!authenticationManager) {
            throw new ReferenceError('The argument \"authenticationManager\" is null or undefined.');
        }

        this.authenticationManager = authenticationManager;
    }


    /**
     * Sets the cache.
     * 
     * @param {ICache} cache 
     * @memberof RequestExecutor
     */
    public setCache(cache: ICache): void {
        this.cache = cache;
    }

    /**
     * Set the language of the requests.
     *
     * @param {string} language The language to use.
     * @memberof RequestExecutor
     */
    public setLanguage(language: string): void {
        this.currentLanguage = language;
    }

    /**
     * Sets the data providers.
     * 
     * @param {IDataProvider[]} dataProviders
     * @memberof RequestExecutor
     */
    public addDataProvider(dataProvider: IDataProvider): void {

        if (dataProvider) {
            const supportedSchemes = dataProvider.getSupportedSchemes();
            if (supportedSchemes && supportedSchemes.length > 0) {

                supportedSchemes.forEach((scheme) => {
                    if (!this.dataProviders.has(scheme)) {
                        this.dataProviders.set(scheme, dataProvider);
                    }
                });

            }
        }
    }


    /**
     * Executes a request by running through the defined request pipeline.
     * 
     * @template T 
     * @param {IResourcePointer} resourcePointer 
     * @param {RequestOptions} [options] 
     * @returns {Promise<IServiceResponse<T>>} 
     * @async 
     * 
     * @memberof RequestExecutor
     */
    public async executeRequest<T>(resourcePointer: IResourcePointer, options?: RequestOptions): Promise<IServiceResponse<T>> {

        return new Promise<IServiceResponse<T>>((resolve, reject) => {

            try {

                if (!resourcePointer) {
                    throw new Error('The argument \"resourcePointer\" is null or undefined');
                }

                // Update the resource pointer with the current language.
                if (this.currentLanguage) {
                    resourcePointer.setLanguage(this.currentLanguage);
                }

                // Try to retrieve values from the cache.
                if (this.cache && (!options || !options.preventFetchFromCache)) {
                    this.cache.getData(resourcePointer).then((cacheData: CacheData) => {
                        if (cacheData && cacheData.isStillAlive) {
                            const response = this.createCacheResponse<T>(cacheData, resourcePointer, options);
                            resolve(response);
                        } else {
                            if ('onLine' in navigator && !navigator.onLine && cacheData) {
                                const response = this.createCacheResponse<T>(cacheData, resourcePointer, options);
                                resolve(response);
                            } else {
                                this.retrieveData<T>(resourcePointer, cacheData, options).then((data) => {
                                    resolve(data);
                                }).catch((error) => {
                                    reject(error);
                                });
                            }
                        }
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    this.retrieveData<T>(resourcePointer, null, options).then((data) => {
                        resolve(data);
                    }).catch((error) => {
                        reject(error);
                    });
                }
            } catch (error) {
                reject(error);
            }

        });

    }

    /**
     * Retrieve the data from the given resourcePointer.
     * 
     * @private
     * @template T 
     * @param {IResourcePointer} resourcePointer The resourcePointer.
     * @param {(CacheData | null)} cacheData The CachedData if it is set.
     * @param {RequestOptions} options The request options.
     * @returns {Promise<IServiceResponse<T>>} The Response from the Request.
     * @async 
     * 
     * @memberof RequestExecutor
     */
    private async retrieveData<T>(resourcePointer: IResourcePointer, cacheData: CacheData | null, options?: RequestOptions): Promise<IServiceResponse<T>> {

        return new Promise<IServiceResponse<T>>((resolve, reject) => {
            if (resourcePointer && !Utils.isDefined(resourcePointer.scheme)) {
                throw new Error('The argument \"resourcePointer.scheme\" is null or undefined');
            }

            if (!resourcePointer.scheme) {
                throw new Error('No scheme was found for the resource pointer');
            }

            // Get the data provider, which matches the desired scheme.
            const dataProvider = this.getDataProvider(resourcePointer.scheme);
            if (!dataProvider) {
                throw new Error('No data provider was found for the given scheme: ' + resourcePointer.scheme);
            }

            // Create a raw request instance
            const ajaxRequest = dataProvider.createRawRequest(resourcePointer);
            if (!ajaxRequest) {
                throw new Error('No raw request could be created');
            }

            // Create a new request instance
            const request = new ServiceRequest(ajaxRequest, resourcePointer);

            let authenticatedPromise = Promise.resolve();
            let authenticationProvider: IAuthenticationProvider | null = null;
            // Handle authentication
            if (this.authenticationManager) {
                if (options && options.authenticationMode) {
                    authenticationProvider = this.authenticationManager.getAuthenticationProviderForMode(options.authenticationMode);
                }
                if (!authenticationProvider) {
                    authenticationProvider = this.authenticationManager.getCurrentAuthenticationProvider();
                }
                if (authenticationProvider) {
                    authenticatedPromise = authenticationProvider.beforeRequestHandler(request.rawRequest).then(async (authenticatedRequest) => {
                        request.rawRequest = authenticatedRequest;
                        return Promise.resolve();
                    }, (_err: Error) => {
                        throw new Error('Unable to set authentication');
                    });
                }
            }
            authenticatedPromise.then(() => {
                if (cacheData && !cacheData.isStillAlive && Utils.isDefined(cacheData.wdTag)) {
                    request.rawRequest.setRequestHeader('If-None-Match', '"' + cacheData.wdTag + '"');
                }
                if (this.currentLanguage) {
                    request.rawRequest.setRequestHeader('WD-Accept-Language', this.currentLanguage);
                }
                // Execute the request
                dataProvider.execute<T>(request).then((response: IServiceResponse<T>) => {
                    const cachableResponse = response as ICacheableResponse<T>;
                    if (cachableResponse) {
                        if (cachableResponse.notModified && cacheData) {
                            cachableResponse.data = cacheData.data;
                        }

                        if (cachableResponse.shouldBeCached) {
                            // Try to cache the response
                            this.tryToCacheResponse(cachableResponse);
                        }
                    }

                    resolve(this.cloneResponseData<T>(response));
                }).catch((reason: HttpResponse<T>) => {
                    /**
                     * Resolves with a response build from the cache.                     *
                     */
                    const resolveWithCachedData = () => {
                        // If cacheData exists transfer it on error (disconnect/offline)
                        if (cacheData && reason && reason.statusCode === 0) {
                            const response = this.createCacheResponse<T>(cacheData, resourcePointer, options);
                            resolve(response);
                        } else {
                            reject(reason);
                        }
                    };

                    if (reason.statusCode === HTTP_RESPONSE_CODES.NOT_AUTHORIZED) {
                        if (authenticationProvider) {
                            authenticationProvider.handleInvalidAuthentication().then((shouldReplay) => {
                                if (shouldReplay) { // Replay request
                                    this.retrieveData<T>(resourcePointer, null, options).then(resolve, reject).catch((err: Error) => {
                                        reject(err);
                                    }); // Do not get from Cache if authentication failed previously
                                } else { // Do not replay request but resolve with response
                                    resolveWithCachedData();
                                }
                            }).catch((err: Error) => {
                                reject(err);
                            });
                            return; // Stop further processing as this is done in the handleInvalidAuthentication() Promise handling above
                        }
                    }
                    resolveWithCachedData();
                });
            }).catch((_err: Error) => {
                reject(new Error('Unable to set authentication'));
            });
        });
    }

    /**
     * Tries to add the response to the cache.
     * 
     * @private
     * @template T 
     * @param {ICacheableResponse<T>} response 
     * @memberof RequestExecutor
     */
    private tryToCacheResponse<T>(response: ICacheableResponse<T>): void {
        if (!this.cache || !response || !response.shouldBeCached || !response.resourcePointer) {
            return;
        }

        // Stop the execution here, if the resource pointer parameter was form-data.
        if (response.resourcePointer.parameter && response.resourcePointer.parameter instanceof FormData) {
            return;
        }

        const newCacheData = new CacheData(response.resourcePointer);
        newCacheData.data = response.data;

        newCacheData.wdTag = response.entityTag;
        newCacheData.timeToLive = response.timeToLive;
        newCacheData.timeRetrieved = new Date().getTime();
        newCacheData.modified = true;
        this.cache.setData(newCacheData);
    }

    /**
     * Gets the data provider.
     * 
     * @private
     * @param {string} scheme 
     * @returns {(IDataProvider | undefined)} 
     * @memberof RequestExecutor
     */
    private getDataProvider(scheme: string): IDataProvider | undefined {
        if (!Utils.isDefined(scheme) || !this.dataProviders || this.dataProviders.size === 0) {
            return undefined;
        }

        if (this.dataProviders.has(scheme)) {
            return this.dataProviders.get(scheme);
        } else {
            return undefined;
        }
    }

    /**
     * Creates a IServiceResponse from the Cache.
     * 
     * @private
     * @template T 
     * @param {CacheData} cacheData The data from the cache.
     * @param {IResourcePointer} resourcePointer The resourcePointer.
     * @param {RequestOptions} [options] The requestOptions.
     * @returns {IServiceResponse<T>} The IServiceResponse.
     * @memberof RequestExecutor
     */
    private async createCacheResponse<T>(cacheData: CacheData, resourcePointer: IResourcePointer, options?: RequestOptions): Promise<IServiceResponse<T>> {
        const response = new ServiceResponse<T>();
        response.data = cacheData.data;
        response.resourcePointer = resourcePointer;
        if (options) {
            response.requestOptions = options;
        }
        return this.cloneResponseData<T>(response);
    }


    /**
     * Clones the data property of the given response object.
     *
     * @private
     * @template T
     * @param {ServiceResponse<T>} response Response to clone data property for.
     * @returns {Promise<IServiceResponse<T>>} The response but with its data property cloned.
     * @memberof RequestExecutor
     */
    private async cloneResponseData<T>(response: ServiceResponse<T>): Promise<IServiceResponse<T>> {
        return new Promise<IServiceResponse<T>>((resolve) => {
            setTimeout(() => {
                // Clone return value to avoid multiple consumers from receiving the same instance
                // This would cause issues when the first consumer manipulates the data as later invocations would return the manipulated value
                // Using JSON.parse(JSON.stringify()) as it is faster than the current deepClone implementation
                // Only clone the data property as it is save to assume that it contains only plain JSON (no functions and no Date objects)
                // While the ResourcePointer has functions and even dependencies that would be cloned as well
                // Wrap in timeout to not block the event queue as we become asynchronous here
                if (typeof response.data === 'object' && response.data instanceof ArrayBuffer === false) {
                    try {
                        response.data = JSON.parse(JSON.stringify(response.data));
                    } catch {
                        this.logger.error('RequestExecutor', 'cloneResponseData()', 'Could not stringify object', response.data);
                    }
                }
                resolve(response);
            });
        });
    }

}