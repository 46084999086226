import { IClonable } from '../../common';
import { IdentityRightsModel } from '.';

/**
 * Model to reflect rights for a directory in windream.
 * 
 * @export
 * @class DirectoryRightsModel
 */
export class DirectoryRightsModel implements IClonable<DirectoryRightsModel> {
    /**
     * Rights of the directory.
     * 
     * @type {string}
     * @memberof DirectoryRightsModel
     */
    public directoryRights: IdentityRightsModel[];

    /**
     * Inheritable rights for documents inside the directory.
     * 
     * @type {IdentityRightsModel[]}
     * @memberof DirectoryRightsModel
     */
    public inheritableDocumentRights: IdentityRightsModel[];

    /**
     * Inheritable rights for directories inside the directory.
     * 
     * @type {IdentityRightsModel[]}
     * @memberof DirectoryRightsModel
     */
    public inheritableDirectoryRights: IdentityRightsModel[];

    /**
     * Creates an instance of DirectoryRightsModel.
     * @memberof DirectoryRightsModel
     */
    public constructor() {
        this.directoryRights = new Array<IdentityRightsModel>();
        this.inheritableDocumentRights = new Array<IdentityRightsModel>();
        this.inheritableDirectoryRights = new Array<IdentityRightsModel>();
    }

    /**
     * Creates a clone of this instance.
     * 
     * @returns {DirectoryRightsModel} The clone.
     * @memberof DirectoryRightsModel
     */
    public clone(): DirectoryRightsModel {
        const clone = new DirectoryRightsModel();
        clone.directoryRights = this.directoryRights.map((right) => right.clone());
        clone.inheritableDirectoryRights = this.inheritableDirectoryRights.map((right) => right.clone());
        clone.inheritableDocumentRights = this.inheritableDocumentRights.map((right) => right.clone());
        return clone;
    }
}