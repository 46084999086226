import { SEARCH_FILLERS, SEARCH_OPERATORS } from '.';


/**
 * This class defines a single search condition.
 *
 * @export
 * @class SearchCondition
 */
export class SearchCondition {
  /**
   * Column to search in.
   * 
   * @type {string}
   * @memberof SearchCondition
   */
  public column?: string;
  /**
   * Object type ID of the column.
   * 
   * @type {number}
   * @memberof SearchCondition
   */
  public columnObjectTypeId?: number;
  /**
   * Value to search for.
   * 
   * @type {(string | boolean | number | null | Date | number[] | Date[] | string[] | boolean[])}
   * @memberof SearchCondition
   */
  public value: string | boolean | number | null | Date | number[] | Date[] | string[] | boolean[] | undefined;
  /**
   * Whether to use autowildcards (i.e. like search).
   * 
   * @type {boolean}
   * @memberof SearchCondition
   */
  public autowildCards?: boolean;
  /**
   * Operator to search with.
   * 
   * @type {SEARCH_OPERATORS}
   * @memberof SearchCondition
   */
  public operator?: SEARCH_OPERATORS;
  /**
   * Whether this condition contains a placeholders.
   * Default is false.
   * 
   * @type {boolean}
   * @memberof SearchCondition
   */
  public isPlaceholder: boolean = false;

  /**
   * Whether this whole condition is s placeholder.
   * Default is false.
   *
   * @type {boolean}
   * @memberof SearchCondition
   */
  public isPlaceholderCondition: boolean = false;

  /**
   * The current filler.
   *
   * @type {SEARCH_FILLERS}
   * @memberof SearchCondition
   */
  public filler?: SEARCH_FILLERS;

  /**
   * Makes searches case sensitive for strings and vector strings.
   *
   * @type {boolean}
   * @memberof SearchQuery
   */
     public caseSensitive: boolean = false;
}