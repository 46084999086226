import { IServiceRequest } from '../ajaxHandler/interfaces/iServiceRequest';
import { WindreamDataProvider } from './windreamDataProvider';
import { IHttpResourcePointer } from '.';


/**
 * This class provides specific methods which are needed to get data from http services.
 * 
 * @export
 * @class ConfigDataProvider
 * @extends {WindreamDataProvider}
 */
export class ConfigDataProvider extends WindreamDataProvider {

    /**
     * URL to host the framework configurations.
     * 
     * @static
     * 
     * @memberof ConfigDataProvider
     */
    public static readonly CONFIG_URL = './config/';
    /**
     * URL to host the GlobalConfig file.
     * 
     * @static
     * 
     * @memberof ConfigDataProvider
     */
    public static readonly GLOBAL_CONFIG_URI = ConfigDataProvider.CONFIG_URL + 'globalConfig.json';

    /**
     * Url to the toolbar config json file.
     *
     * @static
     * @memberof ConfigDataProvider
     */
    public static readonly TOOLBAR_CONFIG_URI = ConfigDataProvider.CONFIG_URL + 'toolbarConfig.json';
    /**
     * URL to host the CustomConfig file.
     * 
     * @static
     * 
     * @memberof ConfigDataProvider
     */
    public static readonly CUSTOM_CONFIG_URI = ConfigDataProvider.CONFIG_URL + 'customConfig.json';
    /**
     * Base of the webservice actions related to the framework.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_BASE: string = '/dynamicworkspace/';
    /**
     * API endpoint to load the application confiurations.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_LOAD_APP_CONFIG_ACTION: string = 'views/LoadAllViewConfigs';
    /**
     * API endpoint to load a view confiuration.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_LOAD_VIEW_CONFIG_PATH: string = 'views/LoadViewConfig';
    /**
     * API endpoint to load all view confiurations.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_LOAD_ALL_VIEW_CONFIGS_PATH: string = 'views/LoadAllViewConfigs';
    /**
     * API endpoint to update a view configuration.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_UPDATE_VIEW_CONFIG_PATH: string = 'views/UpdateViewConfig';
    /**
     * API endpoint to create a new view configuration.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_CREATE_VIEW_CONFIG_PATH: string = 'views/CreateViewConfig';
    /**
     * API endpoint to delete a view configuration.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_DELETE_VIEW_CONFIG_PATH: string = 'views/DeleteView';
    /**
     * API endpoint to save a user specific component configuration.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_SAVE_USER_COMPONENT_CONFIG_PATH: string = 'views/SaveUserComponentConfig';
    /**
     * API endpoint to load the system configuration.
     * 
     * @static
     * @type {string}
     * @memberof ConfigDataProvider
     */
    public static readonly WEBSERVICE_LOAD_SYSTEM_CONFIG_PATH: string = 'config/LoadSystemConfig';

    /**
     * Gets the supported schemes.
     * 
     * @returns {string[]} 
     * 
     * @memberof ConfigDataProvider
     */
    public getSupportedSchemes(): string[] {
        return new Array<string>('config');
    }


    /**
     * This handler will be called before the execution.
     * 
     * @protected
     * @param {IServiceRequest} request 
     * @returns {IServiceRequest} 
     * @memberof WindreamDataProvider
     */
    protected beforeRequestHandler(request: IServiceRequest): IServiceRequest {

        const processedRequest = super.beforeRequestHandler(request);

        if (processedRequest) {
            const httpRessourcePtr = processedRequest.resourcePointer as IHttpResourcePointer;
            if (httpRessourcePtr) {
                const configFilePath: string = httpRessourcePtr.uri;

                httpRessourcePtr.uri = configFilePath;

                if (processedRequest.options) {
                    processedRequest.options.preventFetchFromCache = true;
                }
            }
        }

        return processedRequest;
    }

}