/**
 * Menu item for the AppMenu.
 * 
 * @export
 * @class AppMenuItem
 */
export class AppMenuItem {
    /**
     * Default position value.
     * 
     * @static
     * @memberof AppMenuItem
     */
    public static readonly DEFAULT_POSITION = 50;

    /**
     * The displayed text.
     * 
     * @type {string}
     * @memberof AppMenuItem
     */
    public text: string;

    /**
     * The on click handler.
     * 
     * @memberof AppMenuItem
     */
    public onClick?: () => void;

    /**
     * Sub menu items.
     * 
     * @type {AppMenuItem[]}
     * @memberof AppMenuItem
     */
    public children: AppMenuItem[];

    /**
     * Position of this item.
     * Higher numbers will be lower positioned.
     * Default is 50, should range between 0 and 100.
     * 
     * @type {number}
     * @memberof AppMenuItem
     */
    public position: number;

    /**
     * The css class for this item.
     * 
     * @type {string}
     * @memberof AppMenuItem
     */
    public cssClass?: string;

    /**
     * Whether the item is disabled.
     * Default is not disabled.
     * 
     * @type {string}
     * @memberof AppMenuItem
     */
    public isDisabled: boolean;

    /**
     * Title that is displayed.
     * 
     * @type {string}
     * @memberof AppMenuItem
     */
    public title?: string;

    /**
     * Whether this item has children.
     * 
     * @readonly
     * @type {boolean}
     * @memberof AppMenuItem
     */
    public get hasChildren(): boolean {
        return this.children.length > 0;
    }

    /**
     * Creates an instance of AppMenuItem.
     * @param {string} text 
     * @memberof AppMenuItem
     */
    public constructor(text: string) {
        this.text = text;
        this.position = AppMenuItem.DEFAULT_POSITION;
        this.isDisabled = false;
        this.children = new Array<AppMenuItem>();
    }
}