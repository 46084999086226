import { IServiceResponse } from '../../ajaxHandler/index';
import { AuthenticationModes } from '../../authentication';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders/httpResourcePointer';
import { IRequestExecutor, RequestOptions } from '../../dataProviders/index';
import { Logger } from '../../logging/logger';
import { ActionResponse } from '../index';
import { ServiceAction } from '../serviceAction';

/**
 * Provides the system details call.
 *
 * @export
 * @class GetSystemDetails
 * @extends {ServiceAction}
 */
export class GetSystemDetails extends ServiceAction {

    /**
     * Creates an instance of GetSystemDetails.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global config.
     * @param {Logger} logger The logger.
     * @memberof GetSystemDetails
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getSystemDetails';
    }
    /**
     * Retrieve system details from a webservice.
     *
     * @returns {Promise<ActionResponse>} A promise, which will resolve with the server response.
     * @memberof GetSystemDetails
     */
    public async do(): Promise<ActionResponse> {
        return new Promise<ActionResponse>((resolve, reject) => {
            const tempUrl = `${this.globalConfig.windreamWebServiceURL}/systemDetails/GetSystemDetails`;
            const httpResourcePtr = new HttpResourcePointer('GET', tempUrl);
            const requestOptions = new RequestOptions();
            requestOptions.authenticationMode = AuthenticationModes.Anonymous;
            // TODO: include DTO into internal class instead of any
            this.requestExecutor.executeRequest(httpResourcePtr, requestOptions)
                .then((response: IServiceResponse<any>) => {
                    if (response.data.HasErrors) {
                        this.logger.error('GetSystemDetails', 'do', 'Failed to get system details', response);
                        reject(new Error('Failed to get system details'));
                    } else {
                        const result = new ActionResponse();
                        result.hasErrors = response.data.HasErrors;
                        result.response = response;
                        resolve(result);
                    }
                }).catch((err: Error) => {
                    this.logger.error('GetSystemDetails', 'do', 'Failed to execute request', err);
                    reject(err);
                });
            });
        }
}