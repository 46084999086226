import { DWCore } from '@windream/dw-core/dwCore';
import { PubSubEditorConfig } from '../../config';
import { PubSubEditorRequestOptions } from './pubSubEditorRequestOptions';

/**
 * Represents update pubsub editor config request options.
 *
 * @export
 * @class UpdatePubSubEditorConfigRequestOptions
 * @extends {PubSubEditorRequestOptions}
 */
export class UpdatePubSubEditorConfigRequestOptions extends PubSubEditorRequestOptions {

    /**
     * The config.
     *
     * @type {PubSubEditorConfig}
     * @memberof UpdatePubSubEditorConfigRequestOptions
     */
    public config: PubSubEditorConfig;

    /**
     * Creates an instance of UpdatePubSubEditorConfigRequestOptions.
     * @param {string} viewId
     * @param {DWCore.Common.Devices} device
     * @param {PubSubEditorConfig} config
     * @memberof UpdatePubSubEditorConfigRequestOptions
     */
    public constructor(viewId: string, device: DWCore.Common.Devices, config: PubSubEditorConfig) {
        super(viewId, device);
        if (!config) {
            throw new Error('Argument \'config\' is not valid');
        }
        this.config = config;
    }
}