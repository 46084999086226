/* eslint-disable max-lines */
import * as App from '../../typings/app';
import * as Core from '../../typings/core';
import { DEVICES, ValueTypes, WindreamIdentity } from '../../typings/core';
import * as Formatter from '../../typings/formatter';
import * as Public from '../../typings/index';
import { ModuleRegistration as ApiModuleRegistration } from '../../typings/index';
import * as Language from '../../typings/language';
import { ComponentPopupOptions, InputPopupOptions, Popup, PopupOptions, WindreamFilePickerPopupOptions, WindreamPreviewPopupOptions } from '../../typings/popup';
import * as Services from '../../typings/services';
import * as Ui from '../../typings/ui';
import { ItemResponseDTO } from '../typings/windreamWebService/Windream.WebService';
import { DirectoryResponseDTO } from '../typings/windreamWebService/Windream.WebService.Directories';
import { DocumentResponseDTO } from '../typings/windreamWebService/Windream.WebService.Documents';
import { UserDetails } from './authentication/userDetails';
import { IMetadataStore } from './caching';
import { IDirectScriptExecutor, IWindreamScriptExecutor, ModelFactory } from './common';
import { GlobalConfig } from './config';
import { CultureHelper } from './culture';
import { HttpResourcePointer } from './dataProviders/index';
import { IRequestExecutor } from './dataProviders/interfaces/iRequestExecutor';
import { RequestOptions } from './dataProviders/requestOptions';
import { DWCore, FetchNodesRequestOptions, ILanguageManager, ILanguageProvider, IUiComponentFactory, IUtils, WindreamRequestOptions } from './dynamicWorkspace';
import { ModuleRegistration, ModuleRegistrationHelper } from './loader';
import { IViewManager } from './loader/interfaces/iViewManager';
import { Logger } from './logging';
import { IdentityChangedHandler } from './messages';
import { IMessageBus } from './messages/iMessageBus';
import { WindreamPreviewPopupOptions as WindreamPreviewPopupOptionsImpl } from './popups/models';
import { IPubSubHandler } from './pubSub';
import { RouteManager, RoutingUtils } from './router';
import {
    AddHistoryRequestOptions, BaseRequestOptions, PerformActionRequestOptions as CanPerformActionRequestOptions, CopyRequestOptions,
    GetDetailsRequestOptions, MoveDocumentRequestOptions, MoveRequestOptions,
    ResultAmountRequestOptions, SearchRequestOptions,
    UpdateDirectoryRightsRequestOptions, UpdateDocumentRequestOptions,
    UpdateDocumentRightsRequestOptions, UpdateRequestOptions, UploadRequestOptions,
    WindreamFileRequestOptions, WorklockRequestOptions, SaveToFileSystemRequestOptions, ArchiveDirectoryRequestOptions
} from './services';
import { IServiceManager } from './services/interfaces/iServiceManager';
import { SubscriptionEventOptions } from './services/models/subscriptionEventOptions';
import { SharedSettingsProvider } from './shared';
import { IIntentManager, SHARE_INTENTS } from './sharing/index';
import { BaseFileTransferManagerHandler, INotificationHelper, IPopupHelper, IPopupOptions } from './ui';
import { ValidationHelper } from './ui/components/validation';
import { IPopup } from './ui/interfaces/iPopup';
import { Version } from './version';
import { IAppDownloadHandler, IAppFocusHandler } from './webBridge';

export type DynamicWorkspaceRegister = (componentName: string, component: any, migrateFunction?: MigrateFunction) => void;
export type DynamicWorkspaceRegisterModule = (registration: ApiModuleRegistration) => void;
export type MigrateFunction = (oldVersion: string, newVersion: string, migrationObject: Core.MigrationObject, publicApi: Public.DynamicWorkspace) => Promise<Core.MigrationObject>;

/**
 * The public api.
 *
 * @export
 * @class PublicApi
 */
export class PublicApi {

    private userDetails: UserDetails | null;
    private globalConfig: GlobalConfig;
    private cultureHelper: CultureHelper;
    private intentManager: IIntentManager;
    private languageManager: ILanguageManager;
    private viewManager?: IViewManager;
    private logger: Logger;
    private utils: IUtils;
    private appFocusHandler: IAppFocusHandler;
    private pubSubHandler: IPubSubHandler;
    private serviceManager: IServiceManager;
    private popupHelper: IPopupHelper;
    private notificationHelper: INotificationHelper;
    private metadataStore: IMetadataStore;
    private directScriptExecutor: IDirectScriptExecutor;
    private windreamScriptExecutor: IWindreamScriptExecutor;
    private requestExecutor: IRequestExecutor;
    private uiComponentFactory: IUiComponentFactory;
    private appDownloadHandler: IAppDownloadHandler;
    private sharedSettingsProvider?: SharedSettingsProvider;
    private fileTransferManager: BaseFileTransferManagerHandler;
    private moduleRegistrationHelper: ModuleRegistrationHelper;
    private messageBus: IMessageBus;
    private identityChangedHandler: IdentityChangedHandler;


    /**
     * Creates an instance of PublicApi.
     *
     * @param {(UserDetails | null)} userDetails The UserDetails class.
     * @param {GlobalConfig} globalConfig The GlobalConfig class.
     * @param {CultureHelper} cultureHelper The CultureHelper class.
     * @param {IIntentManager} intentManager The IntentManager interface.
     * @param {ILanguageManager} languageManager The LanguageManager interface.
     * @param {IViewManager} viewManager The ViewManager Interface.
     * @param {Logger} logger The Logger class.
     * @param {IUtils} utils The Utils interface.
     * @param {IAppFocusHandler} appFocusHandler The AppFocusHandler interface.
     * @param {IPubSubHandler} pubSubHandler The PubSubHandler interface.
     * @param {IPopupHelper} popupHelper The PopupHelper interface.
     * @param {INotificationHelper} notificationHelper The NotificationsHelper interface. 
     * @param {IMetadataStore} metadataStore The MetadataStore interface.
     * @param {IDirectScriptExecutor} directScriptExecutor The DirectScriptExecutor interface.
     * @param {IWindreamScriptExecutor} windreamScriptExecutor The WindreamScriptExecutor interface.
     * @param {IServiceManager} serviceManager The ServiceManager interface.
     * @param {IRequestExecutor} requestExecutor The RequestExecutor interface.
     * @param {IUiComponentFactory} uiComponentFactory The UiComponentFactory interface.
     * @param {IAppDownloadHandler} appDownloadHandler The AppDownloadHandler interface.
     * @param {ModuleRegistrationHelper} moduleRegistrationHelper The moduleRegistrationHelper.
     * @param {BaseFileTransferManagerHandler} fileTransferManager The file transfer manager.
     * @param {IMessageBus} messageBus The message bus.
     * @param {IdentityChangedHandler} identityChangedHandler The message bus.
     * @param {SharedSettingsProvider} sharedSettingsProvider The SharedSettings interface.
     * @memberof PublicApi
     */
    public constructor(userDetails: UserDetails | null, globalConfig: GlobalConfig, cultureHelper: CultureHelper, intentManager: IIntentManager,
        languageManager: ILanguageManager, viewManager: IViewManager | undefined, logger: Logger, utils: IUtils,
        appFocusHandler: IAppFocusHandler, pubSubHandler: IPubSubHandler, popupHelper: IPopupHelper,
        notificationHelper: INotificationHelper, metadataStore: IMetadataStore, directScriptExecutor: IDirectScriptExecutor, windreamScriptExecutor: IWindreamScriptExecutor,
        serviceManager: IServiceManager, requestExecutor: IRequestExecutor, uiComponentFactory: IUiComponentFactory, appDownloadHandler: IAppDownloadHandler,
        moduleRegistrationHelper: ModuleRegistrationHelper, fileTransferManager: BaseFileTransferManagerHandler, messageBus: IMessageBus, identityChangedHandler: IdentityChangedHandler,
        sharedSettingsProvider?: SharedSettingsProvider) {

        this.userDetails = userDetails;
        this.globalConfig = globalConfig;
        this.cultureHelper = cultureHelper;
        this.intentManager = intentManager;
        this.languageManager = languageManager;
        this.viewManager = viewManager;
        this.logger = logger;
        this.utils = utils;
        this.appFocusHandler = appFocusHandler;
        this.pubSubHandler = pubSubHandler;
        this.serviceManager = serviceManager;
        this.popupHelper = popupHelper;
        this.notificationHelper = notificationHelper;
        this.metadataStore = metadataStore;
        this.directScriptExecutor = directScriptExecutor;
        this.requestExecutor = requestExecutor;
        this.windreamScriptExecutor = windreamScriptExecutor;
        this.uiComponentFactory = uiComponentFactory;
        this.appDownloadHandler = appDownloadHandler;
        this.sharedSettingsProvider = sharedSettingsProvider;
        this.fileTransferManager = fileTransferManager;
        this.moduleRegistrationHelper = moduleRegistrationHelper;
        this.messageBus = messageBus;
        this.identityChangedHandler = identityChangedHandler;
    }

    /**
     * Generates the public api.
     *
     * @returns {Public.DynamicWorkspace} The public api.
     * @memberof PublicApi
     */
    public generate(): Public.DynamicWorkspace {
        return Object.freeze<any>({
            App: this.generateApp(),
            Config: this.generateConfig(),
            Culture: this.generateCulture(),
            Device: this.generateDevice(),
            Messages: this.generateMessagesApi(),
            Extensions: window['DynamicWorkspace'] && window['DynamicWorkspace'].Extensions ? window['DynamicWorkspace'].Extensions : undefined,
            Intents: this.generateIntents(),
            Language: this.generateLanguage(),
            Lifecycle: this.generateLifecycle(),
            Logger: this.generateLogger(),
            Metadata: this.generateMetadata(),
            Models: this.generateModels(),
            Notification: this.generateNotification(),
            Popup: this.generatePopupHelper(),
            PubSub: this.generatePubSub(),
            RequestExecutor: this.generateRequestExecutor(),
            Script: this.generateScript(),
            Services: this.generateServices(),
            Shared: this.generateShared(),
            Ui: this.generateUi(),
            User: this.generateUser(),
            Utils: this.generateUtils(),
            Routing: this.generateRouting(),
            FileTransferManager: this.generateFileTransferManager(),
            Version: Version.productVersion,
            Events: this.generateEvents(),
            $: $,
            register: this.generateRegister(),
            registerModule: this.generateRegisterModule()
        });
    }

    /**
     * Generates the models api endpoint.
     *
     * @private
     * @returns {Public.Models} Models api endpoint.
     * @memberof PublicApi
     */
    private generateModels(): Public.Models {
        const factory = new ModelFactory();
        return Object.freeze<Public.Models>({
            createWindreamIdentity: () => factory.createWindreamIdentity(),
            createDirectoryDetails: () => factory.createDirectoryDetails(),
            createDirectoryDetailsFromDto: (dto: DirectoryResponseDTO) => factory.createDirectoryDetailsFromDto(dto),
            createDocumentDetails: () => factory.createDocumentDetails(),
            createDocumentDetailsFromDto: (dto: DocumentResponseDTO) => factory.createDocumentDetailsFromDto(dto),
            createWindreamIdentityDetails: () => factory.createWindreamIdentityDetails(),
            createWindreamIdentityDetailsFromDto: (dto: DocumentResponseDTO | ItemResponseDTO) => factory.createWindreamIdentityDetailsFromDto(dto),
            createItemCollectionDataSource: <T>() => factory.createItemCollectionDataSource<T>(),
            createDataSourceItem: <T>(value: T) => factory.createDataSourceItem(value),
            createWindreamAttribute: () => factory.createWindreamAtrribte(),
            createIdentityRightsModel: (name: string, domain: string, entity: Core.RIGHT_ENTITIES) => factory.createIdentityRightsModel(name, domain, entity),
            createRightModel: (right: Core.RIGHTS, isSet: boolean) => factory.createRightModel(right, isSet),
            createHttpResourcePointer: (action: string, uri: string, parameter?: Object, httpHeaders?: Map<string, string>) => factory.createHttpResourcePointer(action, uri, parameter, httpHeaders),
            createFileDataSource: () => factory.createFileDataSource(),
            createContextMenu: () => factory.createContextMenu(),
            createContextMenuItem: (config?: Ui.ContextMenuItemConfig) => factory.createContextMenuItem(config),
            getWebServiceIdentity: (identity: Core.WindreamIdentity) => factory.getWebServiceIdentity(identity),
            getPageNavigation: (languageProvider: ILanguageProvider) => factory.getPageNavigation(languageProvider)
        });
    }

    /**
     * Generates the user api endpoint.
     *
     * @private
     * @returns {Public.User} Public.User api endpoint.
     * @memberof PublicApi
     */
    private generateUser(): Public.User {
        return Object.freeze<Public.User>({
            current: this.generateCurrentUser()
        });
    }

    /**
     * Generates the current user api endpoint.
     *
     * @private
     * @returns {(Core.CurrentUser | null)} The current user api endpoint. 
     * @memberof PublicApi
     */
    private generateCurrentUser(): Core.CurrentUser | null {
        if (this.userDetails) {
            const tokenProvider = this.serviceManager.getTokenProvider();
            return Object.freeze<Core.CurrentUser>({
                fullUsername: this.userDetails.fullUsername || '',
                id: this.userDetails.id || 0,
                isAdmin: !!this.userDetails.isAdmin,
                token: this.userDetails.token || null,
                userName: this.userDetails.userName || '',
                domain: this.userDetails.domain || '',
                getToken: tokenProvider ?
                    () => tokenProvider.getToken() :
                    () => Promise.resolve(this.userDetails && this.userDetails.token ? this.userDetails.token : undefined),
                getInitials: () => this.userDetails ? this.userDetails.getInitials('') : ''
            });
        } else {
            return null;
        }
    }

    /**
     * Generates the date time format helper api endpoint.
     *
     * @private
     * @returns {Formatter.DateTimeFormatHelper} The data time format helper api endpoint.
     * @memberof PublicApi
     */
    private generateDateTimeFormatHelper(): Formatter.DateTimeFormatHelper {
        return Object.freeze<Formatter.DateTimeFormatHelper>({
            getDateFormat: () => this.cultureHelper.dateTimeFormatHelper.getDateFormat(),
            getDateTimeFormat: (includeSeconds) => this.cultureHelper.dateTimeFormatHelper.getDateTimeFormat(includeSeconds),
            getFormattedDateFromDate: (date) => this.cultureHelper.dateTimeFormatHelper.getFormattedDateFromDate(date),
            getFormattedDateFromTimestamp: (timeStamp) => this.cultureHelper.dateTimeFormatHelper.getFormattedDateFromTimestamp(timeStamp),
            getFormattedDateTimeFromDate: (date) => this.cultureHelper.dateTimeFormatHelper.getFormattedDateTimeFromDate(date),
            getFormattedTimeFromDate: (date) => this.cultureHelper.dateTimeFormatHelper.getFormattedTimeFromDate(date),
            getTimeFormat: (includeSeconds) => this.cultureHelper.dateTimeFormatHelper.getTimeFormat(includeSeconds),
            getDateFromString: (value: string) => this.cultureHelper.dateTimeFormatHelper.getDateFromString(value)
        });
    }

    /**
     * Generates the number format helper api endpoint.
     *
     * @private
     * @returns {Formatter.NumberFormatHelper} The number format helper api endpoint.
     * @memberof PublicApi
     */
    private generateNumberFormatHelper(): Formatter.NumberFormatHelper {
        return Object.freeze<Formatter.NumberFormatHelper>({
            format: (value, numberFormatOptions) => this.cultureHelper.numberFormatHelper.format(value, numberFormatOptions),
            fromString: (input) => this.cultureHelper.numberFormatHelper.fromString(input),
            getDecimalSeparator: () => this.cultureHelper.numberFormatHelper.getDecimalSeparator(),
            getFixedPointFormat: (preDigits, postDigits, useThousandsSeparator, forceDigitDisplay) => this.cultureHelper.numberFormatHelper.getFixedPointFormat(preDigits, postDigits,
                useThousandsSeparator, forceDigitDisplay),
            getFloatFormat: (useThousandsSeparator, forceDigitDisplay) => this.cultureHelper.numberFormatHelper.getFloatFormat(useThousandsSeparator, forceDigitDisplay),
            getNumberOfDecimals: (value) => this.cultureHelper.numberFormatHelper.getNumberOfDecimals(value)
        });
    }

    /**
     * Generates the sort helper api endpoint.
     *
     * @private
     * @returns {Formatter.SortHelper} The sort helper api endpoint.
     * @memberof PublicApi
     */
    private generateSortHelper(): Formatter.SortHelper {
        return Object.freeze<Formatter.SortHelper>({
            compareStrings: (value1, value2, sortOptions) => this.cultureHelper.sortHelper.compareStrings(value1, value2, sortOptions),
            sortArrayBy: (property, direction) => this.cultureHelper.sortHelper.sortArrayBy(property, direction)
        });
    }

    /**
     * Generates the boolean format helper api endpoint.
     *
     * @private
     * @returns {Formatter.BooleanFormatHelper} The boolean format helper api endpoint.
     * @memberof PublicApi
     */
    private generateBooleanFormatHelper(): Formatter.BooleanFormatHelper {
        return Object.freeze<Formatter.BooleanFormatHelper>({
            getBooleanString: (value) => this.cultureHelper.booleanFormatHelper.getBooleanString(value),
            fromString: (value) => this.cultureHelper.booleanFormatHelper.fromString(value)
        });
    }

    /**
     * Generates file size format helper api endpoint.
     *
     * @private
     * @returns {Formatter.FileSizeFormatHelper} The file size format helper api endpoint.
     * @memberof PublicApi
     */
    private generateFileSizeFormatHelper(): Formatter.FileSizeFormatHelper {
        return Object.freeze<Formatter.FileSizeFormatHelper>({
            getFileSizeString: (size, decimals) => this.cultureHelper.fileSizeFormatHelper.getFileSizeString(size, decimals)
        });
    }

    /**
     * Generates the windream fs flag format helper api endpoint.
     *
     * @private
     * @returns {Formatter.WindreamFsFlagsFormatHelper} The windream fs flag format helper api endpoint.
     * @memberof PublicApi
     */
    private generateWindreamFsFlagsFormatHelper(): Formatter.WindreamFsFlagsFormatHelper {
        return Object.freeze<Formatter.WindreamFsFlagsFormatHelper>({
            getFsFlagsLong: (flags) => this.cultureHelper.windreamFsFlagsFormatHelper.getFsFlagsLong(flags),
            getFsFlagsShort: (flags) => this.cultureHelper.windreamFsFlagsFormatHelper.getFsFlagsShort(flags)
        });
    }

    /**
     * Generates the windream status format helper api endpoint.
     *
     * @private
     * @returns {Formatter.WindreamStatusFormatHelper} The windream status format helper api endpoint.
     * @memberof PublicApi
     */
    private generateWindreamStatusFormatHelper(): Formatter.WindreamStatusFormatHelper {
        return Object.freeze<Formatter.WindreamStatusFormatHelper>({
            getStatusShort: (flags, entity) => this.cultureHelper.windreamStatusFormatHelper.getStatusShort(flags, entity)
        });
    }

    /**
     * Generates the windream formatter endpoint.
     *
     * @private
     * @returns {Formatter.WindreamFormatter} The WindreamFormatter.
     * @memberof PublicApi
     */
    private generateWindreamFormatter(): Formatter.WindreamFormatter {
        return Object.freeze<Formatter.WindreamFormatter>({
            format: (attribute, entity) => this.cultureHelper.windreamFormatter.format(attribute, entity),
            formatValue: (valueType: Core.ValueTypes | null, value: any, valueUnderlyingType: Core.ValueTypes | null = null) =>
                this.cultureHelper.windreamFormatter.formatValue(valueType, value, valueUnderlyingType)
        });
    }

    /**
     * Generates the vector formatter endpoint.
     *
     * @private
     * @returns {Formatter.VectorFormatter} The VectorFromatter.
     * @memberof PublicApi
     */
    private generateVectorFormatter(): Formatter.VectorFormatter {
        return Object.freeze<Formatter.VectorFormatter>({
            getVectorFormat: (valueType: Core.ValueTypes | null, value: any) => this.cultureHelper.vectorFormatter.getVectorFormat(valueType as number, value)
        });
    }

    /**
     * Generates the events endpoint.
     *
     * @private
     * @returns {Public.Events} The event endpoints.
     * @memberof PublicApi
     */
    private generateEvents(): Public.Events {
        return Object.freeze<Public.Events>({
            IdentityChanged: {
                addEventListener: (listener) => this.identityChangedHandler.attachEventListener(listener),
                removeEventListener: (listener) => this.identityChangedHandler.removeEventListener(listener)
            }
        });
    }

    /**
     * Generates the culture api endpoint.
     *
     * @private
     * @returns {(Public.Culture | undefined)} The culture api endpoint.
     * @memberof PublicApi
     */
    private generateCulture(): Public.Culture | undefined {
        if (this.cultureHelper && this.cultureHelper.isInitialized) {
            return Object.freeze<Public.Culture>({
                booleanFormatHelper: this.generateBooleanFormatHelper(),
                dateTimeFormatHelper: this.generateDateTimeFormatHelper(),
                fileSizeFormatHelper: this.generateFileSizeFormatHelper(),
                numberFormatHelper: this.generateNumberFormatHelper(),
                sortHelper: this.generateSortHelper(),
                vectorFormatter: this.generateVectorFormatter(),
                windreamFormatter: this.generateWindreamFormatter(),
                windreamFsFlagsFormatHelper: this.generateWindreamFsFlagsFormatHelper(),
                windreamStatusFormatHelper: this.generateWindreamStatusFormatHelper()
            });
        }
        return undefined;
    }

    /**
     * Generates the config api endpoint.
     *
     * @private
     * @returns {(Public.Config | undefined)} The config api endpoint.
     * @memberof PublicApi
     */
    private generateConfig(): Public.Config | undefined {
        if (this.globalConfig) {
            return Object.freeze<Public.Config>({
                windreamWebServiceURL: this.globalConfig.windreamWebServiceURL || '',
                windreamWebDAVServiceURL: this.globalConfig.windreamWebDAVServiceURL || '',
                authenticationMode: this.globalConfig.authenticationMode
            });
        }
        return undefined;
    }

    /**
     * Generates the intents api endpoint.
     *
     * @private
     * @returns {Public.Intents} The intents api endpoint.
     * @memberof PublicApi
     */
    private generateIntents(): Public.Intents {
        return Object.freeze<Public.Intents>({
            add: (handler) => this.intentManager.addIntentHandler(handler),
            send: (intent, data) => this.intentManager.sendIntent(intent as SHARE_INTENTS, data)
        });
    }
    /**
     * Generates the language api endpoint.
     *
     * @private
     * @returns {(Public.Language | undefined)} The language api endpoint.
     * @memberof PublicApi
     */
    private generateLanguage(): Public.Language | undefined {
        if (this.languageManager && this.cultureHelper && this.cultureHelper.isInitialized) {
            const frameworkLanguageProvider = this.languageManager.getLanguageProvider('framework');
            return Object.freeze<Public.Language>({
                available: Object.freeze(this.cultureHelper.availableLanguages.slice(0)),
                current: this.cultureHelper.getCurrentLanguage(),
                frameworkLanguageProvider: Object.freeze<Language.LanguageProvider>({
                    get: (key) => frameworkLanguageProvider.get(key),
                    getWithFormat: (key, ...placeHolders) => frameworkLanguageProvider.getWithFormat(key, ...placeHolders),
                    getTranslationFromProperty: (property) => frameworkLanguageProvider.getTranslationFromProperty(property)
                }),
                getLanguageProvider: (componentName: string) => this.languageManager.getLanguageProvider(componentName),
                getLanguageProviderFromUrl: (languageFileUrl: string) => this.languageManager.loadLanguageProviderFromUrl(languageFileUrl)
            });
        }
        return undefined;
    }

    /**
     * Generates the lifecycle api endpoint.
     *
     * @private
     * @returns {(Public.Lifecycle | undefined)} The lifecycle api endpoint.
     * @memberof PublicApi
     */
    private generateLifecycle(): Public.Lifecycle | undefined {
        if (this.viewManager) {
            return Object.freeze<Public.Lifecycle>({
                refresh: (changes) => this.viewManager?.promoteChanges(changes)
            });
        }
        return undefined;
    }

    /**
     * Generates the logger api endpoint.
     *
     * @private
     * @returns {Public.Logger} The logger api endpoint.
     * @memberof PublicApi
     */
    private generateLogger(): Public.Logger {
        return Object.freeze<Public.Logger>({
            debug: (className, methodName, caption, content) => this.logger.debug(className, methodName, caption, content),
            error: (className, methodName, caption, content) => this.logger.error(className, methodName, caption, content),
            fatal: (className, methodName, caption, content) => this.logger.fatal(className, methodName, caption, content),
            info: (className, methodName, caption, content) => this.logger.info(className, methodName, caption, content),
            log: (logLevel, className, methodName, caption, content) => this.logger.log(logLevel, className, methodName, caption, content),
            trace: (className, methodName, caption, content) => this.logger.trace(className, methodName, caption, content),
            warn: (className, methodName, caption, content) => this.logger.warn(className, methodName, caption, content),
        });
    }

    /**
     * Generates the utils api endpoint.
     *
     * @private
     * @returns {Public.Utils} The utils api endpoint.
     * @memberof PublicApi
     */
    private generateUtils(): Public.Utils {
        return Object.freeze<Public.Utils>({
            contains: (array, value) => this.utils.contains(array, value),
            compareVersionString: (versionOne, versionTwo, isFourPointVersion) => this.utils.compareVersionString(versionOne, versionTwo, isFourPointVersion),
            debounce: (func, timeout) => this.utils.debounce(func, timeout),
            deepClone: (object, keysToLowerCase) => this.utils.deepClone(object, keysToLowerCase),
            deepEqual: (expected, given, stringify) => this.utils.deepEqual(expected, given, stringify),
            escapeStringValue: (expected) => this.utils.escapeStringValue(expected),
            findIndex: (array, value) => this.utils.findIndex(array, value),
            getDragFileCount: (expected) => this.utils.getDragFileCount(expected),
            getIdentityTypes: (identities) => this.utils.getIdentityTypes(identities),
            getRandomString: () => this.utils.getRandomString(),
            getUUID: () => this.utils.getUUID(),
            sortObjectPropertiesDeep: (obj) => this.utils.sortObjectPropertiesDeep(obj),
            getValueTypeAsString: (type) => this.utils.getValueTypeAsString(type),
            getWdAttribute: (element, attribute, maxIterations) => this.utils.getWdAttribute(element, attribute, maxIterations),
            isArray: (x): x is any[] => this.utils.isArray(x),
            isClonable: (value): value is Core.Clonable<any> => this.utils.isClonable(value),
            isDate: (element): element is Date => this.utils.isDate(element),
            isDateString: (element) => this.utils.isDateString(element),
            isDeepEqual: (expected, given, stringify) => this.utils.isDeepEqual(expected, given, stringify),
            isDefined: (element): element is (Object | string | boolean | number) => this.utils.isDefined(element),
            isDomainWithinLocalHost: (expected) => this.utils.isDomainWithinLocalHost(expected),
            isExactStringMatch: (exactString, text, ignoreCasing) => this.utils.isExactStringMatch(exactString, text, ignoreCasing),
            isFileDrag: (event) => this.utils.isFileDrag(event),
            isFileDrop: (event) => this.utils.isFileDrop(event),
            isHTMLElement: (element): element is HTMLElement => this.utils.isHTMLElement(element),
            isInternalDrop: (event) => this.utils.isInternalDrop(event),
            isLocalHost: (expected) => this.utils.isLocalHost(expected),
            isNullOrEmptyString: (valueToCheck) => this.utils.isNullOrEmptyString(valueToCheck),
            isNumberAttribute: (type) => this.utils.isNumberAttribute(type as number),
            isPromise: (objectToCheck): objectToCheck is Promise<any> => this.utils.isPromise(objectToCheck),
            isStringArray: (x): x is string[] => this.utils.isStringArray(x),
            isStringNullOrWhitespace: (element) => this.utils.isStringNullOrWhitespace(element),
            makeArrayDistinctive: (array) => this.utils.makeArrayDistinctive(array),
            moveArrayElement: (array, oldIndex, newIndex) => this.utils.moveArrayElement(array, oldIndex, newIndex),
            padString: (pad, str, leftPadded) => this.utils.padString(pad, str, leftPadded),
            parentsUntil: (element, checkFunction, maxIterations) => this.utils.parentsUntil(element, checkFunction, maxIterations),
            getFilesFromDataTransfer: (event) => this.utils.getFilesFromDataTransfer(event),
            isValidWindreamIdentity: (identity) => this.utils.isValidWindreamIdentity(identity),
            isValidNumber: (value) => this.utils.isValidNumber(value),
            round: (value, decimals) => this.utils.round(value, decimals),
            roundByValueType: (value: number, valueType: ValueTypes) => this.utils.roundByValueType(value, valueType)
        });
    }


    /**
     * Generates the focus handler api endpoint.
     *
     * @private
     * @returns {App.AppFocusHandler} The focus handler api point.
     * @memberof PublicApi
     */
    private generateFocusHandler(): App.AppFocusHandler {
        return Object.freeze<App.AppFocusHandler>({
            setDirectoryFocus: (componentGuid, identity) => this.appFocusHandler.setDirectoryFocus(componentGuid, identity),
            unsetFocus: () => this.appFocusHandler.unsetFocus(),
            setSelectedIdentities: (componentGuid, identities) => this.appFocusHandler.setSelectedIdentities(componentGuid, identities)
        });
    }

    /**
     * Generates the download handler api endpoint.
     *
     * @private
     * @returns {App.AppDownloadHandler} The download handler api point.
     * @memberof PublicApi
     */
    private generateDownloadHandler(): App.AppDownloadHandler {
        return Object.freeze<App.AppDownloadHandler>({
            download: (identity, addToBriefcase) => this.appDownloadHandler.download(identity, addToBriefcase),
            checkOut: (identity, addToBriefcase) => this.appDownloadHandler.checkOut(identity, addToBriefcase)
        });
    }

    /**
     * Generates the app api endpoint.
     *
     * @private
     * @returns {Public.App} The app api endpoint.
     * @memberof PublicApi
     */
    private generateApp(): Public.App {
        return Object.freeze<Public.App>({
            FocusHandler: this.generateFocusHandler(),
            DownloadHandler: this.generateDownloadHandler(),
            isApp: () => this.utils.deviceDetection.isApp()
        });
    }

    /**
     * Generates the pubsub api endpoint.
     *
     * @private
     * @returns {(Public.PubSub | undefined)} The pubsub api endpoint.
     * @memberof PublicApi
     */
    private generatePubSub(): Public.PubSub | undefined {
        if (this.pubSubHandler) {
            return Object.freeze<Public.PubSub>({
                publish: (guid, name, value) => this.pubSubHandler.publish(guid, name, value),
                subscribe: (guid, name, callback) => this.pubSubHandler.subscribe(guid, name, callback),
                unsubscribe: (guid, name) => this.pubSubHandler.unsubscribe(guid, name)
            });
        }
        return undefined;
    }

    /**
     * Generates the services api endpoint.
     *
     * @private
     * @returns {(Public.Services | undefined)} The services api endpoint.
     * @memberof PublicApi
     */
    private generateServices(): Public.Services | undefined {
        if (this.serviceManager) {
            const services = this.serviceManager.getServices();
            return Object.freeze<Public.Services>({
                Annotations: Object.freeze<Services.Annotations>({
                    checkAnnotationsEnvironmentPrerequisites: async (parameter) => {
                        return services.Annotations.checkAnnotationsEnvironmentPrerequisites(parameter) as Promise<any>;
                    }
                }),
                ChoiceLists: Object.freeze<Services.ChoiceListServices>({
                    getChoiceLists: async () => {
                        return services.ChoiceLists.getChoiceLists() as Promise<any>;
                    }
                }),
                Common: Object.freeze<Services.CommonServices>({
                    ensureFullIdentity: async (parameter) => {
                        return services.Common.ensureFullIdentity(new WindreamRequestOptions(parameter.identity)) as Promise<any>;
                    }
                }),
                Directories: Object.freeze<Services.DirectoryServices>({
                    addHistoryEntry: async (parameter) => {
                        const request = new AddHistoryRequestOptions(parameter.identity, parameter.comment);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.addHistoryEntry(request) as Promise<any>;
                    },
                    createDirectory: async (parameter): Promise<any> => {
                        const request = parameter.identity;
                        const folderName = parameter.folderName;
                        return services.Directories.createDirectory(request, folderName) as Promise<any>;
                    },
                    archive: async (parameter) => {
                        const request = new ArchiveDirectoryRequestOptions(parameter.identity, parameter.recursive);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.archive(request) as Promise<any>;
                    },
                    getDetails: async (parameter) => {
                        const request = new GetDetailsRequestOptions(parameter.identity);
                        if (parameter.values) {
                            request.values = parameter.values;
                        }
                        if (parameter.attributeFlags) {
                            request.attributeFlags = parameter.attributeFlags;
                        }
                        request.requestOptions = parameter.requestOptions;
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.getDetails(request) as Promise<any>;
                    },
                    getHistory: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.getHistory(request) as Promise<any>;
                    },
                    getRights: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.getDirectoryRights(request) as Promise<any>;
                    },
                    move: async (parameter) => {
                        const request = new MoveRequestOptions(parameter.source, parameter.target, parameter.responseDetailsType);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.moveDirectory(request) as Promise<any>;
                    },
                    update: async (parameter) => {
                        const request = new UpdateRequestOptions(parameter.identity);
                        request.attributes = parameter.attributes;
                        request.newLocation = parameter.newLocation;
                        request.newName = parameter.newName;
                        request.objectType = parameter.objectType;
                        request.requestOptions = parameter.requestOptions;
                        request.responseDetailsType = parameter.responseDetailsType;
                        return services.Directories.updateDirectory(request) as Promise<any>;
                    },
                    updateRights: async (parameter) => {
                        const request = new UpdateDirectoryRightsRequestOptions(parameter.identity, parameter.rightsModel);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.updateDirectoryRights(request) as Promise<any>;
                    },
                    download: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.download(request);
                    },
                    workLock: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.workLockDirectory(request);
                    },
                    undoWorkLock: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Directories.undoWorkLockDirectory(request);
                    }
                }),
                FileSystem: Object.freeze<Services.FileSystemServices>({
                    saveToFileSystem: async (parameter) => {
                        return services.FileSystem.saveToFileSystem(new SaveToFileSystemRequestOptions(parameter.buffer, parameter.fileName)) as Promise<any>;
                    }
                }),
                Documents: Object.freeze<Services.DocumentServices>({
                    addHistoryEntry: async (parameter) => {
                        const request = new AddHistoryRequestOptions(parameter.identity, parameter.comment);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.addHistoryEntry(request) as Promise<any>;
                    },
                    archive: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.archive(request) as Promise<any>;
                    },
                    checkIn: async (parameter) => {
                        const request = new WindreamFileRequestOptions(parameter.identity, parameter.file);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.checkIn(request) as Promise<any>;
                    },
                    checkOut: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.checkOut(request) as Promise<any>;
                    },
                    copy: async (parameter) => {
                        const request = new CopyRequestOptions(parameter.source, parameter.target);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.copy(request) as Promise<any>;
                    },
                    createNewVersion: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.createNewVersion(request) as Promise<any>;
                    },
                    download: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.download(request) as Promise<any>;
                    },
                    getBase64Content: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.DynamicWorkspace.getBase64Image(request) as Promise<any>;
                    },
                    getDetails: async (parameter) => {
                        const request = new GetDetailsRequestOptions(parameter.identity);
                        if (parameter.values) {
                            request.values = parameter.values;
                        }
                        if (parameter.attributeFlags) {
                            request.attributeFlags = parameter.attributeFlags;
                        }
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.getDetails(request) as Promise<any>;
                    },
                    getStringContent: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.DynamicWorkspace.getStringContent(request) as Promise<any>;
                    },
                    getRights: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.getDocumentRights(request) as Promise<any>;
                    },
                    getHistory: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.getHistory(request) as Promise<any>;
                    },
                    getLifecycleDetails: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.getLifecycleDetails(request) as Promise<any>;
                    },
                    getVersions: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.getVersions(request) as Promise<any>;
                    },
                    move: async (parameter) => {
                        const request = new MoveDocumentRequestOptions(parameter.source, parameter.target, parameter.flags, parameter.responseDetailsType);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.moveDocument(request) as Promise<any>;
                    },
                    undoCheckOut: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.undoCheckOut(request) as Promise<any>;
                    },
                    undoWorkLock: async (parameter) => {
                        const request = new WindreamRequestOptions(parameter.identity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.undoWorkLock(request) as Promise<any>;
                    },
                    update: async (parameter) => {
                        const updateRequest = new UpdateDocumentRequestOptions(parameter.identity);
                        updateRequest.attributes = parameter.attributes;
                        updateRequest.createNewVersion = parameter.createNewVersion;
                        updateRequest.newLocation = parameter.newLocation;
                        updateRequest.newName = parameter.newName;
                        updateRequest.objectType = parameter.objectType;
                        updateRequest.requestOptions = parameter.requestOptions;
                        updateRequest.responseDetailsType = parameter.responseDetailsType;
                        return services.Documents.updateDocument(updateRequest) as Promise<any>;
                    },
                    updateRights: async (parameter) => {
                        const request = new UpdateDocumentRightsRequestOptions(parameter.identity, parameter.rightsModel);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.updateDocumentRights(request) as Promise<any>;
                    },
                    upload: async (parameter) => {
                        const request = new UploadRequestOptions(parameter.identity, parameter.data);
                        request.flags = <number>parameter.flags;
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.upload(request) as Promise<any>;
                    },
                    workLock: async (parameter) => {
                        const request = new WorklockRequestOptions(parameter.identity, parameter.downloadOnSuccess);
                        request.requestOptions = parameter.requestOptions;
                        return services.Documents.workLock(request) as Promise<any>;
                    }
                }),
                Journal: Object.freeze<Services.JournalServices>({
                    getLastModifiedDocuments: async (parameter) => {
                        const request = new ResultAmountRequestOptions(parameter.maxAmountOfResults);
                        request.requestOptions = parameter.requestOptions;
                        return services.Journal.getLastModifiedDocuments(request) as Promise<any>;
                    }
                }),
                Navigation: Object.freeze<Services.NavigationServices>({
                    fullRootlineFetchNodes: async (parameter) => {
                        const request = new FetchNodesRequestOptions(parameter.identity, parameter.values, parameter.sorting as any, parameter.entity);
                        request.requestOptions = parameter.requestOptions;
                        return services.Navigation.fullRootlineFetchNodes(request) as Promise<any>;
                    }
                }),
                Permissions: Object.freeze<Services.PermissionServices>({
                    canPerformAction: async (parameter) => {
                        // TODO: Fix typings
                        const request = new CanPerformActionRequestOptions(parameter.identity, parameter.actions);
                        request.requestOptions = parameter.requestOptions;
                        return services.Permissions.canPerformAction(request) as Promise<any>;
                    }
                }),
                Search: Object.freeze<Services.SearchServices>({
                    search: async (parameter) => {
                        const request = new SearchRequestOptions();
                        request.searchQuery = parameter.searchQuery as any;
                        request.searchDTO = parameter.searchDTO;

                        request.requestOptions = parameter.requestOptions;
                        return services.Search.search(request) as Promise<any>;
                    }
                }),
                Users: Object.freeze<Services.UserServices>({
                    getAllUsers: async (parameter?) => {
                        const request = new BaseRequestOptions();
                        if (parameter) {
                            request.requestOptions = parameter.requestOptions;
                        }

                        return services.DynamicWorkspace.getAllUsers(request) as Promise<any>;
                    },
                    getAllUserGroups: async (parameter?) => {
                        const request = new BaseRequestOptions();
                        if (parameter) {
                            request.requestOptions = parameter.requestOptions;
                        }

                        return services.DynamicWorkspace.getAllUserGroups() as Promise<any>;
                    }
                }),
                Subscriptions: Object.freeze<Services.SubscriptionsServices>({
                    createSubscription: async (parameter) => {
                        return services.Subscriptions.createSubscription(parameter) as Promise<void>;
                    }, createSubscriptions: async (parameter) => {
                        return services.Subscriptions.createSubscriptions(parameter) as Promise<void>;
                    }, getSubscriptionEvents: async () => {
                        return services.Subscriptions.getSubscriptionEvents() as Promise<SubscriptionEventOptions>;
                    }
                }),
                Favorites: Object.freeze<Services.FavoritesServices>({
                    addFavorites: async (addFavoritesRequest) => {
                        return services.Favorites.addFavorites(addFavoritesRequest);
                    },
                    getFavorites: async (getFavoritesRequest) => {
                        return services.Favorites.getFavorites(getFavoritesRequest);
                    },
                    removeFavorites: async (removeFavoritesRequest) => {
                        return services.Favorites.removeFavorites(removeFavoritesRequest);
                    }
                })
            });
        }
        return undefined;
    }


    /**
     * Generates the register api endpoint.
     *
     * @private
     * @returns {(DynamicWorkspaceRegister | undefined)} The register api endpoint.
     * @memberof PublicApi
     */
    private generateRegister(): DynamicWorkspaceRegister | undefined {
        return Object.freeze<DynamicWorkspaceRegister>((componentName: string, component: any, migrateFunction?: MigrateFunction) => {
            const moduleRegistration = new ModuleRegistration(componentName, component,
                Public.ModuleRegistrationType.Component, migrateFunction);
            this.moduleRegistrationHelper.registerModule(moduleRegistration);
        });
    }

    /**
     * Generates the register module endpoint.
     *
     * @private
     * @returns {(DynamicWorkspaceRegisterModule | undefined)} The function.
     * @memberof PublicApi
     */
    private generateRegisterModule(): DynamicWorkspaceRegisterModule | undefined {
        return Object.freeze<DynamicWorkspaceRegisterModule>((registration: ApiModuleRegistration) => {
            if (!registration) {
                throw new Error('Registration object not defined.');
            }
            const moduleRegistration = new ModuleRegistration(registration.id, registration.classReference,
                registration.type, registration.migrationFunction);
            this.moduleRegistrationHelper.registerModule(moduleRegistration);
        });
    }

    /**
     * Generates the popup helper endpoint.
     *
     * @private
     * @returns {Public.PopupHelper} The popup helper.
     * @memberof PublicApi
     */
    private generatePopupHelper(): Public.PopupHelper {
        return Object.freeze<Public.PopupHelper>({
            openWindreamPreviewPopup: (options: WindreamPreviewPopupOptions) => {
                const popupOptions = new WindreamPreviewPopupOptionsImpl(options.windreamFile);
                popupOptions.title = options.title;
                return this.popupHelper.openWindreamPreviewPopup(popupOptions);
            },

            openWindreamFilePickerPopup: (options: WindreamFilePickerPopupOptions) =>
                this.popupHelper.openWindreamFilePickerPopup(options),

            closePopup: (popup: any) =>
                this.popupHelper.closePopup(popup),

            openConfirmationPopup: (yesButtonCallback: () => void, noButtonCallback: () => void, options: PopupOptions) =>
                this.popupHelper.openConfirmationPopup(yesButtonCallback, noButtonCallback, options as IPopupOptions) as Popup,

            openIndexPopups: (identities: Core.WindreamIdentity[]) =>
                this.popupHelper.openIndexPopups(identities),

            openInputBoxPopup: (okButtonCallback: (text: string) => void, cancelButtonCallback: () => void, defaultValue: string, options: InputPopupOptions) =>
                this.popupHelper.openInputBoxPopup(okButtonCallback, cancelButtonCallback, defaultValue, options as any) as Popup,

            openOkCancelPopup: (okButtonCallback: () => void, cancelButtonCallback: () => void, options: PopupOptions) =>
                this.popupHelper.openOkCancelPopup(okButtonCallback, cancelButtonCallback, options as IPopupOptions) as Popup,

            openPathPopup: (currentPath: string, options: PopupOptions,
                callback: (path: string | undefined) => void) =>
                this.popupHelper.openPathPopup(currentPath, options as IPopupOptions, callback),

            openFolderIdentityPickerPopup: (currentPath: string, options: PopupOptions,
                callback: (path: WindreamIdentity | undefined) => void) =>
                this.popupHelper.openPathPopup(currentPath, options as IPopupOptions, callback, true),

            openPopup: (options: PopupOptions) =>
                this.popupHelper.openPopup(options as IPopupOptions) as Popup,

            openSavePopup: (okButtonCallback: () => void, cancelButtonCallback: () => void, discardButtonCallback: () => void) =>
                this.popupHelper.openSavePopup(okButtonCallback, cancelButtonCallback, discardButtonCallback),

            openComponentPopup: (options: ComponentPopupOptions) => {
                return this.popupHelper.openComponentPopup(options);
            },

            updatePopupTitle: (popupInstance: Popup, newTitle: string) =>
                this.popupHelper.updatePopupTitle(popupInstance as IPopup, newTitle)
        });
    }

    /**
     * Generates the notification api endpoint.
     *
     * @private
     * @returns {Public.Notification} The notification api endpoint.
     * @memberof PublicApi
     */
    private generateNotification(): Public.Notification {
        return Object.freeze<Public.Notification>({
            error: (options) => this.notificationHelper.error(options),
            info: (options) => this.notificationHelper.info(options),
            success: (options) => this.notificationHelper.success(options),
            warning: (options) => this.notificationHelper.warning(options)
        });
    }

    /**
     * Generates the metadata api endpoint.
     *
     * @private
     * @returns {(Public.Metadata | undefined)} The metadata api endpoint.
     * @memberof PublicApi
     */
    private generateMetadata(): Public.Metadata | undefined {
        if (this.metadataStore) {
            const api = this;
            // @ts-ignore - TODO: Check why this doesn't match
            return Object.freeze<Public.Metadata>({
                windream: Object.freeze({
                    Attributes: Object.freeze({
                        get available() {
                            return api.utils.deepClone(Object.freeze(api.metadataStore.availableAttributes));
                        },
                        get objectTypes() {
                            return api.utils.deepClone(Object.freeze(api.metadataStore.objectTypes));
                        },
                        get relational() {
                            return api.utils.deepClone(Object.freeze(api.metadataStore.relationalAttributes));
                        },
                        get system() {
                            return api.utils.deepClone(Object.freeze(api.metadataStore.systemAttributes));
                        },
                        get type() {
                            return api.utils.deepClone(Object.freeze(api.metadataStore.typeSpecificAttributes));
                        },
                        get virtual() {
                            return api.utils.deepClone(Object.freeze(api.metadataStore.virtualAttributes));
                        }
                    })
                })
            });
        }
        return undefined;
    }

    /**
     * Generates the messages API.
     *
     * @private
     * @returns {Public.Messages} The messages API.
     * @memberof PublicApi
     */
    private generateMessagesApi(): Public.Messages {
        return Object.freeze<Public.Messages>({
            subscribe: (subscriberId: string, topic: string, messageHandler: (message: DWCore.Messages.Message) => void) => this.messageBus.subscribe(subscriberId, topic, messageHandler),
            unsubscribe: (subscriberId: string, topic: string) => this.messageBus.unsubscribe(subscriberId, topic)
        });
    }

    /**
     * Generates the device api endpoint.
     *
     * @private
     * @returns {Public.Device} The device api endpoint.
     * @memberof PublicApi
     */
    private generateDevice(): Public.Device {
        return Object.freeze<Public.Device>({
            isPhone: () => this.utils.deviceDetection.isPhone(),
            isTablet: () => this.utils.deviceDetection.isTablet(),
            getCurrentDevice: () => this.utils.deviceDetection.getCurrentDevice() as unknown as DEVICES
        });
    }

    /**
     * Generates the script api endpoint.
     *
     * @private
     * @returns {(Public.Script | undefined)} The script api endpoint.
     * @memberof PublicApi
     */
    private generateScript(): Public.Script | undefined {
        if (this.directScriptExecutor && this.windreamScriptExecutor) {
            return Object.freeze<Public.Script>({
                Direct: Object.freeze<Public.DirectScript>({
                    execute: async (script, args, callback, options) => this.directScriptExecutor.execute(script, args, callback, options)
                }),
                Windream: Object.freeze<Public.WindreamScript>({
                    execute: async (source, scriptName, args, callback, options) => this.windreamScriptExecutor.execute(source, scriptName, args, callback, options)
                })
            });
        }
        return undefined;
    }

    /**
     * Generates the request executor api endpoint.
     *
     * @private
     * @returns {Public.RequestExecutor} The request executor api endpoint.
     * @memberof PublicApi
     */
    private generateRequestExecutor(): Public.RequestExecutor {
        return Object.freeze<Public.RequestExecutor>({
            execute: async (url, request) => {
                const action = request && request.method ? request.method : 'GET';
                const body = request ? request.body : undefined;
                const httpHeader = request ? request.headers : undefined;
                const resourcePointer = new HttpResourcePointer(action, url, body, httpHeader);
                let options = undefined;
                if (request) {
                    options = new RequestOptions();
                    options.preventFetchFromCache = request.preventFetchFromCache;
                }
                return this.requestExecutor.executeRequest(resourcePointer, options) as Promise<any>;
            }
        });
    }

    /**
     * Generates the ui components api endpoint.
     *
     * @private
     * @returns {Public.UiComponent} The ui components api endpoint.
     * @memberof PublicApi
     */
    private generateUiComponents(): Public.UiComponent {
        return Object.freeze<Public.UiComponent>({
            button: (targetElement) => this.uiComponentFactory.button(targetElement),
            checkBox: (targetElement) => this.uiComponentFactory.checkBox(targetElement),
            comboBox: (targetElement) => this.uiComponentFactory.comboBox(targetElement),
            datePicker: (targetElement) => this.uiComponentFactory.datePicker(targetElement),
            dropDownButton: (targetElement) => this.uiComponentFactory.dropDownButton(targetElement),
            multiSelectBox: (targetElement) => this.uiComponentFactory.multiSelectBox(targetElement),
            numberBox: (targetElement) => this.uiComponentFactory.numberBox(targetElement),
            radioButtonGroup: (targetElement) => this.uiComponentFactory.radioButtonGroup(targetElement),
            selectBox: (targetElement) => this.uiComponentFactory.selectBox(targetElement),
            tagBox: (targetElement) => this.uiComponentFactory.tagBox(targetElement),
            textArea: (targetElement) => this.uiComponentFactory.textArea(targetElement),
            textBox: (targetElement) => this.uiComponentFactory.textBox(targetElement),
            treeView: (targetElement) => this.uiComponentFactory.treeView(targetElement),
            dataGrid: (targetElement) => this.uiComponentFactory.dataGrid(targetElement),
            list: (targetElement) => this.uiComponentFactory.list(targetElement),
            dynamicTreeView: (targetElement, languageProvider) => this.uiComponentFactory.dynamicTreeView(targetElement, languageProvider)
        } as Public.UiComponent);
    }

    /**
     * Generate the validation namespace.
     *
     * @private
     * @returns {Public.Validation} The validation namespace.
     * @memberof PublicApi
     */
    private generateValidation(): Public.Validation {
        return Object.freeze<Public.Validation>({
            getValidationPattern: (type) => ValidationHelper.getValidationPattern(type)
        } as Public.Validation);
    }

    /**
     * Generates the ui api endpoint.
     *
     * @private
     * @returns {Public.Ui} The ui api endpoint.
     * @memberof PublicApi
     */
    private generateUi(): Public.Ui {
        return Object.freeze<Public.Ui>({
            components: this.generateUiComponents(),
            validation: this.generateValidation()
        });
    }

    /**
     * Generates the shared API endpoint.
     *
     * @private
     * @returns {Public.Shared} The shared API endpoint.
     * @memberof PublicApi
     */
    private generateShared(): Public.Shared {
        return Object.freeze<Public.Shared>({
            Settings: {
                get: (settingsName) => {
                    if (this.sharedSettingsProvider) {
                        return this.sharedSettingsProvider.get(settingsName);
                    }
                    this.logger.error('PublicApi.SharedSettingsProvider', 'get', 'No SharedSettingsProvider set');
                    return null;
                },
                set: (settingsName, value) => {
                    if (this.sharedSettingsProvider) {
                        return this.sharedSettingsProvider.setTempSetting(settingsName, value);
                    }
                    this.logger.error('PublicApi.SharedSettingsProvider', 'set', 'No SharedSettingsProvider set');
                }
            }
        });
    }

    /**
     * Generate routing namespace.
     *
     * @private
     * @returns {Public.Routing} The routing namespace.
     * @memberof PublicApi
     */
    private generateRouting(): Public.Routing {
        return Object.freeze<Public.Routing>({
            navigate: (url: string, skipHistory: boolean = false, keepSearch: boolean = true) => {
                return RouteManager.navigate(url, skipHistory, keepSearch);
            },
            getQueryParameter: (parameterName: string): string | null => {
                return RoutingUtils.getQueryParameter(parameterName);
            },
            back: (): void => {
                return RouteManager.back();
            },
            replaceState: (data: any, title: string, url?: string | null | undefined): void => {
                return RouteManager.replaceState(data, title, url);
            },
            pushState: (data: any, title: string, url?: string | null | undefined): void => {
                return RouteManager.pushState(data, title, url);
            },
            replaceOrPushState: (data: any, title: string, url: string, keepSearch: boolean = true): void => {
                return RouteManager.replaceOrPushState(data, title, url, keepSearch);
            },
            replaceCurrentPath: (path: string, keepSearch: boolean = true, keepHash: boolean = false, state?: any): void => {
                return RouteManager.replaceCurrentPath(path, keepSearch, keepHash, state);
            },
            getApplicationBasePath: (onlyPath?: boolean) => {
                return RoutingUtils.getApplicationBasePath(onlyPath);
            }
        });
    }

    /**
     * Generates a file transfer manager.
     *
     * @private
     * @returns {DWCore.Files.FileTransferManager} The file transfer manager.
     * @memberof PublicApi
     */
    private generateFileTransferManager(): DWCore.Files.FileTransferManager {
        return Object.freeze<DWCore.Files.FileTransferManager>({
            PopOver: {
                render: (files: DWCore.Files.FileUpload[]): void => {
                    return this.fileTransferManager.PopOver.render(files);
                },
                updateProgress: (uuid: string, status: string, progress?: number): void => {
                    return this.fileTransferManager.PopOver.updateProgress(uuid, status, progress);
                }
            }
        });
    }
}