import { BaseEntityDTO, IdLocationNameEntityIdentityDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { SearchAttributeFlagsDTO, SearchConditionDTO, SearchDTO, SearchMode } from '../../../typings/windreamWebService/Windream.WebService.Search';
import { IMetadataStore } from '../../caching';
import { Utils, WindreamIdentity } from '../../common';
import { SEARCH_RELATIONS } from '../../dynamicWorkspace';
import { Logger } from '../../logging';
import { SearchCondition } from './searchCondition';
import { SearchQuery } from './searchQuery';
import { SEARCH_FILLERS, SearchGroup, SearchModelConverterRecursionParameter } from '.';

/**
 * This class converts search configuration models.
 *
 * @export
 * @class SearchModelConverter
 */
export class SearchModelConverter {

  private logger: Logger;
  private metadataStore: IMetadataStore;


  /**
   * Creates an instance of SearchModelConverter.
   * @param {Logger} logger Logger instance to use.
   * @param {IMetadataStore} metadataStore List of all windream attributes.
   * @memberof SearchModelConverter
   */
  public constructor(logger: Logger, metadataStore: IMetadataStore) {
    this.logger = logger;
    this.metadataStore = metadataStore;
  }

  /**
   * Converts a given search configuration model to the equivalent WebService model.
   * @param {SearchGroup} searchConfiguration
   * @param searchQuery
   * @returns {SearchDTO}
   * @memberof SearchModelConverter
   */
  public convert(searchQuery: SearchQuery): SearchDTO | null {

    if (!searchQuery) {
      throw new ReferenceError('The argument "searchQuery" is null or undefined');
    }

    const tempEntityValue: number = searchQuery.entity;
    const tempEntitiy = <BaseEntityDTO>tempEntityValue;

    const result: SearchDTO = {
      Conditions: [],
      Entity: tempEntitiy,
      Mode: SearchMode.Synchronous,
    };
    if (searchQuery.sorting) {
      result.Sorting = {
        AttributeName: searchQuery.sorting.attributeName,
        Direction: searchQuery.sorting.direction
      };
    }
    if (searchQuery.resultLimit && searchQuery.resultLimit > 0) {
      result.Limit = searchQuery.resultLimit;
    }
    if (searchQuery.attributeFlags) {
      const tempAttributeFlagsValue: number = searchQuery.attributeFlags;
      result.AttributeFlags = <SearchAttributeFlagsDTO>tempAttributeFlagsValue;
    }
    if (searchQuery.indices) {
      result.Values = searchQuery.indices;
    }

    if (!searchQuery.group) {
      return result;
    }

    const recursionParameter = new SearchModelConverterRecursionParameter();
    recursionParameter.placeholderValues = searchQuery.placeholderValues;
    recursionParameter.conditionPlaceholderValues = searchQuery.conditionPlaceholderValues;

    this.convertRecursive(result, searchQuery.group, recursionParameter);

    // Try to remove the search relation on the last converted condition.
    if (recursionParameter.lastConvertedSearchCondition) {
      delete recursionParameter.lastConvertedSearchCondition.SearchRelation;
    }

    return result;
  }

  /**
   * Converts a given search configuration or condition model to the equivalent WebService model.
   *
   * @private
   * @param {SearchDTO} ioResult
   * @param {(SearchGroup | SearchCondition)} searchObject
   * @param {SearchModelConverterRecursionParameter} recursionParameter
   * @returns {void}
   * @memberof SearchModelConverter
   */
  private convertRecursive(
    ioResult: SearchDTO,
    searchObject: SearchGroup | SearchCondition,
    recursionParameter: SearchModelConverterRecursionParameter): void {

    if (!searchObject || !ioResult) {
      return;
    }

    if (this.isSearchGroup(searchObject) && searchObject.conditions.length === 0) {
      return;
    }

    if (this.isSearchGroup(searchObject) && searchObject.conditions.length === 1) {
      this.convertRecursive(ioResult, searchObject.conditions[0], recursionParameter);
      return;
    }

    // Group of conditions
    if (this.isSearchGroup(searchObject) && searchObject.conditions.length > 1) {
      this.convertSearchGroup(ioResult, searchObject, recursionParameter);
    } else { // Single condition
      const tempSearchCondition = <SearchCondition>searchObject;
      if (this.isValidSearchCondition(tempSearchCondition)) {
        this.convertSearchCondition(ioResult, tempSearchCondition, recursionParameter);
      }
    }
  }

  /**
   * Converts a search group.
   *
   * @private
   * @param {SearchDTO} ioResult
   * @param {SearchGroup} searchGroup
   * @param {SearchModelConverterRecursionParameter} recursionParameter
   * @returns {void}
   * @memberof SearchModelConverter
   */
  private convertSearchGroup(ioResult: SearchDTO,
    searchGroup: SearchGroup,
    recursionParameter: SearchModelConverterRecursionParameter): void {
    // Filter empty placeholder stuff.
    const searchConditionsWithoutEmpty = this.filterEmptyPlaceholderSearchObjects(searchGroup, recursionParameter.placeholderValues, recursionParameter.conditionPlaceholderValues);
    if (searchConditionsWithoutEmpty && searchConditionsWithoutEmpty.conditions.length > 0) {
      // Remember the current relation of the group (used for the creation of a single condition).
      if (Utils.Instance.isDefined(searchConditionsWithoutEmpty.relation)) {
        recursionParameter.relation = searchConditionsWithoutEmpty.relation;
      }

      // Incremet the amount of possible left brackets.
      recursionParameter.leftBrackets++;

      // Process all sub conditions/groups recursive.
      const conditionsCount = searchConditionsWithoutEmpty.conditions.length;
      for (let i = 0; i < conditionsCount; i++) {

        // If the predecessor was a group, then set the current relation in the last condition.
        if (i > 0 && this.isSearchGroup(searchConditionsWithoutEmpty.conditions[i - 1]) && recursionParameter.lastConvertedSearchCondition) {
          recursionParameter.lastConvertedSearchCondition.SearchRelation = <number>searchConditionsWithoutEmpty.relation;
        }

        this.convertRecursive(ioResult, searchConditionsWithoutEmpty.conditions[i], recursionParameter);
      }

      // Try to set right brackets of the last converted condition.
      if (recursionParameter.lastConvertedSearchCondition) {
        if (Utils.isDefined(recursionParameter.lastConvertedSearchCondition.RightBrackets)) {
          recursionParameter.lastConvertedSearchCondition.RightBrackets++;
        } else {
          recursionParameter.lastConvertedSearchCondition.RightBrackets = 1;
        }
      }
    }
  }

  /**
   * Filters empty placeholder search objects.
   * @private
   * @param {((SearchGroup | SearchCondition)[])} searchObjects
   * @param searchGroup
   * @param {(Map<string, string | number | Date | boolean>)} placeholderValues
   * @param {Map<string, SearchGroup>} conditionPlaceHolderValues
   * @returns {((SearchGroup | SearchCondition)[])}
   * @memberof SearchModelConverter
   */
  private filterEmptyPlaceholderSearchObjects(searchGroup: SearchGroup,
    placeholderValues: Map<string, string | number | Date | boolean>,
    conditionPlaceHolderValues: Map<string, SearchGroup>): SearchGroup {

    const result = new SearchGroup();
    result.relation = searchGroup.relation;
    result.conditions = searchGroup.conditions.filter((condition: SearchCondition) => {

      // Filter out empty placeholder condition if filler says it should be treated as deleted
      if (Utils.Instance.isDefined(condition.value) && condition.isPlaceholder && condition.filler === SEARCH_FILLERS.TreatAsDeleted) {
        if (placeholderValues && placeholderValues.has && typeof condition.value === 'string' && placeholderValues.has(condition.value)) {
          const values = placeholderValues.get(condition.value);
          if (values && Utils.Instance.isArray(values) && values.length === 0) {
            return false;
          } else {
            return (Utils.Instance.isDefined(placeholderValues.get(condition.value)) && placeholderValues.get(condition.value) !== '');
          }
        }
        return false;
      }
      // Filter out empty placeholder group condition if filler says it should be treated as deleted
      if (Utils.Instance.isDefined(condition.value) && condition.isPlaceholderCondition && condition.filler === SEARCH_FILLERS.TreatAsDeleted) {
        if (conditionPlaceHolderValues && typeof condition.value === 'string' && conditionPlaceHolderValues.has(condition.value)) {
          return Utils.Instance.isDefined(conditionPlaceHolderValues.get(condition.value));
        } else {
          return false;
        }
      }
      return true;
    });

    return result;
  }

  /**
   * Fix specific values within a search condition.
   *
   * @private
   * @param {SearchCondition} searchCondition The condition to fix.
   * @memberof SearchModelConverter
   */
  private fixSearchCondition(searchCondition: SearchCondition): void {
    if (Utils.Instance.isDefined(searchCondition.value)) {
      if (typeof searchCondition.value === 'string') {
        if (Utils.Instance.isNullOrEmptyString(searchCondition.value) && searchCondition.filler !== SEARCH_FILLERS.TreatAsEmpty) {
          searchCondition.value = null;
        }
      }
    }
  }


  /**
   * Converts a given search condition to the equivalent Web Service search condition.
   *
   * @private
   * @param {SearchDTO} ioResult
   * @param {SearchCondition} searchCondition
   * @param {SearchModelConverterRecursionParameter} recursionParameter
   * @returns {void}
   * @memberof SearchModelConverter
   */
  // Disable because I won't refactor this mess
  // eslint-disable-next-line complexity
  private convertSearchCondition(ioResult: SearchDTO, searchCondition: SearchCondition, recursionParameter: SearchModelConverterRecursionParameter): void {

    if (!searchCondition) {
      return;
    }

    this.fixSearchCondition(searchCondition);

    let newCondition: SearchConditionDTO = {
      Column: searchCondition.column,
      LeftBrackets: recursionParameter.leftBrackets,
      RightBrackets: 0,
      SearchOperator: <number>searchCondition.operator,
      Value: searchCondition.value,
      CaseSensitive: searchCondition.caseSensitive
    };

    if (Utils.isDefined(recursionParameter.relation) && recursionParameter.relation !== SEARCH_RELATIONS.Undefined) {
      newCondition.SearchRelation = <number>recursionParameter.relation;
    }

    // Try to replace placeholders.
    // TODO: FIX Maps if search query doesn't have a Map and is loaded by JSON.parse which will create an object instead of map.
    if (this.isPlaceholderCondition(searchCondition)) {
      if (recursionParameter.placeholderValues && recursionParameter.placeholderValues.has && recursionParameter.placeholderValues.has(newCondition.Value)) {
        let value = recursionParameter.placeholderValues.get(newCondition.Value) === '' ? null : recursionParameter.placeholderValues.get(newCondition.Value);
        if (Utils.Instance.isDefined(value)) {
          // Try to convert value to number if column is a number type
          const attributeInfo = this.metadataStore.availableAttributes.find((attribute) => attribute.name === newCondition.Column);
          if (attributeInfo) {
            if (Utils.Instance.isNumberAttribute(attributeInfo.type) && value) {
              try {
                if (typeof (value) !== 'number') {
                  value = parseFloat(value.toString());
                }
              } catch (err) {
                this.logger.error('SearchModelConverter', 'convertSearchCondition', 'Failed to convert to number', err);
              }
            }
          }
        }
        newCondition.Value = value;
      } else {
        switch (searchCondition.filler) {
          case SEARCH_FILLERS.TreatAsDeleted:
            return;
          case SEARCH_FILLERS.TreatAsEmpty:
            newCondition.Value = '';
            break;
          case SEARCH_FILLERS.TreatAsNull:
            newCondition.Value = null;
            break;
          case SEARCH_FILLERS.TreatAsWildcard:
            newCondition.Value = '*';
            break;
        }
      }
    }

    // Try to replace condition placeholders
    if (this.isConditionPlaceholderCondition(searchCondition)) {
      if (recursionParameter.conditionPlaceholderValues && recursionParameter.conditionPlaceholderValues.has && recursionParameter.conditionPlaceholderValues.has(newCondition.Value)) {
        const placeholderValue = recursionParameter.conditionPlaceholderValues.get(newCondition.Value);

        if (placeholderValue) {
          if (placeholderValue.conditions && placeholderValue.conditions[0] && !Utils.Instance.isDefined((placeholderValue.conditions[0] as SearchCondition).value)
            && !Utils.Instance.isDefined(newCondition.SubItems)) {
            const placeholderCondition = placeholderValue.conditions[0] as SearchCondition;
            switch (placeholderCondition.filler) {
              case SEARCH_FILLERS.TreatAsDeleted:
                return;
              case SEARCH_FILLERS.TreatAsEmpty:
                placeholderCondition.value = '';
                break;
              case SEARCH_FILLERS.TreatAsNull:
                placeholderCondition.value = null;
                break;
              case SEARCH_FILLERS.TreatAsWildcard:
                placeholderCondition.value = '*';
                break;
            }
          }
          this.convertRecursive(ioResult, placeholderValue, recursionParameter);
          return;
        }
      } else { // Condition placeholder without a value, return null
        switch (searchCondition.filler) {
          case SEARCH_FILLERS.TreatAsDeleted:
            return;
          case SEARCH_FILLERS.TreatAsEmpty:
            newCondition.Value = '';
            break;
          case SEARCH_FILLERS.TreatAsNull:
            newCondition.Value = null;
            break;
          case SEARCH_FILLERS.TreatAsWildcard:
            newCondition.Value = '*';
            break;
        }
      }
    } else {
      // Reset the left brackets value, because they were used.
      recursionParameter.leftBrackets = 0;
    }

    if (searchCondition.autowildCards) {
      newCondition.AutoWildcards = searchCondition.autowildCards;
    }

    if (searchCondition.column && searchCondition.column.toLowerCase() === 'subobjects') {
      if (searchCondition.value) {
        newCondition = this.adjustForSubSearch(searchCondition, newCondition, recursionParameter);
      } else { // Ignore condition if no path if set to avoid error in web service
        return;
      }
    }
    if ((this.isPlaceholderCondition(searchCondition) || this.isConditionPlaceholderCondition(searchCondition)) &&
      !Utils.Instance.isDefined(newCondition.Value) && !Utils.Instance.isDefined(newCondition.SubItems)) {
      switch (searchCondition.filler) {
        case SEARCH_FILLERS.TreatAsDeleted:
          return;
        case SEARCH_FILLERS.TreatAsEmpty:
          newCondition.Value = '';
          break;
        case SEARCH_FILLERS.TreatAsNull:
          newCondition.Value = null;
          break;
        case SEARCH_FILLERS.TreatAsWildcard:
          newCondition.Value = '*';
          break;
      }
    }

    // Remember a reference to the last converted search condition for later usage (set the right brackets at the end).
    recursionParameter.lastConvertedSearchCondition = newCondition;

    ioResult.Conditions.push(newCondition);
  }

  /**
   * Adjusts a given Web Service condition, so that the SubItems are set correct.
   * 
   * @private
   * @param {SearchCondition} searchCondition 
   * @param {SearchConditionDTO} wsSearchCondition 
   * @param {SearchModelConverterRecursionParameter} recursionParameter 
   * @returns {SearchConditionDTO} 
   * @memberof SearchModelConverter
   */
  private adjustForSubSearch(searchCondition: SearchCondition, wsSearchCondition: SearchConditionDTO,
    recursionParameter: SearchModelConverterRecursionParameter): SearchConditionDTO {

    wsSearchCondition.Value = null;
    const windreamIdentity = new WindreamIdentity();
    if (searchCondition.isPlaceholder && typeof searchCondition.value === 'string'
      && recursionParameter.placeholderValues.has(searchCondition.value)) {
      // Checked via has before but typescript doesn't understand that.
      windreamIdentity.setLocationComplete(recursionParameter.placeholderValues.get(searchCondition.value) as string);
    } else {
      windreamIdentity.setLocationComplete(<string>searchCondition.value);
    }

    if (windreamIdentity.location && windreamIdentity.name) {
      const newSubItem: IdLocationNameEntityIdentityDTO = {
        Entity: BaseEntityDTO.Folder,
        Id: 0,
        Location: windreamIdentity.location,
        Name: windreamIdentity.name
      };

      if (!wsSearchCondition.SubItems) {
        wsSearchCondition.SubItems = [];
      }
      wsSearchCondition.SubItems.push(newSubItem);
    }

    return wsSearchCondition;
  }


  /**
   * Determines if a given search condition is valid.
   *
   * @private
   * @param {SearchCondition} searchCondition
   * @returns {boolean}
   *
   * @memberof SearchModelConverter
   */
  private isValidSearchCondition(searchCondition: SearchCondition): boolean {

    if (!searchCondition) {
      return false;
    }

    if (searchCondition.column === 'dwObjectTypeID' && (!searchCondition.value || searchCondition.value === null)) {
      return false;
    }

    return true;
  }

  /**
   * Checks if the given search condition is a placeholder condition.
   * 
   * @private
   * @param {SearchCondition} searchCondition The condition to check.
   * @returns {boolean} Whether the condition is a placeholder condition.
   * @memberof SearchModelConverter
   */
  private isPlaceholderCondition(searchCondition: SearchCondition): boolean {
    return searchCondition.isPlaceholder;
  }

  /**
   * Checks if the given search condition is a condition placeholder condition.
   *
   * @private
   * @param {SearchCondition} searchCondition
   * @returns {boolean}
   * @memberof SearchModelConverter
   */
  private isConditionPlaceholderCondition(searchCondition: SearchCondition): boolean {
    return searchCondition.isPlaceholderCondition;
  }


  /**
   * Checks if the given object is a SearchGroup.
   *
   * @private
   * @param {*} group Object to check.
   * @returns {group is SearchGroup}  True if the object is a SearchGroup.
   * @memberof SearchModelConverter
   */
  private isSearchGroup(group: any): group is SearchGroup {
    return typeof group.conditions !== 'undefined';
  }

}