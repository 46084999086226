import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Provides a token for a request,
 *
 * @export
 * @class TokenRequestOptions
 * @extends {BaseRequestOptions}
 */
export class TokenRequestOptions extends BaseRequestOptions {

    /**
     * The token to use.
     *
     * @type {string}
     * @memberof TokenRequestOptions
     */
    public token?: string;
}