import { DependsOnOptionGroup } from './dependsOnOptionGroup';

/**
 * The configuration model.
 * 
 * @export
 * @class Configuration
 */
export class Configuration {

    /**
     * The name of this configuration.
     * 
     * @type {string}
     * @memberof Configuration
     */
    public name: string;

    /**
     * The descirption of this configuration.
     * 
     * @type {string}
     * @memberof Configuration
     */
    public description?: string;

    /**
     * The data type of this configuration.
     * 
     * @type {string}
     * @memberof Configuration
     */
    public dataType?: string;

    /**
     * The default of this configuration.
     * 
     * @type {string}
     * @memberof Configuration
     */
    public default?: any;

    /**
     * Whether the user can change this configuration or not.
     * 
     * @type {boolean}
     * @memberof Configuration
     */
    public userEditable?: boolean;

    /**
     * WHether the configuration has different values for different languages.
     * 
     * @type {boolean}
     * @memberof Configuration
     */
    public isMultilingual?: boolean;

    /**
     * Regex to match value against.
     * 
     * @type {string}
     * @memberof Configuration
     */
    public regex?: string;

    /**
     * Whether the spellcheck is active or not.
     *
     * @type {boolean}
     * @memberof Configuration
     */
    public spellcheck?: boolean;

    /**
     * Specify on which other configuration this configuration depends.
     *
     * @type {DependsOnOptionGroup[]}
     * @memberof Configuration
     */
    public dependsOn?: DependsOnOptionGroup[];

    /**
     * Creates an instance of Configuration.
     *
     * @param {string} name
     * @memberof Configuration
     */
    public constructor(name: string) {
        this.name = name;
    }
}