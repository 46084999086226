import { WindreamIdentity } from '../../common';
import { DirectoryRightsModel } from '../../rights';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents request options for updating directory rights
 *
 * @export
 * @class UpdatedirectoryRightsRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class UpdateDirectoryRightsRequestOptions extends WindreamRequestOptions {

    /**
     * The rights model.
     *
     * @type {DirectoryRightsModel}
     * @memberof UpdateDirectoryRightsRequestOptions
     */
    public rightsModel: DirectoryRightsModel;

    /**
     * Creates an instance of UpdateDirectoryRightsRequestOptions.
     * @param {WindreamIdentity} identity
     * @param {DirectoryRightsModel} rightsModel
     * @memberof UpdateDirectoryRightsRequestOptions
     */
    public constructor(identity: WindreamIdentity, rightsModel: DirectoryRightsModel) {
        super(identity);
        if (!rightsModel) {
            throw new Error('Argument \'rightsModel\' is not valid');
        }
        this.rightsModel = rightsModel;
    }
}