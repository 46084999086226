import { Utils } from '../../common';
import { IConfigMapping, IMappingHandler } from '../interfaces';

type SubstitutionMappingHandlerTypes = string | number | boolean;
/**
 * Mapping handler to perform substitution of primitive data types.
 * Simply replaces current value with new value.
 *
 * @export
 * @class SubstitutionMappingHandler
 * @implements {(IMappingHandler<SubstitutionMappingHandlerTypes, SubstitutionMappingHandlerTypes>)}
 */
export class SubstitutionMappingHandler implements IMappingHandler<SubstitutionMappingHandlerTypes, SubstitutionMappingHandlerTypes> {
    /**
     * Data types of configurations this handler can map.
     *
     * @memberof SubstitutionMappingHandler
     */
    private readonly dataTypes = ['DATATYPE_NUMBER', 'DATATYPE_INTEGER', 'DATATYPE_BOOLEAN', 'DATATYPE_STRING', 'DATATYPE_WINDREAM_LIST'];

    /**
     * Checks whether the mapping for the given configuration data type can be handled.
     *
     * @param {string} configDataType Data type of the configuration to map.
     * @param {string} mappingDataType Data type of the mapping to use.
     * @returns {boolean} Whether the configuration can be mapped by this handler.
     * @memberof SubstitutionMappingHandler
     */
    public canHandleMapping(configDataType: string, mappingDataType: string): boolean {
        return this.dataTypes.indexOf(configDataType) !== -1 && this.dataTypes.indexOf(mappingDataType) !== -1
            && configDataType === mappingDataType;
    }

    /**
     * Applies the mapping to the given input.
     *
     * @param {SubstitutionMappingHandlerTypes} input Configuration value to map.
     * @param {IConfigMapping<SubstitutionMappingHandlerTypes>} mapping Mapping informaton to use.
     * @returns {SubstitutionMappingHandlerTypes} The transformed value.
     * @memberof SubstitutionMappingHandler
     */
    public applyMapping(input: SubstitutionMappingHandlerTypes, mapping: IConfigMapping<SubstitutionMappingHandlerTypes>): SubstitutionMappingHandlerTypes {
        if (Utils.Instance.isDefined(mapping.newValue) && Utils.Instance.isDeepEqual(input, mapping.currentValue)) {
            return mapping.newValue;
        }
        return input;
    }
}