import { SubViewType } from '../../../typings/core';
import { ComponentContainer } from '../components';
import { ComponentInstanceConfig } from '../config';
import { IExtensionProvider } from '../extensions';
import { IUiManager } from '../loader';
import { Logger } from '../logging';
import { IPubSubHandler } from '../pubSub';
import { ISkeletonHelper } from '../ui';
import { IViewLifecycleManager } from './interfaces';
import { Changes, LIFECYCLE_HOOKS } from '.';

/**
 * The ViewLifecycleManager class, will manage every life cycle step and will invoke each registered component hook.
 * 
 * @export
 * @class ViewLifecycleManager
 * @implements {IViewLifecycleManager}
 */
export class ViewLifecycleManager implements IViewLifecycleManager {

    private componentContainers: ComponentContainer[];
    private lifecycleRunning: boolean;
    private currentLifecycleStep: number;
    private skeletonHelper: ISkeletonHelper;
    private logger: Logger;

    /**
     * Initializes classes that are required to perform the initial loading of configuration.
     * These instances are only created once and perstent between view changes.
     * 
     * @param {ISkeletonHelper} skeletonHelper ISkeletonHelper instance to use.
     * @param {Logger} logger Logger instance to use.
     * @memberof ViewLifecycleManager
     */
    public constructor(skeletonHelper: ISkeletonHelper, logger: Logger) {
        this.componentContainers = new Array<ComponentContainer>();
        this.currentLifecycleStep = 0;
        this.skeletonHelper = skeletonHelper;
        this.logger = logger;
        this.lifecycleRunning = false;
    }

    /**
     * Adds given component container to the list of active containers.
     * 
     * @param {ComponentContainer[]} componentContainer 
     * 
     * @memberof ViewLifecycleManager
     */
    public addComponents(componentContainers: ComponentContainer[]): void {
        this.componentContainers = this.componentContainers.concat(componentContainers);
    }

    /**
     * Promotes all components into the current Lifecycle step.
     * 
     * @param {string[]} activeGuids The active guids residing within the current subview. 
     * @param {IPubSubHandler} [pubSubHandler]  PubSub Handler to use for bind step.
     * @param {IUiManager} [uiManager]  Ui Manager to use for render step.
     * @param {IExtensionProvider} [extensionProvider] Provider Provider to use for extension step.
     * @returns {Promise<void>} Promise to resolve after all components have been triggered.
     * 
     * @memberof ViewLifecycleManager
     */
    public async promoteAll(activeGuids: string[], pubSubHandler?: IPubSubHandler, uiManager?: IUiManager, extensionProvider?: IExtensionProvider): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.resolve().then<void>(async () => {
                // Init
                return this.initAllComponents().catch(async (err: Error) => {
                    this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute init for some components', err);
                    // Continue anyways
                    return Promise.resolve();
                });
            }).then<void | boolean[]>(async () => {
                // Skeleton
                return this.skeletonAllComponents(false).catch(async (err: Error) => {
                    this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute skeleton for some components', err);
                    // Continue anyways
                    return Promise.resolve();
                });
            }).then<void | boolean[]>(async () => {
                // Bind
                if (pubSubHandler) {
                    return this.bindAllComponents(pubSubHandler).catch(async (err: Error) => {
                        this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute bind for some components', err);
                        // Continue anyways
                        return Promise.resolve();
                    });
                }
                return Promise.resolve();
            }).then<void | boolean[]>(async () => {
                // After bind
                return this.afterBindAllComponents().catch(async (err: Error) => {
                    this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute afterBind for some components', err);
                    // Continue anyways
                    return Promise.resolve();
                });
            }).then<void | boolean[]>(async () => {
                // Extension
                if (extensionProvider) {
                    return this.extensionAllComponents(extensionProvider).catch(async (err: Error) => {
                        this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute extension for some components', err);
                        // Continue anyways
                        return Promise.resolve();
                    });
                }
                return Promise.resolve();
            }).then<void | boolean[]>(async () => {
                // Render
                if (uiManager) {
                    return this.renderAllComponents(uiManager).catch(async (err: Error) => {
                        this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute render for some components', err);
                        // Continue anyways
                        return Promise.resolve();
                    });
                }
                return Promise.resolve();
            }).then<void | boolean[]>(async () => {
                // Toolbar
                return this.toolbarAllComponents().catch(async (err: Error) => {
                    this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute toolbar for some components', err);
                    // Continue anyways
                    return Promise.resolve();
                });
            }).then<void | boolean[]>(async () => {
                // After render
                return this.afterRenderAllComponents(activeGuids).catch(async (err: Error) => {
                    this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to execute afterRender for some components', err);
                    // Continue anyways
                    return Promise.resolve();
                });
            })
                .then(() => resolve())
                .catch((err: Error) => {
                    this.logger.error('ViewLifecycleManager', 'promoteAll', 'Failed to promote all components', err);
                    reject(err);
                });
        });
    }

    /**
     * Destroys all components.
     *
     * @returns {Promise<void>} Promise to resolve after all components have been triggered.
     *
     * @memberof ViewLifecycleManager
     */
    public async destroyAll(): Promise<void> {
        return this.destroyAllComponents();
    }

    /**
     * Refreshes all components.
     *
     * @param {Changes} changes Changes that occured.
     * @returns {Promise<void>} Promise to resolve after all components have been triggered.
     *
     * @memberof ViewLifecycleManager
     */
    public async refreshAll(changes: Changes): Promise<void> {
        return this.refreshAllComponents(changes);
    }

    /**
     * Inform components that the subview has changed.
     *
     * @param {string[]} activeGuids The active guids residing within that subview.
     * @param {SubViewType} subViewType The sub view type.
     * @returns {Promise<void>} A promise, which will be called if the lifecycle finished.
     * @memberof ViewLifecycleManager
     */
    public async subViewChanged(activeGuids: string[], subViewType: SubViewType): Promise<void> {
        return this.subViewChangedAllComponents(activeGuids, subViewType);
    }

    /**
     * Inform components that the subview will change.
     *
     * @param {string[]} activeGuids The active guids residing within that subview.
     * @param {SubViewType} subViewType The sub view type.
     * @returns {Promise<void>} A promise, which will be called if the lifecycle finished.
     * @memberof ViewLifecycleManager
     */
    public async subViewChanging(activeGuids: string[], subViewType: SubViewType): Promise<void> {
        return this.subViewChangingAllComponents(activeGuids, subViewType);
    }

    /**
     * Promites components until skeleton step.
     *
     * @param {boolean} isInConfigMode Whether component is in config mode.
     * @returns {Promise<void>} Promise to resolve after all components have been triggered.
     *
     * @memberof ViewLifecycleManager
     */
    public async showSkeletons(isInConfigMode: boolean): Promise<void> {
        return this.initAllComponents().then(async () => {
            return this.skeletonAllComponents(isInConfigMode);
        }, (err: Error) => {
            this.logger.error('ViewLifecycleManager', 'showSkeletons', 'Failed to init all components', err);
        });
    }

    /**
     * Triggers destroy step for the Component Instance with the given GUID.
     * 
     * @param {string} guid GUID of the Component Instance to destroy.
     * 
     * @memberof ViewLifecycleManager
     */
    public destroyComponentByGuid(guid: string): void {
        this.findAndDestroyComponentByGuid(guid);
    }

    /**
     * Remove the component from the lifecycle.
     *
     * @param {string} guid The GUID of the component which shall be removed.
     * @memberof ViewLifecycleManager
     */
    public removeComponentByGuid(guid: string): void {
        this.findAndDestroyComponentByGuid(guid, true);
    }

    /**
     * Updates Component Instance Config for the Component Instance with the given GUID.
     * Will also promote the component.
     * Should only be used for components within the view.
     * 
     * @param {string} guid GUID of the Component Instance to init.
     * @param {ComponentInstanceConfig} componentInstanceConfig Configuration to use during init.
     * @param {boolean} isActiveOnSubView Whether the component is active on the current subview or not. 
     * @param {boolean} [promote=true] Whether to promote the component.
     * @param {IPubSubHandler} [pubSubHandler]  PubSub Handler to use for bind step.
     * @param {IUiManager} [uiManager]  Ui Manager to use for render step.
     * @param {IExtensionProvider} [extensionProvider] Provider Provider to use for extension step.
     * 
     * @memberof ViewLifecycleManager
     */
    public updateComponentInstanceConfigByGuid(guid: string, componentInstanceConfig: ComponentInstanceConfig, isActiveOnSubView: boolean, promote: boolean = true,
        pubSubHandler?: IPubSubHandler, uiManager?: IUiManager, extensionProvider?: IExtensionProvider): void {
        const componentContainerWithGuid = this.componentContainers.filter((componentContainer: ComponentContainer) => {
            return componentContainer.component.guid === guid;
        });
        if (componentContainerWithGuid.length === 1) {
            componentContainerWithGuid[0].updateConfig(componentInstanceConfig);
            if (promote) {
                this.promote(componentContainerWithGuid[0], isActiveOnSubView, pubSubHandler, uiManager, extensionProvider);
            }
        }
    }

    /**
     * This method returns whether the lifecycle is running or not.
     * @memberof ViewLifecycleManager
     */
    public isRunning(): boolean {
        return this.lifecycleRunning;
    }

    /**
     * Find a component by it's GUID and call the destroy step.
     *
     * @private
     * @param {string} guid The guid to call the destroy step on.
     * @param {boolean} [deleteFromLifecycle] Whether the component shall be removed from the Lifecycle as well.
     * @memberof ViewLifecycleManager
     */
    private findAndDestroyComponentByGuid(guid: string, deleteFromLifecycle?: boolean): void {
        const componentContainerWithGuid = this.componentContainers.find((componentContainer: ComponentContainer) => {
            return componentContainer.component.guid === guid;
        });
        if (componentContainerWithGuid) {
            componentContainerWithGuid.componentLifecycleManager.destroy();
            if (deleteFromLifecycle) {
                this.componentContainers.splice(this.componentContainers.indexOf(componentContainerWithGuid), 1);
            }
        }
    }

    /**
     * Inform components that the subview has changed.
     *
     * @param {string[]} activeGuids The active guids residing within that subview.
     * @param {SubViewType} subViewType The sub view type.
     * @returns {Promise<void>} A promise, which will be called if the lifecycle finished.
     * @memberof ViewLifecycleManager
     */
    private subViewChangedAllComponents(activeGuids: string[], subViewType: SubViewType): Promise<void> {
        return new Promise<void>((resolve) => {
            this.componentContainers.forEach((container) => container.componentLifecycleManager.subViewChanged(!!activeGuids.find((guid) => guid === container.component.guid), subViewType));
            resolve();
        });
    }

    /**
     * Inform components that the subview will change.
     *
     * @param {string[]} activeGuids The active guids residing within that subview.
     * @param {SubViewType} subViewType The sub view type.
     * @returns {Promise<void>} A promise, which will be called if the lifecycle finished.
     * @memberof ViewLifecycleManager
     */
    private subViewChangingAllComponents(activeGuids: string[], subViewType: SubViewType): Promise<void> {
        return new Promise<void>((resolve) => {
            this.componentContainers.forEach((container) => container.componentLifecycleManager.subViewChanging(!!activeGuids.find((guid) => guid === container.component.guid), subViewType));
            resolve();
        });
    }

    /**
     * Promotes a given Component into the current Lifecycle step.
     * 
     * @param {ComponentContainer} componentContainer   Component Container with component to promote.
     * @param {boolean} isActiveOnSubView Whether the component is active on the current subview or not. 
     * @param {IPubSubHandler} [pubSubHandler]  PubSub Handler to use for bind step.
     * @param {IUiManager} [uiManager]  Ui Manager to use for render step.
     * @param {IExtensionProvider} [extensionProvider] Provider Provider to use for extension step.
     * @private
     *
     * @memberof ViewLifecycleManager
     */
    private promote(componentContainer: ComponentContainer, isActiveOnSubView: boolean, pubSubHandler?: IPubSubHandler, uiManager?: IUiManager, extensionProvider?: IExtensionProvider): void {

        Promise.resolve().then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnInit) {
                componentContainer.componentLifecycleManager.init(componentContainer.componentInstanceConfig);
            }
        }).then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnSkeleton) {
                componentContainer.componentLifecycleManager.skeleton(this.skeletonHelper, false);
            }
        }).then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnBind && pubSubHandler) {
                componentContainer.componentLifecycleManager.bind(pubSubHandler);
            }
        }).then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnAfterBind && pubSubHandler) {
                componentContainer.componentLifecycleManager.afterBind();
            }
        }).then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnExtension && extensionProvider) {
                componentContainer.componentLifecycleManager.extension(extensionProvider);
            }
        }).then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnRender && uiManager) {
                componentContainer.componentLifecycleManager.render(uiManager);
            }
        }).then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnToolbar) {
                componentContainer.componentLifecycleManager.toolbar();
            }
        }).then(() => {
            if (this.currentLifecycleStep > LIFECYCLE_HOOKS.OnAfterRender) {
                componentContainer.componentLifecycleManager.afterRender(isActiveOnSubView);
            }
        }).catch((err: Error) => {
            this.logger.error('ViewLifecycleManager', 'promote', 'Failed to init all components', err);
        });
    }


    /**
     * Triggers init step for all components. 
     * 
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     *
     * @private
     * @memberof ViewLifecycleManager
     */
    private async initAllComponents(): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.init(componentContainer.componentInstanceConfig));
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }

    /**
     * Triggers bind step for all components. 
     * 
     * @param {IPubSubHandler} pubSubHandler    PubSubHandler to pass to components.
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * 
     * @private
     * @memberof ViewLifecycleManager
     */
    private async bindAllComponents(pubSubHandler: IPubSubHandler): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.bind(pubSubHandler));
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }

    /**
     * Triggers skeleton step for all components. 
     *
     * @param {boolean} isInConfigMode Whether the skeleton is displayed in config mode or as a default skeleton.
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * @private
     * @memberof ViewLifecycleManager
     */
    private async skeletonAllComponents(isInConfigMode: boolean): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.skeleton(this.skeletonHelper, isInConfigMode));
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }

    /**
     * Triggers afterBind step for all components. 
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * 
     * @private
     * @memberof ViewLifecycleManager
     */
    private async afterBindAllComponents(): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.afterBind());
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }

    /**
     * Triggers render step for all components. 
     * 
     * @param {IUiManager} uiManager    UI Manager to pass to components.
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * 
     * @private
     * @memberof ViewLifecycleManager
     */
    private async renderAllComponents(uiManager: IUiManager): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.render(uiManager));
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }


    /**
     * Triggers toolbar step for all components. 
     * 
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * 
     * @private
     * @memberof ViewLifecycleManager
     */
    private async toolbarAllComponents(): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.toolbar());
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }

    /**
     * Triggers extension step for all components. 
     * 
     * @param {IExtensionProvider} extensionProvider   ExtensionProvider to pass to components.
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * 
     * @private
     * @memberof ViewLifecycleManager
     */
    private async extensionAllComponents(extensionProvider: IExtensionProvider): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.extension(extensionProvider));
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }
    /**
     * Triggers wdOnAfterRender step for all components. 
     * 
     * @param {string[]} activeGuids The active guids residing within the current subview. 
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * 
     * @private
     * @memberof ViewLifecycleManager
     */
    private async afterRenderAllComponents(activeGuids: string[]): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.afterRender(!!activeGuids.find((guid) => guid === componentContainer.component.guid)));
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }

    /**
     * Triggers wdOnRefresh step for all components.
     * 
     * @param {Changes} changes Changes that occured.
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     *
     * @private
     * @memberof ViewLifecycleManager
     */
    private async refreshAllComponents(changes: Changes): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.refresh(changes));
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }

    /**
     * Triggers destroy step for all components. 
     * @returns {Promise<void>} Promise to resolve after all components have passed the step.
     * 
     * @private
     * @memberof ViewLifecycleManager
     */
    private async destroyAllComponents(): Promise<void> {
        return Promise.all(this.componentContainers.map(async (componentContainer: ComponentContainer) => {
            return Promise.resolve(componentContainer.componentLifecycleManager.destroy());
        })
        ).then(() => {
            this.currentLifecycleStep++;
        });
    }
}
