import { DynamicWorkspaceEntity } from 'typings/core';
import { IdLocationNameEntityIdentityDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { FetchNodesDTO, FullRootlineFetchNodesResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Navigation';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { FetchNodesContainer } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { IdentityMapper } from '../../dynamicWorkspace';
import { Logger } from '../../logging';
import { FetchNodesRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform a FullRootLineFetchNodes call to the windream webservice.
 * 
 * @export
 * @class FullRootlineFetchNodes
 * @extends {ServiceAction}
 */
export class FullRootlineFetchNodes extends ServiceAction {

    /**
     * Creates an instance of FullRootlineFetchNodes.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @memberof FullRootlineFetchNodes
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'fullRootlineFetchNodes';
    }

    /**
     * Fetches folder information including the full rootline of the requested item.
     * Sorty ascending by file name on server side.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {FetchNodesRequestOptions} fetchNodesRequestOptions The request options.
     * @returns {Promise<FetchNodesContainer>} A promise, which will resolve with the server response.
     * @memberof FullRootlineFetchNodes
     */
    public async do(fetchNodesRequestOptions: FetchNodesRequestOptions): Promise<FetchNodesContainer> {
        return new Promise<FetchNodesContainer>((resolve, reject) => {
            const item = IdentityMapper.getWebServiceIdentity(fetchNodesRequestOptions.identity) as IdLocationNameEntityIdentityDTO;
            item.Entity = fetchNodesRequestOptions.identity.entity as number;

            if (fetchNodesRequestOptions.identity.entity === <number>DynamicWorkspaceEntity.FavoritesContainer) {
                // Fake service response for internal dw entity favorites to avoid errors
                resolve(FetchNodesContainer.fromDto(<FullRootlineFetchNodesResponseContainerDTO>{ Item: item }));
            } else {
                this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL +
                    '/navigation/FullRootlineFetchNodes', {
                        Item: item,
                        Sorting: fetchNodesRequestOptions.sorting ? {
                            AttributeName: fetchNodesRequestOptions.sorting.attributeName,
                            Direction: fetchNodesRequestOptions.sorting.direction
                        } : undefined,
                        Values: fetchNodesRequestOptions.values,
                        Entity: fetchNodesRequestOptions.entity
                    } as FetchNodesDTO), fetchNodesRequestOptions.requestOptions)
                    .then((response: IServiceResponse<FullRootlineFetchNodesResponseContainerDTO>) => {
                        if (!response.data || response.data?.HasErrors) {
                            this.logger.error('FullRootlineFetchNodes', 'do', 'Failed to fetch full rootline', response);
                            reject(new Error('Failed to fetch full rootline'));
                        } else {
                            resolve(FetchNodesContainer.fromDto(response.data));
                        }
                    }).catch((err: Error) => {
                        this.logger.error('FullRootlineFetchNodes', 'do', 'Failed to execute request', err);
                        reject(err);
                    });
            }
        });
    }
}