import { WindreamEntity, WindreamIdentity } from '../../../../typings/core';


/**
 * Represents preview popup options.
 *
 * @export
 * @class WindreamPreviewPopupOptions
 */
export class WindreamPreviewPopupOptions {

    /**
     * The windream file.
     *
     * @type {WindreamIdentity}
     * @memberof WindreamPreviewPopupOptions
     */
    public windreamFile: WindreamIdentity;

    /**
     * The title of the popup.
     *
     * @type {string}
     * @memberof WindreamPreviewPopupOptions
     */
    public title?: string;

    /**
     * Creates an instance of WindreamPreviewPopupOptions.
     * 
     * @param {WindreamIdentity} windreamFile The windream file.
     * @memberof WindreamPreviewPopupOptions
     */
    public constructor(windreamFile: WindreamIdentity) {
        if (!windreamFile) {
            throw new Error('Argument windreamFile is undefined.');
        }
        if (windreamFile.entity !== WindreamEntity.Document) {
            throw new Error('Argument windreamFile is not a file.');
        }
        this.windreamFile = windreamFile;
    }
}