import { ToolbarAction } from '../../../../typings/core';

/**
 * This container will hold the toolbar action instance and the target html element.
 *
 * @export
 * @class ToolbarActionContainer
 */
export class ToolbarActionContainer {

    /**
     * The instance.
     *
     * @type {ToolbarAction}
     * @memberof ToolbarActionContainer
     */
    public instance: ToolbarAction;

    /**
     * The target element.
     *
     * @type {HTMLElement}
     * @memberof ToolbarActionContainer
     */
    public targetElement: HTMLElement;

    /**
     * Creates an instance of ToolbarActionContainer.
     * 
     * @param {ToolbarAction} instance The instance.
     * @param {HTMLElement} targetElement The target element.
     * @memberof ToolbarActionContainer
     */
    public constructor(instance: ToolbarAction, targetElement: HTMLElement) {
        this.instance = instance;
        this.targetElement = targetElement;
    }
}