import { DWCore } from '@windream/dw-core/dwCore';
import { SubViewConfig } from '../../loader';
import { PubSubConfig } from '../../pubSub';
import { ISharedSettings, ITrigger } from '../interfaces';
import { ViewConfigMetaData } from './viewConfigMetaData';
import { ComponentInstanceConfig } from '.';

/**
 * This class is a data holder for each view config.
 * Extends the meta data with content specfic information.
 * 
 * @export
 * @class ViewConfig
 * @extends {ViewConfigMetaData}
 */
export class ViewConfig extends ViewConfigMetaData implements DWCore.Views.ViewConfig {

    /**
     * The device this configuration is for.
     *
     * @type {DWCore.Common.Devices}
     * @memberof ViewConfig
     */
    public device: DWCore.Common.Devices;

    /**
     * The components of the ViewConfig.
     * 
     * @type {ComponentInstanceConfig[]}
     * @memberof ViewConfig
     */
    public components: ComponentInstanceConfig[];

    /**
     * The pubsub config of the ViewConfig.
     * 
     * @type {PubSubConfig[]}
     * @memberof ViewConfig
     */
    public pubSub: PubSubConfig[];

    /**
     * The subviews of the ViewConfig.
     * 
     * @type {SubViewConfig[]}
     * @memberof ViewConfig
     */
    public subViews: SubViewConfig[];

    /**
     * The triggers of the ViewConfig.
     * 
     * @type {ITrigger}
     * @memberof ViewConfig
     */
    public triggers: ITrigger;

    /**
     * The view based shared settings.
     *
     * @type {ISharedSettings}
     * @memberof ViewConfig
     */
    public settings: ISharedSettings;

    /**
     * The contextMenu name.
     *
     * @type {string}
     * @memberof ViewConfig
     */
    public contextMenu?: string;

    /**
     * Creates an instance of ViewConfig.
     *
     * @param {string} id The view id.
     * @memberof ViewConfig
     */
    public constructor(id: string) {
        super(id);
        this.device = DWCore.Common.Devices.DESKTOP;
        this.components = new Array<ComponentInstanceConfig>();
        this.pubSub = new Array<PubSubConfig>();
        this.subViews = new Array<SubViewConfig>();
        this.triggers = {};
        this.settings = {};
    }
}