import { Logger } from '../logging';
import { RoutingUtils } from './routingUtils';
import { IRouter } from '.';


/**
 * The edit router will route calls into the edit mode.
 *
 * @export
 * @class EditRouter
 * @implements {IRouter}
 */
export class EditRouter implements IRouter {
    private routeRegExp: RegExp;
    private catchRegExp: RegExp;
    private currentWindow: Window;
    private onEditChangeCallbacks: ((view: string) => void)[];
    private logger: Logger;

    /**
     * Creates an instance of EditRouter.
     * 
     * @param {Logger} logger The logger.
     * @param {Window} currentWindow The window.
     * @memberof EditRouter
     */
    public constructor(logger: Logger, currentWindow: Window) {
        this.routeRegExp = new RegExp('\/edit\/.*', 'i');
        this.catchRegExp = new RegExp('\/edit\/(.*)', 'i');
        this.currentWindow = currentWindow;
        this.onEditChangeCallbacks = new Array<(view: string) => void>();
        this.logger = logger;
    }

    /**
     * Routes the route.
     *
     * @param {string} path The path to route.
     * @returns {boolean} Whether it can route at the moment.
     * @memberof EditRouter
     */
    public route(path: string): boolean {
        const catchingGroups = this.catchRegExp.exec(path);
        if (catchingGroups) {
            this.onEditChangeCallbacks.forEach((callback) => {
                callback(catchingGroups[1]);
            });
            this.clickOnViewChange();
            return true;
        } else {
            this.logger.error('EditRouter', 'route', 'Call was not routed because the catching group is somehow empty');
            return false;
        }
    }

    /**
     * Gets the route pattern.
     *
     * @returns {RegExp} The route pattern.
     * @memberof EditRouter
     */
    public getRoutePattern(): RegExp {
        return this.routeRegExp;
    }

    /**
     * Register a callback for edit change.
     *
     * @param {(view: string) => void} callback The callback.
     * @memberof EditRouter
     */
    public registerOnEditChange(callback: (view: string) => void): void {
        this.onEditChangeCallbacks.push(callback);
    }

    /**
     * Registers a click event to close things like dropdown menus (etc).
     * 
     * @memberof EditRouter
     */
    private clickOnViewChange(): void {
        if (document) {
            // Click event
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
            });
            document.body.dispatchEvent(clickEvent);
        }
    }

    /**
     * Gets the current view from the path or null if not present.
     *
     * @returns {(string | null)} The view as string or null if not found.
     * @memberof EditRouter
     */
    public getCurrentViewFromPath(): string | null {
        const catchingGroups = this.catchRegExp.exec(this.currentWindow.location.pathname);
        if (catchingGroups && catchingGroups.length > 1) {
            return catchingGroups[1];
        }
        return null;
    }

    /**
     * Generates the URL for a specific view edit.
     *
     * @static
     * @param {string} viewId The id of the view which will be edited.
     * @returns {string} The url.
     * @memberof EditRouter
     */
    public static generateEditUrlForViewId(viewId: string): string {
        return RoutingUtils.getApplicationBasePath() + 'edit/' + viewId;
    }
}