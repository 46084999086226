import { IdentityChangedType } from '../../../../typings/events';
import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { DocumentResponseContainerDTO, MoveDocumentDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler';
import { WindreamIdentity } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { IdentityChangedHandler } from '../../messages/events';
import { IdentityMapper } from '../identityMapper';
import { MoveDocumentBulkRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Moves documents within windream.
 *
 * @export
 * @class MoveDocumentBulk
 * @extends {ServiceAction}
 */
export class MoveDocumentBulk extends ServiceAction {

    private identityChangedHandler: IdentityChangedHandler;
    /**
     * Creates an instance of MoveDocumentBulk.
     * @param {IRequestExecutor} requestExecutor
     * @param {GlobalConfig} globalConfig
     * @param {Logger} logger
     * @param {IdentityChangedHandler} identityChangedHandler The identity changed handler.
     * @memberof MoveDocumentBulk
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger,
        identityChangedHandler: IdentityChangedHandler) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'moveDocumentBulk';
        this.identityChangedHandler = identityChangedHandler;
    }

    /**
     * Move documents within windream to its destination.
     *
     * @param {MoveDocumentBulkRequestOptions} moveDocumentBulkRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolve with the server response.
     * @memberof MoveDocumentBulk
     */
    public async do(moveDocumentBulkRequestOptions: MoveDocumentBulkRequestOptions): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const tempSourceDocumentDTOs = new Array<MoveDocumentDTO>();
            moveDocumentBulkRequestOptions.sourceIdentities.forEach((identity: WindreamIdentity) => {
                const tempIdentityDTO = {
                    Flags: moveDocumentBulkRequestOptions.flags as number,
                    Item: IdentityMapper.getWebServiceIdentity(identity),
                    ResponseDetails: moveDocumentBulkRequestOptions.responseDetails ? moveDocumentBulkRequestOptions.responseDetails as number : 0,
                    Target: IdentityMapper.getWebServiceIdentity(moveDocumentBulkRequestOptions.targetDirectory)
                } as MoveDocumentDTO;
                tempIdentityDTO.Target.Entity = <number>moveDocumentBulkRequestOptions.targetEntity;
                tempSourceDocumentDTOs.push(tempIdentityDTO);
            });
            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/documents/MoveBulk',
                tempSourceDocumentDTOs
            ), moveDocumentBulkRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO[]>) => {
                const responseErrorData = response.data?.filter((element: DocumentResponseContainerDTO) => {
                    if (!element.HasErrors) {
                        const identityDetails = this.modelFactory.createWindreamIdentityDetailsFromDto(element.Item);
                        const previousIdentity = moveDocumentBulkRequestOptions.sourceIdentities.find((identity) => identity.id === identityDetails.id);
                        if (previousIdentity) {
                            this.identityChangedHandler.distributeEvent({
                                type: IdentityChangedType.Move,
                                previous: previousIdentity,
                                current: identityDetails
                            });
                        }
                    }
                    return element.HasErrors;
                });

                if (responseErrorData && responseErrorData.length > 0) {
                    // TODO: Use responseErrorData for specific reporting.
                    this.logger.error('MoveDocumentBulk', 'do', 'unable to move documents', responseErrorData);
                    reject(new Error('Unable to move documents'));
                } else {
                    resolve();
                }
            }).catch((err: Error) => {
                this.logger.error('MoveDocumentBulk', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}