import { ToolbarActionLoader } from '../toolbar';
import { IExtension } from '.';

/**
 * Provides certain toolbar extensions.
 *
 * @export
 * @class ToolbarExtension
 * @implements {IExtension}
 */
export class ToolbarExtension implements IExtension {

    /**
     * The name of this extension.
     *
     * @memberof ToolbarExtension
     */
    public name = 'toolbar';

    private toolbarActionLoader: ToolbarActionLoader;

    /**
     * Creates an instance of ToolbarExtension.
     * 
     * @param {ToolbarActionLoader} toolbarActionLoader The toolbar action loader.
     * @memberof ToolbarExtension
     */
    public constructor(toolbarActionLoader: ToolbarActionLoader) {
        this.toolbarActionLoader = toolbarActionLoader;
    }

    /**
     * Gets the toolbar action loader.
     *
     * @returns {ToolbarActionLoader} The toolbar action loader.
     * @memberof ToolbarExtension
     */
    public getToolbarActionLoader(): ToolbarActionLoader {
        return this.toolbarActionLoader;
    }
}