import { SearchDTO } from 'framework/typings/windreamWebService/Windream.WebService.Search';
import { SearchQuery } from '../../interface/search';
import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents search request options.
 *
 * @export
 * @class SearchRequestOptions
 * @extends {BaseRequestOptions}
 */
export class SearchRequestOptions extends BaseRequestOptions {

    /**
     * The search query.
     *
     * @type {SearchQuery}
     * @memberof SearchRequestOptions
     */
    public searchQuery?: SearchQuery;

    /**
     * The windream web service search DTO.
     *
     * @type {SearchDTO}
     * @memberof SearchRequestOptions
     */
    public searchDTO?: SearchDTO;

}