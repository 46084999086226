import { Utils, DeviceDetection } from '../common';
import { MigrationComponentContainer, Position } from '../dynamicWorkspace';

/**
 * Abstract class to handle navigations.
 * Will take care of hiding elements that do not fit into the view.
 * 
 * @export
 * @class MoreDropdownHandler
 */
export class MoreDropdownHandler {
    private element: HTMLElement;
    private currentDocument: Document;
    private componentPosition: Position;
    private prefix: string;
    private dropdownPane?: HTMLElement;
    private elementsInsideDropdown: HTMLElement[];
    private allItems: HTMLElement[];
    private moreContainer?: HTMLElement;
    private resizeListener?: any;

    private isInitialized: boolean;

    /**
     * Creates an instance of MoreDropdownHandler.
     *
     * @param {HTMLElement} componentContainer Element to render into.
     * @param {Document} currentDocument The current Document instance.
     * @param {string | Position | null | undefined} componentPosition Position of parent component.
     * @memberof MoreDropdownHandler
     */
    public constructor(componentContainer: MigrationComponentContainer, currentDocument: Document) {
        this.element = componentContainer.toolbarElement as HTMLElement;
        this.currentDocument = currentDocument;
        this.componentPosition = componentContainer.componentInstanceConfig.position as Position;
        this.prefix = Utils.Instance.getRandomString();
        this.isInitialized = false;

        this.elementsInsideDropdown = new Array<HTMLElement>();
        this.allItems = new Array<HTMLElement>();
    }

    /**
     * Attaches the observer and resize listener to hide elements inside the dropdown.
     *
     * @private
     * @memberof MoreDropdownHandler
     */
    public init(): void {
        this.allItems = Array.from(this.element.querySelectorAll('.wd-toolbar-action'));
        this.addDropdownHtml(this.element);

        if (window) {
            const resizeDebounceDelay = 50;
            let timeout: any;
            window.addEventListener('resize', this.resizeListener = () => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    this.adjustWidth();
                }, resizeDebounceDelay);
            });
        }
        this.adjustWidth();
    }

    /**
     * Destroys the more dropdown and removes event listeners.
     *
     * @memberof MoreDropdownHandler
     */
    public destroy(): void {
        if (this.resizeListener) {
            window.removeEventListener('resize', this.resizeListener);
        }
        this.closeMoreDropdown();
        this.moreContainer?.remove();
        this.dropdownPane?.remove();
    }


    /**
     * Close the more dropdown pane.
     * 
     * @memberof MoreDropdownHandler
     */
    public closeMoreDropdown(): void {
        if (this.dropdownPane) {
            // @ts-ignore - Disabled because of Foundation usage
            $(this.dropdownPane).foundation('close');
        }
    }

    /**
     * Adjusts the width of the navigation by hiding items inside a dropdown.
     *  
     * @memberof MoreDropdownHandler
     */
    public adjustWidth(): void {
        // Move all items out of the dropdown
        // Reverse because last element is pushed first
        this.elementsInsideDropdown.reverse().forEach((element) => {
            this.element.insertBefore(element, this.moreContainer ?? null);
        });
        this.elementsInsideDropdown.length = 0;
        let needsAdjustment = this.needsAdjustment();
        let breaker = 0; // Helper variable to prevent endless loops
        let elementHidden = false; // Used to determine if at least one item was hidden
        while (needsAdjustment && breaker++ < this.allItems.length) {
            const visibleElements = this.allItems.filter((element) => this.elementsInsideDropdown.indexOf(element) === -1);
            if (visibleElements && visibleElements.length >= 1) {
                const lastVisibleElement = visibleElements[visibleElements.length - 1];
                if (lastVisibleElement) {
                    // Move element to dropdown
                    this.dropdownPane?.prepend(lastVisibleElement);
                    this.elementsInsideDropdown.push(lastVisibleElement);
                }
                elementHidden = true;
            }
            needsAdjustment = this.needsAdjustment();
        }
        // Hide more-button if no element was hidden
        if (!elementHidden) {
            if (this.moreContainer) {
                this.moreContainer.classList.add('hidden');
            }
        } else {
            if (this.moreContainer) {
                this.moreContainer.classList.remove('hidden');
            }
        }
    }

    /**
     * Checks whether the navigation needs adjustment in terms of hiding single elements or not.
     * 
     * @private
     * @returns {boolean}  Whether the element needs adjustment.
     * 
     * @memberof MoreDropdownHandler
     */
    private needsAdjustment(): boolean {
        if (this.allItems.length === 0) {
            return false;
        }
        const allItemsWidth = this.allItems.map((element) => element.offsetWidth).reduce((a, b) => a + b);
        const moreContainerWidth = this.moreContainer ? this.moreContainer.offsetWidth : 0;
        const buffer = 40; // Account for container padding, etc.
        return (allItemsWidth + moreContainerWidth) > (this.element.offsetWidth - buffer);
    }


    /**
     * Prepares the HTML.
     *
     * @private
     * @param {HTMLElement} element Element to render into.
     * @memberof MoreDropdownHandler
     */
    private addDropdownHtml(element: HTMLElement): void {
        this.moreContainer = this.currentDocument.createElement('div');
        this.moreContainer.classList.add('wd-more-container');
        const button = this.currentDocument.createElement('button');
        button.innerHTML = '<span aria-hidden="true" class="wd-icon more"></span>';
        button.classList.add('button', 'plain');
        button.setAttribute('data-toggle', `${this.prefix}-more-dropdown`);

        this.dropdownPane = this.currentDocument.createElement('div');
        this.dropdownPane.setAttribute('data-dropdown', '');
        this.dropdownPane.setAttribute('data-close-on-click', 'true');
        this.dropdownPane.setAttribute('data-parent-class', 'grid-stack-item');
        this.dropdownPane.setAttribute('data-allow-overlap', 'true');
        this.dropdownPane.setAttribute('data-position', 'bottom');
        this.dropdownPane.setAttribute('data-v-offset', '0');
        this.dropdownPane.setAttribute('data-h-offset', '0');
        this.dropdownPane.setAttribute('id', `${this.prefix}-more-dropdown`);
        this.adjustDropDownPaneAlignment(this.dropdownPane);

        this.dropdownPane.classList.add('dropdown-pane', 'wd-more-dropdown');
        this.moreContainer.appendChild(button);

        element.appendChild(this.moreContainer);

        if (!this.isInitialized) {
            this.isInitialized = true;
            // Always append the dropdown to the root body element.
            if (this.dropdownPane) {
                document.body.appendChild(this.dropdownPane);
            }
            // @ts-ignore - Disabled because of Foundation usage
            if (typeof window['$'] !== 'undefined') {
                // @ts-ignore - Disabled because of Foundation usage
                $(this.dropdownPane).foundation();
            }

            // Close the dropdown once an item is selected
            this.dropdownPane?.addEventListener('click', () => {
                this.closeMoreDropdown();
            }, true);
        }
    }

    /**
     * Adjust the dropdownPane alignment depending on the postion of the parent element and the type of the device.
     * 
     * @private
     * @param {HTMLElement} dropdownPane the dropdownPane.
     * @memberof MoreDropdownHandler
     */

    private adjustDropDownPaneAlignment(dropdownPane: HTMLElement): void {
        if (this.componentPosition.x !== undefined && this.componentPosition.width !== undefined) {
            if (DeviceDetection.isPhone()) {

                if (this.componentPosition.width <= 2) {
                    if (this.componentPosition.x === 0) {
                        dropdownPane.setAttribute('data-alignment', 'left');
                    } else if (this.componentPosition.x === 2 || this.componentPosition.x === 3) {
                        dropdownPane.setAttribute('data-alignment', 'right');
                    } else {
                        dropdownPane.setAttribute('data-alignment', 'center');
                    }
                } else {
                    dropdownPane.setAttribute('data-alignment', 'right');
                }

            } else if (DeviceDetection.isTablet()) {

                if (this.componentPosition.width <= 3 && this.componentPosition.x <= 2) {
                    dropdownPane.setAttribute('data-alignment', 'left');
                } else {
                    dropdownPane.setAttribute('data-alignment', 'right');
                }

            } else {

                if (this.componentPosition.width <= 2 && this.componentPosition.x <= 1) {
                    dropdownPane.setAttribute('data-alignment', 'left');
                } else {
                    dropdownPane.setAttribute('data-alignment', 'right');
                }

            }

        }
    }

}