import { DWCore } from '@windream/dw-core/dwCore';
import { ViewRightsResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { ViewRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Returns the rights for the current view.
 *
 * @export
 * @class GetViewRights
 * @extends {ServiceAction}
 */
export class GetViewRights extends ServiceAction {

    /**
     * Creates an instance of GetViewRights.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global config.
     * @param {Logger} logger The logger.
     * @memberof GetViewRights
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getViewRights';
    }

    /**
     * Retruns the rights of the current user for the given view.
     *
     * @param {ViewRequestOptions} viewRequestOptions The request options.
     * @returns {Promise<DWCore.Views.ViewRights>} A promise, which will resolve with the view rights.
     * @memberof GetViewRights
     */
    public async do(viewRequestOptions: ViewRequestOptions): Promise<DWCore.Views.ViewRights> {
        return new Promise<DWCore.Views.ViewRights>((resolve, reject) => {
            const resourcePointer = new HttpResourcePointer('get', this.globalConfig.windreamWebServiceURL +
                `/dynamicworkspace/views/GetViewRights?parameter.identifier.id=${viewRequestOptions.viewId}`);
            this.requestExecutor.executeRequest(resourcePointer, viewRequestOptions.requestOptions)
                .then((response: IServiceResponse<ViewRightsResponseContainerDTO>) => {
                    if (response.data && !response.data.HasErrors) {
                        resolve(response.data.Rights);
                    } else {
                        this.logger.error('GetViewRights', 'do', 'Failed to execute request', response);
                        reject(new Error('Failed to get view rights'));
                    }
                }).catch((err) => {
                    this.logger.error('GetViewRights', 'do', 'Failed to execute request', err);
                    reject(err);
                });
        });
    }
}