import { Culture, Logger } from '../../../typings';
import { RowData } from '../../../typings/core';
import { LanguageProvider } from '../../../typings/language';
import { DataCollectionData, MasterListOptions } from '../../../typings/ui';
import { Utils, WindreamIdentity } from '../common';
import { DataCollectionDisplay } from '../dataCollections';
import { IMasterListOptions } from './interfaces';

/**
 * Displays a DataCollection as a list using DevExtreme.
 * 
 * @export
 * @class MasterList
 * @extends {DataCollectionDisplay<T>}
 * @template T Type of the data to display.
 */
export class MasterList<T> extends DataCollectionDisplay<T> {

    private readonly DOUBLE_CLICK_TIMEOUT: number;
    private doubleClickLastTime?: Date;
    private doubleClickTimeout?: any;
    private alreadyAdded: boolean = false;
    private targetElement: HTMLElement;
    private jQuery: JQueryStatic;
    private options: IMasterListOptions;
    private defaultOptions: IMasterListOptions = {
        canShare: false
    };
    private listInstance?: DevExpress.ui.dxList;
    private selectedElements?: T[];

    /**
     * Creates an instance of MasterList.
     *
     * @param {HTMLElement} element The target element.
     * @param {LanguageProvider} languageProvider The language provider.
     * @param {JQueryStatic} jQuery JQueryStatic.
     * @param {Logger} logger The logger.
     * @param {Culture} cultureHelper The culture helper.
     * @param {IMasterListOptions} [options] The class options.
     * @memberof MasterList
     */
    public constructor(element: HTMLElement, languageProvider: LanguageProvider, jQuery: JQueryStatic, logger: Logger, cultureHelper: Culture, options?: IMasterListOptions) {
        super(languageProvider, logger, cultureHelper);
        this.targetElement = element;
        this.jQuery = jQuery;
        this.options = { ...this.defaultOptions, ...options };

        const doubleClickDelayDesktop = 200;
        const doubleClickDelayMobile = 500;
        this.DOUBLE_CLICK_TIMEOUT = Utils.Instance.deviceDetection.isMobile() ? doubleClickDelayMobile : doubleClickDelayDesktop;
    }


    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof MasterList
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.listInstance) {
            this.listInstance.option('disabled', isDisabled);
        }
    }

    /**
     * Nothing to repaint yet.
     *
     * @returns {void}
     * @memberof MasterList
     */
    public repaint(): void {
        return;
    }

    /**
     * Renders and possibly sorts the list.
     *
     * @param {(DataCollectionData<T> | null)} data The data.
     * @memberof MasterList
     */
    public render(data: DataCollectionData<T> | null) {
        if (data) {
            if (this.alreadyAdded) {
                this.alreadyAdded = false;
                this.disposeList();
            }
            this.currentData = data;
            const container = this.jQuery('<div>');
            this.jQuery(this.targetElement).append(container);
            this.listInstance = container.addClass('wd-master-list wd-dragtarget').dxList({
                dataSource: this.generateDataSource(data, this.options),
                height: '100%',
                useNativeScrolling: true,
                onSelectionChanged: (event) => {
                    this.selectionChangedHandling(event);
                },
                selectionMode: 'single',
                onItemRendered: (event) => {
                    if (event && event.itemElement && event.itemData && event.itemData.dataObject) {
                        event.itemElement[0].setAttribute('data-wd-row-identity-id', event.itemData.dataObject.id.toString());
                    }
                },
                itemTemplate: this.initItem.bind(this),
                noDataText: this.languageProvider.get('framework.dataCollection.noData')
            }).dxList('instance').on('itemClick', (e: any) => {
                const event = e.event as MouseEvent;
                event.preventDefault();
                event.stopPropagation();
                const rowData = e.itemData as RowData<T>;
                const now = new Date();
                clearTimeout(this.doubleClickTimeout);
                if (this.doubleClickLastTime && now.getTime() - this.doubleClickLastTime.getTime() < this.DOUBLE_CLICK_TIMEOUT) { // Time was below threshold, so it is a double click
                    if (rowData.doubleClick && rowData.dataObject) {
                        rowData.doubleClick(rowData.dataObject);
                    }
                } else { // Single click
                    this.doubleClickTimeout = setTimeout(() => {
                        if (rowData.onClick && rowData.dataObject) {
                            const convertedData = new Array(rowData.dataObject);
                            rowData.onClick(convertedData);
                        }
                    }, this.DOUBLE_CLICK_TIMEOUT);
                }
                this.doubleClickLastTime = now;
            }).on('itemContextMenu', (e: any) => {
                const event = e.event as MouseEvent;
                event.preventDefault();
                const rowData = e.itemData as RowData<T>;
                const data = rowData.dataObject ? [rowData.dataObject] : [];
                this.setupContextMenu(data);
                this.getContextMenuInstance(data).then((contextMenu) => {
                    if (this.contextMenuExtension && rowData.webSocketDataModel) {
                        this.contextMenuExtension.setFallbackMenu(contextMenu);
                        this.contextMenuExtension.openMenu(rowData.webSocketDataModel.getLocationComplete(), event);
                        // @ts-ignore - Ignore because of Foundation usage
                    } else if (window['Foundation'] && window['Foundation']['ContextMenu']) {
                        // @ts-ignore - Ignore because of Foundation usage
                        new window['Foundation']['ContextMenu'](window['$'](event.target), {
                            accessible: true,
                            position: event,
                            single: true,
                            structure: contextMenu.getItems(),
                            emptyEntryWillCloseMenu: false
                        });
                    }
                }).catch((err) => {
                    throw err;
                });
            });
            this.alreadyAdded = true;
        }
        const errorTextElement = this.targetElement.querySelector('.dx-empty-message');
        if (errorTextElement) {
            errorTextElement.classList.add('wd-placeholder-container');
        }
    }

    /**
     *  Sets the list options.
     *
     * @param {IMasterTableOptions} options The options for the master list.
     * @memberof MasterList
     */
    public setOptions(options: IMasterListOptions): void {
        if (!this.options) {
            throw new ReferenceError('The options were not set.');
        }
        this.options = { ...this.defaultOptions, ...options };
    }

    /**
     * Clears selection.
     *
     * @memberof MasterList
     */
    public clearSelection(): void {
        if (this.listInstance) {
            this.listInstance.unselectAll();
        }
    }
    /**
     * Destroys the list and clears the DOM.
     * 
     * @memberof MasterList
     */
    public destroy(): void {
        if (this.alreadyAdded) {
            this.disposeList();
        }
    }

    /**
     * SelectionChanged event handling.
     *
     * @private
     * @param {*} event The event.
     * @memberof MasterList
     */
    protected selectionChangedHandling(event: any) {
        if (event.addedItems) {
            this.selectedElements = new Array<T>();
            for (const item of event.addedItems) {
                this.selectedElements.push(item.dataObject);
            }
            if (this.options.multipleSelectedIdentitiesCallback) {
                this.options.multipleSelectedIdentitiesCallback(this.selectedElements);
            }
        }
    }
    /**
     * DxList Item Template, responsible for each row in the DxList widget. 
     * Draggable class required so row is draggable, data-wd-row-id required for ghosting effect during drag event.
     *
     * @protected
     * @param {RowData<T>} rowData The row's data model.
     * @param {number} index The index.
     * @param {DevExpress.core.dxElement} itemElement The DOM element.
     * @returns {JQuery<HTMLElement>}
     * @memberof MasterList
     */
    protected initItem(rowData: RowData<T>, index: number, itemElement: DevExpress.core.dxElement): JQuery<HTMLElement> {
        // TODO: remove DevExpress.core.dxElement for public api typings.
        itemElement.attr('data-wd-row-id', index.toString());
        if (this.options.canShare) {
            itemElement.attr('draggable', 'true');
        }
        const result = this.jQuery('<div>').addClass('wd-master-list-entry');

        if (rowData.dataObject) {
            this.jQuery('<div>').addClass('wd-master-list-icon').html(this.getFileIcon(rowData.dataObject)).appendTo(result);

            // Tooltip
            const rowIdentity = rowData.dataObject as unknown as WindreamIdentity;
            result[0].title = `${rowIdentity.location !== '\\' ? rowIdentity.location : ''}\\${rowIdentity.name}`;

            // Favorites
            if (this.options.showFavorites) {
                const iconButton = this.jQuery('<button>').addClass('wd-icon-button').prependTo(result);
                const iconSpan = this.jQuery('<span>').addClass('wd-icon wd-favorites-icon fa-sharp fa-star').appendTo(iconButton);

                if (rowData.isFavorite) {
                    this.setFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.removeFromFavorites'));
                } else {
                    this.removeFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.addToFavorites'));
                }

                iconButton[0].addEventListener('click', async () => {

                    if (!rowData.isFavorite) {
                        this.setFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.removeFromFavorites'));
                    } else {
                        this.removeFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.addToFavorites'));
                    }

                    if (this.onFavoriteClick) {
                        await this.onFavoriteClick(rowData, rowData.dataObject as unknown as WindreamIdentity).then(() => {
                            if (rowData.isFavorite) {
                                this.setFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.removeFromFavorites'));
                            } else {
                                this.removeFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.addToFavorites'));
                            }
                        });
                    }
                });
            }
        }
        const detailsContainer = this.jQuery('<div>').addClass('wd-master-list-details').appendTo(result);
        rowData.cellData.forEach((cellData, index) => {
            if (this.currentData) {
                const title = this.currentData.data.header[index].displayValue || '';
                const text = cellData.displayValue || '';
                if (index === 0) { // The first column is considred the title
                    this.jQuery('<h3>').attr('title', title).addClass('wd-master-list-title').text(text).appendTo(detailsContainer);
                } else {
                    this.jQuery('<div>').attr('title', title).addClass('wd-master-list-column').text(text).appendTo(detailsContainer);
                }
            }
        });
        return result;
    }

    /**
     * Generates the current data source.
     *
     * @protected
     * @param {DataCollectionData<T>} data
     * @param {MasterListOptions} [dataStoreOptions]
     * @returns
     * @memberof MasterList
     */
    protected generateDataSource(data: DataCollectionData<T>, dataStoreOptions?: MasterListOptions) {
        return new DevExpress.data.DataSource({
            group: (dataStoreOptions && dataStoreOptions.group) ? dataStoreOptions.group : undefined,
            postProcess: (dataStoreOptions && dataStoreOptions.postProcess) ? dataStoreOptions.postProcess : undefined,
            sort: (dataStoreOptions && dataStoreOptions.sort) ? dataStoreOptions.sort : undefined,
            store: data.data.rows
        } as DevExpress.data.DataSource.Options);
    }

    /**
     * Disposes the list using DevExtreme API.
     * 
     * @private
     * @memberof MasterList
     */
    private disposeList(): void {
        this.jQuery(this.targetElement).find('.wd-master-list').dxList('instance').dispose();

        this.targetElement.innerHTML = '';
        this.alreadyAdded = false;
    }

}