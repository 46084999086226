import { ArgumentError } from './argumentError';
/**
 * 	Thrown by methods that verify that arguments are in a given range.
 * 
 * @export
 * @class ArgumentOutOfRangeError
 * @extends {Error}
 */
export class ArgumentOutOfRangeError extends ArgumentError {

    /**
     * Creates an instance of ArgumentOutOfRangeError.
     * @param {string} [message] 
     * 
     * @memberof ArgumentOutOfRangeError
     */
    public constructor(message?: string) {
        super(message);
        this.name = 'ArgumentOutOfRangeError';
    }
}