import { Actions } from '../../../typings/windreamWebService/Windream.WebService.Permissions';
import { WindreamIdentity } from '../../common';

/**
 * Container for the response of a permission request.
 *
 * @export
 * @class PermissionResponse
 */
export class PermissionResponse {

    /**
     * Identity the response belongs to.
     *
     * @type {WindreamIdentity}
     * @memberof PermissionResponse
     */
    public identity: WindreamIdentity;

    /**
     * Allowed actions for the given identity.
     *
     * @type {Actions}
     * @memberof PermissionResponse
     */
    public allowedActions: Actions;

    /**
     * Creates an instance of PermissionResponse.
     *
     * @param {WindreamIdentity} identity
     * @param {Actions} allowedActions
     * @memberof PermissionResponse
     */
    public constructor(identity: WindreamIdentity, allowedActions: Actions) {
        this.identity = identity;
        this.allowedActions = allowedActions;
    }
}