import { ValueTypes } from '../common';

/**
 * Item Collection Model for choicelists
 *
 * @export
 * @class ChoiceListItemCollection
 */
export class ChoiceListItemCollectionModel {
    /**
     * ID for not set items.
     *
     * @static
     * @memberof ChoiceListItemCollectionModel
     */
    public static NOT_SET_ITEM_ID = -1;

    /**
     * ID of the choice list.
     *
     * @type {number}
     * @memberof ChoiceListItemCollectionModel
     */
    public id: number;


    /**
     * Name of the choice list.
     *
     * @type {string}
     * @memberof ChoiceListItemCollectionModel
     */
    public name: string;

    /**
     * Data type of the items within the choice list.
     *
     * @type {ValueTypes}
     * @memberof ChoiceListItemCollectionModel
     */
    public type: ValueTypes;

    /**
     * Items within the choice list.
     *
     * @type {((string | number)[])}
     * @memberof ChoiceListItemCollectionModel
     */
    public items: (string | number | boolean)[];

    /**
     * Creates an instance of ChoiceListItemCollectionModel.
     *
     * @param {number} id ID of the choice list.
     * @param {string} name Name of the choice list.
     * @param {ValueTypes} type Data type of the items within the choice list.
     * @param {(string | number | boolean)[]} items Items within the choice list.
     * @memberof ChoiceListItemCollectionModel
     */
    public constructor(id: number, name: string, type: ValueTypes, items: (string | number | boolean)[]) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.items = items;
    }
}
