import { IContextMenuItemConfig } from './interfaces';

/**
 * Reflects items of the ContextMenu class.
 * 
 * @export
 * @class ContextMenuItem
 */
export class ContextMenuItem {

    /**
     * The text of this item.
     * 
     * @type {string}
     * @memberof ContextMenuItem
     */
    public text?: string;

    /**
     * The click callback.
     * 
     * @type {*}
     * @memberof ContextMenuItem
     */
    public click: any;

    /**
     * The css class for this item.
     * 
     * @type {string}
     * @memberof ContextMenuItem
     */
    public cssClass?: string;

    /**
     * Whether the item is disabled.
     * 
     * @type {boolean}
     * @memberof ContextMenuItem
     */
    public isDisabled: boolean;

    /**
     * The children of this item.
     * 
     * @type {ContextMenuItem[]}
     * @memberof ContextMenuItem
     */
    public children?: ContextMenuItem[];

    /**
     * Creates an instance of ContextMenuItem.
     * @param {IContextMenuItemConfig} [config] 
     * 
     * @memberof ContextMenuItem
     */
    public constructor(config?: IContextMenuItemConfig) {
        this.isDisabled = false;

        if (config) {
            if (config.text) {
                this.text = config.text;
            }
            if (config.click) {
                this.click = config.click;
            }
            if (config.cssClass) {
                this.cssClass = config.cssClass;
            }
            if (config.children) {
                this.children = config.children;
            }
            if (config.isDisabled) {
                this.isDisabled = config.isDisabled;
            }
        }
    }

    /**
     * Adds an item as child to this menu.
     * 
     * @param {ContextMenuItem} item 
     * 
     * @memberof ContextMenuItem
     */
    public addItem(item: ContextMenuItem): void {
        if (!this.children) {
            this.children = new Array<ContextMenuItem>();
        }
        this.children.push(item);
    }
}