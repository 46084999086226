import { AuthenticationModes, IAuthenticationManager } from '../authentication/index';
import { UserDetails } from '../authentication/userDetails';
import { Logout } from '../services/index';
import { IPopupHelper } from '../ui';
import { IWebBridgeHandler, WebBridgeEventTypes } from '../webBridge';
import { AuthenticationTypes } from './authenticationTypes';
import { BaseLoginMask, ILoginManager } from './index';

/**
 * The LoginManager will handle different kinds of login forms.
 *
 * @export
 * @class LoginManager
 * @implements {ILoginManager}
 */
export class LoginManager implements ILoginManager {
    private currentSessionTimeoutPopup?: FoundationSites.Reveal;
    private authenticationManager: IAuthenticationManager;
    private loginCallbackCollection: ((userDetails: UserDetails, encryptionSecret?: string) => void)[];
    private logoutCallbackCollection: (() => void)[];
    private loginMask: BaseLoginMask;
    private jQueryStatic: JQueryStatic;
    private popupHelper: IPopupHelper;
    private logoutService: Logout;
    private webBridge: IWebBridgeHandler;

    /**
     * Creates an instance of LoginManager.
     *
     * @param {Logout} logoutAction
     * @param {IAuthenticationManager} authenticationManager
     * @param {BaseLoginMask} loginMask
     * @param {JQueryStatic} jQueryStatic
     * @param {IPopupHelper} popupHelper
     * @param {IWebBridgeHandler} webBridge
     * @memberof LoginManager
     */
    public constructor(logoutAction: Logout, authenticationManager: IAuthenticationManager, loginMask: BaseLoginMask,
        jQueryStatic: JQueryStatic, popupHelper: IPopupHelper, webBridge: IWebBridgeHandler) {
        this.authenticationManager = authenticationManager;
        this.loginCallbackCollection = new Array<((userDetails: UserDetails, encryptionString?: string) => void)>();
        this.logoutCallbackCollection = new Array<(() => void)>();
        this.jQueryStatic = jQueryStatic;
        this.logoutService = logoutAction;
        this.popupHelper = popupHelper;
        this.webBridge = webBridge;
        this.loginMask = loginMask;
        this.loginMask.registerLoginCallback((userDetails, encryptionSecret) => {
            if (this.currentSessionTimeoutPopup) {
                this.currentSessionTimeoutPopup.close();
            }
            this.loginCallbackCollection.forEach((callback) => {
                callback(userDetails, encryptionSecret);
            });
        });
    }

    /**
     * Render the login page.
     *
     * @param {HTMLElement} targetElement The target element.
     * @param {AuthenticationTypes} authenticationType The type of authentication to use.
     * @returns {Promise<boolean>} A promise which will resolve with true if rendering occured or false if auto-login occured thus no rendering needed.
     * @memberof LoginManager
     */
    public async renderLoginPage(targetElement: HTMLElement, authenticationType: AuthenticationTypes): Promise<boolean> {
        switch (authenticationType) {
            case AuthenticationTypes.MSAL:
            case AuthenticationTypes.WindreamLike:
            default:
                this.loginMask.destroy();
                return this.loginMask.renderPage(targetElement);
        }
    }

    /**
     * Render the session timeout popup.
     *
     * @param {AuthenticationTypes} authenticationType The type of authentication to use.
     * @returns {Promise<void>}
     * @memberof LoginManager
     */
    public async renderSessionTimeoutPopup(authenticationType: AuthenticationTypes): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            switch (authenticationType) {
                case AuthenticationTypes.WindreamLike:
                default:
                    this.currentSessionTimeoutPopup = this.popupHelper.openPopup({
                        closeOnClick: false,
                        closeOnEsc: false,
                        destroyOnClose: true,
                        immediatelyOpen: false,
                        isModal: true,
                        isTopMost: true,
                        displayBodyOnly: true,
                        title: '',
                        body: (jQuery) => {
                            const target = jQuery('<div></div>')[0];
                            this.loginMask.renderSessionTimeoutPopup(target).then(() => {
                                // Do not open popup if authenticaiton mode is Windows or MSAL
                                // In that case login will be performed automatically in the background
                                if (this.currentSessionTimeoutPopup &&
                                    (this.authenticationManager.getCurrentAuthenticationMode() !== AuthenticationModes.Windows &&
                                        this.authenticationManager.getCurrentAuthenticationMode() !== AuthenticationModes.MSAL)) {

                                    this.currentSessionTimeoutPopup.open();
                                    this.webBridge.publish(WebBridgeEventTypes.Login, 'Login');
                                }
                                resolve();
                            }).catch((error) => {
                                reject(error);
                            });
                            return target;
                        }
                    });
            }
        });
    }

    /**
     * Register a callback, which will be called when the login was successful.
     *
     * @param {(userDetails: UserDetails, encryptionSecret?: string) => void} callback The callback to call after login.
     * @memberof LoginManager
     */
    public registerLoginCallback(callback: (userDetails: UserDetails, encryptionSecret?: string) => void): void {
        this.loginCallbackCollection.push(callback);
    }

    /**
     * Logout from the application.
     *
     * @returns {Promise<boolean>}
     * @memberof LoginManager
     */
    public async logout(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.loginMask.logout().then((success) => {
                this.logoutService.do().then(() => {
                    if (success) {
                        // eslint-disable-next-line promise/no-callback-in-promise
                        this.logoutCallbackCollection.forEach((callback) => callback());
                        this.jQueryStatic('body').removeClass('wd-logged-in');
                        resolve(success);
                        return;
                    }
                    resolve(false);
                }).catch((error) => reject(error));
            }).catch((error) => {
                reject(error);
            });
        });
    }

    /**
     * Register a logout callback which will be called after a successful logout.
     *
     * @param {() => void} callback The callback to call after a logout.
     * @memberof LoginManager
     */
    public registerLogoutCallback(callback: () => void): void {
        this.logoutCallbackCollection.push(callback);
    }

}