/**
 * Class to represet a single item inside a tree.
 *
 * @export
 * @class TreeItem
 */
export class TreeItem<T> {
  /**
   * Unique ID of the TreeItem..
   *
   * @type {number}
   * @memberof TreeItem
   */
  public id?: number;
  /**
   * Displayed name of the TreeItem.
   *
   * @type {string}
   * @memberof TreeItem
   */
  public name?: string;
  /**
   * Data that is represented by the TreeItem.
   *
   * @type {T}
   * @memberof TreeItem
   */
  public data?: T;
  /**
   * Possible child TreeItems.
   *
   * @type {TreeItem<T>[]}
   * @memberof TreeItem
   */
  public children?: TreeItem<T>[];
  /**
   * ID of the parent TreeItem.
   *
   * @type {number}
   * @memberof TreeItem
   */
  public parentId?: number;
}