/**
 * Response base class of all service actions.
 *
 * @export
 * @class ActionResponse
 * @implements {ServiceActionInterface}
 */
export class ActionResponse {
    /**
     * Response of the webservice.
     * 
     * @type {*}
     * @memberof ActionResponse
     */

    public response: any;
    /**
     * HTTP status code of the response.
     * 
     * @type {number}
     * @memberof ActionResponse
     */
    public statusCode?: number;

    /**
     * If the response contained errors.
     * 
     * @type {boolean}
     * @memberof ActionResponse
     */
    public hasErrors?: boolean;

}