import { LoadingIndicator as ILoadingIndicator } from '../../../../../typings/ui';

/**
 * Provides a loading indicator component.
 *
 * @export
 * @class LoadingIndicator
 * @implements {ILoadingIndicator}
 */
export class LoadingIndicator implements ILoadingIndicator {
    private targetElement: HTMLElement;
    private jQueryStatic: JQueryStatic;

    /**
     * Creates an instance of LoadingIndicator.
     * 
     * @param {HTMLElement} targetElement The target element.
     * @param {JQueryStatic} jQueryStatic The JQuery lib.
     * @memberof LoadingIndicator
     */
    public constructor(targetElement: HTMLElement, jQueryStatic: JQueryStatic) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
        this.jQueryStatic = jQueryStatic;
    }

    /**
     * Bootstrap the component.
     *
     * @memberof LoadingIndicator
     */
    public bootstrap(): void {
        this.getJQueryElement().dxLoadIndicator();
    }

    /**
     * Gets the JQuery element.
     *
     * @private
     * @returns {JQuery<HTMLElement>} The JQuery element.
     * @memberof LoadingIndicator
     */
    private getJQueryElement(): JQuery<HTMLElement> {
        if (!this.targetElement) {
            throw new Error('The target element was not set yet.');
        }

        return this.jQueryStatic(this.targetElement);
    }
}