import { Utils } from '../common';
import { ApplicationConfig, ComponentInstanceConfig, IConfigManager, Style, ViewConfig } from '../config';
import { CultureHelper } from '../culture';
import { ITemplateExtension } from '../extensions';
import { KeyCodes } from '../keyboard';
import { ILanguageProvider } from '../language';
import { SubViewConfig } from '../loader';
import { Logger } from '../logging';
import { PubSubConfig } from '../pubSub';
import { IServiceManager, SaveviewRequestOptions, UseCaseRequestOptions } from '../services';
import { NotificationHelper } from '../ui';
import { INewViewHandler } from './interfaces';
import { UseCase } from './models';

/**
 * Class to handle new view panel.
 * 
 * @export
 * @class NewViewHandler
 * @implements {INewViewHandler}
 */
export class NewViewHandler implements INewViewHandler {
    /**
     * Static ID for empty view.
     * 
     * @static
     * @memberof NewViewHandler
     */
    public static EMPTY_ID = '##EMPTY##';
    /**
     * Callback to execute when a new view is added.
     * 
     * @memberof NewViewHandler
     */
    public onAddView?: (viewConfig: ViewConfig) => Promise<void>;

    protected selectedUseCaseId?: string;

    private element?: HTMLElement;
    private templateExtension: ITemplateExtension;
    private configManager: IConfigManager;
    private serviceManager: IServiceManager;
    private languageProvider: ILanguageProvider;
    private applicationConfig: ApplicationConfig;
    private cultureHelper: CultureHelper;
    private logger: Logger;

    private useCases: UseCase[] | null;
    private clickEventListener?: (e: Event) => void;
    private keyEventListener?: (e: Event) => void;

    /**
     * Creates an instance of NewViewHandler.
     * @param {ITemplateExtension} templateExtension
     * @param {IConfigManager} configManager
     * @param {IServiceManager} serviceManager
     * @param {ILanguageProvider} languageProvider
     * @param {ApplicationConfig} applicationConfig
     * @param {Logger} logger
     * @param {CultureHelper} cultureHelper
     * @memberof NewViewHandler
     */
    public constructor(templateExtension: ITemplateExtension, configManager: IConfigManager, serviceManager: IServiceManager,
        languageProvider: ILanguageProvider, applicationConfig: ApplicationConfig, logger: Logger, cultureHelper: CultureHelper) {
        this.templateExtension = templateExtension;
        this.configManager = configManager;
        this.serviceManager = serviceManager;
        this.languageProvider = languageProvider;
        this.applicationConfig = applicationConfig;
        this.cultureHelper = cultureHelper;
        this.logger = logger;
        this.useCases = null;
        this.templateExtension.addHelper('getTranslationForNewView', (key: string) => this.languageProvider.get(key));
        // NOTE: Removed for beta release. :remove-add-settings-menu
        // > Router.onViewChange(() => this.closeDropdown());
    }

    /**
     * Renders the new view panel.
     * 
     * @memberof NewViewHandler
     */
    public render(element: HTMLElement): void {
        this.element = element;
        this.getUseCases().then((useCases: UseCase[]) => {
            if (!this.element) {
                this.logger.error('NewViewHandler', 'render', 'Element has got undefined');
                return;
            }

            this.templateExtension.render(this.element, require('./templates/newView.html'), {
                useCases: useCases,
                uuid: Utils.Instance.getRandomString()
            });

            // @ts-ignore - Disabled because of Foundation usage
            if (typeof window !== 'undefined'
                // @ts-ignore - Disabled because of Foundation usage
                && typeof window['$'] !== 'undefined') {
                $(this.element).foundation();
                $(this.element).on('show.zf.dropdown', () => {
                    this.onDropdownOpen();
                });
            }

            if (this.clickEventListener) {
                this.element.removeEventListener('click', this.clickEventListener);
            }
            if (this.keyEventListener) {
                this.element.removeEventListener('keyup', this.keyEventListener);
            }
            this.element.addEventListener('click', this.clickEventListener = (e: Event) => {
                const type = Utils.Instance.getWdAttribute(e.target as HTMLElement, 'type');
                if (type === 'selectUseCase') {
                    const id = Utils.Instance.getWdAttribute(e.target as HTMLElement, 'id');
                    if (id) {
                        this.selectUseCase(id);
                        this.checkAddButtonDisabled();
                    }
                } else if (type === 'addView') {
                    if (this.selectedUseCaseId) {
                        this.addView(this.selectedUseCaseId).catch((err: Error) => {
                            this.logger.error('NewViewHandler', 'render', 'Error in addView during click handler', err);
                        });
                    }
                } else if (type === 'cancel') {
                    this.clearNewViewName();
                    this.selectUseCase('');
                    this.checkAddButtonDisabled();
                    this.closeDropdown();
                }
            });

            this.element.addEventListener('keyup', this.keyEventListener = (e: KeyboardEvent) => {
                const type = Utils.Instance.getWdAttribute(e.target as HTMLElement, 'type');
                if (type === 'viewName') {
                    if (!this.checkAddButtonDisabled() && e.keyCode === KeyCodes.ENTER && this.selectedUseCaseId) {
                        this.addView(this.selectedUseCaseId).catch((err: Error) => {
                            this.logger.error('NewViewHandler', 'render', 'Error in addView during click handler', err);
                        });
                    }
                }
                if (type === 'selectUseCase') {
                    const id = Utils.Instance.getWdAttribute(e.target as HTMLElement, 'id');
                    if (id && this.useCases) {
                        this.checkAddButtonDisabled();
                        let usecaseIndex = this.useCases.findIndex((usecase) => usecase.id === id);
                        if (e.keyCode === KeyCodes.ENTER || e.keyCode === KeyCodes.SPACE) {
                            this.selectUseCase(id);
                        } else if (e.keyCode === KeyCodes.RIGHT_ARROW || e.keyCode === KeyCodes.DOWN_ARROW) {
                            usecaseIndex++;
                            this.selectUseCase(usecaseIndex < this.useCases.length ? this.useCases[usecaseIndex].id : this.useCases[0].id);
                        } else if (e.keyCode === KeyCodes.LEFT_ARROW || e.keyCode === KeyCodes.UP_ARROW) {
                            usecaseIndex--;
                            this.selectUseCase(usecaseIndex >= 0 ? this.useCases[usecaseIndex].id : this.useCases[this.useCases.length - 1].id);
                        }
                    }
                }
            });

        }).catch((err: Error) => {
            this.logger.error('NewViewHandler', 'render', 'Fail to get use cases', err);
        });
    }

    /**
     *  Adds a new view from the use case with the given ID.
     * 
     * @private
     * @async
     * @param {string} id 
     * @returns {Promise<ViewConfig>} 
     * @memberof NewViewHandler
     */
    private async addView(id: string): Promise<ViewConfig> {
        if (this.useCases) {
            const name = this.getNewViewName();
            if (name) {
                this.clearNewViewName();
                return this.getUseCase(id).then(async (viewConfig: ViewConfig) => {
                    viewConfig.alias = viewConfig.alias;
                    viewConfig.author = 'Automatically generated';
                    viewConfig.modified = new Date().toDateString();
                    viewConfig.id = this.generateViewId(name);
                    viewConfig.name[this.cultureHelper.getCurrentLanguage()] = name;
                    viewConfig.style = Style.default();
                    // Set transparent as default background
                    viewConfig.style.colors = 'wd-style-colors-15';

                    this.closeDropdown();
                    return this.configManager.createViewConfig(viewConfig.id, viewConfig, this.applicationConfig).then(async () => {
                        // TODO: Avoid this two-staged process for creating a view from a use case
                        return new Promise((resolve, reject) => {
                            this.serviceManager.getServices().DynamicWorkspace.saveView(new SaveviewRequestOptions(viewConfig.id, viewConfig))
                                .then((data) => resolve(data)).catch((error) => reject(error));
                        });
                    }).then(async (newViewConfig: ViewConfig) => {
                        if (this.onAddView) {
                            this.onAddView(newViewConfig).catch((err: Error) => {
                                throw err;
                            });
                        }
                        if (typeof window !== 'undefined' && newViewConfig.id) {
                            // Rewrite it if recomissioned
                            // Router.changeView(newViewConfig.id);
                        }
                        return Promise.resolve(newViewConfig);
                    });
                }, async (err: Error) => {
                    NotificationHelper.Instance.error({
                        body: this.languageProvider.get('framework.addToViewModal.loadUsecase.invalidUseCase'),
                        title: this.languageProvider.get('framework.generic.error')
                    });
                    return Promise.reject(err);
                });
            }
        }
        return Promise.reject(new Error('Can not add view'));
    }

    /**
     * Replaces all invalid charaters in the given view name to generate a valid ID.
     * 
     * @private
     * @param {string} viewName Name of the view.
     * @returns {string} A valid ID for the view.
     * @memberof NewViewHandler
     */
    private generateViewId(viewName: string): string {
        let newName = viewName.replace(/\s/g, '-').toLocaleLowerCase();
        newName = newName.replace(/[\|\:\<\>\*\/\?\"\\]/g, '');

        if (newName.length === 0) { // If there are no characters left after replacement, use GUID as ID
            newName = Utils.Instance.getUUID();
        }

        return newName;
    }

    /**
     * Returns the available use cases.
     * Will query the server if they were not loaded yet and will cache these results.
     * Will automatically add the default empty view.
     * 
     * @private
     * @returns {Promise<UseCase[]>} Promise to resolve with the avaiable use cases.
     * @async
     * @memberof NewViewHandler
     */
    private async getUseCases(): Promise<UseCase[]> {
        if (this.useCases) {
            return Promise.resolve(this.useCases);
        }
        return new Promise<UseCase[]>((resolve, reject) => {
            this.serviceManager.getServices().DynamicWorkspace.getUseCases().then((useCases) => {
                const defaultUseCase = new UseCase(this.languageProvider.get('framework.navigationhandler.addNewView.emptyView'), NewViewHandler.EMPTY_ID);
                this.useCases = [defaultUseCase];
                this.useCases.push(...useCases.map((useCaseId: string) => new UseCase(useCaseId, useCaseId)));
                resolve(this.useCases);
            }).catch((err) => reject(err));
        });
    }

    /**
     * Loads the config for the given use case.
     * Will return an empty view if id equals the empty view string `NewViewHandler.EMPTY_ID`.
     * 
     * @private
     * @param {string} id ID of the use case to load config for.
     * @returns {Promise<ViewConfig>} Promise to resolve with the use case config.
     * @async
     * @memberof NewViewHandler
     */
    private async getUseCase(id: string): Promise<ViewConfig> {
        if (id === NewViewHandler.EMPTY_ID) {
            const viewConfig = new ViewConfig(NewViewHandler.EMPTY_ID);
            viewConfig.version = '0.0.1';
            viewConfig.pubSub = new Array<PubSubConfig>();
            viewConfig.components = new Array<ComponentInstanceConfig>();
            viewConfig.triggers = {};
            viewConfig.subViews = new Array<SubViewConfig>();
            return Promise.resolve(viewConfig);
        }
        return new Promise<ViewConfig>(async (resolve, reject) => {
            return this.serviceManager.getServices().DynamicWorkspace.loadUseCaseConfig(new UseCaseRequestOptions(id))
                .then((viewConfig) => resolve(viewConfig)).catch((error) => reject(error));
        });
    }

    /**
     * Close the dropdown pane.
     * 
     * @private
     * @memberof NewViewHandler
     */
    private closeDropdown(): void {
        if (!this.element) {
            this.logger.error('NewViewHandler', 'closeDropdown', 'Element not defined');
            return;
        }
        const dropdownPane = this.element.querySelector<HTMLElement>('[data-dropdown]');
        if (dropdownPane) {
            // @ts-ignore - Disabled because of Foundation usage
            $(dropdownPane).foundation('close');
        }
    }

    /**
     * Selects the use case with the given ID.
     * 
     * @private
     * @param {string} useCaseId 
     * @memberof NewViewHandler
     */
    private selectUseCase(useCaseId: string): void {
        if (!this.element) {
            this.logger.error('NewViewHandler', 'selectUseCase', 'Element not defined');
            return;
        }
        this.selectedUseCaseId = useCaseId;
        const currentlySelected = this.element.querySelector<HTMLElement>('[data-wd-type="selectUseCase"].active');
        if (currentlySelected) {
            currentlySelected.classList.remove('active');
            currentlySelected.setAttribute('aria-checked', 'false');
            currentlySelected.tabIndex = -1;
        }
        const newSelected = this.element.querySelector<HTMLElement>(`[data-wd-type="selectUseCase"][data-wd-id="${useCaseId}"]`);
        if (newSelected) {
            newSelected.classList.add('active');
            newSelected.setAttribute('aria-checked', 'true');
            newSelected.tabIndex = 0;
            newSelected.focus();
        }
    }

    /**
     * Disables or enables the add view button.
     *
     * @private
     * @returns {boolean} Whether the button is disabled or not.
     * @memberof NewViewHandler
     */
    private checkAddButtonDisabled(): boolean {
        if (!this.element) {
            this.logger.error('NewViewHandler', 'checkAddButtonDisabled', 'Element not defined');
            return false;
        }
        const addViewButton = this.element.querySelector<HTMLButtonElement>('[data-wd-type="addView"]');
        if (addViewButton) {
            const isButtonDisabled = (!this.selectedUseCaseId || !this.getNewViewName());
            addViewButton.disabled = isButtonDisabled;
            return isButtonDisabled;
        }
        return true;
    }

    /**
     * Reads the view name input and returns the current value.
     * 
     * @private
     * @returns {string} The current view name input value.
     * @memberof NewViewHandler
     */
    private getNewViewName(): string {
        if (!this.element) {
            this.logger.error('NewViewHandler', 'getNewViewName', 'Element not defined');
            return '';
        }
        const nameInput = this.element.querySelector<HTMLInputElement>('input[data-wd-type="viewName"]');
        if (nameInput) {
            return nameInput.value.toString();
        }
        return '';
    }
    /**
     * Reads the view name input and returns the current value.
     * 
     * @private
     * @returns {string} The current view name input value.
     * @memberof NewViewHandler
     */
    private clearNewViewName(): void {
        if (!this.element) {
            this.logger.error('NewViewHandler', 'clearNewViewName', 'Element not defined');
            return ;
        }
        const nameInput = this.element.querySelector<HTMLInputElement>('input[data-wd-type="viewName"]');
        if (nameInput) {
            nameInput.value = '';
        }
    }

    /**
     * Select empty view and set focus to name input.
     * 
     * @private
     * @memberof NewViewHandler
     */
    private onDropdownOpen(): void {
        if (!this.element) {
            this.logger.error('NewViewHandler', 'onDropdownOpen', 'Element not defined');
            return ;
        }
        this.selectUseCase(NewViewHandler.EMPTY_ID);
        const textbox = this.element.querySelector<HTMLInputElement>('input');
        if (textbox) {
            textbox.focus();
        }
        this.checkAddButtonDisabled();
    }
}