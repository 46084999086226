import { DocumentResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.Documents';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { WindreamEntity, WindreamIdentity } from '../../common';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';


/**
 * Service action to perform a EnsureFullIdentity call on the windream webservice.
 * 
 * @export
 * @class EnsureFullIdentity
 * @extends {ServiceAction}
 */
export class EnsureFullIdentity extends ServiceAction {

    /**
     * Creates an instance of EnsureFullIdentity.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @memberof EnsureFullIdentity
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'ensureFullIdentity';
    }

    /**
     * Ensure that the given WindreamIdentity has ID, name and location set.
     * Resolves a promise with the full identity.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WindreamRequestOptions} requestOptions The request options for this call.
     * @returns {Promise<WindreamIdentity>} A promise, which will resolve with a full windream identity.
     * @memberof EnsureFullIdentity
     */
    public async do(requestOptions: WindreamRequestOptions): Promise<WindreamIdentity> {
        return new Promise<WindreamIdentity>((resolve, reject) => {
            if (!requestOptions || !requestOptions.identity) {
                reject('Won\'t call the webservice since no identity was provided');
            }
            let apiPoint: string;
            if (requestOptions.identity.entity === WindreamEntity.Document) {
                apiPoint = 'documents';
            } else {
                apiPoint = 'directories';
            }
            const url = `${this.globalConfig.windreamWebServiceURL}/${apiPoint}/GetDetails`;

            const item = IdentityMapper.getWebServiceIdentity(requestOptions.identity);

            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', url, {
                Item: item
            }), requestOptions.requestOptions).then((response: IServiceResponse<DocumentResponseContainerDTO>) => {
                if (response.data?.HasErrors || !response.data?.Item) {
                    this.logger.error('EnsureFullIdentity', 'do', 'Failed to execute request', response);
                    reject(new Error('Couldn\'t fetch windream identity from webservice.'));
                    return;
                }
                const fullIdentity = new WindreamIdentity();
                fullIdentity.id = response.data?.Item.Id;
                fullIdentity.setLocationComplete(response.data.Item.LocationComplete);
                fullIdentity.entity = requestOptions.identity.entity;
                resolve(fullIdentity);
            }).catch((err: Error) => {
                this.logger.error('EnsureFullIdentity', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}