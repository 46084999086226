import { ObjectType } from '../../caching';
import { ResponseDetailsType, WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents basic update request options.
 *
 * @export
 * @class UpdateRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class UpdateRequestOptions extends WindreamRequestOptions {

    /**
     * The object type to set for the object.
     *
     * @type {ObjectType}
     * @memberof UpdateRequestOptions
     */
    public objectType?: ObjectType;

    /**
     * Collection of attributes and it's values to set for the object.
     *
     * @type {{ name: string, value: any }[]}
     * @memberof UpdateRequestOptions
     */
    public attributes?: { name: string, value: any }[];

    /**
     * The new location if the object should be moved.
     *
     * @type {string}
     * @memberof UpdateRequestOptions
     */
    public newLocation?: string;

    /**
     * The new name if the object should be renamed.
     *
     * @type {string}
     * @memberof UpdateRequestOptions
     */
    public newName?: string;

    /**
     * The reponse details type.
     *
     * @type {ResponseDetailsType}
     * @memberof UpdateRequestOptions
     */
    public responseDetailsType?: ResponseDetailsType;

    /**
     * Creates an instance of UpdateRequestOptions.
     * @param {WindreamIdentity} identity
     * @memberof UpdateRequestOptions
     */
    public constructor(identity: WindreamIdentity) {
        super(identity);
    }
}