import { ResponseContainerDTO } from 'typings/core';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { HttpResourcePointer } from '../../dataProviders';
import { SubscriptionRequestOptions } from '../models/subscriptionRequestOptions';
import { ServiceAction } from '../serviceAction';

/**
 * The CreateSubscription action is used to create a new subsciption with given event and element within windream.
 * 
 * @export
 * @class CreateSubscription
 * @extends {ServiceAction}
 */
export class CreateSubscription extends ServiceAction {

  /**
   * Name of the service action.
   * 
   * 
   * @memberof CreateSubscription
   */
  public name = 'createSubscription';

  /**
   * Execute createSubscription of given event and document/folder.
   *
   * Resolves with the server response.
   * Overwrites `do()` method from parent class ServiceAction.
   *
   * @param {SubscriptionRequestOptions} subscriptionRequestOptions The request options.
   * @returns {Promise<ResponseContainerDTO>} A promise, which will resolve with the server response.
   * @memberof CreateSubscription
   */
  public async do(subscriptionRequestOptions: SubscriptionRequestOptions): Promise<void> {

    return new Promise<void>((resolve, reject) => {
      this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/subscriptions/CreateSubscription',
        {
          Subscription: {
            Item: subscriptionRequestOptions.subscription.item,
            Flags: subscriptionRequestOptions.subscription.flags,
            Subscriber: subscriptionRequestOptions.subscription.subscriber,
            Event: {
              Id: subscriptionRequestOptions.subscription.event
            },
            Entity: subscriptionRequestOptions.subscription.entity
          }
        }), subscriptionRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
          if (response.data?.HasErrors) {
            this.logger.error('CreateSubscription', 'do', 'Failed to create subscription', response);
            reject('Failed to Failed to create subscription');
          } else {
            if (response.data) {
              resolve();
            }
          }
        }).catch((err: Error) => {
          this.logger.error('CreateSubscription', 'do', 'Failed to execute request', err);
          reject(err);
        });
    });
  }
}