import { ComponentInstanceConfig } from '../config';
import { PubSubNameMigration } from '../migration';

/**
 * Represents the migration object for component migration.
 *
 * @export
 * @class MigrationObject
 */
export class MigrationObject {

    /**
     * The updated component instance config.
     *
     * @type {ComponentInstanceConfig}
     * @memberof MigrationObject
     */
    public componentInstanceConfig: ComponentInstanceConfig;

    /**
     * Array of all publisher events and new names for the events.
     *
     * @type {PubSubNameMigration[]}
     * @memberof MigrationObject
     */
    public publisherEvents: PubSubNameMigration[];

    /**
     * Array of all subscriber events and new names for the events.
     *
     * @type {PubSubNameMigration[]}
     * @memberof MigrationObject
     */
    public subscriberEvents: PubSubNameMigration[];

    /**
     * Creates an instance of MigrationObject.
     * @param {ComponentInstanceConfig} componentInstanceConfig
     * @param {PubSubNameMigration[]} publisherEvents
     * @param {PubSubNameMigration[]} subscriberEvents
     * @memberof MigrationObject
     */
    public constructor(componentInstanceConfig: ComponentInstanceConfig, publisherEvents: PubSubNameMigration[], subscriberEvents: PubSubNameMigration[]) {
        this.componentInstanceConfig = componentInstanceConfig;
        this.publisherEvents = publisherEvents;
        this.subscriberEvents = subscriberEvents;
    }
}