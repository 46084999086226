/* eslint-disable max-lines */

import { Culture, DynamicWorkspace as PublicApi, Logger } from '../../../typings';
import { DataCollection, SortOptions, WindreamEntity, ValueTypes, WindreamIdentity, MouseClickType } from '../../../typings/core';
import { LanguageProvider } from '../../../typings/language';
import { DataCollectionData } from '../../../typings/ui';
import { CellData, DataCollectionDisplay, HeaderData, ISortingOption, RowData } from '../dataCollections';
import { Utils } from '../dynamicWorkspace';
import { IMasterTableOptions } from './interfaces';
import { DxTableDataViewModel } from './models/dxTableDataViewModel';
import { DxTableHeaderViewModel } from './models/dxTableHeaderViewModel';

/**
 * Provides a table, which will generate a generic table into the DOM.
 *
 * @export
 * @class DxMasterTable
 * @template T
 */
export class DxMasterTable<T> extends DataCollectionDisplay<T> {


    /**
     * The public API.
     *
     * @protected
     * @type {PublicApi}
     * @memberof DxMasterTable
     */
    protected publicApi?: PublicApi;
    protected selectedElements?: T[];
    protected currentId?: string;
    protected currentSorting?: ISortingOption<T>;
    protected options: IMasterTableOptions<T>;
    /**
     * Whether a double click has just occured.
     *
     * @protected
     * @type {boolean}
     * @memberof DxMasterTable
     */
    protected isDoubleClick: boolean;

    // The selected column, DevExtreme sadly doesn't have typings.
    private currentlySortedColumn?: any;
    private targetElement: HTMLElement;
    private jQueryStatic: JQueryStatic;
    private tableInstance?: DevExpress.ui.dxDataGrid;
    // 30 characters pre-padded string that is used for sorting.
    private readonly prePaddedSortingString = '000000000000000000000000000000';
    private readonly doubleClickPrevTime = 350;
    private clickCount = 0;
    private singleClickTimer: NodeJS.Timer | any;
    private internalResizeListener?: () => void;
    private navigationToolbarItem?: DevExpress.ui.dxToolbarItem;

    /**
     * The default options fpr masterTable.
     *
     * @private
     * @type {IMasterTableOptions}
     * @memberof DxMasterTable
     */
    private defaultOptions: IMasterTableOptions<T> = {
        afterRenderCallback: undefined,
        canShare: false,
        canSort: true,
        columnOrdering: true,
        columnOrderingCallback: undefined,
        columnResize: true,
        columnSortingCallback: undefined,
        isSearchable: false,
        pageSize: 0,
        parentNavigationCallback: undefined,
        showFileType: false
    };


    /**
     * Creates an instance of DxMasterTable.
     *
     * @param {HTMLElement} targetElement The target element.
     * @param {LanguageProvider} languageProvider The language provider.
     * @param {JQueryStatic} jQueryStatic jQueryStatic.
     * @param {Logger} logger The logger.
     * @param {Culture} cultureHelper The culture helper.
     * @param {IMasterTableOptions} [options] The options. 
     * @memberof DxMasterTable
     */
    public constructor(targetElement: HTMLElement, languageProvider: LanguageProvider, jQueryStatic: JQueryStatic, logger: Logger, cultureHelper: Culture, options?: IMasterTableOptions<T>) {
        super(languageProvider, logger, cultureHelper);
        this.targetElement = targetElement;
        this.options = { ...this.defaultOptions, ...options };
        this.jQueryStatic = jQueryStatic;
        this.isDoubleClick = false;
    }


    /**
     * Sets the disabled state of the component.
     *
     * @param {boolean} isDisabled Whether the component should be disabled.
     * @memberof DxMasterTable
     */
    public setDisabled(isDisabled: boolean): void {
        if (this.tableInstance) {
            this.tableInstance.option('disabled', isDisabled);
        }
    }

    /**
     * Instruct the datacollection to repaint.
     *
     * @public
     * @returns {void}
     * @memberof DxMasterTable
     */
    public repaint(): void {
        if (this.currentData && this.tableInstance) { // Use instance property to always apply correct, latest given width
            this.currentData.data.header.forEach((header, index) => {
                if (!this.options.showFavorites || index !== 0) {
                    this.tableInstance?.columnOption(index, 'width', this.calculateColumnWidth(header.width));
                }
            });
            this.tableInstance.repaint();
        }
    }

    /**
     *  Sets the table options.
     *
     * @param {IMasterTableOptions} options The options for the master table.
     * @memberof DxMasterTable
     */
    public setOptions(options: IMasterTableOptions<T>): void {
        if (!this.options) {
            throw new ReferenceError('The options were not set.');
        }
        this.options = { ...this.defaultOptions, ...options };
    }

    /**
     * Clears current selection.
     *
     * @memberof DxMasterTable
     */
    public clearSelection() {
        if (this.tableInstance) {
            this.tableInstance.clearSelection();
            if (this.options.multipleSelectedIdentitiesCallback) {
                this.options.multipleSelectedIdentitiesCallback(this.selectedElements as T[]);
            }
        }
        this.selectedElements = new Array();
    }

    /**
     * Render the table into the DOM.
     *
     * @param {DataCollectionData<T>} [data]  The used data model.
     * @returns {void}
     * @memberof DxMasterTable
     */
    public render(data?: DataCollectionData<T> | null): void {
        if (data) {
            if (this.tableInstance && this.options.isSearchable && this.options.resetSearchAfterContentChange) {
                // Clear the filter...
                this.tableInstance.clearFilter();

                // Clear filter operation separately for each column because 'clearFilter' only clears the value
                const columns = this.tableInstance.getVisibleColumns();
                if (columns) {
                    columns.forEach((column) => {
                        if (column.name) {
                            // Set the 'selectedFilterOperation' to undefined to reset the selected operation to default one
                            // Usage of 'columnOption' is currently the only way how the 'selectedFilterOperation' will be correctly resetted
                            this.tableInstance?.columnOption(column.name, 'selectedFilterOperation', undefined);
                        }
                    });
                }
            }
            if (this.currentData) {
                this.clearSelection();
                if (this.onlyUpdateData(data)) {
                    this.currentData = data;
                    this.setDataSource(this.generateDataSource(data.data, this.options ? this.options.pageSize : this.defaultOptions.pageSize));
                    this.updateNavigationToolbarItem(this.disableParentButtonState);
                } else {
                    this.renderDxTable(data);
                }
            } else {
                // Initial render
                this.targetElement.classList.add('wd-full-component');
                this.targetElement.classList.add('wd-master-table');

                this.renderDxTable(data);

                const containerElement = this.targetElement.querySelector('.dx-datagrid-rowsview');
                if (containerElement) {
                    containerElement.classList.add('wd-dragtarget');
                }
            }
        }
        this.selectedElements = new Array<T>();
    }

    /**
     * Destroys the table and cleans the DOM.
     * 
     * @memberof DxMasterTable
     */
    public destroy(): void {
        if (this.tableInstance) {
            this.tableInstance.dispose();
        }
        if (this.internalResizeListener) {
            window.removeEventListener('resize', this.internalResizeListener);
            this.internalResizeListener = undefined;
        }
    }

    /**
     * Returns selected items.
     *
     * @protected
     * @returns
     * @memberof DxMasterTable
     */
    protected getSelectedElements(): T[] | undefined {
        return this.selectedElements;
    }

    /**
     * Generates a pseudo remote data source in order to chunk the results.
     *
     * @protected
     * @param {DataCollection<T>} data The data to chunk.
     * @param {(number | undefined)} pageSize The page size.
     * @returns {DevExpress.data.DataSource} The data source with chunk logic.
     * @memberof DxMasterTable
     */
    protected generateDataSource(data: DataCollection<T>, pageSize: number | undefined): DevExpress.data.DataSource {
        // TODO: Remove direct typings to Datasource since this is in the public api.
        data = this.normalizeData(data);
        const dataSource = this.convertToDataSource(data);
        const customStoreOptions = {
            key: 'id',
            load: async (loadOptions: DevExpress.data.LoadOptions) => {
                return new Promise<any>((resolve) => {
                    if (typeof loadOptions.skip !== 'undefined' && loadOptions.take) {
                        const splicedArray = new Array<DxTableDataViewModel>();
                        const maxIndexThisLoad = loadOptions.skip + loadOptions.take;
                        for (let i = loadOptions.skip; i < maxIndexThisLoad; i++) {
                            if (i < dataSource.length) {
                                splicedArray.push(dataSource[i]);
                            }
                        }
                        resolve(splicedArray);
                    } else {
                        resolve(dataSource);
                    }
                });
            },
            loadMode: 'processed',
            requireTotalCount: true,
            totalCount: async () => {
                return new Promise<any>((resolve) => {
                    resolve(dataSource.length);
                });
            },
        } as DevExpress.data.DataSourceOptions;
        if (pageSize && pageSize > 0) {
            customStoreOptions.paginate = true;
            customStoreOptions.pageSize = pageSize;
        } else {
            customStoreOptions.paginate = false;
            customStoreOptions.pageSize = 0;
        }
        return new DevExpress.data.DataSource(customStoreOptions);
    }

    /**
     * Calculates the custom sort value.
     * Directories are always at the top, regardless of whether they are sorted in ascending or descending order.
     *
     * @protected
     * @param {string | number | Date | undefined} sortValue
     * @param {WindreamEntity} rowEntity
     * @param {boolean} isAscendingSortOrder    
     * @returns {string}
     * @memberof DxMasterTable
     */
    protected calculateCustomSortValue(sortValue: string | number | Date | undefined, rowEntity: WindreamEntity, isAscendingSortOrder: boolean): string {

        let calculatedSortValue = '';
        const isValueSet = sortValue !== undefined && sortValue !== null;

        if (rowEntity === WindreamEntity.Folder && !isValueSet) {
            calculatedSortValue = isAscendingSortOrder ? '00_' : '10_';
        } else if (rowEntity === WindreamEntity.Folder && isValueSet) {
            calculatedSortValue = isAscendingSortOrder ? '01_' : '11_';
        } else if (rowEntity === WindreamEntity.Document && !isValueSet) {
            calculatedSortValue = isAscendingSortOrder ? '10_' : '00_';
        } else if (rowEntity === WindreamEntity.Document && isValueSet) {
            calculatedSortValue = isAscendingSortOrder ? '11_' : '01_';
        }

        if (isValueSet) {
            let stringValue = '';
            if (sortValue instanceof Date) {
                stringValue += Utils.padString(this.prePaddedSortingString, '' + sortValue.getTime(), true);
            } else if (typeof sortValue === 'number') {
                stringValue += Utils.padString(this.prePaddedSortingString, '' + sortValue, true);
            } else {
                stringValue += sortValue;
            }

            calculatedSortValue += stringValue;
        }

        return calculatedSortValue;
    }

    /**
     * SelectionChanged event handling
     *
     * @private
     * @param {*} event
     * @memberof DxMasterTable
     */
    protected selectionChangedHandling(event: any, clickType: MouseClickType) {
        if (event.selectedRowsData) {
            this.selectedElements = new Array<T>();
            for (const item of event.selectedRowsData) {
                this.selectedElements.push(item.data);
            }
            setTimeout(() => {
                if (!this.isDoubleClick) {
                    if (this.options.multipleSelectedIdentitiesCallback) {
                        this.options.multipleSelectedIdentitiesCallback(this.selectedElements as T[], clickType);
                    }
                }

            }, this.doubleClickPrevTime + 1);
        }
    }

    /**
     * Convert header data to DevExtreme model.
     *
     * @protected
     * @param {HeaderData<T>[]} headerData
     * @returns {DxTableHeaderViewModel[]}
     * @memberof DxMasterTable
     */
    protected convertToHeaderData(headerData: HeaderData<T>[]): DxTableHeaderViewModel[] {
        const tempOptionForSorting: SortOptions = {
            numeric: true,
            sortAsc: true,
            sensitivity: 'base'
        };
        const headerDataResult = new Array<DxTableHeaderViewModel>();

        // Calculate the index of the column where the icon should be added
        let startDataColumnIndex = 0;
        if (this.options.showCheckBoxes && this.options.multiSelect) {
            startDataColumnIndex++;
        }

        if (this.options.showFavorites) {
            startDataColumnIndex++;

            const starIconColumn: DxTableHeaderViewModel = {
                allowFiltering: false,
                allowReordering: false,
                allowResizing: false,
                caption: '', // No caption for the favorite icon column
                width: '2.5rem',
                cellTemplate: (container: DevExpress.core.dxElement, cellInfo: DxTableDataViewModel) => {
                    const iconButton = this.jQueryStatic('<button>').addClass('wd-icon-button').prependTo(container);

                    const iconSpan = this.jQueryStatic('<span>').addClass('wd-icon wd-favorites-icon').appendTo(iconButton);
                    iconSpan.addClass('wd-icon');
                    iconSpan.addClass('wd-favorites-icon');
                    iconSpan.addClass('fa-sharp fa-star');

                    if (cellInfo.row.data.isFavorite) {
                        this.setFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.removeFromFavorites'));
                    } else {
                        this.removeFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.addToFavorites'));
                    }

                    iconButton[0].addEventListener('click', async () => {

                        if (!cellInfo.row.data.isFavorite) {
                            this.setFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.removeFromFavorites'));
                        } else {
                            this.removeFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.addToFavorites'));
                        }

                        if (this.onFavoriteClick) {
                            await this.onFavoriteClick(cellInfo.row.data, cellInfo.row.data.data).then(() => {
                                if (cellInfo.row.data.isFavorite) {
                                    this.setFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.removeFromFavorites'));
                                } else {
                                    this.removeFavoriteStyle(iconSpan[0], this.languageProvider.get('framework.favorites.addToFavorites'));
                                }
                            });
                        }
                    });
                },
                dataField: 'isFavorite'
            };

            headerDataResult.push(starIconColumn);
        }

        headerData.forEach((headerElement: HeaderData<T>) => {
            const column = {
                allowFiltering: this.options.isSearchable,
                allowReordering: this.options.columnOrdering,
                calculateDisplayValue: (rowData: DxTableDataViewModel) => {
                    // This function returns the display value field within the data source.
                    if (!headerElement.name) {
                        return '';
                    }
                    const cellData = <CellData>rowData[headerElement.name];
                    return this.calculateCellDisplayValue(cellData);
                },
                calculateSortValue: (rowData: DxTableDataViewModel) => {
                    // This function calculates a custom sort value, which should be used for sorting.
                    if (!headerElement.name) {
                        return '';
                    }

                    const cellData = <CellData>rowData[headerElement.name];
                    if (!cellData) {
                        return undefined;
                    }

                    let rowEntity = WindreamEntity.Document;
                    if (rowData.data && rowData.data.entity) {
                        rowEntity = rowData.data.entity;
                    }

                    let isAscendingSortOrder = true;
                    if (this.tableInstance && this.tableInstance.columnOption(headerElement.name + '.value', 'sortOrder') === 'desc') {
                        isAscendingSortOrder = false;
                    }
                    return this.calculateCustomSortValue(cellData.value, rowEntity, isAscendingSortOrder);
                },
                calculateCellValue: (rowData: DxTableDataViewModel) => {
                    if (headerElement.name && rowData[headerElement.name]) {
                        return rowData[headerElement.name].value;
                    }
                    return undefined;
                },
                caption: headerElement.displayValue,
                cellTemplate: (container: DevExpress.core.dxElement, cellInfo: any) => {
                    // Add icon to first column if showFileType is set
                    const content = this.jQueryStatic('<div>');
                    content.attr('wd-cell-data', '');
                    content.text(cellInfo.displayValue);
                    if (this.options.showFileType && cellInfo.data && cellInfo.data.data) {
                        const iconColumnIndex = startDataColumnIndex;
                        if (cellInfo.columnIndex === iconColumnIndex) {
                            const iconString = this.getFileIcon(cellInfo.data.data);
                            if (iconString) {
                                content.prepend(this.jQueryStatic(iconString));
                            }
                        }
                    }
                    content.appendTo(container);
                },
                dataField: headerElement.name + '.value.' + Utils.Instance.getRandomString(), // Add random part to have unique names
                dataType: Utils.getValueTypeAsString(Utils.Instance.isDefined(headerElement.valueType) ? headerElement.valueType : ValueTypes.String),
                minWidth: 50,
                sortOrder: Utils.Instance.isDefined(headerElement.name) ? this.getSortOrderForColumn(headerElement.name) : undefined,
                sortingMethod: (a, b) => this.cultureHelper.sortHelper.compareStrings(a, b, tempOptionForSorting), // Use our custom sort function
                width: this.calculateColumnWidth(headerElement.width)
            } as DevExpress.ui.dxDataGridColumn;
            headerDataResult.push(column);
        });

        return headerDataResult;
    }


    /**
     * Updates the navigation toolbar item.
     *
     * @protected
     * @param {boolean} disabled The disabled state.
     * @memberof DxMasterTable
     */
    protected updateNavigationToolbarItem(disabled: boolean) {
        // Update the navigation button state
        if (this.navigationToolbarItem) {
            this.navigationToolbarItem.disabled = disabled;
            this.tableInstance?.repaint();
        }
    }


    /**
     * Sets the data source.
     *
     * @protected
     * @param {(DevExpress.data.DataSource | null)} [dataSource] The new data that should be set.
     * @memberof DxMasterTable
     */
    protected setDataSource(dataSource?: DevExpress.data.DataSource | null): void {
        if (dataSource && this.tableInstance) {
            this.tableInstance.option('dataSource', dataSource);
        }
    }

    /**
     * Determines whether only the data (data source) needs to be updated.
     *
     * @protected
     * @param {(DataCollectionData<T> | null)} [data] The new data that should be set.
     * @returns {*}  {boolean} The result that says whether it is sufficient that only the data is updated.
     * @memberof DxMasterTable
     */
    protected onlyUpdateData(data?: DataCollectionData<T> | null): boolean {
        if (!this.currentData || !this.tableInstance || !data) {
            return false;
        }

        // Check whether the header definitions are different.
        if (this.currentData.data && this.currentData.data.header &&
            data.data && data.data.header) {

            // By length
            if (this.currentData.data.header.length !== data.data.header.length) {
                return false;
            }

            // By index/column names
            const headerCount = this.currentData.data.header.length;
            for (let i = 0; i < headerCount; i++) {
                const tempHeader = this.currentData.data.header[i];
                const foundHeader = data.data.header.find((header) => header.name && tempHeader && (header.name === tempHeader.name));
                if (!foundHeader) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Converts the given relative width in percentage into an absolute width in pixels or a DX compatible string.
     *
     * @private
     * @param {number | 'auto' | undefined} relativeWidth Relative width in percentage of table width.
     * @returns {number | string} Absolute width in pixels or a DX compatible string.
     * @memberof DxMasterTable
     */
    private calculateColumnWidth(relativeWidth: number | 'auto' | undefined): number | string | undefined {
        if (relativeWidth === 'auto') {
            return 'auto';
        }
        if (typeof relativeWidth === 'undefined') {
            return undefined;
        }
        const parentElement = this.targetElement.parentElement;
        const tableWidth = parentElement ? parentElement.offsetWidth : this.targetElement.offsetWidth;
        return tableWidth * (relativeWidth / 100);
    }

    /**
     * Renders the dx table into dom.
     *
     * @private
     * @param {DataCollectionData<T>} data The data to render.
     * @memberof DxMasterTable
     */
    private renderDxTable(data: DataCollectionData<T>) {
        // Set sorting before anything otherwise the generates will generate without sorting.
        if (data && data.sorting) {
            this.currentSorting = data.sorting;
        }
        // First, call generateDataSource to populate data with isFavorite attribute
        const dataSource = this.generateDataSource(data.data, this.options.pageSize);

        // Now, call convertToHeaderData to generate header data
        const headerDataResult = this.convertToHeaderData(data.data.header);

        let clickType = MouseClickType.NoClick;
        if (data && data.data) {
            this.currentData = data;
            this.tableInstance = undefined;

            // Initalize devexpress dataGrid.
            this.tableInstance = this.jQueryStatic(this.targetElement).dxDataGrid({
                allowColumnResizing: true,
                columnResizingMode: 'widget',
                columns: headerDataResult,
                dataSource: dataSource,
                editing: {
                    allowUpdating: false,
                    mode: 'cell'
                },
                filterRow: {
                    visible: this.options.isSearchable
                },
                width: '100%',
                height: '100%',
                hoverStateEnabled: true,
                // Disable second loading animation
                loadPanel: {
                    enabled: false
                },
                noDataText: Utils.Instance.escapeStringValue(this.languageProvider.get('framework.dataCollection.noData')),
                onCellClick: (event) => {
                    clickType = MouseClickType.LeftClick;
                    if (event.rowType === 'filter') { // No publishing on filter
                        return;
                    }
                    // Save currently sorted column, in order to access it if the options changed.
                    if (event.rowType === 'header' && event.column) {
                        this.currentlySortedColumn = event.column;
                        return;
                    }
                    this.clickCount++;
                    if (this.clickCount === 1) {
                        this.singleClickTimer = setTimeout(() => {
                            // Single click
                            this.clickCount = 0;
                            // Pubsub
                            if (event && event.data && event.data.data) {
                                this.internalClick([event.data.data], false);
                            }
                            this.isDoubleClick = false;
                        }, this.doubleClickPrevTime);
                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    } else if (this.clickCount === 2) {
                        // Double click
                        clearTimeout(this.singleClickTimer);
                        this.clickCount = 0;
                        // Pubsub
                        if (event && event.data && event.data.data) {
                            this.internalClick(event.data.data, true);
                        }
                        this.isDoubleClick = true;
                    }
                },
                onCellPrepared: (event) => {
                    if (event && event.rowType === 'header' && event.cellElement) {
                        event.cellElement.addClass('wd-datagrid-header');
                        // Always align header to the left
                        // See https://supportcenter.devexpress.com/ticket/details/t604544/datagrid-how-to-change-column-header-text-alignment-and-grid-column-value-alignment
                        event.cellElement.css('text-align', 'left');
                    }
                },
                onContentReady: (event) => {
                    if (this.options && typeof this.options.afterRenderCallback === 'function') {
                        this.options.afterRenderCallback(event.component);
                    }
                },
                onContextMenuPreparing: (event) => {
                    clickType = MouseClickType.RightClick;
                    if (event && event.component) {
                        if (event.row && event.column) { // Click on a row or header
                            if (event.row.rowType === 'header' && event.column.dataField) { // Header
                                this.showHeaderContextMenu(event);
                            } else { // Row
                                if (Utils.Instance.isDefined(event.row.data)) {
                                    const data = event.row.data as any;
                                    if (this.selectedElements && this.selectedElements.length === 0) { // Set only currently clicked item for context menu as seleced
                                        if (Utils.Instance.isDefined(data.data)) {
                                            this.selectedElements = [data.data];
                                            this.showContentContextMenu(event, true);
                                        } else {
                                            this.selectedElements = [];
                                        }
                                    } else {
                                        const tempSelectedItem = data.data;
                                        if (Utils.Instance.isDefined(event.row) && Utils.Instance.isDefined(event.row.data) && Utils.Instance.isDefined(data.data)) {
                                            const isTargetElementSelected = this.selectedElements && this.selectedElements.findIndex((element) => element === tempSelectedItem) !== -1;
                                            if (isTargetElementSelected) { // If item is already selected, open menu
                                                this.showContentContextMenu(event, false);
                                            } else { // Current target is not selected, clear selection
                                                this.clearSelection();
                                                this.selectedElements = [data.data];
                                                this.showContentContextMenu(event, true);
                                            }
                                        }
                                    }
                                }
                            }
                        } else if (event.target === 'content') { // Click on empty space inside listview.
                            this.clearSelection();
                            this.showContentContextMenu(event, false);
                        }
                    }
                },
                onKeyDown: (event) => {
                    if (event && event.event && this.selectedElements) {
                        const keyCode = (event.event as any as KeyboardEvent).key;
                        const crtlKey = (event.event as any as KeyboardEvent).ctrlKey;
                        // Space was hit
                        if (keyCode === ' ') {
                            if (this.selectedElements.length > 0) {
                                const latestClickedIdentity = this.selectedElements[this.selectedElements.length - 1];
                                this.internalClick([latestClickedIdentity], false);
                            }
                        }
                        // Enter / Return was hit
                        if (keyCode && keyCode === 'Enter') {
                            if (this.selectedElements.length > 0) {
                                const latestClickedIdentity = this.selectedElements[this.selectedElements.length - 1];
                                this.internalClick(latestClickedIdentity, true);
                            }
                        }
                        // Prevent navigation via ctrl + arrowUp/arrowDown as it jumps between components
                        if ((keyCode === 'ArrowUp' || keyCode === 'ArrowDown') && crtlKey) {
                            event.event.preventDefault();
                        }
                    }

                },
                onOptionChanged: (event) => {
                    // Check if sorting changed
                    if (event.name === 'columns' && event.fullName && event.fullName.lastIndexOf('sortOrder') > 0 &&
                        typeof (this.options.columnSortingCallback) === 'function' && this.currentlySortedColumn) {
                        if (!Utils.isDefined(this.currentSorting)) {
                            this.currentSorting = {} as ISortingOption<T>;
                        }
                        // Column sorting changed
                        let currentColumn = this.currentlySortedColumn.dataField.slice(0, this.currentlySortedColumn.dataField.lastIndexOf('.value'));
                        if (!Utils.isStringNullOrWhitespace(currentColumn)) {
                            // Filter out semi colon from column name which was added by the the table to identify unique columns.
                            if (currentColumn.includes(';')) {
                                currentColumn = currentColumn.split(';')[0];
                            }
                            // SortOrder will change after this event was handeled, so set it to the opposite
                            this.currentSorting.ascending = event.value === 'asc';
                            this.currentSorting.headerData = {
                                displayValue: this.currentlySortedColumn.caption,
                                name: currentColumn
                            } as HeaderData<T>;
                            this.options.columnSortingCallback(this.currentSorting);
                        }
                    }
                },
                // Drag Drop behaviour
                onRowPrepared: (e) => {
                    if (e.rowElement && e.data && e.data.data) {
                        e.rowElement[0].setAttribute('draggable', 'true');
                        if (typeof e.data.data.id !== 'undefined') {
                            e.rowElement[0].setAttribute('data-wd-row-identity-id', e.data.data.id.toString());
                        }
                    }
                },
                onSelectionChanged: (event) => {
                    this.selectionChangedHandling(event, clickType);
                },
                onToolbarPreparing: (event) => {
                    if (this.options.showParentNavToolbar) {
                        if (event && event.toolbarOptions && event.toolbarOptions.items) {
                            this.navigationToolbarItem = {
                                location: 'before',
                                options: {
                                    disabled: this.disableParentButtonState,
                                    hint: this.languageProvider.get('framework.parentNavigation.buttonHint'),
                                    icon: 'wd-icon small level-up-alt',
                                    onClick: () => {
                                        if (this.options.parentNavigationCallback && typeof (this.options.parentNavigationCallback) === 'function') {
                                            this.options.parentNavigationCallback();
                                        }
                                    },
                                    stylingMode: 'text',
                                    text: this.languageProvider.get('framework.parentNavigation.buttonText'),
                                },
                                widget: 'dxButton'
                            };

                            event.toolbarOptions.items.unshift(this.navigationToolbarItem);
                        }
                    }
                },
                onCellHoverChanged: (event: DevExpress.ui.dxDataGrid.CellHoverChangedEvent<any, any>) => {
                    if (this.onCellHover && event && event.cellElement && event.cellElement.length > 0 && event.column.dataField && event.data && event.data.data) {
                        this.onCellHover(event.cellElement[0], event.data.data, event.column.dataField.split(';')[0]);
                    }
                },
                paging: {
                    pageIndex: 0
                },
                remoteOperations: { paging: true },
                scrolling: {
                    useNative: true
                },
                selection: {
                    // Shows top left checkbox to select all elements
                    allowSelectAll: true,
                    // Allows multiple elements to be selected
                    mode: this.options.multiSelect ? 'multiple' : 'single',
                    // Makes select-all-button work across multiple pages
                    selectAllMode: 'allPages',
                    // Enables and shows checkboxes (once multiple are selected after onClick)
                    showCheckBoxesMode: this.options.showCheckBoxes && this.options.multiSelect ? 'onClick' : 'none'
                },
                showBorders: true
            }).dxDataGrid('instance');
        }

        // Add resize listener to autmatically resize columns
        if (!this.internalResizeListener) {
            const debounceDelay = 100;
            this.internalResizeListener = Utils.Instance.debounce(() => {
                this.repaint();
            }, debounceDelay);
            window.addEventListener('resize', this.internalResizeListener);
        }
    }

    /**
     * Normalize the given data collection in a way we need it inside of the master table.
     *
     * @private
     * @param {DataCollection<T>} data The data to normalize.
     * @returns {DataCollection<T>} The normalized data.
     * @memberof DxMasterTable
     */
    private normalizeData(data: DataCollection<T>): DataCollection<T> {
        // Try to replace all line breaks inside of display value strings.
        if (data && data.rows) {
            data.rows.forEach((row) => {
                if (row && row.cellData) {
                    row.cellData.forEach((cell) => {
                        if (cell && cell.displayValue && typeof (cell.displayValue) === 'string') {
                            cell.displayValue = this.removeLinebreaks(cell.displayValue);
                        }
                    });
                }
            });
        }
        return data;
    }

    /**
     * Removes all line breaks in the given string.
     *
     * @private
     * @param {string} value The original value.
     * @returns {string} The given value without line breaks.
     * @memberof DxMasterTable
     */
    private removeLinebreaks(value: string): string {
        return value.split('\r\n').join(' ');
    }

    /**
     * Convert rowdata to DevExtreme model.
     *
     * @private
     * @param {DataCollection<T>} identityModels Model to convert.
     * @returns {DxTableDataViewModel} DevExtreme model.
     * @memberof DxMasterTable
     */
    private convertToDataSource(identityModels: DataCollection<T>): DxTableDataViewModel[] {

        const row = new Array<DxTableDataViewModel>();
        identityModels.rows.forEach((rowData: RowData<T>, id) => {
            const tempData = new DxTableDataViewModel();
            rowData.cellData.forEach((cellData: CellData, index) => {
                const header = identityModels.header[index].name;
                if (header) {
                    tempData[header] = cellData;
                }
            });

            // Add data
            if (rowData.dataObject) {
                tempData['data'] = rowData.dataObject;
                this.setupContextMenu([rowData.dataObject]);

                // Add webSocketDataModel
                if (rowData.webSocketDataModel) {
                    tempData['webSocketDataModel'] = rowData.webSocketDataModel;
                }
            }
            if (!tempData.id) {
                tempData.id = id;
            }

            tempData['isFavorite'] = !!rowData.isFavorite;
            row.push(tempData);
        });
        return row;
    }

    /**
     * Open contextmenu for header configuration.
     *
     * @private
     * @param {*} event
     * @memberof DxMasterTable
     */
    private showHeaderContextMenu(event: any): void {
        // Do not show dx context menu for filter settings
        event.items = [];
        const data = this.getHeaderDataModelFromElement(event.column.dataField);
        if (Utils.isDefined(data)) {
            if (typeof (data.onContextmenu) === 'function' && event.event) {
                data.onContextmenu(event.event.originalEvent, data);
            }
        }
    }


    /**
     * Show the contextmenu.
     *
     * @private
     * @param {*} event
     * @param {boolean} [isSingleContextMenuClick] Even though multiselct, a single item is selected
     * @memberof DxMasterTable
     */
    private showContentContextMenu(event: any, isSingleContextMenuClick?: boolean): void {
        if (isSingleContextMenuClick) {
            event.component.selectRows(event.row.key, false);
        } else {
            event.component.selectRows(event.component.getSelectedRowKeys(), false);
        }
        const mouseEvent = event.event.originalEvent;
        mouseEvent.preventDefault();
        mouseEvent.type = 'contextmenu';

        let contextMenuElements: T[] | null = null;
        if (this.selectedElements && this.selectedElements.length > 0) {
            contextMenuElements = this.selectedElements;
        } else if (this.currentIdentity) {
            contextMenuElements = [this.currentIdentity] as any as T[];
        }
        if (contextMenuElements && contextMenuElements.length > 0) {
            this.setupContextMenu(contextMenuElements);
            this.getContextMenuInstance(contextMenuElements).then((contextMenu) => {
                if (contextMenuElements) {
                    // TODO: Make this independent from WindreamIdentity
                    const contextMenuTarget = contextMenuElements[0] as any as WindreamIdentity;
                    if (contextMenuTarget) {
                        if (this.contextMenuExtension) {
                            this.contextMenuExtension.setFallbackMenu(contextMenu);
                            this.contextMenuExtension.openMenu(contextMenuTarget.getLocationComplete(), mouseEvent);
                            // @ts-ignore - Ignore because of Foundation usage
                        } else if (window['Foundation'] && window['Foundation']['ContextMenu']) {
                            // @ts-ignore - Ignore because of Foundation usage
                            new window['Foundation']['ContextMenu'](this.jQueryStatic(mouseEvent.target), {
                                accessible: true,
                                emptyEntryWillCloseMenu: false,
                                position: mouseEvent,
                                single: true,
                                structure: contextMenu.getItems()
                            });
                        }
                        if (this.uiManager) {
                            this.uiManager.appIsIdle();
                        }
                    }
                }
            }).catch((err) => {
                throw err;
            });
        }
    }


    /**
     * Return dx compatible sort value depending on defined sort order.
     *
     * @private
     * @param {string} headerElementName
     * @param {HeaderData<T>[]} headerData
     * @returns {(string | undefined)}
     * @memberof DxMasterTable
     */
    private getSortOrderForColumn(headerElementName: string): string | undefined {
        if (this.currentSorting && this.currentSorting.headerData && this.currentSorting.headerData.name === headerElementName) {
            if (this.currentSorting.ascending) {
                return 'asc';
            } else {
                return 'desc';
            }
        } else {
            return undefined;
        }
    }


    /**
     * Calculates a cells display value.
     *
     * @private
     * @param {CellData} cellData
     * @returns {string}
     * @memberof DxMasterTable
     */
    private calculateCellDisplayValue(cellData: CellData): string | number | Date {
        if (!cellData) {
            return '';
        }
        if (Utils.Instance.isDefined(cellData.displayValue)) {
            // If a display value was set, then use it.
            return cellData.displayValue;
        } else if (Utils.Instance.isDefined(cellData.value)) {
            // Calculate the display value.
            return cellData.value;
        }

        return '';
    }

    /**
     * Handles the click.
     *
     * @private
     * @param {(T[] | T)} data The data.
     * @param {boolean} doubleClick Whether it is a double click.
     * @memberof DxMasterTable
     */
    private internalClick(data: T[] | T, doubleClick: boolean): void {
        if (data && this.currentData && this.currentData.data && this.currentData.data.rows && this.currentData.data.rows.length > 0) {

            if (doubleClick) {
                if (typeof this.currentData.data.rows[0].doubleClick === 'function') {
                    this.currentData.data.rows[0].doubleClick(data as any);
                }
            } else {
                if (typeof this.currentData.data.rows[0].onClick === 'function') {
                    this.currentData.data.rows[0].onClick(data as any);
                }
            }
        }
    }

    /**
     * Gets the HeaderDataModel from the template.
     *
     * @private
     * @param {string} value
     * @returns {HeaderData<T>}
     * @memberof DxMasterTable
     */
    private getHeaderDataModelFromElement(value: string): HeaderData<T> {
        if (this.currentData && value) {
            for (const element of this.currentData.data.header) {
                if (value.indexOf('.') > -1) {
                    const fixedWithoutDot = value.substr(0, value.indexOf('.'));
                    if (element.name === fixedWithoutDot) {
                        return element;
                    }
                }
                if (element.name === value) {
                    return element;
                }
            }
        }
        return new HeaderData<T>();
    }
}