/**
 * Configuration of the grid.
 * 
 * @export
 * @class GridConfig
 */
export class GridConfig {
  /**
   * Width of the grid in units.
   * 
   * @type {number}
   * @memberof GridConfig
   */
  public width: number;
  /**
   * Height of the grid in units.
   * 
   * @type {number}
   * @memberof GridConfig
   */
  public height: number;

  /**
   * Creates an instance of GridConfig.
   * @param {number} width Width of the grid.
   * @param {number} height Height of the grid.
   * 
   * @memberof GridConfig
   */
  public constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}