/**
 * Represents mapping of event and callback for PubSub subscriptions.
 * 
 * @export
 * @class PubSubModel
 */
export class PubSubModel {

    /**
     * Concatination of component GUID and parameter name.
     * 
     * @type {string}
     * @memberof PubSubModel
     */
    public name: string;

    /**
     * Callback to perform when this event is published.
     * 
     * 
     * @memberof PubSubModel
     */
    public callback: (data?: Object | null) => void;

    /**
     * Creates an instance of PubSubModel.
     * @param {string} name The name of the event.
     * @param {((data?: Object | null) => void)} callback The callback.
     * @memberof PubSubModel
     */
    public constructor(name: string, callback: (data?: Object | null) => void) {
        this.name = name;
        this.callback = callback;
    }
}