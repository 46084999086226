import { WindreamEntity, WindreamIdentity } from '../common';
import { Logger } from '../logging';
import { IAppDownloadHandler, IWebBridgeHandler, WebBridgeEventTypes } from '../webBridge';


/**
 * Handles download events with the app container using the WebBridge.
 *
 * @export
 * @class AppDownloadHandler
 * @implements {IAppDownloadHandler}
 */
export class AppDownloadHandler implements IAppDownloadHandler {
    private webBridgeHandler: IWebBridgeHandler;
    private logger: Logger;


    /**
     * Creates an instance of AppDownloadHandler.
     *
     * @param {IWebBridgeHandler} webBridgeHandler
     * @param {Logger} logger
     * @memberof AppDownloadHandler
     */
    public constructor(webBridgeHandler: IWebBridgeHandler, logger: Logger) {
        this.webBridgeHandler = webBridgeHandler;
        this.logger = logger;
    }

    /**
     * Downloads an identity into the app.
     *
     * @param {WindreamIdentity} identity Identity to download.
     * @param {boolean} addToBriefcase Whether to add the element to the briefcase.
     * @returns {void}
     * @memberof AppDownloadHandler
     */
    public download(identity: WindreamIdentity, addToBriefcase: boolean): void {
        if (identity.entity === WindreamEntity.Folder && addToBriefcase) {
            this.logger.info('AppDownloadHandler', 'download', 'Cannot add directories to the briefcase', identity);
            return;
        }
        if (addToBriefcase) {
            this.webBridgeHandler.publish(WebBridgeEventTypes.AddToBriefcase, JSON.stringify(identity));
        } else {
            this.webBridgeHandler.publish(WebBridgeEventTypes.Download, JSON.stringify(identity));
        }
    }

    /**
     * Downloads and checks out an identity into the app.
     *
     * @param {WindreamIdentity} identity Identity to download and check out.
     * @param {boolean} addToBriefcase Whether to add the element to the briefcase.
     * @returns {void}
     * @memberof AppDownloadHandler
     */
    public checkOut(identity: WindreamIdentity, addToBriefcase: boolean): void {
        if (identity.entity === WindreamEntity.Folder) {
            this.logger.error('AppDownloadHandler', 'checkOut', 'Cannot check out directories', identity);
            return;
        }
        if (addToBriefcase) {
            this.webBridgeHandler.publish(WebBridgeEventTypes.AddToBriefcaseCheckOut, JSON.stringify(identity));
        } else {
            this.webBridgeHandler.publish(WebBridgeEventTypes.CheckOut, JSON.stringify(identity));
        }
    }

}