import { WebBridgeDataTypes, WebBridgeEventTypes, WebBridgeReponseContainerModel } from './models';

/**
 * This class will construct commands for the WebBridge.
 * 
 * @export
 * @class WebBridgeCommandConstructor
 */
export class WebBridgeCommandConstructor {
    /**
     * The splitted length of the command.
     * 
     * @static
     * @memberof WebBridgeCommandConstructor
     */
    public static readonly commandLength = 3;
    /**
     * Gets the command string.
     * 
     * @static
     * @param {WebBridgeEventTypes} eventType The eventType.
     * @param {WebBridgeDataTypes} dataType The dataType.
     * @param {string} value The value.
     * @returns {string} The command string.
     * @memberof WebBridgeCommandConstructor
     */
    public static getCommandString(eventType: WebBridgeEventTypes, dataType: WebBridgeDataTypes, value: string): string {
        return `${eventType}:${dataType}:${value}`;
    }
    /**
     * Gets the reponse from a onMessage call.
     * 
     * @static
     * @param {string} response The reponse from onMessage
     * @returns {(WebBridgeReponseContainerModel | null)} The converted result or null.
     * @memberof WebBridgeCommandConstructor
     */
    public static getReponseObject(response: string): WebBridgeReponseContainerModel | null {
        try {
            const evenTypeString = response.substring(0, response.indexOf(':'));
            const commandString = response.substring(response.indexOf(':') + 1);
            const valueString = commandString.substring(commandString.indexOf(':') + 1);
            const eventType = Number.parseInt(evenTypeString, 10) as WebBridgeEventTypes;
            const dataType = Number.parseInt(commandString, 10) as WebBridgeDataTypes;
            let value: any;
            switch (dataType) {
                case WebBridgeDataTypes.JSON:
                    value = JSON.parse(valueString);
                    break;
                case WebBridgeDataTypes.string:
                    value = valueString;
                    break;
            }
            return new WebBridgeReponseContainerModel(eventType, dataType, value);
        } catch (err) {
            return null;
        }
    }
}