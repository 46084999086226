import { Clonable, WindreamEntity, WindreamIdentity as IWindreamIdentity} from '../../../typings/core';
import { IdLocationNameEntityIdentityResponseDTO } from '../../typings/windreamWebService/Windream.WebService';


/**
 * With an instance of this class, it's possible to identify an object in windream.
 * This works either with a single ID or with a combination of Name and Location of the object in windream.
 * @access public
 * @version 1.0
 */

export class WindreamIdentity implements Clonable<WindreamIdentity>, IWindreamIdentity  {
    /**
     * The object's identifier (ID).
     * 
     * @type {number}
     * @memberof WindreamIdentity
     */
    public id?: number;


    /**
     * The object's name.
     * 
     * @type {string}
     * @memberof WindreamIdentity
     */
    public name?: string;


    /**
     * The object's location.
     * 
     * @type {string}
     * @memberof WindreamIdentity
     */
    public location?: string;


    /**
     * Gets or sets the entity of the object in windream.
     * 
     * @type {WindreamEntity}
     * @memberof WindreamIdentity
     */
    public entity?: WindreamEntity;


    /**
     * Gets the location complete.
     * 
     * @readonly
     * @type {string}
     * @memberof WindreamIdentity
     */
    public getLocationComplete(): string {
        let result = '';

        if (this.location && this.location !== '' && this.name !== '') {

            if (this.location === '\\') {
                if (this.name === '\\') {
                    result = '\\';
                } else {
                    return this.location + this.name;
                }
            } else {
                if (this.name !== '\\') {
                    result = this.location + '\\' + this.name;
                }
            }

        }

        return result;
    }


    /**
     * Sets the location complete.
     * 
     * 
     * @memberof WindreamIdentity
     */
    public setLocationComplete(value: string) {
        if (!value || value === '') {
            this.location = '';
            this.name = '';
        } else if (value === '\\') {
            this.location = '\\';
            this.name = '\\';
        } else {

            const firstIndexOfSeperator: number = value.indexOf('\\');
            const lastIndexOfSeperator: number = value.lastIndexOf('\\');

            if (firstIndexOfSeperator !== 0 || lastIndexOfSeperator === value.length) {
                // No backslash found or the string does not start with it
                // Or the string ends with a backslash
                this.location = '';
                this.name = '';
            } else if (firstIndexOfSeperator === lastIndexOfSeperator) {
                // Only one backslash was found
                this.location = value.substring(0, firstIndexOfSeperator + 1);
                this.name = value.substring(firstIndexOfSeperator + 1, value.length);
            } else if (firstIndexOfSeperator !== lastIndexOfSeperator) {
                // Multiple backslashes were found
                this.location = value.substring(0, lastIndexOfSeperator);
                this.name = value.substring(lastIndexOfSeperator + 1, value.length);
            }

        }
    }

    /**
     * Returns a clone of this object.
     * 
     * @returns {WindreamIdentity} Cloned object.
     * 
     * @memberof WindreamIdentity
     */
    public clone(): WindreamIdentity {
        const clone = new WindreamIdentity();
        clone.entity = this.entity;
        clone.id = this.id;
        clone.location = this.location;
        clone.name = this.name;
        return clone;
    }

    /**
     * Creates an instance from the given DTO.
     *
     * @static
     * @param {IdLocationNameEntityIdentityResponseDTO} dto DTO to create from.
     * @returns {WindreamIdentity}
     * @memberof WindreamIdentity
     */
    public static fromDto(dto: IdLocationNameEntityIdentityResponseDTO): WindreamIdentity {
        const result = new WindreamIdentity();
        result.id = dto.Id;
        result.entity = dto.Entity as number;
        result.location = dto.Location;
        result.name = dto.Name;
        return result;
    }
}