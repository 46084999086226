
/**
 * View model for the DX table.
 * 
 * @export
 * @class DxTableViewModel
 */
export class DxTableDataViewModel {
    // Dynamic columns.
    [row: string]: any;

}