import { AttributeFlags, DirectoryDetails, DocumentDetails, Utils, WindreamEntity, WindreamIdentity } from '../common';
import { Logger } from '../logging';
import { GetDetailsRequestOptions, IServiceManager } from '../services';
import { ContextMenuCreator } from './contextMenuCreator';
import { IContextMenuDataWebServiceHandler } from './interfaces';
import { ContextMenuEvaluationModel, ContextMenuItemValidator } from '.';

/**
 * Creates context menus based on a windream menu for windream identities.
 * 
 * @export
 * @class WindreamContextMenuCreator
 * @extends {ContextMenuCreator<WindreamIdentity>}
 */
export class WindreamContextMenuCreator extends ContextMenuCreator<WindreamIdentity> {
    private serviceManager: IServiceManager;

    /**
     * Creates an instance of WindreamContextMenuCreator.
     * @param {IContextMenuDataWebServiceHandler} webServiceHandler 
     * @param {ContextMenuItemValidator} contextMenuItemValidator 
     * @param {Logger} logger 
     * @param {IServiceManager} serviceManager 
     * @memberof WindreamContextMenuCreator
     */
    public constructor(webServiceHandler: IContextMenuDataWebServiceHandler, contextMenuItemValidator: ContextMenuItemValidator, logger: Logger, serviceManager: IServiceManager) {
        super(webServiceHandler, contextMenuItemValidator, logger);
        this.serviceManager = serviceManager;
    }

    /**
     * Returns the evaluation model for the given windream identity.
     * 
     * @protected
     * @param {WindreamIdentity[]} data Windream identity to get evaluation model for.
     * @returns {Promise<ContextMenuEvaluationModel | null>} The evaluation model or null in case no context menu can be evaluated for the given data.
     * @memberof WindreamContextMenuCreator
     */
    protected async getEvaluationModel(data: WindreamIdentity[]): Promise<ContextMenuEvaluationModel | null> {
        const columns = ['##ObjectStatus##'];
        const flags = AttributeFlags.Virtual;
        return new Promise<ContextMenuEvaluationModel | null>((resolve, reject) => {
            if (data.length === 1) {
                const identity = data[0];
                const requestOptions = new GetDetailsRequestOptions(identity);
                requestOptions.values = columns;
                requestOptions.attributeFlags = flags;
                if (identity.entity === WindreamEntity.Folder) {
                    this.serviceManager.getServices().Directories.getDetails(requestOptions)
                        .then((data) => {
                            resolve(this.createEvaluationModelFromResponse(data, identity));
                        }).catch((error) => {
                            this.logger.error('ContextMenuCreator', 'getEvaluationModel', 'Unable to get details for directory.', error);
                            reject(new Error('Unable to get details for directory'));
                        });
                } else if (identity.entity === WindreamEntity.Document) {
                    this.serviceManager.getServices().Documents.getDetails(requestOptions)
                        .then((data) => resolve(this.createEvaluationModelFromResponse(data, identity)))
                        .catch(() => {
                            this.logger.error('ContextMenuCreator', 'getEvaluationModel', 'Unable to get details for document.', data);
                            reject(new Error('Unable to get details for document'));
                        });
                } else {
                    // In case the entity is neither a folder nor a document then currently no context menu can be displayed.
                    // This case occurs for example for new DW internal entity "Favorites" (entity = -1)
                    resolve(null);
                }
            } else if (data.length > 1) {
                // Create evaluation model for multiple identities, ignoring flags, etc.
                const contextMenuEvaluation = new ContextMenuEvaluationModel();
                contextMenuEvaluation.entity = Utils.Instance.getIdentityTypes(data);
                contextMenuEvaluation.identityCount = data.length;
                resolve(contextMenuEvaluation);
            }
        });
    }

    /**
     * Creates the evaluation mode fromt he given response.
     * 
     * @private
     * @param {(DirectoryDetails | DocumentDetails)} responseItem WebService response.
     * @param {WindreamIdentity} identity Identity belonging to the response.
     * @returns {ContextMenuEvaluationModel} The evaluation model.
     * @memberof WindreamContextMenuCreator
     */
    private createEvaluationModelFromResponse(responseItem: DirectoryDetails | DocumentDetails, identity: WindreamIdentity): ContextMenuEvaluationModel {
        const contextMenuEvaluation = new ContextMenuEvaluationModel();
        contextMenuEvaluation.entity = identity.entity;
        contextMenuEvaluation.identityCount = 1;
        const attribute = responseItem.attributes.find((attr) => attr.name === '##ObjectStatus##');
        if (attribute) {
            contextMenuEvaluation.documentFlags = typeof attribute.value === 'number' ? attribute.value : undefined;
        } else {
            this.logger.warn('ContextMenuCreator', 'createEvaluationModelFromResponse', 'Attribute ##ObjectStatus## not found.', responseItem);
        }
        return contextMenuEvaluation;
    }
}