/**
 * Options class for notifications.
 *
 * @export
 * @class NotificationOptions
 */
export class NotificationOptions {
    /**
     * Body text of the notificaiton.
     * 
     * @type {string}
     * @memberof NotificationOptions
     */
    public body?: string;

    /**
     * Title of the notificaiton.
     * 
     * @type {(string | null)}
     * @memberof NotificationOptions
     */
    public title?: string | null;
}