import { Component} from 'typings/core';
import { WindreamEntity } from '../../common';

/**
 * Represents an entry in the context menu.
 * 
 * @export
 * @class ContextMenuData
 * @template T 
 */
export class ContextMenuData<T> {
    /**
     * Name of the item.
     * 
     * @type {string}
     * @memberof ContextMenuData
     */
    public name?: string;

    /**
     * Child items.
     * 
     * @type {ContextMenuData<T>[]}
     * @memberof ContextMenuData
     */
    public childs: ContextMenuData<T>[];

    /**
     * Event to perform when the item is clicked.
     * 
     * @param {T[]} data The data.
     * @param {Component} contextMenuSender Refers to the component that ContextMenu belongs to.
     * @memberof ContextMenuData
     */
    public onClick?: (data: T[], contextMenuSender?: Component) => void;

    /**
     * The activation status to associate with the menu item.
     * 
     * @memberof ContextMenuData
     */
    public activationStatus?: number;

    /**
     * Whether all flags should be considered.
     *
     * @type {boolean}
     * @memberof ContextMenuData
     */
    public considerAllFlags?: boolean;

    /**
     * Whether this item is active if the status is set or not.
     *
     * @type {boolean}
     * @memberof ContextMenuData
     */
    public activeIfStatusSet?: boolean;

    /**
     * Whether the item is active on multi selection.
     * 
     * @type {boolean}
     * @memberof ContextMenuData
     */
    public multiSelect?: boolean;

    /**
     * Whether the item is active on single selection.
     * 
     * @type {boolean}
     * @memberof ContextMenuData
     */
    public singleSelect?: boolean;

    /**
     * The entities to enable this item.
     * 
     * @type {WindreamEntity}
     * @memberof ContextMenuData
     */
    public entity?: WindreamEntity;

    /**
     * Creates an instance of ContextMenuData.
     * 
     * @memberof ContextMenuData
     */
    public constructor() {
        this.childs = new Array<ContextMenuData<T>>();
    }
}