import { IMultilingualTranslator } from '../language';
import { IComponent, IUiManager } from '../loader';
import { Logger } from '../logging';
import { ComponentToolbarHandler } from '../toolbar';
import { ComponentLifecycleManager } from './componentLifecycleManager';
import { IComponentLifecycleManager, IComponentLifecycleManagerFactory } from './interfaces';


/**
 * This factory provides instances of IComponentLifecycleManager.
 * 
 * @export
 * @interface IComponentLifecycleManagerFactory
 */
export class ComponentLifecycleManagerFactory implements IComponentLifecycleManagerFactory {
    private logger: Logger;
    private uiManager: IUiManager;
    private translator: IMultilingualTranslator;

    /**
     * Creates an instance of ComponentLifecycleManagerFactory.
     * 
     * @param {Logger} logger Logger instance to pass to the created instances.
     * @param {IUiManager} uiManager UiManager instance  to the created instances.
     * @param {IMultilingualTranslator} translator Translator instance  to the created instances.
     * 
     * @memberof ComponentLifecycleManagerFactory
     */
    public constructor(logger: Logger, uiManager: IUiManager, translator: IMultilingualTranslator) {
        this.logger = logger;
        this.uiManager = uiManager;
        this.translator = translator;
    }

    /**
     * Creates a new instance of IComponentLifecycleManager.
     *
     * @param {IComponent} component The component.
     * @param {ComponentToolbarHandler} componentToolbarHandler The toolbar handler.
     * @returns {IComponentLifecycleManager} The lifecycle manager.
     * @memberof IComponentLifecycleManager
     */
    public create(component: IComponent, componentToolbarHandler: ComponentToolbarHandler): IComponentLifecycleManager {
        return new ComponentLifecycleManager(component, this.logger, this.uiManager, this.translator, componentToolbarHandler);
    }

}