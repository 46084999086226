import { ITooltip } from './interfaces';

/**
 * The default implementation of a tooltip.
 * 
 * @export
 * @class Tooltip
 */
export class Tooltip implements ITooltip {

    /**
     * The JQuery reference.
     *
     * @protected
     * @type {JQueryStatic}
     * @memberof Tooltip
     */
    protected jQueryRef?: JQueryStatic;

    private targetElement: HTMLElement;

    /**
     * Creates an instance of TextBox.
     *
     * @param {HTMLElement} targetElement The target element.
     * @memberof Tooltip
     */
    public constructor(targetElement: HTMLElement) {
        if (!targetElement) {
            throw new ReferenceError('The argument "targetElement" was null or undefined.');
        }
        this.targetElement = targetElement;
    }

    /**
     * Renders the tooltip content.
     *
     * @param {string} content The content.
     * @memberof Tooltip
     */
    public render(content: string): void {

        if (!this.jQueryRef) {
            this.jQueryRef = $;
        }

        const tooltipDiv = document.createElement('div');
        tooltipDiv.innerHTML = content;
        this.targetElement.appendChild(tooltipDiv);

        const showHideDelay = 500;
        this.jQueryRef(tooltipDiv).dxTooltip({
            animation: {
                show: {
                    type: 'fade',
                    from: 0,
                    to: 1,
                    delay: 400
                }
            },
            closeOnOutsideClick: true,
            hideEvent: {
                name: ('ontouchend' in window) ? 'touchend' : 'mouseleave',
                delay: showHideDelay
            },
            showEvent: {
                name: ('ontouchstart' in window) ? 'touchstart' : 'mouseenter',
                delay: showHideDelay
            },
            target: this.targetElement,
            deferRendering: true
        });
    }

}