/**
 * Represents an error coming from one of the services.
 *
 * @export
 * @class ServiceError
 * @extends {Error}
 */
export class ServiceError extends Error {
    /**
     * Error code received.
     *
     * @type {number}
     * @memberof ServiceError
     */
    public errorCode: number;

    /**
     * Creates an instance of ServiceError.
     *
     * @param {string} message
     * @param {number} errorCode
     * @memberof ServiceError
     */
    public constructor(message: string, errorCode: number) {
        super(message);
        this.errorCode = errorCode;
    }

    /**
     * Creates a new ServiceError instance from a generic Error.
     *
     * @static
     * @param {Error} error Generic error to create from.
     * @param {number} errorCode Specific error code.
     * @returns {ServiceError}
     * @memberof ServiceError
     */
    public static fromError(error: Error, errorCode: number): ServiceError {
        return new ServiceError(error.message, errorCode);
    }
}