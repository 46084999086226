import { TokenProvider } from '../authentication';
import { IMetadataStore } from '../caching';
import { GlobalConfig } from '../config';
import { IRequestExecutor } from '../dataProviders/interfaces/iRequestExecutor';
import { IExtensionProvider } from '../extensions';
import { SearchModelConverter } from '../interface/search';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging/logger';
import { IdentityChangedHandler } from '../messages/events';
import { IdentityRightsModelConverter } from '../rights';
import { IPopupHelper } from '../ui';
import { CheckAnnotationsEnvironmentPrerequisites } from './annotations/index';
import { NativeDownloadTrigger } from './base/nativeDownloadTrigger';
import { EnsureFullIdentity } from './common';
import { DownloadDirectory, WorkLockDirectory, UndoWorkLockDirectory, CreateDirectory } from './directories';
import { AddDirectoryHistoryEntry } from './directories/addDirectoryHistoryEntry';
import { Archive as ArchiveDirectory } from './directories/archive';
import { GetDirectoryHistory } from './directories/getDirectoryHistory';
import { DownloadDocument, MoveDocument, WorkLock } from './documents';
import { AddDocumentHistoryEntry } from './documents/addDocumentHistoryEntry';
// Services
import { Archive } from './documents/archive';
import { CheckIn } from './documents/checkIn';
import { CheckOut } from './documents/checkOut';
import { CreateNewVersion } from './documents/createNewVersion';
import { FileSystemSaver } from './documents/fileSystemSaver';
import { GetDocumentHistory } from './documents/getDocumentHistory';
import { GetLifecycleDetails } from './documents/getLifecycleDetails';
import { UndoCheckOut } from './documents/undoCheckOut';
import { UndoWorkLock } from './documents/undoWorkLock';
import { Upload } from './documents/upload';
import {
  GetAllScripts,
  GetAllUserGroups, GetBase64Image, GetContextMenu, GetScriptContent, GetStringContent, GetUseCases, GetViewRights,
  LoadUseCaseConfig, UpdateNavigationSettings
} from './dynamicWorkspace';
import { AddFavorites } from './favorites/addFavorites';
import { GetFavorites } from './favorites/getFavorites';
import { RemoveFavorites } from './favorites/removeFavorites';
import { IServiceManager, IServices } from './interfaces';
import { GetLastModifiedDocuments } from './journal';
import { FullRootlineFetchNodes } from './navigation';
import { Search } from './search/search';
import { ServiceAction } from './serviceAction';
import { CreateSubscription, CreateSubscriptions, GetSubscriptionEvents } from './subscription';
import { GetSystemDetails } from './systemDetails/index';
import {
  CanPerformAction, Copy, GetAllUsers, GetChoiceLists, GetContextMenuScript, GetDocumentDetails, GetDirectoryDetails, GetDirectoryRights,
  GetDocumentRights, GetStorageToken, GetVersions, LoadPubSubEditorConfig, MoveDirectory, MoveDirectoryBulk, MoveDocumentBulk, SaveView,
  UpdateDirectory, UpdateDirectoryRights, UpdateDocument, UpdateDocumentRights, UpdatePubSubEditorConfig, SaveToFileSystem
} from '.';


/**
 * Manages the services provided into a public API.
 * Will initialize earch registered service once created.
 *
 * @export
 * @class ServiceManager
 */
export class ServiceManager implements IServiceManager {
  private requestExecutor: IRequestExecutor;
  private globalConfig: GlobalConfig;
  private serviceMap: Map<string, ServiceAction[]>;
  private services: IServices;
  private logger: Logger;
  private metadataStore: IMetadataStore;
  private popupHelper: IPopupHelper;
  private languageProvider: ILanguageProvider;
  private extensionProvider: IExtensionProvider;
  private tokenProvider?: TokenProvider;
  private identityChangedHandler: IdentityChangedHandler;

  /**
   * Creates an instance of ServiceManager.
   *
   * @param {IRequestExecutor} requestExecutor The request executor.
   * @param {GlobalConfig} globalConfig The global config.
   * @param {Logger} logger The logger.
   * @param {IMetadataStore} metadataStore The metadata store.
   * @param {IPopupHelper} popupHelper The popup helper.
   * @param {ILanguageProvider} languageProvider The language provider.
   * @param {IExtensionProvider} extensionProvider The extension provider.
   * @param {IdentityChangedHandler} identityChangedHandler The identity changed handler.
   * @memberof ServiceManager
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger,
    metadataStore: IMetadataStore, popupHelper: IPopupHelper, languageProvider: ILanguageProvider, extensionProvider: IExtensionProvider,
    identityChangedHandler: IdentityChangedHandler) {

    this.requestExecutor = requestExecutor;
    this.globalConfig = globalConfig;
    this.logger = logger;
    this.metadataStore = metadataStore;
    this.popupHelper = popupHelper;
    this.languageProvider = languageProvider;
    this.extensionProvider = extensionProvider;
    this.identityChangedHandler = identityChangedHandler;

    this.serviceMap = this.createServices();
    this.services = this.generateServices();
  }

  /**
   * Sets the token provider instance.
   * Will re-generate services.
   *
   * @param {TokenProvider} tokenProvider Instance to set.
   * @memberof ServiceManager
   */
  public setTokenProvider(tokenProvider: TokenProvider): void {
    this.tokenProvider = tokenProvider;
    this.serviceMap = this.createServices();
    this.services = this.generateServices();

  }

  /**
   * Gets the currently used token provider from the ServiceManager.
   *
   * @returns {(TokenProvider | undefined)} The tokenprovider.
   * @memberof ServiceManager
   */
  public getTokenProvider(): TokenProvider | undefined {
    return this.tokenProvider;
  }

  /**
   * Returns the current set of services.
   *
   * @returns {Services}
   *
   * @memberof ServiceManager
   */
  public getServices(): IServices {
    return this.services;
  }
  /**
   * Creates the services required to build up the API.
   *
   * @private
   * @returns {Map<string, ServiceAction[]>}
   *
   * @memberof ServiceManager
   */
  private createServices(): Map<string, ServiceAction[]> {
    const serviceMap = new Map<string, ServiceAction[]>();
    serviceMap.set('Annotations', [
      new CheckAnnotationsEnvironmentPrerequisites(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    // Setup required services
    serviceMap.set('Documents', [
      new Archive(this.requestExecutor, this.globalConfig, this.logger),
      new CheckOut(new FileSystemSaver(), this.requestExecutor, this.globalConfig, this.logger),
      new CheckIn(this.requestExecutor, this.globalConfig, this.logger),
      new DownloadDocument(this.requestExecutor, this.globalConfig, this.logger,
        new CanPerformAction(this.requestExecutor, this.globalConfig, this.logger), new FileSystemSaver(), new NativeDownloadTrigger(), this.tokenProvider),
      new WorkLock(new FileSystemSaver(), this.requestExecutor, this.globalConfig, this.logger),
      new CreateNewVersion(this.requestExecutor, this.globalConfig, this.logger),
      new UndoCheckOut(this.requestExecutor, this.globalConfig, this.logger),
      new GetDocumentDetails(this.requestExecutor, this.globalConfig, this.logger),
      new Upload(this.requestExecutor, this.globalConfig, this.logger, this.extensionProvider),
      new GetDocumentHistory(this.requestExecutor, this.globalConfig, this.logger),
      new AddDocumentHistoryEntry(this.requestExecutor, this.globalConfig, this.logger),
      new GetVersions(this.requestExecutor, this.globalConfig, this.logger),
      new GetLifecycleDetails(this.requestExecutor, this.globalConfig, this.logger),
      new Copy(this.requestExecutor, this.globalConfig, this.logger),
      new MoveDocument(this.requestExecutor, this.globalConfig, this.logger, this.identityChangedHandler),
      new MoveDocumentBulk(this.requestExecutor, this.globalConfig, this.logger, this.identityChangedHandler),
      new GetDocumentRights(this.requestExecutor, this.globalConfig, this.logger, new IdentityRightsModelConverter()),
      new UpdateDocumentRights(this.requestExecutor, this.globalConfig, this.logger, new IdentityRightsModelConverter()),
      new UndoWorkLock(this.requestExecutor, this.globalConfig, this.logger),
      new UpdateDocument(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('Directories', [
      new GetDirectoryHistory(this.requestExecutor, this.globalConfig, this.logger),
      new ArchiveDirectory(this.requestExecutor, this.globalConfig, this.logger),
      new AddDirectoryHistoryEntry(this.requestExecutor, this.globalConfig, this.logger),
      new GetDirectoryDetails(this.requestExecutor, this.globalConfig, this.logger),
      new MoveDirectory(this.requestExecutor, this.globalConfig, this.logger, this.identityChangedHandler),
      new MoveDirectoryBulk(this.requestExecutor, this.globalConfig, this.logger, this.identityChangedHandler),
      new GetDirectoryRights(this.requestExecutor, this.globalConfig, this.logger, new IdentityRightsModelConverter()),
      new UpdateDirectoryRights(this.requestExecutor, this.globalConfig, this.logger, new IdentityRightsModelConverter()),
      new UpdateDirectory(this.requestExecutor, this.globalConfig, this.logger),
      new DownloadDirectory(this.requestExecutor, this.globalConfig, this.logger,
        new CanPerformAction(this.requestExecutor, this.globalConfig, this.logger), new FileSystemSaver(), new NativeDownloadTrigger(), this.tokenProvider),
      new WorkLockDirectory(this.requestExecutor, this.globalConfig, this.logger),
      new UndoWorkLockDirectory(this.requestExecutor, this.globalConfig, this.logger),
      new CreateDirectory(this.requestExecutor, this.globalConfig, this.logger, this.languageProvider),
    ]);
    serviceMap.set('FileSystem', [
      new SaveToFileSystem(this.requestExecutor, this.globalConfig, this.logger, new FileSystemSaver())
    ]);
    serviceMap.set('Search', [
      new Search(this.requestExecutor, this.globalConfig, this.logger, new SearchModelConverter(this.logger, this.metadataStore), this.popupHelper, this.languageProvider, this.metadataStore)
    ]);
    serviceMap.set('DynamicWorkspace', [
      new SaveView(this.requestExecutor, this.globalConfig, this.logger),
      new GetBase64Image(this.requestExecutor, this.globalConfig, this.logger),
      new GetStringContent(this.requestExecutor, this.globalConfig, this.logger),
      new LoadUseCaseConfig(this.requestExecutor, this.globalConfig, this.logger),
      new GetUseCases(this.requestExecutor, this.globalConfig, this.logger),
      new LoadPubSubEditorConfig(this.requestExecutor, this.globalConfig, this.logger),
      new UpdatePubSubEditorConfig(this.requestExecutor, this.globalConfig, this.logger),
      new GetContextMenuScript(this.requestExecutor, this.globalConfig, this.logger),
      new GetContextMenu(this.requestExecutor, this.globalConfig, this.logger),
      new UpdateNavigationSettings(this.requestExecutor, this.globalConfig, this.logger),
      new GetAllUsers(this.requestExecutor, this.globalConfig, this.logger),
      new GetChoiceLists(this.requestExecutor, this.globalConfig, this.logger),
      new GetAllUserGroups(this.requestExecutor, this.globalConfig, this.logger),
      new GetViewRights(this.requestExecutor, this.globalConfig, this.logger),
      new GetStorageToken(this.requestExecutor, this.globalConfig, this.logger),
      new GetAllScripts(this.requestExecutor, this.globalConfig, this.logger),
      new GetScriptContent(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('Navigation', [
      new FullRootlineFetchNodes(this.requestExecutor, this.globalConfig, this.logger),
    ]);
    serviceMap.set('Journal', [
      new GetLastModifiedDocuments(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('Common', [
      new EnsureFullIdentity(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('SystemDetails', [
      new GetSystemDetails(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('ChoiceLists', [
      new GetChoiceLists(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('Permissions', [
      new CanPerformAction(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('Subscriptions', [
      new CreateSubscription(this.requestExecutor, this.globalConfig, this.logger),
      new CreateSubscriptions(this.requestExecutor, this.globalConfig, this.logger),
      new GetSubscriptionEvents(this.requestExecutor, this.globalConfig, this.logger)
    ]);
    serviceMap.set('Favorites', [
      new AddFavorites(this.requestExecutor, this.globalConfig, this.logger),
      new GetFavorites(this.requestExecutor, this.globalConfig, this.logger),
      new RemoveFavorites(this.requestExecutor, this.globalConfig, this.logger)
    ]);

    return serviceMap;
  }

  /**
   * Parses the services created by `createServices()` and re
   *
   * @private
   * @returns {Services}
   *
   * @memberof ServiceManager
   */
  private generateServices(): IServices {
    const api = new Object() as any;
    this.serviceMap.forEach((services: ServiceAction[], key: string) => {
      api[key] = new Object();
      services.forEach((service: ServiceAction) => {
        api[key][service.name] = async (...args: any[]) => {
          return service.do(...args);
        };
      });
    });
    return <IServices>api;
  }
}