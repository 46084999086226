/**
 * An enumeration of possible MsgBox types.
 *
 * @export
 * @enum {number}
 */
export enum MsgBoxTypes {
    Undefined,
    /**
     * Red Circle with white cross (error icon)
     */
    Critical,

    /**
     * Speech bubble with question mark (question icon)
     */
    Question,

    /**
     * Yellow warning triangle with exclamation mark (warning)
     */
    Exclamation,

    /**
     * Speech bubble with exclamation mark (notification)
     */
    Information
}