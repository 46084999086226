/**
 * Main file of the Framework.
 * It is responsible for bootstrapping the required components and initializing the Lifecycle Manager.
 * Has multiple exports for static classes.
 * Also exports the public API that will be made available in the global `window` scope in the browser
 * in the `index.ts` file.
 */
import { Application } from './application';
import { PublicApi as PublicApiInterface } from './dynamicWorkspace';
import { CoreProvider, ExternalCoreProvider } from './externalCore';
import { RouteManager } from './router';

const coreProvder: CoreProvider = new ExternalCoreProvider(document);

const app = new Application($, coreProvder);
export const PublicApi: PublicApiInterface = app.generateApi();

app.init().then(() => {
     RouteManager.navigate(window.location.pathname, true);
}).catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
});