import { IRouter } from '.';

/**
 * About router will handle the route for /about.
 *
 * @export
 * @class AboutRouter
 * @implements {IRouter}
 */
export class AboutRouter implements IRouter {

    private onAboutNavigationCallbacks: ((path: string) => void)[];

    /**
     * Creates an instance of AboutRouter.
     * 
     * @memberof AboutRouter
     */
    public constructor() {
        this.onAboutNavigationCallbacks = new Array<() => void>();
    }

    /**
     * Routes the specific route.
     *
     * @param {string} path The url path to route.
     * @returns {boolean} Whether the navigation was a success.
     * @memberof AboutRouter
     */
    public route(path: string): boolean {
        this.onAboutNavigationCallbacks.forEach((callback) => {
            callback(path);
        });
        return true;
    }

    /**
     * Gets the route pattern.
     *
     * @returns {RegExp} The route pattern.
     * @memberof RootRouter
     */
    public getRoutePattern(): RegExp {
        return new RegExp('\/about$|\/about\?.*$', 'i');
    }

    /**
     * Register a callback which will be called if a about navigation occured.
     *
     * @param {(path: string) => void} callback The callback.
     * @memberof AboutRouter
     */
    public registerOnAboutNavigation(callback: (path: string) => void): void {
        this.onAboutNavigationCallbacks.push(callback);
    }
}