import { ResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging/logger';
import { UpdateNavigationSettingsRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform the update of the navigation settings config on the windream webservice.
 * 
 * @export
 * @class UpdateNavigationSettings
 * @extends {ServiceAction}
 */
export class UpdateNavigationSettings extends ServiceAction {

    /**
     * Creates an instance of UpdateNavigationSettings.
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @memberof UpdateNavigationSettings
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'updateNavigationSettings';
    }

    /**
     * Saves the  new view order.
     * Resolves on success.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {UpdateNavigationSettingsRequestOptions} updateNavigationSettingsRequestOptions The request options.
     * @returns {Promise<void>} A promise, which will resolve on success.
     * @memberof UpdateNavigationSettings
     */
    public async do(updateNavigationSettingsRequestOptions: UpdateNavigationSettingsRequestOptions): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {

            if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core && DynamicWorkspace.Extensions.core.viewProvider) {
                try {
                    await DynamicWorkspace.Extensions.core.viewProvider.updateViewsOrder(updateNavigationSettingsRequestOptions.device, updateNavigationSettingsRequestOptions.viewIds);
                    resolve();
                } catch (error) {
                    reject(error);
                }
            } else {
                this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/utils/UpdateNavigationSettings', {
                    activeViewIds: updateNavigationSettingsRequestOptions.viewIds,
                    device: updateNavigationSettingsRequestOptions.device
                }), updateNavigationSettingsRequestOptions.requestOptions).then((response: IServiceResponse<ResponseContainerDTO>) => {
                    if (response.data?.HasErrors) {
                        this.logger.error('UpdateNavigationSettings', 'do', 'Failed to execute request', response);
                        reject(new Error('Failed to update the navigation settings'));
                    } else {
                        resolve();
                    }
                }).catch((err: Error) => {
                    this.logger.error('UpdateNavigationSettings', 'do', 'Failed to execute request', err);
                    reject(err);
                });
            }
        });
    }
}