import { ICacheableResponse } from './interfaces/iCacheableResponse';
import { ServiceResponse } from './serviceResponse';


/**
 * This class represents a cacheable response.
 * 
 * @export
 * @class CacheableResponse
 * @extends {ServiceResponse<T>}
 * @implements {ICacheableResponse<T>}
 * @template T 
 */
export class CacheableResponse<T> extends ServiceResponse<T> implements ICacheableResponse<T> {

    /**
     * Determines whether the resource was not modified in the meantime.
     * 
     * @type {boolean}
     * @memberof CacheableResponse
     */
    public notModified?: boolean;

    /**
     * Determines whether the response should be added to a cache.
     * 
     * @type {boolean}
     * @memberof CacheableResponse
     */
    public shouldBeCached?: boolean;

    /**
     * The resource's entity tag.
     * 
     * @type {string}
     * @memberof ICacheableResponse
     */
    public entityTag?: string;

    /**
     * The time to live in milliseconds.
     * 
     * @type {number}
     * @memberof ICacheableResponse
     */
    public timeToLive?: number;
}