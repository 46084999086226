
import { FavoritesResponse, GetFavoritesRequest, RequestOptions } from '../../../../typings/services';
import { AttributeResponseDTO } from '../../../typings/windreamWebService/Windream.WebService';
import { FavoritesResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { WindreamAttribute } from '../../caching/models/windreamAttribute';
import { WindreamIdentityDetails } from '../../common/webService/windreamIdentityDetails';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { ServiceError } from '../common';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to add favorites for windream.
 *
 * @export
 * @class GetFavorites
 * @extends {ServiceAction}
 */
export class GetFavorites extends ServiceAction {

    /**
     * Creates an instance of GetFavorites.
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global config.
     * @param {Logger} logger The logger.
     * @memberof GetFavorites
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);
        this.name = 'getFavorites';
    }

    /**
     * Gets the favorites folder content.
     *
     * @param {GetFavoritesRequest} favoritesRequest The favorites request.
     * @returns {Promise<void>} A promise, which will resolve with the content of the favorite folders.
     * @memberof GetFavorites
     */
    public async do(favoritesRequest: GetFavoritesRequest): Promise<FavoritesResponse> {
        return new Promise<FavoritesResponse>(async (resolve, reject) => {

            const requestOptions: RequestOptions = {};

            let query = this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/favorites/GetAllFavorites';
            if (favoritesRequest && favoritesRequest.columns && favoritesRequest.columns.length > 0) {
                favoritesRequest.columns.forEach((column, index) => {
                    if (index === 0) {
                        query += '?';
                    } else {
                        query += '&';
                    }
                    query += `parameter.columns=${encodeURIComponent(column)}`;
                });
            }

            const favoritesResponse: IServiceResponse<FavoritesResponseContainerDTO> = await this.requestExecutor.executeRequest(new HttpResourcePointer('GET', query), requestOptions);

            if (favoritesResponse.data) {
                if (favoritesResponse.data.HasErrors) {
                    this.logger.error('GetFavorites', 'do', 'Couldn\'t get favorites', favoritesResponse);
                    reject(new ServiceError('Couldn\'t get favorites', -1));
                    return;
                }

                const result: FavoritesResponse = {
                    identities: []
                };

                favoritesResponse.data.Items.forEach((item) => {
                    const currentIdentity = new WindreamIdentityDetails();
                    currentIdentity.entity = <number>item.Entity;
                    currentIdentity.id = item.Id;
                    currentIdentity.location = item.Location;
                    currentIdentity.name = item.Name;

                    if (item.Attributes) {
                        if (!currentIdentity.attributes) {
                            currentIdentity.attributes = [];
                        }

                        item.Attributes.forEach((attribute) => {
                            const windreamAttribute: WindreamAttribute = this.convertAttribute(<any>attribute);
                            currentIdentity.attributes.push(windreamAttribute);
                        });
                    }

                    result.identities!.push(currentIdentity);
                });

                resolve(result);
            }
        });
    }

    /**
     * Converts the given DTO attribute.
     *
     * @private
     * @param {AttributeResponseDTO} attribute The DTO attribute.
     * @param {number} [objectTypeId] The object type ID.
     * @returns {WindreamAttribute} The converted
     * @memberof GetFavorites
     */
    private convertAttribute(attribute: AttributeResponseDTO, objectTypeId?: number): WindreamAttribute {
        const result = WindreamAttribute.fromDto(attribute);
        result.objectTypeId = typeof objectTypeId === 'number' ? objectTypeId : 0;
        return result;
    }

}