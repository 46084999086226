import { Utils } from '../../common';
import { IConfigMapping, IMappingHandler } from '../interfaces';

/**
 * Mapping handler to apply mapping to an object type configuration.
 * Replaces the ID given with the new ID.
 *
 * @export
 * @class ObjectTypeMappingHandler
 * @implements {IMappingHandler<number, ObjectType>}
 */
export class ObjectTypeMappingHandler implements IMappingHandler<number, number> {
    /**
     * Data types of configurations this handler can map.
     *
     * @memberof ObjectTypeMappingHandler
     */
    private readonly dataTypes = ['DATATYPE_WINDREAM_OBJECTTYPE', 'DATATYPE_WINDREAM_OBJECTTYPE_NULLABLE',
        'DATATYPE_WINDREAM_OBJECTTYPE_DOCUMENT', 'DATATYPE_WINDREAM_OBJECTTYPE_DOCUMENT_NULLABLE',
        'DATATYPE_WINDREAM_OBJECTTYPE_FOLDER', 'DATATYPE_WINDREAM_OBJECTTYPE_FOLDER_NULLABLE'];


    /**
     * Checks whether the mapping for the given configuration data type can be handled.
     *
     * @param {string} configDataType Data type of the configuration to map.
     * @param {string} mappingDataType Data type of the mapping to use.
     * @returns {boolean} Whether the configuration can be mapped by this handler.
     * @memberof SubstitutionMappingHandler
     */
    public canHandleMapping(configDataType: string, mappingDataType: string): boolean {
        return this.dataTypes.indexOf(configDataType) !== -1 && mappingDataType === 'DATATYPE_WINDREAM_OBJECTTYPE';
    }

    /**
     * Applies the mapping to the given input.
     *
     * @param {number} input Configuration value to map (ID of the object type).
     * @param {IConfigMapping<number>} mapping Mapping informaton to use.
     * @returns {number} The transformed value.
     * @memberof ObjectTypeMappingHandler
     */
    public applyMapping(input: number, mapping: IConfigMapping<number>): number {
        if (Utils.Instance.isDefined(mapping.newValue) && Utils.Instance.isDeepEqual(input, mapping.currentValue)) {
            return mapping.newValue;
        }

        return input;
    }
}