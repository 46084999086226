import { ModuleRegistrationType } from 'typings';
import { SystemSettingsSection, SystemSettingsSectionMetadata } from 'typings/core';
import { ILanguageProvider } from '../language';
import { ModuleRegistrationHelper } from '../loader';
import { Activator } from '../loader/activator';
import { ClassReflector } from '../loader/reflector/classReflector';
import { Logger } from '../logging';
import { RouteManager } from '../router';
import { IUiComponentFactory } from './components';
import { IStyleManager } from './interfaces';
/**
 * This class will manage the ui for the system settings.
 *
 * @export
 * @class SystemSettingsUiManager
 */
export class SystemSettingsUiManager {
    private readonly className = 'SystemSettingsUiManager';
    private languageProvider: ILanguageProvider;
    private logger: Logger;
    private targetElement: HTMLElement;
    private currentDocument: Document;
    private componentFactory: IUiComponentFactory;
    private moduleRegistrationHelper: ModuleRegistrationHelper;
    private moduleActivator: Activator;
    private classReflector: ClassReflector<SystemSettingsSection>;
    private systemSettingsContentArea?: HTMLDivElement;
    private systemSettingsPanelArea?: HTMLDivElement;
    private activeSettingsSection?: HTMLDivElement;
    private searchTextboxElement?: HTMLDivElement;
    private backButton?: HTMLButtonElement;
    private viewStyleManager: IStyleManager;

    /**
     * Creates an instance of SystemSettingsUiManager.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {Logger} logger The logger.
     * @param {ModuleRegistrationHelper} moduleRegistrationHelper The module registration helper.
     * @param {HTMLElement} targetElement The target element.
     * @param {IUiComponentFactory} componentFactory The component factory
     * @param {Document} currentDocument The current document.
     * @param {IStyleManager} styleManager The style mangager.
     * @memberof SystemSettingsUiManager
     */
    public constructor(languageProvider: ILanguageProvider, logger: Logger, moduleRegistrationHelper: ModuleRegistrationHelper,
        targetElement: HTMLElement, componentFactory: IUiComponentFactory, currentDocument: Document, styleManager: IStyleManager) {
        this.languageProvider = languageProvider;
        this.logger = logger;
        this.targetElement = targetElement;
        this.currentDocument = currentDocument;
        this.componentFactory = componentFactory;
        this.moduleRegistrationHelper = moduleRegistrationHelper;
        this.moduleActivator = new Activator();
        this.viewStyleManager = styleManager;

        // Initialize the classreflector for the SystemSettingsSection interface
        const interfacePropsMap = new Map<keyof SystemSettingsSection, string>([['getMetadata', 'function'], ['render', 'function'], ['searchText', 'function']]);
        this.classReflector = new ClassReflector<SystemSettingsSection>(undefined, interfacePropsMap);
    }

    /**
     * Render the ui.
     *
     * @memberof SystemSettingsUiManager
     */
    public render(): void {
        this.targetElement.innerHTML = '';
        this.viewStyleManager.updateStyle('wd-style-colors-0');
        const systemSettingsMainContainer = this.currentDocument.createElement('div');
        systemSettingsMainContainer.classList.add('wd-system-settings-main-container');

        const systemSettingsHeaderContainer = this.currentDocument.createElement('div');
        systemSettingsHeaderContainer.classList.add('wd-system-settings-header-container');
        this.createHeaderArea(systemSettingsHeaderContainer);

        this.systemSettingsContentArea = this.currentDocument.createElement('div');
        this.systemSettingsContentArea.classList.add('wd-system-settings-content-area');
        this.createContentArea(this.systemSettingsContentArea);

        systemSettingsMainContainer.appendChild(systemSettingsHeaderContainer);
        systemSettingsMainContainer.appendChild(this.systemSettingsContentArea);
        this.targetElement.appendChild(systemSettingsMainContainer);
    }

    /**
     * Adds the back button to the top bar...
     *
     * @private
     * @param {HTMLDivElement} headerElement The header element.
     * @memberof SystemSettingsUiManager
     */
    private addBackButtonToHeader(headerElement: HTMLDivElement): void {
        if (!headerElement.querySelector('.wd-system-settings-back-button')) {
            this.backButton = this.currentDocument.createElement('button');
            this.backButton.classList.add('button', 'wd-icon-button', 'wd-system-settings-back-button');
            this.backButton.title = this.languageProvider.get('framework.generic.back');
            this.backButton.disabled = true;
            const innerSpan = this.currentDocument.createElement('span');
            innerSpan.classList.add('wd-icon', 'arrow-turn-left');
            this.backButton.appendChild(innerSpan);
            this.backButton.addEventListener('click', () => {
                this.goBackToSettingsOverview();
            });
            // If navigation happens remove the button
            RouteManager.addOnRoutingEventListener((path) => {
                if (this.backButton && !path.includes('/systemsettings')) {
                    headerElement.removeChild(this.backButton);
                    this.backButton = undefined;
                }
            });
            headerElement.appendChild(this.backButton);
        }
    }
    /**
     * Creates the header area.
     *
     * @private
     * @param {HTMLDivElement} headerElement The header element
     * @memberof SystemSettingsUiManager
     */
    private createHeaderArea(headerElement: HTMLDivElement): void {
        const topBarLeftActionZone = this.currentDocument.querySelector<HTMLDivElement>('.wd-app-bar-left-action-zone');
        if (topBarLeftActionZone) {
            this.addBackButtonToHeader(topBarLeftActionZone);
        }
        this.searchTextboxElement = this.currentDocument.createElement('div');
        this.searchTextboxElement.classList.add('wd-system-settings-search-input');
        const textbox = this.componentFactory.textBox(this.searchTextboxElement);
        textbox.setOptions({
            placeholderText: this.languageProvider.get('framework.generic.search')
        });
        textbox.bootstrap();
        textbox.onValueChanged = (value) => {
            if (this.systemSettingsContentArea) {
                if (value) {
                    this.createContentArea(this.systemSettingsContentArea, value);
                } else {
                    this.createContentArea(this.systemSettingsContentArea);
                }
            }
        };
        headerElement.appendChild(this.searchTextboxElement);
    }

    /**
     * Creates the content area.
     *
     * @private
     * @param {HTMLDivElement} contentElement The content element.
     * @param {string} searchTerm Render the content area filtered via a search term.
     * @memberof SystemSettingsUiManager
     */
    private createContentArea(contentElement: HTMLDivElement, searchTerm?: string) {
        contentElement.innerHTML = '';
        const systemSettingsFlexWrapper = this.currentDocument.createElement('div');
        systemSettingsFlexWrapper.classList.add('wd-system-settings-main-flex-wrapper');
        this.systemSettingsPanelArea = this.currentDocument.createElement('div');
        this.systemSettingsPanelArea.classList.add('wd-system-settings-panel-area');
        contentElement.appendChild(this.systemSettingsPanelArea);
        this.systemSettingsPanelArea.appendChild(systemSettingsFlexWrapper);

        const settingSectionsRegistrations = this.moduleRegistrationHelper.getRegistrations(ModuleRegistrationType.SystemSettingsSection);
        settingSectionsRegistrations.forEach((registration) => {
            try {
                const settingSection = this.getSystemSettingsSectionInstance(registration.classReference);
                if (settingSection) {
                    if (searchTerm) {
                        if (!settingSection.searchText(searchTerm)) {
                            return;
                        }
                    }
                    const metadata = settingSection.getMetadata();
                    const systemSettingPanel = this.createSystemSettingsPanel(metadata, settingSection);
                    systemSettingsFlexWrapper.appendChild(systemSettingPanel);
                } else {
                    this.logger.error(this.className, 'createContentArea', 'Failed to load system setting ' + registration.id, 'Invalid type');
                }
            } catch (error) {
                this.logger.error(this.className, 'createContentArea', 'Failed to load system setting ' + registration.id, error);
            }
        });
    }

    /**
     * Create the system settings panel.
     *
     * @private
     * @param {SystemSettingsSectionMetadata} metadata The meta data.
     * @param {SystemSettingsSection} systemSettingsSection The system settings.
     * @returns {HTMLElement} The create HTMLElement.
     * @memberof SystemSettingsUiManager
     */
    private createSystemSettingsPanel(metadata: SystemSettingsSectionMetadata, systemSettingsSection: SystemSettingsSection): HTMLElement {
        const systemSettingsSectionPanel = this.currentDocument.createElement('div');
        systemSettingsSectionPanel.classList.add('wd-system-settings-panel-section');
        systemSettingsSectionPanel.hidden = true;
        if (this.systemSettingsContentArea) {
            this.systemSettingsContentArea.appendChild(systemSettingsSectionPanel);
        } else {
            this.logger.error(this.className, 'createSystemSettingsPanel', 'Failed to find system settings area');
        }
        const systemSettingPanel = this.currentDocument.createElement('div');
        systemSettingPanel.classList.add('wd-system-settings-panel');
        systemSettingPanel.addEventListener('click', () => {
            systemSettingsSectionPanel.hidden = false;
            if (this.activeSettingsSection) {
                this.activeSettingsSection.hidden = true;
            }
            if (this.backButton) {
                this.backButton.disabled = false;
            }
            if (this.searchTextboxElement) {
                this.searchTextboxElement.hidden = true;
            }
            if (this.systemSettingsPanelArea) {
                this.systemSettingsPanelArea.hidden = true;
            }
            this.activeSettingsSection = systemSettingsSectionPanel;
            try {
                systemSettingsSection.render(systemSettingsSectionPanel);
            } catch {
                this.logger.error(this.className, 'createSystemSettingsPanel', 'Failed to render system settings section');
            }
        });
        const imageContainer = this.currentDocument.createElement('div');
        imageContainer.classList.add('wd-system-settings-panel-image-container');
        const image = this.currentDocument.createElement('img');
        image.classList.add('wd-system-settings-panel-image');
        image.src = metadata.image;
        image.title = metadata.header;
        imageContainer.appendChild(image);
        systemSettingPanel.appendChild(imageContainer);

        const header = this.currentDocument.createElement('span');
        header.classList.add('wd-system-settings-panel-header', 'wd-ellipsis');
        header.innerText = metadata.header;
        systemSettingPanel.appendChild(header);

        const description = this.currentDocument.createElement('span');
        description.classList.add('wd-system-settings-panel-description', 'wd-ellipsis');
        description.innerText = metadata.description ? metadata.description : '';
        systemSettingPanel.appendChild(description);

        return systemSettingPanel;
    }

    /**
     * Gets the system settings section instance.
     *
     * @private
     * @param {*} classReference The class reference.
     * @returns {(SystemSettingsSection | undefined)} The created system setting section.
     * @memberof SystemSettingsUiManager
     */
    private getSystemSettingsSectionInstance(classReference: any): SystemSettingsSection | undefined {
        const instance = this.moduleActivator.createInstance(classReference);
        if (instance && this.classReflector.isReflectionOfClass(instance)) {
            return instance as SystemSettingsSection;
        }
        return undefined;
    }

    /**
     * Go back to the settings overview.
     *
     * @private
     * @memberof SystemSettingsUiManager
     */
    private goBackToSettingsOverview() {
        if (this.backButton) {
            this.backButton.disabled = true;
        }
        if (this.activeSettingsSection) {
            this.activeSettingsSection.hidden = true;
        }
        if (this.searchTextboxElement) {
            this.searchTextboxElement.hidden = false;
        }
        if (this.systemSettingsPanelArea) {
            this.systemSettingsPanelArea.hidden = false;
        }
        this.activeSettingsSection = undefined;
    }
}