import { BaseRequestOptions } from './baseRequestOptions';

/**
 * Represents result amount request options.
 *
 * @export
 * @class ResultAmountRequestOptions
 * @extends {BaseRequestOptions}
 */
export class ResultAmountRequestOptions extends BaseRequestOptions {

    /**
     * The max amount of results.
     *
     * @type {number}
     * @memberof ResultAmountRequestOptions
     */
    public maxAmountOfResults: number;

    /**
     * Creates an instance of ResultAmountRequestOptions.
     * @param {number} maxAmountOfResults
     * @memberof ResultAmountRequestOptions
     */
    public constructor(maxAmountOfResults: number) {
        super();
        if (typeof maxAmountOfResults !== 'number') {
            throw new Error('Argument \'maxAmountOfResults\' is not valid');
        }
        this.maxAmountOfResults = maxAmountOfResults;
    }
}