import { ConfigResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler';
import { GlobalConfig, PubSubEditorConfig } from '../../config';
import { HttpResourcePointer, IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging';
import { UpdatePubSubEditorConfigRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform saving of UpdatePubSubEditorConfig on the windream WebsSrvice.
 * 
 * @export
 * @class UpdatePubSubEditorConfig
 * @extends {ServiceAction}
 */
export class UpdatePubSubEditorConfig extends ServiceAction {


    /**
     * Creates an instance of UpdatePubSubEditorConfig.
     * 
     * @param {IRequestExecutor} requestExecutor 
     * @param {GlobalConfig} globalConfig 
     * @param {Logger} logger 
     * @memberof UpdatePubSubEditorConfig
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'updatePubSubEditorConfig';
    }

    /**
     * Saves or updates a PubSubEditorConfig.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {UpdatePubSubEditorConfigRequestOptions} updatePubSubEditorRequestOptions The request options.
     * @returns {Promise<PubSubEditorConfig>} A promise, which will resolve with the server response.
     * @memberof UpdatePubSubEditorConfig
     */
    public async do(updatePubSubEditorRequestOptions: UpdatePubSubEditorConfigRequestOptions): Promise<PubSubEditorConfig> {
        return new Promise<PubSubEditorConfig>(async (resolve, reject) => {

            if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core && DynamicWorkspace.Extensions.core.viewProvider) {
                const pubSubEditorConfig = await DynamicWorkspace.Extensions.core.viewProvider.updatePubSubEditorConfig(updatePubSubEditorRequestOptions.config);
                if (pubSubEditorConfig.data) {
                    resolve(pubSubEditorConfig.data);
                    return;
                } else {
                    this.logger.error('UpdatePubSubEditorConfig', 'do', 'Missing PubSub editor configuration data');
                    reject(new Error('Missing PubSub editor configuration data'));
                    return;
                }
            } else {
                this.requestExecutor.executeRequest(new HttpResourcePointer('POST', this.globalConfig.windreamWebServiceURL + '/dynamicworkspace/views/UpdatePubSubEditorConfig', {
                    ConfigData: updatePubSubEditorRequestOptions.config,
                    Device: updatePubSubEditorRequestOptions.device,
                    ViewID: updatePubSubEditorRequestOptions.viewId
                }), updatePubSubEditorRequestOptions.requestOptions).then((response: IServiceResponse<ConfigResponseContainerDTO<PubSubEditorConfig>>) => {
                    if (response.data?.HasErrors || !response.data) {
                        this.logger.error('UpdatePubSubEditorConfig', 'do', 'Failed to update pubsub editor config', response);
                        reject(new Error('Failed to update pubsub editor config'));
                    } else {
                        resolve(response.data.ConfigData);
                    }
                }).catch((err: Error) => {
                    this.logger.error('UpdatePubSubEditorConfig', 'do', 'Failed to execute request', err);
                    reject(err);
                });
            }
        });
    }
}