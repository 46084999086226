/**
 * This class contains the basic culture handling logic.
 * 
 * @export
 * @abstract
 * @class CultureInfo
 */
export abstract class CultureInfo {
    /**
     * The current culture as string.
     * 
     * @private
     * @type {string}
     * @memberof CultureInfo
     */
    private _currentCulture?: string;
    /**
     * Returns the current cutlure.
     * 
     * @returns {string}    Current culture.
     * 
     * @memberof CultureInfo
     */
    public getCurrentCulture(): string | undefined {
        return this._currentCulture;
    }
    /**
     * Sets the current culture.
     * 
     * @param {string} currentCultureTag    Culture to set.
     * 
     * @memberof CultureInfo
     */
    public setCurrentCulture(currentCultureTag: string) {
        this._currentCulture = currentCultureTag;
    }
}