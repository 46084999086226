/**
 * An enumeration of possible MsgBox buttons.
 *
 * @export
 * @enum {number}
 */
export enum MsgBoxButtons {
    /**
     * OK
     */
    OKOnly,

    /**
     * OK, Cancel
     */
    OKCancel,

    /**
     * Cancel, Retry, Ignore
     */
    AbortRetryIgnore,

    /**
     * Yes, No, Cancel
     */
    YesNoCancel,

    /**
     * Yes, No
     */
    YesNo,

    /**
     * Retry, Cancel
     */
    RetryCancel
}