/**
 * A list of all available search fillers.
 *
 * @export
 * @enum {number}
 */
export enum SEARCH_FILLERS {
    TreatAsDeleted,
    TreatAsNull,
    TreatAsEmpty,
    TreatAsWildcard
  }