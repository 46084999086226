import { EncryptionHelper } from '../encryption';
import { ILanguageProvider } from '../language';
import { Logger } from '../logging';
import { NotificationHelper } from '../ui';
import { IDirectScriptExecutor, IScriptFunction, IScriptFunction2, IScriptFunction3 } from './interfaces';
import { Utils } from '.';

/**
 * Loads and executes scripts.
 * 
 * @export
 * @class DirectScriptExecutor
 * @implements {IDirectScriptExecutor}
 */
export class DirectScriptExecutor implements IDirectScriptExecutor {
    private logger: Logger;
    private windowInstance: Window;
    private encryptionHelper: EncryptionHelper;
    private uuid: string;
    private scriptTagMap: Map<string, HTMLScriptElement>;
    private scriptMap: Map<string, IScriptFunction | IScriptFunction2 | IScriptFunction3>;
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of DirectScriptExecutor.
     * 
     * @param {Logger} logger The logger.
     * @param {Window} windowInstance The windowInstance.
     * @param {ILanguageProvider} languageProvider The languageProvider.
     * @memberof DirectScriptExecutor
     */
    public constructor(logger: Logger, windowInstance: Window, languageProvider: ILanguageProvider) {
        this.logger = logger;
        this.windowInstance = windowInstance;
        this.uuid = `wd-script-${Utils.Instance.getRandomString()}`;
        this.scriptTagMap = new Map<string, HTMLScriptElement>();
        this.scriptMap = new Map<string, IScriptFunction | IScriptFunction2>();
        this.encryptionHelper = new EncryptionHelper();
        this.languageProvider = languageProvider;
    }

    /**
     * Executes the script with the given name.
     * 
     * @param {string} script The script to execute.
     * @param {any[]} [args] Arguments to pass to the script.
     * @param {(Map<string, (result: any) => void> | ((result: any) => void))} [callbacks] [callback] Optional callbacks to pass over. Used when the script executes the callback multiple times.
     * @param {(object)} [options] Options to pass to the script.
     * @returns {Promise<any>} Promise to resolve after the script has been executed and its callback function has been called. Resolves with the result of the script.
     * @memberof DirectScriptExecutor
     */
    public async execute(script: string, args?: any[], callbacks?: Map<string, (result: any) => void> | ((result: any) => void), options?: object): Promise<any> {
        return this.ensureScriptIsLoaded(script)
            .then(async (scriptKey: string) => this.executeScript(scriptKey, args, callbacks, options))
            .catch((err: Error) => {
                this.logger.error('DirectScriptExecutor', 'execute', 'Failed to execute script', err);
                return Promise.reject(err);
            });
    }

    /**
     * Executes the script with the given key.
     * 
     * @private
     * @param {string} scriptKey Key of the script to execute.
     * @param {any[]} [args] Arguments to pass to the script.
     * @param {(Map<string, (result: any) => void> | ((result: any) => void))} [callbacks] [callback] Optional callbacks to pass over. Used when the script executes the callback multiple times.
     * @returns {Promise<any>} Promise to resolve after the script has been executed and its callback function has been called.
     * @memberof DirectScriptExecutor
     */
    private async executeScript(scriptKey: string, args?: any[], callbacks?: Map<string, (result: any) => void> | ((result: any) => void), options?: object): Promise<any> {
        const scriptFunction = this.scriptMap.get(scriptKey);
        if (!scriptFunction) {
            this.logger.error('DirectScriptExecutor', 'executeScript', 'Script is not set', scriptKey);
            return Promise.reject(new Error('Script not found: ' + scriptKey));
        }
        return new Promise<any>((resolve, reject) => {
            try {
                if (callbacks instanceof Map) {
                    const callbackWrapper = (data: any) => {
                        // Trigger first callback when using the callback() syntax
                        const keys = callbacks.keys();
                        const firstKey = keys.next().value;
                        const firstCallback = callbacks.get(firstKey);
                        if (firstCallback) {
                            firstCallback(data);
                        }
                        resolve(data);
                    };

                    const _callbacks = new Map<string, (data: any) => void>();
                    // Wrap callbacks from map so that it also executes resolve
                    callbacks.forEach((callback, key) => {
                        _callbacks.set(key, (data) => {
                            resolve(data);
                            callback(data);
                        });
                    });
                    callbackWrapper.get = (key: string) => _callbacks.get(key);
                    callbackWrapper.has = (key: string) => _callbacks.has(key);
                    callbackWrapper.forEach = (cb: () => void) => _callbacks.forEach(cb);
                    Object.defineProperty(callbackWrapper, 'size', {
                        get: () => _callbacks.size
                    });
                    (scriptFunction as IScriptFunction3)(callbackWrapper, args, options);
                } else if (typeof callbacks === 'function') {
                    (scriptFunction as IScriptFunction3)((data) => {
                        resolve(data);
                        if (callbacks) {
                            callbacks(data);
                        }
                    }, args, options);
                } else {
                    // If no callback was provided just resolve the promise after script is done
                    (scriptFunction as IScriptFunction3)((data) => {
                        resolve(data);
                    }, args, options);
                }
            } catch (error) {
                this.logger.error('DirectScriptExecutor', 'executeScript', 'Script runtime error for function: ' + scriptFunction.name, error);
                NotificationHelper.Instance.error({
                    body: this.languageProvider.getWithFormat('script.errors.runTimeException', scriptFunction.name)
                });
                reject(error);
            }
        });
    }

    /**
     * Makes sure the script with the given name has been loaded and inserted.
     * 
     * @private
     * @param {string} script Script to load.
     * @returns {Promise<string>} Promise to resolve with the script key.
     * @memberof DirectScriptExecutor
     */
    private async ensureScriptIsLoaded(script: string): Promise<string> {
        const scriptKey = this.generateScriptKey(script);
        if (this.scriptMap.has(scriptKey)) { // Resolve immideately if script has already been loaded before
            return Promise.resolve(scriptKey);
        }

        return this.insertScript(script, script);
    }

    /**
     * Inserts the script into the DOM and registers it in the map.
     * 
     * @private
     * @param {string} scriptName Name of the script to register.
     * @param {string} scriptContent Contents of the script.
     * @returns {Promise<string>} Promise to resolve when the script has been loaded.
     * @memberof DirectScriptExecutor
     */
    private async insertScript(scriptName: string, scriptContent: string): Promise<string> {
        const scriptKey = this.generateScriptKey(scriptName);
        const windowScriptKey = `${this.uuid}-${scriptKey}`;
        if (this.scriptMap.has(scriptKey)) { // Resolve immideately if script has already been loaded before
            return Promise.resolve(scriptKey);
        }

        return new Promise<string>((resolve) => {
            const scriptTag = this.windowInstance.document.createElement('script');
            scriptTag.setAttribute('data-wd-script', scriptKey);
            this.scriptTagMap.set(scriptKey, scriptTag);
            const scriptHtml = `
                window['${windowScriptKey}'] = ${scriptContent}
            `;
            scriptTag.innerHTML = scriptHtml;
            scriptTag.onerror = () => {
                this.logger.error('DirectScriptExecutor', 'insertScript', 'Failed to insert script', scriptKey);
            };
            const errorEventListener = () => {
                NotificationHelper.Instance.error({
                    body: this.languageProvider.getWithFormat('script.errors.syntaxError', windowScriptKey)
                });
                this.logger.error('DirectScriptExecutor', 'insertScript', 'Syntax errors in script:', windowScriptKey);
                this.logger.error('DirectScriptExecutor', 'insertScript', 'Please validate that this script is valid JavaScript:',scriptContent);
            };
            this.windowInstance.addEventListener('error', errorEventListener);
            this.windowInstance.document.body.appendChild(scriptTag);
            this.windowInstance.removeEventListener('error', errorEventListener);
            // @ts-ignore - Dynamic window access is required
            const registeredFunction = this.windowInstance[windowScriptKey];
            if (!registeredFunction) {
                this.logger.error('DirectScriptExecutor', 'getScript', 'Failed to register script', windowScriptKey);
            }
            // @ts-ignore - Dynamic window access is required
            this.scriptMap.set(scriptKey, registeredFunction);
            resolve(scriptKey);
        });
    }

    /**
     * Creates a key for the given script name.
     * 
     * @private
     * @param {string} script The script to get the key for..
     * @returns {string} The key for the script.
     * @memberof DirectScriptExecutor
     */
    private generateScriptKey(script: string): string {
        return this.encryptionHelper.sha256(script);
    }
}