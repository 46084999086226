import { SubViewConfigDTO } from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { SubViewConfig } from '../loader';

/**
 * This class provides a factory, which will generate SubViewConfig classes from DTO objects.
 * 
 * @export
 * @class SubViewConfigFactory
 */
export class SubViewConfigFactory {

    /**
     * Converts the given sub view configuration DTO into an internal sub view configuration model.
     * @access public
     * @param {SubViewConfigDTO} subViewConfigDTO The sub view configuration DTO, which should be converted into an internal specifc sub view configuration model.
     * @returns {SubViewConfig}
     */
    public static convertFromDTO(subViewConfigDTO: SubViewConfigDTO): SubViewConfig {

        const result = new SubViewConfig(subViewConfigDTO.Id, subViewConfigDTO.Name);
        result.components = subViewConfigDTO.Components;

        return result;
    }

}