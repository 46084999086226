
import { FileUploadContainer } from '.';


/**
 * Represents a webSocket upload preflight request.
 *
 * @export
 * @class WebSocketUploadPreflightContainer
 */
export class WebSocketUploadPreflightContainer {

    /**
     * The file upload containers.
     *
     * @type {FileUploadContainer[]}
     * @memberof WebSocketUploadPreflightContainer
     */
    public fileUploadContainers: FileUploadContainer[];

    /**
     * Creates an instance of WebSocketUploadPreflightContainer.
     * 
     * @param {FileUploadContainer[]} fileUploadContainers The file upload containers.
     * @memberof WebSocketUploadPreflightContainer
     */
    public constructor(fileUploadContainers: FileUploadContainer[]) {
        this.fileUploadContainers = fileUploadContainers;
    }

}