import { ComponentInstanceConfig } from '../config';
import { IComponent } from '../loader';
import { MigrationObject } from '../migration';

/**
 * Represents a migration component container.
 *
 * @export
 * @class MigrationComponentContainer
 */
export class MigrationComponentContainer {
    private _component: IComponent;
    private _componentInstanceConfig: ComponentInstanceConfig;
    private _migrationObject: MigrationObject | undefined;
    private _toolbarElement: HTMLDivElement | undefined;

    /**
     * Gets the component.
     *
     * @readonly
     * @type {IComponent}
     * @memberof MigrationComponentContainer
     */
    public get component(): IComponent {
        return this._component;
    }

    /**
     * Gets the migration object or undefined if no migration occured.
     *
     * @readonly
     * @type {(MigrationObject | undefined)}
     * @memberof MigrationComponentContainer
     */
    public get migrationObject(): MigrationObject | undefined {
        return this._migrationObject;
    }

    /**
     * Gets the toolbar element.
     *
     * @readonly
     * @type {(HTMLDivElement | undefined)}
     * @memberof MigrationComponentContainer
     */
    public get toolbarElement(): HTMLDivElement | undefined {
        return this._toolbarElement;
    }

    /**
     * Gets the component instance config.
     *
     * @readonly
     * @type {ComponentInstanceConfig}
     * @memberof MigrationComponentContainer
     */
    public get componentInstanceConfig(): ComponentInstanceConfig {
        return this._componentInstanceConfig;
    }

    /**
     * Creates an instance of MigrationComponentContainer.
     * 
     * @param {IComponent} component The component.
     * @param {ComponentInstanceConfig} componentInstanceConfig The component instance config.
     * @param {(HTMLDivElement | undefined)} toolbarElement The toolbar element.
     * @param {MigrationObject} [migrationObject] The migration object.
     * @memberof MigrationComponentContainer
     */
    public constructor(component: IComponent, componentInstanceConfig: ComponentInstanceConfig, toolbarElement: HTMLDivElement | undefined, migrationObject?: MigrationObject) {
        this._component = component;
        this._migrationObject = migrationObject;
        this._toolbarElement = toolbarElement;
        this._componentInstanceConfig = componentInstanceConfig;
    }
}