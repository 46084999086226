import { WindreamIdentity } from '../../common';
import { WindreamRequestOptions } from './windreamRequestOptions';

/**
 * Represents download request options for windream.
 *
 * @export
 * @class DownloadRequestOptions
 * @extends {WindreamRequestOptions}
 */
export class DownloadRequestOptions extends WindreamRequestOptions {

    /**
     * Whether to first download the file and then save it.
     * If set to false (default) it will directly stream the file to the browser and the Promise returned will receive after the download has started.
     * If set to true, the Promise returned will resolve after the download.
     *
     * @type {boolean}
     * @memberof DownloadRequestOptions
     */
    public useBuffer?: boolean;

    /**
     * Creates an instance of DownloadRequestOptions.
     * @param {WindreamIdentity} identity
     * @memberof DownloadRequestOptions
     */
    public constructor(identity: WindreamIdentity) {
        super(identity);

        this.useBuffer = false;
    }
}