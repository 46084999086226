import { EncryptionHelper } from '../encryption';

/**
 * This class will handle the hash for caching.
 *
 * @export
 * @class CacheHashHandler
 */
export class CacheHashHandler {

    private currentDocument: Document;
    private currentWindow: Window;
    private hashString: string = '';
    private currentHash: string = '';
    private encryptionHelper: EncryptionHelper;

    /**
     * Creates an instance of CacheHashHandler.
     * 
     * @param {Window} currentWindow The current window.
     * @param {Document} currentDocument The current document.
     * @param {EncryptionHelper} encryptionHelper The encryption helper.
     * @memberof CacheHashHandler
     */
    public constructor(currentWindow: Window, currentDocument: Document, encryptionHelper: EncryptionHelper) {
        this.currentWindow = currentWindow;
        this.currentDocument = currentDocument;
        this.encryptionHelper = encryptionHelper;
        const metaTag = this.currentDocument.head.querySelector<HTMLMetaElement>('meta[name="revision"]');
        if (metaTag) {
            this.hashString += metaTag.content;
        }
        this.hashify();
    }

    /**
     * Appends the hash to an url.
     *
     * @param {string} url The url.
     * @param {boolean} [onlyAppendIfSameOrigin] Only apply the hash if it's the same origin.
     * @returns {string} The url with the hash.
     * @memberof CacheHashHandler
     */
    public appendHashToUrl(url: string, onlyAppendIfSameOrigin?: boolean): string {
        if (onlyAppendIfSameOrigin) {
            if (!this.isSameOrigin(url)) {
                return url;
            }
        }
        if (url.indexOf('?') > -1) {
            url += '&wdHash=' + this.currentHash;
        } else {
            url += '?wdHash=' + this.currentHash;
        }
        return url;
    }

    /**
     * Expands the hash message with a new message.
     *
     * @param {string} message The message, which should expand the hash message.
     * @memberof CacheHashHandler
     */
    public expandHashingMessage(message: string): void {
        this.hashString += message;
        this.hashify();
    }

    /**
     * Hashify the current hash message.
     *
     * @private
     * @memberof CacheHashHandler
     */
    private hashify(): void {
        this.currentHash = this.encryptionHelper.sha256(this.hashString);
    }

    /**
     * Check if the url is in the same origin.
     *
     * @private
     * @param {string} url The url.
     * @returns {boolean} Whether the url is in the same origin.
     * @memberof CacheHashHandler
     */
    private isSameOrigin(url: string): boolean {
        const location = this.currentWindow.location;
        const dummyLink = this.currentDocument.createElement('a');
        dummyLink.href = url;
        return dummyLink.hostname === location.hostname &&
            dummyLink.port === location.port &&
            dummyLink.protocol === location.protocol;
    }
}