import { DWCore } from '@windream/dw-core/dwCore';
import * as $ from 'jquery';
import {
    ConfigResponseContainerDTO, DeviceFilterDTO, SystemConfigResponseDTO,
    ViewConfigResponseContainerDTO, ViewConfigsResponseContainerDTO
} from '../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { HttpResponse, IServiceResponse } from '../ajaxHandler';
import { DeviceDetection, Utils } from '../common';
import { ComponentLoadManifestFactory, ComponentLoadManifest } from '../components';
import { ConfigDataProvider, HTTP_RESPONSE_CODES, HttpResourcePointer, IRequestExecutor } from '../dataProviders';
import { IMultilingualTranslator } from '../language';
import { Logger } from '../logging';
import { RoutingUtils } from '../router';
import { IConfigLoader } from './interfaces';
import { ApplicationConfig, ComponentConfig, CustomConfig, GlobalConfig, SystemConfig, ToolbarActionMetadata, ToolbarConfig, ViewConfig } from './models';
import {
    ApplicationConfigFactory, ComponentConfigFactory, CustomConfigFactory, GlobalConfigFactory,
    SystemConfigFactory, ToolbarActionMetadataFactory, ToolbarConfigFactory, ViewConfigFactory
} from '.';


/**
 * The ConfigLoader class provides the functionality to get config files from a remote source.
 * @access public
 * @verison 1.0
 */
export class ConfigLoader implements IConfigLoader {

    /**
     * The class name.
     *
     * @static
     *
     * @memberof ConfigLoader
     */
    public static CLASS_NAME = 'ConfigLoader';


    /**
     * Prefix for static views.
     *
     * @static
     * @memberof ConfigLoader
     */
    public static STATIC_VIEW_PREFIX = 'windream.static.';

    /**
     * Prefix for system views.
     *
     * @static
     * @memberof ConfigLoader
     */
    public static SYSTEM_VIEW_PREFIX = '/systemview/';

    /**
     * The currently used global config.
     *
     * @type {GlobalConfig}
     * @memberof ConfigLoader
     */
    public globalConfig?: GlobalConfig;
    public customConfig?: CustomConfig;
    private privateComponentConfigs: Map<string, ComponentConfig>;
    private cachedViewConfigs: Map<string, ViewConfig>;
    private cachedAllViewConfigs?: ViewConfig[];
    private logger: Logger;
    private className: string = 'ConfigLoader';
    private readonly toolbarActionPathBase = './toolbar/actions/';
    private translatorComponentConfigs: Map<string, ComponentConfig>;
    private translator: IMultilingualTranslator;
    private requestExecutor: IRequestExecutor;
    private config?: ViewConfig;
    private readonly errorComponentConfig: ComponentConfig;
    private readonly componentLoadManifestFactory: ComponentLoadManifestFactory;

    /**
     * All internal (framework) components.
     *
     * @private
     * @memberof ConfigLoader
     */
    private internalComponents = [
        'com.windream.configuration',
        'com.windream.pubSub.addComponent',
        'com.windream.componentPanel',
        'com.windream.settingsPanel',
        'com.windream.pubSub.mainframe',
        'com.windream.pubSub.addConnection',
        'com.windream.pubSub.sidePanel',
        'com.windream.profile.settings',
        'com.windream.error',
        'com.windream.colors'
    ];


    /**
     * Creates an instance of ConfigLoader.
     *
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {Logger} logger The logger.
     * @param {IMultilingualTranslator} translator The translator.
     * @param {ComponentLoadManifestFactory} componentLoadManifestFactory The component load-manifest factory.
     * @memberof ConfigLoader
     */
    public constructor(requestExecutor: IRequestExecutor, logger: Logger,
        translator: IMultilingualTranslator, componentLoadManifestFactory: ComponentLoadManifestFactory) {

        this.requestExecutor = requestExecutor;
        this.logger = logger;
        this.privateComponentConfigs = new Map<string, ComponentConfig>();
        this.cachedViewConfigs = new Map<string, ViewConfig>();
        this.logger = logger;
        this.translatorComponentConfigs = new Map<string, ComponentConfig>();
        this.translator = translator;
        this.componentLoadManifestFactory = componentLoadManifestFactory;

        this.errorComponentConfig = {
            name: 'Error component',
            id: 'com.windream.error',
            description: 'Error component displayed if an error occured.',
            version: '7.0.2',
            author: 'dataglobal Bochum GmbH',
            entry: 'js/index.js',
            category: ['internal'],
            tags: [],
            pubSub: {
                in: [],
                out: []
            },
            configuration: [],
            size: {
                defaultWidth: 1,
                defaultHeight: 1
            }
        };
    }

    /**
     * Updates the internal components depending on an external core.
     * 
     * @memberof ConfigLoader
     */
    public updateInternalComponents() : void {
        // If no external core was loaded, then also add the mapping components.
        if (!this.globalConfig?.useExternalCore || !(DynamicWorkspace && DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core)) {
            this.internalComponents.push('com.windream.search.configuration', 'com.windream.mapping.mainframe', 'com.windream.mapping.actions', 'com.windream.mapping.addMappingPanel');
        }
    }

    /**
     * Gets the internal components.
     *
     * @returns {string[]} The internal components.
     * @memberof ConfigLoader
     */
    public getInternalComponents(): string[] {
        return this.internalComponents;
    }


    /**
     * Updates a specific component setting on a cached view.
     *
     * @param {string} viewId The view id.
     * @param {string} componentGuid The component guid.
     * @param {*} componentSetting The component setting.
     * @memberof IConfigLoader
     */
    public updateComponentSetting(viewId: string, componentGuid: string, componentSetting: any): void {
        const currentDevice = DeviceDetection.getCurrentDevice();
        const cacheKey = `${viewId}_${currentDevice.toString()}`;
        const viewConfig = this.cachedViewConfigs.get(cacheKey);
        if (viewConfig) {
            const componentConfig = viewConfig.components.find((component) => component.guid === componentGuid);
            if (componentConfig) {
                componentConfig.configuration = { ...componentConfig.configuration, ...componentSetting };
            }
        }
    }

    /**
     * This function will load a globalConfig.json file and will transpile it into a GlobalConfig class instance.
     *
     * @returns {Promise<GlobalConfig>} Promise to resolve with the fetched GlobalConfig.
     * @async
     *
     * @memberof ConfigLoader
     */
    public async loadGlobalConfig(): Promise<GlobalConfig> {
        return new Promise<GlobalConfig>((resolve, reject) => {
            $.ajax({
                dataType: 'json',
                error: (_err: any, _textStatus: string, errorThrown: string) => {
                    reject(new Error('Unable to load GlobalConfig:' + errorThrown));
                },
                method: 'GET',
                success: (data) => {
                    try {
                        const config: GlobalConfig = GlobalConfigFactory.fromJSON(data);
                        // Handle global config and pre-fetch component configs
                        this.globalConfig = config;

                        // Validate the global configuration
                        this.validateGlobalConfig(this.globalConfig);

                        resolve(config);

                    } catch (err) {
                        reject(err);
                    }
                },
                url: ConfigDataProvider.GLOBAL_CONFIG_URI
            });
        });
    }

    /**
     * Validates the given global configuration.
     *
     * @private
     * @param {GlobalConfig} globalConfig The global configuration.
     * @memberof ConfigLoader
     */
    private validateGlobalConfig(globalConfig: GlobalConfig): void {
        if (!globalConfig) {
            throw new Error('The global configuration is null or undefined.');
        }

        // Look for duplicates inside the components array
        if (globalConfig.availableComponents) {
            const componentDuplicates = globalConfig.availableComponents.filter((item, index) => globalConfig.availableComponents.indexOf(item) !== index);
            if (componentDuplicates && componentDuplicates.length > 0) {
                // Found duplicates
                let components = '';
                componentDuplicates.forEach((component, index) => {
                    components += component;
                    if (index < componentDuplicates.length - 1) {
                        components += ', ';
                    }
                });
                throw new Error('Global configuration: Found duplicates inside the available components: ' + components);
            }
        }

    }

    /**
     *  Gets the view's grid version to know whether to update or not. 
     *
     * @returns {string | null } The grid version.
     * @async
     *
     * @memberof ConfigLoader
     */
    public getGridVersion(): string | null {
        if (this.config) {
            if (this.config.gridVersion) {
                return this.config.gridVersion;
            } else {
                this.config.gridVersion = '2.0';
                return this.config.gridVersion;
            }
        } else {
            return null;
        }
    }

    /**
     * Loads the toolbar config.
     *
     * @returns {Promise<ToolbarConfig>} A promise, which will resolve with the toolbar config.
     * @memberof ConfigLoader
     */
    public async loadToolbarConfig(): Promise<ToolbarConfig> {
        return new Promise<ToolbarConfig>((resolve, reject) => {
            $.ajax({
                dataType: 'json',
                error: (_err: any, _textStatus: string, errorThrown: string) => {
                    reject(new Error('Unable to load ToolbarConfig:' + errorThrown));
                },
                method: 'GET',
                success: (data) => {
                    const config: ToolbarConfig = ToolbarConfigFactory.fromJSON(data);
                    resolve(config);
                },
                url: ConfigDataProvider.TOOLBAR_CONFIG_URI
            });
        });
    }


    /**
     * This function will load a globalConfig.json file and will transpile it into a GlobalConfig class instance.
     *
     * @returns {Promise<GlobalConfig>} Promise to resolve with the fetched GlobalConfig.
     * @async
     *
     * @memberof ConfigLoader
     */
    public async loadCustomConfig(): Promise<CustomConfig> {
        return new Promise<CustomConfig>((resolve, reject) => {
            $.ajax({
                dataType: 'json',
                error: (_err: any, _textStatus: string, errorThrown: string) => {
                    reject(new Error('Unable to load CustomConfig:' + errorThrown));
                },
                method: 'GET',
                success: (data) => {
                    const config: CustomConfig = CustomConfigFactory.fromJSON(data);
                    this.customConfig = config;
                    resolve(config);
                },
                url: ConfigDataProvider.CUSTOM_CONFIG_URI
            });
        });
    }


    /**
     * This function loads the applicationConfig.json file and will transpile it into a ApplicationConfig class instance.
     *
     * @returns {Promise<ApplicationConfig>} Promise to resolve with the fetched ApplicationConfig.
     * @async
     *
     * @memberof ConfigLoader
     */
    public async loadApplicationConfig(): Promise<ApplicationConfig> {
        return new Promise<ApplicationConfig>(async (resolve, reject) => {

            let config: ApplicationConfig;

            const currentDevice = DeviceDetection.getCurrentDevice();

            if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core && DynamicWorkspace.Extensions.core.viewProvider) {

                let coreDevice: DWCore.Common.Devices | undefined;
                switch (currentDevice) {
                    case DWCore.Common.Devices.DESKTOP: { coreDevice = DWCore.Common.Devices.DESKTOP; break; }
                    case DWCore.Common.Devices.TABLET: { coreDevice = DWCore.Common.Devices.TABLET; break; }
                    case DWCore.Common.Devices.PHONE: { coreDevice = DWCore.Common.Devices.PHONE; break; }
                }

                config = new ApplicationConfig();

                const foundViews = await DynamicWorkspace.Extensions.core.viewProvider.getAllViews(DWCore.Views.ViewDetailFlags.ViewMetadata, coreDevice);
                if (foundViews && foundViews.data) {
                    config.activeViews = config.activeViews.concat(foundViews.data);
                }
                resolve(config);
                return;
            }

            if (!this.globalConfig) {
                reject(new Error('No GlobalConfig set'));
                return;
            }

            let remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_LOAD_APP_CONFIG_ACTION;

            // Add device filter
            let deviceFilterDTO = DeviceFilterDTO.Undefined;
            switch (currentDevice) {
                case DWCore.Common.Devices.DESKTOP: { deviceFilterDTO = DeviceFilterDTO.Desktop; break; }
                case DWCore.Common.Devices.TABLET: { deviceFilterDTO = DeviceFilterDTO.Tablet; break; }
                case DWCore.Common.Devices.PHONE: { deviceFilterDTO = DeviceFilterDTO.Phone; break; }
            }

            remoteLocation += `?deviceFilter=${deviceFilterDTO}`;

            const tempResourcePointer = new HttpResourcePointer('GET', remoteLocation);
            tempResourcePointer.scheme = 'config';

            this.requestExecutor.executeRequest(tempResourcePointer).then((response: IServiceResponse<any>) => {
                if (!response || !response.data) {
                    reject(new Error('The response is null or undefined.'));
                } else {
                    try {
                        if (typeof response.data === 'string') {
                            response.data = JSON.parse(response.data);
                        }
                    } catch (err) {
                        this.logger.error(this.className, 'loadApplicationConfig', 'Unable to parse Application config', err);
                        reject(err);
                        return;
                    }
                    if (response.data && response.data.HasErrors) {
                        reject(new Error('Error in webservice call.'));
                        return;
                    }
                    const config: ApplicationConfig = ApplicationConfigFactory.fromJSON(response.data);
                    resolve(config);
                }
            }).catch((err: Error) => {
                this.logger.error(this.className, 'loadApplicationConfig', 'Unable to load Application config', err);
                reject(err);
            });
        });
    }


    /**
     * This function will load a ViewConfig.json file and will transpile it into a ViewConfig class.
     * Global config has to be fetched first via `getGlobalConfig()`.
     * Attention: The ViewConfig can be undefined.
     *
     * @param {string} viewId Id of the view to be loaded.
     * @param {DWCore.Common.Devices} device The device to load the config for.
     * @returns {Promise<ViewConfig>}  Promise to resolve with the fetched ViewConfig.
     * @async
     *
     * @memberof ConfigLoader
     */
    public async loadViewConfig(viewId: string, device: DWCore.Common.Devices): Promise<ViewConfig> {

        // Static views
        if (viewId.startsWith(ConfigLoader.STATIC_VIEW_PREFIX)) {
            const staticViewId = viewId.replace(ConfigLoader.STATIC_VIEW_PREFIX, '');
            let deviceName: string;
            switch (device) {
                case DWCore.Common.Devices.PHONE: deviceName = 'phone'; break;
                case DWCore.Common.Devices.TABLET: deviceName = 'tablet'; break;
                default: deviceName = 'desktop';
            }
            try {
                const config = require(`./staticViews/${deviceName}/${staticViewId}`) as ViewConfig;
                this.logger.debug(this.className, 'loadViewConfig', 'Loading static view: ' + staticViewId, config);
                return Promise.resolve(config);
            } catch (err) {
                this.logger.error(this.className, 'loadViewConfig', 'Failed to load static view: ' + staticViewId, err);
                return Promise.reject(err);
            }
        }

        // System views
        if (RoutingUtils.getCurrentUrl().toLowerCase().includes(ConfigLoader.SYSTEM_VIEW_PREFIX)) {
            let deviceName: string;
            switch (device) {
                case DWCore.Common.Devices.PHONE: deviceName = 'phone'; break;
                case DWCore.Common.Devices.TABLET: deviceName = 'tablet'; break;
                default: deviceName = 'desktop';
            }
            try {
                const config = require(`./systemViews/${deviceName}/${viewId}`) as ViewConfig;
                this.logger.debug(this.className, 'loadViewConfig', 'Loading system view: ' + viewId, config);
                return Promise.resolve(config);
            } catch (err) {
                this.logger.error(this.className, 'loadViewConfig', 'Failed to load system view: ' + viewId, err);
                return Promise.reject(err);
            }
        }

        // View from the external core
        if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core && DynamicWorkspace.Extensions.core.viewProvider) {
            if (viewId && viewId.length > 0 && typeof device === 'number') {
                const foundView = await DynamicWorkspace.Extensions.core.viewProvider.getView(viewId, device, DWCore.Views.ViewDetailFlags.All);
                if (foundView.data) {
                    return Promise.resolve(foundView.data);
                }
            }
        }

        // Views from the web service
        return new Promise<ViewConfig>((resolve, reject) => {
            if (!this.globalConfig) {
                reject(new Error('No GlobalConfig set'));
                return;
            }
            if (viewId && viewId.length > 0 && typeof device === 'number') {
                const cacheKey = `${viewId}_${device.toString()}`;
                if (this.cachedViewConfigs.has(cacheKey)) {
                    const viewConfigClone = Utils.Instance.deepClone(this.cachedViewConfigs.get(cacheKey));
                    if (viewConfigClone) {
                        resolve(viewConfigClone);
                        return;
                    } else {
                        reject(new Error('Can not create a clone of the cached view configuration.'));
                        return;
                    }
                } else {
                    const remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_LOAD_VIEW_CONFIG_PATH;
                    const httpResourcePointer = new HttpResourcePointer('POST', remoteLocation, {
                        Device: device,
                        ViewID: viewId
                    });
                    httpResourcePointer.scheme = 'config';

                    this.requestExecutor.executeRequest(httpResourcePointer)
                        .then((response: IServiceResponse<ViewConfigResponseContainerDTO>) => {
                            if (!response.data) {
                                reject(new Error('The response is null or undefined.'));
                            } else {
                                if (response.data && response.data.HasErrors) {
                                    reject(new Error(`Cannot load config for view ${viewId}.`));
                                    return;
                                }
                                this.config = ViewConfigFactory.convertFromDTO(response.data.ConfigData);
                                this.cachedViewConfigs.set(cacheKey, Utils.Instance.deepClone(this.config));
                                resolve(this.config);
                            }
                        }).catch((err: Error) => {
                            this.logger.error(this.className, 'loadViewConfig', 'Unable to load view config', err);
                            reject(err);
                        });
                }
            } else {
                reject(new Error('Argument `viewId` or `device` is undefined or empty.'));
            }
        });
    }

    /**
     * Updates the view config within the cache.
     *
     * @param {ViewConfig} config Config to update.
     * @param {DWCore.Common.Devices} device Device to update for.
     *
     * @memberof ConfigLoader
     */
    public updateCachedViewConfig(config: ViewConfig, device: DWCore.Common.Devices): void {
        const cacheKey = `${config.id}_${device.toString()}`;
        this.cachedViewConfigs.set(cacheKey, Utils.Instance.deepClone(config));
    }

    /**
     * Loads all view configurations for the current user.
     * Results are cached in memory.
     *
     * @returns {Promise<ViewConfig[]>}    Promise to resolve with a set of available view configurations.
     * @async
     *
     * @memberof ConfigLoader
     */
    public async loadAllViewConfigs(): Promise<ViewConfig[]> {
        return new Promise<ViewConfig[]>((resolve, reject) => {
            if (!this.globalConfig) {
                reject(new Error('No GlobalConfig set'));
                return;
            }
            if (Utils.isDefined(this.cachedAllViewConfigs) && this.cachedAllViewConfigs.length > 0) {
                resolve(this.cachedAllViewConfigs);
            } else {
                const remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_LOAD_ALL_VIEW_CONFIGS_PATH;

                const httpResourcePointer = new HttpResourcePointer('GET', remoteLocation);
                httpResourcePointer.scheme = 'config';

                this.requestExecutor.executeRequest(httpResourcePointer)
                    .then((response: IServiceResponse<ViewConfigsResponseContainerDTO>) => {
                        if (!response.data) {
                            reject(new Error('The response is null or undefined.'));
                        } else {
                            if (response.data && response.data.HasErrors) {
                                reject(new Error('Error in webservice call.'));
                                return;
                            }

                            let configs = new Array<ViewConfig>();
                            if (Utils.isDefined(response.data.ConfigData) && Utils.Instance.isArray(response.data.ConfigData)) {
                                configs = response.data.ConfigData.map((viewConfigDTO) => {
                                    return ViewConfigFactory.convertFromDTO(viewConfigDTO);
                                });
                            }
                            this.cachedAllViewConfigs = configs;
                            resolve(configs);
                        }
                    }).catch((err: Error) => {
                        this.logger.error(this.className, 'loadAllViewConfigs', 'Unable to load all view configs', err);
                    });
            }
        });
    }


    /**
     * Loads a component config.
     *
     * @param {ComponentLoadManifest} componentLoadManifest The component load manifest.
     * @returns {(Promise<ComponentConfig | undefined>)} A promise, which will resolve with a component config or undefined.
     * @memberof ConfigLoader
     */
    public async loadComponentConfig(componentLoadManifest: ComponentLoadManifest): Promise<ComponentConfig | undefined> {
        if (componentLoadManifest) {
            let config: ComponentConfig | undefined = this.privateComponentConfigs.get(componentLoadManifest.componentId);
            if (config) {
                return config;
            }
            try {
                const response = await fetch(componentLoadManifest.componentConfigFileUrl);
                const jsonData = await response.json();
                try {
                    config = ComponentConfigFactory.fromJSON(jsonData);
                    this.translatorComponentConfigs.set(componentLoadManifest.componentId, config);
                    this.translator.setComponentConfigs(this.translatorComponentConfigs);
                    if (this.internalComponents.indexOf(componentLoadManifest.componentId) === -1) {
                        this.privateComponentConfigs.set(componentLoadManifest.componentId, config);
                    }
                } catch (err) {
                    this.logger.error(this.className, 'loadComponentConfig', 'encountered an error for ' + componentLoadManifest.componentConfigFileUrl + ' check the windream-component.json');
                    config = undefined;
                }
            } catch (err) {
                this.logger.error(this.className, 'loadComponentConfig', 'encountered an error for ' + componentLoadManifest.componentConfigFileUrl + ' check the windream-component.json');
                config = undefined;
            }
            return config;
        } else {
            throw new Error('Argument `componentLoadManifest` is undefined.');
        }

    }

    /**
     * Loads the metadata of a toolbar action.
     *
     * @param {string} toolbarActionId The id of the toolbar action.
     * @returns {Promise<ToolbarActionMetadata>} A promise, resolving with the metadata.
     * @memberof ConfigLoader
     */
    public async loadToolbarActionMetadata(toolbarActionId: string): Promise<ToolbarActionMetadata> {
        return new Promise<ToolbarActionMetadata>((resolve, reject) => {
            if (toolbarActionId) {
                const toolbarActionFolderPath = this.toolbarActionPathBase + toolbarActionId + '/';
                const remoteLocation = toolbarActionFolderPath + 'windream-action.json';
                const resourcePointer = new HttpResourcePointer('get', remoteLocation, '');
                resourcePointer.scheme = 'config';
                this.requestExecutor.executeRequest(resourcePointer).then((response: IServiceResponse<ToolbarActionMetadata>) => {
                    try {
                        if (response.data) {
                            const config = ToolbarActionMetadataFactory.fromJSON(response.data);
                            resolve(config);
                        } else {
                            reject(new Error('ToolbarActionMetadata is undefined.'));
                            return;
                        }
                    } catch (err) {
                        this.logger.error(this.className, 'loadToolbarActionMetadata', 'encountered an error for ' + toolbarActionId + ' check the windream-action.json');
                        reject(err);
                        return;
                    }
                }).catch((err) => {
                    this.logger.error(this.className, 'loadToolbarActionMetadata', 'Unable to load toolbar action metadata.', err);
                    reject(err);
                    return;
                });
            } else {
                reject(new Error('ToolbarActionId not set'));
                return;
            }
        });
    }

    /**
     * This function will load a ComponentConfig.json file and will transpile it into a ComponentConfig class.
     * Attention: The ComponentConfig can be undefined.
     * @access public
     * @version 1.0
     * @param {string} componentName The component name (e.g. com.windream.component).
     * @async
     *
     * @returns {Promise<ComponentConfig>}  Promise to resolve with the fetched ComponentConfig.
     * @memberof ConfigLoader
     */
    public async loadFrameworkComponentConfig(componentName: string): Promise<ComponentConfig> {
        return new Promise<ComponentConfig>((resolve, reject) => {

            if (!componentName) {
                reject(new Error('Argument `componentName` is undefined.'));
            } else {

                try {
                    const tempConfig: ComponentConfig = require('../components/' + componentName + '/windream-component.json');
                    if (tempConfig) {
                        resolve(tempConfig);

                    } else {
                        this.logger.error(ConfigLoader.CLASS_NAME, 'loadFrameworkComponentConfig', 'missing component configuration for component: ' + componentName);
                        reject(new Error('missing component information for component: ' + componentName));
                    }

                } catch (err) {
                    this.logger.error(ConfigLoader.CLASS_NAME, 'loadFrameworkComponentConfig', err);
                    reject(err);
                }

            }

        });

    }


    /**
    * Gets all component configurations.
    *
    * @returns {Promise<Map<string, ComponentConfig>>} The component configurations.
    * @memberof ConfigLoader
    */
    public async getAllComponentConfigs(): Promise<Map<string, ComponentConfig>> {
        return new Promise<Map<string, ComponentConfig>>(async (resolve, reject) => {
            let availableComponents: string[] = [];
            if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core && DynamicWorkspace.Extensions.core.componentProvider) {
                availableComponents = await DynamicWorkspace.Extensions.core.componentProvider.getAllComponents();
            } else {
                if (!this.globalConfig) {
                    reject(new Error('No GlobalConfig set'));
                    return;
                }
                availableComponents = this.globalConfig.availableComponents;
            }
            if (this.privateComponentConfigs.size !== availableComponents.length) {
                const componentConfigs = await this.prefetchComponentConfigs(availableComponents);
                resolve(componentConfigs);
            } else {
                resolve(this.privateComponentConfigs);
            }
        });
    }


    /**
     * Returns the Component Config (windream-component.json) for the given component.
     *
     * @param {string} componentId  ID of the component to get the config for.
     * @returns {(ComponentConfig | null)} Configuration of the component, null if not found.
     *
     * @memberof ConfigLoader
     */
    public getComponentConfig(componentId: string): ComponentConfig | null {
        const componentConfig: ComponentConfig | undefined = this.privateComponentConfigs.get(componentId);
        if (componentConfig) {
            return componentConfig;
        } else {
            this.logger.warn(this.className, 'getComponentConfig', 'Unable to find Component Config for component' + componentId);
            return null;
        }
    }

    /**
     * Loads the system config from the WebService.
     *
     * @returns {Promise<SystemConfig | null>} Promise to resolve with the SystemConfig.
     * @memberof ConfigLoader
     */
    public async loadSystemConfig(): Promise<SystemConfig | null> {
        return new Promise<SystemConfig | null>((resolve, reject) => {

            if (DynamicWorkspace.Extensions && DynamicWorkspace.Extensions.core) {
                // TODO: Get a real system config from SaaS environment.
                resolve(new SystemConfig());
            } else {
                if (!this.globalConfig) {
                    reject(new Error('No GlobalConfig set'));
                    return;
                }
                const remoteLocation: string = this.globalConfig.windreamWebServiceURL + ConfigDataProvider.WEBSERVICE_BASE + ConfigDataProvider.WEBSERVICE_LOAD_SYSTEM_CONFIG_PATH;

                const httpResourcePointer = new HttpResourcePointer('GET', remoteLocation);
                httpResourcePointer.scheme = 'config';
                if ('onLine' in navigator && !navigator.onLine) {
                    // Return null if application is used offline
                    resolve(null);
                    return;
                }
                this.requestExecutor.executeRequest(httpResourcePointer)
                    .then((response: IServiceResponse<ConfigResponseContainerDTO<SystemConfigResponseDTO>>) => {
                        if (!response.data) {
                            this.logger.error(this.className, 'loadSystemConfig', 'Unable to load all view configs (response null or undefined)', response.data);
                            reject(new Error('The response is null or undefined.'));
                        } else {
                            if (response.data && response.data.HasErrors) {
                                this.logger.error(this.className, 'loadSystemConfig', 'Unable to load all view configs (error in WebService call)', response.data);
                                reject(new Error('Error in webservice call.'));
                                return;
                            }
                            const config = SystemConfigFactory.convertFromDTO(response.data.ConfigData);
                            resolve(config);
                        }
                    }).catch((err: HttpResponse<any>) => {
                        if (err.statusCode === HTTP_RESPONSE_CODES.INTERNAL_SERVER_ERROR) {
                            this.logger.error(this.className, 'loadSystemConfig', 'Unable to load SystemConfig', err);
                            reject(new Error('Unable to load SystemConfig'));
                        } else { // Server not reachable
                            resolve(null);
                        }
                    });

            }

        });
    }

    /**
     * Prefetches the component configuration of the desired components.
     *
     * @param {string[]} components All components to prefetch.
     * @returns {Promise<Map<string, ComponentConfig>>} All component configurations.
     * @memberof ConfigLoader
     */
    private async prefetchComponentConfigs(components: string[]): Promise<Map<string, ComponentConfig>> {
        return new Promise<Map<string, ComponentConfig>>((resolve, reject) => {
            components.forEach(async (componentId) => {
                const loadComponentManifest = await this.componentLoadManifestFactory.generateLoadManifestForId(componentId);
                this.loadComponentConfig(loadComponentManifest)
                    .then((componentConfig) => {
                        if (!componentConfig) {
                            this.logger.error(this.className, 'loadSystemConfig', 'Unable to load componentConfig for ' + componentId);
                            componentConfig = this.errorComponentConfig;
                        }
                        if (this.internalComponents.indexOf(componentId) === -1) {
                            this.privateComponentConfigs.set(componentId, componentConfig);
                        }

                        // Executes all queued callbacks if last config has been fetched
                        if (this.privateComponentConfigs.size === components.length) {
                            resolve(this.privateComponentConfigs);
                        }
                    }).catch((error) => reject(error));
            });
        });
    }
}