import { ILanguageProvider, ILoaderExtension, IUiComponentFactory, Style, Utils } from '../dynamicWorkspace';
import { IPubSubHandlerFactory } from '../pubSub';
import { WindreamPreviewPopupOptions } from './models';

/**
 * Provides a windream preview popup.
 *
 * @export
 * @class WindreamPreviewPopup
 */
export class WindreamPreviewPopup {
    private currentDocument: Document;
    private languageProvider: ILanguageProvider;
    private jQuery: JQueryStatic;
    private loaderExtension: ILoaderExtension;
    private foundationLib: any;
    private uiComponentFactory: IUiComponentFactory;
    private pubSubHandlerFactory: IPubSubHandlerFactory;

    /**
     * Creates an instance of WindreamPreviewPopup.
     * 
     * @param {Document} currentDocument The current document.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {JQueryStatic} jQuery JQuery.
     * @param {ILoaderExtension} loaderExtension The loader extension.
     * @param {*} foundationLib The foundation library.
     * @param {IUiComponentFactory} uiComponentFactory The ui component factory.
     * @param {IPubSubHandlerFactory} pubSubHandlerFactory The pubsub handler factory.
     * @memberof WindreamPreviewPopup
     */
    public constructor(currentDocument: Document, languageProvider: ILanguageProvider, jQuery: JQueryStatic,
        loaderExtension: ILoaderExtension, foundationLib: any,
        uiComponentFactory: IUiComponentFactory, pubSubHandlerFactory: IPubSubHandlerFactory) {
        this.currentDocument = currentDocument;
        this.languageProvider = languageProvider;
        this.jQuery = jQuery;
        this.loaderExtension = loaderExtension;
        this.foundationLib = foundationLib;
        this.uiComponentFactory = uiComponentFactory;
        this.pubSubHandlerFactory = pubSubHandlerFactory;
    }

    /**
     * Render the popup.
     *
     * @param {WindreamPreviewPopupOptions} options The popup options.
     * @returns {Promise<void>} A promise, which will resolve if the popup was closed.
     * @memberof WindreamPreviewPopup
     */
    public render(options: WindreamPreviewPopupOptions): Promise<void> {
        const modalHtml = this.currentDocument.createElement('div');
        modalHtml.classList.add('reveal', 'large', 'wd-modal');
        const modalContainer = this.currentDocument.createElement('div');
        modalContainer.classList.add('wd-modal-container');
        const modalContainerHeading = this.currentDocument.createElement('div');
        modalContainerHeading.classList.add('wd-modal-heading-container');

        const modalHeading: HTMLDivElement = this.currentDocument.createElement('div');
        modalHeading.classList.add('wd-modal-heading');
        const modalTitle = options.title ? options.title : this.languageProvider.get('popups.windreamPreview.title');
        modalHeading.innerHTML = `<h2>${Utils.Instance.escapeStringValue(modalTitle)}</h2>`;
        modalContainerHeading.appendChild(modalHeading);

        // Close button
        const modalCloseButton = this.currentDocument.createElement('button');
        modalCloseButton.innerHTML = '<span aria-hidden="true">&times;</span>';
        modalCloseButton.setAttribute('title', this.languageProvider.get('framework.generic.close'));
        modalCloseButton.setAttribute('data-close', '');
        modalCloseButton.type = 'button';
        modalCloseButton.classList.add('close-button');
        modalContainerHeading.appendChild(modalCloseButton);
        modalContainer.appendChild(modalContainerHeading);

        const modalContent = this.currentDocument.createElement('div');
        modalContent.classList.add('wd-modal-content');
        modalContainer.appendChild(modalContent);
        const windreamPreviewComponentId = 'wd-popup-windream-preview-' + Utils.Instance.getRandomString();

        const previewContent = this.currentDocument.createElement('div');
        previewContent.id = windreamPreviewComponentId;
        previewContent.style.height = 'calc(80vh - 3.2rem)';
        previewContent.style.overflowY = 'auto';
        modalContent.appendChild(previewContent);

        modalHtml.appendChild(modalContainer);

        const modalFooter = this.currentDocument.createElement('div');
        modalFooter.classList.add('wd-modal-footer');
        const modalFooterButtons = this.currentDocument.createElement('div');
        modalFooterButtons.classList.add('wd-button-group');
        modalFooter.appendChild(modalFooterButtons);

        modalHtml.appendChild(modalFooter);
        this.currentDocument.body.appendChild(modalHtml);

        return this.initializeUi(options, '#' + windreamPreviewComponentId,
            modalFooterButtons, modalHtml);
    }

    /**
     * Initialize the popup ui.
     *
     * @private
     * @param {WindreamPreviewPopupOptions} options The popup options.
     * @param {string} previewPosition The preview position.
     * @param {HTMLDivElement} footerButtonContainer The footer button container.
     * @param {HTMLDivElement} modalHtml The modal html container.
     * @returns {Promise<void>} A promise, which will resolve if the popup was closed.
     * @memberof WindreamPreviewPopup
     */
    private initializeUi(options: WindreamPreviewPopupOptions, previewPosition: string,
        footerButtonContainer: HTMLDivElement, modalHtml: HTMLDivElement): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let modal: any;
            const previewGuid = 'POPUP_WINDREAM_PREVIEW';

            const okButton = this.currentDocument.createElement('div');
            const dxOkButton = this.uiComponentFactory.button(okButton);
            dxOkButton.bootstrap();
            dxOkButton.setOptions({
                text: this.languageProvider.get('framework.generic.ok'),
                isDisabled: false, stylingMode: 'text'
            });
            dxOkButton.onClick = () => {
                if (modal) {
                    modal.close();
                    modal.destroy();
                    modalHtml.remove();
                }
                resolve();
            };
            footerButtonContainer.appendChild(okButton);
            const pubSub = this.pubSubHandlerFactory.create();
            pubSub.loadPubSub([{
                in: [{
                    componentGuid: previewGuid,
                    parameter: 'PreviewDocument'
                }],
                out: [{
                    componentGuid: 'Internal',
                    parameter: 'PreviewDocument'
                }]
            }]);
            this.loaderExtension.loadComponent({
                component: 'com.windream.preview',
                configuration: {},
                guid: previewGuid,
                isTitleVisible: false,
                name: { en: 'com.windream.preview' },
                position: previewPosition,
                style: Style.default()
            }, pubSub).then(() => {
                const previewContent = modalHtml.querySelector<HTMLDivElement>('[data-guid=POPUP_WINDREAM_PREVIEW]');
                if (previewContent) {
                    previewContent.style.height = 'calc(80vh - 3.2rem)';
                    const previewArea = previewContent.getElementsByClassName('wd-apryse-preview-area')[0] as HTMLElement;
                    if(previewArea) {
                        previewArea.style.height = 'calc(80vh - 3.2rem)';
                    }
                }
                modal = new this.foundationLib.Reveal(this.jQuery(modalHtml), {
                    animationOut: 'scale-out-down',
                    closeOnClick: false,
                    closeOnEsc: false
                });
                // Destroy modal on close
                modal.$element.on('closed.zf.reveal', () => {
                    modal.destroy();
                    modalHtml.remove();
                    resolve();
                });
                modal.$element.on('open.zf.reveal', () => {
                    pubSub.publish('Internal', 'PreviewDocument', [options.windreamFile]);
                });
                modal.open();
            }).catch((error) => reject(error));
        });
    }
}