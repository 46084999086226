// TODO: Change import once usage of old treeview has been changed to new one
import { DynamicMasterTreeView as IDynamicMasterTreeView, TreeItem } from '../../../typings/ui';
import { MasterTreeView } from './masterTreeView';


/**
 * Implementation of the master Tree View that is used within the Dynamic Tree View component.
 *
 * @export
 * @class DynamicMasterTreeView
 * @extends {MasterTreeView<T>}
 * @template Datatype of the data each node represents.
 */
export class DynamicMasterTreeView<T> extends MasterTreeView<T> implements IDynamicMasterTreeView<T> {

  /**
   * The tree was clicked.
   * 
   * 
   * @memberof DynamicMasterTreeView
   */
  public onClick?: (event: MouseEvent, treeItem: TreeItem<T>) => void;

  /**
   * The tree collapsed.
   * 
   * 
   * @memberof DynamicMasterTreeView
   */
  public onCollapse?: (event: MouseEvent, treeItem: TreeItem<T>) => void;

  /**
   * The tree expanded.
   * 
   * 
   * @memberof DynamicMasterTreeView
   */
  public onExpand?: (event: MouseEvent, treeItem: TreeItem<T>) => void;

  /**
   * The tree was double clicked.
   * 
   * 
   * @memberof DynamicMasterTreeView
   */
  public onDblClick?: (event: MouseEvent, treeItem: TreeItem<T>) => void;

  /**
   * Function to execute when a node is clicked.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was clicked.
   *
   * @memberof DynamicMasterTreeView
   */
  protected click(event: MouseEvent, treeItem: TreeItem<T>): void {
    if (this.onClick) {
      this.onClick(event, treeItem);
    }
  }

  /**
   * Function to execute when a node is collapsed.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was collapsed.
   *
   * @memberof DynamicMasterTreeView
   */
  protected collapse(event: MouseEvent, treeItem: TreeItem<T>): void {
    if (this.onCollapse) {
      this.onCollapse(event, treeItem);
    }
  }

  /**
   * Function to execute when a node is expanded.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was expanded.
   *
   * @memberof DynamicMasterTreeView
   */
  protected expand(event: MouseEvent, treeItem: TreeItem<T>): void {
    if (this.onExpand) {
      this.onExpand(event, treeItem);
    }
  }

  /**
   * Function to execute when a node is double clicked.
   *
   * @protected
   * @abstract
   * @param {MouseEvent} event Mouse event that has been triggered.
   * @param {TreeItem<T>} treeItem Item that was double clicked.
   *
   * @memberof DynamicMasterTreeView
   */
  protected dblClick(event: MouseEvent, treeItem: TreeItem<T>): void {
    if (this.onDblClick) {
      this.onDblClick(event, treeItem);
    }
  }
}