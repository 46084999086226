import { DWCore } from '@windream/dw-core/dwCore';
import { Position } from '.';

/**
 * This class is a data holder for components within the PubSub editor.
 * 
 * @export
 * @class PubSubEditorComponentConfig
 */
export class PubSubEditorComponentConfig implements DWCore.PubSub.PubSubEditorComponentConfig {

    /**
     * The component guid.
     * 
     * @type {string}
     * @memberof PubSubEditorComponentConfig
     */
    public guid?: string;

    /**
     * The position of this component.
     * 
     * @type {(Position)}
     * @memberof PubSubEditorComponentConfig
     */
    public position?: Position;

}