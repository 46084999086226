import { Utils } from '../common';
import { FormatHelper } from './formatHelper';
import { NumberFormatOptions } from '.';


/**
 * The number format helper provides functionality to transform numbers into a string representation.
 *
 * @export
 * @class NumberFormatHelper
 */
export class NumberFormatHelper extends FormatHelper {

    /**
     * Returns a formatted string representing the given number.
     *
     * @param {number} value
     * @param {NumberFormatOptions} numberFormatOptions
     * @returns {string}
     * @memberof NumberFormatHelper
     */
    public format(value: number, numberFormatOptions: NumberFormatOptions): string {

        if (!Utils.isDefined(value) || isNaN(value)) {
            return '';
        } else if (!numberFormatOptions) {
            throw new ReferenceError('The argument "numberFormatOptions" is null or undefined.');
        }

        // Check if the ECMAScript internationalization API (Intl) can be used.
        if (!Intl) {
            return value.toString();
        } else {

            // Define the number of decimals to use.
            let numberOfDecimals = 0;
            if (Utils.isDefined(numberFormatOptions.decimals)) {
                numberOfDecimals = numberFormatOptions.decimals;
            } else {
                numberOfDecimals = this.getNumberOfDecimals(value);
            }

            const intlNumberFormatOptions: Intl.NumberFormatOptions = {
                style: 'decimal',
                minimumFractionDigits: numberOfDecimals,
                maximumFractionDigits: numberOfDecimals
            };

            // Check whether a currency value should be formatted.
            if (Utils.isDefined(numberFormatOptions.currencyCode)) {
                intlNumberFormatOptions.style = 'currency';
                // @ts-ignore - The typings don't know currencyDisplay for the options...
                intlNumberFormatOptions.currencyDisplay = 'symbol';
                intlNumberFormatOptions.currency = numberFormatOptions.currencyCode;
            }

            // Set whether the thousandsseperator should be used.
            intlNumberFormatOptions.useGrouping = numberFormatOptions.withThousandsSeperator;

            const currentCulture = this.getCurrentCulture();
            const intlFormatter = new Intl.NumberFormat(currentCulture, intlNumberFormatOptions);
            return intlFormatter.format(value);
        }
    }


    /**
     * Gets the number of decimals.
     *
     * @param {number} value
     * @returns {number}
     * @memberof NumberFormatHelper
     */
    public getNumberOfDecimals(value: number): number {

        if (Math.floor(value) === value) {
            return 0;
        } else {
            try {
                return value.toString().split('.')[1].length || 0;
            } catch {
                return 0;
            }
        }
    }

    /**
     * Return the deciaml seperator.
     *
     * @returns {string}
     * @memberof NumberFormatHelper
     */
    public getDecimalSeparator(): string {
        const numberWithDecimalSeparator = 1.1;
        const currentCulture = this.getCurrentCulture();
         // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return numberWithDecimalSeparator.toLocaleString(currentCulture).substring(1, 2);
    }

    /**
     * Returns a format string for the fixed point format.
     *
     * @param {number} preDigits Nax number of digits before decimal separator.
     * @param {number} postDigits Nax number of digits after decimal separator.
     * @param {boolean} [useThousandsSeparator=false] Whether to use a thousands separator.
     * @param {boolean} [forceDigitDisplay=false] Whether all possible digits should be displayed as 0s.
     * @returns {string} The format string.
     * @memberof NumberFormatHelper
     */
    public getFixedPointFormat(preDigits: number, postDigits: number, useThousandsSeparator: boolean = false, forceDigitDisplay: boolean = false): string {
        // Setup pre Digit format
        const placeholderCharacter = forceDigitDisplay ? '0' : '#';
        let preDigitsPlaceholder: string = '';
        if (preDigits > 0) {
            for (let i = 0; i < preDigits; i++) {
                if (i === preDigits - 1) {
                    preDigitsPlaceholder += '0';
                } else {
                    preDigitsPlaceholder += placeholderCharacter;
                }
            }
        } else {
            preDigitsPlaceholder = '#';
        }

        // Setup after digit format
        let afterDigitsPlaceholder: string = '';
        for (let i = 0; i < postDigits; i++) {
            afterDigitsPlaceholder += placeholderCharacter;
        }

        if (useThousandsSeparator) {
            if (preDigitsPlaceholder === '#') {
                preDigitsPlaceholder = '#,###';
            } else {
                const digitsPerChunk = 3;
                const completeChunks = Math.floor(preDigitsPlaceholder.length / digitsPerChunk);
                const firstChunkSize = preDigitsPlaceholder.length % digitsPerChunk;
                preDigitsPlaceholder = placeholderCharacter.repeat(firstChunkSize) + `,${placeholderCharacter.repeat(digitsPerChunk)}`.repeat(completeChunks);
                // Remove leading thousands separator if present
                preDigitsPlaceholder = preDigitsPlaceholder.replace(/^\,/, '');
            }
        }

        return preDigitsPlaceholder + '.' + afterDigitsPlaceholder;
    }


    /**
     * Returns a format string for the float format.
     * Will allow 15 pre and 15 post decimal digits.
     *
     * @param {boolean} [useThousandsSeparator=false] Whether to use a thousands separator.
     * @param {boolean} [forceDigitDisplay=false] Whether all possible digits should be displayed as 0s.
     */
    public getFloatFormat(useThousandsSeparator: boolean = false, forceDigitDisplay: boolean = false): string {
        const maxDigits = 15;
        return this.getFixedPointFormat(maxDigits, maxDigits, useThousandsSeparator, forceDigitDisplay);
    }

    /**
     * Formats the given string to a number.
     *
     * @param {string} input    String to format to number.
     * @returns {number} The number representing the string.
     * @memberof NumberFormatHelper
     */
    public fromString(input: string): number {
        const currentLanguage = this.getCurrentCulture();
        // How to internationally parse string for float: https://stackoverflow.com/questions/7431833/convert-string-with-dot-or-comma-as-decimal-separator-to-number-in-javascript
        input = input.replace(/[^\d,.-]/g, '');
        if (currentLanguage !== 'de' && /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(input)) {
            input = input.replace(/,/g, '');
            return parseFloat(input);
        } else if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(input)) {
            input = input.replace(/\./g, '');
            input = input.replace(/,/g, '.');
            return parseFloat(input);
        } else {
            input = input.replace(/,/g, '');
            return parseFloat(input);
        }
    }
}