import { IStyleManager } from '../ui';

/**
 * Manage styles for the view.
 * 
 * @export
 * @class ViewStyleManager
 */
export class ViewStyleManager implements IStyleManager {
    /**
     * The prefix for style classes.
     * 
     * @private
     * @memberof ViewStyleManager
     */
    private readonly STYLE_PREFIX = 'wd-style-';
    /**
     * Default style.
     * 
     * @private
     * @memberof ViewStyleManager
     */
    private readonly DEFAULT_STYLE = 'colors-15';
    private rootNode: HTMLElement;

    /**
     * Creates an instance of ViewStyleManager.
     * @param {HTMLElement} rootNode Root node to operate.
     * @memberof ViewStyleManager
     */
    public constructor(rootNode: HTMLElement) {
        this.rootNode = rootNode;
    }

    /**
     * Applies the given style to the layout.
     * Will remove any previously set style.
     * 
     * @param {string} styleClassName Style to apply.
     * @memberof ViewStyleManager
     */
    public updateStyle(styleClassName: string): void {
        if (!styleClassName) {
            styleClassName = this.STYLE_PREFIX + this.DEFAULT_STYLE;
        }

        if (this.rootNode) {

            const desiredStyleType = this.getStyleType(styleClassName);

            // At first remove the old style
            const definedClasses = this.rootNode.classList;
            for (let i = 0; i < definedClasses.length; i++) {
                const cssClass = definedClasses.item(i);
                if (!cssClass) {
                    continue;
                }
                // Check whether it is a wd-style class and also get the wd-style type.
                const styleType = this.getStyleType(cssClass);
                if (styleType && styleType !== '' && styleType === desiredStyleType) {
                    // Remove the old matching style class
                    this.rootNode.classList.remove(cssClass);
                }
            }

            // Then set the new style
            this.rootNode.classList.add(styleClassName);
        }
    }

    /**
     * Removes all custom styles.
     * 
     * @memberof ViewStyleManager
     */
    public removeStyles(): void {
        const definedClasses = this.rootNode.classList;
        for (let i = 0; i < definedClasses.length; i++) {
            const cssClass = definedClasses.item(i);
            if (cssClass && cssClass.startsWith(this.STYLE_PREFIX)) {
                // Remove the old matching style class
                this.rootNode.classList.remove(cssClass);
            }
        }
    }

    /**
     * Returns only the style part of a full style class name.
     * Example: wd-style-colors-0 --> wd-style-colors
     *
     * @param {string} styleClassName
     * @returns {string}
     *
     * @memberof ViewStyleManager
     */
    private getStyleType(styleClassName: string): string {
        if (!styleClassName || !styleClassName.startsWith(this.STYLE_PREFIX)) {
            return '';
        }

        return styleClassName.substring(0, styleClassName.lastIndexOf('-'));
    }
}