import { DWCore } from '../dynamicWorkspace';
import { ILanguageManager } from '../language';
import { Logger } from '../logging';
import { IPubSubHandler } from '../pubSub';
import { GridLayoutManager } from './gridLayoutManager';
import { ILayoutManager, ILayoutManagerFactory } from './interfaces';
import { RowLayoutManager } from './rowLayoutManager';

/**
 * This factory provides instances of ILayoutManagerFactory.
 * 
 * @export
 * @interface ILayoutManagerFactory
 */
export class LayoutManagerFactory implements ILayoutManagerFactory {
    private logger: Logger;
    private pubSubHandler: IPubSubHandler;
    private isHeaderLess = false;

    /**
     * Creates an instance of LayoutManagerFactory.
     * @param {Logger} logger The logger.
     * @param {IPubSubHandler} pubSubHandler The pubsub handler.
     * @param {boolean} isHeaderLess Whether the application is running headerless.
     * @memberof LayoutManagerFactory
     */
    public constructor(logger: Logger, pubSubHandler: IPubSubHandler, isHeaderLess: boolean) {
        this.logger = logger;
        this.pubSubHandler = pubSubHandler;
        this.isHeaderLess = isHeaderLess;
    }

    /**
     * Creates a new instance of ILayoutManager.
     * 
     * @param {DWCore.Components.COMPONENT_TYPES} type Type of the LayoutManager to create.
     * @param {ILanguageManager} languageManager LanguageManager instance to pass to the LayoutManager.
     * @param {HTMLElement} root Root element to pass to the LayoutManager.
     * @returns {ILayoutManager} 
     * 
     * @memberof LayoutManagerFactory
     */
    public create(type: DWCore.Components.COMPONENT_TYPES, languageManager: ILanguageManager, root: HTMLElement): ILayoutManager {
        switch (type) {
            case DWCore.Components.COMPONENT_TYPES.LOGICAL:
                return new RowLayoutManager(languageManager, root, this.logger, this.pubSubHandler);
            case DWCore.Components.COMPONENT_TYPES.UI:
                return new GridLayoutManager(languageManager, root, this.logger, this.pubSubHandler, this.isHeaderLess);
            default:
                throw new ReferenceError(`Invalid value for COMPONENT_TYPE: ${type}`);
        }
    }
}