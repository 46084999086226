import { Utils } from '../common';
import { HttpResourcePointer, IBinaryHttpResourcePointer } from '.';

/**
 * An instance of this class can point to a specific HTTP-resource, which will be downloaded via binary stream.
 * 
 * @export
 * @class HttpResourcePointer
 * @extends {BinaryHttpResourcePointer}
 */
export class BinaryHttpResourcePointer extends HttpResourcePointer implements IBinaryHttpResourcePointer {

    /**
     * The response type.
     * 
     * @type {string}
     * @memberof BinaryHttpResourcePointer
     */
    public responseType: string;


    /**
     * Creates an instance of BinaryHttpResourcePointer.
     *
     * @param {string} action 
     * @param {string} uri 
     * @param {string} responseType 
     * @param {Object} [parameter] 
     * @param {Map<string, string>} [httpHeaders] 
     * @memberof BinaryHttpResourcePointer
     */
    public constructor(action: string, uri: string, responseType: string, parameter?: Object, httpHeaders?: Map<string, string>) {
        super(action, uri, parameter, httpHeaders);

        this.scheme = 'http-blob';
        this.responseType = responseType;

        this.parameter = parameter;
    }

    /**
     * Calculates the hash.
     * Overwrite basic function inherited by super class.
     * Returns a random hash.
     *
     * @returns {*}  {string} The hash.
     * @memberof BinaryHttpResourcePointer
     */
    public calculateHash(): string {
        // As requests sending binary data (i.e. files) to the server should not be cached
        // always return a random string as a hash.
        const seed = Utils.Instance.getRandomString();
        // Use SHA-256 to keep hash aligned with other ResourcePointers
        return this.encryptionHelper.sha256(seed);
    }
}