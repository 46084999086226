import { GetStringContentResponseContainerDTO } from '../../../typings/windreamWebService/Windream.WebService.DynamicWorkspace';
import { IServiceResponse } from '../../ajaxHandler/interfaces/iServiceResponse';
import { GlobalConfig } from '../../config';
import { HttpResourcePointer } from '../../dataProviders';
import { IRequestExecutor } from '../../dataProviders/interfaces/iRequestExecutor';
import { Logger } from '../../logging';
import { IdentityMapper } from '../identityMapper';
import { WindreamRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Service action to perform a GetStringContent on the windream webservice.
 * 
 * @export
 * @class GetStringContent
 * @extends {ServiceAction}
 */
export class GetStringContent extends ServiceAction {

    /**
     * Creates an instance of GetStringContent.
     *
     * @param {IRequestExecutor} requestExecutor The request executor.
     * @param {GlobalConfig} globalConfig The global configuration.
     * @param {Logger} logger The logger.
     * @memberof GetStringContent
     */
    public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger) {
        super(requestExecutor, globalConfig, logger);

        this.name = 'getStringContent';
    }

    /**
     * Gets the document's content as string.
     * Resolves with the server response.
     * Overwrites `do()` method from parent class ServiceAction.
     *
     * @param {WindreamRequestOptions} windreamRequestOptions The request options.
     * @returns {Promise<GetStringContentResponseContainerDTO>} A promise, which will resolve with the server response.
     * @memberof GetStringContent
     */
    public async do(windreamRequestOptions: WindreamRequestOptions): Promise<GetStringContentResponseContainerDTO> {
        return new Promise<GetStringContentResponseContainerDTO>((resolve, reject) => {
            const url = `${this.globalConfig.windreamWebServiceURL}/dynamicworkspace/utils/GetStringContent`;

            const item = IdentityMapper.getWebServiceIdentity(windreamRequestOptions.identity);

            this.requestExecutor.executeRequest(new HttpResourcePointer('POST', url, {
                Item: item,
            }), windreamRequestOptions.requestOptions).then((response: IServiceResponse<GetStringContentResponseContainerDTO>) => {
                if (!response.data || response.data.HasErrors) {
                    this.logger.error('GetStringContent', 'do', 'Failed to get string content', response);
                    reject(new Error('Failed to get string content'));
                } else {
                    resolve(response.data);
                }
            }).catch((err) => {
                this.logger.error('GetStringContent', 'do', 'Failed to execute request', err);
                reject(err);
            });
        });
    }
}