import { IdLocationNameIdentityDTO } from '../../typings/windreamWebService/Windream.WebService';
import { WindreamIdentity } from '../common';

/**
 * Class to map WindreamIdentity to the Webservice Identities.
 * 
 * @export
 * @class IdentityMapper
 */
export class IdentityMapper {
  /**
   * Returns a valid identity that can be processed by the webservice from the given WindreamIdenity.
   * 
   * @static
   * @param {WindreamIdentity} identity Identity to parse.
   * @returns {IdLocationNameIdentityDTO} Identity that can be handled by the windream webservice.
   * 
   * @memberof IdentityMapper
   */
  public static getWebServiceIdentity(identity: WindreamIdentity): IdLocationNameIdentityDTO {
    if (typeof identity.id === 'number') {
      return {
        Id: identity.id
      } as IdLocationNameIdentityDTO;
    } else if (typeof identity.name === 'string' && typeof identity.location === 'string') {
      return {
        Location: identity.location,
        Name: identity.name
      } as IdLocationNameIdentityDTO;
    } else {
      throw new Error('Given identity is invalid');
    }
  }

  /**
   * Returns a valid string to append as GET parameter for the webservice from the given WindreamIdentity.
   * 
   * @static
   * @param {WindreamIdentity} identity Identity to parse.
   * @returns {string} String to append to GET URL after the ?.
   * @memberof IdentityMapper
   */
  public static getWebServiceGetParameter(identity: WindreamIdentity): string {
    let parameter = '';
    if (typeof identity.id === 'number') {
      parameter = `parameter.item.id=${identity.id}`;
    } else if (typeof identity.name === 'string' && typeof identity.location === 'string') {
      const encodedName = encodeURIComponent(identity.name);
      const encodedLocation = encodeURIComponent(identity.location);
      parameter = `parameter.item.name=${encodedName}&parameter.item.location=${encodedLocation}`;
    } else {
      throw new Error('Given identity is invalid');
    }
    return parameter;
  }
}