import { ILanguageProvider, ITemplateExtension } from '../../dynamicWorkspace';

/**
 * This class represents a tab panel page.
 *
 * @export
 * @abstract
 * @class TabPanelPage
 */
export abstract class TabPanelPage {

    /**
     * Gets the title.
     *
     * @abstract
     * @return {string} The title.
     * @memberof TabPanelPage
     */
    public abstract getTitle(): string;

    /**
     * Initializes the tab panel page.
     *
     * @returns {Promise<void>}
     * @memberof TabPanelPage
     */
    public initialize(): Promise<void> {
        return Promise.resolve();
    }

    /**
     * Renders the tab panel page.
     *
     * @param {HTMLElement} element The target element.
     * @returns {Promise<void>}
     * @memberof TabPanelPage
     */
    public render(element: HTMLElement): Promise<void> {
        return new Promise<void>(async (resolve) => {
            // Render the content
            await this.renderContent(element);
            resolve();
        });
    }

    /**
     * Renders the content.
     *
     * @protected
     * @abstract
     * @param {HTMLElement} element The target element.
     * @return {Promise<void>}
     * @memberof TabPanelPage
     */
    protected abstract renderContent(element: HTMLElement): Promise<void>;

    /**
     * The current language.
     *
     * @protected
     * @type {string}
     * @memberof TabPanelPage
     */
    protected currentLanguage: string;

    /**
     * The language provider.
     *
     * @protected
     * @type {ILanguageProvider}
     * @memberof TabPanelPage
     */
    protected languageProvider: ILanguageProvider;

    /**
     * The template extension.
     *
     * @protected
     * @type {ITemplateExtension}
     * @memberof TabPanelPage
     */
    protected templateExtension: ITemplateExtension;


    /**
     * Creates an instance of TabPanelPage.
     *
     * @param {ITemplateExtension} templateExtension The template extension.
     * @param {string} currentLanguage The current language.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @memberof TabPanelPage
     */
    public constructor(templateExtension: ITemplateExtension, currentLanguage: string, languageProvider: ILanguageProvider) {
        this.templateExtension = templateExtension;
        this.currentLanguage = currentLanguage;
        this.languageProvider = languageProvider;
    }

}