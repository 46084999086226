/**
 * Represents a windream license.
 *
 * @export
 * @class WindreamLicense
 */
export class WindreamLicense {
    /**
     * The owner of the license.
     *
     * @type {string}
     * @memberof WindreamLicense
     */
    public owner: string;

    /**
     * The copyright.
     *
     * @type {string}
     * @memberof WindreamLicense
     */
    public copyright: string;

    /**
     * The license description.
     *
     * @type {string}
     * @memberof WindreamLicense
     */
    public description: string;

    /**
     * The support mail.
     *
     * @type {string}
     * @memberof WindreamLicense
     */
    public supportMail?: string;

    /**
     * The support website.
     *
     * @type {string}
     * @memberof WindreamLicense
     */
    public supportWebsite?: string;

    /**
     * The support phone number.
     *
     * @type {string}
     * @memberof WindreamLicense
     */
    public supportPhoneNumber?: string;

    /**
     * Creates an instance of WindreamLicense.
     * @param {string} owner The owner of the license.
     * @param {string} copyright The copyright.
     * @param {string} description The description.
     * @memberof WindreamLicense
     */
    public constructor(owner: string, copyright: string, description: string) {
        this.owner = owner;
        this.copyright = copyright;
        this.description = description;
    }
}