import { IFileSaver } from '..';
import { GlobalConfig } from '../../config';
import { IRequestExecutor } from '../../dataProviders';
import { Logger } from '../../logging/logger';
import { SaveToFileSystemRequestOptions } from '../models';
import { ServiceAction } from '../serviceAction';

/**
 * Save to file system service action.
 *
 * @export
 * @class SaveToFileSystem
 * @extends {ServiceAction}
 */
export class SaveToFileSystem extends ServiceAction {

  private fileSaver: IFileSaver;

  /**
   * Name of the service action.
   * 
   * 
   * @memberof SaveToFileSystem
   */
  public name = 'saveToFileSystem';

  /**
   * Creates an instance of SaveToFileSystem.
   * 
   * @param {IRequestExecutor} requestExecutor The request executor.
   * @param {GlobalConfig} globalConfig The global config.
   * @param {Logger} logger The logger.
   * @param {IFileSaver} fileSaver The file saver.
   * @memberof SaveToFileSystem
   */
  public constructor(requestExecutor: IRequestExecutor, globalConfig: GlobalConfig, logger: Logger, fileSaver: IFileSaver) {
    super(requestExecutor, globalConfig, logger);
    this.fileSaver = fileSaver;
    this.name = 'saveToFileSystem';
  }

  /**
   * Save the file to the file system.
   *
   * @param {SaveToFileSystemRequestOptions} saveToFileSystemRequestOptions The file request options.
   * @returns {Promise<void>} A promise, will reject if saving failed.
   * @memberof SaveToFileSystem
   */
  public async do(saveToFileSystemRequestOptions: SaveToFileSystemRequestOptions): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.fileSaver.saveAs(saveToFileSystemRequestOptions.buffer, saveToFileSystemRequestOptions.fileName);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}