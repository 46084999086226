import { ILanguageProvider } from '../language';
import { FormatHelper } from './formatHelper';

/**
 * The boolean format helper transforms a value into a boolean string.
 * 
 * @export
 * @class BooleanFormatHelper
 * @extends {FormatHelper}
 */
export class BooleanFormatHelper extends FormatHelper {
    private languageProvider: ILanguageProvider;

    /**
     * Creates an instance of BooleanFormatHelper.
     *
     * @param {ILanguageProvider} languageProvider
     * @memberof BooleanFormatHelper
     */
    public constructor(languageProvider: ILanguageProvider) {
        super();
        this.languageProvider = languageProvider;
    }

    /**
     * Gets a boolean string from a value.
     * 
     * @param {(number | boolean | null)} value The value.
     * @returns {string} The boolean string.
     * 
     * @memberof BooleanFormatHelper
     */
    public getBooleanString(value: number | boolean | null): string {
        let translation = '';
        if (value === null) {
            // Return nothing, maybe add placeholder
            return translation;
        } else if (value) {
            translation = this.languageProvider.get('framework.generic.true');
        } else {
            translation = this.languageProvider.get('framework.generic.false');
        }
        return translation;
    }

    /**
     * Converts the given string to boolean.
     * Checks if the string is 1 (true) or 0 (false).
     * Checks if the string matches the translations for true or false.
     * Checks if the string matches the translations for yes or no.
     * Returns null if value cannot be parsed.
     *
     * @param {string} value String to convert.
     * @returns {boolean | null} Boolean representation of the string.
     * @memberof BooleanFormatHelper
     */
    public fromString(value: string): boolean | null {
        if (
            value === '1'
            || value.toLocaleLowerCase() === 'true'
            || value.toLocaleLowerCase() === this.languageProvider.get('framework.generic.true').toLocaleLowerCase()
            || value.toLocaleLowerCase() === this.languageProvider.get('framework.generic.yes').toLocaleLowerCase()
            || value.toLocaleLowerCase() === this.languageProvider.get('framework.generic.on').toLocaleLowerCase()
        ) {
            return true;
        } else if (
            value === '0'
            || value.toLocaleLowerCase() === 'false'
            || value.toLocaleLowerCase() === this.languageProvider.get('framework.generic.false').toLocaleLowerCase()
            || value.toLocaleLowerCase() === this.languageProvider.get('framework.generic.no').toLocaleLowerCase()
            || value.toLocaleLowerCase() === this.languageProvider.get('framework.generic.off').toLocaleLowerCase()
        ) {
            return false;
        } else {
            return null;
        }
    }
}