import { ILanguageProvider, ITemplateExtension } from '../../dynamicWorkspace';
import { ILicenseSeviceHelper } from '../interfaces';
import { LicenseTabPanelPage } from './licenseTabPanelPage';

export class LicenseInfoTabPanelPage extends LicenseTabPanelPage {

    private jQuery: JQueryStatic;


    /**
     * Creates an instance of LicenseInfoTabPanelPage.
     *
     * @param {ITemplateExtension} templateExtension The template extension.
     * @param {string} currentLanguage The current language.
     * @param {ILanguageProvider} languageProvider The language provider.
     * @param {ILicenseSeviceHelper} licenseServiceHelper The license service helper.
     * @param {JQueryStatic} jQuery The jQuery instance.
     * @memberof LicenseInfoTabPanelPage
     */
    public constructor(templateExtension: ITemplateExtension, currentLanguage: string, languageProvider: ILanguageProvider, licenseServiceHelper: ILicenseSeviceHelper, jQuery: JQueryStatic) {
        super(templateExtension, currentLanguage, languageProvider, licenseServiceHelper);

        this.jQuery = jQuery;
    }


    /**
     * Gets the title.
     *
     * @abstract
     * @returns {string} The title.
     * @memberof LicenseInfoTabPanelPage
     */
    public getTitle(): string {
        return this.languageProvider.get('framework.license.popup.tabs.legal.title');
    }

    /**
     * Renders the content.
     *
     * @abstract
     * @param {HTMLElement} element The target element.
     * @memberof LicenseInfoTabPanelPage
     */
    public renderContent(element: HTMLElement): Promise<void> {
        this.templateExtension.render(element, require('../templates/licenseInfo.html'));
        this.jQuery(element).find('.wd-license-popup-third-party-scroller').dxAccordion({
            collapsible: true,
            dataSource: this.licenseDetails.thirdPartyLicenses,
            itemTemplate: (_accordionData, itemIndex, itemElement) => {
                itemElement.append(this.jQuery('<div></div>').text(this.licenseDetails.thirdPartyLicenses[itemIndex].description));
            },
            itemTitleTemplate: (_accordionData, itemIndex, itemElement) => {
                itemElement.append(this.jQuery('<div class="wd-accordion-title"></div>').text(this.licenseDetails.thirdPartyLicenses[itemIndex].productName + ' - ' +
                    this.licenseDetails.thirdPartyLicenses[itemIndex].type));
            },
            multiple: true
        });

        return Promise.resolve();
    }

}